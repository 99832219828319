
//UCR
import { LoginUcrPage, LoginUcr2Page, RegisterUcrPage } from "@pages/med2lab/UcrPage";

const ucrRoutes = [
    // UCR Login
    {
        path: "/ucr/login",
        exact: true,
        classPage: '',
        component: LoginUcrPage,
        isLayout: false,
    },
    {
        path: "/ucr/login/v2",
        exact: true,
        classPage: '',
        component: LoginUcr2Page,
        isLayout: false,
    },
    {
        path: "/ucr/register",
        exact: true,
        classPage: '',
        component: RegisterUcrPage,
        isLayout: false,
    }
];

export { ucrRoutes };