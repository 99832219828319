// ModalCreateNewCase.js

import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";

//reactstrap
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormFeedback,
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row
} from 'reactstrap';

//react-router-dom
import { useHistory } from "react-router-dom";

//utils
import { apiCaller } from "@utils";

function ModalCreateNewCase({ isOpen, toggle, params }) {
    let history = useHistory();
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let defaultValues = {};
        defaultValues.Title = params?.Title;
        defaultValues.PatientName = params?.PatientName;
        defaultValues.Age = params?.Age;
        defaultValues.Gender = params?.Gender === '1' ? "Male" : "Female";
        reset({ ...defaultValues });
    }, [params, reset]);

    const onSubmit = (data) => {
        setLoading(true);
        const params = {
            "Mode": "BlankCase",
            "Title": data?.Title,
            "PatientName": data?.PatientName,
            "Age": data?.Age,
            "Gender": data?.Gender
        };
        apiCaller(`/api/teach/create/case/`, "POST", params).then((res) => {
            const data = res?.data;
            const NewCaseId = data?.NewCaseId;
            const statusApi = data?.status;
            if (res?.status === 200 && statusApi === "success") {
                setLoading(false);
                toggle();
                history.push(`/teach/new-version/${NewCaseId}`);
            } else {
                console.log('error');
                setLoading(false);
                toggle();
            }
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>
                Create New Case
            </ModalHeader>
            <ModalBody>
                <Form id="formStep1">
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="labeForm" for="Title">Case Title <span className="m--font-danger">*</span></Label>
                                <Input
                                    {...register("Title", { required: true })}
                                    type="text"
                                    name="Title"
                                    id="Title"
                                    invalid={errors.Title?.type === "required"}
                                    defaultValue={watch("Title")}
                                    onChange={(e) => {
                                        setValue("Title", e.target.value, { shouldValidate: true });
                                    }}
                                    placeholder="example: a 12-year-old boy with fever"
                                    autoComplete="off"
                                />
                                <small className="form-text text-muted">Case title can be revised later</small>
                                <FormFeedback
                                    invalid={String(errors.Title?.type === "required")}
                                >
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="labeForm" for="PatientName">Patient's Name <span className="m--font-danger">*</span></Label>
                                <Input
                                    {...register("PatientName", { required: true })}
                                    type="text"
                                    name="PatientName"
                                    id="PatientName"
                                    invalid={errors.PatientName?.type === "required"}
                                    defaultValue={watch("PatientName")}
                                    onChange={(e) => {
                                        setValue("PatientName", e.target.value, { shouldValidate: true });
                                    }}
                                    placeholder="example: Mary"
                                    autoComplete="off"
                                />
                                <small className="form-text text-muted">The patient’s name can be revised later</small>
                                <FormFeedback
                                    invalid={String(errors.Title?.type === "required")}
                                >
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup>
                                <Label className="labeForm" for="Age">Age  <span className="m--font-danger">*</span></Label>
                                <InputGroup>
                                    <Input
                                        {...register("Age", { required: true })}
                                        type="number"
                                        name="Age"
                                        id="Age"
                                        invalid={errors.Age?.type === "required"}
                                        defaultValue={watch("Age")}
                                        onChange={(e) => {
                                            setValue("Age", e.target.value, { shouldValidate: true });
                                        }}
                                        placeholder=""
                                        autoComplete="off"
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>Year(s)</InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback
                                        invalid={String(errors.Age?.type === "required")}
                                    >
                                        This is required.
                                    </FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup tag="fieldset">
                                <Label className="labeForm" for="Age">Gender  <span className="m--font-danger">*</span></Label>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            {...register("Gender", { required: true })}
                                            type="radio"
                                            checked={watch("Gender") === 'Female' ? true : false}
                                            value='Female'
                                            name="Gender"
                                            onChange={(e) => {
                                                setValue("Gender", e.target.value, { shouldValidate: true });
                                            }}
                                        />{' '}Female
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            {...register("Gender", { required: true })}
                                            type="radio"
                                            checked={watch("Gender") === 'Male' ? true : false}
                                            value='Male'
                                            name="Gender"
                                            onChange={(e) => {
                                                setValue("Gender", e.target.value, { shouldValidate: true });
                                            }}
                                        />{' '}Male
                                    </Label>
                                </FormGroup>
                                {errors.Gender?.type === "required" && <div invalid="true" class="invalid-feedback d-block">This is required.</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCreateNewCase;