import React from 'react';
import { Link } from "react-router-dom";

function ButtonShowDragDrop(props) {
    return (
        <div className="text-center">
            <Link
                className="btn btn-outline-brand d-inline-block"
                to={`/#`}
                target={`_blank`}
            >
                <i className="fa fa-bars" aria-hidden="true"></i>
                &nbsp; Show Drag-and-drop
            </Link>
        </div>
    );
};

export default ButtonShowDragDrop;