/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { ListCard, TableListGenerated } from '@components/admin/managementGenerated'
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@components/common'
import Calendar from '@images/new_interface/sidebar/calendar.svg'
import moment from 'moment';
import { useDispatch } from "react-redux";
import { getMcqUsage, getDetailedMcqUsage } from "@actions"

const ManageGenerated = () => {
  const dispatch = useDispatch();
  const [chartActive, setChartActive] = useState("mcq_usage")
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleGetData = (start, end) => {
    dispatch(getDetailedMcqUsage({
      "action_type": "detailed_usage",
      "tab_name": chartActive,
      "start_date": moment(start).format("MM/DD/YYYY 0:0:0"),
      "end_date": moment(end).format("MM/DD/YYYY 23:59:0"),
    }))
  }

  const handleGetListUsage = (start, end) => {
    dispatch(getMcqUsage({
      "action_type": "overall_usage",
      "start_date": moment(start).format("MM/DD/YYYY 0:0:0"),
      "end_date": moment(end).format("MM/DD/YYYY 23:59:0"),
    }))
  }

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if(newStartDate && newEndDate) {
      handleGetData(newStartDate, newEndDate)
      handleGetListUsage(newStartDate, newEndDate)
    }
  };

  useEffect(() => {
    handleGetListUsage(startDate, endDate)
    handleGetData(startDate, endDate)
  }, [])

  return (
    <div className="wrapper -dashboardGenerated">
      <div className='set-calendar'>
        <Icon src={Calendar} />
        <ReactDatePicker
          selected={startDate}
          onChange={handleChange}
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat="yyyy/MM/dd"
        />
      </div>
      <ListCard chartActive={chartActive} setChartActive={setChartActive} startDate={startDate} endDate={endDate}/>
      <TableListGenerated chartActive={chartActive}/>
    </div>
  )
}

export default ManageGenerated
