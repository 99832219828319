import React, { useState, useMemo } from "react";

//react-select
import Select from "react-select";

function TopicDropdown(props) {

    //props
    const { data, isDisabled } = props && props;

    const [selectedOption, setSelectedOption] = useState(null);

    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.TopicId, label: item?.TopicName };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    }

    const listDropdown = useMemo(() => convertDataOptions((data && data) || []), [data]);
    return (
        <Select
            closeMenuOnSelect={true}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={listDropdown}
            placeholder={`Select an item below`}
            isDisabled={(listDropdown && listDropdown.length === 0) || isDisabled}
        />
    );
}

export default TopicDropdown;
