import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton

//redux
import { useSelector } from "react-redux";

// components
import { ClearAllSelectedButton } from "../Orders";

const CardWrap = (props) => {
  const { addedItems, headingFoeExistingOrder, heading, children, buttonClear, panelHeight: panelHeightProp } = props;
  // Lấy dữ liệu đơn hàng từ Redux store
  const reducerOrders = useSelector((state) => state?.orders || {});
  const isLoading = reducerOrders?.isLoading;
  
  // Khởi tạo state cho panelHeight
  const [panelHeight, setPanelHeight] = useState(0);

  // Load Height Wrap
  useEffect(() => {
    if (panelHeightProp) {
      setPanelHeight(Math.round(panelHeightProp));
    }
  }, [panelHeightProp]);

  return (
    <StyledCardWrap className='border'>
      {(heading || buttonClear) && addedItems?.length > 0 && (
        <ButtonContainer>
          {heading && <CardHeading>{heading}</CardHeading>}
          {buttonClear && <ClearAllSelectedButton {...props} />}
        </ButtonContainer>
      )}
      {headingFoeExistingOrder && headingFoeExistingOrder()}
      <ContentContainer height={panelHeight}>
        {isLoading ? (
          <Skeleton count={5} height={60} /> // Show skeleton loader while loading
        ) : (
          children
        )}
      </ContentContainer>
    </StyledCardWrap>
  );
};

export default CardWrap;

// Styled Components
const StyledCardWrap = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  background-color: #ffffff;
  height: 100%;
  flex-grow: 1; 
`;

const CardHeading = styled.h3`
  color: #0089C2;
  font-size: 16px;
  font-weight: 700;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

// Thay đổi ContentContainer để sử dụng height từ props
const ContentContainer = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? `calc(${height}px - 250px)` : 'auto')};
  overflow-y: auto;
`;