import React from "react";
import { Link } from "react-router-dom";

function CardStatistics(props) {
  const { data, title } = props && props;
  const caseData = data && data.Cases;
  const usersData = data && data.Users;
  const currentCase = caseData && caseData.Current;
  const counterCase = caseData && caseData.MaxPerSubscription;
  const currentUser = usersData && usersData.Active;
  const counterUser = usersData && usersData.MaxPerSubscription;
  return (
    <React.Fragment>
      <div className="widgetBox -box-shadow mb-3">
        <div className="widgetBox__head">
          <h3 className="wg-title --text-primary">
            <span className="mr-2">Subscription:</span>{title}
          </h3>
        </div>
        <div className="widgetBox__body p-2">
          <ul className="lsStatistics">
            <li className="lsStatistics__item">
              <p>
                You have created{" "}
                <span className="--text-primary">{currentCase}</span>
                <span className="font-weight-600">/{counterCase}</span> cases.
              </p>
              <Link
                to={`/adminstrator-tool?active=user`}
                className="lsStatistics__item--arrRight"
              ></Link>
            </li>
            <li className="lsStatistics__item">
              <p>
                You have invited{" "}
                <span className="--text-primary">{currentUser}</span>
                <span className="font-weight-600">/{counterUser}</span> users.
              </p>
              <Link
                to={`/adminstrator-tool?active=case`}
                className="lsStatistics__item--arrRight"
              ></Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CardStatistics;
