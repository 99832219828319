import React from 'react';
// import { AiGenerateButton } from '@components-common';

const HeadingTask = (props) => {
    const { title } = props && props; //GenerateAIButton
    return (
        <div className="available-tasks-header d-flex align-items-center justify-content-between">
            <h4>{title}</h4>
            {/* {GenerateAIButton && <AiGenerateButton {...props} />} */}
        </div>
    );
};

export default HeadingTask;