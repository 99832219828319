import { problemsConstant } from "@constants";

export function LoadProblems(state = {}, action) {
    switch (action.type) {
        case problemsConstant.LOAD_PROBLEMS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case problemsConstant.LOAD_PROBLEMS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.CREATE_PROBLEM_ITEM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.UPDATE_PROBLEM_ITEM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.DELETE_PROBLEM_ITEM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.CREATE_PROBLEM_OPTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.UPDATE_PROBLEM_OPTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.DELETE_PROBLEM_OPTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.CREATE_PROBLEM_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.UPDATE_PROBLEM_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.DELETE_PROBLEM_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case problemsConstant.LOAD_PROBLEMS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};