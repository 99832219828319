import React from 'react'
import { Container } from "reactstrap";
import ResultComponent from './ResultComponent';

const ResultScript = (props) => {
  return (
    <Container>
      <ResultComponent {...props}/>
    </Container>
  )
}

export default ResultScript
