import React, { useEffect } from 'react';
import {
    Link,
    useParams
} from "react-router-dom";

//call Action
import { actFetchSeriesRequest } from "@actions";

//redux
import { useDispatch, useSelector } from 'react-redux';

//boostrap
import { 
  Container, 
  Row, 
  Col
} from 'reactstrap';
import LoaderPage from '@utils/LoaderPage';

function WelcomePage () {
    let { id } = useParams();
    const dispatch = useDispatch();
    
    useEffect(() => {
        const fetchData = async () => {
            dispatch(actFetchSeriesRequest(id));
        };
    fetchData();
    }, [dispatch, id]);

    //get data store
    const seriesData = useSelector(state => state.series || []);
    const responseData = seriesData.data;
    const guidPhoto = responseData && responseData.Series && responseData.Series.GuidePhoto;
    const contentWrap = responseData && responseData.Series && responseData.Series.IntroMessage && responseData.Series.IntroMessage.text;
    const nextCaseId = responseData && responseData.Series && responseData.Series.NextCaseId;

    return (
      <div className="wrapper -welComePage" >
        {seriesData.isLoading &&
          <LoaderPage />
        }
        {!seriesData.isLoading &&
            <Container>
                {/* BEGIN: Subheader */}
                <div className="m-subheader">
                    <div className="d-flex my-3">
                        <a href="#!" className="btn btn-brand ml-auto --btn-primary" data-toggle="modal" data-target="#modal_reset_confirm">Restart Series</a>
                    </div>
                </div>
                {/* END: Subheader */}
                <div className="mainSeries">
                    <Row>
                        <Col md={3}>
                            <img className="mainSeries__photo" src={guidPhoto} alt="guide" />
                        </Col>
                        <Col md={9}>
                            <div className="mainSeries__body">
                                <div className="innerCt">
                                    <div className="innerCt__content" dangerouslySetInnerHTML={{ __html: contentWrap }}></div>
                                    <div className="innerCt__bottom">
                                        {nextCaseId &&
                                            <Link to={`/learn/case/${nextCaseId}`} className="btn m-btn--pill btn-brand button_next">Let's Start &gt;</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        }
      </div>
    );
}

export default WelcomePage;