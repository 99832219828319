import React from "react";

//Icon
import { Iconsax } from "@components-common";

//reactstrap
import { Button } from "reactstrap";

function ButtonDeleteV2(props) {
  const { handleDelete, count, isLoading } = props && props;
  return (
    <Button className="btn-custom btn-danger-v2 btn-sm mx-1" onClick={handleDelete}>
      <Iconsax iconName="trash-2" fill="#F04438" size={24} />
      {isLoading && (
        <span className="spinner-border spinner-border-sm mr-2"></span>
      )}
      {count && <span>Delete Items <span className="count-items">{`(${count})`}</span></span>}
    </Button>
  );
}

export default ButtonDeleteV2;