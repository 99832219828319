import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";
import { Link } from "react-router-dom";

//react-hook-form
import { useForm } from "react-hook-form";

//logo
import imgLogo from "@images/kachihealth/logo.png";

//boostrap
import {
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  // CustomInput,
  Alert,
  FormFeedback,
} from "reactstrap";

//redux
// import { useDispatch } from "react-redux";

//api
// import { apiCaller } from "@utils";
// import { registerConstants } from "@constants";

//untils
import { checkAuth } from "@utils";

function FormRegister() {
  //form
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    // reset,
  } = useForm();

  let history = useHistory();
  // const dispatch = useDispatch();
  const Password = useRef({});
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [isLoading, setLoading] = useState(false);
  Password.current = watch("Password", "");

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const onsubmit = (data) => {
    // const params = {
    //   Mode: "Register",
    //   Email: data.Email,
    //   FirstName: data.FirstName,
    //   LastName: data.LastName,
    //   Institution: data.Institution,
    //   Role: "",
    //   isMedicalEducator: "No",
    //   isAdministrator: "No",
    //   Password: data.Password,
    //   CAPTCHAToken: tokenCaptcha,
    //   PrivacyPolicyVersion: "1.0",
    //   PlatformUseAgreementVersion: "1.0",
    // };

    if (tokenCaptcha) {
      setLoading(true);
      // //Call Api
      // apiCaller(`/api/auth/register/`, "POST", params).then((res) => {
      //   const data = res && res.data;
      //   const statusRes = data && data.status;
      //   if (res && res.status === 200) {
      //     dispatch({ type: registerConstants.REGISTER_SUCCESS, payload: data });
      //     setTimeout(() => {
      //       //reset Data
      //       reset({ keepIsValid: false, keepValues: false });
      //       setLoading(false);
      //       document.body.style["background-color"] = "";
      //       //next page
      //       nextPage(statusRes);
      //     }, 2000);
      //   } else {
      //     dispatch({
      //       type: registerConstants.REGISTER_FAILURE,
      //       error: "error",
      //     });
      //   }
      // });
    }
  };

  // const nextPage = (status) => {
  //   if (status === "confirmation_email_sent") {
  //     history.push("/register/email-sent");
  //   }
  //   if (status === "email_existed") {
  //     history.push("/register/email-existed");
  //   }
  // };

  const onChangeCaptcha = (value) => {
    setTokenCaptcha(value);
  };

  return (
    <React.Fragment>
      <Col
        md={`12`}
        lg={`6`}
        className="bg-white -box-shadow registerKachiHealthForm"
      >
        <div className="logo d-flex align-items-center justify-content-start justify-content-md-center mb-4">
          <Link to={"/"} style={{ maxWidth: "270px" }}>
            <img
              className="d-block mx-auto img-fluid"
              src={imgLogo}
              alt="logo"
            />
          </Link>
        </div>
        <Alert
          className="m-alert--default border-0 font-weight-500 text-center"
          color="secondary"
        >
          Thank you for your interests in KachiHealth!
          <br />
          Please fill out the short form below to get started!
        </Alert>
        <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="FirstName">
                  First Name: <span className="m--font-danger">*</span>
                </Label>
                <Input
                  className="form-rounded-all"
                  {...register("FirstName", { required: true })}
                  type="text"
                  name="FirstName"
                  placeholder="Enter your first name"
                  onChange={(e) => {
                    setValue("FirstName", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  invalid={errors.FirstName?.type === "required"}
                  autoComplete="off"
                />
                <FormFeedback
                  invalid={(errors.FirstName?.type === "required").toString()}
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="LastName">
                  Last Name:<span className="m--font-danger">*</span>
                </Label>
                <Input
                  className="form-rounded-all"
                  {...register("LastName", { required: true })}
                  type="text"
                  name="LastName"
                  placeholder="Enter your last name"
                  onChange={(e) => {
                    setValue("LastName", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  invalid={errors.LastName?.type === "required"}
                  autoComplete="off"
                />
                <FormFeedback
                  invalid={(errors.LastName?.type === "required").toString()}
                >
                  This field is required.
                </FormFeedback>
              </FormGroup>
            </Col>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="Email">
                  Email Address: <span className="m--font-danger">*</span>
                </Label>
                <Input
                  className="form-rounded-all"
                  {...register("Email", {
                    required: "This field is required.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid e-mail address",
                    },
                  })}
                  type="email"
                  name="Email"
                  placeholder="Enter your email address"
                  invalid={errors.Email?.type === "required"}
                />
                {errors.Email?.message && (
                  <FormFeedback className="d-block">
                    {errors.Email?.message}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="Institution">Institution / Organization:</Label>
                <Input
                  className="form-rounded-all"
                  type="text"
                  id="Institution"
                  {...register("Institution", { required: true })}
                  name="Institution"
                  placeholder="Enter the name of your institution / organization here"
                  onChange={(e) => {
                    setValue("Institution", e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  invalid={errors.Institution?.type === "required"}
                  autoComplete="off"
                />
                {errors.Institution?.type && (
                  <FormFeedback
                    invalid={(
                      errors.Institution?.type === "required"
                    ).toString()}
                  >
                    This field is required.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="Password">
                  New Password:<span className="m--font-danger">*</span>
                </Label>
                <Input
                  className="form-rounded-all"
                  {...register("Password", {
                    required: "You must specify a password",
                    pattern: {
                      value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/,
                      message:
                        "Password must be 8 characters long, must contain one UPPERCASE letter, must contain one lowercase letter, must contain one number",
                    },
                  })}
                  type="Password"
                  name="Password"
                  placeholder="Enter your new password"
                  invalid={errors.Password?.type === "required"}
                />
                {errors.Password?.message && (
                  <FormFeedback className="d-block">
                    {errors.Password?.message}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col sm={`12`} md={`6`}>
              <FormGroup>
                <Label for="Password_repeat">
                  Confirm Password:
                  <span className="m--font-danger">*</span>
                </Label>
                <Input
                  className="form-rounded-all"
                  {...register("Password_repeat", {
                    validate: (value) =>
                      value === Password.current ||
                      "The passwords do not match",
                  })}
                  type="password"
                  name="Password_repeat"
                  placeholder="Re-enter password"
                />
                {errors.Password_repeat?.message && (
                  <FormFeedback className="d-block">
                    {errors.Password_repeat?.message}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col sm={`12`}>
              <hr />
            </Col>
            <Col sm={`12`} className="mt-2">
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  sitekey={Config.KEY_CAPTCHA}
                  onChange={onChangeCaptcha}
                />
              </div>
              {!tokenCaptcha && (
                <FormFeedback className="d-block text-center">
                  This field is required.
                </FormFeedback>
              )}
            </Col>
            <Col sm={`12`} className="mt-4">
              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn m-btn--pill btn-brand btn-lg px-4"
                  style={{ maxWidth: "200px" }}
                  onClick={handleSubmit(onsubmit)}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  Register
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </Col>
    </React.Fragment>
  );
}

export default FormRegister;