import React from "react";

//useParams
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Icon
import { Iconsax } from "@components-common";

//Actions
import { actCreateExamPoint } from "@actions";
import { Button } from "reactstrap";

function ButtonAddPoint(props) {
    const { timepointData } = props && props;
    const dispatch = useDispatch();
    let { id } = useParams();

    const hanldeAddpoint = () => {
        const params = {
            Action: "Create",
            Target: "ExamPoint",
            TimePointId: timepointData?.Id
        };
        dispatch(actCreateExamPoint(id, params));
    };

    return (
        <Button
            color="primary"
            type="submit"
            name="mode"
            value="add_point"
            onClick={hanldeAddpoint}
        >
            <Iconsax iconName="plus-square" fill="#fff" size={15} />
            <span className="ml-2">Add Point</span>
        </Button>
    );
}

export default ButtonAddPoint;