import React from 'react'
import styled from 'styled-components'

const WrapTabs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  background: #fff;
  border-bottom: 1px solid #F2F4F7;
  .tab-detail {
    cursor: pointer;
    padding: 8px 12px;
    color: #475467;
    font-size: 14px;
    font-weight: 600;
    &.tab-active {
      color: #0089C2;
      border-bottom: 2px solid #0089C2;
      background: #F0F9FC;
    }
    &:hover {
      color: #0089C2;
      border-bottom: 2px solid #0089C2;
    }
  }  
`

export default function TabsCommon(props) {
  const { activeTab, listTabs, handleTabChange } = props
  return (
    <WrapTabs className='custom-tabs'>
      {listTabs?.map((tab, index) => (
        <div 
          key={index}
          className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
          onClick={() => handleTabChange(tab.id)}
        >
          <p>{tab?.title}</p>
        </div>
      ))}
    </WrapTabs>
  )
}
