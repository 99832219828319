import React from 'react';

//imgPerson
import imgPerson from "@images/common/person.png";

function ChatMaxQuestionsAnswer(props) {
    const { content }= props && props;
    return (
        <div className="messengerCt__messages showDelay">
            <div className={`messengerCt__wrapper`}>
                <div className="messengerCt__message messengerCt__message--in">
                    <div className="messengerCt__message-pic">
                        <img src={imgPerson} alt="" />
                    </div>
                    <div className="messengerCt__message-body">
                        <div className="messengerCt__message-content --arrow-left">
                            <div className="messengerCt__message-username"></div>
                            <div 
                                className="messengerCt__message-text"
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatMaxQuestionsAnswer;