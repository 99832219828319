import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//components
import { HeadingTask } from "../ManageAvailableTasks";

//react-router-dom
import { useParams } from "react-router-dom";

//components
import {
    ContentNarrative,
    InstructionsNarrative,
    QuestionsNarrative,
} from "../ManageAvailableTasks/Narrative";

// redux
import { useSelector } from "react-redux";

//api
import { apiCaller } from "@utils";

const NarrativeMain = (props) => {
    let { id } = useParams();

    //state
    const [activeTab, setActiveTab] = useState(1);
    const [contentAiGenerate, setContentAiGenerate] = useState(null);
    const [isLoadingGenerate, setLoadingGenerate] = useState(false);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Contents / Values", content: <ContentNarrative {...props} contentAiGenerate={contentAiGenerate} isLoadingGenerate={isLoadingGenerate} /> },
        { key: 2, label: "Instructions", content: <InstructionsNarrative {...props} /> },
        { key: 3, label: "Questions", content: <QuestionsNarrative {...props} /> }
    ];

    // Handle Tab Change
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    // handle Generate Ai
    const handleGenerateAi = () => {
        const params = {
            "Action": "AI_Generate",
            "Target": "CaseNarrative"
        };
        setLoadingGenerate(true);
        // Call Api
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                setContentAiGenerate(data);
                setLoadingGenerate(false);
            } else {
                console.log("error");
                setLoadingGenerate(false);
            }
        });
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(3); // 3 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Narrative`}
                handleClickBtn={handleGenerateAi}
                isGenerating={isLoadingGenerate}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default NarrativeMain;