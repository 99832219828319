import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { apiCaller } from "@utils";
import { useSelector } from "react-redux";
import { IconAnimationView } from '@components-common/IconAnimation';

function CongratulationsTimePoint({ hanldeToggleAdvance, isOpen }) {
    const { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});

    const handleUpdateCaseByAdvance = () => {
        setLoading(true); // Start loader when initiating update
        apiCaller(`/api/learn/case/advance/`, 'POST', { "CaseId": id })
            .then((res) => {
                if (res?.status === 200) {
                    window.location.reload();
                }
            })
            .catch(() => console.log("error"))
            .finally(() => setLoading(false)); // Stop loader after the update completes
    };

    const { ModalTimePointCleared = {} } = InterfaceTemplateDetails;
    const convertToHTML = (text) => ({ __html: text });
    return (
        <Modal
            className="modal-custom"
            zIndex={16000}
            isOpen={isOpen}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-custom"
            modalTransition={{ timeout: 700 }}
        >
            <ModalBody>
                <div className="modal-inner">
                    <div className="modal-inner__pic d-flex justify-content-center">
                        <IconAnimationView nameAnimation="FD3LXsh1aE" />
                    </div>
                    <div className="modal-inner__desc text-center">
                        <h2 className="headline --size-4 mb-4 text-center">
                            <div dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_title?.Text || "You’re Ready to Move Forward!")} />
                        </h2>
                        <div dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_msg?.Text || "<p>Great job! Advance to the next part of the case, or revisit the previous part if needed.</p>")} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="mx-auto" style={{ width: "276px" }}>
                    <Button
                        color="primary"
                        className="btn-brand-sm -btn-lg w-100 -shadown"
                        onClick={handleUpdateCaseByAdvance}
                        disabled={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        <span dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_button_confirm?.Text || "Continue")} />
                    </Button>
                    <Button
                        color="white"
                        className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
                        onClick={() => hanldeToggleAdvance("nextTimepoint")}
                        disabled={isLoading}
                    >
                        <span dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_button_cancel?.Text || "Review Previous")} />
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default CongratulationsTimePoint;