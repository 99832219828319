import React from "react";

//boostrap
import { FormGroup, Label } from "reactstrap";

//elements
import { ButtonDelete } from '@components-common/elements';

//react-select
import Select, { components } from 'react-select';

function TagFilterSelect(props) {
    //props
    const { handleChangeOptions, dataSelect, selectedOption, toggle, disabled, isLoading } = props || {};

    // Custom Option
    const Option = ({ innerProps, label, data, value }) => {
        return (
            <div 
                {...innerProps}
                style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <span className="react-select-item">{label}</span>
                <div className="remove-button-container">
                    <ButtonDelete handleDelete={() => toggle("deleteTag", data)} />
                </div>
            </div>
        );
    };

    // Custom Menu
    const Menu = (props) => {
        const { children } = props;
        return (
            <components.Menu {...props}>
                {children}
                <div
                    style={{
                        padding: '8px 12px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        borderTop: '1px solid #ccc',
                    }}
                    onClick={() => toggle('addTag', {})}
                >
                    + Add New
                </div>
            </components.Menu>
        );
    };
    
    return (
        <FormGroup>
            <Label className="font-weight-500" htmlFor="Account">
                Tags
            </Label>
            <Select
                className="react-select"
                closeMenuOnSelect={true}
                // menuIsOpen={true}
                onChange={(optionValue) => handleChangeOptions(optionValue, 'tag_id')}
                options={dataSelect?.tag_id || []}
                value={selectedOption?.tag_id}
                classNamePrefix="-reactSelectQuestion"
                components={{ Option, Menu }}
                menuPortalTarget={document.body}
                isDisabled={disabled}
                isClearable={selectedOption?.tag_id?.value ? true : false}
                isLoading={isLoading?.getTagforValidation}
            />
        </FormGroup>
    );
}

export default TagFilterSelect;