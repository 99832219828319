import { managersConstant } from "@constants";

export function managers (state = {}, action) {
    switch (action.type) {
        case managersConstant.LOAD_MANAGERS_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case managersConstant.LOAD_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload.Managers,
                isLoading: false
            };
        case managersConstant.ADD_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload.Managers,
                isLoading: false
            };
        case managersConstant.REMOVE_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload.Managers,
                isLoading: false
            };
        case managersConstant.LOAD_MANAGERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadCandidatesForManagers  (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case managersConstant.LOAD_CANDIDATES_FOR_MANAGERS_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case managersConstant.LOAD_CANDIDATES_FOR_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case managersConstant.CASE_MANAGERS_ADD_SUCCESS:
            newState = { ...state };
            newState.data = action.payload && action.payload.Managers
            return {
                ...newState,
                isLoading: false
            };
        case managersConstant.LOAD_CANDIDATES_FOR_MANAGERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};