import { paymentConstant } from "@constants";
import {apiCaller} from "@utils";

//Update Assistant Tip Record
export const LaunchStripeCreditCard = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/billing/`, 'POST', params).then(res => {
            const data = res && res.data;
            const LaunchUrl = data?.LaunchUrl;
            if (res && res.status === 200) {
                if(LaunchUrl) {
                    window.location.href = LaunchUrl
                }
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_REQUEST, isLoading } }
    function success(data) { return { type: paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_SUCCESS, payload: data } }
    function failure(error) { return { type: paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_FAILURE, error: error } }
}

export const SetDefaultPaymentMethod = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/billing/`, 'POST', params).then(res => {
            const data = res && res.data;
            const LaunchUrl = data?.LaunchUrl;
            if (res && res.status === 200) {
                if(LaunchUrl) {
                    window.location.href = LaunchUrl
                }
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: paymentConstant.SET_DEFAULT_PAYMENT_METHOD_REQUEST, isLoading } }
    function success(data) { return { type: paymentConstant.SET_DEFAULT_PAYMENT_METHOD_SUCCESS, payload: data } }
    function failure(error) { return { type: paymentConstant.SET_DEFAULT_PAYMENT_METHOD_FAILURE, error: error } }
}

export const setSubscriptionAutomaticRenew = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/billing/`, 'POST', params).then(res => {
            const data = res && res.data;
            const LaunchUrl = data?.LaunchUrl;
            if (res && res.status === 200) {
                if(LaunchUrl) {
                    window.location.href = LaunchUrl
                }
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: paymentConstant.SET_AUTOMATIC_RENEW_REQUEST, isLoading } }
    function success(data) { return { type: paymentConstant.SET_AUTOMATIC_RENEW_SUCCESS, payload: data } }
    function failure(error) { return { type: paymentConstant.SET_AUTOMATIC_RENEW_FAILURE, error: error } }
}