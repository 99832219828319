import React from 'react';

//imgPerson
import imgPerson from "@images/common/person.png";

function BotMessage(props) {
    const { value, loadingIndex, index, dataChatRecords, contentStream, isLoading } = props && props;
    return (
        <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={imgPerson}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            Bot:
                        </div>
                        <div className="messengerCt__message-text whiteSpace-break">
                            {dataChatRecords?.length - 1 === index && contentStream ? (
                                <div dangerouslySetInnerHTML={{ __html: contentStream }} />
                            ) : (
                                <div dangerouslySetInnerHTML={{ __html: value?.content }} />
                            )}
                            {isLoading && dataChatRecords?.length - 1 === index && <div className="typing-loader"></div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BotMessage;