import React from 'react';
import IconMoney from '@images/coin/icon-money.svg';

const EarnCoinItem = ({ title, description, iconWidth, iconAlt, coinValue }) => {
    const isPositive = coinValue > 0;
    const isNegative = coinValue < 0;

    return (
        <li className="list-earn-coins__item">
            <div className="e-coins-inner">
                <div className="e-coins-inner__left">
                    <div className="heading-e-coin">
                        <h3>{title}</h3>
                    </div>
                    <p>{description}</p>
                </div>
                <div className="e-coins-inner__right">
                    <div className={`e-coins-counter ${isPositive ? 'positive' : 'negative'}`}>
                        <span className="coin-sign">
                            {isPositive ? '+' : isNegative ? '-' : ''}
                        </span>
                        <img width={iconWidth} src={IconMoney} alt={iconAlt} />
                        <span className="coin-value">
                            {Math.abs(coinValue)}
                        </span>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default EarnCoinItem;