import React from 'react';
import imgDataEmpty from "@images/common/data-emty.png";

function DataEmpty(props) {
    const { className, hiddenIcon, title, content, size } = props && props;
    return (
        <div className="dataEmpty-container">
            <div className={`dataEmpty ${className}`}>
                <div className="dataEmpty__img">
                    {!hiddenIcon &&
                        <img src={imgDataEmpty} alt="emty" style={{maxWidth: size}} />
                    }
                </div>
                <div className="dataEmpty__info">
                    {title && <div className="dataEmpty__info--title">{title}</div>}
                    <div className="dataEmpty__info--text mt-2" dangerouslySetInnerHTML={{ __html: content || 'No Data' }}></div>
                </div>
            </div>
        </div>
    );
};

export default DataEmpty;