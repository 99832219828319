import React from 'react';

//reactstrap
import { Button } from "reactstrap";

function ButtonResetCase(props) {

    const {
        toggle
    } = props && props;

    return (
        <Button className={`btn-sm mr-2 --bg-primary`} color="secondary" onClick={toggle}>
            <span className="mr-2">Reset Case</span>
            <i className="fa fa-refresh text-1" aria-hidden="true"></i>
        </Button>
    );
};

export default ButtonResetCase;