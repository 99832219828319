import React from 'react';
import { Icon } from '@components/common';
import Empty from '@images/teachV2/empty.svg';

const MessageEmtyData = (props) => {
    const { title, text, ButtonBottomRender } = props;

    return (
        <div className="dataEmpty-container">
            <div className={`dataEmptyCase`}>
                <div className="dataEmptyCase__img">
                    <Icon src={Empty} />
                </div>
                <div className="dataEmptyCase__info">
                    <p
                        className="dataEmptyCase__info-title"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <p
                        className="dataEmptyCase__info-text"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </div>
                {/* Render ButtonBottomRender if it exists */}
                {ButtonBottomRender && <div className="dataEmptyCase__button-bottom">{ButtonBottomRender}</div>}
            </div>
        </div>
    );
};

export default MessageEmtyData;