import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ButtonSubmitCommon from '../ButtonSubmitCommon';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common';
import FileCase from '@images/teachV2/file-case.svg';
import Error from '@images/teachV2/error-circle.svg';
import Close from '@images/teachV2/close.svg';
import { useSelector, useDispatch } from "react-redux";
import { MessageEmtyData } from "@ui-partents/Message";
import { getReferenceBook } from "@actions";
import { wizardConstants } from "@constants";
import HeaderCommon from './HeaderCommon';
import { notifyToast } from "@ui-partents/Toast";
import { paginationOptions } from "@utils";
import { SortCaret } from "@ui-partents/Table";
import { SearchCustomForTable } from "@ui-partents/Search";
import { FormGroup, Input } from 'reactstrap';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`;

export default function SelectListCommon(props) {
  const { setValue, dataForm, watch, handleNext } = props;
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.getReferenceBook) || {};

  const initialSelectedBooks = watch(dataForm?.field) || [];
  const [filteredData, setFilteredData] = useState([]);
  const [showError, setShowError] = useState(true);
  const selectedBooksRef = useRef(initialSelectedBooks);

  useEffect(() => {
    dispatch(getReferenceBook());
  }, [dispatch]);

  useEffect(() => {
    if (documents?.Combined) {
      setFilteredData(
        documents.Combined.map(item => ({
          ...item,
          selected: selectedBooksRef.current.some(book => book.document_id === item.document_id),
        }))
      );
    }
  }, [documents]);

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    setValue(dataForm?.field, []);
    selectedBooksRef.current = [];
    setFilteredData([]);
  };

  const handleNextSelect = () => {
    setValue(dataForm?.field, selectedBooksRef.current);
    handleNext();
  };

  const handleClearSearch = () => {
    setFilteredData(
      documents?.Combined.map(item => ({
        ...item,
        selected: selectedBooksRef.current.some(book => book.document_id === item.document_id),
      })) || []
    );
    setValue(dataForm?.field, selectedBooksRef.current.map(book => book.document_id));
  };

  const handlePageChange = () => {
    setFilteredData(filteredData.map(item => ({
      ...item,
      selected: selectedBooksRef.current.some(book => book.document_id === item.document_id),
    })));
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    selectColumnPosition: 'right',
    onSelect: (row, isSelect, rowIndex, e) => {
      const updatedSelectedBooks = [...selectedBooksRef.current];

      if (isSelect) {
        if (updatedSelectedBooks.length >= 5) {
          notifyToast({
            status: "warning",
            content: "Please select up to 5 documents at a time."
          });
          e.preventDefault();
          return false;
        }
        updatedSelectedBooks.push(row);
      } else {
        const index = updatedSelectedBooks.findIndex(book => book.document_id === row.document_id);
        if (index > -1) updatedSelectedBooks.splice(index, 1);
      }

      selectedBooksRef.current = updatedSelectedBooks;
      setFilteredData(filteredData.map(item => ({
        ...item,
        selected: updatedSelectedBooks.some(book => book.document_id === item.document_id),
      })));
      setValue(dataForm?.field, updatedSelectedBooks.map(book => book.document_id));
    },
    selected: selectedBooksRef.current.map(book => book.document_id),
    selectionRenderer: ({ mode, checked, disabled, onChange }) => (
      <FormGroup check className="form-common">
        <Input
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          name="checkbox"
          className="react-checkbox-common react-checkbox-md"
        />
      </FormGroup>
    ),
  };

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      style: { width: "96%" },
      formatter: (value, row) => (
        <div className="d-flex justify-content-between item-book">
          <div className="d-flex">
            <Icon src={FileCase} />
            <p className="ml-2">{value}</p>
          </div>
          {row?.status?.content === 'Caution' && (
            <div className="item-caution">
              <Icon src={Error} />
            </div>
          )}
        </div>
      ),
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <>
      <ToolkitProvider
        keyField="document_id"
        data={filteredData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
              <SearchCustomForTable
                toolkitprops={toolkitprops}
                paginationProps={paginationProps}
                isResetPagination
                searchMode="auto"
                placeholder="Search"
                onClear={handleClearSearch}
              />
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive list-common"
              data={filteredData || []}
              columns={columns}
              bordered={false}
              noDataIndication={() => (
                <MessageEmtyData
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
              selectRow={selectRow}
              onPageChange={handlePageChange}
            />
          </>
        )}
      </ToolkitProvider>
      {selectedBooksRef.current.some(book => book?.status?.content === "Caution") && showError && (
        <div className="note-reference">
          <Icon src={Error} />
          <p>
            This document contains limited readable text, which may affect the generated content quality.
            Please proceed with caution when using it for generation.
          </p>
          <div className="note-reference__close" onClick={() => setShowError(false)}>
            <Icon src={Close} stroke="#DC6803" />
          </div>
        </div>
      )}
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </>
  );

  const totalSize = filteredData.length || 0;
  const paginationConfig = paginationOptions({ totalSize });

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon {...props} />
        <div className="form-area__select">
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        </div>
      </div>
      <div className="action-mcq">
        <ButtonSubmitCommon
          {...props}
          dataPass={!selectedBooksRef.current.length}
          handleNext={handleNextSelect}
          handleBack={handleActionBack}
        />
      </div>
    </WrapCommon>
  );
}