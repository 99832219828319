import React from 'react';
import styled from 'styled-components';

// Styled component for the button
const ButtonCloseModal = styled.button`
  position: absolute;
  right: 16px;
  top: 22px;
  width: 44px;
  height: 44px;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; // Ensure no padding for better alignment
`;

// SVG Icon
const CloseIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        aria-hidden="true" // Accessibility improvement
    >
        <path
            d="M13 1L1 13M1 1L13 13"
            stroke="#98A2B3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

// Main component
const Modal = ({ handleCloseModal }) => (
    <div>
        <ButtonCloseModal onClick={handleCloseModal} aria-label="Close modal">
            <CloseIcon />
        </ButtonCloseModal>
    </div>
);

export default Modal;