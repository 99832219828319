import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FsLightbox from "fslightbox-react";

// Styled components
const MedicalDescription = styled.div`
  border-radius: 8px;
`;

const MedicalDescriptionContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  height: 100%;
  overflow: auto;
  color: #081B2A;
`;

const ContentWithLightbox = ({ narrative }) => {
  const [images, setImages] = useState([]); // Danh sách URL ảnh
  const [lightboxToggler, setLightboxToggler] = useState(false); // Trạng thái mở FsLightbox
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Chỉ số ảnh hiện tại

  // Fetch all images in .medical-description__content
  useEffect(() => {
    const container = document.querySelector('#html-wrapper');
    if (container) {
      // Lấy tất cả hình ảnh
      const images = Array.from(container.querySelectorAll('img')).map((img) => img.src);
      setImages(images);
  
      // Gắn sự kiện click vào ảnh
      container.querySelectorAll('img').forEach((img, index) => {
        img.addEventListener('click', () => handleImageClick(index));
      });
    }
  
    // Cleanup sự kiện để tránh leak
    return () => {
      if (container) {
        container.querySelectorAll('img').forEach((img) => {
          img.removeEventListener('click', handleImageClick);
        });
      }
    };
  }, [narrative.NarrativeText]);  

  // Handle image click
  const handleImageClick = (index) => {
    setCurrentImageIndex(index); // Cập nhật hình ảnh hiện tại
    setLightboxToggler((prev) => !prev); // Đảm bảo toggler luôn thay đổi
  };

  return (
    <>
      <MedicalDescription>
        {/* Nội dung HTML hiển thị */}
        <MedicalDescriptionContent id="html-wrapper" dangerouslySetInnerHTML={{ __html: narrative.NarrativeText }} />
      </MedicalDescription>
      {/* Tích hợp FsLightbox */}
      <FsLightbox
        toggler={lightboxToggler}
        sources={images}
        sourceIndex={currentImageIndex}
        disableBackgroundClose={true}
      />
    </>
  );
};

export default ContentWithLightbox;