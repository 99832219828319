import React, { useState } from 'react';
import styled from 'styled-components';
import MedicalDescriptionBox from './MedicalDescriptionBox';
import Tabs from './Tabs';

// Redux
import { useSelector } from "react-redux";

const MedicalInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

const MedicalInfoInner = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 190px);
`;

const MedicalInfoTop = styled.div`
  flex: 1;
`;

const MedicalInfo = (props) => {
    // Lấy dữ liệu từ Redux store
    const caseData = useSelector((state) => state.cases || {});
    const narratives = caseData?.data?.Case?.PastNarratives || [];

    // Xác định tab active ban đầu dựa trên `isCurrent`
    const initialActiveTab = narratives.find(narrative => narrative.isCurrent)?.TimePointId;

    // State quản lý tab active
    const [activeTab, setActiveTab] = useState(initialActiveTab);

    return (
        <MedicalInfoWrapper>
            {/* Tabs */}
            <Tabs
                {...props}
                narratives={narratives}
                activeTab={activeTab}
                onTabChange={setActiveTab} // Thay đổi tab khi người dùng bấm
            />
            <MedicalInfoInner>
                {/* Nội dung mô tả */}
                <MedicalInfoTop>
                    <MedicalDescriptionBox
                        {...props}
                        narratives={narratives}
                        activeTab={activeTab}
                    />
                </MedicalInfoTop>
            </MedicalInfoInner>
        </MedicalInfoWrapper>
    );
};

export default MedicalInfo;