import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDelete(props) {
    const { isOpen, toggle, handleRemove, isLoading } = props && props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
            <ModalBody>
                <h4 className="text-center heading-4 --text-primary">
                    Are you certain that you would like to delete this question?
                </h4>
                <p className="mt-4 text-center">
                    This action is not reversible.
                    <br />
                    All data related to this question will also be deleted.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn btn-danger d-flex align-items-center"
                    onClick={handleRemove}
                    disabled={isLoading}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Delete Question
                </Button>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalDelete;
