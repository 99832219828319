import React from "react";
import { Button } from "reactstrap";
import { Iconsax } from "@components-common";
import { actActiveTabsMedicalRecord } from "@actions";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

//common
import { WrapUpFeedback } from "@components-common/Feedback";

function ChatWrapUpContent(props) {
    //props
    const { isLoading } = props;

    // Initialize Redux dispatch
    let dispatch = useDispatch();

    //props
    const { handleContinueCase, handleExpandChatWrapUp, isStatus } = props;

    // Function to handle click event for reviewing chat records
    const handleClickReviewChatRecord = () => {
        dispatch(actActiveTabsMedicalRecord("Chat_Records")); // Dispatch Redux action to activate chat records tab
    };

    const disableClick = isLoading ? { pointerEvents: 'none' } : {};

    // Media query to detect mobile devices
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div className="chat-wrapUp-container">
            <div className="chat-wrapUp-ct__body">
                <div className="wrap-up-box">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="wrap-up-box__title">Performance Summary</h4>
                        {!isMobile && ( // Hide on mobile
                            <div className="chat-wrapUp-action">
                                <div className="chat-wrapUp-action__btn" onClick={handleClickReviewChatRecord} style={disableClick}>
                                    <span className="chat-wrapUp-action__btn--text">Review Chat Records</span>
                                </div>
                                <div className="chat-wrapUp-action__btn btn-expand" onClick={handleExpandChatWrapUp}>
                                    <Iconsax iconName={isStatus ? "shrink" : "expand"} fill="#0089C2" size={16} />
                                </div>
                            </div>
                        )}
                    </div>
                    <div style={{ marginTop: '24px' }}>
                        <WrapUpFeedback
                            {...props}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <div className="btn-Footer">
                {/* Button to continue after reviewing wrap-up feedback */}
                <Button
                    color='primary'
                    size="lg"
                    className="px-5"
                    onClick={handleContinueCase}
                    disabled={isLoading}
                >
                    All set, let’s continue!
                </Button>
            </div>
        </div>
    );
}

export default ChatWrapUpContent;