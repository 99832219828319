import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

//classnames
import classnames from "classnames";

//apiCaller
import { apiCaller } from "@utils";

//Config
import * as Config from "@constants/Config";

//redux
import { useDispatch } from "react-redux";

//reactstrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import {
  updateExamFindingForElement,
} from "@actions";

//components common
import { AudioFile, VideoLink, ImageFile, DropDownMedia, VideoFile } from "@components-common/media";

function ModalPhysicalExam(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, handleClose, data, dataExam } = props && props;
  const editor = useRef(null);
  const TimepointId = data?.TimepointId;
  const PointId = dataExam?.PointId;
  const Time = data?.Time;
  
  //state
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [stateData, setStateData] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [content, setContent] = useState(Time === 0 ? dataExam?.InitialValue : dataExam?.ChangeValue);
  const [isLoading, setIsloading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [dataMediaLoad, setDataMediaLoad] = useState("");

  //Set data
  useEffect(() => {
    //check actions is Edit setState Data
    setStateData((dataExam && dataExam) || {});
    setContent(Time === 0 ? dataExam?.InitialValue : dataExam?.ChangeValue);

    //Save data current
    setDataMediaLoad({
      MediaUrl: (dataExam && dataExam.MediaUrl) || '',
      MediaType: (dataExam && dataExam.MediaType) || ''
    });
  }, [dataExam, Time]);

  //Save
  const handleSubmit = () => {
    const currentType = stateData && stateData.MediaType;
    let formData = new FormData();
    formData.append("Action", "Upload");
    setIsloading(true);

    //Check Upload
    if (isUpload) {
      if (currentType === "ImageFile") {
        formData.append("File", imageData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else if (currentType === "AudioFile") {
        formData.append("File", audioData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else {
        sendData();
      }
    } else {
      //data cu
      setStateData((prevState) => ({
        ...prevState,
        MediaType: dataMediaLoad?.MediaType,
        MediaUrl: dataMediaLoad?.MediaUrl,
      }));
      sendData();
    }
  };

  //Call Api Edit
  const sendData = (newUrl) => {
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: TimepointId,
      PointId: PointId,
      ExamFindings: [
        {
          AttributeId: stateData?.AttributeId,
          Name: stateData?.Name,
          InitialValue: content,
          MediaType: stateData?.MediaType,
          MediaUrl: ""
        }
      ]
    };

    //Media
    if (stateData.MediaType !== "VideoLink" && isUpload) {
      params.ExamFindings[0].MediaUrl = newUrl;
    } else {
      params.ExamFindings[0].MediaUrl = stateData.MediaUrl;
    }

    dispatch(updateExamFindingForElement(id, params));

    //close Modal and Loading
    handleClose();
    setIsloading(false);
    setIsUpload(false);

    //resetData
    resetDataModalAdd();
  };

  const resetDataModalAdd = () => {
    //setState
    setStateData((prevState) => ({
      ...prevState,
      Name: "",
      InitialValue: "",
      MediaType: "",
      MediaUrl: "",
    }));
  };

  //Upload
  const requestApiUpload = (id, params) => {
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const newUrl = res && res.data && res.data.NewUrl;
      if (res && res.status === 200) {
        setTimeout(() => {
          //Send params
          sendData(newUrl);
        }, 3000);
      }
    });
  };

  //tabs
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // handleInput
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  const handleType = (e) => {
    const value = e.target.value;
    //ResetData
    setAudioData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    //Nếu type là data load ban đầu
    if (dataMediaLoad?.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad?.MediaUrl,
      }));
    }
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    //Set File FormData
    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  //Upload Audio
  const onFileAudioUpload = (event) => {
    const fileAudio = event.target.files[0];
    const urlPreview = URL.createObjectURL(fileAudio);
    setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
    setAudioData(fileAudio);
    setIsUpload(true);
  };

  const isType = stateData && stateData.MediaType;
  const urlValue = stateData && stateData.MediaUrl;
  return (
    <Modal
      style={{ maxWidth: "650px" }}
      isOpen={isOpen}
      toggle={handleClose}
      backdrop={`static`}
    >
      <ModalHeader toggle={handleClose}>
        <span>Physical Exam - {(stateData && stateData.Name) || ""}</span>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="Name">Name</Label>
          <Input
            type="text"
            value={(stateData && stateData.Name) || ""}
            onChange={handleInput}
            name="Name"
            disabled={true}
            autoComplete="off"
          />
        </FormGroup>
        <Nav className="tabBorder" tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTabs("1");
              }}
            >
              Content
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTabs("2");
              }}
            >
              Media
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <JoditEditor
              ref={editor}
              value={Time === 0 ? stateData?.InitialValue : stateData?.ChangeValue}
              config={Config.CONFIG_JODIT}
              placeholder= "Enter the instructions for the learner here..."
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)}
            />
          </TabPane>
          <TabPane tabId="2">
            <div className="mediaContainer">
              <DropDownMedia onChange={handleType} value={isType} />
              {isType === "VideoLink" && (
                <VideoLink value={urlValue} onChange={handleInput} />
              )}
              {isType === "ImageFile" && (
                <ImageFile onChange={handleUploadImage} value={urlValue} />
              )}
              {isType === "AudioFile" && (
                <AudioFile onChange={onFileAudioUpload} value={urlValue} />
              )}
              {isType === "VideoFile" && (
                <VideoFile onChange={onFileAudioUpload} value={urlValue} />
              )}
              <hr />
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose} disabled={isLoading}>
          Cancel
        </Button>{" "}
        <Button
          className="--btn-primary d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalPhysicalExam;
