import React from 'react';

function AccordionHeader(props) {
    const { 
        eventHandler,
        index,
        active,
        indexCount,
        value,
        toggleCategory
    } = props && props;
    return (
        <h3 className="accordionExam__heading">
            <span
                onClick={(e) => eventHandler(e, index)}
                className={`innerHead ${active === index ? "active" : "inactive"
                    }`}
                aria-expanded={active === index ? "true" : "false"}
                aria-controls={"sect-" + indexCount(index)}
                aria-disabled={active === index ? "true" : "false"}
                tabIndex={indexCount(index)}
            >
                <div className="d-flex align-items-center">
                    <span className="accordionExam__icon icon-list"></span>
                    {value.Name}
                    <div className="ml-auto">
                        <i className="fa fa-pencil-square-o mr-4 --text-primary" aria-hidden="true" onClick={() => toggleCategory("editCategory", value)}></i>
                        <span className={active === index ? "plus" : "minus"}></span>
                    </div>
                </div>
            </span>
        </h3>
    );
};

export default AccordionHeader;