import React from "react";

//components
import FileUploadComponent from "../ResultTextQuestion/FileUploadComponent";
import FileUrl from "@file/Template.xlsx"
import { TitleNoteCommon } from "../../home/generate";
import { useForm, Controller } from "react-hook-form";
import { Input } from "reactstrap";

//partents
import { FormModal } from '@ui-partents/Modal';

const ModalUploadFileAnalysis = ({ isOpen, toggleModal, title, handleGetListAnalyses, dataAnalyze }) => {

	const handleDownload = () => {
    // Tạo một thẻ <a> ẩn để tải file
    const link = document.createElement('a');
    link.href = FileUrl;
    link.download = 'template.xlsx'; // Tên file khi tải về
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

	const {
    control,
		watch,
  } = useForm()

	return (
		<FormModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			title={title}
			maxWidth="830px"
			hiddenIcon={true}
			hiddenButtonCancel={true}
			classCustom="modal-analyze"
		>
			<form className="form-create-analysis">
				<div className="text-desc">
					<p className="text-desc__content">Upload an Excel file containing the learner’s answers in the drop zone below. Each learner’s answer should be on a separate row under the column labeled <span>Answer Text.</span></p>
					<p className="text-desc__excel" onClick={handleDownload}>Click here to download the Excel template</p>
				</div>
				<div className='input-form mb-4'>
          <TitleNoteCommon title="Group Name"/>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                type='text'
                name='name'
                placeholder="A descriptive name for this set of answers - for example: First-year students - Sprint semester 2024"
                {...field}
              />
            )}
          />
        </div>
				<div className='input-form'>
					<TitleNoteCommon title="Upload File"/>
					<FileUploadComponent watch={watch} toggleModal={toggleModal} handleGetListAnalyses={handleGetListAnalyses} dataAnalyze={dataAnalyze} />
				</div>
			</form>
		</FormModal>
	);
};

export default ModalUploadFileAnalysis;