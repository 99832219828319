import React, { useRef, useState, useEffect } from "react";
import { useForm } from "react-hook-form";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//JoditEditor
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

//actions
import { updateQuestionConcept } from "@actions";

function ModalEditDianostic(props) {
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, data, hanldeDelete } = props && props;
  const comments = data?.comments;
  const cui = data?.cui;
  const questionId = data?.question_id;
  const questionConceptId = data?.question_concept_id;
  const score = data?.score;
  const editor = useRef(null);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  //state
  const [content, setContent] = useState((comments && comments) || '');

  useEffect(() => {
    setContent((comments && comments) || '');
    setValue('Score', (score && score) || 0, { shouldValidate: false });
  }, [comments, score, setValue]);

  const onSubmit = (val) => {
    const params = {
      "Action": "UpdateQuestionConcept",
      "QuestionId": questionId,
      "QuestionConceptId": questionConceptId,
      "Comments": content,
      "Score": val.Score
    }
    dispatch(updateQuestionConcept(params));

    //close and clear
    toggle();
    //Reset data
    setValue('Score', 0, { shouldValidate: false });
  }

  return (
    <Modal
      style={{ maxWidth: "800px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        Edit Concept
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label className="font-weight-400" for="vomiting">
                  Vomiting
                </Label>
                <p className="text-muted">CID {cui}</p>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="Comments">Comments:</Label>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={Config.CONFIG_JODIT}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="Score">Score <span className="m--font-danger">*</span></Label>
                <Input
                  type="number"
                  name="Score"
                  {...register("Score", { required: true })}
                  invalid={errors.Score?.type === "required"}
                  defaultValue={watch("Score")}
                  onChange={(e) => {
                    setValue("Score", e.target.value, { shouldValidate: true });
                  }}
                  placeholder=""
                />
                <FormFeedback
                  invalid={String(errors.Score?.type === "required")}
                >
                  This is required.
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-danger mr-auto" onClick={hanldeDelete}>
          Remove Concept
        </Button>
        <Button color="primary" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
        <Button onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalEditDianostic;