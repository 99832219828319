import React from "react";

const StudiesOrders = () => {
  return (
    <div>
      <h2>StudiesOrders Component</h2>
      {/* Thêm nội dung của StudiesOrders component ở đây */}
    </div>
  );
};

export default StudiesOrders;