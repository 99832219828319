import React from "react";

//reactstrap
import { Row, Col, Button } from "reactstrap";

//components
import PieChart from "./PieChart";
import DisagreementThemes from "./DisagreementThemes";

function AnalyzeReviewsRow(props) {
    const { analyzeReviews, toggle } = props && props;
    const table_data = analyzeReviews?.table_data;
    return (
        <Row className="mt-4">
            <Col md={6}>
                <PieChart {...props} />
                {table_data?.length > 0 && (
                    <Button
                        className="--btn-primary d-block m-auto"
                        onClick={() => toggle("details")}
                    >
                        View Details
                    </Button>
                )}
            </Col>
            <Col md={6}>
                <DisagreementThemes {...props} />
            </Col>
        </Row>
    );
}

export default AnalyzeReviewsRow;