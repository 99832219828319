import React, { useContext, useState } from 'react'
import { InventoryWrap } from '@components/teachV2/inventory';
import { useLocation, useHistory } from 'react-router-dom';
import UserContext from '@context/UserContext';
import Bank from '@images/teachV2/question-bank.svg';
import Scenario from '@images/teachV2/case-scenario.svg';
import Interactive from '@images/teachV2/module-lib.svg';
import Slides from '@images/teachV2/teaching-slide.svg';
import Engage from '@images/teachV2/engage-module.svg';

const InventoryPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const context = useContext(UserContext);
	const [activeTab, setActiveTab] = useState(pathname === "/teach/inventory/slides" ? 4 : pathname === "/teach/inventory/script" ? 2 : pathname === "/teach/inventory/module" ? 3 : pathname === "/teach/inventory/engage" ? 5 : 1);

	const handleTabChange = (tabId) => {
    setActiveTab(tabId);
    if (tabId === 1) {
      history.push("/teach/inventory/mcq")
    } else if (tabId === 2) {
      history.push("/teach/inventory/script")
    } else if (tabId === 3) {
      history.push("/teach/inventory/module")
    } else if (tabId === 4) {
      history.push("/teach/inventory/slides")
    } else {
      history.push("/teach/inventory/engage")
    }
  };

  const tabsOption = context.privilege === "alpha" ? [
    { id: 1, key: "bank", label: "Question Bank", icon: Bank },
    { id: 2, key: "scenario", label: "Case Scenario Library", icon: Scenario },
    { id: 3, key: "interactive", label: "Interactive Module Library", icon: Interactive },
    { id: 4, key: "presentation", label: "Teaching Slides", icon: Slides },
    { id: 5, key: "engage", label: "Engagement Modules", icon: Engage },
  ] : [
    { id: 1, key: "bank", label: "Question Bank", icon: Bank },
    { id: 2, key: "scenario", label: "Case Scenario Library", icon: Scenario },
    { id: 3, key: "interactive", label: "Interactive Module Library", icon: Interactive },
    { id: 4, key: "presentation", label: "Teaching Slides", icon: Slides },
  ];;

  return (
    <div className="wrapper -inventoryPage">
      <InventoryWrap tabs={tabsOption} handleTabChange={handleTabChange} activeTab={activeTab}/>
    </div>
  )
}

export default InventoryPage
