import React, { useState, useEffect } from "react";

//boostrap
import { Row, Col } from "reactstrap";

//components
import { TimeZone, GroupSwitch } from '../profile';

//redux
import { useDispatch } from "react-redux";

//actions
import { updateAccountSettings } from "@actions";

function AccountInformation(props) {
    let dispatch = useDispatch();

    //props
    const { dataAccount, dataSettings } = props && props;
    const notificationSettings = dataSettings?.notification_settings;
    const notifyNewAssignedModule = notificationSettings?.notify_new_assigned_module === "Email" ? true : false;
    const notifyRemoveAssignedModule = notificationSettings?.notify_remove_assigned_module === "Email" ? true : false;
    const notifyNewGradedModule = notificationSettings?.notify_new_graded_module === "Email" ? true : false;
    const notifyNewManagedModule = notificationSettings?.notify_new_managed_module === "Email" ? true : false;

    //state
    const [stateData, setData] = useState({
        notify_new_assigned_module: notifyNewAssignedModule && notifyNewAssignedModule,
        notify_remove_assigned_module: notifyRemoveAssignedModule && notifyRemoveAssignedModule,
        notify_new_graded_module: notifyNewGradedModule && notifyNewGradedModule,
        notify_new_managed_module: notifyNewManagedModule && notifyNewManagedModule
    });

    //load
    useEffect(() => {
        setData((prevState) => ({ ...prevState,
            notify_new_assigned_module: notifyNewAssignedModule && notifyNewAssignedModule,
            notify_remove_assigned_module: notifyRemoveAssignedModule && notifyRemoveAssignedModule,
            notify_new_graded_module: notifyNewGradedModule && notifyNewGradedModule,
            notify_new_managed_module: notifyNewManagedModule && notifyNewManagedModule
        }));
    }, [notifyNewAssignedModule, notifyRemoveAssignedModule, notifyNewGradedModule, notifyNewManagedModule])

    //set Switch
    const handleChangeSwitchNotify = (e) => {
        const { name, checked } = e.target;
        const isChecked = checked === true ? 'Email' : 'None';
        setData((prevState) => ({ ...prevState, [name]: checked }));

        //call Api
        const params = {
            "Action": "Update",
            [name]: isChecked
        }
        dispatch(updateAccountSettings(params));
    }

    const basicAcc = [
        { Name: '... a new module is assigned to me', checked: stateData?.notify_new_assigned_module, param: "notify_new_assigned_module" },
        { Name: '... a module previously assigned to me is removed', checked: stateData?.notify_remove_assigned_module, param: "notify_remove_assigned_module" },
        { Name: '... a score / feedback is generated for a module that I completed', checked: stateData?.notify_new_graded_module, param: "notify_new_graded_module" },
        { Name: '... a module is shared with me to manage', checked: stateData?.notify_new_managed_module, param: "notify_new_managed_module" }
    ]
    return (
        <React.Fragment>
            <div className="inforGroup">
                <h5 className="heading-5 font-weight-500 --text-primary">
                    BASIC ACCOUNT INFORMATION
                </h5>
                <Row className="mb-3">
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                <h6 className="mb-0 font-weight-500">First Name:</h6>
                            </Col>
                            <Col sm={8}>{dataAccount?.firstname || ""}</Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                <h6 className="mb-0 font-weight-500">Last Name:</h6>
                            </Col>
                            <Col sm={8}>{dataAccount?.lastname || ""}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={6}>
                        <Row>
                            <Col sm={4}>
                                <h6 className="mb-0 font-weight-500">Role:</h6>
                            </Col>
                            <Col sm={8}>Clinical Fellow</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col sm={6}>
                        <TimeZone {...props} />
                    </Col>
                </Row>
            </div>
            <GroupSwitch subTitle={`Notify me via email when ...`} handleChangeSwitch={handleChangeSwitchNotify} data={basicAcc} />
        </React.Fragment>
    );
}

export default AccountInformation;