import React, { useState } from "react";

//boostrap
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function ContactForm(props) {
  const [isOther, setOther] = useState(false);
  const toggleOther = (e) => {
    const { checked } = e.target;
    setOther(checked);
  };

  return (
    <section className="formPlan mt-5 mx-auto">
      <p className="text-center text-3 mb-4 --text-primary">
        We’d like to ask you some questions so that we may better assist you and your institution.
      </p>
      <Form className="mx-auto" style={{ maxWidth: "700px" }}>
        <FormGroup>
          <Label className="font-weight-500 text-3" for="institution">
            What is the approximate size of your institution/program?
          </Label>
          <InputGroup style={{ maxWidth: "300px" }}>
            <Input name="institution" placeholder="100" min="0" type="number" />
            <InputGroupText className="border-0 bg-white text-3">
              Instructors & learners
            </InputGroupText>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-500 text-3" for="institution">
            What is/are your specific role(s) within your institution/program?
          </Label>
          <Input name="role" className="w-75" placeholder="" type="text" />
          <p className="text-muted text-1 mt-2">
            (For example: program director, clinical instructor, etc.)
          </p>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-500 d-block text-3" for="institution">
            How can Med2Lab best assist you? <br />
            <span className="text-muted text-1 font-weight-400">
              (select all that apply)
            </span>
          </Label>
          <FormGroup check inline className="mb-2">
            <Input type="checkbox" />
            <Label className="text-3" check>
              We need a technical solution for scaling UME/GME at my institution
            </Label>
          </FormGroup>
          <FormGroup check inline className="mb-2">
            <Input type="checkbox" />
            <Label className="text-3" check>
              We want to better understand a cohort’s strengths and weaknesses
            </Label>
          </FormGroup>
          <FormGroup check inline className="align-items-start mb-2">
            <Input className="mt-1" type="checkbox" />
            <Label className="text-3" check>
              We want to automate learner assessment
            </Label>
          </FormGroup>
          <FormGroup check inline className="align-items-start mb-2">
            <Input className="mt-1" type="checkbox" />
            <Label className="text-3" check>
              We want a longitudinal record of my learners’ clinical reasoning over time
            </Label>
          </FormGroup>
          <FormGroup check inline className="align-items-start mb-2">
            <Input className="mt-1" type="checkbox" />
            <Label className="text-3" check>
              We want to expand my learners’ options for remote training/learning
            </Label>
          </FormGroup>
          <FormGroup check>
            <div className="mb-3">
              <Input className="mt-1" onClick={toggleOther} type="checkbox" />
              <Label className="text-3" check>
                Other{isOther && ":"}
              </Label>
            </div>
            {isOther &&
              <TextareaAutosize
                name="other"
                placeholder="Enter your content..."
                className="w-100 form-control"
                maxRows="10"
                minRows={5}
              />
            }
          </FormGroup>
        </FormGroup>
        <hr className="my-4" />
        <FormGroup className="mt-4 text-center">
          <Button className="--btn-primary rounded-pill px-5 py-2 mb-2">
            Let's Get Started
          </Button>
          <p className="--text-primary text-3">
            Now you may begin your 30-day free pilot to try out the Med2Lab platform.<br/>
            Please stay tuned--we will be reaching out to you soon with more information!
          </p>
        </FormGroup>
      </Form>
    </section>
  );
}

export default ContactForm;
