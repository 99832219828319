
//vinuni
import { VinUniLoginPage } from "@pages/med2lab/VinUniPage";

const vinuniRoutes = [
    // vinuni Login
    {
        path: "/vinuni/login",
        exact: true,
        classPage: '',
        component: VinUniLoginPage,
        isLayout: false,
    },
];

export { vinuniRoutes };