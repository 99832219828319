import React, { useState, useEffect } from "react";

// components
import { TemplateOptions, TableTemplate } from "../Administrator/CustomizableElement";

// apiCaller
import { apiCaller } from "@utils";

// useDispatch
import { useDispatch, useSelector } from "react-redux";

//actions
import { actFetchAllInterfaceTemplates, actFetchStandardInterfaceElements } from "@actions";

function CustomizableElementTemplates(props) {
  const [apiData, setApiData] = useState(null);
  const [clearFilter, setClearFilter] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null); // Khởi tạo bằng null
  const [selectedGroupName, setSelectedGroupName] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedValue !== null) {
          const response = await apiCaller(`/api/teach/interface_template/${selectedValue}/`, 'PUT', { Action: "LoadTemplate" });

          if (response?.data) {
            setApiData(response?.data);
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [selectedValue]);

  //Load Group Default
  useEffect(() => {
    const params = { "Action": "LoadStandardElements" }
    dispatch(actFetchStandardInterfaceElements(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Handle Select Template
  const handleSelectedTemplate = (template) => {
    setSelectedValue(template.value);
  };

  // Reset Data
  const handleResetTable = () => {
    setApiData(null);
    setSelectedValue(null);
    dispatch(actFetchAllInterfaceTemplates());
  }

  //Filter Group
  const onGroupChange = (groupName) => {
    setSelectedGroupName(groupName);
  }

  const listStandardInterfaceElements = useSelector((state) => state?.listStandardInterfaceElements?.data || []);
  return (
    <div className="CustomizableElementCt">
      <TemplateOptions
        {...props}
        onTemplateChange={handleSelectedTemplate}
        handleResetTable={handleResetTable}
        clearFilter={clearFilter}
        templateData={apiData}
        onGroupChange={onGroupChange}
      />
      {selectedValue && (
        <TableTemplate
          {...props}
          templateData={apiData}
          handleResetWhenDel={() => {
            handleResetTable();
            setClearFilter(true);
          }}
          listStandardInterfaceElements={listStandardInterfaceElements}
          selectedGroupName={selectedGroupName}
        />
      )}
    </div>
  );
}

export default CustomizableElementTemplates;