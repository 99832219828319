import React from 'react';

//reactstrap
import { Table, Collapse } from "reactstrap";

//components
import { TableCell } from '../Chat';

function ChatTopicInstances(props) {
    const { data, isOpen, handleEdit, item } = props && props;
    return (
        <tr>
            <td className="groupCollapse p-0" colSpan={12}>
                <Collapse isOpen={isOpen} >

                    <Table responsive className="mb-0 tableSub">
                        <tbody>
                            {data?.map((itemTopic, index) => {
                                const ChatResponses = itemTopic?.ChatResponses;
                                return (
                                    <tr key={index}>
                                        <td width="20%">
                                            <span className="pl-5">{itemTopic?.TopicName}</span>
                                        </td>
                                        {ChatResponses?.map((value, indexValue) => {
                                            const obJectEdit = {...item, ...itemTopic, ...value}
                                            return (
                                                <TableCell key={indexValue} value={value?.ChatResponse} handleEdit={() => handleEdit('edit', obJectEdit)} />
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Collapse>
            </td>
        </tr>
    );
};

export default ChatTopicInstances;