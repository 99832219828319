import React, { useEffect, useState } from "react";

//format time
import moment from "moment";

//boostrap
import { Row, Col } from "reactstrap";

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);


function LatencyChart(props) {
    const { stateData } = props && props;
    const latency_data = stateData?.latency_data;

    //props
    const [chartList, setChart] = useState([]);

    useEffect(() => {
        //convert chart with config
        const convertDataChart = () => {
            let result = {
                x: [],
                y: [],
                type: 'scatter',
                mode: 'lines+markers',
                marker: {
                    color: []
                }
            };

            stateData?.latency_data?.forEach(item => {
                //Chuyen doi dinh dang ngay-thang-nam
                const formattedDate = moment(item.time, 'DD-MM-YYYY').format('YYYY-MM-DD');
                result.x.push(formattedDate);
                result.y.push(parseFloat(item.latency_per_word?.toFixed(2) || '0.00'));
                result.marker.color.push(item.latency_per_word_color);
            });
            setChart([result]);
        };

        //Set Data Chart
        convertDataChart();
    }, [latency_data, stateData]);

    const layout = {
        font: { size: 13 },
        autosize: true,
        height: 400,
        margin: {
            l: 50,
            r: 20,
            b: 50,
            t: 50,
            pad: 10
        },
        xaxis: {
            type: 'date', // Định dạng trục x là kiểu ngày tháng
            tickformat: '%d %b <br>%Y'
        }
    };

    const configResize = {
        useResizeHandler: true,
        style: { width: "100%", height: "100%" },
    }

    return (
        <Row>
            <Col md={12}>
                <div style={{ padding: '15px', border: "1px solid #e0e0e0", borderRadius: '5px' }}>
                    <h3 className="mb-2">Latency Per Request:</h3>
                    <PlotlyComponent
                        divId="scatterChart"
                        data={chartList || []}
                        layout={layout}
                        style={{ width: '100%', height: '100%' }}
                        config={configResize}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default LatencyChart;