import React from 'react';

// Icon
import { Icon } from '@components/common';
import Trash from '@images/teachV2/trash.svg';

const DataFileImported = ({ listFileAccept, dataFileImported, handleTryAgain, setDataFileImported }) => {
    return (
        <div className="wrap-option__file">
            <div className="wrap-option__file-wrap d-flex align-items-center">
                <div className="wrap-option__file-info">
                    <Icon src={File} />
                    {dataFileImported && (
                        <div className='progress-loading'>
                            <h4>{dataFileImported?.name}</h4>
                            {!listFileAccept?.includes(dataFileImported.type) || dataFileImported.size > 10000000 ? (
                                <>
                                    {dataFileImported.size > 10000000 ? (
                                        <p>File is too large (Max: 10 MB). Please try again or upload it to your Reference Library and select ‘Choose from Reference Library’.</p>
                                    ) : (
                                        <>
                                            <p>Invalid format. Please try again.</p>
                                            <p className="italic">Supported formats: .ppt, .pptx, .pdf, .doc, .docx, .txt.</p>
                                        </>
                                    )}
                                    <span className='btn-try' onClick={handleTryAgain}>Try again</span>
                                </>
                            ) : (
                                <p>{(dataFileImported?.size / (1024 * 1024)).toFixed(2)}MB</p>
                            )}
                        </div>
                    )}
                </div>
                {(!listFileAccept?.includes(dataFileImported?.type) || dataFileImported?.size > 10000000) ? (
                    <div className='wrap-option__file-trash'>
                        <Icon src={Error} />
                    </div>
                ) : (
                    <div className='wrap-option__file-trash' onClick={() => {
                        setDataFileImported(null);
                    }}>
                        <Icon src={Trash} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataFileImported;