import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function DropdownActions(props) {
    //props
    const { dropdownOpen, stateChecked, toggleDropdown } = props && props;
    return (
        <React.Fragment>
            {stateChecked?.length > 0 &&
                <Dropdown
                    isOpen={dropdownOpen}
                    toggle={() => toggleDropdown('deleteRow')}
                    className="ml-2"
                >
                    <DropdownToggle className="btn-danger" caret color="danger">
                        Actions
                    </DropdownToggle>
                    <DropdownMenu container="body">
                        <DropdownItem>
                            Delete
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            }
        </React.Fragment>
    );
}

export default DropdownActions;