import React, { useEffect, useState } from "react";
import {
  Row,
  Col
} from "reactstrap";

import { useParams } from "react-router-dom";

//lodash
import _ from "lodash";

//alert
import { toast } from 'react-toastify';

//components
import { ModalPNOrder, ModalDeleteIntervention, ModalScore } from "../Modal";

//call Action
import {
  updateAvailabilityForPN,
  handleChangeAvailableForPN
} from "@actions";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { pnorderConstants } from "@constants";

//components
import { SwithRadio, BadgeScore, DropdownGroupAvailability, AvailableCheckBox } from '../pnorder';

function Setting(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { data, timePointId } = props && props;

  let indexPlus;
  //props
  let PNCategoryId = data?.PNCategoryId;
  const pnOrdersData = data?.PNOrders;
  const newPnOrderArr = Object.entries(pnOrdersData || {});
  const settingsValue = data?.Settings;

  //state
  const [loadingCheckBox, setLoadingCheckBox] = useState(false);
  const [modalIntervention, setModalIntervention] = useState(false);
  const [active, setActive] = useState(0);
  const [actions, setActions] = useState("");
  const [panelCurrent, setPanelCurrent] = useState("");
  const [paramsUpdate, setParamsUpdate] = useState({});
  const [categoryNameCurrent, setCategoryNameCurrent] = useState("");
  const [activeItem, setActiveItem] = useState(0);
  const [interventionId, setInterventionId] = useState(0);
  const [listPNOrders, setListPNOrders] = useState(newPnOrderArr || []);
  const [dataInBatch, setInBatch] = useState([]);

  ///Add Score
  const [modalScore, setModalCore] = useState(false);
  const [actionScore, setActionScore] = useState("");
  const [itemScore, setItemScore] = useState("");
  const [itemEdit, setItemEdit] = useState({});
  const [isLoadingInBatch, setLoadingInBatch] = useState(false);

  //Convert data in batch - Availability
  useEffect(() => {
    //convert Data
    const convertDataInterventions = (list) => {
      let newArrayInbatch = [];
      const objectEntries = Object.entries((list && list) || {})
      _.forEach(objectEntries, function (value) {
        if (value && value[1]?.length) {
          _.forEach(value[1], function (item) {
            newArrayInbatch.push({ ...item, CategoryId: PNCategoryId });
          });
        }
      });
      return newArrayInbatch;
    }
    setInBatch(convertDataInterventions(pnOrdersData) || []);
  }, [pnOrdersData, PNCategoryId])

  //Load Data
  useEffect(() => {
    setListPNOrders(Object.entries((pnOrdersData && pnOrdersData) || {}));
    if (pnOrdersData) {
      setLoadingCheckBox(false);
    }
  }, [pnOrdersData]);

  //delete
  const [modalDel, setModalDel] = useState(false);

  //dropdown
  const [dropdownOpen, setOpenDropdown] = useState({
    'all': false
  });
  const toggleDropdown = (index) => {
    setOpenDropdown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const handleToogleRow = (id) => {
    setActiveItem(id);
  };

  const defineChangeMode = (type, value, unit, limit) => {
    if (type === "increase") {
      return `Increase by: <span>${value} ${unit}</span>${" "}<br/> (but not larger than ${limit} ${unit})`;
    } else if (type === "“decrease”") {
      return `Decrease by: <span>${value} ${unit}</span>${" "}<br/> (but not smaller than ${limit} ${unit})`;
    } else {
      return `Set at: <span>${value} ${unit}</span>${" "}`;
    }
  };

  const toggleIntervention = (type, data, panel) => {
    //get data from entries
    setPanelCurrent(panel);
    setActions(type);
    setParamsUpdate(data);
    setModalIntervention(!modalIntervention);
  };

  const toggleDel = (NameCat, InterventionId) => {
    setInterventionId(InterventionId);
    setCategoryNameCurrent(NameCat);
    setModalDel(!modalDel);
  };

  const convertInterventionAvailabilityData = (status, list) => {
    const newAvailabilityData = list?.map((item) => {
      const isChecked = status === "Available" ? true : false;
      return {
        InterventionId: item.Id,
        CategoryId: PNCategoryId,
        Available: isChecked,
      };
    });
    return newAvailabilityData;
  }

  //Update Available One Only
  const handleChangeAvailable = (e, InterventionId, namePanel) => {
    const isChecked = e.target.checked;
    const paramsUpdate = {
      Action: "Update",
      Target: "Intervention",
      TimePointId: timePointId,
      InterventionId: InterventionId,
      CategoryId: PNCategoryId,
      Available: isChecked,
    };
    dispatch(handleChangeAvailableForPN(id, paramsUpdate, namePanel));
  };

  //Update Available for Group
  const handleAllAvailable = (status, data) => {
    setLoadingCheckBox(true);
    const newAvailabilityData = convertInterventionAvailabilityData(status, data);
    const params = {
      Action: "Update",
      Target: "InterventionAvailability",
      TimePointId: timePointId,
      InterventionType: "PN",
      InterventionAvailabilityData: newAvailabilityData,
    };
    dispatch(updateAvailabilityForPN(id, params));
  };

  //Update Available In Batch
  const handleAllAvailableInBatch = (status) => {
    const isChecked = status === "Available" ? true : false;
    const newAvailabilityData = convertInterventionAvailabilityData(status, dataInBatch);
    const params = {
      Action: "Update",
      Target: "InterventionAvailability",
      TimePointId: timePointId,
      InterventionType: "PN",
      InterventionAvailabilityData: newAvailabilityData,
    };

    setLoadingInBatch(true);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: pnorderConstants.UPDATE_PN_AVAILABLE_IN_BATCH_SUCCESS,
          payload: data
        });
        toast.success(`Turn availability to ${isChecked ? 'ON' : 'OFF'} ${dataInBatch?.length} items`, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoadingInBatch(false);
      } else {
        console.log('error');
        setLoadingInBatch(false);
      }
    });
  }

  const toggleScore = (type, scoreItem, interventionItem, namePanel) => {
    setActionScore(type);
    setModalCore(!modalScore);
    setItemEdit(interventionItem);
    setItemScore(scoreItem);
    setCategoryNameCurrent(namePanel);
  };

  return (
    <div className={`medicationsTab ${isLoadingInBatch ? 'overlayLoader' : ""}`}>
      <div className="text-right mb-4">
        <DropdownGroupAvailability
          toggleDropdown={() => toggleDropdown('all')}
          handleAllAvailable={handleAllAvailableInBatch}
          dropdownOpen={dropdownOpen[`all`]}
          isLoading={isLoadingInBatch}
        />
      </div>
      <div className="accordionExam">
        {listPNOrders?.map((value, index) => {
          const namePanel = value && value[0];
          const listData = value && value[1];
          return (
            <div className="accordionExam__item" key={index}>
              <h3 className="accordionExam__heading">
                <span
                  onClick={(e) => eventHandler(e, index)}
                  className={`innerHead ${active === index ? "active" : "inactive"
                    }`}
                  aria-expanded={active === index ? "true" : "false"}
                  aria-controls={"sect-" + indexCount(index)}
                  aria-disabled={active === index ? "true" : "false"}
                  tabIndex={indexCount(index)}
                >
                  <div className="d-flex align-items-center">
                    <span className="accordionExam__icon icon-list"></span>
                    {namePanel}
                  </div>
                  <span
                    className={active === index ? "plus" : "minus"}
                  ></span>
                </span>
              </h3>
              <div
                id={"sect-" + indexCount(index)}
                className={
                  active === index
                    ? "accordionExam__content accordionExam__open"
                    : "accordionExam__content accordionExam__close"
                }
              >
                <Row className="align-items-center mb-3">
                  {namePanel === 'Total Fluid Goal' &&
                    <Col lg={4}>
                      <SwithRadio value={settingsValue} />
                    </Col>
                  }
                  <Col lg={namePanel === 'Total Fluid Goal' ? 8 : 12}>
                    <div className="d-flex justify-content-lg-end justify-content-md-start mt-3 mt-lg-0">
                      <button
                        className="btn btn-brand"
                        onClick={() => toggleIntervention("add", {}, namePanel)}
                      >
                        <i className="fa fa-plus"></i> Add {namePanel}
                      </button>
                      {listData?.length > 0 && (
                        <DropdownGroupAvailability
                          data={(listData && listData) || []}
                          toggleDropdown={toggleDropdown}
                          handleAllAvailable={handleAllAvailable}
                          dropdownOpen={dropdownOpen[index]}
                          index={index}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <p className="mb-3 --text-primary">(*) score applies only to this time point</p>
                <table className="table tableMedications">
                  <thead>
                    <tr className="m--font-brand">
                      <th className="text-left border-bottom-0">
                        Intervention
                      </th>
                      <th className="text-center border-bottom-0">
                        Available
                      </th>
                      <th
                        className="text-center border-bottom-0"
                        style={{ width: "25%" }}
                      >
                        Score
                      </th>
                      <th className="text-center border-bottom-0">
                        Settings
                      </th>
                    </tr>
                  </thead>
                  {/* /head */}
                  <tbody>
                    {listData.map((item, j) => {
                      return (
                        <React.Fragment key={j}>
                          <tr>
                            <th scope="row">
                              {item.Name}{" "}
                              <span className="sttId">(#{item.Id})</span>
                            </th>
                            <td className="text-center">
                              {loadingCheckBox &&
                                <div className="spinner-border spinner-border-sm m--font-info" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              }
                              {!loadingCheckBox &&
                                <AvailableCheckBox
                                  {...props}
                                  handleChangeAvailable={handleChangeAvailable}
                                  item={item}
                                  namePanel={namePanel}
                                />
                              }
                            </td>
                            <td className="text-center">
                              {/* Add Score */}
                              <BadgeScore
                                {...props}
                                toggleScore={toggleScore}
                                data={item?.DomainScores || []}
                                namePanel={namePanel}
                                item={item}
                                categoryType={`pnOrders`}
                              />
                            </td>
                            <td className="text-center">
                              <div className="lsActions">
                                <div className="lsActions__item">
                                  <button
                                    className="btn btn-sm btn-outline-brand"
                                    onClick={() => handleToogleRow(item.Id)}
                                  >
                                    <span className="med-icon icon-settings"></span>{" "}
                                  </button>
                                </div>
                                <div className="lsActions__item">
                                  <button
                                    className="btn btn-sm btn-outline-brand"
                                    onClick={() =>
                                      toggleIntervention(
                                        "edit",
                                        item,
                                        namePanel
                                      )
                                    }
                                  >
                                    <span className="med-icon icon-pencil"></span>{" "}
                                  </button>
                                </div>
                                <div className="lsActions__item">
                                  <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() =>
                                      toggleDel(namePanel, item.Id)
                                    }
                                  >
                                    <span className="med-icon icon-cancel"></span>{" "}
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                          {activeItem === item.Id && (
                            <tr className="toogleRow">
                              <td colSpan={4} className="p-3">
                                <table className="table table-sm tableSub">
                                  <thead className="--bg-primary">
                                    <tr>
                                      <th style={{ width: "15%" }}>
                                        Attribute
                                      </th>
                                      <th>Effect</th>
                                      <th style={{ width: "35%" }}>Timing</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item &&
                                      item.Effects.map((data, index) => (
                                        <tr key={index}>
                                          <td>{data.AttributeName}</td>
                                          <td>
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: defineChangeMode(
                                                  data.ChangeMode,
                                                  data.ChangeValue,
                                                  data.AttributeUnit,
                                                  data.ChangeLimit
                                                ),
                                              }}
                                            ></p>
                                          </td>
                                          <td>
                                            Start at{" "}
                                            {data.TimeToEffectInMinutes} minutes
                                            - lasting for{" "}
                                            {data.DurationInMinutes} minutes
                                          </td>
                                          <td>
                                            <div className="lsActions">
                                              <div className="lsActions__item">
                                                <button className="btn btn-sm btn-outline-brand">
                                                  <span className="med-icon icon-pencil"></span>{" "}
                                                </button>
                                              </div>
                                              <div className="lsActions__item">
                                                <button className="btn btn-sm btn-outline-danger">
                                                  <span className="med-icon icon-cancel"></span>{" "}
                                                </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                                <p className="text-center my-3">
                                  <button className="btn btn-success btn-sm">
                                    Add Effect
                                  </button>
                                </p>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
      {/* /Modal */}
      <ModalPNOrder
        {...props}
        toggle={toggleIntervention}
        isOpen={modalIntervention}
        type={actions}
        categoryId={PNCategoryId}
        paramsUpdate={paramsUpdate}
        panel={panelCurrent}
      />
      <ModalDeleteIntervention
        {...props}
        toggleDel={toggleDel}
        isOpen={modalDel}
        interventionId={interventionId}
        categoryName={categoryNameCurrent}
        categoryType={`pnOrders`}
      />
      {/* Modal Score */}
      <ModalScore
        {...props}
        toggle={toggleScore}
        isOpen={modalScore}
        actions={actionScore}
        itemEdit={itemEdit}
        itemScore={itemScore}
        attributeType={`pnOrders`}
        categoryName={categoryNameCurrent}
      />
    </div>
  );
}

export default Setting;