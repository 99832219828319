import React, { useState, useEffect } from "react";

function AiLabelContent(props) {
  //props
  const { rowRecord, stateICD } = props && props;
  const text = rowRecord?.text;

  //state
  const [dataCID, setDataCID] = useState(stateICD || []);

  //Load State
  useEffect(() => {
    setDataCID(stateICD || []);
  }, [stateICD]);
  
  const highlightedText = dataCID?.reduce((prevText, keyword) => {
    const pattern = new RegExp(keyword.text, "gi");
    return prevText.replace(pattern, `<span class="tooltipContent"><span class="detail-tooltip" style="left: 0; top: 30px; right: 'unset' !important">${keyword?.is_current_explanation || ''}</span><span style="background-color: ${keyword.is_current ? "#31a24c" : "#f7b125"}; color: white; display: inline-block; padding: 1px 4px; border-radius: 4px;">${keyword.text}</span></span>`);
  }, text);
  
  return (
    <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: highlightedText }}></div>
  );
}

export default AiLabelContent;