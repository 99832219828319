import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Condition components
import {
    QuestionTypeCondition,
    QuestionConditionContent
} from '../FlowControl/Condition';

// Redux actions
import { atcLoadQuestionByIdFolowControl } from "@actions";

// Redux hooks
import { useDispatch } from "react-redux";

function QuestionConditionMain(props) {
    const dispatch = useDispatch();
    let { id } = useParams();

    // Destructure props
    const { timepointData, handleChangeQuestionID, flowControlOfQuestion } = props && props;
    const Questions = timepointData?.Questions;

    // State to manage the selected question ID and content
    const [selectedQuestionId, setSelectedQuestionId] = useState("");
    const [selectedQuestionContent, setSelectedQuestionContent] = useState("");

    // Function to fetch question details
    const fetchQuestionDetails = (questionId) => {
        // Call API for dropdown answer
        const params = {
            Action: "Load",
            Target: "Question",
            QuestionId: questionId,
        };
        dispatch(atcLoadQuestionByIdFolowControl(id, params));

        // Find the selected question content
        const selectedQuestion = Questions?.find((question) => question.QuestionId === parseInt(questionId));
        setSelectedQuestionContent(selectedQuestion?.QuestionContent || "");
        handleChangeQuestionID(questionId);
    };

    // Function to handle change of selected question
    const handleChange = (event) => {
        const questionId = event.target.value;
        setSelectedQuestionId(questionId);
        fetchQuestionDetails(questionId);
    };

    // Set the selected question ID and fetch its details based on flowControlOfQuestion prop
    useEffect(() => {
        setSelectedQuestionId(flowControlOfQuestion.QuestionId);
        fetchQuestionDetails(flowControlOfQuestion.QuestionId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flowControlOfQuestion.QuestionId]);

    return (
        <React.Fragment>
            <QuestionConditionContent
                {...props}
                selectedQuestionId={selectedQuestionId}
                selectedQuestionContent={selectedQuestionContent}
                handleChange={handleChange}
            />
            <QuestionTypeCondition {...props} />
        </React.Fragment>
    );
}

export default QuestionConditionMain;