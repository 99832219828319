import React from 'react';

//filters
// import { LevelFilters } from "../Home";

//New Interface
import { ButtonDeleteV2 } from "@ui-partents/Button";

const FilterContainer = (props) => {

    //props
    const { rowChecked, isLoading, rowOneChecked } = props && props;

    // State để lưu trữ các cấp độ được chọn
    // const [selectedLevels, setSelectedLevels] = useState([]);

    // Hàm xử lý khi người dùng thay đổi cấp độ
    // const onChangeLevel = (selectedOptions) => {
    //     setSelectedLevels(selectedOptions);
    // }

    return (
        <div className="d-flex align-items-center">
            {/* <div className='mr-2' style={{ width: "210px" }}>
                <LevelFilters {...props} isMulti={true} value={selectedLevels} onChangeLevel={onChangeLevel} />
            </div> */}
            {rowChecked?.length > 0 && !rowOneChecked?.length && (
                <ButtonDeleteV2 {...props} count={rowChecked?.length} isLoading={isLoading?.deleteFood} />
            )}
        </div>
    );
};

export default FilterContainer;