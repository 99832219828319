import React, { useState } from 'react';

//react-lines-ellipsis
import LinesEllipsis from "react-lines-ellipsis";

//components
import { ModalAddCollection } from '../templates';

//redux
import { useDispatch } from "react-redux";

//call Action
import { createTemplateCollection } from "@actions";

function CollectionList(props) {
    const dispatch = useDispatch();
    //props
    const { data, hanldeTemplateCollectionById, itemCollection } = props && props;

    //state
    const [modal, setModal] = useState({
        add: false
    });

    //Modal
    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    //Add
    const handleCollection = (params) => {
        dispatch(createTemplateCollection(params));
        setModal((prevState) => ({ ...prevState, add: false }));
    }

    return (
        <div className="collectionBox">
            <div className="text-center">
                <button className="btn-style2 bg-white --btn-ouline-primary" onClick={() => toggle('add')}>
                    <i className="fa fa-plus mr-2" aria-hidden="true" />New Collection
                </button>
            </div>
            <ul className="collectionList mt-3">
                {data?.map((value, index) =>
                    <li
                        className={`py-2 --hover-text-primary ${itemCollection?.TemplateCollectionId === value?.TemplateCollectionId ? "--text-primary" : ""}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => hanldeTemplateCollectionById(value)}
                        key={index}
                        data-id={value?.TemplateCollectionId}
                    >
                        <div className="d-flex align-items-center">
                            <div className="LinesEllipsis">
                                <LinesEllipsis
                                    text={value?.TemplateCollectionName}
                                    maxLine="2"
                                    ellipsis="..."
                                    trimRight
                                    basedOn=""
                                />
                            </div>
                            <i className="fa fa-chevron-right ml-auto" aria-hidden="true" />
                        </div>
                    </li>
                )}
            </ul>
            <ModalAddCollection
                isOpen={modal.add}
                handleCollection={handleCollection}
                toggle={() => toggle('add')}
            />
        </div>
    );
};

export default CollectionList;