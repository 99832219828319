import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

//components
import { HeaderChat, ChatMessage } from '../ChatForm';
import InputWrapperContainer from './InputWrapperContainer';

const ChatPopupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${({ inputHeight }) => `calc(100% - ${inputHeight + 100}px)`};
    overflow-y: auto;
    padding: 16px 0;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    position: relative;
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex-grow: 1;
    width: 100%;
    padding: 8px;
`;

const ChatFormContainer = () => {
    const innerWrapperRef = useRef(null);
    const endOfMessagesRef = useRef(null);
    const inputWrapperRef = useRef(null);
    const [inputHeight, setInputHeight] = useState(0);

    const scrollToBottom = () => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const updateInputHeight = () => {
        if (inputWrapperRef.current) {
            const height = inputWrapperRef.current.clientHeight;
            setInputHeight(height);
        }
    };

    useEffect(() => {
        updateInputHeight();

        window.addEventListener('resize', updateInputHeight);

        return () => {
            window.removeEventListener('resize', updateInputHeight);
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [inputHeight]);

    console.log(inputHeight, "inputHeight...");
    return (
        <React.Fragment>
            <HeaderChat />
            <ChatPopupWrapper inputHeight={inputHeight}>
                <InnerWrapper ref={innerWrapperRef}>
                    <ChatMessage
                        name="Instructor"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={true}
                    />
                    <ChatMessage
                        name="User"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={false}
                    />
                    <ChatMessage
                        name="User"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={false}
                    />
                    <ChatMessage
                        name="Instructor"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={true}
                    />
                    <ChatMessage
                        name="User"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={false}
                    />
                    <ChatMessage
                        name="User"
                        content="This lesson will serve as a gateway into this captivating realm, guiding us through the foundational principles that underpin medical?"
                        image="path-to-image.jpg"
                        isLeft={false}
                    />
                    <div ref={endOfMessagesRef} />
                </InnerWrapper>
            </ChatPopupWrapper>
            <InputWrapperContainer inputWrapperRef={inputWrapperRef} />
        </React.Fragment>
    );
};

export default ChatFormContainer;