import React from 'react';

//boostrap
import {
    Container
} from 'reactstrap';

function StepByStep(props) {
    const { stepActive, data } = props && props;
    const listItems = data?.map((item, idx) =>
        <li key={idx} className={`stepBar__item ${item?.step <= stepActive ? "active --active-primary-color" : null}`}>{item?.name}</li>
    );
    return (
        <Container>
            <ul className="stepBar d-flex align-items-center">
                {listItems}
            </ul>
        </Container>
    );
}

export default StepByStep;