import React from "react";
import ReactPlayer from "react-player";

function ViewMedia(props) {
  const { item } = props && props;
  const type = item && item.MediaType;
  const report = item && item.Report;

  return (
    <React.Fragment>
      <div
        className="mb-3"
        dangerouslySetInnerHTML={{
          __html: report || "",
        }}
      ></div>

      {type === "VideoLink" && (
        <div className="playerWrapper">
          <ReactPlayer
            url={item.Url || item.MediaLink}
            name={item.MediaType}
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      )}
      {type === "AudioFile" && (
        <ReactPlayer
          url={item.Url || item.MediaLink}
          name={item.MediaType}
          width="100%"
          height="50px"
          controls={true}
          loop={true}
        />
      )}
      {type === "VideoFile" && (
        <ReactPlayer
          url={item.Url || item.MediaLink}
          name={item.MediaType}
          width="100%"
          height="100%"
          controls={true}
          loop={true}
        />
      )}
      {type === "ImageFile" && (
        <img
          className="d-block mx-auto"
          src={item.Url || item.MediaLink}
          style={{ maxWidth: "300px" }}
          alt={item.Title}
        />
      )}
    </React.Fragment>
  );
}

export default ViewMedia;
