import { learnerConstants } from '../constants';

export function portfolio (state = {}, action) {
    switch (action.type) {
        case learnerConstants.PORTFOLIO_REQUEST:
            return { 
                ...state,
                isLoading: true
            };
        case learnerConstants.PORTFOLIO_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case learnerConstants.PORTFOLIO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};