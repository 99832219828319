import React from 'react';

//truncate
import { truncate } from "@utils";

//reactstrap
import { Badge, UncontrolledTooltip } from "reactstrap";

//lodash
import _ from 'lodash';

function ItemScoreIndependent(props) {
    const { itemRow, listDomain, toggleScore, timepointData } = props && props;
    const timepointCurrent = timepointData?.data;
    const timeOfTimePoint = timepointCurrent?.Time;
    return (
        <React.Fragment>
            {listDomain?.map((item, index) => {
                const numberScore = parseFloat(item.Score);
                const itemScore = item;
                const timingCondition = item.TimingCondition;
                const timingValue = item.TimingValue;
                const conditionNotValue = (_.isUndefined(timingValue) && _.isUndefined(timingCondition));
                const conditionMatchValue = (timingCondition === 'At' && (timingValue === timeOfTimePoint));
                const domainName = item?.DomainName || ''
                return (
                    <React.Fragment key={index}>
                        {(conditionNotValue || conditionMatchValue) &&
                            <React.Fragment>
                                <Badge
                                    style={{ cursor: "pointer" }}
                                    className="p-1 mb-1"
                                    color={numberScore >= 0 ? "success" : "danger"}
                                    key={index}
                                    id={`tooltipBadgeIndependent_${item?.DomainId}`}
                                    onClick={() =>
                                        toggleScore(
                                            itemRow,
                                            "edit-score",
                                            "Lab",
                                            itemScore,
                                            "Independent-Labs"
                                        )
                                    }
                                >
                                    <span className="font-weight-500">
                                        {truncate(domainName, 12)}
                                    </span>
                                    &nbsp;
                                    <strong>({numberScore})</strong>
                                    {` `}{(typeof timingValue != "undefined" && typeof timingCondition != "undefined") && `(*)`}
                                </Badge>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltipBadgeIndependent_${item?.DomainId}`}
                                >
                                    {domainName}
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }
                    </React.Fragment>
                );
            })}
            <div
                style={{ cursor: "pointer" }}
                className="m--font-info font-weight-500"
                onClick={() =>
                    toggleScore(
                        itemRow,
                        "add-score",
                        "Lab",
                        {},
                        "Independent-Labs"
                    )
                }
            >
                <span className="icon-add icon-size-2x"></span>
            </div>
        </React.Fragment>
    );
};

export default ItemScoreIndependent;