import React, { useState } from "react";

//react-select
import Select from "react-select";

function QuestionPromptTemplate(props) {
  const { handleChangeInput, promptTemplateList, stateCurrentQuestion } = props && props;

  // state
  const [selectedPrompt, setSelectedPrompt] = useState(stateCurrentQuestion?.PromptTemplates && stateCurrentQuestion?.PromptTemplates?.length !== 0 
    ? stateCurrentQuestion?.PromptTemplates.map((prompt) => {
      return {
        ...prompt,
        value: prompt.Name,
        label: prompt.Name
      }
    }) 
    : [] );

  const handleChangeOptions = (data) => {
    handleChangeInput(data, "PromptTemplateIds")
    setSelectedPrompt(data);
  } 
  
  return (
    <div>
      <legend className="col-form-label font-weight-500 mt-4">
        Apply Prompt Template: <span className="m--font-danger">*</span>
      </legend>
      <Select
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={selectedPrompt}
        onChange={handleChangeOptions}
        options={promptTemplateList}
        placeholder={`Select an item below`}
        className="w-100"
        name="PromptTemplateIds"
      />
    </div>
  );
}

export default QuestionPromptTemplate;