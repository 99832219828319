import React from 'react';
import imgPerson from "@images/common/person.png";
//components
// import { ViewMediaButton } from '../../Chat';

//redux
import { useSelector } from "react-redux";

function PatientMessage(props) {
    const { data, value, loadingIndex, index } = props && props;
    const Interface = data?.Interface;
    const PatientAvatar = Interface?.PatientAvatar;

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const VirtualPatient = InterfaceTemplateDetails?.VirtualPatient;
    return (
        <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PatientAvatar || imgPerson}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            <div dangerouslySetInnerHTML={{ __html: VirtualPatient?.patient_name?.Text || "Patient:" }} />
                        </div>
                        <div className="messengerCt__message-text">
                            <React.Fragment>
                                <div dangerouslySetInnerHTML={{ __html: value?.PatientResponse }} />
                                {/* {value?.Media && <ViewMediaButton value={value} />} */}
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientMessage;