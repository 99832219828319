import React from 'react';

//common
import { IconDisplay } from '@components/common/media';

function TableCellExam(props) {
    const { value, handleShow, iconMedia, item } = props && props;
    const isMedia = item?.MediaType;
    return (
        <td
            style={{ cursor: 'pointer' }}
            onClick={handleShow}
            className={`table-cell align-middle`}
        >
            <div className={`${isMedia !== 'None' ? "pr-2" : "mx-auto"} ${value === '(no change)' ? "text-muted" : ""}`}
                dangerouslySetInnerHTML={{ __html: value }}
            />
            {isMedia !== 'None' &&
                <div className="mt-2 text-left">
                    {iconMedia &&
                        <IconDisplay type={isMedia} />
                    }
                </div>
            }
        </td>
    );
};

export default TableCellExam;