import React from "react";
import { Col, Label, Input, FormGroup } from "reactstrap";

function Filter(props) {
  return (
    <div className="filterLeft">
      <FormGroup row>
        <Col sm={12}>
          <Label className="font-weight-500" htmlFor="Search">
            Search
          </Label>
          <div className="d-flex align-items-center">
            <Input
              type="text"
              name="search"
              placeholder="Search"
              style={{ height: "38px" }}
              value={props && props.value}
              onChange={props && props.handleChange}
              autoComplete="off"
            />
            <button className="btn btn-brand ml-2">
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </Col>
      </FormGroup>
    </div>
  );
}

export default Filter;
