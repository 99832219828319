import React from "react";
import imgUser from "@images/common/no-avatar.jpg";

function UserBox(props) {
  const { data } = props && props;
  const profilePhoto = data && data.profile_photo_url;
  const institution = data && data.institution;
  const firstName = data && data.firstname;
  return (
    <div className="-box-shadow my-2">
      <div className="widgetBox" id="m2l_home_box_profile">
        <div className="widgetBox__body">
          <div className="userBox">
            <div className="userBox__left">
              <img src={profilePhoto || imgUser} className="imgUser bg-grey-light" alt="img" />
            </div>
            <div className="userBox__right">
              <p className="branchText --text-primary">
                {institution || ""}
              </p>
              <p className="userName">Hello, {firstName || ""}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBox;
