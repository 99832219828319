import React, { useState, forwardRef, useEffect } from "react";
import DatePicker from "react-datepicker";

//format time
import moment from "moment";

//boostrap
import {
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";

function FilterTime(props) {
  //props 
  const { handleChangeValue } = props && props;

  //state
  const dateFromCustom = moment(new Date()).add(-7, "days").toDate(); //-7 days
  const [startDate, setStartDate] = useState(dateFromCustom);
  const [endDate, setEndDate] = useState(new Date());
  const [isChange, setChange] = useState(false);

  const CutomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
      <Input onClick={onClick} defaultValue={value} type="text" ref={ref} />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <i className="fa fa-calendar" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  ));

  const handeChangeDate = (date, type) => {
    if (type === "startDate") {
      setStartDate(date);
    }
    if (type === "endDate") {
      setEndDate(date);
    }
    setChange(true);
  };

  useEffect(() => {
    if (isChange) {
      const params = {
        StartDate: startDate || null,
        EndDate: endDate || null,
      };
      handleChangeValue(params);
      setChange(false);
    }
  }, [isChange, startDate, endDate, handleChangeValue]);

  return (
    <section className="filterTime -box-shadow bg-white mb-4 p-4">
      <Form>
        <Row style={{ maxWidth: "600px" }}>
          <Col sm={6}>
            <Label for="exampleEmail" className="mr-sm-2 font-weight-500">
              Period:
            </Label>
            <FormGroup className="pickerCustom">
              <DatePicker
                placeholder="mm/dd/yyyy, --:-- --"
                selected={startDate}
                onChange={(e) => handeChangeDate(e, "startDate")}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="form-control"
                dateFormat="MMMM d, yyyy h:mm a"
                showTimeInput
                timeInputLabel="Time:"
                customInput={<CutomInput />}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <Label for="exampleEmail" className="mr-sm-2 font-weight-500">
              &nbsp;
            </Label>
            <FormGroup className="pickerCustom">
              <DatePicker
                placeholder="mm/dd/yyyy, --:-- --"
                selected={endDate}
                onChange={(e) => handeChangeDate(e, "endDate")}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                className="form-control"
                dateFormat="MMMM d, yyyy h:mm a"
                showTimeInput
                timeInputLabel="Time:"
                customInput={<CutomInput />}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </section>
  );
}

export default FilterTime;
