import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

//components
import { TableMcqBank, TableMcqBankOrg } from "@components/teachV2/teachMcqBank";
import { getQuestionBank } from "@actions";
import { TableToBeReviewed } from './TableToBeReviewed';
import { TableAllReviews } from './AllReviews';
import { useLocation } from 'react-router-dom';

const WrapMcqBank = (props) => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location?.state?.type === "review" ? 2 : 1);
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBank || []);
  const Account = JSON.parse(localStorage.getItem("Account"));

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    // Nếu location.state không tồn tại, thay đổi bằng replaceState
    const defaultState = { type: "edit" };
    window.history.replaceState({ ...window.history.state, state: defaultState }, "");
  }, [location]);
  
  const tabsOption = Account?.is_administrator === "Yes" ? [
    { id: 1, key: "my-references", label: "My Question Bank" },
    { id: 2, key: "be-reviewed", label: "To Be Reviewed" },
    // { id: 4, key: "all-reviews", label: "All Reviews" },
    { id: 3, key: "org-references", label: "Organization Bank" },
  ] : [
    { id: 1, key: "my-references", label: "My Question Bank" },
    { id: 2, key: "be-reviewed", label: "To Be Reviewed" },
    { id: 3, key: "org-references", label: "Organization Bank" },
  ]

  useEffect(() => {
    dispatch(getQuestionBank())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wrapper -ReferenceLiblaryPage p-0">
      <div className='mcq-bank__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <TableMcqBank 
            loadDataMcqBank={listQuestionBank?.data || []}
          />
        )}
        {activeTab === 2 && (
          <TableToBeReviewed 
            loadDataMcqBank={listQuestionBank?.data || []}
          />
        )}
        {activeTab === 4 && (
          <TableAllReviews
            loadDataMcqBank={listQuestionBank?.data || []}
          />
        )}
        {activeTab === 3 && (
          <TableMcqBankOrg />
        )}
      </div>
    </div>
  )
}

export default WrapMcqBank
