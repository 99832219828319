import React from 'react';

const EmojiFace = ({ src, alt, isSelected, hasSelection, onSelected }) => {
    return (
        <div 
            className={`emoji-face ${isSelected ? 'selected' : ''} ${hasSelection ? 'has-selection' : ''}`} 
            onClick={onSelected}
        >
            <figure className="emoji-face__icon">
                <img src={src} alt={alt} />
            </figure>
            <figcaption>{alt}</figcaption>
        </div>
    );
}

export default EmojiFace;