import React, { useState } from "react";
import { Table } from "reactstrap";

//components
import { ModalViewMedia } from "@components-common/Modal";

//redux
import { useSelector } from "react-redux";

function StudyResult(props) {
  const { heightPatientMedRecord } = props && props;

  //props
  const caseData = useSelector((state) => state.cases || []);
  const caseDetails = caseData?.data?.Case;
  const clinicalStatusData = caseDetails?.ClinicalStatus;
  const studyResultsData = clinicalStatusData?.StudyResults;

  //state
  const [modal, setModal] = useState(false);
  const [contentCurrent, setContentCurrent] = useState({});

  const toggle = (value) => {
    setModal(!modal);
    setContentCurrent(value);
  };

  return (
    <React.Fragment>
       <div className="table-results-med isShowScroll" style={{maxHeight: `${heightPatientMedRecord - 100}px`, borderRadius: '13px'}}>
        {studyResultsData?.length > 0 ? (
            <Table
              bordered
              size="sm"
            >
              <tbody>
                {studyResultsData?.map((value, index) => (
                  <tr key={index}>
                    <th width="35%">
                      <span className="tbl-heading">{value.Name}</span>
                      <br />
                      <span className="text-muted font-weight-400 text-2">
                        (Ordered at {value.OrderedTime})
                      </span>
                    </th>
                    <td className="p-3">
                      <div
                        dangerouslySetInnerHTML={{ __html: value.Result }}
                      ></div>
                    </td>
                    <td className="text-center align-middle" width={`160px`}>
                      {value?.MediaType !== "None" && (
                        <span
                          color="primary"
                          className="link_underline"
                          onClick={(e) => toggle(value)}
                        >
                          View Result
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
        ) : (
          <h6 className="message-sp text-center py-5">
            There are no study results currently available.
          </h6>
        )}
      </div>
      <ModalViewMedia isOpen={modal} toggle={toggle} data={contentCurrent} />
    </React.Fragment>
  );
}

export default StudyResult;