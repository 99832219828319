import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container, Row, Col, Button } from "reactstrap";

//comnponents
import { ProgressApi, Filter } from '../Learners';

//lodash
import _ from "lodash";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//redux
import { useDispatch, useSelector } from "react-redux";

//api
import { apiCaller, LoaderText } from "@utils";

//common
import { DataEmty } from "@components-common";

//call Action
import {
  actFetchCaseLearnersRequest,
  atcLoadAllCohorts,
  actLoadAllPeriods,
  actReportCaseLearnersDetails
} from "@actions";

// Import the pagination configuration
import {paginationOptions} from "@utils";

function LearnersReport(props) {
  let { id } = useParams();
  let PerformanceIdList = [];
  const dispatch = useDispatch();

  //props
  const handleContentPush = props && props.handleContentPush;

  //data store
  const cohortStore = useSelector(
    (state) => (state.listCohort && state.listCohort.data) || []
  );

  const periodsData = useSelector(
    (state) => (state.loadPeriods && state.loadPeriods.data) || []
  );

  const listLearnersStore = useSelector(
    (state) => state.listLearnersReport || []
  );
  const listLearners = listLearnersStore?.data;

  const enrolledLearners = listLearners && listLearners.EnrolledLearners;
  const dataTable = enrolledLearners && enrolledLearners.CompletionDetails;

  //state
  const [stateData, setStateData] = useState({
    CohortId: null,
    PeriodId: null,
  });
  const [isChange, setChange] = useState(false);
  const [stateToTal, setTotal] = useState(0);
  const [stateSuccess, setSucess] = useState(0);
  const [statusLoad, setStatusLoad] = useState(false);
  const [stateFail, setFail] = useState(0);
  const [progressBar, setProgress] = useState(0);
  const [isSubmit, setSubmit] = useState(false);

  //load api
  useEffect(() => {
    const fetchData = async () => {
      dispatch(actFetchCaseLearnersRequest(id));
      //load Cohorts
      dispatch(atcLoadAllCohorts());

      //load Periods
      const params = { Action: "Load" };
      dispatch(actLoadAllPeriods(params));
    };

    const loadList = async () => {
      const params = {
        ReportType: "learners_Completed",
        CohortId: null,
        PeriodId: null,
        ShowDetails: true,
      };
      dispatch(actReportCaseLearnersDetails(id, params));
    };

    fetchData();
    loadList();
  }, [dispatch, id]);

  const columns = [
    {
      dataField: "Id",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
    },
    {
      dataField: "Email",
      text: "UserID/Email",
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
    },
    {
      dataField: "Details",
      text: "Session(s)/Trial(s)",
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
      formatter: (value, row) => {
        return (
          <React.Fragment>
            {value && value.map((item, index) => (
              <p className="timeTrial d-flex align-items-center" key={index} onClick={() => handleContentPush(item)}>
                <span className="mr-3 text-decoration">
                  {item.CompletionTime}
                </span>
                <span id={`PerformanceId_${item?.PerformanceId}`} className="spinner-border-sm ml-auto">
                  <span className="icon-checked text-success d-none"></span>
                </span>
              </p>
            ))}
          </React.Fragment>
        );
      },
    },
  ];

  const hanldeChange = (e) => {
    const { name, value } = e.target;
    setStateData((prevState) => ({ ...prevState, [name]: value }));
    setChange(true);

    //reset
    handleResetData();
  };

  const handleResetData = () => {
    //reset
    setSubmit(false);
    setTotal(0);
    setSucess(0);
    setProgress(0);
    setStatusLoad(false);
  }

  const handleUpdateGrading = async () => {
    // filter object by Details
    const filterDatatable = _.map(dataTable, 'Details');

    //Total
    let dataTotal = dataTable.reduce(function (prev, cur) {
      return prev + cur.Details.length;
    }, 0);

    //get Id
    for (var value of filterDatatable) {
      // filter in Details has PerformanceId
      const filterPerformanceId = _.map(value, 'PerformanceId');
      PerformanceIdList.push(...filterPerformanceId);
    };

    //Loop PerformanceIdList
    async function processItems(data) {
      for (let i = 0; i < data.length; i++) {
        //Call Api
        await callApiUpdatePerfoman(data[i]);
      }
      return PerformanceIdList;
    }

    //Call Api
    if (PerformanceIdList.length === dataTotal) {
      setTotal(dataTotal);
      setSubmit(true);
      await processItems(PerformanceIdList);
    }
  }

  //countItem
  let count = 0;
  let isSucess = 0;
  let isFail = 0;
  const callApiUpdatePerfoman = (PerformanceId) => {
    const params = {
      "ReportType": "learner_CasePerformance",
      "Action": "Update",
      "PerformanceId": PerformanceId
    }

    //Call Api
    apiCaller(`/api/teach/report/case/${id}/`, "PUT", params).then((res) => {
      let newDataPerformance = _.cloneDeep(PerformanceIdList);
      count++
      if (res && res.status === 200) {
        isSucess++;
        setSucess(isSucess);
      } else {
        isFail++;
      }

      //Stop...
      if (parseFloat(count) === newDataPerformance.length) {
        setStatusLoad(true);
      }

      const progress = parseFloat(Math.round((isSucess * 100) / newDataPerformance.length));
      setProgress(progress);
      setFail(isFail);
    });
  }

  //load api when change
  useEffect(() => {
    const fetchData = async () => {
      const params = {
        ReportType: "learners_Completed",
        CohortId: stateData.CohortId || null,
        PeriodId: stateData.PeriodId || null,
        ShowDetails: true,
      };
      dispatch(actReportCaseLearnersDetails(id, params));
    };

    if (isChange) {
      fetchData();
      setChange(false);
    }
  }, [isChange, stateData, dispatch, id]);

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div className="position-relative">
      {listLearnersStore?.isLoading ? (
        <div style={{ height: '300px' }}>
          <LoaderText />
        </div>
      ) : (
        <React.Fragment>
          <ToolkitProvider
            keyField="Email"
            data={(dataTable && dataTable) || []}
            columns={columns}
            search
          >
            {(toolkitprops) => (
              <BootstrapTable
                condensed
                striped
                wrapperClasses="table-responsive"
                bordered={false}
                noDataIndication={() => (
                  <DataEmty content={`No Data`} />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
              />
            )}
          </ToolkitProvider>
          <div className="pagination-custom-case d-flex justify-content-center mt-4">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </React.Fragment>
      )}
    </div>
  );

  const totalSize = dataTable?.length || 0; // Dynamically calculate the total size

  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="panelBox">
      <div className="panelBox__head">
        <div className="panelHeadCt">
          <div className="panelHeadCt__caption">
            <h3 className="m--font-brand --text-primary">Learners</h3>
          </div>
        </div>
      </div>
      <div className="panelBox__body">
        <Container>
          <Filter 
            cohortStore={cohortStore}
            periodsData={periodsData}
            hanldeChange={hanldeChange}
            stateData={stateData}
          />
          <Row>
            {(dataTable && dataTable.length) > 0 && (
              !isSubmit &&
              <Col className="d-flex justify-content-end" sm={12}>
                <Button className="-btn-primary my-3" onClick={handleUpdateGrading}>Update Grading</Button>
              </Col>
            )}
          </Row>
          {isSubmit ? (
            <ProgressApi
              total={stateToTal}
              current={stateSuccess}
              isFail={stateFail}
              progressBar={progressBar}
              handleSubmit={handleResetData}
              isLoad={statusLoad}
            />
          ) : (
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          )}
        </Container>
      </div>
    </div>
  );
}

export default LearnersReport;