import React, { useState, useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//boostrap
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Input,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actFetchLoadTimePointForElement } from "@actions";

function ModalInitalValue(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { handleClose, isOpenModal, data } = props && props;
    const row = data?.row;
    const TimepointId = data?.TimepointId;

    //state
    const [stateData, setStateData] = useState((row && row) || {});

    useEffect(() => {
        setStateData((row && row) || {});
    }, [row])

    const handleChange = (e) =>{
        const { name, value } = e.target;
        setStateData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = (e) => {
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: TimepointId,
            VitalSign: {
                Id: stateData?.Id,
                Value: stateData?.InitialValue,
                Unit: stateData?.Unit
            },
        };
        dispatch(actFetchLoadTimePointForElement(id, params));
        handleClose();
    }

    return (
        <Modal
            className="modal-dialog"
            isOpen={isOpenModal}
            backdrop={`static`}
        >
            <ModalHeader toggle={handleClose}>
                {`Change At This Time Point ${row?.Name}`}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={8} className="my-4 mx-auto">
                        <InputGroup>
                            <Input
                                type="text"
                                name="InitialValue"
                                defaultValue={row?.InitialValue}
                                placeholder="Initial Value"
                                onChange={handleChange}
                                autoComplete="off"
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: row?.UnitAlias,
                                        }}
                                    ></div>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalInitalValue;