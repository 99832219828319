import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

// common
import { HeadingBox } from "@components/common";

//loader
import { LoaderText } from "@utils";

//boostrap
import { Container, Row, Col, Card } from "reactstrap";

//components
import { TableChat } from '@components/reports/Chat';

//actions
import { loadReportChat } from "@actions";

function ChatReportPage(props) {
  //props
  const { caseDetails } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = { "ReportType": "chat" };
      dispatch(loadReportChat(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //storeChat
  const storeChat = useSelector(
    (state) => state?.listChatReport || {}
  );

  const listData = storeChat?.data;

  //titlePage
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -reportPage">
      <div className="reportCt">
        <Container>
          <Row>
            <Col sm={12}>
              <HeadingBox title={titlePage || ""} currentPage="Chat" />
            </Col>
            <Col md={12}>
              <Card className="border-0 -box-shadow p-4 position-relative">
                {storeChat?.isLoading &&
                  <div style={{height: '300px'}}>
                    <LoaderText />
                  </div>
                }
                <TableChat {...props} data={(listData && listData) || []} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ChatReportPage;