import React, { useEffect, useState } from "react";

//boostrap
import { Row, Col } from "reactstrap";

//components
import {
  WidgetBox,
  NotificationList,
  CardStatistics,
  TabsGroup,
  MyCases,
  MyTemplate
} from "../Home";

//common
import { UserBox } from "@components-common";

//redux
import { useSelector } from "react-redux";

function Home(props) {
  //props
  const { subscription } = props && props;
  //load series from store
  const instructorData = useSelector((state) => state.instructor || {});
  const techHomeStore = useSelector((state) => state.loadTechHome || {});
  const templatesStore = useSelector((state) => state?.loadTemplates || {});
  const templatesData = templatesStore?.data;
  const techHomeData = techHomeStore?.data;

  //state
  const [stateAccount, setAccount] = useState({});

  //state
  useEffect(() => {
    const Account = localStorage.getItem("Account");
    if (Account) {
      const jsonAccount = JSON.parse(Account);
      setAccount(jsonAccount);
    }
  }, []);

  const notificationData = techHomeData?.Notification;
  const AdminUsageData = techHomeData?.AdminUsage;
  const instructorProps = instructorData?.data;
  const myCaseData = instructorProps?.Cases;

  const navListGroup = [
    {
      Name: "My Cases",
      Component: <MyCases {...props} data={(myCaseData && myCaseData) || []} />,
    },
    {
      Name: "My Templates",
      Component: <MyTemplate {...props} data={(templatesData && templatesData) || []} />,
    }
  ];

  return (
    <Row>
      <Col sm={12} md={12} lg={4}>
        <UserBox data={stateAccount} />
        {AdminUsageData && (
          <CardStatistics title={(subscription && subscription) || ''} data={(AdminUsageData && AdminUsageData) || {}} />
        )}
      </Col>
      <Col sm={12} md={12} lg={8}>
        {notificationData && (
          <NotificationList
            value={(notificationData && notificationData) || {}}
          />
        )}
        {techHomeData?.Tasks.map((value, index) => (
          <WidgetBox
            key={index}
            indexParent={index}
            data={value || []}
            {...props}
          />
        ))}
        <TabsGroup data={navListGroup} />
      </Col>
    </Row>
  );
}

export default Home;
