import React, { useState, useEffect } from 'react';

import {
    PaymentMessageModal, // Đã đổi tên từ PaymentSuccessModal
    BillSummaryDetails,
    PaymentMethods
} from "@components/PaymentGateway/PaymentOverview";

//utils
import { apiCaller } from '@utils';

const BillSummaryPage = () => {
    const [isAcknowledged, setIsAcknowledged] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSquarePaymentVisible, setIsSquarePaymentVisible] = useState(false);
    const [modalType, setModalType] = useState('success'); // Thêm trạng thái để điều chỉnh loại modal

    const handleCheckboxChange = () => {
        setIsAcknowledged(!isAcknowledged);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handleSquarePaymentSuccess = (token) => {
        let paymentData = {
            nonce: token,
            amount: 200,
            payment_status: 'COMPLETED'
        };

        apiCaller('/api/billing/square-process-payment/', 'POST', paymentData).then(response => {
            if (response.status === 200) {
                setModalType('success'); // Xác định modal hiển thị thông báo thành công
                setIsModalVisible(true);
                setIsSquarePaymentVisible(false);
            } else {
                setModalType('error'); // Hiển thị thông báo lỗi khi thanh toán thất bại
                setIsModalVisible(true);
            }
        });
    };

    const handleSquarePaymentError = (errors) => {
        console.error(errors);
        setModalType('error'); // Hiển thị modal thông báo lỗi khi có lỗi xảy ra
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (window.paypal) {
            window.paypal.Buttons({
                style: {
                    layout: 'vertical',
                    color: 'white',
                    shape: 'rect',
                    label: 'paypal'
                },
                onError: function (err) {
                    setModalType('error'); // Hiển thị modal thông báo lỗi khi có lỗi xảy ra
                    setIsModalVisible(true);
                    console.log(err, "err...");
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: '10.00' // Thay giá trị thành số thực tế
                            }
                        }]
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(details => {
                        let paymentData = {
                            payment_id: details.id,
                            payer_id: details.payer.payer_id,
                            payment_status: details.status,
                            payment_amount: details.purchase_units[0].amount.value,
                            payment_time: details.create_time
                        };

                        apiCaller('/api/billing/paypal-process-payment/', 'POST', paymentData).then(response => {
                            if (response.status === 200) {
                                setModalType('success'); // Hiển thị modal thành công khi thanh toán thành công
                                setIsModalVisible(true);
                            } else {
                                setModalType('error'); // Hiển thị modal thông báo lỗi nếu thất bại
                                setIsModalVisible(true);
                            }
                        });
                    });
                }
            }).render('#paypal-button-container');
        }
    }, []);

    return (
        <div className="wrapper payment-gateway-page bg-white h-100" style={{ padding: '24px' }}>
            <BillSummaryDetails
                isAcknowledged={isAcknowledged}
                handleCheckboxChange={handleCheckboxChange}
            />
            <PaymentMethods
                setIsSquarePaymentVisible={setIsSquarePaymentVisible}
                handleSquarePaymentSuccess={handleSquarePaymentSuccess}
                handleSquarePaymentError={handleSquarePaymentError}
                isSquarePaymentVisible={isSquarePaymentVisible}
            />
            {/* Sử dụng PaymentMessageModal với type để xác định thông báo */}
            <PaymentMessageModal isVisible={isModalVisible} onClose={handleCloseModal} type={modalType} />
        </div>
    );
};

export default BillSummaryPage;