import React from "react";

//reactstrap
import { Row } from "reactstrap";

//components
import { AddNewReview, ItemContents } from "../../ReviewContentModal/GoldStandard";

function ReviewContentMain(props) {
    return (
        <Row className="h-100">
            <ItemContents {...props} />
            <AddNewReview {...props} />
        </Row>
    );
}

export default ReviewContentMain;