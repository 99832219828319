/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import { RichTextCommon } from "@components/teachV2/storyboard";
import { debounce } from "lodash";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { atcUpdateItemTimePoint } from "@actions";

const InstructionsPhysicalExam = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { activeViewClass, timepointData } = props;
  let TimepointId = timepointData?.Id;

  //state
  const filteredData = timepointData?.TaskElements?.find(item => item.ViewClass === activeViewClass);
  const [valueEditor, setValueEditor] = useState(filteredData?.Instructions);

  //Load Value
  useEffect(() => {
    const filteredData = timepointData?.TaskElements?.find(item => item.ViewClass === activeViewClass);
    setValueEditor(filteredData?.Instructions);
  }, [timepointData, activeViewClass]);

  //Update narrative
  const handleApiContentExam = useCallback((currentContent, TimepointId) => {
    let currentParams = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: TimepointId,
      Flow: {
        Mode: "edit",
        Task: activeViewClass,
        Instructions: currentContent
      }
    };
    dispatch(atcUpdateItemTimePoint(id, currentParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debounced = useCallback(
    debounce((currentContent, TimepointId) => handleApiContentExam(currentContent, TimepointId), 500), []
  );

  const handleUpdateEditor = (currentContent, TimepointId) => {
    debounced(currentContent, TimepointId);
    setValueEditor(currentContent);
  };

  return (
    <div className="content-narrative">
      <RichTextCommon
        handleUpdateEditor={(content) => handleUpdateEditor(content, TimepointId)}
        task={activeViewClass}
        defaultValue={valueEditor}
        placeholder="Enter the instructions for the learner here..."
      />
    </div>
  );
};

export default InstructionsPhysicalExam;