import React, { useState, useEffect } from 'react';

//call Action
import { updateStatusQuestionBank } from "@actions";

//react-tooltip
import ReactTooltip from "react-tooltip";

//redux
import { useDispatch } from "react-redux";

function FavoriteQuestionBank(props) {

    //redux
    const dispatch = useDispatch();

    //props
    const { item } = props && props;

    //status
    const [statusQuestionBank, setQuestionBank] = useState(item?.InQuestionBank);

    useEffect(() => {
        setQuestionBank(item?.InQuestionBank);
    }, [item])

    //reload tooltip
    useEffect(() => {
        ReactTooltip.rebuild()
    });

    const hanldeSwitch = (event) => {
        const { checked } = event.target;
        const params = { "QuestionId": item?.QuestionId };
        if (checked) {
            params[`Action`] = "AddToMyQuestionBank";
        } else {
            params[`Action`] = "RemoveFromQuestionBank";
        }
        setQuestionBank(checked);
        dispatch(updateStatusQuestionBank(params));
        ReactTooltip.rebuild();
    };

    return (
        <input
            className="favorite-star ml-1"
            type="checkbox"
            defaultChecked={statusQuestionBank}
            name={`InQuestionBank`}
            data-tip={statusQuestionBank ? "Remove from my question bank" : "Add to my question bank"}
            onChange={(e) =>
                hanldeSwitch(e)
            }
        />
    );
};

export default FavoriteQuestionBank;