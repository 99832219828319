import React from 'react';
import { Icon } from '@components/common';
import ErrorIcon from '@images/login/error.svg';
import SuccessIcon from '@images/login/success.svg';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const ModalMessageAuth = ({ dataMessage }) => {
  const history = useHistory();

  // Determine icon based on error presence
  const iconSrc = dataMessage?.error ? ErrorIcon : SuccessIcon;

  // Render buttons
  const renderButtons = () =>
    dataMessage?.buttons?.map(({ ButtonStyle, URL, ButtonTitle, onButtonClick }, index) => (
      <Button
        key={index}
        color={ButtonStyle}
        className='modal-message-auth__action-btn'
        onClick={() => {
          if (onButtonClick) {
            // Call the specific button's onButtonClick function if it exists
            onButtonClick(URL);
          } else if (URL) {
            // Fallback to history.push if no onButtonClick is provided but URL is available
            history.push(URL);
          }
        }}
      >
        {ButtonTitle}
      </Button>
    ));

  const message = typeof dataMessage?.error === 'string' ? dataMessage.error : dataMessage?.message || '';
  return (
    <div className='modal-message-auth'>
      <div className='modal-message-auth__icon'>
        <Icon src={iconSrc} />
      </div>
      <div className='modal-message-auth__content text-center'>
        {dataMessage?.title && <p className='modal-message-auth__content--title'>{dataMessage.title}</p>}
        <div className='modal-message-auth__content--message' dangerouslySetInnerHTML={{ __html: message }} />
      </div>
      <div className='modal-message-auth__action'>
        {renderButtons()}
      </div>
    </div>
  );
};

export default ModalMessageAuth;