import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store';
import Root from './Root';
import allRoutes from './routes/allRoutes';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/style/main.css';
import "react-datepicker/dist/react-datepicker.css";

const store = configureStore()
const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, store)

ReactDOM.render((
  <Root
    store={store}
    routes={allRoutes}
    history={history}
  />
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();