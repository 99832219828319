import React, { useState } from "react";

//components
import {
  AnswerContent,
  AnswerText
} from "../FreeText";

import { Row, Badge } from "reactstrap";

function FreeText(props) {
  const { Answers, IsCorrect, callApiFeedbacksAssistantforQuestion } = props && props;

  let [isOpen, setIsOpen] = useState(false);

  let onToggle = (item) => {
    setIsOpen(!isOpen);
    if (!isOpen && item?.IsCorrect === false) {
      callApiFeedbacksAssistantforQuestion(item);
    }
  };

  const listItems = (list) => {
    return (
      <React.Fragment>
        {list?.map((value, index) => {
          const InputText = value?.InputText;
          return (
            <React.Fragment key={index}>
              {index === 0 && InputText && (
                <Row className="wrapQuestion mt-2">
                  <div className="col-md-12">
                    <AnswerText {...props} data={InputText || ""} />
                  </div>
                </Row>
              )}
            </React.Fragment>
          );
        })}
      </React.Fragment>
    )
  }
  
  return (
    <div className="feedbackCollapse border">
      <div className={`feedbackCollapse__heading d-flex align-items-center bg-heading ${isOpen ? "border-bottom" : ""}`} onClick={() => onToggle(props)}>
        <AnswerContent {...props} />
        <div className="feedbackCollapse__right">
          {IsCorrect === false && (
            <Badge className="p-2 mr-3 ml-auto" color="danger">Incorrect</Badge>
          )}
          {IsCorrect === true && (
            <Badge className="p-2 mr-3 ml-auto" color="primary">Correct</Badge>
          )}
          <div className="arrowCollapse">
            <button
              aria-expanded={isOpen}
              className={`arrowCollapse__inner`}
            >
            </button>
          </div>
        </div>
      </div>
      <div className={`feedbackCollapse__content ${isOpen ? "show" : "hide"}`}>
        <div className="p-3">
          {listItems(Answers || [])}
        </div>
      </div>
    </div>
  );
}

export default FreeText;