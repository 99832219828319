import React, { useState, useEffect } from "react";
import { Iconsax } from "@components-common";
import { LockView } from "../StandardScreen/InteractAssistant/AssistantQuestion";
import { useSelector, useDispatch } from "react-redux";

//actions
import { actActiveTabsMedicalRecord, toggleChatFeedbackMobile, toggleEMRForModalMobile } from "@actions";

// Popper
import { PopperItem } from "@components/common/Popper";

//Icon
import iconChatFeedback from '@images/chatvoice/icon-chat-feedback.svg';
import IconErm from "@images/learnerv2/icon-task-erm.svg";

// components PC
import { ButtonOnChatScreen } from "@components/learner/StandardScreen/Chat";

// Utility function to detect if the device is mobile
const isMobile = () => {
    return window.innerWidth <= 768; // You can adjust this width threshold as needed
};

function ButtonClosePanel(props) {
    const { handleExamMenu, isCurrentTask, isViewChatFullScreen, handleChatFeedback, handleOpenErmViewFixed } = props && props;
    const dispatch = useDispatch();

    // Case Data
    const caseData = useSelector((state) => state.cases || []);
    const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);
    const InstantChatFeedbackOption = caseData?.data?.Case?.ChatSettings?.InstantChatFeedbackOption;

    const chatRecordFreeText = useSelector((state) => (state?.dataLoadRecordPatient?.data) || []);
    const chatRecordVoice = useSelector((state) => (state?.countChatVoice?.data) || []);

    // Check Lock Elements
    const isChatPending = useSelector((state) => state.isChatPending || {});
    const isLock = isChatPending?.isLock;

    const [showPopper, setShowPopper] = useState(false);
    const [popperDismissed, setPopperDismissed] = useState(false); // Trạng thái khi người dùng tắt popper

    //Reducer
    const isOpen = useSelector((state) => state.typeChatforChatFeedback.isOpen);
    const ShowPatientEMR = caseData?.data?.Case?.Interface?.ShowPatientEMR;

    //Modal - Mobile
    const isOpenChatFeedbackMobile = useSelector((state) => state.chatFeedbackReducer.isOpen);

    useEffect(() => {
        const FREE_TEXT_LIMIT = 6;
        const VOICE_LIMIT = 3;

        const isFreeTextLimitReached = Array.isArray(chatRecordFreeText) && chatRecordFreeText.length === FREE_TEXT_LIMIT;
        const isVoiceLimitReached = Array.isArray(chatRecordVoice) && chatRecordVoice.length === VOICE_LIMIT;

        // Chỉ hiển thị popper nếu đạt điều kiện và chưa bị tắt thủ công
        if ((isFreeTextLimitReached || isVoiceLimitReached) && !popperDismissed) {
            setShowPopper(true);
        } else {
            setShowPopper(false);
        }
    }, [chatRecordFreeText, chatRecordVoice, popperDismissed]);

    const handleClosePopper = () => {
        setShowPopper(false); // Manually close the popper
        setPopperDismissed(true);
    };

    const handleClose = () => {
        handleExamMenu(isCurrentTask, ['closeTask', isViewChatFullScreen]);

        if (statusTabActiveMedicalRecord === "labs") {
            dispatch(actActiveTabsMedicalRecord(null));
        }
    };

    const handleButtonClick = () => {
        // Prevent clicking if isLock is true
        if (isLock) return;

        handleClose();
    };

    return (
        <React.Fragment>
            <div className={`box-closeChat d-flex align-items-center`}>
                {/* Chat with patient */}
                {ShowPatientEMR &&
                    <ButtonOnChatScreen
                        icon={IconErm}
                        text="Review"
                        handleClick={isMobile() ? () => dispatch(toggleEMRForModalMobile(true)) : handleOpenErmViewFixed}
                        isViewMobile={isMobile()}
                    />
                }
                {isOpen === "chat" && (chatRecordFreeText?.length > 0 || chatRecordVoice?.length > 0) && InstantChatFeedbackOption && (
                    <ButtonOnChatScreen
                        icon={iconChatFeedback}
                        text="Chat Feedback"
                        handleClick={() => {
                            if (isMobile()) {
                                dispatch(toggleChatFeedbackMobile(!isOpenChatFeedbackMobile)); // For mobile devices
                            } else {
                                handleChatFeedback(); // For PC/desktop
                            }
                        }}
                        isViewMobile={isMobile()}
                    />
                )}

                <button
                    className={`btn btn-closeChat position-relative ${isMobile() ? 'close-chat-mobile' : 'close-chat-pc'}`}
                    onClick={handleButtonClick}
                    current-task={isCurrentTask ? 'true' : 'false'}
                    style={{
                        width: isMobile() ? '40px' : undefined,
                        height: isMobile() ? '40px' : undefined,
                        borderRadius: isMobile() ? '50%' : undefined
                    }}
                >
                    <Iconsax iconName="close" fill="#D92D20" />
                    {!isMobile() && <span className="btn-closeChat__text">Close Chat</span>}
                    {isLock && <LockView {...props} />}
                </button>
                {showPopper && !isMobile() && (
                    <PopperItem
                        {...props}
                        content={`Click here to close the chat once you've gathered enough information and are ready to proceed to the next task.`}
                        styleInline={{ right: '165px', top: '0', position: 'absolute', maxWidth: '522px', fontSize: '16px' }}
                        postition="right"
                        alignItems="start"
                        isOpen={true}
                        buttonClose={true}
                        icon={<Iconsax iconName="icon-idea" size={32} fill="#F79E1C" />}
                        fontSizeContent="16px"
                        handleClosePopper={handleClosePopper}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default ButtonClosePanel;