import React from "react";
import styled from "styled-components";
import ContentWithLightbox from "./ContentWithLightbox"; // Import component con

const MedicalDescriptionWrapper = styled.div`
  width: 100%;
`;

const TabContent = styled.div`
  border-radius: 0 0 12px 12px;
`;

const MedicalDescriptionBox = ({ narratives = [], activeTab }) => {
  return (
    <MedicalDescriptionWrapper>
      {narratives?.map((narrative) =>
        activeTab === narrative.TimePointId ? (
          <TabContent key={narrative.TimePointId}>
            <ContentWithLightbox narrative={narrative} />
          </TabContent>
        ) : null
      )}
    </MedicalDescriptionWrapper>
  );
};

export default MedicalDescriptionBox;