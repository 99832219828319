import React from 'react';
import { Button } from "reactstrap";

function ActionAddNotes(props) {
    const { toggle } = props && props;
    return (
        <div className="text-right">
            <Button className="--btn-primary" onClick={toggle}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Note
            </Button>
        </div>
    );
};

export default ActionAddNotes;