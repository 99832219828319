import React, { useEffect, useRef, useState } from "react";
import { ButtonCloseERM } from "../ErmView";
import { useSelector } from "react-redux";

function ErmLayoutWrap(props) {
    //props
    const { heading, children, stateType, onHeightChange } = props;

    // Fetch necessary data from Redux store
    const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);
    const questionData = useSelector((state) => state.question || {});
    const isQuestionNull = questionData?.data?.Question === null;

    // Convert heading to HTML
    const headingHTML = { __html: heading };

    // Kiểm tra điều kiện để áp dụng class
    const panelClass = (isQuestionNull && statusTabActiveMedicalRecord) || stateType?.PatientErmFixed ? "erm-full-screen" : "erm-nomarl-screen";

    // Z-index style
    const zIndexStyle = {
        zIndex: (isQuestionNull && statusTabActiveMedicalRecord === 'Chat_Records') || stateType?.PatientErmFixed ? 18000 : 14000
    };

    // Ref and state to capture the height of the panel
    const taskPanelRef = useRef(null);
    const [panelHeight, setPanelHeight] = useState(0);

    // Resize observer to dynamically track panel height changes
    useEffect(() => {
        const panelElement = taskPanelRef.current;

        if (!panelElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    const newHeight = entry.contentRect.height;
                    setPanelHeight(newHeight);

                    // Trigger the callback to pass the height to the parent
                    if (onHeightChange) {
                        onHeightChange(newHeight);
                    }
                }
            }
        });

        resizeObserver.observe(panelElement);

        // Cleanup when the component unmounts
        return () => {
            if (panelElement) {
                resizeObserver.unobserve(panelElement);
            }
        };
    }, [onHeightChange]);

    return (
        <div id={`${panelClass}`} style={zIndexStyle}>
            <div className={`taskPanel-view`} ref={taskPanelRef}>
                <div className="taskPanelCt">
                    <div className="taskPanelCt__heading">
                        <h3 className="taskPanelCt__heading--name" dangerouslySetInnerHTML={headingHTML}></h3>
                        <ButtonCloseERM {...props} />
                    </div>
                    <div className="taskPanelCt__body">
                        {React.cloneElement(children, { panelHeight })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErmLayoutWrap;