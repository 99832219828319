import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormAnalyzeQuestion } from '@components/teachV2/FreeTextQuestion';
import { ResultTextWrap } from '@components/teachV2/FreeTextQuestion/ResultTextQuestion'
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom'
import { Container } from 'reactstrap';

export default function AnalyzeFreeTextPage() {
  let history = useHistory();
  let { idQuestion } = useParams();
  const [loadingResult, setLoadingResult] = useState(false)
  const [dataAnalyze, setDataAnalyze] = useState()
  const [loadingShowForm, setLoadingShowForm] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm()
  
  const showNotificationCaseScript = () => {
    setTimeout(() => {
      toast.success(<div>
        <h1 className='font-weight-bold'>Your free-text question has been successfully created!</h1>
        <p>You can now analyze your learners' answers or modify the question details at any time. This question is stored in the ‘Question Bank’ tab within ‘My Inventory’. To review, edit, or run new analyses, simply click on the Edit icon next to the question.</p>
      </div>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }, 1000)
  }

  const handleUpload = (data) => {
    const params = {
      Action: "Create",
      Type: "free_text",
      QuestionText: data?.question,
      Rubric: {
        instruction_text: data?.rubrics,
      },
      ModelAnswer: data?.answer
    }
    setLoadingResult(true)
    apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
      // const data = res && res.data;
      if (res.status === 200) {
        showNotificationCaseScript()
        history.push(`/teach/free-text/${res?.data?.QuestionItemId}`)
        setLoadingResult(false)
      } else {
        toast.error("Create new free-text question failed!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoadingResult(false)
      }
    });
  };

  useEffect(() => {
    if (idQuestion) {
      const params = {
        Action: "LoadItem",
        QuestionItemId: idQuestion,
      }
      apiCaller(`/api/feedback/question/items/`, 'POST', params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          setDataAnalyze(data)
          setLoadingShowForm(true)
        } else {
          console.log("error");
        }
      })
    } else {
      setLoadingShowForm(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idQuestion])

  const onSubmit = (data) => {
    handleUpload(data);
  }

  return (
    <div className="wrapper -freeTextPage">
      {loadingShowForm && (
        <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
          {!dataAnalyze ? (
            <FormAnalyzeQuestion 
              register={register}
              setValue={setValue}
              handleSubmit={handleSubmit}
              control={control}
              watch={watch}
              loadingResult={loadingResult}
              setLoadingResult={setLoadingResult}
            />
          ) : (
            <Container>
              <ResultTextWrap 
                register={register}
                setValue={setValue}
                control={control}
                watch={watch}
                setDataAnalyze={setDataAnalyze}
                dataAnalyze={dataAnalyze}
              />
            </Container>
          )}
        </form>
      )}
    </div>
  )
}