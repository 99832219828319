import React from 'react';

function ButtonAddCase(props) {
    const { toggleAddCase } = props && props;
    return (
        <button
            className="btn-style2 btn-outline-brand"
            onClick={toggleAddCase}
        >
            <i className="fa fa-plus mr-1" aria-hidden="true"></i> Add Case
        </button>
    );
};

export default ButtonAddCase;