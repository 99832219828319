import React, { useState } from "react";
import iconGg from '@images/common/icon-gg.svg';
import { YOUR_CLIENT_ID } from '@constants/Config';

const LoginBySocial = (props) => {
  const [loading, setLoading] = useState({
    loadingGg: false,
    loadingFb: false,
  });

  const YOUR_REDIRECT_URI = window.location.origin + '/google-callback';

  const getRedirectState = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('redirect');
    return redirect ? encodeURIComponent(redirect) : 'try_sample_request';
  };

  const oauth2SignIn = () => {
    setLoading({
      ...loading,
      loadingGg: true,
    });

    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const authParams = {
      client_id: YOUR_CLIENT_ID,
      redirect_uri: YOUR_REDIRECT_URI,
      scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
      state: getRedirectState(),
      include_granted_scopes: 'true',
      response_type: 'token'
    };
    const queryString = Object.entries(authParams).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
    window.location = `${oauth2Endpoint}?${queryString}`;
  };

  // const responseFacebook = (response) => {
  //   if(response) {
  //     authenticationFacebook(response.accessToken);
  //   }
  // }

  return (
    <div className="social-wrap">
      <div className="social-wrap__gg" onClick={oauth2SignIn}>
        <img src={iconGg} alt="logo-gg" />
        <span className="text-gg">Sign in with Google</span>
      </div>
      {/* <FacebookLogin
        appId="694560059471060"
        autoLoad={false}
        callback={responseFacebook}
        render={renderProps => (
          <div className="m-portlet__social-fb" onClick={renderProps.onClick}>
            <img src={iconFb} alt="logo-fb" />
            <span className="text-fb">Sign in with Facebook</span>
          </div>
        )}
      /> */}
    </div>
  )
}

export default LoginBySocial;