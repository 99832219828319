// iconList.js
import assignmentIcon01 from '@images/new_interface/assignments/heart.svg';
import assignmentIcon02 from '@images/new_interface/assignments/pills.svg';
import assignmentIcon03 from '@images/new_interface/assignments/pulse.svg';
import assignmentIcon04 from '@images/new_interface/assignments/test-tube.svg';

const iconList = [
    assignmentIcon01,
    assignmentIcon02,
    assignmentIcon03,
    assignmentIcon04
];

export default iconList;