import React from "react";
import styled from "styled-components";
import iconClose from "@images/learnerv2/icon-close.png";
import { useScreenSize } from "@context/ScreenSizeContext";

// Styled-components
const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6.25px;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  width: var(--Spacing-16-px, 16px);
  height: var(--Spacing-16-px, 16px);
  padding: 2.5px 4.375px;
  justify-content: center;
  align-items: center;
  gap: 6.25px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #5b5b5b;
`;

const SvgIcon = styled.svg`
  width: 8px;
  height: 10px;
`;

const MessageContainer = styled.div`
  color: #fff;
  text-align: center;
  font-size: ${(props) => (props.isMobile ? "12px" : "14px")};
  font-weight: 500;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
`;

const MessageInChat = () => {
  const { isMobile } = useScreenSize();

  return (
    <MessageWrapper>
      {/* Phần bao icon */}
      <IconWrapper>
        <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 10" fill="none">
          <path
            d="M2.98895 8.62617V8.50241H5.00563V8.62617H4.80163L4.73663 8.96415C4.66672 9.3277 4.36497 9.58326 3.99729 9.58326C3.65317 9.58326 3.32679 9.3083 3.25735 8.96112L3.19036 8.62617H2.98895ZM1.91516 4.84275L1.91516 4.84275L1.91402 4.84137C1.45532 4.29094 1.23308 3.57388 1.30639 2.83389C1.45503 1.52613 2.54459 0.4941 3.84877 0.419731C5.40161 0.346799 6.70713 1.58671 6.70713 3.10886C6.70713 3.73409 6.48642 4.34044 6.08258 4.81771L6.08256 4.81769L6.07942 4.82148C5.71122 5.2672 5.42204 5.7796 5.24062 6.30788H2.75344C2.57101 5.78217 2.28086 5.28544 1.91516 4.84275Z"
            stroke="white"
            strokeWidth="0.833333"
          />
        </SvgIcon>
      </IconWrapper>
      {/* Nội dung tin nhắn */}
      <MessageContainer isMobile={isMobile}>
        <span>Once you feel you've gathered enough information, click the</span>
        <Icon src={iconClose} alt="icon close" />
        <span>icon in the top right to close the chat and proceed to the next task.</span>
      </MessageContainer>
    </MessageWrapper>
  );
};

export default MessageInChat;