import React, { useState, useEffect } from "react";

//router
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";
import { Iconsax } from "@components-common";

function PopperChatFeedback(props) {

    //props
    const {
        styleInline,
        postition,
        handleChatFeedback,
        endChatFeedbackData,
        isChatFeedbackOpen,
        handleCloseInstructorFeedback
    } = props;

    //react-router-dom
    let { id } = useParams();
    let intervalIDRef = React.useRef(null);

    //state
    const [isLoading, setLoading] = useState(false);
    const [contentStream, setStream] = useState(null);
    const [stateResponse, setResponse] = useState(null);
    const textEndChatFeedback = endChatFeedbackData?.Data;
    const hasEndChatFeedbackData = isChatFeedbackOpen?.endChatFeedback;

    // Call Api Stream
    const handleStreamData = (getFeedbackData) => {
        // Neu getFeedbackData chua co ket qua tra ve thi sau 3s goi api nay
        Promise.race([getFeedbackData, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
            if (result !== 'timeout') {
                console.log('API returned before timeout');
                return;
            }

            console.log('API question timed out. Calling API stream_data...');
            const params = {
                stream_mode: "chat"
            }
            intervalIDRef.current = setInterval(() => {
                apiCaller(`/api/learn/case/stream_data/${id}/`, 'PUT', params).then(res => {
                    const dataStream = res?.data;
                    if (res?.status === 200) {
                        setStream(dataStream);
                    } else {
                        // Xoa Stream
                        clearInterval(intervalIDRef.current);
                        intervalIDRef.current = null;
                    }
                });
            }, 1000);
        });
    }

    //Chat - Get Feedback
    useEffect(() => {
        setResponse(null);
        const fetchData = () => {
            setLoading(true);
            let params = { "Action": "GetFeedback" }
            const GetFeedbackPromise = apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200 && data) {
                    setResponse(data);
                    setLoading(false);
                    setStream(null);
                    clearInterval(intervalIDRef.current);
                    intervalIDRef.current = null;
                } else {
                    setLoading(false);
                }
                return data;
            });
            handleStreamData(GetFeedbackPromise);
        }

        if (isChatFeedbackOpen?.chatFeedbackClick && !hasEndChatFeedbackData) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChatFeedbackOpen, hasEndChatFeedbackData])

    return (
        <div className={`tutorial-component flex-column ${postition} ${isChatFeedbackOpen?.chatFeedbackClick || hasEndChatFeedbackData ? "wow fadeInUp animated d-flex" : "hide animated"}`} style={styleInline}>
            <div className="tutorial-component__close" onClick={hasEndChatFeedbackData ? handleCloseInstructorFeedback : handleChatFeedback}>
                <Iconsax iconName="close" fill="#F1800F" size={21} />
            </div>
            <div className="tutorial-component__title">
                <Iconsax iconName="question" size={32} fill="#F79E1C" />
                <span className="ml-2">Chat Feedback</span>
            </div>
            <div  style={{ maxHeight: '400px', overflowY: 'auto' }} className="tutorial-component__content txt-grey whiteSpace-break" dangerouslySetInnerHTML={{ __html: hasEndChatFeedbackData ? textEndChatFeedback : (contentStream || stateResponse?.FeedbackContent) }} />
            {isLoading && <div className="typing-loader my-3 mx-auto"></div>}
        </div>
    );
}

export default PopperChatFeedback;