import React, { useEffect, useState } from "react";

import { FormGroup, CustomInput, Label, Input, Col } from "reactstrap";
import ReactPlayer from "react-player";

//Dropzone
import { useDropzone } from "react-dropzone";

//style
import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const ContainerUpload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const thumb = {
  display: "block",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginTop: 20,
  marginRight: "auto",
  marginLeft: "auto",
  width: "300px",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

function MediaComponent(props) {
  const dataProps = props && props.data;
  const [stateData, setData] = useState(dataProps || {});
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);

  useEffect(() => {
    setData(dataProps || "");
  }, [dataProps]);

  const handleInputUrl = (e) => {
    const { value } = e.target;
    setData(prevState => ({ ...prevState, MediaUrl: value }));
  };

  const handleType = (e) => {
    const value = e.target.value;

    //Reset Data Not Type Current
    if(value !== stateData && stateData.MediaType) {
      setData(prevState => ({ ...prevState, MediaUrl: "" }));
      setAudioData([]);
      setImageData([]);
    } else {
      setData(prevState => ({ ...prevState, MediaUrl: (stateData && stateData.MediaUrl) }));
    }
    setData(prevState => ({ ...prevState, MediaType: value }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      //Set Image Preview
      acceptedFiles.map((file) =>
        setData(prevState => ({ ...prevState, MediaUrl: URL.createObjectURL(file) }))
      )

      //Set File FormData
      acceptedFiles.map((file) =>
        setImageData(file)
      )
    }
  });

  const onFileAudioUpload = (event) => {
    const fileAudio = event.target.files[0];
    const urlPreview = URL.createObjectURL(fileAudio);
    setData(prevState => ({ ...prevState, MediaUrl: urlPreview }));
    setAudioData(event.target.files);
  };

  //stateData
  // console.log(stateData, "stateData.....");
  console.log(imageData, "imageData.....");
  console.log(audioData, "audioData.....");
  return (
    <div className="mediaContainer">
      <FormGroup row>
        <Label className="text-left" for="MediaType" sm={3}>
          Media type
        </Label>
        <Col sm={9}>
          <Input
            type="select"
            value={(stateData && stateData.MediaType) || ""}
            name="MediaType"
            id="MediaType"
            onChange={handleType}
          >
            <option value="">Select media type...</option>
            <option value="ImageFile">Image</option>
            <option value="VideoLink">Youtube</option>
            <option value="AudioFile">Audio</option>
          </Input>
        </Col>
      </FormGroup>
      {stateData && stateData.MediaType === "VideoLink" && (
        <React.Fragment>
          <FormGroup row>
            <Label className="text-left" for="Image" sm={3}>
              Url Youtube
            </Label>
            <Col sm={9}>
              <div className="d-flex align-items-center">
                <Input
                  type="url"
                  name="videoLink"
                  id="url"
                  defaultValue={(stateData && stateData.MediaUrl) || ""}
                  onChange={handleInputUrl}
                  placeholder=""
                />
              </div>
            </Col>
          </FormGroup>
          {(stateData && stateData.MediaUrl) &&
            <div className="playerWrapper">
              <ReactPlayer
                url={(stateData && stateData.MediaUrl) || ""}
                name="videoLink"
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          }
        </React.Fragment>
      )}
      {stateData && stateData.MediaType === "ImageFile" && (
        <React.Fragment>
          <ContainerUpload {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </ContainerUpload>
          {(stateData && stateData.MediaUrl) &&
            <div style={thumb}>
              <div style={thumbInner}>
                <img src={(stateData && stateData.MediaUrl) || ""} style={img} alt="#/" />
              </div>
            </div>
          }
        </React.Fragment>
      )}
      {stateData && stateData.MediaType === "AudioFile" && (
        <React.Fragment>
          <FormGroup row>
            <Label className="text-left" for="AudioFile" sm={3}>
              File Audio
            </Label>
            <Col sm={9}>
              <CustomInput
                id="AudioFile"
                onChange={onFileAudioUpload}
                className="text-left"
                type="file"
                name="AudioFile"
                accept="audio/*"
              />
            </Col>
          </FormGroup>
          {(stateData && stateData.MediaUrl) &&
            <ReactPlayer
              url={(stateData && stateData.MediaUrl) || ""}
              playing
              width="100%"
              height="50px"
              controls={true}
            />
          }
        </React.Fragment>
      )}
      <hr />
    </div>
  );
}

export default MediaComponent;
