import React, { useState, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { advanceConstants } from "@constants";
import { CongratulationsContent } from "../default";
import { ModalResetCase } from '@components/common/ResetAndBack';

function DefaultEndCase(props) {
  const { isOpen } = props || {};
  let history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleCaseCompleted = useCallback(async () => {
    setLoading(true);
    let params = { "CaseId": id };
    dispatch({ type: advanceConstants.CASE_COMPLETE_REQUEST, isLoading: true });

    try {
      const res = await apiCaller(`/api/learn/case/complete/`, 'POST', params);

      if (res?.status === 200) {
        const data = res?.data;
        if (data?.status === "success" && data?.LearnerSessionId) {
          dispatch({ type: advanceConstants.CASE_COMPLETE_SUCCESS, payload: data });
          return true; // Return true on success
        } else {
          dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "error" });
        }
      } else {
        dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "Failed to complete case" });
      }
    } catch (error) {
      dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: error.message });
    } finally {
      setLoading(false);
    }

    return false; // Return false if the process did not succeed
  }, [id, dispatch]);

  const handleExitCase = async () => {
    const isCompleted = await handleCaseCompleted();

    if (isCompleted) {
      if (ButtonExit?.CaseId) {
        window.location.href = `/learn/case/${ButtonExit.CaseId}`;
      } else {
        history.push("/learn/home");
      }
    }
  };

  const handleRedirect = () => {
    if (ButtonRedirect?.Url) {
      window.location.href = ButtonRedirect.Url; // Redirect to the specified URL
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  // Render Button
  const renderButton = (button, color, onClick, loading = false) => {
    if (!button) return null;

    return (
      <Button
        color={color}
        className={`my-2 -btn-lg w-100 -shadown ${color === 'white' ? 'btn-white-sm' : 'btn-brand-sm'}`}
        onClick={onClick}
        disabled={loading}
      >
        {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
        <div dangerouslySetInnerHTML={{ __html: button.Label || "Exit and Back to Home" }} />
      </Button>
    );
  };

  // Get data from store
  const caseData = useSelector((state) => state.cases || []);
  const caseResponse = caseData?.data;
  const EndCaseViewDetails = caseResponse?.Case?.EndCaseViewDetails;
  const ButtonExit = EndCaseViewDetails?.ButtonExit;
  const ButtonReset = EndCaseViewDetails?.ButtonReset;
  const ButtonRedirect = EndCaseViewDetails?.ButtonRedirect;

  return (
    <React.Fragment>
      <Modal
        className="modal-custom"
        zIndex={16000}
        isOpen={isOpen}
        backdrop={`static`}
        centered
        backdropClassName="modal-backdrop-custom"
        modalTransition={{ timeout: 700 }}
      >
        <ModalBody>
          <CongratulationsContent {...props} />
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto" style={{ width: "276px" }}>
            {ButtonExit && renderButton(ButtonExit, 'primary', handleExitCase, loading)}
            {ButtonRedirect && renderButton(ButtonRedirect, 'primary', handleRedirect)}
            {ButtonReset && renderButton(ButtonReset, 'white', toggle)}
          </div>
        </ModalFooter>
      </Modal>
      <ModalResetCase
        {...props}
        isOpen={modal}
        toggle={toggle}
        isView="case"
      />
    </React.Fragment>
  );
}

export default DefaultEndCase;