import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function CopyForwardModal(props) {
  const { isOpen, toggle, handleUpdateActions, data } = props && props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody>
        <div className="m-3">
          <p className="m--font-brand text-center mb-3 font-weight-normal --text-primary">
            Are you certain that you would like to copy forward the PN orders
            from the previous time point?
          </p>
          <p className="text-dark font-weight-normal">
            All current PN orders, if any, will be discarded in the process.
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="--btn-primary"
          disabled={data.isLoading}
          onClick={() => handleUpdateActions("copy_forward")}
        >
          Copy Forward
        </Button>{" "}
        <Button
          className="btn btn-outline-brand --btn-ouline-primary"
          onClick={toggle}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CopyForwardModal;
