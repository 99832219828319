import React from 'react';
import { Icon } from '@components/common';
import Error from '@images/login/error.svg';
import Success from '@images/login/success.svg';
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";

const ModalResultSetPassword = (props) => {
  let history = useHistory();
  const { dataMessage } = props;

  const handleRestart = () => {
    // Redirect to the password reset start page or any specific reset URL
    history.push('/auth/reset-password');
  };

  return (
    <div className="modal-completed-form">
      <div className="modal-completed-form__icon">
        {dataMessage?.type === 'error' ? (
          <Icon src={Error} />
        ) : (
          <Icon src={Success} />
        )}
      </div>
      <div className='modal-completed-form__content text-center'>
        {dataMessage?.title && (
          <p>{dataMessage?.title}</p>
        )}
        <div dangerouslySetInnerHTML={{ __html: dataMessage?.message }} />
      </div>
      <div className='modal-completed-form__action'>
        {dataMessage?.type === 'error' ? (
          <>
            <Button
              color='secondary'
              className='modal-completed-form__action-btn'
              onClick={() => history.push('/login')}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              className='modal-completed-form__action-btn'
              onClick={handleRestart}
            >
              Restart Password Reset
            </Button>
          </>
        ) : (
          <Button
            color='primary'
            className='modal-completed-form__action-btn'
            onClick={() => history.push('/login')}
          >
            Go to Login
          </Button>
        )}
      </div>
    </div>
  );
};

export default ModalResultSetPassword;