import React from "react";

//component common
import { ItemRow } from '../../timePointActions';

function TableVitalSignsLast (props) {
  const timepoint = props && props.timepointData;
  const vitalSigns = timepoint && timepoint.data && timepoint.data.DefaultVitalSigns;
  const Header = [
    {name: "Item", className: "text-center", styles : {'width' : '25%'}},
    {name: "Initial Value", className: "text-center", styles : {'width' : '20%'}},
    {name: "Change At This Time Point", className: "text-center", styles : {'width' : '25%'}},
    {name: "", className: ""},
    {name: "", className: ""}
  ];

  return (
    <table className="table" width="100%">
      <thead>
        <tr>
        {Header && Header.map((item, index) => (
          <th key={index} style={item.styles} className={item.className}>
            {item.name}
          </th>
        ))}
        </tr>
      </thead>  
      <tbody>
        <ItemRow {...props} data={vitalSigns || []} />
      </tbody>
    </table>
  );
}

export default TableVitalSignsLast;
