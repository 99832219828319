
//Kachi Health
import { KachiHealthLogin, KachiHealthRegister } from "@pages/med2lab/kachiHealth";

const kachiRoutes = [
    {
        path: "/kachi-health/register",
        exact: true,
        classPage: '',
        component: KachiHealthRegister,
        isLayout: false,
    },
    {
        path: "/kachi-health/login",
        exact: true,
        classPage: '',
        component: KachiHealthLogin,
        isLayout: false,
    }
];

export { kachiRoutes };