import React, { useState, useEffect } from "react";

//Boostrap
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

// Chat
import { FrameChat, ModalShowPrompt } from "../AIChat";

//untils
import { apiCaller } from "@utils";

function ModalViewChat(props) {
    const { isOpen, toggle, rowRecord } = props && props;

    //state
    const [stateData, setData] = useState(rowRecord || {});
    const [isLoading, setLoading] = useState(false);
    const [nestedModal, setNestedModal] = useState(false);
    const [closeAll, setCloseAll] = useState(false);

    //load state
    useEffect(() => {
        setData(rowRecord || [])
    }, [rowRecord])

    // Handle Update Status
    const handleUpdateStatus = (e, idx, itemRow) => {
        const { checked } = e.target;
        let newData = { ...stateData };
        newData[`dialog`][itemRow.dialog_idx][`human_label`][idx][`status`] = checked;
        setData(newData);
    }

    //Handle Save Chat Id
    const handleSave = () => {
        setLoading(true);
        const params = {
            "action_type": "update_chatdata",
            "id": stateData?.id,
            "dialog": stateData?.dialog
        }
        apiCaller(`/api/binh/openai/`, "POST", params).then((res) => {
            if (res?.status === 200) {
                console.log('success');
                setLoading(false);
                toggle();
            } else {
                console.log('error');
                setLoading(false);
                toggle();
            }
        });
    }

    // Handle Show Prompt 
    const toggleNested = () => {
        setNestedModal(!nestedModal);
        setCloseAll(false);
    };

    const toggleAll = () => {
        setNestedModal(!nestedModal);
        setCloseAll(true);
    };

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{ maxWidth: '1200px' }}>
                <ModalHeader toggle={toggle}>
                    Chat
                </ModalHeader>
                <ModalBody>
                    <FrameChat
                        {...props}
                        handleUpdateStatus={handleUpdateStatus}
                        data={stateData}
                    />
                    <ModalShowPrompt
                        isOpen={nestedModal}
                        toggle={toggleNested}
                        onClosed={closeAll ? toggle : undefined}
                        toggleAll={toggleAll}
                        data={stateData}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="btn btn-success mr-2" onClick={toggleNested}>
                        Show Prompt
                    </Button>
                    <Button className="btn --btn-primary mr-2" onClick={handleSave}>
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                    <Button className="btn btn-secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default ModalViewChat;