export const managersConstant = {
    LOAD_MANAGERS_REQUEST: 'LOAD_MANAGERS_REQUEST',
    LOAD_MANAGERS_SUCCESS: 'LOAD_MANAGERS_SUCCESS',
    LOAD_MANAGERS_FAILURE: 'LOAD_MANAGERS_FAILURE',

    ADD_MANAGERS_REQUEST: 'ADD_MANAGERS_REQUEST',
    ADD_MANAGERS_SUCCESS: 'ADD_MANAGERS_SUCCESS',
    ADD_MANAGERS_FAILURE: 'ADD_MANAGERS_FAILURE',

    REMOVE_MANAGERS_REQUEST: 'REMOVE_MANAGERS_REQUEST',
    REMOVE_MANAGERS_SUCCESS: 'REMOVE_MANAGERS_SUCCESS',
    REMOVE_MANAGERS_FAILURE: 'REMOVE_MANAGERS_FAILURE',

    LOAD_CANDIDATES_FOR_MANAGERS_REQUEST: 'LOAD_CANDIDATES_FOR_MANAGERS_REQUEST',
    LOAD_CANDIDATES_FOR_MANAGERS_SUCCESS: 'LOAD_CANDIDATES_FOR_MANAGERS_SUCCESS',
    LOAD_CANDIDATES_FOR_MANAGERS_FAILURE: 'LOAD_CANDIDATES_FOR_MANAGERS_FAILURE',
    
    CASE_MANAGERS_ADD_REQUEST: 'CASE_MANAGERS_ADD_REQUEST',
    CASE_MANAGERS_ADD_SUCCESS: 'CASE_MANAGERS_ADD_SUCCESS',
    CASE_MANAGERS_ADD_FAILURE: 'CASE_MANAGERS_ADD_FAILURE'
};