import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Input, FormFeedback } from "reactstrap";

function OtpForm({ onSubmit, errors }) {
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [isFilled, setIsFilled] = useState(false);

    // Kiểm tra xem đã đổ đủ dữ liệu vào tất cả các ô input hay chưa
    useEffect(() => {
        const filled = otp.every(digit => digit !== "");
        setIsFilled(filled);
    }, [otp]);

    // Hàm xử lý sự kiện khi người dùng thay đổi giá trị trong ô input
    const handleInputChange = (e, index) => {
        // Lấy giá trị mới từ ô input và giới hạn chiều dài là 1 ký tự
        const inputOtp = e.target.value.substring(0, 1);
        // Tạo một bản sao của mảng otp để cập nhật giá trị mới
        const newOtp = [...otp];
        // Cập nhật giá trị mới vào vị trí tương ứng trong mảng
        newOtp[index] = inputOtp;
        // Cập nhật state với giá trị mới
        setOtp(newOtp);
    };

    // Hàm xử lý sự kiện khi người dùng paste vào ô input
    const handleInputPaste = (e) => {
        // Ngăn chặn sự kiện mặc định của việc paste
        e.preventDefault();
        // Lấy nội dung được paste
        const pastedData = e.clipboardData.getData("text");
        // Kiểm tra nếu dữ liệu paste là một chuỗi 6 số
        if (/^\d{6}$/.test(pastedData)) {
            // Tạo một mảng từ chuỗi số và cập nhật state với mảng này
            setOtp(pastedData.split("").slice(0, 6));
        }
    };

    return (
        <Form onSubmit={onSubmit}>
            <div className="mx-auto" style={{maxWidth: "380px"}}>
                <FormGroup className="py-4">
                    <div className="list-otp">
                        {/* Duyệt qua từng ô input trong mảng otp và render */}
                        {otp.map((digit, index) => (
                            <Input
                                key={index}
                                type="text"
                                name={`otp${index + 1}`}
                                id={`otp${index + 1}`}
                                className="otp-input"
                                maxLength={1} // Giới hạn chiều dài của ô input là 1 ký tự
                                value={digit} // Giá trị của ô input là giá trị tương ứng trong mảng otp
                                onChange={(e) => handleInputChange(e, index)} // Gọi hàm xử lý sự kiện khi ô input thay đổi
                                onPaste={handleInputPaste} // Gọi hàm xử lý sự kiện khi người dùng paste vào ô input
                            />
                        ))}
                    </div>
                    {/* Hiển thị thông báo lỗi nếu có */}
                    {errors.otp && <FormFeedback invalid>{errors.otp.message}</FormFeedback>}
                </FormGroup>
                {/* Nút gửi form */}
                <Button color="primary" className="btn-brand-sm -btn-lg w-100 -shadown" type="submit" disabled={!isFilled}>
                    Resend code in <span className="ml-2" id="resend-timer">29</span> seconds
                </Button>
            </div>
            <div className="text-center mt-2">
                {/* Link để gửi lại mã OTP nếu cần */}
                <span>Didn't receive the code? </span>
                <span id="resend-link" className="link-redirect" onClick={() => {
                    // Thực hiện logic gửi lại mã OTP ở đây (có thể cập nhật timer)
                    console.log("Resend code clicked");
                }}>
                    Resend code
                </span>
            </div>
        </Form>
    );
}

export default OtpForm;