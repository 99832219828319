import React from "react";

//Table
import { TableFoods } from "@components/Makansafe/Home";

function HomeMakansafePage(props) {
    return (
        <div className="makansafe-homepage">
            <div className="boxPortlet">
                <TableFoods {...props} />
            </div>
        </div>
    );
}

export default HomeMakansafePage;