import React, { createContext, useState, useEffect } from 'react';

// Create context
export const DeviceContext = createContext();

// Device detection function
const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for iOS (iPhone, iPad, iPod)
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    // Check for Android
    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // Check for Windows Phone
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    // If it's neither, return unknown
    return "unknown";
};

// Function to check if it's a smartphone or tablet
const isSmartphoneOrTablet = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if it's an iPhone or iPad
    if (/iPhone/i.test(userAgent)) {
        return "iPhone";
    }
    if (/iPad/i.test(userAgent)) {
        return "iPad";
    }

    // Check for Android devices
    if (/Android/i.test(userAgent)) {
        // For Android, check if the user agent indicates a tablet or smartphone
        const isTablet = /Tablet|Nexus 7|Nexus 10/.test(userAgent);
        return isTablet ? "Android Tablet" : "Android Phone";
    }

    return "unknown";
};

// DeviceContext Provider component
export const DeviceProvider = ({ children }) => {
    const [device, setDevice] = useState('unknown');
    const [deviceType, setDeviceType] = useState('unknown');

    useEffect(() => {
        const os = getMobileOperatingSystem();
        const type = isSmartphoneOrTablet();
        setDevice(os);
        setDeviceType(type);
    }, []);

    return (
        <DeviceContext.Provider value={{ device, deviceType }}>
            {children}
        </DeviceContext.Provider>
    );
};