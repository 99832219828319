export const wizardConstants = {
  LOAD_WIZARD_REQUEST: 'LOAD_WIZARD_REQUEST',
  LOAD_WIZARD_SUCCESS: 'LOAD_WIZARD_SUCCESS',
  LOAD_WIZARD_FAILURE: 'LOAD_WIZARD_FAILURE',

  LOAD_NEXT_STEP_WIZARD_REQUEST: 'LOAD_NEXT_STEP_WIZARD_REQUEST',
  LOAD_NEXT_STEP_WIZARD_SUCCESS: 'LOAD_NEXT_STEP_WIZARD_SUCCESS',
  LOAD_NEXT_STEP_WIZARD_FAILURE: 'LOAD_NEXT_STEP_WIZARD_FAILURE',

  REMOVE_NEXT_STEP_REFERENCE_SUCCESS: 'REMOVE_NEXT_STEP_REFERENCE_SUCCESS',
  REMOVE_NEXT_STEP_WIZARD_SUCCESS: 'REMOVE_NEXT_STEP_WIZARD_SUCCESS',
  REMOVE_NEXT_STEP_MEDIA_SUCCESS: 'REMOVE_NEXT_STEP_MEDIA_SUCCESS',

  IMAGE_GENERATION_REQUEST: 'IMAGE_GENERATION_REQUEST',
  IMAGE_GENERATION_SUCCESS: 'IMAGE_GENERATION_SUCCESS',
  IMAGE_GENERATION_FAILURE: 'IMAGE_GENERATION_FAILURE',
}