import React from 'react';
import styled from "styled-components"
import { ReactSVG } from 'react-svg'

const Image = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;   
  }

  svg {
    height: ${(props) => (props.height ? `${props.height}px` : "")};
    width: ${(props) => (props.width ? `${props.width}px` : "")};
  }

  path {
    fill: ${(props) => (props.color ? `${props.color}` : "")};
    stroke: ${(props) => (props.stroke ? `${props.stroke}` : "")};
  }
`

const Icon = (props) => {
  return (
    <Image color={props.color} stroke={props.stroke} height={props.height} width={props.width}>
      <ReactSVG src={props.src}/>
    </Image>
  )
}

export default Icon