import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import imgLogo from "@images/ucr/logo.png";
import { Link } from "react-router-dom";

//config
import * as Config from "@constants/Config";

//boostrap
import { Row, Col, FormGroup, Input, FormFeedback } from "reactstrap";

//components
import { EmailConfirm } from '../auth';

//react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";

//untils
import { checkAuth } from "@utils/";

function FormForGotPass(props) {
  let history = useHistory();

  //props
  const { handleView } = props && props;

  //state
  const [stateEmail, setStateEmail] = useState("");
  const [stateValid] = useState(false);
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [stateMessage] = useState(
    "Either your email is not valid. Please try again."
  );

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setStateEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(true);
  };

  const onChangeCaptcha = (value) => {
    setTokenCaptcha(value);
  };

  return (
    <div className="m-portlet m-portlet--mobile">
      <form method="post" onSubmit={handleSubmit}>
        <div className="m-portlet__body">
          <div className="text-center mb-5">
            <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
          </div>
          <h4 className="headingForm">Reset Password</h4>
          <Row>
            {isSuccess ? (
              <EmailConfirm />
            ) : (
              <Col md={10} className="mx-auto">
                <FormGroup>
                  <Input
                    type="email"
                    onChange={handleChange}
                    bsSize="lg"
                    value={stateEmail}
                    placeholder="Enter your email address"
                    name="email"
                    invalid={stateValid.toString()}
                  />
                  {stateValid && (
                    <FormFeedback className="text-2 mt-3">
                      {stateMessage}
                    </FormFeedback>
                  )}
                </FormGroup>

                <Col md={12} className="mt-2">
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey={Config.KEY_CAPTCHA}
                      onChange={onChangeCaptcha}
                    />
                  </div>
                  {!tokenCaptcha && (
                    <FormFeedback className="d-block text-center">
                      This field is required.
                    </FormFeedback>
                  )}
                </Col>
              </Col>
            )}
          </Row>
        </div>
        <div className="m-portlet__foot">
          <Row className="text-center">
            {!isSuccess &&
              <button
                on
                type="submit"
                className="btn m-btn--pill btn-lg px-4 mx-auto my-4"
                style={{ minWidth: "200px" }}
              >
                Send password reset email
              </button>
            }
            <Col md={12} className="text-center">
              <Link
                className="m--font-brand"
                onClick={handleView}
                to={`/ucr/login`}
              >
                Back to Log in Page
              </Link>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default FormForGotPass;
