import React, { useState } from "react";

//reactstrap
import { Button } from "reactstrap";

//components
import { ModalAddOther } from '../TabsGroupSchema';

function AddOtherQuestions(props) {

    //state
    const [modal, setModal] = useState({ add: false });

    //toggle
    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };
    
    return (
        <React.Fragment>
            <div className="text-right mb-4">
                <Button className="--btn-primary" onClick={() => toggle("add")}>
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i> Add Other Questions
                </Button>
            </div>
            <ModalAddOther
                {...props}
                isOpen={modal.add}
                toggle={() => toggle("add")}
            />
        </React.Fragment>
    );
}

export default AddOtherQuestions;