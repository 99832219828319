import React from 'react';

// context
import { useScreenSize } from '@context/ScreenSizeContext';

const LearnMainLayout = ({ children }) => {
  // Use screen size context
  const { isMobile } = useScreenSize();

  return (
    <div className={`learner-main-inner ${isMobile ? 'is-mobile' : ''}`}>
      {children}
    </div>
  );
};

export default LearnMainLayout;