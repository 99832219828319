import React from "react";

//reactstrap
import { Card, CardBody, CardHeader } from "reactstrap";

//components
import { DomainTable } from '../Domains';

//loader
import { LoaderText } from "@utils";

function DomainContent(props) {

  //props
  const { itemActive, isLoading } = props && props;
  const domainName = itemActive?.Name;
  const domainId = itemActive?.Id;

  return (
    <Card className="border-0 -box-shadow position-relative">
      {isLoading &&
        <LoaderText />
      }
      <CardHeader className="bg-white --text-primary font-weight-500">
        {domainName} (#{domainId})
      </CardHeader>
      <CardBody>
        <DomainTable {...props} />
      </CardBody>
    </Card>
  );
}

export default DomainContent;
