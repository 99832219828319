import React, { useState } from "react";
import ModalDeleteCommon from "./ModalDeleteCommon";

const WrapModalDeleteCommon = ({ handleRemove, idRemove, children, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () =>  {
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const handleActionRemove = async () => {
    await handleRemove(idRemove)
    handleClose()
  }

  return (
    <>
      {children({ onOpen: handleOpen })}
      {open && 
        <ModalDeleteCommon
          isOpen={open}
          toggle={handleClose}
          handleRemove={handleActionRemove}
          {...props}
        >
        </ModalDeleteCommon>
      }
    </>
  )
}

export default WrapModalDeleteCommon
