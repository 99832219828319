import React from "react";

//boostrap
import { Label, FormGroup, Input } from "reactstrap";

function ToBeShowedOptions(props) {
    //props
    const { handleChange, typeRadio, index } = props && props;

    return (
        <FormGroup>
            <Label for="ToBeShowed" >
                To be showed to:
            </Label>
            <div className="flex-column pl-4">
                <Label check>
                    <Input
                        type="radio"
                        name={`typeRadio_${index}`}
                        onClick={handleChange}
                        value="allCases"
                        defaultChecked={typeRadio === 'allCases'}
                    />{" "}
                    All users loading all cases for first time
                </Label>
                <Label check>
                    <Input
                        type="radio"
                        name={`typeRadio_${index}`}
                        onClick={handleChange}
                        value="following"
                        defaultChecked={typeRadio === 'following'}
                    />{" "}
                    All users loading following cases for first time
                </Label>
            </div>
        </FormGroup>
    );
};

export default ToBeShowedOptions;