import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

function ModulesTop(props) {
  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col sm={4} className="text-center">
          <Link
            to="/teach/new/step1"
            className={`btn btn-primary text-nowrap d-flex align-items-center`}
          >
            <i className="fa fa-plus mr-2" aria-hidden="true"></i>
            Create New Case
          </Link>
        </Col>
        <Col sm={8} className="mt-3 mt-sm-0">
          <p>
            <span className="--text-primary font-weight-600">
              Have a new case in mind for your learners ?
              <br />
            </span>
            Click on the button on the left to quickly set up a new case from
            existing templates!
          </p>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <hr />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ModulesTop;
