import React, { useState } from "react";
import { QuestionFeedbackRow } from "../QuestionFeedback";
import { ChatFeedbackMain } from "../ChatFeedback";

//utils
import { LoaderText } from "@utils";
import { DataEmty } from "@components-common";

//Tabs
import { TabForDomains, TabsForQuestion } from "../../FeedbackV2";

const QuestionFeedbackMain = ({ feedbackData }) => {
    const initialDomainTab = 1; // Mặc định chọn domain đầu tiên
    const [activeDomainTab, setActiveDomainTab] = useState(initialDomainTab);
    const [activeQuestionTab, setActiveQuestionTab] = useState(null);

    if (!feedbackData || !Array.isArray(feedbackData.Domains)) {
        return <LoaderText />;
    }

    const domainData = feedbackData?.Domains?.map((domain, index) => {
        const questions = domain.Questions?.map((question) => ({
            key: question.QuestionId,
            label: `Question ${question.QuestionId}`,
            content: <QuestionFeedbackRow question={question} key={question.QuestionId} />,
        })) || [];

        // Nếu có Chat.ChatDialog, thêm tab Chat vào đầu danh sách questions
        if (domain.Chat?.ChatDialog) {
            questions.unshift({
                key: 'chat',
                label: 'Chat',
                content: <ChatFeedbackMain ChatData={domain.Chat} key="chat" />
            });
        }

        return {
            key: index + 1,
            label: domain.DomainName,
            questions: questions,
        };
    });

    if (domainData?.length === 0) {
        return <DataEmty content="Sorry, no data is available. Please try again later." />;
    }

    const initialDomain = domainData.find((domain) => domain.key === initialDomainTab);
    const initialQuestionTab = initialDomain?.questions?.[0]?.key || null;
    if (activeQuestionTab === null) {
        setActiveQuestionTab(initialQuestionTab);
    }

    const activeDomain = domainData.find((domain) => domain.key === activeDomainTab);
    return (
        <div className="position-relative">
            <div className="domains">
                <TabForDomains
                    tabs={domainData.map(({ key, label }) => ({ id: key, title: label }))}
                    activeTab={activeDomainTab}
                    onTabChange={(tabId) => {
                        setActiveDomainTab(tabId);
                        const newActiveDomain = domainData.find((domain) => domain.key === tabId);
                        setActiveQuestionTab(newActiveDomain?.questions?.[0]?.key || null);
                    }}
                />
            </div>
            <div className="questions">
                {activeDomain?.questions?.length > 0 ? (
                    <TabsForQuestion
                        tabs={activeDomain.questions.map(({ key, label, content }) => ({
                            id: key,
                            title: label,
                            content,
                        }))}
                        activeTab={activeQuestionTab}
                        onTabChange={setActiveQuestionTab}
                    />
                ) : (
                    <p>No questions available in this domain.</p>
                )}
            </div>
        </div>
    );
};

export default QuestionFeedbackMain;