export const actionConstants = {
    LOAD_ACTIONS_TO_ACCORDION_REQUEST: 'LOAD_ACTIONS_TO_ACCORDION_REQUEST',
    LOAD_ACTIONS_TO_ACCORDION_SUCCESS: 'LOAD_ACTIONS_TO_ACCORDION_SUCCESS',
    LOAD_ACTIONS_TO_ACCORDION_FAILURE: 'LOAD_ACTIONS_TO_ACCORDION_FAILURE',

    CREATE_INTERVENTION_FOR_ACTIONS_REQUEST: 'CREATE_INTERVENTION_FOR_ACTIONS_REQUEST',
    CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    CREATE_INTERVENTION_FOR_ACTIONS_FAILURE: 'CREATE_INTERVENTION_FOR_ACTIONS_FAILURE',

    UPDATE_INTERVENTION_FOR_ACTIONS_REQUEST: 'UPDATE_INTERVENTION_FOR_ACTIONS_REQUEST',
    UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    UPDATE_INTERVENTION_FOR_ACTIONS_FAILURE: 'UPDATE_INTERVENTION_FOR_ACTIONS_FAILURE',

    DELETE_INTERVENTION_FOR_ACTIONS_REQUEST: 'DELETE_INTERVENTION_FOR_ACTIONS_REQUEST',
    DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    DELETE_INTERVENTION_FOR_ACTIONS_FAILURE: 'DELETE_INTERVENTION_FOR_ACTIONS_FAILURE',

    UPDATE_AVAILABILITY_FOR_ACTIONS_REQUEST: 'UPDATE_AVAILABILITY_FOR_ACTIONS_REQUEST',
    UPDATE_AVAILABILITY_FOR_ACTIONS_SUCCESS: 'UPDATE_AVAILABILITY_FOR_ACTIONS_SUCCESS',
    UPDATE_AVAILABILITY_FOR_ACTIONS_FAILURE: 'UPDATE_AVAILABILITY_FOR_ACTIONS_FAILURE',

    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_REQUEST: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_REQUEST',
    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_SUCCESS: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_SUCCESS',
    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_FAILURE: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_FAILURE',
    
    CREATE_ACTION_CATEGORY_REQUEST: 'CREATE_ACTION_CATEGORY_REQUEST',
    CREATE_ACTION_CATEGORY_SUCCESS: 'CREATE_ACTION_CATEGORY_SUCCESS',
    CREATE_ACTION_CATEGORY_FAILURE: 'CREATE_ACTION_CATEGORY_FAILURE',

    UPDATE_ACTION_CATEGORY_REQUEST: 'UPDATE_ACTION_CATEGORY_REQUEST',
    UPDATE_ACTION_CATEGORY_SUCCESS: 'UPDATE_ACTION_CATEGORY_SUCCESS',
    UPDATE_ACTION_CATEGORY_FAILURE: 'UPDATE_ACTION_CATEGORY_FAILURE',
    
    DELETE_ACTION_CATEGORY_REQUEST: 'DELETE_ACTION_CATEGORY_REQUEST',
    DELETE_ACTION_CATEGORY_SUCCESS: 'DELETE_ACTION_CATEGORY_SUCCESS',
    DELETE_ACTION_CATEGORY_FAILURE: 'DELETE_ACTION_CATEGORY_FAILURE',

    UPDATE_ATTRIBUTE_SCORE_ACTION_REQUEST: 'UPDATE_ATTRIBUTE_SCORE_ACTION_REQUEST',
    UPDATE_ATTRIBUTE_SCORE_ACTION_SUCCESS: 'UPDATE_ATTRIBUTE_SCORE_ACTION_SUCCESS',
    UPDATE_ATTRIBUTE_SCORE_ACTION_FAILURE: 'UPDATE_ATTRIBUTE_SCORE_ACTION_FAILURE',

    UPDATE_AVAILABLE_IN_BATCH_ACTION_REQUEST: 'UPDATE_AVAILABLE_IN_BATCH_ACTION_REQUEST',
    UPDATE_AVAILABLE_IN_BATCH_ACTION_SUCCESS: 'UPDATE_AVAILABLE_IN_BATCH_ACTION_SUCCESS',
    UPDATE_AVAILABLE_IN_BATCH_ACTION_FAILURE: 'UPDATE_AVAILABLE_IN_BATCH_ACTION_FAILURE',
};