import React, { useState, useEffect } from "react";
import { DragQuestion } from '../QuestionCommon';

// lodash
import _ from "lodash";

// redux
import { useSelector, useDispatch } from "react-redux";

//actions
import { actActiveViewQuestion } from "@actions";

// components
import { AddQuestionButton, CreateUpdateQuestion } from "../QuestionCommon";

function QuestionMain(props) {
    //dispatch
    const dispatch = useDispatch();

    // props
    const { activeViewClass, timepointData } = props;

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    // state
    const [itemQuestion, setItemQuestion] = useState(null);

    const filterQuestionForTask = (currentTask) => {
        // filter questions asked at the current task
        const filterData = _.filter(timepointData?.Questions, (value) => value.AskedAtTask === currentTask);
        return filterData;
    };

    // Handle Click Add Question
    const handleActionView = (type) => {
        dispatch(actActiveViewQuestion(type));
    };

    // Handle Click Back
    const handleClickBack = () => {
        dispatch(actActiveViewQuestion("List_Question"));
        setItemQuestion(null);
    };

    // Handle Edit Question
    const handleEditQuestion = (type, items) => {
        dispatch(actActiveViewQuestion(type));
        setItemQuestion(items);
    };

    // Reset itemQuestion when questionViewActive changes
    useEffect(() => {
        questionViewActive === "Blank_Question" && setItemQuestion(null);
    }, [questionViewActive]);

    const questionByTask = filterQuestionForTask(activeViewClass);
    return (
        <div key={questionViewActive}>
            {questionViewActive === "Blank_Question" || questionViewActive === "Edit_Question" ? (
                <CreateUpdateQuestion
                    {...props}
                    handleClickBack={handleClickBack}
                    questionByTask={questionByTask}
                    itemQuestion={itemQuestion}
                />
            ) : (
                <React.Fragment>
                    <AddQuestionButton
                        {...props}
                        handleActionView={handleActionView}
                    />
                    <DragQuestion
                        {...props}
                        timePointId={timepointData?.Id}
                        data={questionByTask}
                        disableSort={false}
                        task={activeViewClass}
                        handleEditQuestion={handleEditQuestion}
                    />
                </React.Fragment>
            )}
        </div>
    );
}

export default QuestionMain;