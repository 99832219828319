import React, { useState, useEffect } from 'react';
import Select from 'react-select';

//redux
import { useSelector } from "react-redux";

//utils
import { formatReactSelect, apiCaller } from "@utils";

const TagSelect = (props) => {
    const { row, typeReview } = props && props;
    const { tag_objects, log_id } = row;

    // All Tags
    const tagsData = useSelector(
        (state) => (state?.getTagsForValidation?.data) || []
    );

    const dropdownSelect = tagsData?.length > 0 ? formatReactSelect(tagsData || [], 'tag_id', 'name') : [];

    // Format initial options
    const initialOptions = tag_objects?.map(item => ({
        value: item.tag_id,
        label: item.name
    }));

    //state
    const [tags, setTags] = useState(initialOptions || []);

    // Update options when tag_objects change
    useEffect(() => {
        const updatedOptions = tag_objects?.map(item => ({
            value: item.tag_id,
            label: item.name
        }));

        setTags(updatedOptions || []);
    }, [tag_objects]);

    //Handle Change Dropdown
    const handleChangeOptions = (data) => {
        setTags(data);

        //POST API
        const convertedData = data?.map(item => ({
            tag_id: item.value,
            name: item.label
        }));
        const params = {
            "action_type": "add_tag_to_review",
            "tag_objects": convertedData,
            "log_id": log_id,
            "review_type": typeReview?.review_type
        }
        //Call Api Load Text Item
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                console.log('success', data);
            } else {
                console.log("error....");
            }
        })
    };

    return (
        <div className='react-select-tags'>
            <Select
                closeMenuOnSelect={true}
                isMulti={true}
                options={dropdownSelect || []}
                value={tags}
                onChange={handleChangeOptions}
                autoComplete="off"
                name="tags"
            />
        </div>
    );
};

export default TagSelect;