import React, { useState, useEffect } from "react";

//reactstrap
import { Label, FormGroup, Row, Col, Button } from "reactstrap";

//components
import { TabsMain } from '../DiagnosisTabs';

//react-select
import Select from "react-select";

//lodash
import _ from "lodash";

//Axios
import axios from "axios";

//Auth
import * as Config from "@constants/Config";

let atributeTarget = `mark[class*="highlight"]`;
let classRemoveItem = `removeItem`;

function convertHexColorInline(content) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, "text/html");

    //check color
    const isColor = (strColor) => {
        var s = new Option().style;
        s.color = strColor;
        return s.color === strColor;
    }

    doc?.querySelectorAll(atributeTarget).forEach(function (element) {
        const classes = element.className;
        const splitString = classes.split('-');
        const hexColor = splitString && splitString[1];
        const checkColor = isColor(hexColor);
        const nodeRemove = document.createElement(`span`);
        nodeRemove.setAttribute("class", classRemoveItem);
        if (hexColor !== 'sub') {
            element.setAttribute(
                'style',
                `background-color: ${checkColor ? hexColor : `#${hexColor}`};
                color: white;
                display: inline-block;
                padding: 1px 4px;
                border-radius: 4px;
                margin: 5px;`
            );
            element.appendChild(nodeRemove);
        }
    });
    const text = doc?.body.innerHTML;
    return text;
}

//Convert After when Remove
function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("mark");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

//Convert html string
function nodeToString(node) {
    var tmpNode = node?.cloneNode(true);
    var str = tmpNode.innerHTML;
    tmpNode = node = null; // prevent memory leaks in IE
    return str || '';
}

function HtmlResult(props) {
    let listDropdown = [
        {
            "id": "1",
            "label": "None",
            "value": "None"
        },
        {
            "id": "2",
            "label": "Diagnosis",
            "value": "Diagnosis"
        },
        {
            "id": "3",
            "label": "Symptoms",
            "value": "Symptoms"
        },
        {
            "id": "4",
            "label": "Exam Findings",
            "value": "ExamFindings"
        }
    ]

    //props
    const { data, handleBack } = props && props;

    //state
    const [selectedOption, setSelectedOption] = useState({
        "id": "1",
        "label": "None",
        "value": "None"
    });
    const [stateData, setData] = useState((data && data) || {});

    //Load Data
    useEffect(() => {
        setData((data && data) || {});
        removeItemLabel();
    }, [data]);

    //Handle Select
    const handleChangeSelect = (value) => {
        setSelectedOption(value);
    }

    //Remove Attribute Style
    const dataRemoveStyle = () => {
        const divContainer = document.getElementById("htmlContainer");
        const divContainerClone = divContainer?.cloneNode(true);
        divContainerClone.querySelectorAll(atributeTarget).forEach((elem) => {
            elem.removeAttribute('style');
        });

        //remove span by class removeItem
        divContainerClone.querySelectorAll(`.${classRemoveItem}`)?.forEach(n => n.remove());

        //remove Attribute id and contenteditable
        divContainerClone?.removeAttribute('id');
        divContainerClone?.removeAttribute('contenteditable');
        const stringHtml = divContainer?.innerHTML
        if (stringHtml) {
            handleSubmitStatement(stringHtml ? _.escape(stringHtml) : '');
        }
    }

    //Handle Add Label
    const handleAddLabel = () => {
        let hexColor = "";
        if (selectedOption?.value !== "None") {
            switch (selectedOption?.value) {
                case "Diagnosis":
                    hexColor = "8ed1fc";
                    break;
                case "Symptoms":
                    hexColor = "c1771c";
                    break;
                default:
                    hexColor = "fcba03";
            }

            //Create Element
            let range = window.getSelection().getRangeAt(0);
            let markElement = document.createElement('mark');
            let markChild = document.createElement('span');
            let nodeRemove = document.createElement(`span`);
            nodeRemove.setAttribute("class", classRemoveItem);

            if (range.toString() !== "") {
                //Add Mark
                markElement.className = `highlight-${hexColor}`;
                markChild.className = `highlight-sub`;
                markChild.innerText = `(${selectedOption?.value})`;
                markElement.appendChild(range.extractContents());
                markElement.appendChild(markChild);
                range.insertNode(markElement);
                const rangeEndContainer = range?.endContainer;
                const endRangeNodeString = nodeToString(rangeEndContainer);
                setData((prevState) => ({ ...prevState, [`text`]: endRangeNodeString }));

                //Remove Style Inline
                dataRemoveStyle();
            } else {
                setData((prevState) => ({ ...prevState, [`text`]: data }));
            }
        }
    }

    removeItemLabel();
    return (
        <div className={`analyzeCt`}>
            <Row>
                <Col md={12}>
                    <p className="text-muted text-3 text-right font-weight-500">ID: {stateData?.statement_id || ''}</p>
                </Col>
                <Col md={6}>
                    <FormGroup row className="align-items-center">
                        <Label className="font-weight-500" sm={3}>Label:</Label>
                        <Col sm={9}>
                            <div className="d-flex w-100">
                                <Select
                                    closeMenuOnSelect={true}
                                    defaultValue={selectedOption}
                                    onChange={handleChangeSelect}
                                    options={listDropdown}
                                    placeholder={`Select an item below`}
                                    className="w-100"
                                />
                                <Button
                                    color="primary"
                                    className="btn btn-brand mx-2 text-nowrap"
                                    onClick={handleAddLabel}
                                    disabled={selectedOption?.value !== 'None' ? false : true}
                                >
                                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add
                                </Button>
                            </div>
                        </Col>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label className="font-weight-500">Text:</Label>
                        {stateData?.text && (
                            <div id="htmlContainer" contentEditable dangerouslySetInnerHTML={{ __html: convertHexColorInline(stateData?.text ? _.unescape(stateData?.text) : '') }}></div>
                        )}
                    </FormGroup>
                </Col>
                <Col md={12} className="text-center">
                    <Button
                        color="primary"
                        className="btn btn-brand mt-3 mx-2"
                        onClick={handleBack}
                    >
                        <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>Back
                    </Button>
                </Col>
            </Row>
            <TabsMain {...props} />
        </div>
    );
}

//Remove Item
function removeItemLabel() {
    const divContainerHtml = document.getElementById("htmlContainer");
    if (divContainerHtml) {
        divContainerHtml?.addEventListener("click", function (e) {
            const targetElement = e.target;
            const parentNode = targetElement?.parentNode;
            const containsHasClass = targetElement?.classList.contains(classRemoveItem);
            if (containsHasClass) {
                parentNode?.removeAttribute("style");
                parentNode?.querySelectorAll(".highlight-sub").forEach(el => el.remove());
                parentNode?.querySelectorAll(".removeItem").forEach(el => el.remove());
                parentNode?.removeAttribute("highlight-sub");
                if (parentNode) {
                    const convertHtmlToPlainText = convertToPlain(nodeToString(parentNode));
                    parentNode?.replaceWith(convertHtmlToPlainText);

                    //remove Attribute id and contenteditable
                    divContainerHtml.removeAttribute('id');
                    divContainerHtml.removeAttribute('contenteditable');
                    const stringHtml = divContainerHtml.innerHTML
                    if (stringHtml) {
                        handleSubmitStatement(stringHtml ? _.escape(stringHtml) : '');
                    }
                }
            }
        });
    }
}

//send api
function handleSubmitStatement(content) {
    const params = { "text": content }
    axios({
        method: "POST",
        url: `${Config.API_ANALYZE}/api/statement/`,
        mode: "no-cors",
        headers: { 'Content-Type': 'application/json' },
        data: params
    }).then(function (response) {
        if (response?.status === 200) {
            console.log('Success');
        }
    }).catch(function (error) {
        console.log(error);
    });
};

export default HtmlResult;