import React, { useState, useEffect } from 'react';

//Boostrap
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//constants
import { teachConstants } from "@constants";

//apiCaller
import { apiCaller } from "@utils";

//actions
import { fetchAllQuestionsForElement } from "@actions";

//lodash
import _ from "lodash";

//common
import { DataEmty } from "@components-common";

function ModalQuestionBank(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { isOpen, toggle, listQuestionBlank, timePointId, task } = props && props;
    const data = listQuestionBlank?.AvailableQuestions;

    //State
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setLoading] = useState('');
    const [results, setResults] = useState((data && data) || []);
    const [isIdChecked, setIdChecked] = useState('');

    useEffect(() => {
        setResults((data && data) || []);
    }, [data])

    const hanldeSubmit = () => {
        const params = {
            "Action": "Create",
            "Target": "Question",
            "Source": "Template",
            "SourceQuestionId": isIdChecked,
            "TimePointId": timePointId,
            "Task": task
        }
        setLoading(true);
        apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
          dispatch({ type: teachConstants.CREATE_QUESTION_FROM_QUESTION_BANK_REQUEST, isLoading: true });
          if (res?.status === 200) {
            const data = res?.data;
            //Load All Question
            const params = {
              Action: "Load",
              Target: "CaseQuestions",
            };
            dispatch(fetchAllQuestionsForElement(id, params));
            dispatch({ type: teachConstants.CREATE_QUESTION_FROM_QUESTION_BANK_SUCCESS, payload: data });
            setLoading(false);
            toggle();
          } else {
            dispatch({ type: teachConstants.CREATE_QUESTION_FROM_QUESTION_BANK_FAILURE, isLoading: false });
            setLoading(false);
            toggle();
          }
        });
    }

    const hanldeChange = (id) => {
        setIdChecked(id);
    }

    const hanldeSearch = (value) => {
        const listDataClone = _.cloneDeep((data && data) || []);
        if (value) {
            const resultsFilter = listDataClone?.filter(function (elem) {
                return elem.QuestionContent.toLowerCase().includes(value.toLowerCase());
            });
            setResults(resultsFilter);
        } else {
            setResults((data && data) || []);
        }
        setSearchTerm(value);
    }

    const todoItems = results?.map((todo, index) =>
        <FormGroup className="d-flex align-items-center border-bottom py-3" style={{ cursor: 'pointer' }} check key={index} onClick={() => hanldeChange(todo?.QuestionId)}>
            <Input
                name={`radio_schema`}
                type="radio"
                value={todo?.QuestionId}
                checked={isIdChecked === todo?.QuestionId ? true : false}
                bsSize={'lg'}
                onChange={() => hanldeChange(todo?.QuestionId)}
            />
            {' '}
            <Label id={`radio_schema`} check>
                <h3>{todo?.QuestionContent?.replace(/(<([^>]+)>)/ig, '')}</h3>
                <p className="text-muted mt-2">ID: {todo?.QuestionId}</p>
            </Label>
        </FormGroup>
    );

    return (
        <Modal
            toggle={toggle}
            isOpen={isOpen}
            modalClassName={`right ${isOpen ? "in" : ""}`}
            wrapClassName={`modalFixed mw-600`}
        >
            <ModalHeader toggle={toggle}>
                Choose from Question Bank
            </ModalHeader>
            <div className="d-flex align-items-center w-100 p-3 border-bottom">
                <Input
                    type="text"
                    name="search"
                    placeholder="Search"
                    style={{ height: "38px" }}
                    value={searchTerm}
                    onChange={(e) => hanldeSearch(e.target.value)}
                    autoComplete="off"
                />
            </div>
            <ModalBody>
                {todoItems}
                {results && results.length === 0 &&
                    <DataEmty content={`No Data`} />
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={hanldeSubmit}
                    disabled={isIdChecked === '' ? true : false}
                    className="--btn-primary"
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Add
                </Button>
                {' '}
                <Button onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalQuestionBank;