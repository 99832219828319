import React from "react";

function TasksMain(props) {
  const { children, heading, type } = props && props;
  return (
    <div className={`tasks-main ${type} ${heading ? "-show-icon-top" : ""}`}>
      <div className="tasks-main-inner">
        {heading && (
          <h2 className="tasks-main-inner__header">
            {heading}
          </h2>
        )}
        <div className="tasks-nav">
          {children}
        </div>
      </div>
    </div>
  );
}

export default TasksMain;