import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

//actions
import { switchRole } from '@actions';

const SwitchRoleDesktop = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const isTeachingPath = location?.pathname.includes('/teach');
    const isLearnPath = location?.pathname.includes('/learn');

    //Role Active
    const currentRole = useSelector((state) => state?.statusRoleSystem || {});
    const activeRole = currentRole?.roleActive;

    // Set the role on initial load based on the URL path
    useEffect(() => {
        dispatch(switchRole('learner'));
        if (isTeachingPath) {
            dispatch(switchRole('instructor')); // Set role to 'instructor' if path is teaching
        } else if (isLearnPath) {
            dispatch(switchRole('learner')); // Set role to 'learner' if path is learning
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTeachingPath]);

    const handleRoleSwitch = () => {
        if (isTeachingPath) {
            dispatch(switchRole('learner')); // Dispatch action to set learner role
        } else {
            dispatch(switchRole('instructor')); // Dispatch action to set instructor role
        }
    };

    return (
        <div className="btn-switch-role">
            <Link
                to={activeRole === 'instructor' ? '/learn/home' : '/teach/home'}
                onClick={handleRoleSwitch}
            >
                {activeRole === 'instructor' ? 'Switch to Learner View' : 'Switch to Instructor View'}
            </Link>
        </div>
    );
};

export default SwitchRoleDesktop;