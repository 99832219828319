// MgmtSeriesPage.jsx
import React, { useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";

//Components
import { Modules } from "@components/teachV2/Manage/Tabs";

//call Action
import { actFetchTeachPortfolioRequest } from "@actions";

const MgmtSeriesPage = (props) => {
  //active
  const dispatch = useDispatch();

  //load Data
  useEffect(() => {
    const fetchData = async () => {
      //Load My Case From Portfolio
      dispatch(actFetchTeachPortfolioRequest());
    };
    fetchData();
  }, [dispatch]);

  //load series from store
  const instructorData = useSelector((state) => state.instructor || {});
  const responseInstructor = instructorData && instructorData.data;
  const seriesStore = (responseInstructor && responseInstructor.Series) || [];
  return (
    <div className='wrapper MgmtSeriesPage'>
      <Modules
        dataSeries={(seriesStore && seriesStore) || []}
        instructorData={(instructorData && instructorData) || []}
      />
    </div>
  );
};

export default MgmtSeriesPage;