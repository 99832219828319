import React from 'react';

function TableSheet(props) {
    const { propsData } = props && props;
    const rowItems = propsData?.Data?.map((value, index) => {
        return (
            <tr key={index}>
                <td>{value['Problem'] || ''}</td>
                <td>{value['ICD-10-CM'] || ''}</td>
                <td>{value['CMS-HCC'] || ''}</td>
                <td>{value['RAF'] || ''}</td>
            </tr>
        )
    });

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th className="font-weight-500 --text-primary">Problem</th>
                        <th className="font-weight-500 --text-primary">ICD-10-CM</th>
                        <th className="font-weight-500 --text-primary">CMS-HCC</th>
                        <th className="font-weight-500 --text-primary">RAF</th>
                    </tr>
                </thead>
                <tbody>
                    {rowItems}
                    <tr>
                        <td colSpan="3" className="text-right font-weight-600">Total RAF</td>
                        <td className="text-left font-weight-600">{(propsData && propsData['Total RAF']) || ''}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TableSheet;