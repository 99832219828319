import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";

// Condition components
import {
  TopicTypeCondition,
  DropDownCondition,
  OrderTypeCondition,
  ActionTypeCondition,
  // ShowingCondition,
} from '../FlowControl/Condition';

import {
  AskedAtTask,
  DropDownTimePoint,
  QuestionConditionMain,
} from "../FlowControl/";

import { atcLoadChat } from "@actions";

function FlowControl(props) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { timepointData, handleChangeStateCondition, isCondition } = props;

  // Load Chat Topic on timepointData.Id change
  useEffect(() => {
    if (timepointData?.Id) {
      const params = {
        Action: "Load",
        Target: "Chat",
        TimePointId: timepointData.Id,
      };
      dispatch(atcLoadChat(id, params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderConditionContent = useMemo(() => {
    switch (isCondition) {
      case 'Question':
        return <QuestionConditionMain {...props} isCondition={isCondition} />;
      case 'Chat':
        return <TopicTypeCondition {...props} isCondition={isCondition} />;
      case 'Order':
        return <OrderTypeCondition {...props} isCondition={isCondition} />;
      case 'Action':
        return <ActionTypeCondition {...props} isCondition={isCondition} />;
      default:
        return null;
    }
  }, [isCondition, props]);
  
  return (
    <Row>
      <Col md={6}>
        <DropDownCondition
          {...props}
          isCondition={isCondition}
          handleChangeStateCondition={handleChangeStateCondition}
        />
        {renderConditionContent}
      </Col>
      <Col md={6}>
        <AskedAtTask {...props} />
        <DropDownTimePoint {...props} />
        {/* <ShowingCondition {...props} /> */}
      </Col>
    </Row>
  );
}

export default FlowControl;