import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useSelector } from "react-redux";
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
import { DateFormatter } from "@ui-partents/DateTime";
import { TagsText } from '@ui-partents/Tags';
import { Rating } from '@ui-partents/Rating';

//Icon
import { Icon } from '@components/common';
import More from '@images/teachV2/more-vertical.svg';

// Import the pagination configuration
import {paginationOptions} from "@utils";

const TableAllReviews = (props) => {
  const { loadDataMcqBank } = props;
  const listQuestionBank = useSelector((state) => state.getQuestionBank || []);


  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "CreatedAt",
      text: "Date Created",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <DateFormatter date={cell} format="DD/MM/YYYY, h:mm:ss A" />
        );
      },
    },
    {
      dataField: "QuestionType",
      text: "Status",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <TagsText content={cell}/>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "QuestionType",
      text: "Rating",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <Rating rating={2} />
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    // {
    //   dataField: "",
    //   text: "Action",
    //   headerClasses: "text-center",
    //   headerStyle: { width: "50px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="d-flex align-items-center justify-content-center action-reference">
    //         <Icon src={More}/>
    //         <div className="action-reference__list">
    //           {row?.status?.color !== "warning" && (
    //             !row?.SharedToOrganization ? (
    //               <p onClick={() => handleShareReference(row)}>Share to Organization</p>
    //             ) : (
    //               <p onClick={() => handleUnshareReference(row)}>Unshare</p>
    //             )
    //           )}
    //           <WrapModalEdit
    //             dataEdit={row}
    //           >
    //             {({onOpen}) => (
    //               <p onClick={row?.Rubric?.instruction_text 
    //                 ? () => handleQuestionDetail(row?.QuestionItemId)
    //                 : onOpen}
    //               >
    //                 Edit
    //               </p>
    //             )}
    //           </WrapModalEdit>
    //           <WrapModalDeleteCommon
    //             title={`Delete Question`}
    //             sub={`Are you sure you want to delete this question? This action cannot be undone.`}
    //             handleRemove={handleRemove}
    //             idRemove={row?.QuestionItemId}
    //           >
    //             {({onOpen}) => (
    //               <p onClick={onOpen}>Delete</p>
    //             )}
    //           </WrapModalDeleteCommon>
    //         </div>
    //       </div>
    //     );
    //   },
    // }
    {
      dataField: "",
      text: "Action",
      headerClasses: "text-center",
      headerStyle: { width: "50px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-reference">
            <Icon src={More}/>
            <div className="action-reference__list">
              <p>View Details</p>
              <p>Publish to Organization</p>
            </div>
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={loadDataMcqBank || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {loadDataMcqBank?.length}</div>
              <div className="boxSearch">
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder={`Search`}
                />
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={loadDataMcqBank || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = loadDataMcqBank?.length || 0;  // Determine the total size dynamically
  // Generate pagination configuration dynamically
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        loadDataMcqBank?.length === 0 ? (
          <MessageEmtyData 
            title="Your Question Bank is Empty"
            text="It looks like you haven't saved any questions yet. Start creating a new one to see it here."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableAllReviews
