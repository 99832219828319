import React from 'react';

//components
import { TotalCoin, PurchaseCoinList, CoinPanel } from "@components/PaymentGateway/CoinPayment";

const PurchaseCoinPage = (props) => {
    return (
        <React.Fragment>
            <TotalCoin {...props} />
            <PurchaseCoinList {...props} />
            <CoinPanel {...props} />
        </React.Fragment>
    );
};

export default PurchaseCoinPage;