import React from 'react';
import { Iconsax } from '@components-common';

const BtnAddRow = ({ handleClickAddRow, textBtn }) => {
    return (
        <div className="btn-add-item --border-blue --size-sm-v2 text-center m-0" onClick={handleClickAddRow}>
            <Iconsax iconName="plus" fill="#0089C2" size={15} />
            <span className="ml-2 btn-add-item__text --text-blue">{textBtn}</span>
        </div>
    );
}

export default BtnAddRow;