import React, { useRef, useState, useEffect } from 'react';

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, value, index, handleApiExtractICD, icdActive, pageActive } = props;
  const { title, content } = value;
  const listItems = content?.map((item, idx) =>
    <li key={idx} className={`mb-2 hover-primary ls-arrow-left__item d-flex ${icdActive === item ? "--text-primary" : "" }`} onClick={() => handleApiExtractICD(item, value, pageActive)} style={{ cursor: 'pointer' }}>{item}</li>
  );
  return (
    <div className="rc-accordion-card">
      <div className="rc-accordion-header">
        <div className={`rc-accordion-toggle p-3 ${active === index ? 'active' : ''}`} onClick={() => handleToggle(index)}>
          <h5 className="rc-accordion-title">{title}</h5>
          <i className="fa fa-chevron-down rc-accordion-icon"></i>
        </div>
      </div>
      <div ref={contentEl} className={`rc-collapse ${active === index ? 'show' : ''}`} style={
        active === index
          ? { height: contentEl.current.scrollHeight }
          : { height: "0px" }
      }>
        <div className="rc-accordion-body p-3">
          <ul className="ls-arrow-left">{listItems}</ul>
        </div>
      </div>
    </div>
  )
}

const AccordionCard = (props) => {
  const { rowData, handleApiExtractICD, icdActive, pageActive } = props && props;

  //state
  const [active, setActive] = useState(null);
  const [stateData, setData] = useState(rowData?.rightside_bar || []);

  //Load data
  useEffect(() => {
    setData(rowData?.rightside_bar || []);
  }, [rowData])

  // Handle Toggle
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  }
  
  return (
    <div className="card">
      <div className="card-body">
        {stateData?.map((value, index) => {
          return (
            <AccordionItem
              key={index}
              index={index}
              active={active}
              handleToggle={handleToggle}
              value={value}
              handleApiExtractICD={handleApiExtractICD}
              icdActive={icdActive}
              pageActive={pageActive}
            />
          )
        })
        }
      </div>
    </div>
  );
};

export default AccordionCard;