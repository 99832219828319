import React from 'react';

//components
import { VitalSignTableFirst } from "./VitalSigns/TimepointFirst";
import { VitalSignTableLast } from "./VitalSigns/TimepointLast";

const VitalSignMain = (props) => {
    const { timepointData } = props && props;
    const IsFirstTimepoint = timepointData?.Time === 0;
    return (
        <React.Fragment>
            {IsFirstTimepoint ? <VitalSignTableFirst {...props} /> : <VitalSignTableLast {...props} />}
        </React.Fragment>
    );
};

export default VitalSignMain;