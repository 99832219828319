import React, { useEffect, useState } from "react";

//router
import { useParams } from "react-router-dom";

//boostrap
import { Container, Row, Col, Button } from "reactstrap";

//common
import { HeadingBox } from "@components/common";

//components
import { DiagnosticMain, ModalTestSchema } from '@components/schema';

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { getSchemaList, loadQuestionDetailForSchema } from "@actions";

//utils
import { LoaderText, useQuery } from "@utils";

function SchemaPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  let query = useQuery();
  let QuestionId = query.get("QuestionId");

  const [modal, setModal] = useState({
    testSchema: false
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  //Load Data by Slyg
  useEffect(() => {
    const fetchData = () => {
      const params = {
        "Action": "LoadSchema",
        "QuestionId": QuestionId
      }
      dispatch(getSchemaList(params));
    };

    const loadQuestionById = () => {
      const params = {
        "Action": "Load",
        "Target": "Question",
        "QuestionId": QuestionId
      }
      dispatch(loadQuestionDetailForSchema(id, params));
    };

    fetchData();
    loadQuestionById();
  }, [dispatch, QuestionId, id]);

  //get data store
  const resSchema = useSelector((state) => state.dataSchema || {});
  const questionData = useSelector((state) => state?.dataQuestionForSchema?.data || {});
  const dataTestQuestion = useSelector((state) => state?.dataTestQuestion?.data || {});
  const dataRes = resSchema?.data;

  const QuestionContent = questionData?.QuestionContent;
  return (
    <div className="wrapper -schemaPage">
      <Container>
        <div className="diagnosticCt bg-white p-4">
          <div className="position-relative">
            <Row className="align-items-center">
              <Col md={9}>
                <HeadingBox title={`Question #${QuestionId}` || ''} currentPage="Schema" />
              </Col>
              <Col className="text-right" md={3}>
                <Button
                  className="--btn-primary"
                  onClick={() => toggle('testSchema')}
                >
                  Test Schema
                </Button>
              </Col>
            </Row>
            <div 
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: QuestionContent || ''
              }}
            />
            {resSchema.isLoading && (
              <div style={{height: '400px'}}>
                <LoaderText />
              </div>
            )}
            <DiagnosticMain QuestionId={QuestionId} data={dataRes} />
          </div>
        </div>
      </Container>
      <ModalTestSchema isOpen={modal.testSchema} toggle={() => toggle('testSchema')} QuestionId={QuestionId} data={dataTestQuestion} />
    </div>
  );
}

export default SchemaPage;
