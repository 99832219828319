import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input, FormGroup, Button } from "reactstrap";

function Filter(props) {
  const { templatesData, isReset, handleChange, type } = props && props;
  const templateParameters = templatesData?.SystemTemplateParameters;
  const ageData = templateParameters?.AgeGroups;
  const ethnicityData = templateParameters?.EthnicityGroups;
  const genderData = templateParameters?.GenderGroups;

  //state
  const [stateAge, setStateAge] = useState(ageData || []);
  const [stateEthnicity, setStateEthnicity] = useState(ethnicityData || []);
  const [stateGender, setStateGender] = useState(genderData || []);

  //Setstate
  useEffect(() => {
    if (isReset) {
      setStateAge([]);
      setStateEthnicity([]);
      setStateGender([]);
    } else {
      setStateAge(ageData);
      setStateEthnicity(ethnicityData);
      setStateGender(genderData);
    }
  }, [ageData, ethnicityData, genderData, isReset]);

  return (
    <div className="mb-4 -box-shadow p-4">
      <Row className="justify-content-end border-bottom pb-4">
        <Col className="d-flex align-items-center justify-content-end" md={6}>
          <FormGroup className="mb-0 w-100">
            <div className="d-flex align-items-center">
              <Label className="font-weight-500 mb-0 mr-3" htmlFor="Search">
                Search
              </Label>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                style={{ height: "38px" }}
                value={props && props.value}
                onChange={handleChange}
                autoComplete="off"
              />
              <Button className="--btn-primary ml-2">
                <i className="fa fa-search" aria-hidden="true"></i>
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={3}>
          <FormGroup>
            <Label for="Age">
              Age:
            </Label>
            <Input
              type="select"
              defaultValue=""
              onChange={handleChange}
              name="Age"
            >
              <option value="">
                No filter
              </option>
              {stateAge &&
                stateAge.map((item, index) => (
                  <option
                    key={index}
                    data-id={item.AgeGroupId}
                    value={item.AgeGroupId}
                  >
                    {item.AgeGroupName}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Gender">
              Gender:
            </Label>
            <Input
              type="select"
              defaultValue=""
              onChange={handleChange}
              name="Gender"
            >
              <option value="">
                No filter
              </option>
              {stateGender &&
                stateGender.map((item, index) => (
                  <option
                    key={index}
                    data-id={item.GenderId}
                    value={item.GenderId}
                  >
                    {item.GenderName}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="Ethnicity">
              Ethnicity:
            </Label>
            <Input
              type="select"
              defaultValue=""
              onChange={handleChange}
              name="Ethnicity"
            >
              <option value="">
                No filter
              </option>
              {stateEthnicity &&
                stateEthnicity.map((item, index) => (
                  <option
                    key={index}
                    data-id={item.EthnicityGroupId}
                    value={item.EthnicityGroupId}
                  >
                    {item.EthnicityGroupName}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </Col>
        <Col md={3}>
          <FormGroup>
            <Label for="InstitutionalAccess">
              Access:
            </Label>
            {/* (Manger) Access : [All Templates | Institutional Templates | My Own Templates Only] */}
            {/* Teach : [All Templates | My Own Templates Only] */}
            <Input
              type="select"
              defaultValue={``}
              onChange={handleChange}
              name="InstitutionalAccess"
            >
              <option value={``}>
                All Templates
              </option>
              {type === 'adminstrator' &&
                <option value={true} >
                  Institutional Templates
                </option>
              }
              <option value={false} >
                My Own Templates Only
              </option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default Filter;
