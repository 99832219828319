import React from 'react'
import styled from 'styled-components'
import ItemAnswer from './ItemAnswer'
import { ButtonComponent } from "@components/patterns/elements/Button";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { apiCaller } from "@utils";
import ItemResult from './ItemResult';

const WrapResult = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  padding-right: 8px;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #D6DBE3; 
    border-radius: 8px;
  }

  .question-result {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &__question {
      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        font-weight: 600;
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  .question-explanation {
    padding: 10px;
    border-radius: 8px;
    background: #F7F8F9;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    div {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export default function FormQuestion(props) {
  const { dataQuestionDetail, setDataQuestionDetail } = props

  const {
    handleSubmit,
    setValue,
    watch,
  } = useForm()

  const onSubmit = (data) => {
    const answer = data?.answer?.split(".")
    const params = {
      Action: "SubmitAnswerToMCQDuringReview",
      QuestionItemId: dataQuestionDetail?.QuestionItemId,
      Answers: [
        {
          id: answer[0],
          content: answer[1]
        }
      ]
    };
    apiCaller(`/feedback/question/items/`, "POST", params).then((res) => {
      if (res?.status === 200) {
        // toast.success("Successfully Feedback", {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: false,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
        setDataQuestionDetail((prev) => ({
          ...dataQuestionDetail,
          Contents: {
            ...dataQuestionDetail?.Contents,
            answer_choices: res?.data?.FeedbackData?.answer_choices
          }
        }))
      } else {
        toast.error("Failed to feedback", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  }

  return (
    <WrapResult>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="question-result">
          <div className="question-result__question">
            <h1>Question</h1>
            <p>{dataQuestionDetail?.Contents?.question}</p>
          </div>
          {dataQuestionDetail?.Contents?.answer_choices?.length > 0 ? (
            <div className="question-result__list">
              {dataQuestionDetail?.Contents?.answer_choices?.map((choice, i) => (
                <ItemResult
                  key={i}
                  choice={choice} 
                  question={dataQuestionDetail?.Contents} 
                />
              ))}
            </div>
          ) : (
            <div className="question-result__list">
              {dataQuestionDetail?.Contents?.multiple_choices?.map((choice, i) => (
                <ItemAnswer
                  key={i}
                  choice={choice} 
                  question={dataQuestionDetail?.Contents} 
                  setValue={setValue}
                />
              ))}
            </div>
          )}
        </div>
        {dataQuestionDetail?.Contents?.answer_choices?.length > 0 ? (
          <div className="question-explanation">
            <p>Overall Explanation: 
              <div dangerouslySetInnerHTML={{ __html: dataQuestionDetail?.Contents?.best_answer?.explanation || "" }} />
            </p>
          </div>
        ) : (
          <div className='mx-3 mt-4'>
            <ButtonComponent 
              className="px-4" 
              size="small" 
              type="submit"
              disabled={!watch("answer")}
            >
              Submit
            </ButtonComponent>
          </div>
        )}
      </form>
    </WrapResult>
  )
}
