import React from "react";
import visaCard from "@images/payment/visa.png";

function CreditCard(props) {
  const { data } = props && props;
  const Details = data?.Details;
  const CreditCard = Details?.CreditCard;
  const DataCreditCard = CreditCard?.Data;
  const listCard = DataCreditCard?.payment_methods;

  const listItems = listCard?.map((value, index) => (
    <div className="col-12 col-sm-6" key={index}>
      <div className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0">
        <div className="text-4 hiddenNumber d-flex">
          <span className="hiddenNumber__item">****</span>
          <span className="hiddenNumber__item">****</span>
          <span className="hiddenNumber__item">****</span>
          <span className="hiddenNumber__item">{value && value.card?.last4}</span>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-block">
            <span className="account-card-expire text-uppercase d-inline-block opacity-7 mr-2">
                Valid
                <br />
                Dates
                <br />
            </span>{" "}
            <span className="text-4 opacity-9">{value && value.card?.exp_month}/{value && value.card?.exp_year}</span>{" "}
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <img className="ml-auto" src={visaCard} alt="visa" />{" "}
          </div>
        </div>
        <div className="badge badge-warning text-0 font-weight-500 rounded-pill px-2 ml-auto mt-3">
            Primary
        </div>
      </div>
    </div>
  ));

  return <div className="row justify-content-center">{listItems}</div>;
}

export default CreditCard;
