import React, { useState, useEffect } from 'react';

//textarea
import TextareaAutosize from "react-textarea-autosize";

//Boostrap
import { Alert } from "reactstrap";

function VisitDocument(props) {
    //props
    const { caseData, handleDocumentationText } = props && props;
    const resCaseData = caseData?.data;
    const caseDetail = resCaseData?.Case;
    const Documentation = resCaseData?.Case?.Documentation;
    const DocumentationItems = Documentation?.DocumentationItems;
    let DocumentationItemsText = DocumentationItems && DocumentationItems[0];
    let DocumentationTemplate = caseDetail?.DocumentationTemplate;

    //state
    const [stateDocumentationTemplate, setDocumentationTemplate] = useState(DocumentationTemplate || {});
    const [stateText, setText] = useState(DocumentationItemsText?.text || "");

    //loadData
    useEffect(() => {
        const resCaseData = caseData?.data;
        const caseDetail = resCaseData?.Case;
        const Documentation = resCaseData?.Case?.Documentation;
        const DocumentationItems = Documentation?.DocumentationItems;
        let DocumentationItemsText = DocumentationItems && DocumentationItems[0];
        let DocumentationTemplate = caseDetail?.DocumentationTemplate;
        setDocumentationTemplate(DocumentationTemplate || {});
        setText(DocumentationItemsText?.text || "");
    }, [caseData])

    return (
        <React.Fragment>
            <div className="mb-4 align-items-center">
                <Alert className="m-alert--default border-0 mb-0">
                    <p dangerouslySetInnerHTML={{ __html: stateDocumentationTemplate?.Instruction }}></p>
                </Alert>
            </div>
            <TextareaAutosize
                name="Comment"
                placehoder={stateDocumentationTemplate?.Placeholder?.replace(/<\/?[^>]+>/gi, ' ')}
                className="w-100 form-control"
                maxRows="10"
                minRows={5}
                value={stateText}
                onChange={(e) => {
                    const { value } = e.target
                    // Send Du lieu ra de stream Check Document
                    handleDocumentationText(value);

                    //SetState text hien tai
                    setText(value);
                }}
            />
        </React.Fragment>
    );
};

export default VisitDocument;