import React, { useState, useEffect } from "react";

//react-select
import Select from "react-select";

const questionTypeOptions = [
  {
    value: "multiple_choice",
    label: "Multiple Choice",
    name: "typeQuestion"
  },
  {
    value: "multiple_choice_table",
    label: "Multiple Choice Table",
    name: "typeQuestion"
  },
  {
    value: "free_text",
    label: "Free Text",
    name: "typeQuestion"
  },
  {
    value: "drag_n_drop",
    label: "Drag-and-drop",
    name: "typeQuestion"
  },
  {
    value: "drag_n_drop_cloze",
    label: "Drag-and-drop Cloze",
    name: "typeQuestion"
  },

  {
    value: "highlight",
    label: "Highlight",
    name: "typeQuestion"
  },
  {
    value: "drop_down",
    label: "Drop Down",
    name: "typeQuestion"
  },
];

function convertFormatQuestionType(value) {
  var item = {};
  switch (value) {
    case "multiple_choice":
      item = {
        value: "multiple_choice",
        label: "Multiple Choice",
        name: "typeQuestion"
      }
      break;
    case "multiple_choice_table":
      item = {
        value: "multiple_choice_table",
        label: "Multiple Choice Table",
        name: "typeQuestion"
      }
      break;
    case "free_text":
      item = {
        value: "free_text",
        label: "Free Text",
        name: "typeQuestion"
      }
      break;
    case "drag_n_drop":
      item = {
        value: "drag_n_drop",
        label: "Drag-and-drop",
        name: "typeQuestion"
      }
      break;
    case "drag_n_drop_cloze":
      item = {
        value: "drag_n_drop_cloze",
        label: "Drag-and-drop Cloze",
        name: "typeQuestion"
      }
      break;
    case "highlight":
      item = {
        value: "highlight",
        label: "Highlight",
        name: "typeQuestion"
      }
      break;
    default:
      item = {
        value: "drop_down",
        label: "Drop Down",
        name: "typeQuestion"
      }
  }
  return item;
}

function QuestionTypeOptions(props) {
  const { handleChangeInput, isQuestionType } = props && props;

  // state
  const [selectedOption, setSelectedOption] = useState(isQuestionType ? convertFormatQuestionType(isQuestionType) : null);

  //load State
  useEffect(() => {
    setSelectedOption(isQuestionType ? convertFormatQuestionType(isQuestionType) : null);
  }, [isQuestionType]);

  const handleChangeSelect = (value) => {
    handleChangeInput(value);
    setSelectedOption(value);
  }

  return (
    <div>
      <legend className="col-form-label font-weight-500">
        Question Type: <span className="m--font-danger">*</span>
      </legend>
      <Select
        closeMenuOnSelect={true}
        value={selectedOption}
        onChange={handleChangeSelect}
        options={questionTypeOptions || []}
        placeholder={`Select an item below`}
        className="w-100"
        name="typeQuestion"
      />
    </div>
  );
}

export default QuestionTypeOptions;