import { cohortsConstant } from "@constants";
import { apiCaller } from "@utils";

//Load All Cohorts
export const atcLoadAllCohorts = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                return {
                    data: res,
                    dispatch: dispatch(success(data)),
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.GET_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.GET_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.GET_COHORT_FAILURE, error: error } }
}

export const loadCohortsForReview = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                return {
                    data: res,
                    dispatch: dispatch(success(data)),
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.LOAD_COHORTS_FOR_REVIEW_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.LOAD_COHORTS_FOR_REVIEW_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.LOAD_COHORTS_FOR_REVIEW_FAILURE, error: error } }
}

//Load Cohort By Id
export const atcLoadCohortsById = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_FAILURE, error: error } }
}

// Create Cohort with Promise
export const atcCreateCohort = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const isLoading = true;
            dispatch(request(isLoading));

            apiCaller(`/api/teach/cohorts/`, 'POST', params)
                .then(res => {
                    const data = res && res.data;
                    if (res && res.status === 200) {
                        dispatch(success(data));
                        resolve(res); // Resolve with the entire response object
                    } else {
                        const error = "error";
                        dispatch(failure(error));
                        reject(error); // Reject with an error message
                    }
                })
                .catch(err => {
                    const error = "Network or other error occurred";
                    dispatch(failure(error));
                    reject(error); // Reject with an error message
                });
        });
    };

    function request(isLoading) {
        return { type: cohortsConstant.CREATE_COHORT_REQUEST, isLoading };
    }

    function success(data) {
        return { type: cohortsConstant.CREATE_COHORT_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: cohortsConstant.CREATE_COHORT_FAILURE, error: error };
    }
}

// Update Cohort
export const atcUpdateCohort = (params) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const isLoading = true;
            dispatch(request(isLoading));

            apiCaller(`/api/teach/cohorts/`, 'POST', params)
                .then(res => {
                    const data = res && res.data;
                    if (res && res.status === 200) {
                        dispatch(success(data));
                        resolve(res); // Resolve with the entire response object
                    } else {
                        const error = "error";
                        dispatch(failure(error));
                        reject(error); // Reject with an error message
                    }
                })
                .catch(err => {
                    const error = "Network or other error occurred";
                    dispatch(failure(error));
                    reject(error); // Reject with an error message
                });
        });
    };

    function request(isLoading) {
        return { type: cohortsConstant.UPDATE_COHORT_REQUEST, isLoading };
    }

    function success(data) {
        return { type: cohortsConstant.UPDATE_COHORT_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: cohortsConstant.UPDATE_COHORT_FAILURE, error: error };
    }
}

//Delete Cohort
export const atcDeleteCohort = (params) => {
    return (dispatch) => {
        const isLoading = true;
        dispatch(request(isLoading)); // Dispatch loading state

        return apiCaller(`/api/teach/cohorts/`, 'POST', params)
            .then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    dispatch(success(data));
                    return res;
                } else {
                    dispatch(failure("error")); // Dispatch failure if status is not 200
                    return Promise.reject("Error occurred"); // Reject the promise with error message
                }
            })
            .catch(error => {
                dispatch(failure("error")); // Dispatch failure if there was a catch
                return Promise.reject(error); // Reject the promise with error caught
            });
    };

    function request(isLoading) {
        return { type: cohortsConstant.DELETE_COHORT_REQUEST, isLoading };
    }
    function success(data) {
        return { type: cohortsConstant.DELETE_COHORT_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: cohortsConstant.DELETE_COHORT_FAILURE, error: error };
    }
}

//Add Learners To Cohort
export const atcAddLearnersToCohort = (params) => {
    return (dispatch) => {
        const isLoading = true;
        dispatch(request(isLoading));

        return new Promise((resolve, reject) => {
            apiCaller(`/api/teach/cohorts/`, 'POST', params)
                .then(res => {
                    const data = res && res.data;

                    if (res && res.status === 200) {
                        dispatch(success(data));
                        resolve(res);
                    } else {
                        dispatch(failure("error"));
                        reject(new Error("Failed to add learners to cohort"));
                    }
                })
                .catch(error => {
                    dispatch(failure(error.message || "Network or other error occurred"));
                    reject(error);
                })
        });
    };

    function request(isLoading) {
        return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_REQUEST, isLoading };
    }

    function success(data) {
        return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_FAILURE, error: error };
    }
};

export const atcDeleteLearnerToCohort = (params) => {
    return async (dispatch) => {
        const isLoading = true;
        dispatch(request(isLoading));
        try {
            // Gửi API request
            const res = await apiCaller(`/api/teach/cohorts/`, 'POST', params);
            const data = res?.data;

            if (res?.status === 200) {
                dispatch(success(data));

                // Load lại cohort sau khi xóa học viên
                const paramsReload = {
                    Action: "Load",
                    CohortId: params.CohortId
                };
                dispatch(atcLoadCohortsById(paramsReload));
                return res;
            } else {
                dispatch(failure("Error occurred"));
            }
        } catch (error) {
            dispatch(failure("Error occurred"));
            return Promise.reject(error);
        }
    };

    function request(isLoading) {
        return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_REQUEST, isLoading };
    }

    function success(data) {
        return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_SUCCESS, payload: data };
    }

    function failure(error) {
        return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_FAILURE, error: error };
    }
};