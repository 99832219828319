import React, { useEffect, useState } from 'react';

function Iconsax(props) {
  const { iconName, iconSize = '22px', fill } = props;

  const [svgText, setSvgText] = useState('');

  useEffect(() => {
    if (!iconName) {
      setSvgText(`<svg width="${iconSize}" height="${iconSize}"></svg>`);
      return;
    }

    fetch(`${process.env.PUBLIC_URL}/iconsax/icons/${iconName}.svg`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Icon not found');
        }
        return response.text();
      })
      .then((svgText) => {
        if (fill || iconSize) {
          // Modify SVG to add 'fill' attribute to all 'path' elements
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
          const paths = svgDoc.querySelectorAll('path');

          paths.forEach((path) => {
            const strokeWidth = path.getAttribute('stroke-width');
            const strokeColor = path.getAttribute('stroke');
            if (strokeColor && (strokeWidth !== "0" && strokeWidth !== "none")) {
              // Phần tử <path> có thuộc tính 'stroke' và 'stroke-width', nên bạn có thể thay đổi màu sắc của nó tại đây.
              path.setAttribute('stroke', fill); // Thay 'fill' bằng màu sắc bạn muốn sử dụng cho stroke.
            } else {
              // Nếu không có thuộc tính 'stroke' hoặc 'stroke-width' không đúng, bạn có thể thay đổi thuộc tính 'fill'.
              path.setAttribute('fill', fill); // Thay 'fill' bằng màu sắc bạn muốn sử dụng cho fill.
            }
          });
        
          let modifiedSvgText = new XMLSerializer().serializeToString(svgDoc);

          if (iconSize) {
            modifiedSvgText = modifiedSvgText.replace(
              /<svg([^>]*)>/,
              `<svg$1 ${iconSize ? `width="${iconSize}" height="${iconSize}"` : ''}>`
            );
          }

          setSvgText(modifiedSvgText);
        } else {
          setSvgText(svgText);
        }
      })
      .catch((error) => {
        console.error(error);
        setSvgText(`<svg width="${iconSize}" height="${iconSize}"></svg>`);
      });
  }, [iconName, fill, iconSize]);

  return (
    <i className="iconsax" icon-name={iconName}>
      <span dangerouslySetInnerHTML={{ __html: svgText }} />
    </i>
  );
}

export default Iconsax;