import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

const ModalAddCase = (props) => {
  const { toggle, isOpen, seriesId, dataCase, addCaseToSeries, isLoading } = props && props;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm();

  const handleAdd = (data) => {
    const params = {
        "Action": "AddCase",
        "CaseId": data.CaseId
    }
    addCaseToSeries(params, seriesId);
    //Reset data
    setValue('CaseId', '', { shouldValidate: false });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Case</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="CaseId">Case <span className="m--font-danger">*</span></Label>
            <Input
              type="select"
              name="CaseId"
              {...register("CaseId", { required: true })}
              invalid={errors.CaseId?.type === "required"}
              defaultValue={watch("CaseId")}
              onChange={(e) => {
                setValue("CaseId", e.target.value, { shouldValidate: true });
              }}
            >
                <option value="">Select an item below</option>
                {dataCase && dataCase.map((value, index) =>
                  <option key={index} value={value.Id}>{value.Title}</option>
                )}
            </Input>
            <FormFeedback
              invalid={String(errors.CaseId?.type === "required")}
            >
              This is required.
            </FormFeedback>
        </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button 
            color="danger"
            className="d-flex align-items-center"
            onClick={handleSubmit(handleAdd)}
          >
            {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddCase;
