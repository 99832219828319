import React from 'react';

//components
import { ButtonAdd, DiagnosticItem } from '../schema';

//reactstrap
import { Col } from "reactstrap";

function SubFeature({...props}) {
    const { dataSubFeature, featureId, item } = props && props;
    return (
        <Col md={6}>
            {dataSubFeature?.map((value, index) => {
                return (
                    <DiagnosticItem key={index} item={value} />
                )
            })}
            <ButtonAdd
                {...props}
                buttonText={`Add Sub Feature`}
                item={item} parentId={featureId}
                type="Sub-Feature"
            />
        </Col>
    );
};

export default SubFeature;