import React from 'react';
import Avatar from "@images/chatsmart/avatar.svg";

function LoadingMessage(props) {
    const { value, contentStream, stateData } = props && props;
    return (
        <div className={`messengerCt__wrapper ${value?.ChatStatus === "preceptor" ? "isShow" : ""}`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={stateData?.avatar_url || Avatar}
                        alt="assistant"
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-text">
                            {contentStream && (
                                <div dangerouslySetInnerHTML={{ __html: contentStream?.stream_data }} />
                            )}
                            <div style={{minWidth: '30px'}}>
                                <div className="typing-loader my-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;