import React from 'react'
import ResultGenerate from "@components/teachV2/home/result/ResultGenerate";
import { Button } from 'reactstrap';
import Icon from '@components-common/Icon'
import Reload from '@images/common/reload.svg'
import Tips from '@images/teachV2/tip.svg'
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function ResultMcqWizard(props) {
  const { watch, dataContentFile, loadingResult, toggle } = props
  const history = useHistory();

  return (
    <div className="form-report">
      <div className="form-report__question form-report__result">
        <div className="form-area align-items-start">
          <div className="form-area__content align-items-start">
            <h1>Your Multiple Choice Questions Are Ready!</h1>
            <p>Here are {watch("number_questions") || 4} questions generated based on your input.</p>
          </div>
        </div>
        <div className='quick-tips'>
          <div className="quick-tips__icon">
            <Icon src={Tips}/>
          </div>
          <p>Quick Tips: <br />
            <span>1.  Save your favorites to the Question Bank by clicking the save icon. You can review and edit these later under <b>Question Bank </b>in <b>My Inventory.</b><br />2. Use the copy feature for immediate use of any question.</span>
          </p>
        </div>
        <ResultGenerate dataContentFile={dataContentFile} loadingResult={loadingResult} {...props} />
        <div className="btn-result d-flex align-items-center justify-content-end">
          <Button
            data-tip
            data-for={`btn-regenerate`}
            className="d-flex align-items-center"
            onClick={() => toggle('ModalRegenerate')}
            color='secondary'
            disabled={loadingResult}
          >
            <Icon src={Reload} width={20} height={17}/>
            <span className="ml-1">
              Re-generate New Versions
            </span>
          </Button>
          <ReactTooltip id={`btn-regenerate`} place='top' effect='solid' className="mcq-tooltip">
            <span>Re-generate to get alternate question versions.</span>
          </ReactTooltip>
          <Button
            data-tip
            data-for={`btn-view`}
            onClick={() => history.push('/teach/inventory')}
            color='primary'
            disabled={loadingResult}
          >
            Visit the Question Bank 
          </Button>
          <ReactTooltip id={`btn-view`} place='top' effect='solid' className="mcq-tooltip">
            <span>View your saved questions in the Question Bank.</span>
          </ReactTooltip>
        </div>
      </div>
    </div>
  )
}
