import React from "react";

//components
import { EmailOnly } from "@components/auth/SpecialLogIn";

function SpecialLogInPage(props) {
  return (
    <div className="wrapper -loginPageV2">
      <div className="login_panel vh-100">
        <EmailOnly />
      </div>
    </div>
  );
}

export default SpecialLogInPage;