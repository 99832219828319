import React, { useState, useEffect } from "react";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { actFetchAllCase } from "@actions";

//components
import { DropdownOptions, ModalUpdateDomains, ModalDeleteDomain, ModalCopyDomain } from '../teach/Domains';

function ListDomain(props) {
  const dispatch = useDispatch();
  const allCaseData = useSelector((state) => (state.loadInstitutionAllCases && state.loadInstitutionAllCases.data) || []);

  const { data } = props && props;

  const [modal, setModal] = useState({
    addDomain: false,
    copyDomain: false,
    editDomain: false,
    deleteDomain: false
  });

  const [params, setParams] = useState([]);
  const [actionClick, setActionClick] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actFetchAllCase());
    };
    fetchData();
  }, [dispatch])

  const toggle = (data, type) => {
    setParams((data && data) || {});
    setActionClick(type);
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <React.Fragment>
      <div className="widgetBox -box-shadow mt-4">
        <div className="widgetBox__head">
          <h3 className="wg-title m--font-brand --text-primary">Domains</h3>
        </div>
        <div className="widgetBox__body">
          <ul className="lsDomains">
            {data &&
              data.map((item, index) => (
                <li className="lsDomains__item d-flex align-items-center" key={index}>
                  <div className="lsDomains__left">
                    <p className="font-weight-500">{item.Name}</p>
                  </div>
                  <div className="lsDomains__right">
                    <ButtonEdit handleEdit={() => toggle(item, "editDomain")} />
                    <ButtonDelete handleDelete={() => toggle(item, "deleteDomain")} />
                  </div>
                </li>
              ))}
          </ul>
          <DropdownOptions
            handleItemClick={toggle}
          />
        </div>
      </div>

      {/* /Modal */}
      <ModalUpdateDomains
        {...props}
        toggle={() => toggle(params, actionClick)}
        isOpen={modal?.editDomain || modal?.addDomain}
        item={params}
        actionClick={actionClick}
      />

      <ModalCopyDomain
        {...props}
        toggle={() => toggle(params, 'copyDomain')}
        isOpen={modal?.copyDomain}
        actionClick={actionClick}
        dataCase={(allCaseData && allCaseData) || []}
      />

      <ModalDeleteDomain
        {...props}
        toggle={() => toggle(params, 'deleteDomain')}
        isOpen={modal?.deleteDomain}
        item={params}
        actionClick={actionClick}
      />
    </React.Fragment>
  );
}

export default ListDomain;
