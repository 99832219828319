import React from "react";

//react boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//components
import { HelperContent } from "@components-common/Helper";

function ModalHelper(props) {
  const { isOpen, toggle, data } = props && props;

  return (
    <Modal
      style={{ maxWidth: "800px", padding: "0 10px" }}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">
          <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>
          How can we help?
        </span>
      </ModalHeader>
      <ModalBody>
        <HelperContent data={data || {}} />
      </ModalBody>
      <ModalFooter>
        <Button className="mx-auto" color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalHelper;
