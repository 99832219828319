import React from "react";

//reacttrap
import { Row, Col } from "reactstrap";

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);

function PieChartList(props) {
  const arrColor = ["#2D87BB","#FEAE65","#A9DEA6","#E6F69D","#F66D43","#64C2A7","#F2EA91"];
  const dataProps = props && props.data;
  const Performances = dataProps && dataProps.Performances;
  const GradingFieldsData = Performances && Performances.GradingFieldsData;

  const fillData = (list) => {
    const propertyNames = Object.keys(list);
    const propertyValues =  Object.values(list);
    const dataChart =[
      {
        type: "pie",
        direction: "clockwise",
        sort: false,
        values: propertyValues,
        marker: {
          colors: arrColor
        },
        labels: propertyNames,
        hoverinfo: "label+percent",
        textposition: "none",
        automargin: true,
        hole: 0.4,
      }
    ]
    return dataChart;
  }

  const layout = {
    height: 200,
    width: 200,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    showlegend: false,
  };

  return (
    <React.Fragment>
      {GradingFieldsData && GradingFieldsData.map((elem, index) => {
        const nameElem = elem && elem.GradingFieldName;
        const dataElem = elem && elem.GradingFieldData;
        const convertData = fillData(dataElem);
        const dataNotes = Object.entries(dataElem);
        return (
          <Row className="mx-auto align-items-center mt-3" style={{maxWidth: '800px'}} key={index}>
            <Col sm={12}>
              <h4 className="heading-4 --text-primary">{nameElem}</h4>
            </Col>
            <Col className="text-center" sm={4}>
              <PlotlyComponent data={convertData} layout={layout} />
            </Col>
            <Col sm={8}>
              {dataNotes && dataNotes.map((val, j) => {
                const nameNote = val && val[0];
                const countNote = val && val[1];
                return(
                  <p className="d-flex align-items-center" key={j}>
                    <span
                      style={{ fontSize: "large", color: arrColor[j] }}
                      className="text-center font-weight-600 mr-2"
                    >
                      {countNote || 0}
                    </span>{" "}
                    {nameNote}
                  </p>
                );
              })}
            </Col>
          </Row>
        );
      })}
    </React.Fragment>
  );
}

export default PieChartList;