// ElementsLayout.js
import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { SwitchRoleMobile, SwitchRoleDesktop } from "@ui-partents/SwitchRole";
import { CreateNewQuick } from "@ui-partents/Dropdown";

//Json Demo
import dropdownItems from "./dropdownItems.json";

//beta component
import { BetaComponent } from '@ui-partents/Beta';

const ElementsLayout = ({ title, switchRoleMobile, switchRoleDesktop, dropdownSidebar = false, betaComponent }) => {
    const componentsToRender = {
        mobile: switchRoleMobile && <SwitchRoleMobile key="mobile" isAbsolute={false} />,
        desktop: switchRoleDesktop && <SwitchRoleDesktop key="desktop" isAbsolute={false} />,
        dropdown: dropdownSidebar && <CreateNewQuick key="dropdown" menuData={dropdownItems || []} />,
        betaComponent: betaComponent && <BetaComponent fontSize={10} />
    };

    return (
        <Card className='my-2'>
            <CardHeader>{title}</CardHeader>
            <CardBody>
                {Object.values(componentsToRender).filter(Boolean)}
            </CardBody>
        </Card>
    );
};

export default ElementsLayout;