import React, { useState } from 'react';
import { Col } from "reactstrap";

//components
import { ButtonAdd, FeatureRight, ModalAddDianostic, DiagnosticItem } from '../schema';

function DiagnosticMain({...props}) {
    const { data, QuestionId } = props && props;
    const [modal, setModal] = useState(false);
    const [stateData, setData] = useState({});

    const toggle = (type, parentId, itemParent) => {
        setModal(!modal);
        setData({ type, parentId, itemParent });
    }

    return (
        <React.Fragment>
            {data?.map((value, index) => {
                const relevantFeatures = value?.relevant_features;
                const DiagnosisId = value?.cui;
                return (
                    <div className={`d-flex flex-wrap align-items-center border mb-4`} key={index}>
                        <Col lg={4} md={12}>
                            <DiagnosticItem item={value} />
                        </Col>
                        <Col lg={8} md={12}>
                            <FeatureRight
                                handelSubmit={toggle}
                                relevantFeatures={relevantFeatures || []}
                                DiagnosisId={DiagnosisId}
                                itemFeature={value}
                            />
                        </Col>
                    </div>
                );
            })}
            <ButtonAdd
                buttonText={`Add A Diagnosis`}
                handelSubmit={() => toggle(`Diagnosis`, '', '')}
                type="Diagnosis"
            />
            <ModalAddDianostic
                QuestionId={QuestionId}
                data={(stateData && stateData) || {}}
                isOpen={modal} toggle={toggle}
            />
        </React.Fragment>
    );
};

export default DiagnosticMain;