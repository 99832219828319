import React, { useState } from "react";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";

//actions
import { atcCreateCohort } from "@actions";

//redux
import { useDispatch } from "react-redux";

//partents
import { FormModal } from "@ui-partents/Modal";

//formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const ModalAddCohort = (props) => {
  const { toggle, isOpen } = props && props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // State for loading

  // Validation schema using Yup
  const validationSchema = Yup.object({
    Name: Yup.string().required("This is required."),
  });

  return (
    <Formik
      initialValues={{ Name: "" }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        const params = {
          Action: "Create",
          Name: values.Name,
        };

        setIsLoading(true); // Start loading
        try {
          const response = await dispatch(atcCreateCohort(params));

          if (response.status === 200) {
            toggle(); // Close modal if cohort creation is successful
            resetForm(); // Reset form
          } else {
            console.error("Failed to create cohort:", response.status);
            // Handle errors (e.g., notify user)
          }
        } catch (error) {
          console.error("Error occurred:", error);
          // Handle exceptions (e.g., notify user)
        } finally {
          setIsLoading(false); // Stop loading
        }
      }}
    >
      {({ errors, touched, handleChange, handleBlur, submitForm }) => (
        <FormModal
          isOpen={isOpen}
          toggleModal={toggle}
          title={`Add Cohort`}
          hiddenIcon={true}
          actionText={`Add`}
          maxWidth={`600px`}
          isLoading={isLoading}
          onAction={submitForm} // Directly using submitForm from Formik
        >
          <Form>
            <FormGroup className="mb-0">
              <Label for="Name">
                Name <span className="m--font-danger">*</span>
              </Label>
              <Field
                as={Input}
                type="text"
                name="Name"
                id="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={touched.Name && !!errors.Name}
                placeholder=""
                autoComplete="off"
              />
              {touched.Name && errors.Name && (
                <FormFeedback invalid="true">{errors.Name}</FormFeedback>
              )}
            </FormGroup>
          </Form>
        </FormModal>
      )}
    </Formik>
  );
};

export default ModalAddCohort;