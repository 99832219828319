import React, { useRef } from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'
import TitleNoteCommon from './TitleNoteCommon'
import Select from "react-select";
import { Icon } from '@components/common'
import upload from '@images/teachV2/upload-cloud.svg'
import popular from '@images/teachV2/popular.svg'
import File from '@images/teachV2/file.svg'
import Trash from '@images/teachV2/trash.svg'
import { Controller } from 'react-hook-form';
import { listChatGpt, listPopular } from "@utils";

const WrapForm = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #F2F4F7;
  .form-option,
  .form-upload {
    .wrap-option {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .wrap__select {
        .react-select__control {
          border-radius: 8px;
          box-shadow: none;
        }
        .react-select__indicator {
          padding: 10px 14px;
        }
        .react-select__value-container {
          padding-left: 12px;
        }
      }
      .wrap__select-input {
        .option-detail {
          &:hover {
            background: #0089C2;
            .react-select-item {
              color: #fff;
            }
          }
        }
      }
      &__upload {
        min-height: 120px;
        padding: 12px 24px;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
        cursor: pointer;
        &-icon {
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #EAECF0;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }
        &-text {
          text-align: center;
          p {
            color: #475467;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            span {
              color: #F79E1C;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px; /* 142.857% */
            }
          }
        }
      }
      &__file {
        min-height: 120px;
        &-wrap {
          padding: 16px;
          border-radius: 12px;
          background: #FFF;
          display: flex;
          align-items: start;
          gap: 8px;
          border: 1px solid #EAECF0;
        }
        &-info {
          width: 100%;
          display: flex;
          align-items: start;
          gap: 8px;
          h4 {
            color: #344054;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }
          p {
            color: #475467;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
          }
        }
        &-trash {
          cursor: pointer;
          &:hover {
            svg {
              path {
                stroke: red;
              }
            }
          }
        }
      }
    }
  }
`

const GenerateOption = (props) => {
  const { dataFileImported, setDataFileImported, listTemplate, control, setValue, watch } = props;
  const hiddenFileInputText = useRef(null);

  const Option = ({ innerProps, label, data, value }) => {
    return (
      <div
        {...innerProps}
        className='option-detail'
        style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <span className="react-select-item">{label}</span>
        {listPopular?.includes(label) &&  <div>
          <Icon src={popular} />
        </div>}
      </div>
    );
  };
  
  const handleClick = event => {
    hiddenFileInputText.current.click();
  };

  const handleDrag = function(e) {
    e.preventDefault();
  }

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    setDataFileImported(type === "change" ? event.target.files[0] : event.dataTransfer.files[0])
  };

  return (
    <WrapForm>
      <div className='form-option w-100'>
        <Row>
          <Col md={6}>
            <div className='wrap-option'>
              <TitleNoteCommon dataFor={'template'} title={'Template'} content={'Select the template for your multiple-choice questions to be based on.'} />
              <Controller
                control={control}
                name="template_id"
                defaultValue={2}
                render={({ field: { onChange }}) => (
                  <>
                    <Select
                      className="wrap__select-input w-100"
                      placeholder="Select Modal"
                      value={listTemplate.find((c) => c.value === watch("template_id"))}
                      options={listTemplate}
                      onChange={val => {
                        setValue("template_id", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select"
                      components={{
                        IndicatorSeparator: () => null,
                        Option: Option
                      }}
                    />
                  </>
                )}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className='wrap-option'>
              <TitleNoteCommon dataFor={'model'} title={'Model'} content={'Select the AI model used for generation.'} />
              <Controller
                control={control}
                name="gpt_model"
                defaultValue={"gpt-3.5-turbo"}
                render={({ field: { onChange }}) => (
                  <>
                    <Select
                      className="wrap__select-input w-100"
                      placeholder="Default"
                      value={listChatGpt.find((c) => c.value === watch("gpt_model"))}
                      options={listChatGpt}
                      onChange={val => {
                        setValue("gpt_model", val.value)
                        onChange(val.value)
                      }}
                      classNamePrefix="react-select"
                      components={{
                        IndicatorSeparator: () => null,
                        Option: Option
                      }}
                    />
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className='form-upload w-100'>
        <div className='wrap-option'>
          <TitleNoteCommon dataFor={'document'} title={'Attach a Document'} content={'Upload any relevant files, such as lectures or reference materials, to provide the AI with more context for generation.'} />
          {!dataFileImported ? (
            <div className='wrap-option__upload' onDragOver={handleDrag} onDrop={(e) => handleChange(e, "drop")} onClick={handleClick}>
              <input
                type="file"
                onChange={(e) => handleChange(e, "change")}
                ref={hiddenFileInputText}
                name="text"
                style={{ display: "none" }}
              />
              <div className='wrap-option__upload-icon'>
                <Icon src={upload}/>
              </div>
              <div className='wrap-option__upload-text'>
                <p className="list__input-item--note"><span>Click to upload</span> or drag and drop your file here</p>
                <p className="list__input-item--note">PDF, PPT, Word, TXT</p>
              </div>
            </div>
          ) : (
            <div className="wrap-option__file">
              <div className="wrap-option__file-wrap">
                <div className="wrap-option__file-info">
                  <Icon src={File}/>
                  <div className='progress-loading'>
                    <h4>{dataFileImported?.name}</h4>
                    <p>{(dataFileImported?.size / (1024*1024)).toFixed(2)}MB</p>
                  </div>
                </div>
                <div className='wrap-option__file-trash' onClick={() => setDataFileImported(null)}>
                  <Icon src={Trash}/>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </WrapForm>
  )
}

export default GenerateOption
