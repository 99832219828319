import React from "react";

//Tabs
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const TabListStudiesEdit = (props) => {
    const { toggleTabs, activeTab } = props && props;
    return (
        <Nav className="tabBorder" tabs>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                        toggleTabs("1");
                    }}
                >
                    Content
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                        toggleTabs("2");
                    }}
                >
                    Media
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                        toggleTabs("3");
                    }}
                >
                    Explanation
                </NavLink>
            </NavItem>
        </Nav>
    );
};

export default TabListStudiesEdit;