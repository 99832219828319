import React, { useState, useEffect } from "react";

//timezone
import TimezoneSelect from "react-timezone-select";

//boostrap
import { Col, Row, FormGroup, Label } from "reactstrap";

//redux
import { useDispatch, useSelector } from 'react-redux';

//call Action
import {
  setInstitutionTimeZone
} from "@actions";

function InstitutionSettings(props) {
  //props
  const dataTimeZone = useSelector((state) => (state.loadTimeZoneInstitution && state.loadTimeZoneInstitution.data) || {});

  //state
  const dispatch = useDispatch();
  const [selectedTimezone, setSelectedTimezone] = useState(dataTimeZone || {});

  const handleChange = (value) => {
    setSelectedTimezone(value);
    dispatch(setInstitutionTimeZone(value));
  }

  //setState
  useEffect(() => {
    setSelectedTimezone(dataTimeZone || {});
  }, [dataTimeZone]);
  
  return (
    <div className="inforGroup mt-3">
      <h5 className="heading-5 font-weight-500 --text-primary">
        INSTITUTION SETTINGS
      </h5>
      <Row className="mb-3">
        <Col sm={6}>
          <FormGroup>
            <Label for="exampleEmail">Set the time zone:</Label>
            <TimezoneSelect
              value={selectedTimezone?.Timezone || {}}
              onChange={handleChange}
              placeholder="Select a timezone..."
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default InstitutionSettings;
