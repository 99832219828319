import React from 'react';
import styled from 'styled-components';

// Styled component for the avatar group
const AvatarGroupStyled = styled.ul`
    display: flex;
    align-items: center;
    position: relative;
    margin-left: ${({ marginLeft }) => marginLeft || '100px'};

    @media (max-width: 600px) {
        margin-left: 0; // Adjust margin for mobile view
    }
`;

const AvatarItemStyled = styled.li`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    border: 2px solid var(--base-white);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: ${({ size }) => -size / 4}px;

    // Set background image or fallback color
    background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
    background-color: ${({ image }) => (image ? 'transparent' : '#D0D5DD')};
`;

const NotInListText = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    border: 2px solid var(--base-white);
    background-color: #D0D5DD; // Gray background color
    color: #475467; // Text color for the count
    font-size: ${({ size }) => {
        if (size === 24) return '12px';
        if (size === 36) return '14px';
        if (size === 40) return '16px';
        return '14px'; // Default font size if no match
    }};
    font-weight: 600;
    margin-left: ${({ size }) => -size / 4}px;
`;

function AvatarGroup({ avatarData, marginLeft, NotInListNum, size = 24 }) {
    return (
        <AvatarGroupStyled marginLeft={marginLeft}>
            {avatarData.map((student, index) => (
                <AvatarItemStyled
                    key={index}
                    image={student?.ProfilePhotoURL}
                    size={size}
                />
            ))}

            {NotInListNum > 1 && (
                <NotInListText size={size}>+{NotInListNum}</NotInListText>
            )}
        </AvatarGroupStyled>
    );
}

export default AvatarGroup;