import React from 'react';

//reactstrap
import { FormGroup } from 'reactstrap';

//textarea
import TextareaAutosize from "react-textarea-autosize";

function ProposalTemplateContent(props) {
    const { handleProposalTemplate, stateProposalTemplate } = props && props;
    return (
        <FormGroup>
            <TextareaAutosize
                name="proposal_template"
                placeholder=""
                className="w-100 form-control"
                maxRows={12}
                minRows={7}
                onChange={handleProposalTemplate}
                value={stateProposalTemplate}
            />
        </FormGroup>
    );
};

export default ProposalTemplateContent;