// Questions.js
import React from 'react';

//components
import { QuestionMain } from "../QuestionCommon";

const QuestionsOrders = (props) => {
  return (
    <div className="questions">
      <QuestionMain {...props} />
    </div>
  );
};

export default QuestionsOrders;