import React, { useState } from "react";

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//components
import { ListTabFull } from "@components-common";

function TabsGroup(props) {
  //props
  const { data } = props && props;

  //state
  const [activeTab, setActiveTab] = useState("1");

  //handle Tabs
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  
  return (
    <div className="tabsFullBg">
      <ListTabFull toggle={toggle} activeTab={activeTab} navList={data} />
      <TabContent activeTab={activeTab}>
        {data?.map((value, index) => (
            <TabPane key={index} tabId={(index + 1).toString()}>
              {value.Component}
            </TabPane>
          ))}
      </TabContent>
    </div>
  );
}

export default TabsGroup;
