import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Images
import logoMakansafe from '@images/makansafe/logo.png';

//components
import { AuthContainer, BannerLogin, LoginForm } from "@components/Makansafe";

//untils
import { checkAuth } from "@utils";

function MakansafeLoginPage(props) {
  let history = useHistory();

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  return (
    <AuthContainer>
      <div className="col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
        <div className="form-makansafe-ct">
          <div className="logo-makansafe__logo">
            <img src={logoMakansafe} alt="logo" />
          </div>
          <div className="logo-makansafe__title">
            <h1>
              Welcome to
              <span className="text-blue">SACH</span>
              <span className="text-yellow">MakanSafe</span><br />
              <span>Data Manager</span>
            </h1>
          </div>
          <div className="form-makansafe-login">
            <p className="desc-form">Please enter the information below to login.</p>
            <LoginForm />
          </div>
        </div>
      </div>
      <BannerLogin {...props} />
    </AuthContainer>
  );
}

export default MakansafeLoginPage;