import React from "react";
import ReactPlayer from "react-player";

//boostrap
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

function ModalViewMedia (props) {
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const data = props && props.data;
  
  return (
    <Modal
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>{(data && data.Title) || (data && data.Name)}</ModalHeader>
      <ModalBody>
        <div
          className="mb-3"
          dangerouslySetInnerHTML={{
            __html: (data && data.Report) || (data && data.Result),
          }}
        ></div>

        {data && data.MediaType === "VideoLink" && (
          <div className="playerWrapper">
            <ReactPlayer
              url={data.Url || data.MediaLink}
              name={data.MediaType}
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
        )}
        {data && data.MediaType === "AudioFile" && (
          <ReactPlayer
            url={data.Url || data.MediaLink}
            name={data.MediaType}
            width="100%"
            height="50px"
            controls={true}
            loop={true}
          />
        )}
        {data && data.MediaType === "VideoFile" && (
          <ReactPlayer
            url={data.Url || data.MediaLink}
            name={data.MediaType}
            width="100%"
            height="100%"
            controls={true}
            loop={true}
          />
        )}
        {data && data.MediaType === "ImageFile" && (
          <img
            className="d-block mx-auto"
            src={data.Url || data.MediaLink}
            style={{ maxWidth: "300px" }}
            alt={data.Title}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalViewMedia;
