import React, { useState } from "react";

//boostrap
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

//classnames
import classnames from "classnames";

//utils
import { LoaderText } from "@utils";

//components
import {
  TabFeedbackTemplate,
  TabTakeHome,
  SelfReflection,
} from "./EndingTimepoint/";

function EndingTimePointMain(props) {
  //props
  const { isLoadingTimePoint } = props && props;

  //tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const listTabs = [
    {
      id: "1",
      name: "Take-Home Message",
      icon: "fa fa-bullhorn",
      component: <TabTakeHome {...props} />,
    },
    {
      id: "2",
      name: "Feedback Template",
      icon: "fa fa-commenting",
      component: <TabFeedbackTemplate {...props} />,
    },
  ];
  return (
    <div className="endingCt position-relative">
      {isLoadingTimePoint && <LoaderText />}
      <SelfReflection {...props} />
      <div className="tabsPills">
        <Nav tabs>
          {listTabs?.map((item, index) => (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === item.id })}
                onClick={() => {
                  toggle(item.id);
                }}
              >
                <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                {item.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {listTabs.map((item, index) => (
            <TabPane key={index} tabId={item.id}>
              {item.component}
            </TabPane>
          ))}
        </TabContent>
      </div>
    </div>
  );
}

export default EndingTimePointMain;
