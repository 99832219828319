import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import BounceLoader from "react-spinners/BounceLoader";

import iconResetPassword from "@images/common/padlock.svg";
import iconResetPasswordFailed from "@images/common/padlock-red.svg";
import checkEmail from "@images/common/vote.svg";
import userExisted from "@images/common/exclamation-mark.svg";

function ContentProcess(props) {
  const { data, icon, backLogin, backBilling, backHistory } = props || {};
  
  // Function to render different icons based on 'icon' prop
  const renderIcon = () => {
    switch (icon) {
      case "checked":
        return (
          <svg
            className="svgCheck"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="path check"
              fill="none"
              stroke="#73AF55"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        );
      case "not-checked":
        return (
          <svg
            className="svgCheck"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
          >
            <circle
              className="path circle"
              fill="none"
              stroke="#f4516c"
              strokeWidth="6"
              strokeMiterlimit="10"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <polyline
              className="path check"
              fill="none"
              stroke="#f4516c"
              strokeWidth="6"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
          </svg>
        );
      case "loader":
        return (
          <BounceLoader
            loading={true}
            color="#34bfa3"
            css={css`
              display: block;
              margin: 0 auto;
              border-color: #34bfa3;
            `}
            size={80}
          />
        );
      case "reset-password-success":
        return (
          <img
            className="mx-auto d-block"
            width="120px"
            src={iconResetPassword}
            alt="Reset password success"
          />
        );
      case "reset-password-failed":
        return (
          <img
            className="mx-auto d-block"
            width="120px"
            src={iconResetPasswordFailed}
            alt="Reset password failed"
          />
        );
      case "email-sent":
        return (
          <img
            className="mx-auto d-block"
            width="80px"
            src={checkEmail}
            alt="Check Email"
          />
        );
      case "email-existed":
        return (
          <img
            className="mx-auto d-block"
            width="80px"
            src={userExisted}
            alt="User Already Existed"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="messageCt text-center">
            {renderIcon()} {/* Render the appropriate icon */}
            <h2
              className={`heading-2 messageCt__heading mb-0 --text-primary`}
              dangerouslySetInnerHTML={{ __html: data && data.title }}
            ></h2>
            <p
              className="messageCt__content"
              dangerouslySetInnerHTML={{ __html: data && data.description }}
            />
            {backLogin && (
              <Link
                to={`/login`}
                className="btn btn-brand mt-5 --btn-primary ml-auto"
              >
                <i
                  className="fa fa-angle-left mr-2 text-white"
                  aria-hidden="true"
                ></i>
                Go To Log In Page
              </Link>
            )}
            {backBilling && (
              <Link
                to={`/adminstrator-tool?active=billing`}
                className="btn btn-brand mt-5 --btn-primary ml-auto"
              >
                <i
                  className="fa fa-angle-left mr-2 text-white"
                  aria-hidden="true"
                ></i>
                Return To Billing Page
              </Link>
            )}
            {backHistory && (
              <Link
                to={`/teach/home`}
                className="btn btn-brand mt-5 --btn-primary ml-auto"
              >
                <i
                  className="fa fa-angle-left mr-2 text-white"
                  aria-hidden="true"
                ></i>
                Back
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ContentProcess;