import React, { useState } from "react";

//reactstrap
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

//react-hook-form
import { useFormContext } from "react-hook-form";

//classnames
import classnames from "classnames";

//components
import { GeneralTab, IdentificationTab } from "../QuestionSchemaTab/Cell";

function MainTabs(props) {
    const methods = useFormContext();
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const listTabs = [
        { id: "1", name: "General", component: <GeneralTab {...methods} props={props} /> },
        { id: "2", name: "Identification", component: <IdentificationTab {...methods} props={props} /> }
    ];
    
    return (
        <React.Fragment>
            <Nav className="tabBorder" tabs>
                {listTabs?.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({ active: activeTab === item.id })}
                            onClick={() => {
                                toggle(item.id);
                            }}
                        >
                            <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                            {item.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {listTabs?.map((item, index) => (
                    <TabPane key={index} tabId={item.id}>
                        {item.component}
                    </TabPane>
                ))}
            </TabContent>
        </React.Fragment>
    );
}

export default MainTabs;