import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormGroup, Row, Col, Label } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//boostrap
import { Card, CardBody } from "reactstrap";

//lodash
import _ from "lodash";

//call Action
import {
  atcLoadAllCohorts,
  actLoadAllPeriods,
} from "@actions";

//components
import { PeriodsFilter, InputFilter } from '../Domains';

function FilterTop(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { handleChange, handleChangeOptions, itemActive, stateData, domainData } = props && props;

  useEffect(() => {
    const fetchData = async () => {
      //load Cohorts
      dispatch(atcLoadAllCohorts());
      
      //load Periods
      const params = { Action: "Load" };
      dispatch(actLoadAllPeriods(params));
    };

    fetchData();
  }, [dispatch, id]);

  //data store
  const cohortStore = useSelector(
    (state) => (state.listCohort && state.listCohort.data) || []
  );

  const periodsData = useSelector(
    (state) => (state.loadPeriods && state.loadPeriods.data) || []
  );

  return (
    <Card className="border-0 mb-3">
      <CardBody className="-box-shadow">
        <Row className="align-items-center">
          <Col md={4}>
            <InputFilter
              handleChange={handleChange}
              name="Domain"
              placeholder={`Select an item below...`}
              data={(domainData && domainData) || []}
              type="DomainId"
              paramsOptions={{ value: 'Id', content: 'Name' }}
              isDisabled={false}
              defaultValue={stateData?.DomainId}
            />
          </Col>
          <Col md={4}>
            <InputFilter
              handleChange={handleChange}
              name="Cohort"
              placeholder={`All Enrolled Learners`}
              data={(cohortStore && cohortStore) || []}
              type="CohortId"
              paramsOptions={{ value: 'CohortId', content: 'Name' }}
              defaultValue={stateData?.CohortId}
              isDisabled={_.isEmpty(stateData?.DomainId)}
            />
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="Periods">Periods: </Label>
              <PeriodsFilter
                data={(periodsData && periodsData) || []}
                itemActive={itemActive}
                handleChange={handleChangeOptions}
                isDisabled={_.isEmpty(stateData?.DomainId) || _.isEmpty(stateData?.CohortId)}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default FilterTop;
