import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'

const WrapResultExplan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 10px;
  .answer {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #101828;
    &.correct-answer {
      color: #079455;
    }
    &.wrong-answer {
      color: #B13E0C;
    }
  }
  .text-explanation {
    padding-left: 20px;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export default function ItemResult(props) {
  const { choice, question, refAnswer } = props
  const [showData, setShowData] = useState(false)
  
  const handleShowData = () => {
    setShowData(!showData)
  }

  return (
    <WrapResultExplan>
      <div className='d-flex align-items-start justify-content-between'>
        <p className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} ${(choice.selected_by_user && choice.id !== question?.best_answer?.id) ? "wrong-answer" : ""} answer`}>
          {choice.id}. <span ref={refAnswer}>{choice.content}</span>
        </p>
        {choice.id !== question?.best_answer?.id && (
          <div onClick={handleShowData}>
            {showData ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
          </div>
        )}
      </div>
      {showData && (
        <div className='text-explanation'>
          <p>Explanation: <span>{question?.explanation?.[choice?.id]?.explanation || question?.best_answer?.explanation}</span></p>
        </div>
      )}
    </WrapResultExplan>
  )
}
