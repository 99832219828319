import React, { useEffect, useState, useRef } from "react";

//router
import { useParams } from "react-router-dom";

// common
import { HeadingBox } from "@components/common";

//boostrap
import { Container, Row, Col } from "reactstrap";

//call Action
import { atcLoadAllQuestion, atcReportCaseQuestion } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Api
import { apiCaller } from "@utils";

//file-saver
import { saveAs } from 'file-saver';

//components
import {
  FilterTop,
  QuestionContent,
} from "@components/reports/Questions";

//loader
import { LoaderText, LoaderPage } from "@utils";

function QuestionReportPage(props) {

  //props 
  const { caseDetails } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();

  //ref
  const refTop = useRef(null);

  //state
  const [stateData, setStateData] = useState({
    CohortId: null,
    PeriodId: null,
    QuestionId: ''
  });
  const [itemActive, setItemActive] = useState({});
  const [isLoad, setLoad] = useState(false);

  //Load All Questions
  useEffect(() => {
    const fetchAllQuestions = async () => {
      const params = {
        Action: "Load",
        Target: "AllQuestions",
      };
      dispatch(atcLoadAllQuestion(id, params));
    };
    fetchAllQuestions();
  }, [dispatch, id]);

  //click item
  const handleItem = (details) => {
    setItemActive(details);

    //Set State Question ID
    setStateData((prevState) => ({ ...prevState, QuestionId: details?.QuestionId }));
    setLoad(true);
  };

  //handle select
  const hanldeChange = (name, value) => {
    setStateData((prevState) => ({ ...prevState, [name]: value }));
    setLoad(true);
  };
  
  //Set Load Api if has State
  useEffect(() => {
    if(isLoad) {
      const params = {
        ReportType: "question_Records",
        QuestionId: stateData.QuestionId,
        CohortId: stateData.CohortId || null,
        PeriodId: stateData.PeriodId || null,
      };
      dispatch(atcReportCaseQuestion(id, params));
      setLoad(false);
    }
  }, [isLoad, id, dispatch, stateData]);

  const getDateString = () => {
    const date = new Date();
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hour = date.getHours();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day =`${date.getDate()}`.padStart(2, '0');
    return `${year}${month}${day}_${hour}${minutes}`
  }

  const handleExportCsv = () => {
    const params = {
      ReportType: "question_Records",
      QuestionId: stateData.QuestionId,
      CohortId: stateData.CohortId || null,
      PeriodId: stateData.PeriodId || null,
      ExportFileFormat: "CSV"
    };

    apiCaller(`/api/teach/report/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        var blob = new Blob([res?.data], {type: "text/csv;charset=utf-8"});
        saveAs(blob, `question_${stateData?.QuestionId}_${getDateString()}.csv`);
      } else {
        console.log('error');
      }
    });
  }

  //data store
  const questionData = useSelector((state) => state.loadAllQuestion);
  const questionRecord = useSelector((state) => state.getQuestionRecord);
  const statisticsQuestionReport = useSelector((state) => state.loadStatisticsQuestionReport);  //Load statistics
  
  //title
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -reportPage">
      {questionData.isLoading && <LoaderPage />}
      {!questionData.isLoading && (
        <React.Fragment>
          <div className="reportCt" ref={refTop}>
            <Container>
              <Row>
                <Col sm={12}>
                  <HeadingBox title={titlePage || ""} currentPage="Questions" />
                </Col>
              </Row>
              <Row className="gutters-sm">
                <Col md={12} className="mt-3">
                  <FilterTop
                    stateData={stateData}
                    hanldeChange={hanldeChange}
                    itemActive={itemActive}
                    questionData={questionData?.data}
                    handleItem={handleItem}
                  />
                </Col>
                <Col md={12} className="mt-3 position-relative">
                  {questionRecord.isLoading && <div style={{height: '400px'}}><LoaderText /></div>}
                  {!questionRecord.isLoading && (
                    <QuestionContent
                      {...props}
                      data={(questionRecord && questionRecord.data) || []}
                      statisticsQuestionReport={(statisticsQuestionReport && statisticsQuestionReport) || {}} //Load statistics
                      itemActive={itemActive || {}}
                      paramsActive={stateData}
                      handleExportCsv={handleExportCsv}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default QuestionReportPage;