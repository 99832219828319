import React, { useRef } from "react";
import JoditEditor from "jodit-react";

//reactstrap
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input
} from "reactstrap";

//Config
import * as Config from "@constants/Config";

const StartingPage = (props) => {
    const editor = useRef(null);

    //props
    const { handleChangeMessage, data } = props && props;
    const Messages = data?.Messages;
    const IntroMessage = Messages?.IntroMessage;
    const title = IntroMessage?.title;
    const text = IntroMessage?.text;

    return (
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label for="Title">
                        Title:
                    </Label>
                    <Input
                        id="Title"
                        name="Title"
                        placeholder=""
                        type="text"
                        value={title}
                        onChange={(e) => handleChangeMessage('IntroMessage', 'title', e.target.value)}
                        autoComplete="off"
                    />
                </FormGroup>
            </Col>
            <Col md={12}>
                <FormGroup>
                    <Label for="Message">
                        Message:
                    </Label>
                    <JoditEditor
                        ref={editor}
                        value={text}
                        name={`IntroMessage`}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => handleChangeMessage('IntroMessage', 'text', newContent)}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
};

export default StartingPage;
