import React from 'react';
import { Icon } from '@components/common'; // Assuming Icon is a custom component for displaying SVG icons
import { Button } from 'reactstrap'; // Assuming you're using reactstrap for buttons
import { useHistory } from 'react-router-dom'; // For navigating to a new route

// Import SVG icons dynamically
import ErrorIcon from '@images/login/error.svg';
import SuccessIcon from '@images/login/success.svg';

const ModalSSOMessage = ({ dataMessage }) => {
    const history = useHistory();

    return (
        <div className="modal-completed-form">
            <div className="modal-completed-form__icon">
                <Icon src={dataMessage.error ? ErrorIcon : SuccessIcon} />
            </div>
            <div className="modal-completed-form__content text-center">
                <p>{dataMessage.title}</p>
                <div dangerouslySetInnerHTML={{ __html: dataMessage.message }} />
            </div>
            <div className="modal-completed-form__action">
                {dataMessage.buttons.map((button, index) => (
                    <Button
                        key={index}
                        color={button.ButtonStyle}
                        className="modal-completed-form__action-btn btn btn-primary"
                        onClick={() => history.push(button.URL)}
                    >
                        {button.ButtonTitle}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default ModalSSOMessage;