import React, { useState } from 'react';

// Dropdown
import { DropdownCommon } from "@ui-partents/Dropdown";

// Components
import ModalUploadReferenceDocument from "./ModalUploadReferenceDocument";
import { ModalImportFromDB } from "../ReferenceLiblary/ImportFromDB";

const AddNewReferenceDropdown = (props) => {
    const [modalStatus, setModalStatus] = useState({ isOpen: false, type: '' }); // Combined state for modal status

    const menuData = [
        { to: '#', text: 'Upload from Computer', type: 'uploadDocument' },
        { to: '#', text: 'Import from Databases', type: 'importDocument' }
    ];

    const toggleModal = (type = '') => {
        setModalStatus({ isOpen: !!type, type }); // Open if type exists, close if empty
    };

    const handleDropdownItemClick = (item) => {
        console.log('Clicked item:', item);
        toggleModal(item.type); // Toggle modal based on the item's type
    };

    const renderModal = () => {
        if (!modalStatus.isOpen) return null; // Only render if a modal is open

        switch (modalStatus.type) {
            case 'uploadDocument':
                return (
                    <ModalUploadReferenceDocument
                        isOpen={modalStatus.isOpen}
                        toggleModal={() => toggleModal()} // Close modal by resetting
                        title="Upload Reference Document"
                        {...props}
                    />
                );
            case 'importDocument':
                return (
                    <ModalImportFromDB
                        isOpen={modalStatus.isOpen}
                        toggleModal={() => toggleModal()} // Close modal by resetting
                        title="Import from Databases"
                        {...props}
                    />
                );
            default:
                return null; // No modal to render
        }
    };

    return (
        <div className='ml-2'>
            <DropdownCommon
                menuData={menuData}
                textDropdown="Add New Reference"
                onItemClick={handleDropdownItemClick}
            />
            {renderModal()}
        </div>
    );
};

export default AddNewReferenceDropdown;