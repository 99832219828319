import { medicationConstants, storyboardConstants } from "@constants";
import _, { isObject } from 'lodash';

export function loadMedications(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case medicationConstants.LOAD_MEDICATIONS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case medicationConstants.LOAD_MEDICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case medicationConstants.UPDATE_AVAILABLE_IN_BATCH_MEDICATION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.UPDATE_EFFECT_SUCCESS:
            newState = { ...state }
            const filterDataCategory = _.filter(newState && newState.data, value => value.CategoryId === action.idCategory);
            const filterInterventions = filterDataCategory.map((item) => {
                return item.Interventions;
            });

            filterInterventions.map((item) => {
                var ischeck = false;
                for (var i = 0; i < item.length; i++) {
                    if (item[i].Effects.length > 0 && item[i].Id === action.interventionId) {
                        for (var j = 0; j < item[i].Effects.length; j++) {
                            if (item[i].Effects[j] !== 'undefined' && item[i].Effects[j].EffectId === action.payload.EffectId) {
                                ischeck = true;
                                for (var k = 0; k < item[i].Effects.length; k++) {
                                    if (Number(item[i].Effects[j].EffectId) === Number(action.payload.EffectId)) {
                                        if (isObject(item[i].Effects[j])) {
                                            item[i].Effects[j] = action.payload;
                                        }
                                    }
                                }
                            }
                            if (ischeck === true) {
                                break;
                            }
                        }
                    }
                    if (ischeck === true) {
                        break;
                    }
                }
                return true;
            });

            return {
                ...newState,
                data: newState.data,
                isLoading: false,
                isUpdate: true
            };
        case storyboardConstants.CREATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS:
            newState = { ...state }
            const filterDataById = _.filter(
                newState && newState.data, value => value.CategoryId === action.idCategory
            );

            const newItem = {
                Available: (action.payload && action.payload.Available),
                Id: (action.payload && action.payload.InterventionId),
                Name: (action.payload && action.payload.Name),
                Effects: []
            }
            filterDataById[0]?.Interventions.push(newItem);

            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case storyboardConstants.UPDATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS:
            newState = { ...state }
            const filterDataUpdateById = _.filter(
                newState && newState.data, value => Number(value.CategoryId) === Number(action.idCategory)
            );

            filterDataUpdateById.map((item) => {
                const dataMap = item && item.Interventions;
                const index = dataMap.findIndex(x => x.Id === (action.payload && action.payload.InterventionId));
                dataMap[index].Id = (action.payload && action.payload.InterventionId)
                dataMap[index].Name = (action.payload && action.payload.Name)
                dataMap[index].Available = (action.payload && action.payload.Available)
                dataMap[index].Effects = []
                dataMap[index].ActionMessage =  (action.payload && action.payload.ActionMessage)
                dataMap[index].ActionMessageDuration =  (action.payload && action.payload.ActionMessageDuration)
                return true;
            });

            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case storyboardConstants.CREATE_EFFECTS_SUCCESS:
            newState = { ...state }
            const FilterMedicationsByCategoryId = _.filter(
                newState && newState.data, value => Number(value.CategoryId) === Number(action.idCategory)
            );

            FilterMedicationsByCategoryId.map((item) => {
                const dataMap = item && item.Interventions;
                const index = dataMap.findIndex(x => x.Id === action.InterventionId);
                dataMap[index].Effects.push(action.payload);
                return true;
            });
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case storyboardConstants.DELETE_INTERVENTION_SUCCESS:
            newState = { ...state }
            const filterDataDeleteByIdCat = _.filter(
                newState && newState.data, value => Number(value.CategoryId) === Number(action.idCategory)
            );

            filterDataDeleteByIdCat.map((item) => {
                const dataMap = item && item.Interventions;
                dataMap.map((data, index) => {
                    if (data.Id === action.InterventionId) {
                        delete dataMap[index];
                    }
                    return true;
                });
                return true;
            });
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case storyboardConstants.DELETE_EFFECTS_SUCCESS:
            newState = { ...state }
            const filterByCat = _.filter(
                newState && newState.data, value => Number(value.CategoryId) === Number(action.idCategory)
            );
            filterByCat.map((item) => {
                const dataMap = item && item.Interventions;
                dataMap.map((data) => {
                    if (data.Id === action.InterventionId) {
                        const dataRemove = data && data.Effects;
                        const indexEffects = data && data.Effects.findIndex(item => item.EffectId === action.effectId);
                        delete dataRemove[indexEffects];
                    }
                    return true;
                });
                return true;
            });
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case medicationConstants.CREATE_MEDICATION_CATEGORY_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case medicationConstants.UPDATE_MEDICATION_CATEGORY_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case medicationConstants.DELETE_MEDICATION_CATEGORY_SUCCESS:
            newState = { ...state };
            const listMedications = newState && newState.data;
            const categoryIdCurrent = action.categoryId;
            if ((action.payload && action.payload.status === 'Success') && listMedications) {
                const filterDataByCategoryId = _.filter(listMedications, value => Number(value.CategoryId) !== Number(categoryIdCurrent));
                newState.data = filterDataByCategoryId;
            }
            return {
                ...newState,
                isLoading: false
            };
        case medicationConstants.UPDATE_AVAILABILITY_FOR_MEDICATION_SUCCESS:
            newState = { ...state }
            newState.data = action.payload
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case medicationConstants.UPDATE_ATTRIBUTE_SCORE_MEDICATION_SUCCESS:
            newState = { ...state };
            const AttributeId = action.attributeId;
            const CategoryId = action.categoryId;
            const indexItem = _.findIndex(newState.data, item => item.CategoryId === CategoryId);
            if (indexItem !== -1) {
                const dataInterventions = newState.data[indexItem].Interventions
                const indexInterventions = _.findIndex(dataInterventions, item => item.Id === AttributeId);
                if(indexItem !== -1) {
                    dataInterventions[indexInterventions].DomainScores = action.payload
                }
            }
            return {
                ...newState,
                isLoading: false
            };
        case medicationConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_SUCCESS:
            newState = { ...state }
            const filterByCatId = _.filter(newState && newState.data, value => Number(value.CategoryId) === Number(action.categoryId));
            filterByCatId.map((item) => {
                const dataMap = item && item.Interventions;
                dataMap.map((data) => {
                    if (data.Id === action.interventionId) {
                        data['Available'] = action.payload.Available
                        data['Id'] = action.payload.InterventionId
                        data['Name'] = action.payload.Name
                        data['Effects'] = action.payload.Effects || []
                    }
                    return true;
                });
                return true;
            });
            
            return {
                ...newState,
                isLoading: false
            };
        case medicationConstants.LOAD_MEDICATIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};