import React from "react";

//reactstrap
import { FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function TextareaAutosizeInput(props) {
    const { handleChange, nameState } = props && props;
    return (
        <FormGroup>
            <Label className="font-weight-500">Text:</Label>
            <TextareaAutosize
                name="text"
                placeholder=""
                onChange={handleChange}
                className="w-100 form-control"
                maxRows="10"
                minRows={2}
                defaultValue={nameState?.Text || ""}
                autoFocus
            />
        </FormGroup>
    );
}

export default TextareaAutosizeInput;