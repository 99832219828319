import React, { useEffect } from "react";

//redux
import { useSelector } from "react-redux";

//components
import { ActionsLearnerPrimary, TasksMain, TasksBottom } from ".";

function CaseTasksSection(props) {
  //props
  const { handleOverideEffectMenu } = props && props;

  //get question
  const questionData = useSelector((state) => state?.question?.data || {});
  let isQuestion = questionData?.Question;

  //Check Remove and add effects
  useEffect(() => {
    if (isQuestion !== null) {
      handleOverideEffectMenu();
    }
  }, [handleOverideEffectMenu, questionData, isQuestion]);

  return (
    <React.Fragment>
      <ActionsLearnerPrimary
        {...props}
        questionData={questionData}
      />
      <TasksMain type="bottom">
        <TasksBottom
          {...props}
        />
      </TasksMain>
    </React.Fragment>
  );
}

export default CaseTasksSection;