import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

// Define the global style that appends the .sortable class to the head
const GlobalStyle = createGlobalStyle`
  .sortable {
    white-space: nowrap;
    position: relative;
  }
`;

const SortCaretWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-left: 7px;
  position: relative;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  svg {
    display: block;
    margin: 1px 0; /* Adjust spacing as needed */
  }

  .inactive {
    opacity: 0.5;
  }

  .active {
    opacity: 1;
  }
`;

const SortCaret = ({ order }) => {
  return (
    <>
      <GlobalStyle />
      <SortCaretWrapper>
        {/* Up Icon */}
        <span className={order === 'asc' ? 'active' : 'inactive'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
            <path d="M6.87755 3.99651L3.99981 1.11877L1.12207 3.99651" stroke="#1D2939" strokeWidth="1.07915" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>

        {/* Down Icon */}
        <span className={order === 'desc' ? 'active' : 'inactive'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none">
            <path d="M1.12099 1.00353L4.00168 3.87773L6.87646 0.99646" stroke="#1D2939" strokeWidth="1.07915" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </span>
      </SortCaretWrapper>
    </>
  );
};

export default SortCaret;