import React, { useState, useEffect } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";

//boostrap
import { Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";

//components
import { ProfileBox } from "@components/common/Profile";

//Modal
import { ModalUpload } from "./Modal";

//lodash
import _ from "lodash";

//image
import { Link } from "react-router-dom";

//action
import { atcRequestInstructor } from "@actions";

//alert
import { toast } from 'react-toastify';
import ModalAvatar from "@components-common/Profile/ModalAvatar";

function ProfileLeft(props) {
  const dispatch = useDispatch();
  const dataAccount = props && props;
  const userData = useSelector((state) => state.authUser || {});

  //state
  const [modal, setModal] = useState({
    upload: false,
    pickAvatar: false
  });
  const [stateAccount, setStateAccount] = useState(dataAccount || {})

  //Load Account
  useEffect(() => {
    setStateAccount(dataAccount || {});
  }, [dataAccount])

  // Toggle
  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  }

  const handleRequestAdmin = () => {
    toast.success('Your request has been sent to the system administrator.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: 'handleRequestAdmin'
    });
    const params = {
      "RequestPrivilege": "Instructor"
    }
    dispatch(atcRequestInstructor(params));
  }

  // Handle Submit Avatar
  const handleSubmitImageAvatar = ({ all_avatars, current_avatar_id }) => {
    let avatarData = _.filter(all_avatars, function (o) { return o.avatar_id === current_avatar_id });
    const imgUrl = avatarData && avatarData[0]?.image_url;
    setStateAccount((prevState) => ({ ...prevState, profile_photo_url: imgUrl }));
  }

  const isInstructor = stateAccount?.isInstructor;
  const isAdministrator = stateAccount?.is_administrator;
  const roleAdmin = isAdministrator === "Yes" || isInstructor === "Yes";
  return (
    <React.Fragment>
      <Card className="border-0">
        <CardBody className="-box-shadow">
          <ProfileBox
            {...props}
            stateAccount={stateAccount}
            userData={userData}
            toggle={toggle}
          />
        </CardBody>
      </Card>
      <Card className="border-0 mt-3">
        <CardBody className="-box-shadow p-0">
          <ListGroup className="list-group-flush navProfile">
            <ListGroupItem className="navProfile__item p-0" onClick={() => toggle('upload')}>
              <span className="d-block p-3">
                <i className="fa fa-user-circle-o mr-2" aria-hidden="true"></i>
                <span className="--text-primary">Change Profile Photo</span>
              </span>
            </ListGroupItem>
            <ListGroupItem className="navProfile__item p-0">
              <Link className="d-block p-3" to={`/reset-password`}>
                <i className="fa fa-key mr-2" aria-hidden="true"></i>
                <span className="--text-primary">Reset Password</span>
              </Link>
            </ListGroupItem>
            {roleAdmin && (
              <ListGroupItem className="navProfile__item p-0">
                <Link className="d-block p-3" to={` `}>
                  <i className="fa fa-users mr-2" aria-hidden="true"></i>
                  <span className="--text-primary">Change Affiliation</span>
                </Link>
              </ListGroupItem>
            )}
            {(isInstructor === 'None' || isInstructor === 'Pending') &&
              <ListGroupItem className="navProfile__item p-0" style={{ pointerEvents: isInstructor === 'Pending' ? 'none' : 'auto' }} onClick={handleRequestAdmin}>
                <div className="d-block p-3" to={` `}>
                  <i className="fa fa-cogs mr-2" aria-hidden="true"></i>
                  <span className="--text-primary">
                    {isInstructor === 'None' ? "Request Instructor's Privilege" : "Instructor's Privilege Pending"}
                  </span>
                </div>
              </ListGroupItem>
            }
          </ListGroup>
        </CardBody>
      </Card>
      <ModalUpload {...props} isOpen={modal?.upload} toggle={() => toggle('upload')} />
      <ModalAvatar {...props} isOpen={modal?.pickAvatar} toggle={() => toggle('pickAvatar')} handleSubmitImageAvatar={handleSubmitImageAvatar} />
    </React.Fragment>
  );
}

export default ProfileLeft;