import React, { useMemo, useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

function PeriodsFilter(props) {
    const { handleChange, data, isDisabled } = props && props;
    const [selectedOption, setSelectedOption] = useState(null);
    const [isChange, setChange] = useState([]);

    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.PeriodId, label: item?.Name };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    const handleChangeOptions = (data) => {
        setSelectedOption(data);
        setChange(true);
    };

    useEffect(() => {
        if (isChange) {
            handleChange(selectedOption);
            setChange(false);
        }
    }, [isChange, handleChange, selectedOption]);

    const optionsData = useMemo(() => convertDataOptions(data && data), [data]);
    return (
        <CreatableSelect
            isClearable
            onChange={handleChangeOptions}
            options={optionsData || []}
            isMulti
            name="Periods"
            value={selectedOption}
            placeholder="Any Time"
            classNamePrefix="react-select"
            isDisabled={isDisabled}
        />
    );
}

export default PeriodsFilter;