import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//actions
import { actSetStatusVitalSigns } from "@actions";

function CheckBoxVital(props) {
  let { id } = useParams();
  
  //constant
  const dispatch = useDispatch();

  //props
  const { timepointData } = props && props;
  const timepointDetails = timepointData?.data;
  const ShowVitalSigns = timepointDetails?.ShowVitalSigns;
  const timepointId = timepointDetails?.Id;

  //state
  const [isStatus, setStatus] = useState(
    (ShowVitalSigns && ShowVitalSigns) || false
  );

  //Load
  useEffect(() => {
    setStatus((ShowVitalSigns && ShowVitalSigns) || false);
  }, [ShowVitalSigns]);

  const handleChange = (e) => {
    const { checked } = e.target;
    setStatus(!isStatus);

    //Call api
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timepointId,
      ShowVitalSigns: checked,
    };
    dispatch(actSetStatusVitalSigns(id, params));
  };

  return (
    <FormGroup className="mb-3" check>
      <Input type="checkbox" checked={isStatus} onChange={handleChange} />{" "}
      <Label check>Show vital signs by default</Label>
    </FormGroup>
  );
}

export default CheckBoxVital;
