import { checkpointsConstants } from "@constants";

export function loadCheckpoints(state = {}, action) {
    switch (action.type) {
        case checkpointsConstants.CHECKPOINTS_LOAD_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case checkpointsConstants.CHECKPOINTS_LOAD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case checkpointsConstants.CREATE_CHECKPOINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case checkpointsConstants.EDIT_CHECKPOINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case checkpointsConstants.DELETE_CHECKPOINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case checkpointsConstants.CHECKPOINTS_LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function itemsByCheckPoints(state = {}, action) {
    switch (action.type) {
        case checkpointsConstants.CHECKPOINT_LOAD_TYPE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case checkpointsConstants.CHECKPOINT_LOAD_TYPE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case checkpointsConstants.CHECKPOINT_LOAD_TYPE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};