import { problemsConstant } from "@constants";
import { apiCaller } from "@utils";

//Update Assistant Tip Record
export const getListProblem = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/documentation/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: problemsConstant.LOAD_PROBLEMS_REQUEST, isLoading } }
    function success(data) { return { type: problemsConstant.LOAD_PROBLEMS_SUCCESS, payload: data } }
    function failure(error) { return { type: problemsConstant.LOAD_PROBLEMS_FAILURE, error: error } }
}