import React, { memo, useEffect } from "react";

//boostrap
import {
    Form,
    FormGroup,
    Label,
    Row,
    Col,
    FormFeedback,
    Input
} from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const IdentificationTab = memo(({ props, register, getValues, watch, formState: { errors }, setValue }) => {

    //props
    const { data } = props && props;

    //state
    useEffect(() => {
        const colData = data?.col;
        const identification = colData?.identification;
        setValue('concepts', identification?.concepts || '', { shouldValidate: false });
        setValue('regex', identification?.regex || '', { shouldValidate: false });
        setValue('use_concept', identification?.use_concept || false, { shouldValidate: false });
        setValue('use_regex', identification?.use_regex || false, { shouldValidate: false });
    }, [data, setValue]);

    return (
        <Form>
            <Row>
                <Col md={12}>
                    <FormGroup
                        check
                        className="inline-flex align-items-center mb-3"
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                nam="use_regex"
                                id="use_regex"
                                onChange={(e) => {
                                    setValue("use_regex", e.target.checked, {
                                        shouldValidate: true,
                                    });
                                }}
                                checked={watch("use_regex")}
                            />
                            Use Regex
                        </Label>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    {watch("use_regex") && (
                        <FormGroup>
                            <Label for="regex">Regex string to match <span className="m--font-danger">*</span></Label>
                            <TextareaAutosize
                                {...register("regex", {
                                    required: "This field is required.",
                                })}
                                name="regex"
                                className="w-100 form-control"
                                maxRows="10"
                                minRows={5}
                                defaultValue={watch("regex")}
                                onChange={(e) => {
                                    setValue("regex", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                            />
                            {errors.regex?.message && (
                                <FormFeedback className="d-block">
                                    {errors.regex?.message}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    )}
                </Col>
                <Col md={12}>
                    <FormGroup
                        check
                        className="inline-flex align-items-center mb-3"
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                nam="use_concept"
                                id="use_concept"
                                onChange={(e) => {
                                    setValue("use_concept", e.target.checked, {
                                        shouldValidate: true,
                                    });
                                }}
                                checked={watch("use_concept")}
                            />
                            Use AI-detected Concepts
                        </Label>
                    </FormGroup>
                    {watch("use_concept") && (
                        <FormGroup>
                            <Label for="concepts">List of concepts (separated by ;) <span className="m--font-danger">*</span></Label>
                            <TextareaAutosize
                                {...register("concepts", {
                                    required: "This field is required.",
                                })}
                                name="concepts"
                                className="w-100 form-control"
                                maxRows="10"
                                minRows={5}
                                defaultValue={watch("concepts")}
                                onChange={(e) => {
                                    setValue("concepts", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                            />
                            {errors.concepts?.message && (
                                <FormFeedback className="d-block">
                                    {errors.concepts?.message}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    )}
                </Col>
            </Row>
        </Form>
    )
});

export default IdentificationTab;