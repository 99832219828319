import React from 'react';

//boostrap
import { Input, FormGroup, Label } from "reactstrap";

function CorrectResponsive(props) {
    const { index, item, handleChange } = props && props;
    return (
        <React.Fragment>
            <FormGroup check>
                <Input
                    name={`CorrectResponse_${index}`}
                    type="radio"
                    checked={item?.CorrectResponse === true}
                    value={`Correct`}
                    onChange={(e) => handleChange(e, index)}
                />
                {' '}
                <Label check>
                    Correct
                </Label>
            </FormGroup>
            <FormGroup check>
                <Input
                    name={`CorrectResponse_${index}`}
                    type="radio"
                    checked={item?.CorrectResponse === false}
                    value={`Incorrect`}
                    onChange={(e) => handleChange(e, index)}
                />
                {' '}
                <Label check>
                    Incorrect
                </Label>
            </FormGroup>
        </React.Fragment>
    );
};

export default CorrectResponsive;