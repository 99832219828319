import React from "react";
import { ListNotes } from "./componentNote";

const NotesDocumentation = (props) => {
  return (
    <React.Fragment>
      <ListNotes {...props}/>
    </React.Fragment>
  );
};

export default NotesDocumentation;
