import React from 'react';

//Boostrap
import { Input, Label, FormGroup } from "reactstrap";

//common
import { DataEmty } from "@components-common";

//components
import { FormSearch } from '../tabs';

function UmlsTab(props) {
    const {
        hanldeChange,
        isSearching,
        results,
        isIdChecked
    } = props && props;

    const todoItems = results?.map((todo, index) =>
        <FormGroup className="d-flex align-items-center border-bottom py-3" style={{ cursor: 'pointer' }} check key={index} onClick={() => hanldeChange(todo?.cui)}>
            <Input
                name={`radio_schema`}
                type="radio"
                value={todo?.cui}
                checked={isIdChecked === todo?.cui ? true : false}
                bsSize={'lg'}
                onChange={() => hanldeChange(todo?.cui)}
            />
            {' '}
            <Label id={`radio_schema`} check>
                <h3>{todo?.name}</h3>
                <p className="text-muted mt-2">CUI: {todo?.cui}</p>
            </Label>
        </FormGroup>
    );

    return (
        <React.Fragment>
            <FormSearch {...props} typeTab="umls"/>
            {todoItems}

            {isSearching && (
                <DataEmty content={`Searching ...`} />
            )}
            {results?.length === 0 && !isSearching &&
                <DataEmty content={`No Data`} />
            }
        </React.Fragment>
    );
};

export default UmlsTab;