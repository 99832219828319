import React, { useState, useEffect } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//components
import { AddOtherQuestions } from '../TabsGroupSchema';

//actions APi
import { atcLoadAssociatedSchema } from "@actions";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { actOrderAssociatedSchema, actDeleteAssociatedSchema } from "@actions";

//lodash
import _ from "lodash";

const SortableItem = SortableElement(({ value, QuestionId, handleDeleteItem, idCase }) => (
    <li className={`ListSortableElement__item hasDrag -box-shadow`} key={value?.Id}>
        <div className="innerCt p-3">
            <i className="fa fa-bars iconDrag" aria-hidden="true"></i>
            <h6 className="title mr-4 text-2">
                Question Schema #{value?.SchemaQuestionId}
                {QuestionId === value?.SchemaQuestionId && (
                    <span className="text-muted ml-2 text-1">(this question)</span>
                )}
            </h6>
            <div className="d-flex justify-content-end align-items-center ml-auto" md={3} sm={12}>
                <ButtonEdit
                    target='_blank'
                    linkRef={true}
                    url={`/teach/schema/${idCase}?QuestionId=${value?.SchemaQuestionId}`}
                />
                <ButtonDelete
                    handleDelete={() => handleDeleteItem(value)}
                />
            </div>
        </div>
    </li>
));

const SortableList = SortableContainer(({ items, QuestionId, handleDeleteItem, idCase }) => {
    return (
        <ul className="ListSortableElement">
            {items?.map((value, index) => (
                <SortableItem
                    key={`item-${value?.SchemaQuestionId}`}
                    index={index}
                    value={value}
                    QuestionId={QuestionId}
                    handleDeleteItem={handleDeleteItem}
                    idCase={idCase}
                />
            ))}
        </ul>
    );
});

function MergedSchema(props) {
    //props
    const { QuestionId } = props && props;
    const dispatch = useDispatch();
    let { id } = useParams();
    const dataStore = useSelector((state) => (state?.getAssociatedSchema?.data) || {});
    const AvailableToAdd = dataStore?.AvailableToAdd || [];

    //state
    const [itemSort, setItemSort] = useState(dataStore?.QuestionSchema || []);
    
    //Load All Standard Chat Topics
    useEffect(() => {
        const fetchData = () => {
            const params = {
                "Action": "LoadAssociatedSchema",
                "CaseId": id,
                "QuestionId": QuestionId
            }
            dispatch(atcLoadAssociatedSchema(params));
        };
        fetchData();
    }, [dispatch, id, QuestionId]);

    //sort
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newArrSort = arrayMove(itemSort, oldIndex, newIndex);
        const resultSort = _.map(newArrSort, function square(item) { return item?.SchemaQuestionId })
        setItemSort(newArrSort);
        const params = {
            "Action": "OrderAssociatedSchema",
            "CaseId": id,
            "QuestionId": QuestionId,
            "SourceQuestionIds": resultSort
        }
        dispatch(actOrderAssociatedSchema(params));
    };

    //Delete Item
    const handleDeleteItem = (item) => {
        const params = {
            "Action": "DeleteAssociatedSchema",
            "CaseId": id,
            "QuestionId": QuestionId,
            "SourceQuestionId": item?.SchemaQuestionId
        }
        dispatch(actDeleteAssociatedSchema(params));
    }

    return (
        <React.Fragment>
            <AddOtherQuestions
                data={AvailableToAdd || []}
                QuestionId={QuestionId}
            />
            <SortableList
                items={itemSort || []}
                onSortEnd={onSortEnd}
                lockAxis="y"
                helperClass="SortableTbl"
                distance={1}
                useWindowAsScrollContainer={true}
                QuestionId={QuestionId}
                handleDeleteItem={handleDeleteItem}
                idCase={id}
            />
        </React.Fragment>
    );
}

export default MergedSchema;