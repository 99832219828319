import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from "jodit-react";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

//Config
import * as Config from "@constants/Config";

//apiCaller
import { apiCaller } from "@utils";

//utils
import { LoaderText } from "@utils";

//constants
import { schemaContanst } from "@constants";

function convertHexColorInline(content) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, "text/html");

    //check color
    const isColor = (strColor) => {
        var s = new Option().style;
        s.color = strColor;
        return s.color === strColor;
    }

    doc?.querySelectorAll(`[class*="highlight"]`).forEach(function (element) {
        const classes = element.className;
        const splitString = classes.split('-');
        const hexColor = splitString && splitString[1];
        const checkColor = isColor(hexColor);
        if (hexColor !== 'sub') {
            element.setAttribute(
                'style',
                `background-color: ${checkColor ? hexColor : `#${hexColor}`};
                color: white;
                display: inline-block;
                padding: 1px 4px;
                border-radius: 4px;
                margin: 5px;`
            );
        }
    });
    const text = doc?.body.innerHTML;
    return text;
}

function ModalTestSchema(props) {
    const { isOpen, toggleTestSchema, QuestionId, data } = props && props;
    const dispatch = useDispatch();

    //state
    const editor = useRef(null);
    const [content, setContent] = useState('');
    const [stateData, setData] = useState(data || {});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setData(data || {});
    }, [data])


    const handleSubmit = () => {
        const params = {
            "Target": "Question",
            "QuestionId": QuestionId,
            "AnswerText": content
        }

        setLoading(true);
        dispatch({ type: schemaContanst.SUBMIT_TEST_QUESTION_REQUEST, isLoading: true });
        apiCaller(`/api/teach/test/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: schemaContanst.SUBMIT_TEST_QUESTION_SUCCESS, payload: data });
                setLoading(false);
                setData(data);
            } else {
                dispatch({ type: schemaContanst.SUBMIT_TEST_QUESTION_FAILURE, error: "error" });
                setLoading(false);
            }
        });
    }
    
    return (
        <Modal
            toggle={toggleTestSchema}
            isOpen={isOpen}
            modalClassName={`right ${isOpen ? "in" : ""}`}
            wrapClassName={`modalFixed mw-600`}
        >
            <ModalHeader toggle={toggleTestSchema}>
                Test Schema
            </ModalHeader>
            <ModalBody className='p-3 position-relative'>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={Config.CONFIG_JODIT}
                    placeholder="Type a free-text statement"
                    tabIndex={1} // tabIndex of textarea
                    onBlur={newContent => setContent(newContent)}
                />
                {isLoading && (
                    <div style={{ height: '400px' }}>
                        <LoaderText />
                    </div>
                )}
                {stateData?.Feedback && !isLoading &&
                    <div className="mt-4" style={{ height: '500px', overflowY: 'auto' }}>
                        <div dangerouslySetInnerHTML={{ __html: convertHexColorInline(stateData?.Feedback) || '' }}></div>
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    className="d-flex align-items-center"
                    disabled={isLoading}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Submit
                </Button>
                {' '}
                <Button onClick={toggleTestSchema}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalTestSchema;