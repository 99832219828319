import React from 'react';

//components
import { InstitutionalAccess } from "../templates";

//LoaderText
import { messageData } from "@utils";

//common
import { DataEmty } from "@components-common";

//elements
import { ButtonEdit, ButtonDelete } from "../elements";

//thumbDefault
import thumbDefault from "@images/common/placeholder.png";

function TemplateList(props) {
    const { data, type, hanldeSwitch, toggle, itemCollection } = props && props;
    return (
        <ul className="ListSortableElement">
            {data && data.length === 0 && (
                <DataEmty content={messageData?.templatesEmty?.content} />
            )}
            {data?.map((value, index) => {
                const genderName = value?.Gender?.GenderName;
                const ethnicityName = value?.EthnicityGroup?.EthnicityGroupName;
                const ageName = value?.AgeGroup?.AgeGroupName;
                const thumbnail = value?.Thumbnail;
                const templateName = value?.TemplateName;
                const isHighlighted = value?.Highlighted;
                const templateId = value?.TemplateId;
                const inAccessValue = value?.InstitutionalAccess;
                return (
                    <li
                        className="ListSortableElement__item -box-shadow"
                        key={index}
                        style={{ cursor: "auto" }}
                    >
                        <div className="innerCt">
                            <div className="innerCt__left">
                                <img
                                    src={thumbnail || thumbDefault}
                                    alt={templateName || ""}
                                />
                            </div>
                            <div className="innerCt__right">
                                <div className="align-items-center row">
                                    <div className="col-sm-12 col-md-9">
                                        <h6 className="title">{templateName || ""}</h6>
                                        <p className="time">
                                            <span className="time__title --text-primary mr-2">
                                                Age:
                                            </span>
                                            {ageName}
                                            {` `}&#124;
                                            <span className="time__title --text-primary mr-2">
                                                {` `}Gender:
                                            </span>
                                            {genderName}
                                            {` `}&#124;
                                            <span className="time__title --text-primary mr-2">
                                                {` `}Ethnicity:
                                            </span>
                                            {ethnicityName}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center col-sm-12 col-md-3">
                                        {type === "adminstrator" && (
                                            <React.Fragment>
                                                <InstitutionalAccess
                                                    item={value}
                                                    status={inAccessValue}
                                                    hanldeSwitch={hanldeSwitch}
                                                />
                                                <input
                                                    className="favorite-star mr-4"
                                                    type="checkbox"
                                                    defaultChecked={isHighlighted}
                                                    name={`Highlighted`}
                                                    onChange={(e) =>
                                                        hanldeSwitch(templateId, e)
                                                    }
                                                />
                                            </React.Fragment>
                                        )}
                                        <ButtonEdit
                                            handleEdit={() =>
                                                toggle(value, "editTemplate")
                                            }
                                        />
                                        {itemCollection?.TemplateCollectionId !== 0 &&
                                            <ButtonDelete
                                                handleDelete={() =>
                                                    toggle(value, "removeTemplate")
                                                }
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default TemplateList;