import React from 'react';

import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//reactstrap
import { Input } from "reactstrap";

//actions
import { updateAvailableForMedicationOfElement } from "@actions";

function RowIntervention(props) {
    const { item, value, hanldeCollapse, listTimepointLast, toggleDel, toggleIntervention, isOpen } = props && props;

    //dispatch api
    let { id } = useParams();
    const dispatch = useDispatch();

    const handleChangeAvailable = (e, InterventionId, idCategory, TimePointId) => {
        const isChecked = e.target.checked;
        const paramsUpdate = {
            Action: "Update",
            Target: "Intervention",
            InterventionId: InterventionId,
            TimePointId: TimePointId,
            CategoryId: idCategory,
            Available: isChecked,
        };
        dispatch(updateAvailableForMedicationOfElement(id, paramsUpdate));
    };

    return (
        <tr>
            <th scope="row">
                {item.Name}{" "}
                <span className="sttId">(#{item.Id})</span>
            </th>
            {listTimepointLast?.map((row, j) => {
                const TimePointId = row?.TimePoint?.TimepointId;
                const InterventionsId = item?.Id;
                const isChecked = item?.Available;
                return (
                    <td key={j} className="text-center align-middle">
                        <Input
                            className="m-0 checkbox-lg"
                            defaultChecked={isChecked}
                            type="checkbox"
                            onChange={(e) =>
                                handleChangeAvailable(
                                    e,
                                    InterventionsId,
                                    value?.CategoryId,
                                    TimePointId
                                )
                            }
                        />
                    </td>
                );
            })}
            <td className="text-center">
                <div className="lsActions">
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-brand"
                            onClick={hanldeCollapse}
                        >
                            <span className={`fa ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}></span>{" "}
                        </button>
                    </div>
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-brand"
                            onClick={() =>
                                toggleIntervention(
                                    "edit",
                                    item,
                                    value.CategoryId
                                )
                            }
                        >
                            <span className="med-icon icon-pencil"></span>{" "}
                        </button>
                    </div>
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() =>
                                toggleDel(
                                    "delete",
                                    item.Id,
                                    value.CategoryId
                                )
                            }
                        >
                            <span className="med-icon icon-cancel"></span>{" "}
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default RowIntervention;