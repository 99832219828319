const navigation = {
    mainMenuAdmin: [
        {
            name: "Learn",
            path: "/learn/home"
        }, {
            name: "Teach",
            path: "/teach/module/library"
        }, {
            name: "Validate",
            path: "/validate"
        }, {
            name: "Manage",
            path: "/adminstrator-tool"
        }
    ],
    mainMenuLearner: [
        {
            name: "Home",
            path: "/",
            idEl: "m2l_home_menu_title"
        }, {
            name: "In Progress",
            path: "/portfolio/?status=in-progress"
        }, {
            name: "Completed",
            path: "/portfolio/?status=completed"
        }
    ],
    mainAdmin: [
        {
            name: "On-boarding",
            path: "/admin"
        }, {
            name: "Legal",
            path: "/admin/legal"
        }, {
            name: "Monitor",
            path: "/admin/monitor",
            subMenu: [
                {
                    name: 'OpenAI',
                    path: `/admin/monitor/data`,
                    icon: 'fa fa-handshake-o',
                }
            ]
        }, {
            name: "Settings",
            path: "/admin/settings",
            subMenu: [
                {
                    name: "Prompts",
                    path: "/admin/settings/models"
                },
                {
                    name: "Chat Settings ",
                    path: "/admin/settings/chat"
                },
                {
                    name: "Avatars",
                    path: "/admin/settings/avatars"
                }
            ]
        }, {
            name: "Support",
            path: "/admin/support",
            subMenu: [
                {
                    name: "Tickets",
                    path: "/admin/support/tickets"
                }
            ]
        }, {
            name: "Utilities",
            path: "/admin/utilities",
            subMenu: [
                {
                    name: "Scrum",
                    path: "/admin/utilities/scrum"
                },
                {
                    name: "Prompt Generation",
                    path: "/admin/utilities/prompts"
                }
            ]
        }
    ],
    mainDiagnosis: [
        {
            name: "Diagnosis",
            path: "/reli/diagnosis"
        },
        {
            name: "Pdf Demo",
            path: "/reli/pdf-demo"
        },
        {
            name: "Proposal",
            path: "/reli/proposal"
        }
    ],
    mainMenuTeach: [
        {
            name: "Overview",
            path: `/teach/case/:id`,
            subMenu: []
        }, {
            name: "Settings",
            path: "/elements",
            subMenu: [
                {
                    name: 'Properties',
                    isHeader: true,
                    menuGroup: [
                        {
                            name: 'Case Draft',
                            path: `/teach/elements/case-draft`,
                            icon: 'fa fa-pencil-square-o'
                        },
                        {
                            name: 'Chat',
                            path: `/teach/elements/chat`,
                            icon: 'fa fa-commenting-o'
                        }, {
                            name: 'Vital Signs',
                            path: `/teach/elements/vital-signs`,
                            icon: 'fa fa-heart-o'
                        }, {
                            name: 'Intake/Output',
                            path: `/teach/elements/intake-output`,
                            icon: 'fa fa-map-signs'
                        }, {
                            name: 'Exam Findings',
                            path: `/teach/elements/exam-findings`,
                            icon: 'fa fa-stethoscope'
                        }, {
                            name: 'Labs',
                            path: `/teach/elements/labs`,
                            icon: 'fa fa-flask'
                        }, {
                            name: 'Studies',
                            path: `/teach/elements/studies`,
                            icon: 'fa fa-file-picture-o'
                        }
                    ]
                }, {
                    name: 'Interventions',
                    isHeader: true,
                    menuGroup: [
                        {
                            name: 'Medications',
                            path: `/teach/elements/medications`,
                            icon: 'fa fa-medkit'
                        }, {
                            name: 'Actions',
                            path: `/teach/elements/actions`,
                            icon: 'fa fa-magic'
                        }
                    ]
                },
                {
                    name: 'Assessment',
                    isHeader: true,
                    menuGroup: [
                        {
                            name: 'Questions',
                            path: `/teach/elements/questions`,
                            icon: 'fa fa-question-circle-o'
                        }
                    ]
                },
                {
                    name: 'Team',
                    isHeader: true,
                    menuGroup: [

                        {
                            name: 'Learners',
                            path: `/teach/access/learners`,
                            icon: 'fa fa-graduation-cap'
                        }, {
                            name: 'Teams',
                            path: `/teach/access/teams`,
                            icon: 'fa fa-users',
                            isDivider: true
                        },
                        {
                            name: 'Team roles',
                            path: `/teach/elements/teams`,
                            icon: 'fa fa-users'
                        }
                    ]
                }
            ]
        }, {
            name: "Storyboard",
            path: "/teach/storyboard/:id",
            subMenu: []
        }, {
            name: "References",
            path: "/teach/references/:id",
            subMenu: []
        }, {
            name: "Access",
            path: "/access",
            subMenu: [
                {
                    name: 'Collaborators',
                    path: `/teach/access/collaborators`,
                    icon: 'fa fa-handshake-o',
                }, {
                    name: 'Trial Periods',
                    path: `/teach/access/trial-periods`,
                    icon: 'fa fa-clock-o'
                }, {
                    name: 'Auth Code',
                    path: `/teach/access/auth-code`,
                    icon: 'fa fa-flag'
                },
            ]
        }, {
            name: "Reports",
            path: "/reports",
            subMenu: [
                {
                    name: 'AI Records',
                    path: '/teach/reports/ai-records',
                    icon: 'fa fa-lightbulb-o'
                },
                {
                    name: 'Learners',
                    path: '/teach/reports/learners',
                    icon: 'fa fa-graduation-cap'
                }, {
                    name: 'Questions',
                    path: '/teach/reports/questions',
                    icon: 'fa fa-question-circle-o'
                }, {
                    name: 'Domains',
                    path: '/teach/reports/domains',
                    icon: 'fa fa-tasks'
                }, {
                    name: 'Orders',
                    path: '/teach/reports/orders',
                    icon: 'fa fa-clock-o'
                },
                {
                    name: 'Chat',
                    path: '/teach/reports/chat',
                    icon: 'fa fa-commenting-o'
                }
            ]
        }, {
            name: "Preview",
            path: "/learn/case/:id",
            subMenu: []
        }
    ],
    mainMenuTeachV2: [
        {
            name: "Overview",
            path: `/teach/module/overview/:id`,
            subMenu: []
        },
        {
            name: "Storyboard",
            path: "/teach/module/storyboard/:id",
            subMenu: []
        },
        {
            name: "References",
            path: "/teach/module/references/:id",
            subMenu: []
        },
        {
            name: "Learners",
            path: "/teach/module/learners/:id",
            subMenu: []
        }
    ]
}

export default navigation;