import React from "react";

//components
import { CreditCard } from "../Administrator";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { LaunchStripeCreditCard } from "@actions";

function DescriptionPayment(props) {
  const { type, data } = props && props;
  const Details = data?.Details;
  const Invoice = Details?.Invoice;
  const CreditCardInfo = Details?.CreditCard;
  const DataCreditCard = CreditCardInfo?.Data;
  // const VendorCreditCard = CreditCardInfo?.Vendor;
  const UserMessage = Invoice?.UserMessage;
  const PaymentMethods = DataCreditCard?.payment_methods;
  
  //api
  const dispatch = useDispatch();

  const handleUpdateCard = () => {
    const params = {
      Action: "launch_stripe_set_up_credit_card",
    };
    dispatch(LaunchStripeCreditCard(params));
  };

  const LoadStripeStore = useSelector(
    (state) => state.LoadStripeCreditCard || []
  );
  return (
    <div className="payment-content my-5">
      {/* CreditCard Vender NUll */}
      {type === "CreditCard" && PaymentMethods?.length === 0 && (
        <div className="creditCardCt text-center">
          <p className="text-3 font-italic">
            Please click on the button below to securely enter your credit card
            information
          </p>
          <button
            className="btn --btn-primary d-block mx-auto my-3"
            onClick={handleUpdateCard}
          >
            Set Up Credit Card
          </button>
          <p className="--text-primary">
            Note: all credit card information are securely stored and managed
            via Stripe.
            <br />
            Stripe’s privacy policy and related materials can be{" "}
            <a href="https://stripe.com/ssa" rel="noreferrer" target="_blank">
              reviewed here
            </a>
          </p>
        </div>
      )}{" "}
      {/* CreditCard Vender NOT NUll */}
      {type === "CreditCard" && PaymentMethods?.length > 0 && (
        <React.Fragment>
          <CreditCard data={(data && data) || {}} />
          <button
            onClick={handleUpdateCard}
            className="btn --btn-primary d-block mx-auto my-3"
            disabled={LoadStripeStore?.isLoading}
          >
            {LoadStripeStore?.isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Update Credit Card Information
          </button>
          <p className="--text-primary">
            Note: all credit card information are securely stored and managed
            via Stripe.
            <br />
            Stripe’s privacy policy and related materials can be{" "}
            <a href="https://stripe.com/ssa" rel="noreferrer" target="_blank">
              reviewed here
            </a>
          </p>
        </React.Fragment>
      )}
      {/*  PayPal */}
      {type === "PayPal" && (
        <div className="paypalCt text-center">
          <button
            className="btn --btn-primary d-block mx-auto my-3"
            onClick={handleUpdateCard}
          >
            Update Credit Card Information
          </button>
        </div>
      )}
      {/*  Invoice */}
      {type === "Invoice" && UserMessage && (
        <div className="invoiceCt text-center">
          <p
            className="text-3"
            dangerouslySetInnerHTML={{
              __html: UserMessage,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DescriptionPayment;
