// gradingSchemaOptions.js
const gradingSchemaOptions = [
    // Danh sách các tùy chọn
    {
        value: 'null',
        label: 'None (using regular expression)'
    },
    {
        value: 'concept',
        label: 'Concept Recognition'
    },
    {
        value: 'dialog',
        label: 'Guided Dialog'
    },
    // {
    //     value: 'umls',
    //     label: 'UMLS Concept'
    // },
    {
        value: 'diagnosis',
        label: 'Diagnosis Analysis'
    },
    {
        value: 'compliance',
        label: 'Risk Adjustment Documentation / Compliance'
    },
    {
        value: 'chatgpt',
        label: 'Open AI'
    },
    {
        value: 'chatgpt_voice',
        label: 'Open AI - voice'
    }
];

module.exports = gradingSchemaOptions;
