import React from 'react';

//lodash
// import _ from "lodash";

function CollectionTop(props) {
    const { itemCollection, hanldeAllTemplate } = props && props;
    return (
        <React.Fragment>
            <ul className="navSidebar">
                <li
                    className={`navSidebar__item refLink ${itemCollection?.TemplateCollectionId === 0 ? "--text-primary" : ""}`}
                    onClick={hanldeAllTemplate}
                >
                    <div className="d-flex align-items-center w-100" style={{cursor: "pointer"}}>
                        All Templates
                        <i className="fa fa-chevron-right ml-auto" aria-hidden="true"></i>
                    </div>
                </li>
            </ul>
            <hr className="m--font-brand" />
            <h5 className="heading-5 --font-bold">Collections</h5>
        </React.Fragment>
    );
};

export default CollectionTop;