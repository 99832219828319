import React, { useState } from "react";
// import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
// import { useDispatch, useSelector } from "react-redux";

//components
import { ModeTop, RolesMain } from "@components/access/Teams";

// import { InterventionsMain } from "@components/elements/Interventions";
import { HeadingBox } from "@components/common";

//actions
// import { loadAllCaseActions } from "@actions";

function TeamsAccessPage(props) {
    //props
    const { caseDetails } = props && props;

    //state
    const [isStatus, setStatus] = useState(true);

    //titlePage
    const titlePage = caseDetails?.Title;

    const handeleChangeStatus = (e) => {
        const { value } = e.target;
        const isTrueSet = (value === 'true');
        setStatus(isTrueSet);
    }

    return (
        <div className="wrapper -eTeamsPage">
            <section className="eActionsCt">
                <Container>
                    <HeadingBox title={titlePage || ""} currentPage="Teams" />
                    <ModeTop
                        {...props}
                        handeleChangeStatus={handeleChangeStatus}
                        isStatus={isStatus}
                    />
                    {isStatus && (
                        <RolesMain {...props} />
                    )}
                </Container>
            </section>
        </div>
    );
}

export default TeamsAccessPage;