import React from "react";

function HeaderPayMent(props) {
  const { type, data } = props && props;
  const DefaultMethod = data?.DefaultMethod;

  return (
    <div className="messagePayment">
      {DefaultMethod === null && !type && (
        <h4 className="text-center">
          <span className="--text-primary text-3">
            Please choose your default payment method
          </span>
        </h4>
      )}

      {type === "CreditCard" && (
        <h4 className="text-center">
          <span className="--text-primary text-3">
            Your current default payment method is by Credit Card{" "}
          </span>
          <br />
          <span className="text-muted text-3">
            (To change default payment method, click on one of the other buttons
            below)
          </span>
        </h4>
      )}

      {type === "Invoice" && (
        <h4 className="text-center">
          <span className="--text-primary text-3">
            You are currently making payments in response to invoice
          </span>
          <br />
          <span className="text-muted text-3">
            (To change default payment method, click on one of the other buttons
            below)
          </span>
        </h4>
      )}
    </div>
  );
}

export default HeaderPayMent;
