import React from 'react';

function ButtonRemove(props) {
    const { item, toggleRemove, type } = props && props;
    return (
        <button className="btn btn-danger actionConcept__btn actionConcept__btn--Remove btn-sm" onClick={() => toggleRemove(type, item)}>
            <i className="fa fa-times" aria-hidden="true"></i>
        </button>
    );
};

export default ButtonRemove;