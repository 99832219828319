import React, { useState } from 'react';

//React Select
import Select from "react-select";

//boostrap
import {
    Col
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//actions
import { actLoadScrumById } from "@actions";

function ScrumSprints (props) {
    const dispatch = useDispatch();
    const { data } = props && props;
    const sprintsData = data?.sprints;

    //state
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (data) => {
        const newData = { ...data };
        setSelectedOption(newData);
        dispatch(actLoadScrumById(data?.value));
    };

    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.ProjectId, label: item?.Name };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    const dataOptions = convertDataOptions((sprintsData && sprintsData) || []);
    return (
        <Col md={12}>
            <div className="widgetBox -box-shadow mb-3">
                <div className="widgetBox__body">
                    <div className="ml-auto" style={{maxWidth: '500px'}}>
                        <Select
                            closeMenuOnSelect={true}
                            defaultValue={selectedOption}
                            onChange={handleChange}
                            options={dataOptions || []}
                            placeholder={`Select an item below`}
                        />
                    </div>
                </div>
            </div>
        </Col>
    );
};

export default ScrumSprints;