import React from 'react';

//Component
import { IconListContainer } from "@components/learner/StandardScreen/LearnerMobile";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

const LearnerGridLeftParent = (props) => {
  const { sizePatientPhoto } = props && props;
  // Use screen size context
  const { isMobile } = useScreenSize();
  return (
    <div className={`learner-grid__left position-relative`} style={{ maxHeight: `${sizePatientPhoto?.height || 500}px` }}>
      {isMobile && <IconListContainer {...props} />}
      {props.children}
    </div>
  );
};

export default LearnerGridLeftParent;