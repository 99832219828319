import React, { useState } from "react";

//reactstrap
import { TabContent, TabPane, NavItem, NavLink, Nav } from "reactstrap";

//classnames
import classnames from "classnames";

function BillingTab(props) {
  //props
  const { data } = props && props;

  //state
  const [activeTab, setActiveTab] = useState("1");

  //toggle
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabsFullBg">
      <Nav tabs>
        {data?.map((value, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({
                active: activeTab === (index + 1).toString(),
              })}
              onClick={() => {
                toggle(String(index + 1));
              }}
            >
              {value.Name}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
    <TabContent activeTab={activeTab}>
        {data?.map((value, index) => (
        <TabPane key={index} tabId={(index + 1).toString()}>
            {value.Component}
        </TabPane>
        ))}
    </TabContent>
    </div>
  );
}

export default BillingTab;
