import React, { useState } from 'react';
import { Icon } from '@components/common';
import PublicShare from '@images/common/public-share.svg';
import Copy from '@images/teachV2/copy-db.svg';
import { Button, Tooltip } from 'reactstrap';
import { useParams } from 'react-router-dom';

//components
import { MessageShare } from "../ComponentShare";

const PublicSharing = (props) => {
  const { AccountData } = props;
  let { id } = useParams();
  const shareLink = `${window.location.origin}/learn/case/${id}?utm_source=user_share&utm_medium=referral&utm_campaign=module_share&utm_content=user_${AccountData?.id}`;

  // State to control tooltip visibility
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isStatus, setStatus] = useState(false)

  const handleChange = (e) => {
    setStatus(!isStatus);
  }

  const toggleTooltip = () => {
    if (!tooltipOpen) {
      setTooltipOpen(true);
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000); // Hide tooltip after 2 seconds
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      toggleTooltip();
    });
  };

  const messageContent = `By sharing publicly, no credit will be charged for every learner who starts the interactive case (performance reports will not be available).`;
  return (
    <div className='public-share'>
      <div className='public-share__content'>
        <Icon src={PublicShare} />
        <div className='public-share__content-title'>
          <h1>Public Share via Link</h1>
          <p>Share your case across social networks using the provided link. <br /> Anyone with the link will be able to access the case.</p>
        </div>
      </div>
      <MessageShare>
        {messageContent}
      </MessageShare>
      <div className='public-share__toggle'>
        <p>Activate Public Link</p>
        <div className="switches switch-share d-inline-flex">
          <input
            type="checkbox"
            name={'status'}
            checked={isStatus}
            onChange={handleChange}
            id={'status'}
            readOnly
          />
          <label htmlFor={'status'}>
            <span />
          </label>
        </div>
      </div>
      {isStatus ? (
        <div className='public-share--link'>
          <div className="link-text">{shareLink}</div>
          <Button id="copyButton" className="link-copy" onClick={copyToClipboard}>
            <Icon src={Copy} stroke="#0089C2" width="16" height="16" />
            <span className='ml-1'>
              Copy link
            </span>
          </Button>
          <Tooltip
            isOpen={tooltipOpen}
            target="copyButton"
            placement="top"
          >
            Link copied to clipboard!
          </Tooltip>
        </div>
      ) : (
        <p className='public-share--disable'>Turn on the toggle to generate a shareable link.</p>
      )}
    </div>
  );
};

export default PublicSharing;