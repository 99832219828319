import { layoutConstants } from "@constants";

export function isOpenMenuMobile(state = {}, action) {
    switch (action.type) {
        case layoutConstants.TOGGLE_MENU_MOBILE:
            return {
                ...state,
                isOpen: action.payload,
            };
        default: return state;
    }
};