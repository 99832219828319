import React, { useState } from "react";

//api
import { apiCaller } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Reset Case Components
import { ModalResetCase } from "../TestVirtualPatient";
import { caseConstants } from "@constants";

//reactstrap
import { Button } from "reactstrap";

function ResetCase(props) {
    //props
    const { handleResetDataChat } = props && props;

    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    // Handle Modal
    const toggle = () => {
        setModal(!modal);
    };

    // Reset Case
    const requestResetCase = () => {
        setLoading(true);
        const params = {
            "CaseId": id
        }
        apiCaller(`/api/learn/case/reset/`, "POST", params).then((res) => {
            const data = res?.data;
            if (data?.status === "success") {
                setLoading(false);
                setModal(false);
                handleResetDataChat();
                const fetchCase = () => {
                    dispatch({ type: caseConstants.CASE_REQUEST, isLoading: true });
                    apiCaller(`/api/learn/case/${id}/`, 'GET', null).then(res => {
                        if (res?.status === 200) {
                            dispatch({
                                type: caseConstants.CASE_SUCCESS,
                                payload: data,
                                isLoading: false
                            });
                        } else {
                            dispatch({
                                type: caseConstants.CASE_FAILURE,
                                error: 'error',
                                isLoading: false
                            });
                        }
                    });
                }
                fetchCase(); //Load Case
            } else {
                setLoading(false);
            }
        });
    }

    return (
        <React.Fragment>
            <ModalResetCase
                {...props}
                isOpen={modal}
                toggle={toggle}
                isView="case"
                requestResetCase={requestResetCase}
                isLoading={isLoading}
            />
            <Button
                className="mr-2 mb-4"
                onClick={toggle}
                outline
                color="info"
            >
                {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Reset All
            </Button>
        </React.Fragment>
    );
}

export default ResetCase;