import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';

function CreatableSelectAnswer({ handleChangeAnswer, isCondition }) {
    const [selectedOption, setSelectedOption] = useState([]);

    // Store
    const answerById = useSelector((state) => state?.listAnswerbyQuestionId?.data?.Answers || []);

    // Convert options
    const getOptions = () => {
        return answerById.map((item) => ({
            value: item?.AnswerId,
            label: item?.AnswerContent
        }));
    };

    // Handle Change
    const handleChangeSelect = (data) => {
        handleChangeAnswer(isCondition, data);
        setSelectedOption(data);
    };

    return (
        <React.Fragment>
            <CreatableSelect
                isMulti
                closeMenuOnSelect={false}
                options={getOptions()}
                placeholder="Click to select answer(s)"
                value={selectedOption}
                classNamePrefix="react-select-custom"
                onChange={handleChangeSelect}
                styles={{
                    multiValueLabel: (base) => ({
                        ...base,
                        padding: '6px',
                        fontSize: '13px'
                    })
                }}
            />
            <p>
                <small className="text-muted text-justify" style={{ fontSize: "12px" }}>
                    (To NOT impose a condition, simply do not select a question)
                </small>
            </p>
        </React.Fragment>
    );
}

CreatableSelectAnswer.propTypes = {
    handleFlowControlChange: PropTypes.func.isRequired,
    isCondition: PropTypes.string.isRequired,
};

export default CreatableSelectAnswer;