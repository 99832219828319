import React from "react";

//ReactPlayer
import ReactPlayer from "react-player";

function HelperContent(props) {
  const data = props && props.data;
  return (
    <React.Fragment>
      <div className="content">
        <h5 className="heading-5 py-0 mt-0">{data.Title || ""}</h5>
        <div dangerouslySetInnerHTML={{ __html: data.Text || "" }} />
        {data.MediaType === "youtube" && (
          <div className="playerWrapper my-3">
            <ReactPlayer
              url={data.MediaURL || ""}
              name="VideoLink"
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
            />
          </div>
        )}
        {data.MediaType === "image" && (
          <img
            className="img-fluid mt-3"
            src={data.MediaURL || ""}
            alt={data.Title || ""}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default HelperContent;
