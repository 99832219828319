import React, { useEffect, useState } from "react";

function ModelsPagination(props) {
    let pageNumberLimit = 5;

    //props
    const { currentData, loading, handleSetCurrentPage, stateCurrentPage } = props && props;

    //state
    const [currentPage, setCurrentPage] = useState(stateCurrentPage || 1);
    const [maxPageLimit, setMaxPageLimit] = useState(5);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [totalPage, setTotalPage] = useState([]);
    const [stateData, setStateData] = useState(currentData || []);

    //set State Page
    useEffect(() => {
        setCurrentPage(stateCurrentPage || 1);
    }, [stateCurrentPage]);

    //set Data
    useEffect(() => {
        setStateData(currentData || []);
    }, [currentData]);

    //Load total Page
    useEffect(() => {
        let pages = [];
        // Create totalPages
        const totalPages = stateData?.total_pages;
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        setTotalPage(pages);
    }, [stateData]);

    const handlePageClick = (pageClick) => {
        setCurrentPage(pageClick);
        handleSetCurrentPage(pageClick);
    };

    const onPrevClick = () => {
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
        setCurrentPage((prev) => prev - 1);
        handleSetCurrentPage(currentPage - 1);
    };

    const onNextClick = () => {
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }
        setCurrentPage((prev) => prev + 1);
        handleSetCurrentPage(currentPage + 1);
    };

    const pageNumbers = totalPage?.map((page) => {
        if (page <= maxPageLimit && page > minPageLimit) {
            return (
                <li
                    key={page}
                    id={page}
                    className={`page-item ${currentPage === page ? "active" : null}`}
                >
                    <button onClick={() => handlePageClick(page)} className="page-link">
                        {page}
                    </button>
                </li>
            );
        } else {
            return null;
        }
    });

    // page ellipses
    let pageIncrementEllipses = null;
    let pageDecremenEllipses = null;
    if (totalPage?.length > maxPageLimit) {
        pageIncrementEllipses = (
            <li className="page-item" onClick={onNextClick}>
                <span className="page-link">&hellip;</span>
            </li>
        );
    }
    if (minPageLimit >= 1) {
        pageDecremenEllipses = (
            <li className="page-item" onClick={onPrevClick}>
                <span className="page-link">&hellip;</span>
            </li>
        );
    }

    return (
        <React.Fragment>
            {(stateData?.items?.length > 0) && (
                <React.Fragment>
                    {!loading ? (
                        <ul
                            className="pagination justify-content-center"
                            aria-label="Page navigation"
                        >
                            <li className="page-item">
                                <button
                                    onClick={() => onPrevClick()}
                                    disabled={currentPage === totalPage[0]}
                                    className="page-link"
                                >
                                    Prev
                                </button>
                            </li>
                            {pageDecremenEllipses}
                            {pageNumbers}
                            {pageIncrementEllipses}
                            <li className="page-item">
                                <button
                                    onClick={() => onNextClick()}
                                    disabled={currentPage === totalPage[totalPage.length - 1]}
                                    className="page-link"
                                >
                                    Next &gt;
                                </button>
                            </li>
                        </ul>
                    ) : (
                        <ul
                            className="pagination justify-content-center"
                            aria-label="Page navigation"
                        >
                            <li className="page-item">
                                <span className="page-link">Loading... </span>
                            </li>
                        </ul>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ModelsPagination;