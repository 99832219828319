// FeedbackV2Layout.js
import React from 'react';
import FeedbackHeader from './FeedbackHeader';

const FeedbackV2Layout = (props) => {
    const { title, children } = props && props;
    return (
        <div className="learner-feedback-v2">
            <FeedbackHeader
                {...props}
                title={title}
            />
            <div className="feedback-v2-body">
                {children}
            </div>
        </div>
    );
};

export default FeedbackV2Layout;