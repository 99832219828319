import React from "react";
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input, Button } from "reactstrap";

const ItemCell = ({ value, index, handleChange, handleSaveValue, isLoading }) => {
    return (
        <tr key={index}>
            <td className="align-middle">{value.Name || ''}</td>
            <td className="text-center align-middle">
                <span className="span_value ">{value.InitialValue || ''}</span>{" "}
                <span className="font-weight-300">{value.Unit === "C" ? "℃" : value.Unit}</span>
            </td>
            <td className="align-middle">
                <FormGroup className="mb-0">
                    <Input
                        type="select"
                        value={value.ChangeMode || "none"}
                        onChange={(e) => handleChange(e, index)}
                        name="ChangeMode"
                    >
                        <option value="none">No Change</option>
                        <option value="set">Set Value At ...</option>
                        <option value="increase">Increase Value By ...</option>
                        <option value="decrease">Decrease Value By ...</option>
                    </Input>
                </FormGroup>
            </td>
            <td className="align-middle">
                {["set", "increase", "decrease"].includes(value.ChangeMode) && (
                    <InputGroup className="my-1">
                        <Input
                            type="text"
                            name="ChangeValue"
                            value={value.ChangeValue || ''}
                            placeholder="Value"
                            onChange={(e) => handleChange(e, index)}
                            autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <div dangerouslySetInnerHTML={{ __html: value.UnitAlias || '' }} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                )}
                {(value?.ChangeMode === "increase" || value?.ChangeMode === "decrease") && (
                    <div
                        id="change_limit_prompt_145098"
                        className="m--font-info font-weight-500 text-right my-2"
                    >
                        {value?.ChangeMode === "increase" ? "But Not Larger Than" : "But Not Smaller Than"}
                    </div>
                )}
                {["increase", "decrease"].includes(value.ChangeMode) && (
                    <InputGroup className="my-1">
                        <Input
                            type="text"
                            name="ChangeLimit"
                            value={value.ChangeLimit || ''}
                            placeholder="Limit"
                            onChange={(e) => handleChange(e, index)}
                            autoComplete="off"
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <div dangerouslySetInnerHTML={{ __html: value.UnitAlias || '' }} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                )}
            </td>

            <td className="align-middle text-center">
                <Button
                    color="primary"
                    onClick={() => handleSaveValue(index, value)}
                    disabled={value.ChangeMode === "none" || isLoading[index]}
                >
                    {isLoading[index] ? <span className="spinner-border spinner-border-sm ml-2"></span> : <i className="fa fa-floppy-o" aria-hidden="true"></i>}
                </Button>
            </td>
        </tr>
    );
};

export default ItemCell;