import React from "react";
import { Input } from "reactstrap";

function SearchTemplate (props) {
  const { handleChange, value } = props && props;

  return (
    <div className="d-flex align-items-center">
      <Input
        type="text"
        name="search"
        placeholder="Search"
        value={value}
        onChange={handleChange}
        style={{
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          height: "38px",
        }}
        autoComplete="off"
      />
      <button
        className="btn btn-brand"
        style={{
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
        }}
      >
        <i className="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>
  );
}

export default SearchTemplate;
