import React, { useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap'; // Assuming Bootstrap/Reactstrap is being used
import { ProgressStep } from "@ui-partents/ProgressStep";

const ProgressComponent = () => {
  const [step, setStep] = useState(1);
  console.log("🚀 ~ ProgressComponent ~ setStep:", setStep)

  const listStep = [
    {
      StepName: "abc",
    },
    {
      StepName: "def",
    },
    {
      StepName: "ghj",
    },
    {
      StepName: "xyz",
    }
  ]

  return (
        <Card className='my-2'>
            <CardHeader>Progress Step</CardHeader>
            <CardBody>
              <ProgressStep listStep={listStep} step={step}/>
            </CardBody>
        </Card>
    );
};

export default ProgressComponent;