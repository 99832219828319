import React from "react";
import styled from "styled-components";
import { RegisterForm } from "@components/aspet2024";

//Image Background
import backgroundPage from "@images/aspet2024/bg-aspet2024.png";

// Styled component for setting background image
const StyledWrapper = styled.div`
    .wrapper.-loginPartnerPage {
        background-image: url(${backgroundPage});
        background-size: cover;
        background-position: center;
    }
`;

function aspet2024Page(props) {
    return (
        <StyledWrapper>
            <div className="wrapper -loginPartnerPage">
                <RegisterForm />
            </div>
        </StyledWrapper>
    );
}

export default aspet2024Page;