import React from 'react';

//Boostrap
import { Input } from "reactstrap";

function FormSearch(props) {

    const {
        hanldeSearch,
        searchTerm,
        onChangeInputSearch,
        typeTab
    } = props && props;
    
    return (
        <div className="d-flex align-items-center w-100 border-bottom mt-4">
            <Input
                type="text"
                name="search"
                placeholder="Search"
                style={{ height: "38px" }}
                value={searchTerm}
                onChange={(e) => onChangeInputSearch(e, typeTab)}
                autoComplete="off"
            />
            {typeTab === "umls" && (
                <button className="btn btn-brand ml-2" onClick={hanldeSearch}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                </button>
            )}
        </div>
    );
};

export default FormSearch;