import React from 'react';

//components
import { InterventionCollapse } from '../Interventions';

function AccordionFilter(props) {
    const { dataInterventions, listTimepointLast, value } = props && props;
    return (
        <React.Fragment>
            {dataInterventions?.map((item, index) => (
                <InterventionCollapse 
                    {...props}
                    item={item}
                    value={value}
                    listTimepointLast={listTimepointLast}
                    interventionsIndex={index}
                />
            ))}
        </React.Fragment>
    );
};

export default AccordionFilter;