import React, { useState, useMemo } from "react";

//react-select
import Select from "react-select";

function DomainsDropdown(props) {

    //props
    const { dataCase, isDisabled, handleDataCopy } = props && props;

    const [selectedOption, setSelectedOption] = useState(null);

    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.Id, label: item?.Title };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    }

    const handleChange = (data) => {
        setSelectedOption(data);
        handleDataCopy(data);
    };


    const listDropdown = useMemo(() => convertDataOptions((dataCase && dataCase) || []), [dataCase]);
    return (
        <Select
            closeMenuOnSelect={true}
            defaultValue={selectedOption}
            onChange={handleChange}
            options={listDropdown}
            placeholder={`Select an item below`}
            isDisabled={(listDropdown?.length === 0) || isDisabled}
        />
    );
}

export default DomainsDropdown;
