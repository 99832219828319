import React, { useRef, useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TabComponentFill } from "@ui-partents/Tabs";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//partents
import { FormModal } from '@ui-partents/Modal';

//api
import { apiCaller } from "@utils";
import { elementsConstants } from "@constants";

//actions
import { atcLoadChat } from "@actions";

//Edit Chat Topic
import { ResponseTab, ScoringFeedback } from "../ManageAvailableTasks/EditChatTopic";

const ModalFormChatTopic = (props) => {
    const formikRef = useRef(null);
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { isOpen, toggleModal, selectedItem, timepointData, titleModal, typeModal, caseDetails } = props && props;

    //state
    const [activeTab, setActiveTab] = useState(1);
    const [isLoading, setLoading] = useState(false);

    // Validate
    const validationSchema = Yup.object().shape({
        TopicName: Yup.string().required('Topic Name is required'),
        ChatQuestion: Yup.string().required('Question Text is required'),
        ChatResponse: Yup.string().required('Response Text is required'),
        Score: Yup.number()
            .required('Score is required')
            .positive('Score must be a positive number')
            .integer('Score must be an integer'),
        Feedbacks: Yup.string().required('Feedback is required'),
    });

    // Handle Click Submit
    const handleSaveButtonClick = () => {
        if (formikRef.current) {
            formikRef.current.submitForm();
        }
    };

    // Handle Change Tab
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    // Handle Reload Chat Topic
    const handleReloadDataChatTopic = () => {
        const params = {
            Action: "Load",
            Target: "Chat",
            TimePointId: timepointData?.Id,
        };
        dispatch(atcLoadChat(id, params));
    }

    // Define Component for Tabs
    const statusOptions = [
        {
            key: "Response", label: "Response", component: ResponseTab
        },
        {
            key: "Scoring & Feedback", label: "Scoring & Feedback", component: ScoringFeedback
        }
    ];
    const tabs = statusOptions.map((statusOption, index) => ({
        id: index + 1,
        title: statusOption.label,
        content: (
            <statusOption.component
                {...props}
                formikRef={formikRef}
            />
        ),
    }));

    //Handle Change Domains
    const handleChangeDomains = (e, indexItem, setFieldValue) => {
        const { checked } = e.target;
        let newArray = [...caseDetails?.Domains];
        newArray[indexItem]["Selected"] = checked;
        setFieldValue('Domains', newArray);
    };

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={titleModal}
            onAction={handleSaveButtonClick}
            actionText={typeModal === "Create" ? "Add" : "Save"}
            maxWidth="860px"
            isLoading={isLoading}
        >
            <Formik
                initialValues={{
                    TopicName: typeModal === "Create" ? "" : selectedItem?.TopicName,
                    ChatQuestion: typeModal === "Create" ? "" : selectedItem?.ChatQuestion,
                    ChatResponse: typeModal === "Create" ? "" : selectedItem?.ChatResponse,
                    Score: typeModal === "Create" ? 0 : selectedItem?.Score,
                    Feedbacks: typeModal === "Create" ? "" : selectedItem?.Feedbacks,
                    Domains: typeModal === "Create" ? [] : selectedItem?.Domains
                }}
                validationSchema={validationSchema}
                innerRef={formikRef}
                onSubmit={(values) => {
                    const params = {
                        "Action": typeModal === "Create" ? "Create" : "Update",
                        "Target": "Chat",
                        "TimePointId": timepointData?.Id,
                        "TopicId": selectedItem?.TopicId,
                        "IsNegative": true,
                        // Modify
                        "TopicName": values?.TopicName,
                        "ChatResponse": values?.ChatResponse,
                        "ChatQuestion": values?.ChatQuestion,
                        "Score": values?.Score,
                        "Domains": values?.Domains,
                        "Feedbacks": values?.Feedbacks
                    }
                    
                    // Call Api
                    setLoading(true);
                    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
                        const data = res?.data;
                        if (res?.status === 200) {
                            dispatch({ type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS, payload: data });
                            setLoading(false);
                            toggleModal(typeModal);
                            handleReloadDataChatTopic();
                        } else {
                            dispatch({
                                type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_FAILURE,
                                error: "error",
                            });
                            setLoading(false);
                            toggleModal(typeModal);
                        }
                    });
                }}
            >
                {({ values, errors, handleChange, handleBlur, isSubmitting, touched, setFieldValue }) => (
                    <Form>
                        <FormGroup>
                            <Label className="labeForm" for="TopicName">Topic Name <span className="text-danger">*</span></Label>
                            <Input
                                type="text"
                                name="TopicName"
                                id="TopicName"
                                value={values.TopicName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.TopicName && touched.TopicName}
                            />
                            <FormFeedback>{errors.TopicName}</FormFeedback>
                        </FormGroup>
                        <TabComponentFill
                            tabs={tabs.map(tab => ({
                                ...tab,
                                content: React.cloneElement(tab.content, {
                                    ...props,
                                    values: values,
                                    errors: errors,
                                    touched: touched,
                                    handleChange: handleChange,
                                    handleBlur: handleBlur,
                                    setFieldValue: setFieldValue,
                                    typeModal: typeModal,
                                    handleChangeDomains: (e, index) => handleChangeDomains(e, index, setFieldValue)
                                })
                            }))}
                            tabsForm={true}
                            activeTab={activeTab}
                            onTabChange={handleTabChange}
                        />
                    </Form>
                )}
            </Formik>
        </FormModal>
    );
};

export default ModalFormChatTopic;