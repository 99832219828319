import React from 'react';
import styled, { css } from 'styled-components';

const StatusField = ({ color, content }) => {
  
  return (
    <>
      <div>
        <StatusContainer color={color}>{content}</StatusContainer>
      </div>
    </>
  )
};

const commonStyles = css`
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;

const StatusContainer = styled.div`
  ${commonStyles}
  border: 1px solid ${(props) => {
    switch (props.color) {
      case 'Audio':
        return '#F9DBAF';
      case 'URL':
        return '#B2DDFF';
      case 'Image':
        return '#ABEFC6';
      default:
        return '#D9D6FE';
    }
  }};
  background: ${(props) => {
    switch (props.color) {
      case 'Audio':
        return '#FEF6EE';
      case 'URL':
        return '#EFF8FF';
      case 'Image':
        return '#ECFDF3';
      default:
        return '#F4F3FF';
    }
  }};
  color: ${(props) => {
    switch (props.color) {
      case 'Audio':
        return '#B93815';
      case 'URL':
        return '#175CD3';
      case 'Image':
        return '#067647';
      default:
        return '#5925DC';
    }
  }};
  &::before {
    background-color: ${(props) => {
    switch (props.color) {
      case 'Audio':
        return '#EF6820';
      case 'URL':
        return '#2E90FA';
      case 'Image':
        return '#17B26A';
      default:
        return '#7A5AF8';
    }
  }};
  }
`;

export default StatusField;