import React, { useState } from "react";

//boostrap
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";

//redux
import { useSelector } from "react-redux";

//components
import { PortfolioNavItem, CaseList, SeriesList } from '../portfolio';

//untils
import { LoaderText, useQuery } from "@utils";

function PortfolioContainer(props) {
  let query = useQuery();
  let slug = query.get("status");
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //get data store
  const portfolioData = useSelector((state) => state.portfolio || []);
  const dataResponse = portfolioData?.data;
  const caseData = dataResponse?.Enrolled?.Cases;
  const filteredCaseData = caseData?.filter((item) =>
    //check list các series và cases có status == ‘completed’ ở page /portfolio/completed
    slug === "in-progress" ? (item?.Progress?.status === "not_started") || (item?.Progress?.status === "in_progress") : item?.Progress?.status === "completed"
  );

  const seriesData = dataResponse?.Enrolled?.Series;
  const filteredSeriesData = seriesData?.filter((item) =>
    //check list các series và cases có status == ‘completed’ ở page /portfolio/completed
    slug === "in-progress" ? (item?.Progress?.status === "not_started") || (item?.Progress?.status === "in_progress") : item?.Progress?.status === "completed"
  );

  return (
    <Container className="position-relative">
      {portfolioData?.isLoading && <div style={{height: '300px'}}><LoaderText /></div>}
      <Row>
        <Col md={12}>
          <Alert
            className="m-alert--default mb-3 border-0"
            color="secondary"
          >
            {slug === "in-progress"
              ? "Below are the series and cases that you have started. Click the  “Resume” button of each case to continue where you left off."
              : "Below are the series and cases that you have completed. Click on the “Review” button to review your performance, or to restart the modules."
            }
          </Alert>
          <PortfolioNavItem
            activeTab={activeTab}
            filteredCaseData={filteredCaseData}
            filteredSeriesData={filteredSeriesData}
            toggle={toggle}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <CaseList
                data={(filteredCaseData && filteredCaseData)}
                slug={slug}
              />
            </TabPane>
            <TabPane tabId="2">
                <SeriesList
                  filteredSeriesData={filteredSeriesData}
                  filteredCaseData={filteredCaseData}
                  slug={slug}
                />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}

export default PortfolioContainer;