import React from "react";
import { ContentProcess } from "@components/common";

function PlanErrorPage(props) {
  const data = {
    title: "Error",
    description: "",
  };
  const iconStatus = "not-checked";
  return (
    <div className="wrapper -completedPage">
      <ContentProcess data={data} icon={iconStatus} />
    </div>
  );
}

export default PlanErrorPage;
