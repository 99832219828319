import React from 'react';

//Icon
import { Iconsax } from "@components-common";

const RemindListItem = ({ iconSrc, Title, Content }) => {
    return (
        <li className="remind-list__item">
            <div className="remind-inner-ct">
                <img src={iconSrc} alt="icon assignment" />
                <div className="remind-inner-info">
                    <h3 className="title">{Title}</h3>
                    <span className="date">{Content}</span>
                    <span className="icon-ps-item pl-2">
                        <Iconsax iconName="arrow-right" fill="#697586" />
                    </span>
                </div>
            </div>
        </li>
    );
};

export default RemindListItem;