// --------
//Orders/Medications
//Actions/Actions
// --------
import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

//common
import { DataEmty } from "@components-common";

import {
  ModalEffect,
  ModalIntervention,
  ModalDeleteIntervention,
  ModalAddEffects,
  ModalScore,
  ModalDeleteEffects,
  ModalUpdateCategory,
  ModalDelCategory,
} from "../Modal";

//redux
import { useDispatch } from "react-redux";

//action
import {
  updateAvailabilityForMedication,
  updateAvailabilityForAction,
  updateAvailableForAction,
  updateAvailableForMedication
} from "@actions";

//lodash
import _ from 'lodash';

//components
import {
  AccordionFilter,
  TableInterventions,
  AccordionHeader
} from '../../storyboard/common';

//api
import { apiCaller } from "@utils";
import { actionConstants, medicationConstants } from "@constants";

//Orders - Action - Medications
function Accordion(props) {
  let indexPlus;
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { data, categoryType, timePointId } = props && props;

  const [dataAccordion, setDataAccordion] = useState(data || []);
  const [modalIntervention, setModalIntervention] = useState(false);
  const [modalEffect, setModalEffect] = useState(false);
  const [modalDelEffect, setModalDelEffect] = useState(false);
  const [modalAddEffect, setModalAddEffect] = useState(false);
  const [active, setActive] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [dataEffects, setDataEffects] = useState({});
  const [activeCategory, setActiveCategory] = useState(0);
  const [interventionId, setInterventionId] = useState(0);
  const [effectId, setEffectId] = useState(0);
  const [typeIntervention, setTypeIntervention] = useState("");
  const [dataIntervention, setdataIntervention] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isLoadingInBatch, setLoadingInBatch] = useState(false);

  //score
  const [modalScore, setModalCore] = useState(false);
  const [actionScore, setActionScore] = useState("");
  const [itemEdit, setItemEdit] = useState({});
  const [itemScore, setItemScore] = useState("");

  //category state
  const [dataCurrent, setDataCurrent] = useState({});
  const [actionsCategory, setActionsCategory] = useState("");
  const [modalCategory, setModalCategory] = useState({
    addCategory: false,
    editCategory: false,
    deleteCategory: false
  });


  useEffect(() => {
    setDataAccordion(data || []);
  }, [data])

  //delete
  const [modalDel, setModalDel] = useState(false);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const handleToogleRow = (id) => {
    setActiveItem(id);
  };

  const defineChangeMode = (type, value, unit, limit) => {
    if (type === "increase") {
      return `Increase by: <span>${value} ${unit}</span>${" "}<br/> (but not larger than ${limit} ${unit})`;
    } else if (type === "“decrease”") {
      return `Decrease by: <span>${value} ${unit}</span>${" "}<br/> (but not smaller than ${limit} ${unit})`;
    } else {
      return `Set at: <span>${value} ${unit}</span>${" "}`;
    }
  };

  const toggleIntervention = (type, data, idCategory) => {
    setModalIntervention(!modalIntervention);
    setTypeIntervention(type);
    setdataIntervention(data);
    setActiveCategory(idCategory);
  };

  const toggleEffect = (data, idCategory, InterventionId) => {
    setModalEffect(!modalEffect);

    //set data modal
    setDataEffects(data);
    setActiveCategory(idCategory);
    setInterventionId(InterventionId);
  };

  //toggle - all actions category
  const toggleCategory = (type, value) => {
    setModalCategory((prevState) => ({ ...prevState, [type]: !modalCategory[type] }));
    setDataCurrent(value);
    setActiveCategory((value && value.CategoryId) || 0);
    setActionsCategory(type);
  };

  const toggleAddEffect = (InterventionId, idCategory) => {
    setTypeIntervention("addEffects");
    setInterventionId(InterventionId);
    setActiveCategory(idCategory);
    setModalAddEffect(!modalAddEffect);
  };

  const toggleDel = (type, InterventionId, idCategory) => {
    setTypeIntervention(type);
    setInterventionId(InterventionId);
    setActiveCategory(idCategory);
    setModalDel(!modalDel);
  };

  const handleChangeAvailable = (e, InterventionId, idCategory) => {
    const isChecked = e.target.checked;
    const paramsUpdate = {
      Action: "Update",
      Target: "Intervention",
      TimePointId: timePointId,
      InterventionId: InterventionId,
      CategoryId: idCategory,
      Available: isChecked,
    };
    if (categoryType === "Action") {
      dispatch(updateAvailableForAction(id, paramsUpdate, InterventionId, idCategory));
    } else {
      dispatch(updateAvailableForMedication(id, paramsUpdate, InterventionId, idCategory));
    }
  };

  //Hanlde Available group
  const handleAllAvailable = (status, data, categoryId) => {
    const newAvailabilityData = data?.map((item) => {
      const isChecked = status === "Available" ? true : false;
      return {
        InterventionId: item.Id,
        CategoryId: categoryId,
        Available: isChecked,
      };
    });

    const params = {
      Action: "Update",
      Target: "InterventionAvailability",
      TimePointId: timePointId,
      InterventionAvailabilityData: newAvailabilityData || [],
    };

    if (categoryType === "Medication") {
      params.InterventionType = "Medication";
      dispatch(updateAvailabilityForMedication(id, params, categoryId));
    } else {
      params.InterventionType = "Action";
      dispatch(updateAvailabilityForAction(id, params, categoryId));
    }
  };

  //Case - Update Intervention Available in Batch
  const updateAvailableinBatchWithApi = (params, type) => {
    setLoadingInBatch(true);

    const isLoading = true;
    //Call Api
    dispatch({
      type: "Medication" ? medicationConstants.UPDATE_AVAILABLE_IN_BATCH_MEDICATION_REQUEST : actionConstants.UPDATE_AVAILABLE_IN_BATCH_ACTION_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: type === "Medication" ? medicationConstants.UPDATE_AVAILABLE_IN_BATCH_MEDICATION_SUCCESS : actionConstants.UPDATE_AVAILABLE_IN_BATCH_ACTION_SUCCESS,
          payload: data
        });

        setLoadingInBatch(false);
      } else {
        dispatch({
          type: type === "Medication" ? medicationConstants.UPDATE_AVAILABLE_IN_BATCH_MEDICATION_FAILURE : actionConstants.UPDATE_AVAILABLE_IN_BATCH_ACTION_FAILURE,
          error: 'error'
        });
        setLoadingInBatch(false);
      }
    });
  }

  //Score
  const toggleScore = (data, type, itemScore, idCategory) => {
    setActionScore(type);
    setItemEdit(data);
    setItemScore(itemScore);
    setModalCore(!modalScore);
    setActiveCategory(idCategory);
  };

  const toggleDelEffects = (InterventionId, idCategory, EffectId) => {
    setInterventionId(InterventionId);
    setModalDelEffect(!modalDelEffect);
    setActiveCategory(idCategory);
    setEffectId(EffectId);
  };

  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setDataAccordion(data);
    } else {
      const newArrFilter = _.cloneDeep(data);
      if (newArrFilter.length > 0) {
        const searchResult = _.filter(newArrFilter, category => {
          return (
            category.Name.includes(lowercasedValue) ||
            _.find((category.Interventions || []), intervention => intervention.Name.toLowerCase().includes(lowercasedValue))
          )
        }).map(item => {
          item.Interventions = _.filter((item.Interventions || []), intervention => intervention.Name.toLowerCase().includes(lowercasedValue))
          return item
        })
        setDataAccordion(searchResult);
      }
    }
  }

  return (
    <div className="medicationsTab">
      <div className={`accordionExam ${isLoadingInBatch ? 'overlayLoader' : ""}`}>
        <AccordionFilter
          {...props}
          toggleCategory={() => toggleCategory("addCategory", {})}
          onSearch={onSearch}
          searchText={searchText}
          updateAvailableinBatchWithApi={updateAvailableinBatchWithApi}
          isLoading={isLoadingInBatch}
        />
        {dataAccordion?.length === 0 && <DataEmty content="No Data" />}
        {dataAccordion?.map((value, index) => (
          <div className="accordionExam__item" key={index}>
            <AccordionHeader
              eventHandler={eventHandler}
              index={index}
              active={active}
              indexCount={indexCount}
              value={value}
              toggleCategory={toggleCategory}
            />
            <TableInterventions
              {...props}
              index={index}
              indexCount={indexCount}
              active={active}
              value={value}
              handleChangeAvailable={handleChangeAvailable}
              toggleScore={toggleScore}
              handleToogleRow={handleToogleRow}
              toggleIntervention={toggleIntervention}
              toggleDel={toggleDel}
              activeItem={activeItem}
              defineChangeMode={defineChangeMode}
              toggleEffect={toggleEffect}
              toggleDelEffects={toggleDelEffects}
              toggleAddEffect={toggleAddEffect}
              toggleCategory={toggleCategory}
              handleAllAvailable={handleAllAvailable}
            />
          </div>
        ))}
        {/* /Modal */}
        {/* Add and Delete Category */}
        <ModalUpdateCategory
          {...props}
          isOpen={actionsCategory === 'addCategory' ? modalCategory.addCategory : modalCategory.editCategory}
          dataCurrent={dataCurrent}
          toggle={() => toggleCategory(actionsCategory)}
          type={categoryType}
          action={actionsCategory}
        />

        <ModalDelCategory
          {...props}
          activeCategory={activeCategory}
          isOpen={modalCategory.deleteCategory}
          dataCurrent={dataCurrent}
          toggle={() => toggleCategory("deleteCategory")}
          type={categoryType}
          action={actionsCategory}
        />
        {/* /Add and Delete Category */}

        <ModalIntervention
          {...props}
          toggle={toggleIntervention}
          isOpen={modalIntervention}
          typeIntervention={typeIntervention}
          dataIntervention={dataIntervention}
          activeCategory={activeCategory}
        />
        <ModalDeleteIntervention
          {...props}
          toggleDel={toggleDel}
          isOpen={modalDel}
          interventionId={interventionId}
          activeCategory={activeCategory}
        />
        <ModalEffect
          {...props}
          toggle={toggleEffect}
          isOpen={modalEffect}
          data={dataEffects}
          activeCategory={activeCategory}
          interventionId={interventionId}
        />
        <ModalAddEffects
          {...props}
          toggle={toggleAddEffect}
          isOpen={modalAddEffect}
          typeIntervention={typeIntervention}
          interventionId={interventionId}
          activeCategory={activeCategory}
        />
        {/* Modal Score */}
        <ModalScore
          {...props}
          toggle={toggleScore}
          isOpen={modalScore}
          actions={actionScore}
          itemEdit={itemEdit}
          attributeType={categoryType}
          itemScore={itemScore}
          activeCategory={activeCategory}
        />
        <ModalDeleteEffects
          {...props}
          interventionId={interventionId}
          toggle={toggleDelEffects}
          isOpen={modalDelEffect}
          activeCategory={activeCategory}
          effectId={effectId}
        />
      </div>
    </div>
  );
}

export default Accordion;