import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import debounce from 'lodash.debounce'; // Make sure to install lodash.debounce

// Create a context
const ScreenSizeContext = createContext();

// Create a provider component
export const ScreenSizeProvider = ({ children }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  // State to hold the current screen size and width
  const [currentScreenSize, setCurrentScreenSize] = useState('desktop'); // default to desktop
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Callback to update screen size and width
  const updateScreenSize = useCallback(() => {
    setScreenWidth(window.innerWidth);
    if (isMobile) {
      setCurrentScreenSize('mobile');
    } else if (isTablet) {
      setCurrentScreenSize('tablet');
    } else if (isDesktop) {
      setCurrentScreenSize('desktop');
    }
  }, [isMobile, isTablet, isDesktop]);

  // Effect to update screen size and width on mount and resize
  useEffect(() => {
    // Call it on mount
    updateScreenSize();

    // Debounce the resize event handler to avoid performance issues
    const handleResize = debounce(updateScreenSize, 200);
    
    // Listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [updateScreenSize]);

  return (
    <ScreenSizeContext.Provider value={{ isMobile, isTablet, isDesktop, currentScreenSize, screenWidth }}>
      {children}
    </ScreenSizeContext.Provider>
  );
};

// Custom hook to use the screen size context
export const useScreenSize = () => useContext(ScreenSizeContext);