import React from 'react';

//Avatar
import NotAvatar from "@images/common/no-avatar.jpg";

function AvatarProgressBar(props) {
    //props
    const { profile_photo_url } = props && props; //XPData
    // const CurrentLevel = XPData?.UserLevel?.CurrentLevel || 0;

    // const percentage = CurrentLevel;
    // const radius = 75; // Bán kính của progress-bar
    // const circumference = 2 * Math.PI * radius; // Chu vi của progress-bar
    // const offset = circumference - (percentage / 100) * circumference; // Lượng offset để hiển thị tiến trình
    return (
        <div className="indicator-progress-bar">
            <div className="avatar" style={{ backgroundImage: `url(${profile_photo_url || NotAvatar})` }}></div>
            {/* <svg className="progressBar" width="100%" height="100%">
                <circle
                    className="background"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth="10"
                    stroke="#fff"
                />
                <circle
                    className="progressColor"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth="10"
                    stroke="#f1800f"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg> */}
            {/* <div className="percentage-container">
                <div className="percentage" data-percentage={CurrentLevel}></div>
            </div> */}
        </div>
    );
}

export default AvatarProgressBar;