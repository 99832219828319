import React from "react";
import ReactPlayer from "react-player";

//boostrap
import { Container, Row, Col } from "reactstrap";

function DemoPage() {
  return (
    <div className="wrapper -demoPage">
      <Container>
        <Row>
          <Col md={10} className="text-center mx-auto">
            <div className="playerWrapper">
              <ReactPlayer
                url={`https://youtu.be/YcZ0Cd8bshg?autoplay=1`}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
                playing
                muted
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DemoPage;
