import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Row,
  Col,
  Button,
  Input
} from "reactstrap";

//utils
import { apiCaller, truncate, LoaderText } from "@utils";
import { storyboardConstants } from "@constants";

//alert
import { toast } from 'react-toastify';

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//common
import { DataEmty } from "@components/common";
import { IconDisplay } from "@components/common/media";
import { ShowOnlyAvailable } from "../../storyboard/common";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';
import { DropdownGroupAvailability } from '../../storyboard/Orders';

//components
import {
  ModalEditStudies,
  ModalScore,
  ModalDeleteStudy,
} from "../../storyboard/Modal";
import { BadgeScoreStudy, DropDownGenerate, DropdownActions } from '../../storyboard/Orders';

//redux
import { useSelector } from "react-redux";

function StudyTable(props) {
  //props
  const { data, timePointId, timepointData, handleChangeCheckbox } = props && props;
  let isTime = timepointData?.data?.Time;

  // Su dung selector de kiem tra Action EXTRACT_LABS_SUCCESS dang request loading list
  const extractStudies = useSelector(
    (state) => state.extractStudies || {}
  );

  //redux
  let { id } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [actions, setActions] = useState("");
  const [itemCurrent, setItemCurrent] = useState({});
  const [stateChecked, setStateChecked] = useState([]);
  const [isLoading, setLoading] = useState(false);

  //score
  const [modalScore, setModalCore] = useState(false);
  const [actionScore, setActionScore] = useState("");
  const [attributeType, setAttributeType] = useState("");
  const [itemScore, setItemScore] = useState("");
  const [resData, setResData] = useState(data || []);

  //dropdown
  const [dropdownOpen, setOpenDropdown] = useState({
    availability: false,
    deleteRow: false
  });

  const toggleDropdown = (type) => {
    setOpenDropdown((prevState) => ({ ...prevState, [type]: !dropdownOpen[type] }));
  };

  useEffect(() => {
    setResData(data || []);
  }, [data]);

  //Score
  const toggleScore = (data, type, AttributeType, itemScore) => {
    setActionScore(type);
    setModalCore(!modalScore);
    setItemCurrent(data);
    setAttributeType(AttributeType);
    setItemScore(itemScore);
  };

  const toggle = (item, type) => {
    setItemCurrent(item);
    setModal(!modal);
    setActions(type);
  };

  const toggleDel = (item, type) => {
    setItemCurrent(item);
    setModalDel(!modalDel);
    setActions(type);
  };

  const handleAllAvailable = (status) => {
    const isChecked = status === "Available" ? true : false;
    const newAvailabilityData = resData.map((item) => {
      return {
        AttributeId: item.Id,
        Available: isChecked,
      };
    });

    const params = {
      Action: "Update",
      Target: "AttributeAvailability",
      TimePointId: timePointId,
      AttributeType: "Study",
      AttributeAvailabilityData: newAvailabilityData,
    };

    UpdateStudyAvailabilityinBatch(id, params, newAvailabilityData, isChecked);
  };

  const UpdateStudyAvailabilityinBatch = (id, params, list, isChecked) => {
    setLoading(true);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_STUDY_AVAILABILITY_IN_BATCH_SUCCESS,
          payload: data
        });

        toast.success(`Turn availability to ${isChecked ? 'ON' : 'OFF'} ${list?.length} items`, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else {
        console.log('error');
        setLoading(false);
      }
    });
  }

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Name",
      text: "Study",
      editable: false,
      classes: "align-middle",
      headerClasses: "align-middle text-left font-weight-500 --text-primary",
    },
    {
      dataField: "InitialValue",
      text: "Initial Finding",
      classes: "align-middle",
      headerClasses: "align-middle text-left font-weight-500 --text-primary",
      formatter: (cell, row) => {
        return <div dangerouslySetInnerHTML={{ __html: row?.InitialValue ? truncate(row?.InitialValue, 50) : '' }} />;
      }
    },
    {
      dataField: "MediaType",
      text: "Initial Media",
      editable: false,
      classes: "align-middle text-center",
      headerClasses: "align-middle text-center font-weight-500 --text-primary text-nowrap",
      formatter: (cell, row) => {
        const isMedia = row && row.MediaType;
        return (
          <div
            style={{ cursor: "pointer" }}
            className="text-center"
            onClick={() => toggle(row, "edit-study")}
          >
            <IconDisplay type={isMedia} />
          </div>
        );
      },
    },
    {
      dataField: "Avaliable",
      text: "Avaliable at Presentation",
      editable: false,
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center align-items-center">
            <Input
              type="checkbox"
              defaultChecked={row?.Available}
              data-checked={row?.Available}
              data-id={row?.Id}
              key={Math.random()}
              name="Available"
              onClick={(e) => handleChangeCheckbox(e, row, "item")}
              className="m-0"
            />
          </div>
        );
      },
      classes: "align-middle text-center",
      headerClasses: "align-middle text-center font-weight-500 --text-primary",
    },
    {
      dataField: "Score",
      text: "Score",
      editable: false,
      formatter: (cell, row) => {
        return (
          <BadgeScoreStudy
            {...props}
            listDomain={row?.DomainScores || []}
            itemRow={row || {}}
            toggleScore={toggleScore}
          />
        );
      },
      classes: "align-middle text-center",
      headerClasses: "align-middle text-center font-weight-500 --text-primary",
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      style: {
        width: "120px",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex justify-content-center">
            <ButtonEdit handleEdit={() => toggle(row, "edit-study")} />
            <ButtonDelete handleDelete={() => toggleDel(row, "del-study")} />
          </div>
        );
      },
      classes: "align-middle",
      headerClasses: "align-middle text-center font-weight-500 --text-primary",
    },
  ];

  const { SearchBar } = Search;

  const selectRow = {
    mode: 'checkbox',
    // clickToSelect: true, //hidden select row
    onSelect: (row, isSelect) => {
      let newArray = [...stateChecked, row.Id];
      if (stateChecked.includes(row.Id)) {
        newArray = newArray.filter((item) => item !== row.Id);
      }
      setStateChecked(newArray);
    },

    onSelectAll: (isSelect, rows, e) => {
      const newArr = [];
      if (isSelect) {
        rows.map((value) => newArr.push(value.Id));
        setStateChecked(newArr);
      } else {
        setStateChecked([]);
      }
    },
    headerColumnStyle: {
      verticalAlign: 'middle'
    },
    selectColumnStyle: {
      verticalAlign: 'middle'
    }
  };

  return (
    <div className={`${isLoading ? "overlayLoader" : ""}`}>
      <ToolkitProvider
        keyField="Id"
        key={JSON.stringify(resData)} //reload data
        data={resData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <Row className="justify-content-end mb-3">
              <Col md={stateChecked?.length ? 5 : 6}>
                <div className="boxSearch">
                  <SearchBar {...toolkitprops.searchProps} />
                  <Button className="--bg-primary btnSearch" color="primary">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </Button>
                </div>
              </Col>
              <Col md={stateChecked?.length ? 7 : 6}>
                <div className="d-flex ml-auto justify-content-end">
                  {/* Chi hien thi timepoint dau */}
                  {isTime === 0 && <DropDownGenerate {...props} />}
                  <button
                    className="btn btn-brand d-block ml-2"
                    onClick={() => toggle({}, "add-study")}
                  >
                    <i className="fa fa-plus"></i>&nbsp;Add
                  </button>
                  <DropdownGroupAvailability
                    toggleDropdown={toggleDropdown}
                    handleAllAvailable={handleAllAvailable}
                    dropdownOpen={dropdownOpen.availability}
                    isLoading={isLoading}
                  />
                  <DropdownActions
                    {...props}
                    dropdownOpen={dropdownOpen.deleteRow}
                    stateChecked={stateChecked}
                    toggleDropdown={toggleDropdown}
                  />
                </div>
              </Col>
              <Col md={12}>
                <ShowOnlyAvailable
                  {...props}
                  name="studies"
                  label={`Show only available studies`}
                />
              </Col>
            </Row>
            <div className="position-relative">
              {extractStudies?.isLoading && <div style={{ height: '200px' }}><LoaderText /></div>}
              {!extractStudies?.isLoading && (
                <BootstrapTable
                  condensed
                  wrapperClasses="table-responsive"
                  bordered={false}
                  selectRow={selectRow}
                  {...toolkitprops.baseProps}
                  noDataIndication={() => (
                    <DataEmty content="No Data" />
                  )}
                />
              )}
            </div>
          </>
        )}
      </ToolkitProvider>
      <ModalEditStudies
        {...props}
        actions={actions}
        data={itemCurrent}
        isOpen={modal}
        toggle={toggle}
        typeComponent="storyboard"
      />
      <ModalDeleteStudy
        {...props}
        data={itemCurrent}
        isOpen={modalDel}
        toggle={toggleDel}
      />
      {/* Modal Score */}
      <ModalScore
        {...props}
        toggle={toggleScore}
        isOpen={modalScore}
        actions={actionScore}
        itemEdit={itemCurrent}
        attributeType={attributeType}
        itemScore={itemScore}
      />
    </div>
  );
}

export default StudyTable;