import React from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { MainTabs } from '@components/schemav2/TabsGroupSchema';

function SchemaV2Page(props) {
  return (
    <div className="wrapper -schemaPage">
      <Container className="bg-white">
        <div className="diagnosticCt">
          <MainTabs {...props} />
        </div>
      </Container>
    </div>
  );
}

export default SchemaV2Page;