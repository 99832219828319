import React from "react";
import { Link } from "react-router-dom";

function ButtonBack(props) {
  const { url } = props && props;
  return (
    <Link
      className="--text-primary text-3 d-flex align-items-center mb-3"
      style={{ cursor: "pointer" }}
      to={url}
    >
      <i className="fa fa-angle-left mr-2 text-4" aria-hidden="true"></i> Back
    </Link>
  );
}

export default ButtonBack;
