import React, { useState } from "react";

//reactstrap
import { FormGroup, Label, Button, Table } from "reactstrap";

function ConceptsMain(props) {

    //props
    const { handleSubmit, disabled, isLoading } = props && props;

    //state
    const [stateRow, setStateRow] = useState([]);

    const handleAddRow = () => {
        const newArr = [...stateRow];
        var result = [...newArr, ""];
        setStateRow(result);
    };

    const handleRemoveRow = (key) => {
        const newArr = [...stateRow];
        const newData = newArr.filter(function (obj, index) {
            return index !== key;
        });
        setStateRow(newData);
    };

    const handleChangeInput = (e, index) => {
        const { value } = e.target;
        const newArr = [...stateRow];
        newArr[index] = value;
        setStateRow(newArr);
    };

    return (
        <React.Fragment>
            <FormGroup>
                <Label className="font-weight-500">Concepts:</Label>
                <Table responsive>
                    <thead>
                        <tr>
                            <th width="50" className="align-middle font-weight-500">#</th>
                            <th className="align-middle font-weight-500">
                                Concept Text <span className="m--font-danger">*</span>
                            </th>
                            <th className="align-middle" width="50"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stateRow?.map((value, index) => (
                            <tr key={index}>
                                <td className="align-middle">{index + 1}</td>
                                <td className="align-middle">
                                    <textarea
                                        name="Concepts"
                                        className="form-control m-input"
                                        rows={1}
                                        defaultValue={stateRow[index]}
                                        data-index={index}
                                        onChange={(e) => handleChangeInput(e, index)}
                                    />
                                </td>
                                <td className="align-middle text-center">
                                    <i
                                        style={{ cursor: "pointer" }}
                                        className="fa fa-2x fa-times text-danger"
                                        aria-hidden="true"
                                        onClick={() => handleRemoveRow(index)}
                                    ></i>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button
                    className="btn btn-outline-brand d-block mx-auto mt-4"
                    onClick={handleAddRow}
                >
                    <i className="fa fa-plus" />
                    &nbsp; More Options
                </Button>
            </FormGroup>
            <div className="text-right">
                <Button
                    color="primary"
                    className="btn btn-brand mt-3 d-flex align-items-center ml-auto"
                    onClick={() => handleSubmit(stateRow)}
                    disabled={disabled}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Submit
                </Button>
            </div>
        </React.Fragment>
    );
}

export default ConceptsMain;