import React, { useState, useEffect, forwardRef } from "react";

//boostrap
import {
  Button,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col
} from "reactstrap";

//DatePicker
import DatePicker from "react-datepicker";

function Filter(props) {
  //props
  const {
    handleSubmitFilter,
    handleResetFilter,
    handleChangeFilter,
    stateFilter,
    handleChangeDate
  } = props && props;
  const [filter, setFilter] = useState(stateFilter);

  useEffect(() => {
    setFilter(stateFilter);
  }, [stateFilter]);

  const handleSubmit = () => {
    handleSubmitFilter();
  };

  const CutomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
      <Input onClick={onClick} defaultValue={value} type="text" ref={ref} />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <i className="fa fa-calendar" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  ));

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col md={`4`}>
          <FormGroup>
            <Label className="font-weight-500" htmlFor="session_id">
              Session ID
            </Label>
            <Input
              type="number"
              name="session_id"
              placeholder="Session ID"
              style={{ height: "38px" }}
              onChange={handleChangeFilter}
              autoComplete="off"
              inputMode="numeric"
              min="0"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={filter?.session_id || ""}
            />
          </FormGroup>
        </Col>
        <Col md={`4`}>
          <FormGroup>
            <Label className="font-weight-500" htmlFor="user_name">
              User
            </Label>
            <Input
              type="text"
              name="user_name"
              placeholder="User"
              style={{ height: "38px" }}
              onChange={handleChangeFilter}
              autoComplete="off"
              value={filter?.user_name || ""}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="align-items-end">
        <Col md={`4`}>
          <FormGroup>
            <Label className="font-weight-500" htmlFor="from_date">
              From Date
            </Label>
            <DatePicker
              placeholder="mm/dd/yyyy, --:-- --"
              selected={filter?.from_date}
              onChange={(date) => handleChangeDate(date, 'from_date')}
              selectsStart
              startDate={filter?.from_date}
              endDate={filter?.to_date}
              className="form-control"
              dateFormat="MMMM d, yyyy h:mm a"
              showTimeInput
              timeInputLabel="Time:"
              customInput={<CutomInput />}
            />
          </FormGroup>
        </Col>
        <Col md={`4`}>
          <FormGroup>
            <Label className="font-weight-500" htmlFor="to_date">
              End Date
            </Label>
            <DatePicker
              placeholder="mm/dd/yyyy, --:-- --"
              selected={filter?.to_date}
              onChange={(date) => handleChangeDate(date, 'to_date')}
              selectsEnd
              startDate={filter?.from_date}
              endDate={filter?.to_date}
              minDate={filter?.from_date}
              className="form-control"
              dateFormat="MMMM d, yyyy h:mm a"
              showTimeInput
              timeInputLabel="Time:"
              customInput={<CutomInput />}
            />
          </FormGroup>
        </Col>
        <Col md={`2`}>
          <FormGroup>
            <Button className="--btn-primary" onClick={handleSubmit}>
              <i className="fa fa-search" aria-hidden="true"></i>
            </Button>
            <Button className="btn-danger ml-2" onClick={handleResetFilter}>
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Filter;
