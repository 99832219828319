import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import * as Config from "@constants/Config";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { actionLogin, actFetchThemeRequest } from "@actions";
import { useLocation } from "react-router-dom";

const LoadingAccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let history = useHistory();

  const handleLoginSuccess = (dataResponse, queryUrl) => {
    const accountData = dataResponse?.account;
    const isAdministrator = accountData?.is_administrator === 'Yes';
    const isInstructor = accountData?.is_instructor === 'Yes';
    const isSubscription = accountData?.subscription;

    // Set localStorage
    localStorage.setItem("Account", JSON.stringify(accountData || ""));

    // Add token and fetch theme
    dispatch(actionLogin(dataResponse));
    dispatch(actFetchThemeRequest());

    if (queryUrl && queryUrl !== 'try_sample_request') {
      history.push(queryUrl);
    } else {
      // Redirect based on role and subscription status
      redirectUser(isInstructor, isSubscription, isAdministrator, dataResponse);
    }
  };

  const redirectUser = (isInstructor) => {
    if (isInstructor) {
      history.push(`/teach/home`);
    } else {
      history.push("/learn/home");
    }
  };

  useEffect(() => {
    const fragmentString = window.location.hash.substring(1);
    const newParams = fragmentString.split('&').reduce((params, param) => {
      const [key, value] = param.split('=');
      params[decodeURIComponent(key)] = decodeURIComponent(value);
      return params;
    }, {});
    if (location?.hash?.includes("access_denied")) {
      history.push("/login");
    }

    const queryUrlRedirect = newParams?.state !== 'try_sample_request' ? decodeURIComponent(newParams?.state) : newParams?.state;
    if (newParams.access_token) {
      const apiUrl = `${Config.API_URL}/sso/google-user-profile/?access_token=${newParams.access_token}`;
      fetch(apiUrl)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            console.log('Unauthorized error');
          }
        })
        .then(data => {
          if (data && data.status === 'OK') {
            handleLoginSuccess(data, queryUrlRedirect);
          } else {
            console.error('Invalid response structure');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wrapper -loadingAccess d-flex justify-content-center mt-5" >
      <Spinner>
        Loading...
      </Spinner>
    </div>
  );
}

export default LoadingAccess;