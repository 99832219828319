import React from 'react';

//components
import { CheckPointstable, HeadTabs } from "../../storyboard/common";
import { DragQuestion } from "../../storyboard/Question";

//redux
import { useSelector } from "react-redux";

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//RichTextCommon
import RichTextCommon from "../RichTextCommon";

function AdvanceMain(props) {
    //props
    const {
        value,
        activeTabCt,
        listTabs,
        handleUpdateEditor,
        valueEditor,
        timePointId,
        filterQuestionForTask
    } = props && props;

    //get data store
    const checkPointsData = useSelector((state) => state?.loadCheckpoints || []);
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='advance'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.advance.map(
                    (item, index) =>
                        item.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item.id === "1" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={value?.ViewClass}
                                        defaultValue={valueEditor?.advance}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item.id === "2" && (
                                    <DragQuestion
                                        {...props}
                                        task={value.ViewClass}
                                        data={filterQuestionForTask(value.ViewClass)}
                                    />
                                )}
                                {item.id === "3" && (
                                    <CheckPointstable
                                        {...props}
                                        data={checkPointsData?.data || []}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
};

export default AdvanceMain;