import React from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { toggleMenuMobile } from "@actions";

const ButtonMenuMobile = (props) => {
  const dispatch = useDispatch();
  // Get the current location
  const location = useLocation();
  const isOpenMenuMobile = useSelector((state) => state.isOpenMenuMobile.isOpen);

  // Check if the current path contains 'learner'
  const isLearnerPath = location.pathname.includes('/learn/case');

  // Hàm toggle menu
  const toggleMenu = () => {
    dispatch(toggleMenuMobile(!isOpenMenuMobile));
  };

  const lineStyle = {
    width: '32px',
    height: '3px',
    backgroundColor: `${isLearnerPath ? '#fff' : '#000'}`,
    margin: '4px 0',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'all 0.3s ease', // Thêm transition để tạo hiệu ứng mềm mại
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: "absolute",
        left: "0",
      }}
      onClick={toggleMenu} // thêm sự kiện onClick để toggle menu
    >
      <div style={lineStyle}></div>
      <div style={lineStyle}></div>
      <div style={lineStyle}></div>
    </div>
  );
};

export default ButtonMenuMobile;