import React, { useState, useEffect } from "react";
import { ErmLayoutWrap, MedRecordTabContent } from "../ErmView";
import { useSelector } from "react-redux";

function PatientErmContainer(props) {
  // Lấy dữ liệu từ state của Redux
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const PatientEMR = InterfaceTemplateDetails?.PatientEMR;
  const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);

  // Sử dụng useState để lưu trữ giá trị của isCurrentTask và panelHeight
  const [isCurrentTask, setIsCurrentTask] = useState(statusTabActiveMedicalRecord || "PatientErm");
  const [panelHeight, setPanelHeight] = useState(0); // Lưu trữ chiều cao của panel

  // Sử dụng useEffect để kiểm tra sự thay đổi của statusTabActiveMedicalRecord
  useEffect(() => {
    setIsCurrentTask(statusTabActiveMedicalRecord || "PatientErm");
  }, [statusTabActiveMedicalRecord]);

  // Hàm để nhận chiều cao từ ErmLayoutWrap
  const handleHeightChange = (height) => {
    setPanelHeight(height);
  };

  console.log(panelHeight, "panelHeight....");
  return (
    <ErmLayoutWrap
      {...props}
      heading={PatientEMR?.emr_title?.Text || "Review"}
      isCurrentTask={isCurrentTask}
      onHeightChange={handleHeightChange}
    >
      <MedRecordTabContent
        {...props}
        isCurrentTask={isCurrentTask}
        panelHeight={panelHeight}
      />
    </ErmLayoutWrap>
  );
}

export default PatientErmContainer;