import React, { useState, useEffect } from 'react';

//components
import { InputFreeText, UserMessage, PatientMessage } from '../../Chat/FreeText';
import { UserActive } from "../../Chat";
import { LoadingMessage, MessageInChat } from "../FreeText";
import { QuestionEndChat, InstructorMessage } from "../../Chat/EndChat";

//redux
import { useSelector } from "react-redux";

//default
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imgUser from '@images/common/placehoder-avatar.svg';

import styled, { keyframes } from 'styled-components';

// Keyframes for fadeInUp animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled-components
const QuestionBottom = styled.div`
  background-color: white;
  animation: ${fadeInUp} 0.5s ease-in-out;
  position: relative;
`;

const AnimatedMessageInChat = styled.div`
    opacity: 0;
    transform: translateY(20px);
    animation: ${fadeInUp} 1s ease forwards;
    animation-delay: ${({ delay }) => delay || 0.3}s;
`;

function Framechat(props) {
    const {
        chatRecord,
        messagesEndRef,
        heightFooterChat,
        isStreamDataLoading,
        isShowForSwitch,
        ChatMode,
        endChatFeedbackData,
        endChatData,
        divRef,
        profile_photo_url
    } = props;

    // Question - Instructor
    const AnswerChoices = endChatData?.Question?.AnswerChoices;

    // Redux Store
    const caseReducer = useSelector((state) => state.cases || []);
    const caseResponse = caseReducer?.data?.Case;
    const ChatSettings = caseResponse?.ChatSettings;
    const ChatLocked = ChatSettings?.ChatLocked;

    // State
    const [heightFoot, setHeightFoot] = useState(0);
    const [hiddenMessages, setHiddenMessages] = useState([]);

    // Calculate Height
    useEffect(() => {
        setHeightFoot(heightFooterChat);
    }, [heightFooterChat]);

    const [optionsData, setOptionsData] = useState({
        SelectOne: AnswerChoices || null
    });
    const [valueOptions, setvalueOptions] = useState(null);

    // Update Answer Choices
    useEffect(() => {
        setOptionsData((prevState) => ({
            ...prevState,
            SelectOne: AnswerChoices || null
        }));
    }, [AnswerChoices]);

    const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
    const isDisabled = endChatFeedbackData?.Mode === "InstructorFeedback" || ChatLocked;

    // Handle Select One - Multiple Choice
    const handleSelectOne = (data, e) => {
        const { checked, name } = e.target;
        const newDataChoices = [...optionsData?.SelectOne];
        let index = newDataChoices.findIndex((value) => value.Id === data.Id);
        if (name === "Selected") {
            newDataChoices[index]["Selected"] = checked;
        }

        // Show data for input
        displaySelectedItemsInInput(newDataChoices);

        // Update State
        setOptionsData((prevState) => ({ ...prevState, SelectOne: newDataChoices || [] }));
    };

    const displaySelectedItemsInInput = (newDataChoices) => {
        const selectedItems = newDataChoices.filter(item => item.Selected).map(item => item.Content).join(", ");
        setvalueOptions(selectedItems);
    };

    // Characters
    const Characters = caseResponse?.Characters;
    const Patient = Characters?.Patient;
    const Instructor = Characters?.Instructor;
    const PatientCharacter = Patient?.Character;
    const InstructorCharacter = Instructor?.Character;
    const patientNameText = Patient?.DisplayName;
    const preceptorNameText = Instructor?.DisplayName;
    const photoPatient = PatientCharacter?.StandardSquarePhoto || imgUser;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    const userAvatar = profile_photo_url || imgUser;

    // Logic for showing animated MessageInChat
    const hideMessageInChat = (index) => {
        setTimeout(() => {
            setHiddenMessages((prev) => [...prev, index]);
        }, 20000); // Ẩn sau 20 giây
    };

    const shouldShowMessageInChat = (index) => {
        if (hiddenMessages.includes(index)) return false; // Nếu đã ẩn, không hiển thị
        const patientMessages = chatRecord.filter((msg, i) => i <= index && msg.Role === "Patient");
        return patientMessages.length % 5 === 0 && chatRecord[index]?.Role === "Patient";
    };

    return (
        <React.Fragment>
            <div className={`frameChat__fullBox interact_panel position-relative ${ChatMode === "switch_voice_text" ? showSwitchVoiceText : ""}`}>
                {chatRecord?.length === 0 && <UserActive userNameActive={patientNameText} RoleDefault={`Patient`} image={photoPatient} />}
                <div className="messengerCt scrollbarStyle" ref={messagesEndRef} style={{ height: `calc(100vh - ${heightFoot}px)` }}>
                    <div className="messengerCt__messages">
                        {chatRecord?.map((value, index) => (
                            <React.Fragment key={index}>
                                {value?.Role === "User" &&
                                    <UserMessage
                                        {...props}
                                        content={value?.Content}
                                        userAvatar={userAvatar}
                                    />
                                }
                                {value?.Role === "Patient" &&
                                    <PatientMessage
                                        {...props}
                                        index={index}
                                        values={value}
                                        patientNameText={patientNameText}
                                        photoPatient={photoPatient}
                                    />
                                }
                                {value?.Role === "Instructor" &&
                                    <InstructorMessage
                                        {...props}
                                        index={index}
                                        content={value?.Content?.Question?.Prompt}
                                        preceptorNameText={preceptorNameText}
                                        PreceptorAvatar={PreceptorAvatar}
                                    />
                                }
                                {/* Animated MessageInChat */}
                                {shouldShowMessageInChat(index) && (
                                    <AnimatedMessageInChat delay={0.2}>
                                        <MessageInChat />
                                        {hideMessageInChat(index)}
                                    </AnimatedMessageInChat>
                                )}

                                {/* Loading Message */}
                                {chatRecord?.length - 1 === index && isStreamDataLoading &&
                                    <LoadingMessage
                                        {...props}
                                        patientNameText={patientNameText}
                                        photoPatient={photoPatient}
                                    />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <QuestionBottom ref={divRef}>
                    {endChatData?.Mode === "ShowQuestion" &&
                        <QuestionEndChat
                            {...props}
                            AnswerChoices={AnswerChoices}
                            handleSelectOne={handleSelectOne}
                        />
                    }
                    {!isDisabled && <InputFreeText {...props} valueOptions={valueOptions} disabled={isDisabled} />}
                </QuestionBottom>
            </div>
        </React.Fragment>
    );
}

export default Framechat;