import React from 'react';

function MessageCalorie(props) {

    //props
    const { valueLipidsDose, valueDropdownActive } = props && props;

    //variable
    const girValue = parseFloat(valueDropdownActive?.gir);
    const proteinValue = parseFloat(valueDropdownActive?.protein);
    const lipidsDoseValue = parseFloat(valueLipidsDose);
    const resultCalorie = ((girValue * 1.44 * 3.4) + (proteinValue * 4) + (lipidsDoseValue * 2)).toFixed(0);
    return (
        <React.Fragment>
            {(girValue && proteinValue && lipidsDoseValue) ? (
                <p className="mt-4 font-weight-500 --text-primary text-2">
                    The above prescription provides {resultCalorie} calories/kg/day
                </p>
            ):null}
        </React.Fragment>
    );
};

export default MessageCalorie;