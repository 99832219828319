export const periodsConstant = {
    LOAD_ALL_PERIODS_REQUEST: 'LOAD_ALL_PERIODS_REQUEST',
    LOAD_ALL_PERIODS_SUCCESS: 'LOAD_ALL_PERIODS_SUCCESS',
    LOAD_ALL_PERIODS_FAILURE: 'LOAD_ALL_PERIODS_FAILURE',

    CREATE_PERIODS_REQUEST: 'CREATE_PERIODS_REQUEST',
    CREATE_PERIODS_SUCCESS: 'CREATE_PERIODS_SUCCESS',
    CREATE_PERIODS_FAILURE: 'CREATE_PERIODS_FAILURE',

    UPDATE_PERIODS_REQUEST: 'UPDATE_PERIODS_REQUEST',
    UPDATE_PERIODS_SUCCESS: 'UPDATE_PERIODS_SUCCESS',
    UPDATE_PERIODS_FAILURE: 'UPDATE_PERIODS_FAILURE',

    REMOVE_PERIOD_FROM_CASE_REQUEST: 'REMOVE_PERIOD_FROM_CASE_REQUEST',
    REMOVE_PERIOD_FROM_CASE_SUCCESS: 'REMOVE_PERIOD_FROM_CASE_SUCCESS',
    REMOVE_PERIOD_FROM_CASE_FAILURE: 'REMOVE_PERIOD_FROM_CASE_FAILURE',

    REMOVE_PERIOD_REQUEST: 'REMOVE_PERIOD_REQUEST',
    REMOVE_PERIOD_SUCCESS: 'REMOVE_PERIOD_SUCCESS',
    REMOVE_PERIOD_FAILURE: 'REMOVE_PERIOD_FAILURE'
};