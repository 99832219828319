import React, { useState, useEffect } from "react";

//components
import { ModalShowBillingSheet, ModalIdentifiedProblems } from "../InteractAssistant/AssistantMessage";

function AssistantDocumentation(props) {
    //props
    const { isOpenFeedback, isLoading, stateCDIEditSuggestion, stateStream, loadingText, messageErrDocumentation } = props && props;

    //state
    const [stateData, setData] = useState(stateCDIEditSuggestion || stateStream);
    const [modal, setModal] = useState({
        IdentifiedProblems: false,
        ShowBillingSheet: false
    });

    useEffect(() => {
        setData(stateCDIEditSuggestion || stateStream);
    }, [stateCDIEditSuggestion, stateStream])

    //Hanlde Modal Identified Problems | Show Billing Sheet
    // const toggle = (e) => {
    //     const { name } = e.target;
    //     setModal((prevState) => ({ ...prevState, [name]: !modal[name] }));
    // }

    const ActionData = stateData && stateData[0]?.Action;
    const ExplanationData = stateData && stateData[0]?.Explanation;
    return (
        <React.Fragment>
            <div
                className={`assistant-message-ct`}
                style={{
                    transition: "left 1s",
                    left: isOpenFeedback ? "140px" : "-100%",
                    width: "100%"
                }}
            >
                <div className="assistant-message-ct__body" style={{ maxHeight: '500px' }}>
                    {messageErrDocumentation ? (
                        <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: messageErrDocumentation }} />
                    ) : (
                        ActionData &&
                        <React.Fragment>
                            {ActionData && <h2 className="text-3 mb-3" style={{ color: '#EE853F' }}>{ActionData}</h2>}
                            {ExplanationData && <div dangerouslySetInnerHTML={{ __html: ExplanationData }} />}
                        </React.Fragment>
                    )}
                    {isLoading && (
                        <div className="text-center">
                            <p className="my-3">{loadingText}</p>
                            <div className="typing-loader mx-auto"></div>
                        </div>
                    )}
                </div>
                {/* <ButtonFooter
                    {...props}
                    hanldeModalDocument={toggle}
                /> */}
            </div>
            <ModalIdentifiedProblems
                {...props}
                isOpen={modal?.IdentifiedProblems}
                toggle={() => setModal((prevState) => ({ ...prevState, IdentifiedProblems: !modal[`IdentifiedProblems`] }))}
            />
            <ModalShowBillingSheet
                {...props}
                isOpen={modal?.ShowBillingSheet}
                toggle={() => setModal((prevState) => ({ ...prevState, ShowBillingSheet: !modal[`ShowBillingSheet`] }))}
            />
        </React.Fragment>
    );
}

export default AssistantDocumentation;