import React from 'react';

//components
import {
    ButtonRemove,
    ButtonEdit
} from '../../schemav2';

function Header(props) {
    const { data, toggleEditHeader, toggleDel } = props && props;
    return (

        <thead>
            <tr>
                <td width="200"></td>
                {data?.map((col, indexCol) => {
                    return (
                        <td
                            key={indexCol}
                            width="200"
                            className="font-weight-500 --text-primary py-4 align-middle text-center actionConcept"
                        >
                            {col?.name}
                            <ButtonRemove
                                item={col}
                                toggleRemove={toggleDel}
                                type="DeleteConcept"
                            />
                            <ButtonEdit
                                item={col}
                                toggleEditHeader={toggleEditHeader}
                                type="EditHeader"
                            />
                        </td>
                    );
                })}
            </tr>
        </thead>
    );
};

export default Header;