// MgmtLaunchPlansPage.jsx
import React from 'react';

//components
import { LaunchPlan } from "@components/teachV2/Manage/Tabs";

const MgmtLaunchPlansPage = () => {
  return (
    <div className='wrapper MgmtLaunchPlansPage'>
      <LaunchPlan />
    </div>
  );
};

export default MgmtLaunchPlansPage;