import React from 'react';

//components
import { ButtonDelete, ButtonEdit } from '@components-common/elements';

function AccordionHeader(props) {
    const { 
        eventHandler,
        index,
        active,
        indexCount,
        value,
        toggle
    } = props && props;
    return (
        <h3 className="accordionExam__heading">
            <span
                onClick={(e) => eventHandler(e, index)}
                className={`innerHead ${active === index ? "active" : "inactive"}`}
                aria-expanded={active === index ? "true" : "false"}
                aria-controls={"sect-" + indexCount(index)}
                aria-disabled={active === index ? "true" : "false"}
                tabIndex={indexCount(index)}
            >
                <div className="d-flex align-items-center">
                    <span className="accordionExam__icon icon-list"></span>
                    {value?.ProblemName || ''}
                    <div className="mr-4 ml-auto">
                        <ButtonEdit handleEdit={() => toggle('UpdateProblem', value)} />
                        <ButtonDelete handleDelete={() => toggle('DeleteProblem', value)} />
                    </div>
                    <span className={`${active === index ? "plus" : "minus"}`}></span>
                </div>
            </span>
        </h3>
    );
};

export default AccordionHeader;