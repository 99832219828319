import React, { useState, forwardRef } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from "reactstrap";

//format time
import moment from "moment";

//redux
import { useDispatch } from "react-redux";

//actions
import { actCreatePeriod } from "@actions";

const ModalAddPeriods = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;

  //state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  const onSubmit = (data) => {
    const params = {
      Action: "Create",
      CaseId: id,
      Name: data.Name,
      StartTime: moment(startDate).format(),
      EndTime: moment(endDate).format(),
    };
    dispatch(actCreatePeriod(params));
    props && props.toggle();

    //Reset data
    setValue('Name', '', { shouldValidate: false });
  };

  const CutomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup>
      <Input onClick={onClick} defaultValue={value} type="text" ref={ref} />
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <i className="fa fa-calendar" />
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  ));

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Trial Periods</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Name"
              {...register("Name", { required: true })}
              invalid={errors.Name?.type === "required"}
              defaultValue={watch("Name")}
              onChange={(e) => {
                setValue("Name", e.target.value, { shouldValidate: true });
              }}
              placeholder="Enter the name for the new period"
              autoComplete="off"
            />
            <FormFeedback
              invalid={String(errors.Name?.type === "required")}
            >
              This is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="pickerCustom">
            <Label className="d-block" for="StartTime">
              Start Time:
            </Label>
            <DatePicker
              placeholder="mm/dd/yyyy, --:-- --"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
              dateFormat="MMMM d, yyyy h:mm a"
              showTimeInput
              timeInputLabel="Time:"
              customInput={<CutomInput />}
            />
          </FormGroup>
          <FormGroup className="pickerCustom">
            <Label className="d-block" for="EndTime">
              End Time:
            </Label>
            <DatePicker
              placeholder="mm/dd/yyyy, --:-- --"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
              dateFormat="MMMM d, yyyy h:mm a"
              showTimeInput
              timeInputLabel="Time:"
              customInput={<CutomInput />}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddPeriods;
