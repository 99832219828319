
//report
import {
    AIRecordsPage,
    LearnersReportPage,
    QuestionReportPage,
    DomainsReportPage,
    ChatReportPage,
    DetailReportPage,
} from "@pages/med2lab/report";

const reportRoutes = [
    // Report - Teach
    {
        path: "/teach/reports/ai-records/:id",
        exact: true,
        classPage: '',
        component: AIRecordsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/reports/learners/:id",
        exact: true,
        classPage: '',
        component: LearnersReportPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/reports/questions/:id",
        exact: true,
        classPage: '',
        component: QuestionReportPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/reports/questions/detail/:id",
        exact: true,
        classPage: '',
        component: DetailReportPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/reports/domains/:id",
        exact: true,
        classPage: '',
        component: DomainsReportPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/reports/chat/:id",
        exact: true,
        classPage: '',
        component: ChatReportPage,
        isLayout: true,
        navigator: true
    }
];

export { reportRoutes };