import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Api
import {apiCaller} from "@utils";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";
import imgLogo from '@images/login/logo.png';

function LoginTokenPage(props) {
  const dispatch = useDispatch();
  let history = useHistory();

  //state
  const [isLoading, setLoading] = useState(false);
  const [authCode, setAuthCode] = useState('');
  const [stateValid, setStateValid] = useState(false);
  const [stateMessage, setMessage] = useState('Either your email or password is not valid. Please try again.');

  const nextPage = (data) => {
    const dataAccount = data && data.account;
    if(dataAccount && dataAccount.is_instructor === 'Yes') {
      history.push(`/teach/home`);
    } else {
      history.push("/learn/home");
    }

    //remove background gradient
    document.body.classList.remove('bg_gradient');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    apiCaller(`/api/auth/login/`, "POST", {
      auth_code: authCode
     }).then((response) => {
        const dataResponse = response && response.data;
        const messageStatus = dataResponse && dataResponse.status;
        if(messageStatus) {
          setMessage(messageStatus);
          setLoading(false);
        }

        if(dataResponse && dataResponse.status === "OK") {
          setStateValid(false);
          
          //add token
          dispatch(actionLogin(dataResponse));

          setLoading(false);
          //Call API Theme
          dispatch(actFetchThemeRequest());
          nextPage(dataResponse);
        } else {
          setStateValid(true);
        }
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAuthCode(value);
    setStateValid(false);
  }

  useEffect(() => {
    document.body.classList.add('bg_gradient');
  }, []);

  return (
    <div className="wrapper -loginPage -tokenPage">
      <div id="login_panel">
        <div className="m-portlet m-portlet--mobile">
          <form method="post" id="form_sign_in" onSubmit={handleSubmit}>
            <div className="m-portlet__body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <img src={imgLogo} style={{ maxWidth: "50%" }} alt="logo" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12" style={{ textAlign: "center" }}>
                  <h4>Interactive Medical Education Platform</h4>
                </div>
              </div>
              <div className="row mt-5 mb-3">
                <div className="col-md-10 mx-auto">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      name="auth_code"
                      onChange={handleChange}
                      className="form-control form-control-lg m-input--pill"
                      value={authCode}
                      placeholder="Authentication Code"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              {stateValid &&
                <div className="row">
                  <div className="col-md-10 mx-auto m--font-danger" id="message_invalid" style={{textAlign: 'center'}}>
                    {stateMessage}
                  </div>
                </div>
              }
            </div>
            <div className="m-portlet__foot text-center">
              <button disabled={isLoading} type="submit" className="btn m-btn--pill btn-lg text-white">
                <div className="d-flex align-items-center justify-content-center">
                  {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  Log In
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginTokenPage;
