import { feedbackConstant } from "@constants";
import {apiCaller} from "@utils";

//case feedbacks
export const actFetchCaseFeedbacks = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/feedbacks/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.CASE_FEEDBACKS_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.CASE_FEEDBACKS_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.CASE_FEEDBACKS_FAILURE, error: error } }
}

//Load Feedback Template
export const actLoadFeedbackTemplate = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.LOAD_FEEDBACK_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.LOAD_FEEDBACK_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.LOAD_FEEDBACK_TEMPLATE_FAILURE, error: error } }
}

// Case - Create Feedback Template
export const atcCreateFeedbackTemplate = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.CREATE_FEEDBACK_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.CREATE_FEEDBACK_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.CREATE_FEEDBACK_TEMPLATE_FAILURE, error: error } }
}

// atcUpdateFeedbackTemplate
export const atcUpdateFeedbackTemplate = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.UPDATE_FEEDBACK_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.UPDATE_FEEDBACK_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.UPDATE_FEEDBACK_TEMPLATE_FAILURE, error: error } }
}

// atcUpdateFeedbackTemplate
export const actFetchLoadAvailableFeedbackTemplates = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/feedback_templates/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_FAILURE, error: error } }
}

export const atcAssignFeedback = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: feedbackConstant.ASSIGN_FEEDBACK_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: feedbackConstant.ASSIGN_FEEDBACK_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: feedbackConstant.ASSIGN_FEEDBACK_TEMPLATE_FAILURE, error: error } }
}