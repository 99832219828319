import React from "react";
import { Link } from "react-router-dom";

function SubNav(props) {
  const { data, idCase } = props && props;
  return (
    <ul className="subNav">
      {data &&
        data.map((item, index) => {
          const isHeader = item?.isHeader;
          const isDivider = item?.isDivider;
          return (
            <React.Fragment key={index}>
              <li
                className={`subNav__item ${isHeader ? "dr-header" : ""}`}
                key={index}
              >
                {isHeader ? (
                  <span>{item.name}</span>
                ) : (
                  <Link className="subLink" to={`${item.path}/${idCase || ''}`}>
                    <i
                      className={`${item.icon || ""} mr-2`}
                      aria-hidden="true"
                    ></i>
                    {item.name}
                  </Link>
                )}
              </li>
              {isDivider && (
                <li class="dropdown-divider"></li>
              )}
            </React.Fragment>
          );
        })}
    </ul>
  );
}

export default SubNav;
