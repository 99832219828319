import React, { useEffect, useCallback, useState } from "react";

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);


function ScatterPlot(props) {
    //props
    const { Chart, itemActive, emailActive, myRef, hanldeClearData } = props && props;
    const [chartList, setChart] = useState([]);

    //convert chart with config
    const convertDataChart = useCallback(() => {
        //common
        const configCommon = {
            textposition: 'top center',
            mode: 'lines+markers+text', //duong  + diem + text
            type: 'scatter',
            name: '',
        };

        //checkData
        const newArr = Chart?.map((value) => {
            const colorElement = value?.Object === "Cohort" ? "#0089c3" : "#949494";
            const resultYAxix = value?.y.map(function (x) { 
                return parseFloat(x)/100;
            });
            return {
                ...configCommon,
                name: value?.Object,
                x: value?.x,
                y: resultYAxix,
                marker: { size: 14, color: colorElement }
            };
        })
        setChart(newArr);
    }, [Chart]);

    useEffect(() => {
        //Set Data Chart
        convertDataChart();
    }, [convertDataChart, Chart]);

    const layout = {
        font: { size: 13 },
        autosize: true,
        margin: {
            l: 50,
            r: 20,
            b: 50,
            t: 50,
            pad: 10
        },
        xaxis: {
            automargin: true,
            zeroline: true,
            title: {
                standoff: 20
            },
        },
        yaxis: {
            automargin: true,
            tickformat: '0%',
            range: [0, 1]
        }
    };

    const configResize = {
        useResizeHandler: true,
        style: { width: "100%", height: "100%" },
    }

    return (
        <div className="position-relative p-4">
            <div className="mx-auto" ref={myRef} style={{ maxWidth: '900px' }}>
                <div className="infoChart d-flex align-items-center">
                    <div className="flex-wrap mr-3">
                        <p className="font-weight-500"><span className="--text-primary">Cohort:</span><span className="text-dark ml-2">{itemActive?.Name}</span></p>
                        {emailActive &&
                            <p className="font-weight-500"><span className="--text-primary">Learner:</span><span className="text-dark ml-2">{emailActive}</span></p>
                        }
                    </div>
                    {emailActive &&
                        <button className="btn btn-primary ml-auto" onClick={hanldeClearData}>
                            Clear
                        </button>
                    }
                </div>
                <PlotlyComponent
                    divId="scatterChart"
                    data={(chartList && chartList) || []}
                    layout={layout}
                    style={{ width: '100%', height: '100%' }}
                    config={configResize}
                />
            </div>
        </div>
    );
}

export default ScatterPlot;