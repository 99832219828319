import React from 'react';
import { Link } from "react-router-dom";

//boostrap
import {
    Row,
    Col
} from "reactstrap";

//LinesEllipsis
import LinesEllipsis from "react-lines-ellipsis";

//logo default
import logoImg from "@images/portfolio/med2lab_logo.png";

//DataEmty
import { DataEmty } from "@components/common";

function CaseList(props) {
    const { data, slug } = props && props;
    const itemCase = data?.map(function (item, i) {
        return (
            <Col lg={3} md={4} className="cardList__item" key={i}>
                <div className="cardwrap">
                    <div
                        className="cardwrap__header"
                        style={{ backgroundImage: `url("${item.Thumbnail}")` }}
                    >
                        <div
                            className="cardwrap__thumb -box-shadow"
                            style={{ backgroundImage: `url("${logoImg}")` }}
                        ></div>
                    </div>
                    <div className="cardwrap__content">
                        <h5>
                            <LinesEllipsis
                                text={item.Title}
                                maxLine="2"
                                ellipsis="..."
                                trimRight
                                basedOn=""
                            />
                        </h5>
                        {/* 
                            <ul className="tagList">
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                                <li className="tagList__item">
                                <a className="refTag" href="#/">Tag Review</a>
                                </li>
                            </ul>
                        */}
                        <div className="cardwrap__footer d-flex justify-content-center">
                            {slug === "in-progress" ? (
                                // Case inProgress
                                <Link
                                    to={`/learn/case/${item.Id}`}
                                    className="btn-style2 btn-outline-brand --btn-ouline-primary"
                                >
                                    <i className="fa fa-play mr-2" aria-hidden="true"></i>
                                    {item.Progress && item.Progress.status === "not_started"
                                        ? "Start"
                                        : "Resume"}
                                </Link>
                            ) : (
                                // Case completed
                                <Link
                                    to={`/learn/load_case/${item.Id}`}
                                    className="btn-style2 btn-outline-brand --btn-ouline-primary"
                                    target="_blank"
                                >
                                    <i className="fa fa-refresh mr-2" aria-hidden="true"></i>
                                    {item.Progress && item.Progress.status === "not_started"
                                        ? "Start"
                                        : "Review"}
                                </Link>
                            )}
                            {/* <a href="# " className="btn-style2 btn-outline-danger" data-case-id="357">
                                <i className="fa fa-trash-o mr-2" aria-hidden="true"></i>
                                Delete
                            </a> */}
                        </div>
                    </div>
                </div>
            </Col>
        );
    });
    return (
        <React.Fragment>
            {data?.length > 0 ? (
                <Row className="cardList">{itemCase}</Row>
            ) : (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: "200px" }}
                >
                    <DataEmty content="Once you complete a case, it will appear here for you to review" />
                </div>
            )}
        </React.Fragment>
    );
};

export default CaseList;