import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//router
import { useParams } from "react-router-dom";

//action
import {
  atcDeleteActionCategory,
  atcDeleteMedicationCategory,
} from "@actions";

function ModalDelCategory(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, activeCategory, categoryType } = props && props;

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "InterventionCategory",
      CategoryId: activeCategory,
    };
    if (categoryType === "Action") {
      dispatch(atcDeleteActionCategory(id, params, activeCategory));
    } else {
      dispatch(atcDeleteMedicationCategory(id, params, activeCategory));
    }
    props && props.toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that you want to <br />
          delete this category?
        </h4>
        <p className="mt-2 text-center">This action is not reversible.</p>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-danger" onClick={handleRemove}>
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDelCategory;
