import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

function InputSearchCases() {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChangeOptions = (data) => {
        setSelectedOption(data);
    };

    const optionsData = [
        { value: '1', label: 'Item Dropdown 001' },
        { value: '2', label: 'Item Dropdown 002' },
        { value: '3', label: 'Item Dropdown 003' },
        { value: '4', label: 'Item Dropdown 004' }
    ]

    return (
        <CreatableSelect
            isClearable
            onChange={handleChangeOptions}
            options={optionsData || []}
            isMulti
            name="Periods"
            value={selectedOption}
            placeholder="Search for cases"
            classNamePrefix="react-select"
        />
    );
}

export default InputSearchCases;