export const medicationConstants = {
    LOAD_MEDICATIONS_REQUEST: 'LOAD_MEDICATIONS_REQUEST',
    LOAD_MEDICATIONS_SUCCESS: 'LOAD_MEDICATIONS_SUCCESS',
    LOAD_MEDICATIONS_FAILURE: 'LOAD_MEDICATIONS_FAILURE',

    CREATE_MEDICATION_CATEGORY_REQUEST: 'CREATE_MEDICATION_CATEGORY_REQUEST',
    CREATE_MEDICATION_CATEGORY_SUCCESS: 'CREATE_MEDICATION_CATEGORY_SUCCESS',
    CREATE_MEDICATION_CATEGORY_FAILURE: 'CREATE_MEDICATION_CATEGORY_FAILURE',

    UPDATE_MEDICATION_CATEGORY_REQUEST: 'UPDATE_MEDICATION_CATEGORY_REQUEST',
    UPDATE_MEDICATION_CATEGORY_SUCCESS: 'UPDATE_MEDICATION_CATEGORY_SUCCESS',
    UPDATE_MEDICATION_CATEGORY_FAILURE: 'UPDATE_MEDICATION_CATEGORY_FAILURE',

    DELETE_MEDICATION_CATEGORY_REQUEST: 'DELETE_MEDICATION_CATEGORY_REQUEST',
    DELETE_MEDICATION_CATEGORY_SUCCESS: 'DELETE_MEDICATION_CATEGORY_SUCCESS',
    DELETE_MEDICATION_CATEGORY_FAILURE: 'DELETE_MEDICATION_CATEGORY_FAILURE',

    UPDATE_ATTRIBUTE_SCORE_MEDICATION_REQUEST: 'UPDATE_ATTRIBUTE_SCORE_MEDICATION_REQUEST',
    UPDATE_ATTRIBUTE_SCORE_MEDICATION_SUCCESS: 'UPDATE_ATTRIBUTE_SCORE_MEDICATION_SUCCESS',
    UPDATE_ATTRIBUTE_SCORE_MEDICATION_FAILURE: 'UPDATE_ATTRIBUTE_SCORE_MEDICATION_FAILURE',

    UPDATE_AVAILABILITY_FOR_MEDICATION_REQUEST: 'UPDATE_AVAILABILITY_FOR_MEDICATION_REQUEST',
    UPDATE_AVAILABILITY_FOR_MEDICATION_SUCCESS: 'UPDATE_AVAILABILITY_FOR_MEDICATION_SUCCESS',
    UPDATE_AVAILABILITY_FOR_MEDICATION_FAILURE: 'UPDATE_AVAILABILITY_FOR_MEDICATION_FAILURE',

    UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_REQUEST: 'UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_REQUEST',
    UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_SUCCESS: 'UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_SUCCESS',
    UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_FAILURE: 'UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_FAILURE',

    UPDATE_AVAILABLE_IN_BATCH_MEDICATION_REQUEST: 'UPDATE_AVAILABLE_IN_BATCH_MEDICATION_REQUEST',
    UPDATE_AVAILABLE_IN_BATCH_MEDICATION_SUCCESS: 'UPDATE_AVAILABLE_IN_BATCH_MEDICATION_SUCCESS',
    UPDATE_AVAILABLE_IN_BATCH_MEDICATION_FAILURE: 'UPDATE_AVAILABLE_IN_BATCH_MEDICATION_FAILURE',
};