import { promptTemplateConstants } from "@constants";

export function dataPromptTemplate (state = {}, action) {
    switch (action.type) {
        case promptTemplateConstants.GET_PROMPT_TEMPLATE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case promptTemplateConstants.GET_PROMPT_TEMPLATE_SUCCESS:
            const test = action.payload.map(test => {
                return {
                    ...test,
                    value: test.Name, 
                    label: test.Name, 
                }
            })
            return {
                ...state,
                data: test,
                isLoading: false
            };
        case promptTemplateConstants.GET_PROMPT_TEMPLATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};