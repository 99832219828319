import React, { useState, useEffect } from "react";

//timezone
import TimezoneSelect from "react-timezone-select";

//boostrap
import { FormGroup, Label } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { setAccountTimeZone } from "@actions";

function TimeZone(props) {
  //props
  const { dataTimeZone } = props && props;

  //state
  const dispatch = useDispatch();
  const [selectedTimezone, setSelectedTimezone] = useState(
    (dataTimeZone && dataTimeZone.Timezone) || {}
  );
  const [isChange, setChange] = useState(false);

  const handleChange = (value) => {
    setSelectedTimezone(value);
    setChange(true);
  };

  //setState
  useEffect(() => {
    if (dataTimeZone) {
      setSelectedTimezone((dataTimeZone && dataTimeZone.Timezone) || {});
    }
  }, [dataTimeZone]);

  //Call Api
  useEffect(() => {
    if (isChange) {
      dispatch(setAccountTimeZone(selectedTimezone));
      setChange(false);
    }
  }, [dispatch, isChange, selectedTimezone]);
  
  return (
    <FormGroup>
      <Label className="font-weight-500" for="timeZone">Set the time zone:</Label>
      <TimezoneSelect
        value={selectedTimezone}
        onChange={handleChange}
        placeholder="Select a timezone..."
      />
    </FormGroup>
  );
}

export default TimeZone;