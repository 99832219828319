import React, { useEffect } from "react";
import { FormGroup, Label, Input, FormFeedback, Row, Col } from "reactstrap";

//actions
import { actFetchAllInterfaceTemplates } from "@actions";

//components
import { TemplateOptions } from "../../instructor/LaunchPlans";

//react-redux
import { useDispatch, useSelector } from "react-redux";

const GeneralTab = (props) => {
    const dispatch = useDispatch();
    const {
        register,
        errors,
        watch,
        setValue,
        stateSeries,
        stateCohort,
        statePeriods,
        isDisable,
        action,
    } = props && props;

    // Get All Template
    useEffect(() => {
        dispatch(actFetchAllInterfaceTemplates());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    // Convert
    let listInterfaceTemplates = useSelector((state) => state?.listInterfaceTemplates?.data || []);
    return (
        <React.Fragment>
            <Row>
                <Col md={6}>
                    <FormGroup>
                        <Label for="Name">
                            Name <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            type="text"
                            name="Name"
                            id="Name"
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <TemplateOptions 
                        {...props}
                        listInterfaceTemplates={listInterfaceTemplates}
                    />
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="SeriesId">
                            Series <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            {...register("SeriesId", { required: true })}
                            invalid={errors.SeriesId?.type === "required"}
                            defaultValue={watch("SeriesId")}
                            onChange={(e) => {
                                setValue("SeriesId", e.target.value, { shouldValidate: true });
                            }}
                            disabled={isDisable}
                            type="select"
                            name="SeriesId"
                            id="SeriesId"
                        >
                            <option value="">Select an item below</option>
                            {stateSeries &&
                                stateSeries.map((value, index) => (
                                    <option key={index} value={value.Id}>
                                        {value.Name}
                                    </option>
                                ))}
                        </Input>
                        <FormFeedback invalid={String(errors.SeriesId?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="CohortId">
                            Cohort <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            {...register("CohortId", { required: true })}
                            invalid={errors.CohortId?.type === "required"}
                            defaultValue={watch("CohortId")}
                            onChange={(e) => {
                                setValue("CohortId", e.target.value, { shouldValidate: true });
                            }}
                            disabled={isDisable}
                            type="select"
                            name="CohortId"
                            id="CohortId"
                        >
                            <option value="">Select an item below</option>
                            {stateCohort &&
                                stateCohort.map((value, index) => (
                                    <option key={index} value={value.CohortId}>
                                        {value.Name}
                                    </option>
                                ))}
                        </Input>
                        <FormFeedback invalid={String(errors.CohortId?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for="PeriodId">
                            Period <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            {...register("PeriodId", { required: true })}
                            invalid={errors.PeriodId?.type === "required"}
                            defaultValue={watch("PeriodId")}
                            onChange={(e) => {
                                setValue("PeriodId", e.target.value, { shouldValidate: true });
                            }}
                            disabled={isDisable}
                            type="select"
                            name="PeriodId"
                            id="PeriodId"
                        >
                            <option value="">Select an item below</option>
                            {statePeriods &&
                                statePeriods.map((value, index) => (
                                    <option key={index} value={value.PeriodId}>
                                        {value.Name}
                                    </option>
                                ))}
                        </Input>
                        <FormFeedback invalid={String(errors.PeriodId?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                {action === "edit" && (
                    <Col md={6}>
                        <FormGroup>
                            <Label for="Status">
                                Status <span className="m--font-danger">*</span>
                            </Label>
                            <Input
                                {...register("Status", { required: true })}
                                invalid={errors.Status?.type === "required"}
                                defaultValue={watch("Status")}
                                onChange={(e) => {
                                    setValue("Status", e.target.value, { shouldValidate: true });
                                }}
                                type="select"
                                name="Status"
                                id="Status"
                            >
                                <option value="">Select an item below</option>
                                <option value="Draft">Draft</option>
                                <option value="Active">Active</option>
                            </Input>
                            <FormFeedback invalid={String(errors.Status?.type === "required")}>
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};

export default GeneralTab;