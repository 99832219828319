import React from "react";
import thumbDefault from "@images/common/placeholder.png";

//common
import { DataEmty } from "@components-common";

//elements
import { ButtonEdit } from '@components-common/elements';

//components
import { Filter } from "../cases/";

function ListCase(props) {

  let dataProps = props && props.dataInstitution;
  //state
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);

  //Search With Name
  React.useEffect(() => {
    const results =
      dataProps &&
      dataProps.filter(function (elem) {
        return elem.Title.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      });
    setSearchResults(results || dataProps);
  }, [searchTerm, dataProps]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "search") {
      setSearchTerm(value);
    }
  };

  return (
    <div className="modulesTab">
      <div className="modulesTab__left">
        <Filter
          value={searchTerm}
          templatesData={dataProps}
          handleChange={handleChange}
        />
      </div>
      <div className="modulesTab__right">
        {/* <div className="text-right">
            <Button
              className="btn btn-brand --btn-primary mb-3"
            >
              <i className="fa fa-plus mr-2" aria-hidden="true" />
              New Template
            </Button>
          </div> */}
        <div className="caseBottom">
          <ul className="ListSortableElement">
            {searchResults && searchResults.length === 0 && (
              <DataEmty content="No Data" />
            )}
            {searchResults &&
              searchResults.map((value, index) => {
                return (
                  <li
                    className="ListSortableElement__item -box-shadow"
                    key={index}
                    style={{ cursor: "auto" }}
                  >
                    <div className="innerCt">
                      <div className="innerCt__left">
                        <img
                          src={value.Thumbnail || thumbDefault}
                          alt={value.Title}
                        />
                      </div>
                      <div className="innerCt__right">
                        <div className="align-items-center row">
                          <div className="col-sm-12 col-md-10">
                            <h6 className="title">{value.Title}</h6>
                          </div>
                          <div className="d-flex col-sm-12 col-md-2">
                            <ButtonEdit linkRef={true} url={`/teach/case/${value.Id}`} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ListCase;