import React from "react";

//useParams
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Actions
import { actCreateExamPoint } from "@actions";

function ButtonAddPoint(props) {
  const { timePointId } = props && props;
  const dispatch = useDispatch();
  let { id } = useParams();

  const hanldeAddpoint = () => {
    const params = {
      Action: "Create",
      Target: "ExamPoint",
      TimePointId: timePointId
    };
    dispatch(actCreateExamPoint(id, params));
  };

  return (
    <div className="d-flex justify-content-end">
      <button
        type="submit"
        className="btn btn-success mr-2"
        name="mode"
        value="add_point"
        onClick={hanldeAddpoint}
      >
        <i className="fa fa-plus" /> Add Point
      </button>
    </div>
  );
}

export default ButtonAddPoint;
