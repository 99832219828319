import React, { useState, useEffect, useRef } from 'react';
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

const TasksDropdown = ({ onToggleItem }) => {

    // Store
    const timepointData = useSelector((state) => state?.timepoint?.data || {});
    const TaskElements = timepointData?.TaskElements;

    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [options, setOptions] = useState(TaskElements || []);

    // Load TaskElements
    useEffect(() => {
        const TaskElements = timepointData?.TaskElements || [];
        setOptions(TaskElements);
    }, [timepointData?.TaskElements]);

    //handle Outside Click 
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const defaultTasks = [
        { Label: "Profile Image", Status: "Active", ViewClass: "profile_image", isDisabled: true }
    ];

    // List of ViewClasses to hide
    const hiddenViewClasses = ["io", "orders", "pn", "documentation", "actions"];
    return (
        <div className='list-available-custom' ref={dropdownRef}>
            <div className="list-available-custom__control" onClick={toggleDropdown}>
                <Iconsax iconName="available-tasks" fill="#101828" size={20} />
                <span className='ml-2'>Manage Available Tasks</span>
            </div>
            {isOpen && (
                <div className='list-available-custom__menu-list'>
                    {[...defaultTasks, ...options].map((option, index) => {
                        const isDisabled = ["profile_image", "history", "vital", "advance"].includes(option.ViewClass);
                        const isHidden = hiddenViewClasses.includes(option.ViewClass);
                        if (isHidden) return null;
                        return (
                            <div
                                className={`list-available-custom__menu-list--item ${option?.Status === "Active" ? "is-selected" : ""} ${isDisabled ? 'disabled' : ''}`}
                                key={index}
                                onClick={() => !isDisabled && onToggleItem(option)}
                                disabled={isDisabled}
                            >
                                <div className="d-flex w-100 justify-content-between">
                                    {option.Label}
                                    {option?.Status === "Active" && (
                                        <Iconsax iconName="is-selected" fill="#0089C2" size={14} />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default TasksDropdown;