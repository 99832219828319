export const templatesConstants = {
    TEMPLATE_LIST_REQUEST: 'TEMPLATE_LIST_REQUEST',
    TEMPLATE_LIST_SUCCESS: 'TEMPLATE_LIST_SUCCESS',
    TEMPLATE_LIST_FAILURE: 'TEMPLATE_LIST_FAILURE',

    ALL_TEMPLATE_LIST_REQUEST: 'ALL_TEMPLATE_LIST_REQUEST',
    ALL_TEMPLATE_LIST_SUCCESS: 'ALL_TEMPLATE_LIST_SUCCESS',
    ALL_TEMPLATE_LIST_FAILURE: 'ALL_TEMPLATE_LIST_FAILURE',

    CREATE_TEMPLATE_REQUEST: 'CREATE_TEMPLATE_REQUEST',
    CREATE_TEMPLATE_SUCCESS: 'CREATE_TEMPLATE_SUCCESS',
    CREATE_TEMPLATE_FAILURE: 'CREATE_TEMPLATE_FAILURE',

    UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
    UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
    UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',

    DELETE_TEMPLATE_REQUEST: 'DELETE_TEMPLATE_REQUEST',
    DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
    DELETE_TEMPLATE_FAILURE: 'DELETE_TEMPLATE_FAILURE',
    
    UPDATE_TEMPLATE_HIGHT_LIGHT_REQUEST: 'UPDATE_TEMPLATE_HIGHT_LIGHT_REQUEST',
    UPDATE_TEMPLATE_HIGHT_LIGHT_SUCCESS: 'UPDATE_TEMPLATE_HIGHT_LIGHT_SUCCESS',
    UPDATE_TEMPLATE_HIGHT_LIGHT_FAILURE: 'UPDATE_TEMPLATE_HIGHT_LIGHT_FAILURE',

    LOAD_TEMPLATE_BY_COLLECTION_ID_REQUEST: 'LOAD_TEMPLATE_BY_COLLECTION_ID_REQUEST',
    LOAD_TEMPLATE_BY_COLLECTION_ID_SUCCESS: 'LOAD_TEMPLATE_BY_COLLECTION_ID_SUCCESS',
    LOAD_TEMPLATE_BY_COLLECTION_ID_FAILURE: 'LOAD_TEMPLATE_BY_COLLECTION_ID_FAILURE',

    CREATE_TEMPLATE_COLLECTION_REQUEST: 'CREATE_TEMPLATE_COLLECTION_REQUEST',
    CREATE_TEMPLATE_COLLECTION_SUCCESS: 'CREATE_TEMPLATE_COLLECTION_SUCCESS',
    CREATE_TEMPLATE_COLLECTION_FAILURE: 'CREATE_TEMPLATE_COLLECTION_FAILURE',

    ADD_TEMPLATE_TO_COLLECTION_REQUEST: 'ADD_TEMPLATE_TO_COLLECTION_REQUEST',
    ADD_TEMPLATE_TO_COLLECTION_SUCCESS: 'ADD_TEMPLATE_TO_COLLECTION_SUCCESS',
    ADD_TEMPLATE_TO_COLLECTION_FAILURE: 'ADD_TEMPLATE_TO_COLLECTION_FAILURE',

    REMOVE_TEMPLATE_FROM_COLLECTION_REQUEST: 'REMOVE_TEMPLATE_FROM_COLLECTION_REQUEST',
    REMOVE_TEMPLATE_FROM_COLLECTION_SUCCESS: 'REMOVE_TEMPLATE_FROM_COLLECTION_SUCCESS',
    REMOVE_TEMPLATE_FROM_COLLECTION_FAILURE: 'REMOVE_TEMPLATE_FROM_COLLECTION_FAILURE',

    DELETE_COLLECTION_ID_REQUEST: 'DELETE_COLLECTION_ID_REQUEST',
    DELETE_COLLECTION_ID_SUCCESS: 'DELETE_COLLECTION_ID_SUCCESS',
    DELETE_COLLECTION_ID_FAILURE: 'DELETE_COLLECTION_ID_FAILURE',

    LIST_ALL_INTERFACE_TEMPLATES_REQUEST: 'LIST_ALL_INTERFACE_TEMPLATES_REQUEST',
    LIST_ALL_INTERFACE_TEMPLATES_SUCCESS: 'LIST_ALL_INTERFACE_TEMPLATES_SUCCESS',
    LIST_ALL_INTERFACE_TEMPLATES_FAILURE: 'LIST_ALL_INTERFACE_TEMPLATES_FAILURE',

    STANDARD_INTERFACE_ELEMENTS_REQUEST: 'STANDARD_INTERFACE_ELEMENTS_REQUEST',
    STANDARD_INTERFACE_ELEMENTS_SUCCESS: 'STANDARD_INTERFACE_ELEMENTS_SUCCESS',
    STANDARD_INTERFACE_ELEMENTS_FAILURE: 'STANDARD_INTERFACE_ELEMENTS_FAILURE',
};