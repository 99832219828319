import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DragQuestion } from '@components/storyboard/Question';
import { LoaderText } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllQuestionsForElement } from "@actions";

function QuestionMain(props) {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const params = {
                Action: "Load",
                Target: "CaseQuestions",
            };
            dispatch(fetchAllQuestionsForElement(id, params))
                .then(() => {
                    setIsLoading(false); // Data has been loaded, set isLoading to false
                })
                .catch((error) => {
                    // Handle errors if needed
                });
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const storeQuestion = useSelector((state) => state.allQuestionForelement || {});
    const data = storeQuestion.data || [];
    return (
        <div className="boxPortlet position-relative">
            {data?.map((value, index) => {
                const { TimePoint, Tasks } = value;
                const timePointId = TimePoint?.Id;
                return (
                    <section className="qsSection mb-5" key={index}>
                        <h2 className="font-weight-500 text-3 --text-primary">{TimePoint?.DisplayName}</h2>
                        {Tasks.map((task, taskIndex) => (
                            <div className="qsContent pl-4 my-3" key={taskIndex}>
                                <h3 className="font-weight-600 text-2 mb-3" style={{ 'textTransform': 'capitalize' }}>{task.Task}</h3>
                                <DragQuestion
                                    {...props}
                                    timePointId={timePointId}
                                    task={task.Task}
                                    data={task.Questions || []}
                                    isViewQuestion="elements"
                                    disableSort={true}
                                />
                            </div>
                        ))}
                    </section>
                );
            })}
            {isLoading && <div style={{ height: '400px' }}>
                <LoaderText />
            </div>}
        </div>
    );
}

export default QuestionMain;