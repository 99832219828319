import React from 'react';

const InteractPanelOrderSheet = ({ children}) => {
  return (
    <div className="taks-content-inner">
      {children}
    </div>
  );
};

export default InteractPanelOrderSheet;