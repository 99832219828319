import React from "react";

function Thead(props) {
  const { isTime } = props && props;
  return (
    <thead>
      <tr>
        <th
          className="align-middle font-weight-500 --text-primary text-left"
          width={isTime === 0 ? "25%" : "15%"}
        >
          Lab Item
        </th>
        <th className="align-middle font-weight-500 --text-primary text-center text-nowrap">Initial Value</th>
        {isTime === 0 && (
          <React.Fragment>
            <th className="align-middle font-weight-500 --text-primary text-center">Unit</th>
            <th className="align-middle font-weight-500 --text-primary text-center" width="20%">
              Reference Range
            </th>
          </React.Fragment>
        )}
        {isTime > 0 && (
          <th
            className="font-weight-500 --text-primary align-middle"
            title="Change At This Time Point"
            width="25%"
          >
            Change At This Time Point
          </th>
        )}
        <th
          className="align-middle font-weight-500 --text-primary"
          title="Available"
          width="10%"
        >
          Available
        </th>
        <th
          className="font-weight-500 --text-primary align-middle text-center"
          title="Score"
          style={{ maxWidth: "20%" }}
        >
          Score
        </th>
        <th Name="font-weight-500 --text-primary align-middle text-center" style={{ minWidth: isTime > 0 ? "140px" : "160px" }} />
      </tr>
    </thead>
  );
}

export default Thead;
