import React, { useEffect, useState } from "react";

//boostrap
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//components
import { ViewMedia } from ".";

function ViewExamContent(props) {
  const { data } = props && props;
  const [activeTab, setActiveTab] = useState("1");
  const [itemActive, setItem] = useState({});

  const toggleTab = (tab, value) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    setItem(value);
  };

  useEffect(() => {
    setActiveTab("1");
    setItem(data && data[0]);
  }, [data]);

  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <Row className="tabVertical -border-item-none">
          <Col className="tabVertical__left" sm={12} md={4}>
            <Nav className="flex-column" tabs>
              {data &&
                data.map((value, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames("-font-14", {
                        "active --bg-primary": activeTab === String(index + 1),
                      })}
                      onClick={() => {
                        toggleTab(String(index + 1), value);
                      }}
                    >
                      {value.Title}
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
          </Col>
          <Col sm={12} md={8} className="tabVertical__right mt-sm-4 mt-md-0">
            <TabContent activeTab={activeTab}>
              {data &&
                data.map((value, index) => (
                  <TabPane key={index} tabId={String(index + 1)}>
                    <h5 className="heading-5 mt-0 font-weight-500">
                      {value.Title}
                    </h5>
                    <ViewMedia item={itemActive} />
                  </TabPane>
                ))}
            </TabContent>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

export default ViewExamContent;
