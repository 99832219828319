import React, { useState } from 'react';

//reactstrap
import { Button } from "reactstrap";

//apiCaller
import { apiCaller } from "@utils";

function BtnExportCSV(props) {
    //props
    const { items } = props && props;

    //state
    const [loadingDowload, setLoadingDowload] = useState(false);

    //Handle Export csv
    // Handle Export csv
const handleExportCsv = (items) => {
    setLoadingDowload(true);

    const params = {
        "Action": "ExportRawData",
        "LaunchPlanId": items?.LaunchPlanId,
        "ExcelName": items?.Name,
        "DataIncluded": ["Chat", "Questions"]
    };

    apiCaller(`/api/teach/launchplan/`, 'POST', params)
        .then(res => {
            const processedName = params?.ExcelName?.replace(/\s/g, '_');
            const fileName = `${processedName}.xlsx`;
            const excelUrl = res?.data?.excel_url;

            if (res?.status === 200) {
                fetch(excelUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        const link = document.createElement('a');
                        const url = window.URL.createObjectURL(new Blob([blob]));

                        link.href = url;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);

                        link.click();

                        // Remove the a element after the download
                        document.body.removeChild(link);

                        setLoadingDowload(false);
                    })
                    .catch(error => {
                        console.error('Error downloading file:', error);
                        setLoadingDowload(false);
                    });
            } else {
                setLoadingDowload(false);
            }
        })
        .catch(error => {
            console.error('Error exporting CSV:', error);
            setLoadingDowload(false);
        });
    };

    return (
        <Button
            className="--btn-primary ml-auto"
            onClick={() => handleExportCsv(items)}
            disabled={loadingDowload}
        >
            {loadingDowload ? (
                <span className="spinner-border spinner-border-sm"></span>
            ) : (
                <i className={`fa fa-download`} aria-hidden="true"></i>
            )}
        </Button>
    );
};

export default BtnExportCSV;