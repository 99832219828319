import React from 'react';
import { Iconsax } from "@components-common";
import { useSelector } from "react-redux";

function ButtonNextQuestion(props) {
    const { handleNextQuestion } = props || {};

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const CaseTask = InterfaceTemplateDetails?.CaseTask;

    // Get the question data and loading state from the Redux store
    const questionData = useSelector((state) => state.question || {});
    const isQuestionLoading = questionData?.isLoading;
    return (
        <div className="mx-auto mb-3 btn-gotIt" style={{ maxWidth: "300px" }}>
            <button
                type="button"
                className="btn-send w-100 justify-content-center"
                disabled={isQuestionLoading}
                onClick={handleNextQuestion}
            >
                {/* Use dangerouslySetInnerHTML to render HTML content */}
                <span dangerouslySetInnerHTML={{ __html: CaseTask?.button_next_question?.Text || "I got it!" }} className="mr-2" />
                {isQuestionLoading ? <span className="spinner-border spinner-border-sm"></span> : <Iconsax iconName="arrow-right" fill="#fff" />}
            </button>
        </div>
    );
}

export default ButtonNextQuestion;