import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col, Input, Row } from "reactstrap";
import { useSelector } from "react-redux";
import styled from 'styled-components';

const StyledLabel = styled(Label)`
    color: #0089C2;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
`;

const StyledNote = styled.p`;
    color: #697586;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 17px;
`;

const QuestionDomains = ({ teachCaseData, handleChangeDomains, itemQuestion }) => {
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);
    const [domainData, setDomainData] = useState([]);

    useEffect(() => {
        const domains = questionViewActive === "Edit_Question" ? itemQuestion?.Domains : teachCaseData?.Domains;
        setDomainData(domains || []);
    }, [questionViewActive, itemQuestion, teachCaseData]);

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        const newArray = domainData.map((domain) =>
            domain.Id === Number(value) ? { ...domain, Selected: checked } : domain
        );
        setDomainData(newArray);
        handleChangeDomains(newArray);
    };

    const renderDomains = (domains) => (
        domains.map((value, index) => (
            <FormGroup check style={{ color: value.Color }} className="mb-2" key={index}>
                <Label check>
                    <Input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        value={value.Id}
                        name="Domains"
                        checked={value.Selected}
                    />
                    {value.Name}
                </Label>
            </FormGroup>
        ))
    );

    const splitIndex = Math.ceil(domainData.length / 2);
    const firstHalf = domainData.slice(0, splitIndex);
    const secondHalf = domainData.slice(splitIndex);

    return (
        <Col md={12}>
            <div className="mb-4">
                <StyledLabel>Domains</StyledLabel>
                <StyledNote><i>Which assessment domain(s) does this question belong?</i></StyledNote>
            </div>
            <p className='form-note my-2'>
                <i>The point learners earn from this question will be added to all of the designated domains</i>
            </p>
            {domainData.length > 10 ? (
                <Row>
                    <Col md={6}>{renderDomains(firstHalf)}</Col>
                    <Col md={6}>{renderDomains(secondHalf)}</Col>
                </Row>
            ) : (
                renderDomains(domainData)
            )}
        </Col>
    );
};

export default QuestionDomains;