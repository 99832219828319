import React, { useState } from "react";

//reactstrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Badge
} from "reactstrap";

//classnames
import classnames from "classnames";

//components
import { TableErrors, LatencyChart, Usage } from "../data";

function MonitorTabs(props) {
  const { stateData } = props && props;

  //state
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const listTabs = [
    { id: "1", name: "Latency", component: <LatencyChart {...props} />, showBadge: false },
    { id: "2", name: "Errors", component: <TableErrors {...props} />, showBadge: true },
    { id: "3", name: "Usage", component: <Usage {...props} />, showBadge: false }
  ];

  return (
    <Container className="bg-white">
      <Nav className="tabBorder" tabs>
        {listTabs?.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => {
                toggle(item.id);
              }}
            >
              <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
              {item.name}
              {item?.showBadge && <Badge className="ml-2" color="primary">{stateData?.table_errors?.length || 0}</Badge>}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {listTabs?.map((item, index) => (
          <TabPane key={index} tabId={item.id}>
            {item.component}
          </TabPane>
        ))}
      </TabContent>
    </Container>
  );
}

export default MonitorTabs;