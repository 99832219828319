import React, { useState, useEffect } from 'react';

// Components
import { FeedbackBox } from "../../FeedbackV2";
import { MessageAssistant } from '@components-common/ChatTemplate';

// Icons
import IconChatRecord from '@images/learnerv2/icon-star-feedback.svg';

// Image
import ImageAssistant from "@images/common/assistant.png";

// Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

function QuestionFeedbackDetails(props) {
    // Khởi tạo state để lưu trữ feedback từ question.Scoring.Feedbacks
    const [instructorFeedback, setInstructorFeedback] = useState('');

    // Sử dụng useEffect để cập nhật state khi props thay đổi
    useEffect(() => {
        if (props.question?.Scoring?.Feedbacks) {
            setInstructorFeedback(props.question.Scoring.Feedbacks);
        }
    }, [props.question]);

    return (
        <FeedbackBox
            icon={IconChatRecord}
            title={`Score Rationale`}
        >
            <div className={`FrameChat__fullBox`}>
                <Scrollbar style={{ minHeight: 400 }}>
                    <div className="messengerCt">
                        <div className="messengerCt__messages">
                            <MessageAssistant
                                content={instructorFeedback}
                                avataAssistant={ImageAssistant}
                                roleText={`Instructor`}
                                theme={'--bg-blue-light'}
                            />
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </FeedbackBox>
    );
}

export default QuestionFeedbackDetails;