import React from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

// Styled components for the button and the icon container
const IconContainer = styled.div`
  background: #F2F6F9;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
`;

const ButtonAddCohort = ({ toggle }) => {
  return (
    <div style={{ marginTop: '12px' }}>
      <Button
        color="primary"
        outline
        onClick={() => toggle("addCohort")}
        className="w-100 d-flex align-items-center"
      >
        <IconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            style={{
              stroke: '#0089C2',    // Icon stroke color
              strokeWidth: '2',     // Stroke thickness
            }}
          >
            <path d="M7.50057 2.91714V11.0838M3.41724 7.00047H11.5839" stroke="#0089C2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconContainer>
        <span style={{marginLeft: 8}}>Add Cohort</span>
      </Button>
    </div>
  );
};

export default ButtonAddCohort;