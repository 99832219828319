//Makansafe Dashboard
import {
  MakansafeLoginPage,
  ForgotPassPage,
  CreateNewPasswordPage,
  OtpPage,
  HomeMakansafePage,
  AddNewFoodPage,
  EditNewFoodPage,
  AIGenerationFoodPage
} from "@pages/makansafe";

const Makansafe = [
  {
    path: "/makansafe/auth/login",
    exact: true,
    classPage: '',
    component: MakansafeLoginPage,
    isLayout: false
  },
  {
    path: "/makansafe/auth/forgot-password",
    exact: true,
    classPage: '',
    component: ForgotPassPage,
    isLayout: false
  },
  {
    path: "/makansafe/auth/create-new-password",
    exact: true,
    classPage: '',
    component: CreateNewPasswordPage,
    isLayout: false
  },
  {
    path: "/makansafe/auth/otp",
    exact: true,
    classPage: '',
    component: OtpPage,
    isLayout: false
  },
  {
    path: ["/home", "/"],
    exact: true,
    classPage: '-homePage',
    component: HomeMakansafePage,
    makansafeLayout: true
  },
  {
    path: ["/makansafe/add-food"],
    exact: true,
    classPage: '-add-food-page',
    component: AddNewFoodPage,
    makansafeLayout: true
  },
  {
    path: ["/makansafe/generate-food-ai"],
    exact: true,
    classPage: '-add-food-page',
    component: AIGenerationFoodPage,
    makansafeLayout: true
  },
  {
    path: ["/makansafe/edit-food/:id"],
    exact: true,
    classPage: '-edit-food-page',
    component: EditNewFoodPage,
    makansafeLayout: true
  },
];

export { Makansafe };
