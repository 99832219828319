import { pnorderConstants } from "@constants";
// import _ from 'lodash';

export function pnorder(state = {}, action) {
    switch (action.type) {
        case pnorderConstants.PNORDER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case pnorderConstants.PNORDER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case pnorderConstants.PNORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function pnorderUpdate(state = {}, action) {
    switch (action.type) {
        case pnorderConstants.PNORDER_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case pnorderConstants.PNORDER_UPDATE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case pnorderConstants.PNORDER_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function pnorderCheck(state = {}, action) {
    switch (action.type) {
        case pnorderConstants.CHECK_MY_ORDERS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case pnorderConstants.CHECK_MY_ORDERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case pnorderConstants.CHECK_MY_ORDERS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadPnOrders(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case pnorderConstants.LOAD_PNORDER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case pnorderConstants.LOAD_PNORDER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case pnorderConstants.CREATE_INTERVENTION_FOR_PNORDER_SUCCESS:
            newState = { ...state }
            const nameCategoryCreate = action.nameCategory;
            const filterDataByCategory = newState && newState.data && newState.data.PNOrders[nameCategoryCreate];
            if (filterDataByCategory) {
                const newItem = {
                    Available: (action.payload && action.payload.Available),
                    Id: (action.payload && action.payload.InterventionId),
                    Name: (action.payload && action.payload.Name),
                    Effects: []
                }
                filterDataByCategory.push(newItem);
            }

            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case pnorderConstants.UPDATE_AVAILABILITY_FOR_PNORDER_SUCCESS:
            newState = { ...state }
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case pnorderConstants.UPDATE_PN_AVAILABLE_IN_BATCH_SUCCESS:
            newState = { ...state }
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case pnorderConstants.UPDATE_INTERVENTION_FOR_PNORDER_SUCCESS:
            newState = { ...state }
            const nameCategoryUpdate = action.nameCategory;
            const filterDataUpdateByCategory = newState && newState.data && newState.data.PNOrders[nameCategoryUpdate];
            if (filterDataUpdateByCategory && (action.payload && action.payload.InterventionId)) {
                const index = filterDataUpdateByCategory.findIndex(x => Number(x.Id) === Number(action.interventionId));
                filterDataUpdateByCategory[index].Id = (action.payload && action.payload.InterventionId)
                filterDataUpdateByCategory[index].Name = (action.payload && action.payload.Name)
                filterDataUpdateByCategory[index].Available = (action.payload && action.payload.Available)
            }
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case pnorderConstants.DELETE_INTERVENTION_FOR_PNORDER_SUCCESS:
            newState = { ...state }
            const nameCategoryDel = action.nameCategory;
            const filterDataDeleteByIdCat = newState && newState.data && newState.data.PNOrders[nameCategoryDel];
            if (action.payload && action.payload.status === "Success") {
                const indexDel = filterDataDeleteByIdCat.findIndex(x => x.Id === action.InterventionId);
                delete filterDataDeleteByIdCat[indexDel];
            }
            return {
                ...newState,
                data: newState.data,
                isLoading: false
            };
        case pnorderConstants.UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_SUCCESS:
            newState = { ...state }
            const filteArrByNameCategory = newState && newState.data && newState.data.PNOrders[action.nameCategory];
            if (action.payload && action.payload.InterventionId) {
                const idPayLoad = action.payload && action.payload.InterventionId;
                const dataPayLoad = action.payload && action.payload;
                const indexUpdate = filteArrByNameCategory.findIndex(x => Number(x.Id) === Number(idPayLoad));
                filteArrByNameCategory[indexUpdate].Id = dataPayLoad.InterventionId
                filteArrByNameCategory[indexUpdate].Name = dataPayLoad.Name
                filteArrByNameCategory[indexUpdate].Available = dataPayLoad.Available
                filteArrByNameCategory[indexUpdate].Effects = []
            }
            return {
                ...newState,
                isLoading: false
            };
        case pnorderConstants.UPDATE_SCORE_FOR_PNORDER_SUCCESS:
            newState = { ...state };
            if (action.payload) {
                const dataPNOrdersByName = newState.data.PNOrders[action.namePanel];
                const inndexInterventionById = dataPNOrdersByName && dataPNOrdersByName.findIndex(x => Number(x.Id) === Number(action.attributeId));
                if (dataPNOrdersByName[inndexInterventionById]) {
                    dataPNOrdersByName[inndexInterventionById]['DomainScores'] = action.payload
                }
            }
            return {
                ...newState,
                isLoading: false
            };
        case pnorderConstants.LOAD_PNORDER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};