import React from 'react';

//Icon
import ImageCasePath from "@images/common/case-path.svg";

function ImageAfter() {
    return (
        <div className="section-path d-flex justify-content-center">
            <img src={ImageCasePath} alt="path" />
        </div>
    );
}

export default ImageAfter;