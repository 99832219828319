import React from 'react'
import { Container, Row, Col } from "reactstrap";
import ResultComponent from './ResultComponent';

const ResultPresentation = (props) => {
  return (
    <Container className='px-0'>
      <Row>
        <Col md={12} className='px-0'>
          <ResultComponent {...props}/>
        </Col>
      </Row>
    </Container>
  )
}

export default ResultPresentation
