import {
    EngagePage,
    QuestionGeneratePage,
    SmartShopPage,
    ValidatePage,
    AdministratorPage,
    QuestionPage,
    ProfilePage,
    HomeRedirectView,
    ssoPage,
    DesignPatternsPage
} from "@pages/med2lab";

const otherRoutes = [
    {
        path: "/",
        exact: true,
        classPage: '',
        component: HomeRedirectView,
        isLayout: false,
        ladingPage: true
    },
    {
        path: "/sso",
        exact: true,
        classPage: '',
        component: ssoPage,
        isLayout: false,
        ladingPage: false
    },
    {
        path: "/engage/:id",
        exact: true,
        classPage: '',
        component: EngagePage,
        isLayout: false,
        ladingPage: true
    },
    {
        path: "/question-generate",
        exact: true,
        classPage: '',
        component: QuestionGeneratePage,
        isLayout: false,
        ladingPage: true
    },
    {
        path: "/ecommerce/:id",
        exact: true,
        classPage: '',
        component: SmartShopPage,
        isLayout: false,
        ladingPage: true
    },
    {
        path: "/validate",
        exact: true,
        classPage: '',
        component: ValidatePage,
        isLayout: true,
    },
    {
        path: "/administrator-tool",
        exact: true,
        classPage: '',
        component: AdministratorPage,
        isLayout: true,
    },
    {
        path: "/question/:id",
        exact: true,
        classPage: '',
        component: QuestionPage,
        isLayout: true,
    },
    {
        path: "/profile",
        exact: true,
        classPage: '',
        component: ProfilePage,
        isLayout: true,
    },
    {
        path: "/design-patterns/:slug",
        exact: true,
        component: DesignPatternsPage,
        isLayout: true,
    },
    {
        path: "/design-patterns",
        exact: true,
        component: DesignPatternsPage,
        isLayout: true,
    },
];

export { otherRoutes };