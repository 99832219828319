import React, { useState, useEffect } from "react";

//reactstrap
import { Row } from "reactstrap";

//components
import { AddNewReview, ItemContents, ListAddReview } from "../../ReviewContentModal/Comment";

function ReviewContentMain(props) {
    //props
    const { loggingItem } = props && props;

    //state
    const [isShow, setShow] = useState(false);
    const [typeReview, setTypeReview] = useState(false);
    const [itemReviewSections, setItemReviewSections] = useState({});

    // Luc nay data thay doi khi click
    useEffect(() => {
        setShow(false);
    }, [loggingItem]);

    //Handle click back
    const handleClickBtnBack = () => {
        setShow(false);
    }

    // Hanlde Review
    const handleIsEditable = (item, type) => {
        setShow(true);
        setItemReviewSections(item);
        setTypeReview(type);
    }

    //Handle Update Review
    const handleClickBtnEdit = (item, type) => {
        setShow(true);
        setItemReviewSections(item);
        setTypeReview(type);
    }
    
    return (
        <Row className="h-100">
            <ItemContents {...props} />
            {isShow ? (
                <AddNewReview
                    {...props}
                    handleBack={handleClickBtnBack}
                    itemReviewSections={itemReviewSections}
                    typeReview={typeReview}
                />
            ) : (
                <ListAddReview
                    {...props}
                    handleIsEditable={handleIsEditable}
                    handleEdit={handleClickBtnEdit}
                />
            )}
        </Row>
    );
}

export default ReviewContentMain;