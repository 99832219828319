import React from "react";

const ActionsActions = () => {
  return (
    <div>
      <h2>ActionsActions Component</h2>
      {/* Thêm nội dung của ActionsActions component ở đây */}
    </div>
  );
};

export default ActionsActions;