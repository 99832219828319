import React, { useState, useEffect } from "react";
// import { Scrollbar } from "react-scrollbars-custom";

function VitalSignTable(props) {
  const [items, setItems] = useState(props);

  useEffect(() => {
    setItems(props);
  }, [props]);

  var objectListItems = [];
  var objectDiastolicBloodPressure = {};
  items?.data.map((value, index) => {
      if (value[0] === "Diastolic Blood Pressure") {
        objectDiastolicBloodPressure = value;
      } else {
        objectListItems.push(value);
      }
      return true;
    });

  const renderName = (value) => {
    let showName = '';
    let varName = value[1]?.Name;
    if(varName.includes("Temp")) {
      showName = 'Temp';
    } else if (varName.includes("Respiratory Rate")) {
      showName = 'RR';
    } else if (varName.includes("O2 saturation")) {
      showName = 'SpO2';
    } else if (varName.includes("Blood")) {
      showName = 'BP';
    } else if (varName.includes("Heart")) {
      showName = 'HR';
    } else {
      showName = varName;
    }
    return showName;
  }

  return (
    <div className="vital-sign-list p-3">
      {objectListItems?.map((value, index) => (
        <div className="vital-sign-list__item" key={index}>
          <div className="vital-sign-list__item--name">
            {renderName(value)}
          </div>
          <div className={`vital-sign-list__item--number ${value[1]?.CurrentValue > value[1]?.NormalMin && value[1]?.CurrentValue < value[1]?.NormalMax ? "" : "has-color"}`}>
            {value[1]?.Name === "Systolic Blood Pressure" ? 
              value[1]?.CurrentValue + "/" + (value[1]?.Name === "Diastolic Blood Pressure" ? 
              objectListItems[1]?.CurrentValue : 
              objectDiastolicBloodPressure[1]?.CurrentValue) :
              value[1]?.CurrentValue
            }
            <span className={`vital-sign-list__item--unit`}>
              ({value[1]?.Unit.includes("/min") ? "bpm": value[1]?.Unit})
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default VitalSignTable;