import React, { useState, useEffect } from "react";

//boostrap
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//components
import { PdfViewer } from "../References";

function TabContainer(props) {
  const { data } = props && props;
  const [activeTab, setActiveTab] = useState("1");

  const [stateData, setData] = useState(data || {});

  useEffect(() => {
    setData(data || {});
  }, [data])

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  
  return (
    <Container>
      <Row className="tabVertical">
        <Col className="tabVertical__left border-0" sm={12} md={3}>
          <Nav className="flex-column" tabs>
            {stateData?.map((item, index) => (
              <NavItem className="border mb-1" key={index}>
                <NavLink
                  className={classnames({
                    "active --bg-primary": activeTab === String(index + 1),
                  })}
                  onClick={() => {
                    toggle(String(index + 1));
                  }}
                >
                  {item.ReferenceName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Col>
        <Col sm={12} md={9} className="tabVertical__right mt-sm-4 mt-md-0">
          <TabContent className="pt-0" activeTab={activeTab}>
            {stateData?.map((item, index) => (
              <TabPane key={index} tabId={String(index + 1)}>
                {item.Type === "pdf" && <PdfViewer url={item.File} />}
                {item.Type === "html" && (
                  <div dangerouslySetInnerHTML={{ __html: item.Contents }} />
                )}
                {item.Type === "avomd" && (
                  <div>
                    <iframe src={item.Contents} title='avomd' frameBorder="0" height="550px" width="100%"></iframe>
                  </div>
                )}
              </TabPane>
            ))}
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}

export default TabContainer;
