import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { InterventionsMain } from "@components/elements/Interventions";
import { HeadingBox } from "@components/common";

//actions
import { loadAllCaseMedications } from "@actions";

function MedicationsPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "Medications",
      };
      dispatch(loadAllCaseMedications(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //store
  const storeMedications = useSelector(
    (state) => state?.listMedicationsForElement || {}
  );

  return (
    <div className="wrapper -eMedicationsPage">
      <section className="eMedicationsCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Medications" />
          <InterventionsMain 
            dataStore={storeMedications}
            categoryType={`Medications`}
            paramsElements={`Medications`}
          />
        </Container>
      </section>
    </div>
  );
}

export default MedicationsPage;