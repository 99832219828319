import React, { useState } from 'react';
import styled from 'styled-components';
import { Iconsax } from "@components-common";
import SignOrdersButton from './SignOrdersButton';

const AddedAtThisTimePoint = (props) => {
  const { addedItems, onRemove, onSignOrders } = props && props; 
  const [hoverIndex, setHoverIndex] = useState(null);

  return (
    <Container>
      <ItemList>
        {addedItems?.map((item, index) => (
          <Item key={item.OrderRecordId}>
            <ItemText>{item.Name}</ItemText>
            <RemoveButton
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}
              onClick={() => onRemove(item)}
            >
              <Iconsax
                iconName={`remove-item`}
                fill={hoverIndex === index ? "#D92D20" : "#98A2B3"}
                size={20}
              />
            </RemoveButton>
          </Item>
        ))}
      </ItemList>
      <StickyWrapper>
        <SignOrdersButton {...props} addedItems={addedItems} onClick={onSignOrders} />
      </StickyWrapper>
    </Container>
  );
};

export default AddedAtThisTimePoint;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const ItemList = styled.div`
  overflow-y: auto;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
  gap: 12px;
  margin-bottom: 6px;
  align-self: stretch;
  cursor: pointer;
  // border: 1px solid #D0D5DD;
  background: #FFF;
  transition: background 0.3s, border-radius 0.3s;
  border-radius: 6px;
  &:hover {
    background-color: #F2F4F7;
    color: #101828;
  }
`;

const ItemText = styled.span`
  color: #101828;
  font-size: 16px;
  font-weight: 500;
`;

const RemoveButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: #FEE4E2;
    svg {
      fill: #D92D20;
    }
  }
`;

const StickyWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 10px 0;  
  background-color: white;
`;