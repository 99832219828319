import React from "react";
import { Col } from "reactstrap";

function SwitchItem(props) {
    const { 
        data,
        handleChangeSwitch,
        labelClassName,
        fullWidth,
        showBorder,
        noPadding
    } = props;
    
    return (
        <React.Fragment>
            {data?.map((val, index) => {
                return (
                    <Col 
                        md={fullWidth ? 12 : 6} 
                        className={data?.length > 1 && showBorder ? "border-bottom" : ""} 
                        key={index}
                    >
                        <div 
                            className={`justify-content-between align-items-center d-flex ${noPadding ? "" : "py-3"}`}
                        >
                            <span className={labelClassName || ''}>{val?.Name}</span>
                            <div className="switches d-inline-flex">
                                <input
                                    type="checkbox"
                                    name={val?.param}
                                    checked={!!val?.checked}
                                    onChange={handleChangeSwitch}
                                    id={val?.param}
                                    readOnly
                                />
                                <label htmlFor={val?.param}>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </Col>
                );
            })}
        </React.Fragment>
    );
}

export default SwitchItem;