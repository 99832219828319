import React from "react";

function DescriptionCase(props) {
  const { data, classCustom } = props && props;
  return (
    <h4 className={`m-subheader__description heading-4 d-block mt-0 ${classCustom}`}>
      {data}
    </h4>
  );
}

export default DescriptionCase;