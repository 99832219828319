import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const Title = styled.h3`
    color: #364152;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 16px;
`;

const SelectContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    justify-content: center;
    margin-bottom: 8px;
`;

const SelectItem = styled.div`
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #CDD5DF;
    color: #364152;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    cursor: pointer;
    background-color: ${({ isSelected }) => isSelected ? '#E0E7FF' : 'white'};
    &:active {
        background-color: #D1D5DB;
    }
`;

const options = ["How are you", "Are you Ok", "What happen for you"];

const MultiSelect = ({ setSelectedText, multiple = false }) => {
    const [selectedIndexes, setSelectedIndexes] = useState([]);

    const handleSelect = (index) => {
        if (multiple) {
            if (selectedIndexes.includes(index)) {
                setSelectedIndexes(selectedIndexes.filter(i => i !== index));
            } else {
                setSelectedIndexes([...selectedIndexes, index]);
            }
        } else {
            setSelectedIndexes([index]);
        }
        updateSelectedText([...selectedIndexes, index], multiple);
    };

    const updateSelectedText = (indexes, multiple) => {
        const selectedOptions = indexes.map(i => options[i]);
        setSelectedText(multiple ? selectedOptions.join(', ') : selectedOptions[0]);
    };

    return (
        <Container>
            <Title>Select only One answer</Title>
            <SelectContainer>
                {options.map((option, index) => (
                    <SelectItem
                        key={index}
                        isSelected={selectedIndexes.includes(index)}
                        onClick={() => handleSelect(index)}
                    >
                        {option}
                    </SelectItem>
                ))}
            </SelectContainer>
        </Container>
    );
};

export default MultiSelect;