import React, { useState, useEffect } from "react";

//components
import ChatRecordsContent from "./ChatRecordsContent";

//utils
import { LoaderText, apiCaller } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

function ChatRecordsTab(props) {
    let { id } = useParams();
    const caseData = useSelector((state) => state.cases || []);
    const TimePointNum = caseData?.data?.Case?.Timeline?.TimePointNum;

    //state
    const [activeTab, setActiveTab] = useState(1);
    const [stateData, setData] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            const params = { "Action": "LoadChatRecord" }
            apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                    setLoading(false);
                } else {
                    console.log('error');
                    setLoading(false);
                }
            });
        }
        //Load Api Model
        fetchData();
    }, [id]);

    useEffect(() => {
        // Set active tab based on TimePointNum
        if (TimePointNum) {
            setActiveTab(TimePointNum);
        }
    }, [TimePointNum]);

    const handleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    
    return (
        <React.Fragment>
            <ul className="tabs-corner">
                {/* Chỉ hiển thị các TimePoint từ 1 đến TimePointNum */}
                {stateData?.EMRChatData?.map((value, index) => (
                    index < TimePointNum && (
                        <li
                            className={`tabs-corner__item ${activeTab === index + 1 ? 'active' : ''}`}
                            key={index}
                            onClick={() => handleTab(index + 1)}
                        >
                            <span className="tabs-corner__item--text">{value.TimePointName}</span>
                            {value?.Icon && <span className={`tabs-corner__item--icon ${value?.Icon}`}></span>}
                        </li>
                    )
                ))}
            </ul>
            <div className="result-tab-content pt-3">
                {/* Hiển thị nội dung tương ứng với các TimePoint */}
                {stateData?.EMRChatData?.map((value, index) => (
                    index < TimePointNum && (
                        <div key={index} className={`${activeTab === index + 1 ? 'd-block' : 'd-none'}`}>
                            <ChatRecordsContent
                                {...props}
                                ChatRecords={value?.ChatRecords}
                            />
                        </div>
                    )
                ))}
            </div>
            {isLoading && <LoaderText />}
        </React.Fragment>
    );
}

export default ChatRecordsTab;