import React, { useState } from "react";
import { Input, Tooltip, Label, FormGroup, Col } from "reactstrap";

//lodash
import _ from "lodash";

function ItemOrderDropdown(props) {
  const {
    name,
    data,
    handleChange,
    isLoading,
    defaultValue,
    findOrderId,
    label,
    fullWidth
  } = props && props;

  const [tooltipOpen, setTooltipOpen] = useState({ [name]: false });

  const handleTooltipOpen = (e) => {
    const { name } = e.target;
    setTooltipOpen({ [name]: !tooltipOpen[name] });
  };

  const handleChangeInput = (e, data) => {
    handleChange(e, data);
    setTooltipOpen({ [name]: false });
  }

  const filterName = _.filter(data, { 'OrderId': defaultValue });
  return (
    <FormGroup className={`${fullWidth ? "mb-0" : ""}`}>
      <div className="d-flex">
        {label &&
          <Label className="col-order-label" for={label} sm={5}>
            {label}
          </Label>
        }
        <Col sm={fullWidth ? 12 : 7}>
          <Input
            id={name}
            type="select"
            disabled={isLoading}
            onChange={(e) => handleChangeInput(e, data)}
            value={defaultValue}
            name={name}
            data-selected={findOrderId({ name }, data || [])}
          >
            <option disabled value="0">
              Select...
            </option>
            {data?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <option
                    data-id={item?.OrderId}
                    data-selected={item?.CurrentlyOrdered}
                    data-value={item?.Value}
                    value={item?.OrderId}
                  >
                    {item?.Name}
                  </option>
                </React.Fragment>
              );
            })}
            {filterName && filterName[0]?.Name && (
              <Tooltip
                placement="top"
                isOpen={tooltipOpen[name]}
                target={name}
                toggle={handleTooltipOpen}
              >
                {filterName && filterName[0]?.Name}
              </Tooltip>
            )}
          </Input>
        </Col>
      </div>
    </FormGroup>
  );
}

export default ItemOrderDropdown;
