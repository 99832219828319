import React, { useState, useRef } from "react";

import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Drag
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

//common
import { DataEmty } from "@components-common";

//components
import {
  ModalPhysicalExam,
  ModalDeleteExam,
  ModalDeleteExamPoint,
} from "../Modal";
import { ContainerDragPoint } from ".";
import { LoaderText } from "@utils";

//Actions
import {
  actDeleteExamFindingById,
  actDeleteExamPointById,
  actCreateExamPoint,
} from "@actions";

function HumanModel(props) {
  let { id } = useParams();
  // let history = useHistory();
  const dispatch = useDispatch();

  //props
  const caseData = props && props.caseData;
  const timepointData =
    props && props.timepointData && props.timepointData.data;
  const examData = timepointData && timepointData.Exam;
  // const profileData = examData && examData.Profile;
  const listPoints = examData && examData.ExamPoints;
  const timePointId = props && props.timePointId;

  //state
  const [actions, setActions] = useState(false);
  const [modal, setModal] = useState(false);
  const [dataDel, setDataDel] = useState({});
  const [modalDel, setModalDel] = useState(false);
  const [modalDelExam, setModalDelExam] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [heightExPoint, setheightExPoint] = useState(0);
  const [widthExPoint, setwidthExPoint] = useState(0);
  const [stateAttributeId, setStateAttributeId] = useState("");
  const [statePointId, setPointId] = useState("");
  const getSizeWrap = useRef();

  //Modal Update
  const toggle = (PointId, value, actions) => {
    setPointId(PointId);
    setStateAttributeId(value && value.AttributeId);
    setModal(!modal);
    setActions(actions);
  };

  const toggleDel = (data) => {
    setModalDel(!modalDel);
    setModal(false);
    setDataDel(data);
  };

  const toggleDelExamPoint = (PointId, value, actions) => {
    setPointId(PointId);
    setActions(actions);
    setModalDelExam(!modalDelExam);
  };

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "ExamFinding",
      TimePointId: timePointId,
      PointId: statePointId,
      AttributeId: dataDel.AttributeId,
    };
    dispatch(actDeleteExamFindingById(id, params));
    setModalDel(false);
  };

  const handleRemoveExamPoint = () => {
    const params = {
      Action: "Delete",
      Target: "ExamPoint",
      TimePointId: timePointId,
      PointId: statePointId,
    };
    dispatch(actDeleteExamPointById(id, params));
    setModalDelExam(false);
  };

  const hanldeAddpoint = () => {
    const params = {
      Action: "Create",
      Target: "ExamPoint",
      TimePointId: timePointId,
    };
    dispatch(actCreateExamPoint(id, params));
  };

  //Check load exam
  const checkImageLoad = () => {
    const domRect =
      getSizeWrap &&
      getSizeWrap.current &&
      getSizeWrap.current.getBoundingClientRect();
    setwidthExPoint(domRect && domRect.width);
    setheightExPoint(domRect && domRect.height);
    setLoadImage(true);
  };

  const styles = {
    position: "absolute",
    left: "0",
    top: "0",
    width: "100%",
    height: "100%",
  };

  const imgHumanUrl = caseData && caseData.HumanModelUrl;
  return (
    <React.Fragment>
      {imgHumanUrl ? (
        <div style={{maxWidth: '400px'}} className="mx-auto">
          <div className="patientPhoto" ref={getSizeWrap}>
            {!loadImage && <LoaderText className="h-100" />}
            <DndProvider backend={HTML5Backend}>
              <img
                className={`img-fluid`}
                src={imgHumanUrl || ""}
                alt="exam_photo"
                onLoad={() => checkImageLoad()}
                style={loadImage ? styles : null}
              />
              <ContainerDragPoint
                {...props}
                toggle={toggle}
                toggleDelExamPoint={toggleDelExamPoint}
                heightExPoint={heightExPoint}
                widthExPoint={widthExPoint}
                data={listPoints}
              ></ContainerDragPoint>
            </DndProvider>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn-success mr-2"
              name="mode"
              value="add_point"
              onClick={hanldeAddpoint}
            >
              <i className="fa fa-plus" /> Add Point
            </button>
          </div>
        </div>
      ): (
        <DataEmty content="No Data" />
      )}
      
      <ModalPhysicalExam
        {...props}
        AttributeId={stateAttributeId}
        toggle={toggle}
        isOpen={modal}
        PointId={statePointId}
        toggleDel={toggleDel}
        actions={actions}
      />
      <ModalDeleteExam
        {...props}
        toggleDel={toggleDel}
        isOpen={modalDel}
        handleRemove={handleRemove}
      />
      <ModalDeleteExamPoint
        {...props}
        toggleDel={toggleDelExamPoint}
        isOpen={modalDelExam}
        handleRemove={handleRemoveExamPoint}
        PointId={statePointId}
      />
    </React.Fragment>
  );
}

export default HumanModel;
