import React, { useState, useEffect } from 'react';

//Concepts Components
import { TheadConcepts, ModalEditConcept } from '../Concepts';

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components
import { ButtonDelete, ButtonEdit } from '@components-common/elements';

//common
import { DataEmty } from "@components-common";

//components common
import { ModalDeleteCommon } from "@ui-partents/Modal";

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

function TableConcept(props) {
    const { value } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [stateData, setStateData] = useState(value?.Concepts || []);
    const [itemCurrent, setItemCurrent] = useState('');
    const [modal, setModal] = useState({
        UpdateProblemConcept: false,
        DeleteProblemConcept: false
    });
    const [isLoading, setLoading] = useState(false);

    //load Data
    useEffect(() => {
        setStateData(value?.Concepts || []);
    }, [value]);

    //Modal
    const toggle = (type, value) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        setItemCurrent(value);
    };

    //remove
    const handleRemove = () => {
        const params = {
            "Action": "DeleteProblemConcept",
            "CaseId": id,
            "ProblemConceptId": itemCurrent?.ProblemConceptId
        };

        setLoading(true);
        dispatch({ type: problemsConstant.DELETE_PROBLEM_CONCEPT_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_CONCEPT_SUCCESS, payload: data });
                setModal((prevState) => ({ ...prevState, DeleteProblemConcept: false }));
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_CONCEPT_FAILURE, error: "error" });
                setLoading(false);
                setModal((prevState) => ({ ...prevState, DeleteProblemConcept: false }));
            }
        });
    }
    const RowItems = stateData?.map((value, index) => {
        return (
            <tr key={index} data-id={value?.ProblemConceptId || ''}>
                <td>{value?.ProblemConceptName || ''}</td>
                <td>{value?.SearchType || ''}</td>
                <td>{value?.SearchString || ''}</td>
                <td className='text-center'>
                    {value?.Required && <i className="fa fa-check-square-o text-success" aria-hidden="true"></i>}
                </td>
                <td className='text-right'>
                    <div className="d-flex justify-content-end">
                        <ButtonEdit handleEdit={() => toggle('UpdateProblemConcept', value)} />
                        <ButtonDelete handleDelete={() => toggle('DeleteProblemConcept', value)} />
                    </div>
                </td>
            </tr>
        )
    });

    return (
        <React.Fragment>
            <table className="table mb-0">
                {stateData?.length > 0 && <TheadConcepts />}
                {stateData?.length > 0 && (
                    <tbody>
                        {RowItems}
                    </tbody>
                )}
            </table>
            {stateData?.length === 0 && <DataEmty content={`No Data`} />}
            <ModalEditConcept
                {...props}
                isOpen={modal?.UpdateProblemConcept}
                toggle={() => toggle('UpdateProblemConcept')}
                data={itemCurrent}
            />
            <ModalDeleteCommon
                title={`Are you sure you want <br/> to delete this Problem Concept ? `}
                sub={`This action is not reversible.`}
                isOpen={modal?.DeleteProblemConcept}
                toggle={() => toggle('DeleteProblemConcept', itemCurrent)}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default TableConcept;