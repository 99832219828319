import { themeConstants } from "@constants";
import {apiCaller} from "@utils";

//fetch theme
export const actFetchThemeRequest = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/theme/`, 'GET', null).then(res => {
            const data = res && res.data;
            const themeData = JSON.stringify(data);
            if(res && res.status === 200) {
                dispatch(success(data));
                localStorage.setItem("themeData", themeData);
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: themeConstants.THEME_REQUEST, isLoading } }
    function success(data) { return { type: themeConstants.THEME_SUCCESS, payload: data } }
    function failure(error) { return { type: themeConstants.THEME_FAILURE, error: error } }
}

export const atcLoadStateTheme = (data) => {
    return dispatch => {
        dispatch(success(data));
    };

    function success(data) { return { type: themeConstants.LOAD_THEME_STATE, payload: data } }
}