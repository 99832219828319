import React, { useEffect, useState } from "react";

//lodash
import _ from "lodash";

import UpdateEndingTimePoint from './UpdateEndingTimePoint';

function FeedbackTemplate(props) {
  //props
  const { data } = props && props;

  const checkData = _.isObject(data && data);
  const [stateData, setStateData] = useState((data && data) || {});

  useEffect(() => {
    setStateData((data && data) || {});
  }, [data, checkData]);
  
  return (
    <UpdateEndingTimePoint data={stateData} />
  );
}

export default FeedbackTemplate;
