import React from 'react';

function TabComponentFill({ tabs, activeTab, onTabChange, tabsForm }) {
  return (
    <div className={`--new-tabs-custom`}>
      <ul className={`--new-tabs-fill ${tabsForm ? 'tabs-for-form' : ''}`}>
        {tabs.map((tab) => (
          <li className={`--new-tabs-fill__item ${tabsForm ? 'tabs-for-form__item' : ''}`} key={tab.id}>
            <span
              className={`--new-tabs-fill__item--link ${tabsForm ? 'tabs-for-form__item--link' : ''} ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => onTabChange(tab.id)}
            >
              {tab.title}
              <span className="badge-circle">{tab.count}</span>
            </span>
          </li>
        ))}
      </ul>

      <div className="tab-content mt-3">
        {tabs.map((tab) => (
          <div
            className={`tab-pane ${activeTab === tab.id ? 'active' : ''}`}
            id={tab.id}
            key={tab.id}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default TabComponentFill;