import React from "react";

//components
import { TabsContent } from "../Learners/tabs";

//logo default
import iconClose from "@images/common/close_modal.svg";

function ContentPush(props) {
  const { isOpen, toggle, dataUser, layout } = props && props;
  const title = `${
    dataUser?.Name
      ? dataUser?.Name
      : `${dataUser?.FirstName} ${dataUser?.LastName}`
  } ${dataUser?.CompletionTime || ""}`;
  return (
    <div
      className={`boxPushCt showRight scrollbarStyle ${layout} ${
        isOpen && "open-right"
      }`}
    >
      <div className="boxPushCt__heading">
        <img
          className="icon-exit-to-app-button ml-auto closeIcon"
          src={iconClose}
          alt="close modal"
          onClick={toggle}
        />
      </div>
      <div className="boxPushCt__body">
        <h3 className="heading-3 p-3">{title}</h3>
        <TabsContent {...props} />
      </div>
    </div>
  );
}

export default ContentPush;