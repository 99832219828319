import React, { useState } from 'react';

//components
import { AddOption, ModalAddOption } from '../Options';

function ProblemAccordionChild(props) {
    //state
    const [modal, setModal] = useState({ CreateProblemOption: false });

    //Modal
    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    return (
        <React.Fragment>
            <AddOption
                toggle={() => toggle('CreateProblemOption')}
            />
            <ModalAddOption
                {...props}
                isOpen={modal?.CreateProblemOption}
                toggle={() => toggle('CreateProblemOption')}
            />
        </React.Fragment>
    );
};

export default ProblemAccordionChild;