import React, { useEffect, useState } from "react";
import styled, { keyframes } from 'styled-components';

function ProgressBar(props) {
  const { data } = props && props;
  const percentCompleted = data?.PercentCompleted;
  const timePointNum = data?.TimePointNum;
  const numberOfTimePoints = data?.NumberOfTimePoints;
  const contentProgress = timePointNum === numberOfTimePoints ? "Last time point !" : "timepoints";
  const contentNumberProgress = `${timePointNum}/${numberOfTimePoints}` || "";

  //state
  const [showEffect, setEffect] = useState(false);

  useEffect(() => {
    //remove item 
    timePointNum === 1 && localStorage?.removeItem("percentCompletedOld");
    timePointNum === 2 && setEffect(true);
    setTimeout(() => {
      setEffect(false);
    }, 300);
  }, [timePointNum])

  // const fileList = dataTour?.filter(function (event) {
  //   return event?.selector?.indexOf('step-2') > -1
  // })

  const getlocalStorage = localStorage?.getItem("percentCompletedOld");
  const percentCompletedOld = JSON.parse(getlocalStorage) || 0;
  const progressAnimationStrike = keyframes`
    from { width: ${percentCompletedOld || 0}% }
    to   { width: ${percentCompleted}% }
  `
  const ProgressContainer = styled.div`
    display: flex;
    height: 1rem;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  `

  const AnimationProgress = styled.div`
    animation: ${progressAnimationStrike} 0.6s;
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0089c3;
    width: ${percentCompleted || 0}%
  `

  const NotAnimationProgress = styled.div`
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0089c3;
    width: ${percentCompleted || 0}%
  `

  return (
    <React.Fragment>
      <div className={`d-block w-25`}>
        <ProgressContainer>
          {showEffect ? (
            <AnimationProgress
              className={`--bg-primary`}
            >
              {percentCompleted}%
            </AnimationProgress>
          ) : (
            <NotAnimationProgress
              className={`--bg-primary`}
            >
              {percentCompleted}%
            </NotAnimationProgress>
          )}
        </ProgressContainer>
      </div>
      <div className="d-inline-block ml-2">
        <span
          className="ml-2 text-nowrap --text-primary font-weight-500"
          style={{ fontSize: "12px" }}
        >
          {timePointNum !== numberOfTimePoints
            ? `${contentNumberProgress} ${contentProgress}`
            : `${contentProgress}`}
        </span>
      </div>
    </React.Fragment>
  );
}

export default ProgressBar;
