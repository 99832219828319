import React from "react";

//components
import { PatientListGroup } from "../../StandardScreen/PatientSummary";

function PatientSummaryMain(props) {
    const { caseData, heightPatientMedRecord } = props && props;
    const PatientSummary = caseData?.data?.Case?.PatientSummary;
    const ProblemList = PatientSummary?.ProblemList;
    const HomeMedications = PatientSummary?.HomeMedications;
    const Immunization = PatientSummary?.Immunization;
    const Allergies = PatientSummary?.Allergies;

    const getAge = (dateString) => {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
    
    return (
        <div className="bg-white">
            <div className="patient-info border-bt-2-blue">
                <div className="patient-info__left">
                    <div className="patient-group">
                        <h3 className="patient-group__heading">
                            <span className="icon-person patient-group__heading--icon"></span>Name
                        </h3>
                        <p className="patient-group__text">{PatientSummary?.Name}</p>
                    </div>
                </div>
                <div className="patient-info__right">
                    <div className="row algin-items-center">
                        <div className="col-6">
                            <div className="patient-group solid-r-grey">
                                <h3 className="patient-group__heading">
                                    <span className="icon-dob patient-group__heading--icon"></span>D.O.B
                                </h3>
                                <p className="patient-group__text">
                                    {PatientSummary?.DOB}
                                    <span className="ml-1 text-1 font-weight-400" style={{ color: '#666666' }}>({getAge(PatientSummary?.DOB)} y.o.)</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="patient-group">
                                <h3 className="patient-group__heading">
                                    <span className="icon-person patient-group__heading--icon"></span>Gender
                                </h3>
                                <p className="patient-group__text">{PatientSummary?.Gender}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /top */}
            <div className="patient-info">
                <div className="patient-info__left isShowScroll" style={{ height: `${heightPatientMedRecord - 80}px`, borderBottomLeftRadius: '13px' }}>
                    {ProblemList?.length > 0 && (
                        <PatientListGroup
                            {...props}
                            label='Problem List'
                            icon='icon-person'
                            data={ProblemList || []}
                        />
                    )}
                </div>
                <div className="patient-info__right isShowScroll" style={{ height: `${heightPatientMedRecord - 80}px`, borderBottomRightRadius: '13px' }}>
                    {HomeMedications?.length > 0 && (
                        <PatientListGroup
                            {...props}
                            label='Home Medications'
                            icon='icon-home-medication'
                            data={HomeMedications || []}
                        />
                    )}
                    {Immunization?.length > 0 && (
                        <PatientListGroup
                            {...props}
                            label='Immunization'
                            icon='icon-immunisations'
                            data={Immunization || []}
                        />
                    )}
                    {Allergies?.length > 0 && (
                        <PatientListGroup
                            {...props}
                            label='Allergies'
                            icon='icon-allergies-2'
                            data={Allergies || []}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default PatientSummaryMain;