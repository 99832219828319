import React from 'react';
import { Button } from 'reactstrap';

const ButtonComponent = ({ size, color = "primary", children, ...props }) => {
    let buttonSize = '';

    switch (size) {
        case 'small':
            buttonSize = 'sm';
            break;
        case 'large':
            buttonSize = 'lg';
            break;
        default:
            buttonSize = '';
            break;
    }

    return (
        <Button color={color} size={buttonSize} {...props}>
            {children}
        </Button>
    );
};

export default ButtonComponent;