
import React from 'react';

const LearnerGridParent = (props) => {
  return (
    <div className={`learner-grid`}>
      {props.children}
    </div>
  );
};

export default LearnerGridParent;