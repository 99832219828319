import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";
import { Link } from "react-router-dom";

//react-hook-form
import { useForm } from "react-hook-form";

//logo
import imgLogo from "@images/common/med2lab_logo.png";

//boostrap
import {
    Row,
    Col,
    Form,
    Label,
    FormGroup,
    Input,
    FormFeedback,
    Alert
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//utils
import { checkAuth, useQuery, apiCaller } from "@utils";

function FormRegisterDemo() {
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    let history = useHistory();
    const dispatch = useDispatch();
    let query = useQuery();

    //Check email url
    let activeParams = query.get("email");
    useEffect(() => {
        if (activeParams) {
            setValue("Email", activeParams);
        }
    }, [activeParams, setValue]);

    const [tokenCaptcha, setTokenCaptcha] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [stateMessage, setMessage] = useState('');

    useEffect(() => {
        document.body.style["background-color"] = "#fafbfb";
        if (checkAuth()) {
            history.push("/");
        }
    }, [history]);

    // Chuyen huong page
    const nextPage = (data) => {
        const dataAccount = data?.account;
        if (dataAccount?.is_instructor === "Yes") {
            history.push(`/teach/home`);
        } else {
            history.push("/learn/home");
        }
    };

    // Handle Register And Login
    const onsubmit = (data) => {
        const params = {
            "Email": data?.Email,
            "Firstname": data?.FirstName,
            "Lastname": data?.LastName,
            "Organization": data?.Institution,
        }

        setLoading(true);
        //Call Api
        apiCaller(`/api/auth/demo/`, "POST", params).then((res) => {
            const dataResponse = res?.data;
            if (res?.status === 200) {
                if (res?.error) {
                    setMessage(res?.error);
                } else {
                    if (dataResponse?.status === "OK") {
                        const accountData = dataResponse?.account;

                        // set localStorage
                        localStorage.setItem(
                            "Account",
                            accountData ? JSON.stringify(accountData) : ""
                        );

                        //add token
                        dispatch(actionLogin(dataResponse));

                        //Call API Theme
                        dispatch(actFetchThemeRequest());

                        // Check Redirect Page
                        nextPage(dataResponse);
                    }
                }
            }
        });
    };

    const onChangeCaptcha = (value) => {
        setTokenCaptcha(value);
    };

    return (
        <Col lg={`6`} className="p-5 bg-white mx-auto border -box-shadow">
            <div className="logo d-flex align-items-center justify-content-start justify-content-md-center mb-4">
                <Link to={"/"} style={{ maxWidth: "270px" }}>
                    <img className="d-block mx-auto img-fluid" src={imgLogo} alt="logo" />
                </Link>
            </div>
            <div className="text-center">
                <h1 className="heading-3 mt-0 mb-0 --text-primary font-weight-500">
                    Request Live Demo
                </h1>
            </div>
            <Alert className="m-alert--default border text-center my-4 font-weight-500">
                Thank you for your interest! <br />Please fill out the short form below to start the demo.
            </Alert>
            <Row>
                <Col
                    md={10}
                    className="mx-auto m--font-danger text-center"
                    id="message_invalid"
                >
                    {stateMessage}
                </Col>
            </Row>
            <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
                <Row>
                    <Col lg={`6`}>
                        <FormGroup>
                            <Label for="FirstName">
                                First Name: <span className="m--font-danger">*</span>
                            </Label>
                            <Input
                                {...register("FirstName", { required: true })}
                                type="text"
                                name="FirstName"
                                placeholder="Enter your first name"
                                onChange={(e) => {
                                    setValue("FirstName", e.target.value, {
                                        shouldValidate: true,
                                    });
                                }}
                                invalid={errors.FirstName?.type === "required"}
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={(errors.FirstName?.type === "required").toString()}
                            >
                                This field is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm={`12`} md={`6`}>
                        <FormGroup>
                            <Label for="LastName">
                                Last Name:<span className="m--font-danger">*</span>
                            </Label>
                            <Input
                                {...register("LastName", { required: true })}
                                type="text"
                                name="LastName"
                                placeholder="Enter your last name"
                                onChange={(e) => {
                                    setValue("LastName", e.target.value, {
                                        shouldValidate: true,
                                    });
                                }}
                                invalid={errors.LastName?.type === "required"}
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={(errors.LastName?.type === "required").toString()}
                            >
                                This field is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm={`12`} md={`12`}>
                        <FormGroup>
                            <Label for="Email">
                                Email Address: <span className="m--font-danger">*</span>
                            </Label>
                            <Input
                                {...register("Email", {
                                    required: "This field is required.",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Enter a valid e-mail address",
                                    },
                                })}
                                type="email"
                                name="Email"
                                placeholder="Enter your email address"
                                invalid={errors.Email?.type === "required"}
                                defaultValue={watch("Email")}
                            />
                            {errors.Email?.message && (
                                <FormFeedback className="d-block">
                                    {errors.Email?.message}
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col sm={`12`} md={`12`}>
                        <FormGroup>
                            <Label for="Institution">Institution:</Label>
                            <Input
                                type="text"
                                id="Institution"
                                {...register("Institution", { required: true })}
                                name="Institution"
                                placeholder="Enter the name of your institution / organization here"
                                onChange={(e) => {
                                    setValue("Institution", e.target.value, {
                                        shouldValidate: true,
                                    });
                                }}
                                invalid={errors.Institution?.type === "required"}
                                autoComplete="off"
                            />
                            {errors.Institution?.type && (
                                <FormFeedback
                                    invalid={(
                                        errors.Institution?.type === "required"
                                    ).toString()}
                                >
                                    This field is required.
                                </FormFeedback>
                            )}
                        </FormGroup>
                    </Col>
                    <Col sm={`12`}>
                        <hr />
                    </Col>
                    <Col sm={`12`} className="mt-2">
                        <div className="d-flex justify-content-center">
                            <ReCAPTCHA
                                sitekey={Config.KEY_CAPTCHA}
                                onChange={onChangeCaptcha}
                            />
                        </div>
                        {!tokenCaptcha && (
                            <FormFeedback className="d-block text-center">
                                This field is required.
                            </FormFeedback>
                        )}
                    </Col>
                    <Col sm={`12`} className="mt-4">
                        <div className="d-flex justify-content-center">
                            <button
                                type="submit"
                                className="btn btn-brand btn-lg d-flex align-items-center ml-3"
                                onClick={handleSubmit(onsubmit)}
                                disabled={isLoading}
                            >
                                {isLoading && (
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                )}
                                Start Demo
                            </button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Col>
    );
}

export default FormRegisterDemo;