import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { debounce } from 'lodash';
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";
import { ChatPromptTemp } from ".";
import TextareaAutosize from "react-textarea-autosize";

//reactstrap
import { FormGroup, Label } from "reactstrap";

//components
import { ButtonSwitchChatWrapup } from "../../InteractiveChat/ChatWrapup";

function ChatWrapupInstructions(props) {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { timepointData } = props || {};
    const ChatSettings = timepointData?.ChatSettings;
    const EndCaseFeedbackPromptTemplates = ChatSettings?.EndCaseFeedbackPromptTemplates || [];

    //state
    const [content, setContent] = useState(ChatSettings?.EndCaseFeedbackInstructions || "");
    const [stateEndCaseFeedback, setEndCaseFeedback] = useState(ChatSettings?.EndCaseFeedback);

    useEffect(() => {
        setContent(ChatSettings?.EndCaseFeedbackInstructions);
        setEndCaseFeedback(ChatSettings?.EndCaseFeedback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update Chat Settings
    const updateChatSettings = (params) => {
        const isLoading = true;
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
                console.log("error");
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedApiCaseScript = useCallback(debounce((value) => {
        const params = {
            "Action": "Update",
            "Target": "ChatSettings",
            "EndCaseFeedbackInstructions": value,
            "TimePointId": timepointData?.Id
        };
        updateChatSettings(params);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 1000), []);

    const handleContentChange = (value) => {
        setContent(value);
        debouncedApiCaseScript(value);
    };

    //set Switch
    const handleChangeSwitch = (e) => {
        const { checked } = e.target;
        setEndCaseFeedback(checked);
        // const params = {
        //     "Action": "Update",
        //     "Target": "ChatSettings",
        //     "TimePointId": timepointData?.Id,
        //     "EndCaseFeedback": checked,
        // };
        // updateChatSettings(params);
    }

    return (
        <React.Fragment>
            <ButtonSwitchChatWrapup
                {...props}
                handleChangeSwitch={handleChangeSwitch}
                value={stateEndCaseFeedback}
            />
            <FormGroup>
                <Label className="labeForm mt-4">
                    Chat Wrap-up:
                </Label>
                <TextareaAutosize
                    name="GetEndCaseFeedback"
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={8}
                    onChange={(e) => handleContentChange(e.target.value)}
                    value={content}
                />
            </FormGroup>
            <ChatPromptTemp
                {...props}
                currentTemplate={EndCaseFeedbackPromptTemplates || []}
                typeChatSettings={"EndCaseFeedbackPromptTemplates"}
            />
        </React.Fragment>
    );
}

export default ChatWrapupInstructions;