import { Makansafe } from "./MakansafeRoutes";
import { Med2lab } from "../routes/Med2labRoutes";
import { aspet2024Routes } from "../routes/aspet2024Routes";

const allRoutes = [
  ...Makansafe,
  ...Med2lab,
  ...aspet2024Routes
  // Các route khác (nếu có)
];

export default allRoutes;