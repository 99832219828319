import React, { useState } from "react";
import { useParams } from "react-router-dom";

//Icon
import { Iconsax } from "@components-common";

//components
import { ModalUpdateTimePoint } from "../TimePoint";
import { ModalDeleteCommon } from "@ui-partents/Modal";

//call Api
import { apiCaller } from "@utils";

// constants
import { storyboardConstants } from "@constants";

//redux
import { useDispatch } from "react-redux";

const UpdateTimePoint = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { timepointIdActive, handleActiveTimepointFirst, timepointData } = props && props;

    //state
    const [isModalOpen, setIsModalOpen] = useState({ delete: false, edit: false });
    const [isLoading, setIsLoading] = useState(false);

    // Handle toggle
    const toggleModal = (type) => {
        setIsModalOpen({ ...isModalOpen, [type]: !isModalOpen[type] });
    };

    // Handle Remove
    const handleRemove = () => {
        const params = {
            Action: "Delete",
            Target: "TimePoint",
            TimePointId: timepointIdActive,
        };
        handleApiDeleteTimepoint(params);
    };

    // Handle API Delete Timepoint
    const handleApiDeleteTimepoint = (params) => {
        const isLoading = true;
        setIsLoading(true);
        dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_SUCCESS, payload: data });
                setIsLoading(false);
                toggleModal("delete");
                handleActiveTimepointFirst();
            } else {
                dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_FAILURE, error: 'error' });
                setIsLoading(false);
                toggleModal("delete");
            }
        });
    }
    
    return (
        <React.Fragment>
            <div className="d-flex align-items-center">
                <div className="btn-action-timpoint ml-4" onClick={() => toggleModal("edit")}>
                    <Iconsax iconName="edit-line-bottom" fill="#0089C2" size={25} />
                </div>
                {timepointData?.Time > 0 && (
                    <div className="btn-action-timpoint ml-2" onClick={() => toggleModal("delete")}>
                        <Iconsax iconName="trash" fill="#D92D20" size={25} />
                    </div>
                )}
            </div>
            <ModalUpdateTimePoint
                {...props}
                isOpen={isModalOpen.edit}
                toggle={() => toggleModal("edit")}
                action={isModalOpen.edit ? "Update" : "Delete"}
            />
            <ModalDeleteCommon
                title={`Delete Time Point`}
                sub={`Are you sure you want to delete this time point? <br/> This action cannot be undone. All data related to this time point will be removed.`}
                isOpen={isModalOpen.delete}
                toggle={() => toggleModal("delete")}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default UpdateTimePoint;