import React, { useState } from "react";

//router
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

function ModalDeleteEffects(props) {
  let { id } = useParams();
  const location = window.location.pathname;
  let pathName = (location.includes("/elements/medications") || location.includes("/elements/actions"));
  const dispatch = useDispatch();

  //state
  const [isLoading, setLoading] = useState(false);

  //props
  const {
    categoryType,
    interventionId,
    effectId,
    activeCategory,
    toggle,
    isOpen,
    timePointId
  } = props && props;

  const handleRemove = () => {
    setLoading(true);
    const params = {
      "Action": "Delete",
      "Target": "Effects",
      "InterventionEffectId": effectId
    }

    //Reload Data
    if (pathName) {
      params["ReloadData"] = categoryType === "Action" ? "Actions" : "Medications";
      params["TimePointId"] = timePointId;
    }
    atcDeleteEffects(id, params, activeCategory, interventionId, effectId);
  }

  const atcDeleteEffects = (id, params, idCategory, interventionId, effectId) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: storyboardConstants.DELETE_EFFECTS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.DELETE_EFFECTS_SUCCESS,
          payload: data,
          InterventionId: interventionId,
          idCategory: idCategory,
          effectId: effectId
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.DELETE_EFFECTS_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that <br /> you want to delete this effect?
        </h4>
        <p className="mt-2 text-center">
          This action is not reversible.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteEffects;
