import React from "react";

import { PastNarrativesBox } from '.';

function History(props) {
  //props
  const { handleWidthTabs, caseData } = props && props;
  const PastNarratives = caseData?.data?.Case?.PastNarratives;

  return (
    <React.Fragment>
      {PastNarratives?.length > 0 &&
        <PastNarrativesBox
          {...props}
          handleWidthTabs={handleWidthTabs}
        />
      }
    </React.Fragment>
  );
}

export default History;
