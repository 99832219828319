import React, { useState } from "react";
import styled from "styled-components";
import { ChatFreeText, InputFreeText } from "@components/EngageApp/FreeText";
import _ from "lodash";
import { apiCaller } from "@utils";

const EngageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    overflow: hidden;
`;

const ChatWrapper = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const InputWrapper = styled.div`
    flex-shrink: 0;
`;

function EngageFreeText({ resChatData, setData, isStreaming, messagesEndRef, intervalIDRef, contentStream, setStream, handleStreamData }) {
    const [loading, setLoading] = useState(false); // Add loading state

    // Submit FreeText
    const handleInput = (value) => {
        if (value) {
            setLoading(true); // Set loading to true

            const UserMessage = {
                "role": "user",
                "content": value
            }
            let cloneData = _.cloneDeep(resChatData);
            cloneData.dialog?.push(UserMessage);
            setData(cloneData);

            const params = {
                "action_type": "engage_conversation",
                "engage_id": resChatData?.engage_id,
                "model": "gpt-3.5-turbo",
                "data_chat": value
            }
            const chatPromise = apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
                let data = res?.data;
                if (res?.status === 200) {
                    setData(data);

                    messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);

                    setStream(null);
                    clearInterval(intervalIDRef.current);
                    intervalIDRef.current = null;
                } else {
                    console.log("error");
                }
            }).finally(() => {
                setLoading(false); // Set loading to false after API call
            });
            handleStreamData(chatPromise);
        }
    };

    return (
        <EngageContainer>
            <ChatWrapper>
                <ChatFreeText
                    data={resChatData || []}
                    messagesEndRef={messagesEndRef}
                    contentStream={contentStream}
                    isLoading={loading}
                />
            </ChatWrapper>
            {!resChatData?.is_end && (
                <InputWrapper>
                    <InputFreeText
                        handleInput={handleInput}
                        isStreaming={isStreaming}
                        disabled={loading} // Disable input while loading
                    />
                </InputWrapper>
            )}
        </EngageContainer>
    );
}

export default EngageFreeText;
