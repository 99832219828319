import React from "react";

//reactstrap
import { Input, FormGroup, Col, Progress, Label } from "reactstrap";

function UserFilter(props) {
  const { data, listAcc, handleChange } = props && props;

  //Check Color
  const colorProgress = (value) => {
    if (value >= 75 && value <= 95) {
      return "warning";
    } else if (value > 95) {
      return "danger";
    } else {
      return "success";
    }
  };

  const totalAcc = listAcc && listAcc.length;
  const maxAcc = data && data.UserLimits && data.UserLimits.MaxAccounts;
  const valueProgress = (totalAcc / maxAcc) * 100;
  return (
    <Col md={3}>
      <div className="Item">
        <Progress
          striped
          color={colorProgress(valueProgress)}
          value={valueProgress}
        >{`${parseFloat(valueProgress)} %`}</Progress>
        <div className="mt-3">
          <p>
            Number of active user:{" "}
            <span className="text-success">{listAcc.length}</span>
          </p>
          <p>
            Max number of active user in subscription:{" "}
            <span className="font-weight-600">{maxAcc || 0}</span>
          </p>
        </div>
      </div>
      <hr />
      <div className="boxGroup">
        <FormGroup>
          <Label for="IsInstructor">User Type:</Label>
          <Input type="select" name="IsInstructor" onChange={handleChange}>
            <option value="">All User</option>
            <option value="No">Learner</option>
            <option value="Yes">Instructor</option>
            <option value="Pending">Instructor (Pending Approval)</option>
          </Input>
        </FormGroup>
      </div>
    </Col>
  );
}

export default UserFilter;
