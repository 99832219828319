import React, { useState } from "react";
import { Icon } from '@components/common'
import Close from '@images/teachV2/close.svg'
import { Modal, ModalBody } from "reactstrap";
import { EmailSharing, PublicSharing } from "@components/teachV2/storyboard/ComponentShare";

const ModalShare = (props) => {
  const { isOpen, toggle } = props && props;
	const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

	const tabsOption = [
    { id: 1, key: "email", label: "Email Sharing" },
    { id: 2, key: "public", label: "Public Sharing" },
  ];

  return (
    <Modal className="modal-share" isOpen={isOpen} toggle={toggle} centered>
      <ModalBody className="modal-share__content">
        <div className="modal-close">
          <div onClick={toggle}>
            <Icon src={Close}/>
          </div>
        </div>
        <div className="modal-share__tab">
          {tabsOption?.map((tab, index) => (
            <div 
              key={index}
              className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
              onClick={() => handleTabChange(tab.id)}
            >
              <p>{tab?.label}</p>
            </div>
          ))}          
        </div>
        <div>
          {activeTab === 1 && (
            <EmailSharing {...props}/>
          )}
          {activeTab === 2 && (
            <PublicSharing {...props}/>
          )}
        </div>
      </ModalBody>
      {/* <ModalBody className="modal-share__noti">
        <p className="modal-share__noti-text">Email sent successfully</p>
      </ModalBody> */}
    </Modal>
  )
}

export default ModalShare
