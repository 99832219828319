import React, { useState, useEffect } from 'react';

//reactstrap
import { TabContent, TabPane } from 'reactstrap';

//lodash
import _ from "lodash";

function PastNarratives(props) {
    const { caseData, heightPatientMedRecord } = props && props;
    const PastNarratives = caseData?.data?.Case?.PastNarratives;
    const indexCurrent = _.findIndex(PastNarratives, 'isCurrent');

    const [activeTab, setActiveTab] = useState(indexCurrent || 0);

    // Load Active
    useEffect(() => {
        setActiveTab(indexCurrent || 0);
    }, [indexCurrent])

    //tabs
    const toggleTabs = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <ul className="tabs-corner border-bt">
                {PastNarratives?.map((item, index) => {
                    return (
                        <li
                            key={index}
                            className={`tabs-corner__item ${parseFloat(activeTab) === index ? 'active' : ''}`}
                            onClick={() => {
                                toggleTabs(index);
                            }}
                        >
                            <span className="tabs-corner__item--text">{item?.TimePointName}</span>
                            <span className="tabs-corner__item--icon icon-next-timepoint-3" />
                        </li>
                    );
                })}
            </ul>
            <div className="isShowScroll" style={{ maxHeight: `${heightPatientMedRecord - 100}px` }}>
                <TabContent activeTab={activeTab} className="p-3">
                    {PastNarratives?.map((item, index) =>
                        <TabPane tabId={index} key={index}>
                            <div dangerouslySetInnerHTML={{ __html: item?.NarrativeText }}></div>
                        </TabPane>
                    )}
                </TabContent>
            </div>
        </React.Fragment>
    );
};
export default PastNarratives;