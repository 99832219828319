import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

const ModalAddCollection = ({...props}) => {
  const { toggle, isOpen, handleCollection} = props && props;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm();

  const onsubmit = (data) => {
    const params = {
      Action: "CreateCollection",
      TemplateName: data.Title,
    };
    handleCollection(params);

    //reset Data
    reset({ keepIsValid: false, keepValues: false });
    setValue("Title", '');
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Collection</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label htmlFor="Title">Title: <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Title"
              {...register("Title", { required: true })}
              onChange={(e) => {
                setValue("Title", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("Title")}
              invalid={errors.Title?.type === "required"}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback invalid={String(errors.Title?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button 
            color="primary"
            onClick={handleSubmit(onsubmit)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddCollection;