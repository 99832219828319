import React from "react";

//reactstrap
import { Badge, UncontrolledTooltip } from "reactstrap";

//lodash
import _ from 'lodash';

//truncate
import { truncate } from "@utils";

function BadgeScoreStudy(props) {
    const { listDomain, toggleScore, timepointData, itemRow } = props && props;
    const timepointCurrent = timepointData?.data;
    const timeOfTimePoint = timepointCurrent?.Time;
    return (
        <React.Fragment>
            <div>
                {listDomain?.map((item, index) => {
                    const numberScore = parseFloat(item.Score);
                    const itemScore = item;
                    const timingCondition = item?.TimingCondition;
                    const timingValue = item?.TimingValue;
                    const conditionNotValue = (_.isUndefined(timingValue) && _.isUndefined(timingCondition));
                    const conditionMatchValue = (timingCondition === 'At' && (timingValue === timeOfTimePoint));
                    return (
                        <React.Fragment key={index}>
                            {(conditionNotValue || conditionMatchValue) &&
                                <React.Fragment>
                                    <Badge
                                        style={{ cursor: "pointer" }}
                                        className="p-1 mb-1 mx-1"
                                        color={numberScore >= 0 ? "success" : "danger"}
                                        key={index}
                                        onClick={() =>
                                            toggleScore(itemRow, "edit-score", "Study", itemScore)
                                        }
                                        id={`tooltipIntervention_${item?.DomainId}`}
                                    >
                                        <span className="font-weight-500">
                                            {truncate(item?.DomainName, 12)}
                                        </span>
                                        &nbsp;
                                        <strong>({numberScore})</strong>
                                        {typeof timingValue != "undefined" &&
                                            typeof timingCondition != "undefined" &&
                                            `(*)`}
                                    </Badge>
                                    <UncontrolledTooltip
                                        placement="bottom"
                                        target={`tooltipIntervention_${item?.DomainId}`}
                                    >
                                        {item?.DomainName}
                                    </UncontrolledTooltip>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    );
                })}
            </div>
            <div
                style={{ cursor: "pointer" }}
                className="m--font-info font-weight-500"
                data-id={itemRow?.Id || ''}
                onClick={() => toggleScore(itemRow, "add-score", "Study", {})}
            >
                <span className="icon-add icon-size-2x"></span>
            </div>
        </React.Fragment>
    );
}

export default BadgeScoreStudy;