import React, { useState, useEffect } from 'react';

//components
import {
    ButtonRemove,
    ButtonEdit,
} from '../../schemav2';

function Body(props) {
    // props
    const { rowData, data, toggleDel, toggleEditHeader, toggleEdit } = props && props;

    //state
    const [stateRow, setRow] = useState(rowData || []);
    const [dataWrap, setDataWrap] = useState(data || {});

    // load state
    useEffect(() => {
        setRow(rowData || []);
        setDataWrap(data || {});
    }, [data, rowData]);

    const extractContent = (s) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    return (
        <React.Fragment>
            {stateRow?.map((row, rowIndex) => {
                const dataConcepts = row?.Concepts;
                return (
                    <tbody key={rowIndex}>
                        <tr style={{ backgroundColor: 'rgb(242, 242, 242)' }}>
                            <td colSpan={dataWrap?.length + 1} className="--text-primary font-weight-500">{row?.Category}</td>
                        </tr>
                        {dataConcepts?.map((item, colIndex) => {
                            const isCommentsNotNull = extractContent(item?.comments);
                            return (
                                <tr key={colIndex}>
                                    {/* Name */}
                                    <td className={`align-middle text-left font-weight-500 actionConcept ${isCommentsNotNull ? "tooltipContent" : ""}`}>
                                        {item?.name}
                                        <ButtonRemove
                                            item={item}
                                            toggleRemove={toggleDel}
                                            type="DeleteConcept"
                                        />
                                        <ButtonEdit
                                            item={item}
                                            toggleEditHeader={toggleEditHeader}
                                            type="EditHeader"
                                        />
                                        {isCommentsNotNull && (
                                            <div className={`detail-tooltip`}
                                                dangerouslySetInnerHTML={{ __html: item?.comments }}
                                            />
                                        )}
                                    </td>
                                    {/* Add By Column */}
                                    {dataWrap?.map((col, j) => {
                                        const question_concept_id = col?.question_concept_id;
                                        const itemCurrent = col?.relevant_features_by_category[rowIndex]?.Concepts[colIndex]; //Filter By Category and Index Cloumn
                                        const relationValue = itemCurrent?.relation;
                                        const description = relationValue?.description;
                                        const typeRelation = relationValue ? 'Edit' : 'Add';
                                        let item = { ...itemCurrent }
                                        let allData = { typeRelation, col, item }
                                        const commentsCurrent = extractContent(relationValue?.comments);
                                        return (
                                            <td
                                                key={j}
                                                className={`py-4 align-middle text-center actionConcept ${commentsCurrent !== "undefined" && commentsCurrent !== "" ? "tooltipContent" : ""}`}
                                                question-concept-id={question_concept_id}
                                            >
                                                <div onClick={() => toggleEdit(allData)}>
                                                    {relationValue ? description : <i style={{ cursor: 'pointer' }} className="fa fa-plus text-muted opacity-5" aria-hidden="true"></i>}
                                                </div>
                                                {relationValue && (
                                                    <ButtonRemove
                                                        item={itemCurrent}
                                                        toggleRemove={toggleDel}
                                                        type="DeleteConceptRelation"
                                                    />
                                                )}
                                                {commentsCurrent !== "" && (
                                                    <div className={`detail-tooltip`}
                                                        dangerouslySetInnerHTML={{ __html: relationValue?.comments }}
                                                    />
                                                )}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                );
            })}
        </React.Fragment>
    );
};

export default Body;