export const storyboardConstants = {
    TIMLINE_REQUEST: 'TIMLINE_REQUEST',
    TIMLINE_SUCCESS: 'TIMLINE_SUCCESS',
    TIMLINE_FAILURE: 'TIMLINE_FAILURE',

    TIME_POINT_REQUEST: 'TIME_POINT_REQUEST',
    TIME_POINT_SUCCESS: 'TIME_POINT_SUCCESS',
    TIME_POINT_FAILURE: 'TIME_POINT_FAILURE',

    UPDATE_POINT_REQUEST: 'UPDATE_POINT_REQUEST',
    UPDATE_POINT_SUCCESS: 'UPDATE_POINT_SUCCESS',
    UPDATE_POINT_FAILURE: 'UPDATE_POINT_FAILURE',

    //Xoa Sau khi xong phan Tech Moi
    UPDATE_STATUS_TIMEPOINT_REQUEST: 'UPDATE_STATUS_TIMEPOINT_REQUEST',
    UPDATE_STATUS_TIMEPOINT_SUCCESS: 'UPDATE_STATUS_TIMEPOINT_SUCCESS',
    UPDATE_STATUS_TIMEPOINT_FAILURE: 'UPDATE_STATUS_TIMEPOINT_FAILURE',

    UPDATE_STATUS_TAKS_ELEMENTS_REQUEST: 'UPDATE_STATUS_TAKS_ELEMENTS_REQUEST',
    UPDATE_STATUS_TAKS_ELEMENTS_SUCCESS: 'UPDATE_STATUS_TAKS_ELEMENTS_SUCCESS',
    UPDATE_STATUS_TAKS_ELEMENTS_FAILURE: 'UPDATE_STATUS_TAKS_ELEMENTS_FAILURE',

    CREATE_TIMEPOINT_REQUEST: 'CREATE_TIMEPOINT_REQUEST',
    CREATE_TIMEPOINT_SUCCESS: 'CREATE_TIMEPOINT_SUCCESS',
    CREATE_TIMEPOINT_FAILURE: 'CREATE_TIMEPOINT_FAILURE',

    UPDATE_TIMEPOINT_REQUEST: 'UPDATE_TIMEPOINT_REQUEST',
    UPDATE_TIMEPOINT_SUCCESS: 'UPDATE_TIMEPOINT_SUCCESS',
    UPDATE_TIMEPOINT_FAILURE: 'UPDATE_TIMEPOINT_FAILURE',

    DELETE_TIMEPOINT_REQUEST: 'DELETE_TIMEPOINT_REQUEST',
    DELETE_TIMEPOINT_SUCCESS: 'DELETE_TIMEPOINT_SUCCESS',
    DELETE_TIMEPOINT_FAILURE: 'DELETE_TIMEPOINT_FAILURE',

    UPLOAD_EXAM_PROFILE_REQUEST: 'UPLOAD_EXAM_PROFILE_REQUEST',
    UPLOAD_EXAM_PROFILE_SUCCESS: 'UPLOAD_EXAM_PROFILE_SUCCESS',
    UPLOAD_EXAM_PROFILE_FAILURE: 'UPLOAD_EXAM_PROFILE_FAILURE',

    UPDATE_EXAM_POINT_REQUEST: 'UPDATE_EXAM_POINT_REQUEST',
    UPDATE_EXAM_POINT_SUCCESS: 'UPDATE_EXAM_POINT_SUCCESS',
    UPDATE_EXAM_POINT_FAILURE: 'UPDATE_EXAM_POINT_FAILURE',

    LOAD_LABS_REQUEST: 'LOAD_LABS_REQUEST',
    LOAD_LABS_SUCCESS: 'LOAD_LABS_SUCCESS',
    LOAD_LABS_FAILURE: 'LOAD_LABS_FAILURE',

    SORT_LAB_ITEM_REQUEST: 'SORT_LAB_ITEM_REQUEST',
    SORT_LAB_ITEM_SUCCESS: 'SORT_LAB_ITEM_SUCCESS',
    SORT_LAB_ITEM_FAILURE: 'SORT_LAB_ITEM_FAILURE',

    LOAD_STUDIES_REQUEST: 'LOAD_STUDIES_REQUEST',
    LOAD_STUDIES_SUCCESS: 'LOAD_STUDIES_SUCCESS',
    LOAD_STUDIES_FAILURE: 'LOAD_STUDIES_FAILURE',

    UPDATE_STUDIES_REQUEST: 'UPDATE_STUDIES_REQUEST',
    UPDATE_STUDIES_SUCCESS: 'UPDATE_STUDIES_SUCCESS',
    UPDATE_STUDIES_FAILURE: 'UPDATE_STUDIES_FAILURE',

    CREATE_STUDIES_REQUEST: 'CREATE_STUDIES_REQUEST',
    CREATE_STUDIES_SUCCESS: 'CREATE_STUDIES_SUCCESS',
    CREATE_STUDIES_FAILURE: 'CREATE_STUDIES_FAILURE',

    DELETE_STUDIES_REQUEST: 'DELETE_STUDIES_REQUEST',
    DELETE_STUDIES_SUCCESS: 'DELETE_STUDIES_SUCCESS',
    DELETE_STUDIES_FAILURE: 'DELETE_STUDIES_FAILURE',

    LOAD_ACTIONS_TO_ACCORDION_REQUEST: 'LOAD_ACTIONS_TO_ACCORDION_REQUEST',
    LOAD_ACTIONS_TO_ACCORDION_SUCCESS: 'LOAD_ACTIONS_TO_ACCORDION_SUCCESS',
    LOAD_ACTIONS_TO_ACCORDION_FAILURE: 'LOAD_ACTIONS_TO_ACCORDION_FAILURE',

    UPDATE_EFFECT_REQUEST: 'UPDATE_EFFECT_REQUEST',
    UPDATE_EFFECT_SUCCESS: 'UPDATE_EFFECT_SUCCESS',
    UPDATE_EFFECT_FAILURE: 'UPDATE_EFFECT_FAILURE',

    DELETE_EFFECTS_REQUEST: 'DELETE_EFFECT_REQUEST',
    DELETE_EFFECTS_SUCCESS: 'DELETE_EFFECT_SUCCESS',
    DELETE_EFFECTS_FAILURE: 'DELETE_EFFECT_FAILURE',

    CREATE_INTERVENTION_FOR_MEDICATIONS_REQUEST: 'CREATE_INTERVENTION_FOR_MEDICATIONS_REQUEST',
    CREATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS: 'CREATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS',
    CREATE_INTERVENTION_FOR_MEDICATIONS_FAILURE: 'CREATE_INTERVENTION_FOR_MEDICATIONS_FAILURE',

    UPDATE_INTERVENTION_FOR_MEDICATIONS_REQUEST: 'UPDATE_INTERVENTION_FOR_MEDICATIONS_REQUEST',
    UPDATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS: 'UPDATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS',
    UPDATE_INTERVENTION_FOR_MEDICATIONS_FAILURE: 'UPDATE_INTERVENTION_FOR_MEDICATIONS_FAILURE',

    DELETE_INTERVENTION_REQUEST: 'DELETE_INTERVENTION_REQUEST',
    DELETE_INTERVENTION_SUCCESS: 'DELETE_INTERVENTION_SUCCESS',
    DELETE_INTERVENTION_FAILURE: 'DELETE_INTERVENTION_FAILURE',

    ATTRIBUTESF_OR_EFFECTS_REQUEST: 'ATTRIBUTESF_OR_EFFECTS_REQUEST',
    ATTRIBUTESF_OR_EFFECTS_SUCCESS: 'ATTRIBUTESF_OR_EFFECTS_SUCCESS',
    ATTRIBUTESF_OR_EFFECTS_FAILURE: 'ATTRIBUTESF_OR_EFFECTS_FAILURE',

    CREATE_EFFECTS_REQUEST: 'CREATE_EFFECTS_REQUEST',
    CREATE_EFFECTS_SUCCESS: 'CREATE_EFFECTS_SUCCESS',
    CREATE_EFFECTS_FAILURE: 'CREATE_EFFECTS_FAILURE',

    VITAL_SIGN_CHANGE_REQUEST: 'VITAL_SIGN_CHANGE_REQUEST',
    VITAL_SIGN_CHANGE_SUCCESS: 'VITAL_SIGN_CHANGE_SUCCESS',
    VITAL_SIGN_CHANGE_FAILURE: 'VITAL_SIGN_CHANGE_FAILURE',

    CREATE_VITAL_SIGN_REQUEST: 'CREATE_VITAL_SIGN_REQUEST',
    CREATE_VITAL_SIGN_SUCCESS: 'CREATE_VITAL_SIGN_SUCCESS',
    CREATE_VITAL_SIGN_FAILURE: 'CREATE_VITAL_SIGN_FAILURE',

    DELETE_VITAL_SIGN_REQUEST: 'DELETE_VITAL_SIGN_REQUEST',
    DELETE_VITAL_SIGN_SUCCESS: 'DELETE_VITAL_SIGN_SUCCESS',
    DELETE_VITAL_SIGN_FAILURE: 'DELETE_VITAL_SIGN_FAILURE',

    LAB_CHANGE_REQUEST: 'LAB_CHANGE_REQUEST',
    LAB_CHANGE_SUCCESS: 'LAB_CHANGE_SUCCESS',
    LAB_CHANGE_FAILURE: 'LAB_CHANGE_FAILURE',

    UDPATE_STUDY_CHANGE_REQUEST: 'UDPATE_STUDY_CHANGE_REQUEST',
    UDPATE_STUDY_CHANGE_SUCCESS: 'UDPATE_STUDY_CHANGE_SUCCESS',
    UDPATE_STUDY_CHANGE_FAILURE: 'UDPATE_STUDY_CHANGE_FAILURE',

    UPDATE_STUDY_AVAILABILITY_IN_BATCH_REQUEST: 'UPDATE_STUDY_AVAILABILITY_IN_BATCH_REQUEST',
    UPDATE_STUDY_AVAILABILITY_IN_BATCH_SUCCESS: 'UPDATE_STUDY_AVAILABILITY_IN_BATCH_SUCCESS',
    UPDATE_STUDY_AVAILABILITY_IN_BATCH_FAILURE: 'UPDATE_STUDY_AVAILABILITY_IN_BATCH_FAILURE',

    UPDATE_COMMON_AVAILABILITY_REQUEST: 'UPDATE_COMMON_AVAILABILITY_REQUEST',
    UPDATE_COMMON_AVAILABILITY_SUCCESS: 'UPDATE_COMMON_AVAILABILITY_SUCCESS',
    UPDATE_COMMON_AVAILABILITY_FAILURE: 'UPDATE_COMMON_AVAILABILITY_FAILURE',

    UPDATE_LAB_PANEL_AVAILABILITY_REQUEST: 'UPDATE_LAB_PANEL_AVAILABILITY_REQUEST',
    UPDATE_LAB_PANEL_AVAILABILITY_SUCCESS: 'UPDATE_LAB_PANEL_AVAILABILITY_SUCCESS',
    UPDATE_LAB_PANEL_AVAILABILITY_FAILURE: 'UPDATE_LAB_PANEL_AVAILABILITY_FAILURE',

    UPDATE_LAB_ITEM_AVAILABILITY_REQUEST: 'UPDATE_LAB_ITEM_AVAILABILITY_REQUEST',
    UPDATE_LAB_ITEM_AVAILABILITY_SUCCESS: 'UPDATE_LAB_ITEM_AVAILABILITY_SUCCESS',
    UPDATE_LAB_ITEM_AVAILABILITY_FAILURE: 'UPDATE_LAB_ITEM_AVAILABILITY_FAILURE',

    UPDATE_LAB_AVAILABILITY_IN_BATCH_REQUEST: 'UPDATE_LAB_AVAILABILITY_IN_BATCH_REQUEST',
    UPDATE_LAB_AVAILABILITY_IN_BATCH_SUCCESS: 'UPDATE_LAB_AVAILABILITY_IN_BATCH_SUCCESS',
    UPDATE_LAB_AVAILABILITY_IN_BATCH_FAILURE: 'UPDATE_LAB_AVAILABILITY_IN_BATCH_FAILURE',

    UPDATE_TIMEPOINT_EXAM_FINDING_REQUEST: 'UPDATE_TIMEPOINT_EXAM_FINDING_REQUEST',
    UPDATE_TIMEPOINT_EXAM_FINDING_SUCCESS: 'UPDATE_TIMEPOINT_EXAM_FINDING_SUCCESS',
    UPDATE_TIMEPOINT_EXAM_FINDING_FAILURE: 'UPDATE_TIMEPOINT_EXAM_FINDING_FAILURE',

    CREATE_TIMEPOINT_EXAM_FINDING_REQUEST: 'CREATE_TIMEPOINT_EXAM_FINDING_REQUEST',
    CREATE_TIMEPOINT_EXAM_FINDING_SUCCESS: 'CREATE_TIMEPOINT_EXAM_FINDING_SUCCESS',
    CREATE_TIMEPOINT_EXAM_FINDING_FAILURE: 'CREATE_TIMEPOINT_EXAM_FINDING_FAILURE',

    LOAD_EXAM_FINDINGS_ID_REQUEST: 'LOAD_EXAM_FINDINGS_ID_REQUEST',
    LOAD_EXAM_FINDINGS_ID_SUCCESS: 'LOAD_EXAM_FINDINGS_ID_SUCCESS',
    LOAD_EXAM_FINDINGS_ID_FAILURE: 'LOAD_EXAM_FINDINGS_ID_FAILURE',

    DELETE_EXAM_FINDINGS_ID_REQUEST: 'DELETE_EXAM_FINDINGS_ID_REQUEST',
    DELETE_EXAM_FINDINGS_ID_SUCCESS: 'DELETE_EXAM_FINDINGS_ID_SUCCESS',
    DELETE_EXAM_FINDINGS_ID_FAILURE: 'DELETE_EXAM_FINDINGS_ID_FAILURE',

    CREATE_LAB_PANEL_REQUEST: 'CREATE_LAB_PANEL_REQUEST',
    CREATE_LAB_PANEL_SUCCESS: 'CREATE_LAB_PANEL_SUCCESS',
    CREATE_LAB_PANEL_FAILURE: 'CREATE_LAB_PANEL_FAILURE',

    UPDATE_LAB_PANEL_REQUEST: 'UPDATE_LAB_PANEL_REQUEST',
    UPDATE_LAB_PANEL_SUCCESS: 'UPDATE_LAB_PANEL_SUCCESS',
    UPDATE_LAB_PANEL_FAILURE: 'UPDATE_LAB_PANEL_FAILURE',

    DELETE_LAB_PANEL_REQUEST: 'DELETE_LAB_PANEL_REQUEST',
    DELETE_LAB_PANEL_SUCCESS: 'DELETE_LAB_PANEL_SUCCESS',
    DELETE_LAB_PANEL_FAILURE: 'DELETE_LAB_PANEL_FAILURE',

    UPDATE_LAB_ITEM_REQUEST: 'UPDATE_LAB_ITEM_REQUEST',
    UPDATE_LAB_ITEM_SUCCESS: 'UPDATE_LAB_ITEM_SUCCESS',
    UPDATE_LAB_ITEM_FAILURE: 'UPDATE_LAB_ITEM_FAILURE',

    CREATE_LAB_ITEM_REQUEST: 'CREATE_LAB_ITEM_REQUEST',
    CREATE_LAB_ITEM_SUCCESS: 'CREATE_LAB_ITEM_SUCCESS',
    CREATE_LAB_ITEM_FAILURE: 'CREATE_LAB_ITEM_FAILURE',

    DELETE_LAB_ITEM_REQUEST: 'DELETE_LAB_ITEM_REQUEST',
    DELETE_LAB_ITEM_SUCCESS: 'DELETE_LAB_ITEM_SUCCESS',
    DELETE_LAB_ITEM_FAILURE: 'DELETE_LAB_ITEM_FAILURE',

    UPDATE_ATTRIBUTE_SCORE_LAB_REQUEST: 'UPDATE_ATTRIBUTE_SCORE_LAB_REQUEST',
    UPDATE_ATTRIBUTE_SCORE_LAB_SUCCESS: 'UPDATE_ATTRIBUTE_SCORE_LAB_SUCCESS',
    UPDATE_ATTRIBUTE_SCORE_LAB_FAILURE: 'UPDATE_ATTRIBUTE_SCORE_LAB_FAILURE',

    UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_REQUEST: 'UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_REQUEST',
    UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_SUCCESS: 'UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_SUCCESS',
    UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_FAILURE: 'UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_FAILURE',

    UPDATE_ATTRIBUTE_SCORE_STUDY_REQUEST: 'UPDATE_ATTRIBUTE_SCORE_STUDY_REQUEST',
    UPDATE_ATTRIBUTE_SCORE_STUDY_SUCCESS: 'UPDATE_ATTRIBUTE_SCORE_STUDY_SUCCESS',
    UPDATE_ATTRIBUTE_SCORE_STUDY_FAILURE: 'UPDATE_ATTRIBUTE_SCORE_STUDY_FAILURE',

    LOAD_CHAT_REQUEST: 'LOAD_CHAT_REQUEST',
    LOAD_CHAT_SUCCESS: 'LOAD_CHAT_SUCCESS',
    LOAD_CHAT_FAILURE: 'LOAD_CHAT_FAILURE',

    REMOVE_CHAT_REQUEST: 'REMOVE_CHAT_REQUEST',
    REMOVE_CHAT_SUCCESS: 'REMOVE_CHAT_SUCCESS',
    REMOVE_CHAT_FAILURE: 'REMOVE_CHAT_FAILURE',

    ADD_CHAT_REQUEST: 'ADD_CHAT_REQUEST',
    ADD_CHAT_SUCCESS: 'ADD_CHAT_SUCCESS',
    ADD_CHAT_FAILURE: 'ADD_CHAT_FAILURE',

    EDIT_CHAT_REQUEST: 'EDIT_CHAT_REQUEST',
    EDIT_CHAT_SUCCESS: 'EDIT_CHAT_SUCCESS',
    EDIT_CHAT_FAILURE: 'EDIT_CHAT_FAILURE',

    CREATE_INTERVENTION_FOR_ACTIONS_REQUEST: 'CREATE_INTERVENTION_FOR_ACTIONS_REQUEST',
    CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    CREATE_INTERVENTION_FOR_ACTIONS_FAILURE: 'CREATE_INTERVENTION_FOR_ACTIONS_FAILURE',

    UPDATE_INTERVENTION_FOR_ACTIONS_REQUEST: 'UPDATE_INTERVENTION_FOR_ACTIONS_REQUEST',
    UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    UPDATE_INTERVENTION_FOR_ACTIONS_FAILURE: 'UPDATE_INTERVENTION_FOR_ACTIONS_FAILURE',

    DELETE_INTERVENTION_FOR_ACTIONS_REQUEST: 'DELETE_INTERVENTION_FOR_ACTIONS_REQUEST',
    DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS: 'DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS',
    DELETE_INTERVENTION_FOR_ACTIONS_FAILURE: 'DELETE_INTERVENTION_FOR_ACTIONS_FAILURE',

    UPDATE_AVAILABILITY_FOR_ACTIONS_REQUEST: 'UPDATE_AVAILABILITY_FOR_ACTIONS_REQUEST',
    UPDATE_AVAILABILITY_FOR_ACTIONS_SUCCESS: 'UPDATE_AVAILABILITY_FOR_ACTIONS_SUCCESS',
    UPDATE_AVAILABILITY_FOR_ACTIONS_FAILURE: 'UPDATE_AVAILABILITY_FOR_ACTIONS_FAILURE',

    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_REQUEST: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_REQUEST',
    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_SUCCESS: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_SUCCESS',
    UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_FAILURE: 'UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_FAILURE',

    DELETE_EXAM_POINT_REQUEST: 'DELETE_EXAM_POINT_REQUEST',
    DELETE_EXAM_POINT_SUCCESS: 'DELETE_EXAM_POINT_SUCCESS',
    DELETE_EXAM_POINT_FAILURE: 'DELETE_EXAM_POINT_FAILURE',

    CREATE_EXAM_POINT_REQUEST: 'CREATE_EXAM_POINT_REQUEST',
    CREATE_EXAM_POINT_SUCCESS: 'CREATE_EXAM_POINT_SUCCESS',
    CREATE_EXAM_POINT_FAILURE: 'CREATE_EXAM_POINT_FAILURE',

    UPDATE_CHANGE_MODE_CHAT_REQUEST: 'UPDATE_CHANGE_MODE_CHAT_REQUEST',
    UPDATE_CHANGE_MODE_CHAT_SUCCESS: 'UPDATE_CHANGE_MODE_CHAT_SUCCESS',
    UPDATE_CHANGE_MODE_CHAT_FAILURE: 'UPDATE_CHANGE_MODE_CHAT_FAILURE',

    UPDATE_MAX_CHAT_QUESTION_REQUEST: 'UPDATE_MAX_CHAT_QUESTION_REQUEST',
    UPDATE_MAX_CHAT_QUESTION_SUCCESS: 'UPDATE_MAX_CHAT_QUESTION_SUCCESS',
    UPDATE_MAX_CHAT_QUESTION_FAILURE: 'UPDATE_MAX_CHAT_QUESTION_FAILURE',

    UPDATE_NARRATIVE_CONTENT_REQUEST: 'UPDATE_NARRATIVE_CONTENT_REQUEST',
    UPDATE_NARRATIVE_CONTENT_SUCCESS: 'UPDATE_NARRATIVE_CONTENT_SUCCESS',
    UPDATE_NARRATIVE_CONTENT_FAILURE: 'UPDATE_NARRATIVE_CONTENT_FAILURE',

    PUT_DOCUMENTATION_NOTE_REQUEST: 'PUT_DOCUMENTATION_NOTE_REQUEST',
    PUT_DOCUMENTATION_NOTE_SUCCESS: 'PUT_DOCUMENTATION_NOTE_SUCCESS',
    PUT_DOCUMENTATION_NOTE_FAILURE: 'PUT_DOCUMENTATION_NOTE_FAILURE',

    UDPATE_VALUE_VITAL_REQUEST: 'UDPATE_VALUE_VITAL_REQUEST',
    UDPATE_VALUE_VITAL_SUCCESS: 'UDPATE_VALUE_VITAL_SUCCESS',
    UDPATE_VALUE_VITAL_FAILURE: 'UDPATE_VALUE_VITAL_FAILURE',

    CREATE_VITAL_REQUEST: 'CREATE_VITAL_REQUEST',
    CREATE_VITAL_SUCCESS: 'CREATE_VITAL_SUCCESS',
    CREATE_VITAL_FAILURE: 'CREATE_VITAL_FAILURE',

    CHAT_SETTINGS_REQUEST: 'CHAT_SETTINGS_REQUEST',
    CHAT_SETTINGS_SUCCESS: 'CHAT_SETTINGS_SUCCESS',
    CHAT_SETTINGS_FAILURE: 'CHAT_SETTINGS_FAILURE',

    UPDATE_DOCUMENTATION_TEMPLATE_REQUEST: 'UPDATE_DOCUMENTATION_TEMPLATE_REQUEST',
    UPDATE_DOCUMENTATION_TEMPLATE_SUCCESS: 'UPDATE_DOCUMENTATION_TEMPLATE_SUCCESS',
    UPDATE_DOCUMENTATION_TEMPLATE_FAILURE: 'UPDATE_DOCUMENTATION_TEMPLATE_FAILURE',

    EXTRACT_VITAL_SIGNS_REQUEST: 'EXTRACT_VITAL_SIGNS_REQUEST',
    EXTRACT_VITAL_SIGNS_SUCCESS: 'EXTRACT_VITAL_SIGNS_SUCCESS',
    EXTRACT_VITAL_SIGNS_FAILURE: 'EXTRACT_VITAL_SIGNS_FAILURE',

    EXTRACT_LABS_REQUEST: 'EXTRACT_LABS_REQUEST',
    EXTRACT_LABS_SUCCESS: 'EXTRACT_LABS_SUCCESS',
    EXTRACT_LABS_FAILURE: 'EXTRACT_LABS_FAILURE',

    EXTRACT_STUDIES_REQUEST: 'EXTRACT_STUDIES_REQUEST',
    EXTRACT_STUDIES_SUCCESS: 'EXTRACT_STUDIES_SUCCESS',
    EXTRACT_STUDIES_FAILURE: 'EXTRACT_STUDIES_FAILURE',
    
    GET_PROMPT_TEMPLATE_REQUEST: 'GET_PROMPT_TEMPLATE_REQUEST',
    GET_PROMPT_TEMPLATE_SUCCESS: 'GET_PROMPT_TEMPLATE_SUCCESS',
    GET_PROMPT_TEMPLATE_FAILURE: 'GET_PROMPT_TEMPLATE_FAILURE',

    UPDATE_PROMPT_TEMPLATE_REQUEST: 'UPDATE_PROMPT_TEMPLATE_REQUEST',
    UPDATE_PROMPT_TEMPLATE_SUCCESS: 'UPDATE_PROMPT_TEMPLATE_SUCCESS',
    UPDATE_PROMPT_TEMPLATE_FAILURE: 'UPDATE_PROMPT_TEMPLATE_FAILURE',
};