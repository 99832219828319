import React, { useEffect, useState } from 'react';

//components
import { QuestionInformation, AnswerAnalyses } from "@components/teachV2/FreeTextQuestion/ResultTextQuestion";
import { useHistory, useLocation } from 'react-router-dom'
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'

const ResultTextWrap = (props) => {
  const { dataAnalyze } = props
  const location = useLocation();
  let history = useHistory();
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (!!location.state?.analysis) {
      setActiveTab(2)
    } 
  }, [location.state?.analysis])
  
  const tabsOption = [
    { id: 1, key: "question", label: "Question Information" },
    { id: 2, key: "answer", label: "Answer Analyses" },
  ] 

  const handleBack = () => {
    if (!!location.state?.listBank) {
      history.push("/teach/inventory/mcq")
    } else {
      history.push("/teach/free-text")
    }
  }

  return (
    <div className="result-wrap">
      <div className="icon-back mb-3" onClick={handleBack}>
        <Icon src={Back}/>
        <span>Question #{dataAnalyze?.QuestionItemId}</span>
      </div>
      <div className='reference-wrap__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && ( 
          <QuestionInformation
            {...props}
          />
        )}
        {activeTab === 2 && (
          <AnswerAnalyses
            isBank={location.state?.listBank}
            {...props}
          />
        )}
      </div>
    </div>
  )
}

export default ResultTextWrap