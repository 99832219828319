import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//InteractiveChat
import {
    ChatContents,
    Instructions,
    Questions,
    ScoringInstruction,
    SettingsForm,
    GenerativeAI
} from "./InteractiveChat";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const InteractiveChatMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Chat Contents", component: <ChatContents {...props} /> },
        { key: 2, label: "Instructions", component: <Instructions {...props} /> },
        { key: 3, label: "Questions", component: <Questions {...props} /> },
        { key: 4, label: "Scoring Instruction", component: <ScoringInstruction {...props} /> },
        { key: 5, label: "Settings", component: <SettingsForm {...props} {...props} /> },
        { key: 6, label: "Generative AI", component: <GenerativeAI {...props} {...props}  /> }
    ];

    // Handle Change Tab
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    // Status tabs
    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.component
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(3); // 3 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);
    
    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Interactive Chat`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default InteractiveChatMain;