import React from 'react';

//components
import { FormSearch } from '../tabs';

function GeneralTab(props) {
    return (
        <FormSearch {...props} typeTab="general" />
    );
};

export default GeneralTab;