import React from 'react';

//boostrap
import { Card, CardBody, Label, FormGroup, Input, Form } from "reactstrap";

function ModeTop(props) {
    const { handeleChangeStatus, isStatus } = props && props;
    return (
        <Card className="border-0 mb-4">
            <CardBody className="-box-shadow">
                <h2 className="heading-5 font-weight-500 --text-primary mt-0">Team Assignment Mode</h2>
                <Form>
                    <FormGroup
                        check
                        inline
                    >
                        <Label className="d-flex align-items-center" check>
                            <Input type="radio" name='status' value={false} defaultChecked={Boolean(isStatus) === false} onChange={handeleChangeStatus} />
                            Open Registration
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Label className="d-flex align-items-center" check>
                            <Input type="radio" name='status' value={true} defaultChecked={Boolean(isStatus) === true} onChange={handeleChangeStatus} />
                            Manual Assignment
                        </Label>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
};

export default ModeTop;