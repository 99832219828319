import React, { useState, useEffect } from "react";

//reactstrap
import { FormGroup } from "reactstrap";

//lodash
import _ from "lodash";

let atributeTarget = `mark[class*="highlight"]`;

function convertHexColorInline(content) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(content, "text/html");

    //check color
    const isColor = (strColor) => {
        var s = new Option().style;
        s.color = strColor;
        return s.color === strColor;
    }

    doc?.querySelectorAll(atributeTarget).forEach(function (element) {
        const classes = element.className;
        const splitString = classes.split('-');
        const hexColor = splitString && splitString[1];
        const checkColor = isColor(hexColor);
        if (hexColor !== 'sub') {
            element.setAttribute(
                'style',
                `background-color: ${checkColor ? hexColor : `#${hexColor}`};
                color: white;
                display: inline-block;
                padding: 1px 4px;
                border-radius: 4px;
                margin: 5px;`
            );
        }
    });
    const text = doc?.body.innerHTML;
    return text;
}

function HtmlResult(props) {
    //props
    const { data, listConcept } = props && props;

    //state
    const [stateData, setData] = useState((data && data) || {});

    //Load Data
    useEffect(() => {
        setData((data && data) || {});
    }, [data, listConcept]);

    return (
        <div className={`analyzeCt`}>
            <FormGroup>
                {stateData?.FeedbackText && (
                    <div
                        id="htmlContainer"
                        contentEditable
                        dangerouslySetInnerHTML={{ __html: convertHexColorInline(stateData?.FeedbackText ? _.unescape(stateData?.FeedbackText) : '') }}>
                    </div>
                )}
            </FormGroup>
        </div>
    );
}

export default HtmlResult;