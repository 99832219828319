import React, { useState } from "react";

//components
import { FormLogin, FormForGotPass } from "@components/UcrComponent/auth";

//boostrap
import { Container, Row } from "reactstrap";

function LoginUcrPage(props) {
  const [showView, setShowView] = useState(false);
  const handleView = () => {
    setShowView(!showView);
  };
  return (
    <div className="wrapper -loginPartnerPage">
      <div className="loginPartnerContainer">
        <Container>
          <Row className="align-items-center">
            {showView ? (
              <FormForGotPass handleView={handleView} />
            ) : (
              <FormLogin handleView={handleView} />
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LoginUcrPage;