import React from 'react';
import { Card, CardHeader, CardBody, FormGroup, Label, Input } from 'reactstrap';

// Define size configurations
const dropdownSizes = [
    { label: 'Radio Input (16 px)', className: 'react-radio-common' },
    { label: 'Radio Input (24 px)', className: 'react-radio-common react-radio-md' },
];

const RadioInput = () => {
    return (
        <Card className="my-2">
            <CardHeader>Radio Input</CardHeader>
            <CardBody>
                {dropdownSizes.map((size, index) => (
                    <FormGroup check key={index} className='form-common'>
                        <Input className={`${size.className}`} type="radio" name="radio"/>
                        <Label check>{size.label}</Label>
                    </FormGroup>
                ))}
                <FormGroup check className='form-common'>
                    <Input disabled className="react-radio-common react-radio-md disabled" type="radio" name="radio"/>
                    <Label check>Disable</Label>
                </FormGroup>
            </CardBody>
        </Card>
    );
};

export default RadioInput;