import React from 'react';

//reactstrap
import { Container, Row, Col } from 'reactstrap';

//components
import { OverviewProfile, CaseSection, RemindSection, QuoteSection } from "../Home";

function HomeLearnMain(props) {
    return (
        <Container>
            <Row>
                <Col className="custom-padding-x" md={8}>
                    <OverviewProfile {...props} />
                    <QuoteSection {...props} />
                    <CaseSection {...props} />
                </Col>
                <Col className="custom-padding-x" md={4}>
                    {/* <GoogleCalendar {...props} /> */}
                    <RemindSection {...props} />
                </Col>
            </Row>
        </Container>
    );
}

export default HomeLearnMain;