import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { ExamFindings } from "@components/elements";
import { HeadingBox } from "@components/common";

//actions
import { loadExamFindingsElements } from "@actions";

function ExamFindingsPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "ExamFinding",
      };
      dispatch(loadExamFindingsElements(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //storeExamFindings
  const storeExamFindings = useSelector(
    (state) => state?.dataExamFindingsByCaseId || {}
  );

  const listData = storeExamFindings?.data;
  return (
    <div className="wrapper -eExamFindingsPage">
      <section className="examFindingsCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Exam Findings" />
          <ExamFindings storeExamFindings={storeExamFindings} data={(listData && listData) || []} />
        </Container>
      </section>
    </div>
  );
}

export default ExamFindingsPage;
