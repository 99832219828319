import React from "react";

function StatisticsComponent(props) {
  const { data } = props && props;
  const Wilcoxon = data?.Wilcoxon;
  const PValue = parseFloat(Wilcoxon && Wilcoxon[`p-value`]).toFixed(4);
  return (
    <div className="p-4">
      <p className="font-weight-500"><span className="mr-2 --text-primary">p-value:</span><span>{PValue}</span></p>
    </div>
  );
}

export default StatisticsComponent;