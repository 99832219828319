import React from 'react';
import JoditEditor from "jodit-react";

//reactBoostrap
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";

//Config
import * as Config from "@constants/Config";

function QuesionText(props) {
    const { value, onBlurChange, refDiv } = props && props;
    console.log(value, "test...");
    return (
        <FormGroup>
            <Label className="font-weight-500 mb-3" for="QuestionText">
                Question Text <span className="m--font-danger">*</span>
            </Label>
            <JoditEditor
                ref={refDiv}
                value={value}
                config={Config.CONFIG_JODIT}
                placeholder="Enter your text here..."
                tabIndex={1} // tabIndex of textarea
                onBlur={onBlurChange}
            />
            <FormGroup
                check
                className="mt-3"
            >
                <Label check>
                    <Input
                        type="checkbox"
                        // onChange={handleChangeDomains}
                        value={value.Id}
                        name="review_erm"
                        defaultChecked={false}
                    />
                    Review Patient EMR
                </Label>
            </FormGroup>
        </FormGroup>
    );
};

export default QuesionText;