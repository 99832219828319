import React from "react";

//reactstrap
import { FormGroup, Label, Col, Input } from "reactstrap";

//React PLayer
import ReactPlayer from "react-player";

function VideoLink (props) {
  
  const value = props && props.value;
  const onChange = props && props.onChange;

  return (
    <React.Fragment>
      <FormGroup row>
        <Label className="text-left" for="Image" sm={3}>
          Url Youtube
        </Label>
        <Col sm={9}>
          <div className="d-flex align-items-center">
            <Input
              type="url"
              name="VideoLink"
              id="url"
              defaultValue={value || ""}
              onChange={onChange}
              placeholder=""
            />
          </div>
        </Col>
      </FormGroup>
      {value && (
        <div className="playerWrapper">
          <ReactPlayer
            url={value || ""}
            name="VideoLink"
            className="react-player"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default VideoLink;
