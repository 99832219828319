import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";

import {
  Badge,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

//Config
import * as Config from "@constants/Config";

function QuestionPage() {
  const [activeTabCt, setActiveTabCt] = useState("1");
  const [valueEditor3, setValueEditor3] = useState("");
  const editor = useRef(null);
  const [dataSort] = useState([
    {
      id: "1",
      tag: "497",
      name: "What should you do in the next 2-3 minutes?<br/> (select ONE best answer)",
    },
    {
      id: "2",
      tag: "498",
      name: "What should you do in the next 2-3 minutes?<br/> (select ONE best answer)",
    },
    {
      id: "3",
      tag: "499",
      name: "What should you do in the next 2-3 minutes?<br/> (select ONE best answer)",
    },
  ]);

  const toggleTabCt = (tab) => {
    if (activeTabCt !== tab) setActiveTabCt(tab);
  };

  return (
    <div className="wrapper -questionPage" >
      <div className="questionContainer">
        <Container>
          <Nav className="tabBorder" tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTabCt === "1",
                  "--text-primary": activeTabCt === "1",
                  "--border-1-bottom": activeTabCt === "1",
                })}
                onClick={() => {
                  toggleTabCt("1");
                }}
              >
                Contents / Values
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTabCt === "2",
                  "--text-primary": activeTabCt === "2",
                  "--border-1-bottom": activeTabCt === "2",
                })}
                onClick={() => {
                  toggleTabCt("2");
                }}
              >
                Instructions
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTabCt}>
            <TabPane tabId="1">
              <ul className="lsQuestion">
                {dataSort &&
                  dataSort.map((item, index) => (
                    <li className="lsQuestion__item -box-shadow" key={index}>
                      <div className="innerCt">
                        <div className="innerCt__left">
                          <p className="font-weight-bold mb-2">#{item.tag}</p>
                          <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                        </div>
                        <div className="innerCt__right">
                          <Badge className="p-2" href="#" color="info">
                            Reasoning Principles
                          </Badge>
                          <div className="actionsList ml-auto">
                            <a href="# /" className="actionsList__item --edit">
                              <i
                                className="fa fa-pencil-square-o mr-2"
                                aria-hidden="true"
                              ></i>
                              Edit
                            </a>
                            <a href="# /" className="actionsList__item --delete">
                              <i className="fa fa-trash-o mr-2" aria-hidden="true"></i>
                              Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </TabPane>
            <TabPane tabId="2">
              <div className="richText mb-5">
                <h3 className="richText__head mb-3">Instructions</h3>
                <div className="richText__content mt-4">
                  <JoditEditor
                    ref={editor}
                    value={valueEditor3}
                    config={Config.CONFIG_JODIT}
                    placeholder="Enter the instructions for the learner here..."
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setValueEditor3(newContent)}
                  />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </div>
  );
}

export default QuestionPage;
