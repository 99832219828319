import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteExam(props) {
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggleDel;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that you would like<br/> to delete this exam finding?
        </h4>
        <p className="mt-2 text-center">This action is irreversible.</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={props && props.handleRemove}
          //   disabled={checkData && checkData.isLoading}
        >
          {/* {checkData && checkData.isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          } */}
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteExam;
