import React from 'react';

//reactstrap
import { FormGroup, Input, Row, Col, Label } from "reactstrap";

function Filter(props) {
    const { stateData, cohortStore, periodsData, hanldeChange } = props && props;
    return (
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <Label for="CohortId">Cohorts: </Label>
                    <Input
                        className="ml-auto"
                        type="select"
                        name="CohortId"
                        onChange={hanldeChange}
                        defaultValue={stateData.CohortId}
                    >
                        <option value="">All Enrolled Learners</option>
                        {cohortStore?.map((value, index) => (
                            <option key={index} value={value.CohortId}>
                                {value.Name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="PeriodId">Periods:</Label>
                    <Input
                        className="ml-auto"
                        type="select"
                        name="PeriodId"
                        onChange={hanldeChange}
                        defaultValue={stateData.PeriodId}
                    >
                        <option value="">Any Time</option>
                        {periodsData?.map((value, index) => (
                            <option key={index} value={value.PeriodId}>
                                {value.Name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default Filter;