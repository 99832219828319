import React, { useState } from "react";

//boostrap
import { Row, Col } from "reactstrap";

//components
import {
  DescriptionCase,
  ProgressBar
} from "../Heading";

//common
import { ResetAndBackCase } from '@components-common';

//Modal
import { ModalResetCase } from '@components/common/ResetAndBack';

function HeadingBox(props) {
  const {
    currentPage,
    title,
    description,
    progress,
  } = props && props;

  //state
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const currentPageHtml = currentPage
    ? `- <span className="subheader__title--current">${currentPage}</span>`
    : "";
    
  return (
    <div className="m-subheader">
      <Row className="align-items-center">
        <Col md={12}>
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              {title && (
                <h5
                  className="m-subheader__title"
                  dangerouslySetInnerHTML={{
                    __html: `${title} ${currentPageHtml}`,
                  }}
                />
              )}
            </div>
            {progress &&
              <ProgressBar {...props} data={progress} />
            }
            {description && (
              <DescriptionCase data={`You Have Completed This Case !`} />
            )}
            <ResetAndBackCase
              {...props}
              toggle={toggle}
            />
          </div>
        </Col>
      </Row>
      <ModalResetCase
        {...props}
        isOpen={modal}
        toggle={toggle}
        isView="case"
      />
    </div>
  );
}

export default HeadingBox;