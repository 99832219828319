import React from "react";
import { Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

//utils
import { LoaderText } from "@utils";

//redux
import { useSelector } from "react-redux";

//Button
import { AddUserButton, DeleteCohortButton } from '../Cohorts/Button';

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";

//components
import { ActionsHeadingCohort } from "../Cohorts";

function CohortMain(props) {
  const { toggle, columns } = props && props;
  //data Store
  const userStoreByCohortId = useSelector((state) => state.dataLearnersByCohortId || []);
  const dataLearner = userStoreByCohortId?.data;
  const hasData = dataLearner && dataLearner.length > 0;
  return (
    <React.Fragment>
      <ActionsHeadingCohort {...props} />
      <Row>
        <Col sm={12} className="position-relative">
          {userStoreByCohortId?.isLoading ? <div className="d-flex align-items-cente justify-content-center" style={{ height: '400px' }}><LoaderText /></div> : (
            <React.Fragment>
              <ToolkitProvider
                keyField="email"
                data={(dataLearner && dataLearner) || []}
                columns={columns}
                key={JSON.stringify(dataLearner && dataLearner)}
                search
              >
                {(toolkitprops) => (
                  <React.Fragment>
                    {hasData ? (
                      <BootstrapTable
                        wrapperClasses="table-custom-common table-responsive mt-3"
                        bordered={false}
                        {...toolkitprops.baseProps}
                      />
                    ) : (
                      <div
                        className="mt-3"
                        style={{
                          borderRadius: '8px',
                          border: '1px solid #D0D5DD',
                          padding: '16px',
                          backgroundColor: '#fff'
                        }}
                      >
                        <MessageEmtyData
                          title="No Users Added"
                          text="This cohort is empty. Click ‘Add Users’ to select and add users to this cohort."
                          ButtonBottomRender={<AddUserButton {...props} />}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
              <hr />
              {/* {cohortObject && } */}
              <DeleteCohortButton toggle={toggle} />
            </React.Fragment>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default CohortMain;