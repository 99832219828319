import React from 'react';

//components
import { ActionDelete } from '../Chat';

function RowSencond(props) {
    const { Children, handleCollapse, isOpenCollpase, isOpen, item, toggle } = props && props;
    return (
        <tr className="accordion-toggle">
            <td className="align-middle" width="60%" onClick={() => Children?.length > 0 ? handleCollapse(isOpenCollpase) : undefined}>
                {Children?.length > 0 &&
                    <i className={`fa ${isOpen[isOpenCollpase] ? "fa-chevron-up" : "fa-chevron-down"} --text-primary`} aria-hidden="true"></i>
                }
                <span className="ml-2 --text-primary font-weight-500">{item?.Name}</span>
            </td>
            <td onClick={() => toggle('edit', item)}>{item?.StandardResponse || ''}</td>
            <ActionDelete 
                {...props}
            />
        </tr>
    );
};

export default RowSencond;