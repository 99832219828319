import React, { useState } from "react";

//Boostrap
import {
    Label,
    Input,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Nav
} from "reactstrap";

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//classnames
import classnames from "classnames";

function LabelCategoryTabs(props) {
    //props
    const { handleChangeLabel, rowRecord, handleClickCategory } = props && props;

    const grouped_concepts = rowRecord?.grouped_concepts;

    //Convert to array
    let propertyNames = Object.keys(grouped_concepts);
    const [activeTab, setActiveTab] = useState("1");

    //toggle
    const toggleTab = (tab, value) => {
        if (activeTab !== tab) setActiveTab(tab);
        handleClickCategory(value);
    };

    const listRender = (list) => {
        return (
            <Scrollbar className="scrollbarCustom p-3" style={{ height: 300 }}>
                {list?.map((val, index) => (
                    <div className="d-flex align-items-center pl-4 py-3 mb-3 border-bottom border-top boder-right" key={index} style={{ borderLeft: `10px solid rgb(${val?.color})`, paddingLeft: '10px' }}>
                        <Label
                            check
                        >
                            <Input
                                type="radio"
                                name={"label_text"}
                                onClick={() => handleChangeLabel(val)}
                                value={val}
                            />{" "}
                            <span className="mr-2">{val?.concept}</span>
                        </Label>
                        <i
                            className="fa fa-trash text-danger ml-auto"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            aria-hidden="true"
                            onClick={() => { console.log('Demo....') }}
                        ></i>
                    </div>
                ))}
            </Scrollbar>
        );
    }
    
    return (
        <React.Fragment>
            <Nav tabs className="tabBorder" >
                {propertyNames?.map((value, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({
                                active: activeTab === (index + 1).toString(),
                            })}
                            onClick={() => {
                                toggleTab(String(index + 1), value);
                            }}
                        >
                            {value}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {propertyNames?.map((name, index) => (
                    <TabPane className="p-2" key={index} tabId={(index + 1).toString()}>
                        {listRender(grouped_concepts[name])}
                    </TabPane>
                ))}
            </TabContent>
        </React.Fragment>
    );
}

export default LabelCategoryTabs;