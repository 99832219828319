import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContainer, BannerLogin, OtpForm } from "@components/Makansafe";

function OtpPage(props) {
    const { handleSubmit, formState: { errors } } = useForm();
    const [otp, setOtp] = useState(Array(6).fill(""));

    const onSubmit = () => {
        const enteredOtp = otp.join("");
        console.log("Submitted OTP:", enteredOtp);
    };

    return (
        <AuthContainer >
            <div className="col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="form-makansafe-ct">
                    <div className="logo-makansafe__title text-center">
                        <h1>Check your inbox</h1>
                    </div>
                    <div className="description text-center">
                        <p>Check your inbox. We’ve sent you a temporary 6-digit login code at <strong>labc@sach.com</strong>. Please enter this code to login your account.</p>
                    </div>
                    <OtpForm
                        onSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                        otp={otp}
                        setOtp={setOtp}
                    />
                </div>
            </div>
            <BannerLogin {...props} />
        </AuthContainer>
    );
}

export default OtpPage;