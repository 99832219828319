import React from 'react';

//components
import { CaseProcessItem, LevelHeader, ImageAfter } from "../Level";

function LevelSection(props) {
    //props
    const { isLastItem, item } = props && props;
    return (
        <section className="assignments-level mt-4">
            <div className="card-area bg-white">
                <LevelHeader {...props} />
                <div className="case-process">
                    {item?.Cases?.map((item, index, array) => (
                        <CaseProcessItem
                            {...item}
                            key={index}
                            isLastItem={index === array.length - 1}
                        />
                    ))}
                </div>
            </div>
            {!isLastItem && <ImageAfter />}
        </section>
    );
}

export default LevelSection;