import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

//format time
import moment from "moment";

//common
import { DataEmty } from "@components/common";

function FullContentDetails(props) {
  const { data } = props && props;
  const PerformancesData = data && data.Performances;
  const dataTable = PerformancesData && PerformancesData.Details;
  let GradingFields = data && data.GradingFields;

  //state
  const [arrNew, setArrNew] = useState([]);

  let columns = [
    {
      dataField: "Username/Email",
      text: "Username/Email",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
    },
    {
      dataField: "Answer",
      text: "Answer",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
      formatter: (value, row) => {
        return (
          <div
            className="answerText"
            dangerouslySetInnerHTML={{ __html: value }}
          ></div>
        );
      },
      style: {
        width: "350px",
      },
    },
    {
      dataField: "Session Time",
      text: "Session Time",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      editable: false,
      formatter: (value, row) => {
        return <span>{moment(value).format("YYYY-MM-DD")}</span>;
      },
    },
    {
      dataField: "isCorrect",
      text: "Correct",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      editable: false,
      formatter: (value, row) => {
        return (
          <div>
            {value ? (
              <span className="icon-checkmark-switcher text-success"></span>
            ) : (
              <span className="icon-uncheck-switcher text-danger"></span>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const convertColumnsData = () => {
      const test = GradingFields.map((value) => ({
        dataField: value.Name,
        text: value.Name,
        headerClasses: "align-middle text-left font-weight-500",
        GradingFieldId: value.GradingFieldId,
      }));
      return test;
    };

    //Convert data
    if(GradingFields && GradingFields.length > 0) {
      const newData = convertColumnsData();
      setArrNew(newData);
    }
  }, [GradingFields]);

  const columnsMerge = [...columns, ...arrNew];
  return (
    <BootstrapTable
      keyField="SessionId"
      data={(dataTable && dataTable) || []}
      wrapperClasses="table-responsive"
      columns={columnsMerge}
      noDataIndication={() => <DataEmty content="No Data" />} 
    />
  );
}

export default FullContentDetails;