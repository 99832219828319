import React, { useEffect } from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { NavigationComponent } from "@components/layouts";

//utils
import { LoaderPage, navigation } from "@utils";

//call Action
import { actLoadTicket } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { TableReliDiagnosis } from "@components/ReliDiagnosis";

function ReliDiagnosisPage(props) {
    const dispatch = useDispatch();
    const mainMenu = navigation?.mainDiagnosis;

    useEffect(() => {
        const fetchData = async () => {
            dispatch(actLoadTicket());
        };
        //fetch data
        fetchData();
    }, [dispatch]);

    const dataTicket = useSelector(
        (state) => (state.loadTicket) || []
    );
    
    return (
        <React.Fragment>
            {dataTicket.isLoading && <LoaderPage />}
            {!dataTicket.isLoading && (
                <React.Fragment>
                    <NavigationComponent data={mainMenu} />
                    <div className="wrapper -OnBoardingPage">
                        <Container className="-box-shadow bg-white p-4">
                            <TableReliDiagnosis {...props} />
                        </Container>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ReliDiagnosisPage;