import React, { useState, useEffect } from "react";

//react-hook-form
import { FormProvider, useForm } from "react-hook-form";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { schemaContanst } from "@constants";

//components
import { MainTabsEditHeader } from "./QuestionSchemaTab";

function ModalEditHeaderCol(props) {
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, data } = props && props;
  
  //current edit
  const relationValue = data?.relation;
  const comments = relationValue?.comments;

  //current edit
  const questionConceptId = data?.question_concept_id;
  const questionId = data?.question_id;

  //form
  const {
    setValue,
  } = useForm();

  //state
  const [content, setContent] = useState(comments || '');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setContent(data?.comments || '');
  }, [data, setValue]);

  const onSubmit = (val) => {
    const params = {
      Action: "UpdateQuestionConcept",
      QuestionId: questionId,
      Name: val?.Name,
      QuestionConceptId: questionConceptId,
      Comments: content,
      Score: val?.Score,
      Negation: val?.Negation,
      Tags: data?.tags,
      identification: {
        concepts: val?.concepts,
        regex: val?.regex,
        use_concept: val?.use_concept,
        use_regex: val?.use_regex,
      },
      category: val?.Category
    }
    
    setLoading(true);
    dispatch({ type: schemaContanst.UPDATE_CONCEPT_REQUEST, isLoading: true });
    //Call Api
    apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: schemaContanst.UPDATE_CONCEPT_SUCCESS, payload: data });
        setLoading(false);
        toggle();
      } else {
        dispatch({ type: schemaContanst.UPDATE_CONCEPT_FAILURE, error: "error" });
        setLoading(false);
        toggle();
      }
    })
  };

  const methods = useForm();
  return (
    <Modal
      style={{ maxWidth: "800px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">{data?.name}</span>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods} >
          <MainTabsEditHeader
            {...props}
            handleSetContent={newContent => setContent(newContent)}
            stateComment={content}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button
          className="d-flex align-items-center"
          color="primary"
          onClick={methods?.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          Save
        </Button>
        <Button onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalEditHeaderCol;