import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 100px;
  .indicator-progress {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .progressBar {
      transform: rotate(-90deg);
      width: 96px;
      height: 96px;
    }
  
    .background {
      fill: none;
    }
  
    .progressColor {
      fill: none;
      transition: stroke-dashoffset 0.5s ease-in-out;
    }
  
    .percentage {
      font-size: 14px;
      font-weight: bold;
    }
    .percentage-container {
      .percentage {
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
        font-size: 16px;
        font-family: "Fraunces", serif;
        &:before {
          content: attr(data-percentage);
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          border-radius: 50%;
          height: 38px;
          width: 38px;
          color: #F79E1C;
          font-size: 15px;
          font-weight: 600;
          border: 2px solid #F79E1C;
        }
      }
    }
  }
  .progress__text {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75px;
    position: absolute;
    &-percent {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: baseline;
      border-radius: 4px;
      &--int {
        color:#F79E1C;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
`

const ProgressBar = (props) => {
  const { percent } = props;
  const radius = 40; // Bán kính của progress-bar
  const circumference = 2 * Math.PI * radius; // Chu vi của progress-bar

  return (
    <Wrapper>
      <div className="indicator-progress">
        <svg className="progressBar" width="100%" height="100%">
          <circle
            className="background"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth="6"
            stroke="#EAECF0"
          />
          <circle
            className="progressColor"
            cx="50%"
            cy="50%"
            r={radius}
            strokeWidth="6"
            stroke={`#0089C3`}
            strokeDasharray={circumference}
            strokeDashoffset={circumference - ((percent || 0) / 100) * circumference}
            strokeLinecap="round"
          />
      </svg>
      <div className="progress__text">
        <div className="progress__text-percent">
          <span className="progress__text-percent--int">{percent || "0"}%</span>
        </div>
      </div>
    </div>
    </Wrapper>
  );
}

export default ProgressBar;