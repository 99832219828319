import React from 'react';
import styled from 'styled-components';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom'; // Import useHistory

//partents
import { ButtonCloseModal } from '@ui-partents/Modal';

//Images
import successImage from '@images/payment/pic_payment_success.svg';
import errorImage from '@images/payment/pic_payment_error.svg';
import IconMoney from '@images/coin/icon-money.svg';

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const ModalImage = styled.img`
  max-width: 240px;
`;

const ModalHeading = styled.div`
  color: #121926;
  font-size: 30px;
  font-weight: 600;
  margin-top: 16px;
`;

const ModalDescription = styled.div`
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  font-weight: 400;
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  width: 300px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
`;

const CoinNumber = styled.span`
  color: #F79E1C;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.32px;
  margin: 0 5px;
`;

const PaymentMessageModal = ({ isVisible, onClose, type }) => {
  const history = useHistory(); // Sử dụng useHistory hook để điều hướng
  const isSuccess = type === 'success';
  
  const handleButtonClick = () => {
    if (isSuccess) {
      history.push('/learn/home'); // Điều hướng về trang learn/home khi thành công
    } else {
      onClose(); // Đóng modal nếu không thành công
    }
  };

  return (
    <Modal
      zIndex={16000}
      isOpen={isVisible}
      backdrop={true}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
      toggle={onClose}
      style={{
        maxWidth: '750px',
        margin: '0 auto'
      }}
    >
      <ButtonCloseModal handleCloseModal={onClose} />
      <ModalBody className='p-5'>
        <ModalContainer>
          <ModalImage src={isSuccess ? successImage : errorImage} alt={isSuccess ? "Success" : "Error"} />
          <ModalHeading>{isSuccess ? "Yes, you’ve successfully ordered!" : "Oops! There was an issue with your payment"}</ModalHeading>
          <ModalDescription>
            {isSuccess ? (
              <>
                Thanks a lot for putting up this order. <br />
                <div className="d-flex algign-items-center">
                  Your order <CoinNumber>20</CoinNumber> <img className='mr-2' width={22} src={IconMoney} alt="20 coin" /> has successfully been placed.
                </div>
              </>
            ) : (
              "We’re sorry, but it looks like there was an error processing your order. Please try again or contact our support team if the issue persists."
            )}
          </ModalDescription>
        </ModalContainer>
      </ModalBody>
      <ModalFooter className='border-0'>
        <StyledButton color="primary" onClick={handleButtonClick}>
          {isSuccess ? "Back to home" : "Try again"}
        </StyledButton>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentMessageModal;