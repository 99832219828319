import React from 'react';

//untils
import { LoaderText } from "@utils";

function FeedbackContent(props) {
    const { isLoading, isLoadingStream, stateAiLabel, contentStream } = props && props;
    const latency = stateAiLabel?.ProcessTime?.latency;
    return (
        <div className={`mt-4`}>
            {(stateAiLabel?.Feedback || contentStream) && (
                <React.Fragment>
                    {latency && <p className="mb-3">Process Time: <span className="font-weight-500">{latency} sec</span></p>}
                    <div
                        className="whiteSpace-break"
                        dangerouslySetInnerHTML={{
                            __html: stateAiLabel?.Feedback || contentStream
                        }}
                    />
                </React.Fragment>
            )}
            <div className="position-relative">
                {(isLoadingStream || isLoading) && <div style={{ height: '50px' }}><LoaderText /></div>}
            </div>
        </div>
    );
};

export default FeedbackContent;