import React, { useEffect, useState } from "react";
import { FormGroup, Label, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";
import { useDispatch } from "react-redux";
import { actionLogin, actFetchThemeRequest } from "@actions";
import { checkAuth, apiCaller } from "@utils";
import { Formik, Form, Field, ErrorMessage } from "formik";
import queryString from "query-string";
import imgLogo from "@images/ucr/logo.png";

// Component for the login form
function FormLogin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const parsed = queryString.parse(window.location.search);
  const [recaptchaValue, setRecaptchaValue] = useState(""); // State variable for ReCAPTCHA value

  // Initial form values
  const initialValues = {
    email: "",
    recaptcha: ""
  };

  // Effect to set background color and redirect if already logged in
  useEffect(() => {
    document.body.style["background-color"] = "#fafbfb";
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  // Form validation function
  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(values.email)) {
      errors.email = "Invalid email address";
    }

    if (!recaptchaValue) { // Use recaptchaValue for validation
      errors.recaptcha = "Please complete the captcha";
    }

    return errors;
  };


  // Form submission handler
  const handleSubmit = (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("Email", values?.Email);
    formData.append("Institution", "NUS_Demo");

    setSubmitting(true);
    apiCaller(`/api/auth/demo/`, "POST", formData).then((response) => {
      let dataResponse = response?.data;
      if (dataResponse?.status === "OK") {
        const accountData = dataResponse?.account;
        localStorage.setItem("Account", accountData ? JSON.stringify(accountData) : "");
        dispatch(actionLogin(dataResponse));
        dispatch(actFetchThemeRequest());
        nextPage(dataResponse);
      } else {
        setSubmitting(false);
      }
    });
  };

  // Function to determine the next page after successful login
  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed[`redirect`];

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
      if (dataAccount?.is_instructor === "Yes") {
        history.push(`/teach/home`);
      } else {
        history.push("/learn/home");
      }
    }
  };

  return (
    <div className="m-portlet m-portlet--mobile" style={{ maxWidth: "450px" }}>
      <div className="m-portlet__body">
        <div className="text-center mb-5">
          <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
        </div>
        {/* Formik wrapper for managing form state and validation */}
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mt-3">
              {/* Email input field */}
              <FormGroup>
                <Label for="email">
                  Email: <span className="m--font-danger">*</span>
                </Label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email address"
                  className="form-control"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-danger mt-1"
                />
              </FormGroup>
              {/* ReCAPTCHA component for spam prevention */}
              <FormGroup>
                <div className="d-flex justify-content-center">
                  <ReCAPTCHA
                    sitekey={Config.KEY_CAPTCHA}
                    onChange={(value) => setRecaptchaValue(value)} // Update recaptchaValue on change
                  />
                </div>
                <ErrorMessage
                  name="recaptcha"
                  component="div"
                  className="text-danger text-center mt-1"
                />
              </FormGroup>
              {/* Submit button */}
              <div className="d-flex justify-content-center mt-4">
                <Button
                  color="primary"
                  type="submit"
                  className="btn m-btn--pill btn-lg w-100"
                  disabled={isSubmitting}
                >
                  {/* Display spinner while submitting */}
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FormLogin;