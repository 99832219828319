import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardHeader,
  Alert
} from "reactstrap";

//classnames
import classnames from "classnames";

//lodash
import _ from "lodash";

//components
import { AnswerDetails, Statistics } from "./TabQuestions";
import { BtnExportCSV } from '@components-common/elements';

function QuestionContent(props) {
  //PROPS
  const { itemActive, paramsActive, data } = props && props;

  //STATE
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const listTabs = [
    {
      id: "1",
      name: "Statistics",
      icon: "fa fa-bar-chart",
      component: (
        <Statistics
          paramsActive={paramsActive}
          dataQuestion={itemActive}
          {...props}
        />
      ),
    },
    {
      id: "2",
      name: "Answer Details",
      icon: "fa fa-check-square-o",
      component: (
        <AnswerDetails
          paramsActive={paramsActive}
          dataQuestion={itemActive}
          {...props}
        />
      ),
    },
  ];

  return (
    <Card className="border-0 -box-shadow">
      {!_.isEmpty(itemActive) && (
        <React.Fragment>
          <CardHeader className="bg-white d-flex justify-content-lg-between align-items-center">
            <span className="--text-primary font-weight-500 mr-auto">Question #{itemActive?.QuestionId}</span>
            <BtnExportCSV
              {...props}
              text="Export"
            />
          </CardHeader>
          <CardBody>
            <Alert
              className="m-alert--default border-0 font-weight-500"
              color="secondary"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: data.QuestionContent || "",
                }}
              />
            </Alert>
            <div className="tabsPills">
              <Nav tabs>
                {listTabs.map((item, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({ "active --bg-primary": activeTab === item.id })}
                      onClick={() => {
                        toggle(item.id);
                      }}
                    >
                      <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                      {item.name}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {listTabs.map((item, index) => (
                  <TabPane key={index} tabId={item.id}>
                    {item.component}
                  </TabPane>
                ))}
              </TabContent>
            </div>
          </CardBody>
        </React.Fragment>
      )}
      {_.isEmpty(itemActive) && (
        <CardBody>
          <Alert className="border-0 font-weight-400 text-center" color="info">
            Select a question in the panel on the left to view report
          </Alert>
        </CardBody>
      )}
    </Card>
  );
}

export default QuestionContent;