import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

//components
import { GroupSettings } from '../Settings';

//redux
import { useDispatch } from "react-redux";

//call Action
import { actUpdateTeachCaseRequest } from "@actions";

function MainSettings(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { caseDetails } = props && props;

    //state
    const [stateData, setData] = useState(caseDetails || {});

    //Set Data Case
    useEffect(() => {
        setData(caseDetails || {});
    }, [caseDetails]);

    //variable
    const LearnModeStatus = stateData?.LearnMode
    const RetriesAllowedStatus = stateData?.RetriesAllowed === 0 ? false : true
    const PublishStatus = stateData?.Status === "Active" ? true : false
    const HasNotesStatus = stateData?.HasNotes
    const LearnerInterfaceMode = stateData?.LearnerInterfaceMode === "standard2" ? true : false

    const handleChangeSwitch = (e) => {
        const { name, checked } = e.target;
        let params = {
            Action: "Update"
        }

        if (name === "Publish") {
            const conditionPublish = checked ? "Active" : "Draft";
            params[`Status`] = conditionPublish

            //setState
            setData((prevState) => ({
                ...prevState,
                Status: conditionPublish
            }));
        } else if (name === "Retries") {
            const conditionRetries = checked ? "Unlimited" : 0
            params[`Target`] = "Retries";
            params[`Retries`] = conditionRetries

            //setState
            setData((prevState) => ({
                ...prevState,
                RetriesAllowed: conditionRetries
            }));
        } else if (name === "LearnerInterfaceMode") {
            const conditionLearnerInterfaceMode = checked ? "standard2" : "standard";
            params[`LearnerInterfaceMode`] = conditionLearnerInterfaceMode

            //setState
            setData((prevState) => ({
                ...prevState,
                "LearnerInterfaceMode": conditionLearnerInterfaceMode
            }));
        } else {
            params[name] = checked;
            //setState
            setData((prevState) => ({
                ...prevState,
                [name]: checked
            }));
        }

        dispatch(actUpdateTeachCaseRequest(id, params));
    };

    //Data Settings
    const quickSettings = [
        { Name: 'Learn Mode', checked: LearnModeStatus, param: "LearnMode" },
        { Name: 'Allow Reset', checked: RetriesAllowedStatus, param: "Retries" },
        { Name: 'Publish', checked: PublishStatus, param: "Publish" },
        { Name: 'Notes', checked: HasNotesStatus, param: "HasNotes" },
        { Name: 'New Interface', checked: LearnerInterfaceMode, param: "LearnerInterfaceMode" }
    ]
    
    return (
        <GroupSettings
            handleChangeSwitch={handleChangeSwitch}
            title={`Quick Settings`}
            data={quickSettings || {}}
        />
    );
};

export default MainSettings;