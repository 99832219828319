import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

function DropDownCondition(props) {
    const { handleChangeStateCondition, isCondition } = props;
    // {
    //     "QuestionId": 2342,
    //     "ConditionType": "Question",
    //     "Status": "correct",
    //     "QuestionAnswers": [
    //         17338
    //     ]
    // }
    const options = [
        { value: '', name: 'No Condition' },
        { value: 'Question', name: 'Answering status of another question' },
        // { value: 'Chat', name: 'Chat status of a topic' },
        // { value: 'Order', name: 'Chat status of a order' },
        // { value: 'Action', name: 'Chat status of a action' }
    ];

    const listItems = options.map((item, idx) => (
        <option value={item.value} key={idx}>{item.name}</option>
    ));

    return (
        <FormGroup>
            <Label className="labelForm">Condition <span className="form-note">(OPTIONAL) Only show this question if...</span></Label>
            <Input
                type="select"
                name="ConditionType"
                value={isCondition}
                onChange={(e) => handleChangeStateCondition(e.target.value)}
            >
                {listItems}
            </Input>
        </FormGroup>
    );
}

export default DropDownCondition;