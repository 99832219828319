import React from 'react';

// Logo
import MakansafeLogo from './MakansafeLogo';
import Med2labLogo from './Med2labLogo';

const Logo = (props) => {
    const { makansafeLayout } = props && props;
    return (
        <>
            {makansafeLayout ? (
                <MakansafeLogo {...props} />
            ) : (
                <Med2labLogo {...props} />
            )}
        </>
    );
};

export default Logo;