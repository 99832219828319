import React from 'react';
import styled from 'styled-components';
import { Icon } from '@components/common';
import Error from '@images/teachV2/error.svg';
import File from '@images/teachV2/file.svg';

const FileWrap = styled.div`
  padding: 16px;
  border-radius: 6px;
  background: #FFF;
  display: flex;
  align-items: start;
  gap: 8px;
  border: 1px solid #EAECF0;
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 8px;

  h4 {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const ErrorMessage = styled.p`
  overflow: hidden;
  color: #475467;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const SupportedFormat = styled.p`
  color: #475467;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

const ErrorText = styled.p`
  color: #475467;
  font-size: 14px;
  font-style: normal; /* Không in nghiêng */
  font-weight: 400;
  line-height: 20px;
`;

const WrapOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  border-radius: 6px;
  border: 2px solid #D92D20;
`;

const TextAgain = styled.div`
  color: #475467;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`

const FileError = ({ error, setError, fileName, setDataFileImported }) => {
  return (
    <WrapOption className="wrap-option__file">
      <FileWrap className="wrap-option__file-wrap">
        <FileInfo className="wrap-option__file-info">
          <Icon src={File} stroke="#98A2B3"/>
          <div className='content-error'>
            <h4>{fileName}</h4>
            <ErrorText>Invalid format. Please try again.</ErrorText>
            {error === 'Only PDF files are allowed.' ? (
              <SupportedFormat>Supported format: .pdf.</SupportedFormat>
            ) : (
              <ErrorMessage>{error}</ErrorMessage>
            )}
            <TextAgain 
              onClick={() => {
                setError(null)
                setDataFileImported(null)
              }}
            >
              Try again
            </TextAgain>
          </div>
        </FileInfo>
        <div>
          <Icon src={Error} />
        </div>
     </FileWrap>
    </WrapOption>
  );
};

export default FileError;