import React, { useState, useEffect } from "react";

//reactstrap
import { FormGroup, Label, Input } from "reactstrap";

//redux
import { useSelector } from "react-redux";

//CreatableSelect
import CreatableSelect from "react-select/creatable";

//lodash
import _ from "lodash";

function ChatTypeCondition(props) {
    const { flowControlOfTopic, handleUpdateFlowControlChatTopic } = props && props;
    //storeChat
    const chatData = useSelector((state) => (state.loadChat && state.loadChat.data) || []);

    //convert dropdown
    const convertDataOptions = (data) => {
        let listOptions = [];
        let topics = [...data];
        // Sap Xep theo thu tu abc
        topics?.sort(function (a, b) {
            let nameA = a.TopicName.toUpperCase(); // chuyển đổi tất cả các ký tự thành chữ hoa để so sánh
            let nameB = b.TopicName.toUpperCase(); // chuyển đổi tất cả các ký tự thành chữ hoa để so sánh
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        topics?.map(function (item) {
            const dataReturn = { value: item?.TopicId, label: `${item?.TopicName} (#${item?.TopicId})` };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    const DataOption = convertDataOptions((chatData && chatData) || []);

    //state
    const [selectedOption, setSelectedOption] = useState(null);
    const [isStatus, setStatus] = useState(flowControlOfTopic?.Status);

    //Load State
    useEffect(() => {
        setStatus(flowControlOfTopic?.Status);
    }, [flowControlOfTopic]);

    //setState
    useEffect(() => {
        const findObjectById = _.filter(chatData, function (o) { return o.TopicId === flowControlOfTopic?.ChatTopicId });
        setSelectedOption({ value: findObjectById && findObjectById[0]?.TopicId, label: findObjectById && findObjectById[0]?.TopicName });
    }, [flowControlOfTopic, chatData]);

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        handleUpdateFlowControlChatTopic("ChatTopicId", data);
    };

    const handleChangeStatus = (e) => {
        const { name, value } = e.target;
        setStatus(value);
        handleUpdateFlowControlChatTopic(name, value);
    }

    return (
        <React.Fragment>
            <div className="my-3">
                <CreatableSelect
                    closeMenuOnSelect={true}
                    options={DataOption}
                    placeholder={`Click to select chat topic(s)`}
                    value={selectedOption}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                />
            </div>
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    (To NOT impose a condition, simply not select a question)
                </small>
            </p>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="asked"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "asked"}
                    />
                    is asked
                </Label>
            </FormGroup>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="not_asked"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "not_asked"}
                    />
                    is NOT asked
                </Label>
            </FormGroup>
        </React.Fragment>
    );
}

export default ChatTypeCondition;