import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//actions
import { atcDeleteCohort } from "@actions";

function ModalDeleteCohorts(props) {
  //props
  const { isOpen, toggle, cohortObject, reloadDataAfterDelete } = props && props;
  const dispatch = useDispatch();

  const hanldeRemove = () => {
    const params = {
      Action: "Delete",
      CohortId: cohortObject?.CohortId,
    };
    dispatch(atcDeleteCohort(params))
      .then(res => {
        if (res?.status === 200) {
          toggle();
          reloadDataAfterDelete();
        }
      })
      .catch(error => {
        // Xử lý khi có lỗi
        console.error('Error deleting cohort', error);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure you want to delete this Cohort?
        </h4>
        <p className="mt-2 text-center">
          All cases will be unassigned from this Cohort
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={hanldeRemove}
        >
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteCohorts;
