import React from 'react';

//components
import { ModalEditTitle } from '../Settings';

function EditTitleCase(props) {
    const { dataResponse, toggle } = props && props;
    return (
        <React.Fragment>
            <div className="itemBox">
                <div className="itemBox__left">
                    <div className="innerCt">
                        <label className="innerCt__label" htmlFor="Title">
                            Title
                        </label>
                    </div>
                </div>
                <div className="itemBox__right">
                    <div className="d-flex align-items-center">
                        <p className="innerCt__content">
                            {dataResponse?.Title}
                        </p>
                        <span
                            className="m--font-brand --text-primary p-2"
                            onClick={toggle}
                            style={{ cursor: 'pointer' }}
                        >
                            Edit
                        </span>
                    </div>
                </div>
            </div>
            <ModalEditTitle {...props} />
        </React.Fragment>
    );
};

export default EditTitleCase;