// CommonBoxWrapper.js
import styled from 'styled-components';

const CommonBoxWrapper = styled.div`
  max-width: 750px;
  padding: 40px;
  gap: 36px;
  border-radius: 12px;
  border: 1px solid #D0D5DD;
  margin: 24px auto;
`;

export default CommonBoxWrapper;