import React from 'react'
import { BuilderChoice } from '@components/teachV2/interactiveBuilder'

const InteractiveBuilder = () => {
  return (
    <div className="wrapper -interactiveBuilderPage">
      <BuilderChoice />
    </div>
  )
}

export default InteractiveBuilder
