import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//logo
import imgLogo from "@images/kachihealth/logo.png";

//Link
import { Link } from "react-router-dom";

//config
import * as Config from "@constants/Config";

//boostrap
import { Row, Col, FormGroup, Input, FormFeedback } from "reactstrap";

//components
import { EmailConfirm } from '../auth';

//react-google-recaptcha
import ReCAPTCHA from "react-google-recaptcha";

//untils
import { checkAuth } from "@utils";

function FormForGotPass(props) {
  let history = useHistory();

  //props
  const { handleView } = props && props;

  //state
  const [stateEmail, setStateEmail] = useState("");
  const [stateValid] = useState(false);
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [stateMessage] = useState(
    "Either your email is not valid. Please try again."
  );

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setStateEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(true);
  };

  const onChangeCaptcha = (value) => {
    setTokenCaptcha(value);
  };

  const getFullYear = new Date().getFullYear();
  return (
    <div className="m-portlet m-portlet--mobile flex-column">
      <div className="m-portlet__body">
        <form method="post" onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="text-center">
              <img src={imgLogo} style={{ maxWidth: "50%" }} alt="logo" />
            </Col>
            <Col md={12} className="text-center m--font-brand mt-3">
              <h4>Reset Password</h4>
            </Col>
            {isSuccess ? (
              <EmailConfirm />
            ) : (
              <React.Fragment>
                <Col md={10} className="text-center mx-auto mt-4">
                  <FormGroup>
                    <Input
                      type="email"
                      onChange={handleChange}
                      className="form-control form-control-lg m-input--pill"
                      value={stateEmail}
                      placeholder="Enter your email address"
                      name="email"
                      invalid={stateValid.toString()}
                    />
                    {stateValid && (
                      <FormFeedback className="text-2 mt-3">
                        {stateMessage}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-2">
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      sitekey={Config.KEY_CAPTCHA}
                      onChange={onChangeCaptcha}
                    />
                  </div>
                  {!tokenCaptcha && (
                    <FormFeedback className="d-block text-center">
                      This field is required.
                    </FormFeedback>
                  )}
                </Col>
              </React.Fragment>
            )}
          </Row>
          <Row>
            {!isSuccess &&
              <Col md={12} className="text-center">
                <button
                  on
                  type="submit"
                  className="btn m-btn--pill btn-brand btn-lg px-4"
                  id="button_submit_form"
                  style={{ minWidth: "200px" }}
                >
                  Send password reset email
                </button>
              </Col>
            }
            <Col md={12} className="text-center">
              <Link
                className="m--font-brand"
                onClick={handleView}
                to={`/kachi-health/login`}
              >
                Back to Log in Page
              </Link>
            </Col>
          </Row>
        </form>
      </div>
      <div className="m-portlet__foot w-100" style={{ textAlign: "center" }}>
        <span className="m-footer__copyright">
          {`2017-${getFullYear}`}©
          <a
            href="http://med2lab.com"
            target="_blank"
            rel="noreferrer"
            className="m-link ml-1"
          >
            Med2Lab Inc
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
}

export default FormForGotPass;