export const reportConstant = {
    REPORT_CASE_COMPELETED_REQUEST: 'REPORT_CASE_COMPELETED_REQUEST',
    REPORT_CASE_COMPELETED_SUCCESS: 'REPORT_CASE_COMPELETED_SUCCESS',
    REPORT_CASE_COMPELETED_FAILURE: 'REPORT_CASE_COMPELETED_FAILURE',

    REPORT_CASE_COMPELETED_DETAILS_REQUEST: 'REPORT_CASE_COMPELETED_DETAILS_REQUEST',
    REPORT_CASE_COMPELETED_DETAILS_SUCCESS: 'REPORT_CASE_COMPELETED_DETAILS_SUCCESS',
    REPORT_CASE_COMPELETED_DETAILS_FAILURE: 'REPORT_CASE_COMPELETED_DETAILS_FAILURE',

    LEARNER_PERFORMANCE_FEEDBACKS_REQUEST: 'LEARNER_PERFORMANCE_FEEDBACKS_REQUEST',
    LEARNER_PERFORMANCE_FEEDBACKS_SUCCESS: 'LEARNER_PERFORMANCE_FEEDBACKS_SUCCESS',
    LEARNER_PERFORMANCE_FEEDBACKS_FAILURE: 'LEARNER_PERFORMANCE_FEEDBACKS_FAILURE',

    REPORT_QUESTION_RECORD_REQUEST: 'REPORT_QUESTION_RECORD_REQUEST',
    REPORT_QUESTION_RECORD_SUCCESS: 'REPORT_QUESTION_RECORD_SUCCESS',
    REPORT_QUESTION_RECORD_FAILURE: 'REPORT_QUESTION_RECORD_FAILURE',
    
    REPORT_DOMAIN_RECORD_REQUEST: 'REPORT_DOMAIN_RECORD_REQUEST',
    REPORT_DOMAIN_RECORD_SUCCESS: 'REPORT_DOMAIN_RECORD_SUCCESS',
    REPORT_DOMAIN_RECORD_FAILURE: 'REPORT_DOMAIN_RECORD_FAILURE',

    UPDATE_QUESTION_GRADING_FIELD_REQUEST: 'UPDATE_QUESTION_GRADING_FIELD_REQUEST',
    UPDATE_QUESTION_GRADING_FIELD_SUCCESS: 'UPDATE_QUESTION_GRADING_FIELD_SUCCESS',
    UPDATE_QUESTION_GRADING_FIELD_FAILURE: 'UPDATE_QUESTION_GRADING_FIELD_FAILURE',

    LOAD_STATISTICS_QUESTION_REPORT_REQUEST: 'LOAD_STATISTICS_QUESTION_REPORT_REQUEST',
    LOAD_STATISTICS_QUESTION_REPORT_SUCCESS: 'LOAD_STATISTICS_QUESTION_REPORT_SUCCESS',
    LOAD_STATISTICS_QUESTION_REPORT_FAILURE: 'LOAD_STATISTICS_QUESTION_REPORT_FAILURE',

    ON_BOARDING_REPORT_REQUEST: 'ON_BOARDING_REPORT_REQUEST',
    ON_BOARDING_REPORT_SUCCESS: 'ON_BOARDING_REPORT_SUCCESS',
    ON_BOARDING_REPORT_FAILURE: 'ON_BOARDING_REPORT_FAILURE',

    LOAD_CHAT_REPORT_REQUEST: 'LOAD_CHAT_REPORT_REQUEST',
    LOAD_CHAT_REPORT_SUCCESS: 'LOAD_CHAT_REPORT_SUCCESS',
    LOAD_CHAT_REPORT_FAILURE: 'LOAD_CHAT_REPORT_FAILURE',

    UPDATE_CORRECT_RESPONSE_REQUEST: 'UPDATE_CORRECT_RESPONSE_REQUEST',
    UPDATE_CORRECT_RESPONSE_SUCCESS: 'UPDATE_CORRECT_RESPONSE_SUCCESS',
    UPDATE_CORRECT_RESPONSE_FAILURE: 'UPDATE_CORRECT_RESPONSE_FAILURE',

    LOAD_TOPIC_REQUEST: 'LOAD_TOPIC_REQUEST',
    LOAD_TOPIC_SUCCESS: 'LOAD_TOPIC_SUCCESS',
    LOAD_TOPIC_FAILURE: 'LOAD_TOPIC_FAILURE'
};