import React from "react";

//Boostrap
import {
    FormGroup,
    Label
} from "reactstrap";

function BasicInfo({ item }) {
    const dataAcc = item?.SubmittingAccount;
    const email = dataAcc?.email;
    return (
        <React.Fragment>
            {email && (
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="Email" className="mr-sm-2 font-weight-500 --text-primary">
                        Email:
                    </Label>
                    <span>{email}</span>
                </FormGroup>
            )}
            {item?.CaseId &&
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="TimePointId" className="mr-sm-2 font-weight-500 --text-primary">
                        Case ID:
                    </Label>
                    <span>{item?.CaseId}</span>
                </FormGroup>
            }
            {item?.TimePointId &&
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="TimePointId" className="mr-sm-2 font-weight-500 --text-primary">
                        Timepoint ID:
                    </Label>
                    <span>{item?.TimePointId}</span>
                </FormGroup>
            }
            {item?.Message && (
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="Message" className="mr-sm-2 font-weight-500 --text-primary">
                        Message:
                    </Label>
                    <span>{item?.Message}</span>
                </FormGroup>
            )}
            {item?.AttachmentUrl && (
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="Message" className="mr-sm-2 font-weight-500 --text-primary">
                        Screenshot:
                    </Label>
                    <img className="img-fluid" src={item?.AttachmentUrl} alt="" />
                </FormGroup>
            )}
        </React.Fragment>
    );
}

export default BasicInfo;
