import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Iconsax } from "@components-common";

// CancelOrderButton
import CancelOrderButton from './CancelOrderButton';

// Styled components
const AccordionContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const AccordionItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    border-bottom: none;
  }
`;

const AccordionHeader = styled.div`
  padding: 6px 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  &:hover {
    background-color: #f5f5f5;
  }
  h4 {
    color: #101828;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    margin-left: 10px;
  }
`;

const AccordionBody = styled.div`
  background-color: #ffffff;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')}; 
`;

const AccordionGroup = styled.div`
  margin-bottom: 12px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 22px;
`;

const StyledCol = styled.div`
  flex: 0 0 100%;
  position: relative;
`;

const TextItem = styled.div`
  color: #101828;
  font-size: 16px;
  font-weight: 500;
  padding: 6px;
  padding-right: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #F2F4F7;
  }

  .cancel-button {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  &:hover .cancel-button {
    opacity: 1;
  }
`;

function ExistingOrders(props) {

  //props
  const { addedOrders, handleRemoveItem, loadingStates } = props && props;

  //state
  const [openIndex, setOpenIndex] = useState([]);

  useEffect(() => {
    if (addedOrders?.length) {
      const allIndices = Object.keys(groupedOrders)?.map((_, index) => index);
      setOpenIndex(allIndices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAccordionToggle = (index) => {
    if (openIndex.includes(index)) {
      setOpenIndex(openIndex.filter((i) => i !== index));
    } else {
      setOpenIndex([...openIndex, index]);
    }
  };

  const typeLabels = {
    Tests: "Tests",
    intervention: "Medications",
  };

  const groupedOrders = addedOrders?.reduce((acc, order) => {
    const groupName = order.Type === "item" || order.Type === "study" || order.Type === "panel" ? "Tests" : order.Type;
    (acc[groupName] = acc[groupName] || []).push(order);
    return acc;
  }, {});

  return (
    <AccordionContainer>
      {Object.keys(groupedOrders)?.map((type, index) => (
        <AccordionGroup key={type}>
          <AccordionItem>
            <AccordionHeader isActive={openIndex.includes(index)} onClick={() => handleAccordionToggle(index)}>
              <h4 className="text-capitalize">{typeLabels[type] || type}</h4>
              <Iconsax
                iconName={openIndex.includes(index) ? "chevron-up" : "chevron-right"}
                fill="#1D2939"
                size={20}
              />
            </AccordionHeader>
            <AccordionBody isOpen={openIndex.includes(index)}>
              <StyledRow>
                {groupedOrders[type]?.map((order, index) => (
                  <StyledCol key={order.Id}>
                    <TextItem style={{ marginTop: index === 0 ? '6px' : '0' }}>
                      {order.Name}
                      <div className="cancel-button">
                        <CancelOrderButton
                          {...props}
                          onRemove={() => handleRemoveItem(order)}
                          isLoading={loadingStates[order.Id]} //
                        />
                      </div>
                    </TextItem>
                  </StyledCol>
                ))}
              </StyledRow>
            </AccordionBody>
          </AccordionItem>
        </AccordionGroup>
      ))}
    </AccordionContainer>
  );
}

export default ExistingOrders;