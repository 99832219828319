export const ticketConstant = {
   SUBMIT_TICKET_REQUEST: 'SUBMIT_TICKET_REQUEST',
   SUBMIT_TICKET_SUCCESS: 'SUBMIT_TICKET_SUCCESS',
   SUBMIT_TICKET_FAILURE: 'SUBMIT_TICKET_FAILURE',

   GET_TICKET_REQUEST: 'GET_TICKET_REQUEST',
   GET_TICKET_SUCCESS: 'GET_TICKET_SUCCESS',
   GET_TICKET_FAILURE: 'GET_TICKET_FAILURE',

   UPDATE_TICKET_REQUEST: 'UPDATE_TICKET_REQUEST',
   UPDATE_TICKET_SUCCESS: 'UPDATE_TICKET_SUCCESS',
   UPDATE_TICKET_FAILURE: 'UPDATE_TICKET_FAILURE'
};