import React, { useEffect } from "react";

//boostrap
import { Container, Row, Col } from "reactstrap";

//components
import { ProfileLeft, ProfileRight } from "@components/profile";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import { getAccountTimeZone, getAccountSettings, getInstitutionTimeZone } from "@actions";

//common
import { HeadingBox } from "@components/common";

function ProfilePage(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const { is_administrator, is_instructor } = props && props;
    const roleAdmin = is_administrator === "Yes" || is_instructor === "Yes";
    const fetchData = async () => {
      dispatch(getAccountTimeZone());
      dispatch(getAccountSettings());
    };
    fetchData();

    //Role Admin
    roleAdmin && dispatch(getInstitutionTimeZone());
  }, [dispatch, props]);

  //store
  const dataTimeZone = useSelector((state) => (state.loadAccountTimeZone && state.loadAccountTimeZone.data) || {});
  const dataSettings = useSelector((state) => (state.listAccountSettings && state.listAccountSettings.data) || {});
  return (
    <div className="wrapper -profilePage">
      <Container>
        <HeadingBox title={`Profile Settings`} buttonBack={true} urlBack={"/"} />
        <Row className="gutters-sm">
          <Col md={4} className="mt-3">
            <ProfileLeft {...props} />
          </Col>
          <Col md={8} className="mt-3">
            <ProfileRight dataTimeZone={dataTimeZone} dataSettings={dataSettings} {...props} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProfilePage;
