import { Player } from '@lottiefiles/react-lottie-player';

const IconAnimationView = ({ nameAnimation }) => {
    return (
        <>
            <Player
                autoplay
                loop
                src={process.env.PUBLIC_URL + `/icon-animation/${nameAnimation}.json`}
                style={{ width: 220, height: 220 }}
            >
            </Player>
        </>
    );
}

export default IconAnimationView;