import React from 'react';

//lodash
import _ from 'lodash';

function TableCell(props) {
    const { value, handleShow, lastTimepoint, item } = props && props;
    let logicCharacter = '';
    switch (item?.ChangeMode) {
        case 'decrease':
            logicCharacter = `<p class="text-muted">(but stay >= ${item?.ChangeLimit} ${item?.Unit})</p>`
            break;
        case 'increase':
            logicCharacter = `<p class="text-muted">(but stay <= ${item?.ChangeLimit} ${item?.Unit})</p>`
            break;
        default:
            logicCharacter = ""
    }
    const eleChangeMode = item?.ChangeMode === 'none' || _.isEmpty(item?.ChangeMode) ? '' : item?.Unit;
    return (
        <td style={{ cursor: 'pointer' }} onClick={handleShow} className={`align-middle ${lastTimepoint && 'text-center'}`}>
            <div dangerouslySetInnerHTML={{ __html: lastTimepoint ? `${value} ${eleChangeMode}` : `${value}` }} />
            {lastTimepoint &&
                <div dangerouslySetInnerHTML={{ __html: logicCharacter }} />
            }
        </td>
    );
};

export default TableCell;