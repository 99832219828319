import React from "react";

function PatientListGroup(props) {
    const { label, icon, data} = props && props;
    const listItems = data?.map((val, index) =>
        <li key={index}>
            {val?.Name}
        </li>
    );
    return (
        <div className="patient-group">
            <h3 className="patient-group__heading">
                <span className={`patient-group__heading--icon ${icon}`}></span>
                {label}
            </h3>
            <ul className="list-doted list-patient pl-2 mt-2">
                {listItems}
            </ul>
        </div>
    );
}

export default PatientListGroup;