import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";

//lodash
import _ from "lodash";

//Table Common
import { TableHeader, TableCell } from '../elements/TableElements';

//utils
import { LoaderText, messageData } from "@utils";

//common
import { DataEmty } from "@components-common";

//Modal
import { ModalUpdateVital, ModalInitalValue } from './Modal/VitalSigns';

function VitalSigns(props) {
  //props
  const { data, storeVitalSign } = props && props;
  const [show, setShow] = useState({
    vitalSign: false,
    initalValue: false
  });
  const [itemEdit, setItem] = useState({});

  //state
  const [stateData, setData] = useState((data && data) || []);

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const handleShow = (name, row, TimepointData) => {
    setShow((prevState) => ({ ...prevState, [name]: true }));

    // set State Data
    const itemData = { row, ...TimepointData }
    setItem(itemData);
  }

  const TableData = ({ data }) => {
    //filter Time !== 0
    const listTimepointLast = _.filter(data, function (o) {
      return o?.TimePoint?.Time !== 0;
    });

    //filter Time === 0
    const TimePointFirst = _.filter(data, function (o) {
      return o?.TimePoint?.Time === 0;
    });
    const dataDefault = TimePointFirst?.[0]?.VitalSigns;

    //Header
    const listHeader = [
      { Name: 'Item', width: "300px", firstTimepoint: true },
      { Name: 'Inital Value', width: "250px", firstTimepoint: true },
      ...listTimepointLast
    ]

    const listItemSort = TimePointFirst && TimePointFirst[0]?.VitalSigns;
    return (
      <Table responsive>
        {/* listTimepointLast */}
        <TableHeader 
          data={listHeader}
          dataSort={(listItemSort && listItemSort) || []}
          typeComponent={`VitalSigns`}
          isSort={true}
        />
        <tbody>
          {stateData && stateData.length === 0 && (
            <tr>
              <td>
                <DataEmty content={messageData?.default?.content} />
              </td>
            </tr>
          )}
          {dataDefault?.map((col, index) => {
            return (
              <tr key={index}>
                {/* Before */}
                {TimePointFirst?.map((row) => {
                  const item = row.VitalSigns[index];
                  return <TableCell value={item?.Name} />;
                })}
                {TimePointFirst?.map((row) => {
                  const item = row.VitalSigns[index];
                  return (
                    <TableCell
                      handleShow={() => handleShow('initalValue', item, row?.TimePoint)}
                      value={`${item?.InitialValue} ${item?.Unit}`}
                    />
                  );
                })}

                {/* Last */}
                {listTimepointLast?.map((row) => {
                  const item = row.VitalSigns[index];
                  let ChangeModeStyle = '';
                  switch (item?.ChangeMode) {
                    case 'decrease':
                      ChangeModeStyle = `decrease by ${item?.ChangeValue}`
                      break;
                    case 'increase':
                      ChangeModeStyle = `increase by ${item?.ChangeValue}`
                      break;
                    case 'set':
                      ChangeModeStyle = `set at ${item?.ChangeValue}`
                      break;
                    case 'none':
                      ChangeModeStyle = "-"
                      break;
                    default:
                      ChangeModeStyle = "-"
                  }
                  return (
                    <TableCell
                      lastTimepoint={true}
                      handleShow={() => handleShow('vitalSign', item, row?.TimePoint)}
                      value={ChangeModeStyle}
                      item={item}
                    />
                  )
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <div className="boxPortlet position-relative">
      {!storeVitalSign?.isLoading && (
        <TableData data={(stateData && stateData) || []} />
      )}
      {storeVitalSign?.isLoading && (
        <div style={{ height: '400px' }}>
          <LoaderText />
        </div>
      )}
      <ModalUpdateVital data={itemEdit} handleClose={() => { setShow((prevState) => ({ ...prevState, vitalSign: false })) }} isOpenModal={show?.vitalSign} />
      <ModalInitalValue data={itemEdit} handleClose={() => { setShow((prevState) => ({ ...prevState, initalValue: false })) }} isOpenModal={show?.initalValue} />
    </div>
  );
}

export default VitalSigns;