import React from "react";

//boostrap
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

function Filter(props) {
    //props
    const { handleChange } = props && props;
    return (
        <Row className="align-items-center mb-4">
            <Col md={``} sm={`12`} className="text-left text-nowrap mb-sm-3 mb-md-0">
                <Label className="font-weight-600 mb-0" for="StatusFilter">Filter by: </Label>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="StatusFilter">Type: </Label>
                    <Input type="select" name="select" onChange={(e) => handleChange(e, 'Type')} id="StatusFilter">
                        <option value="">All Types</option>
                        <option value="Bug">Bug</option>
                        <option value="Question">Question</option>
                        <option value="Chat">Chat</option>
                    </Input>
                </FormGroup>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="StatusFilter">Status: </Label>
                    <Input type="select" name="select" onChange={(e) => handleChange(e, 'Status')} id="StatusFilter">
                        <option value="">All Status</option>
                        <option value="New">New</option>
                        <option value="Confirmed">Confirmed</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Deployed">Deployed</option>
                    </Input>
                </FormGroup>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="Priority">Priority:</Label>
                    <Input type="select" name="select" onChange={(e) => handleChange(e, 'Priority')} id="Priority">
                        <option value="">All Priority</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                        <option value="Low">Low</option>
                    </Input>
                </FormGroup>
            </Col>
        </Row>
    );
}

export default Filter;