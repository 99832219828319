import React from 'react';

function ListAnswerChoice(props) {
    const { data } = props && props;
    const AnswerChoices = data?.AnswerChoices;
    const listItems = AnswerChoices?.map((item) =>
        <li className={`py-2 border-bottom d-flex align-items-center font-weight-400 ${item?.isCorrect ? 'text-success font-weight-500' : ''}`}>
            {item?.AnswerContent}
            <span className={`ml-auto font-weight-400 pl-3 ${item?.isCorrect ? 'text-success font-weight-500' : ''}`}>{item?.SelectedCount}</span>
        </li>
    );
    return (
        <ul>
           {listItems}
        </ul>
    );
};

export default ListAnswerChoice;