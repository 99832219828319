import React, { useState, useEffect } from "react";

//boostrap
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//components
import { ResponseTab, ScoringFeedBack } from '../Chat';

//classnames
import classnames from "classnames";

//lodash
import _ from "lodash";

//api
import { apiCaller } from "@utils";
import { elementsConstants } from "@constants";

function ModalEdit(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { toggle, isOpen, data, Timeline } = props && props;

    //state
    const [activeTab, setActiveTab] = useState("1");
    const [stateData, setData] = useState((data && data) || {});
    const [chatResponseData, setChatResponseData] = useState({});
    const [domainData, setDomainData] = useState([]);
    const [stateScore, setScore] = useState(0);
    const [stateFeedback, setFeedback] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setData((data && data) || {});
    }, [data]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSubmit = () => {
        const params = {
            "Action": "Update",
            "Target": "Chat",
            "TimePointId": stateData?.TimePointId,
            "TopicId": stateData?.TopicId,
            "IsNegative": chatResponseData?.IsNegative,
            "ChatResponse": chatResponseData?.ChatResponse || '',
            "ChatQuestion": chatResponseData?.ChatQuestion || '',
            "Score": stateScore,
            "Domains": domainData,
            "Feedback": stateFeedback
        }
        setLoading(true);

        //Call Api
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS, payload: data });
                setLoading(false);
                toggle();
            } else {
                dispatch({
                    type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_FAILURE,
                    error: "error",
                });
            }
        });
    }

    const filterData = _.filter(Timeline, { 'TimepointId': stateData?.TimePointId });
    const TimepointName = filterData && filterData[0] && filterData[0]?.DisplayName;

    //Tabs
    const listTabs = [
        {
            id: '1',
            name: 'Response',
            component:
                <ResponseTab
                    data={(stateData && stateData) || {}}
                    handleSendParamsResponse={(data) => setChatResponseData(data)}
                />,
            show: true
        },
        {
            id: '2',
            name: 'Scoring & Feedback:',
            component:
                <ScoringFeedBack
                    data={(stateData && stateData) || {}}
                    handleSendParamsDomains={(data) => setDomainData(data)}
                    handleSendParamsScore={(data) => setScore(data)}
                    handleSendParamsFeedback={(data) => setFeedback(data)}
                />,
            show: true
        }
    ]

    return (
        <Modal
            style={{ maxWidth: "900px" }}
            className="modal-dialog"
            isOpen={isOpen}
            backdrop={`static`}
        >
            <ModalHeader toggle={toggle}>
                <span className="--text-primary">{data?.TopicName}</span>
                <span className="--text-primary ml-2 text-3">(at <span className="font-italic">{TimepointName}</span>)</span>
            </ModalHeader>
            <ModalBody>
                <div className="tabsPills">
                    <Nav tabs>
                        {listTabs.map((item, index) =>
                            item.show && (
                                <NavItem key={index}>
                                    <NavLink
                                        className={classnames({ active: activeTab === item.id })}
                                        onClick={() => {
                                            toggleTab(item.id);
                                        }}
                                    >
                                        {item.name}
                                    </NavLink>
                                </NavItem>
                            )
                        )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {listTabs.map((item, index) =>
                            item.show && (
                                <TabPane key={index} tabId={item.id}>
                                    {item.component}
                                </TabPane>
                            )
                        )}
                    </TabContent>
                </div>
                {/* /tabContent */}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="d-flex align-items-center"
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Update
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalEdit;
