import React from "react";

//component common
import { ItemRow } from '../../timePointActions';

//components
import { HeadingTable } from '../../../storyboard/TimeLineItems/IOTableBlock';

function IOTableLastTimepoint(props) {
  //props
  const { titleBlock } = props && props;
  
  const Header = [
    { name: "Item", className: "--text-primary font-weight-500 text-left", styles: { 'width': '25%' } },
    { name: "Initial Value", className: "--text-primary font-weight-500 text-center", styles: { 'width': '20%' } },
    { name: "Change At This Time Point", className: "--text-primary font-weight-500 text-center", styles: { 'width': '25%' } },
    { name: "", className: "" },
    { name: "", className: "" }
  ];

  return (
    <React.Fragment>
      <HeadingTable title={titleBlock} />
      <table className="table" width="100%">
        <thead>
          <tr>
            {Header && Header.map((item, index) => (
              <th key={index} style={item.styles} className={item.className}>
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <ItemRow {...props} />
        </tbody>
      </table>
    </React.Fragment>
  );
}

export default IOTableLastTimepoint;
