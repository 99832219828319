import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

//components
import { AddNote, AddCondition } from '.';

//thumbDefault
import thumbDefault from "@images/common/placeholder.png";

//reactstrap
import { Row, Col } from "reactstrap";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//call Action
import { actSortDataCase } from "@actions";

//lodash
import _ from "lodash";

//redux
import { useDispatch } from "react-redux";

const SortableItem = SortableElement(({ value, toggleModalDel }) => (
    <li className={`ListSortableElement__item hasDrag -box-shadow`} key={value?.Id}>
        <div className="innerCt p-2">
            <i className="fa fa-bars iconDrag" aria-hidden="true"></i>
            <div className="innerCt__left">
                <img src={value?.Thumbnail || thumbDefault} alt={value?.Title} />
            </div>
            <div className="innerCt__right">
                <Row className="align-items-center">
                    <Col md={9} sm={12}>
                        <h6 className="title">
                            {value?.Title}
                            <span className="text-muted ml-2 text-1">{`(#${value?.Id})`}</span>
                        </h6>
                        <p className="time">
                            <span className="time__title --text-primary">
                                Active
                            </span>
                            {/* –Last Edited: 2021-05-01 */}
                        </p>
                    </Col>
                    <Col className="d-flex justify-content-end" md={3} sm={12}>
                        <ButtonEdit linkRef={true} url={`/teach/case/${value?.Id}`} />
                        <ButtonDelete handleDelete={() => toggleModalDel(value, "deleteCase")} />
                    </Col>
                </Row>
            </div>
        </div>
        <div className="d-flex border-top align-items-center justify-content-between">
            <AddNote item={value} />
            <AddCondition />
        </div>
    </li>
));

const SortableList = SortableContainer(({ items, toggleModalDel }) => {
    return (
        <ul className="ListSortableElement">
            {items?.map((value, index) => (
                <SortableItem 
                    key={`item-${value?.Id}`} 
                    toggleModalDel={toggleModalDel}
                    index={index}
                    value={value}
                />
            ))}
        </ul>
    );
});

function SortCaseList(props) {
    const dispatch = useDispatch();
    const { data, toggleModalDel, activeSeries } = props && props;
    const [itemSort, setItemSort] = useState(data);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newArrSort = arrayMove(itemSort, oldIndex, newIndex);
        setItemSort(newArrSort);

        //call api
        const result = _.map(newArrSort, function square(item) {
            return { Id: item?.Id }
        })
        const newParams = {
            Action: "Update-CasesOrder",
            Cases: result,
        };
        dispatch(actSortDataCase(activeSeries, newParams));
    };

    return (
        <SortableList
            items={itemSort || []}
            onSortEnd={onSortEnd}
            toggleModalDel={toggleModalDel}
            lockAxis="y"
            helperClass="SortableTbl"
            distance={1}
            useWindowAsScrollContainer={true}
        />
    );
}

export default SortCaseList;