import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback
} from "reactstrap";

//lodash
import _ from "lodash";

//Tabs
import { TabContent, TabPane } from "reactstrap";

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Actions
import { atcUpdateStudyInfo, atcCreateStudyInfo, atcUpdateStudyInfoForElements } from "@actions";

//components tabs
import { TabListStudiesEdit, ContentEditor, MediaContainer } from '../Modal/Study';

//Api
import { apiCaller } from "@utils";

const ModalEditStudies = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, timePointId, actions, typeComponent, timepointData } = props && props;
  const propsData = props && props.data;
  const TimeCurrent = timepointData?.data?.Time;

  //state
  const [activeTab, setActiveTab] = useState("1");
  const [stateData, setStateData] = useState((propsData && propsData) || {});
  const [audioData, setAudioData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [dataMediaLoad, setDataMediaLoad] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [invalid, setValid] = useState(false);

  useEffect(() => {
    if (actions === "edit-study") {
      setStateData((propsData && propsData) || {});

      //Save data current
      setDataMediaLoad({
        MediaUrl: propsData?.MediaUrl,
        MediaType: propsData?.MediaType,
      });
      setValid(false);
    } else {
      //reset Data
      setStateData({});
      setDataMediaLoad({
        MediaUrl: "",
        MediaType: "None",
      });
      setValid(false);
    }
  }, [props, actions, propsData]);

  //Handle Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  //Upload Audio
  const onFileAudioUpload = (event) => {
    const { name } = event.target;
    let fileUpload = event.target.files[0];
    let urlPreview = URL.createObjectURL(fileUpload);

    if (name === "AudioFile") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
      setAudioData(fileUpload);
    }
    if (name === "VideoFile") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
      setVideoData(fileUpload);
    }
    setIsUpload(true);
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    //Set File FormData
    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  const handleType = (e) => {
    const value = e.target.value;
    //ResetData
    setAudioData([]);
    setVideoData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    //Nếu type là data load ban đầu
    if (dataMediaLoad.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
    }
  };

  //tabs
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Save
  const handleSubmit = () => {
    const currentType = stateData?.MediaType;
    let formData = new FormData();
    formData.append("Action", "Upload");

    if (stateData?.ProcessTime !== '') {
      //Check Upload
      if (isUpload) {
        if (currentType === "ImageFile") {
          formData.append("File", imageData);
          //Call API Upload
          requestApiUpload(id, formData);
        } else if (currentType === "AudioFile") {
          formData.append("File", audioData);
          //Call API Upload
          requestApiUpload(id, formData);
        } else if (currentType === "VideoFile") {
          formData.append("File", videoData);
          //Call API Upload
          requestApiUpload(id, formData);
        } else {
          UpdateData();
        }
      } else {
        //data cu
        setStateData((prevState) => ({
          ...prevState,
          MediaType: dataMediaLoad.MediaType,
          MediaUrl: dataMediaLoad.MediaUrl,
        }));
        UpdateData();
      }
      setValid(false);
    } else {
      //validate
      setValid(true);
    }
  };

  const UpdateData = (newUrl) => {
    if (actions === "edit-study") {
      const params = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: timePointId,
        Study: {
          Id: stateData?.Id,
          Name: stateData?.Name,
          InitialValue: stateData?.InitialValue,
          ProcessTime: parseFloat(stateData?.ProcessTime) || 0,
          MediaType: stateData?.MediaType,
          Explanation: stateData?.Explanation
        },
      };

      //Media
      if (stateData.MediaType !== "VideoLink" && isUpload) {
        params.Study.MediaUrl = newUrl;
      } else {
        params.Study.MediaUrl = stateData.MediaUrl;
      }

      if (typeComponent === "elements") {
        dispatch(atcUpdateStudyInfoForElements(id, params));
      } else {
        dispatch(atcUpdateStudyInfo(id, params));
      }
      toggle();
    }

    //close Modal and Loading
    toggle();
    setIsloading(false);
    setIsUpload(false);
  };

  const handleAdd = () => {
    const params = {
      Action: "Create",
      Target: "Study",
      TimePointId: timePointId,
      Study: {
        Name: stateData?.Name,
        InitialValue: stateData?.InitialValue,
        ProcessTime: parseFloat(stateData?.ProcessTime)
      },
    };

    //Validate ProccessTime
    if (!_.isEmpty(stateData?.ProcessTime)) {
      dispatch(atcCreateStudyInfo(id, params));
      toggle();
      setValid(false);
    } else {
      setValid(true);
    }
  };

  const requestApiUpload = (id, params) => {
    setIsloading(true);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const newUrl = res && res.data && res.data.NewUrl;
      if (res && res.status === 200) {
        setTimeout(() => {
          //Send params
          UpdateData(newUrl);
          setIsloading(false);
        }, 3000);
      } else {
        setIsloading(false);
      }
    });
  };

  const isType = stateData?.MediaType;
  const urlValue = stateData?.MediaUrl;
  const currentContent = TimeCurrent !== 0 ? stateData?.DefaultChange?.ChangeValue : stateData?.InitialValue;
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ maxWidth: "700px", padding: "0 10px" }}
        backdrop={`static`}
      >
        <ModalHeader toggle={toggle}>
          {actions === "edit-study" ? "Edit Study" : "Add Study"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="Name">Name:</Label>
              <Input
                type="text"
                name="Name"
                id="Name"
                placeholder="Enter name of the study"
                defaultValue={(stateData?.Name) || ""}
                onChange={handleChange}
                autoComplete="off"
              />
            </FormGroup>
            <FormGroup>
              <Label for="ProcessTime">Process Time <span className="m--font-danger">*</span></Label>
              <InputGroup>
                <Input
                  type="number"
                  name="ProcessTime"
                  id="ProcessTime"
                  placeholder=""
                  defaultValue={stateData?.ProcessTime || 0}
                  onChange={handleChange}
                  inputMode="numeric"
                  pattern="[0-9]+"
                  min="0"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>mins</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {invalid &&
                <FormFeedback className="d-block" invalid={invalid.toString()}>This field is required.</FormFeedback>
              }
              <p className="form-help mt-1">
                The number of minutes for the results of{" "}
                <strong>{stateData?.Name}</strong> to become
                available
              </p>
            </FormGroup>

            {/* Tabs */}
            {actions === "edit-study" && (
              <React.Fragment>
                <TabListStudiesEdit
                  {...props}
                  toggleTabs={toggleTabs}
                  activeTab={activeTab}
                />
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <ContentEditor
                      content={currentContent}
                      placeholder={`"Initial finding of the study"`}
                      onChange={(newContent) => {
                        if(TimeCurrent !== 0) {
                          setStateData((prevState) => ({
                            ...prevState,
                            DefaultChange: {
                              ...prevState.DefaultChange,    // keep all other key-value pairs
                              ChangeValue: newContent // update the value of specific key
                            }
                          }));
                        } else {
                          setStateData((prevState) => ({
                            ...prevState,
                            InitialValue: newContent
                          }));
                        }
                      }}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <MediaContainer
                      handleType={handleType}
                      isType={isType}
                      handleChange={handleChange}
                      urlValue={urlValue}
                      handleUploadImage={handleUploadImage}
                      onFileAudioUpload={onFileAudioUpload}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <ContentEditor
                      content={stateData?.Explanation}
                      placeholder={``}
                      onChange={(newContent) => setStateData((prevState) => ({
                        ...prevState,
                        Explanation: newContent
                      }))}
                    />
                  </TabPane>
                </TabContent>
              </React.Fragment>
            )}
            {/* /Tabs */}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} disabled={isLoading}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={actions === "edit-study" ? handleSubmit : handleAdd}
            disabled={isLoading}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            {actions === "edit-study" ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditStudies;