import React from 'react';

//reactstrap
import { Input } from "reactstrap";

//TwitterPicker
import { TwitterPicker } from 'react-color';

function TagsEdit (props) {

    const { onkeyUpdateTags, handleChangeComplete, color, value } = props && props;

    return (
        <React.Fragment>
            <Input
                type="text"
                onKeyUp={event => event.key === "Enter" ? onkeyUpdateTags(event, 'editTag') : null}
                placeholder="Press enter to add tags"
                defaultValue={value?.Text}
                autoFocus
                className='mt-3'
                autoComplete="off"
            />
            <div className="d-flex justify-content-center mt-3">
                <TwitterPicker
                    color={color}
                    onChangeComplete={handleChangeComplete}
                    className={`mt-2`}
                    disableAlpha={true}
                />
            </div>
        </React.Fragment>
    );
};

export default TagsEdit;