import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function RemoveTemplateFromCollection(props) {
  const { handleRemove, isOpen, toggle, data } = props && props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure you want to remove this template from the collection <span style={{fontSize: '16px'}} className="font-italic">{data?.TemplateName || ''}</span> ?
        </h4>
        <p className="mt-2 text-center">
          The case will NOT be deleted. <br/>
          However, learners who are assigned this series will no longer have access to this template.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={() => handleRemove(data)}
        >
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default RemoveTemplateFromCollection;