import React, { useEffect, useState } from "react";

//boostrap
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    ModalHeader,
    FormGroup,
    Label,
    Input,
    Col
} from "reactstrap";

//api
import { apiCaller } from "@utils";

//router
import { useParams } from "react-router-dom";

function ModalTimeLineEffect(props) {
    const { isOpen, toggleModal, item, timePointCurrent } = props && props;

    let { id } = useParams();
    const [stateData, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    let InterventionId = item?.Id;

    //Case - Load Timeline Effects for an Intervention
    useEffect(() => {
        const fetchData = () => {
            const params = {
                "Action": "Load",
                "Target": "TimelineEffects",
                "InterventionId": InterventionId
            }

            //Call Api Load Text Item
            apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                } else {
                    console.log("error....");
                }
            })
        }

        isOpen && fetchData();
    }, [isOpen, item, id, InterventionId])

    //convert Bolean
    const strToBool = (str) => {
        return str.toLowerCase() === 'true';
    }

    // Handle Change
    const handleChange = (e, TimePointId) => {
        const { name, value } = e.target;
        let newData = [...stateData];
        var idx = stateData?.findIndex(function (c) {
            return c.TimePointId === TimePointId;
        });
        newData[idx][name] = value === '' ? null : strToBool(value)
        setData(newData);
    }

    const handleSave = () => {
        const params = {
            "Action": "Update",
            "Target": "TimelineEffects",
            "InterventionId": InterventionId,
            "TimePointId": timePointCurrent?.Id,
            "TimelineEffects": stateData
        }

        setLoading(true);
        //Call Api Load Text Item
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);
                toggleModal();
                setLoading(false);
            } else {
                console.log("error....");
                toggleModal();
                setLoading(false);
            }
        })
    }

    // loop
    const listItems = stateData?.map((value, idx) => {
        const DisplayName = value?.DisplayName;
        const IsVisible = value?.IsVisible;
        const TimePointId = value?.TimePointId;
        return (
            <FormGroup row key={idx}>
                <Label
                    for="TimepointItem_1"
                    sm={3}
                >
                    {DisplayName}
                </Label>
                <Col sm={9}>
                    <Input
                        id={TimePointId}
                        name="IsVisible"
                        type="select"
                        onChange={(e) => handleChange(e, TimePointId)}
                        defaultValue={IsVisible}
                    >
                        <option value={``}>
                            No Change
                        </option>
                        <option value={true}>
                            Active
                        </option>
                        <option value={false}>
                            InActive
                        </option>
                    </Input>
                </Col>
            </FormGroup>
        )
    });

    return (
        <Modal
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            backdrop={`static`}
            style={{ maxWidth: "600px" }}
        >
            <ModalHeader toggle={toggleModal}>
                Timeline Effect for <span className="font-weight-500 --text-primary">{item?.Name || ''}</span>
            </ModalHeader>
            <ModalBody>
                {listItems}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Save
                </Button>
                <Button color="default" onClick={toggleModal}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalTimeLineEffect;
