import React from 'react';
import { Button } from "reactstrap";

function ButtonAdd(props) {
    const { handleAddItem } = props && props;
    return (
        <div className="mb-4 text-right">
            <Button className="--btn-primary" onClick={() => handleAddItem("Column")}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Column
            </Button>
            <Button className="--btn-primary ml-2" onClick={() => handleAddItem("Row")}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Row
            </Button>
        </div>
    );
};

export default ButtonAdd;