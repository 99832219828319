import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import ListPagesSlides from './ListPagesSlides'
import DetailPagePptx from './DetailPagePptx';

const DataSlides = (props) => {
  const { dataSlides, listSlides } = props
	const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="resultComponent__pp">
      <Row>
        <Col sm="2" className='resultComponent__pp-slides'>
          {dataSlides.map((tab) => (
            <ListPagesSlides key={tab.id} tab={tab} activeTab={activeTab} onTabChange={handleTabChange} />
          ))}
        </Col>
        <Col sm="10" className='resultComponent__pp-page'>
          <div className="pptx-content">
            {dataSlides.map((tab, index) => (
              <div
                className={`tab-pptx ${activeTab === tab.id ? 'active' : ''}`}
                id={tab.id}
                key={tab.id}
              >
                <DetailPagePptx 
                  tab={tab} 
                  activeTab={activeTab} 
                  onTabChange={handleTabChange} 
                  dataRef={listSlides.current[index]}
                  index={index}
                  {...props}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default DataSlides
