import { ordersConstants } from "@constants";

export function orders(state = {}, action) {
    switch (action.type) {
        case ordersConstants.LOAD_ORDER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case ordersConstants.LOAD_ORDER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ordersConstants.SIGN_ORDERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ordersConstants.REMOVE_ORDER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ordersConstants.LOAD_ORDER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};