import React from 'react';

function NewSeriesButton(props) {
  const { toggleAddSeries } = props && props;
  return (
    <div className="btnTop text-center mt-3">
      <button
        className="btn-style2 btn-outline-brand --btn-ouline-primary ml-auto mr-auto"
        onClick={toggleAddSeries}
      >
        <i className="fa fa-plus mr-2" aria-hidden="true"></i>
        New Series
      </button>
    </div>
  );
};

export default NewSeriesButton;