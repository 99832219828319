import React from 'react';
import IconRanking from "@images/leader_board/icon-ranking-1.svg";
import IconStudent from "@images/leader_board/icon-ranking-2.svg";
import IconPoint from "@images/leader_board/icon-ranking-3.svg";
import ImageAvatar from "@images/common/avatar-user-demo.png";

// Your rank images
import PicRank1 from "@images/leader_board/rank-1.svg";
import PicRank2 from "@images/leader_board/rank-2.svg";
import PicRank3 from "@images/leader_board/rank-3.svg";
import PicRank4 from "@images/leader_board/rank-4.svg";
import PicRank5 from "@images/leader_board/rank-5.svg";

//lodash
import _ from "lodash";

const LeaderboardSection = ({ leaderboardData }) => {
    const sortedData = _.chain(leaderboardData).sortBy('Position').value();

    const RankImage = (rank) => {
        let imageSource;
        switch (rank) {
            case 1:
                imageSource = PicRank1;
                break;
            case 2:
                imageSource = PicRank2;
                break;
            case 3:
                imageSource = PicRank3;
                break;
            case 4:
                imageSource = PicRank4;
                break;
            case 5:
                imageSource = PicRank5;
                break;
            default:
                imageSource = null; // Set a default image or handle this case as needed
        }

        return (
            <div>
                {imageSource && <img src={imageSource} alt={`Rank ${rank}`} />}
            </div>
        );
    }
    return (
        <section className="leader-board">
            <div className="card-area bg-white">
                <h2 className="headline --size-6 mb-4">Leaderboard</h2>
                <div className="leader-board-inner">
                    <div className="div-table">
                        <div className="div-table-row div-table-header">
                            <div className="div-table-cell fixed-width-column">
                                <img src={IconRanking} alt="Ranking" className="header-icon" />
                                Ranking
                            </div>
                            <div className="div-table-cell auto-width-column text-left">
                                <img src={IconStudent} alt="Student" className="header-icon" />
                                Student
                            </div>
                            <div className="div-table-cell fixed-width-column text-right">
                                <img src={IconPoint} alt="Point" className="header-icon" />
                                Point
                            </div>
                        </div>
                        {/* Loop through the leaderboard data and render each row */}
                        {sortedData?.map((data, index) => (
                            <div className={`div-table-row ${data?.isCurrentAccount ? 'active' : ''}`} key={index}>
                                <div className="div-table-cell fixed-width-column">
                                    <div className="pl-3">
                                        {data?.isCurrentAccount ? <span className="number-active">{data.Position}</span> : (RankImage(data.Position))}
                                    </div>
                                </div>
                                <div className="div-table-cell auto-width-column text-left">
                                    <div className="rank-user">
                                        <div className="rank-user__avatar" style={{ backgroundImage: `url(${data?.ProfilePhotoUrl || ImageAvatar})` }}></div>
                                        <div className="rank-user__name">{`${data.FirstName} ${data.LastName} ${data?.isCurrentAccount ? '(You)' : ''}`}</div>
                                    </div>
                                </div>
                                <div className="div-table-cell fixed-width-column text-right">
                                    <span className="student-point">{data.Points}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <Link className="link-see-all">See all leaderboard</Link> */}
                </div>
            </div>
        </section>
    );
};

export default LeaderboardSection;
