import React from "react";

//components
import { PricingContentSp } from "../pricing";

function PricingMobile(props) {
  const { data } = props && props;
  return (
    <section className="pricing-wrapper-mobile">
      <div className="section-content">
        <div className="pricing-block-mobile">
          <div className="headline-small mb-3">PILOT</div>
          <div className="third-break">
            For teaching / practicing in small group sessions, or simply trying
            out our system
          </div>
          <h1 className="headline my-1">
            <span>US$7</span>
            <sup>99</sup>
          </h1>
          <div className="pricing-plan-description third-break">
            per active user* per month
          </div>
          <PricingContentSp data={(data && data) || []} type={`type1`} />
        </div>
        <div className="pricing-block-mobile">
          <div className="headline-small mb-3">CURRICULUM</div>
          <div className="third-break">
            For assessment and coaching in training programs, practice groups,
            or small health facilities
          </div>
          <h1 className="headline my-1">
            <span>US$499</span>
          </h1>
          <div className="pricing-plan-description">
            per month
            <br />
            <span className="text-dark font-weight-500 mt-1 d-block">
              (includes <strong>100</strong> users)
            </span>
          </div>
          <PricingContentSp data={(data && data) || []} type={`type2`} />
        </div>
        <div className="pricing-block-mobile">
          <div className="headline-small mb-3">INSTITUTION</div>
          <div className="third-break">
            For scalable evaluation and teaching in professional schools and
            health systems
            <br />
          </div>
          <h1 className="headline text-10">Custom pricing</h1>
          <PricingContentSp data={(data && data) || []} type={`type3`} />
        </div>
      </div>
    </section>
  );
}

export default PricingMobile;
