import React, { useState, useEffect } from 'react';

//reactstrap
import { CustomInput, Label } from "reactstrap";

//react router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import {
    atcUpdateChatModeChat
} from "@actions";

function ChatMode(props) {
    const dispatch = useDispatch();
    let { id } = useParams();
    const options = [
        {
            label: 'Learner to choose from a list of questions',
            value: 'click'
        },
        {
            label: 'Learner to enter question as free-text',
            value: 'freetext'
        },
        {
            label: 'Learner to interact by voice (mic required)',
            value: 'voice'
        },
        {
            label: 'Learner can switch between voice vs typing',
            value: 'switch_voice_text'
        }
    ]

    //props
    const { timePointId, timepointData } = props && props;
    const chatModeParams = timepointData?.data?.ChatMode;

    //state
    const [stateChecked, setChecked] = useState((chatModeParams && chatModeParams) || '');

    //Load
    useEffect(() => {
        setChecked((chatModeParams && chatModeParams) || '');
    }, [chatModeParams]);

    //hanlde Change
    const handleChange = (type) => {
        const params = {
            "Action": "Update",
            "Target": "TimePoint",
            "TimePointId": timePointId,
            "ChatMode": type
        }
        setChecked(type);
        dispatch(atcUpdateChatModeChat(id, params));
        // handleChangeMode(type);
    }

    return (
        <div className='flex-wrap'>
            {options?.map((item, index) =>
                <Label className="d-inline-flex align-items-center" key={index}>
                    <CustomInput
                        id={`${item?.value}`}
                        type="radio"
                        name="ChatMode"
                        defaultValue={stateChecked}
                        checked={stateChecked === item?.value}
                        onChange={() => handleChange(`${item?.value}`)}
                    />
                    {item?.label}
                </Label>
            )}
        </div>
    );
};

export default ChatMode;