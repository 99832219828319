import React from "react";

//icon
import ImagePlayer from '@images/common/play.svg';

//Icon
import { Iconsax } from "@components-common";

//react-router-dom
import { Link } from "react-router-dom";

function ButtonCaseItem(props) {
    const { Progress, pdFfile, Id } = props && props;
    const checkStatus = (type) => {
        let statusHtml = ''
        switch (type) {
            case 'in_progress':
                statusHtml = 'Resume';
                break;
            case 'not_started':
                statusHtml = 'Start';
                break;
            case 'completed':
                statusHtml = 'Review';
                break;
            case 'document':
                statusHtml = 'Open Document';
                break;
            default:
                statusHtml = ''
        }
        return statusHtml;
    }

    const handleClick = () => {
        window.open(pdFfile);
    };

    const refLink = (Progress?.status !== 'completed') ? `/learn/case/${Id}` : `/learn/feedback/${Id}?status=completed`;
    return (
        <React.Fragment>
            {Progress?.status !== 'document' ? (
                <Link
                    to={refLink}
                    className={`btn ${Progress?.status !== 'completed' ? "btn-brand-sm -btn-sm" : "btn-green-sm -btn-sm"}`}
                    target={"_self"}
                >
                    {Progress?.status === 'completed' && <Iconsax iconName="check-circle" fill="#fff" size={24} />}
                    {Progress?.status !== 'completed' && <img src={ImagePlayer} alt={Progress?.status} />}
                    <span className="ml-2 text-white">{checkStatus(Progress?.status)}</span>
                </Link>
            ) : (
                <span
                    style={{ cursor: 'pointer' }}
                    onClick={Progress?.status === 'document' ? handleClick : undefined}
                    className="btn-style2 btn-outline-brand --btn-ouline-primary"
                >
                    {Progress?.status === 'document' && <i className="fa fa-eye mr-2" aria-hidden="true"></i>}
                    {checkStatus(Progress?.status)}
                </span>
            )}
        </React.Fragment>
    );
}

export default ButtonCaseItem;