import React from "react";
import checkEmail from "@images/common/vote.svg";

//boostrap
import { Col } from "reactstrap";

function EmailConfirm(props) {
  return (
    <Col md={12} className="text-center mt-3">
      <img
        className="mx-auto d-block my-4"
        width="80px"
        src={checkEmail}
        alt="Check Email"
      />
      <p>
        An email has been sent to{" "}
        <span className="m--font-brand">bao@med2lab.com</span>
      </p>
      <p>
        Please check your email and follow the instruction to complete resetting
        password
      </p>
    </Col>
  );
}

export default EmailConfirm;
