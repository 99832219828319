import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

function ListTabFull(props) {
  const activeTab = props && props.activeTab;
  const toggle = props && props.toggle;
  const navList = props && props.navList;
  return (
    <Nav tabs>
      {navList &&
        navList.map((value, index) => (
          <NavItem className="border-0" key={index}>
            <NavLink
              className={
                classnames({ active: activeTab === ((index + 1).toString()) })
              }
              onClick={() => {
                toggle((index + 1).toString());
              }}
            >
              {value.Name}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
}

export default ListTabFull;