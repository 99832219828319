import React from "react";

//components
import { ForgotpasswordForm } from "@components/weitzman";

function ForgotpasswordPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <ForgotpasswordForm />
    </div>
  );
}

export default ForgotpasswordPage;