import React from "react";

//Components
import { PdfViewerComponent } from '../PdfViewerComponent';

function PdfViewer(props) {
  const { rowData } = props && props;
  const pdf_url = rowData?.pdf_url;
  return (
    <div className="PDF-viewer">
      <PdfViewerComponent
        {...props}
        document={pdf_url}
      />
    </div>
  );
}

export default PdfViewer;