import React, { useState } from 'react'
import { Icon } from '@components/common'
import Close from '@images/teachV2/close.svg'
import styled from 'styled-components';
import Error from '@images/teachV2/error-circle.svg'

const WrapError = styled.div`
  padding: 20px 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 9.6px 0px rgba(175, 165, 165, 0.25);
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .error-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    p {
      color: #000;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .error-close {
    cursor: pointer;
  }
`

export default function FormError(props) {
  const { content } = props
  const [showError, setShowError] = useState(true);
  // This document contains limited readable text, which may affect the generated content quality. Please proceed with caution when using it for generation.
  return (
    showError && (
      <WrapError>
        <div className="error-content">
          <Icon src={Error} />
          <p>{content}</p>
        </div>
        <div className="error-close" onClick={() => setShowError(false)}>
          <Icon src={Close} stroke="#DC6803" />
        </div>
      </WrapError>
    )
  )
}
