import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//utils
import { LoaderText } from "@utils";

//components
import { HeadingBox } from "@components/common";
import { IntakeOuputTable } from '@components/elements';

//actions
import { loadIODataForElement } from "@actions";

function IOPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "IOData",
      };
      dispatch(loadIODataForElement(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //store Intake Output
  const storeIntakeOutput = useSelector(
    (state) => state?.dataIntakeOutput || {}
  );

  const listData = storeIntakeOutput?.data;
  return (
    <div className="wrapper -eIOelementPage">
      <section className="intakeOuputCt position-relative">
        <Container>
          {storeIntakeOutput?.isLoading && (
            <div style={{ height: '400px' }}>
              <LoaderText />
            </div>
          )}
          <HeadingBox title={titlePage || ""} currentPage="Intake/Output" />
          <div className="boxPortlet">
            <IntakeOuputTable storeIntakeOutput={(storeIntakeOutput && storeIntakeOutput) || {}} data={(listData && listData) || []} type={'Intake'} />
            <IntakeOuputTable storeIntakeOutput={(storeIntakeOutput && storeIntakeOutput) || {}} data={(listData && listData) || []} type={'Output'} />
          </div>
        </Container>
      </section>
    </div>
  );
}

export default IOPage;