import React from 'react';

//reactstrap
import { FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function ShowingCondition(props) {
    const { stateCurrentQuestion, handleChange } = props && props;
    return (
        <div>
            <FormGroup>
                <Label>Showing Condition:</Label>
                <TextareaAutosize
                    name="ShowingConditionText"
                    placeholder=""
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    defaultValue={stateCurrentQuestion?.ShowingConditionText || ''}
                    onChange={handleChange}
                />
            </FormGroup>
        </div>
    );
};

export default ShowingCondition;