import React from "react";

import {
    Input,
    FormGroup,
    Label,
} from "reactstrap";

function TemplateType(props) {
    const { handleChange, stateDefaultActiveStatus } = props && props;
    return (
        <React.Fragment>
            <Label className="font-weight-500 mb-0 mt-4" for="title">
                Template Type
            </Label>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`Type`}
                        type="radio"
                        value={`question_feedback_fixed`}
                        className="mt-1"
                        onChange={handleChange}
                        checked={stateDefaultActiveStatus === true}
                    />
                    General Prompt (can be sent to the AI)
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`Type`}
                        type="radio"
                        value={`question_feedback_fill_in`}
                        className="mt-1"
                        onChange={handleChange}
                        checked={stateDefaultActiveStatus === false}
                    />
                    User Fill-Out Template (cannot be sent to the AI)
                </Label>
            </FormGroup>
        </React.Fragment>
    );
}

export default TemplateType;