import React from 'react';

//reactstrap
import { Alert } from "reactstrap";

//react-scrollbars-custom
import { Scrollbar } from "react-scrollbars-custom";

function ListAsk(props) {
    const { isLoading, dataListAsk, handleClickAsk, responseChatData, dataBoxRight, caseData } = props && props;
    const ChatMaxQuestionsAllowed = responseChatData?.ChatMaxQuestionsAllowed;
    const TimePointId = caseData?.data?.Case?.Timeline?.CurrentTimePointId;
    return (
        <div className="frameChat__left interact_panel">
            {isLoading &&
                <div id="chat_questions_overlay">
                    <h4 className="m--font-brand">Awaiting Response<br />...</h4>
                </div>
            }
            <Scrollbar style={{ height: 500 }}>
                <Alert className="m-alert--default text-center border-0">
                    <span className="font-weight-500">
                        Available Questions
                        <br />
                        to Ask the Patient / Caregiver
                    </span>
                </Alert>
                <ul className="listAsk">
                    {dataListAsk?.map((value, index) => (
                        value.Message &&
                        <li className="listAsk__item" key={index}>
                            <span className="title">{value.Message}</span>
                            <button
                                className="btn btn-sm btn-outline-brand"
                                onClick={() => {
                                    //Submit API
                                    const params = {
                                        "Action": "Submit",
                                        "Message": value?.Message,
                                        "TimePointId": TimePointId
                                    }
                                    handleClickAsk(params, index)
                                }}
                                disabled={ChatMaxQuestionsAllowed === dataBoxRight?.length}
                            >
                                Ask
                            </button>
                        </li>
                    ))}
                </ul>
            </Scrollbar>
        </div>
    );
};

export default ListAsk;