import React, { useState } from "react";

//boostrap
import { Label, FormGroup } from "reactstrap";

//react-select
import Select from "react-select";

function FormSelectItem(props) {

    //props
    const { optionsData, placeholder, label } = props && props;

    //state
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <FormGroup>
            <Label for="Tour" >
                {label}:
            </Label>
            <Select
                closeMenuOnSelect={true}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={optionsData || []}
                placeholder={placeholder}
            // isDisabled={(listDropdown && listDropdown.length === 0) || isDisabled}
            />
        </FormGroup>
    );
};

export default FormSelectItem;