import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoaderPage from "@utils/LoaderPage";

//redux
import { useDispatch } from "react-redux";

//call Api
import {apiCaller} from "@utils";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//utils
import { useQuery } from "@utils";

function AutoLoginPage() {
  const dispatch = useDispatch();
  let history = useHistory();

  //state
  const [isLoading, setLoading] = useState(true);

  //slug
  let query = useQuery();
  let username = query.get("username");
  let onTimeCode = query.get("one_time_code");
  let caseId = query.get("case");
  // {domain}/auth?username=nus@med2lab.com&one_time_code=1234567ABCDEFG&case=565

  //Call Api
  useEffect(() => {
    const nextPage = (data) => {
      const dataAccount = data && data.account;

      //Check Case
      if(caseId) {
        history.push(`/learn/case/${caseId}`);
      } else {
        if (dataAccount && dataAccount.is_instructor === "Yes") {
          history.push(`/teach/home`);
        } else {
          history.push("/learn/home");
        }
      }
    };

    const fetchData = async () => {
      apiCaller(`/api/auth/login/`, "POST", {
        username: username,
        one_time_code: onTimeCode,
      }).then((response) => {
        const dataResponse = response && response.data;
        if (dataResponse && dataResponse.status === "OK") {
          const accountData = dataResponse && dataResponse.account;

          // set localStorage
          localStorage.setItem(
            "Account",
            accountData ? JSON.stringify(accountData) : ""
          );

          //add token
          dispatch(actionLogin(dataResponse));

          //Call API Theme
          dispatch(actFetchThemeRequest());
          nextPage(dataResponse);
          setLoading(false);
        }
      });
    };
    //fetch data
    fetchData();
  }, [dispatch, history, onTimeCode, username, caseId]);

  return (
    <div className="wrapper -autoLoginPage">
      {isLoading &&
        <LoaderPage />
      }
    </div>
  );
}

export default AutoLoginPage;
