import React, { useState } from "react";

//reactstrap
import { Card, CardBody, Container } from "reactstrap";

//components
import { TextareaAutosizeInput, HtmlResult } from '@components/playground/Diagnosis';

//Axios
import axios from "axios";

//Auth
import * as Config from "@constants/Config";

function DiagnosisPage() {
  const [nameState, setNameState] = useState({ text: '' });
  const [result, setResult] = useState({});
  const [isShowResult, setShowResult] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitStatement = (content) => {
    const params = { "text": content }
    axios({
      method: "POST",
      url: `${Config.API_ANALYZE}/api/statement/`,
      mode: "no-cors",
      headers: { 'Content-Type': 'application/json' },
      data: params
    }).then(function (response) {
      setShowResult(true);
      setResult(response?.data || {});
    }).catch(function (error) {
      console.log(error);
    });
  };

  return (
    <div className="wrapper -analyzePage">
      <Container style={{ maxWidth: "800px" }}>
        <Card>
          <CardBody>
            {!isShowResult && (
              <TextareaAutosizeInput
                handleChange={handleChange}
                nameState={nameState}
                handleSubmit={() => handleSubmitStatement(nameState?.text)}
                disabled={nameState?.text ? false : true}
              />
            )}
            {isShowResult && (
              <HtmlResult
                data={result || ''}
                handleBack={() => setShowResult(false)}
              />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

export default DiagnosisPage;