import { useState, useEffect, useRef } from "react";

//ReactPaginate
import ReactPaginate from 'react-paginate';
function PaginatedItems(props) {
    const { itemsPerPage, items, children, handleItemsPerPage } = props && props;

    // We start with an empty list of items.
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [selectedPage, setselectedPage] = useState(null);

    useEffect(() => {
        // Fetch items from another resources.
        if (items && items.length > 0) {
            const endOffset = itemOffset + itemsPerPage;
            console.log(`Loading items from ${itemOffset} to ${endOffset}`);
            setCurrentItems(items.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(items.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, items]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        handleItemsPerPage(currentItems);
        setselectedPage(event.selected);
    };

    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = currentItems;
        props && props.handleItemsPerPage(currentItems);
    }, [currentItems, props]); // Only re-run if value changes
    return (
        <>
            {children}
            <ReactPaginate
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName={(pageCount === 1 ? "pagination d-none" : "pagination pagination-custom-case justify-content-center mt-4")}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                previousLabel={"<"}
                renderOnZeroPageCount={null}
                forcePage={selectedPage}
            />
        </>
    );
}

export default PaginatedItems;