import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Iconsax } from "@components-common";
import UserContext from '@context/UserContext';
import sidebarData from './sidebarItems.json';

//Management
import { AccountSidebarMenu } from "../Sidebar/AccountMenu";
import { LogoTop } from "../Sidebar/SidebarTop";
import { MainMenu, ManagementMain, DesignPatterns } from "../Sidebar/MainMenuTop";

//UI common
import { CreateNewQuick } from "@ui-partents/Dropdown";
import dropdownItemsData from './CreateNewQuick/dropdownItems.json';

const addIconToItems = (items) => {
    return items.map(item => ({
        ...item,
        icon: <Iconsax iconName={item.icon} fill="#697592" />
    }));
};

// Function to generate sidebar items based on path and user privileges
const generateSidebarItems = (pathname, privilege) => {
    let items = [];

    if (pathname.startsWith('/learn')) {
        items = sidebarData.learn;
    } else if (pathname.startsWith('/design-patterns')) {
        items = sidebarData.designPatterns;
    } else if (privilege === "alpha") {
        //Nếu tester_privilege alpha
        items = sidebarData.alpha;
    } else {
        //Nếu tester_privilege null
        items = sidebarData.default;
    }

    // Map the items to include Iconsax components
    return items.map(item => ({
        ...item,
        icon: <Iconsax iconName={item.icon} fill="#697592" />,
    }));
};

// Hàm lấy menuData theo title
const getMenuDataByTitle = (teachMenu, title) => {
    const menu = teachMenu?.find(item => item.title === title);
    return menu ? menu.menuData : [];
};

const SidebarMain = (props) => {
    //props
    const { isSidebarCollapsed } = props && props;

    //params
    const { pathname } = useLocation();
    const { privilege } = useContext(UserContext);
    const sidebarItems = useMemo(() => generateSidebarItems(pathname, privilege), [pathname, privilege]);

    //sẽ dùng json 2 trường hợp default, alpha dựa vào hàm getMenuDataByTitle
    const mainMenuData = addIconToItems(getMenuDataByTitle(sidebarItems, "MAIN MENU")) || [];
    const managementMenuData = addIconToItems(getMenuDataByTitle(sidebarItems, "MANAGEMENT")) || [];
    return (
        <nav className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            {/* Dùng chung LogoTop vs Makansafe */}
            <LogoTop {...props} />
            {pathname?.startsWith('/teach') &&
                <React.Fragment>
                    <CreateNewQuick
                        {...props}
                        menuData={dropdownItemsData || []}
                    />
                    <MainMenu
                        {...props}
                        sidebarItems={mainMenuData}
                    />
                    <ManagementMain
                        {...props}
                        sidebarItems={managementMenuData || []}
                    />
                </React.Fragment>
            }
            {pathname?.startsWith('/learn') &&
                <MainMenu
                    {...props}
                    sidebarItems={sidebarItems || []}
                />
            }
            {pathname?.startsWith('/design-patterns') &&
                <DesignPatterns
                    {...props}
                    sidebarItems={sidebarItems || []}
                />
            }
            {!pathname?.startsWith('/design-patterns') && (
                <AccountSidebarMenu
                    {...props}
                    pathname={pathname}
                />
            )}
        </nav>
    );
};

export default SidebarMain;