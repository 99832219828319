import React, { useRef, memo, useEffect } from "react";

//boostrap
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    FormFeedback
} from "reactstrap";

//JoditEditor
import JoditEditor from "jodit-react";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const GeneralTab = memo(({ props, editHeader, register, formState: { errors }, setValue, getValues }) => {
    const editor = useRef(null);

    //props
    const { data, stateComment, handleSetContent } = props && props;
    const rowData = data?.item;
    const colData = data?.col;

    //state
    useEffect(() => {
        const valueCurrent = data?.item?.relation;
        setValue('description', valueCurrent?.description, { shouldValidate: false });
        setValue('Score', valueCurrent?.score || 0, { shouldValidate: false });
    }, [data, setValue]);

    const values = getValues();
    return (
        <Form>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Comments">In {colData?.name}, {rowData?.name} is … <span className="m--font-danger">*</span></Label>
                        <TextareaAutosize
                            {...register("description", {
                                required: "This field is required.",
                            })}
                            name="description"
                            className="w-100 form-control"
                            maxRows="10"
                            minRows={5}
                            value={values?.description || ''}
                            onChange={(e) => {
                                setValue("description", e.target.value, { shouldValidate: true });
                            }}
                            placeholder="type a description here, which will be used by AI to evaluate the learner’s answer"
                        />
                        {errors.description?.message && (
                            <FormFeedback className="d-block">
                                {errors.description?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Comments">Comments / Explanation:</Label>
                        <JoditEditor
                            ref={editor}
                            value={stateComment}
                            config={{
                                direction: "ltr",
                                readonly: false,
                                placeholder: "text entered here will only be used in the feedback to the learner",
                                toolbarButtonSize: 'small',
                                toolbarAdaptive: true,
                                askBeforePasteFromWord: false,
                                askBeforePasteHTML: false
                            }}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => handleSetContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for="Score">Score <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Score", {
                                required: "This field is required.",
                            })}
                            type="number"
                            name="Score"
                            value={values?.Score}
                            onChange={(e) => {
                                setValue("Score", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                        />
                        {errors.Score?.message && (
                            <FormFeedback className="d-block">
                                {errors.Score?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
});

export default GeneralTab;