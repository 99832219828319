import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { Col, Button, Modal, ModalBody, ModalFooter, FormGroup, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { teachConstants } from "@constants";

function DeleteCaseBox() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [stateEmail, setStateEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { value } = e.target;
    setStateEmail(value);
    setEmailError("");
  };

  const handleDeleteCase = async () => {
    try {
      setIsLoading(true);
      const params = {
        EmailConfirm: stateEmail,
        CaseId: id,
      };
      const res = await apiCaller(`/api/teach/delete/case/`, 'POST', params);
      const data = res.data;

      if (data.status === "success") {
        dispatch({
          type: teachConstants.DELETE_CASE_SUCCESS,
          payload: data
        });
        window.location.href = '/teach/home';
      } else {
        dispatch({
          type: teachConstants.DELETE_CASE_FAILURE,
          payload: data
        });
        setEmailError(data.error || "An error occurred");
      }
    } catch (error) {
      console.error("Error while deleting case:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="widgetBox -box-shadow mt-4">
        <div className="widgetBox__head">
          <div className="delCase" onClick={toggle}>
            <i className="delCase__icon fa fa-trash-o" aria-hidden="true"></i>
            <span className="delCase__text">Delete Case</span>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h3 className="text-center --text-primary lead">
            Are you certain that you would like to <br /> delete this case ?
          </h3>
          <p className="mt-4 text-center">
            Please note that this is an irreversible action. All learner’s data
            associated with this case will be removed.
          </p>
          <Col md="10" className="mx-auto">
            <FormGroup className="mt-4">
              <Input
                type="email"
                name="email"
                onChange={handleChange}
                value={stateEmail}
                placeholder="Enter your email address to confirm …"
                id="email"
              />
              {emailError && <p className="text-danger mt-2">{emailError}</p>}
            </FormGroup>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            disabled={isLoading}
            className="-btn-primary d-flex align-items-center"
            onClick={handleDeleteCase}
            color="primary"
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Delete Case
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default DeleteCaseBox;