import React from "react";
import { Input } from "reactstrap";
import { apiCaller } from "@utils";

const ValidateFoodItem = ({ row, cell }) => {
    const handleChangeValidate = () => {
        const params = {
            "action_type": "validate_food_item",
            "food_id": row?.food_id
        };
        //Truyền true hàm apiCaller để sử dụng api makansafe
        apiCaller(`/api/iddsi/functional/`, "POST", params, true)
            .then(res => {
                if (res?.status === 200) {
                    console.log("success....");
                } else {
                    console.error("Error occurred:", res?.statusText);
                }
            })
            .catch(error => {
                console.error("Error occurred:", error);
            })
            .finally(() => {
                console.log("finally....");
            });
    };

    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
            <div className="checkBox-custom-style --green-color" style={{ height: "24px" }}>
                <div className="form-group">
                    <Input type="checkbox" id={`Reviewed_${row?.food_id}`} defaultChecked={cell} onChange={handleChangeValidate} />
                    <label htmlFor={`Reviewed_${row?.food_id}`} style={{ width: "24px" }}></label>
                </div>
            </div>
        </div>
    );
};

export default ValidateFoodItem;