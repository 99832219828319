import React from 'react';
import { FormAdd } from "@components/teach";

function AddCasePage() {
  return (
    <div className="wrapper -AddCasePage" >
      <FormAdd />
    </div>
  );
}

export default AddCasePage;