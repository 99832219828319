import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import imgUser from '@images/common/placehoder-avatar.svg';

// Redux
import { useSelector, useDispatch } from 'react-redux';

//UI Parttents
import { AvatarImage } from '@ui-partents/Avatar';

// Actions
import { switchRole } from '@actions';

const ProfileHeader = () => {
    const dropdownRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();

    // Get profile
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const AccountData = AccountInfoData?.Account;
    const { profile_photo_url: profilePhoto, firstname, lastname, email } = AccountData || {};
    const is_instructor = AccountData?.is_instructor === "Yes";

    // Role Active
    const currentRole = useSelector((state) => state?.statusRoleSystem || {});
    const activeRole = currentRole?.roleActive;

    // State
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    // Handle Click Outside
    const handleOutsideClick = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    }, []);

    // Handle Logout
    const handleLogout = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
    }, [history]);

    // Handle Role Change
    const handleRoleChange = useCallback((role, link) => {
        dispatch(switchRole(role));
        setIsDropdownVisible(false); // Close dropdown after selection
        history.push(link); // Navigate to the new role's link
    }, [dispatch, history]);

    // Handle event Click Outside
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    // Roles
    const roles = [
        { label: "View as Learner", link: "/learn/home", role: "learner" },
        { label: "View as Instructor", link: "/teach/home", role: "instructor" }
    ];

    return (
        <div className="profile-info">
            <AvatarImage
                image={profilePhoto || imgUser}
                size="sm"
                isOnline={true}
                onHover={() => setIsDropdownVisible(true)}
            />
            {isDropdownVisible && (
                <div
                    ref={dropdownRef}
                    className="profile-info__inner show-dropdown"
                    onMouseEnter={() => setIsDropdownVisible(true)}
                    onMouseLeave={() => setIsDropdownVisible(false)}
                >
                    <div className="profile-lg-top">
                        <AvatarImage
                            image={profilePhoto || imgUser}
                            size="sm"
                            isOnline={true} // Đặt true nếu người dùng trực tuyến
                        />
                        <div className="profile-lg-top__right d-flex flex-column">
                            <p className="profile-name">{firstname} {lastname}</p>
                            <p className="profile-email">{email}</p>
                        </div>
                    </div>
                    <div className="profile-role">
                        <span className="profile-role__text">Current Role:</span>
                        <span className="profile-role__current ml-2" style={{ textTransform: 'capitalize' }}>{activeRole}</span>
                    </div>
                    <ul className="nav-profile">
                        {is_instructor && (
                            <li>
                                <h3 className="nav-profile__heading">SWITCH ROLE</h3>
                            </li>
                        )}
                        {is_instructor && roles.map((item, index) => (
                            <li
                                key={index}
                                className={`nav-profile__item ${activeRole === item.role ? 'active' : ''}`}
                            >
                                <span
                                    className="nav-profile__item--link"
                                    onClick={() => handleRoleChange(item.role, item.link)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {item.label}
                                </span>
                            </li>
                        ))}
                        <li className="nav-profile__item logout-item">
                            <span className="nav-profile__item--link" onClick={handleLogout} style={{ cursor: "pointer" }}>
                                Logout
                            </span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ProfileHeader;