import React from "react";
import { Row, Col, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

//common
import { DataEmty, LockMessage } from "@components-common";

function CohortTab(props) {
  const { newDataLearner, toggle, dataLearner, columns, message } = props && props;
  return (
    <div>
      {typeof newDataLearner === "object" &&
      newDataLearner.hasOwnProperty("LockMessage") ? (
        <LockMessage {...props} data={newDataLearner} />
      ) : (
        <React.Fragment>
          <Row className="mb-4">
            <Col sm={12}>
              <Button
                className="--btn-primary"
                onClick={() => toggle("addLearner")}
              >
                Add Learner(s)
              </Button>
              <Button
                className="--btn-primary ml-2"
                onClick={() => toggle("editCohort")}
              >
                Edit Cohort
              </Button>
              <Button
                className="btn-danger ml-2"
                onClick={() => toggle("deleteCohort")}
              >
                Delete Cohort
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <ToolkitProvider
                keyField="email"
                data={(dataLearner && dataLearner) || []}
                columns={columns}
                key={JSON.stringify(dataLearner && dataLearner)} //reload data
                search
              >
                {(toolkitprops) => (
                  <React.Fragment>
                    <BootstrapTable
                      striped
                      condensed
                      wrapperClasses="table-responsive"
                      bordered={false}
                      {...toolkitprops.baseProps}
                      noDataIndication={() => (
                        <div className="py-5">
                          <DataEmty content={message} />
                        </div>
                      )}
                    />
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}

export default CohortTab;
