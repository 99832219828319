import React, { useRef } from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import TextareaAutosize from 'react-textarea-autosize';

//Config
import * as Config from "@constants/Config";


const TemplateDocumentation = ({ timepointData }) => {
  const formikRef = useRef(null);

  const validationSchema = Yup.object().shape({
    text: Yup.string().required("Text is required"),
    placeholder: Yup.string().required("Title is required"),
    defaultContent: Yup.string().required("Default Content is required"),
    feedback: Yup.string().required("Feedback is required"),
    scoring: Yup.string().required("Scoring is required"),
  });

  // const handleSaveButtonClick = () => {
  //   if (formikRef.current) {
  //     formikRef.current.submitForm();
  //   }
  // };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          text: timepointData?.DefaultNarrative?.Content,
          placeholder: "",
          defaultContent: "",
          feedback: "",
          scoring: "",
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
        }) => (
          <Form>
            <FormGroup>
              <Label className="labeForm mt-4" for="dataOffset">
                Text 
              </Label>
              <JoditEditor
                // ref={editor}
                value={values.text}
                config={Config.CONFIG_JODIT}
                placeholder={''}
                tabIndex={1}
                onBlur={(newContent, e) => handleBlur(newContent, e)}
              />
              <FormFeedback>{errors.text}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm mt-4" for="placeholder">
                Placeholder
              </Label>
              <TextareaAutosize
                type='textarea'
                name='placeholder'
                id='placeholder'
                value={values.placeholder}
                className='w-100 form-control'
                maxRows='10'
                minRows={5}
                placeholder='Enter the title'
              />
              <FormFeedback>{errors.placeholder}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm mt-4" for="defaultContent">
                Default Content
              </Label>
              <TextareaAutosize
                type='textarea'
                name='defaultContent'
                id='defaultContent'
                value={values.defaultContent}
                className='w-100 form-control'
                maxRows='10'
                minRows={5}
                placeholder='Enter the title'
              />
              <FormFeedback>{errors.defaultContent}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm mt-4" for="feedback">
                Feedback Instructions:
              </Label>
              <TextareaAutosize
                type='textarea'
                name='feedback'
                id='feedback'
                value={values.feedback}
                className='w-100 form-control'
                maxRows='10'
                minRows={5}
                placeholder='Enter the title'
              />
              <FormFeedback>{errors.feedback}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm mt-4" for="scoring">
                Scoring Instructions:
              </Label>
              <TextareaAutosize
                type='textarea'
                name='scoring'
                id='scoring'
                value={values.scoring}
                className='w-100 form-control'
                maxRows='10'
                minRows={5}
                placeholder='Enter the title'
              />
              <FormFeedback>{errors.scoring}</FormFeedback>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default TemplateDocumentation;
