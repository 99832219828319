import React, { useState, useEffect } from "react";

//boostrap
import { Container, Row, Col, Button, FormGroup, Label } from "reactstrap";

//components
import { TagFilterSelect, CategoryFilterSelect, ModalAddTag, ReviewAccountDropdown } from "../../AnalyzeReviews/Filter";

//api
import { formatReactSelect } from "@utils";

//common
import { ModalDeleteCommon } from "@ui-partents/Modal";

//constants
import { validateConstant } from "@constants";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";

function FitterTop(props) {
    //props
    const { handleSubmitFilter, isLoading, handleUpdateNewData, handleTagsForValidationByCategory, fetchAccountsByCategory, optionsData } = props && props;
    const dispatch = useDispatch();

    //state
    const [loadingModal, setLoadingModal] = useState({
        deleteTag: false
    });
    const [selectedOption, setSelectedOption] = useState({
        category: { label: '', value: '' },
        tags: { label: '', value: '' },
        account: null
    });
    const [modal, setModal] = useState({
        addTag: false,
        deleteTag: false,
    });
    const [dataCurrent, setDataCurrent] = useState({});
    const [filterData, setFilterData] = useState({
        "tags": "",
        "category": "",
        "account": null
    });

    const [dataSelect, setDataSelect] = useState({
        category: [],
        tags: [],
        account: null
    });


    //Fetch All Category
    useEffect(() => {
        const dropdownSelect = optionsData?.category?.length > 0 ? formatReactSelect(optionsData?.category, 'code', 'name') : [];
        setDataSelect(prevData => ({
            ...prevData,
            category: dropdownSelect
        }));
    }, [optionsData])

    //Fetch All Tag for Validation
    useEffect(() => {
        const dropdownSelect = optionsData?.tags?.length > 0 ? formatReactSelect(optionsData?.tags, 'tag_id', 'name') : [];
        setDataSelect(prevData => ({
            ...prevData,
            tags: dropdownSelect
        }));
    }, [optionsData])

    //Fetch accounts by category
    useEffect(() => {
        const dropdownSelect = optionsData?.account?.length > 0 ? formatReactSelect(optionsData?.account) : [];
        setDataSelect(prevData => ({
            ...prevData,
            account: dropdownSelect
        }));
    }, [optionsData])

    //Handle Change Dropdown
    const handleChangeOptions = (data, name) => {
        setSelectedOption(prevData => ({
            ...prevData,
            [name]: data
        }));

        // Xử lý khi name là 'account'
        if (name === 'account') {
            const accountValues = data.map(option => option.value);
            setFilterData(prevData => ({
                ...prevData,
                [name]: accountValues
            }));
        } else {
            setFilterData(prevData => ({
                ...prevData,
                [name]: data?.value
            }));
        }

        // Nếu chọn category thì call API tags
        if (name === 'category' && data) {
            handleResetDataDropdown();
            handleTagsForValidationByCategory(data);
            fetchAccountsByCategory(data);
        }
    };

    // Reset Dropdown When Category Change
    const handleResetDataDropdown = () => {
        // reset Tag
        setSelectedOption(prevData => ({
            ...prevData,
            tags: { label: '', value: '' },
            account: null
        }));
    }

    //Hanldle Add Tag
    const toggle = (type, value) => {
        setModal((prevState) => ({
            ...prevState,
            [type]: !prevState[type]
        }));
        setDataCurrent(value);
    };

    // Handle Remove Tag
    const handleRemoveTag = () => {
        const params = {
            "action_type": "delete_tag_item",
            "tag_id": dataCurrent?.value
        }
        setLoadingModal((prevState) => ({ ...prevState, deleteTag: true }));
        //Call Api
        dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_REQUEST, isLoading });
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                handleUpdateNewData(data);
                dispatch({
                    type: validateConstant.GET_TAGS_FOR_VALIDATION_SUCCESS,
                    payload: data
                });
                setLoadingModal((prevState) => ({ ...prevState, deleteTag: false }));
                toggle('deleteTag', dataCurrent);
                setSelectedOption(prevData => ({
                    ...prevData,
                    tags: {
                        label: '',
                        value: ''
                    }
                }));
            } else {
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_FAILURE, error: 'error' });
                setLoadingModal((prevState) => ({ ...prevState, deleteTag: false }));
                toggle('deleteTag', dataCurrent);
            }
        });
    }
    
    return (
        <Container className="border p-4 rounded mb-3">
            <Row className="align-items-center">
                <Col md={`3`}>
                    <CategoryFilterSelect
                        {...props}
                        handleChangeOptions={handleChangeOptions}
                        dataSelect={dataSelect}
                        selectedOption={selectedOption}
                    />
                </Col>
                <Col md={`3`}>
                    <TagFilterSelect
                        {...props}
                        handleChangeOptions={handleChangeOptions}
                        dataSelect={dataSelect}
                        selectedOption={selectedOption}
                        toggle={toggle}
                        disabled={filterData?.category !== "" ? false : true} //disable khi chua chon category
                    />
                </Col>
                <Col md={`6`}>
                    <FormGroup>
                        <Label className="font-weight-500" htmlFor="Review_Accounts">
                            Review Accounts
                        </Label>
                        <div className="d-flex align-items-center">
                            <div style={{ width: `calc(100% - 120px)` }}>
                                <ReviewAccountDropdown
                                    {...props}
                                    handleChangeOptions={handleChangeOptions}
                                    dataSelect={dataSelect}
                                    selectedOption={selectedOption}
                                    disabled={filterData?.category !== "" ? false : true} //disable khi chua chon category
                                />
                            </div>
                            <div style={{ width: '120px' }}>
                                <Button
                                    className="--btn-primary ml-2 text-nowrap"
                                    onClick={() => handleSubmitFilter(filterData)}
                                    disabled={filterData?.category !== "" && selectedOption?.account ? false : true} //disable button khi chua chon category
                                >
                                    {isLoading?.analyzeReviews && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                    Analyze
                                </Button>
                            </div>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
            {filterData?.category === "" && (
                <Row>
                    <Col md={12}><p className="--text-primary text-1">(*) Select a Category to start reviewing data</p></Col>
                </Row>
            )}
            <ModalAddTag
                {...props}
                isOpen={modal?.addTag}
                toggle={() => toggle('addTag', dataCurrent)}
                filterData={filterData}
            />
            <ModalDeleteCommon
                title={`Are you sure you want to delete this record ?`}
                sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
                isOpen={modal?.deleteTag}
                toggle={() => toggle('deleteTag', dataCurrent)}
                handleRemove={handleRemoveTag}
                isLoading={loadingModal?.deleteTag}
            />
        </Container>
    );
}

export default FitterTop;