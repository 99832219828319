import React from 'react';
import { toast } from 'react-toastify';

export const Toasts = (props) => {
    const { data } = props && props;

    const closeToast = (event) => {
        event.preventDefault();
        toast.dismiss();
    }

    return (
        <div className="row toastifyCt" >
            <div className="col-md-12">
                {data?.Title &&
                    <h5 className="toastifyCt__heading">{data?.Title}</h5>
                }
                {data?.Message &&
                    <div className="toastifyCt__detail" dangerouslySetInnerHTML={{ __html: data?.Message }}></div>
                }
                <div className="d-flex justify-content-end">
                    <a href="# " className="btn btn-transparent text-white" onClick={closeToast}>Got It</a>
                </div>
            </div>
        </div>
    );
};