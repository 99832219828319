import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components
import { AssignTemplate, OptionView, AddTemplate } from '../EndingTimepoint';

//common
import { DataEmty } from "@components-common";

//message
import { messageData } from "@utils";

//boostrap
import {
  Col,
  Row,
} from "reactstrap";

//lodash
import _ from "lodash";

//actions
import { atcUpdateFeedbackTemplate } from "@actions";

function UpdateEndingTimePoint(props) {
  
  //props
  const { data } = props && props;
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [typeActive, setTypeActive] = useState(_.isEmpty(data) ? "standard" : "existing");

  useEffect(() => {
    setTypeActive(_.isEmpty(data) ? "standard" : "existing");
  }, [data]);

  const handleChangeRaido = (name) => {
    setTypeActive(name);

    //reset
    if (name === 'standard') {
      updateFeedbackTemplateIsNull();
    }
  }

  const updateFeedbackTemplateIsNull = () => {
    const params = {
      Action: "Update",
      Target: "SelectFeedbackTemplate",
      FeedbackTemplateId: null
    }
    dispatch(atcUpdateFeedbackTemplate(id, params));
  }

  return (
    <Row>
      <Col md={3}>
        <OptionView
          handleChangeRaido={handleChangeRaido}
          active={typeActive}
        />
      </Col>
      <Col md={9} className="border-left">
        {typeActive !== "standard" ? (
          <React.Fragment>
            {typeActive === "existing" &&
              <AssignTemplate {...props} />
            }
            {typeActive === "new" &&
              <AddTemplate />
            }
          </React.Fragment>
        ) : (
          <Row>
            <Col sm={12} className="my-4 text-center">
              <DataEmty hiddenIcon={true} content={messageData?.feedbackTemplate?.content} />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default UpdateEndingTimePoint;
