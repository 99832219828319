import React from "react";
import Draggable from 'react-draggable'; 

function VitalsignsOverlay(props) {
    const { caseData } = props && props;
    const listCase = caseData?.data?.Case;
    const propsVitalSigns = listCase?.ClinicalStatus?.VitalSigns;
    const dataVitalSigns = propsVitalSigns && Object.entries(propsVitalSigns);
    var objectListItems = [];
    var objectDiastolicBloodPressure = {};
    dataVitalSigns.map((value, index) => {
        if (value[0] === "Diastolic Blood Pressure") {
            objectDiastolicBloodPressure = value;
        } else {
            objectListItems.push(value);
        }
        return true;
    });

    const renderName = (value) => {
        let showName = '';
        let varName = value[1]?.Name;
        if (varName.includes("Temp")) {
            showName = 'Temp';
        } else if (varName.includes("Respiratory Rate")) {
            showName = 'RR';
        } else if (varName.includes("O2 saturation")) {
            showName = 'SpO2';
        } else if (varName.includes("Blood")) {
            showName = 'BP';
        } else if (varName.includes("Heart")) {
            showName = 'HR';
        } else {
            showName = varName;
        }
        return showName;
    }

    const renderColorByName = (value) => {
        let className = '';
        if (value.includes("Temp")) {
            className = 'txt-green';
        } else if (value.includes("RR")) {
            className = 'txt-yellow';
        } else if (value.includes("SpO2")) {
            className = 'txt-blue';
        } else if (value.includes("HR")) {
            className = 'txt-pink';
        } else {
            className = 'text-white';
        }
        return className;
    }

    const sortData = objectListItems?.sort((x) => x[0] === "Systolic Blood Pressure" ? -1 : 0);
    return (
        <Draggable>
            <div className="vital-box">
                <div className="list-vital-overlay">
                    <div className="list-vital-overlay__item row p-4">
                        {sortData?.map((value, index) => {
                            const nameItem = renderName(value);
                            return (
                                <div className={`${nameItem === 'BP' ? "col-md-12" : "col-md-6 divide-top"} py-2`} key={index}>
                                    <div className={`vital-unit ${renderColorByName(renderName(value))}`}>
                                        <span className={`vital-unit__lg`}>{nameItem}</span>
                                        <span className={`vital-unit__sm`}>
                                            {value[1]?.Unit.includes("/min") ? "bpm" : value[1]?.Unit}
                                        </span>
                                    </div>
                                    <div className={`list-vital-overlay__item--name ${renderColorByName(renderName(value))}`}>
                                        {value[1]?.Name === "Systolic Blood Pressure" ? value[1]?.CurrentValue + "/" + (value[1]?.Name === "Diastolic Blood Pressure" ? objectListItems[1]?.CurrentValue : objectDiastolicBloodPressure[1]?.CurrentValue) : value[1]?.CurrentValue}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Draggable>
    );
}

export default VitalsignsOverlay;
