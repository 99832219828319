import React from 'react';
import styled from 'styled-components';

const MedicalDescription = styled.div`
    margin-top: 24px;
    padding: 16px;
    border-radius: 8px;
    height: calc(100vh - 500px);
    background: #fff;
    overflow: auto;
`;

const MedicalDescriptionContent = styled.div`
    color: #4b5565;
    font-size: 16px;
    font-weight: 400;
`;

const MedicalDescriptionBox = ({ content }) => (
    <MedicalDescription>
        <MedicalDescriptionContent dangerouslySetInnerHTML={{ __html: content }} />
    </MedicalDescription>
);

export default MedicalDescriptionBox;