import React from "react";
import { ModalDeleteCommon } from "@ui-partents/Modal";

const DeleteModal = ({ isOpen, toggle, isLoading, handleRemove }) => {
    return (
        <ModalDeleteCommon
            title={`Delete Food Item`}
            sub={`Are you sure you want to delete this item? This action is not reversible.`}
            isOpen={isOpen}
            toggle={toggle}
            isLoading={isLoading}
            handleRemove={handleRemove}
        />
    );
}

export default DeleteModal;