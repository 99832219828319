import React from 'react'
import { Icon } from '@components/common'

import styled from 'styled-components'

const WrapHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 6px;
  background: #EBF9FF;
  width: 100%;
  .wrap-icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => (props.isLight ? `#FFF` : "#0089C2")};
    border-radius: 50%;
  }
  .wrap-title {
    font-size: 18px;
    color: #101828;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
`

export default function HeadingTitle(props) {
  const { icon, title, isLight } = props
  
  return (
    <WrapHeading isLight={isLight}>
      <div className="wrap-icon">
        <Icon src={icon} color={isLight ? "#0089C2" : "#fff"}/>
      </div>
      <p className="wrap-title">{title}</p>
    </WrapHeading>
  )
}
