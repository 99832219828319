import React, { useState } from "react";
// import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//components
import { ModeTop, RolesMain } from "@components/elements/Teams";

import { HeadingBox } from "@components/common";

function TeamsPage(props) {
    //props
    const { caseDetails } = props && props;
    const TeamsInfo = caseDetails?.TeamsInfo;

    //state
    const [isStatus, setStatus] = useState(true);

    //titlePage
    const titlePage = caseDetails?.Title;

    const handeleChangeStatus = (e) => {
        const { value } = e.target;
        var isTrueSet = (value === 'true');
        setStatus(isTrueSet);
    }

    return (
        <div className="wrapper -eTeamsPage">
            <section className="eTeamsCt">
                <Container>
                    <HeadingBox title={titlePage || ""} currentPage="Set up team roles" />
                    <ModeTop
                        {...props}
                        handeleChangeStatus={handeleChangeStatus}
                        isStatus={isStatus}
                    />
                    {isStatus && (
                        <RolesMain 
                            {...props}
                            data={TeamsInfo}
                        />
                    )}
                </Container>
            </section>
        </div>
    );
}

export default TeamsPage;