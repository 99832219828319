import {
  teachConstants,
  templatesConstants,
  reportConstant,
  humanConstants,
  caseConstants,
} from "@constants";
import _ from "lodash";

export function teachCase(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case teachConstants.TEACH_CASE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.TEACH_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        listDomain: action.payload && action.payload.Domains,
        isLoading: false,
      };
    case teachConstants.UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.UPDATE_TEACH_CASE_SUCCESS:
      const stateData = { ...state };
      const titleUpdate = action.payload && action.payload.Title;
      const statusUpdate = action.payload && action.payload.Status;
      const dataUpdate = stateData && stateData.data;
      var objectNew = _.assign(
        {},
        dataUpdate,
        titleUpdate ? { Title: titleUpdate } : { Status: statusUpdate }
      );
      return {
        data: objectNew,
        isLoading: false,
      };
    case teachConstants.UPDATE_DOMAIN_SUCCESS:
      newState = { ...state };
      newState.data.Domains = (action.payload && action.payload.Domains) || [];
      return {
        ...state,
        isLoading: false,
      };
    case teachConstants.COPY_DOMAINS_FROM_SOURCE_CASE_SUCCESS:
      newState = { ...state };
      newState.data.Domains = (action.payload && action.payload.Domains) || [];
      return {
        ...state,
        isLoading: false,
      };
    case reportConstant.REPORT_CASE_COMPELETED_SUCCESS:
      newState = { ...state };
      if (newState.data) {
        newState.data.ReportLearnersCompleted = action?.payload || {};
      }
      return {
        ...newState,
        isLoading: false,
      };
    case humanConstants.UPLOAD_IMAGE_HUMAN_MODEL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.CREATE_REFERENCE_IN_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.REMOVE_REFERENCE_IN_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    // Case - Update Take-Home Message
    case caseConstants.TAKE_HOME_MESSAGE_SUCCESS:
      newState = { ...state };
      newState.data.TakeHomeMessage = action?.payload?.TakeHomeMessage
      newState.data[`TakeHomeMessageSections`] = action?.payload?.TakeHomeMessageSections
      return {
        ...newState,
        isLoading: false
      };
    case teachConstants.TEACH_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function createCase(state = {}, action) {
  switch (action.type) {
    case teachConstants.CREATE_CASE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.CREATE_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        status: action.payload && action.payload.status,
      };
    case teachConstants.CREATE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function deleteCase(state = {}, action) {
  switch (action.type) {
    case teachConstants.DELETE_CASE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.DELETE_CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.DELETE_CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function caseLearnersList(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case teachConstants.GET_CASE_LEARNERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.GET_CASE_LEARNERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.PUT_ENROLL_SUCCESS:
      newState = { ...state };
      newState.data.Learners = action.payload && action.payload.Learners;
      return {
        ...newState,
        isLoading: false,
      };
    case teachConstants.REMOVE_LEARNERS_SUCCESS:
      newState = { ...state };
      newState.data.Learners = action.payload && action.payload.Learners;
      return {
        ...newState,
        isLoading: false,
      };
    case teachConstants.GET_CASE_LEARNERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadQuestion(state = {}, action) {
  switch (action.type) {
    case teachConstants.LOAD_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.LOAD_QUESTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.LOAD_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function allTemplate(state = {}, action) {
  switch (action.type) {
    case templatesConstants.ALL_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case templatesConstants.ALL_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case templatesConstants.ALL_TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadTemplates(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case templatesConstants.TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case templatesConstants.TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case templatesConstants.CREATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.payload && action.payload.TemplateList,
        isLoading: false,
      };
    case templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_SUCCESS:
      newState = { ...state }
      newState.data.SystemTemplates = action.payload.Templates
      return {
        ...newState,
        isLoading: false
      };
    case templatesConstants.CREATE_TEMPLATE_COLLECTION_SUCCESS:
      newState = { ...state }
      newState.data.TemplateCollections = action.payload
      return {
        ...newState,
        isLoading: false
      };
    case templatesConstants.DELETE_COLLECTION_ID_SUCCESS:
      newState = { ...state }
      newState.data.TemplateCollections = action.payload
      return {
        ...newState,
        isLoading: false
      };
    case templatesConstants.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        data: action.payload && action.payload.TemplateList,
        isLoading: false,
      };
    case templatesConstants.TEMPLATE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadCandidatesForLearner(state = {}, action) {
  switch (action.type) {
    case teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.LOAD_CANDIDATES_FOR_LEARNERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadAllQuestion(state = {}, action) {
  switch (action.type) {
    case teachConstants.LOAD_ALL_QUESTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.LOAD_ALL_QUESTION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.LOAD_ALL_QUESTION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadTechHome(state = {}, action) {
  switch (action.type) {
    case teachConstants.TEACHER_HOME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.TEACHER_HOME_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.TEACHER_HOME_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Load Template Collection
export function listTemplateCollectionById(state = {}, action) {
  switch (action.type) {
    case templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Load Question for FLowControl
export function listAnswerbyQuestionId(state = {}, action) {
  switch (action.type) {
    case teachConstants.LOAD_QUESTION_FLOW_CONTROL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.LOAD_QUESTION_FLOW_CONTROL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.LOAD_QUESTION_FLOW_CONTROL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

//Case - Load Questions From Templates (for potentially adding to new case)
export function listQuestionFormTemplates(state = {}, action) {
  switch (action.type) {
    case teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachConstants.LOAD_QUESTIONS_FROM_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

//Task Active
export function statusTaskActive(state = {}, action) {
  switch (action.type) {
    case teachConstants.TEACH_TASK_ACTIVE:
      return {
        ...state,
        currentTask: action.payload
      };
    case teachConstants.UPDATE_TASK_ACTIVE:
      return {
        ...state,
        currentTask: action.payload
      };
    default:
      return state;
  }
}

//Task Active
export function questionViewActive(state = {}, action) {
  switch (action.type) {
    case teachConstants.QUESTION_VIEW_ACTIVE:
      return {
        ...state,
        questionView: action.payload
      };
    default:
      return state;
  }
}