import React from "react";

//react boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//components
import { HelperList, HelperContent } from "../Helper/";

function ModalHelper(props) {
  const {
    isOpen,
    toggle,
    data,
    handleChange,
    itemActive,
    showContent
  } = props && props;
  return (
    <Modal
      style={{ maxWidth: "800px", padding: "0 10px" }}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">
          <i className="fa fa-question-circle mr-2" aria-hidden="true"></i>How
          can we help?
        </span>
      </ModalHeader>
      <ModalBody>
        {/* list */}
        {data && data.length > 1 && !showContent && (
          <HelperList handleChange={handleChange} data={data} />
        )}
        {/* One data */}
        {data.length === 1 && <HelperContent data={(data && data[0]) || {}} />}
        {/* Click element */}
        {showContent && itemActive && <HelperContent data={itemActive || {}} />}
      </ModalBody>
      <ModalFooter>
        {showContent && (
          <Button outline color="info" onClick={handleChange}>
            Back
          </Button>
        )}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalHelper;
