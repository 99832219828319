import React from 'react';
import { EmojiFace } from '../Feedback';

//Icon
import iconPoor from "@images/learnerv2/pic-poor.png";
import iconFair from "@images/learnerv2/pic-fair.png";
import iconGood from "@images/learnerv2/pic-good.png";
import iconVeryGood from "@images/learnerv2/pic-very-good.png";
import iconExcellent from "@images/learnerv2/pic-excellent.png";

function EmojiList({onEmojiChange, formData}) {
    const icons = [
        { src: iconPoor, alt: "Poor" },
        { src: iconFair, alt: "Fair" },
        { src: iconGood, alt: "Good" },
        { src: iconVeryGood, alt: "Very Good" },
        { src: iconExcellent, alt: "Excellent" }
    ];


    return (
        <div className="question-feel question-fb-group">
            <p className="question-title">How do you feel about the case?</p>
            <div className="emoji-list">
                {icons.map((icon, index) => (
                    <EmojiFace
                        key={index}
                        src={icon.src}
                        alt={icon.alt}
                        isSelected={formData?.OverallImpression === icon?.alt}
                        hasSelection={formData?.OverallImpression !== null}
                        onSelected={() => onEmojiChange(icon.alt)}
                    />
                ))}
            </div>
        </div>
    );
}

export default EmojiList;