import React from 'react';

function TableHeader(props) {
    const { data } = props && props;
    return (
        <thead className="table-row">
            <tr>
                <th scope="col" width={`20%`}></th>
                {data?.map((col, index) => (
                    <th scope="col" width={`20%`} className={`align-middle font-weight-500 --text-primary`} key={index}>
                        {col?.DisplayName}
                    </th>
                ))}
                <th scope="col" className="text-center align-middle" width={`5%`}></th>
            </tr>
        </thead>
    );
};

export default TableHeader;