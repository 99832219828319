import { medicationConstants } from "@constants";
import {apiCaller} from "@utils";

//Load Medications
export const atcLoadMedications = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: medicationConstants.LOAD_MEDICATIONS_REQUEST, isLoading } }
    function success(data) { return { type: medicationConstants.LOAD_MEDICATIONS_SUCCESS, payload: data } }
    function failure(error) { return { type: medicationConstants.LOAD_MEDICATIONS_FAILURE, error: error } }
}

// Case - Delete Medication Category
export const atcDeleteMedicationCategory = (id, params, categoryId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data, categoryId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: medicationConstants.DELETE_MEDICATION_CATEGORY_REQUEST, isLoading } }
    function success(data, categoryId) {
        return {
            type: medicationConstants.DELETE_MEDICATION_CATEGORY_SUCCESS,
            payload: data,
            categoryId: categoryId
        }
    }
    function failure(error) { return { type: medicationConstants.DELETE_MEDICATION_CATEGORY_FAILURE, error: error } }
}

//Case - Update Medication Availability
export const updateAvailabilityForMedication = (id, params, categoryId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data, categoryId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: medicationConstants.UPDATE_AVAILABILITY_FOR_MEDICATION_REQUEST, isLoading } }
    function success(data, categoryId) {
        return {
            type: medicationConstants.UPDATE_AVAILABILITY_FOR_MEDICATION_SUCCESS,
            payload: data,
            categoryId: categoryId
        }
    }
    function failure(error) { return { type: medicationConstants.UPDATE_AVAILABILITY_FOR_MEDICATION_FAILURE, error: error } }
}

//Case - Update Attribute Score (Medication)
export const atcUpdateScoreMedication = (id, params, AttributeId, categoryId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data, AttributeId, categoryId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { 
        return { type: medicationConstants.UPDATE_ATTRIBUTE_SCORE_MEDICATION_REQUEST, isLoading } 
    }
    function success(data, attributeId) {
        return {
            type: medicationConstants.UPDATE_ATTRIBUTE_SCORE_MEDICATION_SUCCESS,
            payload: data,
            attributeId: attributeId,
            categoryId: categoryId
        }
    }
    function failure(error) { return { type: medicationConstants.UPDATE_ATTRIBUTE_SCORE_MEDICATION_FAILURE, error: error } }
}

//Case - Update Intervention Available For Medication 
export const updateAvailableForMedication = (id, params, interventionId, categoryId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data, interventionId, categoryId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: medicationConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_REQUEST, isLoading } }
    function success(data, interventionId, categoryId) {
        return {
            type: medicationConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_SUCCESS,
            payload: data,
            categoryId: categoryId,
            interventionId: interventionId
        }
    }
    function failure(error) { return { type: medicationConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_MEDICATION_FAILURE, error: error } }
}