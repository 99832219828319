import React, { useState, useEffect } from 'react';

//reactstrap
import { Row, Col } from "reactstrap";

//thumbDefault
import thumbDefault from "@images/common/placeholder.png";

//elements
import { ButtonEdit } from '@components-common/elements';

//components
import {
  AddNote,
  AddCondition,
  SortCaseList,
  BriefViewMain
} from '.';

//Paginate
import { PaginatedItems } from '@components-common';

//lodash
import isEqual from 'lodash/isEqual';

function CaseList(props) {
  //props
  const { data, activeSeries, isBrief } = props && props;

  //state
  const [stateData, setData] = useState((data && data) || []);

  //load Data
  useEffect(() => {
    setData((data && data) || []);
  }, [data])

  //set Data Pagination
  const handleItemsPerPage = (dataPerPage) => {
    if (!isEqual(data, dataPerPage)) {
      setData(dataPerPage || []);
    }
  }

  return (
    <React.Fragment>
      {activeSeries === "all_case" ? (
        <PaginatedItems
          itemsPerPage={5}
          items={(data && data) || []}
          handleItemsPerPage={handleItemsPerPage}
        >
          {stateData?.map((item, i) => (
            <React.Fragment key={i}>
              <li className={`ListSortableElement__item notDarg -box-shadow`}>
                <div className="innerCt p-2">
                  <div className="innerCt__left">
                    <img src={item?.Thumbnail || thumbDefault} alt={item?.Title} />
                  </div>
                  <div className="innerCt__right">
                    <Row className="align-items-center">
                      <Col md={9} sm={12}>
                        <h6 className="title">
                          {item?.Title}
                          <span className="text-muted ml-2 text-1">{`(#${item?.Id})`}</span>
                        </h6>
                        <p className="time">
                          <span className="time__title --text-primary">
                            Active
                          </span>
                          {/* –Last Edited: 2021-05-01 */}
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end" md={3} sm={12}>
                        <ButtonEdit linkRef={true} url={`/teach/case/${item?.Id}`} />
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="d-flex border-top align-items-center justify-content-between">
                  <AddNote item={item} />
                  <AddCondition />
                </div>
              </li>
            </React.Fragment>
          ))}
        </PaginatedItems>
      ) : (
        <div>
          {isBrief ? (
            <BriefViewMain {...props} data={stateData || []} />
          ) : (
            <SortCaseList {...props} data={stateData || []} />
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default CaseList;