import React from 'react';

//check Html
import { hasTextInside } from "@utils";

function UserActive(props) {
    const { userNameActive, image, RoleDefault } = props && props;
    return (
        <div className="userActive-chat">
            <div
                className="userActive-chat__item active"
                style={{
                    backgroundImage: `url(${image})`,
                    cursor: 'pointer'
                }}
            ></div>
            <div className="userActive-chat__name">
                {hasTextInside(userNameActive) ? (
                    <div dangerouslySetInnerHTML={{ __html: userNameActive }} />
                ) : (
                    <div>{RoleDefault}</div>
                )}
            </div>
        </div>
    );
};

export default UserActive;