import React, { useEffect } from 'react';

//reacttrap
import { FormGroup, Label, Input } from 'reactstrap';

//redux
import { useDispatch, useSelector } from "react-redux";
import { actFetchLoadAvailableFeedbackTemplates } from "@actions";

function DropdownTemplate(props) {
    const dispatch = useDispatch();

    //props
    const { hanldeChange, feedbackTemplateId, typeActive } = props && props;

    //load page
    useEffect(() => {
        //load api dropdown
        dispatch(actFetchLoadAvailableFeedbackTemplates());
    }, [dispatch])

    //get data store
    const storeTemplate = useSelector(
        (state) => state.ListAvailableFeedbackTemplates || {}
    );

    const dataTemplate = storeTemplate?.data;
    return (
        <FormGroup>
            <Label for="FeedbackTemplateId">
                Feedback Template:
            </Label>
            <Input
                id="FeedbackTemplateId"
                name="FeedbackTemplateId"
                type="select"
                placehoder="select a feedback template"
                onChange={hanldeChange}
                value={feedbackTemplateId}
            >
                {typeActive === 'new' &&
                    <option value={``}>
                        Select a feedback template
                    </option>
                }
                {dataTemplate?.map((value, index) => {
                    return (
                        <option value={value?.FeedbackTemplateId} key={index}>{value?.Name}</option>
                    );
                })}
            </Input>
        </FormGroup>
    );
};

export default DropdownTemplate;