import React, { useState, useEffect } from "react";

//Components
import { AvailableTasksDropdown, SelectedTasksList } from "../CaseTimeline";
// import { AddTimePoint } from "../TimePoint";
import { TimepointContent } from "../TimeLineLayout";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//api
import { apiCaller } from "@utils";

//constants
import { storyboardConstants } from "@constants";

//actions
import { actUpdateTaskActive, actUpdateUpdateAvailableTask } from "@actions";

const CaseTimelineLeftPanel = (props) => {
    let { id } = useParams();
    let dispatch = useDispatch();

    //store
    const timepointData = useSelector((state) => state?.timepoint?.data || []);

    // Props
    const { timelineData: initialTimelineData, timepointIdActive } = props && props;
    
    // State
    const [timelineData, setTimelineData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedTasksList, setSelectedTasksList] = useState([]);


    // Set Task
    useEffect(() => {
        const TaskElements = timepointData?.TaskElements || [];
        const defaultSelectedTasks = TaskElements.filter(task => task.Status === "Active");
        setSelectedTasksList(defaultSelectedTasks);
    }, [timepointData?.TaskElements]);

    // Set Timline
    useEffect(() => {
        if (Array.isArray(initialTimelineData)) {
            const endingPage = {
                Id: 9999,
                DisplayName: "Ending Page",
                Time: 100,
                DefaultActiveStatus: true
            };
            setTimelineData([...initialTimelineData, endingPage]);
        }
    }, [initialTimelineData]);
    
    // Click Active/Inactive Task
    const handleApiUpdateTaskActive = async (mode, task) => {
        setLoading(true);
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timepointIdActive,
            Flow: {
                Mode: mode,
                Task: task,
            },
        };

        try {
            const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
            const data = res?.data;

            if (res?.status === 200) {
                dispatch({
                    type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_SUCCESS,
                    payload: data
                });
                setLoading(false);
                return data;
            } else {
                dispatch({
                    type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_FAILURE,
                    error: 'API call failed'
                });
                setLoading(false);
                throw new Error('API call failed');
            }
        } catch (error) {
            dispatch({
                type: storyboardConstants.UPDATE_STATUS_TAKS_ELEMENTS_FAILURE,
                error: error.message || 'An error occurred while calling API'
            });
            setLoading(false);
            throw new Error(error.message || 'An error occurred while calling API');
        }
    };

    // Handle Task active
    const handleUpdateTaskActive = (task) => {
        dispatch(actUpdateTaskActive(task));
    };

    const onToggleItem = async (item) => {
        try {
            const mode = item.Status === "Active" ? "deactivate" : "activate";
            const result = await handleApiUpdateTaskActive(mode, item.ViewClass);
            if (result?.status === "success") {
                let updatedSelectedTasksList = [];
                if (mode === "activate") {
                    updatedSelectedTasksList = [...selectedTasksList, item];
                } else {
                    updatedSelectedTasksList = selectedTasksList.filter(task => task.ViewClass !== item.ViewClass);
                }
                setSelectedTasksList(updatedSelectedTasksList);
                const clonedTaskElements = [...timepointData?.TaskElements];
                const index = clonedTaskElements.findIndex(task => task.ViewClass === item.ViewClass);
                clonedTaskElements[index] = {
                    ...clonedTaskElements[index],
                    Status: mode === "activate" ? "Active" : "Inactive"
                };
                dispatch(actUpdateUpdateAvailableTask(clonedTaskElements));
            }
        } catch (error) {
            // Handle error here
        }
    };

    const renderTimePoint = (timePoint) => (
        <TimepointContent
            {...props}
            key={timePoint.Id}
            id={timePoint.Id}
            title={timePoint.DisplayName}
        >
            {timePoint.Id !== 9999 &&
                <AvailableTasksDropdown
                    {...props}
                    handleApiUpdateTaskActive={handleApiUpdateTaskActive}
                    onToggleItem={onToggleItem}
                />
            }
            <SelectedTasksList
                {...props}
                selectedTasksList={selectedTasksList}
                handleUpdateTaskActive={handleUpdateTaskActive}
                isLoading={isLoading}
            />
        </TimepointContent>
    );
    
    return (
        <div className="case-timelineCt__left">
            <div className="case-timpoint-vertical">
                {timelineData.map(renderTimePoint)}
            </div>
        </div>
    );
};

export default CaseTimelineLeftPanel;