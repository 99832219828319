import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, FormGroup, Label, Input } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//action
import { actUpdatePNOrderTFUnit } from "@actions";

function SwithRaido(props) {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { value } = props && props;
  const checkedUnit = value["TF-Unit"];
  const [isChecked, setChecked] = useState((checkedUnit && checkedUnit) || "");
  const [isChange, setChange] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    setChecked(value);
    setChange(true);
  };

  useEffect(() => {
    if (isChange) {
      setChange(false);
      const params = {
        Action: "Update",
        Target: "PNOrder",
        "TF-Unit": isChecked,
      };
      dispatch(actUpdatePNOrderTFUnit(id, params));
    }
  }, [dispatch, id, isChange, isChecked]);
  
  return (
    <Form className="d-flex align-items-center">
      <FormGroup className="pl-0" check inline>
        <legend className="font-weight-500 mr-3 mb-0">Unit</legend>
        <Label className="d-flex align-items-center mb-0">
          <Input
            type="radio"
            name="unitRadio"
            defaultChecked={isChecked === `ml/kg/day` ? true : false}
            value={`ml/kg/day`}
            onClick={handleChange}
          />
          ml/kg/day
        </Label>
      </FormGroup>
      <FormGroup check inline>
        <Label className="d-flex align-items-center mb-0">
          <Input
            type="radio"
            name="unitRadio"
            defaultChecked={isChecked === `ml/day` ? true : false}
            value={`ml/day`}
            onClick={handleChange}
          />
          ml/day
        </Label>
      </FormGroup>
    </Form>
  );
}

export default SwithRaido;
