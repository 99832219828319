import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//reactstrap
import { Row, Col } from "reactstrap";

//components
import { ChatTableClick } from "./Bock";

//actions
import { loadChatTopicsAllTimepoints } from "@actions";

//common
import { LoaderText } from "@utils";

//components
import { ChatMode, MaxQuestionInput, NatureOfInteraction } from '../Chat/Bock';

function ChatContents(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  // const { timepointData } = props && props;
  // const chatModeParams = timepointData?.data?.ChatMode;

  //state
  // const [isChatMode, setChatMode] = useState((chatModeParams && chatModeParams) || '');

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "Chat",
      };
      dispatch(loadChatTopicsAllTimepoints(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  // const handleChangeMode = (type) => {
  //   setChatMode(type);
  // }

  //storeChat
  const storeChat = useSelector(
    (state) => state?.loadChatTopics || {}
  );

  // const listData = storeChat?.data;
  return (
    <div className="ChatTab position-relative">
      {(storeChat.isLoading &&
        <div style={{ height: '400px' }}><LoaderText /></div>
      )}
      <Row className='mb-2 align-items-center'>
        <Col md={6}>
          <ChatMode {...props} />
          {/* handleChangeMode={handleChangeMode} */}
        </Col>
        <Col md={6}>
          <MaxQuestionInput {...props} />
          <NatureOfInteraction {...props} />
        </Col>
      </Row>
      <ChatTableClick {...props} />
      {/* {isChatMode === 'freetext' ? (
        <ChatTable {...props} storeChat={storeChat} data={(listData && listData) || []} />
      ) : (
        <ChatTableClick {...props} />
      )} */}
    </div>
  );
}

export default ChatContents;