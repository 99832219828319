import React, { useEffect, useState } from "react";
import { Table } from 'reactstrap';

//lodash
import _ from "lodash";

function TotalIO (props) {
  const { timepointData } = props && props;
  const timepointDetails = timepointData?.data;
  const IOData = timepointDetails?.IO;
  const Intake = IOData?.Intake;
  const Output = IOData?.Output;

  //state
  const [stateIntake, setIntake] = useState((Intake && Intake) || []);
  const [stateOutput, setOutput] = useState((Output && Output) || []);

  useEffect(() => {
    setIntake((Intake && Intake) || []);
    setOutput((Output && Output) || []);
  }, [Intake, Output]);

  const convertStringAndSum = (data) => {
    const arrNumberIsString = _.map(data, 'Value');
    const arrToNumber = arrNumberIsString.map(Number);
    const totalCurrent = _.sum(arrToNumber);
    return totalCurrent;
  }

  const totalIntake = convertStringAndSum([...stateIntake]);
  const totalOutput = convertStringAndSum([...stateOutput]);
  const totalNetBalance = totalIntake - totalOutput;
  return (
    <div style={{maxWidth: "250px"}}>
      <Table className="bg-light-3" borderless>
        <tbody>
          <tr>
            <th scope="row">
              <span className="m--font-success font-weight-500">Total Intake</span>
            </th>
            <td>
              <span className="m--font-success">{totalIntake}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <span className="m--font-danger font-weight-500">Total Output</span>
            </th>
            <td>
              <span className="m--font-danger">{totalOutput}</span>
            </td>
          </tr>
          <tr className="border-top">
            <th scope="row">
              <span className="m--font-brand font-weight-500">Net Balance</span>
            </th>
            <td>
              <span className="m--font-brand">
                {parseFloat(totalNetBalance) > 0 ? `+ ${totalNetBalance}` : `${totalNetBalance}`}{` `}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default TotalIO;
