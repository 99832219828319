import React, { useState, useEffect } from "react";

//react-router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//reactstrap
import { Col, Alert, Row } from "reactstrap";

//components
import {
  TopicTypeCondition,
  QuestionTypeCondition,
  QuestionConditionContent,
  DropDownCondition,
  OrderTypeCondition,
  ActionTypeCondition,
  ShowingCondition,
  AskedAtTask,
  DropDownTimePoint
} from '../FlowControl';

//actions
import { atcLoadChat } from "@actions";

function FlowControl(props) {
  const { timePointId, stateCondition } = props && props;

  //useParams
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [isCondition, setStateCondition] = useState(stateCondition || '');

  //load state
  useEffect(() => {
    setStateCondition(stateCondition || '');
  }, [stateCondition])


  //Call APi load chat topic
  useEffect(() => {
    const params = {
      Action: "Load",
      Target: "Chat",
      TimePointId: timePointId,
    };
    dispatch(atcLoadChat(id, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col md={6}>
        <h5 className="heading-5">Condition {isCondition !== "" && <span className="m--font-danger">*</span>}</h5>
        <Alert className="m-alert--default border-0">
          (OPTIONAL) Only show this question if ...
        </Alert>
        <DropDownCondition
          {...props}
          isCondition={isCondition}
        />
        {isCondition === "Question" && (
          <React.Fragment>
            <QuestionConditionContent
              {...props}
            />
            <QuestionTypeCondition
              {...props}
            />
          </React.Fragment>
        )}
        {isCondition === "Chat" && <TopicTypeCondition {...props} />}
        {isCondition === "Order" && <OrderTypeCondition {...props} />}
        {isCondition === "Action" && <ActionTypeCondition {...props} />}
      </Col>
      <Col md={6}>
        <AskedAtTask
          {...props}
        />
        <ShowingCondition
          {...props}
        />
        <DropDownTimePoint
          {...props}
        />
      </Col>
    </Row>
  );
}

export default FlowControl;
