import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';

//utils
import { apiCaller } from "@utils";

import { ButtonEdit } from '@components-common/elements';

//componenst
import { DeleteTemplateMain, ModalEditElement } from "../CustomizableElement";

//Icon
import { Iconsax } from "@components-common";

const DataTableComponent = (props) => {
    const { selectedGroupName, templateData, listStandardInterfaceElements } = props || {};

    //state
    const [dataTable, setDataTable] = useState([]);
    const [currentValue, setCurrentValue] = useState(null);
    const [modal, setModal] = useState({
        editElement: false
    });
    const [isLoading, setLoading] = useState({
        editElement: false
    });

    //Convert cấu trúc json chuẩn cho table
    const convertData = (originalData) => {
        let result = {};

        for (let key in originalData) {
            if (Array.isArray(originalData[key])) {
                result[key] = {
                    header: key,
                    collapsed: false,
                    rows: originalData[key].map((element, index) => {
                        return {
                            "InterfaceTemplateItemId": index + 1,
                            "Element": element,
                            "Value": null,
                            "Group": key
                        };
                    })
                };
            } else {
                console.warn(`The value for key "${key}" is not an array.`);
            }
        }

        return result;
    };

    // Merge Data
    const mergeData = (newDataInitial, templateDataGroups) => {
        let mergedData = JSON.parse(JSON.stringify(newDataInitial));

        for (let group_name in templateDataGroups) {
            if (mergedData.hasOwnProperty(group_name)) {
                for (let item2 of templateDataGroups[group_name]) {
                    let found = mergedData[group_name]['rows'].find(item1 => item1['Element'] === item2['Element']);
                    if (found && item2['Value']) {
                        found['Value'] = item2['Value'];
                    }
                }
            }
        }

        return mergedData;
    };

    // Load Data By Groups
    useEffect(() => {
        const initialData = { ...listStandardInterfaceElements };
        const newDataInitial = convertData(initialData || {});
        let result = mergeData(newDataInitial, templateData?.Groups);
        const dataArray = Object.entries(result).map(([sectionName, sectionContent]) => {
            return {
                sectionName,
                ...sectionContent
            };
        });
        setDataTable(dataArray);
    }, [listStandardInterfaceElements, templateData]);

    // Theo dõi thay đổi của selectedGroupName
    useEffect(() => {
        // Kiểm tra nếu selectedGroupName là null hoặc "all", reset về data gốc
        if (!selectedGroupName || selectedGroupName === "all") {
            const allGroupsData = Object.entries(templateData?.Groups || {}).map(([group, rows]) => ({
                sectionName: group,
                header: group,
                collapsed: false,
                rows: [...rows]
            }));
            setDataTable(allGroupsData);
        } else {
            // Lọc dữ liệu với selectedGroupName và thêm các thuộc tính cần thiết
            const groupData = templateData?.Groups?.[selectedGroupName] || [];
            const selectedGroupData = [{
                sectionName: selectedGroupName,
                header: selectedGroupName,
                collapsed: false,
                rows: [...groupData]
            }];
            setDataTable(selectedGroupData);
        }
    }, [selectedGroupName, templateData]);

    // Handle Collapse Group
    const toggleGroup = index => {
        const newData = [...dataTable];
        newData[index].collapsed = !newData[index].collapsed;
        setDataTable(newData);
    };

    //toggle
    const toggle = (type, value) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
        setCurrentValue(value);
    };

    // Handle Save Template
    const handleSubmitElement = async (elementValue, rowValue) => {
        setLoading((prevState) => ({ ...prevState, editElement: true }));
        try {
            const params = {
                "Action": "UpdateTemplateItem",
                "Group": rowValue?.Group,
                "Element": rowValue?.Element,
                "Value": elementValue
            };
            const response = await apiCaller(`/api/teach/interface_template/${templateData?.InterfaceTemplateId}/`, 'PUT', params);
            if (response?.status === 200) {
                toggle();
                setLoading((prevState) => ({ ...prevState, editElement: false }));

                //Update element for group
                const newData = [...dataTable];
                const group = newData.find(g => g.rows.some(r => r.Element === rowValue.Element));
                const rowToUpdate = group?.rows.find(r => r.Element === rowValue.Element);
                if (rowToUpdate) {
                    rowToUpdate.Value = elementValue;
                    setDataTable(newData);
                }
            }
        } catch (error) {
            console.error('Error loading templates:', error);
            toggle();
            setLoading((prevState) => ({ ...prevState, editElement: false }));
        }
    };

    return (
        <React.Fragment>
            <Table bordered>
                <thead className='--bg-primary'>
                    <tr>
                        <th className='font-weight-400'>Element</th>
                        <th className='font-weight-400'>{templateData?.Name}</th>
                        <th></th>
                    </tr>
                </thead>
                {dataTable?.map((group, index) => (
                    <tbody key={group.header}>
                        <tr>
                            <th className='font-weight-400 text-left --text-primary' style={{ background: "rgba(0, 61, 124, 0.15)" }} colSpan="3">
                                <div className="d-flex">
                                    {group.header}
                                    <div className="ml-auto">
                                        <span style={{ cursor: 'pointer' }} className='ml-4' onClick={() => toggleGroup(index)}>
                                            {group.collapsed ? (
                                                <Iconsax iconName="plus-2" fill="#003D7C" size={25} />
                                            ) : (
                                                <Iconsax iconName="minimize" fill="#003D7C" size={25} />
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        {!group.collapsed && group?.rows?.map(row => (
                            <tr key={row.InterfaceTemplateItemId}>
                                <td className='align-middle'>{row.Element}</td>
                                <td className='align-middle'>
                                    <div style={{ cursor: 'pointer' }} className='py-3' dangerouslySetInnerHTML={{ __html: row.Value }} />
                                </td>
                                <td className='align-middle text-center'>
                                    <ButtonEdit handleEdit={() => toggle("editElement", row)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                ))}
            </Table>
            <DeleteTemplateMain
                {...props}
            />
            <ModalEditElement
                {...props}
                handleSubmitElement={handleSubmitElement}
                isOpen={modal?.editElement}
                toggle={() => toggle("editElement", {})}
                currentValue={currentValue}
                isLoading={isLoading?.editElement}
            />
        </React.Fragment>
    );
};

export default DataTableComponent;