import React from 'react';

//boostrap
import { Input } from "reactstrap";

function InputEdit(props) {
    const { value, indexItem, disabled, handelOnkeyUp, handleChange } = props && props;
    return (
        <Input
            type="text"
            defaultValue={value?.TeamRoleName}
            onChange={(e) => handleChange(e, indexItem)}
            name="TeamRoleName"
            className="form-control"
            disabled={disabled}
            autoComplete='false'
            onKeyUp={(e) => handelOnkeyUp(e)}
        />
    );
};

export default InputEdit;