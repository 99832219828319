import React from 'react';

//components
import { LabSearch } from '../Labs';

//reactstrap
import { Col, Row } from "reactstrap";

//common
import { ButtonSwitch } from '@components/common/elements';

function LabsTop(props) {
    const { onSearch, searchText, isSwitch } = props && props;
    return (
        <Row className="align-items-center mb-4">
            <Col md={8}>
                <ButtonSwitch
                    {...props}
                    value={isSwitch}
                />
            </Col>
            <Col md={4}>
                <LabSearch
                    onSearch={onSearch}
                    searchText={searchText}
                />
            </Col>
        </Row>
    );
};

export default LabsTop;