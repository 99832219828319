import React from 'react';

//components
import { TableConcept, ButtonAddConcept } from '../Concepts';

function ConceptsTableMain(props) {
    const {
        index,
        indexCount,
        active
    } = props && props;

    return (
        <div
            key={index}
            id={"sect-" + indexCount(index)}
            className={
                active === index
                    ? "accordionExam__content accordionExam__open"
                    : "accordionExam__content accordionExam__close"
            }
        >
            <TableConcept
                {...props}
            />
            <ButtonAddConcept
                {...props}
            />
        </div>
    );
};

export default ConceptsTableMain;