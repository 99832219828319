import React from 'react';

function TableHeader(props) {
    const { data } = props && props;
    return (
        <thead className="table-row">
            <tr>
                {data?.map((col, index) => (
                    <th scope="col" width={col?.width || (col?.TimePoint ? '250px' : '')} className={`align-middle --text-primary font-weight-500 ${col?.TimePoint ? 'text-center' : 'text-left'}`} key={index}>
                        {col?.Name ? col.Name : col?.TimePoint?.DisplayName}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;