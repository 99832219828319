import React, { useState, useEffect } from "react";

//reactstrap
import { Button, Col } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const AddNewReview = (props) => {
    const { loggingItem, handleEditReviewforLogging, isLoading } = props && props;

    //state
    const [stateLoggingItem, setLoggingItem] = useState(loggingItem || {});

    //Load loggingItem
    useEffect(() => {
        setLoggingItem(loggingItem || {});
    }, [loggingItem])

    //Handle Change
    const handleChangeTextAreA = (e) => {
        const { name, value } = e.target;
        setLoggingItem((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <Col md={4} className="h-100" style={{ overflowY: 'auto' }}>
            <div className={`d-flex align-items-center justify-content-end`}>
                <div className="groupbtn">
                    <Button color="primary" className="--btn-primary" onClick={() => handleEditReviewforLogging(stateLoggingItem)}>
                        {isLoading?.editReviewForLogging && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </div>
            </div>
            <TextareaAutosize
                className={`w-100 form-control mt-3`}
                name="standard_output"
                placeholder=""
                type="text"
                onChange={handleChangeTextAreA}
                value={stateLoggingItem?.standard_output}
                maxRows={10}
                minRows={10}
            />
        </Col>
    );
};

export default AddNewReview;