import React, { useState } from 'react';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardHeader,
    CardBody,
    FormFeedback
} from 'reactstrap';
import { FormUpload } from '@ui-partents/Form/FormUpload';
import { DragDropZoneFile } from "@ui-partents/Form/FormUpload";
import styled from 'styled-components';

// Divider Component
const Divider = styled.hr`
    border: none;
    border-top: 1px solid #ddd;
    margin: 1.5rem 0;
`;

const MyForm = () => {
    const [dataFileImported, setDataFileImported] = useState(null);

    return (
        <Card className='my-2'>
            <CardHeader>Quick Example</CardHeader>
            <CardBody>
                <Form>
                    {/* Basic Input Group */}
                    <h5 className="mb-3">Basic Inputs</h5>
                    <FormGroup>
                        <Label for="basicInput">Basic Input</Label>
                        <Input id="basicInput" name="basic" placeholder="Enter text" type="text" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="disabledInput">Disabled Input</Label>
                        <Input id="disabledInput" name="disabled" placeholder="Disabled field" type="text" disabled />
                    </FormGroup>
                    <FormGroup>
                        <Label for="readOnlyInput">Read-Only Input</Label>
                        <Input id="readOnlyInput" name="readonly" placeholder="Read-only field" type="text" readOnly value="Read-only text" />
                    </FormGroup>

                    <Divider />

                    {/* Validation Input Group */}
                    <h5 className="mb-3">Validation Inputs</h5>
                    <FormGroup>
                        <Label for="successInput">Success Input</Label>
                        <Input id="successInput" name="success" placeholder="Valid entry" type="text" valid />
                        <FormFeedback valid>This is a hint text to help user.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="errorInput">Error Input</Label>
                        <Input id="errorInput" name="error" placeholder="Invalid entry" type="text" invalid />
                        <FormFeedback>This is a hint text to help user.</FormFeedback>
                    </FormGroup>
                    
                    <Divider />

                    {/* Text Area Group */}
                    <h5 className="mb-3">Text Areas</h5>
                    <FormGroup>
                        <Label for="basicText">Basic Text Area</Label>
                        <Input id="basicText" name="text" type="textarea" placeholder="Enter text here" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="basicText">Larger Text Area</Label>
                        <Input id="basicText" size={'lg'} name="text" type="textarea" placeholder="Enter text here" />
                    </FormGroup>

                    <FormGroup>
                        <Label for="errorText">Error Text Area</Label>
                        <Input id="errorText" name="text" type="textarea" placeholder="Invalid entry" value="A little about the company and the team that you’ll be working with." invalid />
                        <FormFeedback>Oops! Please check your input.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="disabledText">Disabled Text Area</Label>
                        <Input id="disabledText" name="text" type="textarea" placeholder="Enter a description..." disabled />
                    </FormGroup>

                    <Divider />

                    {/* File Upload Group */}
                    <h5 className="mb-3">File Uploads</h5>
                    <FormGroup>
                        <Label for="exampleFile">Upload File (Style 01)</Label>
                        <DragDropZoneFile />
                    </FormGroup>
                    <FormGroup>
                        <Label for="exampleFile">Upload File (Style 02)</Label>
                        <FormUpload
                            dataFileImported={dataFileImported}
                            setDataFileImported={setDataFileImported}
                            typeUpload="PDF, PPT, Word, TXT. Max: 10 MB."
                            typeFile={["application/msword", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/pdf", "text/plain"]}
                            errorFile="Only PDF, PPT, Word, TXT files are allowed."
                        />
                    </FormGroup>

                    <Divider />

                    {/* Checkbox Group */}
                    <h5 className="mb-3">Custom Checkboxes</h5>
                    <div className="checkBox-custom-style --green-color mb-2">
                        <FormGroup>
                            <Input type="checkbox" id="checkbox-green" defaultChecked={true} />
                            <Label htmlFor="checkbox-green">Checkbox Green</Label>
                        </FormGroup>
                    </div>
                    <div className="checkBox-custom-style --blue-color mb-2">
                        <FormGroup>
                            <Input type="checkbox" id="checkbox-blue" defaultChecked={true} />
                            <Label htmlFor="checkbox-blue">Checkbox Blue</Label>
                        </FormGroup>
                    </div>
                    <div className="checkBox-custom-style --black-color mb-2">
                        <FormGroup>
                            <Input type="checkbox" id="checkbox-default" defaultChecked={true} />
                            <Label htmlFor="checkbox-default">Checkbox Default</Label>
                        </FormGroup>
                    </div>

                    <Button color="primary" type="submit">Submit</Button>
                </Form>
            </CardBody>
        </Card>
    );
};

export default MyForm;