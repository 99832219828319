import React from 'react';

const ListTimePoint = (props) => {
    const { data, handleLoadTimePoint, stateTimePointId, children } = props && props;
    return (
        <ul className="timeList">
            {data?.map(
                (value, index) => {
                    const isActiveStatus = value?.DefaultActiveStatus;
                    return (
                        <li className="timeList__item d-flex align-items-center" key={index}>
                            <div
                                onClick={() => handleLoadTimePoint(value)}
                                data-id={value.Id}
                                className={`d-flex align-items-center presentationTime__name ${isActiveStatus ? "" : "default-not-active"} ${parseInt(stateTimePointId) ===
                                    parseInt(value.Id)
                                    ? "active"
                                    : "not-active"
                                    }`}
                            >
                                {index > 0 && <span className={`dot ${isActiveStatus ? "" : "default-not-active"}`}>&#8226;</span>}
                                {value.DisplayName}
                            </div>{" "}
                        </li>
                    )
                })}
            {children}
        </ul>
    );
}

export default ListTimePoint;