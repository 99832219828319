import React from 'react';

//components
import { CheckBoxAvailability } from '@components/elements';

function CellFirstTimePoint(props) {
    const { item } = props && props;
    return (
        <React.Fragment>
            <td className={`table-cell align-middle m--font-danger`}>
                {item?.Name}
            </td>
            <CheckBoxAvailability {...props} typeFeat="labs" />
        </React.Fragment>
    );
};

export default CellFirstTimePoint;