import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//alert
import { toast } from 'react-toastify';

//Modal
import {
  ModalEditLabItem,
  ModalEditLabPanel,
  ModalDeleteLab,
  ModalScore
} from "../../Modal";

//components
import ItemLabs from "./ItemLabs";
import IndependentLabs from "./IndependentLabs";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//components
import { AvailabilityDropdown, ShowOnlyAvailable } from '../../../storyboard/common';
import DropDownGenerate from "./DropDownGenerate";

//lodash
import _ from 'lodash';

//utils
import { apiCaller, LoaderText } from "@utils";
import { storyboardConstants } from "@constants";

//redux
import { useSelector } from "react-redux";

function LabsTableTimePoint(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { timePointId, isTime, data } = props && props;

  // Su dung selector de kiem tra Action EXTRACT_LABS_SUCCESS dang request loading list
  const extractLabs = useSelector(
    (state) => state.extractLabs || {}
  );

  //state
  const [modalItem, setModalItem] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [modalPanel, setModalPanel] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [actions, setActions] = useState(false);
  const [actionsDel, setActionsDel] = useState(false);
  const [itemEdit, setItemEdit] = useState({});
  const [itemDel, setItemDel] = useState({});
  const [labPanelId, setLabPanelId] = useState({});
  const [isComponent, setIsComponent] = useState("");
  const [stateData, setData] = useState(data || {});
  const [searchText, setSearchText] = useState("");

  //score
  const [modalScore, setModalCore] = useState(false);
  const [actionScore, setActionScore] = useState('');
  const [attributeType, setAttributeType] = useState('');
  const [itemScore, setItemScore] = useState('');

  //dropdown
  const [dropdownOpen, setOpen] = useState(false);

  //load
  useEffect(() => {
    setData(data || {});
  }, [data])

  //dropdown
  const toggleDropdown = () => setOpen(!dropdownOpen);

  //Modal edit lab item
  const toggleItem = (item, labPanelId, type) => {
    setLabPanelId(labPanelId);
    setItemEdit(item);
    setActions(type);
    setModalItem(!modalItem);
  };

  //Modal Delete panel and item
  const toggleDel = (item, type) => {
    setItemDel(item);
    setModalDel(!modalDel);
    setActionsDel(type);
  };

  //Score
  const toggleScore = (data, type, AttributeType, itemScore, typePanel) => {
    setActionScore(type);
    setItemEdit(data);
    setAttributeType(AttributeType);
    setItemScore(itemScore);
    setIsComponent(typePanel);//Independent-Labs
    setModalCore(!modalScore);
  };

  //add, edit lab panel
  const togglePanel = (item, type) => {
    setItemEdit(item);
    setActions(type);
    setModalPanel(!modalPanel);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setData({ ...stateData, Panels: data?.Panels });
    } else {
      const newArrFilterPanel = _.cloneDeep(data?.Panels);
      const newArrFilterIndependentLabs = _.cloneDeep(data?.IndependentLabs);

      if (newArrFilterPanel.length > 0) {
        const filteredPanel = newArrFilterPanel.filter(item => {
          let o = item['Items'].filter(({ Name }) => Name.toLowerCase().includes(lowercasedValue));
          return item['Items'] = o
        });
        setData({ ...stateData, Panels: filteredPanel });
      }

      if (newArrFilterIndependentLabs.length > 0) {
        const filteredIndependentLabs = newArrFilterIndependentLabs.filter(({ Name }) => Name.toLowerCase().includes(lowercasedValue));
        setData({ ...stateData, IndependentLabs: filteredIndependentLabs });
      }
    }
  }

  //Attribute Availability for All
  const handleAllAvailable = (status, data, categoryId) => {
    setLoading(true);
    if (stateData?.Panels?.length > 0 || stateData?.IndependentLabs?.length > 0) {
      let isChecked = status === "Available" ? true : false;
      const availableforPanel = stateData?.Panels.map((item) => {
        return {
          AttributeId: item?.Id,
          Available: isChecked,
          Type: "panel"
        };
      });

      const availableforItem = stateData?.IndependentLabs?.map((item) => {
        return {
          AttributeId: item?.Id,
          Available: isChecked,
          Type: "item"
        };
      });

      const AttributeMerge = [...availableforPanel, ...availableforItem]

      //Call Api
      const params = {
        Action: "Update",
        Target: "AttributeAvailability",
        TimePointId: timePointId,
        AttributeType: "Lab",
        AttributeAvailabilityData: AttributeMerge
      }
      UpdateLabAvailabilityinBatch(id, params, isChecked);
    }
  };

  //Case - Update Lab Availability in Batch
  const UpdateLabAvailabilityinBatch = async (id, params, isChecked) => {
    await apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_LAB_AVAILABILITY_IN_BATCH_SUCCESS,
          payload: data
        });

        toast.success(`Turn availability to ${isChecked ? 'ON' : 'OFF'} ${params?.AttributeAvailabilityData?.length} items`, {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      } else {
        console.log('error');
      }
    });
  }

  // Search
  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    filterData(searchTerm);
  };
  
  return (
    <div className={`${isLoading ? "overlayLoader" : ""}`}>
      <Row className="align-items-center">
        <Col md={6}>
          <div className="boxSearch">
            <input
              type="text"
              className="form-control"
              onChange={onSearch}
              value={searchText}
              placeholder="Search"
              autoComplete="off"
            />
            <button className="--bg-primary btnSearch btn btn-primary">
              <i className="fa fa-search" aria-hidden="true" />
            </button>
          </div>
        </Col>
        <Col md={6} className="mb-md-0 d-flex align-items-center justify-content-end">
          {/* Chi hien thi timepoint dau */}
          {isTime === 0 && <DropDownGenerate {...props} />}
          <AvailabilityDropdown
            handleAllAvailable={handleAllAvailable}
            isLoading={isLoading}
          />
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="d-table ml-2"
          >
            <DropdownToggle caret color="primary">
              <i className="fa fa-plus"></i>&nbsp;Add
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={() => togglePanel({}, "add-panel")}>
                New Panel
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>New Independent Lab</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>
        <Col md={6}>
          <ShowOnlyAvailable
            {...props}
            name="labs"
            label={`Show only available labs`}
          />
        </Col>
      </Row>
      <div className="table-responsive position-relative">
        {extractLabs?.isLoading && <div style={{ height: '200px' }}><LoaderText /></div>}
        <ItemLabs
          {...props}
          toggle={toggleItem} //handle Modal Item
          togglePanel={togglePanel} //handle Modal Panel
          toggleDel={toggleDel} //handle Modal Delete
          toggleScore={toggleScore} //handle Modal Score
          isTime={isTime}
          key={Math.random()}
          data={stateData}
        />
        <IndependentLabs
          {...props}
          toggleScore={toggleScore}
          toggle={toggleItem}
          toggleDel={toggleDel} //handle Modal Delete
          key={Math.random()}
          data={stateData}
        />
      </div>

      {/* Modal Update And Create ITem */}
      <ModalEditLabItem
        {...props}
        isOpen={modalItem}
        data={itemEdit}
        toggle={toggleItem}
        actions={actions}
        labPanelId={labPanelId}
      />
      {/* Modal Update And Create Panel */}
      <ModalEditLabPanel
        {...props}
        isOpen={modalPanel}
        data={itemEdit}
        toggle={togglePanel}
        actions={actions}
      />
      {/* Modal Delete Item and Panel */}
      <ModalDeleteLab
        {...props}
        isOpen={modalDel}
        data={itemDel}
        toggle={toggleDel}
        actions={actionsDel}
      />
      {/* Modal Score */}
      <ModalScore
        {...props}
        toggle={toggleScore}
        isOpen={modalScore}
        actions={actionScore}
        itemEdit={itemEdit}
        attributeType={attributeType}
        itemScore={itemScore}
        isComponent={isComponent}
      />
    </div>
  );
}

export default LabsTableTimePoint;