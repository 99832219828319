import React from "react";

//partents
import { ModalAssistantCommon } from '@ui-partents/Modal';

//components
import { FeedbackContent } from "../Feedback";

function ModalUserFeedback(props) {
    return (
        <ModalAssistantCommon
            {...props}
        >
            <FeedbackContent {...props} />
        </ModalAssistantCommon>
    );
}

export default ModalUserFeedback;