import React, { useState, useEffect } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";

//lodash
import _ from "lodash";

import { SliderContent } from '../TabQuestions';

function DetailGFModal(props) {
  const { isOpen, toggle, item, listAnswer, QuestionId, sendApi } = props && props;
  const listSlider = _.filter(listAnswer, function(o) { return item?.text; });

  //STATE
  const [isLoadData, setLoadData] = useState(true);
  const [sliderActive, setSlider] = useState((listSlider && listSlider[0]) || {});
  
  useEffect(() => {
    if(isOpen && isLoadData) {
      //Slider Active Default
      setSlider((listSlider && listSlider[0]) || {});
      setLoadData(false);
    }
  }, [listSlider, isLoadData, isOpen]);

  const hanldeSliderActive = (item) => {  
    setSlider(item);
  }

  const handleSubmit = (val) => {
    const params = {
      ReportType: "question_Records",
      Action: "Update",
      Target: "GradingField",
      SessionId: sliderActive?.SessionId,
      QuestionId: QuestionId,
      Email: sliderActive && sliderActive[`Username/Email`],
      GradingFieldId: item?.GradingFieldId,
      Value: val,
    };

    //setValue Item Active
    setSlider(prevState => ({
      ...prevState,
      [item?.text]: val
    }));
    sendApi(params);
  }

  return (
    <Modal style={{ maxWidth: "900px" }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{item?.dataField}</ModalHeader>
      <ModalBody>
        <FormGroup className="d-flex align-items-center mb-3" check>
          <Input className="mt-0" type="checkbox" />
          {' '}
          <Label check>
            Only answers that have not been graded
          </Label>
        </FormGroup>
        <SliderContent 
          data={listAnswer}
          hanldeSliderActive={hanldeSliderActive}
          sliderActive={sliderActive}
          handleSubmit={handleSubmit}
          colActive={item?.text}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DetailGFModal;