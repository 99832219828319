import React, { useRef, memo, useEffect } from "react";

//boostrap
import {
    Form,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    FormFeedback
} from "reactstrap";

//JoditEditor
import JoditEditor from "jodit-react";

const GeneralTab = memo(({ props, register, watch, formState: { errors }, setValue }) => {
    const editor = useRef(null);

    //props
    const { data, stateComment, handleSetContent } = props && props;

    //state
    useEffect(() => {
        setValue('Name', data?.name || '', { shouldValidate: false });
        setValue('Negation', data?.negation || false, { shouldValidate: false });
        setValue('Score', data?.score || 0, { shouldValidate: false });
        setValue('Category', data?.category || '', { shouldValidate: false });
    }, [data, setValue]);
    
    return (
        <Form>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Name">Name <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Name", {
                                required: "This field is required.",
                            })}
                            type="text"
                            name="Name"
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                        />
                        {errors.Name?.message && (
                            <FormFeedback className="d-block">
                                {errors.Score?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup
                        check
                        className="inline-flex align-items-center mb-3"
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                nam="Negation"
                                id="Negation"
                                onChange={(e) => {
                                    setValue("Negation", e.target.checked, {
                                        shouldValidate: true,
                                    });
                                }}
                                checked={watch("Negation")}
                            />
                            Negation
                        </Label>
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label for="Comments">Comments / Explanation:</Label>
                        <JoditEditor
                            ref={editor}
                            value={stateComment}
                            config={{
                                direction: "ltr",
                                readonly: false,
                                placeholder: "text entered here will only be used in the feedback to the learner",
                                toolbarButtonSize: 'small',
                                toolbarAdaptive: true,
                                askBeforePasteFromWord: false,
                                askBeforePasteHTML: false
                            }}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => handleSetContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        />
                    </FormGroup>
                </Col>
                <Col md={4}>
                    <FormGroup>
                        <Label for="Score">Score <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Score", {
                                required: "This field is required.",
                            })}
                            type="number"
                            name="Score"
                            defaultValue={watch("Score")}
                            onChange={(e) => {
                                setValue("Score", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                        />
                        {errors.Score?.message && (
                            <FormFeedback className="d-block">
                                {errors.Score?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label for="Category">Category <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Category", {
                                required: "This field is required.",
                            })}
                            type="text"
                            name="Category"
                            defaultValue={watch("Category")}
                            onChange={(e) => {
                                setValue("Category", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                        />
                        {errors.Category?.message && (
                            <FormFeedback className="d-block">
                                {errors.Category?.message}
                            </FormFeedback>
                        )}
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
});

export default GeneralTab;