import React, { useState } from "react";
import { Col, Row } from "reactstrap";

//components
import { ButtonGenerateFeedback, GenerateFeedbackBubble, ResetCase, ChatFreeText, ButtonChatWrapup } from "../TestVirtualPatient";
import { WrapUpFeedback } from "@components-common/Feedback";

//router
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

function TestVirtualPatientMain(props) {
    let { id } = useParams();
    let intervalIDRef = React.useRef(null);

    //state
    const [isLoading, setLoading] = useState(false);
    const [contentStream, setStream] = useState(null);
    const [stateResponse, setResponse] = useState(null);
    // const [isSubmit, setSubmit] = useState(false);
    const [isReset, setReset] = useState(false);
    const [isActive, setActive] = useState("Generate_Feedback");
    const [chatWrapUpData, setChatWrapUpData] = useState([]);

    // Call Api Stream
    const handleStreamData = (questionPromise) => {
        // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
        Promise.race([questionPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
            if (result !== 'timeout') {
                console.log('API question returned before timeout');
                return;
            }

            console.log('API question timed out. Calling API stream_data...');
            const params = { 'stream_mode': 'chat' }
            intervalIDRef.current = setInterval(() => {
                apiCaller(`/api/learn/case/stream_data/${id}/`, 'PUT', params).then(res => {
                    const dataStream = res?.data;
                    if (res?.status === 200) {
                        setStream(dataStream);
                    } else {
                        console.log("error....");
                        // Xoa Stream
                        clearInterval(intervalIDRef.current);
                        intervalIDRef.current = null;
                    }
                });
            }, 1000);
        });
    }

    //Chat - Get Feedback
    const handleGenerateFeedback = () => {
        let params = { "Action": "GetFeedback" }

        // Reset Data
        setStream(null);
        setResponse(null);

        setLoading(true);
        // setSubmit(true);
        const GetFeedbackPromise = apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setResponse(data);

                // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
                setLoading(false);
                setStream(null);
                clearInterval(intervalIDRef.current);
                intervalIDRef.current = null;
            } else {
                console.log('error');
                setLoading(false);
            }
            return data;
        });
        handleStreamData(GetFeedbackPromise);
    }

    // Function to fetch chat wrap-up data
    const featchChatWrapup = () => {
        // Define API request parameters
        const params = {
            "Action": "GetWrapUpFeedback"
        };
        // Make API call to fetch chat wrap-up data
        apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            // If API call is successful
            if (res?.status === 200) {
                setChatWrapUpData(data);
            } else {
                console.log("error...."); // Log error if API call fails
            }
        });
    };

    // Handle Click Tab
    const handleClickTab = (name) => {
        setActive(name);
        name === "Generate_Feedback" ? handleGenerateFeedback() : featchChatWrapup();
    }
    
    return (
        <Row>
            <Col md={12} className="text-right">
                <ResetCase
                    {...props}
                    handleResetDataChat={() => { setReset(true) }}
                />
            </Col>
            <Col md={6}>
                <div className="box-grey h-100">
                    <h4 className="box-grey__title mb-3">Virtual Patient</h4>
                    <ChatFreeText
                        {...props}
                        isReset={isReset}
                        handleResetDataChat={() => { setReset(false) }}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="box-grey h-100">
                    <h4 className="box-grey__title mb-3">Generate Feedback</h4>
                    <div className="d-flex justify-content-between">
                        <ButtonGenerateFeedback
                            {...props}
                            isLoading={isLoading}
                            handleClickTab={() => handleClickTab("Generate_Feedback")}
                            isActive={isActive === "Generate_Feedback"}
                        />
                        <ButtonChatWrapup
                            {...props}
                            handleClickTab={() => handleClickTab("Chat_Wrapup")}
                            isActive={isActive === "Chat_Wrapup"}
                        />
                    </div>
                    {isActive === "Generate_Feedback" &&
                        <GenerateFeedbackBubble
                            {...props}
                            isLoading={isLoading}
                            stateResponse={stateResponse}
                            contentStream={contentStream}
                        />
                    }
                    {isActive === "Chat_Wrapup" &&
                        <WrapUpFeedback
                            {...props}
                            feedbacksList={chatWrapUpData}
                        />
                    }
                </div>
            </Col>
        </Row>
    );
}

export default TestVirtualPatientMain;