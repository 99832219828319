import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Icon
import IconClose from '@images/learnerv2/white-close-sp.svg';

// Define keyframes for the opening and closing animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const PopupVitalSignMobileContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color: #FFF;
  padding: 20px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease-out;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 10000;
`;

const Box = styled.div`
  text-align: left;
  margin-bottom: 35px;
`;

const MediumText = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 24px;
`;

const TitleText = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
`;

const RegularText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
`;

const Box2 = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 12px;
`;

const Group = styled.div`
  margin-bottom: 24px;
  padding: 12px;
`;

const BP = styled(MediumText)`
  margin-bottom: 20px;
`;

const vitalSignColors = {
  Temp: '#9FCE63',
  RR: '#FDF3D0',
  HR: '#E75BA0',
  SpO2: '#91D5F3',
  BP: '#FFFFFF'
};

const renderName = (value) => {
  let showName = '';
  let varName = value[1]?.Name;
  if (varName.includes("Temp")) {
    showName = 'Temp';
  } else if (varName.includes("Respiratory Rate")) {
    showName = 'RR';
  } else if (varName.includes("O2 saturation")) {
    showName = 'SpO2';
  } else if (varName.includes("Blood")) {
    showName = 'BP';
  } else if (varName.includes("Heart")) {
    showName = 'HR';
  } else {
    showName = varName;
  }
  return showName;
};

const PopupVitalSignMobile = (props) => {
  // state
  const [isClosing, setIsClosing] = useState(false);

  const { caseData, onClose } = props;
  const listCase = caseData?.data?.Case;
  const propsVitalSigns = listCase?.ClinicalStatus?.VitalSigns;
  const dataVitalSigns = propsVitalSigns && Object.entries(propsVitalSigns);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(onClose, 300); // Delay to match animation duration
  };

  const renderVitalSign = (vitalSign) => {
    const name = renderName(vitalSign);
    const color = vitalSignColors[name];
    const value = vitalSign[1].CurrentValue;
    const unit = vitalSign[1].Unit;

    return (
      <Group key={vitalSign[1].AttributeId}>
        <MediumText style={{ color }}>{name}</MediumText>
        <TitleText style={{ color }}>{value} <RegularText as="span" style={{ color }}>{unit}</RegularText></TitleText>
      </Group>
    );
  };

  return (
    <PopupVitalSignMobileContainer isClosing={isClosing}>
      <CloseButton onClick={handleClose}>
        <img src={IconClose} alt="Close" />
      </CloseButton>

      <Box>
        <BP>BP</BP>
        <TitleText>110/70 <RegularText as="span">mmHg</RegularText></TitleText>
      </Box>

      <Box2>
        <Col>
          {dataVitalSigns?.filter((vs) => ["Temp", "RR"].includes(renderName(vs))).map(renderVitalSign)}
        </Col>
        <Col>
          {dataVitalSigns?.filter((vs) => ["HR", "SpO2"].includes(renderName(vs))).map(renderVitalSign)}
        </Col>
      </Box2>
    </PopupVitalSignMobileContainer>
  );
};

export default PopupVitalSignMobile;