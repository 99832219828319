import React, { useState } from "react";
import styled from "styled-components";
import BootstrapTable from "react-bootstrap-table-next";
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { paginationOptions } from "@utils";
import { HeaderSort, InfoLibraryRow } from "../ImportFromDB";
import { FormGroup, Input } from "reactstrap";
import { v4 as uuidv4 } from "uuid"; // Import UUID

// API
import { apiCaller, LoaderText } from "@utils";

const StyledRowTable = styled.div`
    table {
        border-collapse: separate;
        border-spacing: 0 20px;
        tbody {
            tr {
                td {
                    cursor: pointer;
                    padding: 8px !important;
                    border: 1px solid #F2F4F7;
                    &:first-child {
                        border-right: 0;
                        border-radius: 6px 0 0 6px;
                    }
                    &:last-child {
                        border-left: 0;
                        border-radius: 0 6px 6px 0;
                    }
                }
            }
        }
        .selection-cell {
            vertical-align: top !important;
            .form-check {
                margin-top: 8px;
            }
        }
    }

    .selection-row {
        background: var(--Background-bg-primary-sublest, #F5FCFF);
        td {
            border: 1px solid var(--Border-border-brand, #0089C2);
        }

        td:first-child {
            border-right: none;
            border-radius: 8px 0 0 8px;
        }

        td:last-child {
            border-left: none;
            border-radius: 0 8px 8px 0;
        }
    }

    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
`;

function TableDataFromDB({ setHasSelection, selectedItemsRef }) {
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]); // Dữ liệu gốc
    const [loading, setLoading] = useState(false);
    const [resetKey, setResetKey] = useState(0);

    // State để lưu option hiện tại
    const options = [
        { value: "", label: "Filter by Source" },
        { value: "pubmed", label: "PubMed" },
        { value: "scholar_rx", label: "ScholarRx" },
    ];
    const [selectedOption, setSelectedOption] = useState(options[0]); // Quản lý option hiện tại

    const columns = [
        {
            dataField: "uuid",
            text: "UUID", // Sử dụng UUID làm primary key
            hidden: true,
        },
        {
            dataField: "name",
            text: "Name",
            formatter: (value, row) => <InfoLibraryRow row={row} />,
        },
    ];

    const addUUIDToData = (data) =>
        data.map((item) => ({ ...item, uuid: item.uuid || uuidv4() }));

    const handleCheckboxChange = (row, isSelected) => {
        if (isSelected) {
            selectedItemsRef.current.add(row);
        } else {
            selectedItemsRef.current.delete(row);
        }
        setHasSelection(selectedItemsRef.current.size > 0);
    };

    const handleSelectAll = (isSelected, rows) => {
        if (isSelected) {
            rows.forEach((row) => selectedItemsRef.current.add(row));
        } else {
            rows.forEach((row) => selectedItemsRef.current.delete(row));
        }
        setHasSelection(selectedItemsRef.current.size > 0);
    };

    const handleFilterChange = (source, paginationProps) => {
        if (paginationProps.page !== 1) {
            paginationProps.onPageChange(1); // Reset về trang đầu tiên
        }

        let filteredData = [];
        if (source) {
            filteredData = originalData.filter(
                (item) => item.Source?.toLowerCase() === source.toLowerCase()
            );
        } else {
            filteredData = originalData; // Reset về dữ liệu gốc nếu không có filter
        }

        setData(filteredData);
        setResetKey((prev) => prev + 1); // Reset HeaderSort
    };

    const handleSearch = (value, paginationProps) => {
        setLoading(true);
        if (value) {
            const params = { Query: value };
            setResetKey((prev) => prev + 1);
            if (paginationProps.page !== 1) {
                paginationProps.onPageChange(1); // Reset về trang đầu tiên
            }
            apiCaller(`/api/reference/import/search/`, "POST", params)
                .then((res) => {
                    const fetchedData = res?.data || [];
                    const dataWithUUID = addUUIDToData(fetchedData);

                    setData(dataWithUUID);
                    setOriginalData(dataWithUUID);

                    selectedItemsRef.current.clear();
                    setHasSelection(false); // Reset selected state
                    setResetKey((prev) => prev + 1);
                    setSelectedOption(options[0]);

                })
                .catch((err) => {
                    console.error("Error during search:", err);
                    setData([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setData(originalData);
            setLoading(false);
        }
    };

    const handleResetData = (paginationProps) => {
        setOriginalData([]);
        setData([]);
        selectedItemsRef.current.clear();
        setResetKey((prev) => prev + 1);
        setSelectedOption(options[0]);

        if (paginationProps.page !== 1) {
            paginationProps.onPageChange(1);
        }
    };

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        hideSelectAll: false,
        classes: "selection-row",
        selectionRenderer: ({ mode, checked, disabled }) => (
            <FormGroup check className="form-common">
                <Input
                    type="checkbox"
                    {...mode}
                    checked={checked}
                    disabled={disabled}
                    className="react-checkbox-common react-checkbox-md"
                />
            </FormGroup>
        ),
        onSelect: (row, isSelected) => handleCheckboxChange(row, isSelected),
        onSelectAll: (isSelected, rows) => handleSelectAll(isSelected, rows),
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider keyField="uuid" data={data || []} columns={columns} search>
            {(toolkitprops) => (
                <StyledRowTable className="table-custom-common table-not-header mt-4">
                    <div style={{ marginBottom: "16px" }}>
                        <SearchCustomForTable
                            toolkitprops={{
                                ...toolkitprops,
                                searchProps: {
                                    ...toolkitprops.searchProps,
                                    onSearch: (value) => handleSearch(value, paginationProps),
                                },
                            }}
                            searchMode="button"
                            onClear={() => handleResetData(paginationProps)}
                            showButton
                            placeholder="Enter keywords..."
                            textButton="Search"
                            loading={loading}
                            onChange={(value) => {
                                if (value.trim() === "") handleResetData(paginationProps); // Reset nếu giá trị rỗng
                            }}
                        />
                    </div>
                    <HeaderSort
                        data={data}
                        handleChange={(source) => handleFilterChange(source, paginationProps)}
                        resetKey={resetKey}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        options={options}
                    />
                    <BootstrapTable
                        {...paginationTableProps}
                        {...toolkitprops.baseProps}
                        key={resetKey}
                        selectRow={selectRow}
                        bordered={false}
                        wrapperClasses="position-relative"
                        noDataIndication={() =>
                            loading ? (
                                <div className="loader-container">
                                    <LoaderText />
                                </div>
                            ) : (
                                <MessageEmtyData
                                    title="No Results Found"
                                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                                />
                            )
                        }
                    />
                    <div className="pagination-custom-case d-flex justify-content-end mt-4">
                        <PaginationListStandalone {...paginationProps} />
                    </div>
                </StyledRowTable>
            )}
        </ToolkitProvider>
    );

    const totalSize = data?.length || 0;
    const paginationConfig = paginationOptions({ totalSize, pageSize: 4 });

    return (
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {({ paginationProps, paginationTableProps }) =>
                contentTable({ paginationProps, paginationTableProps })
            }
        </PaginationProvider>
    );
}

export default TableDataFromDB;