import React from "react";

//components
import { MainSettings, EditTitleCase } from "../teach/Settings";

function QuickSettings(props) {
  return (
    <div className="widgetBox -box-shadow">
      <div className="widgetBox__head">
        <h3 className="wg-title m--font-brand --text-primary">
          <i className="fa fa-cogs mr-2" aria-hidden="true"></i>
          Quick Settings
        </h3>
      </div>
      <div className="widgetBox__body">
        <EditTitleCase {...props} />
        <MainSettings {...props} />
      </div>
    </div>
  );
}

export default QuickSettings;
