import React, { useState } from 'react';

//reactstrap
import { Button } from "reactstrap";

//components
import { ModalAddProblem } from '../ProblemGroup';

function ButtonAddProblem(props) {

    //state
    const [modal, setModal] = useState(false);

    //handleAdd
    const handleAdd = () => {
        setModal(!modal);
    }

    return (
        <React.Fragment>
            <div className="text-right mb-3">
                <Button className="--btn-primary" onClick={handleAdd}>
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Problem
                </Button>
            </div>
            <ModalAddProblem
                {...props}
                isOpen={modal}
                toggle={handleAdd}
            />
        </React.Fragment>
    );
};

export default ButtonAddProblem;