import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

//redux
import { useDispatch } from "react-redux";

//actions
import { atcUpdateAgreement } from "@actions";

//Config
import * as Config from "@constants/Config";

//boostrap
import {
  Label,
  FormGroup
} from "reactstrap";

const UseAgreement = (props) => {
  //props
  const { data } = props && props;

  const dispatch = useDispatch();
  const editor = useRef(null);
  const [content, setContent] = useState((data && data.Contents) || "");
  const [isSend, setSend] = useState(false);

  const handleApi = (content) => {
    setContent(content);
    setTimeout(() => {
      setSend(true);
    }, 1000);
  };

  useEffect(() => {
    setContent((data && data.Contents) || "");
  }, [data]);

  useEffect(() => {
    if (isSend) {
      const params = {
        Action: "Save",
        "PlatformUseAgreement": content,
      };

      dispatch(atcUpdateAgreement(params));
      setSend(false);
    }
  }, [isSend, dispatch, content, data]);

  return (
    <FormGroup>
      <Label for="UseAgreement">Version:</Label>
      <JoditEditor
        ref={editor}
        value={content}
        config={Config.CONFIG_JODIT}
        tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => handleApi(newContent)}
      />
    </FormGroup>
  );
};

export default UseAgreement;
