import React, { useEffect, useState } from "react";

//react router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components
import { ResponseTab, ScoringFeedBack } from '../../Chat/ClickType';

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//boostrap
import {
  Modal,
  ModalFooter,
  ModalHeader,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";

//classnames
import classnames from "classnames";

//actions
import { atcLoadChat } from "@actions";

function ModalUpdate(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, type, item, timePointId } = props && props;

  //state
  const [stateData, setData] = useState((item && item) || {});
  const [activeTab, setActiveTab] = useState("1");
  const [isLoading, setLoading] = useState(false);

  //Load Data
  useEffect(() => {
    setData((item && item) || {});
  }, [item])

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Submit Api
  const handleSubmit = () => {
    const params = {
      Target: "Chat",
      TimePointId: timePointId,
      TopicName: stateData?.TopicName || "",
      ChatQuestion: stateData?.ChatQuestion || "",
      ChatResponse: stateData?.ChatResponse || "",
      Score: stateData?.Score,
      Domains: stateData?.Domains,
      Feedback: stateData?.Feedback
    };

    if (type === 'add') {
      params.Action = "Create";
      callApiCreate(id, params);
    }

    if (type === "edit") {
      params.Action = "Update";
      params.TopicId = item?.TopicId || "";
      callApiUpdate(id, params);
    }

    //close
    toggle(item, type);
  };

  //Call Api Update
  const callApiUpdate = (id, params) => {
    const isLoading = true;
    dispatch({ type: storyboardConstants.EDIT_CHAT_REQUEST, isLoading });
    setLoading(isLoading);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.EDIT_CHAT_SUCCESS, payload: data });

        //reload Data
        const paramsLoad = {
          Action: "Load",
          Target: "Chat",
          TimePointId: params?.TimePointId,
        };
        dispatch(atcLoadChat(id, paramsLoad));
        toggle(false);
        setLoading(false);
      } else {
        dispatch({ type: storyboardConstants.EDIT_CHAT_FAILURE, error: 'error' });
        toggle(false);
        setLoading(false);
      }
    });
  }

  //Call Api Create
  const callApiCreate = (id, params) => {
    const isLoading = true;
    dispatch({ type: storyboardConstants.ADD_CHAT_REQUEST, isLoading });
    setLoading(isLoading);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.ADD_CHAT_SUCCESS, payload: data });
        toggle(false);
        setLoading(false);
      } else {
        dispatch({ type: storyboardConstants.ADD_CHAT_FAILURE, error: 'error' });
        toggle(false);
        setLoading(false);
      }
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({ ...prevState, [name]: value }));
  }

  //Domains
  const handleChangeDomains = (index, e) => {
    const { checked } = e.target;
    let newArray = {...stateData}
    newArray[`Domains`][index]['Selected'] = checked;
    setData(newArray);
  };

  //Tabs
  const listTabs = [
    {
      id: '1',
      name: 'Response',
      component: (
        <ResponseTab
          {...props}
          handleChange={handleChange}
          data={(stateData && stateData) || {}}
        />
      ),
      show: true
    },
    {
      id: '2',
      name: 'Scoring & Feedback:',
      component: (
        <ScoringFeedBack
          data={(stateData && stateData) || {}}
          handleChange={handleChange}
          handleChangeDomains={handleChangeDomains}
        />
      ),
      show: true
    }
  ]
  
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(item, type)}
      backdrop={`static`}
      style={{ maxWidth: "900px" }}
    >
      <ModalHeader toggle={() => toggle(item, type)}>{type === 'add' ? 'Add' : 'Edit'} Chat Topic</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="TopicName">
                Topic name:
                {/* <span className="m--font-danger">*</span> */}
              </Label>
              <Input
                type="text"
                onChange={handleChange}
                defaultValue={stateData?.TopicName}
                name="TopicName"
                autoComplete="off"
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="tabsPills">
          <Nav tabs>
            {listTabs.map((item, index) =>
              item.show && (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === item.id })}
                    onClick={() => {
                      toggleTab(item.id);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              )
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            {listTabs.map((item, index) =>
              item.show && (
                <TabPane key={index} tabId={item.id}>
                  {item.component}
                </TabPane>
              )
            )}
          </TabContent>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => toggle(item, type)}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          className="btn btn-brand d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {type === 'add' ? 'Add' : 'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalUpdate;