import React from 'react';
import styled from 'styled-components';
import IconCase from '@images/learnerv2/case-alert.svg';

const CaseTitleBlock = styled.div`
    background: #fff;
    border-radius: 12px;
    padding: 12px 16px;
    text-align: center;
    margin-top: 8px;
`;

const CaseTitleName = styled.h2`
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 12px;

    span {
        color: #F1800F;
    }
`;

const CaseTitle = ({ title }) => (
    <CaseTitleBlock>
        <img src={IconCase} alt="case" className="case-info" />
        <CaseTitleName>
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </CaseTitleName>
    </CaseTitleBlock>
);

export default CaseTitle;