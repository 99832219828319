import React from 'react'
import { Icon } from '@components/common'
import More from '@images/teachV2/more-circle.svg'

const ListPagesSlides = (props) => {
  const { tab, onTabChange, activeTab } = props
  // const [showDelete, setShowDelete] = useState()
  return (
    <div className={`slides-detail ${activeTab === tab.id ? 'active' : ''}`} onClick={() => onTabChange(tab.id)}>
      <p className="slide-page">{tab.id}</p>
      {activeTab === tab.id && (
        <div className='slide-more'>
          <Icon src={More}/>
        </div>
      )}
    </div>
  )
}

export default ListPagesSlides
