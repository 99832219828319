import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//Icon
import { Iconsax } from "@components-common";

function ModalDeleteCommon(props) {
  const { isOpen, toggle, title, sub, handleRemove, buttonText, centerButton, isLoading, hideIcon } = props && props;
  return (
    <Modal className="modal-delete-custom" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="modal-header-custom">
          {!hideIcon &&
            <Iconsax
              iconName={"icon-modal-delete"}
              size={20}
              fill={`#D92D20`}
            />
          }
          <div className="title-modal" dangerouslySetInnerHTML={{ __html: title || "" }} />
        </div>
        <div className="mt-2" dangerouslySetInnerHTML={{ __html: sub || "" }} />
      </ModalBody>
      <ModalFooter className={`${centerButton && 'justify-content-center'}`}>
        <Button className="btn btn-secondary" disabled={isLoading} onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={() => handleRemove()}
          disabled={isLoading || false}
        >
          {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          {buttonText ? buttonText : `Delete`}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteCommon;