import React from 'react';

//components
import { DiagnosticItem, SubFeature, ButtonAdd } from '.';

//reactstrap
import { Col, Row } from "reactstrap";

function FeatureRight({ ...props }) {
    const { relevantFeatures, DiagnosisId, itemFeature } = props && props;
    return (
        <React.Fragment>
            {relevantFeatures?.map((value, index) => {
                const featureId = value?.cui;
                const itemSubFeature = value;
                const dataSubFeature = value?.relevant_features;
                return (
                    <Row className="align-items-center" key={index}>
                        <Col md={6}>
                            <DiagnosticItem 
                                key={index}
                                item={value}
                            />
                        </Col>
                        <SubFeature
                            {...props}
                            item={itemSubFeature}
                            dataSubFeature={dataSubFeature}
                            featureId={featureId}
                        />
                    </Row>
                )
            })}
            <Row>
                <Col lg={6} md={12}>
                    <ButtonAdd
                        {...props}
                        buttonText={`Add Feature`}
                        item={itemFeature}
                        type="Feature"
                        parentId={DiagnosisId}
                    />
                </Col>
                <Col lg={6} md={12}></Col>
            </Row>
        </React.Fragment>
    );
};

export default FeatureRight;