import React, { useRef, useState, useEffect } from 'react';

//reactstrap
import { Badge } from "reactstrap";

//actions
import { updateQuestionConceptForTag } from "@actions";

//components
import { TagsAdd, TagsEdit } from "../tags";

//redux
import { useDispatch } from "react-redux";

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler]
    );
}

const Items = ({ ...props }) => {
    const dispatch = useDispatch();
    const ref = useRef();

    //props
    const { item } = props && props;

    //state
    const [tags, setTags] = useState(item?.tags || []);
    const [valueEdit, setValueEdit] = useState({});
    const [color, setColor] = useState('#9ea1a3');
    const [isOpen, setOpen] = useState('');
    const [itemIndex, setIndex] = useState('');

    //Load Data
    useEffect(() => {
        setTags(item?.tags || []);
    }, [item])

    const removeTags = indexToRemove => {
        const newArrTag = [...tags.filter((_, index) => index !== indexToRemove)]
        setTags(newArrTag);

        //Call Api
        updateTagOfQuestion(newArrTag);
    };

    const hanldeAction = (type, index) => {
        setOpen(type);
        if (type === 'editTag') {
            setValueEdit(tags[index]);
            setIndex(index);
        }
    }

    const onkeyUpdateTags = (event, type) => {
        if (type === 'addTag') {
            const newArrTag = [...tags, { Text: event.target.value, Color: '#9ea1a3'}]
            setTags(newArrTag);

            event.target.value = "";
            setColor("#9ea1a3");

            //Call Api
            updateTagOfQuestion(newArrTag);
        } else {
            let tagData = [...tags];
            tagData[itemIndex][`Text`] = event.target.value
            setTags(tagData);

            //Call Api
            updateTagOfQuestion(tagData);
        }
        setOpen('');
    }

    const handleChangeComplete = (color, event) => {
        const colorHex = color?.hex;
        let tagData = [...tags];
        tagData[itemIndex][`Color`] = colorHex
        setTags(tagData);

        //update color selected and tags
        setColor(color);

        //Call Api
        updateTagOfQuestion(tagData);
    }

    const updateTagOfQuestion = (newArrTag) => {
        const currentQuestionId = item?.question_id;
        const currentQuestionConceptId = item?.question_concept_id;
        const currentScore = item?.score;
        const currentComment = item?.comments;
        const currentNegation = item?.negation;
        const params = {
            Action: "UpdateQuestionConcept",
            QuestionId: currentQuestionId,
            QuestionConceptId: currentQuestionConceptId,
            Comments: currentComment,
            Score: currentScore,
            Tags: newArrTag,
            Negation: currentNegation
        }
        dispatch(updateQuestionConceptForTag(params));
    }
    
    useOnClickOutside(ref, () => setOpen());
    return (
        <div className="mt-2 py-2">
            <div className="tagsInput">
                {tags?.map((tag, index) => (
                    <Badge
                        key={index}
                        className="p-2 m-1"
                        style={{ backgroundColor: tag?.Color, cursor: 'pointer' }}
                    >
                        <span className='tagsInput__title'>{tag?.Text}</span>
                        <span className='tagsInput__edit item'
                            onClick={() => hanldeAction("editTag", index)}
                        >
                            <i className="fa fa-pencil" aria-hidden="true"></i>
                        </span>
                        <span className='tagsInput__close item'
                            onClick={() => removeTags(index)}
                        >
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                    </Badge>
                ))}
                <div
                    className="btn btnFeature text-1 border bg-light-1 m-1"
                    onClick={() => hanldeAction("addTag", '')}
                >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add
                </div>
            </div>
            <div ref={ref}>
                {isOpen === "addTag" && (
                    <TagsAdd
                        onkeyUpdateTags={onkeyUpdateTags}
                    />
                )}
                {isOpen === "editTag" && valueEdit && (
                    <TagsEdit
                        handleChangeComplete={handleChangeComplete}
                        onkeyUpdateTags={onkeyUpdateTags}
                        color={color}
                        value={valueEdit}
                    />
                )}
            </div>
        </div>
    );
};

const MainTags = ({ ...props }) => {
    //props
    const { item } = props && props;
    
    const selectedTags = tags => {
        console.log(tags);
    };

    return (
        <Items selectedTags={selectedTags} item={item} />
    );
};

export default MainTags;