import React, { useState } from "react";

//components
import { ModalUpload } from "./Modal/";

import { LoaderText } from "@utils";

function ProFileImage(props) {
  //props
  const timepointData =
    props && props.timepointData && props.timepointData.data;
  const examData = timepointData && timepointData.Exam;
  const profileData = examData && examData.Profile;
  const [loadImage, setLoadImage] = useState(false);

  //Modal Upload
  const [modalUpload, setModalUpload] = useState(false);
  const hanleModalUpload = () => {
    setModalUpload(!modalUpload);
  };

  //Check load exam
  const checkImageLoad = () => {
    setLoadImage(true);
  };

  return (
    <React.Fragment>
      <div className="patientPhoto">
        {!loadImage && <LoaderText />}
        <img
          className={`img-fluid`}
          src={profileData && profileData.Url}
          alt="exam_photo"
          onLoad={() => checkImageLoad()}
        />
        <button
          type="button"
          className="btn btn-info my-4 d-block mx-auto"
          onClick={hanleModalUpload}
        >
          <i className="fa fa-image" /> Change Image
        </button>
      </div>
      <ModalUpload {...props} toggle={hanleModalUpload} isOpen={modalUpload} />
    </React.Fragment>
  );
}

export default ProFileImage;
