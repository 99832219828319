import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';

//lodash
import _ from "lodash";

//reactstrap
import { Button, FormGroup, Input, Label, Col } from "reactstrap";

function SliderContent(props) {
  const ref = useRef(null);

  //props
  const { data, hanldeSliderActive, sliderActive, handleSubmit, colActive } =
    props && props;

  //state
  const [isActive, setActive] = useState("1");
  const [stateGrade, setGrade] = useState("");

  useEffect(() => {
    const gradeActive = sliderActive && sliderActive[colActive];
    setGrade(gradeActive);
  }, [sliderActive, colActive]);

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  const handleActive = (item) => {
    setActive(item + 1);
    if (data && data.length > 0) {
      const dataClone = _.cloneDeep(data);
      const dataCurrent = dataClone && dataClone[item];
      hanldeSliderActive(dataCurrent);
    }
  };

  const hanldeChange = (e) => {
    const { value } = e.target;
    setGrade(value);
  };

  const params = {
    effect: "fade",
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    on: {
      realIndexChange: (swiper) => {
        handleActive(swiper.realIndex);
      },
    },
  };

  const answerActive = sliderActive?.Answer;
  return (
    <div style={{ overflow: "hidden" }}>
      <Label className="font-weight-500">
        Answer <span className="--text-primary">{isActive}</span> of{" "}
        {data && data.length}
      </Label>
      <Swiper {...params} ref={ref}>
        {data?.map((value, index) => (
          <SwiperSlide key={index}>
            <div dangerouslySetInnerHTML={{ __html: answerActive }} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="btnNextPrev text-center mt-4">
        <Button color="primary" className="--btn-primary mx-1" onClick={goPrev}>
          <i className="fa fa-angle-left mr-2" aria-hidden="true"></i>Prev
        </Button>
        <Button color="primary" className="--btn-primary mx-1" onClick={goNext}>
          Next<i className="fa fa-angle-right ml-2" aria-hidden="true"></i>
        </Button>
      </div>

      <FormGroup style={{ maxWidth: "50%" }} className="mt-4 mb-0" row>
        <Label for="Grade" sm={4} className="font-weight-500">
          Grade / Label
        </Label>
        <Col className="d-flex align-items-center" sm={8}>
          <Input
            name="stateGrade"
            placeholder=""
            type="text"
            bsSize="lg"
            value={stateGrade}
            onChange={hanldeChange}
            autoComplete="off"
          />
          <Button
            color="primary"
            className="btn --btn-primary ml-3"
            onClick={() => handleSubmit(stateGrade)}
          >
            <i className="fa fa-floppy-o" aria-hidden="true"></i>
          </Button>
        </Col>
      </FormGroup>
    </div>
  );
}

export default SliderContent;