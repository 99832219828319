import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { CreatableSelectAnswer } from "../Condition";

function QuestionTypeCondition(props) {
    const { handleChangeStatusByCondition, handleUpdateFlowControl, itemQuestion } = props;
    const FlowConditions = itemQuestion?.FlowConditions && itemQuestion?.FlowConditions[0];

    // Initialize state from flowControlOfQuestion.Status
    const [status, setStatus] = useState(FlowConditions?.Status || '');
    const [answerType, setAnswerType] = useState("with_answers_one");

    useEffect(() => {
        if (status === "WithAnswers") {
            handleChangeStatusByCondition("with_answers_one");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeStatus = (e) => {
        const { value } = e.target;
        setStatus(value);
        if (value === "WithAnswers") {
            setAnswerType("with_answers_one");
            handleChangeStatusByCondition("with_answers_one");
        } else {
            handleChangeStatusByCondition(value);
        }
    };

    const handleAnswerTypeChange = (e) => {
        const { value } = e.target;
        setAnswerType(value);
        handleChangeStatusByCondition(value);
    };

    const handleChangeAnswer = (type, options) => {
        handleUpdateFlowControl(type, options);
    };

    return (
        <React.Fragment>
            {['correct', 'incorrect', 'WithAnswers'].map((value) => (
                <FormGroup check className="mb-2" key={value}>
                    <Label check className={value === "WithAnswers" ? "d-flex align-items-center" : ""}>
                        <Input
                            type="radio"
                            value={value}
                            name="Status"
                            onChange={handleChangeStatus}
                            checked={status === value}
                        />
                        {value === "WithAnswers" ? (
                            <>
                                <span className="text-nowrap mr-3">Is with</span>
                                <Input
                                    type="select"
                                    value={answerType}
                                    onChange={handleAnswerTypeChange}
                                    disabled={status !== "WithAnswers"}
                                >
                                    <option value="with_answers_one">ONE</option>
                                    <option value="with_answers_all">ALL</option>
                                </Input>
                                <span className="text-nowrap ml-3">of these answers</span>
                            </>
                        ) : (
                            <span>is {value.charAt(0).toUpperCase() + value.slice(1)}</span>
                        )}
                    </Label>
                </FormGroup>
            ))}
            {status === "WithAnswers" && (
                <CreatableSelectAnswer
                    {...props}
                    handleChangeAnswer={handleChangeAnswer}
                />
            )}
        </React.Fragment>
    );
}

export default QuestionTypeCondition;