import React from 'react';
import { Button } from 'reactstrap';

function LabSearch(props) {
    const { onSearch, searchText } = props && props;
    return (
        <div className="boxSearch justify-content-end">
            <input
                type="text"
                className="form-control"
                onChange={onSearch}
                value={searchText}
                placeholder="Search"
                autoComplete="off"
            />
            <Button color='primary' className="btnSearch">
                <i className="fa fa-search" aria-hidden="true" />
            </Button>
        </div>
    );
};

export default LabSearch;