import React, { useState, useCallback, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label, FormFeedback } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import ClipLoader from "react-spinners/ClipLoader";
import LoginBySocial from './LoginBySocial/LoginBySocial';
import { actionLogin, actFetchThemeRequest } from "@actions";
import { apiCaller } from "@utils";

//untils
import { checkAuth } from "@utils";

// More comprehensive email validation regex
const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const SignupSchema = Yup.object().shape({
  email: Yup.string().matches(emailValidationRegex, 'Please enter a valid email address.').required('Please enter your email.'),
  password: Yup.string().required('Please enter your password.'),
});

const FormLoginV2 = () => {
  const history = useHistory();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [dataLogin, setDataLogin] = useState('');
  const dispatch = useDispatch();

  // Check Token Login
  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Redirect Register
  const handleRedirectRegister = useCallback(() => {
    history.push('/register');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsed = queryString.parse(window.location.search);

  // Handle Next Page
  const nextPage = useCallback((isInstructor) => {
    const paramsRedirectLink = parsed?.redirect;
    const redirectPath = paramsRedirectLink ? paramsRedirectLink : (isInstructor === 'Yes') ? "/teach/home" : "/learn/home";
    history.push(redirectPath);
  }, [parsed, history]);

  // Handle Login Success
  const handleLoginSuccess = (dataResponse) => {
    const accountData = dataResponse?.account || {};
    const { is_instructor: isInstructor } = accountData;

    // Load Theme
    dispatch(actionLogin(dataResponse));
    dispatch(actFetchThemeRequest());

    //Instructor
    nextPage(isInstructor);
  };

  // Handle Submit
  const handleSubmit = async (data) => {
    setLoadingLogin(true);
    try {
      const response = await apiCaller('/api/auth/login/', 'POST', {
        username: data.email,
        password: data.password,
      });

      // Save localStorage
      localStorage.setItem("Account", JSON.stringify(response.data || ""));

      if (response?.data?.status === 'OK') {
        response?.data && handleLoginSuccess(response.data);
      } else {
        setDataLogin(response?.data?.status || 'Error');
      }
    } catch (error) {
      console.error('Login failed', error);
      setDataLogin('Error');
    } finally {
      setLoadingLogin(false);
    }
  };

  return (
    <div className="wrap-login">
      <div className="wrap-login__title">
        <h1>Welcome to CognitusAI</h1>
        <p>Please enter the information below to login.</p>
      </div>
      {dataLogin && <FormFeedback className="d-block text-center" dangerouslySetInnerHTML={{ __html: dataLogin }}></FormFeedback>}
      <div className="wrap-login__form">
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form className='form-custom'>
              <div className='form-custom__wrap'>
                <div>
                  <Label for="exampleEmail">
                    Email <span>*</span>
                  </Label>
                  <Input
                    className={errors.email && touched.email ? 'error' : ''}
                    id="exampleEmail"
                    type="email"
                    name="email"
                    placeholder='Enter your email address'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && <span className="text-err">{errors.email}</span>}
                </div>
                <div>
                  <Label for="password">
                    Password <span>*</span>
                  </Label>
                  <Input
                    className={errors.password && touched.password ? 'error' : ''}
                    id="password"
                    type="password"
                    name="password"
                    placeholder='Enter your password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {errors.password && touched.password && <span className="text-err">{errors.password}</span>}
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-end'>
                <Link to='/auth/reset-password' className='text-forget'>Forgot password?</Link>
              </div>
              <div className='form-custom__action d-flex align-items-center justify-content-center'>
                <Button
                  type="submit"
                  color='primary'
                  className='d-flex align-items-center justify-content-center'
                  disabled={loadingLogin}
                >
                  {loadingLogin && <div className='mr-1 d-flex align-items-center'><ClipLoader color="#FFF" size={16} /></div>}
                  Login
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="wrap-login__form-register">
          <p>Don’t have an account?<span onClick={handleRedirectRegister}> Register now</span></p>
        </div>
        <p className='wrap-login__form-option'>Or</p>
        <div className="wrap-login__form-social">
          <LoginBySocial />
        </div>
      </div>
    </div>
  );
};

export default FormLoginV2;