import React, { useEffect } from 'react';

//components
import { 
    ScrumLeft,
    ScrumRight,
    ScrumSprints
} from '../scrum';

//call Action
import {
    actLoadScrum
} from "@actions";

//boostrap
import {
    Container,
    Row
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

function ScrumContainer(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        //Load Api
        const fetchData = async () => {
            dispatch(actLoadScrum());
        };

        fetchData();
    }, [dispatch]);

    const scrumStore = useSelector((state) => state.dataScrum || []);
    return (
        <div className="scrumCt">
            <Container className="position-relative">
                <Row>
                    <ScrumSprints {...scrumStore} />
                    <ScrumLeft {...scrumStore} />
                    <ScrumRight {...scrumStore} />
                </Row>
            </Container>
        </div>
    );
};

export default ScrumContainer;