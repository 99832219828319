import React, { useState, useEffect } from "react";

function ExplanationItem(props) {
  const { currentObject, handleChangeInput, indexParent } = props && props;

  //state
  const [stateData, setData] = useState(currentObject || {});

  //load
  useEffect(() => {
    setData(currentObject || {});
  }, [currentObject]);

  return (
    <textarea
      name="Explanation"
      className="form-control m-input"
      rows={1}
      value={stateData?.Explanation}
      data-index-parent={indexParent}
      onChange={handleChangeInput}
    />
  );
}

export default ExplanationItem;
