import React, { useState, useEffect } from "react";

const CaseHeader = (props) => {
    const { teachCaseData } = props;

    const [caseData, setCaseData] = useState(teachCaseData);

    useEffect(() => {
        setCaseData(teachCaseData);
    }, [teachCaseData]);

    return (
        <div className="case-header">
            <p className="case-header__title">Case Title:</p>
            <p className="case-header__text">{caseData?.Title}</p>
        </div>
    );
};

export default CaseHeader;