import React from "react";

import {
    Input,
    FormGroup,
    Label,
} from "reactstrap";

function StatusOptions(props) {
    const { handleChange, stateDefaultActiveStatus } = props && props;
    return (
        <React.Fragment>
            <Label className="font-weight-500" for="title">
                Default Status
            </Label>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`DefaultActiveStatus`}
                        type="radio"
                        value={`active`}
                        className="mt-1"
                        onChange={handleChange}
                        checked={stateDefaultActiveStatus === true}
                    />
                    Active
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`DefaultActiveStatus`}
                        type="radio"
                        value={`inactive`}
                        className="mt-1"
                        onChange={handleChange}
                        checked={stateDefaultActiveStatus === false}
                    />
                    Inactive
                </Label>
            </FormGroup>
        </React.Fragment>
    );
}

export default StatusOptions;