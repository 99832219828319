import React, { useState } from 'react';

//components
import {
    RowIntervention,
    EffectCollapse
} from '../../storyboard/common';

function AccordionFilter(props) {
    const { interventionsIndex, value, item } = props && props;

    //state
    const [isOpen, setOpen] = useState(false);

    const hanldeCollapse = () => {
        setOpen(!isOpen);
    }
    
    return (
        <React.Fragment key={interventionsIndex}>
            <tbody>
                <RowIntervention
                    {...props}
                    item={item}
                    value={value}
                    hanldeCollapse={hanldeCollapse}
                    isOpen={isOpen}
                />
            </tbody>
            {isOpen &&
                <tbody>
                    <EffectCollapse {...props} value={value} item={item} />
                </tbody>
            }
        </React.Fragment>
    );
};

export default AccordionFilter;