import React from "react";

const TimepointContent = ({ id, children, timepointIdActive }) => {
    return (
        <div className={`case-timpoint-vertical__item ${timepointIdActive === id ? "isOpen" : "d-none"}`}>
            <div className="tp-vertical-inner">
                <div className="tp-vertical-inner__details">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default TimepointContent;