import React, { useState } from "react";

//reactstrap
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actVitalSignChange } from "@actions";

function ItemRow (props) {
  //props
  const { data, timePointId } = props && props;

  //params
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [stateName, setName] = useState(data || []);

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    const newState = [...stateName];
    newState[index][name] = value;
    setName(newState);
  };

  const handleSave = (item) => {
    const params = {
      "Action": "Update",
      "Target": "TimePoint",
      "TimePointId": timePointId,
      "VitalSignChange": {
        "Id": item?.Id,
        "ChangeMode": item?.ChangeMode,
        "ChangeValue": item?.ChangeValue,
        "ChangeLimit": item?.ChangeLimit
      }
    }
    dispatch(actVitalSignChange(id, params));
  }

  return (
    <React.Fragment>
      {stateName?.map((value, index) => (
        <tr key={index}>
          <td className="align-middle">{value?.Name || ''}</td>
          <td className="text-center align-middle">
            <span className="span_value ">{value?.InitialValue || ''}</span>{" "}
            <span className="font-weight-300">
              {value.Unit === "C" ? "℃" : value?.Unit}
            </span>
          </td>
          <td className="align-middle">
            <FormGroup className="mb-0">
              <Input
                type="select"
                defaultValue={value?.ChangeMode}
                onChange={(e) => handleChange(e, index)}
                name="ChangeMode"
              >
                <option value="none">No Change</option>
                <option value="set">Set Value At ...</option>
                <option value="increase">Increase Value By ...</option>
                <option value="decrease">Decrease Value By ...</option>
              </Input>
            </FormGroup>
          </td>
          <td className="align-middle">
            {((value?.ChangeMode) === "set" ||
              (value?.ChangeMode) === "increase" ||
              (value?.ChangeMode) === "decrease") &&
              <React.Fragment>
                <InputGroup>
                  <Input
                    type="text"
                    name="ChangeValue"
                    defaultValue={value.ChangeValue}
                    placeholder="Value"
                    onChange={(e) => handleChange(e, index)}
                    autoComplete="off"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <div
                        dangerouslySetInnerHTML={{ __html: value?.UnitAlias || '' }}
                      ></div>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {(value?.ChangeMode === "increase" || value?.ChangeMode === "decrease") && (
                  <div
                    id="change_limit_prompt_145098"
                    className="m--font-info font-weight-500 text-right mt-2"
                  >
                    {value?.ChangeMode === "increase" && "But Not Larger Than"}
                    {value?.ChangeMode === "decrease" && "But Not Smaller Than"}
                  </div>
                )}
              </React.Fragment>
            }
            {((value?.ChangeMode) === "increase" ||
              (value?.ChangeMode) ===
              "decrease") &&
              <InputGroup className="mt-3">
                <Input
                  type="text"
                  name="ChangeLimit"
                  defaultValue={value?.ChangeLimit}
                  placeholder="Limit"
                  onChange={(e) => handleChange(e, index)}
                  autoComplete="off"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <div
                      dangerouslySetInnerHTML={{ __html: value?.UnitAlias }}
                    ></div>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            }
          </td>
          <td className="align-middle text-center">
            <button
              className="btn btn-sm btn-info button_edit_item"
              onClick={() => handleSave(value)}
              disabled={value?.ChangeMode === "none"}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
}

export default ItemRow;