import { questionConstants } from "@constants";

export function postQuestion (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case questionConstants.QUESTION_SUBMIT:
            newState = { ...state };
            newState.data = action.payload
            if(action.payload && action.payload.QuestionResponse) {
                newState.isQuestionResponse = true
            } else {
                newState.isQuestionResponse = false
            }
            return {
                ...newState,
                isLoading: false
            };
        case questionConstants.QUESTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
}