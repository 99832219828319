import React, { useEffect, useState } from 'react';

//components
import { CollectionList, CollectionTop } from '../templates';

function CollectionMain(props) {
    const { templatesStore, hanldeTemplateCollectionById, hanldeAllTemplate } = props && props;
    const data = templatesStore?.data;
    const TemplateCollections = data?.TemplateCollections;

    //state
    const [allCollection, setCollection] = useState((TemplateCollections && TemplateCollections) || []);

    useEffect(() => {
        setCollection((TemplateCollections && TemplateCollections) || []);
    }, [TemplateCollections])

    return (
        <React.Fragment>
            <CollectionTop
                hanldeAllTemplate = {hanldeAllTemplate}
                {...props}
            />
            <CollectionList 
                data={allCollection}
                hanldeTemplateCollectionById={hanldeTemplateCollectionById}
                {...props}
            />
        </React.Fragment>
    );
};

export default CollectionMain;