import React from 'react'
import { Row, Col } from "reactstrap";

// img
import Summary1 from "@images/teach/summary1.svg";
import Summary2 from "@images/teach/summary2.svg";
import Summary3 from "@images/teach/summary3.svg";

export default function SummaryIntakeOutput() {
  return (
    <div className="summay-intake-output mb-4">
      <h1 className="summary-title">Summary</h1>
      <Row className="summary-wrap">
        <Col md={4}>
          <div className="summary-wrap__common">
            <div className="summary-wrap__common-icon">
              <img src={Summary1} alt="summary" />
            </div>
            <h1 className="summary-wrap__common-cost">$ 887,072.96</h1>
            <p className="summary-wrap__common-title">Total Intake</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="summary-wrap__common">
            <div className="summary-wrap__common-icon">
              <img src={Summary2} alt="summary" />
            </div>
            <h1 className="summary-wrap__common-cost">$ 186,803.04</h1>
            <p className="summary-wrap__common-title">Net Balance</p>
          </div>
        </Col>
        <Col md={4}>
          <div className="summary-wrap__common">
            <div className="summary-wrap__common-icon">
              <img src={Summary3} alt="summary" />
            </div>
            <h1 className="summary-wrap__common-cost">$ 887,072.96</h1>
            <p className="summary-wrap__common-title">Total Output</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}
