export const vitalConstant = {
    SET_STATUS_VITAL_SIGNS_REQUEST: 'SET_STATUS_VITAL_SIGNS_REQUEST',
    SET_STATUS_VITAL_SIGNS_SUCCESS: 'SET_STATUS_VITAL_SIGNS_SUCCESS',
    SET_STATUS_VITAL_SIGNS_FAILURE: 'SET_STATUS_VITAL_SIGNS_FAILURE',

    LOAD_VITAL_SIGNS_FOR_ELEMENTS_REQUEST: 'LOAD_VITAL_SIGNS_FOR_ELEMENTS_REQUEST',
    LOAD_VITAL_SIGNS_FOR_ELEMENTS_SUCCESS: 'LOAD_VITAL_SIGNS_FOR_ELEMENTS_SUCCESS',
    LOAD_VITAL_SIGNS_FOR_ELEMENTS_FAILURE: 'LOAD_VITAL_SIGNS_FOR_ELEMENTS_FAILURE',

    VITAL_SIGN_CHANGE_FOR_ELEMENTS_REQUEST: 'VITAL_SIGN_CHANGE_FOR_ELEMENTS_REQUEST',
    VITAL_SIGN_CHANGE_FOR_ELEMENTS_SUCCESS: 'VITAL_SIGN_CHANGE_FOR_ELEMENTS_SUCCESS',
    VITAL_SIGN_CHANGE_FOR_ELEMENTS_FAILURE: 'VITAL_SIGN_CHANGE_FOR_ELEMENTS_FAILURE',

    UPDATE_VALUE_VITAL_FOR_ELEMENTS_REQUEST: 'UPDATE_VALUE_VITAL_FOR_ELEMENTS_REQUEST',
    UPDATE_VALUE_VITAL_FOR_ELEMENTS_SUCCESS: 'UPDATE_VALUE_VITAL_FOR_ELEMENTS_SUCCESS',
    UPDATE_VALUE_VITAL_FOR_ELEMENTS_FAILURE: 'UPDATE_VALUE_VITAL_FOR_ELEMENTS_FAILURE',

    UPDATE_VITAL_SIGN_ORDER_REQUEST: 'UPDATE_VITAL_SIGN_ORDER_REQUEST',
    UPDATE_VITAL_SIGN_ORDER_SUCCESS: 'UPDATE_VITAL_SIGN_ORDER_SUCCESS',
    UPDATE_VITAL_SIGN_ORDER_FAILURE: 'UPDATE_VITAL_SIGN_ORDER_FAILURE'
};