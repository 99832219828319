import React, { useState, useEffect } from 'react';

//Boostrap
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//lodash
import _ from "lodash";

//actions
import { loadChatTopicsAllTimepoints } from "@actions";

//api
import { apiCaller } from "@utils";
import { elementsConstants } from "@constants";

//common
import { DataEmty } from "@components-common";

function ModalAddChatTopic(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { isOpen, toggle, data, buttonText, Timeline } = props && props;

    //State
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState((data && data) || []);
    const [isIdChecked, setIdChecked] = useState('');
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setResults((data && data) || []);
    }, [data])

    const hanldeSubmit = () => {
        const TimePointIdFirst = _.filter(Timeline, function (o) {
            return o?.Time === 0;
        });
        const TimePointIdFilter = TimePointIdFirst && TimePointIdFirst[0]?.TimepointId;

        if (TimePointIdFilter) {
            const params = {
                "Action": "Create",
                "Target": "ChatFromTopics",
                "TimePointId": TimePointIdFilter,
                "ChatTopicIds": [isIdChecked]
            }
            setLoading(true);
            
            //Call Api
            apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
                const data = res && res.data;
                if (res && res.status === 200) {
                    dispatch({ type: elementsConstants.ADD_CHAT_TOPICS_TO_CASE_SUCCESS, payload: data });

                    //Reload data
                    const paramsLoad = {
                        "Action": "Load",
                        "Target": "Chat"
                    }
                    dispatch(loadChatTopicsAllTimepoints(id, paramsLoad));

                    setTimeout(() => {
                        setLoading(false);
                        toggle();
                    }, 3000);
                } else {
                    dispatch({
                        type: elementsConstants.ADD_CHAT_TOPICS_TO_CASE_FAILURE,
                        error: "error",
                    });
                }
            });
        }
    }

    const hanldeChange = (id) => {
        setIdChecked(id);
    }

    const hanldeSearch = (value) => {
        const listDataClone = _.cloneDeep((data && data) || []);
        if (value) {
            const resultsFilter = listDataClone?.filter(function (elem) {
                return elem.Name.toLowerCase().includes(value.toLowerCase());
            });
            setResults(resultsFilter);
        } else {
            setResults((data && data) || []);
        }
        setSearchTerm(value);
    }

    const todoItems = results?.map((todo, index) =>
        <FormGroup className="d-flex align-items-center border-bottom py-3" style={{ cursor: 'pointer' }} check key={index} onClick={() => hanldeChange(todo?.Id)}>
            <Input
                name={`radio_schema`}
                type="radio"
                value={todo?.Id}
                checked={isIdChecked === todo?.Id ? true : false}
                bsSize={'lg'}
                onChange={() => hanldeChange(todo?.Id)}
            />
            {' '}
            <Label id={`radio_schema`} check>
                <h3>{todo?.Name}</h3>
                <p className="text-muted mt-2">ID: {todo?.Id}</p>
            </Label>
        </FormGroup>
    );

    return (
        <Modal
            toggle={toggle}
            isOpen={isOpen}
            modalClassName={`right ${isOpen ? "in" : ""}`}
            wrapClassName={`modalFixed`}
        >
            <ModalHeader toggle={toggle}>
                Add {buttonText}
            </ModalHeader>
            <div className="d-flex align-items-center w-100 p-3 border-bottom">
                <Input
                    type="text"
                    name="search"
                    placeholder="Search"
                    style={{ height: "38px" }}
                    value={searchTerm}
                    onChange={(e) => hanldeSearch(e.target.value)}
                    autoComplete="off"
                />
            </div>
            <ModalBody>
                {todoItems}
                {results && results.length === 0 &&
                    <DataEmty content={`No Data`} />
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={hanldeSubmit}
                    disabled={(isIdChecked === '' ? true : false) || isLoading}
                    className="d-flex align-items-center"
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Add
                </Button>
                {' '}
                <Button onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddChatTopic;