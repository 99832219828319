import { scrumConstant } from "@constants";

export function dataScrum (state = {}, action) {
    switch (action.type) {
        case scrumConstant.LOAD_SCRUM_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case scrumConstant.LOAD_SCRUM_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case scrumConstant.LOAD_SCRUM_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case scrumConstant.LOAD_SCRUM_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function dataScrumById (state = {}, action) {
    switch (action.type) {
        case scrumConstant.LOAD_SCRUM_BY_ID_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case scrumConstant.LOAD_SCRUM_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case scrumConstant.LOAD_SCRUM_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};