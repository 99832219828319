import React from "react";

//router
import { useParams } from "react-router-dom";

//action
import { atcDeleteCheckPoint } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//Boostrap
import { 
    Button, 
    Modal,
    ModalBody, 
    ModalFooter
} from "reactstrap";

function ModalDeleteCheckPoints (props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const timePointId = props && props.timePointId;
  const paramsData = props && props.paramsData;
  
  const handleRemove = () => {
    const params = {
      "Action": "Delete",
      "Target": "Checkpoints",
      "TimePointId": timePointId,
      "CheckPointId": String(paramsData && paramsData.CheckPointId)
    }
    dispatch(atcDeleteCheckPoint(id, params));
    props && props.toggleDel();
  }

  const checkpoints = useSelector(state => state.loadCheckpoints || []);
  return (
    <Modal isOpen={props && props.isOpen} toggle={props && props.toggleDel} backdrop={`static`}>
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you certain that you would like to delete this check point?
        </h4>
        <p className="mt-2 text-center">
          This action is not reversible
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
          disabled={checkpoints && checkpoints.isLoading}
        >
          {checkpoints && checkpoints.isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={props && props.toggleDel}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteCheckPoints;
