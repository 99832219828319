import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";

const ModalInvite = (props) => {
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;
  const handleAdd = props && props.handleAdd;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Account</ModalHeader>
        <ModalBody>
          <p>
            Enter an email to create / add a new account to your subscription
            <span className="m--font-danger">*</span>
          </p>
          <hr />
          <FormGroup className="mt-2">
            <Input
              type="email"
              name="Email"
              {...register("Email", { required: true })}
              invalid={errors.Email?.type === "required"}
              defaultValue={watch("Name")}
              onChange={(e) => {
                setValue("Email", e.target.value, { shouldValidate: true });
              }}
              placeholder="Enter your email address"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button 
            color="primary"
            onClick={handleSubmit(handleAdd)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalInvite;
