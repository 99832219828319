import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { Studies } from "@components/elements";
import { HeadingBox } from "@components/common";

//actions
import { atcLoadAllStudiesForElements } from "@actions";

function StudyPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "Studies",
      };
      dispatch(atcLoadAllStudiesForElements(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //storeExamFindings
  const storeStudies = useSelector(
    (state) => state?.dataStudiesForElement || {}
  );

  const listData = storeStudies?.data;
  return (
    <div className="wrapper -eStudyPage">
      <section className="eStudyElementCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Studies" />
          <Studies storeStudies={storeStudies} data={(listData && listData) || []} />
        </Container>
      </section>
    </div>
  );
}

export default StudyPage;
