import React from "react";

//components
import { PricingContentPc } from "../pricing";

function PricingPc(props) {
  const { data } = props && props;
  return (
    <div className="pricing-wrapper">
      <div className="pricing-content">
        <div className="pricing-flex-wrapper">
          <div className="pricing-flex pricing-description border-end"></div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row pricing-top">
              <div className="headline-small">PILOT</div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row pricing-top">
              <div className="headline-small">CURRICULUM</div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier">
            <div className="flex-row pricing-top">
              <div className="headline-small">INSTITUTION</div>
            </div>
          </div>
        </div>
        <div className="pricing-flex-wrapper">
          <div className="pricing-flex pricing-description border-end">
            <div className="flex-row" />
          </div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row">
              <div className="third-break">
                <div>
                  For teaching / practicing in small group sessions, or simply
                  trying out our system
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row">
              <div className="third-break">
                <div>
                  For assessment and coaching in training programs, practice
                  groups, or small health facilities{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier">
            <div className="flex-row">
              <div className="third-break">
                <div>
                  For scalable evaluation and teaching in professional schools
                  and health systems{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing-flex-wrapper">
          <div className="pricing-flex pricing-description border-end">
            <div className="flex-row" />
          </div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row">
              <h1 className="headline">
                <span>US$7</span>
                <sup>99</sup>
              </h1>
              <div className="pricing-plan-description">
                per active user* per month
              </div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier border-end">
            <div className="flex-row">
              <h1 className="headline">
                <span>US$499</span>
              </h1>
              <div className="pricing-plan-description">
                per month
                <br />
                <span className="text-dark font-weight-500 mt-1 d-block">
                  (includes <strong>100</strong> users)
                </span>
              </div>
            </div>
          </div>
          <div className="pricing-flex pricing-tier">
            <div className="flex-row">
              <h1 className="headline text-10">
                Custom <br /> pricing
              </h1>
            </div>
          </div>
        </div>
        <PricingContentPc data={(data && data) || []} />
      </div>
    </div>
  );
}

export default PricingPc;