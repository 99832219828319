import React, { useState, useEffect } from 'react';

//reactstrap
import { Row, Col, Button } from "reactstrap";

//components
import { AvailabilityDropdown } from '../../storyboard/common';

//lodash
import _ from "lodash";

function AccordionFilter(props) {
    //props
    const { data, searchText, onSearch, toggleCategory, categoryType, timePointId, updateAvailableinBatchWithApi } = props && props;

    //state
    const [listInterventions, setInterventions] = useState([]);

    //load Data
    useEffect(() => {
        //convert Data
        const convertDataInterventions = (list) => {
            let newInterventions = [];
            _.forEach(list, function (value) {
                if (value?.Interventions?.length > 0) {
                    const CategoryId = value?.CategoryId;
                    const Interventions = value?.Interventions.map(item => ({ ...item, CategoryId }))
                    newInterventions.push(...Interventions);
                }
            });
            return newInterventions;
        }
        setInterventions(convertDataInterventions(data) || []);
    }, [data])

    // Case - Update Intervention Available in Batch
    const updateInterventionAvailableinBatch = (status, data, categoryId) => {
        if (listInterventions?.length > 0) {
            const newAvailabilityData = listInterventions?.map((item) => {
                const isChecked = status === "Available" ? true : false;
                return {
                    InterventionId: item?.Id,
                    CategoryId: item?.CategoryId,
                    Available: isChecked,
                };
            });

            const params = {
                Action: "Update",
                Target: "InterventionAvailability",
                TimePointId: timePointId,
                InterventionAvailabilityData: newAvailabilityData || [],
            };

            if (categoryType === "Medication") {
                params.InterventionType = "Medication";
                updateAvailableinBatchWithApi(params, categoryType)
            } else {
                params.InterventionType = "Action";
                updateAvailableinBatchWithApi(params, categoryType)
            }
        }
    };

    return (
        <Row className="justify-content-end mb-3">
            <Col md={6}>
                <div className="boxSearch">
                    <input
                        type="text"
                        className="form-control"
                        onChange={onSearch}
                        value={searchText}
                        placeholder="Search"
                        autoComplete="off"
                    />
                    <Button className="btnSearch" color='primary'>
                        <i className="fa fa-search" aria-hidden="true" />
                    </Button>
                </div>
            </Col>
            <Col md={6} className="mb-md-0 d-flex algin-items-center justify-content-end">
                <AvailabilityDropdown
                    {...props}
                    handleAllAvailable={updateInterventionAvailableinBatch}
                />
                <button
                    className="btn ml-2 btn-outline-brand"
                    onClick={toggleCategory}
                >
                    Add Category
                </button>
            </Col>
        </Row>
    );
};

export default AccordionFilter;