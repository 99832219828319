import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalClone(props) {
  const { isOpen, toggle, handleSubmit, isLoading } = props && props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you certain that you would like to duplicate this question?
        </h4>
        <p className="mt-2 text-center">
          This action is not reversible.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="btn btn-brand d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          OK
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalClone;
