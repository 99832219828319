import React, { useRef } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//partents
import { FormModal } from '@ui-partents/Modal';

const ModalFormEditNote = ({ isOpen, toggleModal, title }) => {
  const formikRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    explanation: Yup.string().required("Explanation is required"),
    actionMessage: Yup.string().required("Action Message is required"),
    duration: Yup.number().required("Duration is required"),
  });

  const handleSaveButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      onAction={handleSaveButtonClick}
      actionText={"Save"}
      maxWidth="860px"
    >
      <Formik
        initialValues={{
          name: "",
          explanation: 0,
          actionMessage: 0,
          duration: 0,
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
        }) => (
          <Form className="form-intake">
            <FormGroup>
              <Label className="labeForm" for="title">
                Name <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="CT - Abdomen ADAD test image"
                type="text"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.title && touched.title}
              />
              <FormFeedback>{errors.title}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="type">
                Explanation <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder=""
                type="number"
                name="type"
                id="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.type && touched.type}
              />
              <p className="txt-modal-note mt-1">[Optional] Notifying the learner that the action has been activated</p>
              <FormFeedback>{errors.type}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="actionMessage">
                Action Message: <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder=""
                type="number"
                name="actionMessage"
                id="actionMessage"
                value={values.actionMessage}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.actionMessage && touched.actionMessage}
              />
              <FormFeedback>{errors.actionMessage}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="duration">
                Action Message Showing Duration: <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder=""
                type="number"
                name="duration"
                id="duration"
                value={values.duration}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.duration && touched.duration}
              />
              <FormFeedback>{errors.duration}</FormFeedback>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </FormModal>
  );
};

export default ModalFormEditNote;
