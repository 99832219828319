import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label } from 'reactstrap';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actionLogin, actFetchThemeRequest } from "@actions";
import { apiCaller, checkAuth } from "@utils";

// Components
import { ModalMessageEmail } from "@components/auth/SpecialLogIn";

// Styled-components
import styled from 'styled-components';

// Logo
import imgLogo from '@images/logo_cognitus_ai.png';

// Email validation regex
const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .matches(emailValidationRegex, 'Please enter a valid email address.')
        .required('Please enter your email.'),
    checkbox: Yup.boolean()
        .oneOf([true], ''),
});

// Styled component for the logo
const Logo = styled.img`
  max-width: 260px;
`;

// const StyledLink = styled.a`
//   color: var(--base-brand-green);
//   font-size: 14px;
//   font-weight: 600;
//   text-decoration: none;
// `;

const EmailOnly = () => {
    const history = useHistory();
    const { slug } = useParams(); // Get slug from the URL
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState(''); // State to hold the email
    const dispatch = useDispatch();

    // Check Token Login
    useEffect(() => {
        if (checkAuth()) {
            history.push("/");
        }
    }, [history]);

    // Handle Login Success
    const handleLoginSuccess = (dataResponse) => {
        dispatch(actionLogin(dataResponse));
        dispatch(actFetchThemeRequest());
        history.push("/"); // Redirect after login
    };

    // Handle Submit
    const handleSubmit = async (data) => {
        setLoadingLogin(true);
        setEmail(data.email); // Store email for the error message
        try {
            const response = await apiCaller('/api/auth/login_special/', 'POST', {
                Username: data.email,
                Code: slug // Use slug from URL as Code
            });

            if (response?.data?.status === 'OK') {
                handleLoginSuccess(response.data);
            } else if (response?.data?.status === 'account_exists') {
                setShowModal(true); // Show modal if account exists
            } else {
                // Handle other errors
            }
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setLoadingLogin(false);
        }
    };

    // Handle modal button click
    const handleModalButtonClick = (url) => {
        setShowModal(false); // Close modal
    };

    // Modal message configuration
    const dataMessage = {
        error: true,
        title: 'Account Already Exists',
        message: `<p>An account with the email <strong>${email}</strong> already exists. Please log in to continue.</p>`,
        buttons: [
            {
                ButtonTitle: 'Back',
                ButtonStyle: 'secondary',
                onButtonClick: () => {
                    setShowModal(false); // Close the modal
                },
            },
            {
                ButtonTitle: 'Go to Login',
                ButtonStyle: 'primary',
                URL: '/login',
                onButtonClick: (url) => {
                    history.push(url);
                },
            },
        ]
    };

    return (
        <React.Fragment>
            {!showModal && (
                <div className="wrap-login w-100">
                    <div className="wrap-login__title">
                        <div className="my-3">
                            <Logo src={imgLogo} alt="weitzman" />
                        </div>
                        <h1>Welcome to CognitusAI</h1>
                        <p>Please enter the information below to login.</p>
                    </div>
                    <div className="wrap-login__form">
                        <Formik
                            initialValues={{ email: ''}}
                            validationSchema={SignupSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, handleChange, handleBlur }) => (
                                <Form className='form-custom'>
                                    <div className='form-custom__wrap'>
                                        <div>
                                            <Label for="exampleEmail">
                                                Email <span>*</span>
                                            </Label>
                                            <Input
                                                className={errors.email && touched.email ? 'error' : ''}
                                                id="exampleEmail"
                                                type="email"
                                                name="email"
                                                placeholder='Enter your email address'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email && <span className="text-err">{errors.email}</span>}
                                        </div>
                                    </div>
                                    {/* <FormGroup check className='form-common'>
                                        <Input
                                            className={`react-checkbox-common`}
                                            type="checkbox"
                                            name="checkbox"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={values.checkbox}
                                        />
                                        <span>
                                            I have read and agree to the{" "}
                                            <StyledLink href='https://cognitusai.com/terms' rel="noreferrer" target="_blank">
                                                Terms of Service
                                            </StyledLink>{" "}
                                            and{" "}
                                            <StyledLink href='https://cognitusai.com/privacy' rel="noreferrer" target="_blank">
                                                Privacy Policy.
                                            </StyledLink>
                                        </span>
                                        {errors.checkbox && touched.checkbox && <span className="text-err">{errors.checkbox}</span>}
                                    </FormGroup> */}
                                    <div className='form-custom__action d-flex align-items-center justify-content-center'>
                                        <Button
                                            type="submit"
                                            color='primary'
                                            className='d-flex align-items-center justify-content-center'
                                            disabled={loadingLogin} // Disable if loading or checkbox is not checked
                                        >
                                            {loadingLogin ? <span className='mr-1'>Loading...</span> : `Login`}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            )}
            {showModal && <ModalMessageEmail dataMessage={dataMessage} handleModalButtonClick={handleModalButtonClick} />}
        </React.Fragment>
    );
};

export default EmailOnly;