import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const SectionTitle = styled.h3`
  color: #081B2A;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
`;

const Group = styled.div`
  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  background: #E9F7FE;
  box-shadow: 3px 4px 0px 0px #5197C8;
  overflow-y: auto;
`;

const InnerWrapper = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 16px;
`;

const MediaWrapper = styled.div`
  margin-left: 12px;
`;

const ExamListData = () => {
  const caseData = useSelector((state) => state.cases || []);
  const dataResponse = caseData?.data;
  const ExamFindingList = dataResponse?.Case?.ClinicalStatus?.ExamFindingList || [];

  return (
    <ContentWrapper>
      <InnerWrapper>
        {ExamFindingList?.map((item, index) => (
          <Group key={index}>
            <SectionTitle>{item.Title}</SectionTitle>
            <MediaWrapper>
              {item.Report && (
                <div dangerouslySetInnerHTML={{ __html: item.Report }} />
              )}
              <div className='mt-3'>
                {item.MediaType === "ImageFile" && item.Url && (
                  <img className='img-fluid' src={item.Url} alt={item.Title} />
                )}
                {item.MediaType === "VideoLink" && item.Url && (
                  <ReactPlayer
                    url={item.Url}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </MediaWrapper>
          </Group>
        ))}
      </InnerWrapper>
    </ContentWrapper>
  );
};

export default ExamListData;