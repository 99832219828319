import React, { useState, useEffect } from "react";

//reactstrap
import { UncontrolledAlert } from "reactstrap";

//Modal components
import { ModalUpdateTemplate } from "../Modal";

//redux
import { useDispatch } from "react-redux";

//Paginate
import { PaginatedItems } from '@components-common';

import isEqual from 'lodash/isEqual'

//components
import {
  Filter,
  CollectionMain,
  TemplateListRight,
  ButtonTemplateToCollection,
  RemoveTemplateFromCollection
} from "../templates";

//call Action
import {
  atcHightLightTemplate,
  loadTemplateByCollectionId,
  actFetchTemplate,
  actRemoveTemplateFromCollection
} from "@actions";

//lodash
import _ from "lodash";

function TemplateMain(props) {
  const dispatch = useDispatch();
  const { type, templatesStore } = props && props;
  const templatesData = templatesStore?.data;
  let SystemTemplates = templatesData?.SystemTemplates;

  //state
  //Modal Update
  const [modal, setModal] = useState({
    addTemplate: false,
    editTemplate: false,
    removeTemplate: false
  });
  const [actions, setActions] = useState("");
  const [itemEdit, setItemEdit] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isReset, setIsReset] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [itemCollection, setCollection] = useState({ TemplateCollectionId: 0 });

  //Search With Name
  useEffect(() => {
    const data = templatesData?.SystemTemplates;
    const results = data?.filter(function (elem) {
      return elem.TemplateName.toLowerCase().includes(
        searchTerm.toLowerCase()
      );
    });
    setSearchResults(results);
  }, [searchTerm, templatesData]);

  const toggle = (data, type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setActions(type);
    setItemEdit(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "search") {
      setSearchTerm(value);
    } else {
      filterOptions(name, value);
    }
  };

  //Load Template Collection
  const hanldeTemplateCollectionById = (item) => {
    setCollection(item);

    //Load api
    const params = {
      "Action": "LoadTemplateCollection",
      "TemplateCollectionId": item?.TemplateCollectionId || null
    }

    dispatch(loadTemplateByCollectionId(params));
  }

  //get All Template
  const hanldeAllTemplate = () => {
    //load templates
    dispatch(actFetchTemplate());
    setCollection({ TemplateCollectionId: 0 });
  }

  const hanldeRemoveTemplatefromCollection = (value) => {
    const params = {
      Action: "RemoveTemplateFromCollection",
      TemplateCollectionId: parseFloat(itemCollection?.TemplateCollectionId),
      TemplateId: parseFloat(value?.TemplateId)
    }
    dispatch(actRemoveTemplateFromCollection(params));
    setModal((prevState) => ({ ...prevState, removeTemplate: false }));
  }

  const hanldeSwitch = (idTemplate, event) => {
    const { name, checked } = event.target;
    const params = {
      Action: "Update",
      TemplateId: idTemplate,
      [name]: checked,
    };
    dispatch(atcHightLightTemplate(params));
  };

  const filterOptions = (name, value) => {
    setFilter(true);
    let data = templatesData?.SystemTemplates;
    if (name === "Age" && value) {
      const results = _.filter(data, function (elem) { return elem.AgeGroup.AgeGroupId === parseFloat(value) });
      setSearchResults(results || data);
    } else if (name === "Gender" && value) {
      const results = _.filter(data, function (elem) { return elem.Gender.GenderId === parseFloat(value) });
      setSearchResults(results || data);
    } else if (name === "Ethnicity" && value) {
      const results = _.filter(data, function (elem) { return elem.EthnicityGroup.EthnicityGroupId === parseFloat(value) });
      setSearchResults(results || data);
    } else if (name === "InstitutionalAccess" && value) {
      //define Boolean
      let boolValueFalse = (value === "false");
      let boolValueTrue = (value === "true");
      if (type === 'adminstrator') {//(Manger)
        if (boolValueTrue) {
          //Institutional Templates - show GlobalAccess = false, Institution Access = true
          const results = _.filter(data, function (elem) { return elem.GlobalAccess === false && elem.InstitutionalAccess === boolValueTrue });
          setSearchResults(results || data);
        } else {
          //My Own Templates Only - show Global Access = false, InstitutionAccess = false
          const results = _.filter(data, function (elem) { return elem.GlobalAccess === false && elem.InstitutionalAccess === boolValueFalse });
          setSearchResults(results || data);
        }
      } else {
        const results = _.filter(data, function (elem) { return elem.InstitutionalAccess === boolValueFalse });
        setSearchResults(results || data);
      }
    } else {
      setSearchResults(data);
    }
  };

  const resetFilter = (value) => {
    const data = templatesData?.SystemTemplates;
    if (value) {
      setSearchResults(data);
      setSearchTerm("");
      filterOptions("reset", []);
      setIsReset(value);
    }
  };

  //pagination
  const handleItemsPerPage = (dataPerPage) => {
    //Neu filter thi khong chay
    if(!isEqual(searchResults, dataPerPage) && !isFilter) {
      setSearchResults(dataPerPage);
    }
  }

  return (
    <React.Fragment>
      {!templatesStore.isLoading &&
        <UncontrolledAlert
          className="m-alert--default border-0 text-left"
          color="secondary"
        >
          Templates allow you to quickly create new cases from existing
          ones (the sources cases). The new case created from a template
          will have all elements (history narrative, vital signs, exam
          findings, labs, medications etc.) copied from the source case.
        </UncontrolledAlert>
      }
      <Filter
        value={searchTerm}
        templatesData={templatesData}
        handleChange={handleChange}
        isReset={isReset}
        type={type}
      />
      <div className="modulesTab">
        <div className="modulesTab__left">
          <CollectionMain
            {...props}
            hanldeTemplateCollectionById={hanldeTemplateCollectionById}
            hanldeAllTemplate={hanldeAllTemplate}
            itemCollection={itemCollection}
          />
        </div>
        <div className="modulesTab__right">
          {itemCollection?.TemplateCollectionId !== 0 &&
            <ButtonTemplateToCollection
              {...props}
              itemCollection={itemCollection}
              searchResults={searchResults}
              handleReloadTemplate={() => setCollection({ TemplateCollectionId: 0 })} //reload data - active all 
            />
          }
          <PaginatedItems
            itemsPerPage={5}
            items={isFilter ? searchResults : SystemTemplates}
            handleItemsPerPage={handleItemsPerPage}
          >
            <TemplateListRight
              {...props}
              searchResults={searchResults}
              hanldeSwitch={hanldeSwitch}
              toggle={toggle}
              itemCollection={itemCollection}
              type={type}
            />
          </PaginatedItems>
        </div>
      </div>
      {(actions === 'addTemplate' || actions === 'editTemplate') &&
        <ModalUpdateTemplate
          toggle={toggle}
          isOpen={modal[actions]}
          templatesOptions={templatesData}
          actions={actions}
          data={itemEdit}
          resetFilter={resetFilter}
        />
      }
      {actions === 'removeTemplate' &&
        <RemoveTemplateFromCollection
          toggle={toggle}
          isOpen={modal[actions]}
          actions={actions}
          data={itemEdit}
          resetFilter={resetFilter}
          handleRemove={hanldeRemoveTemplatefromCollection}
        />
      }
    </React.Fragment>
  );
}

export default TemplateMain;
