import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap';

const ScoringFeedback = (props) => {
    // Destructure props
    const { values, errors, touched, handleChange, handleBlur, handleChangeDomains, caseDetails, typeModal } = props;

    // State for managing domains
    const [domains, setDomains] = useState([]);

    // Initialize state with domains from props or values based on typeModal
    useEffect(() => {
        if (typeModal === 'Update' && values?.Domains) {
            setDomains(values.Domains);
        } else {
            setDomains(caseDetails.Domains);
        }
    }, [caseDetails, typeModal, values?.Domains]);
    
    return (
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label className="labeForm" for="Score">Score <span className="text-danger">*</span></Label>
                    <Input
                        type="number"
                        name="Score"
                        id="Score"
                        value={values.Score}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.Score && touched.Score}
                        autoComplete='off'
                    />
                    <FormFeedback>{errors.Score}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label className="labeForm" for="feedback">Feedback <span className="text-danger">*</span></Label>
                    <Input
                        type="textarea"
                        name="Feedbacks"
                        id="Feedbacks"
                        value={values.Feedbacks}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.Feedbacks && touched.Feedbacks}
                        rows="5"
                        autoComplete='off'
                    />
                    <FormFeedback>{errors.Feedbacks}</FormFeedback>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label className="labeForm">Domains:</Label>
                    <p><i>Which assessment domain(s) does this chat topic belong?</i></p>
                    <div className='py-3'>
                        {domains.map((domain, index) => (
                            <Label className='d-flex align-items-center mb-2 label-checkbox-custom' check key={domain.Id}>
                                <Input
                                    type="checkbox"
                                    name={`domains_${index}`}
                                    checked={domain.Selected || false}
                                    onChange={(e) => handleChangeDomains(e, index)}
                                    className='custom-checkbox gray-checkbox'
                                />
                                {' '}
                                <span className='ml-2'>{domain.Name}</span>
                            </Label>
                        ))}
                    </div>
                    <p className='text-note-organge'>* The points learners earn from asking this chat question will be added to all of the designated domains above</p>
                </FormGroup>
            </Col>
        </Row>
    );
}

export default ScoringFeedback;