import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

//boostrap
import { Container, Row } from "reactstrap";

//components
import { FormRegister } from "@components/kachiHealth";

function KachiHealthRegister() {
    const location = useLocation();
    const pathName = location.pathname;

    //Check Style for Login Page
    useEffect(() => {
        //set background
        if (pathName.includes("/kachi-health/register")) {
            document.body.classList.add("-bg-kachi-health");
        } else {
            document.body.classList.remove("-bg-kachi-health");
        }
    }, [pathName]);

    return (
        <div className="wrapper -registerKachiHealthPage">
            <div className="d-flex vh-100 align-items-center p-3">
                <Container>
                    <Row className="align-items-center justify-content-end">
                        <FormRegister />
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default KachiHealthRegister;
