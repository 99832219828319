import React from "react";

//components
import { OTPForm } from "@components/auth/ResetPassword";
import { AuthLogoHeader } from "@components/auth";

function OTPPage(props) {

  return (
    <div className="wrapper -loginPageV2">
      <AuthLogoHeader {...props} />
      <div className="login_panel">
        <OTPForm />
      </div>
    </div>
  );
}

export default OTPPage;