import styled from 'styled-components';

const FileNameLink = styled.a`
  display: flex;
  align-items: center;
  color: #101828;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-decoration: none;

  &:hover {
    color: #1570EF;
    text-decoration: underline;
  }
`;

const FileNameContainer = ({row}) => {
  const title = row?.title;
  const url = row?.source_url;
  return (
    <FileNameLink href={url} target="_blank" rel="noopener noreferrer">
      <span style={{ wordBreak: 'break-all' }}>
        {title}
      </span>
    </FileNameLink>
  );
};

export default FileNameContainer;