import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

//lodash
import _ from "lodash";

//utils
import { LoaderText, LoaderPage } from "@utils";

//reactstrap
import { Row, Col } from "reactstrap";

//toast
import { toast } from 'react-toastify';

//Components content right
import {
  VitalSignsMain,
  IOMain,
  HistoryMain,
  ChatMain,
  PhysicalExamMain,
  OrdersMain,
  ActionsMain,
  AdvanceMain
} from "./TimeLineItems";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//call Action
import {
  atcUpdateItemTimePoint,
  atcLoadStudies,
  atcLoadLabs,
  atcLoadCheckpoints,
  atcLoadMedications,
  atcLoadActionsTarget,
  atcLoadPnOrders,
  atcLoadAllQuestion,
  atcLoadChat,
} from "@actions";

//redux
import { useDispatch } from "react-redux";

//components
import { TimeLineVertical } from "../storyboard/Home";
import { DocumentationMain } from '../storyboard/Documentation';

//pnOrder
import { PnOrderTabs } from '../storyboard/pnorder';

//ProFileImage
import ProFileImage from "./ProFileImage";

//Vital Signs
import { ModalAddVitalSign } from "../storyboard/Modal";

//tabs common
import { listTabs } from "@utils";

//lodash
import { debounce } from 'lodash';

function TimeLine(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { timePointId, timepointData, isLoadingTimePoint, timePointCurrent } = props && props;
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabCt, setActiveTabCt] = useState("1");
  const [valueNarrativeId, setValueNarrativeId] = useState("");
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState('');

  //data Instructions
  const [valueEditor, setValueEditor] = useState({
    narrative: "", //ouside Data
    history: "",
    chat: "",
    vital: "",
    io: "",
    exam: "",
    orders: "",
    pn: "",
    actions: "",
    advance: ""
  });


  const toggle = () => setModal(!modal);

  //Load All Questions
  useEffect(() => {
    //All Question
    const fetchAllQuestions = async () => {
      const params = {
        Action: "Load",
        Target: "AllQuestions",
      };
      dispatch(atcLoadAllQuestion(id, params));
    };
    fetchAllQuestions();
  }, [dispatch, id]);

  //Load narrative
  useEffect(() => {
    const responseDataTimePoint = timepointData?.data;
    const narrativeData = responseDataTimePoint?.DefaultNarrative;
    const defaultNarrativeId = responseDataTimePoint?.DefaultNarrative?.Id;
    const dataEditor = narrativeData?.Content;

    //setState
    setValueEditor((prevState) => ({ ...prevState, "narrative": dataEditor }));
    setValueNarrativeId(defaultNarrativeId);
  }, [timepointData])

  let responseData = timepointData?.data;
  const timeLineList = responseData?.TaskElements;
  const promptTemplateList = responseData?.AvailablePromptTemplates?.map(prompt => {
    return {
        ...prompt,
        value: prompt.Name, 
        label: prompt.Name, 
    }
  }) || []
  const questionsData = responseData?.Questions;

  //Update narrative
  const handleApiContentNarrative = (currentContent, currentTimepointId, valueNarrativeId) => {
    let params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: currentTimepointId,
      Narrative: {
        Id: valueNarrativeId,
        Content: currentContent,
      }
    };

    //Call Api
    dispatch({ type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_REQUEST, isLoading: true });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_SUCCESS,
          payload: params?.Narrative || {}
        });
        toast.success('The narrative has been updated', {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        dispatch({
          type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_FAILURE,
          error: 'error'
        });
      }
    });
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce((currentContent, currentTimepointId, valueNarrativeId) => handleApiContentNarrative(currentContent, currentTimepointId, valueNarrativeId), 500), []
  );

  const handleUpdateEditor = (currentContent, event) => {
    const targetElement = event?.target;
    const targetElementByClass = targetElement.closest(".richText__content");
    const currentTimepointId = targetElementByClass.getAttribute('data-timepoint-id');
    const currentTask = targetElementByClass.getAttribute('data-task');

    let currentParams = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: currentTimepointId
    };

    if (currentTask === "narrative") {
      debounced(currentContent, currentTimepointId, valueNarrativeId);

      //setState
      setValueEditor((prevState) => ({ ...prevState, "narrative": currentContent }));
    } else {
      currentParams[`Flow`] = {
        Mode: "edit",
        Task: currentTask,
        Instructions: currentContent
      }
      dispatch(atcUpdateItemTimePoint(id, currentParams));
    }

    //setState
    setValueEditor((prevState) => ({ ...prevState, [currentTask]: currentContent }));
  }

  // load list by view class
  const loadApiByViewClass = (targetTask) => {
    const params = {
      Action: "Load",
      Target: targetTask,
      TimePointId: timePointId,
    };
    switch (targetTask) {
      case "Checkpoints":
        params[`ObjectType`] = "Actions";
        dispatch(atcLoadCheckpoints(id, params));
        break;
      case "Medications":
        dispatch(atcLoadMedications(id, params));
        break;
      case "PN":
        dispatch(atcLoadPnOrders(id, params));
        break;
      case "Actions":
        dispatch(atcLoadActionsTarget(id, params));
        break;
      case "Studies":
        dispatch(atcLoadStudies(id, params));
        break;
      case "Labs":
        dispatch(atcLoadLabs(id, params));
        break;
      case "Chat":
        dispatch(atcLoadChat(id, params));
        break;
      default:
        console.log('stop');
        //process default outcome 
        break;
    }
  }

  const toggleTabCt = (tab) => {
    if (activeTabCt !== tab) setActiveTabCt(tab);
  };

  const handleTab = (id, name) => {
    setActiveTab(id);
    setActiveTabCt("1");

    //Orders tabs (timline)
    // Truyen dung params de call api
    switch (name) {
      case "orders":
        // Medications, Labs, Studies (Target)
        loadApiByViewClass("Medications");
        loadApiByViewClass("Labs");
        loadApiByViewClass("Studies");
        break;
      case "pn":
        // Medications, PN (Target)
        loadApiByViewClass("Medications");
        loadApiByViewClass("PN");
        break;
      case "actions":
        // Actions (Target)
        loadApiByViewClass("Actions");
        break;
      case "chat":
        // Chat (Target)
        loadApiByViewClass("Chat");
        break;
      case "advance":
        // Checkpoints (Target)
        loadApiByViewClass("Checkpoints");
        break;
      default:
        console.log('stop');
        //process default outcome 
        break;
    }
  };

  // Click Active/Inactive Dot Blue With API
  const handleActive = (value) => {
    const Status = value?.Status
    const task = value?.ViewClass

    //default not handle
    if (task !== "history" && task !== "vital" && task !== "advance") {
      const params = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: timePointId,
        Flow: {
          Mode: Status === "Active" ? "deactivate" : "activate",
          Task: task,
        },
      };
      setTaskLoading(task);
      updateItemTimePointActive(id, params, value);
    }
  };

  //Update Active/Inactive Timeline
  const updateItemTimePointActive = (id, params, value) => {
    setLoading(true);
    const isLoading = true;
    toast.success(`Switching task "${value?.Label}" ${params?.Flow?.Mode === 'activate' ? "ON" : "OFF"} for this timepoint`, {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: "SwitchingTask"
    });

    //Call Api
    dispatch({
      type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_SUCCESS,
          payload: data
        });
        toast.dismiss("SwitchingTask");
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UPDATE_STATUS_TIMEPOINT_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  //set Instructions By ViewClass
  useEffect(() => {
    timeLineList?.map((item) => (
      setValueEditor((prevState) => ({ ...prevState, [item?.ViewClass]: item?.Instructions }))
    ));
  }, [timeLineList]);

  const filterQuestionForTask = (nameTab) => {
    //filter Question AskedAtTask
    const filterData = _.filter(
      questionsData && questionsData,
      function (value) {
        return value.AskedAtTask === nameTab;
      }
    );
    return filterData;
  };

  //Kiem tra neu nguoi dung click timepoint thi call api load list tuong ung vs tab dang active
  useEffect(() => {
    // Profile Image la tab mac dinh nen khong tra ve tu timeLineList
    // gia tri mac dinh tab active = 1 && Profile Image nen activeTab - 2
    if (timePointCurrent) {
      const timelineItemActiveFilter = timeLineList && timeLineList[activeTab - 2];
      const ViewClass = timelineItemActiveFilter?.ViewClass;
      //Orders tabs (timline)
      // Truyen dung params de call api
      switch (ViewClass) {
        case "orders":
          // Medications, Labs, Studies (Target)
          loadApiByViewClass("Medications");
          loadApiByViewClass("Labs");
          loadApiByViewClass("Studies");
          break;
        case "pn":
          // Medications, PN (Target)
          loadApiByViewClass("Medications");
          loadApiByViewClass("PN");
          break;
        case "actions":
          // Actions (Target)
          loadApiByViewClass("Actions");
          break;
        case "chat":
          // Chat (Target)
          loadApiByViewClass("Chat");
          break;
        case "advance":
          // Checkpoints (Target)
          loadApiByViewClass("Checkpoints");
          break;
        default:
          console.log('stop');
          //process default outcome 
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePointCurrent])

  const renderTabs =
    timeLineList?.map((value, index) => {
      const isActive = activeTab === index + 2 && value.ViewClass;
      return (
        <div key={index} data-key={index + 1}>
          {isActive === "history" && (
            <HistoryMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditor={valueEditor}
              filterQuestionForTask={filterQuestionForTask}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "chat" && (
            <ChatMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditor={valueEditor}
              filterQuestionForTask={filterQuestionForTask}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "vital" && (
            <VitalSignsMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              toggle={toggle}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditorVital={valueEditor?.vital || ''}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "io" && (
            <IOMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              toggle={toggle}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditorIO={valueEditor?.io || ''}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "exam" && (
            <PhysicalExamMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditor={valueEditor}
              filterQuestionForTask={filterQuestionForTask}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "orders" && (
            <OrdersMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditor={valueEditor}
              filterQuestionForTask={filterQuestionForTask}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "pn" && (
            <PnOrderTabs
              {...props}
              listTabs={listTabs || []}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              valueEditor={valueEditor}
              value={value}
              timePointId={timePointId}
              filterQuestionForTask={filterQuestionForTask}
              toggleTabCt={toggleTabCt}
            />
          )}
          {isActive === "documentation" && (
            <DocumentationMain
              {...props}
              activeTabCt={activeTabCt}
              promptTemplateList={promptTemplateList}
              listTabs={listTabs || []}
              toggleTabCt={toggleTabCt}
              value={value}
              valueEditor={valueEditor}
              handleUpdateEditor={handleUpdateEditor}
              filterQuestionForTask={filterQuestionForTask}
            />
          )}
          {isActive === "actions" && (
            <ActionsMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              toggleTabCt={toggleTabCt}
              listTabs={listTabs || []}
              handleUpdateEditor={handleUpdateEditor}
              valueEditor={valueEditor}
              timePointId={timePointId}
              filterQuestionForTask={filterQuestionForTask}
            />
          )}
          {isActive === "advance" && (
            <AdvanceMain
              {...props}
              value={value}
              promptTemplateList={promptTemplateList}
              activeTabCt={activeTabCt}
              listTabs={listTabs || []}
              valueEditor={valueEditor}
              filterQuestionForTask={filterQuestionForTask}
              handleUpdateEditor={handleUpdateEditor}
              toggleTabCt={toggleTabCt}
            />
          )}
        </div>
      );
    });
  return (
    <React.Fragment>
      {isLoadingTimePoint && <LoaderText />}
      <Row>
        <TimeLineVertical
          activeTab={activeTab}
          timeLineList={timeLineList}
          handleActive={handleActive}
          handleTab={handleTab}
          isLoading={isLoading}
          taskLoading={taskLoading}
        />
        <Col lg={9}>
          <div className="panelActive position-relative">
            {/* default */}
            {activeTab === 1 && <ProFileImage {...props} />}

            {/* Loading full page by timeLineList */}
            {(timeLineList && renderTabs) || <LoaderPage />}
          </div>
        </Col>
      </Row>
      <ModalAddVitalSign
        {...props}
        isOpen={modal}
        toggle={toggle}
      />
    </React.Fragment>
  );
}

export default TimeLine;