import React from "react";

//menu
import navigation from "@utils/navigation";

//components
import { NavigationComponent } from "@components/layouts";
import { MainData } from "@components/admin/data";

function DataPage(props) {
  const mainMenu = navigation?.mainAdmin;
  return (
    <div className="wrapper -dataPage">
      <NavigationComponent data={mainMenu} />
      <MainData {...props} />
    </div>
  );
}

export default DataPage;