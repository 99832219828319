import React, { useState, useEffect } from 'react';

//textarea
import TextareaAutosize from "react-textarea-autosize";

//boostrap
import {
    FormGroup,
    Input,
    Label,
    Row,
    Col
} from "reactstrap";

function ResponseTab(props) {
    const { data, handleSendParamsResponse } = props && props;

    //state
    const [stateData, setData] = useState({
        ChatResponse: data?.ChatResponse || '',
        ChatQuestion: data?.ChatQuestion || '',
        IsNegative: data?.IsNegative
    });

    const [sendData, setSendData] = useState(true);

    //setState onload
    useEffect(() => {
        setData((prevState) => ({
            ...prevState,
            ChatResponse: data?.ChatResponse || '',
            ChatQuestion: data?.ChatQuestion || '',
            IsNegative: data?.IsNegative
        }));
    }, [data]);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setData((prevState) => ({ ...prevState, [name]: name === "IsNegative" ? checked : value }));
        setSendData(true);
    }

    //send Data to parent
    useEffect(() => {
        if (sendData) {
            handleSendParamsResponse(stateData);
            setSendData(false);
        }
    }, [data, handleSendParamsResponse, stateData, sendData]);

    return (
        <Row>
            <Col md={12} className="mb-3">
                <FormGroup className='d-flex align-items-center' check>
                    <Input
                        id="IsNegative"
                        name="IsNegative"
                        type="checkbox"
                        className='mt-0'
                        defaultChecked={stateData?.IsNegative}
                        onChange={handleChange}
                    />
                    <Label
                        check
                        for="Negative"
                    >
                        Negative
                    </Label>
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label for="answer">
                        Custom Response: <span className='font-italic'>(optional)</span>
                    </Label>
                    <TextareaAutosize
                        name="ChatResponse"
                        placeholder=""
                        className="w-100 form-control"
                        maxRows="10"
                        minRows={5}
                        onChange={handleChange}
                        value={stateData?.ChatResponse}
                    />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label for="answer">
                        Sample Question: <span className='font-italic'>(optional)</span>
                    </Label>
                    <TextareaAutosize
                        name="ChatQuestion"
                        placeholder=""
                        className="w-100 form-control"
                        maxRows="10"
                        minRows={5}
                        onChange={handleChange}
                        value={stateData?.ChatQuestion}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
};

export default ResponseTab;