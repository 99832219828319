import React, { useRef } from 'react';
import styled from 'styled-components';

const UploadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const UploadText = styled.div`
  flex: 1;
`;

const UploadTextBold = styled.div`
  color: #081B2A;
  font-size: 16px;
  font-weight: 600;
`;

const UploadTextItalic = styled.div`
  color: #081B2A;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  margin-top: 12px;
`;

const UploadButtonWrapper = styled.div`
  flex-shrink: 0;
`;

const UploadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: none;
  }

  span {
    color: #344054;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const UploadImageButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  cursor: pointer;
  max-width: 152px;

  svg {
    width: 20px;
    height: 20px;
    fill: none;
  }

  span {
    color: #1D2939;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

const FileInput = styled.input`
  display: none;
`;
const ButtonUploadFile = ({ isPage, handleImageChange, isManuallyUpload }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = (event) => {
    event.preventDefault(); // Ngăn chặn hành động mặc định
    fileInputRef.current.click(); // Click vào file input
  };

  const contentDefault = {
    title: `Or choose an image from your device to replace the AI-generated image`,
    desc: `Once uploaded, regeneration will be disabled, the AI image will be replaced and cannot be restored. Maximum file size: 10MB`,
    nameButton: `Upload Your Own Image`
  }
  return (
    <React.Fragment>
      {isPage === "edit" || isManuallyUpload ? (
        <div className='d-flex justify-content-center flex-column align-items-center'>
          <FileInput ref={fileInputRef} type="file" name="image" onChange={handleImageChange} />
          <UploadImageButton onClick={handleButtonClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M3.55991 17.2733L9.05703 11.7761C9.38704 11.4461 9.55205 11.2811 9.74232 11.2193C9.90969 11.1649 10.09 11.1649 10.2574 11.2193C10.4476 11.2811 10.6126 11.4461 10.9426 11.7761L16.4031 17.2366M11.6665 12.5L14.057 10.1095C14.387 9.77946 14.552 9.61445 14.7423 9.55263C14.9097 9.49825 15.09 9.49825 15.2574 9.55263C15.4476 9.61445 15.6126 9.77946 15.9426 10.1095L18.3332 12.5M8.33317 7.5C8.33317 8.42047 7.58698 9.16667 6.6665 9.16667C5.74603 9.16667 4.99984 8.42047 4.99984 7.5C4.99984 6.57953 5.74603 5.83333 6.6665 5.83333C7.58698 5.83333 8.33317 6.57953 8.33317 7.5ZM5.6665 17.5H14.3332C15.7333 17.5 16.4334 17.5 16.9681 17.2275C17.4386 16.9878 17.821 16.6054 18.0607 16.135C18.3332 15.6002 18.3332 14.9001 18.3332 13.5V6.5C18.3332 5.09987 18.3332 4.3998 18.0607 3.86502C17.821 3.39462 17.4386 3.01217 16.9681 2.77248C16.4334 2.5 15.7333 2.5 14.3332 2.5H5.6665C4.26637 2.5 3.56631 2.5 3.03153 2.77248C2.56112 3.01217 2.17867 3.39462 1.93899 3.86502C1.6665 4.3998 1.6665 5.09987 1.6665 6.5V13.5C1.6665 14.9001 1.6665 15.6002 1.93899 16.135C2.17867 16.6054 2.56112 16.9878 3.03153 17.2275C3.56631 17.5 4.26637 17.5 5.6665 17.5Z" stroke="#1D2939" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span>Change Image</span>
          </UploadImageButton>
          <UploadTextItalic>Use this button to upload a new image to replace the current one. Maximum file size: 10MB</UploadTextItalic>
        </div>
      ) : (
        <UploadContainer>
          <UploadText>
            <UploadTextBold>
              {isPage === 'add-by-ai' ? contentDefault?.title : `Click the 'Upload Image' button to upload an image for this food item`}
            </UploadTextBold>
            <UploadTextItalic>
              {isPage === 'add-by-ai' ? contentDefault?.desc : `JPG, JPEG, PNG. Maximum file size: 10MB`}
            </UploadTextItalic>
          </UploadText>
          <UploadButtonWrapper>
            <UploadButton onClick={handleButtonClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>{isPage === 'add-by-ai' ? contentDefault?.nameButton : `Upload Image`}</span>
            </UploadButton>
            <FileInput ref={fileInputRef} type="file" name="image" onChange={handleImageChange} />
          </UploadButtonWrapper>
        </UploadContainer>
      )}
    </React.Fragment>
  );
};

export default ButtonUploadFile;