import React, { useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap'; // Assuming Bootstrap/Reactstrap is being used
import { TabsCommon } from "@ui-partents/Tabs";

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const listTabs = [
    {
      id: 1,
      title: "tab 1",
    },
    {
      id: 2,
      title: "tab 2",
    },
    {
      id: 3,
      title: "tab 3",
    },
  ]

  return (
      <Card className='my-2'>
        <CardHeader>Tabs</CardHeader>
        <CardBody>
          <TabsCommon listTabs={listTabs} activeTab={activeTab} handleTabChange={handleTabChange} />
        </CardBody>
      </Card>
    );
};

export default TabsComponent;