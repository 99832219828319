import React, { useState, useEffect } from "react";

import { Table } from "reactstrap";

//lodash
import _ from "lodash";

//utils
import { LoaderText, messageData } from "@utils";

//redux
import { useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";

//elemenst
import { ButtonSwitch } from '@components/common/elements';

//components from storyboard
import { ModalEditStudies } from '@components/storyboard/Modal';

//Studies
import { TableHeader, TableCellStudies } from '../elements/StudiesElements';

function Studies(props) {

  let typeAvailability = "item";

  //props
  const { data, storeStudies } = props && props;

  //state
  const [stateData, setData] = useState((data && data) || []);
  const [show, setShow] = useState({
    studiesModal: false
  });
  const [itemEdit, setItem] = useState({});
  const [isSwitch, setSwitch] = useState("Value");

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const handleShow = (name, row, TimepointData) => {
    setShow((prevState) => ({ ...prevState, [name]: true }));

    //set State Data
    const itemData = { row, ...TimepointData }
    setItem(itemData);
  }

  const TableData = ({ data }) => {
    //filter Time !== 0
    const listTimepointLast = _.filter(data, function (o) {
      return o?.TimePoint?.Time !== 0;
    });

    //filter Time === 0
    const TimePointFirst = _.filter(data, function (o) {
      return o?.TimePoint?.Time === 0;
    });
    const dataDefault = TimePointFirst?.[0]?.Studies;

    //Header
    const listHeader = [
      { Name: 'Study', width: "300px" },
      { Name: 'Initial Finding', width: "300px" },
      ...listTimepointLast
    ]

    return (
      <React.Fragment>
        <div className="mb-4">
          <ButtonSwitch
            {...props}
            handleSwitchsActive={(value) => setSwitch(value)}
            value={isSwitch}
          />
        </div>
        <div className="table-layout-fixed">
          <Table responsive>
            <TableHeader
              data={listHeader}
              isSwitch={isSwitch}
            />
            <tbody>
              {stateData && stateData.length === 0 && (
                <tr>
                  <td>
                    <DataEmty content={messageData?.default?.content} />
                  </td>
                </tr>
              )}

              {dataDefault?.map((col, index) => {
                return (
                  <tr className="table-row" key={index}>
                    {/* Before */}
                    {TimePointFirst?.map((row) => {
                      const item = row.Studies[index];
                      const TimePointData = TimePointFirst && TimePointFirst[0]?.TimePoint;
                      return (
                        <TableCellStudies
                          {...props}
                          value={item?.Name}
                          iconMedia={false}
                          item={item}
                          isSwitch={isSwitch}
                          TimePointData={TimePointData}
                          type="first_name"
                          typeAvailability={typeAvailability}
                        />
                      );
                    })}
                    {TimePointFirst?.map((row) => {
                      const item = row.Studies[index];
                      const TimePointData = TimePointFirst && TimePointFirst[0]?.TimePoint;
                      return (
                        <TableCellStudies
                          {...props}
                          handleShow={() => handleShow(`studiesModal`, item, row?.TimePoint)}
                          value={item?.InitialValue}
                          item={item}
                          firstTimepoint={true}
                          iconMedia={true}
                          isSwitch={isSwitch}
                          TimePointData={TimePointData}
                          type="first"
                          typeAvailability={typeAvailability}
                        />
                      )
                    })}

                    {/* Last */}
                    {listTimepointLast?.map((row, j) => {
                      const item = row.Studies[index];
                      const ChangeMode = item?.ChangeMode;
                      const TimePointData = row?.TimePoint;
                      return (
                        <TableCellStudies
                          {...props}
                          handleShow={() => handleShow(`studiesModal`, item, row?.TimePoint)}
                          value={ChangeMode ? item?.ChangeValue : '(no change)'}
                          lastTimepoint={true}
                          item={item}
                          iconMedia={true}
                          isSwitch={isSwitch}
                          TimePointData={TimePointData}
                          type="last"
                          typeAvailability={typeAvailability}
                        />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  };

  //get data store
  const dataExamStore = useSelector(
    (state) => state.StudiesByAtributeId?.data || {}
  );

  return (
    <div className="boxPortlet position-relative">
      {!storeStudies?.isLoading && (
        <TableData data={(stateData && stateData) || []} />
      )}
      {storeStudies?.isLoading && (
        <div style={{ height: '400px' }}>
          <LoaderText />
        </div>
      )}
      <ModalEditStudies
        {...props}
        data={itemEdit?.row}
        toggle={() => { setShow((prevState) => ({ ...prevState, studiesModal: false })) }}
        isOpen={show?.studiesModal}
        dataExam={dataExamStore?.ExamFinding || {}}
        actions={`edit-study`}
        timePointId={itemEdit?.TimepointId}
        timePointCurrent={itemEdit}
        typeComponent="elements"
      />
    </div>
  );
}

export default Studies;
