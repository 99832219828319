import React, { useState } from "react";

//components
import { FormLogin, FormForGotPass } from "@components/kachiHealth/auth";

//boostrap
import { Container, Row, Col } from "reactstrap";

function KachiHealthLogin() {
  const [showView, setShowView] = useState(false);

  const handleView = () => {
    setShowView(!showView);
  };

  return (
    <div className="wrapper -loginKachiHealthPage">
      <div className="d-flex vh-100 align-items-center p-3">
        <Container>
          <Row className="align-items-center justify-content-end flex-wrap">
            <Col md={`12`} lg={`6`}>
              {showView ? (
                <FormForGotPass handleView={handleView} />
              ) : (
                <FormLogin handleView={handleView} />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default KachiHealthLogin;
