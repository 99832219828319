
//list icon
import IconChat from "@images/learnerv2/icon-taks-chat.svg";
import IconExam from "@images/learnerv2/icon-taks-exam.svg";
import IconOrder from "@images/learnerv2/icon-taks-order.svg";
import IconAction from "@images/learnerv2/icon-taks-action.svg";

// Hàm lấy biểu tượng cho một đối tượng cụ thể
const getIconForObject = (task) => {
    switch (task) {
        case "chat":
            return IconChat;
        case "exam":
            return IconExam;
        case "labs":
            return IconOrder;
        case "pn":
            return IconAction;
        case "actions":
            return IconAction;
        case "documentation":
            return IconOrder;
        default:
            return IconOrder; // Biểu tượng mặc định nếu không khớp
    }
}

export default getIconForObject;