import React from 'react';

// Icon
import { Iconsax } from "@components-common";

const ButtonBackAssigment = () => {
    const handleBackClick = () => {
        window.location.href = '/learn/assignments'; // Assign URL to window.location.href
    };

    return (
        <div onClick={handleBackClick} style={{ cursor: 'pointer' }}> {/* Add onClick handler */}
            <Iconsax iconName="arrow-back" fill="#1C274C" />
        </div>
    );
}

export default ButtonBackAssigment;