import React, { useState } from "react";
import ModalEditEngage from "./ModalEditEngage";

const WrapModalEditEngage = ({ title, dataDetail, children, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () =>  {
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      {children({ onOpen: handleOpen })}
      {open && 
        <ModalEditEngage
          isOpen={open}
          toggle={handleClose}
          title={title}
          dataDetail={dataDetail}
          {...props}
        >
        </ModalEditEngage>
      }
    </>
  )
}

export default WrapModalEditEngage
