import React, { useState } from 'react';

//boostrap
import { Row, UncontrolledCollapse } from "reactstrap";

//components
import { AvatarItem } from '../AvatarUpdate';

function AvatarUpload(props) {
    const { dataResponse } = props && props;
    const PatientAvatarUrl = dataResponse?.PatientAvatarUrl;
    const PreceptorAvatarUrl = dataResponse?.PreceptorAvatarUrl;

    //state
    const [isOpen, setOpen] = useState(false);

    const toggle = () => {
        setOpen(!isOpen);
    }
    
    return (
        <div className="widgetBox -box-shadow mt-4">
            <div className="widgetBox__head" style={{ cursor: 'pointer' }} onClick={toggle} id="toggler">
                <h3 className={`wg-title --text-primary hasArrow ${isOpen ? "hasArrow-up" : "hasArrow-down"}`}>
                    <i className="fa fa-user-circle-o mr-2" aria-hidden="true"></i>
                    Change Avatar
                </h3>
                {/* {isOpen && } */}
            </div>
            <UncontrolledCollapse defaultOpen={isOpen} toggler="#toggler">
                <div className="widgetBox__body">
                    <Row>
                        <AvatarItem
                            srcImage={PreceptorAvatarUrl}
                            name={`Preceptor`}
                        />
                        <AvatarItem
                            srcImage={PatientAvatarUrl}
                            name={`Patient`}
                        />
                    </Row>
                </div>
            </UncontrolledCollapse>
        </div>
    );
};

export default AvatarUpload;