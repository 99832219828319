import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import imgLogo from "@images/ucr/logo.png";
import { Link } from "react-router-dom";

//boostrap
import { Row, Col, FormGroup, Input } from "reactstrap";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//redux
import { useDispatch } from "react-redux";

//untils
import { checkAuth, apiCaller } from "@utils";

//queryString
import queryString from "query-string";

function FormLogin(props) {
  let history = useHistory();
  //props
  const { handleView } = props && props;

  //check params
  let parsed = queryString.parse(window.location.search);

  //state
  const [stateEmail, setStateEmail] = useState("");
  const [statePassword, setStatePassword] = useState("");
  const [stateValid, setStateValid] = useState(false);
  const [stateMessage, setMessage] = useState(
    "Either your email or password is not valid. Please try again."
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setStateEmail(value);
        break;
      case "password":
        setStatePassword(value);
        break;
      default:
    }
  };

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed[`redirect`];

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
      if (dataAccount?.is_instructor === "Yes") {
        history.push(`/teach/home`);
      } else {
        history.push("/learn/home");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stateEmail === "" || statePassword === "") {
      setStateValid(true);
      return;
    }

    if (stateEmail && statePassword) {
      apiCaller(`/api/auth/login/`, "POST", {
        username: stateEmail,
        password: statePassword,
      }).then((response) => {
        const dataResponse = response?.data;
        const messageStatus = dataResponse?.status;
        if (messageStatus) {
          setMessage(messageStatus);
        }
        if (dataResponse?.status === "OK") {
          const accountData = dataResponse?.account;
          const isAdministrator = accountData?.is_administrator;
          // const isInstructor = accountData?.is_instructor;
          const isSubscription = accountData?.subscription;
          setStateValid(false);

          // set localStorage
          localStorage.setItem(
            "Account",
            accountData ? JSON.stringify(accountData) : ""
          );

          //add token
          dispatch(actionLogin(dataResponse));

          //Call API Theme
          dispatch(actFetchThemeRequest());

          // Check Redirect Page
          if (isSubscription !== null) {
            nextPage(dataResponse);
          } else if (isSubscription === null && isAdministrator === "Yes") {
            history.push(`/plan`);
          } else {
            history.push(`/plan/error`);
          }
        } else {
          setStateValid(true);
        }
      });
    }
  };

  return (
    <div className="m-portlet m-portlet--mobile">
      <form method="post" onSubmit={handleSubmit}>
        <div className="m-portlet__body">
          <div className="text-center mb-5">
            <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
          </div>
          <Row className="mt-4">
            <Col md={10} className="mx-auto">
              <FormGroup>
                <Input
                  type="email"
                  onChange={handleChange}
                  bsSize="lg"
                  value={stateEmail}
                  placeholder="Email"
                  name="email"
                />
              </FormGroup>
              <FormGroup className={`${stateValid ? "" : "mb-0"}`}>
                <Input
                  type="password"
                  onChange={handleChange}
                  bsSize="lg"
                  value={statePassword}
                  placeholder="Password"
                  autoComplete="on"
                  name="password"
                />
              </FormGroup>
            </Col>
          </Row>
          {stateValid && (
            <Row>
              <Col
                md={10}
                className="mx-auto m--font-danger text-center"
                id="message_invalid"
              >
                {stateMessage}
              </Col>
            </Row>
          )}
        </div>
        <div className="m-portlet__foot">
          <Row>
            <button
              type="submit"
              className="btn m-btn--pill btn-lg px-4 m-4 w-100"
            >
              Log In
            </button>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col md={12} className="text-center">
              <span
                style={{ cursor: "pointer" }}
                className="m--font-brand"
                onClick={handleView}
              >
                Forgot your password?
              </span>
            </Col>
            <Col md={12} className="text-center">
              <Link className="m--font-brand" to={`/ucr/register`}>
                Register for a new FREE account
              </Link>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default FormLogin;