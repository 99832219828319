import React, { useState } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//boostrap
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//components
import { SortContent } from '../SortList';

//call Action
import { updateExamFindingOrder, updateVitalSignOrder } from "@actions";

//lodash
import _ from "lodash";

function ModalSortList(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { toggle, isOpen, dataSort, typeComponent } = props && props;
    const [dataAttributeIds, setDataAttributeIds] = useState([]);

    const handleSendListSort = (data) => {
        let idFilter = '';
        if (typeComponent === "ExamFindings") {
            idFilter = "PointId";
        } else {
            idFilter = "Id";
        }
        var result = _.map(data, idFilter);
        const newArray = result.map((item) => {
            return item;
        });
        setDataAttributeIds(newArray);
    }

    const handleSubmit = () => {
        let params = {
            "Action": "Update",
            "Target": "AttributesOrder",
            "AttributeIds": dataAttributeIds
        }
        if (typeComponent === "ExamFindings") {
            params[`AttributeType`] = "ExamFinding";
            dispatch(updateExamFindingOrder(id, params));
        }

        if (typeComponent === "VitalSigns") {
            params[`AttributeType`] = "VitalSign";
            dispatch(updateVitalSignOrder(id, params));
        }
        toggle();
    }

    return (
        <Modal
            className="modal-dialog"
            isOpen={isOpen}
            backdrop={`static`}
            style={{ maxWidth: "500px" }}
        >
            <ModalHeader toggle={toggle}>
                Sort - Exam Findings
            </ModalHeader>
            <ModalBody>
                <SortContent {...props} handleSendListSort={handleSendListSort} data={dataSort || []} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalSortList;