import { actionConstants } from "@constants";
import {apiCaller} from "@utils";

//Load Actions
export const atcLoadActionsTarget = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: actionConstants.LOAD_ACTIONS_TO_ACCORDION_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: actionConstants.LOAD_ACTIONS_TO_ACCORDION_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: actionConstants.LOAD_ACTIONS_TO_ACCORDION_FAILURE,
      error: error,
    };
  }
};

//Case - Update Action Availability
export const updateAvailabilityForAction = (id, params, categoryId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data, categoryId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: actionConstants.UPDATE_AVAILABILITY_FOR_ACTIONS_REQUEST,
      isLoading,
    };
  }
  function success(data, categoryId) {
    return {
      type: actionConstants.UPDATE_AVAILABILITY_FOR_ACTIONS_SUCCESS,
      payload: data,
      categoryId: categoryId,
    };
  }
  function failure(error) {
    return {
      type: actionConstants.UPDATE_AVAILABILITY_FOR_ACTIONS_FAILURE,
      error: error,
    };
  }
};

//Case - Update Intervention Available For Action
export const updateAvailableForAction = (
  id,
  params,
  interventionId,
  categoryId
) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data, interventionId, categoryId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: actionConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_REQUEST,
      isLoading,
    };
  }
  function success(data, interventionId, categoryId) {
    return {
      type: actionConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_SUCCESS,
      payload: data,
      categoryId: categoryId,
      interventionId: interventionId,
    };
  }
  function failure(error) {
    return {
      type: actionConstants.UPDATE_INTERVENTION_AVAILABLE_FOR_ACTIONS_FAILURE,
      error: error,
    };
  }
};

//Case - Delete Action Category
export const atcDeleteActionCategory = (id, params, categoryId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data, categoryId));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: actionConstants.DELETE_ACTION_CATEGORY_REQUEST,
      isLoading,
    };
  }
  function success(data, categoryId) {
    return {
      type: actionConstants.DELETE_ACTION_CATEGORY_SUCCESS,
      payload: data,
      categoryId: categoryId,
    };
  }
  function failure(error) {
    return {
      type: actionConstants.DELETE_ACTION_CATEGORY_FAILURE,
      error: error,
    };
  }
};

//Case - Update Attribute Score (Action)
export const atcUpdateScoreAction = (id, params, AttributeId, categoryId) => {
  const isLoading = true;
  return dispatch => {
      dispatch(request(isLoading));
      return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
          const data = res && res.data;
          if (data) {
              dispatch(success(data, AttributeId, categoryId));
          } else {
              dispatch(failure("error"));
          }
      });
  };
  function request(isLoading) { 
      return { type: actionConstants.UPDATE_ATTRIBUTE_SCORE_ACTION_REQUEST, isLoading } 
  }
  function success(data, attributeId) {
      return {
          type: actionConstants.UPDATE_ATTRIBUTE_SCORE_ACTION_SUCCESS,
          payload: data,
          attributeId: attributeId,
          categoryId: categoryId
      }
  }
  function failure(error) { return { type: actionConstants.UPDATE_ATTRIBUTE_SCORE_ACTION_FAILURE, error: error } }
}