import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import { notifyToast } from "@ui-partents/Toast";

const ToastComponent = () => {
  const [toastVisible, setToastVisible] = useState({
    normal: false,
    error: false,
    warning: false,
    success: false,
    noti: false
  });

  const showToast = (type) => {
    // Check if the toast is already visible
    if (toastVisible[type]) return;

    // Set the corresponding toast visible
    setToastVisible((prevState) => ({
      ...prevState,
      [type]: true
    }));

    // Define options for each type of toast
    const options = {
      normal: { content: "Normal notification" },
      error: {
        status: "error",
        title: "There was a problem with that action",
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
        place: "bottom-center",
        timeClose: 5000
      },
      warning: { status: "warning", content: "Warning message!" },
      success: { status: "success", content: "Success!" },
      noti: { status: "notification", content: "General notification" }
    };

    // Display toast
    notifyToast(options[type]);
    
    // Log visibility state
    console.log("Toast triggered:", type, toastVisible[type]);

    // Reset toast visibility after the specified time
    setTimeout(() => {
      setToastVisible((prevState) => ({
        ...prevState,
        [type]: false
      }));
    }, options[type].timeClose || 3000);
  };

  return (
    <Card className='my-2'>
      <CardHeader>List Toast</CardHeader>
      <CardBody>
        <Button onClick={() => showToast('normal')} disabled={toastVisible.normal}>
          Normal
        </Button>
        <Button onClick={() => showToast('error')} disabled={toastVisible.error}>
          Error
        </Button>
        <Button onClick={() => showToast('warning')} disabled={toastVisible.warning}>
          Warning
        </Button>
        <Button onClick={() => showToast('success')} disabled={toastVisible.success}>
          Success
        </Button>
        <Button onClick={() => showToast('noti')} disabled={toastVisible.noti}>
          Notification
        </Button>
      </CardBody>
    </Card>
  );
};

export default ToastComponent;