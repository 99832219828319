import React from 'react';

function ButtonAdd(props) {
    const { buttonText, type, handelSubmit, item } = props && props;
    return (
        <div
            className="btn --btn-primary mr-2"
            onClick={() => handelSubmit(type, item)}
            //type, item (parentId)
        >
            <i className="fa fa-plus mr-2" aria-hidden="true"></i>
            {buttonText}
        </div>
    );
};

export default ButtonAdd;