import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, FormGroup, Label, Input, Tooltip } from "reactstrap";

//call Action
import {
  actFetchPNOrderRequest,
  actUpdatePNOrderRequest,
  actCheckMyOrders,
  actFetchCopyForwardRequest,
} from "@actions";

//components
import {
  CopyForwardModal,
  ItemOrderDropdown,
  HeaderPnOrder,
  CalculatedOsmolarity,
  MessageCalorie
} from "./PN";

//lodash
import _ from "lodash";

//redux
import { useDispatch, useSelector } from "react-redux";

import LoaderText from "@utils/LoaderText";

function PNOrderSection(props) {
  //props
  const { dataCase, handleOverideEffectMenu } = props && props;
  const ClinicalStatus = dataCase?.ClinicalStatus;
  const IOBalance = ClinicalStatus?.IOBalance;
  const Intake = IOBalance?.Intake;
  const filterIntake = _.filter(Intake, ['Name', "Enteral Tube"]);
  let valueIntake = filterIntake ? parseFloat(filterIntake && filterIntake[0]?.CurrentValue) : 0;

  // ----------------------------------------------------------------------
  // GET DATA FROM STORE
  // ----------------------------------------------------------------------
  let pnorderData = useSelector((state) => state.pnorder || []);
  let pnorderAlert = useSelector((state) => state.pnorderUpdate || []);
  let pnorderCheck = useSelector((state) => state.pnorderCheck || []);

  let { id } = useParams();
  let ObjectDefault = {
    access: "",
    anion_balance: "",
    ca: "",
    carnitine: "",
    chromium: "",
    copper: "",
    famotidine: "",
    fat: "",
    gir: "",
    heparin: "",
    insulin: "",
    iron: "",
    k: "",
    manganese: "",
    mg: "",
    na: "",
    phos: "",
    phytonadione: "",
    protein: "",
    selenium: "",
    tf_goal: "",
    trace_elements: "",
    type_fe: "",
    vitamins: "",
    zinc: "",
    //value caculator
    fromFE: "",
    Lipids: "",
    nutrition: "",
    dextrose_concentration: "",
    osmolarity: "",
  };
  let ObjectDefaultBolean = {
    access: false,
    anion_balance: false,
    ca: false,
    carnitine: false,
    chromium: false,
    copper: false,
    famotidine: false,
    fat: false,
    gir: false,
    heparin: false,
    insulin: false,
    iron: false,
    k: false,
    manganese: false,
    mg: false,
    na: false,
    phos: false,
    phytonadione: false,
    protein: false,
    selenium: false,
    tf_goal: false,
    trace_elements: false,
    type_fe: false,
    vitamins: false,
    zinc: false,
    //value caculator
    fromFE: false,
    Lipids: false,
    nutrition: false,
    dextrose_concentration: false,
    osmolarity: false,
  };
  const dispatch = useDispatch();
  const [stateDataGlobal, setSateDataGlobal] = useState(pnorderData?.data || []);
  const [modal, setModal] = useState(false);
  const [stateValue, setStateValue] = useState(ObjectDefault);
  const [state, setState] = useState(ObjectDefault);
  const [resultOsmolarity, setCaculatorOsmolarity] = useState("");
  const [resultAAconcentration, setCaculatorAAconcentration] = useState("");
  const [isUpdateOsmolarity, setUpdateOsmolarity] = useState(false);

  const [tooltipOpen, setTooltipOpen] = useState(ObjectDefaultBolean);

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const toggleTooltip = (e) => {
    const { name } = e.target;
    setTooltipOpen({
      ...tooltipOpen,
      [name]: !tooltipOpen[name],
    });
  };

  const toggle = () => setModal(!modal);

  // ----------------------------------------------------------------------
  // CONNECT API
  // ----------------------------------------------------------------------
  useEffect(() => {
    const fetchData = async () => {
      dispatch(actFetchPNOrderRequest(id));
    };

    //fetch data
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    setSateDataGlobal(pnorderData?.data);
  }, [pnorderData]);

  // ----------------------------------------------------------------------
  // Data API
  // ----------------------------------------------------------------------
  let responseData = stateDataGlobal && stateDataGlobal;
  let accessData = responseData?.access;
  let tf_goalData = responseData?.tf_goal;
  let girData = responseData?.gir;
  let proteinData = responseData?.protein;
  let type_feData = responseData?.type_fe;
  let fatData = responseData?.fat;
  let naData = responseData?.na;
  let kData = responseData?.k;
  let caData = responseData?.ca;
  let mgData = responseData?.mg;
  let phosData = responseData?.phos;
  let anion_balanceData = responseData?.anion_balance;
  let vitaminsData = responseData?.vitamins;
  let phytonadioneData = responseData?.phytonadione;
  let trace_elementsData = responseData?.trace_elements;
  let manganeseData = responseData?.manganese;
  let chromiumData = responseData?.chromium;
  let seleniumData = responseData?.selenium;
  let zincData = responseData?.zinc;
  let copperData = responseData?.copper;
  let heparinData = responseData?.heparin;
  let famotidineData = responseData?.famotidine;
  let insulinData = responseData?.insulin;
  let ironData = responseData?.iron;
  let carnitineData = responseData?.carnitine;
  let osmolarityData = responseData?.osmolarity;
  let tf_unitName = responseData?.tf_unit;
  let pn_order_view_mode = responseData?.pn_order_view_mode;

  // ----------------------------------------------------------------------
  // Checked Option
  // ----------------------------------------------------------------------
  //useEffect set value
  useEffect(() => {
    //setState OrderId
    setState((prevState) => ({
      ...prevState,
      access: findOrderId("access", accessData || []),
      tf_goal: findOrderId("tf_goal", tf_goalData || []),
      gir: findOrderId("gir", girData || []),
      protein: findOrderId("protein", proteinData || []),
      type_fe: findOrderId("type_fe", type_feData || []),
      fat: findOrderId("fat", fatData || []),
      na: findOrderId("na", naData || []),
      k: findOrderId("k", kData || []),
      ca: findOrderId("ca", caData || []),
      mg: findOrderId("mg", mgData || []),
      phos: findOrderId("phos", phosData || []),
      anion_balance: findOrderId("anion_balance", anion_balanceData || []),
      vitamins: findOrderId("vitamins", vitaminsData || []),
      phytonadione: findOrderId("phytonadione", phytonadioneData || []),
      trace_elements: findOrderId("trace_elements", trace_elementsData || []),
      manganese: findOrderId("manganese", manganeseData || []),
      chromium: findOrderId("chromium", chromiumData || []),
      selenium: findOrderId("selenium", seleniumData || []),
      zinc: findOrderId("zinc", zincData || []),
      copper: findOrderId("copper", copperData || []),
      heparin: findOrderId("heparin", heparinData || []),
      famotidine: findOrderId("famotidine", famotidineData || []),
      insulin: findOrderId("insulin", insulinData || []),
      iron: findOrderId("iron", ironData || []),
      carnitine: findOrderId("carnitine", carnitineData || []),
      osmolarity: findOrderId("osmolarity", osmolarityData || []),
    }));

    //setState Value by CurrentlyOrdered - Active Dropdown
    setStateValue((prevState) => ({
      ...prevState,
      access: findValueActive(accessData || []),
      tf_goal: findValueActive(tf_goalData || []),
      gir: findValueActive(girData || []),
      protein: findValueActive(proteinData || []),
      type_fe: findValueActive(type_feData || []),
      fat: findValueActive(fatData || []),
      na: findValueActive(naData || []),
      k: findValueActive(kData || []),
      ca: findValueActive(caData || []),
      mg: findValueActive(mgData || []),
      phos: findValueActive(phosData || []),
      anion_balance: findValueActive(anion_balanceData || []),
      vitamins: findValueActive(vitaminsData || []),
      phytonadione: findValueActive(phytonadioneData || []),
      trace_elements: findValueActive(trace_elementsData || []),
      manganese: findValueActive(manganeseData || []),
      chromium: findValueActive(chromiumData || []),
      selenium: findValueActive(seleniumData || []),
      zinc: findValueActive(zincData || []),
      copper: findValueActive(copperData || []),
      heparin: findValueActive(heparinData || []),
      famotidine: findValueActive(famotidineData || []),
      insulin: findValueActive(insulinData || []),
      iron: findValueActive(ironData || []),
      carnitine: findValueActive(carnitineData || []),
      osmolarity: findValueActive(osmolarityData || []),
    }));
  }, [
    responseData,
    accessData,
    tf_goalData,
    girData,
    proteinData,
    type_feData,
    fatData,
    naData,
    kData,
    caData,
    mgData,
    phosData,
    anion_balanceData,
    vitaminsData,
    phytonadioneData,
    trace_elementsData,
    manganeseData,
    chromiumData,
    seleniumData,
    zincData,
    copperData,
    heparinData,
    famotidineData,
    insulinData,
    ironData,
    carnitineData,
    osmolarityData,
  ]);

  // ----------------------------------------------------------------------
  // caculator
  // ----------------------------------------------------------------------
  useEffect(() => {
    function checkValueInput() {
      const TypeofFE = findValueActive(type_feData || []);
      const Dose = findValueActive(fatData || []);
      const TotalFluidGoal = findValueActive(tf_goalData || []);
      const glucoseInfusionRate = findValueActive(girData || []);

      if (TypeofFE > 0 && Dose > 0) {
        //intravenousFatEmulsion
        const weightValue = responseData?.weight;
        const setCaculator = (Dose * 1000) / TypeofFE;
        let resultVolFromFE = tf_unitName === "ml/day" ? setCaculator.toFixed(1) * weightValue : setCaculator.toFixed(1);
        setState((prevState) => ({
          ...prevState,
          fromFE: resultVolFromFE,
          Lipids: resultVolFromFE,
        }));
      } else {
        setState((prevState) => ({ ...prevState, fromFE: "", Lipids: "" }));
      }

      //nutrition_caculator
      const valuefromFE = parseFloat(state.fromFE);
      if (TotalFluidGoal > 0 && state.fromFE > 0) {
        let VolforNutrition = (TotalFluidGoal - valuefromFE - valueIntake).toFixed(1);
        setState((prevState) => ({ ...prevState, nutrition: VolforNutrition }));
      } else {
        setState((prevState) => ({ ...prevState, nutrition: "" }));
      }

      //dextrose_concentration
      if (state.nutrition > 0 && glucoseInfusionRate > 0) {
        if (tf_unitName === "ml/day") {
          const result = (((glucoseInfusionRate * 60 * 24 * 0.001 * responseData.weight) / state.nutrition) * 100).toFixed(1);
          setState((prevState) => ({
            ...prevState,
            dextrose_concentration: result,
          }));
        } else {
          const result = (((glucoseInfusionRate * 60 * 24 * 0.001) / state.nutrition) * 100).toFixed(1);
          setState((prevState) => ({
            ...prevState,
            dextrose_concentration: result,
          }));
        }
      } else {
        setState((prevState) => ({ ...prevState, dextrose_concentration: "" }));
      }

      //aa_concentration
      if (state.nutrition) {
        if (tf_unitName === "ml/day") {
          const result = ((stateValue.protein * responseData.weight) / (state.nutrition / 1000)).toFixed(1);
          setCaculatorAAconcentration(result);
        } else {
          const result = ((stateValue.protein) / (state.nutrition / 1000)).toFixed(1);
          setCaculatorAAconcentration(result);
        }
      }

      //call function
      setUpdateOsmolarity(true);
    }

    checkValueInput();
  }, [
    type_feData,
    fatData,
    tf_goalData,
    girData,
    state.fromFE,
    state.nutrition,
    tf_unitName,
    responseData,
    stateValue.protein,
    valueIntake
  ]);

  //Onload caculator Osmolarity
  useEffect(() => {
    const dextrose_concentration = Number(state.dextrose_concentration);
    const { protein, na, k, ca, mg } = stateValue;
    const caculatorOsmolarity = async () => {
      if (
        !isNaN(dextrose_concentration) &&
        !isNaN(protein) &&
        !isNaN(na) &&
        !isNaN(k) &&
        !isNaN(ca) &&
        !isNaN(mg)
      ) {
        const caculatorResult = (dextrose_concentration * 5 + protein * 10 + (na + k + ca + mg) * 2).toFixed(1);
        setCaculatorOsmolarity(caculatorResult);
        setUpdateOsmolarity(false);
      }
    };

    if (isUpdateOsmolarity) {
      caculatorOsmolarity();
    }
  }, [
    isUpdateOsmolarity,
    state.dextrose_concentration,
    stateValue,
    responseData,
  ]);

  // ----------------------------------------------------------------------
  // Update result
  // ----------------------------------------------------------------------
  function updateInputValue() {
    const TypeofFE = findValueActive(type_feData || []);
    const Dose = findValueActive(fatData || []);
    const TotalFluidGoal = findValueActive(tf_goalData || []);
    const glucoseInfusionRate = findValueActive(girData || []);

    if (TypeofFE && Dose) {
      //intravenousFatEmulsion
      const weightValue = responseData?.weight;
      const setCaculator = (Dose * 1000) / TypeofFE;
      let resultVolFromFE =
        tf_unitName === "ml/day"
          ? setCaculator.toFixed(1) * weightValue
          : setCaculator.toFixed(1);
      setState((prevState) => ({
        ...prevState,
        fromFE: resultVolFromFE,
        Lipids: resultVolFromFE,
      }));
    }

    //nutrition_caculator
    const valuefromFE = parseFloat(state.fromFE);
    if (TotalFluidGoal > 0 && valuefromFE > 0) {
      let VolforNutrition = (TotalFluidGoal - valuefromFE - valueIntake).toFixed(1);//
      setState((prevState) => ({ ...prevState, nutrition: VolforNutrition }));
    } else {
      setState((prevState) => ({ ...prevState, nutrition: "" }));
    }

    //dextrose_concentration
    if (state.nutrition && glucoseInfusionRate) {
      if (tf_unitName === "ml/day") {
        const result = (((glucoseInfusionRate * 60 * 24 * 0.001 * responseData.weight) / state.nutrition) * 100).toFixed(1);
        setState((prevState) => ({
          ...prevState,
          dextrose_concentration: result,
        }));
      } else {
        const result = (((glucoseInfusionRate * 60 * 24 * 0.001) / state.nutrition) * 100).toFixed(1);
        setState((prevState) => ({
          ...prevState,
          dextrose_concentration: result,
        }));
      }
    }

    //aa_concentration
    if (state.nutrition) {
      if (tf_unitName === "ml/day") {
        const result = ((stateValue.protein * responseData.weight) / (state.nutrition / 1000)).toFixed(1);
        setCaculatorAAconcentration(result);
      } else {
        const result = ((stateValue.protein) / (state.nutrition / 1000)).toFixed(1);
        setCaculatorAAconcentration(result);
      }
    }
  }

  // ----------------------------------------------------------------------
  // SetState Data
  // ----------------------------------------------------------------------
  const handleChange = (event, arrData) => {
    const _target = event.target;
    const valueOrderId = _target.getAttribute("data-selected");
    const { name, value } = _target; //name, value/OrderId

    //filter Value By OrderId
    if (valueOrderId) {
      const attrValue = findValueActive(arrData || []);
      //Gia tri Value
      setStateValue((prevState) => ({ ...prevState, [name]: attrValue }));
    }

    const dataStore = { ...stateDataGlobal };
    const params = {
      Action: "update",
      OrderId: value === "0" ? "" : value,
      PNCategory: name,
    };

    setState((prevState) => ({ ...prevState, [name]: value }));

    //Submit api
    if (dataStore && dataStore[name].length > 0) {
      dataStore &&
        dataStore[name].forEach(function (item, index) {
          if (item.OrderId === parseFloat(value)) {
            item.CurrentlyOrdered = true;
          } else {
            item.CurrentlyOrdered = false;
          }
        });

      //Update data
      setSateDataGlobal(dataStore);
      dispatch({ type: "PNORDER_UPDATE_SUCCESS", payload: dataStore });
      dispatch(actUpdatePNOrderRequest(id, params));
    }

    //Check type_fe, fat, tf_goal, gir
    if (
      (name === "type_fe" ||
        name === "fat" ||
        name === "tf_goal" ||
        name === "gir") &&
      value !== "0"
    ) {
      updateInputValue();
    } else {
      setState((prevState) => ({
        ...prevState,
        fromFE: "",
        Lipids: "",
        nutrition: "",
        dextrose_concentration: "",
      }));
    }

    //Check caculator Osmolarity
    caculatorOsmolarityChange();
  };

  //caculator Osmolarity
  const caculatorOsmolarityChange = () => {
    //caculator dextrose_concentration
    const dextrose_concentration = Number(state.dextrose_concentration);
    const { protein, na, k, ca, mg } = stateValue;
    if (
      !isNaN(dextrose_concentration) &&
      !isNaN(protein) &&
      !isNaN(na) &&
      !isNaN(k) &&
      !isNaN(ca) &&
      !isNaN(mg)
    ) {
      const caculatorResult = (dextrose_concentration * 5 + protein * 10 + (na + k + ca + mg) * 2).toFixed(1);
      setCaculatorOsmolarity(caculatorResult);
    }
  };

  // ----------------------------------------------------------------------
  // Handle button Check My Orders, Load PnOrders
  // ----------------------------------------------------------------------
  const handleUpdateActions = (name) => {
    if (name === "check_orders") {
      const params = {
        Action: "check",
      };
      dispatch(actCheckMyOrders(id, params));
    }
    if (name === "copy_forward") {
      const params = {
        Action: "copy_forward",
      };
      dispatch(actFetchCopyForwardRequest(id, params));
      setModal(false);
    }
  };

  // ----------------------------------------------------------------------
  // Function Find Id, Value, Name
  // ----------------------------------------------------------------------
  const findOrderId = (name, data) => {
    const itemActive =
      data && data.filter((item) => item.CurrentlyOrdered === true);
    const valueActive = itemActive[0] && itemActive[0].OrderId;
    return valueActive || "0";
  };

  const findValueActive = (data) => {
    const itemActive =
      data && data.filter((item) => item.CurrentlyOrdered === true);
    const valueActive = itemActive[0] && itemActive[0].Value;
    return valueActive || "0";
  };

  const findNamebyId = (data) => {
    const itemActive =
      data && data.filter((item) => item.CurrentlyOrdered === true);
    const valueActive = itemActive[0] && itemActive[0].Name;
    return valueActive || "0";
  };

  // ----------------------------------------------------------------------
  // Data Current Case from props
  // ----------------------------------------------------------------------
  const dataProps = props && props.dataCase;
  const thisTimePoint =
    dataProps && dataProps.Timeline && dataProps.Timeline.CurrentTimePoint;

  const isTraceElement = findNamebyId(trace_elementsData || []);
  const isAccessElement = findNamebyId(accessData || []);
  return (
    <div className="bg-white" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1000, background: "rgba(255, 255, 255, 0.9)" }}>
      <HeaderPnOrder
        thisTimePoint={thisTimePoint}
        toggle={toggle}
        handleUpdateActions={handleUpdateActions}
        pnorderCheck={pnorderCheck}
      />
      <div
        className="pnOrderSection p-0"
        style={{ height: "100%" }}
      >
        <Row className="tpnOrderItem align-items-center mt-4">
          <Col md={12} lg={6}>
            <ItemOrderDropdown
              label={``}
              name="access"
              data={accessData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.access}
              findOrderId={findOrderId}
              fullWidth={true}
            />
          </Col>
          <Col md={12} lg={6}>
            <FormGroup className="mb-0">
              <div className="d-flex">
                <Label className="col-order-label" for="example01" sm={5}>
                  Weight:
                </Label>
                <Col sm={7}>
                  <Input
                    type="text"
                    disabled
                    name="weight"
                    value={responseData?.weight + " kg"}
                    placeholder={responseData?.weight + "kg"}
                    autoComplete="off"
                  />
                </Col>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="tpnOrderItem align-items-center">
          <Col md={12} lg={6}>
            <ItemOrderDropdown
              label={`Total Fluid Goal:`}
              name="tf_goal"
              data={tf_goalData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.tf_goal}
              findOrderId={findOrderId}
            />
          </Col>
          <Col md={12} lg={6}>
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="example01" sm={7}>
                  Vol. of Infusions/Meds:
                </Label>
                <Col sm={5}>
                  <Input type="text" autoComplete="off" value="0" disabled name="text01" />
                </Col>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="Lipids" sm={7}>
                  Vol. of Lipids:
                </Label>
                <Col sm={5}>
                  <Input
                    id="Lipids"
                    type="text"
                    autoComplete="off"
                    value={state.Lipids && `${state.Lipids} ${tf_unitName}`}
                    readOnly
                    name="Lipids"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["Lipids"]}
                    target="Lipids"
                    toggle={toggleTooltip}
                  >
                    {state.Lipids && `${state.Lipids} ${tf_unitName}`}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="example01" sm={7}>
                  Vol. for PN:
                </Label>
                <Col sm={5}>
                  <Input
                    id="nutrition"
                    type="text"
                    autoComplete="off"
                    value={
                      state.nutrition && `${state.nutrition} ${tf_unitName}`
                    }
                    readOnly
                    name="nutrition"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["nutrition"]}
                    target="nutrition"
                    toggle={toggleTooltip}
                  >
                    {state.nutrition && `${state.nutrition} ${tf_unitName}`}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="oral_intake" sm={7}>
                  Enteral Intake (24 hrs)
                </Label>
                <Col sm={5}>
                  <Input
                    id="oral_intake"
                    type="text"
                    autoComplete="off"
                    value={`${valueIntake} ${tf_unitName}`}
                    readOnly
                    name="oral_intake"
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["oral_intake"]}
                    target="oral_intake"
                    toggle={toggleTooltip}
                  >
                    {`${valueIntake} ${tf_unitName}`}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <Row className="tpnOrderItem">
          <Col md={12} lg={6}>
            <h5 className="m--font-success head-5">Dextrose</h5>
            <ItemOrderDropdown
              label={`Glucose Infusion Rate`}
              name="gir"
              data={girData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.gir}
              findOrderId={findOrderId}
            />
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="Dextrose" sm={5}>
                  Dextrose Concentration
                </Label>
                <Col sm={7}>
                  <Input
                    id="dextrose_concentration"
                    type="text"
                    autoComplete="off"
                    value={
                      state.dextrose_concentration
                        ? state.dextrose_concentration + " %"
                        : " "
                    }
                    name="dextrose_concentration"
                    readOnly
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["dextrose_concentration"]}
                    target="dextrose_concentration"
                    toggle={toggleTooltip}
                  >
                    {state.dextrose_concentration
                      ? state.dextrose_concentration + " %"
                      : " "}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
          </Col>
          <Col md={12} lg={6}>
            <h5 className="m--font-success head-5">Protein</h5>
            <ItemOrderDropdown
              label={`Amino Acid Dose`}
              name="protein"
              data={proteinData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.protein}
              findOrderId={findOrderId}
            />
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="aa_concentration" sm={5}>
                  Amino Acid Concentration
                </Label>
                <Col sm={7}>
                  <Input
                    id="aa_concentration"
                    type="text"
                    autoComplete="off"
                    value={resultAAconcentration ? `${resultAAconcentration} g/L` : ''}
                    name="aa_concentration"
                    readOnly
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["aa_concentration"]}
                    target="aa_concentration"
                    toggle={toggleTooltip}
                  >
                    {resultAAconcentration ? `${resultAAconcentration} g/L` : ''}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row className="tpnOrderItem">
          <Col md={12} lg={6}>
            <h5 className="m--font-success head-5">Intravenous Fat Emulsion</h5>
            <ItemOrderDropdown
              label={`Type of FE`}
              name="type_fe"
              data={type_feData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.type_fe}
              findOrderId={findOrderId}
            />
            <ItemOrderDropdown
              label={`Dose`}
              name="fat"
              data={fatData}
              handleChange={handleChange}
              isLoading={pnorderAlert.isLoading}
              defaultValue={state.fat}
              findOrderId={findOrderId}
            />
            <FormGroup>
              <div className="d-flex">
                <Label className="col-order-label" for="example01" sm={5}>
                  Vol. from FE
                </Label>
                <Col sm={7}>
                  <Input
                    id="volFromFE"
                    type="text"
                    autoComplete="off"
                    name="volFromFE"
                    value={state.fromFE && `${state.fromFE} ${tf_unitName}`}
                    readOnly
                  />
                  <Tooltip
                    placement="top"
                    isOpen={tooltipOpen["volFromFE"]}
                    target="volFromFE"
                    toggle={toggleTooltip}
                  >
                    {state.fromFE && `${state.fromFE} ${tf_unitName}`}
                  </Tooltip>
                </Col>
              </div>
            </FormGroup>
          </Col>
        </Row>
        {/* View Mode */}
        {pn_order_view_mode === "default" && (
          <React.Fragment>
            <Row className="tpnOrderItem">
              <Col md={12} lg={6}>
                <h5 className="m--font-success head-5">Electrolytes</h5>
                <ItemOrderDropdown
                  label={`Sodium`}
                  name="na"
                  data={naData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.na}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Potassium`}
                  name="k"
                  data={kData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.k}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Calcium`}
                  name="ca"
                  data={caData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.ca}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Magnesium`}
                  name="mg"
                  data={mgData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.mg}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Phosphorus`}
                  name="phos"
                  data={phosData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.phos}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Anion Balance`}
                  name="anion_balance"
                  data={anion_balanceData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.anion_balance}
                  findOrderId={findOrderId}
                />
              </Col>
              <Col md={12} lg={6}>
                <h5 className="m--font-success head-5">&nbsp;</h5>
                <ItemOrderDropdown
                  label={`Vitamins`}
                  name="vitamins"
                  data={vitaminsData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.vitamins}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Additional Phytonadione`}
                  name="phytonadione"
                  data={phytonadioneData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.phytonadione}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Trace Elements`}
                  name="trace_elements"
                  data={trace_elementsData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.trace_elements}
                  findOrderId={findOrderId}
                />
                {isTraceElement !== "Standard" && (
                  <React.Fragment>
                    {/* {manganeseData && manganeseData.length > 0 && (
                      <ItemOrderDropdown
                        label={`Manganese`}
                        name="manganese"
                        data={manganeseData}
                        handleChange={handleChange}
                        isLoading={pnorderAlert.isLoading}
                        defaultValue={state.manganese}
                        findOrderId={findOrderId}
                      />
                    )}
                    {chromiumData && chromiumData.length > 0 && (
                      <ItemOrderDropdown
                        label={`Chromium`}
                        name="chromium"
                        data={chromiumData}
                        handleChange={handleChange}
                        isLoading={pnorderAlert.isLoading}
                        defaultValue={state.chromium}
                        findOrderId={findOrderId}
                      />
                    )} */}
                    <ItemOrderDropdown
                      label={`Selenium`}
                      name="selenium"
                      data={seleniumData}
                      handleChange={handleChange}
                      isLoading={pnorderAlert.isLoading}
                      defaultValue={state.selenium}
                      findOrderId={findOrderId}
                    />
                    <ItemOrderDropdown
                      label={`Zinc`}
                      name="zinc"
                      data={zincData}
                      handleChange={handleChange}
                      isLoading={pnorderAlert.isLoading}
                      defaultValue={state.zinc}
                      findOrderId={findOrderId}
                    />
                    <ItemOrderDropdown
                      label={`Copper`}
                      name="copper"
                      data={copperData}
                      handleChange={handleChange}
                      isLoading={pnorderAlert.isLoading}
                      defaultValue={state.copper}
                      findOrderId={findOrderId}
                    />
                  </React.Fragment>
                )}
              </Col>
            </Row>
            <hr />
            <Row className="tpnOrderItem">
              <Col md={12} lg={6}>
                <h5 className="m--font-success head-5">Additives</h5>
                <ItemOrderDropdown
                  label={`Heparin`}
                  name="heparin"
                  data={heparinData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.heparin}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Famotidine`}
                  name="famotidine"
                  data={famotidineData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.famotidine}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Insulin`}
                  name="insulin"
                  data={insulinData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.insulin}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Iron`}
                  name="iron"
                  data={ironData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.iron}
                  findOrderId={findOrderId}
                />
                <ItemOrderDropdown
                  label={`Carnitine`}
                  name="carnitine"
                  data={carnitineData}
                  handleChange={handleChange}
                  isLoading={pnorderAlert.isLoading}
                  defaultValue={state.carnitine}
                  findOrderId={findOrderId}
                />
              </Col>
              <Col md={12} lg={6}>
                {osmolarityData?.length > 0 && (
                  <React.Fragment>
                    <h5 className="m--font-success head-5">Osmolarity</h5>
                    <ItemOrderDropdown
                      label={`Desired Osmolarity`}
                      name="osmolarity"
                      data={osmolarityData}
                      handleChange={handleChange}
                      isLoading={pnorderAlert.isLoading}
                      defaultValue={state.osmolarity}
                      findOrderId={findOrderId}
                    />
                  </React.Fragment>
                )}
                {isAccessElement === "Peripheral Access" && (
                  <CalculatedOsmolarity
                    resultOsmolarity={resultOsmolarity}
                  />
                )}
                <MessageCalorie
                  valueLipidsDose={state?.fromFE || ''}
                  valueDropdownActive={stateValue || []}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}
        {pn_order_view_mode === "show-top-only" && (
          <MessageCalorie
            valueLipidsDose={state?.fromFE || ''}
            valueDropdownActive={stateValue || []}
          />
        )}
      </div>
      <CopyForwardModal
        isOpen={modal}
        toggle={toggle}
        handleUpdateActions={handleUpdateActions}
        data={(pnorderData && pnorderData) || {}}
      />
      {pnorderData.isLoading && <LoaderText />}
    </div>
  );
}

export default PNOrderSection;
