import React from 'react';
import styled from 'styled-components';
import CaseTitle from './CaseTitle';
import MedicalDescriptionBox from './MedicalDescriptionBox';

const MedicalInfoWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 16px;
`;

const MedicalInfoInner = styled.div`
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;

const MedicalInfoTop = styled.div`
    flex: 1;
`;

const ButtonGroupFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
`;

const ButtonDefault = styled.button`
    margin-right: 8px;
`;

const ButtonGroup = ({ handleRedirectUrl, handleBeginLearner, isLoadingGotIt }) => (
    <ButtonGroupFooter>
        <button
            className="button-yellow mb-3 mx-0"
            onClick={handleBeginLearner}
            disabled={isLoadingGotIt}
        >
            {isLoadingGotIt && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Start the Encounter
        </button>
        <ButtonDefault
            onClick={handleRedirectUrl}
            disabled={isLoadingGotIt}
            className='button-default mx-0'
        >
            Back to Assignments
        </ButtonDefault>
    </ButtonGroupFooter>
);

const MedicalInfo = (props) => {
    const {
        handleRedirectUrl,
        handleBeginLearner,
        isLoadingGotIt,
    } = props;

    const content = `NARRATIVE: 
        Meet Mrs. Johnson, a 65-year-old retired school teacher who lives with her loving husband. 
        She is a mother of two loving adult children who live out of state. Today, she presents to your clinic 
        with severe abdominal pain and bloating that she has been experiencing for the past 2 days. She describes 
        the pain as a "stabbing sensation" around her belly button that radiates to the lower parts of her abdomen. 
        Mrs. Johnson is visibly distressed and seeks your assistance to relieve her discomfort. Your task is to navigate 
        through her medical history to unravel the cause of her complaints.`;

    return (
        <MedicalInfoWrapper>
            <MedicalInfoInner>
                <MedicalInfoTop>
                    <CaseTitle title="A <span>7-year-old</span> boy who was just brought into the Emergency Department by his mother." />
                    <MedicalDescriptionBox content={content} />
                </MedicalInfoTop>
                <ButtonGroup
                    handleRedirectUrl={handleRedirectUrl}
                    handleBeginLearner={handleBeginLearner}
                    isLoadingGotIt={isLoadingGotIt}
                />
            </MedicalInfoInner>
        </MedicalInfoWrapper>
    );
};

export default MedicalInfo;