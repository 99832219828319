import React from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";

const ForgotPassWordForm = ({ onSubmit }) => {
    const { register, handleSubmit, formState: { errors }, setError } = useForm();

    const handleFormSubmit = (data) => {
        if (!data.email) {
            setError("email", { type: "manual", message: "Email is required" });
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            setError("email", { type: "manual", message: "Invalid email format" });
        } else {
            onSubmit(data);
        }
    };

    return (
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <FormGroup>
                <Label className="ms-label" for="email">Email</Label>
                <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                    invalid={errors.email}
                    {...register("email")}
                />
                {errors.email && <FormFeedback invalid>{errors.email.message}</FormFeedback>}
            </FormGroup>
            <Button type="submit" color="primary" className="btn-brand-sm -btn-lg w-100 -shadown">
                Reset password
            </Button>
        </Form>
    );
};

export default ForgotPassWordForm;