import React from "react";

//reactstrap
import { FormGroup, Input } from "reactstrap";

//redux
import { useSelector } from "react-redux";

function DropDownTimePoint(props) {
    const { handleChange, stateCurrentQuestion } = props && props;
    const timelineData = useSelector((state) => state.timeline || []);
    const timepointList = timelineData?.data;
    return (
        <FormGroup>
            <h5 className="heading-5">Timepoint</h5>
            <Input type="select" name="TimePointId" defaultValue={stateCurrentQuestion?.TimePointId} onChange={handleChange}>
                {timepointList?.map((value, index) =>
                    <option value={value.Id} key={index}>{value.DisplayName}</option>
                )}
            </Input>
        </FormGroup>
    );
}

export default DropDownTimePoint;