import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from "reactstrap";
import { useForm } from "react-hook-form";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const ContactModal = (props) => {
  const { isOpen, toggle } = props && props;
  const [isLoading, setLoading] = useState(false);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  //submit
  const onSubmit = () => {
    setLoading(true);
  };

  return (
    <Modal style={{ maxWidth: "600px" }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="answer">
              We are more than happy to assist you in choosing the best plan.{" "}
              <span className="m--font-danger">*</span>
            </Label>
            <p className="text-muted mb-3 text-2">
              Please just provide us with some information on your training
              needs in the textbox below. A Med2Lab representative will reach
              out to you within the next 24 hours.
            </p>
            <TextareaAutosize
              {...register("answer", {
                required: "This field is required.",
              })}
              invalid={(errors.answer?.type === "answer").toString()}
              name="answer"
              placeholder="Enter your answer"
              className="w-100 form-control"
              maxRows="10"
              minRows={5}
            />
            {errors.answer?.message && (
              <FormFeedback className="d-block">
                {errors.answer?.message}
              </FormFeedback>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          className="--btn-primary d-flex align-items-center"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ContactModal;
