import React, { useState } from "react";

//reactstrap
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

//classnames
import classnames from "classnames";

//components
import {
    General,
    StartingPage,
    TransitionPage,
    EndingPage,
    AccessTab
} from '../Modules/SettingTabs';

function SettingsContent(props) {

    //props
    const { data, handleChange, handleChangeMessage } = props && props;
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const listTabs = [
        { id: "1", name: "General", component: <General {...props} data={(data && data) || {}} {...props} handleChange={handleChange} /> },
        { id: "2", name: "Starting Page", component: <StartingPage {...props} data={(data && data) || {}} handleChangeMessage={handleChangeMessage} /> },
        { id: "3", name: "Transition Page", component: <TransitionPage {...props} data={(data && data) || {}} handleChangeMessage={handleChangeMessage} /> },
        { id: "4", name: "Ending Page", component: <EndingPage {...props} data={(data && data) || {}} handleChangeMessage={handleChangeMessage} /> },
        { id: "5", name: "Access", component: <AccessTab {...props} /> }
    ];

    return (
        <div className="bg-white">
            <Nav className="tabBorder" tabs>
                {listTabs?.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({ active: activeTab === item.id })}
                            onClick={() => {
                                toggle(item.id);
                            }}
                        >
                            <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                            {item.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {listTabs?.map((item, index) => (
                    <TabPane key={index} tabId={item.id}>
                        {item.component}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
}

export default SettingsContent;