import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//router
import { useParams } from "react-router-dom";

//action
import {
    atcDeleteStudyInfo
} from "@actions";

function ModalDelCategory(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const data = props && props.data;
  const timePointId = props && props.timePointId;

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "Study",
      TimePointId: timePointId,
      StudyId: data && data.Id
    };
    dispatch(atcDeleteStudyInfo(id, params));
    props && props.toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that <br /> you want to delete this study ?
        </h4>
        <p className="mt-2 text-center">This action is irreversible</p>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-danger" onClick={handleRemove}>
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDelCategory;
