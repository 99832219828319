import React from 'react';

//components
import { ChangeModeFormat } from '../Labs';

//components
import { CheckBoxAvailability } from '@components/elements';

function TableCell(props) {
    const { item, TimePointData, handleEdit, handleShowModalChangeValue, isSwitch, type } = props && props;
    const Time = TimePointData?.Time;
    const InitialValue = item?.InitialValue;
    const DefaultChange = item?.DefaultChange;
    const ItemName = item?.Name;
    const TimepointId = TimePointData?.TimepointId;
    return (
        <React.Fragment>
            {isSwitch === 'Value' && type !== 'first_name' ? (
                <td
                    style={{ cursor: 'pointer' }}
                    className={`table-cell align-middle ${Time !== 0 ? 'text-center' : 'text-left'}`}
                    onClick={() => Time === 0 ? handleEdit('', TimePointData, item, "edit-lab-item") : handleShowModalChangeValue(item, TimePointData)}
                >
                    {Time === 0 ? (
                        <span>{type === 'first_name' ? ItemName : InitialValue}</span>
                    ) : (
                        <ChangeModeFormat
                            DefaultChange={(DefaultChange && DefaultChange) || {}}
                            row={(item && item) || {}}
                            TimePointDataLast={(TimePointData && TimePointData) || {}}
                        />
                    )}
                </td>
            ) : (
                type === 'first_name' ? <td className='table-cell align-middle'>{ItemName}</td> : <CheckBoxAvailability {...props} typeFeat="labs" TimepointId={TimepointId} />
            )}
        </React.Fragment>
    );
};

export default TableCell;