import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RenderRoutes } from './routes';

const Root = ({ store, routes, history }) => (
  <Provider store={store}>
    <BrowserRouter>
      <Route history={history}>
          <RenderRoutes routes={routes} />
      </Route>
    </BrowserRouter>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
}

export default Root
