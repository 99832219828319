import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Table } from "reactstrap";

//lodash
import _ from "lodash";

//utils
import { LoaderText, messageData } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";
import { ModalPhysicalExam } from './Modal/Exam';

//Table Common
import { TableHeader, TableCellExam } from './TableElements';

//actions
import { loadExambyAttributeIdForElement } from "@actions";

function ExamFindings(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { data, storeExamFindings } = props && props;

  //state
  const [stateData, setData] = useState((data && data) || []);
  const [show, setShow] = useState({
    examFindings: false
  });
  const [itemEdit, setItem] = useState({});

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const handleShow = (name, row, TimepointData) => {
    setShow((prevState) => ({ ...prevState, [name]: true }));

    //set State Data
    const itemData = { row, ...TimepointData }
    setItem(itemData);

    //Load Exam by ExamFindingId
    if (itemData) {
      callApiLoadExambyId(itemData);
    }
  }

  const callApiLoadExambyId = (itemData) => {
    //Load Exam Finding
    const paramsLoad = {
      Action: "Load",
      Target: "ExamFinding2",
      TimePointId: itemData?.TimepointId,
      AttributeId: itemData?.row?.ExamFindingId,
      PointId: itemData?.PointId
    };
    dispatch(loadExambyAttributeIdForElement(id, paramsLoad));
  }

  const TableData = ({ data }) => {
    //filter Time !== 0
    const listTimepointLast = _.filter(data, function (o) {
      return o?.TimePoint?.Time !== 0;
    });

    //filter Time === 0
    const TimePointFirst = _.filter(data, function (o) {
      return o?.TimePoint?.Time === 0;
    });
    const dataDefault = TimePointFirst?.[0]?.ExamFindings;

    //Header
    const listHeader = [
      { Name: 'Item', width: "300px" },
      { Name: 'Inital Value', width: "300px" },
      ...listTimepointLast
    ]
    
    const listItemSort = TimePointFirst && TimePointFirst[0]?.ExamFindings;
    return (
      <div className="table-layout-fixed">
        <Table responsive>
          <TableHeader 
            data={listHeader}
            dataSort={(listItemSort && listItemSort) || []}
            typeComponent={`ExamFindings`}
            isSort={true}
          />
          <tbody>
            {stateData && stateData.length === 0 && (
              <tr>
                <td>
                  <DataEmty content={messageData?.default?.content} />
                </td>
              </tr>
            )}

            {dataDefault?.map((col, index) => {
              return (
                <tr key={index}>
                  {/* Before */}
                  {TimePointFirst?.map((row) => {
                    const item = row.ExamFindings[index];
                    return <TableCellExam value={item?.ExamName} iconMedia={false} item={item} />;
                  })}
                  {TimePointFirst?.map((row) => {
                    const item = row.ExamFindings[index];
                    return (
                      <TableCellExam
                        handleShow={() => handleShow(`examFindings`, item, row?.TimePoint)}
                        value={item?.InitialValue}
                        item={item}
                        firstTimepoint={true}
                        iconMedia={true}
                      />
                    )
                  })}

                  {/* Last */}
                  {listTimepointLast?.map((row, j) => {
                    const item = row.ExamFindings[index];
                    const ChangeMode = item?.ChangeMode;
                    return (
                      <TableCellExam
                        handleShow={() => handleShow(`examFindings`, item, row?.TimePoint)}
                        value={ChangeMode ? item?.ChangeValue : ''}
                        lastTimepoint={true}
                        item={item}
                        iconMedia={true}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  //get data store
  const dataExamStore = useSelector(
    (state) => state.examFindingsByAtributeId?.data || {}
  );
  
  return (
    <div className="boxPortlet position-relative">
      {!storeExamFindings?.isLoading && (
        <TableData data={(stateData && stateData) || []} />
      )}
      {storeExamFindings?.isLoading && (
        <div style={{ height: '400px' }}>
          <LoaderText />
        </div>
      )}
      <ModalPhysicalExam
        data={itemEdit}
        handleClose={() => { setShow((prevState) => ({ ...prevState, examFindings: false })) }}
        isOpen={show?.examFindings}
        dataExam={dataExamStore?.ExamFinding || {}}
      />
    </div>
  );
}

export default ExamFindings;
