import React from 'react';
import { Progress } from 'reactstrap';

const CustomProgressBar = ({ value, colorClass }) => {
    // Đảm bảo giá trị nằm trong khoảng từ 0 đến 1.0
    const normalizedValue = Math.min(Math.max(value, 0), 1.0);

    // Tính tỉ lệ phần trăm từ giá trị nằm trong khoảng 0 đến 1.0
    const percentage = parseFloat((normalizedValue * 100).toFixed(2)); // Chuyển thành số và giới hạn số lượng chữ số thập phân

    return (
        <div className="progress-bar-custom">
            <Progress
                className={`my-2 ${colorClass}`}
                value={percentage} // Sử dụng tỉ lệ đã tính thay vì giá trị ban đầu
            />
            <span className="progress-bar-custom__text">{`${percentage} %`}</span>
        </div>
    );
};

export default CustomProgressBar;