import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import _ from "lodash";

//components
import { AnswerContent } from "../DragDrop";

function DragDrop(props) {
  const { Answers, UserAnswers } = props && props;

  //filter answer isCorrect === true
  const answerIsCorrect = _.filter(Answers, function (value) {
    return value.isCorrect === true;
  });

  let [isOpen, setIsOpen] = useState(false);

  let onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="feedbackCollapse border">
      <div className={`feedbackCollapse__heading ${isOpen ? "border-bottom" : ""}`} onClick={onToggle}>
        <AnswerContent {...props} />
        <div className="iconCollapse">
          <button
            aria-expanded={isOpen}
            className={`iconCollapse__inner`}
          >
          </button>
        </div>
      </div>
      <div className={`feedbackCollapse__content ${isOpen ? "show" : "hide"}`}>
        <div className="p-3">
          <Row className="mt-3">
            <Col md={6}>
              <p className="font-weight-400 mb-3 --text-primary">Your answer:</p>
              <ul className="answerList">
                {UserAnswers?.map((item, i) => (
                  <li
                    className="answerList__item --border-green --color-green"
                    data-id={item.AnswerId}
                    key={i}
                  >
                    {item?.AnswerContent}
                  </li>
                ))}
              </ul>
            </Col>
            <Col md={6}>
              <p className="font-weight-400 mb-3 --text-primary">
                Correct answer:
              </p>
              <ul className="answerList">
                {answerIsCorrect?.map((item, i) => (
                  <li
                    className="answerList__item --border-green --color-green"
                    key={i}
                  >
                    {item?.AnswerContent}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default DragDrop;
