import React, { useState } from 'react';

//components
import { AddConcept, ModalAddConcept } from '../Concepts';

function ButtonAddConcept(props) {
    //state
    const [modal, setModal] = useState({ CreateProblemConcept: false });

    //Modal
    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    return (
        <React.Fragment>
            <AddConcept
                toggle={() => toggle('CreateProblemConcept')}
            />
            <ModalAddConcept
                {...props}
                isOpen={modal?.CreateProblemConcept}
                toggle={() => toggle('CreateProblemConcept')}
            />
        </React.Fragment>
    );
};

export default ButtonAddConcept;