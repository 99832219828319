import React, { useEffect, useState } from 'react'
import { FormGenerate } from '@components/teachV2/teachPresentation'
import { useForm } from 'react-hook-form'
import { ResultPresentation } from '@components/teachV2/teachPresentation/resultPresentation'
import * as Config from "@constants/Config";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { apiCaller } from "@utils";
import { teachV2Constants } from "@constants";
import { getCoin, getCoinCaseScript } from "@actions";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap';
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'

const PresentationPage = () => {
  let history = useHistory();
  const location = useLocation();
  let { idScript } = useParams();
  const dispatch = useDispatch();
  const { data: dataLoadSlideDesk } = useSelector((state) => idScript ? state?.loadSlideDesk : []);
  const [dataContentFile, setDataContentFile] = useState()
  const [loadingResult, setLoadingResult] = useState(false)
  const [loadingShowForm, setLoadingShowForm] = useState(false)
  const [dataFileImported, setDataFileImported] = useState(null)
  const [bookId, setBookId] = useState(null)

  const getCoinCaseScriptByTemplate = (value) => {
    dispatch(getCoinCaseScript({
      "CaseScriptTemplateId": value
    }))
  }

  useEffect(() => {
    dispatch({ type: teachV2Constants.GET_SLIDE_TEMPLATES_REQUEST });
    apiCaller(`/api/medicalcase/functional/`, 'POST', {
      "action_type": "get_slide_templates"
    }).then(res => {
      const data = res && res.data;
      if (res.status === 200) {
        dispatch({ type: teachV2Constants.GET_SLIDE_TEMPLATES_SUCCESS, payload: data });
        getCoinCaseScriptByTemplate(data[data?.length - 1]?.Id)
      } else {
        dispatch({ 
          type: teachV2Constants.GET_SLIDE_TEMPLATES_FAILURE,
          error: 'error'
        });
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showNotificationCaseScript = (step) => {
    apiCaller(`/api/medicalcase/guide/`, "POST", {
      "step": step
    }).then((res) => {
      if (res && res.status === 200) {
        if (res.data?.notification) {
          toast.success(<div dangerouslySetInnerHTML={{ __html: res.data?.notification?.Message || ''  }} />, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        console.log('error');
      }
    });
  }

  const {
    register,
    handleSubmit,
    setValue, 
    control, 
    watch
  } = useForm()

  useEffect(() => {
    if (idScript) {
      const params = {
        action_type: "load_slide_deck",
        id: idScript,
      }
      dispatch({ type: teachV2Constants.LOAD_SLIDE_DESK_REQUEST, isLoading: true });
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({ type: teachV2Constants.LOAD_SLIDE_DESK_SUCCESS, payload: data });
          setValue("prompt", data?.requirements);
          setValue("gpt_model", data?.model)
          setValue("template_id", data?.template_id)
          setDataContentFile(data)
          setLoadingShowForm(true)
        } else {
          console.log("error");
          dispatch({ type: teachV2Constants.LOAD_SLIDE_DESK_FAILURE, error: 'error' });
        }
      })
    } else {
      setLoadingShowForm(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, idScript])

  const handleUpload = (data, file) => {
    // Lấy tất cả document_id
    const documentIds = bookId?.map(doc => doc.document_id);
    // Tạo chuỗi ngăn cách bởi dấu phẩy
    const documentIdString = documentIds?.join(',');

    setLoadingResult(true)
    const formData = new FormData();
    file && formData.append("document_file", file);
    formData.append("action_type", 'slides_creation_pipeline');
    formData.append("template_id", data?.template_id);
    formData.append("gpt_model", data?.gpt_model);
    formData.append("step", "learning_objectives");
    formData.append("prompt", data?.prompt);
    if (documentIdString) formData.append("document_id", documentIdString);

    axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if (response.status === 200) {
        setDataContentFile(response.data)
        dispatch(getCoin());
        showNotificationCaseScript("learning_objectives")
        history.push(`/teach/presentation/${response?.data?.id}`)
      }
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingResult(false)
    })
  };

  const onSubmit = (data) => {
    handleUpload(data, dataFileImported)
  }

  const handleBack = () => {
    if (!!location.state?.listCase) {
      history.push("/teach/inventory/slides")
    } else {
      history.push(`/teach/presentation`)
    }
  }

  return (
    <div className="wrapper -teachV2HomePage">
      {(loadingShowForm) && (
        <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
          {!dataContentFile ? (
            <FormGenerate 
              register={register}
              setValue={setValue}
              handleSubmit={handleSubmit}
              control={control}
              watch={watch}
              setDataFileImported={setDataFileImported}
              dataFileImported={dataFileImported}
              loadingResult={loadingResult}
              getCoinCaseScriptByTemplate={getCoinCaseScriptByTemplate}
              setBookId={setBookId}
              bookId={bookId}
              slides={true}
            />
          ) : (
            <>
              <Container>
                <Row>
                  <Col md={12}>
                    <div className="icon-back mb-2" onClick={handleBack}>
                      <Icon src={Back}/>
                      <span>Back</span>
                    </div>
                  </Col>
                </Row>
              </Container>
              <ResultPresentation 
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit}
                control={control}
                watch={watch}
                dataContentFile={dataContentFile}
                setDataContentFile={setDataContentFile}
                dataFileImported={dataFileImported}
                dataLoadSlideDesk={dataLoadSlideDesk}
                idScript={idScript}
                showNotificationCaseScript={showNotificationCaseScript}
              />
            </>
          )}
        </form>
      )}
    </div>
  )
}

export default PresentationPage
