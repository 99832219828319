import React from "react";

//truncate
import { truncate } from "@utils";

//reactstrap
import { Badge, UncontrolledTooltip } from "reactstrap";

//lodash
import _ from 'lodash';

function BadgeScore(props) {
  const { data, toggleScore, namePanel, item, timepointData, categoryType } = props && props;
  const timepointCurrent = timepointData?.data;
  const timeOfTimePoint = timepointCurrent?.Time;
  return (
    <React.Fragment>
      {data?.map((val, index) => {
          const numberScore = parseFloat(val.Score);
          const timingCondition = val.TimingCondition;
          const timingValue = val.TimingValue;
          const conditionNotValue = (_.isUndefined(timingValue) && _.isUndefined(timingCondition));
          const conditionMatchValue = (timingCondition === 'At' && (timingValue === timeOfTimePoint));
          return (
            <React.Fragment key={index}>
              {(conditionNotValue || conditionMatchValue) &&
                <React.Fragment>
                  <Badge
                    style={{ cursor: "pointer" }}
                    className="p-1 mb-1"
                    color={numberScore >= 0 ? "success" : "danger"}
                    key={index}
                    onClick={() => toggleScore("edit-score", val, item, namePanel)}
                    id={`tooltipScore_${categoryType}_${val?.DomainId}`}
                  >
                    <span className="font-weight-500">
                      {truncate(val?.DomainName, 12)}
                    </span>
                    &nbsp;
                    <strong>({numberScore})</strong>{` `}
                    {typeof timingValue != "undefined" &&
                      typeof timingCondition != "undefined" &&
                      `(*)`}
                  </Badge>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`tooltipScore_${categoryType}_${val?.DomainId}`}
                  >
                    {val?.DomainName}
                  </UncontrolledTooltip>
                </React.Fragment>
              }
            </React.Fragment>
          );
        })}
      <div
        className="m--font-info font-weight-500"
        style={{ cursor: "pointer" }}
      >
        <span
          className="icon-add icon-size-2x"
          onClick={() => toggleScore("add-score", null, item, namePanel)}
        ></span>
      </div>
    </React.Fragment>
  );
}

export default BadgeScore;
