import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { DialogueRecords, QuestionFeedbackDetails } from ".";

// Styled component for the question content
const QuestionContent = styled.div`
    color: #081B2A;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
    white-space: pre-line;
    display: flex;
`;

function QuestionFeedbackRow(props) {
    const { question } = props && props;
    return (
        <Row>
            <Col md={12}>
                {/* Sử dụng styled component để áp dụng style */}
                {question?.QuestionContent && (
                    <QuestionContent>
                        <span>{`Question ${question.QuestionId}: `}</span>
                        <span className="ml-2" dangerouslySetInnerHTML={{ __html: question.QuestionContent }} />
                    </QuestionContent>
                )}
            </Col>
            <Col md={6}>
                <div className="h-100">
                    <DialogueRecords {...props} />
                </div>
            </Col>
            <Col md={6}>
                <div className="h-100">
                    <QuestionFeedbackDetails {...props} />
                </div>
            </Col>
        </Row>
    );
}

export default QuestionFeedbackRow;