import React from "react";

//conmponents
import { PriorNotes } from "../TabGreen";

function PriorDocuments(props) {
    const { isOpen, data } = props && props;
    
    //props
    const dataCase = data?.Case;
    const PriorDocuments = dataCase?.PriorDocuments;

    return (
        <div className="boxContent -hasBg">
            <PriorNotes {...props} data={PriorDocuments || []} isOpen={isOpen} />
        </div>
    );
}

export default PriorDocuments;