import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InfoLibraryRowContainer = styled.div`
    margin: 10px 0;
`;

const RowHeader = styled.div`
    display: flex;
    align-items: start;
`;

const LibraryLogo = styled.div`
    display: flex;
    width: 60px;
    height: 24px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 11px;
    background: #F1F1F1;
    margin-right: 10px;
    overflow: hidden; /* Đảm bảo không bị vượt ra ngoài box */
    
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(${props => props.src});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
    }
`;

const LibraryTitle = styled.a`
    color: #101828;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;

    &:hover {
        color: #1570EF;
    }
`;

const LibraryAuthors = styled.span`
    color: #344054;
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-top: 4px;
`;

const LibraryCitation = styled.span`
    color: #006E9B;
    font-size: 14px;
    font-weight: 400;
    display: block;
    margin-top: 4px;
`;

const InfoLibraryRow = ({ row }) => {
    const { SourceLogoUrl, Title, Authors, ItemData, SourceURL } = row;


    const isValidLogoUrl = SourceLogoUrl && SourceLogoUrl.startsWith('http');

    return (
        <InfoLibraryRowContainer>
            <RowHeader>
                <LibraryLogo
                    src={
                        isValidLogoUrl
                            ? SourceLogoUrl
                            : "https://via.placeholder.com/53x22"
                    }
                />
                <LibraryTitle
                    href={SourceURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    dangerouslySetInnerHTML={{ __html: Title }}
                />
            </RowHeader>
            <LibraryAuthors>{Authors}</LibraryAuthors>
            <LibraryCitation>{ItemData}</LibraryCitation>
        </InfoLibraryRowContainer>
    );
};

InfoLibraryRow.propTypes = {
    row: PropTypes.shape({
        SourceLogoUrl: PropTypes.string,
        Title: PropTypes.string.isRequired,
        Authors: PropTypes.string.isRequired,
        ItemData: PropTypes.string.isRequired,
        SourceURL: PropTypes.string,
        SourceItemId: PropTypes.string.isRequired,
    }).isRequired,
};

const App = ({ row }) => {
    return (
        <div>
            <InfoLibraryRow row={row} />
        </div>
    );
};

App.propTypes = {
    row: PropTypes.shape({
        SourceLogoUrl: PropTypes.string,
        Title: PropTypes.string.isRequired,
        Authors: PropTypes.string.isRequired,
        ItemData: PropTypes.string.isRequired,
        SourceURL: PropTypes.string.isRequired,
        SourceItemId: PropTypes.string.isRequired,
    }).isRequired,
};

export default App;