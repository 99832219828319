import React from "react";

//components
import { NavigationComponent } from "@components/layouts";
import { AdminChatMain } from '@components/admin/Chat';

//menu
import navigation from "@utils/navigation";

function AdminChatPage() {
    const mainMenu = navigation && navigation.mainAdmin;
    return (
        <React.Fragment>
            <NavigationComponent data={mainMenu} />
            <div className="wrapper -OnBoardingPage">
                <AdminChatMain />
            </div>
        </React.Fragment>
    );
}

export default AdminChatPage;