import React from "react";

//Avatar
import imgAvatar from "@images/learnerv2/interactAssistantAvatar.png";

function GenerateFeedbackBubble({ isLoading, stateResponse, contentStream }) {
    return (
        <div className="bg-white" style={{ borderRadius: '12px' }}>
            <div className="generateFeedbackBubble d-flex align-items-start">
                <div
                    className="generateFeedbackBubble__avatar"
                    style={{
                        backgroundImage: `url(${imgAvatar})`
                    }}
                />
                <div
                    className="tutorial-component size-lg success left wow fadeInUp animated shadow-none"
                    style={{ minWidth: 'auto', width: 'calc(100% - 80px)', marginLeft: '20px' }}
                >
                    <div className="tutorial-component-content">
                        <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: contentStream?.FeedbackContent || stateResponse?.FeedbackContent }} />
                        {isLoading && <div className="typing-loader my-3 mx-auto"></div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerateFeedbackBubble;