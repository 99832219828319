import React, { useState, useEffect } from 'react';

//components
import { BadgeScore } from '../../storyboard/common';

//reactstrap
import { Input } from "reactstrap";

function RowIntervention(props) {
    const {
        item,
        value,
        hanldeCollapse,
        handleChangeAvailable,
        toggleScore,
        toggleDel,
        toggleIntervention,
        isOpen,
        categoryType
    } = props && props;

    const [stateItem, setStateItem] = useState(item || {});

    useEffect(() => {
        setStateItem(item || {});
    }, [item])
    
    return (
        <tr>
            <th scope="row">
                {stateItem?.Name}{" "}
                <span className="sttId">(#{stateItem?.Id})</span>
            </th>
            <td className="text-center">
                <Input
                    className="m-0 checkbox-lg"
                    defaultChecked={stateItem?.Available}
                    type="checkbox"
                    onChange={(e) => {
                        stateItem['Available'] = !stateItem?.Available
                        handleChangeAvailable(e, stateItem?.Id, value.CategoryId)
                    }}
                />
            </td>
            <td className="text-center">
                <BadgeScore
                    {...props}
                    data={stateItem?.DomainScores}
                    toggleScore={toggleScore}
                    valuePanel={value}
                    itemIntervention={item}
                    categoryType={categoryType}
                />
            </td>
            <td className="text-center">
                <div className="lsActions">
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-brand"
                            onClick={hanldeCollapse}
                        >
                            <span className={`fa ${isOpen ? "fa-angle-up" : "fa-angle-down"}`}></span>{" "}
                        </button>
                    </div>
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-brand"
                            onClick={() =>
                                toggleIntervention(
                                    "edit",
                                    item,
                                    value.CategoryId
                                )
                            }
                        >
                            <span className="med-icon icon-pencil"></span>{" "}
                        </button>
                    </div>
                    <div className="lsActions__item">
                        <button
                            className="btn btn-sm btn-outline-danger"
                            onClick={() =>
                                toggleDel(
                                    "delete",
                                    stateItem?.Id,
                                    value.CategoryId
                                )
                            }
                        >
                            <span className="med-icon icon-cancel"></span>{" "}
                        </button>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default RowIntervention;