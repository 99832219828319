import React from 'react';
import { CustomProgressBar } from "@components-common";
import ImageCertificate from "@images/common/pic-certificate.svg";

function CertificateProcessSection() {
    return (
        <section className="certificate-process">
            <div className="card-area bg-white">
                <h2 className="headline --size-6">COVID-19 Cases - Foundational</h2>
                <img className="certificate-process__image mx-auto d-block" src={ImageCertificate} alt="certificate" />
                <CustomProgressBar value={20} colorClass={`--green-bg`} />
                <div className="certificate-process__alert text-center">
                    Complete the case and get a CME certificate
                </div>
            </div>
        </section>
    );
}

export default CertificateProcessSection;