import React from 'react'
import { TableMcqBank } from '@components/teachV2/teachMcqBank'

const TeachMcqBank = () => {
  return (
    <div className='wrapper -mcqBank'>
      <p className='bank-title'>Question Bank</p>
      <TableMcqBank />
    </div>
  )
}

export default TeachMcqBank
