import React from "react";
import { Link } from "react-router-dom";

function ButtonExitCase(props) {
  const { data } = props && props;

  return (
    <React.Fragment>
      {data ? (
        <Link
          to={data}
        >
          <i className="fa fa-reply mr-2" aria-hidden="true"></i> Exit Case
        </Link>
      ) : (
        <Link
          to={"/teach/home"}
        >
          <i className="fa fa-reply mr-2" aria-hidden="true"></i> Exit Case
        </Link>
      )}
    </React.Fragment>
  );
}

export default ButtonExitCase;