import React, { useState } from 'react';

//reactstrap
import { Input } from "reactstrap";

function InputFreeText(props) {
    const { handleSubmitAnswerText } = props && props;

    //state
    const [value, setValue] = useState('');

    //send Api
    const handleSubmit = (currentValue) => {
        const params = {
            "Action": "Submit",
            "Message": value || currentValue
        }
        if (value || currentValue) {
            handleSubmitAnswerText(params);
            setValue('');
        }
    }

    //handlechange
    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    //Handle Key Press
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    return (
        <React.Fragment>
            <div className="freeTextTyping mt-3">
                <div className="freeTextTyping__input">
                    <Input
                        name="answer"
                        placeholder={`Enter your question ...`}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={value}
                        autoComplete="off"
                    />
                </div>
                <button
                    type="button"
                    className="freeTextTyping__btn btn --btn-primary px-4 d-flex align-items-center justify-content-center"
                    onClick={handleSubmit}
                >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    <span className="text-nowrap ml-2">Submit</span>
                </button>
            </div>
        </React.Fragment>
    );
};

export default InputFreeText;