import React from "react";

//Boostrap
import { Button, Input, Modal, ModalBody } from "reactstrap";
import { Icon } from '@components/common'
import stick from '@images/teachV2/loading-result.svg'
import close from '@images/teachV2/close.svg'
import { TitleNoteCommon } from "../../home/generate";
import Reload from '@images/common/reload.svg'
import * as Config from "@constants/Config";
import axios from "axios";

function ModalRegenerate(props) {
  const { isOpen, toggle, setValue, getValues, watch, item, handleNewRegenerate, setLoadingResult, setDataContentFile, dataForm } = props;

  const handleSubmit = (data) => {
    const values = getValues();
    const formData = new FormData();
    for (const [key, value] of Object.entries(values)) {
      if (key === "document_id" && value) {
        const documentIds = value?.map(doc => doc.document_id);
        const documentIdString = documentIds?.join(',');
        if (documentIdString) formData.append("document_id", documentIdString);
      } else if (key === "media_item_id" && value) {
        const documentIds = value?.map(doc => doc.id);
        const documentIdString = documentIds?.join(',');
        if (documentIdString) formData.append("media_item_id", documentIdString);
      } else {
        if (value && value !== 'none') formData.append(key, value);
      }
    }
    setLoadingResult(true);
    toggle('ModalRegenerate')
    axios({
      method: dataForm?.action?.api_call_mode,
      url: `${Config.API_URL}${dataForm?.action?.api}`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      onDownloadProgress: progressEvent => {
        const xhr = progressEvent.target;
        const { responseText } = xhr;
        let datasplit = responseText.split("event: question\ndata: ");
        setDataContentFile(
          datasplit?.map((data, index) => {
            return index > 0 && JSON.parse(data);
          })
        );
      }
    }).then(function (response) {
      let datasplit = response.data.split("event: question\ndata: ");
      setDataContentFile(
        datasplit?.map((data, index) => {
          return index > 0 && {
            id: index,
            ...JSON.parse(data)
          }
        })
      );
    }).catch(function (error) {
      console.error("Error in API call", error);
    }).finally(() => {
      setLoadingResult(false);
    });
  };
  
  return (
    <Modal className="modal-regenerate" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="modal-header">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="modal-header__icon">
              <Icon src={stick} width={24} height={24}/>
            </div>
            <div onClick={() => toggle('ModalRegenerate')}>
              <Icon src={close} width={24} height={24}/>
            </div>
          </div>
          <div className="modal-header__title">
            <h1>Re-generate New Versions</h1>
            <p>You can update the instructions below or leave them as they are to regenerate the current set. Alternatively, you can start over to create a brand-new set.</p>
          </div>
        </div>
        <div className='wrap-option'>
          <TitleNoteCommon 
            dataFor={'instructions'} 
            title={'Instructions'} 
            content=""
          />
          <Input
            type={item[0]?.Contents?.items[0]?.type}
            name={item[0]?.Contents?.items[0]?.field}
            value={watch(item[0]?.Contents?.items[0]?.field)}
            placeholder={item[0]?.Contents?.items[0]?.placeholder}
            style={{ height: '160px' }}
            onChange={(e) => {
              setValue(item[0]?.Contents?.items[0]?.field, e.target.value);
            }}
          />
        </div>
        <div className='wrap-action'>
          <div className='d-flex align-items-center'>
            {/* <Button
              className="d-flex align-items-center mr-2"
              onClick={() => {
                toggle('ModalRegenerate')
              }}
              color='secondary'
            >
              Cancel 
            </Button> */}
            <Button
              className="d-flex align-items-center"
              onClick={() => {
                handleNewRegenerate()
                toggle('ModalRegenerate')
              }}
              color='secondary'
            >
              Start Over 
            </Button>
          </div>
          <Button
            onClick={handleSubmit}
            className="d-flex align-items-center"
            color='primary'
            // disabled={loadingResult}
          >
            <Icon src={Reload} color="#fff" width={20} height={17}/>
            <span className="ml-1">
              Re-generate
            </span>
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalRegenerate;