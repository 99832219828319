import React, { useState } from "react";
import { Label, CustomInput, Badge } from "reactstrap";

//components
import { AnswerContent, QuestionExplanation } from "../MutipleChoice";

function MutipleChoice(props) {
  const {
    Answers,
    MissedCorrect,
    SelectedAndCorrect,
    SelectedAndIncorrect,
    IsCorrect,
    callApiFeedbacksAssistantforQuestion
  } = props && props;

  let [isOpen, setIsOpen] = useState(false);

  let onToggle = (item) => {
    setIsOpen(!isOpen);
    if (!isOpen && item?.IsCorrect === false) {
      callApiFeedbacksAssistantforQuestion(item);
    }
  };

  const itemCheckBoxConfirm = (list) => {
    return (
      <React.Fragment>
        {
          list?.map(function (item, i) {
            const checkClass = () => {
              if (MissedCorrect || SelectedAndCorrect || SelectedAndIncorrect) {
                const checkMissedCorrect = MissedCorrect.some(
                  (value) => value.Id === item.AnswerId
                );
                const checkSelectedAndCorrect = SelectedAndCorrect.some(
                  (value) => value.Id === item.AnswerId
                );
                const checkSelectedAndIncorrect = SelectedAndIncorrect.some(
                  (value) => value.Id === item.AnswerId
                );
                if (checkMissedCorrect) {
                  return "txt-miss-correct";
                } else if (checkSelectedAndCorrect) {
                  return "txt-correct";
                } else if (checkSelectedAndIncorrect) {
                  return "txt-incorrect";
                } else {
                  return "txt-black";
                }
              }
            };

            const renderFontawesome = () => {
              if (checkClass() === "txt-correct") {
                return <i className="fa fa-check mr-3 text-4" aria-hidden="true"></i>;
              } else if (checkClass() === "txt-incorrect") {
                return <i className="fa fa-times mr-3 text-4" aria-hidden="true"></i>;
              } else if (checkClass() === "txt-miss-correct") {
                return <i className="fa fa-check mr-3 text-4 text-success" aria-hidden="true"></i>;
              } else {
                return <i className="mr-3 text-4" aria-hidden="true" style={{width: '18px'}}>&nbsp;</i>;
              }
            }

            return (
              <div className={`ml-4 statusGroup ${i === 0 ? "mt-0" : "my-3"}`} key={i}>
                <div className={`d-flex align-items-center ${checkClass()}`}>
                  <Label className="mb-0 d-flex align-items-center">
                    {renderFontawesome()}
                    <CustomInput
                      className="mt-0"
                      checked={item.UserChoose}
                      data-selected={item.UserChoose}
                      type="checkbox"
                      disabled
                    />{" "}
                    <span className={`${checkClass()}`}>{item.AnswerContent}</span>
                  </Label>
                </div>

                {/* Text */}
                {/* {checkClass() === "txt-incorrect" && (
                  <p className={`${checkClass()}`} style={{ paddingLeft: "52px" }}>(This is NOT correct)</p>
                )}
                {checkClass() === "txt-correct" && (
                  <p className={`${checkClass()}`} style={{ paddingLeft: "52px" }}>(This is CORRECT!)</p>
                )}
                {checkClass() === "txt-miss-correct" && (
                  <p className={`${checkClass()} text-success font-weight-500`} style={{ paddingLeft: "52px" }}>(You MISSED this correct answer)</p>
                )} */}

                <div className="d-block" style={{ paddingLeft: "57px" }}>
                  <p className={`font-weight-300 mt-2 ${checkClass() === 'txt-miss-correct' ? 'text-info' : checkClass()}`}>
                    {item.AnswerExplain}
                  </p>
                </div>

                {item?.InputText && (
                  <div className="mt-2"   >
                    <p>
                      <span className="font-weight-600">
                        Your Explanation for choosing this answer:
                      </span>
                      <br />
                      {item.InputText}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </React.Fragment>
    )
  }

  return (
    <div className="feedbackCollapse border">
      <div className={`feedbackCollapse__heading d-flex align-items-center bg-heading ${isOpen ? "border-bottom" : ""}`} onClick={() => onToggle(props)}>
        <AnswerContent {...props} />
        <div className="feedbackCollapse__right">
          {IsCorrect === false && (
            <Badge className="p-2 mr-3 ml-auto" color="danger">Incorrect</Badge>
          )}
          {IsCorrect === true && (
            <Badge className="p-2 mr-3 ml-auto" color="primary">Correct</Badge>
          )}
          <div className="arrowCollapse">
            <button
              aria-expanded={isOpen}
              className={`arrowCollapse__inner`}
            >
            </button>
          </div>
        </div>
      </div>
      <div className={`feedbackCollapse__content ${isOpen ? "show" : "hide"}`}>
        <div className="p-3">
          {itemCheckBoxConfirm(Answers || [])}
          <QuestionExplanation {...props} />
        </div>
      </div>
    </div>
  );
}

export default MutipleChoice;