import React from 'react';
import styled from 'styled-components';

// Styled button component
const Button = styled.button`
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  color: #475467;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  &:focus {
    outline: none;
  }
`;

const ClearAllSelectedButton = ({ handleClearAllSelected }) => {
  return (
    <Button onClick={handleClearAllSelected}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12 4L4 12M4 4L12 12" stroke="#D92D20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      Clear All Selected
    </Button>
  );
};

export default ClearAllSelectedButton;
