import { agreementsConstants } from "@constants";

export function getAgreements(state = {}, action) {
    switch (action.type) {
        case agreementsConstants.LOAD_AGREEMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case agreementsConstants.LOAD_AGREEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case agreementsConstants.LOAD_AGREEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function getDetailAgreements(state = {}, action) {
    switch (action.type) {
        case agreementsConstants.GET_AGREEMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case agreementsConstants.GET_AGREEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case agreementsConstants.GET_AGREEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function getAllStandardChatTopics(state = {}, action) {
    switch (action.type) {
        case agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case agreementsConstants.ADD_STANDARD_CHAT_TOPICS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                isActive: action?.isActive,
                ParentChatTopicId: action?.ParentChatTopicId
            };
        case agreementsConstants.UPDATE_STANDARD_CHAT_TOPICS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                isActive: action?.isActive,
                ParentChatTopicId: action?.ParentChatTopicId
            };
        case agreementsConstants.DELETE_STANDARD_CHAT_TOPICS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                isActive: action?.isActive,
                ParentChatTopicId: action?.ParentChatTopicId
            };
        case agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};