// Med2labLogo.js
import React, { useContext } from 'react';
import imgLogo from '@images/common/med2lab_logo.png';
import ThemeContext from "@context/ThemeContext";
import { Link } from 'react-router-dom';

//Logo small
import LogoSmall from '@images/top/logo_med2lab_sm.svg';

const Med2labLogo = ({ isSidebarCollapsed }) => {
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo || imgLogo;
    return (
        <div className={`logo`}>
            <Link className={`${isSidebarCollapsed ? "small_logo" : "large_logo"}`} to={"/"}>
                <img
                    src={isSidebarCollapsed ? LogoSmall : primaryLogo}
                    alt="Med2lab Logo"
                    style={{ maxWidth: isSidebarCollapsed ? '72px' : '100%' }}
                />
            </Link>
        </div>
    );
};

export default Med2labLogo;