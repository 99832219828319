import React, { useEffect, useState, useCallback } from "react";

//lodash
import _ from "lodash";

//components
import { ModalHelper } from "@components/common/Helper";

//router
import { useLocation } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//action
import { actUpdateAssistantTipRecord } from "@actions";
import { ContactUs } from "@components/common/ContactUs";

//boostrap
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const DropdownHelper = (props) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!dropdownOpen);

  const location = useLocation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    modalHelper: false,
    modalContact: false,
  });
  const [showContent, setShowContent] = useState(false);
  const [stateAssistant, setStateAssistant] = useState([]);
  const [itemActive, setItemActive] = useState({});
  const [isClick, setIsClick] = useState(false);

  //props
  const themeData = props && props.themeData;

  useEffect(() => {
    if (!isClick) {
      setStateAssistant((props && props.data) || {});
    }
  }, [props, isClick]);

  const toggle = (status, type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    //check click
    if (status) {
      setIsClick(true);
      setDataList(themeData);
    } else {
      setIsClick(false);
    }
  };

  const toggleContact = (type) => {
    setModal({ modalHelper: false });
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  const setDataList = (data) => {
    const currentPath = location.pathname;
    const dataAssistant = data && data.Assistant && data.Assistant.Tips;

    //filter By slug
    const indexData = _.findIndex(dataAssistant, function (element) {
      return currentPath.includes(element.ViewCode);
    });

    if (indexData !== -1) {
      const dataAssistantByPath =
        dataAssistant[indexData] && dataAssistant[indexData].Messages;
      setStateAssistant(dataAssistantByPath);
    }
  };

  const handleChange = (value) => {
    setItemActive(value);
    setShowContent(!showContent);
    setIsClick(true);
    //Set Viewed
    const TipMessageId = value && value.TipMessageId;
    updateAssistantWithApi(TipMessageId);
  };

  const updateAssistantWithApi = useCallback(
    (TipMessageId) => {
      //Set Viewed
      const params = {
        Action: "UpdateTipRecord",
        TipMessageId: TipMessageId,
        Viewed: true,
        Autorun: false,
      };
      dispatch(actUpdateAssistantTipRecord(params));
    },
    [dispatch]
  );

  // onload data
  useEffect(() => {
    //If Autorun === true
    const checkData = _.filter(
      stateAssistant && stateAssistant,
      function (value) {
        return value.Autorun === true;
      }
    );

    if (checkData.length > 0 && !isClick) {
      setTimeout(() => {
        setModal(true);
      }, 2000);
    }

    if (stateAssistant.length === 1) {
      const messageId =
        stateAssistant && stateAssistant[0] && stateAssistant[0].TipMessageId;
      updateAssistantWithApi(messageId);
    }
  }, [stateAssistant, updateAssistantWithApi, isClick]);

  const handleItemClick = (type) => {
    switch (type) {
      case "modalHelper":
        toggle("isClick", "modalHelper")
        break;
      default:
        toggleContact("modalContact")
    }
  }

  return (
    <React.Fragment>
      <ButtonDropdown className="ml-2" isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle className={`btn-sm`} caret>
          <span className={`fa fa-question-circle mr-2`}></span>
          Help
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleItemClick("modalHelper")}>
            <span className="fa fa-question-circle mr-2"></span>FAQ / Tips
          </DropdownItem>
          <DropdownItem onClick={() => handleItemClick("modalContact")}>
            <span className="fa fa-paper-plane mr-2"></span>Contact Us
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <ModalHelper
        showContent={showContent}
        isOpen={modal.modalHelper}
        toggle={() => toggle("", "modalHelper")}
        data={stateAssistant}
        itemActive={itemActive}
        handleChange={handleChange}
      />
      <ContactUs
        {...props}
        isOpen={modal.modalContact}
        toggle={() => toggleContact("modalContact")}
      />
    </React.Fragment>
  );
};

export default DropdownHelper;
