import React from 'react';

// Icons
import IconMoney from '@images/coin/icon-money.svg';

const PurchaseCoin = (props) => {
    const { handleClickBack, handlePurchaseCoin } = props && props;
    return (
        <div className="purchase-coin">
            <div className="btn-back-coin" style={{ cursor: 'pointer' }} onClick={handleClickBack}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path
                        d="M19 12.5H5M5 12.5L12 19.5M5 12.5L12 5.5"
                        stroke="#1D2939"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
                <span>Purchase Credits</span>
            </div>
            <div className="purchase-list row">
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
                <div className="purchase-list__item col-md-4" onClick={handlePurchaseCoin}>
                    <div className="purchase-coin-ct">
                        <div className="purchase-coin-number">
                            <span>20</span>
                            <img width={22} src={IconMoney} alt="20 coin" />
                        </div>
                        <div className="price-of-coin">
                            10 USD
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchaseCoin;