import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

// common
import { HeadingBox } from "@components/common";

//components
import { LearnersReport, ContentPush } from "@components/reports/Learners";

//boostrap
import { Container, Row, Col } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import {
  actLoadPerformanceFeedbacks
} from "@actions";

function LearnersReportPage(props) {
  //useParams
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { caseDetails } = props && props;

  //state
  const [toggleContent, setToggleContent] = useState(false);
  const [dataUser, setDataUser] = useState({});
  const ref = useRef();
  useOnClickOutside(ref, () => setToggleContent(false));

  const handleContentPush = (value) => {
    setToggleContent(!toggleContent);
    setDataUser(value);
    if(value) {
      const params = {
        ReportType: "learner_CasePerformance",
        PerformanceId: value.PerformanceId,
      };
      dispatch(actLoadPerformanceFeedbacks(id, params));
    }
  };

  const listPerformanceFeedbacks = useSelector(
    (state) => (state.getPerformanceFeedbacks && state.getPerformanceFeedbacks.data) || {}
  );

  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -reportPage">
      <div className="reportCt">
        <Container>
          <Row>
            <Col sm={12}>
              <HeadingBox title={titlePage || ''} currentPage="Learners" />
              <LearnersReport handleContentPush={handleContentPush} />
            </Col>
          </Row>
        </Container>
        <div ref={ref}>
          <ContentPush
            ref={ref}
            isOpen={toggleContent}
            toggle={handleContentPush}
            dataUser={dataUser}
            data={listPerformanceFeedbacks}
          />
        </div>
      </div>
    </div>
  );
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export default LearnersReportPage;
