export const studiesConstants = {
    LOAD_STUDIES_REQUEST: 'LOAD_STUDIES_REQUEST',
    LOAD_STUDIES_SUCCESS: 'LOAD_STUDIES_SUCCESS',
    LOAD_STUDIES_FAILURE: 'LOAD_STUDIES_FAILURE',

    UPDATE_STUDIES_REQUEST: 'UPDATE_STUDIES_REQUEST',
    UPDATE_STUDIES_SUCCESS: 'UPDATE_STUDIES_SUCCESS',
    UPDATE_STUDIES_FAILURE: 'UPDATE_STUDIES_FAILURE',

    CREATE_STUDIES_REQUEST: 'CREATE_STUDIES_REQUEST',
    CREATE_STUDIES_SUCCESS: 'CREATE_STUDIES_SUCCESS',
    CREATE_STUDIES_FAILURE: 'CREATE_STUDIES_FAILURE',

    DELETE_STUDIES_REQUEST: 'DELETE_STUDIES_REQUEST',
    DELETE_STUDIES_SUCCESS: 'DELETE_STUDIES_SUCCESS',
    DELETE_STUDIES_FAILURE: 'DELETE_STUDIES_FAILURE',

    LOAD_ALL_STUDIES_FOR_ELEMENT_REQUEST: 'LOAD_ALL_STUDIES_FOR_ELEMENT_REQUEST',
    LOAD_ALL_STUDIES_FOR_ELEMENT_SUCCESS: 'LOAD_ALL_STUDIES_FOR_ELEMENT_SUCCESS',
    LOAD_ALL_STUDIES_FOR_ELEMENT_FAILURE: 'LOAD_ALL_STUDIES_FOR_ELEMENT_FAILURE',

    UPDATE_STUDIES_FOR_ELEMENTS_REQUEST: 'UPDATE_STUDIES_FOR_ELEMENTS_REQUEST',
    UPDATE_STUDIES_FOR_ELEMENTS_SUCCESS: 'UPDATE_STUDIES_FOR_ELEMENTS_SUCCESS',
    UPDATE_STUDIES_FOR_ELEMENTS_FAILURE: 'UPDATE_STUDIES_FOR_ELEMENTS_FAILURE',
};