import { reportConstant } from "@constants";
import { apiCaller } from "@utils";

//Report - Case - Learners Completed
export const actReportCaseLearnersCompleted = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.REPORT_CASE_COMPELETED_SUCCESS, isLoading } }
    function success(data) { return { type: reportConstant.REPORT_CASE_COMPELETED_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.REPORT_CASE_COMPELETED_FAILURE, error: error } }
}

// Report - Case - Learners Completed - DETAILS
export const actReportCaseLearnersDetails = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.REPORT_CASE_COMPELETED_DETAILS_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.REPORT_CASE_COMPELETED_DETAILS_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.REPORT_CASE_COMPELETED_DETAILS_FAILURE, error: error } }
}

//Load Performance Feedbacks
export const actLoadPerformanceFeedbacks = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_FAILURE, error: error } }
}

//Case Question Report
export const atcReportCaseQuestion = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.REPORT_QUESTION_RECORD_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.REPORT_QUESTION_RECORD_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.REPORT_QUESTION_RECORD_FAILURE, error: error } }
}

//Domain Report
export const atcReportDomain = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.REPORT_DOMAIN_RECORD_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.REPORT_DOMAIN_RECORD_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.REPORT_DOMAIN_RECORD_FAILURE, error: error } }
}

//Update GradingField
export const atcUpdateQuestionGradingField = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.UPDATE_QUESTION_GRADING_FIELD_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.UPDATE_QUESTION_GRADING_FIELD_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.UPDATE_QUESTION_GRADING_FIELD_FAILURE, error: error } }
}

// On-Boarding Report
export const atcOnBoardingReport = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/onboard/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.ON_BOARDING_REPORT_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.ON_BOARDING_REPORT_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.ON_BOARDING_REPORT_FAILURE, error: error } }
}

// Report - Case - Chat
export const loadReportChat = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/report/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: reportConstant.LOAD_CHAT_REPORT_REQUEST, isLoading } }
    function success(data) { return { type: reportConstant.LOAD_CHAT_REPORT_SUCCESS, payload: data } }
    function failure(error) { return { type: reportConstant.LOAD_CHAT_REPORT_FAILURE, error: error } }
}

//Load Topic
export const atcLoadTopic = (id, params) => {
    const isLoading = true;
    return (dispatch) => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) {
        return { type: reportConstant.LOAD_TOPIC_REQUEST, isLoading };
    }
    function success(data) {
        return { type: reportConstant.LOAD_TOPIC_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: reportConstant.LOAD_TOPIC_FAILURE, error: error };
    }
};