import React from "react";

//boostrap
import { Col } from "reactstrap";

// Chat common
import { Framechat } from "../GoldStandard";

//Avtar
import avatarUser from "@images/common/avatar-user-demo.png";

const ItemContents = (props) => {
    //props
    const { loggingItem } = props && props;
    const layoutChatGoldStandard = {
        user: {
            name: 'Prompt / Input:',
            image: avatarUser
        },
        assistant: {
            name: 'Assistant:',
            image: avatarUser
        }
    }
    return (
        <Col className="h-100" md={8} style={{ overflowY: 'auto' }}>
            <Framechat
                {...props}
                data={loggingItem?.item_content}
                layoutChat={layoutChatGoldStandard}
            />
        </Col>
    );
};

export default ItemContents;