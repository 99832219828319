import React from 'react';
import { AssignmentItem } from "@components/learner/Assignments/Home";

const OtherAssignmentsSection = ({ launchPlansList }) => {
    return (
        <section className="other-assignments mt-4">
            <div className="card-area bg-white">
                <div className="d-flex justify-content-between mb-4">
                    <h2 className="headline --size-6">Other assignments</h2>
                    <span className="link-see-all-sm">See all</span>
                </div>
                <div className="assignment-list">
                    {launchPlansList?.map((assignment, index) => (
                        <AssignmentItem key={index} assignment={assignment} fullWidth={true} borderStyle={true} showProgres={false} />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default OtherAssignmentsSection;