import React from 'react';
import { useSelector } from "react-redux";

//common
import { IconAnimationView } from '@components-common/IconAnimation';

function CongratulationsContent() {
    const { data: caseResponse } = useSelector((state) => state.cases || {});
    const EndCaseViewDetails = caseResponse?.Case?.EndCaseViewDetails || {};
    const { Title, Comments } = EndCaseViewDetails;

    const defaultContent = { title: 'Bravo! Case Completed!', comments: 'Congratulations, you’ve reached the finish line of the case. Well done on your amazing work!' }
    const convertToHTML = (text) => ({ __html: text });
    return (
        <div className="modal-inner">
            <div className="modal-inner__pic d-flex justify-content-center">
                <IconAnimationView nameAnimation={`vt0hUbkI1S`} />
            </div>
            <div className="modal-inner__desc text-center">
                <h2
                    className="headline --size-4 mb-4 text-center"
                    dangerouslySetInnerHTML={convertToHTML(Title || defaultContent.title)}
                />
                <div
                    dangerouslySetInnerHTML={convertToHTML(Comments || defaultContent.comments)}
                />
            </div>
        </div>
    );
}

export default CongratulationsContent;