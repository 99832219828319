import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { paginationOptions } from "@utils";
import { FormGroup, Input } from 'reactstrap';

function TableSelectRow(props) {
    const { stateData } = props;

    const columns = [
        {
            dataField: "id",
            text: "ID"
        },
        {
            dataField: "name",
            text: "Name"
        },
    ];

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        hideSelectAll: false,
        classes: "selection-row",
        selectionHeaderRenderer: ({ mode, checked, disabled, onChange }) => (
            <FormGroup check className="form-common">
                <Input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    name="checkbox"
                    className="react-checkbox-common react-checkbox-md"
                />
            </FormGroup>
        ),
        selectionRenderer: ({ mode, checked, disabled, onChange }) => (
            <FormGroup check className="form-common">
                <Input
                    type="checkbox"
                    checked={checked}
                    disabled={disabled}
                    onChange={onChange}
                    name="checkbox"
                    className="react-checkbox-common react-checkbox-md"
                />
            </FormGroup>
        ),
    };

    const contentTable = ({ paginationProps, paginationTableProps }) => {
        return (
            <ToolkitProvider
                keyField="id"
                data={stateData || []}
                columns={columns}
                search
            >
                {(toolkitprops) => {
                    return (
                        <div className="table-custom-common mt-4">
                            <div style={{ marginBottom: "16px" }}>
                                <SearchCustomForTable
                                    toolkitprops={toolkitprops}
                                    searchMode="button"
                                    placeholder={`Enter keywords...`}
                                    textButton={`Search`}
                                />
                            </div>
                            <BootstrapTable
                                selectRow={selectRow}
                                bordered={false}
                                noDataIndication={() => (
                                    <MessageEmtyData
                                        title="No Results Found"
                                        text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                                    />
                                )}
                                {...paginationTableProps}
                                {...toolkitprops.baseProps}
                            />
                            <div className="pagination-custom-case d-flex justify-content-end mt-4">
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </div>
                    );
                }}
            </ToolkitProvider>
        );
    };

    const totalSize = stateData?.length || 0;
    const paginationConfig = paginationOptions({ totalSize });

    return (
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
        </PaginationProvider>
    );
}

export default TableSelectRow;