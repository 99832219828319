import React, { useState, useCallback, useEffect } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import { atcLoadTipMessageById } from "@actions";

//lodash
import _ from 'lodash';

//components
import { ModalTipMessage } from "../Home";
import AccordionItem from "./AccordionItem";

const AccordionCard = (props) => {
  const dispatch = useDispatch();

  //props
  const { data, parent } = props && props;

  //state
  const [activeIndex, setActiveIndex] = useState({});
  const [idTipMessage, setIdTipMessage] = useState("");
  const [modal, setModal] = useState(false);

  //onload
  useEffect(() => {
    //Check active if Open === true
    const indexIsOpen = _.findIndex(data, function(o) { return o.Open; });
    const itemCurrent = `${parent}_${indexIsOpen + 1}`;
    setActiveIndex(() => ({
      [itemCurrent]: true
    }));
  }, [data, parent])

  //toggle Modal
  const toggle = () => {
    setModal(!modal);
  };

  //function handleTipMessage
  const handleTipMessage = useCallback(
    (e) => {
      var idTipMessageAttr = e.target.getAttribute("data-tipmessage");
      const params = {
        Action: "LoadTipMessage",
        TipMessageId: parseFloat(idTipMessageAttr),
      };
      dispatch(atcLoadTipMessageById(params));
      setIdTipMessage(parseFloat(idTipMessageAttr));
      setModal(true);
    },
    [dispatch]
  );

  const renderedQuestionsAnswers =
    data &&
    data.map((item, index) => {
      const itemCurrent = `${parent}_${index + 1}`;
      const showDescription =
        activeIndex[itemCurrent] === true && "show-description";
      const ariaExpanded = activeIndex[itemCurrent] === true ? "true" : "false";
      return (
        <AccordionItem
          showDescription={showDescription}
          ariaExpanded={ariaExpanded}
          item={item}
          indexItem={index}
          key={index}
          parent={itemCurrent}
          onClick={() => {
            setActiveIndex(() => ({
              [itemCurrent]: !activeIndex[itemCurrent],
            }));
          }}
        />
      );
    });

  const dataApi = useSelector(
    (state) => (state.getAssistantByid && state.getAssistantByid.data) || []
  );

  //handle Click Link Tip Message
  const elements = document.getElementsByClassName("eventTipmessage");
  Array.from(elements).forEach(function (element, index) {
    elements[index].addEventListener("click", handleTipMessage);
  });
  return (
    <React.Fragment>
      <div className="Acc">
        <dl className="Acc__list">{renderedQuestionsAnswers}</dl>
      </div>
      {dataApi && (
        <ModalTipMessage
          isOpen={modal}
          toggle={toggle}
          idTipMessage={idTipMessage}
          data={dataApi}
        />
      )}
    </React.Fragment>
  );
};

export default AccordionCard;
