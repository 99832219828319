import React, { useState } from "react";
import Select from 'react-select';
import { FormGroup, Label } from "reactstrap";

//truncate
import { truncate } from "@utils";

function QuestionFilter(props) {
    const { questionData, handleItem, handleChangeQuestion } = props && props;
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (data) => {
        const newData = { ...data };
        const questionId = newData?.value;
        newData.label = `Question #${questionId}`;
        setSelectedOption(newData);
        handleItem(newData.details);
        handleChangeQuestion(`QuestionId`, questionId);
    };

    const showLIstFlowConditions = (data) => {
        return (
            data?.map(function (val) {
                return (
                    `if #${val.QuestionId} is ${val.Status}`
                )
            })
        )
    }

    const convertDataReactSelect = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const flowConditionsList = item?.FlowConditions;
            const titleQuestionId = `<div class="d-flex my-2"><span class="--text-primary text-nowrap">Question #${item.QuestionId}</span>&nbsp; - &nbsp;<span>${truncate(item.QuestionContent, 30)}</span></div>`;
            const dataReturn = {
                value: item.QuestionId,
                label: `${titleQuestionId}`,
                followCondition: showLIstFlowConditions(flowConditionsList),
                details: item
            };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    }

    const dropdownQuestion = questionData && convertDataReactSelect(questionData);
    return (
        <FormGroup>
            <Label for="CohortId">Question: </Label>
            <Select
                closeMenuOnSelect={true}
                // menuIsOpen={true}
                onChange={handleChange}
                options={(dropdownQuestion && dropdownQuestion) || []}
                placeholder={`Select an item below...`}
                value={selectedOption}
                classNamePrefix="-reactSelectQuestion"
                getOptionLabel={
                    option => (
                        <React.Fragment>
                            <div dangerouslySetInnerHTML={{ __html: option?.label }} />
                            {option?.followCondition.map((opFollowCondition, key) =>
                                <div className="my-2 csFlowCondition" key={key}>
                                    {opFollowCondition}
                                </div>
                            )}
                        </React.Fragment>
                    )
                }
            />
        </FormGroup>
    );
}

export default QuestionFilter;
