import React from 'react';

//Image Default
import placehoder from "@images/common/no-avatar.jpg";

function AvatarRole() {
    return (
        <div className="avatarRole ml-3">
            <img className="avatarRole__img" src={`${placehoder}`} alt="avatar" />
        </div>
    );
};

export default AvatarRole;