import React from 'react';
import styled from 'styled-components';

const QuestionTabsContainer = styled.div`
  position: relative;
`;

const QuestionTabsList = styled.ul`
  display: flex;
  padding: 0 5px;
  list-style: none;
  overflow-x: auto;
  border-radius: 6px;
  background: #F2F4F7;
  margin-bottom: 25px;
`;

const QuestionTabItem = styled.li`
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.3s, color 0.3s;
  color: ${(props) => (props.active ? '#079455' : '#081B2A')};
  font-weight: ${(props) => (props.active ? '600' : '400')};
  background: ${(props) => (props.active ? '#ECFDF3' : 'transparent')};
  border-radius: ${(props) => (props.active ? '6px 6px 0 0' : '0')};
  border-bottom: ${(props) => (props.active ? '2px solid #079455' : 'none')};

  &:hover {
    background: ${(props) => (!props.active ? '#e0e4e8' : '')};
  }
`;

const QuestionTabLink = styled.span`
  text-decoration: none;
  white-space: nowrap; /* Prevents text wrapping */
`;

const QuestionTabContent = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

function TabsForQuestion({ tabs, activeTab, onTabChange, theme, classNames }) {
    return (
        <QuestionTabsContainer>
            <QuestionTabsList className={`${classNames || ''} ${theme || ''}`}>
                {tabs?.map((tab) => (
                    <QuestionTabItem
                        key={tab.id}
                        active={activeTab === tab.id}
                        onClick={() => onTabChange(tab.id)}
                    >
                        <QuestionTabLink>{tab.title}</QuestionTabLink>
                    </QuestionTabItem>
                ))}
            </QuestionTabsList>

            <div className="tab-content">
                {tabs?.map((tab) => (
                    <QuestionTabContent key={tab.id} active={activeTab === tab.id}>
                        {tab.content}
                    </QuestionTabContent>
                ))}
            </div>
        </QuestionTabsContainer>
    );
}

export default TabsForQuestion;