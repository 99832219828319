import React from 'react';

const LastTimePoint = (props) => {
    const { handleShowComponentEnding, endingPage } = props && props;
    return (
        <li className="timeList__item d-flex align-items-center">
            <span
                className={`d-flex align-items-center m--font-info presentationTime__name ${endingPage ? "active" : "not-sactive"}`}
                onClick={handleShowComponentEnding}
            >
                <span className="dot">•</span>
                Ending Page
            </span>
        </li>
    );
}

export default LastTimePoint;