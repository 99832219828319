import React, { useState, useEffect } from "react";
import { Iconsax } from "@components-common";

//redux
import { useSelector, useDispatch } from "react-redux";

//lodash
import _ from "lodash";

//Action
import { actUpdateTaskActive, actActiveViewQuestion } from "@actions";

// gradingSchemaOptions
import FormatQuestionType from "../DefineParams/FormatQuestionType";

const ListQuestionForTask = ({ indexItem, task, activeViewClass, timepointData, handleOpenViewCreateQuestion }) => {
  let dispatch = useDispatch();

  //Redux store
  let taskActive = useSelector((state) => state?.statusTaskActive?.currentTask || {});

  //state
  const [collapsedViewClass, setCollapsedViewClass] = useState(taskActive);

  useEffect(() => {
    setCollapsedViewClass(taskActive);
  }, [taskActive]);

  const isCollapsed = task.ViewClass !== collapsedViewClass;

  // Nhưng task cần hiển thị question
  const hasQuestion = ["history", "chat", "exam", "orders", "pn", "documentation", "actions", "advance"].includes(task.ViewClass);

  const isActive = collapsedViewClass === task.ViewClass;
  //Filter Question by Task
  const filterQuestionForTask = (currentTask) => {
    //filter Question AskedAtTaskee
    const filterData = _.filter(timepointData?.Questions,
      function (value) {
        return value.AskedAtTask === currentTask;
      }
    );
    return filterData;
  };

  // Handle Active Task
  const handleClickTask = () => {
    dispatch(actUpdateTaskActive(task.ViewClass));

    //Reset View
    dispatch(actActiveViewQuestion(null));
  }

  const questionByTask = filterQuestionForTask(activeViewClass);
  return (
    <div key={indexItem} className={`task-card ${isCollapsed ? 'not-active' : (isActive ? 'active' : '')}`}>
      <div className={`task-card-head`} is-task={task.ViewClass} onClick={handleClickTask}>
        <h3 className="task-card-head__title dot-item">{task.Label}</h3>
      </div>
      {/* Hidden */}
      {!isCollapsed && hasQuestion && 1 === 2 && (
        <div className="task-card__body">
          <div className="task-list-inner">
            {questionByTask?.map((item, index) => (
              <div key={index} className="task-list-inner__item">
                <div className="left-item">
                  <Iconsax iconName={`question-circle`} fill="#1C274C" size={15} />
                  <h3 className="left-item__title">Question {item.QuestionId}</h3>
                </div>
                <div className="right-item ml-auto">
                  {item.QuestionType &&
                    <div className="count-question">{FormatQuestionType(item.QuestionType)}</div>
                  }
                </div>
              </div>
            ))}
          </div>
          <div className="btn-add-item --border-blue --size-sm" onClick={handleOpenViewCreateQuestion}>
            <Iconsax iconName="add-point" fill="#17475C" size={15} />
            <span className="ml-2 btn-add-item__text">Add question</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListQuestionForTask;