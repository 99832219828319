import React from "react";

function DisagreementThemes(props) {
    const { analyzeReviews } = props; //handleClickDisagreementThemes
    const disagreement_themes = analyzeReviews?.disagreement_themes || [];

    return (
        <div>
            {disagreement_themes?.length > 0 && (
                <React.Fragment>
                    <h2 className="text-2">Disagreement Themes:</h2>
                    <div className="mt-3">
                        {disagreement_themes?.map((theme, index) => (
                            <div key={index} className="d-flex justify-content-between py-3 border-bottom">
                                <div className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: theme }} />
                                {/* <span className="--text-primary" style={{ cursor: 'pointer', whiteSpace: 'nowrap' }} onClick={handleClickDisagreementThemes}>[12 items]</span> */}
                            </div>
                        ))}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default DisagreementThemes;