import { elementsConstants, actionConstants, medicationConstants, storyboardConstants } from "@constants";

//lodash
import _ from 'lodash';

export function dataIntakeOutput(state = {}, action) {
    switch (action.type) {
        case elementsConstants.LOAD_IODATA_FOR_ELEMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_IODATA_FOR_ELEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_IODATA_FOR_ELEMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadCaseByIdForElement(state = {}, action) {
    switch (action.type) {
        case elementsConstants.LOAD_CASE_FOR_ELEMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_CASE_FOR_ELEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_CASE_FOR_ELEMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function listLabsForElement(state = {}, action) {
    switch (action.type) {
        case elementsConstants.LOAD_LABS_FOR_ELEMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_LABS_FOR_ELEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_LABS_FOR_ELEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadChatTopics(state = {}, action) {
    switch (action.type) {
        case elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.DELETE_CHAT_TOPIC_IN_CASE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.DELETE_CHAT_TOPIC_FREE_TEXT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function listStandardChatTopics(state = {}, action) {
    switch (action.type) {
        case elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function listMedicationsForElement(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case elementsConstants.LOAD_ALL_CASE_MEDICATIONS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_ALL_CASE_MEDICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case medicationConstants.CREATE_MEDICATION_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case medicationConstants.UPDATE_MEDICATION_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.CREATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.UPDATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.CREATE_EFFECTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.UPDATE_EFFECT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case medicationConstants.DELETE_MEDICATION_CATEGORY_SUCCESS:
            newState = { ...state };
            const pathName = window.location.pathname.includes("/elements/medications");
            const categoryIdCurrent = action.categoryId;
            const listDataClone = _.cloneDeep(newState?.data);
            if ((action.payload && action.payload.status === 'Success') && pathName) {
                for (var i = 0; i < listDataClone.length; i++) {
                    const newData = _.filter(listDataClone[i]?.Medications, value => Number(value.CategoryId) !== Number(categoryIdCurrent));
                    listDataClone[i].Medications = newData;
                }
                //Update
                newState.data = listDataClone;
            }
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_INTERVENTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.DELETE_EFFECTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_ALL_CASE_MEDICATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function listActionsForElement(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case elementsConstants.LOAD_ALL_CASE_ACTIONS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case elementsConstants.LOAD_ALL_CASE_ACTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case actionConstants.CREATE_ACTION_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case actionConstants.UPDATE_ACTION_CATEGORY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case actionConstants.CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case actionConstants.UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.CREATE_EFFECTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.UPDATE_EFFECT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case actionConstants.DELETE_ACTION_CATEGORY_SUCCESS:
            newState = { ...state };
            const pathName = window.location.pathname.includes("/elements/actions");
            const categoryIdCurrent = action.categoryId;
            const listDataClone = _.cloneDeep(newState?.data);
            if ((action.payload && action.payload.status === 'Success') && pathName) {
                for (var i = 0; i < listDataClone.length; i++) {
                    const newData = _.filter(listDataClone[i]?.Actions, value => Number(value.CategoryId) !== Number(categoryIdCurrent));
                    listDataClone[i].Actions = newData;
                }
                //Update
                newState.data = listDataClone;
            }
            return {
                ...newState,
                isLoading: false
            };
        case actionConstants.DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.DELETE_EFFECTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case elementsConstants.LOAD_ALL_CASE_ACTIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};