import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import { getReferenceMedia } from "@actions";
import { MessageEmtyData } from "@ui-partents/Message";
import { useHistory } from "react-router-dom";

// Import the pagination configuration
import {paginationOptions} from "@utils";

//partents
import { SortCaret } from "@ui-partents/Table";

const ModalListMedia = (props) => {
  const { isOpen, toggle, setMediaId } = props;
  const { SearchBar } = Search;
  let history = useHistory();
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.getReferenceMedia) || {};
  const [selectedBooks, setSelectedBooks] = useState([]);

  useEffect(() => {
    dispatch(getReferenceMedia())
  }, [dispatch])

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middlefont-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => <SortCaret order={order} />,
      editable: false,
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
          </div>
        );
      },
    },
  ]

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    hideSelectColumn: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedBooks([row]);
      } else {
        setSelectedBooks(selectedBooks.filter((book) => book.id !== row.id));
      }
    },
    // onSelectAll: (isSelect, rows, e) => {
    //   if (isSelect) {
    //     const selected = rows.slice(0, 5);
    //     setSelectedBooks(selected);
    //     if (rows.length > 5) {
    //       showToast("Please select up to 5 documents at a time.");
    //     }
    //   } else {
    //     setSelectedBooks([]);
    //   }
    // },
    selected: selectedBooks.map(book => book.id)
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="id"
          data={documents?.Combined || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }} />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-books"
                data={documents?.Combined || []}
                columns={columns}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  const handleClose = () => {
    setSelectedBooks([]);
    toggle()
  }

  const paginationConfig = paginationOptions({ totalSize: documents?.Combined.length || 0 });
  return (
    <Modal centered={true} isOpen={isOpen} toggle={handleClose} backdrop={`static`} style={{ maxWidth: '830px' }} className='modal-scenario'>
      <ModalHeader toggle={handleClose}></ModalHeader>
      <ModalBody className="p-3">
        {documents?.Combined.length ? (
          <>
            <div className="title-scenario">
              <h1>Select Media from Your Media Library</h1>
              <p>Please choose only 1 media file for generation. Use the search bar to find your media.</p>
            </div>
            <PaginationProvider pagination={paginationFactory(paginationConfig)}>
              {contentTable}
            </PaginationProvider>
          </>
        ) : (
          <MessageEmtyData
            title="Your List is Empty"
            text="It looks like you haven't uploaded any reference documents yet. Click ‘Go to Reference Library’ and upload your document to add new reference materials."
          />
        )}
      </ModalBody>
      {documents?.Combined.length ? (
        <ModalFooter>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn d-flex align-items-center"
            color="primary"
            onClick={() => {
              setMediaId(selectedBooks);
              handleClose();
            }}
            disabled={selectedBooks.length === 0}
          >
            Confirm
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter className='footer-empty'>
          <Button
            className="btn"
            color="primary"
            onClick={() => history.push("/teach/reference-media")}
          >
            Go to Media Library
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Back
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalListMedia;