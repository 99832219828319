import React, { useState } from "react";
import { ModalResetCase } from '@components/common/ResetAndBack';

//reactstrap
import { Button } from "reactstrap";

function ResetCaseButton(props) {
    //props
    const { isMobile, fullWidth } = props && props;

    //state
    const [isResetCaseOpen, setIsResetCaseOpen] = useState(false);

    const toggleResetCase = () => {
        setIsResetCaseOpen(!isResetCaseOpen);
    };

    const buttonSize = isMobile ? `50px` : "auto";
    const buttonStyle = {
        display: "flex",
        padding: isMobile ? "6px" : "14px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: isMobile ? "50%" : "8px",
        border: "1px solid #D0D5DD",
        background: "#FFF",
        width: buttonSize,
        height: buttonSize,
        position: isMobile ? "absolute" : "static",
        right: isMobile ? "0" : "auto",
        top: isMobile ? "50%" : "auto",
        transform: isMobile ? "translateY(-50%)" : "none",
        margin: isMobile ? "unset !important" : undefined, // Only applies for mobile
        maxWidth: "170px", // max width of the button
    };

    const iconColor = "#F04438"; // Set icon color to match SVG color

    const textButtonStyle = {
        color: "#475467",
        fontSize: "16px",
        fontWeight: 500,
        display: isMobile ? "none" : "inline-block", // Hide text on mobile
    };

    return (
        <React.Fragment>
            <Button
                className={`btn btn-reset-case mr-2 ${fullWidth ? "mw-100" : ''}`}
                style={buttonStyle}
                onClick={toggleResetCase}
                color="default"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19" fill="none">
                    <path d="M6 7H1C0.4 7 0 6.6 0 6V1C0 0.4 0.4 0 1 0C1.6 0 2 0.4 2 1V5H6C6.6 5 7 5.4 7 6C7 6.6 6.6 7 6 7Z" fill={iconColor} />
                    <path d="M8.0002 19.0002C6.9002 19.0002 5.8002 18.8002 4.8002 18.4002C3.0002 17.7002 1.5002 16.5002 0.400195 14.9002C0.100195 14.4002 0.200195 13.8002 0.700195 13.5002C1.2002 13.2002 1.8002 13.3002 2.1002 13.8002C2.9002 15.0002 4.1002 16.0002 5.5002 16.5002C6.9002 17.0002 8.4002 17.1002 9.9002 16.7002C11.3002 16.3002 12.6002 15.4002 13.5002 14.2002C14.4002 13.0002 14.9002 11.6002 15.0002 10.1002C15.0002 8.6002 14.6002 7.10019 13.7002 5.9002C12.8002 4.7002 11.6002 3.8002 10.2002 3.3002C8.8002 2.8002 7.3002 2.8002 5.8002 3.3002C4.4002 3.8002 3.2002 4.7002 2.3002 5.9002C2.0002 6.4002 1.4002 6.5002 0.900195 6.20019C0.400195 5.90019 0.300195 5.2002 0.700195 4.8002C1.8002 3.2002 3.4002 2.0002 5.2002 1.4002C7.0002 0.800195 9.0002 0.800195 10.8002 1.4002C12.6002 2.0002 14.2002 3.2002 15.3002 4.8002C16.4002 6.4002 17.0002 8.2002 16.9002 10.2002C16.9002 12.1002 16.2002 14.0002 15.0002 15.5002C13.8002 17.0002 12.2002 18.1002 10.3002 18.7002C9.7002 18.9002 8.8002 19.0002 8.0002 19.0002Z" fill={iconColor} />
                </svg>
                <span style={textButtonStyle}>Reset Case</span>
            </Button>
            <ModalResetCase
                {...props}
                isOpen={isResetCaseOpen}
                toggle={toggleResetCase}
                isView="case"
            />
        </React.Fragment>
    );
}

export default ResetCaseButton;