import React from "react";
import { Iconsax } from "@components-common";

function PopperItem(props) {
    const { fontSizeContent, content, styleInline, postition, isOpen, icon, handleClosePopper, buttonClose, alignItems = 'center', divElement } = props;
    
    // Xác định lớp align-items dựa trên alignItems prop
    const alignItemsClass = `align-items-${alignItems}`;
    // Tạo style cho phần tử nội dung
    const contentStyle = {
        fontSize: fontSizeContent || '16px'
    };
    return (
        <div 
            className={`tutorial-component ${divElement} ${alignItemsClass} ${postition} ${isOpen ? "wow fadeInUp animated d-flex" : "hide animated"}`} 
            style={styleInline}
        >
            {icon &&
                <div className="tutorial-component__icon">
                    {icon}
                </div>
            }
            {content && (
                <div style={contentStyle} className="tutorial-component__content whiteSpace-break scrollbarStyle-small pr-3" dangerouslySetInnerHTML={{ __html: content }} />
            )}
            {buttonClose && (
                <div className="tutorial-component__close" onClick={handleClosePopper}>
                    <Iconsax iconName="close" fill="#F1800F" size={21} />
                </div>
            )}
        </div>
    );
}

export default PopperItem;