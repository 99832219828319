import React from 'react';

//components
import { ButtonClosePanel } from "@components/learner/StandardScreen";

const ChatPanel = (props) => {
    // props
    const { stateType, children, isCurrentTask } = props && props;
    const divId = stateType?.question ? "is-view-question" : "is-view-patient";
    return (
        <React.Fragment>
            <ButtonClosePanel
                {...props}
                isCurrentTask={stateType?.question ? "question" : isCurrentTask}
            />
            <div id={divId} className="frameChat">
                {children}
            </div>
        </React.Fragment>
    );
}

export default ChatPanel;