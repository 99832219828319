import React from 'react';

function UserMessage(props) {
    const { value, userAvatar } = props && props;
    return (
        <div className={`messengerCt__wrapper`}>
            <div className="messengerCt__message messengerCt__message--out">
                <div className="messengerCt__message-body">
                    <div className="messengerCt__message-content --arrow-right">
                        <div className="messengerCt__message-text">
                            {value?.Type === "multiple_choice" && (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: value.Content }} />
                            )}
                            {value?.Type === "free_text" && (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: value?.Content }} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="messengerCt__message-pic">
                    <img src={userAvatar} alt="User" />
                </div>
            </div>
        </div>
    );
};

export default UserMessage;