import React from "react";

//components
import { SetNewPasswordForm } from "@components/auth/ResetPassword";
import { AuthLogoHeader } from "@components/auth";

function SetNewPasswordPage(props) {
    return (
        <div className="wrapper -loginPageV2">
            <AuthLogoHeader {...props} />
            <SetNewPasswordForm {...props} />
        </div>
    );
}

export default SetNewPasswordPage;