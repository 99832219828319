import React, { useState } from 'react';

//reactstrap
import { Input, FormGroup } from "reactstrap";

function InputFreeText(props) {
    const { handleInput, disabled, isStreaming } = props && props;

    //state
    const [value, setValue] = useState('');

    //send Api
    const handleSubmit = () => {
        handleInput(value);

        //reset
        setValue('');
    }

    //handlechange
    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    //Handle Key Press
    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !isStreaming) {
            handleSubmit();
        }
    }

    return (
        <div className="freeTextTyping p-3">
            <div className="freeTextTyping__input">
                <FormGroup className="mb-0" controlId="answer">
                    <Input
                        name="answer"
                        placeholder={`Send a message...`}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={value}
                        disabled={disabled}
                        autoComplete="off"
                    />
                </FormGroup>
            </div>
            <button
                type="button"
                className="freeTextTyping__btn btn btn-primary px-4 d-flex align-items-center justify-content-center"
                disabled={isStreaming || disabled}
                onClick={handleSubmit}
            >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
                <span className="text-nowrap freeTextTyping__text ml-2">Send</span>
            </button>
        </div>
    );
};

export default InputFreeText;