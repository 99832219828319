import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actUpdateLabChange } from "@actions";

//components
import ItemPanel from "./ItemPanel.jsx";
import ItemChildren from "./ItemChildren";

function ItemLabs(props) {

  //props
  const { data, toggleScore, timePointId, handleChangeCheckbox } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();

  // Add Field Collapse
  const addIsCollapseField = (data) => {
    return data?.map(item => ({
      ...item,
      isCollapse: false
    }));
  }

  //state
  const [stateData, setData] = useState(addIsCollapseField(data?.Panels || []));

  useEffect(() => {
    setData(addIsCollapseField(data?.Panels || []));
  }, [data]);

  //handleChange
  const handleChange = (e, index, IdItem) => {
    const { value, name } = e.target;
    const newState = [...stateData];
    const indexItems =
      newState[index] &&
      newState[index].Items.findIndex((x) => x.Id === IdItem);
    const filterItems = newState[index].Items[indexItems];
    if (filterItems.DefaultChange) {
      filterItems.DefaultChange[name] = value;
      setData(newState);
    }
  };

  // Handle Save
  const handleSave = (item, index) => {
    const itemsArr = item.Items;
    const itemLab =
      itemsArr && itemsArr[index] && itemsArr[index].DefaultChange;
    const idLab = itemsArr && itemsArr[index] && itemsArr[index].Id;
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      LabChange: {
        Id: idLab,
        ChangeMode: itemLab && itemLab.ChangeMode,
        ChangeValue: itemLab && itemLab.ChangeValue,
        ChangeLimit: itemLab && itemLab.ChangeLimit,
      },
    };
    dispatch(actUpdateLabChange(id, params));
  };

  // Handle Collaps Panel
  const handleCollapseToggle = (index) => {
    setData(prevData => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], isCollapse: !newData[index].isCollapse };
      return newData;
    });
  };

  return (
    <table className="table" id="lab_table">
      <tbody key={Math.random()}>
        {stateData?.map((value, index) => (
          <React.Fragment key={index}>
            <ItemChildren
              {...props}
              handleChange={handleChange}
              handleSave={handleSave}
              items={value?.Items || []}
              valuePanel={value}
              indexPanel={index}
              key={Math.random()}
            >
              {/* PANEL */}
              <ItemPanel
                {...props}
                toggleScore={toggleScore}
                handleChangeCheckbox={handleChangeCheckbox}
                item={value}
                key={Math.random()}
                handleCollapseToggle={handleCollapseToggle}
                indexItem={index}
              />
            </ItemChildren>
            {value?.isCollapse && (
              <tr>
                <td className="text-center" colSpan="8">
                  <button
                    className="btn btn-brand"
                    onClick={() =>
                      props && props.toggle({}, value.Id, "add-lab-item")
                    }
                  >
                    <i className="fa fa-plus"></i>&nbsp;Add Item
                  </button>
                </td>
              </tr>
            )}
            {/* ITEM */}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default ItemLabs;