import {
    BillSummaryPage,
    PurchaseCoinPage,
    PaymentMessagePage
} from '../../pages/med2lab/PaymentGateway';

const paymentRoutes = [
    // plan
    {
        path: "/bill-summary",
        exact: true,
        classPage: '',
        component: BillSummaryPage,
        isLayout: true,
    },
    {
        path: "/purchase-coin",
        exact: true,
        classPage: '',
        component: PurchaseCoinPage,
        isLayout: true,
    }, {
        path: "/set-up-payment/:slug",
        exact: true,
        classPage: '',
        component: PaymentMessagePage,
        isLayout: true,
    }
];

export { paymentRoutes };

