import React, { useRef, useState, useEffect } from 'react';
import JoditEditor from "jodit-react";

// reactBootstrap
import {
    FormGroup,
    Label
} from "reactstrap";

//Media Upload
// import { MediaUpload } from "../../QuestionCommon";

// Config
import * as Config from "@constants/Config";

function QuesionText(props) {
    const { questionData, handleChangeQuestionContent } = props;
    const refDiv = useRef(null);
    const [questionContent, setQuestionContent] = useState("");

    useEffect(() => {
        // Update questionContent state when questionData.QuestionContent changes
        if (questionData && questionData.QuestionContent) {
            setQuestionContent(questionData.QuestionContent);
        }
    }, [questionData]);

    const handleEditorChange = (newContent) => {
        if (handleChangeQuestionContent) {
            handleChangeQuestionContent(newContent);
        }
        setQuestionContent(newContent); // Update questionContent state
    };

    return (
        <FormGroup>
            <Label className="labeForm" for="Lastname">
                Question Text <span className="m--font-danger">*</span>
            </Label>
            <JoditEditor
                ref={refDiv}
                value={questionContent}
                config={Config.CONFIG_JODIT}
                placeholder="Enter your text here..."
                tabIndex={1}
                onChange={handleEditorChange}
            />
            {/* <MediaUpload {...props} /> */}
        </FormGroup>
    );
};

export default QuesionText;