import React from 'react';
import styled from "styled-components";
import ReactECharts from 'echarts-for-react';

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  p {
    font-size: 16px;
    font-weight: 600;
  }
`

const ChartStatistics = (props) => {
  const { dataStatistics, title } = props && props;

  const option = {
    xAxis: dataStatistics?.xAxis,
    yAxis: dataStatistics?.yAxis,
    series: dataStatistics?.series
  };

  return (
    <Wrapper>
      <ReactECharts style={{ height: '250px'}} option={option} />
      <p>{title}</p>
    </Wrapper>
  )
}

export default ChartStatistics