import React from 'react'
import { Icon } from '@components/common'
import Mcq from '@images/teachV2/mcq.svg'
import CaseScript from '@images/teachV2/case-script.svg'
import Interactive from '@images/teachV2/interactive.svg'
// import Decrease from '@images/common/arrow-down.svg'
// import Increase from '@images/common/arrow-up.svg'
import { useDispatch, useSelector } from "react-redux";
import { getDetailedMcqUsage } from "@actions"
import moment from 'moment'

const ListCard = (props) => {
  const { chartActive, setChartActive, startDate, endDate } = props
  const dispatch = useDispatch();
  const mcqUsage = useSelector((state) => state.getMcqUsage.data || []);

  const handleActiveUsage = (type) => {
    setChartActive(type)
    dispatch(getDetailedMcqUsage({
      "action_type": "detailed_usage",
      "tab_name": type,
      "start_date": moment(startDate).format("MM/DD/YYYY 0:0:0"),
      "end_date": moment(endDate).format("MM/DD/YYYY 23:59:0"),
    }))
  }

  return (
    <div className='list-card'>
      <div 
        className={`list-card__detail ${chartActive === "mcq_usage" && 'active'}`}
        onClick={() => handleActiveUsage("mcq_usage")}
      >
        <div className='list-card__detail-header'>
          <div className='header-icon'>
            <Icon src={Mcq}/>
          </div>
          <p className='header-title'>Multiple Choice Question</p>
        </div>
        <div className='list-card__detail-info'>
          <div className='info-amount'>{mcqUsage?.mcq_usage?.account_cnt} 
            <span> {mcqUsage?.mcq_usage?.account_cnt > 1 ? "accounts" : "account"}</span>
          </div>
          {/* <p className='info-percent increase'>
            <Icon src={Increase}/>
            <span>12%</span>
          </p> */}
        </div>
      </div>
      <div 
        className={`list-card__detail ${chartActive === "case_usage" && 'active'}`}
        onClick={() => handleActiveUsage("case_usage")}
      >
        <div className='list-card__detail-header'>
          <div className='header-icon'>
            <Icon src={CaseScript}/>
          </div>
          <p className='header-title'>Case Scenario</p>
        </div>
        <div className='list-card__detail-info'>
          <div className='info-amount'>{mcqUsage?.case_usage?.account_cnt} 
            <span> {mcqUsage?.case_usage?.account_cnt > 1 ? "accounts" : "account"}</span>
          </div>
          {/* <p className='info-percent increase'>
            <Icon src={Increase}/>
            <span>12%</span>
          </p> */}
        </div>
      </div>
      <div 
        className={`list-card__detail ${chartActive === "module_usage" && 'active'}`}
        onClick={() => handleActiveUsage("module_usage")}
      >
        <div className='list-card__detail-header'>
          <div className='header-icon'>
            <Icon src={Interactive}/>
          </div>
          <p className='header-title'>Interactive Module</p>
        </div>
        <div className='list-card__detail-info'>
          <div className='info-amount'>{mcqUsage?.module_usage?.account_cnt} 
            <span> {mcqUsage?.module_usage?.account_cnt > 1 ? "accounts" : "account"}</span>
          </div>
          {/* <p className='info-percent decrease'>
            <Icon src={Decrease}/>
            <span>12%</span>
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default ListCard
