import React from 'react';
import styled from 'styled-components';
import topTaskIcon from '@images/learnerv2/icon-top-task-sp.svg';

//components
import { MedicalInfo } from ".";

const ChatPopupWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 80%;
    padding: 8px 8px;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    flex-direction: column;
    background-color: #7CD9FD;
    border-radius: 12px;
`;

const InnerWrapper = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;
    background: #E9F7FE;
    width: 100%;
    border-radius: 12px;
    box-shadow: 3px 4px 0px 0px #5197C8;
    position: relative;

    &:before {
        content: "";
        background-image: url(${topTaskIcon});
        width: 137px;
        height: 53px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -35px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }
`;

const ChatPopup = () => {
    return (
        <ChatPopupWrapper>
            <InnerWrapper>
                <MedicalInfo />
            </InnerWrapper>
        </ChatPopupWrapper>
    );
};

export default ChatPopup;