import React from "react";

//Form
import { AIGenerationFoodForm } from "@components/Makansafe/AIGenerationFood";

function AIGenerationFoodPage(props) {
    return (
        <div className="makansafe-homepage">
            <div className="boxPortlet h-100">
                <AIGenerationFoodForm />
            </div>
        </div>
    );
}

export default AIGenerationFoodPage;