import React from "react";

//boostrap
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';

function Filter(props) {
    //props
    const { handleChange, filter, defaultFilter } = props || {};
    return (
        <Row className="align-items-center mb-4">
            <Col md={``} sm={`12`} className="text-left text-nowrap mb-sm-3 mb-md-0">
                <Label className="font-weight-600 mb-0" for="StatusFilter">Filter by: </Label>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="gender">Gender: </Label>
                    <Input type="select" name="gender" value={filter.gender} onChange={(e) => handleChange(e, 'gender')}>
                        <option value="">All</option>
                        {defaultFilter?.gender?.map((option) => (
                            <option value={option} key={option}>
                                {option}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="roletype">Role: </Label>
                    <Input type="select" name="roletype" value={filter.roletype} onChange={(e) => handleChange(e, 'roletype')}>
                        <option value="">All</option>
                        {defaultFilter?.roletype?.map((option) => (
                            <option value={option} key={option}>
                                {option}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
            <Col md={`3`} sm={`6`}>
                <FormGroup className="d-flex align-items-center mb-0 mt-3 mt-md-0">
                    <Label className="font-weight-600 mb-0 mr-2" for="level">Level:</Label>
                    <Input type="select" name="select" value={filter.level} onChange={(e) => handleChange(e, 'level')}>
                        <option value="">All</option>
                        {defaultFilter?.level?.map((option) => (
                            <option value={option} key={option}>
                                {option}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Col>
        </Row>
    );
}

export default Filter;