import React from "react";
import { Alert } from "reactstrap";

function AnswerContent(props) {
  const { QuestionContent } = props && props;
  return (
    <Alert color="secondary" className="m-alert--default my-0 border-0 pr-5">
      <p><strong>Question:</strong></p>
      <div className="d-flex align-items-center">
        <p className={`whiteSpace-break`} dangerouslySetInnerHTML={{ __html: QuestionContent || "" }}></p>
      </div>
    </Alert>
  );
}

export default AnswerContent;