import React, { createContext, useContext, useState, useEffect } from 'react';

const LockScrollBodyContext = createContext();

export function LockScrollBodyProvider({ children }) {
    const [isBodyLocked, setIsBodyLocked] = useState(false);

    // Hàm để khoá scroll body
    const lockBodyScroll = () => {
        setIsBodyLocked(true);
    };

    // Hàm để mở khóa scroll body
    const unlockBodyScroll = () => {
        setIsBodyLocked(false);
    };

    useEffect(() => {
        // Thêm class 'body-locked' khi isBodyLocked là true
        if (isBodyLocked) {
            document.body.classList.add('body-locked');
        } else {
            // Xoá class 'body-locked' khi isBodyLocked là false
            document.body.classList.remove('body-locked');
        }
    }, [isBodyLocked]);

    return (
        <LockScrollBodyContext.Provider value={{ isBodyLocked, lockBodyScroll, unlockBodyScroll }}>
            {children}
        </LockScrollBodyContext.Provider>
    );
}

export function useLockScrollBody() {
    return useContext(LockScrollBodyContext);
}