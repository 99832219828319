import React, { useState, useEffect } from "react";
import gradingSchemaOptions from "./GradingSchemaList";
import { FormGroup, Label, Col, Input } from "reactstrap";

function GSFreeText({ itemQuestion, handleChangeSchema, questionData }) {
    // State để lưu trữ Grading Schema
    const [gradingSchema, setGradingSchema] = useState(itemQuestion?.GradingSchema || questionData?.GradingSchema);

    // Thực hiện side effect khi nhận dữ liệu questionData.GradingSchema
    useEffect(() => {
        if(itemQuestion?.GradingSchema) {
            setGradingSchema(itemQuestion?.GradingSchema);
        }
    }, [itemQuestion?.GradingSchema]);

    // Xử lý khi người dùng thay đổi Grading Schema
    const handleSchemaChange = (value) => {
        setGradingSchema(value);
        handleChangeSchema(value); // Gọi hàm handleChangeSchema truyền từ props
    };

    return (
        <Col md={3}>
            <FormGroup>
                <Label className="labeForm">Grading Schema:</Label>
                {gradingSchemaOptions?.map((item, index) => (
                    <FormGroup check className="mb-2" key={index}>
                        <Label check>
                            <Input
                                type="radio"
                                onChange={() => handleSchemaChange(item?.value)}
                                defaultChecked={gradingSchema === item?.value}
                                name="GradingSchema"
                            />
                            {item?.label}
                        </Label>
                    </FormGroup>
                ))}
            </FormGroup>
        </Col>
    );
}

export default GSFreeText;