import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

//lodash
import _ from "lodash";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcUpdateQuestionGradingField } from "@actions";

function GradingFieldsModal (props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  const { isOpen, toggle, data, itemActive, paramsActive } = props && props;
  const { Performances } = data && data;
  const dataTable = Performances && Performances.Details;
  const { GradingFields } = data && data;

  //state
  const [arrNew, setArrNew] = useState([]);

  let columns = [
    {
      dataField: "Username/Email",
      text: "Username/Email",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
    },
    {
      dataField: "Answer",
      text: "Answer",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
      formatter: (value, row) => {
        return (
          <div
            className="answerText"
            dangerouslySetInnerHTML={{ __html: value }}
          ></div>
        );
      },
      style: {
        width: "350px",
      },
    }
  ];

  useEffect(() => {
    const { GradingFields } = data && data;
    const convertColumnsData = () => {
      const test = GradingFields?.map((value) => ({
        dataField: value.Name,
        text: value.Name,
        headerClasses: "align-middle text-left font-weight-500",
        GradingFieldId: value.GradingFieldId,
      }));
      return test;
    };
    const newData = convertColumnsData();
    if(newData && newData.length > 0) {
      setArrNew(newData);
    }
  }, [data]);

  //Edit Cell With API
  const afterSaveCell = (oldValue, newValue, row, column) => {
    let filtered_array = _.filter(GradingFields, ["Name", column.dataField]);
    const filterGradingFieldId = filtered_array && filtered_array[0] && filtered_array[0].GradingFieldId;
    const params = {
      ReportType: "question_Records",
      Action: "Update",
      Target: "GradingField",
      SessionId: row["SessionId"],
      QuestionId: (itemActive && itemActive.QuestionId) || "",
      CohortId: paramsActive?.CohortId,
      PeriodId: paramsActive?.PeriodId,
      Email: row["Username/Email"],
      GradingFieldId: filterGradingFieldId,
      Value: newValue,
    };
    dispatch(atcUpdateQuestionGradingField(id, params));
  };

  const columnsMerge = [...columns, ...arrNew];
  return (
    <Modal style={{ maxWidth: "1200px" }} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Grading Fields</ModalHeader>
      <ModalBody>
        <BootstrapTable
          keyField="SessionId"
          data={dataTable}
          columns={columnsMerge}
          wrapperClasses="table-responsive"
          cellEdit={cellEditFactory({
            mode: "click",
            blurToSave: true,
            afterSaveCell,
          })}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default GradingFieldsModal;