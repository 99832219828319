import React from 'react';
import styled from 'styled-components';

// Styled component for the timepoint display
const TimepointDisplay = styled.div`
  display: inline-flex;
  padding: 4px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 23px;
  background: #EBF9FF;
  overflow: hidden;
  color: #0089C2;
  font-size: 14px;
  font-weight: 500;
`;

const CurrentTimepointName = ({name}) => {
  return (
    <TimepointDisplay>
      <span>{name}</span>
    </TimepointDisplay>
  );
};

export default CurrentTimepointName;