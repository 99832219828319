import React from 'react'
import styled from 'styled-components'
import { FormGroup, Input } from 'reactstrap'

const WrapItemOption = styled.div`
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  min-width: 310px;
  max-width: 310px;
  cursor: pointer;  
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  &:hover {
    background: #F5FCFF;
  }
  &:active {
    background: #FFF;
    border: 1px solid #0089C2;
    box-shadow: 0px 0px 0px 4px rgba(0, 110, 155, 0.12);
  }
  .wrap-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    h1 {
      color: #101828;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%; /* 24px */
    }
  }
  .text-sub {
    color: #344054;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
  &.disabled {
    pointer-events: none;
    background: #F7F8F9;
    .text-sub, h1 {
      color: #98A2B3;
    }
  }
  &.active {
    border: 1px solid #0089C2;
    box-shadow: 0px 0px 0px 4px rgba(0, 110, 155, 0.12);
  }
`

export default function ItemOption(props) {
  const { title, subTitle, isCheck, disabled, name, handleChoose } = props

  return (
    <WrapItemOption 
      className={`${disabled ? "disabled" : ""} ${isCheck ? 'active' : ''}`}
      onClick={handleChoose}
    >
      <div className="wrap-header">
        <h1>{title}</h1>
        <FormGroup check className='form-common'>
          <Input className="react-radio-common" type="radio" checked={isCheck} name={name} />
        </FormGroup>
      </div>
      {subTitle && <p className="text-sub">{subTitle}</p>}
    </WrapItemOption>
  )
}
