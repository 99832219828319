import React from 'react';
    
//boostrap
import {
    Col
} from "reactstrap";

//components
import { TeamMembers, BurnDown } from '../scrum';

function ScrumRight(props) {
    return (
        <Col sm={12} md={12} lg={5}>
            <TeamMembers {...props} />
            <BurnDown {...props}/>
        </Col>
    );
};

export default ScrumRight;