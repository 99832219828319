import React from 'react';
import Select from 'react-select';
import { useLanguage } from '@context/LanguageContext';

const languages = [
    { value: 'en', label: 'English', flag: 'https://flagcdn.com/w20/us.png' },
    { value: 'my', label: 'Burmese', flag: 'https://flagcdn.com/w20/mm.png' }
];

const DropdownLanguage = () => {

    //state
    const { language, setLanguage } = useLanguage();

    // Handle Change
    const handleChange = (selectedOption) => {
        setLanguage(selectedOption);
    };

    // Format
    const formatOptionLabel = ({ label, flag }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={flag} alt="" />
            <span className="ml-2">{label}</span>
        </div>
    );
    
    return (
        <div className="language-dropdown-container">
            <Select
                id="language-select"
                options={languages}
                onChange={handleChange}
                isSearchable={false}
                value={language}
                formatOptionLabel={formatOptionLabel}
                className="language-dropdown"
                classNamePrefix="custom-select"
                styles={{
                    container: (provided) => ({
                        ...provided,
                        width: '100%'
                    })
                }}
            />
        </div>
    );
};

export default DropdownLanguage;