import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MessageUser, MessageAssistant } from '@components-common/ChatTemplate';
import { FeedbackBox } from "..";
import IconChatRecord from '@images/learnerv2/icon-chat-record.svg';
import ImageAssistant from "@images/common/assistant.png";
import ImageUser from '@images/common/no-avatar.jpg';
import { Scrollbar } from "react-scrollbars-custom";
import { DataEmty } from "@components-common";

function ChatRecords(props) {
    const { profile_photo_url, isLoading, ChatData } = props;

    // Extract ChatDialog from ChatData and initialize state
    const [chatDialogState, setChatDialogState] = useState(ChatData?.ChatDialog || []);

    // Use effect to update state if ChatData changes
    useEffect(() => {
        setChatDialogState(ChatData?.ChatDialog || []);
    }, [ChatData]);

    // Kiểm tra isLoading, nếu true, hiển thị Skeleton
    if (isLoading) {
        return (
            <FeedbackBox
                icon={IconChatRecord}
                title={`Chat Records`}
            >
                <div className={`FrameChat__fullBox`}>
                    <Skeleton height={400} />
                </div>
            </FeedbackBox>
        );
    }

    // Nếu không đang tải, hiển thị dữ liệu thực
    const listItems = chatDialogState.map((item, index) => (
        <div className="messengerCt__messages" key={index}>
            {item?.role === "You" &&
                <MessageUser
                    content={item?.content}
                    avatarUser={profile_photo_url || ImageUser}
                />}
            {item?.role === "Patient" &&
                <MessageAssistant
                    content={item?.content}
                    avataAssistant={ImageAssistant || ImageUser}
                    roleText={`Patient`}
                    theme={'default'}
                />}
        </div>
    ));

    return (
        <FeedbackBox
            icon={IconChatRecord}
            title={`Chat Records`}
        >
            <div className={`FrameChat__fullBox`}>
                <Scrollbar style={{ minHeight: 400 }}>
                    <div className="messengerCt">
                        {listItems}
                        {chatDialogState.length === 0 && <DataEmty />}
                    </div>
                </Scrollbar>
            </div>
        </FeedbackBox>
    );
};

export default ChatRecords;