import React from "react";

//reactstrap
import {
  FormGroup,
  Label,
  Col,
  CustomInput
} from "reactstrap";

//React PLayer
import ReactPlayer from "react-player";

function VideoFile (props) {

  const value = props && props.value;
  const onChange = props && props.onChange;
  return (
    <React.Fragment>
      <FormGroup row>
        <Label className="text-left" for="VideoFile" sm={3}>
          File Video
        </Label>
        <Col sm={9}>
          <CustomInput
            id="VideoFile"
            onChange={onChange}
            className="text-left"
            type="file"
            name="VideoFile"
            accept="video/mp4,video/x-m4v,video/*"
          />
        </Col>
      </FormGroup>
      {value && (
        <ReactPlayer
          url={value || ""}
          width="100%"
          height="100%"
          controls={true}
          loop={true}
        />
      )}
    </React.Fragment>
  );
}

export default VideoFile;