import React, { useState, useEffect } from 'react';

import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";

//constants
import { validateConstant } from "@constants";

const ModalAddTag = (props) => {
    const { isOpen, toggle, handleUpdateNewData, filterData, typeReview } = props && props;
    const dispatch = useDispatch();

    //state
    const [isLoading, setLoading] = useState({
        addTag: false
    });

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset
    } = useForm();

    // Load reset
    useEffect(() => {
        reset({tag_name: ''})
    }, [isOpen, reset])

    const onSubmit = (data) => {
        setLoading((prevState) => ({ ...prevState, addTag: true }));
        const params = {
            "action_type": "add_tag_item",
            "tag_name": data?.tag_name,
            "code": filterData?.category,
            "review_type": typeReview?.review_type
        }
        //Call Api
        dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_REQUEST, isLoading });
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                handleUpdateNewData(data);
                dispatch({
                    type: validateConstant.GET_TAGS_FOR_VALIDATION_SUCCESS,
                    payload: data
                });
                setLoading((prevState) => ({ ...prevState, addTag: false }));
                toggle();
            } else {
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_FAILURE, error: 'error' });
                setLoading((prevState) => ({ ...prevState, addTag: false }));
                toggle();
            }
        });
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Tag</ModalHeader>
                <ModalBody>
                    <FormGroup className="mt-2">
                        <Label for="tag_name">Name <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("tag_name", { required: true })}
                            type="text"
                            name="tag_name"
                            invalid={errors.tag_name?.type === "required"}
                            defaultValue={watch("tag_name")}
                            onChange={(e) => {
                                setValue("tag_name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.tag_name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="primary"
                        className="d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading?.addTag}
                    >
                        {isLoading?.addTag && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAddTag;