import React, { useEffect } from "react";

//lodash
import _ from "lodash";

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);

function BurnDown(props) {
    const { data } = props && props;
    const burndownData = data?.burndown;

    useEffect(() => {
        Plotly.Plots.resize("plotlyChart");
    }, []);

    const filteredDay = _.map(burndownData, 'Day');
    const filteredPoint = _.map(burndownData, 'Point');
    const maxNumber = _.max(filteredPoint);
    return (
        <PlotlyComponent
            divId="plotlyChart"
            data={[
                {
                    x: ['', ...filteredDay],
                    y: [0, ...filteredPoint],
                    type: 'scatter',
                    mode: 'lines+markers',
                    marker: { color: 'red' },
                },
                {
                    x: [filteredDay[0], 0],
                    y: [maxNumber, 0],
                    mode: 'lines',
                    marker: { color: 'blue' },
                }
            ]}
            className="w-100"
            useResizeHandler
            layout={{
                font: { size: 13 },
                responsive: true,
                margin: { t: 0, r: 0, b: 0, l: 20 },
                xaxis: {
                    automargin: true,
                    tickangle: 45,
                    zeroline: true,
                    title: {
                        text: "Day",
                        standoff: 20
                    }
                },
                yaxis: {
                    automargin: true,
                    tickangle: 90,
                    zeroline: true,
                    title: {
                        standoff: 40
                    }
                }
            }} />
    );
};

export default BurnDown;