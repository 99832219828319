import React from "react";

//boostrap
import { Row, Col } from "reactstrap";

//components
import { AccordionCard, ProgressBar } from "../Home";

function WidgetBox(props) {
  const { data, indexParent } = props && props;
  return (
    <div className="widgetBox -box-shadow mb-3">
      <div className="widgetBox__head">
        <Row className="align-items-center">
          <Col md={7} sm={12}>
            <h3 className="wg-title --text-primary">{data.Title}</h3>
          </Col>
          <Col md={5} sm={12}>
            <ProgressBar value={data.Percent} />
          </Col>
        </Row>
      </div>
      <div className="widgetBox__body p-0">
        <AccordionCard
          key={indexParent}
          data={(data && data.Tasks) || []}
          parent={`parent_${indexParent}`}
        />
      </div>
    </div>
  );
}

export default WidgetBox;