import React from "react";
import { Row, Col } from "reactstrap";
import ButtonAddPoint from "./ButtonAddPoint";

// Component to display and manage the header of the exam points, allowing users to toggle the use of human models
const ExamPointHeader = (props) => {
  return (
    <Row className="d-flex align-items-center">
      <Col md={12} className="text-right">
        <ButtonAddPoint {...props} />
      </Col>
    </Row>
  );
};

export default ExamPointHeader;