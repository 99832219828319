import React, { useState, useEffect } from "react";

//boostrap
import { Container, Row, Col, Button } from "reactstrap";

//components
import { DocumentsLeft, AccordionCard, MainTabsPdf, ModalAIInstructions } from "../reliPdf";

function ReliDiagnosisPage(props) {
    const { rowData, handleBackList } = props && props;
    const [itemCurrent, setItemCurrent] = useState((rowData?.analyze_result && rowData?.analyze_result[0]) || null);
    const [activeIndex, setActiveIndex] = useState(1);
    const [stateICD, setICD] = useState([]);
    const [icdActive, setIcdActive] = useState(null);
    const [activeTab, setActiveTab] = useState("1");
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [stateLabels, setStateLabels] = useState({});

    useEffect(() => {
        setItemCurrent((rowData?.analyze_result && rowData?.analyze_result[0]) || null);
    }, [rowData])

    // Handle Click Page
    const handleClick = (currentItem) => {
        setItemCurrent(currentItem);
        setActiveIndex(currentItem?.page_num);

        // Call lai api de labels
        icdActive && handleApiExtractICD(icdActive, stateLabels, currentItem?.page_num);
    }

    //handle Click tab
    const toggleTab = (tab) => {
        setActiveTab(tab);
    };

    // Handle Extract ICD
    const handleApiExtractICD = (value, labelsArr, pageActive) => {
        setIcdActive(value);// active ICD
        setActiveTab("2"); //active tab
        setStateLabels(labelsArr); //Array Labels - de dung call khi click page

        if (labelsArr?.labels && Object.keys(labelsArr?.labels)?.length > 0) {
            setLoading(true);
            setTimeout(() => {
                // lấy object theo CID
                const resultObj = labelsArr?.labels[value];
                const resultObjForPage = resultObj[`page_${pageActive}`];
                setICD(resultObjForPage);
                setLoading(false);
            }, 1000);
        } else {
            setICD([]);
            setLoading(false);
        }
    }

    const toggle = () => {
        setModal(!modal);
    }

    return (
        <Container className="-box-shadow bg-white p-4">
            <ModalAIInstructions
                {...props}
                isOpen={modal}
                toggle={toggle}
            />
            <Row>
                <Col className="text-right mb-4" md={12}>
                    <Button
                        color="primary"
                        className="btn btn-primary mr-2"
                        onClick={toggle}
                    >
                        AI Instructions
                    </Button>
                    <Button
                        color="primary"
                        className="btn btn-primary"
                        onClick={handleBackList}
                    >
                        <span className="fa fa-arrow-left mr-2"></span>
                        Back To List
                    </Button>
                </Col>
                <DocumentsLeft
                    {...props}
                    handleClick={handleClick}
                    activeIndex={activeIndex}
                />
                <Col md={9}>
                    <div className="document-body">
                        <div className="document-body__left">
                            <MainTabsPdf
                                {...props}
                                rowRecord={itemCurrent}
                                pageActive={activeIndex}
                                stateICD={stateICD}
                                isLoading={isLoading}
                                activeTab={activeTab}
                                toggleTab={toggleTab}
                            />
                        </div>
                        <div className="document-body__right">
                            <AccordionCard
                                {...props}
                                rowData={rowData}
                                handleApiExtractICD={handleApiExtractICD}
                                icdActive={icdActive}
                                pageActive={activeIndex}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ReliDiagnosisPage;