import React, { useEffect } from "react";

//router
import { useParams } from "react-router-dom";

//boostrap
import { Container, Col, Alert } from "reactstrap";

//call Action
import { atcReportCaseQuestion } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { FullContentDetails } from "@components/reports/Questions";

//utils
import { LoaderPage, useQuery } from "@utils/";

function QuestionReportPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //slug
  let query = useQuery();
  let questionIdBySlug = Number(query.get("question"));
  let periodIdSlug =
    query.get("PeriodId") === "null" ? null : query.get("PeriodId");
  let cohortIdSlug =
    query.get("CohortId") === "null" ? null : query.get("CohortId");

  useEffect(() => {
    const fetchAllQuestions = async () => {
      const params = {
        ReportType: "question_Records",
        QuestionId: questionIdBySlug,
        CohortId: periodIdSlug,
        PeriodId: cohortIdSlug,
      };
      dispatch(atcReportCaseQuestion(id, params));
    };
    fetchAllQuestions();
  }, [dispatch, id, questionIdBySlug, periodIdSlug, cohortIdSlug]);

  const questionRecord = useSelector((state) => state.getQuestionRecord);
  const resData = questionRecord && questionRecord.data;
  const questionContent = resData && resData.QuestionContent;
  return (
    <div className="wrapper -reportPage">
      {questionRecord.isLoading && <LoaderPage />}
      {!questionRecord.isLoading && (
        <React.Fragment>
          <div className="reportCt">
            <Container>
              <Col sm={12}>
                {questionContent && (
                  <Alert
                    className="m-alert--default border-0 font-weight-500"
                    color="secondary"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: questionContent || "",
                      }}
                    />
                  </Alert>
                )}
              </Col>
              <Col md={12} className="mt-3">
                <FullContentDetails data={resData || []} {...props} />
              </Col>
            </Container>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default QuestionReportPage;
