import React from "react";

//common
import { DataEmty } from "@components-common";

//components
import { ReactSelectTags } from "../../Filter";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//reactstrap
import { Button } from "reactstrap";
import { LoaderText } from "@utils";

//partents
import { SortCaret } from "@ui-partents/Table";

//format time
import moment from "moment";

// Import the pagination configuration
import { paginationOptions } from "@utils";

function AccountTable(props) {
  const { toggle, itemsReview, isLoading } = props && props;
  const columns = [
    {
      dataField: "log_id",
      text: "ID",
      hidden: true,
      headerClasses: "align-middle font-weight-500",
    },
    {
      dataField: "title",
      text: "Item",
      headerClasses: "align-middle font-weight-500",
      sort: true,
      sortCaret: (order, column) => {
        return <SortCaret order={order} />;
      },
    },
    {
      dataField: "tags",
      text: "Tags",
      headerClasses: "align-middle font-weight-500",
      style: {
        width: "350px",
      },
      formatter: (cell, row) => {
        return <ReactSelectTags {...props} row={row} />
      },
    },
    {
      dataField: "Comparison",
      text: "Comparison",
      headerClasses: "align-middle font-weight-500",
      style: {
        width: "50px",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex algins-items-center justify-content-center">
            <Button color="primary" className="btn-sm mx-1 --btn-primary" onClick={() => toggle('comparisonData', row)}>
              <i className="fa fa fa-eye" aria-hidden="true"></i>
            </Button>
          </div>
        );
      },
    },
    {
      dataField: "is_reviewed",
      text: "Labeled",
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      formatter: (cell, row) => {
        return <span>{row.is_reviewed ? <i className="fa fa-check-circle text-success fa-2x" aria-hidden="true"></i> : ""}</span>;
      },
      sort: true,
      sortCaret: (order, column) => {
        return <SortCaret order={order} />;
      },
    },
    {
      dataField: "generated_time",
      text: "Created At",
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      sort: true,
      sortCaret: (order, column) => {
        return <SortCaret order={order} />;
      },
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: "align-middle font-weight-500",
      style: {
        width: "50px",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex algins-items-center justify-content-center">
            <Button color="primary" className="btn-sm mx-1 --btn-primary" onClick={() => toggle('reviewItem', row)}>
              <i className="fa fa-edit" aria-hidden="true"></i>
            </Button>
          </div>
        );
      },
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="log_id"
        data={itemsReview || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <BootstrapTable
            condensed
            striped
            wrapperClasses="table-responsive"
            noDataIndication={() => (
              <div className="py-5">
                <DataEmty content={"No Data"} />
              </div>
            )}
            {...paginationTableProps}
            {...toolkitprops.baseProps}
          />
        )}
      </ToolkitProvider>
      {/* /Table */}
      <div className="pagination-custom-case d-flex justify-content-center mt-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </div>
  );

  const totalSize = itemsReview?.length || 0; // Dynamically calculate the total size

  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="position-relative">
      {isLoading && <div style={{ height: '400px' }}><LoaderText /></div>}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
          {contentTable}
        </PaginationProvider>
      )}
    </div>
  );
}

export default AccountTable;