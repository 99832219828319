import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//react-hook-form
import { useForm } from "react-hook-form";

//logo
import imgLogo from "@images/weitzman/logo.png";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//untils
import { checkAuth } from "@utils";

//boostrap
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Alert,
  Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";

//queryString
import queryString from "query-string";

function LaunchModules(props) {
  //form
  const {
    register,
    formState: { errors
    },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  //check params
  let parsed = queryString.parse(window.location.search);

  //state
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    document.body.style["background-color"] = "#fafbfb";

    // Kiem tra xem da login chua
    const Account = localStorage.getItem("Account");
    if (checkAuth()) {
      const jsonAccount = JSON.parse(Account);
      if (Account) {
        setValue("Email", jsonAccount?.email);
      }
    }
  }, [id, setValue]);

  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append('Firstname', 'User');
    formData.append('Lastname', data?.Email);
    formData.append('Email', data?.Email);
    formData.append('Institution', 'Weitzman');
    formData.append('LaunchPlanId', id);

    setLoading(true);
    //Call Api
    apiCaller(`/api/auth/demo/`, "POST", formData).then((response) => {
      let dataResponse = response?.data;
      if (dataResponse?.status === "OK") {
        const accountData = dataResponse?.account;
        const isAdministrator = accountData?.is_administrator;
        // const isInstructor = accountData?.is_instructor;
        const isSubscription = accountData?.subscription;

        // set localStorage
        localStorage.setItem(
          "Account",
          accountData ? JSON.stringify(accountData) : ""
        );

        //add token
        dispatch(actionLogin(dataResponse));

        //Call API Theme
        dispatch(actFetchThemeRequest());

        // Check Redirect Page
        if (isSubscription !== null) {
          nextPage(dataResponse);
        } else if (isSubscription === null && isAdministrator === "Yes") {
          history.push(`/plan`);
        } else {
          history.push(`/plan/error`);
        }

        //reset Data
        reset({ keepIsValid: false, keepValues: false });
      } else if (dataResponse?.status === 'Unable to authenticate with core back end') {
        setLoading(false);
      } else {
        setLoading(false);
        console.log('error');
      }
    });
  }

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed[`redirect`];

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
      if (dataAccount?.is_instructor === "Yes") {
        history.push(`/teach/home`);
      } else {
        history.push(`/?status=main&LaunchPlanId=${id}`);
      }
    }
  };

  return (
    <Col md={`12`} lg={`6`} className="p-4 bg-white mx-auto position-relative">
      <div className="text-center mb-5">
        <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
      </div>
      <Alert className="m-alert--default border-0 text-center">
        Please enter your email address below.<br />
        (Please use the same email address you used for registration on the Weitzman Institute site.)
      </Alert>
      <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={`12`}>
            <FormGroup>
              <Input
                {...register("Email", {
                  required: "This field is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
                type="email"
                name="Email"
                placeholder="Enter your email address here"
                className="form-control-lg form-lg-corner"
                invalid={errors.Email?.type === "required"}
                autoComplete="off"
                defaultValue={watch('Email')}
              />
              {errors.Email?.message && (
                <FormFeedback className="d-block">
                  {errors.Email?.message}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col md={`12`} className="mt-3">
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="btn m-btn--pill btn-lg w-100 text-white"
                onClick={handleSubmit(onsubmit)}
                disabled={isLoading}
                style={{ minWidth: "25%", backgroundColor: "#4b77ba", borderColor: "#4b77ba" }}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Launch Modules
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}

export default LaunchModules;