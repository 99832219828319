import React, { useEffect, useState, useRef } from "react";

//router
import { useParams } from "react-router-dom";

//boostrap
import { Row, Container, Col } from "reactstrap";

//utils
import { apiCaller, useQuery } from "@utils";

//common
import { HeadingBox } from "@components/common";

//components
import { GradingInstruction, QuestionContent, DiagnosisSchema, ModalConfirm } from "@components/teach/TestAI";
//VoiceSpeechToText

function TestQuestionAIPage(props) {
  let { id } = useParams();
  let query = useQuery();
  let QuestionId = query.get("QuestionId");
  let isSchema = query.get("Schema");
  let interval = null;
  const messageEl = useRef(null);

  //state
  const [stateQuestion, setQuestion] = useState({});
  const [stateInstruction, setInstruction] = useState({
    AI_Instructions: null,
    Scoring_Instructions: null
  });
  const [stateAiLabel, setStateAiLabel] = useState(null);
  const [stateAnswer, setStateAnswer] = useState("");
  const [contentStream, setStream] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingStream, setLoadingStream] = useState(false);
  const [loaderModal, setLoaderModal] = useState({
    AI_Instructions: false,
    Scoring_Instructions: false
  });
  const [requestDone, setRequest] = useState(false);
  const [modal, setModal] = useState({
    AI_Instructions: false,
    Scoring_Instructions: false
  });
  const intervalIDRef = React.useRef(null);

  //Load Data by Slyg
  useEffect(() => {
    const loadQuestionById = () => {
      const params = {
        "Action": "Load",
        "Target": "Question",
        "QuestionId": QuestionId
      }
      apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
        const data = res?.data;
        if (res?.status === 200) {
          setQuestion(data);
          setInstruction((prevState) => ({
            ...prevState,
            AI_Instructions: data?.AI_Instructions?.Text ? { Text: data?.AI_Instructions?.Text } : null,
            Scoring_Instructions: data?.Scoring_Instructions?.Text ? { Text: data?.Scoring_Instructions?.Text } : null
          }));
        } else {
          console.log("error....");
        }
      });
    };

    loadQuestionById();
  }, [QuestionId, id]);

  // Call Api Stream
  const handleStreamData = (questionPromise) => {
    // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([questionPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        console.log('API question returned before timeout');
        return;
      }

      console.log('API question timed out. Calling API stream_data...');
      intervalIDRef.current = setInterval(() => {
        setLoadingStream(true);
        apiCaller(`/api/learn/case/stream_data/${id}/`, 'GET').then(res => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setStream(dataStream);
            setLoadingStream(false);
            isSchema === "diagnosis" && messageEl?.current && messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
          } else {
            setLoadingStream(false);
            console.log("error....");
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        });
      }, 1000);
    });
  }

  // Handle Submit Api
  const handleSubmit = (text) => {
    setLoading(true);

    // Clear
    setStateAiLabel(null);
    setStream(null);

    const params = {
      "Target": "Question",
      "CaseId": id,
      "QuestionId": QuestionId,
      "AnswerText": text,
      "AI_Instructions": stateInstruction
    }
    const questionPromise = apiCaller(`/api/teach/test/`, 'POST', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        setStateAiLabel(data);
        setLoading(false);
        setRequest(true);

        // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
        setStream(null);
        clearInterval(intervalIDRef.current);
        intervalIDRef.current = null;
      } else {
        console.log("error....");
        setLoading(false);
      }
      return data;
    });
    handleStreamData(questionPromise);
  }


  // Clear TimeOut
  useEffect(() => {
    clearTimeout(intervalIDRef.current);
    return () => clearInterval(intervalIDRef.current);
  }, [interval, requestDone]);

  // Submit Instruction (Scoring Instruction, Feedback Instruction)
  const handleSubmitInstruction = (type) => {
    setLoaderModal((prevState) => ({ ...prevState, [type]: true }));
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    let params = {
      "Target": `${type === "AI_Instructions" ? "Question_AI_Instruction" : "Question_Scoring_Instruction"}`,
      "Action": "Update",
      "QuestionId": QuestionId,
      [type]: stateInstruction[type] || null
    };
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      if (res?.status === 200) {
        //Hidden Loader and Hide Modal
        setLoaderModal((prevState) => ({ ...prevState, [type]: false }));
        setModal(prevState => ({ ...prevState, [type]: false }));
      } else {
        //Hidden Loader and Hide Modal
        setLoaderModal((prevState) => ({ ...prevState, [type]: false }));
        setModal(prevState => ({ ...prevState, [type]: false }));
      }
    });
  }

  // Handle Change TextBox
  const handleChange = (e) => {
    const { value } = e.target;
    setStateAnswer(value);
    setStateAiLabel(null);
  }

  // Handle Change Instruction
  const handleChangeInstruction = (e) => {
    const { value, name } = e.target;
    setInstruction((prevState) => ({ ...prevState, [name]: { Text: value } }));
  }

  //toggle
  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  // Render Component Schema
  const renderComponent = () => {
    let componentSchema = "";
    switch (isSchema) {
      // case "chatgpt_voice":
      //   componentSchema = (
      //     <VoiceSpeechToText
      //       {...props}
      //       handleSubmitVoiceText={handleSubmit}
      //       stateAiLabel={stateAiLabel}
      //       contentStream={contentStream} //Common
      //       isLoadingStream={isLoadingStream} //Common
      //       isLoading={isLoading}
      //       isSchema={isSchema}
      //     />
      //   )
      //   break;
      case "diagnosis":
      case "dialog":
        componentSchema = (
          <DiagnosisSchema
            {...props}
            QuestionId={QuestionId}
            handleStreamData={handleStreamData} //Common
            contentStream={contentStream} //Common
            isLoadingStream={isLoadingStream} //Common
            isLoading={isLoading}
            stateInstruction={stateInstruction}
            clearTimeOutStreamData={() => {
              // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
              clearInterval(intervalIDRef.current);
              setStream(null);
            }} //Xoa Time Out
            isSchema={isSchema}
            messageEl={messageEl}
          />
        )
        break;
      default:
        componentSchema = (
          <QuestionContent
            {...props}
            QuestionId={QuestionId}
            stateQuestion={stateQuestion}
            handleChange={handleChange}
            handleSubmit={() => handleSubmit(stateAnswer)}
            stateAiLabel={stateAiLabel}
            contentStream={contentStream} //Common
            isLoadingStream={isLoadingStream} //Common
            isLoading={isLoading}
            stateAnswer={stateAnswer}
            isSchema={isSchema}
          />
        )
    }
    return componentSchema;
  }

  return (
    <div className="-schemaPage">
      <div className="diagnosticCt">
        <Container className="-box-shadow bg-white p-4">
          <Row>
            <Col md={12}>
              <HeadingBox title={`Question #${QuestionId}` || ''} buttonReset={true} />
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: stateQuestion?.QuestionContent || ''
                }}
              />
            </Col>
          </Row>
          <Row className="align-items-start">
            {renderComponent()}
            <GradingInstruction
              {...props}
              handleChangeInstruction={handleChangeInstruction}
              stateInstruction={stateInstruction}
              toggle={toggle}
              stateQuestion={stateQuestion}
              stateAnswer={stateAnswer}
              isSchema={isSchema}
            />
          </Row>
          <ModalConfirm
            isOpen={modal.AI_Instructions}
            toggle={toggle}
            handleConfirm={handleSubmitInstruction}
            loaderModal={loaderModal}
            type={`AI_Instructions`}
          />
          <ModalConfirm
            isOpen={modal.Scoring_Instructions}
            toggle={toggle}
            handleConfirm={handleSubmitInstruction}
            loaderModal={loaderModal}
            type={`Scoring_Instructions`}
          />
        </Container>
      </div>
    </div>
  );
}

export default TestQuestionAIPage;