import React from "react";
import { DragDrop, MutipleChoice, FreeText } from "../../../feedback/type";

function ContentType(props) {
  const dataQuestion = props && props.data;
  return (
    <div className="divContent">
      <h5 className="divContent__heading m--font-info">QUESTIONS</h5>
      <div className="divContent__detail">
        {dataQuestion?.map((value, index) => {
            const type = value?.QuestionType;
            return (
              <React.Fragment key={index}>
                {type === "multiple_choice" && (
                  <MutipleChoice key={index} {...value} />
                )}
                {type === "drag_n_drop" && (
                  <DragDrop data={value} />
                )}
                {type === "free_text" && (
                  <FreeText key={index} {...value} />
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default ContentType;
