import React, { useState } from 'react';

//reactstrap
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

function AvailabilityDropdown(props) {
    const { index, handleAllAvailable, value, isLoading } = props && props;

    //state
    const [dropdownOpen, setOpenDropdown] = useState({});

    //toggleDropdown
    const toggleDropdown = (index) => {
        setOpenDropdown((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    return (
        <Dropdown
            isOpen={dropdownOpen[index]}
            toggle={() => toggleDropdown(index)}
            className="ml-2"
        >
            <DropdownToggle
                className="btn-brand d-flex align-items-center"
                caret
                color="primary"
            >
                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Set Availability
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem
                    onClick={() =>
                        handleAllAvailable(
                            "Available",
                            value?.Interventions,
                            value?.CategoryId
                        )
                    }
                >
                    All Available
                </DropdownItem>
                <DropdownItem
                    onClick={() =>
                        handleAllAvailable(
                            "Unavailable",
                            value?.Interventions,
                            value?.CategoryId
                        )
                    }
                >
                    All Unavailable
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default AvailabilityDropdown;