import React, { useState } from "react";

//react-router-dom
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

//reactstrap
import { Col } from "reactstrap";

//components
import ModalCreateNewCase from "./ModalCreateNewCase";

const CreateNewCaseButton = ({ listData }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { slug } = useParams();

  // toggle Modal
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  // determine the to prop based on activeParams
  const toLink = slug === "new-version" ? "#" : "/teach/new/step1";

  // determine the onClick action based on activeParams
  const onClickAction = slug === "new-version" ? toggleModal : undefined;

  return (
    <Col
      md={listData?.length > 0 ? 6 : 12}
      className={`text-right ${listData?.length > 0 ? `mt-3 mt-md-0` : ""}`}
    >
      <Link
        to={`${toLink}`}
        className={`btn --btn-primary rounded-pill`}
        onClick={onClickAction}
      >
        <i className="fa fa-plus mr-2" aria-hidden="true"></i>
        Create New Case
      </Link>

      <ModalCreateNewCase
        isOpen={isModalOpen}
        toggle={toggleModal}
      />
    </Col>
  );
};

export default CreateNewCaseButton;