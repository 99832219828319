import React from 'react';

// components
import { ProfileHeader, DropdownLanguage } from "@components/layouts/HeaderMakansafe";

const HeaderRight = (props) => {
    return (
        <div className="header_right d-flex justify-content-between align-items-center ml-auto">
            <DropdownLanguage {...props} />
            <ProfileHeader {...props} />
        </div>
    );
};

export default HeaderRight;