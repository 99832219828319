import React from "react";
import { Row, Col, Button } from "reactstrap";
import { CreatableMultiselect } from "@components/common";

//untils
import { formatReactSelect } from "@utils";

function FormSearch(props) {
  const {
    dataCandidatesForManagers,
    submitEnroll,
    isLoading,
    isEnroll,
    listInvitedEmails,
    handleChangeData,
  } = props && props;

  const dropdownSelect = dataCandidatesForManagers?.Candidates?.length > 0 ? formatReactSelect(dataCandidatesForManagers?.Candidates, 'Email', 'Email') : [];
  return (
    <Row className="justify-content-start mb-3">
      <Col sm={`12`}>
        <div className="dropdownMutiCt">
          <div className="dropdownMutiCt__left">
            <CreatableMultiselect
              handleChangeData={handleChangeData}
              placeholder={`Click and select users to add as collaborators`}
              DataOption={dropdownSelect}
              isEnroll={isEnroll}
            />
          </div>
          <div className="dropdownMutiCt__right text-right">
            <Button
              className="--btn-primary d-flex align-items-center text-nowrap"
              onClick={submitEnroll}
              disabled={
                isLoading ||
                (listInvitedEmails?.length > 0
                  ? false
                  : true)
              }
            >
              {isLoading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
              )}
              Add Collaborator(s)
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default FormSearch;
