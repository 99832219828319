import React from "react";

const MedicationsOrders = () => {
  return (
    <div>
      <h2>MedicationsOrders Component</h2>
      {/* Thêm nội dung của MedicationsOrders component ở đây */}
    </div>
  );
};

export default MedicationsOrders;