import React from "react";

function ButtonDelete(props) {
  const { handleDelete } = props && props;
  return (
    <button className="btn btn-danger btn-sm mx-1" onClick={handleDelete}>
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  );
}

export default ButtonDelete;