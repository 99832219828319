import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container, Row, Col } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { HeadingBox } from "@components/common";

//actions
import { caseLoadById } from "@actions";

//apiCaller
import { apiCaller } from "@utils";

//JoditEditor
import JoditEditor from "jodit-react";

//lodash
import { debounce } from 'lodash';

//Config
import * as Config from "@constants/Config";

function CaseDraftPage(props) {
  //props
  const { caseDetails } = props && props;
  const editor = useRef(null);
  let { id } = useParams();
  const dispatch = useDispatch();
  //store
  const store = useSelector(
    (state) => state?.loadCaseByIdForElement || {}
  );

  //state
  const [content, setContent] = useState(store?.data?.CaseDraft || "");
  const [isSuccess, setSuccess] = useState(false);

  // Load Case Draft
  useEffect(() => {
    setContent(store?.data?.CaseDraft || "");
  }, [store])

  useEffect(() => {
    const saveContent = (newContent) => {
      const params = {
        Action: 'Update',
        Target: 'CaseDraft',
        CaseDraft: newContent
      };

      apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then((res) => {
        if (res?.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        } else {
          console.log('error');
        }
      });
    };

    const debouncedSave = debounce(saveContent, 2000);
    debouncedSave(content);

    return () => {
      debouncedSave.cancel(); // Clear the debounce timeout when the component is unmounted
    };
  }, [content, id]);

  //call Api
  useEffect(() => {
    const fetchData = () => {
      dispatch(caseLoadById(id));
    };
    fetchData();
  }, [dispatch, id]);

  const handleChange = (newContent) => {
    setContent(newContent);
  };

  //titlePage
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -eChatPage">
      <section className="eChatCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Case Draft" />
          <Row>
            <Col md={10}>
              {isSuccess && (
                <div className="alert alert-success" role="alert">
                  Data has been updated successfully!
                </div>
              )}
              <JoditEditor
                ref={editor}
                value={content}
                config={Config.CONFIG_JODIT}
                tabIndex={1} // tabIndex of textarea
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default CaseDraftPage;