import React, { useState } from "react";

//reactstrap
import { Card, CardBody, Container, Button } from "reactstrap";

//components
import { DragDropZoneFile } from "@ui-partents/Form/FormUpload";

//Axios
import axios from "axios";

//Auth
import * as Config from "@constants/Config";
import { authHeader } from "@helpers";

function EvaluateNLPPage() {

    //state
    const [isLoading, setLoading] = useState(false);
    const [imageData, setImageData] = useState([]); //files

    //handleUploadFile
    const handleUploadFile = (data) => {
        //Set File FormData
        data.map((file) => setImageData(file));
    };

    //submit
    const onSubmit = () => {
        const formData = new FormData();
        formData.append("File", imageData);
        setLoading(true);

        //Call Api
        axios({
            method: "POST",
            url: `${Config.API_NLP}/api/evaluate/`,
            headers: authHeader(),
            data: formData
        }).then(function (response) {
            console.log(response, "response...");
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    };
    
    return (
        <div className="wrapper -analyzePage">
            <Container style={{ maxWidth: "800px" }}>
                <Card>
                    <CardBody>
                        <p className="mb-3 font-weight-500">Evaluate Entity Recognition Algorithms</p>
                        <DragDropZoneFile 
                            placeholder="Drop a CSV test file here, then click Submit to evaluate"
                            onChange={handleUploadFile}
                            acceptFile=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        />
                        <Button
                            className="--btn-primary d-flex align-items-center mt-3 ml-auto"
                            color="primary"
                            onClick={onSubmit}
                            disabled={isLoading}
                        >
                            {isLoading && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            Submit
                        </Button>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default EvaluateNLPPage;