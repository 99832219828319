import React, { useState, useCallback, useEffect } from 'react';
import { RichTextCommon } from "@components/teachV2/storyboard";
import { debounce } from "lodash";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//constants
import { storyboardConstants } from "@constants";

//utils
import { apiCaller, LoaderText } from "@utils";

const ContentNarrative = ({ activeViewClass, timepointData, contentAiGenerate, isLoadingGenerate }) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const [valueEditor, setValueEditor] = useState(timepointData?.DefaultNarrative);

    //Load Value
    useEffect(() => {
        setValueEditor(timepointData?.DefaultNarrative);
    }, [timepointData]);

    //Update narrative
    const handleApiContentNarrative = useCallback((currentContent, currentTimepointId, valueNarrativeId) => {
        let params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: currentTimepointId,
            Narrative: {
                Id: valueNarrativeId,
                Content: currentContent,
            }
        };

        //Call Api
        dispatch({ type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                dispatch({
                    type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_SUCCESS,
                    payload: params?.Narrative || {}
                });
            } else {
                dispatch({
                    type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_FAILURE,
                    error: 'Failed to update the narrative content'
                });
            }
        });
    }, [dispatch, id]);

    // Call API when contentAiGenerate changes
    useEffect(() => {
        if (contentAiGenerate) {
            handleApiContentNarrative(contentAiGenerate, timepointData?.Id, timepointData?.DefaultNarrative?.Id ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentAiGenerate]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(
        debounce((currentContent, currentTimepointId, valueNarrativeId) => handleApiContentNarrative(currentContent, currentTimepointId, valueNarrativeId), 500), []
    );

    // debounce content
    const handleUpdateEditor = (currentContent, currentTask) => {
        const currentTimepointId = timepointData?.Id;
        const valueNarrativeId = timepointData?.DefaultNarrative?.Id ?? "";
        if (currentTask === "history") {
            debounced(currentContent, currentTimepointId, valueNarrativeId);
            // Update valueEditor to synchronize with DefaultNarrative
            setValueEditor({
                ...timepointData?.DefaultNarrative,
                Content: currentContent
            });
        }
    };

    return (
        <div className="content-narrative position-relative">
            {isLoadingGenerate && <LoaderText />}
            <RichTextCommon
                handleUpdateEditor={(content) => handleUpdateEditor(content, activeViewClass)}
                task={activeViewClass}
                defaultValue={valueEditor?.Content || ""}
                placeholder="Enter the instructions for the learner here..."
            />
        </div>
    );
};

export default ContentNarrative;