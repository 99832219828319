import React, { useState, useEffect } from 'react';
import { Input } from "reactstrap";

function AvailableCheckBox(props) {
    const { item, handleChangeAvailable, namePanel } = props && props;

    //state
    const [stateItem, setStateItem] = useState(item || {});

    useEffect(() => {
        setStateItem(item || {});
    }, [item])

    return (
        <Input
            className="m-0 checkbox-lg"
            checked={stateItem?.Available}
            data-checked={stateItem?.Available}
            onChange={(e) => {
                //Update Item
                let cloneItem = {...item}
                cloneItem[`Available`] = !stateItem?.Available
                setStateItem(cloneItem);
                handleChangeAvailable(e, stateItem?.Id, namePanel);
            }}
            type="checkbox"
        />
    );
};

export default AvailableCheckBox;