import React, { useState } from "react";
import { useParams } from "react-router-dom";

//components 
import { FrameChat } from "./ChatFreeText";

//boostrap
import { Col } from "reactstrap";

//apiCaller
import { apiCaller } from "@utils";

//lodash
import _ from "lodash";

function DiagnosisSchema(props) {
    let { id } = useParams();

    //props
    const { QuestionId, handleStreamData, stateGradingInstruction, clearTimeOutStreamData, messageEl } = props && props;

    //state
    const [chatRecord, setChatrecord] = useState([]);

    const [isLoading, setLoading] = useState(false);

    const handleSubmitAnswerText = (params) => {
        // Push Object vao Array sau do scroll
        const UserMessage = {
            "role": "user",
            "content": params?.Message,
            "is_response": true
        }
        let newData = [...chatRecord, UserMessage];
        setChatrecord(newData);
        setLoading(true);

        const paramsApi = {
            "Target": "Question",
            "CaseId": id,
            "QuestionId": QuestionId,
            "AnswerText": params?.Message,
            "AI_Instructions": stateGradingInstruction
        }

        const questionPromise = apiCaller(`/api/teach/test/`, 'POST', paramsApi).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                // Clear Call Api TimeOut
                clearTimeOutStreamData();
                setLoading(false);

                //SetState Data Response va scroll
                let filterRoleNotSysTem = _.filter(data, function (o) { return o.role !== 'system' && o.is_response === true });
                setChatrecord(filterRoleNotSysTem);
                messageEl?.current && messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
            } else {
                console.log("error....");
                setLoading(false);
            }
            return data;
        });
        handleStreamData(questionPromise);
    };

    return (
        <Col md={6}>
            <FrameChat
                {...props}
                dataChatRecords={chatRecord || []}
                messageEl={messageEl}
                handleSubmitAnswerText={handleSubmitAnswerText}
                isLoading={isLoading}
            />
        </Col>
    );
}

export default DiagnosisSchema;