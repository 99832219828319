export default function authHeader() {
    // return authorization header with basic auth credentials
    const localStorageToken = localStorage.getItem('token');

    if (localStorageToken !== "undefined" && localStorageToken !== null) {
        return {
            'Authorization': `Token ${localStorageToken}`,
            'Content-Type': 'application/json'
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}