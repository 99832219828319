import React from 'react';

//textarea
import TextareaAutosize from 'react-textarea-autosize';

//boostrap
import {
    FormGroup,
    Label,
    Row,
    Col
} from "reactstrap";

function ResponseTab(props) {
    
    //props
    const { data, handleChange } = props && props;

    return (
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label for="ChatQuestion">
                        Question text:
                    </Label>
                    <TextareaAutosize
                        className={`w-100 form-control`}
                        maxRows={12}
                        minRows={7}
                        onChange={handleChange}
                        name="ChatQuestion"
                        defaultValue={data?.ChatQuestion}
                    />
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label for="ChatResponse">
                        Response text:
                    </Label>
                    <TextareaAutosize
                        className={`w-100 form-control`}
                        maxRows={12}
                        minRows={7}
                        onChange={handleChange}
                        name="ChatResponse"
                        defaultValue={data?.ChatResponse}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
};

export default ResponseTab;