import React from "react";

//boostrap
import { Row, Col } from "reactstrap";

function OnBoarding(props) {
  const { data } = props && props;

  // dataOnboaringReport
  const Summary = data && data.Summary;
  const ActiveInstructors = Summary && Summary.ActiveInstructors;
  const NewAccounts = Summary && Summary.NewAccounts;
  const NewCaseCompletions = Summary && Summary.NewCaseCompletions;
  const NewCases = Summary && Summary.NewCases;
  const NewEnrolledLearners = Summary && Summary.NewEnrolledLearners;
  const NewInstitutions = Summary && Summary.NewInstitutions;
  const listReport = [
    {
      Name: "New Accounts",
      ClassColor: "m--font-brand",
      ClassIcon: "fa-user",
      Value: NewAccounts,
    },
    {
      Name: "New Institutions",
      ClassColor: "m--font-brand",
      ClassIcon: "fa-universal-access",
      Value: NewInstitutions,
    },
    {
      Name: "New Cases",
      ClassColor: "text-orange",
      ClassIcon: "fa-puzzle-piece",
      Value: NewCases,
    },
    {
      Name: "Case Completions",
      ClassColor: "text-orange",
      ClassIcon: "fa-check-square-o",
      Value: NewCaseCompletions,
    },
    {
      Name: "Newly enrolled learners",
      ClassColor: "m--font-success",
      ClassIcon: "fa-graduation-cap",
      Value: NewEnrolledLearners,
    },
    {
      Name: "Active institutions",
      ClassColor: "m--font-success",
      ClassIcon: "fa-rss",
      Value: ActiveInstructors,
    },
  ];
  
  return (
    <div className="OnBoardingCt">
      <section className="statistic-section -box-shadow bg-white p-3">
        <div className="container">
          <Row className="text-center justify-content-center">
            {listReport.map((item, index) => (
              <Col className="mt-3" lg={`3`} md={`6`} xs={`12`} key={index}>
                <div className="counter p-4 border">
                  <i
                    className={`fa ${item.ClassIcon} fa-2x ${item.ClassColor}`}
                    aria-hidden="true"
                  ></i>
                  <h2
                    className={`timer count-title count-number ${item.ClassColor}`}
                  >
                    {item.Value}
                  </h2>
                  <div className="stats-line-black" />
                  <p className="stats-text">{item.Name}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section>
    </div>
  );
}

export default OnBoarding;
