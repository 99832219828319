import React, { useState, useEffect } from "react";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//components
import BadgeScoreLabs from './BadgeScoreLabs.jsx';

function ItemPanel(props) {
  const {
    item,
    isTime,
    handleChangeCheckbox,
    toggleDel,
    togglePanel,
    toggleScore,
    handleCollapseToggle,
    indexItem
  } = props && props;

  //state
  const [stateItem, setStateItem] = useState(item || {});

  //Load
  useEffect(() => {
    setStateItem(item || {});
  }, [item])

  return (
    <tr
      className="row_lab row_lab_panel"
      style={{ backgroundColor: "rgb(242, 242, 242)" }}
      data-id-panel={stateItem?.Id}
    >
      <td
        colSpan={isTime > 0 ? "3" : "4"}
        className="text-left font-weight-600 align-middle"
      > 
        <span className="d-flex align-items-center" style={{cursor: 'pointer'}} onClick={() => handleCollapseToggle(indexItem)}>
          <span className="mr-3 text-6 --text-primary">
            <i className={`fa ${stateItem?.isCollapse ? "fa-angle-up" : "fa-angle-down"}`} aria-hidden="true"></i>
          </span>
          <span className="m--font-danger lab_name lab_panel_name">
            {stateItem?.Name}
          </span>
        </span>
      </td>
      <td className="text-center align-middle">
        <input
          type="checkbox"
          className="checkbox_lab_availability"
          name="Available"
          defaultChecked={stateItem?.Available}
          data-id={stateItem?.Id}
          onClick={(e) => {
            //Update Item
            stateItem[`Available`] = !stateItem?.Available
            handleChangeCheckbox(e, stateItem, indexItem, "panel");
          }}
        />
      </td>
      <td className="text-center align-middle">
        <BadgeScoreLabs
          {...props}
          listDomain={stateItem?.DomainScores || []}
          itemRow={stateItem}
          key={Math.random()}
        />
        <div
          style={{ cursor: "pointer" }}
          className="m--font-info font-weight-500"
          onClick={() => toggleScore(stateItem, "add-score", "Lab", {}, "")}
        >
          <span className="icon-add icon-size-2x"></span>
        </div>
      </td>
      <th className="text-right align-middle">
        <ButtonEdit handleEdit={() => togglePanel(stateItem, "edit-panel")} />
        <ButtonDelete handleDelete={(e) => toggleDel(stateItem, "delete-panel")} />
      </th>
    </tr>
  );
}

export default ItemPanel;