export const coinConstant = {
  GET_COIN_REQUEST: 'GET_COIN_REQUEST',
  GET_COIN_SUCCESS: 'GET_COIN_SUCCESS',
  GET_COIN_FAILURE: 'GET_COIN_FAILURE',

  GET_COIN_MCQ_REQUEST: 'GET_COIN_MCQ_REQUEST',
  GET_COIN_MCQ_SUCCESS: 'GET_COIN_MCQ_SUCCESS',
  GET_COIN_MCQ_FAILURE: 'GET_COIN_MCQ_FAILURE',

  GET_COIN_CASE_SCRIPT_REQUEST: 'GET_COIN_CASE_SCRIPT_REQUEST',
  GET_COIN_CASE_SCRIPT_SUCCESS: 'GET_COIN_CASE_SCRIPT_SUCCESS',
  GET_COIN_CASE_SCRIPT_FAILURE: 'GET_COIN_CASE_SCRIPT_FAILURE',
};