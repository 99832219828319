import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//table
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

//redux
import { useDispatch } from "react-redux";

//lodash
import _ from 'lodash';

//elements
import { ButtonDelete } from '@components-common/elements';

//components
import { ModalDeleteVitalSign } from '../../storyboard/Modal';
import { ReferenceRange } from '../VitalSigns';

//apiCaller
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//Loader
import { LoaderText } from "@utils";

//redux
import { useSelector } from "react-redux";

function TableVitalSignsFirst(props) {
  //props
  const { timepointData, timePointId } = props && props;

  // Su dung selector de kiem tra Action EXTRACT_VITAL_SIGNS_SUCCESS dang request loading list
  const extractVitalSignsData = useSelector(
    (state) => state.extractVitalSigns || {}
  );

  //state
  let { id } = useParams();
  const dispatch = useDispatch();
  const [tblvitalSigns, setTblvitalSigns] = useState([]);
  const [vitalSignId, setVitalSignId] = useState("");
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const responseData = timepointData?.data;
    const vitalSigns = responseData?.DefaultVitalSigns;
    const tblvitalSigns = vitalSigns?.map((value) => ({
      Id: value.Id,
      Name: value.Name,
      Initial_Value: value.Value,
      Unit: value.Unit === "C" ? "℃" : value.Unit,
      Reference_Range: `${value.NormalMin} ${value.Unit === "C" ? "℃" : value.Unit
        } - ${value.NormalMax} ${value.Unit === "C" ? "℃" : value.Unit}`,
      NormalMax: value.NormalMax,
      NormalMin: value.NormalMin
    }));
    setTblvitalSigns(tblvitalSigns);
  }, [timepointData]);

  const toggle = (VitalSignId) => {
    setVitalSignId(VitalSignId);
    setModal(!modal);
  }

  //table
  const columns = [
    {
      dataField: "Id",
      text: "Id",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    },
    {
      dataField: "Name",
      text: "Item",
      editable: false,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    },
    {
      dataField: "Initial_Value",
      text: "initial Value",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    },
    {
      dataField: "Unit",
      text: "Unit",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    },
    {
      dataField: "Reference_Range",
      text: "Reference Range",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle",
      style: {
        width: '300px'
      },
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
        <ReferenceRange handleSaveReferenceRange={handleSaveReferenceRange} {...editorProps} row={row} />
      )
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle",
      style: {
        width: "50px",
        textAlign: "center",
      },
      formatter: (cell, row) => {
        return (
          <ButtonDelete handleDelete={() => toggle(row.Id)} />
        );
      }
    }
  ];

  // call Api Timepoint
  const fetchApiUpdateDataForTimePoint = (id, params) => {
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        // Convert data tuong tu nhu khi load page
        const tblvitalSigns = data?.VitalSigns?.map((value) => ({
          Id: value.Id,
          Name: value.Name,
          Initial_Value: value.Value,
          Unit: value.Unit === "C" ? "℃" : value.Unit,
          Reference_Range: `${value.NormalMin} ${value.Unit === "C" ? "℃" : value.Unit
            } - ${value.NormalMax} ${value.Unit === "C" ? "℃" : value.Unit}`,
          NormalMax: value.NormalMax,
          NormalMin: value.NormalMin
        }));
        setTblvitalSigns(tblvitalSigns);

        dispatch({
          type: storyboardConstants.UDPATE_VALUE_VITAL_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: storyboardConstants.UDPATE_VALUE_VITAL_FAILURE,
          error: "error",
        });
      }
    });
  };

  // Auto Save Cell
  const afterSaveCell = (oldValue, newValue, row, column) => {
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      VitalSign: {
        Id: row && row.Id,
        Value: row && row.Initial_Value,
        Unit: row && row.Unit
      },
    };
    fetchApiUpdateDataForTimePoint(id, params);
  };

  // Save Edit Cell
  const handleSaveReferenceRange = (data) => {
    //update object by Id
    const newData = { ...data }
    const ReferenceFormat = `${data.NormalMin} ${data.Unit === "C" ? "℃" : data.Unit} - ${data.NormalMax} ${data.Unit === "C" ? "℃" : data.Unit}`;
    newData.Reference_Range = ReferenceFormat

    //Clone data and update state
    const newArrUpdate = _.cloneDeep(tblvitalSigns);
    const indexData = _.findIndex(newArrUpdate, function (o) { return o.Id === data.Id });
    newArrUpdate[indexData] = newData;
    setTblvitalSigns(newArrUpdate);

    //Call API
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      VitalSign: {
        Id: data && data.Id,
        NormalMax: data && data.NormalMax,
        NormalMin: data && data.NormalMin
      },
    };
    fetchApiUpdateDataForTimePoint(id, params);
  }

  return (
    <React.Fragment>
      <ModalDeleteVitalSign
        {...props}
        isOpen={modal}
        toggle={toggle}
        vitalSignId={vitalSignId}
      />
      {extractVitalSignsData?.isLoading && <div style={{ height: '200px' }}><LoaderText /></div>}
      <BootstrapTable
        keyField="Id"
        data={tblvitalSigns}
        columns={columns}
        bordered={false}
        cellEdit={cellEditFactory({
          mode: 'click',
          blurToSave: true,
          afterSaveCell
        })}
      />
    </React.Fragment>
  );
}

export default TableVitalSignsFirst;