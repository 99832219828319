import React from 'react';
import styled from 'styled-components';

const TabsContainer = styled.div`
  position: relative;
`;

const TabsList = styled.ul`
  display: flex;
  padding: 0;
  list-style: none;
  overflow-x: auto;
`;

const TabItem = styled.li`
  display: flex;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  gap: 18px; /* Increased gap to accommodate badge */
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s, color 0.3s;
  color: ${(props) => (props.active ? '#079455' : '#081B2A')};
  background: ${(props) => (props.active ? '#F2F4F7' : 'transparent')};
  border-radius: ${(props) => {
    if (props.active) {
      if (props.first) return '6px 6px 0 0'; // First tab, radius on the top corners only
      if (props.last) return '6px 6px 0 0';  // Last tab, same as above
      return '6px 6px 0 0'; // Default active tab
    }
    return '0'; // Non-active tabs have no radius
  }};

  &:hover {
    background: #e0e4e8;
  }
`;

const TabLink = styled.span`
  text-decoration: none;
  white-space: nowrap;
`;

const Badge = styled.span`
  display: none;
  padding: 2px 10px;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #ABEFC6;
  background: #17B26A;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

const TabContent = styled.div`
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

function TabForDomains({ tabs, activeTab, onTabChange, theme, classNames }) {
  return (
    <TabsContainer>
      <TabsList className={`${classNames || ''} ${theme || ''}`}>
        {tabs?.map((tab, index) => (
          <TabItem
            key={tab.id}
            active={activeTab === tab.id}
            first={index === 0}
            last={index === tabs.length - 1}
            onClick={() => onTabChange(tab.id)}
          >
            <TabLink>{tab.title}</TabLink>
            <Badge>{`84%`}</Badge> {/* Adding the badge */}
          </TabItem>
        ))}
      </TabsList>

      <div className="tab-content">
        {tabs?.map((tab) => (
          <TabContent key={tab.id} active={activeTab === tab.id}>
            {tab.content}
          </TabContent>
        ))}
      </div>
    </TabsContainer>
  );
}

export default TabForDomains;