import React, { useState, useEffect } from "react";

import { Table } from "reactstrap";

//lodash
import _ from "lodash";

//utils
import { messageData } from "@utils";

//common
import { DataEmty } from "@components-common";

//components
import {
  TableHeader,
  ModalUpdateChangeValue,
  PanelsGroup,
  IndependentLabsGroup,
  IndependentLabsRow
} from '../Labs';

function TableLabs(props) {
  //props
  const { dataLabs, typeGroup } = props && props;
  const data = dataLabs && dataLabs;

  //state
  const [stateData, setData] = useState((data && data) || []);
  const [show, setShow] = useState({
    labs: false
  });
  const [itemEdit, setItem] = useState({});

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const TableData = ({ data }) => {
    //filter Time !== 0
    const listTimepointLast = _.filter(data, function (o) {
      return o?.TimePoint?.Time !== 0;
    });

    //filter Time === 0
    const lisTimePointFirst = _.filter(data, function (o) {
      return o?.TimePoint?.Time === 0;
    });
    const dataDefault = lisTimePointFirst?.[0] && lisTimePointFirst?.[0][typeGroup];

    //Header
    const listHeader = [
      { Name: 'Lab Item', width: "300px" },
      { Name: 'Inital Value', width: "300px" },
      ...listTimepointLast
    ]

    //Modal Change Value
    const handleShow = (row, timepoint) => {
      setShow((prevState) => ({ ...prevState, 'labs': true }));

      // set State Data
      const itemData = { row, ...timepoint }
      setItem(itemData);
    }

    const totalColumnLastTimepoint = listTimepointLast?.length;
    return (
      <div className="table-layout-fixed">
        <Table responsive>
          <TableHeader
            {...props}
            data={listHeader}
          />
          <tbody>
            {stateData && stateData.length === 0 && (
              <tr>
                <td>
                  <DataEmty content={messageData?.default?.content} />
                </td>
              </tr>
            )}
            {typeGroup === 'IndependentLabs' && (
              <IndependentLabsRow total={totalColumnLastTimepoint} />
            )}
            {dataDefault?.map((col, index) => {
              const Items = col?.Items;
              return (
                <React.Fragment key={index}>
                  {/* Panels */}
                  {Items?.length > 0 && typeGroup === 'Panels' && (
                    <PanelsGroup
                      {...props}
                      col={col}
                      indexRow={index}
                      typeGroup={typeGroup}
                      typeAvailability={'panel'}
                      dataChildren={Items || []}
                      lisTimePointFirst={lisTimePointFirst}
                      listTimepointLast={listTimepointLast}
                      handleShow={handleShow}
                    />
                  )}

                  {/* IndependentLabs */}
                  {typeGroup === 'IndependentLabs' && (
                    <IndependentLabsGroup
                      {...props}
                      col={col}
                      indexRow={index}
                      typeGroup={typeGroup}
                      typeAvailability={'item'}
                      dataChildren={Items || []}
                      lisTimePointFirst={lisTimePointFirst}
                      listTimepointLast={listTimepointLast}
                      handleShow={handleShow}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
        <ModalUpdateChangeValue data={itemEdit} handleClose={() => { setShow((prevState) => ({ ...prevState, labs: false })) }} isOpenModal={show?.labs} />
      </div>
    );
  };

  return (
    <div className="mb-4">
      <TableData data={(stateData && stateData) || []} />
    </div>
  );
}

export default TableLabs;