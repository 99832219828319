import React from "react";

//router
import { useParams } from "react-router-dom";

//action
import { atcDeleteDomain } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//Boostrap
import { 
    Button, 
    Modal,
    ModalBody, 
    ModalFooter
} from "reactstrap";

function ModalDelete (props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { item, isOpen, toggle } = props && props;
  
  const handleRemove = () => {
    const params = {
      "Action": "Delete",
      "Target": "Domain",
      "DomainId": item?.Id
    }
    dispatch(atcDeleteDomain(id, params));
    toggle();
  }

  const checkData = useSelector(state => state.teachCase || []);
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you certain that you want to delete this domain ?
        </h4>
        <p className="mt-4 text-center">
          This action is not reversible.
          <br />
          All data related to this domain will be deleted.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
          disabled={checkData?.isLoading}
        >
          {checkData?.isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Delete Domain
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDelete;
