import React, { useState, useEffect } from "react";

//FIlter
import { FitterTop } from "../../validate/AnalyzeReviews/Filter";

//components
import { AnalyzeReviewsRow, ModalDetails } from "../AnalyzeReviews/AnalyzeReviewsMain";

//call Api
import { LoaderText, apiCaller } from "@utils";

//constants
import { validateConstant } from "@constants";

//redux
import { useDispatch } from "react-redux";

function AnalyzeReviewsMain(props) {
    let dispatch = useDispatch();

    //state
    const [stateData, setData] = useState({
        tags: [],
        category: [],
        account: [],
        analyzeReviews: {}
    });
    const [isLoading, setLoading] = useState({
        getTagforValidation: false,
        getItemsForReviews: false,
        getCategoryReviews: false,
        analyzeReviews: false,
        getAccountsByCategory: false
    });
    const [modal, setModal] = useState({
        details: false
    });

    //load category
    useEffect(() => {
        // Goi lay category truoc
        const fetchCategory = () => {
            setLoading((prevState) => ({ ...prevState, getCategoryReviews: true }));
            const params = { "action_type": "get_category_reviews" }
            apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    // fetchData(data || []);
                    setData((prevState) => ({ ...prevState, category: data }));
                    setLoading((prevState) => ({ ...prevState, getCategoryReviews: false }));
                } else {
                    console.log('error');
                    setLoading((prevState) => ({ ...prevState, getCategoryReviews: false }));
                }
            });
        }
        //Load Api Model
        fetchCategory();
    }, [dispatch]);

    // Handle Call Api All Tags
    const handleApiAllTag = (categoryValue) => {
        const params = {
            "action_type": "get_tags_for_validation",
            "code": categoryValue?.value
        }
        dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_REQUEST, isLoading: true });
        setLoading((prevState) => ({ ...prevState, getTagforValidation: true }));
        //Call Api Load Text Item
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData((prevState) => ({ ...prevState, tags: data }));
                setLoading((prevState) => ({ ...prevState, getTagforValidation: false }));
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_SUCCESS, payload: data });
            } else {
                setLoading((prevState) => ({ ...prevState, getTagforValidation: false }));
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_FAILURE, payload: data });
            }
        })
    }

    //[GET] accounts by category
    const fetchAccountsByCategory = (categoryValue) => {
        const params = {
            "action_type": "get_accounts_by_category",
            "category": categoryValue?.value
        }
        setLoading((prevState) => ({ ...prevState, getAccountsByCategory: true }));
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData((prevState) => ({ ...prevState, account: data }));
                setLoading((prevState) => ({ ...prevState, getAccountsByCategory: false }));
            } else {
                setLoading((prevState) => ({ ...prevState, getAccountsByCategory: false }));
            }
        });
    }

    //handle Submit Filter
    const handleSubmitFilter = (dataFilter) => {
        const params = {
            "action_type": "analyze_reviews",
            "category": dataFilter?.category,
            "accounts": dataFilter?.account
        }
        setLoading((prevState) => ({ ...prevState, analyzeReviews: true }));
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData((prevState) => ({ ...prevState, analyzeReviews: data }));
                setLoading((prevState) => ({ ...prevState, analyzeReviews: false }));
            } else {
                console.log('error');
                setLoading((prevState) => ({ ...prevState, analyzeReviews: false }));
            }
        });
    }

    //Handle Modal
    const toggle = (type) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    };

    // Update data neu remove hoac add moi
    const handleUpdateNewData = (data) => {
        setData((prevState) => ({ ...prevState, tags: data }));
    }

    const handleClickDisagreementThemes = () => {
        console.log("handleClickDisagreementThemes....");
    }

    return (
        <React.Fragment>
            <FitterTop
                {...props}
                handleSubmitFilter={handleSubmitFilter}
                isLoading={isLoading}
                optionsData={stateData}
                handleUpdateNewData={handleUpdateNewData}
                handleTagsForValidationByCategory={handleApiAllTag}
                fetchAccountsByCategory={fetchAccountsByCategory}
            />
            <div className="position-relative">
                {isLoading?.analyzeReviews && <div style={{ height: '400px' }}><LoaderText /></div>}
                {!isLoading?.analyzeReviews && (
                    <AnalyzeReviewsRow
                        {...props}
                        analyzeReviews={stateData?.analyzeReviews}
                        handleClickDisagreementThemes={handleClickDisagreementThemes}
                        toggle={toggle}
                    />
                )}
            </div>
            <ModalDetails
                {...props}
                isOpen={modal.details}
                toggle={() => toggle('details')}
                stateData={stateData}
            />
        </React.Fragment>
    );
}

export default AnalyzeReviewsMain;