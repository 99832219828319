import React from "react";
import { Button } from "reactstrap";

function ButtonGenerateFeedback(props) {
    const { isLoading, handleClickTab, isActive } = props;
    return (
        <Button
            className="mb-4 w-50 mx-2"
            outline={!isActive}
            color={isActive ? "primary" : "info"}
            onClick={handleClickTab}
        >
            {isLoading && (
                <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Instant Feedback
        </Button>
    );
}

export default ButtonGenerateFeedback;