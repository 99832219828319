import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function ModalAIInstructions(props) {
    const { isOpen, toggle, handleSave } = props && props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{maxWidth: '900px'}}>
            <ModalBody>
                <FormGroup>
                    <Label className="font-weight-500" for="Specific_Rules">Specific Rules:</Label>
                    <TextareaAutosize
                        name="Specific_Rules"
                        placeholder="Enter your Specific Rules..."
                        className="w-100 form-control"
                        maxRows="10"
                        minRows={6}
                    />
                </FormGroup>
                <FormGroup>
                    <Label className="font-weight-500" for="Categorize_Rules">Categorize Rules:</Label>
                    <TextareaAutosize
                        name="Categorize_Rules"
                        placeholder="Enter your Categorize Rules..."
                        className="w-100 form-control"
                        maxRows="10"
                        minRows={6}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn --btn-primary d-flex align-items-center"
                    onClick={handleSave}
                >
                    Save & Apply
                </Button>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalAIInstructions;
