import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";

function CreatableMultiselect(props) {
  const { handleChangeData, placeholder, DataOption, isEnroll } = props && props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [isChange, setChange] = useState(false);

  const handleChange = (data) => {
    setSelectedOption(data);
    setChange(true);
  };

  //Send data to parent component
  useEffect(() => {
    if(isChange) {
      handleChangeData(selectedOption);
      setChange(false);
    }

    if(isEnroll) {
      setSelectedOption(null);
    }
  }, [isChange, handleChangeData, selectedOption, isEnroll]);

  return (
    <CreatableSelect
      isMulti
      closeMenuOnSelect={false}
      // menuIsOpen={true}
      onChange={handleChange}
      options={DataOption}
      placeholder={placeholder}
      value={selectedOption}
      classNamePrefix="react-select-custom"
      getOptionLabel={
        option => option.__isNew__ ?
        <span className="text-info"><span className="nameInvite mr-1 font-weight-600">Inviting:</span>{option.label}</span> : <span>{option.value}</span>
      }
      formatCreateLabel={ inputValue => <span className="text-muted font-weight-600">Invite by email: <span className="font-weight-500">{ inputValue }</span></span>}
      styles={{
        multiValueLabel: (base) => ({
          ...base,
          padding: '6px',
          fontSize: '13px'
        })
      }}
    />
  );
}

export default CreatableMultiselect;
