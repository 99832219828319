import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const AutomaticallyLaunchFeedback = ({ timepointData, updateChatSettings }) => {
    const [stateValue, setStateValue] = useState({
        AutomatedFeedbackTrigger: timepointData?.ChatSettings?.AutomatedFeedbackTrigger,
        AutomatedFeedbackTriggerCount: timepointData?.ChatSettings?.AutomatedFeedbackTriggerCount
    });

    // Handle Change Launch Feedback
    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        
        setStateValue((prevState) => ({
            ...prevState,
            [name]: newValue
        }));

        const params = {
            Action: "Update",
            Target: "ChatSettings",
            AutomatedFeedbackTrigger: name === 'AutomatedFeedbackTrigger' ? newValue : stateValue.AutomatedFeedbackTrigger,
            AutomatedFeedbackTriggerCount: name === 'AutomatedFeedbackTriggerCount' ? parseFloat(value) || 0 : parseFloat(stateValue.AutomatedFeedbackTriggerCount),
            TimePointId: timepointData?.Id
        };

        updateChatSettings(params);
    };

    return (
        <div className='mb-4'>
            <div className="d-flex align-items-center">
                <FormGroup check className='pl-0'>
                    <Label check className="d-flex align-items-center">
                        <Input
                            type="checkbox"
                            id="AutomatedFeedbackTrigger"
                            name="AutomatedFeedbackTrigger"
                            onChange={handleChange}
                            checked={stateValue?.AutomatedFeedbackTrigger}
                            className='custom-checkbox'
                        />{' '}
                        <span className='ml-2'>Automatically launch feedback after</span>
                    </Label>
                </FormGroup>
                <FormGroup className="d-flex align-items-center mb-0 ml-3">
                    <div style={{ width: '70px' }}>
                        <Input
                            name="AutomatedFeedbackTriggerCount"
                            placeholder=""
                            type="number"
                            inputMode="numeric"
                            className="mx-2"
                            value={stateValue?.AutomatedFeedbackTriggerCount}
                            onChange={handleChange}
                            style={{ maxWidth: '70px' }}
                            pattern="[2-9]+"
                            min="2"
                            onKeyPress={(event) => {
                                if (!/[2-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <Label className='ml-4 mb-0' for="questions">Questions</Label>
                </FormGroup>
            </div>
            <div className='form-note form-note-italic ml-4 mt-2'>
                Value must be larger than 1. If not selected, instant feedback <br /> will be generated manually in Learner Interface.
            </div>
        </div>
    );
};

export default AutomaticallyLaunchFeedback;