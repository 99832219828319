import React, { useState, useEffect } from "react";

// gradingSchemaOptions
import gradingSchemaOptions from "../DefineParams/GradingSchemaList";

//reactBoostrap
import {
    FormGroup,
    Label,
    Col,
    Input
} from "reactstrap";

function GSFreeText(props) {
    const { stateGradingSchema, handleChangeSchema } = props && props;

    //state
    const [status, setStatus] = useState(stateGradingSchema || 'null');

    //load State
    useEffect(() => {
        setStatus(stateGradingSchema || 'null');
    }, [stateGradingSchema])

    return (
        <Col md={4}>
            <legend className="col-form-label font-weight-500">
                Grading Schema:
            </legend>
            {gradingSchemaOptions?.map((item, index) =>
                <FormGroup check className="mb-2" key={index}>
                    <Label check>
                        <Input
                            type="radio"
                            onChange={handleChangeSchema}
                            defaultValue={item?.value}
                            defaultChecked={status === item?.value}
                            name="GradingSchema"
                        />
                        {item?.label}
                    </Label>
                </FormGroup>
            )}
        </Col>
    );
};

export default GSFreeText;