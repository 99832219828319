import React, { useState, useEffect } from 'react';

// Icons
import IconFeedback from '@images/learnerv2/icon-chat-record.svg';

// Image
import ImageAssistant from "@images/common/assistant.png";
import ImageUser from '@images/common/no-avatar.jpg';

//components
import { MessageUser, MessageAssistant } from '@components-common/ChatTemplate';

//common
import { FeedbackBox } from "..";

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

function DialogueRecords(props) {
    const { profile_photo_url, question } = props;
    const [dialogData, setDialogData] = useState([]);

    useEffect(() => {
        if (question?.QuestionDialog) {
            setDialogData(question.QuestionDialog);
        }
    }, [question]);

    const listItems = dialogData.map((item, index) => (
        <div className="messengerCt__messages" key={index}>
            {item?.role === "You" &&
                <MessageUser
                    content={item?.content}
                    avatarUser={profile_photo_url || ImageUser}
                />}
            {item?.role === "Instructor" &&
                <MessageAssistant
                    content={item?.content}
                    avataAssistant={ImageAssistant || ImageUser}
                    roleText={`Instructor`}
                    theme={'default'}
                />}
        </div>
    ));

    return (
        <div className="feedback-details">
            <FeedbackBox
                icon={IconFeedback}
                title={`Dialogue Records`}
            >
                <div className={`FrameChat__fullBox`}>
                    <Scrollbar style={{ minHeight: 400 }}>
                        <div className="messengerCt">
                            {listItems}
                        </div>
                    </Scrollbar>
                </div>
            </FeedbackBox>
        </div>
    );
}

export default DialogueRecords;