import React, { useState, useEffect } from 'react';

//reactstrap
import { FormGroup, Label } from 'reactstrap';

//textarea
import TextareaAutosize from "react-textarea-autosize";

function FeedbackText(props) {
    const { data, handleChange } = props && props;
    const Feedback = data?.Feedback;

    //state
    const [stateFeedback, setFeedback] = useState((Feedback && Feedback) || '');

    useEffect(() => {
        setFeedback((Feedback && Feedback) || '');
    }, [Feedback])

    return (
        <FormGroup>
            <Label for="answer">
                Feedback:
            </Label>
            <TextareaAutosize
                name="Feedback"
                placeholder=""
                className="w-100 form-control"
                maxRows={12}
                minRows={7}
                onChange={handleChange}
                defaultValue={stateFeedback}
            />
        </FormGroup>
    );
};

export default FeedbackText;