import React from "react";

// Action
import { actActiveTabsMedicalRecord } from "@actions";

// Redux
import { useDispatch } from "react-redux";

function ButtonCloseERM(props) {
    const { handleExamMenu, isCurrentTask } = props || {};
    const dispatch = useDispatch();

    const handleCloseClick = () => {
        handleExamMenu(isCurrentTask, "closeTask");

        // Set reducer null
        dispatch(actActiveTabsMedicalRecord(null));
    };

    return (
        <div
            className="taskPanelCt__heading--close"
            onClick={handleCloseClick}
            current-task={isCurrentTask}
            style={{ cursor: "pointer" }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M12 4L4 12M4 4L12 12"
                    stroke="#D92D20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}

export default ButtonCloseERM;