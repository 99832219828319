import React from "react";

const ConditionalNote = ({ flowConditionsValue }) => {
    const { Status, QuestionId } = flowConditionsValue || {};

    if (!Status || !QuestionId) {
        return null; // Không có dữ liệu, không cần render gì cả
    }

    let conditionText = "";

    if (Status === "correct") {
        conditionText = `(asked only if #${QuestionId} is correct)`;
    } else if (Status === "incorrect") {
        conditionText = `(asked only if #${QuestionId} is incorrect)`;
    } else {
        return null; // Nếu trạng thái không hợp lệ, không cần render gì cả
    }

    return <div className="note-italic"><span>{conditionText}</span></div>;
};

export default ConditionalNote;
