import React, { useCallback, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import { ElementPoint } from "../PhysicalExam";

//router
import { useParams } from "react-router-dom";

//lodash
import _ from "lodash";

//context menu
import {
  Menu,
  Item,
  Separator,
  animation,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

//api
import { apiCaller } from "@utils";

function ContainerDragPoint(props) {
  //props
  let { widthExPoint, heightExPoint, timePointId, data } = props && props;

  const styles = {
    width: widthExPoint,
    height: heightExPoint,
    position: "relative",
  };

  let { id } = useParams();
  const [boxes, setBoxes] = useState({});
  const [statePointId, setPointId] = useState("");
  const [dataContext, setDataContext] = useState([]);

  useEffect(() => {
    const covertData = async () => {
      //Convert data
      let convertData = Object.assign({}, data);
      let newObjectData = {};
      Object.keys(convertData).map((key) => {
        newObjectData = {
          ...convertData[key],
          top: (heightExPoint * convertData[key].PositionY) / 100,
          left: (widthExPoint * convertData[key].PositionX) / 100,
        };
        convertData[key] = newObjectData;
        return true;
      });
      setBoxes(convertData);
    };
    covertData();
  }, [widthExPoint, heightExPoint, data]);

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes, setBoxes]
  );

  const apiUpdateExamPoint = (item, left, top) => {
    const boxesClone = _.cloneDeep(boxes);
    //params
    const pointId = boxesClone[item.id] && boxesClone[item.id].PointId;
    const PositionX = (left * 100) / widthExPoint;
    const PositionY = (top * 100) / heightExPoint;
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      ExamPoint: {
        PointId: pointId,
        PositionX: PositionX.toFixed(),
        PositionY: PositionY.toFixed(),
      },
    };

    //Call Api Update Point
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      // const data = res && res.data;
      // const listPoint = data?.Exam?.ExamPoints;
      if (res && res.status === 200) {
        //Convert left, top
        // covertData(listPoint);
      } else {
        console.log("error");
      }
    });
  }

  // const covertData = async (list) => {
  //   //Convert PositionX, PositionY => left, top
  //   let convertData = Object.assign({}, list);
  //   let newObjectData = {};
  //   Object.keys(convertData).map((key) => {
  //     newObjectData = {
  //       ...convertData[key],
  //       top: (heightExPoint * convertData[key].PositionY) / 100,
  //       left: (widthExPoint * convertData[key].PositionX) / 100,
  //     };
  //     convertData[key] = newObjectData;
  //     return true;
  //   });
  //   setBoxes(convertData);
  // };

  const [, drop] = useDrop(() => {
    return {
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        apiUpdateExamPoint(item, left, top);
        moveBox(item.id, left, top);
        return undefined;
      },
    };
  }, [moveBox]);

  const { show } = useContextMenu({ id: statePointId });
  const handleContextMenu = (event, PointId, data) => {
    event.preventDefault();
    show(event, {
      props: {
        key: PointId,
      },
    });
    setPointId(PointId);
    setDataContext(data);
  };

  const handleItemClick = (PointId, value, actions) => {
    if (actions === "delete-exam-point") {
      props && props.toggleDelExamPoint(PointId, value, actions);
    } else {
      props && props.toggle(PointId, value, actions);
    }
  };

  //Context Menu - Fidings
  const renderMenu =
    dataContext?.map((value, index) => {
      return (
        <Item
          onClick={(e) => handleItemClick(statePointId, value, "edit")}
          key={index}
        >
          <i className="fa fa-dot-circle-o mr-2" aria-hidden="true"></i>
          {value.Name}
        </Item>
      );
    });

  return (
    <div ref={drop} style={styles}>
      {Object.keys(boxes).map((key) => {
        const { left, top, PointId, Findings } = boxes[key];
        return (
          <div
            key={key}
            onContextMenu={(e) => handleContextMenu(e, PointId, Findings)}
          >
            <ElementPoint
              id={key}
              left={left}
              top={top}
              PointId={PointId}
              props={props}
            />
          </div>
        );
      })}
      {/* Menu */}
      <Menu id={statePointId} animation={animation.fade}>
        {dataContext && dataContext.length > 0 ? renderMenu : <Item className="noExam text-muted opacity-7">No exam finding</Item>}
        <Separator />
        <Item onClick={(e) => handleItemClick(statePointId, {}, "add")}>
          <i className="fa fa-plus mr-2" aria-hidden="true"></i> Add Exam Finding
        </Item>
        <Item onClick={(e) => handleItemClick(statePointId, {}, "delete-exam-point")}>
          <span className="text-danger">
            <i className="fa fa-trash mr-2" aria-hidden="true"></i> Delete Point
          </span>
        </Item>
      </Menu>
    </div>
  );
}

export default ContainerDragPoint;
