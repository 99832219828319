import React, { useState } from "react";

//boostrap
import { Container, Row, Col, Button } from "reactstrap";

//components
import { DragDropZoneFile } from "@ui-partents/Form/FormUpload";
import { ReLiPdfContent } from ".";

//api
import { apiCaller } from "@utils";

function PdfUpload(props) {

    //pagination
    const [isShow, setShow] = useState(false);
    const [uploadData, setUploadData] = useState(null); //files
    const [stateData, setData] = useState(null);
    const [isLoading, setLoading] = useState(false);

    // Upload File
    const handleUpload = (data) => {
        //Set Image Preview
        data.map((file) => setUploadData(URL.createObjectURL(file)));

        //Set File FormData
        data.map((file) => setUploadData(file));
    };

    // Handle Submit Upload
    const handleSubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("pdf", uploadData);
        formData.append("action_type", "pipeline");

        //Call Api
        apiCaller(`/api/binh/reli/`, "POST", formData).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;

                //set State
                setData(data);
                setLoading(false);

                //Clear du lieu da upload
                setUploadData(null);
                setShow(true);
            } else {
                console.log("error");
                setLoading(false);
            }
        });
    }

    return (
        <React.Fragment>
            {!isShow && (
                <Container style={{ maxWidth: "600px" }} className="-box-shadow bg-white p-4 mt-5">
                    <Row>
                        <Col md={12}>
                            <DragDropZoneFile
                                onChange={handleUpload}
                                acceptFile="application/pdf"
                                value={""}
                            />
                            <div className="text-center">
                                <Button
                                    color="primary"
                                    className="btn btn-primary mt-3 ml-auto"
                                    onClick={handleSubmit}
                                    disabled={uploadData ? false : true}
                                >
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                    Upload
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            )}
            {isShow && (
                <ReLiPdfContent
                    {...props}
                    rowData={stateData}
                />
            )}
        </React.Fragment>
    );
}

export default PdfUpload;