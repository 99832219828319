import React, { useState } from "react";

//reactstrap
import {
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

//classnames
import classnames from "classnames";

//Loader
import { LoaderPage } from "@utils";

//redux
import { useSelector } from "react-redux";

// common
// import { NavigationComponent } from "@components/layouts"; //xoa
import { ItemReviewMain, AnalyzeReviewsMain } from "@components/instructor/validate/tabs";

//menu
// import navigation from "@utils/navigation"; //xoa

function ValidatePage(props) {

  //active
  const [activeTab, setActiveTab] = useState(1);

  const techHomeStore = useSelector((state) => state.loadTechHome || {});

  // Handle Click Tab
  const handleClickTab = (indexActive) => {
    setActiveTab(indexActive);
  }

  //load series from store
  const navList = [
    {
      Name: "Item Review",
      Component: <ItemReviewMain {...props} />,
    },
    {
      Name: "Analyze Reviews",
      Component: <AnalyzeReviewsMain {...props} />
    }
  ];

  // const roleAdmin = props.is_administrator === "Yes" || props.is_instructor === "Yes";
  // const mainMenu = roleAdmin ? navigation && navigation.mainMenuAdmin : navigation && navigation.mainMenuLearner;
  return (
    <div className="wrapper -instructorPage">
      {techHomeStore.isLoading && <LoaderPage />}
      <React.Fragment>
        <div className="instructorCt bg-white">
          <Container>
            <Nav className="tabBorder" tabs>
              {navList?.map((item, index) =>
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === index + 1 })}
                    onClick={() => {
                      handleClickTab(index + 1);
                    }}
                  >
                    {item.Name}
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              {navList?.map((value, index) => (
                <TabPane key={index} tabId={index + 1}>
                  {value.Component}
                </TabPane>
              ))}
            </TabContent>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default ValidatePage;