import React from "react";

//components
import { AuthContainer, BannerLogin, ForgotPassWordForm } from "@components/Makansafe";

//Images
import logoMakansafe from '@images/makansafe/logo.png';

function ForgotPassPage(props) {
    const onSubmit = (data) => {
        // Proceed with submitting the form
        console.log(data);
    };

    return (
        <AuthContainer >
            <div className="col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
                <div className="form-makansafe-ct">
                    <div className="logo-makansafe__logo">
                        <img src={logoMakansafe} alt="logo" />
                    </div>
                    <div className="logo-makansafe__title">
                        <h1>
                            Reset Your Password
                        </h1>
                    </div>
                    <div className="form-makansafe-login">
                        <p className="desc-form">Enter your email to receive a link to reset your password.</p>
                        <ForgotPassWordForm onSubmit={onSubmit} />
                    </div>
                    <div className="link-redirect text-center my-2">
                        <a href="/makansafe/auth/login">Back to login</a>
                    </div>
                </div>
            </div>
            <BannerLogin {...props} />
        </AuthContainer>
    );
}

export default ForgotPassPage;