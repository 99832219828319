import { assistantConstant } from "@constants";
import {apiCaller} from "@utils";

//Update Assistant Tip Record
export const actUpdateAssistantTipRecord = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/assistant/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: assistantConstant.UPDATE_ASSISTANT_REQUEST, isLoading } }
    function success(data) { return { type: assistantConstant.UPDATE_ASSISTANT_SUCCESS, payload: data } }
    function failure(error) { return { type: assistantConstant.UPDATE_ASSISTANT_FAILURE, error: error } }
}

//Load Assistant Tip Message
export const atcLoadTipMessageById = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/assistant/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: assistantConstant.LOAD_TIP_MESSAGE_BY_ID_REQUEST, isLoading } }
    function success(data) { return { type: assistantConstant.LOAD_TIP_MESSAGE_BY_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: assistantConstant.LOAD_TIP_MESSAGE_BY_ID_FAILURE, error: error } }
}