import React, { useState, useEffect } from "react";

//boostrap
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";

//common
import { DataEmty } from "@components-common";

//components
import { BoxTabs, Overview } from "../tabs";

function TabsContent(props) {
  const { data } = props && props;
  const [stateData, setStateData] = useState((data && data) || {});
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    setStateData((data && data) || {});
  }, [data]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const feedbackData = stateData && stateData.FeedbackDetails;
  return (
    <Container className="feedbackTemplate">
      {feedbackData && feedbackData.length > 0 ? (
        <Row className="tabVertical">
          <Col className="tabVertical__left" sm={12} md={3}>
            <Nav className="flex-column" tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    "active --bg-primary": activeTab === String(1),
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Overview
                </NavLink>
              </NavItem>
              {feedbackData &&
                feedbackData.map((value, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        "active --bg-primary": activeTab === String(index + 2),
                      })}
                      onClick={() => {
                        toggle(String(index + 2));
                      }}
                    >
                      {value.Name}
                    </NavLink>
                  </NavItem>
                ))}
            </Nav>
          </Col>
          <Col sm={12} md={9} className="tabVertical__right mt-sm-4 mt-md-0">
            <TabContent activeTab={activeTab}>
              {activeTab === "1" && <Overview data={(feedbackData && feedbackData) || {}} />}
              {feedbackData &&
                feedbackData.map((value, index) => (
                  <TabPane key={index} tabId={String(index + 2)}>
                    <h4 className="heading-4 font-weight-500 font-roboto m--font-brand --text-primary">
                      {value.Name}
                    </h4>
                    <Row>
                      <Col sm="12">
                        <BoxTabs data={value} />
                      </Col>
                    </Row>
                  </TabPane>
                ))}
            </TabContent>
          </Col>
        </Row>
      ): <DataEmty content="No Data" />}
    </Container>
  );
}

export default TabsContent;
