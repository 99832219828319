import React from 'react';

// Icons
import IconFeedbackHeader from '@images/learnerv2/feedback-header.svg';

// Components
import { ButtonNext, ButtonBack } from '../FeedbackV2';


function FeedbackHeader(props) {
    const { title, feedbackData } = props && props;
    return (
        <div className="overview-fb-header">
            <div className="row d-flex align-items-center">
                <div className='col-md-8'>
                    <div className="feedback-heading">
                        <img src={IconFeedbackHeader} className='mr-2' alt={title} />
                        <h3>{title}</h3>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="d-flex align-items-center justify-content-end ml-auto">
                        <ButtonBack {...props} />
                        {feedbackData?.NextCaseId && <ButtonNext {...props} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeedbackHeader;