import React from 'react';

//reactstrap
import { Button } from "reactstrap";

function RowAdd(props) {
    const { name, handleAddStandardChatTopic } = props && props;
    return (
        <div className="text-center p-3">
            <Button className="btn-style2 btn-outline-brand d-inline-block" onClick={handleAddStandardChatTopic}>
                <i className="fa fa-plus mr-2" aria-hidden="true" /> Add {name}
            </Button>
        </div>
    );
};

export default RowAdd;