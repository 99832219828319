import React from 'react';
import { useHistory } from 'react-router-dom';
//reactstrap
import { Button } from 'reactstrap';

function ButtonBack() {
    const history = useHistory();

    const handleBackToHome = () => {
        history.push('/learn/home');
    };

    return (
        <div className="button-next" style={{ minWidth: '142px' }}>
            <Button
                size='lg'
                className='w-100'
                onClick={handleBackToHome}
            >
                Back to Home
            </Button>
        </div>
    )
}

export default ButtonBack;