import React, { useState, useMemo } from "react";

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label
} from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//react Select
import Select from 'react-select';

//redux
import { useDispatch } from "react-redux";

//api
import { associatedSchemaConstant } from "@constants";
import { apiCaller } from "@utils";

const ModalAddOther = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { toggle, isOpen, data, QuestionId } = props && props;

    //state
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setLoading] = useState(false);

    //handleChange
    const handleChange = (value) => {
        setSelectedOption(value);
    }

    //convert react select
    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.QuestionId, label: item?.QuestionContent };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    }

    //send Api
    const handleAddAssociatedSchema = () => {
        const params = {
            "Action": "AddAssociatedSchema",
            "CaseId": id,
            "QuestionId": QuestionId,
            "SourceQuestionId": `${selectedOption?.value}`
        }
        setLoading(true);
        dispatch({ type: associatedSchemaConstant.ADD_ASSOCIATED_SCHEMA_REQUEST, isLoading: true });
        apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoading(false);
                dispatch({ type: associatedSchemaConstant.ADD_ASSOCIATED_SCHEMA_SUCCESS, payload: data });
                toggle();
            } else {
                console.log('error');
                dispatch({ type: associatedSchemaConstant.ADD_ASSOCIATED_SCHEMA_FAILURE, error: "error" });
                setLoading(false);
                toggle();
            }
        });
    }

    const listDropdown = useMemo(() => convertDataOptions(data || []), [data]) || [];
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalBody>
                    <FormGroup>
                        <Label for="Name">Select a question to add to this question’s merged schema: <span className="m--font-danger">*</span></Label>
                        <Select
                            options={listDropdown || []}
                            closeMenuOnSelect={true}
                            value={selectedOption}
                            onChange={value => handleChange(value)}
                            placeholder={`Select an item below...`}
                            getOptionLabel={
                                option => (
                                    <React.Fragment>
                                        <span>{`#${option.value} -`}</span>
                                        <span>{option?.label ? option?.label.replace(/<\/?[^>]+>/gi, ' ') : ''}</span>
                                    </React.Fragment>
                                )
                            }
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle} disabled={isLoading}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="primary"
                        onClick={handleAddAssociatedSchema}
                    >
                        {isLoading &&
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        }
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAddOther;