import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import classnames from "classnames";

//tabs
import { ContentType, OrdersTab, Overview } from '../domains';

import _ from 'lodash';

function BoxTabs(props) {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const propsData = props && props.data;
  const questions = propsData && propsData.Questions;
  const orders = propsData && propsData.Orders;

  const listTabs = [
    {id:'1', name: 'Overview', icon: 'fa fa-bar-chart', component: <Overview data={propsData} />, show: true},
    {id:'2', name: 'Questions', icon: 'fa fa-question', component: <ContentType data={questions} />, show: (questions && questions.length > 0 ? true : false)},
    {id:'3', name: 'Orders', icon: 'fa fa-user-md', component: <OrdersTab data={orders} />, show: (orders && !_.isEmpty(orders) ? true : false)}
  ]

  return (
    <div className="tabsPills">
      <Nav tabs>
        {listTabs.map((item, index) =>
          item.show && (
            <NavItem key={index}>
              <NavLink
                className={classnames({ active: activeTab === item.id })}
                onClick={() => {
                  toggle(item.id);
                }}
              >
                <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                {item.name}
              </NavLink>
            </NavItem>
          )
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        {listTabs.map((item, index) =>
          item.show && (
            <TabPane key={index} tabId={item.id}>
              {item.component}
            </TabPane>
          )
        )}
      </TabContent>
    </div>
  );
}

export default BoxTabs;
