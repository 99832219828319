import React from 'react';
import { Button } from 'reactstrap';

const DeleteCohortButton = ({ toggle, color = 'danger', label = 'Delete Cohort', size = 'sm', className = '', outline = true }) => {
  return (
    <Button
      color={color}
      outline={outline}
      className={`d-flex align-items-center ml-auto ${className}`}
      onClick={() => toggle('deleteCohort')}
      size={size}
    >
      {label}
    </Button>
  );
};

export default DeleteCohortButton;