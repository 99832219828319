import React from "react";
import styled from "styled-components";

//Icon
import { Iconsax } from "@components-common";

const HeaderFeature = ({ title, handleClickBack }) => {
    return (
        <HeaderFeatureWrapper>
            <ButtonBack onClick={handleClickBack}>
                <Iconsax iconName="arrow-back-short" fill="#1D2939" size={25} />
            </ButtonBack>
            <Title>
                {title}
            </Title>
        </HeaderFeatureWrapper>
    );
};

export default HeaderFeature;

const HeaderFeatureWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`;

const ButtonBack = styled.div`
    cursor: pointer;
    margin-right: 16px; /* Adjust as needed */
`;

const Title = styled.div`
    color: var(--brand-color-orange-colors-orange-400);
    font-size: 22px; /* or 1.375rem */
    font-weight: 600;
`;