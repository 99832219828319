import { caseConstants, feedbackConstant, learnerConstants } from "@constants";

//lodash
import _ from "lodash";

//Update Number Of Cases
function compareAssistantMessage(dataOld, dataNew) {
  let statusEffect = false;
  const resAssistantMessageOld = dataOld?.data?.Case?.Documentation[`AssistantMessage`];
  const resAssistantMessageNew = dataNew?.payload?.AssistantMessage;
  if ((resAssistantMessageOld && resAssistantMessageNew) && resAssistantMessageNew !== resAssistantMessageOld) {
    statusEffect = true;
  }
  return statusEffect;
}

export function cases(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case caseConstants.CASE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CASE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.CLICK_TASK_RECORD_SUCCESS:
      newState = { ...state };
      newState.data.Case.TaskRecords = action?.payload?.TaskRecords;
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.RESET_CASE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case caseConstants.CHAT_SUBMIT_SUCCESS:
      newState = { ...state };
      //data records
      const dataRecords = newState.data.Case.ChatData.ChatRecords;
      dataRecords.push(action.payload);

      //data available
      const dataAvailableMessages = newState.data.Case.ChatData.AvailableStandardMessages;
      const newDta = dataAvailableMessages.filter(
        (value) => value.Message !== action.payload.UserMessage
      );
      newState.data.Case.ChatData.AvailableStandardMessages = newDta;
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.PUT_ACTIONS_TASK_SUCCESS:
      newState = { ...state };
      newState.data.Case.Interface.Elements =
        action?.payload?.InterfaceChanges;
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.CHECK_MY_NOTE_SUCCESS:
      newState = { ...state };
      const listDataClone = _.cloneDeep(state);
      newState.data.Case.Documentation[`AssistantMessage`] = action?.payload?.AssistantMessage || "";
      newState.data.Case.Documentation[`Feedback`] = action?.payload?.Feedback || "";
      newState.data.Case.Documentation[`BillingSheet`] = action?.payload?.BillingSheet || {};
      newState.data.Case.Documentation[`DocumentationItems`] = action?.payload?.DocumentationItems || [];
      newState.data.Case.Documentation[`OptimalBillingSheet`] = action?.payload?.OptimalBillingSheet || {};
      newState.data.Case.Documentation[`compareAssistantMessage`] = compareAssistantMessage(listDataClone, action);
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.SUBMIT_DOCUMENTATION_SUCCESS:
      newState = { ...state };
      newState.data.Case.Documentation[`DocumentationItems`] =
        action?.payload?.DocumentationItems;
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.CASE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function actionsWidthCaseId(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case caseConstants.CASE_ACTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CASE_ACTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.PUT_ACTIONS_TASK_SUCCESS:
      newState = { ...state };
      var Actions = (action?.payload?.Actions) || null;
      newState = {
        ...newState,
        ...Actions,
        data: action?.payload?.Actions,
      };
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.CASE_ACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function dataFeedbacks(state = {}, action) {
  var newState = {};
  switch (action.type) {
    case caseConstants.CASE_FEEDBACKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CASE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case feedbackConstant.REMOVE_XPDATAUPDATE:
      newState = { ...state };
      newState.data[`XPDataUpdate`] = null
      return {
        ...newState,
        isLoading: false,
      };
    case caseConstants.FEEDBACKS_ASSISTANT_FOR_QUESTION_SUCCESS:
      newState = { ...state };
      newState.data[`FeedbackAssistant`] = action?.payload?.FeedbackAssistant
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.CASE_FEEDBACKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function valueChatSubmit(state = {}, action) {
  switch (action.type) {
    case caseConstants.CHAT_SUBMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CHAT_SUBMIT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action?.payload?.status,
        isLoading: false,
      };
    case caseConstants.CHAT_SUBMIT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function checkMissingTask(state = {}, action) {
  switch (action.type) {
    case caseConstants.CHECK_MISSING_TASK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CHECK_MISSING_TASK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.CHECK_MISSING_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function InterfaceTemplateData(state = {}, action) {
  switch (action.type) {
    case caseConstants.CASE_INTERFACE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.CASE_INTERFACE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case caseConstants.CASE_INTERFACE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function caseTaskCurrent(state = { previousData: null, data: null }, action) {
  switch (action.type) {
    case caseConstants.PUT_CURRENT_TAKS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case caseConstants.PUT_CURRENT_TAKS_SUCCESS:
      // Kiểm tra nếu có dữ liệu mới
      if (action.payload) {
        // Lưu trữ phiên bản trước đó của dữ liệu và cập nhật dữ liệu mới
        return {
          ...state,
          previousData: state.data,
          data: action.payload,
          isLoading: false,
        };
      } else {
        // Không có dữ liệu mới, giữ nguyên dữ liệu hiện tại
        return {
          ...state,
          isLoading: false,
        };
      }
    case caseConstants.PUT_CURRENT_TAKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

//Medical Record - Active tab
export function statusTaskActiveTabMedicalRecord(state = {}, action) {
  switch (action.type) {
    case caseConstants.ACTIVE_TAB_MEDICAL_RECORD:
      return {
        ...state,
        activeTab: action.payload || null
      };
    default:
      return state;
  }
}

//Load Virtual Preceptor Status
export function VirtualPreceptorStatus(state = {}, action) {
  switch (action.type) {
    case learnerConstants.LOAD_VIRTUAL_PRECEPTOR_STATUS_SUCCESS:
      return {
        ...state,
        taskReset: action.payload
      };
    default:
      return state;
  }
}