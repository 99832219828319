import React from "react";

//boostrap
import { Col } from "reactstrap";

// Chat common
import { Framechat } from "../Comment";

const ItemContents = (props) => {
    //props
    const { loggingItem } = props && props;
    return (
        <Col className="h-100" md={8} style={{ overflowY: 'auto' }}>
            <Framechat
                {...props}
                data={loggingItem?.item_content}
                user="Prompt / Input:"
                assistant="Assistant:"
            />
        </Col>
    );
};

export default ItemContents;