import React, { useState } from "react";
import { FormGroup, Label, Badge } from "reactstrap";
import PromptTemplateModal from "./PromptTemplateModal"; // Import component PromptTemplateModal

function PromptTemplates(props) {
    const {
        stateQuestion
    } = props;

    const [selectedPromptTemplate, setSelectedPromptTemplate] = useState(null);
    const [isPromptTemplateModalOpen, setIsPromptTemplateModalOpen] = useState(false);

    const BadgeList = (data) => {
        return (
            <div>
                {data?.PromptTemplates?.map((item, index) => (
                    <Badge
                        className={`border px-2 ${index === 0 ? "" : "ml-2"}`}
                        key={item.Id}
                        color="default"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setSelectedPromptTemplate(item);
                            setIsPromptTemplateModalOpen(true);
                        }}
                    >
                        {item.Name}
                    </Badge>
                ))}
            </div>
        );
    };

    const togglePromptTemplateModal = () => {
        setIsPromptTemplateModalOpen(!isPromptTemplateModalOpen);
    };

    return (
        <React.Fragment>
            <FormGroup>
                <Label className="font-weight-500" for="Comment">Prompt Templates:</Label>
                {BadgeList(stateQuestion)}
            </FormGroup>
            <PromptTemplateModal
                isOpen={isPromptTemplateModalOpen}
                toggleModal={togglePromptTemplateModal}
                selectedPromptTemplate={selectedPromptTemplate}
            />
        </React.Fragment>
    );
}

export default PromptTemplates;