import React, { useState } from "react";

//reactstrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

//classnames
import classnames from "classnames";

//components
import { OnBoarding, Accounts } from '../admin';

function TabsOnBoarding(props) {
  const { dataReport } = props && props;
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const listTabs = [
    { id: "1", name: "Overview", component: <OnBoarding data={(dataReport && dataReport) || {}} /> },
    { id: "2", name: "Accounts", component: <Accounts data={(dataReport && dataReport) || {}} /> }
  ];

  return (
    <div className="bg-white">
      <Nav className="tabBorder" tabs>
        {listTabs?.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => {
                toggle(item.id);
              }}
            >
              <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {listTabs?.map((item, index) => (
          <TabPane key={index} tabId={item.id}>
            {item.component}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}

export default TabsOnBoarding;