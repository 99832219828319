import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//actions
import { atcDeleteLearnerToCohort } from "@actions";

function ModalDeleteLearner(props) {
  //props
  const { isOpen, toggle, item, cohortObject } = props && props;
  const dispatch = useDispatch();

  const hanldeRemove = () => {
    const params = {
      Action: "RemoveLearners",
      CohortId: cohortObject?.CohortId,
      LearnerIds: [item && item.id]
    };
    dispatch(atcDeleteLearnerToCohort(params)).then(res => {
      if (res?.status === 200) {
        toggle(); // Close modal
        // setIsLoading(false);
      }
      // Các bước xử lý tiếp theo sau khi thành công
    }).catch(error => {
      console.error('Delete failed, error:', error);
      // setIsLoading(false);
      // Các bước xử lý khi có lỗi
    }).finally(() => {
      // Set loading state back to false once the process is done
      // setIsLoading(false);
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          <span dangerouslySetInnerHTML={{ __html: `Are you sure you want to remove <span class="font-weight-400">${item && item.firstname} ${item && item.lastname}</span> from this cohort?` }}></span>
        </h4>
        <p className="mt-2 text-center">
          The learner might not have access to certain modules that are assigned
          to this cohort.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={hanldeRemove}
        >
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteLearner;
