import React from 'react';

//reactstrap
import { CustomInput, Label } from "reactstrap";

function CheckBoxFilter(props) {
    const { handleCheckBox, filterData } = props && props;
    const options = [
        {
            label: 'Only items not yet reviewed',
            name: 'only_no_review',
            value: 'only_no_review'
        }
    ]

    return (
        <div className='flex-wrap mt-3'>
            {options?.map((item, index) =>
                <Label className="d-inline-flex align-items-center" key={index}>
                    <CustomInput
                        id={`${item?.value}`}
                        type="checkbox"
                        name={item?.name}
                        onChange={handleCheckBox}
                        value={filterData?.only_no_review}
                    />
                    {item?.label}
                </Label>
            )}
        </div>
    );
};

export default CheckBoxFilter;