import React from "react";
import styled from "styled-components";

// Styled Component for TextArea
const StyledTextArea = styled.textarea`
  resize: none;
  width: 100%;
  min-height: ${(props) => (props.isMobile ? "56px" : "64px")};
  border-radius: 12px;
  border: ${(props) => (props.isMobile ? "none" : "1px solid #cdd5df")};
  background: #fff;
  padding: ${(props) => (props.isMobile ? "12px" : "8px 12px")};
  font-size: 14px;
  line-height: 20px;
  color: #333;
  outline: none;

  &:focus {
    border-color: ${(props) =>
      props.isMobile ? "unset !important" : "#0089c2"};
    box-shadow: ${(props) =>
      props.isMobile ? "unset !important" : "0 0 0 2px rgba(0, 137, 194, 0.2)"};
  }

  &:disabled {
    background: #f9f9f9;
    color: #aaa;
    cursor: not-allowed;
    border: ${(props) => (props.isMobile ? "none !important" : "unset !important")};
  }
`;

function TextArea({
  value,
  handleChange,
  handleKeyDown,
  disabled,
  textareaRef,
  placeholder,
  answer,
  isMobile,
}) {
  return (
    <StyledTextArea
      name={answer}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      value={value}
      disabled={disabled}
      ref={textareaRef}
      isMobile={isMobile}
    />
  );
}

export default TextArea;