import React from "react";
import styled from "styled-components";
import iconAddTimepoint from '@images/new_interface/timepoint/add-timepoint.svg';

const StyledButton = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  border: 1px solid #D0D5DD;
  background: #fff;
  cursor: pointer;
  height: 44px;
`;

const StyledText = styled.span`
  color: #1D2939;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.28px;
  padding-left: 10px;
`;

const BtnAddTimepoint = ({ onClick }) => (
  <StyledButton onClick={onClick}>
    <img src={iconAddTimepoint} alt="add-point" />
    <StyledText>Add time point</StyledText>
  </StyledButton>
);

export default BtnAddTimepoint;