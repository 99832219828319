/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { debounce } from 'lodash';
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";
import TextareaAutosize from "react-textarea-autosize";
import ChatPromptTemp from "./ChatPromptTemp";

function InteractionScript(props) {
    const dispatch = useDispatch();
    const { id } = useParams();

    //props
    const { timepointData } = props && props;
    const { ChatSettings } = timepointData || {};
    const { ChatScript } = ChatSettings || {};
    const InteractionPromptTemplates = ChatSettings?.InteractionPromptTemplates || [];

    //state
    const [tempContent, setTempContent] = useState(ChatScript || "");

    useEffect(() => {
        setTempContent(ChatScript || "");
    }, []);

    // Handle Submit
    const handleSubmit = useCallback(debounce((value) => {
        const params = {
            Action: "Update",
            Target: "ChatSettings",
            ChatScript: value,
            TimePointId: timepointData?.Id
        };
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: "error" });
            }
        });
    }, 1000), []);

    // Handle Change
    const handleChange = (e) => {
        const { value } = e.target;
        setTempContent(value);
        handleSubmit(value);
    };
    
    return (
        <React.Fragment>
            <TextareaAutosize
                name="ChatScript"
                className="w-100 form-control"
                maxRows="10"
                minRows={8}
                onChange={handleChange}
                value={tempContent}
                placeholder="Enter a description..."
            />
            <ChatPromptTemp
                {...props}
                currentTemplate={InteractionPromptTemplates}
                typeChatSettings={"InteractionPromptTemplates"}
            />
        </React.Fragment>
    );
}

export default InteractionScript;