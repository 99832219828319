import { checkpointsConstants } from "@constants";
import {apiCaller} from "@utils";

//GET LIST
export const atcLoadCheckpoints = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: checkpointsConstants.CHECKPOINTS_LOAD_REQUEST, isLoading } }
    function success(data) { return { type: checkpointsConstants.CHECKPOINTS_LOAD_SUCCESS, payload: data } }
    function failure(error) { return { type: checkpointsConstants.CHECKPOINTS_LOAD_FAILURE, error: error } }
}

export const atcCreateCheckPoints = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: checkpointsConstants.CREATE_CHECKPOINT_REQUEST, isLoading } }
    function success(data) { return { type: checkpointsConstants.CREATE_CHECKPOINT_SUCCESS, payload: data } }
    function failure(error) { return { type: checkpointsConstants.CREATE_CHECKPOINT_FAILURE, error: error } }
}

export const atcEditCheckPoints = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: checkpointsConstants.EDIT_CHECKPOINT_REQUEST, isLoading } }
    function success(data) { return { type: checkpointsConstants.EDIT_CHECKPOINT_SUCCESS, payload: data } }
    function failure(error) { return { type: checkpointsConstants.EDIT_CHECKPOINT_FAILURE, error: error } }
}

export const atcDeleteCheckPoint = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: checkpointsConstants.DELETE_CHECKPOINT_REQUEST, isLoading } }
    function success(data) { return { type: checkpointsConstants.DELETE_CHECKPOINT_SUCCESS, payload: data } }
    function failure(error) { return { type: checkpointsConstants.DELETE_CHECKPOINT_FAILURE, error: error } }
}

//GET ITEMS DROPDOWN
export const loadItemTypeForCheckPoint = (idParam, params, name) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data, name));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: checkpointsConstants.CHECKPOINT_LOAD_TYPE_REQUEST, isLoading } }
    function success(data) { 
        return { 
            type: checkpointsConstants.CHECKPOINT_LOAD_TYPE_SUCCESS,
            payload: data,
            typeItem: name
        }
    }
    function failure(error) { return { type: checkpointsConstants.CHECKPOINT_LOAD_TYPE_FAILURE, error: error } }
}