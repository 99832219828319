import React from 'react';

//components
import { HeaderInterventions, InterventionsBody } from '../../storyboard/common';

function TableInterventions(props) {
    const {
        index,
        indexCount,
        active,
        value,
    } = props && props;
    return (
        <div
            id={"sect-" + indexCount(index)}
            className={
                active === index
                    ? "accordionExam__content accordionExam__open"
                    : "accordionExam__content accordionExam__close"
            }
        >
            <HeaderInterventions
                {...props}
                index={index}
                value={value}
            />
            <table className="table tableMedications">
                <thead>
                    <tr className="m--font-brand">
                        <th className="text-left border-bottom-0">
                            Intervention
                        </th>
                        <th className="text-center border-bottom-0">Available</th>
                        <th
                            className="text-center border-bottom-0"
                            style={{ width: "25%" }}
                        >
                            Score
                        </th>
                        <th className="text-center border-bottom-0">Settings</th>
                    </tr>
                </thead>
                {/* /head */}
                <InterventionsBody
                    {...props}
                    dataInterventions={value?.Interventions || []}
                    value={value}
                />
            </table>
        </div>
    );
};

export default TableInterventions;