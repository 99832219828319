// TemplateSelect Component
import React from "react";

//react-select
import Select from "react-select";

//reactstrap
import { FormGroup, Label } from "reactstrap";

function TemplateSelect({ label, onChange, options, placeholder, value }) {
    return (
        <FormGroup>
            {label && <Label>{label}</Label>}
            <Select
                closeMenuOnSelect={true}
                onChange={(option) => onChange(option, "selectedTemplate")}
                options={options}
                placeholder={placeholder}
                value={value}
            />
        </FormGroup>
    );
}

export default TemplateSelect;