import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Col,
    Row
} from "reactstrap";

//components
import { CropImageAvatar } from ".";

//api
import { apiCaller } from "@utils";

const ModalAddAvatar = (props) => {
    const { isOpen, toggle, handleUpdateData, defaultFilter } = props && props;

    //state
    const [dataImageCrop, setDataImageCrop] = useState(null);
    const [validateImage, setValidateImage] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset
    } = useForm();

    // Reset data when modal is opened
    useEffect(() => {
        if (isOpen) {
            reset();
        }
    }, [isOpen, reset]);

    const onSubmit = (data) => {
        if (dataImageCrop === null) {
            setValidateImage(true);
            return;
        } else {
            const paramsTags = {
                "gender": data?.gender,
                "roletype": data?.roletype,
                "level": data?.level
            }
            const jsonTags = JSON.stringify(paramsTags);
            const formData = new FormData();
            formData.append("action_type", "upload_avatar");
            formData.append("name", data?.name);
            formData.append("image", dataImageCrop);
            formData.append("tags", jsonTags);
            formData.append("description", data?.description || '');

            setLoading(true);
            //Call Api
            apiCaller(`/api/character/avatar/`, "POST", formData).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setLoading(false);
                    handleUpdateData(data);

                    //Close & reset Data
                    toggle();
                    handleResetData();
                } else {
                    console.log('error');
                    toggle();
                    setLoading(false);
                    handleResetData();
                }
            });
        }
    }

    const handleResetData = () => {
        setDataImageCrop(null);
        reset();
    }

    // Render Options
    const renderOptions = (type) => {
        return (
            defaultFilter &&
            defaultFilter[type]?.map((option) => (
                <option value={option} key={option}>
                    {option}
                </option>
            ))
        );
    };

    //Handle Save Image Crop
    const handleCropImage = (blob) => {
        setDataImageCrop(blob);
        setValidateImage(false);
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '600px' }}>
                <ModalHeader toggle={toggle}>Add New Avatar</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label for="name">Name <span className="m--font-danger">*</span></Label>
                                <Input
                                    type="text"
                                    name="name"
                                    {...register("name", { required: true })}
                                    invalid={errors.name?.type === "required"}
                                    defaultValue={watch("name")}
                                    onChange={(e) => {
                                        setValue("name", e.target.value, { shouldValidate: true });
                                    }}
                                    placeholder=""
                                    autoComplete="off"
                                />
                                <FormFeedback invalid={String(errors.name?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="gender">
                                    Gender <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("gender", { required: true })}
                                    invalid={errors.gender?.type === "required"}
                                    defaultValue={watch("gender")}
                                    onChange={(e) => {
                                        setValue("gender", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="gender"
                                    id="gender"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('gender')}
                                </Input>
                                <FormFeedback invalid={String(errors.gender?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label for="Image">Image <span className="m--font-danger">*</span></Label>
                                <CropImageAvatar
                                    {...props}
                                    handleCropImage={handleCropImage}
                                    srcImage={false}
                                    handleChangeZoom={() => setValidateImage(true)}
                                    handleUploadFile={() => setValidateImage(false)}
                                />
                                {validateImage && (
                                    <FormFeedback invalid={"true"} className="d-block">
                                        Please select and crop an image.
                                    </FormFeedback>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="roletype">
                                    Role <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("roletype", { required: true })}
                                    invalid={errors.roletype?.type === "required"}
                                    defaultValue={watch("roletype")}
                                    onChange={(e) => {
                                        setValue("roletype", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="roletype"
                                    id="roletype"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('roletype')}
                                </Input>
                                <FormFeedback invalid={String(errors.roletype?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}
                        >    <FormGroup>
                                <Label for="level">
                                    Level <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("level", { required: true })}
                                    invalid={errors.level?.type === "required"}
                                    defaultValue={watch("level")}
                                    onChange={(e) => {
                                        setValue("level", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="level"
                                    id="level"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('level')}
                                </Input>
                                <FormFeedback invalid={String(errors.level?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label htmlFor="description">Description:</Label>
                                <Input
                                    {...register("description", { required: true })}
                                    type="textarea"
                                    name="description"
                                    onChange={(e) => {
                                        setValue("description", e.target.value, { shouldValidate: true });
                                    }}
                                    defaultValue={watch("description")}
                                    invalid={errors.description?.type === "required"}
                                    style={{ height: '100px' }}
                                />
                                <FormFeedback invalid={String(errors.description?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="primary"
                        className="btn d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading || validateImage}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalAddAvatar;