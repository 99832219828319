import React from 'react';

//boostrap
import {
    ModalBody,
    ModalFooter,
    Button,
    Modal,
} from "reactstrap";

function ModalDeleteTimepoint(props) {
    const { isOpen, toggle, hanldeRemove, isLoading } = props && props;
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <h4 className="text-center heading-4 --text-primary">
                    Are you certain that you would like to delete this time
                    point ?
                </h4>
                <p className="text-center">
                    This action is not reversible.
                    <br />
                    All data related to this time point will be removed
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn btn-danger d-flex align-items-center"
                    onClick={hanldeRemove}
                    disabled={isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Delete
                </Button>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
};

export default ModalDeleteTimepoint;