import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

//partents
import { FormModal } from '@ui-partents/Modal';

//DataEmty
import { DataEmty } from "@components/common";

function convertDataToArray(data) {
    const dataArray = [];
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            const levelName = key.split('_')[1] || key;
            const contentLevel = data[key];
            dataArray.push({
                level_name: levelName,
                content_level: contentLevel
            });
        }
    }
    return dataArray;
}

const ModalModification = ({ itemActive, isOpen, toggleModal }) => {
    const modificationData = itemActive?.modifications;
    const data_array = convertDataToArray(modificationData);

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const toggle = tabIndex => {
        setActiveTabIndex(tabIndex);
    }

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={`${itemActive?.food_name} - Modification`}
            actionText={"Save"}
            maxWidth="900px"
        >
            {data_array.length > 0 ? (
                <div className="tabsPills justify-content-start">
                    <Nav tabs className='flex-nowrap border-bottom pb-2' style={{overflowX: "auto", overflowY: "hidden"}}>
                        {data_array?.map((data, index) => (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames('text-nowrap', { active: activeTabIndex === index })}
                                    onClick={() => { toggle(index); }}
                                >
                                    Level {data.level_name}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={activeTabIndex}>
                        {data_array?.map((data, index) => (
                            <TabPane tabId={index} key={index}>
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: data.content_level }} />
                            </TabPane>
                        ))}
                    </TabContent>
                </div>
            ) : (
                <DataEmty content="No Data" />
            )}
        </FormModal>
    );
};

export default ModalModification;