import React from 'react';

//reactstrap
import { Input } from "reactstrap";

function TagsAdd(props) {
    const { onkeyUpdateTags } = props && props;

    return (
        <Input
            type="text"
            onKeyUp={event => event.key === "Enter" ? onkeyUpdateTags(event, 'addTag') : null}
            placeholder="Press enter to add tags"
            defaultValue={``}
            autoFocus
            className='mt-3'
            autoComplete="off"
        />
    );
};

export default TagsAdd;