import { associatedSchemaConstant } from "@constants";

export function getAssociatedSchema(state = {}, action) {
    switch (action.type) {
        case associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case associatedSchemaConstant.ADD_ASSOCIATED_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case associatedSchemaConstant.ORDER_ASSOCIATED_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case associatedSchemaConstant.DELETE_ASSOCIATED_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};