import { coinConstant } from "@constants";
import {apiCaller} from "@utils";

//Update Assistant Tip Record
export const getCoin = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/billing/coin/`, 'GET', null).then(res => {
          const data = res && res.data;
          if(res && res.status === 200) {
            dispatch(success(data));
          } else {
            dispatch(failure("error"));
          }
        });
    };
    function request(isLoading) { return { type: coinConstant.GET_COIN_REQUEST, isLoading } }
    function success(data) { return { type: coinConstant.GET_COIN_SUCCESS, payload: data } }
    function failure(error) { return { type: coinConstant.GET_COIN_FAILURE, error: error } }
}

export const getCoinMcq = (params) => {
  const isLoading = true;
  return dispatch => {
      dispatch(request(isLoading));
      return apiCaller(`/api/billing/coin_ui/`, 'POST', params).then(res => {
        const data = res && res.data;
        if(res && res.status === 200) {
          dispatch(success(data));
        } else {
          dispatch(failure("error"));
        }
      });
  };
  function request(isLoading) { return { type: coinConstant.GET_COIN_MCQ_REQUEST, isLoading } }
  function success(data) { return { type: coinConstant.GET_COIN_MCQ_SUCCESS, payload: data } }
  function failure(error) { return { type: coinConstant.GET_COIN_MCQ_FAILURE, error: error } }
}

export const getCoinCaseScript = (params) => {
  const isLoading = true;
  return dispatch => {
      dispatch(request(isLoading));
      return apiCaller(`/api/billing/coin_ui/`, 'POST', params).then(res => {
        const data = res && res.data;
        if(res && res.status === 200) {
          dispatch(success(data));
        } else {
          dispatch(failure("error"));
        }
      });
  };
  function request(isLoading) { return { type: coinConstant.GET_COIN_CASE_SCRIPT_REQUEST, isLoading } }
  function success(data) { return { type: coinConstant.GET_COIN_CASE_SCRIPT_SUCCESS, payload: data } }
  function failure(error) { return { type: coinConstant.GET_COIN_CASE_SCRIPT_FAILURE, error: error } }
}