import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Image and Modal components
import MessageImage from '@images/message/message_case_access.png';
import { ModalResetCase } from '@components/common/ResetAndBack';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  background: #fff;
  ${({ fullScreen }) => 
    fullScreen && `
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
    `
  }
`;

const ContentWrapper = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 24px 15px;
`;

const MessageTitle = styled.h3`
  color: #121926;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
`;

const MessageText = styled.p`
  color: #697586;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const ImageContainer = styled.div`
  max-width: 194px;
  margin: 0 auto 16px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const MessageDisplay = ({ imageSrc, title, text, accessDeniedCase, statusCase, fullScreen = false }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const showResetButton = statusCase?.ResetButton;
  const showExitButton = statusCase?.ExitButton;

  return (
    <Container fullScreen={fullScreen}>
      <ContentWrapper>
        <ImageContainer>
          <Image src={imageSrc || MessageImage} alt="Message Image" />
        </ImageContainer>
        <MessageTitle>{title || 'Oops! Access Denied'}</MessageTitle>
        <MessageText>{text || 'You don’t have permission to access this content.'}</MessageText>
        {accessDeniedCase && (
          <Link
            className='mt-5 mx-auto btn btn-primary btn-lg d-flex align-items-center justify-content-center'
            style={{ maxWidth: '353px' }}
            to={'/'}
          >
            {`Return to Home`}
          </Link>
        )}
        <div className="mx-auto">
          {(showResetButton || showExitButton) && (
            <ButtonContainer>
              {showResetButton && (
                <button
                  className='mt-3 btn btn-primary btn-lg d-flex align-items-center justify-content-center'
                  onClick={toggleModal}
                >
                  {`Reset Case`}
                </button>
              )}
              {showExitButton && (
                <Link className='mt-3 btn btn-secondary btn-lg d-flex align-items-center justify-content-center' to='/learn/home'>
                  {`Exit to Home`}
                </Link>
              )}
            </ButtonContainer>
          )}
        </div>
      </ContentWrapper>

      {/* Modal for resetting the case */}
      <ModalResetCase
        isOpen={modalOpen}
        toggle={toggleModal}
        isView="case"
      />
    </Container>
  );
};

export default MessageDisplay;