import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//table
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actUpdateValueBalance } from "@actions";

//lodash
import _ from 'lodash';

//elements
import { ButtonDelete } from '@components-common/elements';

//components common with VitalSigns
import { ReferenceRange } from '../../VitalSigns';
import { ModalDeleteIO, ModalAddIO } from '../IOTableBlock';

//components
import { HeadingTable } from '.';

function IOTable(props) {

  //props
  const { timePointId, dataBalance, titleBlock, buttonText, typeBalance, isView } = props && props;

  //state
  let { id } = useParams();
  const dispatch = useDispatch();
  const [stateData, setData] = useState([]);
  const [AttributeId, setAttributeId] = useState('');
  const [modal, setModal] = useState({ add: false, delete: false });

  useEffect(() => {
    const convertData = dataBalance
      ?.map((value) => ({
        Id: value.Id,
        Name: value.Name,
        Initial_Value: value.Value,
        Unit: value.Unit === "C"
          ? "℃"
          : value.Unit,
        Reference_Range: `${value.NormalMin} ${value.Unit === "C"
          ? "℃"
          : value.Unit} - ${value.NormalMax} ${value.Unit === "C"
            ? "℃"
            : value.Unit}`,
        NormalMax: value.NormalMax,
        NormalMin: value.NormalMin
      }));
    setData(convertData);
  }, [dataBalance]);

  const toggle = (type, value) => {
    setModal((prevState) => ({
      ...prevState,
      [type]: !modal[type]
    }));
    setAttributeId(value);
  };

  //table
  const columns = [
    {
      dataField: "Id",
      text: "Id",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    }, {
      dataField: "Name",
      text: "Item",
      editable: false,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    }, {
      dataField: "Initial_Value",
      text: "initial Value",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    }, {
      dataField: "Unit",
      text: "Unit",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle"
    }, {
      dataField: "Reference_Range",
      text: "Reference Range",
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle",
      style: {
        width: '300px'
      },
      editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (<ReferenceRange
        handleSaveReferenceRange={handleSaveReferenceRange}
        {...editorProps}
        row={row} />)
    }, {
      dataField: "Actions",
      text: "",
      editable: false,
      headerClasses: "--text-primary font-weight-500",
      classes: "align-middle",
      style: {
        width: "50px",
        textAlign: "center"
      },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <ButtonDelete handleDelete={() => toggle("delete", row.Id)} />
          </React.Fragment>
        );
      }
    }
  ];

  const afterSaveCell = (oldValue, newValue, row, column) => {
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      IO: {
        IOType: typeBalance,
        Id: row && row.Id,
        Value: row && row.Initial_Value,
        Unit: row && row.Unit
      }
    }
    dispatch(actUpdateValueBalance(id, params));
  };

  const handleSaveReferenceRange = (data) => {
    //update object by Id
    const newData = {
      ...data
    }
    const ReferenceFormat = `${data.NormalMin} ${data.Unit === "C"
      ? "℃"
      : data.Unit} - ${data.NormalMax} ${data.Unit === "C"
        ? "℃"
        : data.Unit}`;
    newData.Reference_Range = ReferenceFormat

    //Clone data and update state
    const newArrUpdate = _.cloneDeep(dataBalance);
    const indexData = _.findIndex(newArrUpdate, function (o) {
      return o.Id === data.Id
    });
    newArrUpdate[indexData] = newData;
    setData(newArrUpdate);

    //Call API
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      VitalSign: {
        Id: data && data.Id,
        NormalMax: data && data.NormalMax,
        NormalMin: data && data.NormalMin
      }
    };
    dispatch(actUpdateValueBalance(id, params));
  }
  
  return (
    <React.Fragment>
      {isView === 'elements' && titleBlock ? (
        <HeadingTable title={titleBlock} />
      ) : (
        <HeadingTable buttonText={buttonText} title={titleBlock} handleAdd={() => toggle("add", '')} />
      )}
      <BootstrapTable
        keyField="Id"
        data={(stateData && stateData) || []}
        columns={columns}
        bordered={ false }
        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell })}
      />
      <ModalDeleteIO
        isOpen={modal.delete}
        toggleDelete={() => toggle("delete", AttributeId)}
        AttributeId={AttributeId}
        typeBalance={typeBalance}
        {...props}
      />
      <ModalAddIO
        isOpen={modal.add}
        toggleAdd={() => toggle("add", '')}
        AttributeId={AttributeId}
        typeBalance={typeBalance}
        {...props}
      />
    </React.Fragment>
  );
}

export default IOTable;