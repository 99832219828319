const stepData = {
    addCase: [
        { "step": 1, "name": "Enter Basic Info" },
        { "step": 2, "name": "CHOOSE TEMPLATE" },
        { "step": 3, "name": "ENTER CASE SCRIPT" },
        { "step": 4, "name": "START EDITING" }
    ],
    addCaseV2: [
        { "step": 1, "name": "Basic Information" },
        { "step": 2, "name": "Main Teaching Points" },
        { "step": 3, "name": "Set Up Contents" },
        { "step": 4, "name": "START EDITING" }
    ]
}

export default stepData;