import React from 'react'
import { Iconsax } from "@components-common";

export default function BtnSetAvailable(props) {
  const { colorIcon, handleClick, text } = props

  return (
    <div className='btn-available d-flex align-items-center' onClick={handleClick}>
      <Iconsax iconName="upload-cloud" fill={colorIcon || "#344054"} size={20} />
      {text && <span className="available-text">{text}</span>}
    </div>
  )
}
