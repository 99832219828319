import React from "react";
import imgLogo from '@images/common/med2lab_logo.png';

function AuthLogoHeader(props) {
    return (
        <header className="auth-header">
            <div className="loginPage-header">
                <img src={imgLogo} alt="logo" />
            </div>
        </header>
    );
}

export default AuthLogoHeader;