import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from "reactstrap";

function MessageButton({ ButtonText }) {
    const history = useHistory();

    const handleClickButton = () => {
        history.push("/");
    };

    return (
        <div className="message-answer__button ml-auto">
            <Button color='primary' size='lg' className='px-5' onClick={handleClickButton}>
                {ButtonText}
            </Button>
        </div>
    );
}

export default MessageButton;