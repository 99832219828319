import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import TextareaAutosize from "react-textarea-autosize";
import Iconfavorite from "@images/learnerv2/message-favorite.png";
import { Button, FormFeedback } from "reactstrap";
import { EmojiList } from ".";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";

function FeedbackContent(props) {
    let { id } = useParams();
    const { hanldeToggleAdvance } = props;

    const [formData, setFormData] = useState({
        OverallImpression: null,
        Comments: ""
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        new WOW.WOW({ live: false }).init();
    }, []);

    const validateForm = () => {
        let formErrors = {};

        if (!formData.OverallImpression) {
            formErrors.OverallImpression = "Please select an emoji.";
        }

        if (!formData.Comments || formData.Comments.trim() === "") {
            formErrors.Comments = "Comments are required.";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleCommentChange = e => {
        setFormData({ ...formData, Comments: e.target.value });

        // Xóa bỏ lỗi nếu người dùng đã nhập giá trị
        if (e.target.value.trim() !== "" && errors.Comments) {
            setErrors(prevErrors => {
                let newErrors = { ...prevErrors };
                delete newErrors.Comments;
                return newErrors;
            });
        }
    };

    const handleEmojiChange = emoji => {
        setFormData({ ...formData, OverallImpression: emoji });

        // Xóa bỏ lỗi nếu đã chọn một emoji
        if (errors.OverallImpression) {
            setErrors(prevErrors => {
                let newErrors = { ...prevErrors };
                delete newErrors.OverallImpression;
                return newErrors;
            });
        }
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);

        try {
            const res = await apiCaller(`/api/learn/case/learner_feedback/${id}/`, 'PUT', formData);
            if (res?.status === 200) {
                hanldeToggleAdvance("ThankYou");
                hanldeToggleAdvance("userFeedback");
            } else {
                hanldeToggleAdvance("ThankYou");
                hanldeToggleAdvance("userFeedback");
                console.log("Error submitting feedback");
            }
        } catch (error) {
            console.log("Error:", error);
        }

        setLoading(false);
    };

    return (
        <div className="feedback-inner-ct">
            <div className="alert-box-orange">
                <img src={Iconfavorite} alt={`message`} width={32} height={32} />
                <span className="ml-1">Please share your thoughts on the case before diving into the specific analysis! 🎤 </span>
            </div>
            <div className="question-formCt">
                <EmojiList {...props} onEmojiChange={handleEmojiChange} formData={formData} />
                {errors.OverallImpression && (
                    <FormFeedback className="d-block text-center">
                        {errors.OverallImpression}
                    </FormFeedback>
                )}
                <div className="question-fb-group">
                    <p className="question-title">Can you share a brief note on your thoughts or feelings about the case?</p>
                    <TextareaAutosize
                        name="Comment"
                        placeholder="Enter your sharing here!"
                        className="w-100 form-control"
                        maxRows="8"
                        minRows={5}
                        onChange={handleCommentChange}
                    />
                    {errors.Comments && (
                        <FormFeedback className="d-block">
                            {errors.Comments}
                        </FormFeedback>
                    )}
                </div>
            </div>
            <div className="btn-Footer">
                <Button
                    color='primary'
                    className="btn-brand-sm -btn-lg w-100 -shadown"
                    disabled={isLoading}
                    onClick={handleSubmit}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Next
                </Button>
            </div>
        </div>
    );
}

export default FeedbackContent;