import React from 'react';

//components
import { IntakeComponent, SummaryIntakeOutput } from "./component";

const ContentIntakeOutput = () => {
  return (
    <React.Fragment>
      <SummaryIntakeOutput />
      <IntakeComponent
        title="Intake"
      />
      <IntakeComponent 
        title="Output"
      />
    </React.Fragment>
  );
};

export default ContentIntakeOutput;