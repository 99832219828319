import React, { useState } from "react";
import { useSelector } from "react-redux";
import IconNote from "@images/learnerv2/icon-taks-note.svg";

//components
import { ModalReferences } from "@components/learner/StandardScreen/References";
import { LockView } from "../AssistantQuestion";

const MenuActionFixed = (props) => {

    //props
    const { caseData } = props;

    //state
    const [modal, setModal] = useState({ References: false });

    //Check Lock Elements
    const isChatPending = useSelector((state) => state.isChatPending || {});
    const isLock = isChatPending?.isLock;

    //toggle
    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const dataReferences = caseData?.References || {};
    return (
        <React.Fragment>
            <div className="nav-task-fixed d-flex align-items-center justify-content-center">
                {dataReferences?.length > 0 && (
                    <NavItem onClick={() => toggle("References")} icon={IconNote} alt="References" lockViewFixed={isLock} />
                )}
            </div>
            <ModalReferences
                {...props}
                isOpen={modal.References}
                toggle={() => toggle("References")}
            />
        </React.Fragment>
    );
};

// Component for individual nav item
const NavItem = ({ onClick, icon, alt, lockViewFixed }) => {
    return (
        <div
            className="nav-task-fixed__item position-relative"
            onClick={!lockViewFixed ? onClick : undefined} // Chỉ gọi onClick khi lockViewFixed là false
        >
            <div className="nav-task-fixed__item--image">
                <img src={icon} alt={alt} />
            </div>
            {lockViewFixed && <LockView />}
        </div>
    );
};

export default React.memo(MenuActionFixed);