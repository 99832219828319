import React from "react";
import { TabContent, TabPane } from "reactstrap";

//common components
import { HeadTabs } from "../../storyboard/common";

// Editor
import RichTextCommon from '../RichTextCommon';

//components
import { ExamPointContainer } from "../../storyboard/PhysicalExam";
import { DragQuestion } from "../../storyboard/Question";

function PhysicalExamMain(props) {
    const {
        listTabs,
        value,
        activeTabCt,
        handleUpdateEditor,
        valueEditor,
        timePointId,
        filterQuestionForTask
    } = props && props;
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='exam'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.exam?.map(
                    (item, index) =>
                        item.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item.id === "1" && <ExamPointContainer {...props} />}
                                {item.id === "2" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={value?.ViewClass}
                                        defaultValue={valueEditor?.exam}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item.id === "3" && (
                                    <DragQuestion
                                        {...props}
                                        task={value.ViewClass}
                                        data={filterQuestionForTask(value.ViewClass)}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
}

export default PhysicalExamMain;