import React from "react";
import styled from "styled-components";

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomIcon = ({onSubmitWIcon}) => (
  <IconWrapper onClick={onSubmitWIcon}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.833 6.17463L8.10953 11.9592L1.59992 7.88767C0.667239 7.30414 0.861254 5.88743 1.91621 5.57893L17.3717 1.05277C18.3377 0.769629 19.233 1.67283 18.9461 2.642L14.3736 18.0868C14.0603 19.1432 12.6517 19.3319 12.0737 18.3953L8.10649 11.9602"
        fill="#F79E1C"
      />
      <path
        d="M13.833 6.17463L8.10953 11.9592L1.59992 7.88767C0.667239 7.30414 0.861254 5.88743 1.91621 5.57893L17.3717 1.05277C18.3377 0.769629 19.233 1.67283 18.9461 2.642L14.3736 18.0867C14.0603 19.1432 12.6517 19.3319 12.0737 18.3953L8.10649 11.9602"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);

export default CustomIcon;