import React from "react";

//boostrap
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

//icon
import succsessEnrollImage from "@images/weitzman/pic_succsess_enroll.png";

function ModaEnrollmentSuccessful(props) {
  //props
  const { isOpen, toggle } = props && props;
  return (
    <Modal
      className="modal-custom modal-size-lg"
      zIndex={16000}
      isOpen={isOpen}
      backdrop={`static`}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
    >
      <ModalBody>
        <div className="modal-inner">
          <div className="modal-inner">
            <div className="weitzman-enrollment-ct">
              <div className="weitzman-enrollment-ct__img">
                <img src={succsessEnrollImage} alt={`Enrollment Successful!`} />
              </div>
              <div className="weitzman-enrollment-ct__details">
                <h2 className="headline --size-4 mb-4 text-center">
                  Enrollment Successful!
                </h2>
                <p className="desc text-center">
                  Congratulations! You have successfully enrolled in <br/>
                  <span>Improving COVID-19 Care for High-Risk, Vulnerable Populations through Case-based Simulation Modules</span><br/>
                  by the Weitzman Institute. Let’s dive right in!
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ width: "276px" }}>
          <Button
            color='primary'
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={toggle}
          >
            {`Explore Our Cases`}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModaEnrollmentSuccessful;