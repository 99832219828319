import React, { useState } from 'react';
import { TruncatedText } from "@components-common";

const DomainList = ({ item }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const { Domains } = item;
    const selectedDomains = Domains.filter(domain => domain.Selected);
    const visibleDomains = selectedDomains.slice(0, 2);
    const additionalCount = Math.max(0, selectedDomains.length - 2);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    return (
        <React.Fragment>
            {visibleDomains?.length > 0 &&
                <React.Fragment>
                    <ul className="domain-task">
                        {visibleDomains.map((value, i) => (
                            <li className="domain-task__item" key={i}>
                                <span className="domain-task__item--text">
                                    <TruncatedText str={value.Name} maxLength={10} />
                                </span>
                            </li>
                        ))}
                        {/* Hiển thị dấu "+" và dropdown khi cần */}
                        {additionalCount > 0 && (
                            <li
                                className="domain-task__item"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <span className="domain-task__item--text">
                                    +{additionalCount}
                                </span>
                                {showDropdown && (
                                    <div className="domain-task-dropdown">
                                        {selectedDomains.slice(2).map((value, index) => (
                                            <div className="domain-task-dropdown__item" key={index}>
                                                <TruncatedText str={value.Name} maxLength={10} />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </li>
                        )}
                    </ul>

                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default DomainList;