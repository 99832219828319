import React, { useState } from 'react';
// Validate, Form
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label } from 'reactstrap';
// react-router-dom
import { useHistory } from "react-router-dom";
import { apiCaller } from "@utils";
import ClipLoader from "react-spinners/ClipLoader";
// Icon
import IconClose from "@images/auth/x-circle.svg";

// More comprehensive email validation regex
const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .matches(emailValidationRegex, 'Please enter a valid email address.')
    .required('Invalid email. Please try again.'),
});

const FormEmailResetPassWord = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleSubmit = async (data) => {
    setLoading(true);
    const endpoint = "/api/auth/reset_password/";

    try {
      const response = await apiCaller(endpoint, "POST", {
        Email: data.email,
        "Action": "SendOTP"
      });

      const dataResponse = response?.data;
      if (dataResponse?.status === "OTP_sent") {
        // Handle success response
        setError(null);
        history.push(`/auth/check-otp?email=${data.email}`);
      } else {
        setError(dataResponse?.error);
      }
    } catch (error) {
      setError("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="wrap-login">
      <div className="wrap-login__title">
        <h1>Reset Your Password</h1>
        <p>Enter your email to receive a password reset code.</p>
      </div>
      <div className="wrap-login__form">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={EmailSchema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue
          }) => (
            <Form className='form-custom'>
              <div className='form-custom__wrap'>
                <div>
                  <Label for="exampleEmail">
                    Email <span>*</span>
                  </Label>
                  <div className="form-input-icon">
                    <Input
                      className={`${errors.email && touched.email ? "error" : ""}`}
                      id="exampleEmail"
                      type="email"
                      name="email"
                      placeholder='Enter your email address'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {values.email && (
                      <img
                        className='form-input-icon__icn'
                        src={IconClose}
                        alt="close"
                        onClick={() => setFieldValue('email', '')}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  </div>
                  <span className="text-err">
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                {error && <div className="text-err">{error}</div>}
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                <Button
                  type="submit"
                  color='primary'
                  className='w-50 py-3 d-flex align-items-center justify-content-center'
                  disabled={loading}
                >
                  Reset Password
                  {loading && (
                    <div className='ml-1 d-flex align-items-center'>
                      <ClipLoader color="#FFF" size={16} />
                    </div>
                  )}
                </Button>
              </div>
              <div className="redirect-page">
                <a href="/login">Back to Login</a>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FormEmailResetPassWord;