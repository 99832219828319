import React, { useState } from "react";
import { Alert } from "reactstrap";

function QuestionExplanation(props) {
  const { Explanation } = props && props;

  //state
  const [isOpen, setOpen] = useState(false);
  
  return (
    <React.Fragment>
      {Explanation && (
        <React.Fragment>
          <button
            className="btn btn-sm my-4 btn-info"
            onClick={() => setOpen(!isOpen)}
          >
            See Explanation
            <i className={`fa ${isOpen ? "fa-angle-up": "fa-angle-down"} ml-2`} aria-hidden="true"></i>
          </button>
          {isOpen && (
            <Alert className="m-alert--outline m-alert--info">
              <div className={`whiteSpace-break`} dangerouslySetInnerHTML={{ __html: Explanation }} />
            </Alert>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default QuestionExplanation;