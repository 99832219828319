import React from 'react'
import styled from 'styled-components'
import { FormGroup, Input, Label } from 'reactstrap'

const WrapResultExplan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 8px 10px;
  .answer {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #101828;
    &.correct-answer {
      color: #079455;
    }
  }
  .text-explanation {
    padding-left: 20px;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export default function ItemAnswer(props) {
  const { choice, setValue } = props
  return (
    <WrapResultExplan>
      {/* <div className='d-flex align-items-center justify-content-between'> */}
        {/* <p className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} answer`}>
          {choice.id}. {choice.content}
        </p>
        {choice.id !== question?.best_answer?.id && (
          <div onClick={handleShowData}>
            {showData ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
          </div>
        )} */}
        <FormGroup check className='form-common' onChange={e => setValue("answer", e?.target?.value)}>
          <Input className="react-radio-common react-radio-md" value={`${choice.id}.${choice.content}`} type="radio" name="answers"/>
          <Label check>{choice.id}. {choice.content}</Label>
        </FormGroup>
      {/* </div> */}
    </WrapResultExplan>
  )
}
