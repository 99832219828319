// FormPage.js
import {
    ButtonComponent
} from "@components/patterns/elements/Button";

import React from 'react';
import { Row, Col } from 'reactstrap';

const FormPage = () => {
    return (
        <section className="content-component mb-5">
            <Row className='mb-4'>
                <Col md={12}>
                    <div className="content-header">
                        <h1>Buttons</h1>
                    </div>
                </Col>
            </Row>
            {/* Primary Buttons */}
            <Row className="d-flex align-items-center">
                <Col md={4}>
                    <ButtonComponent size="small">Small Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent>Medium Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent size="large">Large Button</ButtonComponent>
                </Col>
            </Row>
            {/* Danger Buttons */}
            <Row className='mt-4 d-flex align-items-center'>
                <Col md={4}>
                    <ButtonComponent size="small" color="danger">Small Danger Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent color="danger">Medium Danger Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent size="large" color="danger">Large Danger Button</ButtonComponent>
                </Col>
            </Row>
            {/* Info Buttons */}
            <Row className='mt-4 d-flex align-items-center'>
                <Col md={4}>
                    <ButtonComponent size="small" color="info">Small Info Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent color="info">Medium Info Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent size="large" color="info">Large Info Button</ButtonComponent>
                </Col>
            </Row>
            {/* Outline Buttons */}
            <Row className='mt-4 d-flex align-items-center'>
                <Col md={4}>
                    <ButtonComponent size="small" color="outline">Small Outline Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent color="outline">Medium Outline Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent size="large" color="outline">Large Outline Button</ButtonComponent>
                </Col>
            </Row>
            {/* Disabled Buttons */}
            <Row className='mt-3 d-flex align-items-center'>
                <Col md={4}>
                    <ButtonComponent size="small" color="danger" disabled>Small Disabled Danger Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent color="danger" disabled>Medium Disabled Danger Button</ButtonComponent>
                </Col>
                <Col md={4}>
                    <ButtonComponent size="large" color="danger" disabled>Large Disabled Danger Button</ButtonComponent>
                </Col>
            </Row>
        </section>
    );
};

export default FormPage;