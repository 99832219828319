import { schemaContanst } from "@constants";

//load Schema
export function dataSchema(state = {}, action) {
    switch (action.type) {
        case schemaContanst.GET_SCHEMA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case schemaContanst.GET_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.ADD_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.DELETE_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.UPDATE_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.UPDATE_CONCEPT_FOR_TAG_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.SORT_CONCEPTS_IN_A_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.GET_SCHEMA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

//Load Question
export function dataQuestionForSchema(state = {}, action) {
    switch (action.type) {
        case schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

//Load Search
export function dataSearchSchema(state = {}, action) {
    switch (action.type) {
        case schemaContanst.SEARCH_CONCEPT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case schemaContanst.SEARCH_CONCEPT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.SEARCH_CONCEPT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

// Case - Test Question
export function dataTestQuestion(state = {}, action) {
    switch (action.type) {
        case schemaContanst.SUBMIT_TEST_QUESTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case schemaContanst.SUBMIT_TEST_QUESTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case schemaContanst.SUBMIT_TEST_QUESTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};