import React, { useState, useEffect, useCallback } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { apiCaller } from "@utils";

function ActionTypeCondition({ handleUpdateFlowControl, timepointData, handleChangeStatusByCondition, isCondition }) {
    const timePointId = timepointData?.Id;
    const { id } = useParams();

    // state
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [status, setStatus] = useState("taken");
    const [loading, setLoading] = useState(false);

    const fetchActions = useCallback(async () => {
        const params = {
            Action: "Load",
            Target: "Actions",
            TimePointId: timePointId,
        };

        try {
            setLoading(true);
            const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
            if (res?.status === 200 && res?.data) {
                const sortedData = res.data.sort((a, b) => a.Name.localeCompare(b.Name));
                const mergedInterventions = sortedData.reduce((acc, obj) => acc.concat(obj.Interventions), []).sort((a, b) => a.Id - b.Id);
                const listOptions = mergedInterventions.map(item => ({
                    value: item.Id,
                    label: `${item.Name} (#${item.Id})`,
                }));
                setOptions(listOptions);
            } else {
                console.error("Failed to load actions");
            }
        } catch (error) {
            console.error("API call failed", error);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchActions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        handleUpdateFlowControl(isCondition, data);
    };

    const handleChangeStatus = (e) => {
        const { value } = e.target;
        setStatus(value);
        handleChangeStatusByCondition(value);
    };

    const statusOptions = [
        { value: "taken", label: "is taken" },
        { value: "not_taken", label: "is NOT taken" },
    ];

    return (
        <React.Fragment>
            <div className="my-3">
                <CreatableSelect
                    closeMenuOnSelect
                    options={options}
                    placeholder="Click to select chat action(s)"
                    value={selectedOption}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                    isLoading={loading}
                    isDisabled={loading}
                />
            </div>
            <p>
                <small className="text-muted text-justify" style={{ fontSize: "12px" }}>
                    (To NOT impose a condition, simply not select a question)
                </small>
            </p>
            {statusOptions?.map((option) => (
                <FormGroup check className="mb-2" key={option.value}>
                    <Label check>
                        <Input
                            type="radio"
                            value={option.value}
                            name="Status"
                            onChange={handleChangeStatus}
                            checked={status === option.value}
                        />
                        {option.label}
                    </Label>
                </FormGroup>
            ))}
        </React.Fragment>
    );
}

export default ActionTypeCondition;