import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Label, Badge } from "reactstrap";

//lodash
import _ from "lodash";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//common
import { DataEmty, HeadingBox } from "@components/common";

//redux
import { useDispatch, useSelector } from "react-redux";

//untils
import { LoaderPage, messageData} from "@utils";

// common
import { ModalDelete, ModalInfoEnroll } from "@components/teach/Modal";

//api
import { managersConstant } from "@constants";
import {apiCaller} from "@utils";

//call Action
import {
  loadCandidatesOfManagers,
  actLoadManagers,
  actRemoveManagers,
} from "@actions";

//components
import { FormSearch } from '@components/Collaborators';

//elements
import { ButtonDelete } from '@components/common/elements';

function CollaboratorsPage(props) {
  const { caseDetails } = props && props;
  
  let { id } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    delete: false,
    confirm: false,
  });
  const [objectDel, setObjectDel] = useState({});

  const [isLoading, setLoading] = useState(false);
  const [dataConfirm, setDataConfirm] = useState({});
  const [isEnroll, setEnroll] = useState(false);
  const [listInvitedEmails, setInvitedEmails] = useState([]);

  const toggle = (type, data) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setObjectDel(data);
  };

  useEffect(() => {
    //load list
    const fetchData = async () => {
      dispatch(actLoadManagers(id));
    };

    //load list invite
    const fetchDataEnroll = async () => {
      dispatch(
        loadCandidatesOfManagers(id, {
          Action: "candidates",
        })
      );
    };

    fetchDataEnroll();
    fetchData();
  }, [dispatch, id]);

  const handleRemove = () => {
    const params = {
      Action: "remove",
      Emails: [objectDel.email],
    };
    dispatch(actRemoveManagers(id, params));
    toggle("delete", {});

    //load Candidates
    loadCandidates();
  };

  const loadCandidates = () => {
    //load list invite
    dispatch(
      loadCandidatesOfManagers(id, {
        Action: "candidates",
      })
    );
  };

  const handleConfirm = () => {
    //load list
    dispatch(actLoadManagers(id));

    //load Candidates
    loadCandidates();

    //set Modal
    setModal((prevState) => ({ ...prevState, confirm: false }));
    setEnroll(false);
  };

  const handleChangeData = (data) => {
    setInvitedEmails(data);
  }

  const submitEnroll = () => {
    //Convert Array to String
    if (listInvitedEmails.length > 0) {
      var arrEmails = _.map(listInvitedEmails, function (element, idx) {
        return element.value;
      });
    }

    const params = {
      Action: "add",
      Emails: arrEmails || [],
    };

    //Call Api Case - Case Managers - Add
    setLoading(true);
    dispatch({
      type: managersConstant.CASE_MANAGERS_ADD_REQUEST,
      isLoading: true,
    });
    apiCaller(`/api/teach/managers/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      const InvitedEmails = data && data.InvitedEmails;
      const AddedUsers = data && data.AddedUsers;
      if (res && res.status === 200) {
        dispatch({
          type: managersConstant.CASE_MANAGERS_ADD_SUCCESS,
          payload: data,
        });
        setLoading(false);
        setEnroll(true);
        if (
          (AddedUsers && AddedUsers.length > 0) ||
          (InvitedEmails && InvitedEmails.length > 0)
        ) {
          setDataConfirm(data);
          setModal((prevState) => ({ ...prevState, confirm: true }));
        }
      } else {
        dispatch({
          type: managersConstant.CASE_MANAGERS_ADD_FAILURE,
          error: "error",
        });
      }
    });
  };

  const columns = [
    {
      dataField: "Id",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "lastname",
      text: "Last Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "firstname",
      text: "First Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: "--text-primary font-weight-500",
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerClasses: "--text-primary align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      formatter: (value, row) => {
        const checkStatus = (status) => {
          let statusBlock = "";
          switch (status) {
            case "Active":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="success"
                >
                  {value}
                </Badge>
              );
              break;
            default:
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="danger"
                >
                  Pending ...
                </Badge>
              );
          }
          return statusBlock;
        };
        return <div>{checkStatus(value)}</div>;
      },
    },
    {
      dataField: "Actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
      headerClasses: "--text-primary font-weight-400 align-mid5le",
      formatter: (cell, row) => {
        return (
           <ButtonDelete handleDelete={() => toggle("delete", row)} />
        );
      },
    },
  ];

  //get data store
  const managerDataStore = useSelector((state) => state.managers || []);
  const managerData = managerDataStore && managerDataStore.data;
  const { SearchBar } = Search;
  const dataCandidatesForManagers = useSelector(
    (state) =>
      (state.loadCandidatesForManagers &&
        state.loadCandidatesForManagers.data) ||
      []
  );
  
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -collaboratorsPage">
      {managerDataStore.isLoading && <LoaderPage />}
      {!managerDataStore.isLoading && (
        <React.Fragment>
          <div className="learnersCt">
            <Container>
              <HeadingBox title={titlePage || ''} currentPage="Collaborators" />
              <FormSearch
                dataCandidatesForManagers={dataCandidatesForManagers}
                submitEnroll={submitEnroll}
                isLoading={isLoading}
                isEnroll={isEnroll}
                listInvitedEmails={listInvitedEmails}
                handleChangeData={handleChangeData}
              />
              <div className="boxPortlet">
                <ToolkitProvider
                  keyField="Id"
                  data={managerData || []}
                  columns={columns}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <Row className="justify-content-start mb-3 mt-3">
                        <Col md={6} sm={6}>
                          <div className="formSearchInline">
                            <Label for="search" className="mr-2 mb-0">
                              Search:
                            </Label>
                            <SearchBar
                              {...toolkitprops.searchProps}
                              placeholder="Enter name or email"
                            />
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        condensed
                        striped
                        wrapperClasses="table-responsive"
                        bordered={false}
                        noDataIndication={() => (
                          <DataEmty content={messageData?.filter} />
                        )}
                        {...toolkitprops.baseProps}
                      />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </Container>
          </div>
        </React.Fragment>
      )}
      <ModalDelete
        data={objectDel}
        toggleDel={() => toggle("delete", objectDel)}
        isOpen={modal.delete}
        handleRemove={handleRemove}
        type={`Collaborators`}
        title={`Are you sure you’d like to remove this manager account from the case?`}
        desc={`The account will no longer have access to the case editor, settings, and learners’ information.`}
      />
      <ModalInfoEnroll
        isOpen={modal.confirm}
        toggle={() => toggle("confirm", {})}
        data={dataConfirm}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

export default CollaboratorsPage;
