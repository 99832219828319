import React, { useState } from "react";
import { useParams } from "react-router-dom";

//Boostrap
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//components
import { DomainsDropdown } from '../Domains';

//api
import { apiCaller } from "@utils";
import { teachConstants } from "@constants";

function ModalCopyDomain(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { toggle, isOpen } = props && props;

    //state
    const [dataCopy, setDataCopy] = useState({});
    const [isLoading, setLoading] = useState(false);

    //set data
    const handleDataCopy = (data) => {
        setDataCopy(data);
    }

    //handle Api
    const handleSubmit = () => {
        setLoading(true);
        const params = {
            "Action": "Create",
            "Target": "Domain",
            "SourceCaseId": dataCopy?.value
        }

        //Call Api
        const isLoading = true;
        //Call Api
        dispatch({ type: teachConstants.COPY_DOMAINS_FROM_SOURCE_CASE_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: teachConstants.COPY_DOMAINS_FROM_SOURCE_CASE_SUCCESS, payload: data });
                setLoading(false);
                toggle();
            } else {
                dispatch({
                    type: teachConstants.COPY_DOMAINS_FROM_SOURCE_CASE_FAILURE,
                    error: "error",
                });
                setLoading(false);
                toggle();
            }
        });
    }
    
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop={`static`}
        >
            <ModalHeader toggle={toggle}>
                Copy from an existing case
            </ModalHeader>
            <ModalBody>
                <DomainsDropdown
                    {...props}
                    handleDataCopy={handleDataCopy}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="primary"
                    className="d-flex align-items-center"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCopyDomain;
