import React from "react";

//boostrap
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

//components
import { ConditionContent } from '../Modules';

function ModalCondition(props) {
    const { isOpen, toggle } = props && props;

    return (
        <Modal
            style={{ maxWidth: "800px" }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            backdrop={`static`}
        >
            <ModalHeader toggle={toggle}>
                Add Condition
            </ModalHeader>
            <ModalBody className="p-2 py-4">
                <ConditionContent />
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCondition;
