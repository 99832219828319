import React from 'react';

const MessageAssistant = ({ content, avataAssistant, theme, roleText }) => (
    <div className={`messengerCt__wrapper`}>
        <div className="messengerCt__message messengerCt__message--in">
            <div className="messengerCt__message-pic">
                <img src={avataAssistant} alt={roleText} />
            </div>
            <div className="messengerCt__message-body">
                <div className={`messengerCt__message-content ${theme ? theme : '--bg-blue'} --arrow-left`}>
                    {roleText &&
                        <div className="messengerCt__message-username">
                            {roleText}
                        </div>
                    }
                    <div className="messengerCt__message-text">
                        <div dangerouslySetInnerHTML={{ __html: content || '' }} />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default MessageAssistant;