import React from "react";
import { Iconsax } from "@components-common";

function BtnIconEditV2({ colorIcon, handleEditButtonClick }) {
  return (
    <div className="action-btn-table" onClick={handleEditButtonClick}>
      <Iconsax iconName="edit-2" fill={colorIcon || "#667085"} size={24} />
    </div>
  );
}

export default BtnIconEditV2;