import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//call Action
import { atcUpdateTimePoint } from "@actions";
import { toast } from "react-toastify";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";


//lodash
import _ from "lodash";

import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//components
import { StatusOptions } from "../Modal/Timepoint";

function ModalAddTimePoint(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, actions, timePointCurrent, handleLoadTimePoint } = props && props;
  const DefaultActiveStatus = timePointCurrent?.DefaultActiveStatus;

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [stateDefaultActiveStatus, setDefaultActiveStatus] = useState(DefaultActiveStatus);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  //Load Active/Inactive
  useEffect(() => {
    const DefaultActiveStatus = timePointCurrent?.DefaultActiveStatus;
    setDefaultActiveStatus(DefaultActiveStatus);
  }, [timePointCurrent])

  useEffect(() => {
    const timeConvert = (time) => {
      const days = parseInt(time / (60 * 24));
      const hours = parseInt((time - (days * 60 * 24)) / 60);
      const mins = time - (days * 60 * 24) - (hours * 60);
      return { days, hours, mins };
    };

    if (actions === "add") {
      reset({ keepIsValid: false, keepValues: false });
      setIsLoading(false);
      setValue("days", 0);
      setValue("hours", 0);
      setValue("mins", 0);
    }

    if (actions === "edit") {
      const timeTotal = timePointCurrent?.Time;
      const title = timePointCurrent?.DisplayName;
      const dataReturn = timeConvert(timeTotal);
      setValue("title", title);
      setValue("days", dataReturn?.days, { shouldDirty: false });
      setValue("hours", dataReturn?.hours, { shouldDirty: false });
      setValue("mins", dataReturn?.mins, { shouldDirty: false });
      setIsLoading(false);
    }
  }, [isOpen, reset, actions, setValue, timePointCurrent]);

  const hanldeActions = (data) => {
    const dataDays = parseInt(data?.days);
    const dataHours = parseInt(data?.hours);
    const dataMins = parseInt(data?.mins);
    const logic1 = (dataDays === 0 && dataHours === 0 && dataMins === 0);
    const logic2 = (dataDays < 0 || dataHours < 0 || dataMins < 0);
    if (logic1 || logic2) {
      validateTime();
    } else {
      const daysValue = data?.days;
      const hoursValue = data?.hours;
      const minsValue = data?.mins;
      const totalTimeInMinutes = parseInt(daysValue) * 24 * 60 + parseInt(hoursValue) * 60 + parseInt(minsValue);
      const params = {
        "Target": "TimePoint",
        "TimeInMinutes": totalTimeInMinutes,
        "DisplayName": data?.title,
        "DefaultActiveStatus": stateDefaultActiveStatus
      }
      if (actions === "add") {
        params[`Action`] = "Create";
        actCreateTimepoint(id, params);
      } else {
        params[`Action`] = "Update";
        params[`TimePointId`] = timePointCurrent?.Id;
        dispatch(atcUpdateTimePoint(id, params));
        setIsLoading(true);
        setTimeout(() => {
          toggle();
          setIsLoading(false);
        }, 3000);
      }
    }
  };

  const actCreateTimepoint = (id, params) => {
    const isLoading = true;
    setIsLoading(true);
    //Call Api
    dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_SUCCESS, payload: data });
        setIsLoading(false);
        toast.error(data?.NewTimePoint?.error, {
          autoClose: 5000,
          toastId: "CREATE_TIMEPOINT",
        });

        //Active Timepoint
        const NewTimePointId = data?.NewTimePointId;
        const NewTimeLine = data?.UpdatedTimeline;
        const dataTimepointNew = _.filter(NewTimeLine, function (o) {
          return o?.Id === NewTimePointId;
        });
        toggle();
        if (dataTimepointNew) {
          handleLoadTimePoint(...dataTimepointNew);
        }
      } else {
        dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_FAILURE, error: 'error' });
        setIsLoading(false);
      }
    });
  }

  const validateTime = () => {
    toast.warning("Time must be larger than 0 second", {
      autoClose: 3000
    });
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: "600px" }}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>
        {actions === "add" ? "Add Time Point" : "Edit Time Point"}
      </ModalHeader>
      <ModalBody>
        <form>
          <Label className="font-weight-500">Set the new time point at: </Label>
          <Row>
            <FormGroup className="col-md-4">
              <InputGroup>
                <Input
                  type="number"
                  invalid={errors.days?.type === "required"}
                  {...register("days", {
                    required: true
                  })}
                  onChange={(e) => {
                    setValue("days", e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={watch("days")}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>days</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.days?.type === "required" && (
                <div className="invalid-feedback d-block">
                  This is required.
                </div>
              )}
            </FormGroup>

            <FormGroup className="col-md-4">
              <InputGroup>
                <Input
                  type="number"
                  invalid={errors.hours?.type === "required"}
                  {...register("hours", {
                    required: true
                  })}
                  onChange={(e) => {
                    setValue("hours", e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={watch("hours")}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>hours</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              {errors.hours?.type === "required" && (
                <div className="invalid-feedback d-block">
                  This is required.
                </div>
              )}
            </FormGroup>

            <FormGroup className="col-md-4">
              <InputGroup>
                <Input
                  type="number"
                  invalid={errors.mins?.type === "required"}
                  {...register("mins", {
                    required: true
                  })}
                  onChange={(e) => {
                    setValue("mins", e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={watch("mins")}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>mins</InputGroupText>
                </InputGroupAddon>
                {errors.mins?.type === "required" && (
                  <div className="invalid-feedback d-block">
                    This is required.
                  </div>
                )}
              </InputGroup>
            </FormGroup>
          </Row>
          <FormGroup>
            <Label className="font-weight-500" for="title">
              Time Point Name
            </Label>
            <Input
              type="text"
              {...register("title")}
              onChange={(e) => {
                setValue("title", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("title")}
              autoComplete="off"
            />
            <p className="mt-2">
              <small className="text-muted">
                Leave blank to use the time above as name
              </small>
            </p>
          </FormGroup>
          <StatusOptions
            {...props}
            handleChange={(e) => {
              const { value } = e.target;
              setDefaultActiveStatus(value === 'active' ? true : false);
            }}
            stateDefaultActiveStatus={stateDefaultActiveStatus}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          disabled={isLoading}
          className="--btn-primary d-flex align-items-center"
          onClick={handleSubmit(hanldeActions)}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {actions === "add" ? "Add" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddTimePoint;
