import React from 'react';

//boostrap
import { Input } from "reactstrap";

function EditCell(props) {
    //props
    const { data, handleOnKeyUp, handleChange, index } = props && props;
    return (
        <Input
            id="TeamName"
            name="TeamName"
            placeholder=""
            type="text"
            onChange={handleChange}
            defaultValue={data?.TeamName}
            onKeyUp={(e) => handleOnKeyUp(e, index)}
            autoComplete="off"
        />
    );
};

export default EditCell;