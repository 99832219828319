import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;
    max-width: ${(props) => props.maxWidth || 'none'};
`;

const Input = styled.input`
    flex: 1;
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    color: #101828;
    padding: 8px 12px;
    height: 36px;
    outline: none;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-overflow: ellipsis;
    padding-left: 36px;
    padding-right: 36px;
`;

const SearchIcon = styled.svg`
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
    fill: none;
`;

const ClearButton = styled.button`
    position: absolute;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
`;

const ClearIcon = styled.svg`
    width: 20px;
    height: 20px;
`;

const SearchButton = styled.button`
    height: 36px;
    padding: 0 12px;
    border-radius: 8px;
    margin-left: 12px;
`;

const SearchBasic = ({ onSearch, showButton = true, maxWidth, placeholder, textButton }) => {
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (inputValue === "") {
            onSearch(""); // Clear search when input is empty
        }
    }, [inputValue, onSearch]);

    const handleSearch = () => {
        console.log("Search clicked:", inputValue);
        onSearch(inputValue); // Trigger search on button click
    };

    const handleClear = () => {
        setInputValue(""); // Clear input
        inputRef.current.focus(); // Refocus input
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSearch(inputValue); // Trigger search on Enter key press
        }
    };

    return (
        <SearchContainer>
            <InputContainer maxWidth={maxWidth}>
                <SearchIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#7E8EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </SearchIcon>
                <Input
                    ref={inputRef}
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyPress={handleKeyPress} 
                />
                {inputValue && (
                    <ClearButton onClick={handleClear}>
                        <ClearIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                            <path d="M14.1666 5.83301L5.83331 14.1663M5.83331 5.83301L14.1666 14.1663" stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </ClearIcon>
                    </ClearButton>
                )}
            </InputContainer>
            {showButton && (
                <SearchButton className="btn btn-primary" onClick={handleSearch}>
                    {textButton || 'Search'}
                </SearchButton>
            )}
        </SearchContainer>
    );
};

export default SearchBasic;