export const agreementsConstants = {
    LOAD_AGREEMENTS_REQUEST: 'LOAD_AGREEMENTS_REQUEST',
    LOAD_AGREEMENTS_SUCCESS: 'LOAD_AGREEMENTS_SUCCESS',
    LOAD_AGREEMENTS_FAILURE: 'LOAD_AGREEMENTS_FAILURE',

    UPDATE_AGREEMENTS_REQUEST: 'UPDATE_AGREEMENTS_REQUEST',
    UPDATE_AGREEMENTS_SUCCESS: 'UPDATE_AGREEMENTS_SUCCESS',
    UPDATE_AGREEMENTS_FAILURE: 'UPDATE_AGREEMENTS_FAILURE',

    GET_AGREEMENTS_REQUEST: 'GET_AGREEMENTS_REQUEST',
    GET_AGREEMENTS_SUCCESS: 'GET_AGREEMENTS_SUCCESS',
    GET_AGREEMENTS_FAILURE: 'GET_AGREEMENTS_FAILURE',

    UPDATE_PRIVACY_POLICY_REQUEST: 'UPDATE_PRIVACY_POLICY_REQUEST',
    UPDATE_PRIVACY_POLICY_SUCCESS: 'UPDATE_PRIVACY_POLICY_SUCCESS',
    UPDATE_PRIVACY_POLICY_FAILURE: 'UPDATE_PRIVACY_POLICY_FAILURE',

    LOAD_ALL_STANDARD_CHAT_TOPICS_REQUEST: 'LOAD_ALL_STANDARD_CHAT_TOPICS_REQUEST',
    LOAD_ALL_STANDARD_CHAT_TOPICS_SUCCESS: 'LOAD_ALL_STANDARD_CHAT_TOPICS_SUCCESS',
    LOAD_ALL_STANDARD_CHAT_TOPICS_FAILURE: 'LOAD_ALL_STANDARD_CHAT_TOPICS_FAILURE',

    DELETE_STANDARD_CHAT_TOPICS_REQUEST: 'DELETE_STANDARD_CHAT_TOPICS_REQUEST',
    DELETE_STANDARD_CHAT_TOPICS_SUCCESS: 'DELETE_STANDARD_CHAT_TOPICS_SUCCESS',
    DELETE_STANDARD_CHAT_TOPICS_FAILURE: 'DELETE_STANDARD_CHAT_TOPICS_FAILURE',

    ADD_STANDARD_CHAT_TOPICS_REQUEST: 'ADD_STANDARD_CHAT_TOPICS_REQUEST',
    ADD_STANDARD_CHAT_TOPICS_SUCCESS: 'ADD_STANDARD_CHAT_TOPICS_SUCCESS',
    ADD_STANDARD_CHAT_TOPICS_FAILURE: 'ADD_STANDARD_CHAT_TOPICS_FAILURE',

    UPDATE_STANDARD_CHAT_TOPICS_REQUEST: 'UPDATE_STANDARD_CHAT_TOPICS_REQUEST',
    UPDATE_STANDARD_CHAT_TOPICS_SUCCESS: 'UPDATE_STANDARD_CHAT_TOPICS_SUCCESS',
    UPDATE_STANDARD_CHAT_TOPICS_FAILURE: 'UPDATE_STANDARD_CHAT_TOPICS_FAILURE'
};