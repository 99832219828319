import React, { useState } from "react";
import { useParams } from "react-router-dom";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Label
} from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//api
import { LoaderText, apiCaller } from "@utils";

//common
import { ContentProcess } from "@components/common";

function ModalDiscussThisQuestion(props) {
  //props
  let { id } = useParams();
  const { isOpen, toggle, type, currentChatData } = props && props;

  //State
  const [paramsState, setParams] = useState({
    "ReactionIcon": "",
    "Comments": ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const handleSubmit = () => {
    let params = {
      "Action": "Flag",
      "Data": {
        "Target": "Chat",
        "CaseId": id,
        ...paramsState
      }
    }

    if (type === "chat") {
      params[`Data`][`ChatData`] = currentChatData;
    }

    setIsLoading(true);
    apiCaller(`/api/learn/discussion/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        setIsLoading(false);
        if (data?.status === "OK") {
          setSuccess(true);
        }
      } else {
        console.log("error");
        setIsLoading(false);
        toggle("DiscussThisQuestion");
      }
    });
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setParams((prevState) => ({ ...prevState, [name]: value }));
  };

  const smileysData = [
    { label: 'happy', value: 'Good' },
    { label: 'neutral', value: 'OK' },
    { label: 'sad', value: 'Bad' }
  ]

  const listItems = smileysData?.map((item, idx) =>
    <input
      key={idx}
      type="radio"
      name="ReactionIcon"
      defaultValue={item?.value}
      className={item?.label}
      defaultChecked={paramsState?.ReactionIcon === item?.value}
      data-image={item?.label}
      onClick={handleChange}
    />
  );

  const dataMessage = {
    title: `Thank You Very Much!`,
    description: `We will review your feedback carefully`,
  };

  const toogleDiscussThisQuestion = () => {
    toggle("DiscussThisQuestion");
    setSuccess(false);
  }

  return (
    <Modal
      style={{
        maxWidth: "600px",
      }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
      zIndex={20000}
    >
      <ModalHeader toggle={toogleDiscussThisQuestion}>
        <span className="--text-primary">Discuss This Question</span>
      </ModalHeader>
      <ModalBody className="position-relative">
        {isLoading && <LoaderText />}
        {isSuccess ? (
          <ContentProcess backLogin={false} data={dataMessage} icon={'checked'} />
        ) : (
          <React.Fragment>
            <div className="mb-4 text-center --text-primary font-weight-500" dangerouslySetInnerHTML={{ __html: `Rate this feedback (optional)` }} />
            <FormGroup id="smileys" className="text-center">
              {listItems}
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-500" htmlFor="Comments">
                {/* Comments (optional) */}
                {type === "chat" ? `What’s the issue? (optional)` : 'Tell us more (optional)'}
              </Label>
              <TextareaAutosize
                name="Comments"
                placeholder=""
                className="w-100 form-control"
                maxRows="10"
                minRows={5}
                onChange={handleChange}
                value={paramsState?.Comments}
              />
            </FormGroup>
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toogleDiscussThisQuestion}>
          Cancel
        </Button>
        {!isSuccess && (
          <Button
            color="primary"
            className="--btn-primary"
            onClick={handleSubmit}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            Submit
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDiscussThisQuestion;