import React from "react";
import { Learners, CME } from "../teach";

function ContentRight(props) {
  // const { TasksData } = props && props;
  return (
    <React.Fragment>
      {/* <CaseGuide {...props} /> */}
      {/* {TasksData?.map((value, index) => (
        <TaksBox
          key={index}
          indexParent={index}
          data={value || []}
          {...props}
        />
      ))} */}
      <CME {...props} />
      <Learners {...props} />
    </React.Fragment>
  );
}

export default ContentRight;
