import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Dropzone
import { useDropzone } from "react-dropzone";

// Style
import styled from "styled-components";

// Redux
import { useDispatch } from "react-redux";

//partents
import { FormModal } from '@ui-partents/Modal';

// API
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";
import { FormFeedback } from "reactstrap";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const ContainerUpload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const ModalUpload = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { isOpen, toggleModal, timepointData } = props && props;
  const timePointId = timepointData?.Id;

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [sizeError, setSizeError] = useState("");

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections // New: to get rejected files
  } = useDropzone({ accept: ['.png', '.jpg'], multiple: false, maxSize: 10 * 1024 * 1024 }); // 10MB limit

  useEffect(() => {
    // Handle rejected files
    const handleRejectedFiles = (fileRejections) => {
      const errors = fileRejections.map(({ file, errors }) => {
        const { code, message } = errors[0];
        console.log(code);
        return `${file.name}: ${message}`;
      });
      setSizeError(errors);
    };

    // Call this function to handle rejected files
    handleRejectedFiles(fileRejections);
  }, [fileRejections]);

  const handleUpload = async () => {
    let formData = new FormData();

    // Params
    acceptedFiles.map((file) => {
      return formData.append("PatientProfileImage", file);
    });

    formData.append("Action", "Update");
    formData.append("Target", "TimePoint");
    formData.append("TimePointId", timePointId);

    // Call Api
    dispatch({ type: storyboardConstants.UPLOAD_EXAM_PROFILE_REQUEST, isLoading: true });
    setIsLoading(true);
    apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch({
          type: storyboardConstants.UPLOAD_EXAM_PROFILE_SUCCESS,
          payload: data
        });
        toggleModal();
        setIsLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UPLOAD_EXAM_PROFILE_FAILURE,
          error: 'error'
        });
        console.error("Error uploading:");
        setIsLoading(false);
      }
    });
  };

  const acceptedFileItems = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  console.log(sizeError, "sizeError....");
  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={`Change Case Photo`}
      actionText={"Save"}
      maxWidth="650px"
      onAction={() => handleUpload()}
      isLoading={isLoading}
      actionDisabled={acceptedFiles?.length === 0}
    >
      <ContainerUpload
        {...getRootProps({
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          {acceptedFileItems}
          {acceptedFiles?.length === 0 && (
            <React.Fragment>
              <p>Drag 'n' drop some files here, or click to select files</p>
              <em>(Only *.jpeg and *.png images will be accepted, max size 10MB)</em>
            </React.Fragment>
          )}
        </div>
      </ContainerUpload>
      {sizeError && (
        <FormFeedback className="d-block">{sizeError}</FormFeedback>
      )}
    </FormModal>
  );
};

export default ModalUpload;