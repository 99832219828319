import React, { useEffect, useState } from "react";

//logo default
import iconClose from "@images/common/close_modal.svg";

//components
import { ReviewContentMainTypeComment } from "../../TypeReview/Comment";

//call Api
import { LoaderText, apiCaller } from "@utils";

//format time
import moment from "moment";

function ModalReview(props) {
    //props
    const { isOpen, toggle, dataCurrent, handleUpdateReviewforLogging } = props && props;

    //state
    const [loggingItem, setLoggingItem] = useState({});
    const [isLoading, setLoading] = useState({
        loadLoggingItem: false,
        editReviewForLogging: false
    });

    useEffect(() => {
        setLoading((prevState) => ({ ...prevState, loadLoggingItem: true }));
        const fetchData = () => {
            const params = {
                "action_type": "load_logging_item",
                "log_id": dataCurrent?.log_id,
                "review_type": dataCurrent?.review_type
            }
            apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setLoggingItem(data);
                    setLoading((prevState) => ({ ...prevState, loadLoggingItem: false }));
                } else {
                    setLoading((prevState) => ({ ...prevState, loadLoggingItem: false }));
                    console.log('error');
                }
            });
        }
        //Load Api Model
        isOpen && fetchData();
    }, [dataCurrent, isOpen]);

    //Handle Update Review
    const handleEditReviewforLogging = (itemReview, type) => {
        setLoading((prevState) => ({ ...prevState, editReviewForLogging: true }));
        const params = {
            "action_type": type === "Add" ? "add_review_for_logging" : "edit_review_for_logging",
            "log_id": dataCurrent?.log_id,
            "review_content": itemReview?.review_content,
            "review_type": dataCurrent?.review_type
        }
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoggingItem(data);
                setLoading((prevState) => ({ ...prevState, editReviewForLogging: false }));
                handleUpdateReviewforLogging(params);
            } else {
                setLoading((prevState) => ({ ...prevState, editReviewForLogging: false }));
                console.log('error');
            }
        });
    }

    return (
        <div
            className={`boxPushCt showRight scrollbarStyle fixed bg-white w-100 ${isOpen && "open-right"}`}
        >
            <div className="boxPushCt__heading">
                <h3 className="heading-3 p-0">
                    {`${moment(dataCurrent?.generated_time).format("LLL")} ${dataCurrent?.email ? `- ${dataCurrent?.email}` : ''}`}
                </h3>
                <img
                    className="icon-exit-to-app-button ml-auto closeIcon"
                    src={iconClose}
                    alt="close modal"
                    onClick={toggle}
                />
            </div>
            <div className="boxPushCt__body p-3 position-relative" style={{ overflowY: 'hidden' }}>
                {isLoading?.loadLoggingItem && <LoaderText />}
                <ReviewContentMainTypeComment
                    {...props}
                    loggingItem={loggingItem}
                    handleEditReviewforLogging={handleEditReviewforLogging}
                    isLoading={isLoading}
                />
            </div>
        </div>
        // </Resizable>
    );
}

export default ModalReview;