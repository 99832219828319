export const instructorConstants = {
    INSTRUCTOR_REQUEST: 'INSTRUCTOR_REQUEST',
    INSTRUCTOR_SUCCESS: 'INSTRUCTOR_SUCCESS',
    INSTRUCTOR_FAILURE: 'INSTRUCTOR_FAILURE',

    FILTER_CASE_REQUEST: 'FILTER_CASE_REQUEST',
    FILTER_CASE_SUCCESS: 'FILTER_CASE_SUCCESS',
    FILTER_CASE_FAILURE: 'FILTER_CASE_FAILURE',

    ACCOUNT_INSTITUTION_REQUEST: 'ACCOUNT_INSTITUTION_REQUEST',
    ACCOUNT_INSTITUTION_SUCCESS: 'ACCOUNT_INSTITUTION_SUCCESS',
    ACCOUNT_INSTITUTION_FAILURE: 'ACCOUNT_INSTITUTION_FAILURE',
    
    ADD_USER_ACCOUNT_REQUEST: 'ADD_USER_ACCOUNT_REQUEST',
    ADD_USER_ACCOUNT_SUCCESS: 'ADD_USER_ACCOUNT_SUCCESS',
    ADD_USER_ACCOUNT_FAILURE: 'ADD_USER_ACCOUNT_FAILURE',

    REMOVE_USER_ACCOUNT_REQUEST: 'REMOVE_USER_ACCOUNT_REQUEST',
    REMOVE_USER_ACCOUNT_SUCCESS: 'REMOVE_USER_ACCOUNT_SUCCESS',
    REMOVE_USER_ACCOUNT_FAILURE: 'REMOVE_USER_ACCOUNT_FAILURE',

    ADD_CASE_TO_SERIES_REQUEST: 'ADD_CASE_TO_SERIES_REQUEST',
    ADD_CASE_TO_SERIES_SUCCESS: 'ADD_CASE_TO_SERIES_SUCCESS',
    ADD_CASE_TO_SERIES_FAILURE: 'ADD_CASE_TO_SERIES_FAILURE',
    
    LOAD_INSTITUTION_ALL_CASES_REQUEST: 'LOAD_INSTITUTION_ALL_CASES_REQUEST',
    LOAD_INSTITUTION_ALL_CASES_SUCCESS: 'LOAD_INSTITUTION_ALL_CASES_SUCCESS',
    LOAD_INSTITUTION_ALL_CASES_FAILURE: 'LOAD_INSTITUTION_ALL_CASES_FAILURE',
    
    IMPORT_EXEL_ACCOUNT_REQUEST: 'IMPORT_EXEL_ACCOUNT_REQUEST',
    IMPORT_EXEL_ACCOUNT_SUCCESS: 'IMPORT_EXEL_ACCOUNT_SUCCESS',
    IMPORT_EXEL_ACCOUNT_FAILURE: 'IMPORT_EXEL_ACCOUNT_FAILURE',

    IMPORT_EXEL_ACCOUNT_CONFIRM_REQUEST: 'IMPORT_EXEL_ACCOUNT_CONFIRM_REQUEST',
    IMPORT_EXEL_ACCOUNT_CONFIRM_SUCCESS: 'IMPORT_EXEL_ACCOUNT_CONFIRM_SUCCESS',
    IMPORT_EXEL_ACCOUNT_CONFIRM_FAILURE: 'IMPORT_EXEL_ACCOUNT_CONFIRM_FAILURE',

    REQUEST_INSTRUCTOR_PRIVILEGE_REQUEST: 'REQUEST_INSTRUCTOR_PRIVILEGE_REQUEST',
    REQUEST_INSTRUCTOR_PRIVILEGE_SUCCESS: 'REQUEST_INSTRUCTOR_PRIVILEGE_SUCCESS',
    REQUEST_INSTRUCTOR_PRIVILEGE_FAILURE: 'REQUEST_INSTRUCTOR_PRIVILEGE_FAILURE',

    SET_ACCOUNT_PRIVILEGE_REQUEST: 'SET_ACCOUNT_PRIVILEGE_REQUEST',
    SET_ACCOUNT_PRIVILEGE_SUCCESS: 'SET_ACCOUNT_PRIVILEGE_SUCCESS',
    SET_ACCOUNT_PRIVILEGE_FAILURE: 'SET_ACCOUNT_PRIVILEGE_FAILURE',

    SET_ACCOUNT_STATUS_PRIVILEGE_REQUEST: 'SET_ACCOUNT_STATUS_PRIVILEGE_REQUEST',
    SET_ACCOUNT_STATUS_PRIVILEGE_SUCCESS: 'SET_ACCOUNT_STATUS_PRIVILEGE_SUCCESS',
    SET_ACCOUNT_STATUS_PRIVILEGE_FAILURE: 'SET_ACCOUNT_STATUS_PRIVILEGE_FAILURE',

    UPDATE_INSTRUCTOR_NOTE_REQUEST: 'UPDATE_INSTRUCTOR_NOTE_REQUEST',
    UPDATE_INSTRUCTOR_NOTE_SUCCESS: 'UPDATE_INSTRUCTOR_NOTE_SUCCESS',
    UPDATE_INSTRUCTOR_NOTE_FAILURE: 'UPDATE_INSTRUCTOR_NOTE_FAILURE',

    SERIES_UPDATE_SETTINGS_REQUEST: 'SERIES_UPDATE_SETTINGS_REQUEST',
    SERIES_UPDATE_SETTINGS_SUCCESS: 'SERIES_UPDATE_SETTINGS_SUCCESS',
    SERIES_UPDATE_SETTINGS_FAILURE: 'SERIES_UPDATE_SETTINGS_FAILURE',

    MESSAGE_MICROPHONE_ACTIVE_INSTRUCTOR: 'MESSAGE_MICROPHONE_ACTIVE_INSTRUCTOR'
};