import React from 'react'
import { InteractiveWrap } from '@components/teachV2/interactiveModule';

const InteractiveModule = () => {

  return (
    <div className="wrapper interactiveModule">
      <div className='interactiveModule__header'>
        <p className='interactive-title'>Module Library</p>
        {/* <p className='interactive-beta'>Beta</p> */}
      </div>
      <InteractiveWrap />
    </div>
  )
}

export default InteractiveModule
