import React, { useEffect, useState, useMemo } from "react";
import { FormGroup, Input } from "reactstrap";

function QuestionConditionContent(props) {
    const { selectedQuestionId, handleChange, selectedQuestionContent, timepointData } = props;

    // State to manage the list of questions
    const [stateQuestion, setQuestion] = useState(timepointData?.Questions || []);
    const [quesionIdActive, setQuestionIdActive] = useState(selectedQuestionId || "");

    // Update stateQuestion when timepointData.Questions changes
    useEffect(() => {
        setQuestion(timepointData?.Questions || []);
    }, [timepointData?.Questions]);

    // Memoize options to prevent re-renders
    const questionOptions = useMemo(() => {
        return stateQuestion?.map((value, index) => {
            const qsId = value?.QuestionId;
            return (
                <option key={index} value={qsId}>
                    Question #{qsId}
                </option>
            );
        });
    }, [stateQuestion]);

    // Conditional rendering of selected question content to avoid re-renders
    const renderSelectedQuestionContent = useMemo(() => {
        if (selectedQuestionContent) {
            return (
                <div
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                        __html: selectedQuestionContent,
                    }}
                ></div>
            );
        }
        return null;
    }, [selectedQuestionContent]);

    // Update active question id when selectedQuestionId changes
    useEffect(() => {
        setQuestionIdActive(selectedQuestionId);
    }, [selectedQuestionId]);
    return (
        <React.Fragment>
            <FormGroup>
                <Input
                    type="select"
                    name="QuestionId"
                    value={quesionIdActive}
                    onChange={handleChange}
                >
                    <option value="" disabled>
                        Select a question
                    </option>
                    {questionOptions}
                </Input>
            </FormGroup>
            {renderSelectedQuestionContent}
        </React.Fragment>
    );
}

export default QuestionConditionContent;