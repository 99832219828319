import React, { useState } from "react";

//boostrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { BasicInfo, Details } from "../Ticket";

function ListTabDetail(props) {
  //props
  const { item } = props && props;

  //state
  const [activeTab, setActiveTab] = useState("1");
  const checkTabDetails = (item?.Details === '' || item?.Details === null) ? false : true;
  const listTab = [
    { name: 'Basic Info ', component: <BasicInfo {...props} />, show: true },
    { name: 'Details ', component: <Details {...props} />, show: checkTabDetails }
  ]

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Nav className="tabBorder border-bottom-0" tabs>
        {listTab?.map((item, index) => (
          item?.show &&
          <NavItem className="mb-1" key={index}>
            <NavLink
              className={classnames({
                "active --bg-primary": activeTab === String(index + 1),
              })}
              onClick={() => {
                toggle(String(index + 1));
              }}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent className="pt-0" activeTab={activeTab}>
        {listTab?.map((item, index) => (
          item?.show &&
          <TabPane key={index} tabId={String(index + 1)}>
            {item?.component}
          </TabPane>
        ))}
      </TabContent>
    </React.Fragment>
  );
}

export default ListTabDetail;