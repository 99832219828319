import React from 'react';
import styled from 'styled-components';

//Icon
import IconSend from "@images/learnerv2/icon-send-sp.svg";

// Tạo styled component cho SendIcon sử dụng thẻ img
const StyledSendIcon = styled.img`
    width: 28px;
    height: 28px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    cursor: pointer;
`;

const SendIcon = ({ onClick }) => {
    return (
        <StyledSendIcon
            src={IconSend}
            alt="Send Icon"
            onClick={onClick}
        />
    );
};

export default SendIcon;