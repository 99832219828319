import React from "react";

//boostrap
import { Row, Col } from "reactstrap";

function HeadingForAdmin(props) {
  const {
    title
  } = props && props;

  return (
    <div className="m-subheader">
      <Row className="align-items-center">
        <Col md={12}>
          <h5 className="m-subheader__title">{title}</h5>
        </Col>
      </Row>
    </div>
  );
}

export default HeadingForAdmin;