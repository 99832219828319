import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { checkAuth, apiCaller } from '@utils';
import { actionLogin, actFetchThemeRequest } from '@actions';

import imgLogo from '@images/weitzman/logo.png';

function RegisterForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, formState: { errors }, reset } = useForm();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#fafbfb';
    if (checkAuth()) {
      history.push('/weitzman/confirm');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Check if enrolled in Program
  const CheckEnrolledInProgram = () => {
    const params = {
      "Mode": "Check",
      "Program": "weitzman"
    }
    apiCaller(`/api/learn/program/`, "POST", params).then((res) => {
      const data = res?.data;
      const isEnrolled = data?.Enrolled;
      if (res?.status === 200) {
        if (isEnrolled) {
          //nếu Enrolled rồi (True) thì đi thẳng vào trang home luôn
          history.push("/");
        } else {
          //nếu False thì hiện view confirm
          history.push('/weitzman/confirm');
        }
        setLoading(false);
      } else {
        console.log("error....");
        setLoading(false);
      }
    });
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('Firstname', data?.Firstname);
    formData.append('Lastname', data?.Lastname);
    formData.append('Email', data?.Email);
    formData.append('Institution', 'Weitzman');

    setLoading(true);
    //Call Api
    apiCaller(`/api/auth/demo/`, "POST", formData).then((response) => {
      let dataResponse = response?.data;
      if (dataResponse?.status === "OK") {
        //add token
        dispatch(actionLogin(dataResponse));

        //Call API Theme
        dispatch(actFetchThemeRequest());

        CheckEnrolledInProgram();
        //reset Data
        reset({ keepIsValid: false, keepValues: false });
      } else if (dataResponse?.status === 'Unable to authenticate with core back end') {
        setLoading(false);
      } else if (dataResponse?.error === "Account already exists") {
        history.push(`/weitzman/login?status=account_existed`);
      } else {
        setLoading(false);
        console.log('error');
      }
    });
  };

  return (
    <div className="loginPartnerContainer">
      <div className="form-auth-panel">
        <div className="form-auth-panel__logo">
          <img src={imgLogo} alt="weitzman" />
        </div>
        <div className="auth-description text-center">
          <p className="text-desc-lg">Unlock access to our COVID-19 cases by entering your information below:</p>
        </div>
        <div className="auth-login-form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label className="labeForm" for="Firstname">
                First Name <span className="m--font-danger ml-2">*</span>
              </Label>
              <Controller
                name="Firstname"
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Enter your first name"
                    invalid={errors.Firstname ? true : false}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="Lastname">
                Last Name <span className="m--font-danger ml-2">*</span>
              </Label>
              <Controller
                name="Lastname"
                control={control}
                rules={{ required: 'This field is required' }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="text"
                    placeholder="Enter your last name"
                    invalid={errors.Lastname ? true : false}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="Email">
                Email <span className="m--font-danger ml-2">*</span>
              </Label>
              <Controller
                name="Email"
                control={control}
                rules={{ required: 'This field is required', pattern: /^\S+@\S+$/i }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="Email"
                    placeholder="Enter your email address"
                    invalid={errors.Email ? true : false}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="btn-brand-sm -btn-lg w-100 -shadown mt-4"
                disabled={isLoading}
              >
                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Submit
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;