export const checkpointsConstants = {
    CHECKPOINTS_LOAD_REQUEST: 'CHECKPOINTS_LOAD_REQUEST',
    CHECKPOINTS_LOAD_SUCCESS: 'CHECKPOINTS_LOAD_SUCCESS',
    CHECKPOINTS_LOAD_FAILURE: 'CHECKPOINTS_LOAD_FAILURE',

    CREATE_CHECKPOINT_REQUEST: 'CREATE_CHECKPOINT_REQUEST',
    CREATE_CHECKPOINT_SUCCESS: 'CREATE_CHECKPOINT_SUCCESS',
    CREATE_CHECKPOINT_FAILURE: 'CREATE_CHECKPOINT_FAILURE',

    EDIT_CHECKPOINT_REQUEST: 'EDIT_CHECKPOINT_REQUEST',
    EDIT_CHECKPOINT_SUCCESS: 'EDIT_CHECKPOINT_SUCCESS',
    EDIT_CHECKPOINT_FAILURE: 'EDIT_CHECKPOINT_FAILURE',

    DELETE_CHECKPOINT_REQUEST: 'DELETE_CHECKPOINT_REQUEST',
    DELETE_CHECKPOINT_SUCCESS: 'DELETE_CHECKPOINT_SUCCESS',
    DELETE_CHECKPOINT_FAILURE: 'DELETE_CHECKPOINT_FAILURE',

    CHECKPOINT_LOAD_TYPE_REQUEST: 'CHECKPOINT_LOAD_TYPE_REQUEST',
    CHECKPOINT_LOAD_TYPE_SUCCESS: 'CHECKPOINT_LOAD_TYPE_SUCCESS',
    CHECKPOINT_LOAD_TYPE_FAILURE: 'CHECKPOINT_LOAD_TYPE_FAILURE'
};
