import React, { useState } from "react";

//boostrap
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";

//components
import { GeneralTab, UmlsTab } from '../tabs';

//classnames
import classnames from "classnames";

function MainTabs(props) {

    //state
    const [activeTab, setActiveTab] = useState("1");

    //handle tab
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };


    //Tabs
    const listTabs = [
        {
            id: '1',
            name: 'General',
            component: <GeneralTab {...props} />,
            show: true
        },
        {
            id: '2',
            name: 'UMLS',
            component: <UmlsTab {...props} />,
            show: true
        }
    ]

    return (
        <div className="tabsPills mt-3">
            <Nav tabs>
                {listTabs.map((item, index) =>
                    item.show && (
                        <NavItem key={index}>
                            <NavLink
                                className={classnames({ active: activeTab === item.id })}
                                onClick={() => {
                                    toggleTab(item.id);
                                }}
                            >
                                {item.name}
                            </NavLink>
                        </NavItem>
                    )
                )}
            </Nav>
            <TabContent className="py-0" activeTab={activeTab}>
                {listTabs.map((item, index) =>
                    item.show && (
                        <TabPane key={index} tabId={item.id}>
                            {item.component}
                        </TabPane>
                    )
                )}
            </TabContent>
        </div>
    );
};

export default MainTabs;