import React from 'react';

//reactstrap
import { FormGroup, Input, Label } from "reactstrap";

function BriefViewCheckbox(props) {
    const { isBrief, handleChangeBriefView } = props && props;
    return (
        <FormGroup
            check
            inline
        >
            <Label className="d-flex align-items-center" check>
                <Input
                    type="checkbox"
                    name="isBrief"
                    defaultValue={isBrief}
                    onChange={handleChangeBriefView}
                />
                Brief View
            </Label>
        </FormGroup>
    );
};

export default BriefViewCheckbox;