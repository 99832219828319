import React from 'react';

function IndependentLabsRow(props) {
    const { total } = props && props;
    return (
        <tr
            className="row_lab row_lab_panel font-weight-500"
            style={{ backgroundColor: "rgb(242, 242, 242)" }}
        >
            <td colSpan={total + 2} className="m--font-danger">Independent Labs</td>
        </tr>
    );
};

export default IndependentLabsRow;