import React from "react";

//boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

//redux
import { useSelector } from "react-redux";

function ModalResetCase(props) {
  //props
  const { isOpen, toggle, isLoading, requestResetCase } = props && props;

  // Interface Template
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const ModalResetCase = InterfaceTemplateDetails?.ModalResetCase;
  return (
    <Modal zIndex={10000} isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Reset Case</ModalHeader>
      <ModalBody>
        <h5 className="text-center">
          <div dangerouslySetInnerHTML={{ __html: ModalResetCase?.m_reset_title?.Text || "Are you sure that you want to restart the case from the beginning?" }}></div>
        </h5>
        <div className="mt-4 text-center" dangerouslySetInnerHTML={{ __html: ModalResetCase?.m_reset_msg?.Text || "All of your progress and data on this case will be permanently deleted." }}></div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-secondary"
          disabled={isLoading}
          onClick={toggle}
        >
          <span dangerouslySetInnerHTML={{ __html: ModalResetCase?.m_reset_button_confirm?.Text || "No, Take Me Back!" }} />
        </Button>{" "}
        <Button
          className="btn-brand --btn-primary d-flex align-items-center"
          disabled={isLoading}
          onClick={requestResetCase}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {ModalResetCase?.m_reset_button_cancel?.Text || "Reset Case"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalResetCase;