import React from 'react';

//components
import { ButtonTimelineEffect } from "@components/storyboard/common/TimelineEffect";

function EffectCollapse(props) {
    const { item, value, toggleAddEffect, toggleDelEffects, toggleEffect } = props && props;

    const defineChangeMode = (type, value, unit, limit) => {
        if (type === "increase") {
            return `Increase by: <span>${value} ${unit}</span>${" "}<br/> (but not larger than ${limit} ${unit})`;
        } else if (type === "“decrease”") {
            return `Decrease by: <span>${value} ${unit}</span>${" "}<br/> (but not smaller than ${limit} ${unit})`;
        } else {
            return `Set at: <span>${value} ${unit}</span>${" "}`;
        }
    };

    return (
        <tr className="toogleRow">
            <td colSpan={5} className="py-2 px-0">
                <table className="table table-sm tableSub">
                    <thead className="--bg-primary">
                        <tr>
                            <th style={{ width: "15%" }}>Attribute</th>
                            <th>Effect</th>
                            <th style={{ width: "35%" }}>Timing</th>
                            <th className='text-center' width={200} ></th>
                        </tr>
                    </thead>
                    <tbody>
                        {item?.Effects.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.AttributeName}</td>
                                    <td>
                                        <p dangerouslySetInnerHTML={{
                                            __html: defineChangeMode(
                                                data.ChangeMode,
                                                data.ChangeValue,
                                                data.AttributeUnit,
                                                data.ChangeLimit
                                            ),
                                        }}
                                        ></p>
                                    </td>
                                    <td>
                                        Start at {data.TimeToEffectInMinutes}{" "}
                                        minutes - lasting for{" "}
                                        {data.DurationInMinutes} minutes
                                    </td>
                                    <td>
                                        <div className="lsActions">
                                            <div className="lsActions__item">
                                                <button
                                                    className="btn btn-sm btn-outline-brand"
                                                    onClick={() =>
                                                        toggleEffect(
                                                            data,
                                                            value.CategoryId,
                                                            item.Id
                                                        )
                                                    }
                                                >
                                                    <span className="med-icon icon-pencil"></span>{" "}
                                                </button>
                                            </div>
                                            <div className="lsActions__item">
                                                <button
                                                    className="btn btn-sm btn-outline-danger"
                                                    data-id={data.EffectId}
                                                    onClick={() =>
                                                        toggleDelEffects(
                                                            item.Id,
                                                            value.CategoryId,
                                                            data.EffectId
                                                        )
                                                    }
                                                >
                                                    <span className="med-icon icon-cancel"></span>{" "}
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <p className="text-center my-3">
                    <button
                        className="btn btn-success btn-sm"
                        onClick={() =>
                            toggleAddEffect(item.Id, value.CategoryId)
                        }
                    >
                        Add Effect
                    </button>
                    <ButtonTimelineEffect {...props} />
                </p>
            </td>
        </tr>
    );
};

export default EffectCollapse;