import React from "react";

//components
import { RegisterForm } from "@components/weitzman";

function RegisterWeitzmanPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <RegisterForm />
    </div>
  );
}

export default RegisterWeitzmanPage;