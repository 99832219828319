import React, { useState } from 'react';

//components
import { ModalTimeLineEffect } from "../TimelineEffect";

function ButtonTimelineEffect(props) {
    
    //state
    const [isOpen, setOpen] = useState(false);

    const toggleModal = () => {
        setOpen(!isOpen);
    }
    
    return (
        <React.Fragment>
            <button
                className="btn btn-success btn-sm ml-3"
                onClick={() =>
                    toggleModal()
                }
            >
                Timeline Effect
            </button>
            <ModalTimeLineEffect
                {...props}
                isOpen={isOpen}
                toggleModal={toggleModal}
            />
        </React.Fragment>
    );
};

export default ButtonTimelineEffect;