import React, { useState } from "react";

//reactstrap
import { TabContent, TabPane, NavItem, NavLink, Nav } from "reactstrap";

//components
import { TableChat } from "../AIRecords/Chat";

//classnames
import classnames from "classnames";

function TabsMain(props) {
    //props
    const listTab = [
        {
            Name: "Chat",
            Component: <TableChat {...props} />,
        }
    ];

    //state
    const [activeTab, setActiveTab] = useState("1");

    //toggle
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="tabsPills">
            <Nav tabs>
                {listTab?.map((value, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({
                                active: activeTab === (index + 1).toString(),
                            })}
                            onClick={() => {
                                toggle(String(index + 1));
                            }}
                        >
                            {value.Name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {listTab?.length > 0 && (
                <TabContent activeTab={activeTab}>
                    {listTab?.map((value, index) => (
                        <TabPane key={index} tabId={(index + 1).toString()}>
                            {value.Component}
                        </TabPane>
                    ))}
                </TabContent>
            )}
        </div>
    );
}

export default TabsMain;