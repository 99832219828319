import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";
import { useHistory } from 'react-router-dom';
import imgLogo from "@images/weitzman/logo.png";

//api
import { apiCaller } from "@utils";

//utils
import { checkAuth } from '@utils';

function ConfirmLaunchPlan() {
    const history = useHistory();

    //state
    const [isLoading, setLoading] = useState(false);

    //load
    useEffect(() => {
        document.body.style.backgroundColor = '#fafbfb';
        if (!checkAuth()) {
            history.push('/weitzman/access-email');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Click Button
    const handleButtonClick = (confirm) => {
        if (confirm) {
            const params = {
                "Mode": "Enroll",
                "Program": "weitzman"
            }
            setLoading(true);
            apiCaller(`/api/learn/program/`, "POST", params).then((res) => {
                if (res?.status === 200) {
                    setLoading(false);
                    history.push(`/home?weitzman-confirm=${confirm}`);
                } else {
                    console.log("error....");
                    setLoading(false);
                }
            });
        } else {
            // If the user cancels, navigate to the home page
            history.push("/");
        }
    };

    return (
        <div className="loginPartnerContainer">
            <div className='form-auth-panel'>
                <div className="form-auth-panel__logo">
                    <img src={imgLogo} alt="weitzman" />
                </div>
                <div className="auth-description text-center">
                    <p className='text-desc-sm'>You’re enrolling in</p>
                    <p className='text-desc-lg my-3'>Improving COVID-19 Care for High-Risk, Vulnerable Populations through Case-based Simulation Modules</p>
                    <p className='text-desc-sm'>Select "Confirm" to unlock valuable cases offered by the Weitzman Institute!</p>
                </div>
                <div className="form-auth-panel__footer">
                    <Button
                        color='primary'
                        className="btn-brand-sm -btn-lg w-100 -shadown"
                        onClick={() => handleButtonClick(true)}
                        isLoading={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Confirm
                    </Button>
                    <Button
                        color='white'
                        className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
                        onClick={() => handleButtonClick(false)}
                        isLoading={isLoading}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmLaunchPlan;