import React from "react";

//reactstrap
import { Badge, UncontrolledTooltip } from "reactstrap";

//lodash
import _ from 'lodash';

//truncate
import { truncate } from "@utils";

function BadgeScore(props) {
  const { data, toggleScore, valuePanel, itemIntervention, timepointData } = props && props;
  const timepointCurrent = timepointData?.data;
  const timeOfTimePoint = timepointCurrent?.Time;
  return (
    <React.Fragment>
      {data?.map((val, index) => {
        const numberScore = Number(val.Score);
        const itemScore = val;
        const timingCondition = val.TimingCondition;
        const timingValue = val.TimingValue;
        const conditionNotValue = (_.isUndefined(timingValue) && _.isUndefined(timingCondition));
        const conditionMatchValue = (timingCondition === 'At' && (timingValue === timeOfTimePoint));
        const domainName = val?.DomainName || ''
        return (
          <React.Fragment key={index}>
            {(conditionNotValue || conditionMatchValue) &&
              <React.Fragment>
                <Badge
                  style={{ cursor: "pointer" }}
                  className="p-1 mb-1"
                  color={numberScore >= 0 ? "success" : "danger"}
                  key={index}
                  id={`tooltipIntervention_${val?.DomainId}`}
                  onClick={() =>
                    toggleScore(itemIntervention, "edit-score", itemScore, valuePanel.CategoryId)
                  }
                >
                  <span className="font-weight-500">{truncate(domainName, 12)}</span>
                  &nbsp;
                  <strong>({numberScore})</strong>
                  {` `}{(typeof timingValue != "undefined" && typeof timingCondition != "undefined") && `(*)`}
                </Badge>
                <UncontrolledTooltip
                  placement="bottom"
                  target={`tooltipIntervention_${val?.DomainId}`}
                >
                  {domainName}
                </UncontrolledTooltip>
              </React.Fragment>
            }
          </React.Fragment>
        );
      })}
      <div
        className="m--font-info font-weight-500"
        style={{ cursor: "pointer" }}
      >
        <span
          className="icon-add icon-size-2x"
          onClick={() => toggleScore(itemIntervention, "add-score", {}, valuePanel.CategoryId)}
        ></span>
      </div>
    </React.Fragment>
  );
}

export default BadgeScore;
