import React from "react";
import { Col, FormGroup, Label } from "reactstrap";

//Image Preview
import { ImagePreview, ButtonUploadFile } from "../AddUpdateFood";

const FileUpload = (props) => {
    const { imagePreview, foodItem } = props && props;
    const image_url = foodItem?.image_url;
    return (
        <Col md={12}>
            <FormGroup className="mt-2">
                <Label className="ms-label" for="file">Food Image</Label>
                {(imagePreview || image_url) &&
                    <ImagePreview
                        {...props}
                        imgUrl={imagePreview || image_url}
                    />
                }
                <ButtonUploadFile {...props} />
            </FormGroup>
        </Col>
    );
};

export default FileUpload;