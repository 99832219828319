import React from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap'; // Assuming Bootstrap/Reactstrap is being used
import { Icon } from '@components/common';
import result from '@images/teachV2/engage.svg';
import analyze from '@images/teachV2/analyze.svg';

const HeadingForm = () => {
    return (
        <Card className='my-2'>
            <CardHeader>Heading Form</CardHeader>
            <CardBody>
                <div className="title-generate">
                    <div className="title-generate__icon">
                        <Icon src={result} color="#fff" />
                    </div>
                    <p className="title-generate__text">Heading Form 01</p>
                </div>
                <br/>
                <div className="title-generate">
                    <div className="title-generate__icon white-bg-icon">
                        <Icon src={analyze} />
                    </div>
                    <p className="title-generate__text">Heading Form 02</p>
                </div>
            </CardBody>
        </Card>
    );
};

export default HeadingForm;