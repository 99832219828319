import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//router
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

//Actions
import { atcCreateLabPanel, atcUpdateLabPanel } from "@actions";

//Config
import * as Config from "@constants/Config";

const ModalExample = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const actions = props && props.actions;
  const timePointId = props && props.timePointId;

  //state
  const [stateData, setStateData] = useState((props && props.data) || {});
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    setStateData(props && props.data);
  }, [props]);

  const handleSave = () => {
    const panelId = stateData && stateData.Id;
    const panelName = stateData && stateData.Name;
    const panelProcessTime = stateData && stateData.ProcessTime;

    if (actions === "add-panel") {
      const params = {
        Action: "Create",
        Target: "LabPanel",
        TimePointId: timePointId,
        LabPanel: {
          Name: panelName || "",
          ProcessTime: panelProcessTime || 0,
          Explanation: content,
        },
      };
      dispatch(atcCreateLabPanel(id, params));
    } else {
      const params = {
        Action: "Update",
        Target: "LabPanel",
        TimePointId: timePointId,
        LabPanel: {
          LabPanelId: String(panelId) || "",
          Name: panelName || "",
          ProcessTime: panelProcessTime || "",
          Explanation: content,
        },
      };
      dispatch(atcUpdateLabPanel(id, params));
    }
    props && props.toggle();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ maxWidth: "650px", padding: "0 10px" }}
        backdrop={`static`}
      >
        <ModalHeader toggle={toggle}>
          {actions === "edit-panel" ? "Edit Lab Panel" : "Add Lab Panel"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="Name">Name:</Label>
            <Input
              type="text"
              name="Name"
              id="Name"
              placeholder="Enter name of lab sign"
              defaultValue={(stateData && stateData.Name) || ""}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Label for="ProcessTime">Process Time</Label>
            <InputGroup>
              <Input
                type="number"
                name="ProcessTime"
                id="ProcessTime"
                placeholder=""
                defaultValue={(stateData && stateData.ProcessTime) || ""}
                onChange={handleChange}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>mins</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <p className="form-help mt-1">
              The number of minutes for the results of to become available
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="Content">Explanation</Label>
            <JoditEditor
              ref={editor}
              value={(stateData && stateData.Explanation) || ""}
              config={Config.CONFIG_JODIT}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
