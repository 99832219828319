import React, { useState, useEffect, useRef, useMemo } from "react";

//components 
import { FrameChat } from "./Preceptor";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//lodash
import _ from "lodash";

//constants
import { questionConstants } from "@constants";

//call Action
import { actFetchCheckMissingTask } from "@actions";

function ChatQuestion(props) {
  //PROPS
  const { apiCallerAddChatRecord } = props && props;

  //variables
  const dispatch = useDispatch();
  let { id } = useParams();
  const messagesEndRef = useRef(null);

  //state
  const [isLoading] = useState(false);

  useEffect(() => {
    dispatch(actFetchCheckMissingTask(id));
  }, [dispatch, id])

  //Check Missing Task
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask?.data) || {});
  const isPendingQuestion = missingTaskStatus?.status === "PendingQuestion";

  // ----------------------------------------------------------------------
  // LOAD RECORD INSTRUCTOR
  // ----------------------------------------------------------------------
  const chatRecordReducer = useSelector((state) => (state?.chatRecordForQuestion?.data) || []);
  const chatRecordToApi = useSelector((state) => (state?.dataLoadRecordInstuctor?.data) || []);

  // Tạo một mảng mới chứa dữ liệu từ chatRecordToApi và sau đó thêm các object từ chatRecordReducer vào sau
  const combinedChatRecord = useMemo(() => {
    if (chatRecordToApi.length === 0 && chatRecordReducer.length === 0) {
      return [];
    }
    if (chatRecordToApi.length === 0) {
      return chatRecordReducer;
    }
    if (chatRecordReducer.length === 0) {
      return chatRecordToApi;
    }
    return [...chatRecordToApi, ...chatRecordReducer];
  }, [chatRecordToApi, chatRecordReducer]);

  // Sử dụng combinedChatRecord trong useState và useEffect
  const [chatRecord, setChatRecord] = useState([]);

  // Reducer Load Chat record
  useEffect(() => {
    const isDuplicate = (record1, record2) => record1.Role === "Instructor" && record1.Content === record2.Content;
    const uniqueRecords = _.uniqWith(combinedChatRecord, isDuplicate).map(record => ({
      ...record
    }));
    setChatRecord(uniqueRecords || []);
  }, [combinedChatRecord, dispatch]);

  // ----------------------------------------------------------------------
  // /LOAD RECORD INSTRUCTOR
  // ----------------------------------------------------------------------

  // Scroll to the bottom of the chat box
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }, 100);
    return () => clearTimeout(timeoutId);
  }, [chatRecord]);

  //Hanlde Update Record
  const handleUpdateRecord = async (response, type, role) => {
    const contentResponse = role === "User" ? response : (response?.Prompt || response?.Explanation)
    //params common
    const newMessage = {
      "Channel": "Instructor", //Prompt, Explanation la du lieu cua Instructor
      "Role": role,
      "AvatarURL": "",
      "Type": type || "message",
      "Content": contentResponse,
      "FullQuestionResponse": response || null
    }

    if (contentResponse) {
      // Dispatch Data Question to Store
      await new Promise((resolve) => {
        dispatch({
          type: questionConstants.UPDATE_CHAT_RECORD_FOR_QUESTION,
          payload: newMessage,
        });
        resolve();
      });

      await apiCallerAddChatRecord(newMessage);
      await dispatch(actFetchCheckMissingTask(id));
    }
  };

  return (
    <FrameChat
      {...props}
      chatRecord={chatRecord || []}
      messagesEndRef={messagesEndRef}
      isLoading={isLoading}
      handleUpdateRecord={handleUpdateRecord}
      isPendingQuestion={isPendingQuestion}
    />
  );
}

export default ChatQuestion;