import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { useSelector } from "react-redux";

function DropDownTimePoint(props) {
    const { handelSelectTimepointId, itemQuestion } = props;
    const TimePointId = itemQuestion?.TimePointId;

    // Initialize selectedTimePointId with TimePointId from itemQuestion or an empty string
    const [selectedTimePointId, setSelectedTimePointId] = useState(TimePointId || '');

    // Use useSelector to get data from Redux store
    const timelineData = useSelector((state) => state.timeline || []);
    const timepointList = timelineData?.data;

    // Update selectedTimePointId state when itemQuestion changes
    useEffect(() => {
        if (itemQuestion?.TimePointId) {
            setSelectedTimePointId(itemQuestion.TimePointId);
        }
    }, [itemQuestion]);

    // Handle change event
    const handleChange = (event) => {
        const { value } = event.target;
        setSelectedTimePointId(value);
        handelSelectTimepointId(value);
    };

    return (
        <FormGroup>
            <Label className="labelForm">Timepoint</Label>
            <Input
                type="select"
                name="TimePointId"
                value={selectedTimePointId}
                onChange={handleChange}
            >
                {timepointList?.map((value, index) => (
                    <option value={value.Id} key={index}>
                        {value.DisplayName}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
}

export default DropDownTimePoint;