// Generate.js
import React, { useState } from 'react';
import { Row, Col, Input } from 'reactstrap';
import HeaderCommon from "@components/teachV2/performanceReport/listReportCommon/HeaderCommon";
import { TitleCommon } from "@ui-partents/Form/TitleCommon";
import { ItemOption } from '@ui-partents/Form/FormChoose';
import { ItemsChose } from '@ui-partents/Form/FormChoose';
import FileCase from '@images/teachV2/file-case.svg'
import { FormUpload } from '@ui-partents/Form/FormUpload';
import { FormError } from '@ui-partents/Form/FormError';

const Generate = () => {
  const [dataFileImported, setDataFileImported] = useState(null);
  const [checkFile, setCheckFile] = useState(false);

  return (
    <section className="content-component mb-5">
        <Row className='mb-4'>
          <Col md={12}>
            <div className="content-header">
              <h1>Generate Form</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='px-5'>
            <HeaderCommon title="Enter Instructions for AI" subtitle="Provide clear and detailed instructions for the AI to generate your MCQs. (You can specify the topic, target learners, difficulty level etc. to get the most relevant questions)"/>
            <TitleCommon 
              title="Example Title"
              content="Example Tooltip"
              dataFor="textarea"
            >
              <span>Provide clear and detailed instructions for the AI to generate your MCQs.</span>
            </TitleCommon>
            <Input
              type="textarea"
              style={{ height: '160px' }}
              placeholder="Lets's input anything you want"
              className='mt-2'
            />
            <div className="d-flex align-items-center mt-2">
              <div>
                <ItemOption title="Generate Multiple Choice Question" subTitle="Please provide a detailed request" disabled isCheck={false}/>
              </div>
              <div className='ml-2'>
                <ItemOption title="Generate Multiple Choice Question" subTitle="Please provide a detailed request" isCheck={true}/>
              </div>
            </div>
            <div className="mt-2">
              <ItemsChose icon={FileCase} title="Example title" isCheck={checkFile} handleCheck={() => setCheckFile(!checkFile)}/>
              <div className='mt-2'>
                <ItemsChose icon={FileCase} title="Example title" isCheck={false} disabled={true}/>
              </div>
            </div>
            <div className="mt-2">
              <FormUpload
                dataFileImported={dataFileImported}
                setDataFileImported={setDataFileImported}
                typeUpload="PDF, PPT, Word, TXT. Max: 10 MB."
                typeFile={["application/msword", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/pdf", "text/plain"]}
                errorFile="Only PDF, PPT, Word, TXT files are allowed."
              />
            </div>
            <div className="mt-2">
              <FormError content="This document contains limited readable text, which may affect the generated content quality. Please proceed with caution when using it for generation."/>
            </div>
          </Col>
        </Row>
    </section>
  );
};

export default Generate;