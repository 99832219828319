/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { debounce } from 'lodash';
import TextareaAutosize from "react-textarea-autosize";
import { useDispatch } from "react-redux";
import { apiCaller } from "@utils";

//constant
import { storyboardConstants } from "@constants";

//components
import ChatPromptTemp from "./ChatPromptTemp";
import { ButtonSwitchInstantFeedback, AutomaticallyLaunchFeedback } from "../InstantFeedbackTab";

//reactstrap
import { FormGroup, Label } from "reactstrap";

function InstantFeedbackInstructions(props) {
  const { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { timepointData } = props && props;
  const InstantFeedbackPromptTemplates = timepointData?.ChatSettings?.InstantFeedbackPromptTemplates || [];

  //state
  const [content, setContent] = useState(timepointData?.ChatSettings?.FeedbackInstructions || "");
  const [stateInstantChatFeedbackOption, setInstantChatFeedbackOption] = useState(timepointData?.ChatSettings?.InstantChatFeedbackOption);

  useEffect(() => {
    setContent(timepointData?.ChatSettings?.FeedbackInstructions || "");
    setInstantChatFeedbackOption(timepointData?.ChatSettings?.InstantChatFeedbackOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timepointData]);

  // Update Chat Settings
  const updateChatSettings = (params) => {
    const isLoading = true;
    dispatch({ type: storyboardConstants.CHAT_SETTINGS_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
      } else {
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
        console.log("error");
      }
    });
  };

  const debouncedApiCaseScript = useCallback(debounce((value) => {
    const params = {
      "Action": "Update",
      "Target": "ChatSettings",
      "FeedbackInstructions": value,
      "TimePointId": timepointData?.Id
    };
    updateChatSettings(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 1000), []);

  // Handle Change Editor
  const handleChange = (e) => {
    const { value } = e.target;
    setContent(value);
    debouncedApiCaseScript(value);
  };

  //set Switch
  const handleChangeSwitch = (e) => {
    const { checked } = e.target;
    setInstantChatFeedbackOption(checked);
    const params = {
      "Action": "Update",
      "Target": "ChatSettings",
      "TimePointId": timepointData?.Id,
      "InstantChatFeedbackOption": checked
    };
    updateChatSettings(params);
  }

  return (
    <React.Fragment>
      <ButtonSwitchInstantFeedback
        {...props}
        handleChangeSwitch={handleChangeSwitch}
        value={stateInstantChatFeedbackOption}
      />
      <AutomaticallyLaunchFeedback
        {...props}
        updateChatSettings={updateChatSettings}
      />
      <FormGroup>
        <Label className="labeForm mt-4">
          Instant Feedback:
        </Label>
        <TextareaAutosize
          name="GradingFeedbackInstructions"
          className="w-100 form-control"
          maxRows="10"
          minRows={8}
          onChange={handleChange}
          value={content}
        />
      </FormGroup>
      <ChatPromptTemp
        currentTemplate={InstantFeedbackPromptTemplates}
        typeChatSettings="InstantFeedbackPromptTemplates"
      />
    </React.Fragment>
  );
}

export default InstantFeedbackInstructions;