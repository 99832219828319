import React from 'react';
import { Button } from 'reactstrap';
import { CustomProgressBar } from "@components-common";

//Icon
import { Iconsax } from "@components-common";

//components
import { ButtonCaseItem } from "../../Details";

function CaseProcessItem(props) {
    const { Locked, Thumbnail, Title, Progress, CaseSettings } = props && props;
    const caseProcessClasses = Locked ? 'case-process__item gray-filter' : 'case-process__item';
    return (
        <div className={caseProcessClasses}>
            <div className="case-process-info">
                <div className="case-process-info__left" style={{ backgroundImage: `url("${Thumbnail}")` }}>
                    {CaseSettings?.level && <span className="tag-el">{CaseSettings?.level}</span>}
                </div>
                <div className="case-process-info__right">
                    <div className="d-flex justify-content-between case-process-box">
                        <div className="inner-content-left w-100">
                            <h3 className={`case-title ${Locked ? 'gray-filter-element' : ''}`}>{Title}</h3>
                            <div className="d-flex align-items-center">
                                {(CaseSettings?.duration || CaseSettings?.credit) && <Iconsax iconName="history" fill="#697592" />}
                                {CaseSettings?.duration && (
                                    <span className="content-gray ml-2">{CaseSettings?.duration} mins</span>
                                )}
                                {CaseSettings?.credit && <span className={`ml-2 content-gray ${Locked ? 'gray-filter-element' : ''}`}>({CaseSettings?.credit} credits)</span>}
                            </div>
                            {Locked && <p className="gray-filter-element mt-3">Pass to complete cases</p>}
                            {!Locked && <CustomProgressBar value={Progress?.progress_percent} colorClass={`--yellow-bg`} />}
                        </div>
                        <div className="inner-content-right ml-3">
                            {Locked ? (
                                <Button color="primary" className="btn-brand-sm" style={{ pointerEvents: 'none' }}>
                                    <Iconsax iconName="lock" size={20} fill="#fff" />
                                    <span className="ml-2">Lock</span>
                                </Button>
                            ) : (
                                <ButtonCaseItem
                                    {...props}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CaseProcessItem;