import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actVitalSignChange } from "@actions";
import ItemCell from "./ItemCell";

const ItemRow = (props) => {
    //props
    const { timepointData } = props || {};

    const { DefaultVitalSigns: initialVitalSigns, Id: timePointId } = timepointData || {};
    const { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [vitalSigns, setVitalSigns] = useState(initialVitalSigns);
    const [isLoading, setIsLoading] = useState(Array(initialVitalSigns?.length).fill(false));

    //Load Data
    useEffect(() => {
        setVitalSigns(initialVitalSigns);
        initialVitalSigns?.length > 0 && setIsLoading(Array(initialVitalSigns?.length).fill(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle Save
    const handleSaveValue = useCallback((index, values) => {
        // Call API to update vital signs whenever it changes
        setIsLoading(prev => {
            const newLoadingState = [...prev];
            newLoadingState[index] = true;
            return newLoadingState;
        });
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timePointId,
            "VitalSignChange": {
                "Id": values?.Id,
                "ChangeMode": values?.ChangeMode,
                "ChangeValue": values?.ChangeValue,
                "ChangeLimit": values?.ChangeLimit
            }
        };
        dispatch(actVitalSignChange(id, params))
            .then(() => {
                setIsLoading(prev => {
                    const newLoadingState = [...prev];
                    newLoadingState[index] = false;
                    return newLoadingState;
                });
            })
            .catch(() => {
                setIsLoading(prev => {
                    const newLoadingState = [...prev];
                    newLoadingState[index] = false;
                    return newLoadingState;
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Change
    const handleChange = useCallback((e, index) => {
        const { value, name } = e.target;
        setVitalSigns(prevVitalSigns => {
            const newVitalSigns = [...prevVitalSigns];
            newVitalSigns[index][name] = value;
            return newVitalSigns;
        });
    }, []);

    return (
        <>
            {vitalSigns?.map((value, index) => (
                <ItemCell
                    {...props}
                    key={index}
                    value={value}
                    index={index}
                    handleChange={handleChange}
                    handleSaveValue={handleSaveValue}
                    isLoading={isLoading}
                />
            ))}
        </>
    );
};

export default ItemRow;