import React from "react";

//reactstrap
import { FormGroup, Input, Label } from "reactstrap";

function InputFilter(props) {

    const { data,
        handleChange,
        placeholder,
        name,
        type,
        paramsOptions,
        isDisabled,
        defaultValue
    } = props && props;

    return (
        <FormGroup>
            <Label for={name}>{name}:</Label>
            <Input
                className="ml-auto"
                type="select"
                name={type}
                placeholder={placeholder}
                style={{ height: '38px' }}
                onChange={handleChange}
                disabled={isDisabled}
                defaultValue={defaultValue}
            >
                <option value="">{placeholder}</option>
                {data?.map((value, index) => (
                    <option key={index} value={value[paramsOptions?.value]}>
                        {value[paramsOptions?.content]}
                    </option>
                ))}
            </Input>
        </FormGroup>
    );
}

export default InputFilter;