import { planConstant } from "@constants";
import {apiCaller} from "@utils";

//List All Launch Plans
export const atcLoadLaunchPlans = () => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/launchplan/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: planConstant.GET_LAUNCH_PLANS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: planConstant.GET_LAUNCH_PLANS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: planConstant.GET_LAUNCH_PLANS_FAILURE, error: error };
  }
};

//Delete Launch Plan
export const atcDeleteLaunchPlan = (params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/launchplan/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: planConstant.DELETE_LAUNCH_PLANS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: planConstant.DELETE_LAUNCH_PLANS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: planConstant.DELETE_LAUNCH_PLANS_FAILURE, error: error };
  }
};

export const atcLoadPlanDetails = (params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/launchplan/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: planConstant.LOAD_PLAN_DETAILS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: planConstant.LOAD_PLAN_DETAILS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: planConstant.LOAD_PLAN_DETAILS_FAILURE, error: error };
  }
};

//Change Subscription
export const changeSubscriptionAccount = (params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/account/subscription/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
        window.location.href = '/teach/home';
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: planConstant.SUMIT_CHANGE_SUBSCRIPTION_REQUEST, isLoading };
  }
  function success(data) {
    return { type: planConstant.SUMIT_CHANGE_SUBSCRIPTION_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: planConstant.SUMIT_CHANGE_SUBSCRIPTION_FAILURE, error: error };
  }
};

//Update Assistant Tip Record
export const loadFeatureList = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/features/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.statusText === "OK") {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: planConstant.LOAD_FEATURE_LIST_REQUEST, isLoading } }
    function success(data) { return { type: planConstant.LOAD_FEATURE_LIST_SUCCESS, payload: data } }
    function failure(error) { return { type: planConstant.LOAD_FEATURE_LIST_FAILURE, error: error } }
}