import React from 'react';
import styled from 'styled-components';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  margin-bottom: 12px;
`;

const Tab = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 6px;
  border: 1.5px solid #0089C2;
  margin-right: 8px;
  background-color: ${({ isActive }) => (isActive ? '#0089C2' : '#FFF')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#081B2A')};
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

const Tabs = (props) => {
    const { narratives, activeTab, onTabChange } = props && props;
    return (
        <TabsWrapper>
            {narratives?.map((narrative) => (
                <Tab
                    key={narrative.TimePointId}
                    isActive={activeTab === narrative?.TimePointId}
                    onClick={() => onTabChange(narrative?.TimePointId)}
                >
                    {narrative?.TimePointName}
                </Tab>
            ))}
        </TabsWrapper>
    );
};

export default Tabs;