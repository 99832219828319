//access
import {
    LearnersPage,
    CollaboratorsPage,
    TrialPeriodsPage,
    TeamsAccessPage,
} from "@pages/med2lab/access";

const accessRoutes = [
    // access - teach
    {
        path: "/teach/access/learners/:id",
        exact: true,
        classPage: '',
        component: LearnersPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/access/collaborators/:id",
        exact: true,
        classPage: '',
        component: CollaboratorsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/access/trial-periods/:id",
        exact: true,
        classPage: '',
        component: TrialPeriodsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/access/teams/:id",
        exact: true,
        classPage: '',
        component: TeamsAccessPage,
        isLayout: true,
        navigator: true
    }
];

export { accessRoutes };