import React, { useState, useEffect, useRef } from 'react';
import JoditEditor from "jodit-react";
import { FormGroup, Label } from "reactstrap";
import * as Config from "@constants/Config";

function OverallExplanation({ questionData, handleOverallExplanation }) {
    const { QuestionExplanation } = questionData || {};
    const [editorValue, setEditorValue] = useState(QuestionExplanation || "");
    const refDiv = useRef(null);

    useEffect(() => {
        if (QuestionExplanation !== undefined) {
            setEditorValue(QuestionExplanation);
        }
    }, [QuestionExplanation]);

    const handleEditorBlur = (newContent) => {
        setEditorValue(newContent);
        if (handleOverallExplanation) {
            handleOverallExplanation(newContent);
        }
    };

    return (
        <FormGroup>
            <Label className="labeForm" for="QuestionText">
                Overall Explanation
            </Label>
            <JoditEditor
                ref={refDiv}
                value={editorValue}
                config={Config.CONFIG_JODIT}
                placeholder="Enter your text here..."
                tabIndex={1}
                onChange={handleEditorBlur}
            />
        </FormGroup>
    );
};

export default OverallExplanation;