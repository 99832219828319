import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  margin-bottom: 24px;
`;

const Li = styled.li`
  display: flex;
  padding: 6px 25px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #1D2939;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  
  &.active {
    border: 1px solid var(--Base-Brand-Green, #0089C2);
    background: var(--Base-Brand-Green, #0089C2);
    color: #fff;
  }
`;

const PointList = ({ timepointData, PointId, currentAttributeId, onItemClick }) => {
  const [activePointId, setActivePointId] = useState(PointId);
  const [activeAttributeId, setActiveAttributeId] = useState(currentAttributeId);

  useEffect(() => {
    setActivePointId(PointId);
  }, [PointId]);

  useEffect(() => {
    setActiveAttributeId(currentAttributeId);
  }, [currentAttributeId]);

  const filteredFindings = timepointData?.Exam?.ExamPoints?.find(point => point.PointId === activePointId)?.Findings || [];
  return (
    <Ul>
      {filteredFindings.map((finding, index) => (
        <Li
          key={index}
          className={activeAttributeId === finding?.AttributeId ? 'active' : ''}
          onClick={() => onItemClick(finding?.AttributeId)}
          style={{ cursor: 'pointer' }}
        >
          {finding.Name}
        </Li>
      ))}
    </Ul>
  );
};

export default PointList;