import React, { useState } from "react";
import { Row, Col, Label, FormGroup, Input, Button } from "reactstrap";
import { TableContainer } from "../TabQuestions";
import { useParams } from "react-router-dom";

//Link
import { Link } from "react-router-dom";

//components
import { GradingFieldsModal } from "../TabQuestions";

function AnswerDetails(props) {
  let { id } = useParams();
  const { dataQuestion, paramsActive } = props && props;
  const QuestionId = dataQuestion && dataQuestion.QuestionId;

  //state
  const [modal, setModal] = useState(false);
  const [isShowColumn, setShowColumn] = useState(true);

  //Modal
  const toggle = () => setModal(!modal);

  const handleChangeShow = (e) => {
    const { checked } = e.target;
    setShowColumn(checked);
  }
  
  return (
    <div className="tabDetails">
      <Row className="mb-4 align-items-center">
        <Col md={`5`}>
          <h3 className="bg-white --text-primary font-weight-500 mr-5">
            Answer from Learners
          </h3>
        </Col>
        <Col
          className="justify-content-md-end d-flex mt-3 mt-md-0 justify-content-sm-start"
          md={`7`}
        >
          <FormGroup
            check
            inline
          >
            <Input 
              type="checkbox"
              onChange={handleChangeShow}
              defaultChecked={isShowColumn}
              name="isShowColumn"
            />
            <Label check>
              Show custom grading fields
            </Label>
          </FormGroup>
          <Button
            className="--btn-primary"
            onClick={toggle}
          >
            Grading Fields
          </Button>
          <Link
            target="_blank"
            to={`/teach/reports/questions/detail/${id}?question=${QuestionId}&PeriodId=${paramsActive.PeriodId}&CohortId=${paramsActive.CohortId}`}
            className="btn btn-brand --btn-primary ml-2"
          >
            Detail Report
          </Link>
        </Col>
      </Row>
      <TableContainer {...props} isShow={isShowColumn} />
      <GradingFieldsModal {...props} isOpen={modal} toggle={toggle} />
    </div>
  );
}

export default AnswerDetails;
