import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const CheckboxInput = styled.input`
    width: 16px;
    height: 16px;
`;

const Label = styled.label`
    color: #020246;
    font-size: 13px;
    margin: 0;
    & span {
        font-weight: 700;
    }
`;

const AcknowledgementCheckbox = ({ isAcknowledged, handleCheckboxChange }) => {
    return (
        <Container>
            <CheckboxInput
                type="checkbox"
                id="acknowledge"
                checked={isAcknowledged}
                onChange={handleCheckboxChange}
            />
            <Label htmlFor="acknowledge">
                Please check to acknowledge our <span>Privacy & Terms Policy</span>
            </Label>
        </Container>
    );
};

export default AcknowledgementCheckbox;