import React from 'react';

function MessageNotCase(props) {
    return (
        <div className="text-center d-flex algin-items-center justify-content-center flex-wrap my-5">
            <p className="--text-primary">You must have created at least one case to create a new template.</p>
            <p>Click <span className="font-weight-500">{`Cases / Series > Create New Case`}</span> to create a new case.</p>
        </div>
    );
};

export default MessageNotCase;