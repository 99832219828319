import React from "react";

//boostrap
import {
  CustomInput,
  Label
} from "reactstrap";

function QuestionSelectMany(props) {
  const { AnswerChoices, handleSelectMany } = props && props;

  // Choice
  const itemMultipleChoice = AnswerChoices?.map(function (item, i) {
    return (
      <div className={`answerItem fadeInElement ${item?.Selected && "selected"}`} key={i}>
        <Label
          className="answerItem__option"
          style={{ cursor: "pointer" }}
        >
          <CustomInput
            id={`checkbox_${i}`}
            name="Selected"
            type="checkbox"
            data-name={item.Content}
            onChange={(e) => handleSelectMany(item, e)}
            defaultValue={item.Id}
            className="mr-0 d-none"
          />
          {item?.Content}
        </Label>
      </div>
    );
  });

  return (
    <div className="answerList">
      <p className="note_answer_choices py-2 font-weight-600 text-center">select ALL that apply</p>
      <div className="d-flex flex-wrap justify-content-center">
        {itemMultipleChoice}
      </div>
    </div>
  );
}

export default QuestionSelectMany;