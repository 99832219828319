import React, { useEffect } from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { NavigationComponent } from "@components/layouts";

//utils
import { LoaderPage, navigation } from "@utils";

//components
import { TableTicket } from "@components/admin/Ticket";

//call Action
import { actLoadTicket } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

function OnBoardingPage() {
  const dispatch = useDispatch();
  const mainMenu = navigation && navigation.mainAdmin;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actLoadTicket());
    };
    //fetch data
    fetchData();
  }, [dispatch]);

  const dataTicket = useSelector(
    (state) => (state.loadTicket) || []
  );

  const resData = dataTicket && dataTicket.data;
  return (
    <React.Fragment>
      {dataTicket.isLoading && <LoaderPage />}
      {!dataTicket.isLoading && (
        <React.Fragment>
          <NavigationComponent data={mainMenu} />
          <div className="wrapper -OnBoardingPage">
            <Container>
              <TableTicket data={(resData && resData) || []} />
            </Container>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default OnBoardingPage;
