// ExamNote.js
import React from 'react';
import styled from 'styled-components';

// Icon
import { Iconsax } from "@components-common";

const NoteContainer = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #FFF;
  margin-bottom: 12px;
`;

const NoteText = styled.div`
  color: #F1800F;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const ExamNote = () => {
    return (
        <NoteContainer>
            <Iconsax iconName="exam-note" fill="#fff" size={24} />
            <NoteText>
                Left click and hold to move the location of each point.<br/>
                Right click to edit the exam findings at each point.
            </NoteText>
        </NoteContainer>
    );
};

export default ExamNote;