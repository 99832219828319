import React from "react";

//boostrap
import { Alert } from "reactstrap";

function ModulesLeft(props) {
  const { handleAllCase, children } = props && props;
  return (
    <div className="modulesTab__left">
      <ul className="navSidebar">
        <li className="navSidebar__item --hover-text-primary" onClick={handleAllCase}>
          <span className="refLink --text-primary">
            All Cases
            <i className="fa fa-chevron-right ml-auto" aria-hidden="true"></i>
          </span>
        </li>
        {/* item */}
      </ul>
      <hr className="m--font-brand" />
      <h5 className="heading-5 --font-bold">Series</h5>
      <Alert
        className="m-alert--default border-0 text-center"
        color="secondary"
      >
        Series are group of cases that you can assign to learners.
      </Alert>
      <div className="seriesBox">
        <ul className="seriesLs mt-3">{children}</ul>
      </div>
    </div>
  );
}

export default ModulesLeft;
