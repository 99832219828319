import { billingConstant, paymentConstant } from "@constants";

export function billingReport (state = {}, action) {
    switch (action.type) {
        case billingConstant.GET_BILLING_REPORT_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case billingConstant.GET_BILLING_REPORT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case paymentConstant.SET_AUTOMATIC_RENEW_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case billingConstant.GET_BILLING_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};