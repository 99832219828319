import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useHistory, Link, useLocation } from 'react-router-dom';
import imgLogo from '@images/weitzman/logo.png';
import { checkAuth, apiCaller } from '@utils';

//redux
import { useDispatch } from "react-redux";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//common
import { LoaderPage } from "@utils";

function LoginForm() {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { handleSubmit, control, formState: { errors } } = useForm();

    //state
    const [isLoading, setLoading] = useState({
        loginSubmit: false,
        checkEnroll: false
    });
    const [status, setStatus] = useState(null);

    //Check if enrolled in Program
    const CheckEnrolledInProgram = useCallback(() => {
        const params = {
            "Mode": "Check",
            "Program": "weitzman"
        };

        setLoading((prevLoading) => ({
            ...prevLoading,
            checkEnroll: true
        }));
        apiCaller(`/api/learn/program/`, "POST", params).then((res) => {
            const data = res?.data;
            const isEnrolled = data?.Enrolled;
            if (res?.status === 200) {
                if (isEnrolled) {
                    history.push("/");
                } else {
                    history.push('/weitzman/confirm');
                }

                setLoading((prevLoading) => ({
                    ...prevLoading,
                    checkEnroll: false
                }));
            } else {
                console.log("error....");
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    checkEnroll: false
                }));
            }
        });
    }, [history]);

    useEffect(() => {
        document.body.style.backgroundColor = '#fafbfb';
        const searchParams = new URLSearchParams(location.search);
        const statusParam = searchParams.get('status');
        const newStatus = statusParam === 'account_existed'
            ? 'An account with this email already exists. <br /> Please log in to access your existing account.'
            : null;

        setStatus(newStatus);

        if (checkAuth()) {
            CheckEnrolledInProgram();
        } else if (statusParam === 'account_existed') {
            history.push(`/weitzman/login`);
        } else {
            history.push(`/weitzman/access-email`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        setLoading((prevLoading) => ({
            ...prevLoading,
            loginSubmit: true
        }));
        try {
            const formData = new FormData();
            formData.append('username', data?.email);
            formData.append('password', data?.password);
            const response = await apiCaller('/api/auth/login/', 'POST', formData);
            const dataResponse = response?.data;

            if (dataResponse?.status === "OK") {
                //add token
                dispatch(actionLogin(dataResponse));

                //Call API Theme
                dispatch(actFetchThemeRequest());

                setLoading((prevLoading) => ({
                    ...prevLoading,
                    loginSubmit: false
                }));

                // Check Redirect Page
                CheckEnrolledInProgram();
            } else {
                dataResponse?.status && setStatus(response?.data?.status);
                setLoading((prevLoading) => ({
                    ...prevLoading,
                    loginSubmit: false
                }));
            }
        } catch (error) {
            console.error('Error during login:', error);
            setLoading((prevLoading) => ({
                ...prevLoading,
                loginSubmit: false
            }));
        }
    };

    return (
        <div className="loginPartnerContainer">
            {isLoading?.checkEnroll && <LoaderPage />}
            <div className="form-auth-panel">
                <div className="form-auth-panel__logo">
                    <img src={imgLogo} alt="weitzman" />
                </div>
                <div className="auth-login-form">
                    {status && (
                        <div className="message-form m--font-danger my-3 text-center" dangerouslySetInnerHTML={{ __html: status }} />
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <Label className="labeForm" htmlFor="email">
                                Email <span className="m--font-danger ml-2">*</span>
                            </Label>
                            <Controller
                                name="email"
                                control={control}
                                rules={{ required: 'This field is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' } }}
                                render={({ field }) => (
                                    <React.Fragment>
                                        <Input
                                            {...field}
                                            type="email"
                                            placeholder="Enter your email address"
                                            invalid={errors.email ? true : false}
                                        />
                                        {errors.email && (
                                            <div className="invalid-feedback">{errors.email.message}</div>
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="labeForm" htmlFor="password">
                                Password <span className="m--font-danger ml-2">*</span>
                            </Label>
                            <Controller
                                name="password"
                                control={control}
                                rules={{ required: 'This field is required' }}
                                render={({ field }) => (
                                    <React.Fragment>
                                        <Input
                                            {...field}
                                            type="password"
                                            placeholder="Enter your password"
                                            invalid={errors.password ? true : false}
                                        />
                                        {errors.password && (
                                            <div className="invalid-feedback">{errors.password.message}</div>
                                        )}
                                        <Link className="d-block text-right my-2" to="/weitzman/forgot-password">
                                            Forgot password?
                                        </Link>
                                    </React.Fragment>
                                )}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Button
                                type="submit"
                                color="primary"
                                className="btn-brand-sm -btn-lg w-100 -shadown mt-4"
                            >
                                {isLoading?.loginSubmit && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                Submit
                            </Button>
                        </FormGroup>
                        <div className="suggest-action text-center">
                            Looking for a fresh start?{' '}
                            <Link className="link-suggest" to="/weitzman/access-email">
                                Enter another email to continue as a guest
                            </Link>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;