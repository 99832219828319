import React from 'react';

function TableCell(props) {
    const { value, handleEdit } = props && props;
    return (
        <td style={{ cursor: 'pointer' }} className="align-middle" width="20%" onClick={handleEdit}>
            {value}
        </td>
    );
};

export default TableCell;