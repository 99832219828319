import React, { useState } from 'react'
import { Icon } from '@components/common'
import Email from '@images/common/email.svg'
import Success from '@images/teachV2/success.svg'
import { Button } from 'reactstrap'
import CreatableSelect from 'react-select/creatable';
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';
import { components } from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import { teachV2Constants } from "@constants";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//components
import { MessageShare, MessageContentInvite } from "../ComponentShare";

const createOption = (label) => ({
  label,
  value: label,
});

const EmailSharing = (props) => {
  const { idStory } = props
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [listValue, setListValue] = useState([]);
  const [listEmailError, setListEmailError] = useState([]);
  const [loadingInvite, setLoadingInvite] = useState(false);

  const componentsCommon = {
    DropdownIndicator: () => {
      return (
        <Button
          color="primary"
          className='d-flex align-items-center'
          onClick={() => handleEnroll()}
          disabled={listValue?.length === 0 || listEmailError?.length > 0 || loadingInvite ? true : false}
        >
          Invite
          {loadingInvite && <ClipLoader color="#FFF" size={16} />}
        </Button>
      )
    },
    MultiValueContainer: (props) => {
      const emailError = listEmailError?.map((email) => email?.value)
      return (
        <div className={`${emailError.includes(props?.data?.value) > 0 ? "mail-error" : ""}`}>
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleEnroll = () => {
    setLoadingInvite(true)
    const listEmail = listValue?.map((email) => email.value)
    const params = {
      "Action": "enroll",
      "Emails": listEmail
    }
    apiCaller(`/api/teach/learners/case/${idStory}/`, 'PUT', params).then(res => {
      if (res?.status === 200) {
        setListValue([])
        toast(() => {
          return (
            <div className="content-enroll">
              <Icon src={Success} />
              <p>Email sent successfully</p>
            </div>
          )
        }, {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        dispatch({ type: teachV2Constants.ENROLL_EMAIL_SUCCESS, payload: res.data })
        setLoadingInvite(false)
      } else {
        setLoadingInvite(false)
        setListValue([])
      }
    });
  }

  const handleKeyDown = (event) => {
    const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
      case ' ':
        if (!emailValidationRegex.test(inputValue)) {
          setListEmailError((prev) => [...prev, createOption(inputValue)]);
        }
        setListValue((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleBlur = () => {
    const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (inputValue && emailValidationRegex.test(inputValue)) {
      setListValue((prev) => [...prev, createOption(inputValue)]);
      setInputValue('');
    } else if (inputValue && !emailValidationRegex.test(inputValue)) {
      setListEmailError((prev) => [...prev, createOption(inputValue)]);
      setListValue((prev) => [...prev, createOption(inputValue)]);
      setInputValue('');
    }
  };

  const handleAddEmail = (value) => {
    let emailError = []
    const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setListValue(value)
    value?.forEach((email) => {
      if (!emailValidationRegex.test(email.value)) {
        emailError.push(email)
      }
    })
    setListEmailError(emailError)
  }

  return (
    <div className="email-share">
      <div className="email-share__invite">
        <Icon src={Email} />
        <div className="email-share__invite-content">
          <h1>Invite via Email</h1>
          <p>Invite others to access your case via email. Only those you <br /> add will be able to access it.</p>
        </div>
      </div>
      <MessageShare>
        <MessageContentInvite />
      </MessageShare>
      <div className="email-share__form">
        <p className="email-share__form-label">Enter Email Addresses</p>
        <div className="email-share__form-input">
          <CreatableSelect
            components={componentsCommon}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={(newValue) => handleAddEmail(newValue)}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            placeholder="Type an email, then press space to add another"
            value={listValue}
            classNamePrefix={`select-mail`}
          />
        </div>
        {listEmailError?.length > 0 && (
          <div className="email-share__form-error">
            <p>
              {listEmailError?.map((email, index) => (
                <span key={index}>{`"${email?.value}" `}</span>
              ))}
              <span>is not a valid email</span>
            </p>
          </div>
        )}
      </div>
      <div className="email-share__form">
        <p className="email-share__form-label">Write a Custom Message <span>(Optional)</span></p>
        <TextareaAutosize
          name="prompt"
          placeholder="Write a message..."
          className="w-100 form-control"
          maxRows="10"
          minRows={4}
        // value={content}
        // onChange={(e) => {
        //   setValue("prompt", e.target.value);
        // }}
        />
      </div>
    </div>
  )
}

export default EmailSharing