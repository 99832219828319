const paginationOptions = ({
  totalSize = 0,
  paginationComponent = null,
  custom = true,
  pageSize = window.innerWidth <= 480 ? 1 : 10, // Default to 10, adjust based on screen width
  startPageIndex = 1,
  labels = {}, // Start with an empty object
  showTotal = true
} = {}) => {
  
  // Default labels
  const defaultLabels = {
    first: "First",
    back: "Back",
    next: "Next",
    last: "Last",
    firstTitle: "First page",
    backTitle: "Previous page",
    nextTitle: "Next page",
    lastTitle: "Last page"
  };

  // Merge provided labels with defaults
  const mergedLabels = { ...defaultLabels, ...labels };

  return {
    paginationComponent, // Custom pagination component
    custom,
    sizePerPage: pageSize, // Set the number of items displayed per page
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "All", value: totalSize }
    ], // Add multiple options for page size selection
    pageStartIndex: startPageIndex,
    firstPageText: mergedLabels.first,
    prePageText: mergedLabels.back,
    nextPageText: mergedLabels.next,
    lastPageText: mergedLabels.last,
    nextPageTitle: mergedLabels.nextTitle,
    prePageTitle: mergedLabels.backTitle,
    firstPageTitle: mergedLabels.firstTitle,
    lastPageTitle: mergedLabels.lastTitle,
    showTotal,
    totalSize
  };
};

export default paginationOptions;