import React from "react";
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";
import { teachV2Constants } from "@constants";

//Boostrap
import { Button, Input, Modal, ModalBody } from "reactstrap";
import TitleNoteCommon from "../generate/TitleNoteCommon";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

function ModalRegenerate(props) {
  const dispatch = useDispatch();
  const { isOpen, toggle, idCaseScript, setLoadingContent, titleGenerate } = props && props;
  
  //form
  const {
    handleSubmit,
    watch,
    setValue
  } = useForm();
  
  const onSubmit = (data) => {
    setLoadingContent(true)
    const params = {
      action_type: "regenerate_case_script",
      id: idCaseScript,
      section: titleGenerate,
      modification_request: data?.requirements
    }
    toggle()
    apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      if (res.status === 200) {
        setLoadingContent(false)
        dispatch({ type: teachV2Constants.REGENERATE_CASE_SCRIPT_SUCCESS, payload: res?.data });
      } else {
        setLoadingContent(false)
        toast.success(`Regenerate failed!`, {
          position: "top-right"
        });
      }
    });
  }

  return (
    <Modal className="modal-feedback" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="feedback__content">
          <div className="feedback__content-info">
            <div className="info-feedback align-items-start">
              <h1 className="info-feedback__title">Re-generate Section Content</h1>
              <p className="info-feedback__note">Please provide specific requirements you'd like the AI to incorporate when regenerating this section. Changes will apply to this section only.</p>
            </div>
          </div>
          <div className="feedback__content-form">
            <TitleNoteCommon
              title="Additional Requirements"
            />
            <Input
              type="textarea"
              name="requirements"
              placeholder="Example: Include more details on patient symptoms"
              onChange={(e) => {
                setValue("requirements", e.target.value);
              }}
              defaultValue={watch("requirements")}
              className="mt-1"
            />
          </div>          
        </div>
        <div className="feedback__action">
          <Button className="btn btn-secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn d-flex align-items-center"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalRegenerate;