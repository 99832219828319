import React from "react";

//reactstrap
import { Button } from 'reactstrap';

function HeadingTable (props) {
  const { buttonText, title, handleAdd } = props && props;
  return (
    <div className="d-flex align-items-center">
      <h2 className="heading-4 -heading-primary --text-primary">
        {title || ''}
      </h2>
      {buttonText &&
        <Button className="btn-sm --btn-primary ml-auto" onClick={handleAdd}>
          <i className="fa fa-plus mr-2"></i>
          {buttonText}
        </Button>
      }
    </div>
  );
}

export default HeadingTable;