import React from 'react';
//reactBoostrap
import {
    FormGroup,
    Label,
    Col,
    Input,
    Alert
} from "reactstrap";

function Domains(props) {
    const { domainData, handleChangeDomains } = props && props;
    return (
        <Col md={12}>
            <h5 className="heading-5">Domains</h5>
            <Alert className="m-alert--default border-0">
                Which assessment domain(s) does this patient belong ?
            </Alert>
            {domainData &&
                domainData.map((value, index) => (
                    <FormGroup
                        check
                        style={{ color: value.Color }}
                        className="mb-2"
                        key={index}
                    >
                        <Label check>
                            <Input
                                type="checkbox"
                                onChange={handleChangeDomains}
                                value={value.Id}
                                name="Domains"
                                defaultChecked={value.Selected}
                            />
                            {value.Name}
                        </Label>
                    </FormGroup>
                ))}
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    The point learners earn from this question will be added
                    to all of the designated domains
                </small>
            </p>
        </Col>
    );
};

export default Domains;