// MakansafeLogo.js
import React from 'react';
import { Link } from 'react-router-dom';

// Logo
import logoMakansafe from '@images/makansafe/logo_makansafe.png';
import logoMakansafeSmall from '@images/makansafe/logo_makansafe_sm.png';

const MakansafeLogo = ({ isSidebarCollapsed }) => {
    console.log(isSidebarCollapsed, "isSidebarCollapsed....");
    return (
        <div className={`logo d-flex ${isSidebarCollapsed ? "justify-content-end" : "justify-content-center"}`}>
            <Link className={`${isSidebarCollapsed ? "small_logo" : "large_logo"}`} to={"/"}>
                <img src={isSidebarCollapsed ? logoMakansafeSmall : logoMakansafe} alt="Makansafe Logo" style={{ maxWidth: isSidebarCollapsed ? '72px' : '100%' }} />
            </Link>
        </div>
    );
};

export default MakansafeLogo;