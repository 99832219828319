import qs from 'qs';

//clear item
function beginPage (history, pathname) {
    if (history.action === 'POP') {
        setTimeout(() => {
            // clear stores
            const paramOuters = qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
            });
            for (let item in paramOuters) {
                localStorage.setItem(item, paramOuters[item]);
            }
            history.replace({
                pathname: pathname,
                search: history.location.search,
                hash: history.location.hash,
            });
        }, 0);
    }
};

export default beginPage;