import React, { useState } from "react";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";

//parents
import { FormModal } from "@ui-partents/Modal";

//actions
import { atcUpdateCohort } from "@actions";

//redux
import { useDispatch } from "react-redux";

//formik
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const ModalEditCohort = (props) => {
  const { toggle, isOpen, cohortObject, activeCohortAfterEdit } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // State for loading

  // Validation schema using Yup
  const validationSchema = Yup.object({
    Name: Yup.string().required("This is required."),
  });

  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    const params = {
      Action: "Update",
      CohortId: cohortObject?.CohortId,
      Name: values.Name,
    };

    setIsLoading(true); // Start loading
    try {
      const response = await dispatch(atcUpdateCohort(params));
      if (response?.status === 200) {
        activeCohortAfterEdit({
          ...cohortObject,
          Name: values.Name,
        });
      } else {
        console.error("Update failed: Response status not 200");
      }
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      setIsLoading(false); // Stop loading
      resetForm(); // Reset form values to initial state
      toggle(); // Close modal
    }
  };

  return (
    <Formik
      initialValues={{
        Name: cohortObject?.Name || "", // Initialize with cohortObject.Name
      }}
      enableReinitialize={true} // Reinitialize values when cohortObject changes
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleChange, handleBlur, resetForm, submitForm, values }) => (
        <FormModal
          isOpen={isOpen}
          toggleModal={() => {
            resetForm(); // Reset form to original values on close
            toggle(); // Close modal
          }}
          title={`Edit Cohort`}
          hiddenIcon={true}
          onAction={submitForm} // Trigger Formik submit
          actionText={`Save`}
          maxWidth={`600px`}
          isLoading={isLoading} // Pass loading state to modal
        >
          <Form>
            <FormGroup className="mb-0">
              <Label for="Name">
                Name <span className="m--font-danger">*</span>
              </Label>
              <Field
                as={Input}
                type="text"
                name="Name"
                id="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.Name} // Controlled input via Formik
                invalid={touched.Name && !!errors.Name}
                placeholder=""
                autoComplete="off"
              />
              {touched.Name && errors.Name && (
                <FormFeedback invalid="true">{errors.Name}</FormFeedback>
              )}
            </FormGroup>
          </Form>
        </FormModal>
      )}
    </Formik>
  );
};

export default ModalEditCohort;