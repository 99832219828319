// MessagePage.js
import React from 'react';
import { Row, Col } from 'reactstrap';

//common
import { MessageFullScreen, MessageEmtyData } from "@ui-partents/Message";

const MessagePage = () => {
    return (
        <section className="content-component">
            <div className='my-3'>
                <Row className='mb-4'>
                    <Col md={12}>
                        <div className="content-header">
                            <h1>Message - Full Screen</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <MessageFullScreen
                            title="Oops! Access Denied"
                            text="We're sorry, but it looks like you don't have permission to access this interactive case. Please click the button below to return to the Home Page."
                            accessDeniedCase={true}
                        />
                    </Col>
                </Row>
            </div>
            <div className='my-3'>
                <Row className='mb-4'>
                    <Col md={12}>
                        <div className="content-header">
                            <h1>Message - Data Emty</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <MessageEmtyData
                            title="Your Library is Empty"
                            text="It looks like you haven't generated any case scenarios yet. Start creating a new one to see it here."
                        />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default MessagePage;