import { humanConstants } from "@constants";
import {apiCaller} from "@utils";

//Case - Switch to Use Human Model
export const atcSwitchHumanModel = (params, id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: humanConstants.SWITCH_HUMAN_MODEL_REQUEST, isLoading } }
    function success(data) { return { type: humanConstants.SWITCH_HUMAN_MODEL_SUCCESS, payload: data } }
    function failure(error) { return { type: humanConstants.SWITCH_HUMAN_MODEL_FAILURE, error: error } }
}