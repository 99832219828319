import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import { UpdateTimePoint } from "../../storyboard/TimePoint";
import { Button } from "reactstrap";

const HeaderTimePoint = (props) => {
    const { isLoading, timepointData } = props;
    const [title, setTitle] = useState(timepointData?.DisplayName);

    useEffect(() => {
        setTitle(timepointData?.DisplayName);
    }, [timepointData?.DisplayName]);

    return (
        <div className="tab-details__header d-flex justify-content-between">
            <div>
                {isLoading ? (
                    <Skeleton width={`100%`} height={30} />
                ) : (
                    <h2 className="tab-details__headline">{title}</h2>
                )}
            </div>
            <div className="align-items-center">
                <Button
                    color="primary"
                    className="px-3 d-none"
                >
                    Open Prompt Template Editor
                </Button>
                <UpdateTimePoint
                    {...props}
                />
            </div>
        </div>
    );
};

export default HeaderTimePoint;