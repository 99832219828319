import React, { useState, useEffect } from 'react';

//tabs
import { VisitDocument } from '../MedicalRecord';

function MedicalRecordTabs(props) {
    //props
    const { caseData } = props && props;

    //state
    const [stateDataCase, setDataCase] = useState(caseData || {});

    //load
    useEffect(() => {
        setDataCase(caseData || {});
    }, [caseData])

    const resCaseData = stateDataCase?.data;
    const caseDetail = resCaseData?.Case;
    const Documentation = caseDetail?.Documentation;
    const FeedbackData = Documentation?.Feedback;
    return (
        <VisitDocument {...props} FeedbackData={FeedbackData || ''} />
    );
};

export default MedicalRecordTabs;