import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

//utils
import { apiCaller, LoaderText } from "@utils";

//components
import {
  Filter,
  ModalAiLabel,
  ModalHumanLabel,
  ModelsPagination,
} from "@components/ReliDiagnosis";

//queryString
import queryString from "query-string";

//lodash
import _ from "lodash";

//Components common
import { ButtonDelete } from "@components/common/elements";
import { ModalDeleteCommon } from "@ui-partents/Modal";
import { DataEmty } from "@components/common";

function TableReliDiagnosis(props) {
  let pageNumberLimit = 10;

  //check params
  let queryParams = queryString.parse(window.location.search);
  let questionIdHardCode = 1534

  //state
  const [rowRecord, setRowRecord] = useState({});
  const [stateData, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [stateFilter, setStateFilter] = useState({
    record_id: queryParams?.record_id || null,
    model_id: queryParams?.model_id || null,
    question_id: queryParams?.question_id || null,
  });

  // Get All Records - khi load page
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "get_record_list",
        page_size: pageNumberLimit,
        current_page: currentPage,
        question_id: questionIdHardCode,
        model_id: parseFloat(stateFilter?.model_id) || null,
        record_id: parseFloat(stateFilter?.record_id) || null,
      };
      setLoading(true);
      apiCaller(
        `/api/binh/model_management/model_for_fe/`,
        "POST",
        params
      ).then((res) => {
        const resData = res?.data;
        if (res?.status === 200) {
          setData(resData);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      });
    };
    currentPage && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageNumberLimit, questionIdHardCode]);

  //props

  const [modal, setModal] = useState({
    aiLabel: false,
    humanlabel: false,
  });

  const toggle = (type, row) => {
    setRowRecord(row);
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  //Handle Filter id
  const handleUpdateDataFilter = (type) => {
    const clickReset = type === "isReset";
    const params = {
      Action: "get_record_list",
      page_size: pageNumberLimit,
      current_page: 1,
      // question_id: clickReset
      //   ? null
      //   : parseFloat(stateFilter?.question_id) || null,
      question_id: questionIdHardCode,
      model_id: clickReset ? null : parseFloat(stateFilter?.model_id) || null,
      record_id: clickReset ? null : parseFloat(stateFilter?.record_id) || null
    };
    setLoading(true);
    setCurrentPage(1);
    apiCaller(`/api/binh/model_management/model_for_fe/`, "POST", params).then(
      (res) => {
        const resData = res?.data;
        if (res?.status === 200) {
          setData(resData);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      }
    );
  };

  //Handle Search
  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setStateFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  //Update score for item
  const handleUpdateScoreTextItem = (data) => {
    const textIdCurrent = data?.record_items && data?.record_items[0]?.text_id;
    const scoreCurrent = data?.record_items && data?.record_items[0]?.score;
    let listData = [...stateData?.items];
    let cloneData = _.cloneDeep(stateData?.items);
    const indexCurrent = _.findIndex(cloneData, function (o) {
      return o.text_id === textIdCurrent;
    });
    listData[indexCurrent][`score`] = scoreCurrent;
    setData({ ...stateData, items: listData });
  };

  //Hanle Remove Record 
  const handleRemove = () => {
    const params = {
      "Action": "delete_record",
      "page_size": pageNumberLimit,
      "current_page": currentPage,
      "question_id": rowRecord?.question_id || null,
      "model_id": rowRecord?.model_id || null,
      "record_id": rowRecord?.record_id || null
    }

    setLoading(true);
    apiCaller(`/api/binh/model_management/model_for_fe/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        console.log(data, "data...");
        setModal((prevState) => ({ ...prevState, DeleteRecord: false }));
        setLoading(false);
        setData(data);
      } else {
        setLoading(false);
        setModal((prevState) => ({ ...prevState, DeleteRecord: false }));
      }
    });
  }

  const rowItems = stateData?.items?.map((value, index) => {
    return (
      <tr key={index}>
        <td>{value?.record_id}</td>
        <td>{value?.model_id}</td>
        <td className="text-center">
          {" "}
          <Button
            className="btn btn-brand btn-sm"
            color="primary"
            onClick={() => toggle("aiLabel", value)}
          >
            <i className="fa fa-eye mr-2" aria-hidden="true"></i> View
          </Button>
        </td>
        <td className="text-center">
          <Button
            className="btn btn-brand btn-sm"
            color="primary"
            onClick={() => toggle("humanlabel", value)}
          >
            <i className="fa fa-eye mr-2" aria-hidden="true"></i> View
          </Button>
        </td>
        <td>{value?.score}</td>
        <td>
          <ButtonDelete handleDelete={() => toggle('DeleteRecord', value)} />
        </td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <Filter
        {...props}
        handleSubmitFilter={handleUpdateDataFilter}
        handleChangeFilter={handleChangeFilter}
        stateFilter={stateFilter}
        currentPage={currentPage}
        handleResetFilter={() => {
          setStateFilter({
            record_id: null,
            model_id: null,
            question_id: questionIdHardCode,
          });
          handleUpdateDataFilter("isReset");
          setCurrentPage(1);
        }}
      />
      <div className="table-responsive position-relative">
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th className="align-middle font-weight-500 text-nowrap">
                Record Id
              </th>
              <th className="align-middle font-weight-500 text-nowrap">
                Models ID
              </th>
              <th width="150px" className="align-middle font-weight-500 text-nowrap text-center">
                AI Labels
              </th>
              <th width="150px" className="align-middle font-weight-500 text-nowrap text-center">
                Human Labels
              </th>
              <th className="align-middle font-weight-500 text-nowrap">
                Score
              </th>
              <th width="50px"></th>
            </tr>
          </thead>
          <tbody>
            {rowItems}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <LoaderText />
                </td>
              </tr>
            )}
            {stateData?.items?.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <DataEmty content="No Data" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ModelsPagination
        {...props}
        loading={loading}
        currentData={stateData}
        stateCurrentPage={currentPage}
        handleSetCurrentPage={(pageClick) => setCurrentPage(pageClick)}
      />
      <ModalAiLabel
        isOpen={modal.aiLabel}
        toggle={() => toggle("aiLabel", rowRecord)}
        rowRecord={rowRecord || {}}
      />
      <ModalHumanLabel
        isOpen={modal.humanlabel}
        toggle={() => toggle("humanlabel", rowRecord)}
        rowRecord={rowRecord || {}}
        handleUpdateScoreTextItem={handleUpdateScoreTextItem}
      />
      <ModalDeleteCommon
        title={`Are you sure you want to delete this record ?`}
        sub={`AI labels will be removed. Human labels will be retained`}
        isOpen={modal?.DeleteRecord}
        toggle={() => toggle('DeleteRecord', rowRecord)}
        handleRemove={handleRemove}
      // isLoading={isLoading}
      />
    </React.Fragment>
  );
}

export default TableReliDiagnosis;