import React from "react";
import { Row, Col } from "reactstrap";
import { ChatRecords, FeedbackContent } from ".";

function ChatFeedbackMain(props) {
    return (
        <Row>
            <Col md={6}>
                <div className="h-100">
                    <ChatRecords
                        {...props}
                    />
                </div>
            </Col>
            <Col md={6}>
                <div className="h-100">
                    <FeedbackContent
                        {...props}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default ChatFeedbackMain;