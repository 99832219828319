import React, { useCallback, useRef } from 'react'
import { useWavesurfer } from '@wavesurfer/react'
// import Timeline from 'wavesurfer.js/dist/plugins/timeline.esm.js'
import styled from 'styled-components'
import { Icon } from '@components/common';
import Play from '@images/common/play-audio.svg';
import Pause from '@images/common/pause.svg';

const WrapAudio = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  width: 100%;
  .btn-audio {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #BCE3F3;
  }
  .audio-run {
    width: 100%;
    z-index: 0;
  }
  .audio-time {
    width: max-content;
  }
`

const formatTime = (seconds) => [seconds / 60, seconds % 60].map((v) => `0${Math.floor(v)}`.slice(-2)).join(':')

// A React component that will render wavesurfer
export const Waveform = (props) => {
  const { dataView } = props
  const containerReff = useRef(null)

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: containerReff,
    height: 44,
    waveColor: 'rgba(15, 8, 43, 0.20)',
    progressColor: 'rgba(15, 8, 43, 0.60)',
    url: dataView?.url,
    // plugins: useMemo(() => [Timeline.create()], []),
  })

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause()
  }, [wavesurfer])

  return (
    <WrapAudio>
      <div className='btn-audio' onClick={onPlayPause}>
        {isPlaying ? (
          <Icon src={Pause}/>
        ) : (
          <Icon src={Play}/>
        )}
      </div>
      <div className='audio-run' ref={containerReff} />
      <p className='audio-time'>{formatTime(currentTime)}</p>
    </WrapAudio>
  )
}
