import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Modal
import { TemplateType, TextareaAutosizeInput } from "../Modal/Timepoint";

//Select
import Select from "react-select";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

import {
  Row,
  Modal,
  ModalBody,
  Button,
  Input,
  FormGroup,
  Label,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

// common 
import { ModalDeleteCommon } from "@ui-partents/Modal";

function ModalTemplateEditor(props) {
  const dispatch = useDispatch();
  const { isOpen, toggle } = props && props;
  const [isLoading, setIsLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [actionPrompt, setActionPrompt] = useState(null)
  const [promptText, setPromptText] = useState('');
  const [valueName, setValueName] = useState(null)
  const [valueTemplate, setValueTemplate] = useState()
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [stateDefaultActiveStatus, setDefaultActiveStatus] = useState(true);

  const dataPromptTemplate = useSelector(
    (state) => state.dataPromptTemplate || {}
  );
  
  const handleChangeOptions = (data) => {
    changeActionPrompt("Update")
    setValue("template", data.Name);
    setValue("Name", data.Name);
    setValueName(data.Name)
    setValue("Content", data.Content);
    setValueTemplate(data)
    setPromptText(data.Content);
    setDefaultActiveStatus(data.Type === 'question_feedback_fixed' ? true : false);
    setSelectedTemplate(data);
  };

  const handleCancleModal = () => {
    toggle()
    setSelectedTemplate(null)
    setActionPrompt(null)
    setValueTemplate([])
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValue(name, value);
    setPromptText(value);
  };

  const getListPromptTemplate = () => {
    const isLoading = true;
    setIsLoading(true);
    dispatch({ type: storyboardConstants.GET_PROMPT_TEMPLATE_REQUEST, isLoading });
    apiCaller(`/api/teach/prompt/template/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res?.status === 200) {
        dispatch({
          type: storyboardConstants.GET_PROMPT_TEMPLATE_SUCCESS,
          payload: data,
          isLoading: false
        });
        setIsLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.GET_PROMPT_TEMPLATE_FAILURE,
          error: 'error',
          isLoading: false
        });
        setIsLoading(false);
      }
    })
  }

  useEffect(() => {
    getListPromptTemplate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeActionPrompt = (actions) => {
    setActionPrompt(actions)
    if (actions === "Create") {
      reset({
        Name: "",
        Content: "",
        template: ""
      })
      setValueName("")
      setValueTemplate([])
      setPromptText("");
      setDefaultActiveStatus(true);
      setValue("Type", 'question_feedback_fixed');
      setSelectedTemplate(null);
    }
  }

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const hanldeActionsUpdate = (data) => {
    let params
    params = {
      Name: data?.Name,
      Type: data?.Type,
      Content: data?.Content,
      Action: actionPrompt
    }
    if (actionPrompt === "Update") {
      params["Id"] = selectedTemplate.Id
    }
    const isLoading = true;
    setIsLoading(true);
    //Call Api
    dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_REQUEST, isLoading });
    apiCaller(`/api/teach/prompt/template/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_SUCCESS, payload: data });
        setIsLoading(false);
        handleCancleModal()
        getListPromptTemplate()
        // if (actionPrompt === "Create") {
        //   toast.success('Template has been created', {
        //     autoClose: 4000,
        //   });
        // } else {
        //   toast.success('Template has been updated', {
        //     autoClose: 4000,
        //   });
        // }
      } else {
        dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_FAILURE, error: 'error' });
        setIsLoading(false);
      }
    });
  };

  const toggleConfirm = () => {
    setModalDelete(!modalDelete)
  }

  const hanldeActionsDelete = () => {
    const params = {
      "Action": "Delete",
      "Id": selectedTemplate.Id
    }
    const isLoading = true;
    setIsLoading(true);
    //Call Api
    dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_REQUEST, isLoading });
    apiCaller(`/api/teach/prompt/template/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_SUCCESS, payload: data });
        setIsLoading(false);
        handleCancleModal()
        getListPromptTemplate()
        toggleConfirm()
        // toast.success('Template has been deleted', {
        //   autoClose: 4000,
        // });
      } else {
        dispatch({ type: storyboardConstants.UPDATE_PROMPT_TEMPLATE_FAILURE, error: 'error' });
        setIsLoading(false);
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: "900px", margin: "4rem auto" }}
      backdrop={`static`}
    >
      <ModalHeader toggle={handleCancleModal}>
        Prompt Template
      </ModalHeader>
      <ModalBody>
        <form>
          <Row>
            <FormGroup className="col-12">
              <div className="row justify-content-between">
                <div className="col-6">
                <Label className="font-weight-500  mb-0" htmlFor="template">
                  Select Template
                </Label>
                <Select
                  options={dataPromptTemplate.data || []}
                  value={valueTemplate || []}
                  onChange={(optionValue) => handleChangeOptions(optionValue)}
                  placeholder="Select a template"
                  name="template"
                />
                </div>
                <div className="col-3 mt-4 ">
                  <Label className="font-weight-500 mb-0" htmlFor="">
                    {" "}
                  </Label>
                  <Button color="primary" className="p-2 w-100" onClick={() => changeActionPrompt("Create")}>
                    New Template
                  </Button>
                  <Button color="primary" className="p-2 w-100 mt-2">
                    Duplicate
                  </Button>
                </div>
              </div>
            </FormGroup>
          </Row>
          {actionPrompt !== null && (
            <>
              <Row>
                <FormGroup className="col-6">
                  <Label className="font-weight-500 mb-0" for="name">
                    Template Name
                  </Label>
                  <Input
                    type="text"
                    name="Name"
                    {...register("Name", { required: true })}
                    onChange={(e) => {
                      setValue("Name", e.target.value, { shouldValidate: true });
                      setValueName(e.target.value)
                    }}
                    value={valueName}
                    autoComplete="off"
                    placeholder="Name"
                  />
                  {(valueName === "" && errors?.Name?.type === "required") && (
                    <div className="invalid-feedback d-block">
                      This is required.
                    </div>
                  )}
                  <TemplateType
                    {...props}
                    handleChange={(e) => {
                      const { value } = e.target;
                      setDefaultActiveStatus(value === 'question_feedback_fixed' ? true : false);
                      setValue("Type", value);
                    }}
                    stateDefaultActiveStatus={stateDefaultActiveStatus}
                  />
                </FormGroup>
              </Row>
              <TextareaAutosizeInput
                errors={errors}
                register={register}
                handleChange={handleChange}
                nameState={promptText}
              />
            </>
          )}
        </form>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <div>
          <Button color="danger" onClick={toggleConfirm} style={{display: `${actionPrompt !== "Update" ? "none" : ""}`}} disabled={actionPrompt !== "Update"}>
            Delete
          </Button>{" "}
        </div>
        <div className="d-flex">
          <Button color="secondary" onClick={handleCancleModal}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            disabled={isLoading || actionPrompt === null}
            className="d-flex align-items-center ml-3"
            onClick={handleSubmit(hanldeActionsUpdate)}
          >
            {isLoading && (
              <span className="spinner-border spinner-border-sm mr-2"></span>
            )}
            {actionPrompt === "Update" ?  "Save & Apply To All" : "Create Template" }
          </Button>
        </div>
      </ModalFooter>
      <ModalDeleteCommon
        title={`Are you sure you want to delete this template?`}
        sub={`This is not reversible. The template will be removed from all questions.`}
        isOpen={modalDelete}
        toggle={toggleConfirm}
        handleRemove={hanldeActionsDelete}
        isLoading={isLoading}
      />
    </Modal>
  );
}

export default ModalTemplateEditor;
