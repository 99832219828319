import React from 'react';

// Avatar
import NotAvatar from "@images/common/no-avatar.jpg";

// redux
import { useSelector } from "react-redux";

function AvatarProgressBar({ profile_photo_url, sizeSm }) {
    // portfolio
    const portfolioData = useSelector(state => state.portfolio || {});
    const AccountDetails = portfolioData.data?.Account;

    const XPData = AccountDetails?.xp_data;
    const CurrentLevel = XPData?.UserLevel?.CurrentLevel || 0;

    const percentage = CurrentLevel;
    const radius = sizeSm ? 32 : 75;
    const strokeWidth = sizeSm ? 6 : 10;
    const viewBoxSize = 2 * (radius + strokeWidth);
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    return (
        <div className={`indicator-progress-bar ${sizeSm ? "size-xs" : ""}`}>
            <div className="avatar" style={{
                backgroundImage: `url(${profile_photo_url || NotAvatar})`,
                top: `${strokeWidth}px`,
                left: `${strokeWidth}px`,
                width: `${2 * radius}px`,
                height: `${2 * radius}px`
            }}></div>
            <svg className="progressBar" width={viewBoxSize} height={viewBoxSize} viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}>
                <circle
                    className="background"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth={strokeWidth}
                    stroke="#fff"
                />
                <circle
                    className="progressColor"
                    cx="50%"
                    cy="50%"
                    r={radius}
                    strokeWidth={strokeWidth}
                    stroke="#f1800f"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </svg>
            <div className="percentage-container">
                <div className="percentage" data-percentage={CurrentLevel}></div>
            </div>
        </div>
    );
}

export default AvatarProgressBar;