import React from "react";
import { toast } from "react-toastify";

const LockView = ({ hiddenToast }) => {
    const handleLockViewClick = () => {
        if (!hiddenToast) {
            toast.warn("Wait... The system hasn’t finished responding. Please hold on before proceeding to anything else!", {
                autoClose: 3000,
                toastId: 'LOCK_VIEW_CHAT'
            });
        }
    };

    return (
        <div className="lock-view" onClick={handleLockViewClick}></div>
    );
};

export default LockView;