import React from 'react';

//common
import { DataEmty } from "@components/common";

function FamilyHistoryList(props) {
    const { data } = props && props;
    const listItems = data?.map((value, index) => {
        return (
            <li key={index}>{value?.Text || ''}</li>
        );
    });
    return (
        <React.Fragment>
            <h2 className="font-weight-500 mb-3">FAMILY HISTORY</h2>
            {data?.length > 0 ? (
                <ul className="ml-4">
                    {listItems}
                </ul>
            ) : (
                <DataEmty content={`No Data`} />
            )}
        </React.Fragment>
    );
};

export default FamilyHistoryList;