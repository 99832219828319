import React, { useState, useEffect } from "react";

//reactstrap
import { Row, Col } from "reactstrap";

//react-bootstrap-table
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//components
import { ButtonCreateNewCase } from  "../Home/CreateNewCase";

//thumb
import thumbDefault from "@images/common/placeholder.png";

//redux
import { useSelector } from "react-redux";

//elements
import { ButtonEdit } from '@components-common/elements';

//common
import { DataEmty } from "@components-common";

//boostrap
import { Button } from "reactstrap";

//utils
import { LoaderText, messageData } from "@utils";

// Import the pagination configuration
import {paginationOptions} from "@utils";

function MyCase(props) {
  const { data } = props && props;

  //state
  const [listData, setListData] = useState((data && data) || []);

  useEffect(() => {
    setListData((data && data) || []);
  }, [data]);

  const columns = [
    {
      dataField: "Id",
      text: "Product ID",
      hidden: true,
    },
    {
      dataField: "Thumbnail",
      text: "",
      headerClasses: "--text-primary border-0 p-0",
      classes: "align-middle text-left font-weight-500",
      style: {
        width: "120px",
      },
      formatter: (cell, row) => {
        return (
          <img
            src={row.Thumbnail || thumbDefault}
            alt={row.Title}
            className="img-fluid ml-0"
          />
        );
      },
    },
    {
      dataField: "Title",
      text: "",
      headerClasses: "--text-primary border-0 p-0",
      classes: "align-middle text-left font-weight-500",
      formatter: (cell, row) => {
        return (
          <p className="text-3">
            {row.Title}
            <span className="text-muted ml-2 text-1">{`(#${row.Id})`}</span>
          </p>
        );
      },
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      headerClasses: "align-middle font-weight-normal border-0 p-0",
      classes: "align-middle text-right",
      style: {
        width: "100px",
      },
      formatter: (cell, row) => {
        return (
          <ButtonEdit linkRef={true} url={`/teach/case/${row.Id}`} />
        );
      },
    },
  ];

  const { SearchBar } = Search;
  const instructorData = useSelector((state) => state.instructor || []);
  const totalSize = listData?.length || 0; // Dynamically calculate the total size
  
  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="myCaseSection position-relative">
      {instructorData?.isLoading && <LoaderText />}
      <PaginationProvider
        pagination={paginationFactory(paginationConfig)}
      >
        {
          ({
            paginationProps,
            paginationTableProps
          }) => (
            <div>
              <ToolkitProvider
                keyField="Id"
                key={JSON.stringify(listData)}
                data={listData || []}
                columns={columns}
                search
              >
                {(toolkitprops) => {
                  return (
                    <React.Fragment>
                      <Row className="mb-4">
                        {listData?.length > 0 && (
                          <Col md={6}>
                            <div className="boxSearch">
                              <React.Fragment>
                                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                                    paginationProps.page = 1
                                    toolkitprops.searchProps.onSearch(e)
                                  }}
                                />
                                <Button
                                  className="btnSearch"
                                  color="primary"
                                >
                                  <i className="fa fa-search" aria-hidden="true"></i>
                                </Button>
                              </React.Fragment>
                            </div>
                          </Col>
                        )}
                        {/* Version Moi/Cu Xử lý trong component này */}
                        <ButtonCreateNewCase
                          listData={listData}
                        />
                      </Row>
                      <div className="pagination-custom-case d-flex justify-content-center mb-4">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                      <div className="panelCt__body p-0">
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          data={listData || []}
                          columns={columns}
                          bordered={false}
                          noDataIndication={() => (
                            <DataEmty content={listData?.length === 0 ? messageData?.casesEmty?.content : messageData?.filter} />
                          )}
                          {...toolkitprops.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </React.Fragment>
                  );
                }}
              </ToolkitProvider>
              <div className="pagination-custom-case d-flex justify-content-center mt-4">
                <PaginationListStandalone {...paginationProps} />
              </div>
            </div>
          )
        }
      </PaginationProvider>
    </div>
  );
}

export default MyCase;
