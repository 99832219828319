import React, { useEffect, useState } from "react";
import { ContentProcess } from '@components/common';
import { useParams } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

//router
import { useHistory } from "react-router-dom";

//lodash
import _ from "lodash";

function ConfirmPage() {
    let { slug } = useParams();
    let history = useHistory();
    let registerData = useSelector((state) => (state.register && state.register.data) || []);
    const [stateData, setData] = useState((registerData && registerData.MessageView) || {});
    const [isLoad, setIsLoad] = useState(true);
    
    useEffect(() => {
      const getData = async () => {
        setData((registerData && registerData.MessageView) || {});
        setIsLoad(false);
      };
      if(isLoad) {
        getData();
      }
    }, [isLoad, registerData]);

    //Check Reload
    useEffect(() => {
      if(_.isEmpty(registerData)) {
        history.push(`/login`);
      }
    }, [history, registerData]);
  
    const data = {
      title: stateData && stateData.Title,
      description: stateData && stateData.Message
    }

    return (
      <div className="wrapper -completedPage" >
        <ContentProcess data={data} icon={slug} backLogin={slug === 'email-existed' ? true : false} />
      </div>
    );
}

export default ConfirmPage;