import React, { useState, useEffect } from "react";
import thumbDefault from "@images/common/placeholder.png";

//reactstrap
import { Button, Row, Col } from "reactstrap";

//redux
import { useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";

//Loader
import { LoaderText, messageData } from "@utils";

//components
import { ModalUpdateTemplate } from "@components-common/Modal";
import { SearchTemplate } from ".";

//lodash
import isEqual from 'lodash/isEqual';

//Paginate
import { PaginatedItems } from '@components-common';

function MyTemplate(props) {
  const dataPropsAll = props && props.data;
  const templatesOptions = dataPropsAll && dataPropsAll.SystemTemplates;

  //state
  const [modalUpdate, setModalUpdate] = useState(false);
  const [itemEdit, setItemEdit] = useState({});
  const [actions, setActions] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearch, setChangeSearch] = useState(false);

  //Search With Name
  useEffect(() => {
    const data = dataPropsAll && dataPropsAll.SystemTemplates;
    const results =
      data &&
      data.filter(function (elem) {
        return elem.TemplateName.toLowerCase().includes(
          searchTerm.toLowerCase()
        );
      });
    setSearchResults(results || data);
  }, [searchTerm, dataPropsAll]);

  const toggleModalUpdate = (data, type) => {
    setModalUpdate(!modalUpdate);
    setActions(type);
    setItemEdit(data);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "search") {
      setSearchTerm(value);
      setChangeSearch(true);
    }
  };

  const resetFilter = () => {
    setSearchResults(templatesOptions);
    setSearchTerm("");
  };
  
  const handleItemsPerPage = (dataPerPage, type) => {
    //Neu filter thi khong chay
    if(!isEqual(templatesOptions, dataPerPage) && type === 'onLoad') {
      setSearchResults(dataPerPage);
    }
  }

  const templatesData = useSelector((state) => state.loadTemplates || {});
  return (
    <React.Fragment>
      <div className="position-relative">
        {templatesData.isLoading && <LoaderText />}
        <React.Fragment>
          <Row className="mb-4 align-items-center">
            {templatesOptions && templatesOptions.length > 0 &&
              <Col md={6}>
                <SearchTemplate
                  value={searchTerm}
                  templatesData={dataPropsAll}
                  handleChange={handleChange}
                  hiddenFilter={false}
                />
              </Col>
            }
            <Col
              md={templatesOptions && templatesOptions.length > 0 ? 6 : 12}
              className={`text-right ${templatesOptions && templatesOptions.length > 0 ? `mt-3 mt-md-0` : ``}`}
            >
              <Button
                color="primary"
                className="--btn-primary rounded-pill"
                onClick={(e) => toggleModalUpdate({}, "addTemplate")}
              >
                <i className="fa fa-plus mr-2" aria-hidden="true" />
                Create New Template
              </Button>
            </Col>
          </Row>
          <PaginatedItems
            itemsPerPage={5}
            items={isSearch ? searchResults : templatesOptions}
            handleItemsPerPage={handleItemsPerPage}
          >
            <ul className="ListSortableElement">
              {searchResults?.map((value, index) => {
                const genderName =
                  value && value.Gender && value.Gender.GenderName;
                const ethnicityName =
                  value &&
                  value.EthnicityGroup &&
                  value.EthnicityGroup.EthnicityGroupName;
                const ageName =
                  value && value.AgeGroup && value.AgeGroup.AgeGroupName;
                const thumbnail = value && value.Thumbnail;
                const templateName = value && value.TemplateName;
                return (
                  <li
                    className="ListSortableElement__item -box-shadow"
                    key={index}
                    style={{ cursor: "auto" }}
                  >
                    <div className="innerCt">
                      <div className="innerCt__left">
                        <img
                          src={thumbnail || thumbDefault}
                          alt={templateName || ""}
                        />
                      </div>
                      <div className="innerCt__right">
                        <div className="align-items-center row">
                          <div className="col-sm-12 col-md-9">
                            <h6 className="title">{templateName || ""}</h6>
                            <p className="time">
                              <span className="time__title --text-primary mr-2">
                                Age:
                              </span>
                              {ageName}
                              {` `}&#124;
                              <span className="time__title --text-primary mr-2">
                                {` `}Gender:
                              </span>
                              {genderName}
                              {` `}&#124;
                              <span className="time__title --text-primary mr-2">
                                {` `}Ethnicity:
                              </span>
                              {ethnicityName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </PaginatedItems>
          {/* Emty Data - Onload Data */}
          {templatesOptions && templatesOptions.length === 0 && (
            <DataEmty content={messageData?.templatesEmty?.content} />
          )}

          {/* On Search */}
          {searchResults && searchResults.length === 0 && isSearch && (
            <DataEmty content={messageData?.filter} />
          )}
        </React.Fragment>
      </div>
      {/* Modal */}
      <ModalUpdateTemplate
        toggle={toggleModalUpdate}
        isOpen={modalUpdate}
        templatesOptions={dataPropsAll}
        actions={actions}
        data={itemEdit}
        resetFilter={resetFilter}
      />
    </React.Fragment>
  );
}

export default MyTemplate;
