import React from 'react';

//reactstrap
import { Button } from "reactstrap";

function BtnExportCSV({ handleExportCsv, text, loadingDowload }) {
    return (
        <Button
            className="--btn-primary ml-auto"
            onClick={handleExportCsv}
        >
            {loadingDowload ? (
                <span className="spinner-border spinner-border-sm"></span>
            ) : (
                <i className={`fa fa-download ${text ? "mr-2" : ""}`} aria-hidden="true"></i>
            )}
            {text && text}
        </Button>
    );
};

export default BtnExportCSV;