import React from 'react';

//truncate
import { truncate } from "@utils";

//reactstrap
import { Badge, UncontrolledTooltip } from "reactstrap";

//lodash
import _ from 'lodash';

function BadgeScoreLabs(props) {
    const { itemRow, listDomain, toggleScore, timepointData } = props && props;
    const timepointCurrent = timepointData?.data;
    const timeOfTimePoint = timepointCurrent?.Time;
    return (
        <React.Fragment>
            {listDomain?.map((value, index) => {
                const numberScore = parseFloat(value?.Score);
                const itemScore = value;
                const timingCondition = value.TimingCondition;
                const timingValue = value.TimingValue;
                const conditionNotValue = (_.isUndefined(timingValue) && _.isUndefined(timingCondition));
                const conditionMatchValue = (timingCondition === 'At' && (timingValue === timeOfTimePoint));
                return (
                    <React.Fragment key={index}>
                        {(conditionNotValue || conditionMatchValue) &&
                            <React.Fragment>
                                <Badge
                                    style={{ cursor: "pointer" }}
                                    className="p-1 mb-1"
                                    color={numberScore >= 0 ? "success" : "danger"}
                                    key={Math.random()}
                                    id={`tooltipIntervention_${value?.DomainId}`}
                                    onClick={() =>
                                        toggleScore(itemRow, "edit-score", "Lab", itemScore, "")
                                    }
                                >
                                    <span className="font-weight-500">{truncate(value?.DomainName, 12)}</span>&nbsp;
                                    <strong>({numberScore})</strong>{` `}
                                    {typeof timingValue != "undefined" &&
                                        typeof timingCondition != "undefined" &&
                                        `(*)`}
                                </Badge>
                                <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltipIntervention_${value?.DomainId}`}
                                >
                                    {value?.DomainName}
                                </UncontrolledTooltip>
                            </React.Fragment>
                        }
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default BadgeScoreLabs;