import React, { useState, useEffect } from "react";

//reactstrap
import { Input, FormGroup } from "reactstrap";

function AnswerItem(props) {
  const { AnswersGroupChild, indexParent, handleChangeInput } = props && props;

  //state
  const [stateData, setData] = useState(AnswersGroupChild || []);

  //load
  useEffect(() => {
    setData(AnswersGroupChild || []);
  }, [AnswersGroupChild]);

  return (
    <React.Fragment key={indexParent}>
      {stateData?.map((value, index) => {
        return (
          <FormGroup
            key={index}
            style={{ height: "35px" }}
            className="d-flex align-items-center justify-content-center my-2"
          >
            <Input
              className="m-0"
              id={`isCorrect_${indexParent}_${index}`}
              name={`isCorrect`}
              type="checkbox"
              checked={value?.isCorrect}
              data-index-parent={indexParent}
              data-index-child={index}
              onChange={handleChangeInput}
            />
          </FormGroup>
        );
      })}
    </React.Fragment>
  );
}

export default AnswerItem;
