import React, { useState, useEffect, useRef } from "react";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//react-transition-group
import { Transition } from 'react-transition-group';

//redux
import { useDispatch } from "react-redux";

//call Action
import { updateInstructorNote } from "@actions";

function AddNote(props) {
    const dispatch = useDispatch();

    //props
    const { item } = props && props;
    const InstructorNotes = item?.InstructorNotes;

    //state
    const [isClick, setClick] = useState(false);
    const [stateInstructorNotes, setInstructorNotes] = useState((InstructorNotes && InstructorNotes) || '');

    //Click Outside
    const itemEl = useRef();
    const onClickOutSide = (itemEl, (e) => {
        const targetEl = e.target;
        // Check if user is clicking outside of div
        if (itemEl.current && !itemEl.current.contains(targetEl)) {
            const caseId = itemEl.current.getAttribute("data-id");
            setClick(false);

            //Call api
            const params = {
                "Action": "Update",
                "InstructorNotes": stateInstructorNotes
            }
            dispatch(updateInstructorNote(caseId, params));
        }
    });

    //set load data
    useEffect(() => {
        setInstructorNotes((InstructorNotes && InstructorNotes) || '');
    }, [InstructorNotes]);

    //Check Click Outside
    useEffect(() => {
        // Handle outside clicks on mounted state
        if (isClick) {
            document.addEventListener("mousedown", onClickOutSide);
        }

        // This is a necessary step to "dismount" unnecessary events when we destroy the component
        return () => {
            document.removeEventListener("mousedown", onClickOutSide);
        };
    });

    //Handle Button Add
    const handleClick = () => {
        setClick(!isClick);
    }

    //Handle Textarea
    const handeChange = (e) => {
        const { value } = e.target;
        setInstructorNotes(value);
    }

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    return (
        <div className="p-3 w-100" ref={itemEl} id={`itemNote_${item?.Id}`} data-id={item?.Id}>
            {!isClick && !stateInstructorNotes &&
                <span
                    style={{ cursor: 'pointer' }}
                    className="--text-primary font-weight-500"
                    onClick={handleClick}
                >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Note
                </span>
            }
            {isClick &&
                <Transition
                    transitionname="effectGroup"
                    timeout={500}
                >
                    <TextareaAutosize
                        name="InstructorNotes"
                        placeholder=""
                        className="form-control"
                        maxRows="3"
                        minRows={5}
                        value={stateInstructorNotes || ''}
                        onChange={handeChange}
                        autoFocus
                    />
                </Transition>
            }
            {stateInstructorNotes && !isClick &&
                <div onClick={handleClick} className="d-flex" style={{ cursor: 'text' }}>
                    <p>{truncate(stateInstructorNotes, 100)}</p>
                </div>
            }
        </div>
    );
};

export default AddNote;