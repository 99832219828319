import React, { useState, useEffect } from "react";

//boostrap
import { Container, Row, Col } from "reactstrap";

//components
import { CheckBoxFilter, TagFilterSelect, CategoryFilterSelect, ModalAddTag } from "../../ItemReview/Filter";

//api
import { formatReactSelect } from "@utils";

//common
import { ModalDeleteCommon } from "@ui-partents/Modal";

//constants
import { validateConstant } from "@constants";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";

function FitterTop(props) {
    //props
    const { handleSubmitFilter, isLoading, tagsData, handleUpdateNewData, categoryOptions, handleTagsForValidationByCategory, typeReview } = props && props;
    const dispatch = useDispatch();

    //state
    const [loadingModal, setLoadingModal] = useState({
        deleteTag: false
    });
    const [selectedOption, setSelectedOption] = useState({
        category: [],
        tag_id: {
            label: '',
            value: ''
        }
    });
    const [modal, setModal] = useState({
        addTag: false,
        deleteTag: false,
    });
    const [dataCurrent, setDataCurrent] = useState({});
    const [filterData, setFilterData] = useState({
        "tag_id": "",
        "category": "",
        "only_no_review": false,
    });

    const [dataSelect, setDataSelect] = useState({
        category: [],
        tag_id: []
    });


    //Fetch All Category
    useEffect(() => {
        const dropdownSelect = categoryOptions?.length > 0 ? formatReactSelect(categoryOptions, 'code', 'name') : [];
        setDataSelect(prevData => ({
            ...prevData,
            category: dropdownSelect
        }));
    }, [categoryOptions])

    //Fetch All Tag for Validation
    useEffect(() => {
        const dropdownSelect = tagsData?.length > 0 ? formatReactSelect(tagsData || [], 'tag_id', 'name') : [];
        setDataSelect(prevData => ({
            ...prevData,
            tag_id: dropdownSelect
        }));
    }, [tagsData])

    //Handle Change Dropdown
    const handleChangeOptions = (data, name) => {
        setSelectedOption(prevData => ({
            ...prevData,
            [name]: data
        }));
    
        // Sử dụng hàm callback để đảm bảo setFilterData đã cập nhật giá trị mới nhất
        setFilterData(prevFilterData => {
            const updatedFilterData = { ...prevFilterData, [name]: data?.value };
    
            // Sau khi cập nhật setFilterData, gọi handleSubmitFilter
            handleSubmitFilter(updatedFilterData);
    
            return updatedFilterData; // Trả về giá trị mới cho setFilterData
        });
    
        // Neu chon category thi call api tags
        if (name === 'category' && data) {
            handleResetDataDropdown();
            handleTagsForValidationByCategory(data);
        }
    };    

    // Reset Dropdown When Category Change
    const handleResetDataDropdown = () => {
        // reset Tag
        setSelectedOption(prevData => ({
            ...prevData,
            tag_id: { label: '', value: '' }
        }));
    }

    // Handle Change Checkbox
    const handleCheckBox = (e) => {
        const { name, checked } = e.target;
    
        // Sử dụng hàm callback để đảm bảo setFilterData đã cập nhật giá trị mới nhất
        setFilterData(prevFilterData => {
            const updatedFilterData = { ...prevFilterData, [name]: checked };
    
            // Sau khi cập nhật setFilterData, gọi handleSubmitFilter
            handleSubmitFilter(updatedFilterData);
    
            return updatedFilterData; // Trả về giá trị mới cho setFilterData
        });
    }    

    //Hanldle Add Tag
    const toggle = (type, value) => {
        setModal((prevState) => ({
            ...prevState,
            [type]: !prevState[type]
        }));
        setDataCurrent(value);
    };

    // Handle Remove Tag
    const handleRemoveTag = () => {
        const params = {
            "action_type": "delete_tag_item",
            "tag_id": dataCurrent?.value,
            "review_type": typeReview?.review_type
        }
        setLoadingModal((prevState) => ({ ...prevState, deleteTag: true }));
        //Call Api
        dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_REQUEST, isLoading });
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                handleUpdateNewData(data);
                dispatch({
                    type: validateConstant.GET_TAGS_FOR_VALIDATION_SUCCESS,
                    payload: data
                });
                setLoadingModal((prevState) => ({ ...prevState, deleteTag: false }));
                toggle('deleteTag', dataCurrent);
                setSelectedOption(prevData => ({
                    ...prevData,
                    tag_id: {
                        label: '',
                        value: ''
                    }
                }));
            } else {
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_FAILURE, error: 'error' });
                setLoadingModal((prevState) => ({ ...prevState, deleteTag: false }));
                toggle('deleteTag', dataCurrent);
            }
        });
    }

    return (
        <Container className="border p-4 rounded mb-3">
            <Row className="align-items-center">
                <Col md={`3`}>
                    <CategoryFilterSelect
                        {...props}
                        handleChangeOptions={handleChangeOptions}
                        dataSelect={dataSelect}
                        selectedOption={selectedOption}
                        isLoading={isLoading}
                    />
                </Col>
                <Col md={`3`}>
                    <TagFilterSelect
                        {...props}
                        handleChangeOptions={handleChangeOptions}
                        dataSelect={dataSelect}
                        selectedOption={selectedOption}
                        toggle={toggle}
                        disabled={filterData?.category !== "" ? false : true} //disable khi chua chon category
                        typeReview={typeReview}
                        isLoading={isLoading}
                    />
                </Col>
                <Col md={`3`}>
                    <CheckBoxFilter
                        {...props}
                        handleCheckBox={handleCheckBox}
                        filterData={filterData}
                    />
                </Col>
            </Row>
            {filterData?.category === "" && (
                <Row>
                    <Col md={12}><p className="--text-primary text-1">(*) Select a Category to start reviewing data</p></Col>
                </Row>
            )}
            <ModalAddTag
                {...props}
                isOpen={modal?.addTag}
                toggle={() => toggle('addTag', dataCurrent)}
                filterData={filterData}
            />
            <ModalDeleteCommon
                title={`Are you sure you want to delete this record ?`}
                sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
                isOpen={modal?.deleteTag}
                toggle={() => toggle('deleteTag', dataCurrent)}
                handleRemove={handleRemoveTag}
                isLoading={loadingModal?.deleteTag}
            />
        </Container>
    );
}

export default FitterTop;