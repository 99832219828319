import React, { useEffect } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";

//call Action
import {
  getBillingReport
} from "@actions";

//components
import {
  BillingTab,
  BillingContent,
  PaymentContent
} from "../Administrator";

function Billing (props) {
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      dispatch(getBillingReport());
    }
    fetchData();
  }, [dispatch]);

  //get data store
  const billingReportData = useSelector(
    (state) => (state.billingReport && state.billingReport.data) || []
  );
  
  const navListGroup = [
    {
      Name: "Plan Overview",
      Component: <BillingContent {...props} data={(billingReportData && billingReportData) || {}} />,
    },
    {
      Name: "Payment Method",
      Component: <PaymentContent {...props} data={(billingReportData && billingReportData) || {}} />,
    },
    {
      Name: "INVOICES",
      Component: <DataEmty className="my-5" content="Current and past invoices for your account will be listed here in the future" />,
    }
  ];

  return (
    <BillingTab data={navListGroup} />
  );
}

export default Billing;