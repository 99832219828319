import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

function QuestionPromptTemplate(props) {
  const { handleChangePromptTemplate, timepointData, questionData } = props;

  // State to store the list of prompt templates
  const [promptTemplates, setPromptTemplates] = useState([]);

  // Effect hook to update the list of prompt templates when `AvailablePromptTemplates` changes
  useEffect(() => {
    if (timepointData?.AvailablePromptTemplates) {
      // Format prompt templates data for react-select
      const formattedTemplates = timepointData.AvailablePromptTemplates.map((template) => ({
        value: template.Id,
        label: template.Name
      }));
      setPromptTemplates(formattedTemplates);
    }
  }, [timepointData]);

  // Compute the selected prompts based on questionData
  const selectedPrompt = useMemo(() => {
    if (questionData?.PromptTemplateIds) {
      return questionData.PromptTemplateIds.map((id) => {
        const template = promptTemplates.find((template) => template.value === id);
        return template ? { value: template.value, label: template.label } : null;
      }).filter((item) => item !== null);
    }
    return [];
  }, [questionData, promptTemplates]);

  // Handle changes in prompt templates selection
  const handleChangeOptions = (data) => {
    handleChangePromptTemplate(data, "PromptTemplateIds");
  };

  return (
    <FormGroup>
      <Label className="labeForm" for="promptTemplateList">
        Apply Prompt Template:
      </Label>
      <Select
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={selectedPrompt}
        onChange={handleChangeOptions}
        options={promptTemplates}
        placeholder={`Select an item below`}
        className="w-100"
        name="PromptTemplateIds"
      />
    </FormGroup>
  );
}

export default QuestionPromptTemplate;