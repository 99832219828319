import React from "react";

const AccordionItem = ({
    showDescription,
    ariaExpanded,
    item,
    indexItem,
    onClick,
    parent
}) => (
    <div className="Acc__item" data-parent={parent} key={indexItem}>
        <dt>
            <button
                aria-expanded={ariaExpanded}
                aria-controls={`faq${indexItem + 1}_desc`}
                data-qa={`faq${indexItem + 1}_desc`}
                className={`Acc__button`}
                onClick={() => onClick(`${parent}_${indexItem}`)}
            >
                {item.Name}
                {item.Status === "Done" &&
                    <span className="icon-checkmark-switcher text-success ml-auto"></span>
                }
            </button>
        </dt>
        <dd>
            <p
                id={`faq${indexItem + 1}_desc`}
                data-qa="Acc__desc"
                className={`Acc__desc ${showDescription}`}
                dangerouslySetInnerHTML={{
                    __html: item.Contents
                }}
            />
        </dd>
    </div>
);

export default AccordionItem;