import React from 'react';

//components
import { ButtonExitCase, ButtonResetCase } from "./ResetAndBack";

function ResetAndBackCase(props) {
    const {
        exitCaseUrl,
        buttonReset
    } = props && props;
    return (
        <div className="ml-4">
            {buttonReset && <ButtonResetCase {...props} />}
            <ButtonExitCase {...props} data={exitCaseUrl} />
        </div>
    );
};

export default ResetAndBackCase;