import React from "react";

// Components
import { CommonCollapse } from "../../ComponentCommon"
import { BtnAddRow } from "@ui-partents/Button";
import SettingItem from './SettingItem';

const SettingList = (props) => {
  //state
  // const [modalAddOpen, setModalAddOpen] = useState(false);

  // const toggleModal = () => {
  //   setModalAddOpen(prev => !prev);
  // };

  return (
    <div className="setting-list d-flex flex-column mb-4">
      <CommonCollapse title="Demo 999888">
        <SettingItem />
      </CommonCollapse>
      <BtnAddRow
        // handleClickAddRow={toggleModal}
        textBtn="Add Intervention"
      />
    </div>
  );
};

export default SettingList;
