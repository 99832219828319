import React from "react";
import { Iconsax } from "@components-common";

function BtnIconAddV2({ colorIcon, handleEditButtonClick }) {
  return (
    <div className="action-btn-table" onClick={handleEditButtonClick}>
      <Iconsax iconName="plus-v2" fill={colorIcon || "#F1800F"} size={24} />
    </div>
  );
}

export default BtnIconAddV2;