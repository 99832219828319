import React from "react";
import { Alert } from "reactstrap";

function ActionsCategory(props) {
    // Props
    const { data, handleSubMenu, stateCategory, heightPatientMedRecord } = props || {};

    // Check if data is truthy and an array
    if (data && Array.isArray(data)) {
        return (
            <React.Fragment>
                <Alert
                    className="m-alert--default text-1"
                    color="secondary"
                >
                    Click on each of the following categories to see available actions
                </Alert>
                <ul className="actions-category" style={{ height: `${heightPatientMedRecord - 92}px`}}>
                    {data.map((value, index) => (
                        <li
                            key={index}
                            onClick={() => handleSubMenu(value)}
                            className={`actions-category__item ${stateCategory === value?.CategoryName ? 'active' : 'not-active'}`}
                        >
                            <span className="actions-category__item--text">{value?.CategoryName}</span>
                            <span className="actions-category__item--icon icon-arrow-right"></span>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        );
    }

    // Return null if data is not an array or is falsy
    return null;
}

export default ActionsCategory;