// ChatContents.js
import React, { useEffect } from 'react';

//actions
import { atcLoadChat } from "@actions";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//components
import { IntakeComponent, QuestionTypeComponent, MaxQuestionInput } from "../../ManageAvailableTasks/InteractiveChat";

const ChatContents = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { timepointData } = props && props;

  //Load chat topic
  useEffect(() => {
    const params = {
      Action: "Load",
      Target: "Chat",
      TimePointId: timepointData?.Id,
    };
    dispatch(atcLoadChat(id, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <React.Fragment>
      <QuestionTypeComponent {...props} />
      <MaxQuestionInput {...props} />
      <IntakeComponent {...props} />
    </React.Fragment>
  );
};

export default ChatContents;