import React from 'react';

//reactstrap
import { Progress, Row, Col, Button } from "reactstrap";

function ProgressApi(props) {
    const { total, current, progressBar, handleSubmit, isFail, isLoad } = props && props;
    return (
        <Row className="d-flex justify-content-center">
            <Col md={6}>
                <div className="p-5 text-center">
                    <Progress
                        striped
                        animated
                        value={progressBar}
                    />
                    <p className="font-weight-400 text-3 mt-3 text-center">
                        <span className="font-weight-500 text-success mr-2">{current}</span> done out of
                        <span className="--text-primary font-weight-500 ml-2">{total}</span>
                    </p>
                    {isFail > 0 &&
                        <p className='font-weight-400 text-3 mt-3 text-center text-danger'>Error: <span className='ml-2 font-weight-500'>{isFail}</span></p>
                    }
                    {isLoad && (
                        <Button className="btn --btn-primary my-3 d-flex align-items-center mx-auto" onClick={handleSubmit}>
                            <i className="fa fa-angle-left mr-2" aria-hidden="true"></i> Back
                        </Button>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default ProgressApi;