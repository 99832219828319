import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

//utils
import { checkAuth } from "@utils";

const UserContext = createContext();
export const UserProvider = ({ children }) => {
    const [privilege, setPrivilege] = useState("");

    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data?.Account || {});

    const setAccountTesterPrivilege = () => {
        if (AccountInfoData) {
            setPrivilege(AccountInfoData?.tester_privilege);
        } else {
            console.warn("No account info data available");
        }
    };

    useEffect(() => {
        if (checkAuth()) {
            setAccountTesterPrivilege();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AccountInfoData]);

    return (
        <UserContext.Provider value={{ privilege }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;