import React, { useState, useEffect, useRef } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

//Image Default
import placehoder from "@images/common/no-avatar.jpg";

//components
import { BtnDeleteRole, AvatarRole, InputEdit } from '../AddRemoveTable';

function TableRows({ rowsData, hanldeModal, handleUpdate, disabled, action }) {
    const newArr = rowsData?.map(el => ({ ...el, Avatar: placehoder }));
    const [itemSort, setItemSort] = useState((newArr && newArr) || []);
    const [isEdit, setEdit] = useState();

    //Click outSide
    const ref = useRef();
    useOnClickOutside(ref, () => {
        const removeIdx = ref.current.getAttribute("data-index");
        setEdit({ [removeIdx]: false });
        handleUpdate(itemSort, removeIdx);
    });

    //Update Array Store
    useEffect(() => {
        const newArr = rowsData?.map(el => ({ ...el, Avatar: placehoder }));
        setItemSort((newArr && newArr) || []);
    }, [rowsData]);

    //Handle Enter
    const handelOnkeyUp = (e, data, index) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            handleUpdate(data, index);
            setEdit({ [index]: false })
        }
    }

    //Handle Edit
    const handleEdit = (index) => {
        setEdit({ [index]: true })
    }

    //Handle Change
    const handleChange = (evnt, index) => {
        const { name, value } = evnt.target;
        let arrCopy = [...itemSort];
        arrCopy[index][name] = value;
    }

    // Sort Item
    const SortableItem = SortableElement(({ indexItem, value }) => {
        const { TeamRoleId, TeamRoleName, Avatar } = value;
        return (
            <div className="divTableRow SortableDivRow" key={TeamRoleId} style={{ position: "relative" }}>
                <div className={`divTableCell SortableDivCell border-bottom ${indexItem === 0 ? 'border-top' : ''}`} style={{ width: '50px' }}>
                    <i className="fa fa-bars iconDrag" aria-hidden="true"></i>
                    <AvatarRole
                        value={Avatar}
                    />
                </div>
                <div className={`divTableCell SortableDivCell border-bottom ${indexItem === 0 ? 'border-top' : ''}`}>
                    {isEdit && isEdit[indexItem] ? (
                        <div ref={ref} data-index={indexItem}>
                            <InputEdit
                                handleChange={handleChange}
                                disabled={(action === 'Delete' || action === 'Create') ? disabled : false}
                                value={value}
                                indexItem={indexItem}
                                handelOnkeyUp={(e) => handelOnkeyUp(e, itemSort, indexItem)}
                            />
                        </div>
                    ) : <div onClick={() => handleEdit(indexItem)}>{TeamRoleName}</div>}
                </div>
                <div className={`divTableCell SortableDivCell border-bottom ${indexItem === 0 ? 'border-top' : ''}`} style={{ width: '50px' }}>
                    <BtnDeleteRole
                        disabled={disabled}
                        hanldeModal={hanldeModal}
                        action={action}
                        data={value}
                    />
                </div>
            </div>
        );
    });

    // Sort List
    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className="divTable">
                {items?.map((value, index) => (
                    <SortableItem
                        key={`item-${value?.TeamRoleId}`}
                        index={index}
                        value={value}
                        indexItem={index}
                        style={{ zIndex: 100000 }}
                    />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setItemSort(arrayMove(itemSort, oldIndex, newIndex));
    };

    return (
        <SortableList
            items={itemSort}
            onSortEnd={onSortEnd}
            lockAxis="y"
            helperClass="SortableDiv"
            distance={1}
        />
    )
}

export default TableRows;

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        }, [ref, handler]
    );
}