import React from "react";

//boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//components
import { TabContainer } from '../References';

const ModalReferences = (props) => {
  const { caseData, isOpen, toggle } = props && props;
  const dataReferences = caseData?.References || {};
  return (
    <Modal 
      style={{ maxWidth: "70%" }}
      isOpen={isOpen}
      toggle={toggle}
      zIndex={17000}
      centered
    >
      <ModalHeader toggle={toggle}>References</ModalHeader>
      <ModalBody>
        <TabContainer data={dataReferences} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalReferences;