import React from "react";

//Boostrap
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

//components
import { TableData } from "../AnalyzeReviewsMain";

function ModalDetails(props) {
    const { isOpen, toggle, stateData } = props && props;
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{ maxWidth: '1200px' }}>
            <ModalHeader toggle={toggle}>
                <span className="--text-primary">View Details</span>
            </ModalHeader>
            <ModalBody className="p-3">
                <TableData
                    {...props}
                    analyzeReviews={stateData?.analyzeReviews || {}}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalDetails;
