// ModalMessage.js
import React from "react";

// Modal Message
import { ModalMessageAuth } from "@components/auth/ModalMessage";

const ModalMessageEmail = (props) => {
    return (
        <ModalMessageAuth {...props} />
    );
};

export default ModalMessageEmail;