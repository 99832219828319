import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoaderPage from '@utils/LoaderPage';
import { checkAuth } from '@utils';

const HomeRedirectView = () => {
    const history = useHistory();

    useEffect(() => {
        const isAuthenticated = checkAuth();
        const accountData = JSON.parse(localStorage.getItem("Account"));

        const redirect = () => {
            if (!isAuthenticated) {
                history.push("/login");
                return;
            }

            if (accountData) {
                const { is_instructor: isInstructor } = accountData;
                if (isInstructor === 'Yes') {
                    history.push("/teach/home");
                } else {
                    history.push("/learn/home");
                }
            } else {
                // Nếu không có dữ liệu tài khoản, chuyển hướng đến trang đăng nhập
                history.push("/login");
            }
        };

        setTimeout(redirect, 2000); // Chờ 2 giây trước khi chuyển hướng
    }, [history]);

    return <LoaderPage />;
};

export default HomeRedirectView;