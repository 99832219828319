import React, { useState } from "react";

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//components
import { NavTab } from '../../storyboard/Documentation/TabsGroup';

function TabWrap(props) {
    const { data } = props && props;

    //state
    const [activeTab, setActiveTab] = useState("1");

    //toggle
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="tabsFullBg m-2">
            <NavTab
                data={data || []}
                activeTab={activeTab}
                toggle={toggle}
            />
            <TabContent activeTab={activeTab}>
                {data?.map((value, index) => (
                    <TabPane className="p-0" key={index} tabId={(index + 1).toString()}>
                        {value.Component}
                    </TabPane>
                ))}
            </TabContent>
        </div>
    );
}

export default TabWrap;