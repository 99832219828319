import React from 'react';
import Image from '@images/learnerv2/pic-message-answer.png';

function MessageImage() {
    return (
        <div className="message-answer__img">
            <img src={Image} alt="Message Answer" />
        </div>
    );
}

export default MessageImage;