import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import styled from 'styled-components';
import { Waveform } from './testAudio';

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 26px;
  top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  &:hover svg {
    stroke: #17475C;
  }
`;

const WrapMedia = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .media-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  audio {
    width: 100%;
    transition: none;
    box-shadow: none;
    border-radius: 0;
    &:hover {
      box-shadow: none;
      transform: none;
    }
  }
  video {
    width: 100%;
    height: 100%;
  }
`

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M18 6L6 18M6 6L18 18" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default function ModalWatchMedia(props) {
  const { isOpen, toggleModal, dataView } = props

  const showMedia = (type) => {
    switch (type) {
      case "Image":
        return (
          <img className='media-img' src={dataView.url} alt={dataView.title}/>
        )
      case "Video":
        return (
          <video controls>
            {/* <source src={dataView?.url} type="video/mp4" /> */}
            <source src={dataView?.url} />
            Your browser does not support the video tag.
          </video>
        )
      case "Audio":
        return (
          <Waveform {...props}/>
        )
      default:
        break;

    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      style={{ maxWidth: '800px' }}
      centered
      className={`modal-custom`}
    >
      <ModalHeader>
        <CloseButton onClick={toggleModal}>
          <CloseIcon />
        </CloseButton>
      </ModalHeader>
      <ModalBody>
        <WrapMedia>
          {showMedia(dataView?.type)}
        </WrapMedia>
      </ModalBody>
    </Modal>
  )
}
