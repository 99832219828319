import React from 'react';

function NegationUpdate(props) {
    const { item, handleUpdateNegation } = props && props;
    const negation = item?.negation;
    
    return (
        <React.Fragment>
            {negation ? (
                <span style={{ cursor: 'pointer' }} onClick={() => handleUpdateNegation(negation)} className="badge-outlined badge-outlined-danger px-2">NEG</span>
            ) : (
                <span style={{ cursor: 'pointer' }} onClick={() => handleUpdateNegation(negation)} className="badge-outlined badge-outlined-primary px-2">POS</span>
            )}
        </React.Fragment>
    );
};

export default NegationUpdate;