import React from 'react'
import { Input } from 'reactstrap';
import TitleNoteCommon from './TitleNoteCommon';

const InputComponent = (props) => {
  const { setValue, title, textPlaceholder } = props
  return (
    <div className='inputComponent first-step'>
      <div className='inputComponent__header w-100'>
        <TitleNoteCommon 
          dataFor='instruction' 
          required={true} 
          title={title}
          content={'In the text box below, describe the case scenario you want to create. Your description should include: the main topic of the case, the target audience (e.g., medical students, residents), the key learning objective, and any specific reference materials or guidelines to be used.'}
          width={360}
          {...props}
        />
      </div>
      <div className='inputComponent__input w-100 second-step'>
        <Input
          type='textarea'
          name='prompt'
          placeholder={textPlaceholder}
          style={{ height: '200px' }}
          onChange={(e) => {
            setValue("prompt", e.target.value);
          }}
        />
      </div>
    </div>
  )
}

export default InputComponent
