import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

//components
import { UserMessage, InstructorMessage, LoadingMessage } from '@components/learner/StandardScreen/Chat/Preceptor';

//images
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imageDefault from "@images/common/person.png";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//react-redux
import { useSelector } from "react-redux";

// Styled-component for message wrapper
const MessageWrapper = styled.div`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation-delay: ${({ delay }) => delay || 0}s;
`;

function ScrollableMessages(props) {
    // Use screen size context
    const { isMobile } = useScreenSize();

    // Props
    const { messagesEndRef, chatRecord, isLoading, heightFooterChat, profile_photo_url, nextQuestion } = props;

    // State
    const [calculatedHeight, setCalculatedHeight] = useState(heightFooterChat || 0);

    // Update height based on chat record
    useEffect(() => {
        setCalculatedHeight(heightFooterChat);
    }, [heightFooterChat]);

    const heightBottom = `calc(100vh - ${calculatedHeight}px)`;
    const containerRef = useRef(null); // Ref cho container cuộn
    const messagesTopDivRef = useRef(null);

    useEffect(() => {
        if (isMobile && messagesTopDivRef?.current) {
            // Nếu là mobile, cuộn đến vị trí offsetTop
            const offsetTop = messagesTopDivRef.current.offsetTop;
            const adjustment = 10; // Khoảng cách cần điều chỉnh (sửa giá trị theo độ lệch thực tế)

            containerRef.current.scrollTo({
                top: offsetTop - adjustment, // Trừ đi khoảng cách điều chỉnh
                behavior: 'smooth',
            });
        } else if (!isMobile && messagesTopDivRef?.current) {
            // Nếu không phải mobile, cuộn đến bottom (vị trí cuối cùng của container)
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight, // Cuộn đến cuối container
                behavior: 'smooth',
            });
        }
    }, [chatRecord, isMobile, heightBottom, nextQuestion]);  // Chạy lại khi chatRecord thay đổi hoặc heightBottom, nextQuestion thay đổi  

    // Redux Store
    const caseData = useSelector((state) => state.cases || []);
    const caseResponse = caseData?.data?.Case;

    // Characters - Instructor
    const Characters = caseResponse?.Characters;
    const Instructor = Characters?.Instructor;
    const preceptorNameText = Instructor?.DisplayName;
    const InstructorCharacter = Characters?.Instructor;
    const PreceptorAvatar = InstructorCharacter?.StandardSquarePhoto || imageInstructor;
    const userAvatar = profile_photo_url || imageDefault;

    const isloadingStream = isLoading?.streamData || isLoading?.submitQuestion;
    return (
        <div
            className="messengerCt scrollbarStyle"
            style={{ height: heightBottom }}
            ref={isloadingStream ? messagesEndRef : containerRef}
        >
            <div className="messengerCt__messages">
                {chatRecord?.map((value, index) => {
                    const isLastMessage = index === chatRecord.length - 1;
                    return (
                        <React.Fragment key={index}>
                            {/* Individual messages */}
                            {value?.Role === "Instructor" && (
                                <MessageWrapper
                                    delay={(index % 4) * 0.1}
                                    ref={isLastMessage ? messagesTopDivRef : null}
                                >
                                    <InstructorMessage
                                        {...props}
                                        index={index}
                                        value={value}
                                        PreceptorAvatar={PreceptorAvatar}
                                        preceptorNameText={preceptorNameText}
                                    />
                                </MessageWrapper>
                            )}

                            {value?.Role === "User" && (
                                <UserMessage {...props} value={value} userAvatar={userAvatar} />
                            )}

                            {/* Loading message */}
                            {chatRecord?.length - 1 === index &&
                                (isloadingStream &&
                                    <LoadingMessage
                                        {...props}
                                        PreceptorAvatar={PreceptorAvatar}
                                        preceptorNameText={preceptorNameText}
                                    />
                                )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}

export default ScrollableMessages;