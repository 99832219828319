import React, { useState } from 'react';

//reactstrap
import { Table, Collapse } from "reactstrap";

//components
import { RowAdd, ModalAddChildren, ModalEditChatTopic, ActionDelete } from '../Chat';

function ChatTopicChildren(props) {
    const { data, isOpen, handleUpdateSuccess } = props && props;

    //state
    const [modal, setModal] = useState({
        add: false,
        edit: false
    });
    const [itemEdit, setItem] = useState({});

    const toggle = (type, data) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
        setItem(data);
    }
    
    return (
        <tr>
            <td className="groupCollapse p-0" colSpan={12}>
                <Collapse isOpen={isOpen} >
                    <Table responsive className="mb-0 tableSub">
                        <thead className="table-row opacity-0">
                            <tr>
                                <th scope="col" className="align-middle p-0" width={`60%`}></th>
                                <th scope="col" className="align-middle p-0"></th>
                                <th scope="col" className="text-center align-middle p-0" width={`5%`}></th>
                            </tr>
                        </thead>
                        {data?.map((itemChild, index) => {
                            return (
                                <tbody key={index}>
                                    <tr key={index}>
                                        <td width="60%">
                                            <span className="pl-5">{itemChild?.Name}</span>
                                        </td>
                                        <td
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => toggle('edit', itemChild)}
                                        >
                                            {itemChild?.StandardResponse || ''}
                                        </td>
                                        <ActionDelete
                                            {...props}
                                            item={itemChild}
                                        />
                                    </tr>
                                </tbody>
                            )
                        })}
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <RowAdd
                                        name={`Property`}
                                        handleAddStandardChatTopic={() => toggle('add', {})}
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <ModalAddChildren
                            {...props}
                            isOpen={modal?.add}
                            toggle={() => toggle('add', {})}
                            isChildren={true}
                        />
                        <ModalEditChatTopic
                            {...props}
                            isOpen={modal?.edit}
                            toggle={() => toggle('edit', itemEdit)}
                            item={itemEdit}
                            handleUpdateSuccess={handleUpdateSuccess}
                        />
                    </Table>
                </Collapse>
            </td>
        </tr>
    );
};

export default ChatTopicChildren;