import React, { useState, useEffect } from "react";

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Collapse
} from "reactstrap";

//api
import { apiCaller } from "@utils";
import { actionConstants, storyboardConstants } from "@constants";

const ModalIntervention = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { dataIntervention, typeIntervention, timePointId, categoryType, isOpen, toggle } = props && props;
  const headingModal = typeIntervention === "add" ? "Add Intervention" : "Edit Intervention";
  const idCategory = props && props.activeCategory;

  //state
  const [nameState, setNameState] = useState({});
  const [isOpenOption, setIsOpenOption] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleOption = () => setIsOpenOption(!isOpenOption);

  useEffect(() => {
    if (typeIntervention === "edit") {
      setNameState(dataIntervention);
    } else {
      setNameState({
        Name: "",
        Explanation: "",
      });
    }
  }, [dataIntervention, typeIntervention]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  };

  const checkReloadApiForElements = (type) => {
    const params = {}
    const pathName = window.location.pathname.includes("/elements");
    if (pathName) {
      params["ReloadData"] = type;
    }
    return params;
  }

  const hanldeSubmit = () => {
    setLoading(true);
    let commonParameter = {
      Target: "Intervention",
      TimePointId: timePointId,
      CategoryId: idCategory,
      Name: nameState.Name,
      Available: true,
      Explanation: nameState.Explanation,
      ActionMessage: nameState.ActionMessage,
      ActionMessageDuration: nameState.ActionMessageDuration
    };

    const reload = checkReloadApiForElements(categoryType === "Action" ? "Actions" : "Medications");
    if (reload) {
      //Reload Data
      commonParameter = { ...commonParameter, ...reload }
    }

    if (typeIntervention === "add") {
      commonParameter["Action"] = "Create";
      //Check Type
      if (categoryType === "Action") {
        atcCreateInterventionForActions(id, commonParameter, idCategory)
      } else {
        atcCreateInterventionForMedications(id, commonParameter, idCategory);
      }
    } else {
      commonParameter["Action"] = "Update";
      commonParameter["InterventionId"] = nameState.Id;

      if (categoryType === "Action") {
        atcUpdateInterventionForActions(id, commonParameter, idCategory);
      } else {
        atcUpdateInterventionForMedicatons(id, commonParameter, idCategory)
      }
    }
  };

  //Create Intervention For Actions
  const atcCreateInterventionForActions = (id, params, idCategory) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: actionConstants.CREATE_INTERVENTION_FOR_ACTIONS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: actionConstants.CREATE_INTERVENTION_FOR_ACTIONS_SUCCESS,
          payload: data,
          idCategory: idCategory
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: actionConstants.CREATE_INTERVENTION_FOR_ACTIONS_FAILURE,
          error: 'error',
        });
        setLoading(false);
      }
    });
  }

  //Create Intervention For Medications
  const atcCreateInterventionForMedications = (id, params, idCategory) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: storyboardConstants.CREATE_INTERVENTION_FOR_MEDICATIONS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.CREATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS,
          payload: data,
          idCategory: idCategory
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.CREATE_INTERVENTION_FOR_MEDICATIONS_FAILURE,
          error: 'error',
        });
        setLoading(false);
      }
    });
  }

  //Update Intervention For Actions
  const atcUpdateInterventionForActions = (id, params, idCategory) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: actionConstants.UPDATE_INTERVENTION_FOR_ACTIONS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: actionConstants.UPDATE_INTERVENTION_FOR_ACTIONS_SUCCESS,
          payload: data,
          idCategory: idCategory
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: actionConstants.UPDATE_INTERVENTION_FOR_ACTIONS_FAILURE,
          error: 'error',
        });
        setLoading(false);
      }
    });
  }

  //Update Intervention For Medications
  const atcUpdateInterventionForMedicatons = (id, params, idCategory) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: storyboardConstants.UPDATE_INTERVENTION_FOR_MEDICATIONS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_INTERVENTION_FOR_MEDICATIONS_SUCCESS,
          payload: data,
          idCategory: idCategory
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UPDATE_INTERVENTION_FOR_MEDICATIONS_FAILURE,
          error: 'error',
        });
        setLoading(false);
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>{headingModal}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              name="Name"
              placeholder="Name of the new intervention"
              id="Name"
              defaultValue={nameState.Name}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormGroup>
        </Form>
        <FormGroup>
          <Label for="Explanation">Explanation:</Label>
          <Input
            type="textarea"
            name="Explanation"
            id="Explanation"
            placeholder="Example: Medication has been given"
            defaultValue={nameState.Explanation}
            onChange={handleChange}
          />
        </FormGroup>
        <p className="text-muted small">
          <small>
            [Optional] Notifying the learner that the action has been activated
          </small>
        </p>
        <hr />
        <Button
          className="mt-2"
          color="primary"
          onClick={toggleOption}
          style={{ marginBottom: "1rem" }}
        >
          More Option{" "}
          {isOpenOption ? (
            <i className="fa fa-chevron-up" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          )}
        </Button>
        <Collapse isOpen={isOpenOption}>
          <FormGroup>
            <Label for="ActionMessage">Action Message: </Label>
            <Input
              type="textarea"
              name="ActionMessage"
              id="ActionMessage"
              defaultValue={nameState.ActionMessage}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup row>
            <Label for="ActionMessageDuration" sm={7}>
              Action Message Showing Duration
            </Label>
            <Col sm={5}>
              <InputGroup>
                <Input
                  type="number"
                  name="ActionMessageDuration"
                  id="ActionMessageDuration"
                  defaultValue={nameState.ActionMessageDuration || 0}
                  onChange={handleChange}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>sec</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </FormGroup>
        </Collapse>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={hanldeSubmit}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          {typeIntervention === "add" ? "Add" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalIntervention;
