import React from 'react';

//reactBoostrap
import {
    FormGroup,
    Label,
    Row,
    Col,
    Input
} from "reactstrap";

//components
import { ButtonShowDragDrop } from '../Tabs';

//GradingSchema components
import { GSDropCloze, GSFreeText } from '../GradingSchema';

//Question Type
import { QuestionTypeOptions, QuestionPromptTemplate, ImmediateFeedbackModeSwitch } from "../../Question";

function AnswerTab(props) {
    const {
        isQuestionType,
        explanationRequired,
        onChangeExplanation
    } = props && props;
    return (
        <Row className="mb-3">
            <Col md={4}>
                <QuestionTypeOptions {...props} />
                <QuestionPromptTemplate {...props} />
            </Col>
            {isQuestionType === "free_text" && (
                <GSFreeText {...props} />
            )}
            {isQuestionType === "drag_n_drop" && (
                <ButtonShowDragDrop />
            )}
            {isQuestionType === "drag_n_drop_cloze" && (
                <GSDropCloze {...props} />
            )}
            <Col md={4} className="ml-auto">
                <ImmediateFeedbackModeSwitch
                    {...props}
                />
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            defaultChecked={explanationRequired}
                            defaultValue={explanationRequired}
                            disabled={isQuestionType === "free_text"}
                            onChange={onChangeExplanation}
                        />{" "}
                        Require follow up explanation
                    </Label>
                </FormGroup>
                <table style={{ maxWidth: "320px" }} className="mt-3">
                    <tbody>
                        <tr>
                            <td
                                className="font-weight-500"
                                style={{ width: "20%", verticalAlign: "top" }}
                            >
                                Max Score:
                            </td>
                            <td style={{ width: "40%" }}>
                                <input
                                    type="number"
                                    className="form-control"
                                    name="max_score"
                                />
                                <p>
                                    <small
                                        className="text-muted text-justify"
                                        style={{ fontSize: "12px" }}
                                    >
                                        Leave blank to set the max score as the sum of
                                        scores given to all options
                                    </small>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Col>
        </Row>
    );
};

export default AnswerTab;