import { agreementsConstants } from "@constants";
import { apiCaller } from "@utils";

//Load Agreements
export const actLoadAgreements = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/agreement/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.LOAD_AGREEMENTS_REQUEST, isLoading } }
    function success(data) { return { type: agreementsConstants.LOAD_AGREEMENTS_SUCCESS, payload: data } }
    function failure(error) { return { type: agreementsConstants.LOAD_AGREEMENTS_FAILURE, error: error } }
}

// Update Agreement
export const atcUpdateAgreement = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/agreement/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.UPDATE_AGREEMENTS_REQUEST, isLoading } }
    function success(data) { return { type: agreementsConstants.UPDATE_AGREEMENTS_SUCCESS, payload: data } }
    function failure(error) { return { type: agreementsConstants.UPDATE_AGREEMENTS_FAILURE, error: error } }
}

// Update Agreement
export const atcPrivacyPolicy = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/agreement/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.UPDATE_PRIVACY_POLICY_REQUEST, isLoading } }
    function success(data) { return { type: agreementsConstants.UPDATE_PRIVACY_POLICY_SUCCESS, payload: data } }
    function failure(error) { return { type: agreementsConstants.UPDATE_PRIVACY_POLICY_FAILURE, error: error } }
}

// Update Agreement
export const atcGetAgreement = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/agreements/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.GET_AGREEMENTS_REQUEST, isLoading } }
    function success(data) { return { type: agreementsConstants.GET_AGREEMENTS_SUCCESS, payload: data } }
    function failure(error) { return { type: agreementsConstants.GET_AGREEMENTS_FAILURE, error: error } }
}

// Load All Standard Chat Topics
export const loadAllStandardChatTopics = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/chat/standard/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_REQUEST, isLoading } }
    function success(data) { return { type: agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_SUCCESS, payload: data } }
    function failure(error) { return { type: agreementsConstants.LOAD_ALL_STANDARD_CHAT_TOPICS_FAILURE, error: error } }
}

// Delete Standard Chat Topic
export const deleteStandardChatTopic = (ParentChatTopicId, type, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/chat/standard/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(type, data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: agreementsConstants.DELETE_STANDARD_CHAT_TOPICS_REQUEST, isLoading } }
    function success(type, data) {
        return {
            type: agreementsConstants.DELETE_STANDARD_CHAT_TOPICS_SUCCESS,
            payload: data,
            isActive: type,
            ParentChatTopicId: ParentChatTopicId || ''
        }
    }
    function failure(error) { return { type: agreementsConstants.DELETE_STANDARD_CHAT_TOPICS_FAILURE, error: error } }
}