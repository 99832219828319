//admin
import {
    AdminChatPage,
    LegalPage,
    OnBoardingPage,
    ScrumPage,
    TicketPage,
    DataPage,
    ModelsPage,
    AvatarsPage,
    ManageGenerated,
} from "@pages/med2lab/admin";

const adminRoutes = [
    {
        path: ["/admin", "/admin/on-boarding"],
        exact: true,
        classPage: '',
        component: OnBoardingPage,
        isLayout: true,
    },
    {
        path: "/admin/utilities/scrum",
        exact: true,
        classPage: '',
        component: ScrumPage,
        isLayout: true,
    },
    {
        path: "/admin/utilities/prompts",
        exact: true,
        classPage: '',
        component: ModelsPage,
        isLayout: true,
    },
    {
        path: "/admin/support/tickets",
        exact: true,
        classPage: '',
        component: TicketPage,
        isLayout: true,
    },
    {
        path: "/admin/legal",
        exact: true,
        classPage: '',
        component: LegalPage,
        isLayout: true,
    },
    {
        path: "/admin/settings/models",
        exact: true,
        classPage: '',
        component: ModelsPage,
        isLayout: true,
    },
    {
        path: "/admin/settings/chat",
        exact: true,
        classPage: '',
        component: AdminChatPage,
        isLayout: true,
    },
    {
        path: "/admin/settings/avatars",
        exact: true,
        classPage: '',
        component: AvatarsPage,
        isLayout: true,
    },
    {
        path: "/admin/monitor/data",
        exact: true,
        classPage: '',
        component: DataPage,
        isLayout: true,
    },
    {
        path: "/admin/dashboard",
        exact: true,
        classPage: '',
        component: ManageGenerated,
        isLayout: true,
    }
];

export { adminRoutes };