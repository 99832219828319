import React from "react";

//reactstrap
import {
    FormGroup,
    Input,
    Label
} from "reactstrap";

function AutomaticLauchFeedback(props) {
    const { handleChange, automatedFeedback } = props && props;
    return (
        <React.Fragment>
            <FormGroup className='d-flex align-items-center' check>
                <Input
                    id="AutomatedFeedbackTrigger"
                    name="AutomatedFeedbackTrigger"
                    type="checkbox"
                    className='mt-0'
                    defaultChecked={automatedFeedback?.AutomatedFeedbackTrigger}
                    onChange={handleChange}
                />
                <Label
                    check
                    for="AutomatedFeedbackTrigger"
                >
                    Automatically launch feedback after
                </Label>
                <Input
                    name="AutomatedFeedbackTriggerCount"
                    placeholder=""
                    type="number"
                    inputMode="numeric"
                    className="mx-2"
                    defaultValue={automatedFeedback?.AutomatedFeedbackTriggerCount}
                    onChange={handleChange}
                    style={{ maxWidth: '70px' }}
                    pattern="[2-9]+"
                    min="2"
                    onKeyPress={(event) => {
                        if (!/[2-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
                questions
            </FormGroup>
            <p className="text-danger mt-2"><i><span className="mr-2">(*)</span>Value must be larger than 1</i></p>
        </React.Fragment>
    );
}

export default AutomaticLauchFeedback;