import React from "react";

//components common
import {
    AudioFile,
    VideoFile,
    VideoLink,
    ImageFile,
    DropDownMedia,
} from "@components-common/media";

const MediaContainer = (props) => {
    const {
        handleType,
        isType,
        handleChange,
        urlValue,
        handleUploadImage,
        onFileAudioUpload
    } = props && props;

    return (
        <div className="mediaContainer">
            <DropDownMedia onChange={handleType} value={isType} />
            {isType === "VideoLink" && (<VideoLink value={urlValue} onChange={handleChange} />)}
            {isType === "ImageFile" && (<ImageFile onChange={handleUploadImage} value={urlValue} />)}
            {isType === "AudioFile" && (<AudioFile onChange={onFileAudioUpload} value={urlValue} />)}
            {isType === "VideoFile" && (<VideoFile onChange={onFileAudioUpload} value={urlValue} />)}
            <hr />
        </div>
    );
};

export default MediaContainer;