import React from 'react'
import { Icon } from '@components/common'
import stick from '@images/teachV2/stick-btn.svg'
import InputComponent from './InputComponent'
import GenerateOption from './GenerateOption'
import ClipLoader from "react-spinners/ClipLoader";

export default function InputGenerate(props) {
  const { loadingResult } = props

  return (
    <div className="inputGenerate p-0">
      <div className="inputGenerate__form mb-2">
        <InputComponent {...props}/>
        <GenerateOption {...props}/>
      </div>
      <div className="inputGenerate__submit w-100">
        <button type='submit' className={`${loadingResult ? "disabled" : ""} inputGenerate__submit-btn`}>
          <Icon src={stick}/>
          Generate 
          {loadingResult && <ClipLoader color="#FFF" size={16}/>}
        </button>
      </div>
    </div>
  )
}
