import React from "react";
import { Link } from "react-router-dom";

function ButtonExitCase(props) {
    //props
    const { exitCaseUrl } = props && props;
    return (
        <React.Fragment>
            {exitCaseUrl ? (
                <Link
                    className="btn btn-sm --bg-primary"
                    to={exitCaseUrl}
                >
                    <span className="text-white">Exit Case</span>
                    <i className="fa fa-reply text-white text-1 ml-2" aria-hidden="true"></i>
                </Link>
            ) : (
                <Link
                    className="btn btn-sm --bg-primary"
                    to={"/teach/home"}
                >
                    <span className="text-white">Exit Case</span>
                    <i className="fa fa-reply text-white text-1 ml-2" aria-hidden="true"></i>
                </Link>
            )}
        </React.Fragment>
    );
}

export default ButtonExitCase;