import React from 'react';
import styled from 'styled-components';
import { Icon } from '@components/common';
import Error from '@images/teachV2/error-circle.svg';
import Success from '@images/common/success-noti.svg';

const WrapOption = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  gap: 6px;
`;

const FileWrap = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ hasContent }) => (hasContent ? 'start' : 'center')};
  gap: 16px;

  .content-toast {
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    h4 {
      color: #101828;
      font-size: 14px;
      font-weight: 600;
    }
  
    p {
      color: #344054;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
`;

export default function ToastCommon(props) {
  const { status = "default", content, title } = props;

  const statusIcon = (status) => {
    switch (status) {
      case "default":
        return <Icon src={Error} stroke="#475467" />;
      case "error":
        return <Icon src={Error} stroke="#D92D20" />;
      case "warning":
        return <Icon src={Error} stroke="#DC6803" />;
      case "success": 
        return <Icon src={Success} />;
      case "noti":
        return <Icon src={Error} stroke="#1570EF" />;
      default:
        return <Icon src={Error} stroke="#475467" />;
    }
  };

  return (
    <WrapOption>
      <FileWrap className="wrap-option__file-wrap">
        <FileInfo className="wrap-option__file-info" hasContent={!!content}>
          {statusIcon(status)}
          <div className="content-toast">
            {title && <h4>{title}</h4>}
            {content && <p>{content}</p>}
          </div>
        </FileInfo>
      </FileWrap>
    </WrapOption>
  );
}