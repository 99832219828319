import React from "react";

//components
import { TextareaInstruction } from "./FreeText";
import { TableAnswerMutipleChoice } from "./QuestionType";

//components common
import { CustomCollapseSection } from "@components-common";

function TableAnswerList(props) {

    // Props
    const { QuestionType, questionData } = props;

    // Render component for free text based on grading schema
    const renderComponentForFreeText = (isGradingSchema) => {
        let renderComponent;
        switch (isGradingSchema) {
            case "chatgpt":
            case "dialog":
                renderComponent = (
                    <TextareaInstruction
                        {...props}
                    />
                );
                break;
            default:
                renderComponent = null; // Change to null instead of empty string
        }
        return renderComponent;
    };

    // Render component based on question type
    const renderComponentQuestionType = (questionType) => {
        let renderComponent;
        switch (questionType) {
            case "multiple_choice":
                renderComponent = (
                    <TableAnswerMutipleChoice
                        {...props}
                    />
                );
                break;
            case "free_text":
                renderComponent = renderComponentForFreeText(questionData?.GradingSchema);
                break;
            default:
                renderComponent = null; // Change to null instead of empty string
        }
        return renderComponent;
    };

    // Decide whether to render CustomCollapseSection based on question type
    const renderAnswerSection = (questionType) => {
        if (questionType === "free_text") {
            return renderComponentQuestionType(QuestionType);
        } else {
            return (
                <CustomCollapseSection borderHeader={true} title="Answer List">
                    {renderComponentQuestionType(QuestionType)}
                </CustomCollapseSection>
            );
        }
    };

    return renderAnswerSection(QuestionType);
}

export default TableAnswerList;