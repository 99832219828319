import React, { useState } from "react";

//components
import { ModalCondition } from '../Modules';

function AddCondition(props) {
    const [modal, setModal] = useState(false);

    const toggle = (row, type) => {
        setModal(!modal);
    };

    return (
        <React.Fragment>
            <span style={{ cursor: 'pointer' }} className="text-muted font-weight-500 text-nowrap p-3" onClick={toggle}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                Add Condition
            </span>
            <ModalCondition
                isOpen={modal}
                toggle={toggle}
            />
        </React.Fragment>
    );
};

export default AddCondition;