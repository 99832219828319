import React from 'react';

// components
import { ProfileHeader, CountCoin } from "../Header"; //SearchHeader, XpBox

// redux
import { useSelector } from "react-redux";

//common
import { SwitchRoleDesktop } from "@ui-partents/SwitchRole";

const HeaderRight = (props) => {
    const { makansafeLayout } = props;

    //redux
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const is_instructor = AccountInfoData?.Account?.is_instructor === "Yes";
    return (
        <div className="header_right d-flex justify-content-between align-items-center">
            {!makansafeLayout && (
                <React.Fragment>
                    {/* <SearchHeader {...props} /> */}
                    {is_instructor && <SwitchRoleDesktop {...props} />}
                    {/* <XpBox {...props} /> */}
                    <CountCoin {...props} />
                    {/* <Notic {...props} /> */}
                </React.Fragment>
            )}
            <ProfileHeader {...props} />
        </div>
    );
};

export default HeaderRight;