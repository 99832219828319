import React, { useState, useEffect } from 'react';

//reactstrap
import { Container } from 'reactstrap';

//react-router-dom
import { useParams } from "react-router-dom";

//components
import { FeedbackTabsContainer } from '@components/learner/FeedbackV2'; //SelfReflectionDialogueMain
import { CaseTitle } from "@components/learner/StandardScreen/InteractAssistant";

//utils
import { apiCaller } from "@utils";

function FeedbackV2Page(props) {
    const { id } = useParams();

    //state
    const [feedbackData, setFeedbackData] = useState(null);
    const [isLoading, setLoading] = useState(false);

    // Load Feedback
    useEffect(() => {
        const fetchData = () => {
            const params = { CaseId: id };
            setLoading(true);
            apiCaller(`/api/learn/case/feedbacks_v2/`, 'POST', params).then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    setFeedbackData(data);
                    setLoading(false);
                } else {
                    console.log("error....");
                    setLoading(false);
                }
            });
        };
        fetchData();
    }, [id]);

    return (
        <div className="wrapper -feedbackPage">
            <Container fluid>
                <CaseTitle {...props} title={feedbackData?.CaseTitle} />
                <FeedbackTabsContainer
                    {...props}
                    feedbackData={feedbackData}
                    isLoading={isLoading}
                />
                {/* <SelfReflectionDialogueMain {...props} /> */}
            </Container>
        </div>
    );
}

export default FeedbackV2Page;