import React from "react";

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);

function PieChart(props) {
  const { analyzeReviews } = props && props;
  const total_items = analyzeReviews?.total_items;
  const sentiment_analysis = analyzeReviews?.sentiment_analysis;
  const valueTotalAgree = (sentiment_analysis?.total_agree / total_items) * 100;
  const valueTotalDisagree = (sentiment_analysis?.total_disagree / total_items) * 100;

  const data = [
    {
      type: "pie",
      labels: ["Agree", "Disagree"],
      sort: false,
      values: [valueTotalAgree, valueTotalDisagree],
      marker: {
        colors: ["#2D87BB", "#FEAE65"],
      },
      hoverinfo: "label+percent",
      // textposition: "none",
      automargin: true,
      hole: 0.4,
    },
  ];

  const layout = {
    width: 400,
    height: 400,
    margin: { t: "auto", b: "auto", l: "auto", r: "auto" },
    showlegend: true
  };

  const configResize = {
    useResizeHandler: true,
    style: { width: "100%", height: "100%" },
    displayModeBar: false
  }
  
  return (
    <div className="position-relative">
      {sentiment_analysis && (
        <React.Fragment>
          <div className="font-weight-500 mx-auto text-center text-5 mb-3">N = {total_items}</div>
          <PlotlyComponent
            data={data}
            layout={layout}
            config={configResize}
            style={{ width: "100%", height: "100%" }}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export default PieChart;