import React, { useState, useEffect } from "react";

//reactstrap
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

//useParams
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

// constants
import { storyboardConstants } from "@constants";

//apiCaller
import { apiCaller } from "@utils";

function DropDownGenerate(props) {
    const { caseData, timepointData, timePointId } = props && props;
    let { id } = useParams();
    const responseData = timepointData?.data;
    const vitalSigns = responseData?.DefaultVitalSigns;
    const dispatch = useDispatch();

    //state
    const [stateData, setData] = useState(caseData || {});
    const [stateVitalSigns, setVitalSigns] = useState(vitalSigns || []);
    const [dropdownOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // Load Case
    useEffect(() => {
        setData(caseData || {});
    }, [caseData])

    // Load TimePoint
    useEffect(() => {
        const responseData = timepointData?.data;
        const vitalSigns = responseData?.DefaultVitalSigns;
        setVitalSigns(vitalSigns || {});
    }, [timepointData])

    // Toggle Dropdown
    const toggleDropdown = () => setOpen(!dropdownOpen);

    // Handle Click Item
    const handleExtractVitalSigns = () => {
        const params = {
            "action_type": "extract_vital_signs",
            "case_draft": stateData?.CaseDraft,
            "vital_signs": stateVitalSigns,
            "timepoint_id": timePointId,
            "case_id": id
        }
        setLoading(true);
        dispatch({ type: storyboardConstants.EXTRACT_VITAL_SIGNS_REQUEST, isLoading: true });
        apiCaller(`/api/medicalcase/functional/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoading(false);
                dispatch({ type: storyboardConstants.EXTRACT_VITAL_SIGNS_SUCCESS, payload: data });
            } else {
                console.log("error");
                setLoading(false);
                dispatch({ type: storyboardConstants.EXTRACT_VITAL_SIGNS_FAILURE, error: 'error' });
            }
        });
    }

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className="--bg-primary" caret disabled={isLoading}>
                {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Generate
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem onClick={handleExtractVitalSigns}>
                    From Case Draft
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
}

export default DropDownGenerate;