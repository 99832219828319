import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';

function ScoreInput(props) {
    const { data, handleChange } = props && props;
    const Score = data?.Score;

    //state
    const [stateScore, setScore] = useState((Score && Score) || 0);

    useEffect(() => {
        setScore((Score && Score) || 0);
    }, [Score])
    
    return (
        <Form>
            <FormGroup>
                <Label for="Score" >
                    Score:
                </Label>
                <Input
                    id="Score"
                    name="Score"
                    placeholder=""
                    type="number"
                    inputMode="numeric"
                    value={stateScore}
                    onChange={handleChange}
                    style={{ maxWidth: '150px' }}
                    step="any"
                />
            </FormGroup>
        </Form>
    );
};

export default ScoreInput;