import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

//common
import { DataEmty } from "@components/common";

// common
import { HeadingBox } from "@components/common";

//boostrap
import { Container, Row, Col, Card } from "reactstrap";

//call Action
import { atcLoadAllQuestion, atcReportDomain } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//lodash
import _ from "lodash";

//components
import {
  FilterTop,
  DomainContent,
  ScatterPlot,
  StatisticsComponent
} from "@components/reports/Domains";

//loader
import { LoaderText, LoaderPage } from "@utils";

function DomainsReportPage(props) {
  //props
  const { caseDetails } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();

  //ref
  const refTop = useRef(null);

  //state
  const [stateData, setStateData] = useState({
    DomainId: null,
    CohortId: null,
    Periods: [],
    Learner: ''
  });
  const [isScroll, setScroll] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const [textMessage, setMessage] = useState('');

  //Load All Questions
  useEffect(() => {
    const fetchAllQuestions = async () => {
      const params = {
        Action: "Load",
        Target: "AllQuestions",
      };
      dispatch(atcLoadAllQuestion(id, params));
    };
    fetchAllQuestions();
  }, [dispatch, id]);

  //handle select
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateData((prevState) => ({ ...prevState, [name]: value }));

    //call api
    setLoad(true);
  };

  const scrollTo = () => {
    var elem = document.getElementById("divScrollChart");
    elem.scrollIntoView(
      { behavior: 'smooth', block: 'start' }
    );
  }

  const handleChangeOptions = (data) => {
    const CohortArray = _.map(data, "value");
    setStateData((prevState) => ({ ...prevState, Periods: CohortArray }));

    //call api
    setLoad(true);
  }

  const handleChart = (email) => {
    setStateData((prevState) => ({ ...prevState, Learner: email }));
    callApiReportDomain(email);

    //scroll top
    setScroll(true);
  }

  const callApiReportDomain = useCallback(
    (email) => {
      const params = {
        ReportType: "domains_Longitudinal",
        DomainId: stateData.DomainId || '',
        CohortId: parseFloat(stateData.CohortId) || null,
        Periods: stateData.Periods || []
      };

      if (email) {
        params[`Learner`] = email
      }
      dispatch(atcReportDomain(id, params));
    },
    [id, dispatch, stateData]
  );

  const hanldeClearData = () => {
    setStateData((prevState) => ({ ...prevState, Learner: '' }));
    setLoad(true);
  }

  //Set Load Api if has State
  useEffect(() => {
    if (isLoad) {
      callApiReportDomain();
      setLoad(false);
    }

    //scroll top
    if (isScroll) {
      scrollTo();
      setScroll(false);
    }
  }, [id, callApiReportDomain, isScroll, stateData, isLoad]);

  //Check Message
  useEffect(() => {
    //CheckMessage
    if (_.isEmpty(stateData?.DomainId)) {
      setMessage("Choose a Domain from the dropdown");
    } else if (_.isEmpty(stateData?.CohortId)) {
      setMessage("Choose a Cohort");
    } else if (stateData?.Periods && stateData?.Periods.length === 0) {
      setMessage("Chose at least 2 Periods from the dropdown");
    } else if (_.isEmpty(stateData?.Periods) || stateData?.Periods.length === 1) {
      setMessage("Choose at least 1 more Period from the dropdown");
    } else {
      setMessage("Choose a Domain from the dropdown");
    }
  }, [stateData]);

  const teachCaseData = useSelector((state) => state.teachCase);
  const domainStore = useSelector((state) => state?.dataDomainRecord || {});
  const domainRecord = domainStore?.data;

  //data store
  const resData = teachCaseData && teachCaseData.data;
  const domainData = resData && resData.Domains;

  //titlePage
  const titlePage = caseDetails?.Title;

  //Filter Domain is Active By Id
  const itemActive = domainData && _.filter(domainData, function (o) { return o?.Id === parseFloat(stateData?.DomainId) });
  const ChartData = domainRecord?.Chart;
  const Statistics = domainRecord?.Statistics;
  return (
    <div className="wrapper -reportPage">
      {_.isEmpty(resData) && <LoaderPage />}
      {!_.isEmpty(resData) && (
        <React.Fragment>
          <div className="reportCt" ref={refTop}>
            <Container>
              <Row>
                <Col sm={12}>
                  <HeadingBox title={titlePage || ""} currentPage="Domains" />
                </Col>
              </Row>
              <Row className="gutters-sm">
                <Col md={12} className="mt-3">
                  <FilterTop
                    stateData={stateData}
                    handleChange={handleChange}
                    handleChangeOptions={handleChangeOptions}
                    itemActive={itemActive}
                    domainData={(domainData && domainData) || []}
                  />
                </Col>
                <Col md={12} className="mt-3 position-relative" id={"divScrollChart"}>
                  {(stateData?.DomainId && stateData?.CohortId && (stateData?.Periods && stateData?.Periods.length > 1) ? (
                    <React.Fragment>
                      <Card className="border-0 -box-shadow mb-4">
                        {domainStore?.isLoading &&
                          <LoaderText />
                        }
                        <Row className="align-items-center">
                          <Col md={9}>
                            {ChartData && ChartData.length > 0 &&
                              <ScatterPlot
                                Chart={(ChartData && ChartData) || []}
                                itemActive={(itemActive && itemActive[0]) || {}}
                                emailActive={stateData?.Learner}
                                isLoading={domainStore?.isLoading}
                                hanldeClearData={hanldeClearData}
                              />
                            }
                          </Col>
                          <Col md={3}>
                            <StatisticsComponent data={(Statistics && Statistics) || {}} />
                          </Col>
                        </Row>
                      </Card>
                      <DomainContent
                        data={(domainRecord && domainRecord) || {}}
                        itemActive={(itemActive && itemActive[0]) || {}}
                        handleChart={handleChart}
                        isLoading={domainStore?.isLoading}
                        stateData={stateData}
                      />
                    </React.Fragment>
                  ) : (
                    <DataEmty content={textMessage} />
                  ))}
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default DomainsReportPage;
