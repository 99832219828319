import React, { useState, useEffect } from 'react';

//reactstrap
import { FormGroup, Label, Col, Input } from "reactstrap";

function FilterStatus(props) {
    const { handleChange, value } = props && props;
    const [isStatus, setStatus] = useState(value || 'Active');

    //Load Status
    useEffect(() => {
        setStatus(value || 'Active');
    }, [value])

    return (
        <FormGroup className="mb-0 mr-3" style={{ maxWidth: '250px' }} row>
            <Label
                for="exampleSelect"
                sm={3}
                className='text-nowrap font-weight-500'
            >
                Filter by:
            </Label>
            <Col sm={9}>
                <Input
                    id="exampleSelect"
                    name="Status"
                    type="select"
                    className="ml-4"
                    onChange={handleChange}
                    value={isStatus}
                >
                    <option value={``}>
                        All
                    </option>
                    <option value={`Active`}>
                        Active
                    </option>
                    <option value={`Draft`}>
                        Draft
                    </option>
                    <option value={`Expired`}>
                        Expired
                    </option>
                </Input>
            </Col>
        </FormGroup>
    );
};

export default FilterStatus;