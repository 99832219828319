import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';

//boostrap
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Form,
  FormFeedback,
} from "reactstrap";

//ReCAPTCHA
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcViewDemoUsingGuestAccount } from "@actions";

//call Api
import {apiCaller} from "@utils";

//untils
import { checkAuth } from '@utils/';

function DemoPage() {
  let history = useHistory();
  const dispatch = useDispatch();
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [stateValid, setStateValid] = useState(false);
  const [isLoading, setLoading] = useState(false);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    window.location.href = 'https://youtu.be/YcZ0Cd8bshg';
    if(checkAuth()) {
      history.push('/');
    }
  }, [history]);

  const onChangeCaptcha = (value) => {
    setTokenCaptcha(value);
  };

  const nextPage = () => {
    history.push("/");
  };

  const onsubmit = (data) => {
    if (tokenCaptcha) {
      const params = { Email: data.Email };
      setLoading(true);
      apiCaller(`/api/auth/demo/`, "POST", params).then((response) => {
          const dataResponse = response && response.data;
          if(dataResponse?.status === "OK") {
            dispatch(atcViewDemoUsingGuestAccount(dataResponse));
            setLoading(false);
            nextPage();
          } else {
            setStateValid(true);
            setLoading(false);
          }
      });
    }
  };

  return (
    <div className="wrapper -demoPage">
      <Container>
        <Row>
          <Col md={8} className="text-center mx-auto">
            <h1 className="heading-2 mb-0 --text-primary">
              Thank you for your interest in Med2lab platform!
            </h1>
            <p>Please enter your email to view our demo modules:</p>
            <Form className="formCt">
              <FormGroup className="mt-5 mx-auto" style={{ maxWidth: "500px" }}>
                <Input
                  type="email"
                  placeholder="Enter your email address"
                  bsSize="lg"
                  name="Email"
                  {...register("Email", {
                    required: "Email is required",
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  onChange={(e) => {
                    setValue("Email", e.target.value, { shouldValidate: true });
                  }}
                  invalid={errors.Email?.type === "required"}
                />
                {errors.Email?.message && (
                  <FormFeedback className="d-block text-left">
                    {errors.Email?.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  sitekey={Config.KEY_CAPTCHA}
                  onChange={onChangeCaptcha}
                />
                ,
              </div>
              {stateValid && 
                <div className="row mt-2">
                  <div className="col-md-12 m--font-danger" id="message_invalid" style={{textAlign: 'center'}}>
                    Either your email not valid. Please try again.
                  </div>
                </div>
              }
              <button
                className="btn btn-brand mx-auto mt-4 d-flex align-items-center"
                onClick={handleSubmit(onsubmit)}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Run Demo
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DemoPage;
