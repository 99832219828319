import React from "react";

function OrdersTab(props) {

  const dataProps = props && props.data;
  const beneficial = dataProps && dataProps.BeneficialOrders;
  const missedBeneficial = dataProps && dataProps.MissedBeneficialOrders;
  const unnecessary = dataProps && dataProps.UnnecessaryOrders;

  return (
    <div className="ordersTabFb">
        <div className="groupOrders">
            {beneficial && beneficial.length > 0 && 
                <div className="groupOrders__title mb-2">
                    <p>You have made the following order(s) that are <strong>recommended</strong>:</p>
                </div>
            }
            {beneficial && beneficial.map((value, index) =>
                <dl style={{color: "#61bca4"}} className="groupOrders__row row mb-2" key={index}>
                    <dt className="col-sm-3">{value.OrderName}</dt>
                    <dd className="col-sm-9">{value.Explanation}</dd>
                </dl>
            )}
        </div>
        {/* /group */}
        <div className="groupOrders">
            {missedBeneficial && missedBeneficial.length > 0 && 
                <div className="groupOrders__title mb-2">
                    <p>You <strong>missed</strong> the following recommended order(s) </p>
                </div>
            }
            {missedBeneficial && missedBeneficial.map((value, index) =>
                <dl style={{color: "#3b87be"}} className="groupOrders__row row mb-2" key={index}>
                    <dt className="col-sm-3">{value.OrderName}</dt>
                    <dd className="col-sm-9">{value.Explanation}</dd>
                </dl>
            )}
        </div>
        {/* /group */}
        <div className="groupOrders">
            {unnecessary && unnecessary.length > 0 &&
                <div className="groupOrders__title mb-2">
                    <p>The following order(s) you made are <strong>not recommended</strong>:</p>
                </div>
            }
            {unnecessary && unnecessary.map((value, index) =>
                <dl style={{color: "#b02418"}} className="groupOrders__row row mb-2" key={index}>
                    <dt className="col-sm-3">{value.OrderName}</dt>
                    <dd className="col-sm-9">{value.Explanation}</dd>
                </dl>
            )}
        </div>
        {/* /group */}
    </div>
  );
}

export default OrdersTab;
