const convertDataReactSelect = (data, keyField, labelField) => {
    if (!Array.isArray(data)) {
        console.error('Invalid data format');
        return [];
    }

    if (data?.length === 0) {
        console.error('Empty data');
        return [];
    }

    if (keyField && labelField) {
        return data?.map(item => ({
            value: item[keyField],
            label: item[labelField]
        }));
    }

    return data?.map(item => ({
        value: item,
        label: item
    }));
};

export default convertDataReactSelect;