import React, { useState } from 'react'
import { Icon } from '@components/common'
import Copy from '@images/teachV2/copy.svg'
import Regenerate from '@images/teachV2/regenerate.svg'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import stick from '@images/teachV2/stick-btn.svg'
import check from '@images/teachV2/check-done.svg'
import ReactTooltip from 'react-tooltip';
import Feedback from '@images/teachV2/feedback.svg'
import { WrapModalRegenerate } from '../../home/common'
import { apiCaller } from "@utils";

const GenerateItem = (props) => {
  const [showData, setShowData] = useState(true)
  const [loadingContent, setLoadingContent] = useState(false)
  const [showGuide, setShowGuide] = useState(true)
  const [showStep, setShowStep] = useState(true)
  const [showCheckDone, setShowCheckDone] = useState(false)
  const [showSaveDone, setShowSaveDone] = useState(false)
  
  const { 
    contentGenerate, 
    titleGenerate, 
    dataCaseScript, 
    setDataCaseScript, 
    handleGenerateScript, 
    loadingGenerateScript, 
    dataRef,
    refDefault,
    index,
    toggleModal,
    setDataFeedback,
    modalOpen,
    lineRefs
  } = props

  const handleSave = (dataRef, title) => {
    if (dataCaseScript?.case_sections) {
      const dataCloneFile = {
        ...dataCaseScript,
        learning_objectives: refDefault?.current?.innerHTML
      }
      setDataCaseScript(dataCloneFile)
      const dataChange = dataCaseScript?.case_sections?.map((caseSection) => {
        if (caseSection?.name === title) {
          return {
            ...caseSection,
            contents: dataRef?.current?.innerHTML
          }
        }
        return caseSection
      })
      setDataCaseScript(prev => {
        return {
          ...prev,
          case_sections: dataChange,
        }
      })
      const dataClone = {
        ...dataCaseScript,
        case_sections: dataChange,
        learning_objectives: refDefault?.current?.innerHTML
      }
      const params = {
        "action_type": "save_case_script",
        ...dataClone
      }
      const filterDataChange = dataCaseScript?.case_sections?.filter((section, index) => {
        return section?.contents !== lineRefs.current[index]?.current?.innerHTML
      })
      if (filterDataChange?.length === 0 && refDefault?.current?.innerHTML === dataCaseScript?.learning_objectives) {
        return
      } else {
        apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
          if (res.status === 200) {
            setShowSaveDone(true)
            setTimeout(() => {
              setShowSaveDone(false)
            }, 2000)
          }
        });
      }
    } else {
      const dataClone = {
        ...dataCaseScript,
        learning_objectives: refDefault?.current?.innerHTML
      }
      setDataCaseScript(dataClone)
      const params = {
        "action_type": "save_case_script",
        ...dataClone
      }
      if (refDefault?.current?.innerHTML === dataCaseScript?.learning_objectives) {
        return        
      } else {
        apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
          if (res.status === 200) {
            setShowSaveDone(true)
            setTimeout(() => {
              setShowSaveDone(false)
            }, 2000)
          }
        });
      }
    }
  }

  const handleShowData = () => {
    setShowData(!showData)
  }

  const handleCopy = (e, title, dataRef) => {
    e.preventDefault()
    setShowCheckDone(true)
    const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Objectives:".concat(" \n", dataRef?.current?.innerText)
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setShowCheckDone(false)
    }, 2000)
  }

  const handleFeedback = (title, dataRef) => {
    toggleModal("modalGiveFeedback")
    const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Objectives:".concat(" \n", dataRef?.current?.innerText)
    setDataFeedback(text)
  }

  const handleCloseGuide = () => {
    if (showGuide && !dataCaseScript?.case_sections?.length) {
      setShowGuide(false)
    } else if (showStep && dataCaseScript?.case_sections?.length) {
      setShowStep(false)
    }
  } 

  return (
    <>
      {showGuide && !dataCaseScript?.case_sections?.length && (
        <ReactTooltip id={`case-${dataCaseScript?.id}`} place='top' effect='solid' className="guide-tooltip">
          <span>We've generated learning objectives based on your input. Carefully review these objectives and edit them to ensure they align with what you want to teach in this case.</span>
        </ReactTooltip>
      )}
      {showStep && index === 1 && (
        <ReactTooltip id={`step-${dataCaseScript?.id}`} place='top' effect='solid' className="guide-tooltip">
          <span>Review & modify text. Click text to edit.</span>
        </ReactTooltip>
      )}
      <div 
        className={`generateItem ${showGuide && !dataCaseScript?.case_sections?.length ? "active-item" : ""}`}
        data-tip
        data-for={`${showGuide && !dataCaseScript?.case_sections?.length ? `case-${dataCaseScript?.id}` : showStep && index === 1 ? `step-${dataCaseScript?.id}` : null}`}
        onClick={handleCloseGuide}
      >
        <div className="generateItem__header w-100">
          <div className="generateItem__header-title">
            <div className="icon-show" onClick={handleShowData}>
              {showData ? <Icon src={Up}/> : <Icon src={Down} stroke="#1D2939"/>}
            </div>
            <p className={`${showData ? "text-show" : ""}`}>{titleGenerate ? titleGenerate : 'Learning Objectives'}</p>
          </div>        
          <div className="generateItem__header-action">
            {showSaveDone && (
              <div className='d-flex align-items-center'>
                <Icon src={check} stroke="#0089C2"/>
                <span className="text-saved">Saved</span>
              </div>
            )}
            {modalOpen && (
              <WrapModalRegenerate
                idCaseScript={dataCaseScript?.id}
                setLoadingContent={setLoadingContent}
                titleGenerate={titleGenerate}
              >
                {({onOpen}) => (
                  <div 
                    data-tip
                    data-for={`generate-${index}`}
                    className="action-copy p-1" 
                    onClick={() => onOpen()}
                  >
                    <Icon src={Regenerate} stroke="#475467"/>
                  </div>
                )}
              </WrapModalRegenerate>
            )}
            <ReactTooltip id={`generate-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Re-generate This Section</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`copy-${index}`}
              className="action-copy p-1" 
              onClick={(e) => handleCopy(e, titleGenerate, dataRef)}
            >
              <Icon src={showCheckDone ? check : Copy}/>
            </div>
            <ReactTooltip id={`copy-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Copy This Section</span>
            </ReactTooltip>
            <div 
              data-tip
              data-for={`feedback-${index}`}
              className="action-copy p-1" 
              onClick={() => handleFeedback(titleGenerate, dataRef)}
            >
              <Icon src={Feedback}/>
            </div>
            <ReactTooltip id={`feedback-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Report Issues With This Section</span>
            </ReactTooltip>
          </div>
        </div>
        {showData && (
          loadingContent ? (
            <div className="generateItem w-100">
              <Skeleton height={30} width={`60%`} />
              <Skeleton height={30} width={`100%`} />
              <Skeleton height={30} width={`75%`} />
            </div>
          ) : (
            <div
              ref={dataRef}
              id="htmlContainer"
              onBlur={() => handleSave(dataRef, titleGenerate)}
              className="generateItem__content"
              contentEditable
              dangerouslySetInnerHTML={{ __html: contentGenerate?.replace(/\n/g,"<br />") || '' }}>
            </div>
          )
        )}
      </div>
      {!dataCaseScript?.case_sections && !loadingGenerateScript && (
        <div className="caseGenerate__v2">
          <p>Once you’re satisfied with the above learning objectives, <br /> click here to generate the next elements of the Case Scenario.</p>
          <div onClick={handleGenerateScript} className={`caseGenerate__v2-btn`}>
            <Icon src={stick}/>
            Generate 
          </div>
        </div>
      )}
      {loadingGenerateScript && !dataCaseScript?.case_sections?.length && 
        <div className="generateItem w-100">
          <Skeleton height={30} width={`60%`} />
          <Skeleton height={30} width={`100%`} />
          <Skeleton height={30} width={`75%`} />
        </div>
      }
    </>
  )
}

export default GenerateItem
