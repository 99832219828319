import React, { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";

//components 
import { FrameChat } from "../Chat/FreeText";

//redux
import { useDispatch, useSelector } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";
import { chatConstants } from "@constants";

//actions
import { atcCallLockElementsChat } from "@actions";

function ChatFreeText(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const {
    handleOverideEffectMenu,
    switchChat,
    messageFromVoiceToText,
    ChatMode,
    apiCallerAddChatRecord,
    handleCheckChatFeedback,
    handleOpenInstructorFeedback
  } = props && props; //handeOpenInstructionForChat, hanldeOpenInstantChatFeedback

  let intervalIDRef = React.useRef(null);

  //state
  const [isStreamDataLoading, setStreamDataLoading] = useState(false);
  const [contentStream, setStream] = useState(null);
  const messagesEndRef = useRef(null);
  const [chatRecord, setChatRecord] = useState([]);
  const [endChatData, setEndChatData] = useState({});
  // Ref for textarea
  const textareaRef = useRef(null);

  // LOAD RECORD PATIENT
  const chatRecordToApi = useSelector((state) => (state?.dataLoadRecordPatient) || []);
  const chatRecordReducer = useSelector((state) => (state?.ChatRecordFreeText?.data) || []);

  // Chỉ setState khi load page
  useEffect(() => {
    //Check Xem co render data khong ?
    setChatRecord(chatRecordToApi?.data || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get height bottom of chat - endChatData
  const divRef = useRef(null);
  const [heightFooterChat, setheightFooterChat] = useState(0);
  useEffect(() => {
    console.log(divRef, "divRef...");
    if (divRef.current) {
      // Sử dụng offsetHeight để lấy chiều cao của div
      const height = divRef.current.offsetHeight;
      setheightFooterChat(height);
    }
  }, [chatRecord]);

  // Auto-focus textarea on mount
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  //Check Xem chatRecordToApi đã render chưa ?
  useEffect(() => {
    // Nếu isRender là false thì dùng data reducer
    if (!chatRecordToApi.isRender) {
      setChatRecord(chatRecordReducer || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRecordToApi]);

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to the bottom of the chat box
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [chatRecord]);

  //Kiem tra neu ChatMode === switch_voice_text
  useEffect(() => {
    ChatMode === "switch_voice_text" && handleQuestionTypePatient(messageFromVoiceToText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFromVoiceToText]);

  // Call Api Stream
  const handleStreamData = (questionPromise) => {

    //Set Loading And Scroll bottom
    setStreamDataLoading(true);
    handleScrollBottom();

    // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([questionPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        console.log('API question returned before timeout');
        return;
      }

      console.log('API question timed out. Calling API stream_data...');
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/learn/case/stream_data/${id}/`, 'GET').then(res => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setStream(dataStream);
            handleScrollBottom();
          } else {
            console.log("error....");

            //clear stream
            clearStream();
          }
        });
      }, 1000);
    });
  }

  //Clear Stream Data
  const clearStream = () => {
    setStream(null);
    clearInterval(intervalIDRef.current);
    intervalIDRef.current = null;
  }

  //Push Data To reducer (User)
  const pushDataToReducerForUser = (Message, chatRecord) => {
    let userMessage = {
      "Channel": "Patient",
      "Role": "User",
      "AvatarURL": "",
      "Type": "message",
      "Content": Message,
      "ChatSettings": {}
    }

    if (Message) {
      let newDataUser = [...chatRecord, userMessage];
      return [newDataUser, userMessage]; // Trả về cả newData và userMessage
    }

    return [chatRecord, null]; // Trả về chatRecord và null nếu không có Message
  }

  //Push Data To reducer (Patient)
  const pushDataToReducerForPatient = (newRecord, dataResponse) => {
    const PatientResponse = dataResponse?.PatientResponse;
    const ChatSettings = dataResponse?.ChatSettings;
    let patientMessage = {
      "Channel": "Patient",
      "Role": "Patient",
      "AvatarURL": "",
      "Type": "message",
      "Content": PatientResponse,
      "ChatSettings": ChatSettings,
      "FullQuestionResponse": dataResponse
    }
    if (dataResponse?.PatientResponse) {
      let newDataPatient = [...newRecord, patientMessage];
      return [newDataPatient, patientMessage]; // Trả về cả newData và patientMessage
    }
    return [newRecord, null]; // Trả về newRecord và null n
  }

  //Push Data To reducer (Patient)
  const pushDataToReducerForInstructor = (chatRecord, InstructorResponse) => {
    let InstructorMessage = {
      "Channel": "Instructor",
      "Role": "Instructor",
      "AvatarURL": "",
      "Type": "message",
      "Content": InstructorResponse,
      "FullQuestionResponse": InstructorResponse
    }
    let newData = [...chatRecord, InstructorMessage];
    setChatRecord(newData);
  }

  //Scroll Bottom
  const handleScrollBottom = () => {
    messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
  }

  const handleQuestionTypePatient = (params) => {
    // Khóa view trước khi gọi API
    dispatch(atcCallLockElementsChat(true));
  
    const [newDataUser, userMessage] = pushDataToReducerForUser(params?.Message, chatRecord);
    setChatRecord(newDataUser);
    apiCallerAddChatRecord(userMessage);
    dispatch({ type: chatConstants.CHAT_FREE_TEXT_RECORD_REQUEST, isLoading: true });
  
    const chatPromise = apiCaller(`/api/learn/case/chat/${id}/`, 'PUT', params)
      .then((res) => {
        if (res?.status === 200) {
          const dataResponse = res?.data;
          const EndChatNow = dataResponse?.EndChatNow || null;
  
          if (EndChatNow) {
            if (EndChatNow?.Mode) {
              setEndChatData(EndChatNow);
            }
  
            if (EndChatNow?.Mode === "InstructorFeedback") {
              handleOpenInstructorFeedback(EndChatNow);
            }
  
            if (EndChatNow?.Mode === "ShowQuestion") {
              pushDataToReducerForInstructor(chatRecord, EndChatNow);
            }
          }
  
          const [newData, currentMessage] = pushDataToReducerForPatient(newDataUser, dataResponse);
          if (newData && currentMessage) {
            setChatRecord(newData);
            apiCallerAddChatRecord(currentMessage);
  
            dispatch({ type: chatConstants.CHAT_FREE_TEXT_RECORD_SUCCESS, payload: newData });
            dispatch({ type: chatConstants.IS_RENDER_RECORD_PATIENT_SUCCESS, payload: newData });
  
            setStreamDataLoading(false);
            handleScrollBottom();
            clearStream();
            dispatch(atcCallLockElementsChat(false));
  
            handleCheckChatFeedback(newData, 'FreeText');
          }
  
          return dataResponse; // Trả về kết quả thành công
        } else {
          throw new Error("API response status is not 200");
        }
      })
      .catch((error) => {
        console.error(error);
        setStreamDataLoading(false);
        dispatch({ type: chatConstants.CHAT_FREE_TEXT_RECORD_FAILURE, error });
        clearStream();
        dispatch(atcCallLockElementsChat(false));
        return Promise.reject(error); // Trả về lỗi nếu có exception
      })
      .finally(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      });
  
    handleStreamData(chatPromise);
  
    // Trả về Promise để xử lý bên ngoài nếu cần
    return chatPromise;
  };
  

  return (
    <FrameChat
      {...props}
      chatRecord={chatRecord || []}
      messagesEndRef={messagesEndRef}
      handleQuestionTypePatient={handleQuestionTypePatient}
      isStreamDataLoading={isStreamDataLoading}
      isShowForSwitch={switchChat === "freetext"}
      contentStream={contentStream}
      endChatData={endChatData}
      heightFooterChat={heightFooterChat}
      divRef={divRef}
      textareaRef={textareaRef}
    />
  );
}

export default ChatFreeText;