import React from "react";

//boostrap
import { Container, Alert } from "reactstrap";

//redux
import { useSelector } from "react-redux";

import LoaderPage from "@utils/LoaderPage";

//components
import { HeadingBox } from "@components/common";
import { ReferencesList } from "@components/references";

function ReferencesPage(props) {
  const teachCaseData = useSelector((state) => state.teachCase || []);
  const dataResponse = teachCaseData && teachCaseData.data;
  const dataReferences = dataResponse && dataResponse.References;
  return (
    <React.Fragment>
      {teachCaseData.isLoading && <LoaderPage />}
      {!teachCaseData.isLoading && (
        <div className="wrapper -referencesPage">
          <Container>
            <HeadingBox title={(dataResponse && dataResponse.Title) || ""} currentPage="References" />
            <Alert color="success">
              References are materials that you’d like to have available for
              the learners to review at any time during the case
            </Alert>
            <div className="panelBox">
              <ReferencesList data={dataReferences || []} />
            </div>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
}

export default ReferencesPage;
