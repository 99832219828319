import React from "react";

//boostrap
import { Card, CardBody, Alert } from "reactstrap";

//components
import { GroupSwitch, AccountInformation, InstitutionSettings } from '../profile';

function ProfileRight(props) {
  //props
  const { is_administrator, is_instructor } = props && props;
  const roleAdmin = is_administrator === "Yes" || is_instructor === "Yes";
  const roleInstructorOnly = is_administrator === "None" && is_instructor === "Yes";

  const instructionGroup = [
    { Name: 'A new module is assigned to me', checked: false, param: "LearnMode" }
  ]
  
  return (
    <Card className="border-0">
      <CardBody className="-box-shadow">
        <Alert
          className="m-alert--default border-0 text-center"
          color="secondary"
        >
          Click on any field to update information.
        </Alert>
        {roleAdmin &&
          <React.Fragment>
            <AccountInformation {...props} />
            <InstitutionSettings {...props} />
          </React.Fragment>
        }
        {roleInstructorOnly && (
          <InstitutionSettings {...props} />
        )}
        <GroupSwitch title={`INSTRUCTION ACCOUNT SETTINGS`} subTitle={`Email Notifications`} data={instructionGroup} />
      </CardBody>
    </Card>
  );
}

export default ProfileRight;
