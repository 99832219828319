import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//constants
import { teachConstants } from "@constants";

//apiCaller
import { apiCaller } from "@utils";

//actions
import { fetchAllQuestionsForElement } from "@actions";

//reactBoostrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

//classnames
import classnames from "classnames";

//lodash
import _ from "lodash";

//components
import {
  FlowControl,
  DomainsTab,
  AnswerTab,
  QuesionText,
  OverallExplanation,
  TableAnswerUMLS,
  TableConceptRecognition,
  ButtonShowSchema,
  TableAnswerCloze,
  TableAnswerDropdown,
  TableHighLight,
  TableAnswerFreeText,
  TableAnswerMutipleChoice,
  MutipleChoiceTable,
  SettingsTab,
  TextareaInstruction
} from "../../Question/Tabs";

//actions
import {
  atcLoadQuestionByIdFolowControl,
} from "@actions";

function ModalCreateQuestion(props) {
  //props
  const { isOpen, toggle, actions, task, domainsDefault, timePointId, isViewQuestion } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();

  //store Data
  const allQuestion = useSelector((state) => state?.loadAllQuestion?.data || []);

  const answerById = useSelector((state) => state?.listAnswerbyQuestionId?.data || []);

  //caseData from MainLearnerPage.jsx
  const editor = useRef(null);
  const [activeTab, setActive] = useState("1");
  const [isLoading, setLoading] = useState(false);

  //Question Current
  const [stateCurrentQuestion, setCurrentQuestion] = useState({});

  //editor
  const [valueEditorQuestion, setEditorQuestion] = useState("");
  const [valueEditorExplanation, setEditorExplanation] = useState("");

  //array
  const [domainData, setDomainData] = useState([]);
  const [answerData, setAnswerData] = useState([]);

  //State For Dropdown - Type
  const [stateAnswerGroups, setStateAnswerGroups] = useState([]);

  //State For Mutiple Choice Tavle - Type
  const [stateAnswerTable, setStateAnswerTable] = useState([]);

  // ----------Begin State FlowConditions
  const [stateCondition, setCondition] = useState("");
  const [flowControlOfQuestion, setFlowControlOfQuestion] = useState({
    QuestionId: '',
    Status: '',
    QuestionAnswers: [],
  });
  const [flowControlOfTopic, setFlowControlOfTopic] = useState({
    "ChatTopicId": '',
    "TopicName": '',
    "Status": ''
  });
  const [flowControlOfOrder, setFlowControlOrder] = useState({
    "OrderId": '',
    "OrderName": '',
    "Status": ''
  });
  const [flowControlOfAction, setFlowControlAction] = useState({
    "ActionId": '',
    "ActionName": '',
    "Status": ''
  });
  // ----------End State FlowConditions

  //value
  const [explanationRequired, setExplanationRequired] = useState(false);
  const [isQuestionType, setIsQuestionType] = useState("multiple_choice");
  const [qsContentCurrent, setQsContentCurrent] = useState("");
  const [stateGradingSchema, setGradingSchema] = useState("");
  const [listTemplateChoose, setListTemplateChoose] = useState([])
  const [stateGradingFields, setGradingFields] = useState([]);
  const [stateQSHighlightContent, setQuestionHighlightContent] = useState("");
  const [stateInstruction, setInstruction] = useState({
    AI_Instructions: null,
    Scoring_Instructions: null
  });

  // Feedback Text
  const [stateFeedbackText, setStateFeedbackText] = useState({
    mentioned_correct: "You mentioned the following correct items:",
    missed_correct: "You missed the following items:"
  });

  //valid
  const [validQuestionType, setValidQuestionType] = useState(false);

  //toggle
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActive(tab);
  };

  useEffect(() => {
    setQsContentCurrent("");

    //action is add
    setDomainData(setDataDomainNotSelected(domainsDefault) || []);
    setEditorQuestion("");
    setEditorExplanation("");
    setAnswerData([]);
    setStateAnswerGroups([]);
    setExplanationRequired(false);
    setIsQuestionType("multiple_choice");
    setGradingSchema("null");

    // Flow Condition
    setCondition("");
    setFlowControlOfQuestion({
      QuestionId: '',
      Status: "",
      QuestionAnswers: [],
    },);
    setFlowControlOfTopic({
      "ChatTopicId": "",
      "TopicName": "",
      "Status": "",
    });
    setFlowControlOrder({
      "OrderId": "",
      "OrderName": "",
      "Status": "",
    });
    setFlowControlAction({
      "ActionId": "",
      "ActionName": "",
      "Status": "",
    });
    // End Flow Condition

    setGradingFields([]);

    //HightLight
    setQuestionHighlightContent("");

    //mutiple choice table
    setStateAnswerTable([]);

    //Grading Instruction
    setInstruction({
      AI_Instructions: null,
      Scoring_Instructions: null
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataDomainNotSelected = (data) => {
    const newListDomain = [...data];
    newListDomain.map((value, i) => (value["Selected"] = false));
    return newListDomain;
  };

  //Add Answer
  const handleAddAnswer = () => {
    const newArr = [...answerData];
    const result = [
      ...newArr,
      {
        AnswerId: 0,
        AnswerContent: "",
        Explanation: "",
        Score: 0,
        QuestionAnswerId: null,
        isCorrect: false,
      },
    ];
    setAnswerData(result);
  };

  //Add Answer Groups - dropdown
  const handleAddAnswerGroups = () => {
    const newArr = [...stateAnswerGroups];
    const itemAnswerGroup = {
      Explanation: "",
      Score: 0,
      AnswerContent: "",
      Answers: [
        {
          AnswerContent: "",
          isCorrect: false,
        },
      ],
    };
    const result = [...newArr, itemAnswerGroup];
    setStateAnswerGroups(result);
  };

  //Handle Add Item Answer for Group
  const handleAddItemAnswerForGroup = (indexParent) => {
    const itemPush = { AnswerContent: "", isCorrect: false };
    let arrClone = [...stateAnswerGroups];
    let answersOfIndexParent = arrClone[indexParent][`Answers`];
    answersOfIndexParent.push(itemPush);
    setStateAnswerGroups(arrClone);
  };

  //Handle Change Input for Answer Groups
  const handleFormAnswerGroups = (e) => {
    const { name, value, checked } = e.target;
    const indexGroup = e?.target?.getAttribute("data-index-parent");
    const indexChild = e?.target?.getAttribute("data-index-child");
    // 1. Make a shallow copy of the items
    let items = [...stateAnswerGroups];
    if (indexGroup && indexChild) {
      if (items[indexGroup][`Answers`]?.length > 0) {
        // 2. Make a shallow copy of the item you want to mutate
        items[indexGroup][`Answers`][indexChild][name] = checked || value;
        setStateAnswerGroups(items);
      }
    } else {
      items[indexGroup][name] = value;
      setStateAnswerGroups(items);
    }
  };

  //Handle Remove Answer Group
  const handleRemoveAnswerGroup = (index) => {
    let arrClone = [...stateAnswerGroups]; // make a separate copy of the array
    _.remove(arrClone, function (e, key) {
      return key === index;
    });
    setStateAnswerGroups(arrClone);
  };

  //Handle Item Dropdown for Answer Group
  const handleRemoveItemAnswerGroup = (indexParent, indexChild) => {
    let arrClone = [...stateAnswerGroups]; // make a separate copy of the array
    let thisAnswers = arrClone[indexParent][`Answers`];
    if (thisAnswers?.length > 0) {
      _.remove(thisAnswers, function (e, key) {
        return key === indexChild;
      });
    }
    setStateAnswerGroups(arrClone);
  };

  //Handle Remove Answer
  const handleRemoveAnswer = (index) => {
    const arrClone = [...answerData];
    _.remove(arrClone, function (e, key) {
      return key === index;
    });
    setAnswerData(arrClone);
  };

  //Handle Change Input for Answer
  const handleChangeInput = (e, nameType) => {
    let arrClone = [...answerData];
    const { name, value, checked } = e.target || e;
    let indexObject = e.target && e.target.getAttribute("data-index");
    if (name === "isCorrect") {
      arrClone[indexObject][name] = checked;
    } else if (name === "typeQuestion") {
      setIsQuestionType(value);
      setValidQuestionType(false);
    } else if (nameType === "PromptTemplateIds") {
      const listTemplateIds = e.map(s => s.Id)
      setListTemplateChoose(listTemplateIds)
    } else {
      arrClone[indexObject][name] = value;
    }

    setAnswerData(arrClone);

    //disabled and set false
    if (value === "free_text") {
      setExplanationRequired(false);
    }
  };

  const handleChangeSchema = (e) => {
    const { value } = e.target;
    setGradingSchema(value);
  };

  // Handle Change Condition Type
  const handleChangeFlow = (e) => {
    const { name, value } = e.target;
    if (name === "QuestionId") {
      let newState = { ...flowControlOfQuestion };
      newState[`QuestionId`] = parseInt(value);
      setFlowControlOfQuestion(newState);

      const itemIndex = allQuestion?.findIndex((x) => x.QuestionId === Number(value));
      setQsContentCurrent(allQuestion[itemIndex]);
      if (itemIndex && name === "QuestionId") {
        //Call Api for Dropdown answer
        const params = {
          Action: "Load",
          Target: "Question",
          QuestionId: value,
        };
        dispatch(atcLoadQuestionByIdFolowControl(id, params));
      }
    } else if (name === 'TimePointId') {
      setCurrentQuestion({ ...stateCurrentQuestion, NewTimePointId: value });
    } else {
      setCurrentQuestion({ ...stateCurrentQuestion, [name]: value });
    }
  };

  //Handle Change dropdown Chat Topic
  const handleUpdateFlowControlChatTopic = (name, data) => {
    let dataClone = { ...flowControlOfTopic }
    if (name === "ChatTopicId") {
      dataClone[`ChatTopicId`] = data?.value;
      dataClone[`TopicName`] = data?.label;
    } else {
      dataClone[name] = data;
    }
    setFlowControlOfTopic(dataClone);
  };

  //Hanlde Change Dropdown Order Id
  const handleUpdateFlowControlOrderId = (name, data) => {
    let dataClone = { ...flowControlOfOrder }
    if (name === "OrderId") {
      dataClone[`OrderId`] = data?.value;
      dataClone[`OrderName`] = data?.label;
    } else {
      dataClone[name] = data;
    }
    setFlowControlOrder(dataClone);
  };

  //Handle Change For Actions
  const handleUpdateFlowControlActionId = (name, data) => {
    let dataClone = { ...flowControlOfAction }
    if (name === "ActionId") {
      dataClone[`ActionId`] = data?.value;
      dataClone[`ActionName`] = data?.label;
    } else {
      dataClone[name] = data;
    }
    setFlowControlAction(dataClone);
  }

  //mutiple answer
  const handleChangeAnswerFlowControl = (name, data) => {
    let dataClone = { ...flowControlOfQuestion }
    if (name === "QuestionAnswers") {
      const resultAnswer = _.map(data, "value");
      dataClone[`QuestionAnswers`] = resultAnswer;
    } else {
      dataClone[name] = data;
    }
    setFlowControlOfQuestion(dataClone);
  };

  //Handle Change Domain
  const handleChangeDomains = (e) => {
    const { value, checked } = e.target;
    let newArray = [...domainData];
    const itemIndex =
      newArray && newArray.findIndex((x) => x.Id === Number(value));
    newArray[itemIndex]["Selected"] = checked;
    setDomainData(newArray);
  };

  //Validate
  const checkValidate = () => {
    if (!valueEditorQuestion) {
      toast.warning("Please enter a valid Question Text", {
        autoClose: 3000,
      });
      return false;
    } else if (!isQuestionType) {
      setValidQuestionType(true);
    } else if (answerData.length === 0 && isQuestionType !== "free_text") {
      toast.warning("Choose at least one answer.", {
        autoClose: 3000,
      });
    } else if (
      flowControlOfQuestion[`QuestionAnswers`].length === 0 &&
      (flowControlOfQuestion?.Status === "with_answers_one" ||
        flowControlOfQuestion?.Status === "with_answers_all")
    ) {
      toast.warning("Choose at least one answer - Flow Control.", {
        autoClose: 3000,
      });
    } else if (checkAnswerData(answerData)) {
      toast.warning("Please enter a valid Answer Text", {
        autoClose: 3000,
      });
    } else if (checkGradingFields(stateGradingFields)) {
      toast.warning("Please enter a valid Grading Fields", {
        autoClose: 3000,
      });
    }
    else if (checkConditionTypeValid()) {
      toast.warning("Please enter a valid Flow Control", {
        autoClose: 3000,
      });
    }
    else {
      return true;
    }
  };

  //validate answer
  const checkAnswerData = (data) => {
    const result = data.some((value) => value.AnswerContent === "");
    return result;
  };

  //validate GradingFields
  const checkGradingFields = (data) => {
    const result = data.some(
      (value) => value.Name === "" || value.ValueType === ""
    );
    return result;
  };

  // Validate Condition
  const checkConditionTypeValid = () => {
    let isValid = true;
    // Validate Condition Type
    if (stateCondition === "Question") {
      isValid = false;
    }
    if (stateCondition === "Chat") {
      return false;
    }
    if (stateCondition === "Order") {
      isValid = false;
    }
    if (stateCondition === "Action") {
      isValid = false;
    }
    if (stateCondition === "") {
      isValid = false;
    }
    return isValid;
  }

  //Validate Answer Group
  const validateAnswerGroup = () => {
    let resultFilter = [];
    let cloneAnswerGroups = [...stateAnswerGroups];
    //Check Answers has AnswerContent is Null
    const filterArrHasItem = cloneAnswerGroups?.filter((item, index) => {
      let thisAnswers = item?.Answers;
      if (thisAnswers?.length > 0) {
        const newArr = thisAnswers?.filter(function (el) {
          return el?.AnswerContent !== "";
        });
        cloneAnswerGroups[index][`Answers`] = newArr;
      }
      return thisAnswers;
    });

    //Filter Answers !== Null
    if (filterArrHasItem?.length > 0) {
      resultFilter = filterArrHasItem?.filter(function (el) {
        return el?.Answers?.length > 0;
      });
    }

    return resultFilter;
  };

  //send Api with action
  const sendApiByType = (id, params) => {
    setLoading(true);
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const createActions = {
        request: teachConstants.CREATE_QUESTION_REQUEST,
        success: teachConstants.CREATE_QUESTION_SUCCESS,
        failure: teachConstants.CREATE_QUESTION_FAILURE,
      };
      if (isViewQuestion !== "elements") {
        dispatch({ type: createActions.request, isLoading: true });
      }
      if (res?.status === 200) {
        const data = res?.data;
        //Load All Question
        const params = {
          Action: "Load",
          Target: "CaseQuestions",
        };
        dispatch(fetchAllQuestionsForElement(id, params));
        if (isViewQuestion !== "elements") {
          dispatch({ type: createActions.success, payload: data });
        }

        //Close And Set Active Tab
        toggle();
        setActive("1");
        setLoading(false);
      } else {
        if (isViewQuestion !== "elements") {
          dispatch({ type: createActions.failure, isLoading: false });
        }
        toggle();
        setLoading(false);
      }
    });
  }

  //submit Data
  const handleSubmitData = () => {
    let QuestionIdByType = null;
    let stateAiInstructionText = stateInstruction?.AI_Instructions?.Text;
    let stateInstructionsText = stateInstruction?.Scoring_Instructions?.Text;
    let params = {
      Target: "Question",
      Action: "Create",
      TimePointId: stateCurrentQuestion?.NewTimePointId || timePointId,
      NewTimePointId: stateCurrentQuestion?.NewTimePointId || timePointId, //Neu chon moi timepoint khac hien tai
      Task: stateCurrentQuestion?.AskedAtTask || task,
      ShowingConditionText: stateCurrentQuestion?.ShowingConditionText || '',
      QuestionContent: valueEditorQuestion,
      QuestionExplanation: valueEditorExplanation,
      QuestionType: isQuestionType,
      Domains: domainData,
      FollowUpExplanationRequired: explanationRequired,
      GradingSchema: stateGradingSchema,
      AI_Instructions: stateAiInstructionText ? { "Text": stateAiInstructionText } : null,
      Scoring_Instructions: stateInstructionsText ? { "Text": stateInstructionsText } : null,
      FeedbackTexts: stateFeedbackText,
      PromptTemplateIds: listTemplateChoose
    };

    // Neu question type la drop_down
    if (isQuestionType === "drop_down") {
      //submit
      params["AnswerGroups"] = validateAnswerGroup();
      params["QuestionId"] = QuestionIdByType;
      params["GradingFields"] = stateGradingFields;
      sendApiByType(id, params);
    } else if (isQuestionType === "multiple_choice_table") {
      params["AnswerTable"] = stateAnswerTable;
      params["QuestionId"] = QuestionIdByType;
      params["GradingFields"] = stateGradingFields;
      sendApiByType(id, params);
    } else {
      const resultValidate = checkValidate();
      //common
      params["Answers"] = answerData;
      if (resultValidate) {
        // Update FlowConditions for Chat
        if (stateCondition === "Chat") {
          params["FlowConditions"] = [{
            ConditionType: stateCondition,
            ...flowControlOfTopic
          }]
        }

        //Update FlowConditions for Order
        if (stateCondition === "Order") {
          params["FlowConditions"] = [{
            ConditionType: stateCondition,
            ...flowControlOfOrder
          }];
        }

        //Update FlowConditions for Question
        if (stateCondition === "Question") {
          params["FlowConditions"] = [{
            ConditionType: stateCondition,
            QuestionAnswers: flowControlOfQuestion?.QuestionAnswers,
            ...flowControlOfQuestion
          }]
        }

        //Update FlowConditions for Action
        if (stateCondition === "Action") {
          params["FlowConditions"] = [{
            ConditionType: stateCondition,
            ...flowControlOfAction
          }]
        }

        params["QuestionId"] = QuestionIdByType;
        params["GradingFields"] = stateGradingFields;
        if (isQuestionType === "highlight") {
          params["QuestionHighlightContent"] = stateQSHighlightContent;
        }
        sendApiByType(id, params);
      }
    }
  };

  // Handle Change Feedback Text
  const handleChangeFeedbackText = (e) => {
    const { name, value } = e.target;
    setStateFeedbackText((prevState) => ({ ...prevState, [name]: value }));
  }

  //Add Concept CUID
  const handleUpdateConcept = (id, indexItem, type) => {
    const listDataClone = _.cloneDeep(answerData);
    if (type === "add") {
      listDataClone[indexItem][`CUID`] = id;
    } else {
      listDataClone[indexItem][`CUID`] = "";
    }
    setAnswerData(listDataClone);
  };

  // Handle change Instruction
  const handleChangeInstruction = (e) => {
    const { value, name } = e.target;
    setInstruction((prevState) => ({ ...prevState, [name]: { Text: value } }));
  }

  //tabs
  const listTabs = [
    { id: "1", name: "Question", show: true },
    { id: "2", name: "Answers", show: true },
    { id: "3", name: "Explanation", show: true },
    { id: "4", name: "Flow Control", show: true },
    { id: "5", name: "Domains", show: true },
    { id: "6", name: "Grading Fields", show: false },
    { id: "7", name: "Settings", show: isQuestionType === "free_text" },
  ];

  const renderComponentForFreeText = (isGradingSchema) => {
    let renderComponent;
    switch (isGradingSchema) {
      case "concept":
        renderComponent = (
          <TableConceptRecognition
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={isQuestionType}
            stateGradingSchema={stateGradingSchema}
            handleUpdateConcept={handleUpdateConcept}
            handleAddAnswer={handleAddAnswer}
          />)
        break;
      case "umls":
        renderComponent = (
          <TableAnswerUMLS
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={isQuestionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleUpdateConcept={handleUpdateConcept}
            handleAddAnswer={handleAddAnswer}
          />)
        break;
      case "compliance":
        renderComponent = <ButtonShowSchema {...props} itemQuestion={null} />
        break;
      case "chatgpt":
      case "chatgpt_voice":
      case "diagnosis":
      case "dialog":
        renderComponent = (
          <TextareaInstruction
            {...props}
            handleChangeInstruction={handleChangeInstruction}
            stateInstruction={stateInstruction}
          />
        );
        break;
      default:
        renderComponent = (
          <TableAnswerFreeText
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={isQuestionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleAddAnswer={handleAddAnswer}
          />
        )
    }
    return renderComponent;
  }

  const renderComponentQuestionType = (questionType, isGradingSchema) => {
    let renderComponent;
    switch (questionType) {
      case "multiple_choice":
        renderComponent = (
          <TableAnswerMutipleChoice
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={questionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleAddAnswer={handleAddAnswer}
          />
        )
        break;
      case "multiple_choice_table":
        renderComponent = (
          <MutipleChoiceTable
            answerTable={stateAnswerTable || []}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={questionType}
            handleAddAnswer={handleAddAnswer}
            handleUpdateStateAnswerTable={(currentData) => setStateAnswerTable(currentData)}
          />
        )
        break;
      case "highlight":
        renderComponent = (
          <TableHighLight
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleChanegeQuestionHighlightContent={(e) => setQuestionHighlightContent(e.target.value)}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={questionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleAddAnswer={handleAddAnswer}
            QuestionHighlightContent={stateQSHighlightContent}
          />
        )
        break;
      case "free_text":
        renderComponent = renderComponentForFreeText(isGradingSchema);
        break;
      case "drop_down":
        renderComponent = (
          <TableAnswerDropdown
            answerData={stateAnswerGroups || []}
            handleChangeInput={handleFormAnswerGroups}
            handleAddAnswerGroups={handleAddAnswerGroups}
            handleRemoveItemAnswerGroup={handleRemoveItemAnswerGroup}
            handleAddItemAnswerForGroup={handleAddItemAnswerForGroup}
            isQuestionType={questionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleRemoveAnswerGroup={handleRemoveAnswerGroup}
          />
        )
        break;
      case "drag_n_drop_cloze":
        renderComponent = (
          <TableAnswerCloze
            answerData={answerData}
            handleChangeInput={handleChangeInput}
            handleRemoveAnswer={handleRemoveAnswer}
            isQuestionType={questionType}
            stateGradingSchema={stateGradingSchema}
            itemQuestion={null}
            handleAddAnswer={handleAddAnswer}
          />
        )
        break;
      default:
        renderComponent = ""
    }
    return renderComponent;
  }

  return (
    <form>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ width: "90%", maxWidth: "1200px", position: "relative" }}
        backdrop={`static`}
      >
        <ModalHeader toggle={toggle}>
          Create Question
        </ModalHeader>
        <ModalBody>
          <div className="tabsPills">
            <Nav className="nav nav-pills nav-fill" tabs>
              {listTabs.map(
                (item, index) =>
                  item.show && (
                    <NavItem className="text-center" key={index}>
                      <NavLink
                        className={classnames({
                          active: activeTab === `${index + 1}`,
                          "--bg-primary": activeTab === `${index + 1}`,
                        })}
                        onClick={() => {
                          toggleTab(`${index + 1}`);
                        }}
                      >
                        {item.name}
                      </NavLink>
                    </NavItem>
                  )
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <QuesionText
                  refDiv={editor}
                  value={valueEditorQuestion}
                  onBlurChange={(newContent) => setEditorQuestion(newContent)}
                />
              </TabPane>
              <TabPane tabId="2">
                <AnswerTab
                  {...props}
                  validQuestionType={validQuestionType}
                  handleChangeInput={handleChangeInput}
                  isQuestionType={isQuestionType}
                  stateGradingSchema={stateGradingSchema}
                  handleChangeSchema={handleChangeSchema}
                  explanationRequired={explanationRequired}
                  onChangeExplanation={(e) =>
                    setExplanationRequired(e.target.checked)
                  }
                />
                {renderComponentQuestionType(isQuestionType, stateGradingSchema)}
              </TabPane>
              <TabPane tabId="3">
                <OverallExplanation
                  refDiv={editor}
                  value={valueEditorExplanation}
                  onBlurChange={(newContent) =>
                    setEditorExplanation(newContent)
                  }
                />
              </TabPane>
              <TabPane tabId="4">
                <FlowControl
                  {...props}
                  stateCurrentQuestion={stateCurrentQuestion}
                  qsContentCurrent={qsContentCurrent}
                  flowControlOfQuestion={flowControlOfQuestion}
                  flowControlOfOrder={flowControlOfOrder}
                  flowControlOfTopic={flowControlOfTopic}
                  flowControlOfAction={flowControlOfAction}
                  handleChange={handleChangeFlow}
                  allQuestion={allQuestion}
                  stateCondition={stateCondition}
                  answerById={answerById}
                  handleChangeAnswerFlowControl={handleChangeAnswerFlowControl}
                  handleUpdateFlowControlChatTopic={handleUpdateFlowControlChatTopic}
                  handleUpdateFlowControlOrderId={handleUpdateFlowControlOrderId}
                  handleUpdateFlowControlActionId={handleUpdateFlowControlActionId}
                  handleChangeStateCondition={(e) => {
                    setCondition(e.target.value)
                  }}
                />
              </TabPane>
              <TabPane tabId="5">
                <DomainsTab
                  domainData={(domainData && domainData) || []}
                  handleChangeDomains={handleChangeDomains}
                  actions={actions}
                />
              </TabPane>
              {isQuestionType === "free_text" && (
                <TabPane tabId="7">
                  <SettingsTab
                    {...props}
                    stateFeedbackText={stateFeedbackText}
                    handleChangeFeedbackText={handleChangeFeedbackText}
                  />
                </TabPane>
              )}
            </TabContent>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="--btn-primary" onClick={handleSubmitData} disabled={isLoading}>
            {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
            Add
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </form>
  );
}

export default ModalCreateQuestion;