export const examFindings = {
    LOAD_EXAM_FINDINGS_FOR_ELEMENTS_REQUEST: 'LOAD_EXAM_FINDINGS_FOR_ELEMENTS_REQUEST',
    LOAD_EXAM_FINDINGS_FOR_ELEMENTS_SUCCESS: 'LOAD_EXAM_FINDINGS_FOR_ELEMENTS_SUCCESS',
    LOAD_EXAM_FINDINGS_FOR_ELEMENTS_FAILURE: 'LOAD_EXAM_FINDINGS_FOR_ELEMENTS_FAILURE',

    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_REQUEST: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_REQUEST',
    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_SUCCESS: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_SUCCESS',
    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_FAILURE: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_FAILURE',

    EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_REQUEST: 'EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_REQUEST',
    EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_SUCCESS: 'EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_SUCCESS',
    EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_FAILURE: 'EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_FAILURE',

    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_REQUEST: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_REQUEST',
    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_SUCCESS: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_SUCCESS',
    UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_FAILURE: 'UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_FAILURE',

    CASE_UPDATE_EXAM_ORDER_REQUEST: 'CASE_UPDATE_EXAM_ORDER_REQUEST',
    CASE_UPDATE_EXAM_ORDER_SUCCESS: 'CASE_UPDATE_EXAM_ORDER_SUCCESS',
    CASE_UPDATE_EXAM_ORDER_FAILURE: 'CASE_UPDATE_EXAM_ORDER_FAILURE',

    TOGGLE_EXAM_MOBILE: 'TOGGLE_EXAM_MOBILE'
};