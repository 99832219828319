import React from "react";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//presentation
import { PointBubble, PatientBackground } from "../StandardScreen";

function PatientImageBackground(props) {
    const { getSizeWrap, caseData, handleOverideEffectMenu, isTask } = props && props;
    // ----------------------------------------------------------------------
    // data
    // ----------------------------------------------------------------------
    const dataResponse = caseData?.data;
    const dataClinicalStatus = dataResponse?.Case?.ClinicalStatus;
    
    // Use screen size context
    const { isMobile } = useScreenSize();
    return (
        <div className="patientPhoto" ref={getSizeWrap}>
            <PatientBackground {...props} />
            {isTask === 'exam' && !isMobile && (
                <PointBubble
                    data={dataClinicalStatus?.ExamPoints}
                    devRef={getSizeWrap}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                />
            )}
        </div>
    );
}

export default PatientImageBackground;