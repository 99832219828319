import React from 'react';

//reactstrap
import { Row, Col } from "reactstrap";

//components
import { DiagnosisList, FamilyHistoryList } from ".";

function EntitiesTab(props) {
    const { data } = props && props;
    const Diagnoses = data?.Diagnoses;
    const FamilyHistory = data[`Family History`];
    return (
        <Row>
            <Col md={6}>
                <DiagnosisList data={Diagnoses || []} />
            </Col>
            <Col md={6}>
                <FamilyHistoryList data={FamilyHistory || []} />
            </Col>
        </Row>
    );
};

export default EntitiesTab;