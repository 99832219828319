import React, { useState, useEffect } from 'react';

//components
import { HeadTabs, Accordion } from "../../storyboard/common";
import { DragQuestion } from "../../storyboard/Question";

//redux
import { useSelector } from "react-redux";

//utils
import { LoaderText } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcUpdateLabPanelAvailability, atcUpdateOneOnlyCommon } from "@actions";

//lodash
import _ from 'lodash';

//component check time
import {
    LabsTableTimePoint, //2 condition time = 0 and time >0  
    StudyTableTP,
} from "../../storyboard/timePointActions";
import { StudyTable } from "../../storyboard/Orders";

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//RichTextCommon
import RichTextCommon from "../RichTextCommon";

function OrdersMain(props) {
    //props
    const {
        value,
        activeTabCt,
        listTabs,
        handleUpdateEditor,
        valueEditor,
        timePointId,
        filterQuestionForTask,
        timepointData
    } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //store
    const labsData = useSelector((state) => state.loadLabs || []);
    const studiesData = useSelector((state) => state.loadStudies || []);
    const medicationsData = useSelector((state) => state.loadMedications || []);

    //check timpoint on load
    const dataProps = timepointData?.data;
    const isTime = dataProps?.Time;

    //state - labs
    const [sateDataLabs, setDataLabs] = useState(labsData || {});
    const [displayedLabs, setDisplayedLabs] = useState(labsData || {}); // Dùng hiển thị khi click Show Only Available (không bị sai dữ liệu)

    // stats - studies
    const [displayedStudies, setDisplayedStudies] = useState(studiesData || {}); // Dùng hiển thị khi click Show Only Available (không bị sai dữ liệu)
    const [sateDataStudies, setDataStudies] = useState(studiesData || {});
    const [availableOnly, setAvailableOnly] = useState({
        labs: false,
        studies: false
    });

    //Load Labs
    useEffect(() => {
        setDataStudies(studiesData || {});
        setDisplayedStudies(studiesData || {});
    }, [studiesData])

    //Load studies
    useEffect(() => {
        setDisplayedLabs(labsData || {});
        setDataLabs(labsData || {});
    }, [labsData])

    // Handle Click CheckBox Show Only Available (labs, studies)
    const handleShowOnlyAvailable = (e, type) => {
        const { checked } = e.target;
        setAvailableOnly({ ...availableOnly, [type]: checked });
        if (type === "labs") {
            if (checked) {
                const filteredData = {
                    Panels: _.filter(sateDataLabs?.data?.Panels, { Available: true }),
                    IndependentLabs: _.filter(sateDataLabs?.data?.IndependentLabs, { Available: true }),
                };
                setDataLabs({ ...sateDataLabs, data: filteredData });
            } else {
                // Sử dụng dữ liệu lưu state tạm 
                setDataLabs({ ...sateDataLabs, data: displayedLabs?.data });
            }
        }
        if (type === "studies") {
            if (checked) {
                const filteredData = _.filter(sateDataStudies?.data, { Available: true });
                setDataStudies(prevState => ({
                    ...prevState,
                    data: filteredData
                }));
            } else {
                console.log(displayedStudies, "displayedStudies...");
                // Sử dụng dữ liệu lưu state tạm
                setDataStudies(prevState => ({
                    ...prevState,
                    data: displayedStudies?.data
                }));
            }
        }
    }

    // Handle Update Only Available When Change Available
    const handleUpdateOnlyAvailableLabs = (panelData, IndependentLabsData) => {
        if (availableOnly?.labs) {
            setDataLabs(prevState => ({
                ...prevState.data,
                data: {
                    ...prevState.data,
                    Panels: _.filter(panelData, { Available: true }),
                    IndependentLabs: _.filter(IndependentLabsData, { Available: true })
                }
            }));
        }
    }

    //Handle Checkbox Available Labs
    const handleChangeAvailableLabs = (e, items, indexItem, typeItem) => {
        const { checked } = e.target;
        let listDataClone = _.cloneDeep(sateDataLabs?.data?.Panels || []);
        let cloneIndependentLabs = _.cloneDeep(sateDataLabs?.data?.IndependentLabs);
        let params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timePointId,
            LabAvailability: {
                Id: items?.Id,
                Type: typeItem,
                Available: checked
            },
        };

        if (typeItem === 'panel') {
            listDataClone[indexItem][`Available`] = checked
            setDataLabs(prevState => ({
                ...prevState.data,
                data: {
                    ...prevState.data,
                    Panels: listDataClone
                }
            }));
            // Update Show only available labs
            handleUpdateOnlyAvailableLabs(listDataClone, cloneIndependentLabs);
            dispatch(atcUpdateLabPanelAvailability(id, params, items?.Id));

            //Cập nhật state mới nhất khi thay đổi Available
            setDisplayedLabs(prevState => ({
                ...prevState.data,
                data: {
                    ...prevState.data,
                    Panels: listDataClone
                }
            }));
        } else {
            cloneIndependentLabs[indexItem][`Available`] = checked
            setDataLabs(prevState => ({
                ...prevState.data,
                data: {
                    ...prevState.data,
                    IndependentLabs: cloneIndependentLabs
                }
            }));
            // Update Show only available labs
            handleUpdateOnlyAvailableLabs(listDataClone, cloneIndependentLabs);
            dispatch(atcUpdateOneOnlyCommon(id, params, items?.Id));

            //Cập nhật state mới nhất khi thay đổi Available
            setDisplayedLabs(prevState => ({
                ...prevState.data,
                data: {
                    ...prevState.data,
                    IndependentLabs: cloneIndependentLabs
                }
            }));
        }
    };

    // Handle Update Only Available When Change Available
    const handleUpdateOnlyAvailableStudies = (currentData) => {
        // Khi đang check vào Show only mà người dùng click change Available
        if (availableOnly?.studies) {
            const filteredData = _.filter(currentData, { Available: true });
            setDataStudies(prevState => ({
                ...prevState,
                data: filteredData
            }));
        }
    }

    //Handle Checkbox Available Studies
    const handleChangeAvailableStudies = (e, items, typeItem) => {
        const { checked } = e.target;
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timePointId,
            StudyAvailability: {
                Id: items?.Id,
                Type: typeItem,
                Available: checked,
            },
        };
        dispatch(atcUpdateOneOnlyCommon(id, params, items?.Id));

        //Update Data
        const itemIndex = sateDataStudies?.data?.findIndex(item => item.Id === items?.Id);
        let cloneStudies = _.cloneDeep(sateDataStudies?.data);
        cloneStudies[itemIndex][`Available`] = checked
        setDataStudies(prevState => ({
            ...prevState,
            data: cloneStudies
        }));
        handleUpdateOnlyAvailableStudies(cloneStudies);

        //Cập nhật state mới nhất khi thay đổi Available
        setDisplayedLabs(prevState => ({
            ...prevState.data,
            data: cloneStudies
        }));
    };
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='orders'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.orders.map(
                    (item, index) =>
                        item.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item.id === "1" && (
                                    <React.Fragment>
                                        {sateDataLabs.isLoading && <div style={{ height: '300px' }}><LoaderText /></div>}
                                        {!sateDataLabs.isLoading && (
                                            <LabsTableTimePoint
                                                {...props}
                                                isTime={isTime}
                                                data={(sateDataLabs?.data) || []}
                                                handleShowOnlyAvailable={handleShowOnlyAvailable}
                                                availableOnly={availableOnly?.labs}
                                                handleChangeCheckbox={handleChangeAvailableLabs}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {item.id === "2" && (
                                    <React.Fragment>
                                        {isTime === 0 && (
                                            <StudyTable
                                                {...props}
                                                data={(sateDataStudies?.data) || []}
                                                handleShowOnlyAvailable={handleShowOnlyAvailable}
                                                availableOnly={availableOnly?.studies}
                                                handleChangeCheckbox={handleChangeAvailableStudies}
                                            />
                                        )}
                                        {isTime > 0 && (
                                            <StudyTableTP
                                                {...props}
                                                data={(sateDataStudies?.data) || []}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {item.id === "3" && (
                                    <Accordion
                                        {...props}
                                        categoryType={`Medication`}
                                        data={(medicationsData?.data) || []}
                                    />
                                )}
                                {item.id === "4" && (
                                    <div className="richText">
                                        <div className="richText__content mt-4">
                                            <RichTextCommon
                                                handleUpdateEditor={handleUpdateEditor}
                                                task={value?.ViewClass}
                                                defaultValue={valueEditor?.orders}
                                                placeholder="Enter the instructions for the learner here..."
                                                timePointId={timePointId}
                                            />
                                        </div>
                                    </div>
                                )}
                                {item.id === "5" && (
                                    <DragQuestion
                                        {...props}
                                        task={value.ViewClass}
                                        data={filterQuestionForTask(value.ViewClass)}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
}

export default OrdersMain;
