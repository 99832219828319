import React from 'react';

//reactstrap
import { Row, Col } from "reactstrap";

//components
import { DiagnosisList } from ".";

function StatisticsTab() {
    return (
        <Row>
            <Col md={4}> <DiagnosisList /></Col>
            <Col md={4}> <DiagnosisList /></Col>
            <Col md={4}> <DiagnosisList /></Col>
        </Row>
    );
};

export default StatisticsTab;