import React, { useEffect } from "react";

//lodash
import _ from "lodash";

function AiLabelContent(props) {

  //props
  const { rowRecord, activeCategory } = props && props;
  let idContainer = "htmlAiLabelText";

  // Load State
  useEffect(() => {
    const createStyledString = (obj) => {
      let { text, model_label, ai_label_text } = obj;
      let dataByCategory = _.filter(model_label, function(o) { return o.category === activeCategory});
      let newData = activeCategory ? dataByCategory : model_label;

      // TO KEEP TRACK OF INSERTED TEXT
      let insertedAmmount = [];
      let listDuplicate = [];
      newData?.forEach(t => {
        const locationsEl = t?.annotation?.locations;
        const colorRgb = t?.rgb;
        if (locationsEl?.length > 0) {
          locationsEl?.forEach((el) => {
            const {
              start,
              end
            } = el;

            //Loc label_text neu dung vs toa do hien tai
            let filterLabelTextByStartEnd = _.filter(ai_label_text, function (o) {
              const startText = o?.start;
              const endText = o?.end;
              return startText === start && endText === end;
            });



            //Cat chuoi label_text
            const splitStringLabelText = (label_text) => {
              let arrSplit = label_text?.split('||').join(')(');
              return arrSplit || '';
            }

            //Label
            //da loc xong va day vao 1 mang
            const tagLabelText = splitStringLabelText(filterLabelTextByStartEnd && filterLabelTextByStartEnd[0]?.label_text);

            if (!listDuplicate.includes(tagLabelText)) {
              listDuplicate.push(tagLabelText);

              // COMPUTE THE REAL START AND END POSITIONS
              // TAKING INSERTED TEXT INTO ACCOUNT
              let realStart = start
              let realEnd = end
              for (let idx in insertedAmmount) {
                if (idx < start) {
                  realStart += insertedAmmount[idx]
                }
                if (idx <= end) {
                  realEnd += insertedAmmount[idx]
                }
              }

              let pre = `<mark class="highlight-${colorRgb}" style="background-color: rgb(${colorRgb}); color: white; display: inline-block; padding: 1px 4px; border-radius: 4px; margin: 5px;">`
              let pos = `<span class="highlight-sub">${tagLabelText ? `(${tagLabelText})` : ''}</mark>`

              // UPDATE THE INFORMATION ABOUT INSERTED TEXT
              insertedAmmount[start] = (insertedAmmount[start] || 0) + pre?.length;
              insertedAmmount[end] = (insertedAmmount[end] || 0) + pos?.length;
              // DON'T INSERT DIRECTLY THE VALUE BUT THE ALREADY EXISTENT TEXT
              text = text.substring(0, realStart)
                + pre
                + text.substring(realStart, realEnd)
                + pos
                + text.substring(realEnd)
              document.getElementById(idContainer).innerHTML = text;
            }
          });
        } else { document.getElementById(idContainer).innerHTML = text; }
      });
    }

    createStyledString(rowRecord);
  }, [rowRecord, idContainer, activeCategory]);

  return (
    <div className="formatHtmlLabel" id={idContainer} />
  );
}

export default AiLabelContent;