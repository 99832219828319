import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

//boostrap
import { Card, CardBody } from "reactstrap";

//components
import { TableRows, BtnAddRole } from '../../elements/Teams/AddRemoveTable';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//apiCaller
import { apiCaller } from "@utils";

function RolesMain(props) {
    let { id } = useParams();

    //props
    const { data } = props && props;

    //state
    const [rowsData, setRowsData] = useState(data?.Roles || []);
    const [isLoading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [action, setAction] = useState('');
    const [modal, setModal] = useState({
        Delete: false
    });

    //Load Data
    useEffect(() => {
        setRowsData(data?.Roles || []);
    }, [data]);

    //Add Row
    const addTableRows = () => {
        const params = {
            "Action": "Create",
            "Target": "TeamRole"
        }
        callApi(`Create`, params);
    }

    const hanldeModal = (type, data) => {
        setModal((prevState) => ({ ...prevState, [type]: !modal[type] }))
        setCurrentData(data);
    }

    //Delete Row
    const deleteTableRows = () => {
        const params = {
            "Action": "Delete",
            "Target": "TeamRole",
            "RoleId": currentData?.TeamRoleId
        }
        callApi(`Delete`, params);
    }

    //Update Row
    const updateTableRows = (data, index) => {
        const idx = parseFloat(index);
        const params = {
            "Action": "Update",
            "Target": "TeamRole",
            "RoleId": data[idx]?.TeamRoleId,
            "RoleName": data[idx]?.TeamRoleName
        }
        callApi(`Update`, params);
        setRowsData(data);
    }

    //Call Api
    const callApi = (type, params) => {
        setLoading(true);
        //removeAction
        setAction(type);

        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res && res.status === 200) {
                const rowsData = res?.data?.TeamsInfo?.Roles;

                if (type !== `Update`) {
                    setRowsData(rowsData);
                }

                //Cloase Modal
                if (type === 'Delete') {
                    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
                }

                //reset
                setAction('');
                setLoading(false);
                setCurrentData({});
            } else {
                console.log('error');
                setLoading(false);
            }
        });
    }

    // handle Update
    const handleUpdate = (data, index) => {
        updateTableRows(data, index);
    }

    return (
        <Card className="border-0">
            <CardBody className="-box-shadow">
                <h2 className="heading-5 font-weight-500 --text-primary mt-0">Roles</h2>
                <div className="position-relative" style={{ maxWidth: '800px' }}>
                    <TableRows
                        rowsData={rowsData}
                        hanldeModal={hanldeModal}
                        disabled={isLoading}
                        action={action}
                        handleUpdate={handleUpdate}
                    />
                    <BtnAddRole
                        isLoading={isLoading}
                        addTableRows={addTableRows}
                        action={action}
                    />
                </div>
                <ModalDeleteCommon
                    title={`Are you sure you want to delete the role ${currentData?.TeamRoleName}`}
                    sub={`All team members assigned to this role will be removed from their teams. This action is not reversible.`}
                    isOpen={modal.Delete}
                    toggle={() => hanldeModal('Delete', currentData)}
                    handleRemove={deleteTableRows}
                    isLoading={isLoading}
                />
            </CardBody>
        </Card>
    );
};

export default RolesMain;