import React, { useState } from 'react';

//elements
import { ButtonDelete } from '@components/common/elements';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { deleteChatTopicInCase } from "@actions";

function ActionDelete(props) {
  //props
  const { value } = props && props;
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [modal, setModal] = useState({
    delete: false,
  });
  const [itemCurrent, setItem] = useState({});

  const toggle = (type, value) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setItem(value);
  };

  const handleRemove = () => {
    const params = {
      "Action": "Delete",
      "Target": "Chat",
      "TopicId": itemCurrent?.TopicId
    }
    dispatch(deleteChatTopicInCase(id, params));
    toggle("delete", {});
  }

  return (
    <td className="text-center align-middle">
      <ButtonDelete handleDelete={() => toggle("delete", value)} />
      <ModalDeleteCommon
        title={`Are you sure you want to remove this account from your subscription?`}
        sub={`The account will no longer have access to the contents that are limited to your subscription`}
        isOpen={modal.delete}
        toggle={() => toggle("delete", itemCurrent)}
        handleRemove={handleRemove}
      />
    </td>
  );
};

export default ActionDelete;