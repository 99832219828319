import React from 'react';

// //components
import { ProductSuggest } from "@components/AppChatSmart";
//image
import Avatar from "@images/chatsmart/avatar.svg";

function AssistantMessage(props) {
    const { value, loadingIndex, index } = props && props;
    return (
        <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img src={Avatar} alt="assistant" />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-text">
                            <div className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: value?.content }} />
                            {value?.search_items?.length > 0 && (
                                <ProductSuggest {...props} data={value?.search_items} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssistantMessage;