/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

//components 
import { FrameChatSmart, InputFreeText } from "@components/AppChatSmart";

//Helmet
import { Helmet } from "react-helmet";

//lodash
import _ from "lodash";

//useParams
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

function SmartShopPage(props) {
  let { id } = useParams();
  let intervalIDRef = React.useRef(null);

  //state
  const [stateData, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [contentStream, setStream] = useState(null);

  // Scroll to the bottom of the chat box
  useEffect(() => {
      const timeoutId = setTimeout(() => {
          messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
      }, 100);

      return () => clearTimeout(timeoutId);
  }, [stateData]);


    // Load
  useEffect(() => {
    const fetchData = () => {
      const params = {
        "action_type": "create_engage",
        "topic_id": id
      }
      setLoading(true);
      apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
        let data = res?.data;
        if (res?.status === 200) {
          setData(data);
          messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
        } else {
          console.log("error");
          setLoading(false);
        }
      });
    }
    fetchData();
  }, [])

  // Call Api Stream
  const handleStreamData = (dataPromise) => {
    const params = {
      "action_type": "get_stream_data",
      "engage_id": stateData?.engage_id
    }
    // Neu dataPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([dataPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        console.log('API question returned before timeout');
        return;
      }

      console.log('API question timed out. Calling API stream_data...');
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setStream(dataStream);
            messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
          } else {
            console.log("error....");
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        });
      }, 1000);
    });
  }

    // Submit FreeText Neu Dung View Voice (ChatMode === switch_voice_text)
    const handleInput = (value) => {
    if (value) {
      // Push Vao Mang 1 Object User Vua Nhap
      const UserMessage = {
        "role": "user",
        "content": value
      }
      let cloneData = _.cloneDeep(stateData);
      cloneData.dialog?.push(UserMessage);
      setData(cloneData);

      const params = {
        "action_type": "engage_conversation",
        "engage_id": stateData?.engage_id,
        "model": "gpt-3.5-turbo",
        "data_chat": value
      }
      setLoading(true);
      const chatPromise = apiCaller(`/api/engage/functional/`, 'POST', params).then(res => {
        let data = res?.data;
        if (res?.status === 200) {
          setData(data);

          //Scroll Bottom And Set Loading
          setLoading(false);
          messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);

          // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
          setStream(null);
          clearInterval(intervalIDRef.current);
          intervalIDRef.current = null;
        } else {
          console.log("error");
          setLoading(false);
        }
      });
      handleStreamData(chatPromise);
    }
  };

    return (
      <div className="wrapper -appChatPage">
        <Helmet>
          <title>{stateData?.topic_name}</title>
        </Helmet>
        <FrameChatSmart
          data={stateData || []}
          messagesEndRef={messagesEndRef}
          isLoading={isLoading}
          contentStream={contentStream}
        />
        {!stateData?.is_end && (
          <InputFreeText
            {...props}
            handleInput={handleInput}
          />
        )}
      </div>
    );
}

export default SmartShopPage;