import React from "react";

const ProductSuggestion = (props) => {
  const { data } = props && props;
  return (
    <div className="product-suggestion__smart wow fadeInUp animated delay-2s mt-3">
      {data?.map((product, index) => (
        <div className="product" key={index}>
          <div className="product-image">
            <img src={product.img_link} alt="Product" />
          </div>
          <div className="product-info">
            <a 
              href={product.link_product}
              target="_blank"
              rel="noopener noreferrer"
              className="product-name truncate"
            >
              {product.content}
            </a>
            <div className="product-info__footer">
              <div className="product-price">
                {product.price}
              </div>
              {/* <div className="product-discount">
                Discount: ${product.discount}
              </div> */}
              {/* <a
                className="view-product-button"
                href={product.link_product}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Product
              </a> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductSuggestion;