import React, { useState } from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { listFileAccept } from '@utils'
import { useDispatch } from "react-redux";
import { wizardConstants } from "@constants";
import HeaderCommon from './HeaderCommon'
import { FormUpload } from '@ui-partents/Form/FormUpload'

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function UploadCommon(props) {
  const { setValue, dataForm, watch } = props;
  const dispatch = useDispatch();
  const [dataFileImported, setDataFileImported] = useState(watch(dataForm?.field) || null);

  const handleActionBack = () => {
    dispatch({ type: wizardConstants.REMOVE_NEXT_STEP_REFERENCE_SUCCESS, payload: null });
    setValue(dataForm?.field, null)
  }

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon {...props} />
        <div className="form-area__upload">
          <FormUpload
            dataFileImported={dataFileImported}
            setDataFileImported={setDataFileImported}
            typeUpload="PDF, PPT, Word, TXT. Max: 10 MB."
            typeFile={["application/msword", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/pdf", "text/plain"]}
            errorFile="Only PDF, PPT, Word, TXT files are allowed."
            setValue={setValue}
            name={dataForm?.field}
          />
        </div>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon {...props} handleBack={handleActionBack} dataPass={!listFileAccept?.includes(dataFileImported?.type) || dataFileImported?.size > 10000000}/>
      </div>     
    </WrapCommon>
  )
}
