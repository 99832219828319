import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteSeries(props) {
  //props
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const handleRemove = props && props.handleRemove;

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure you want to delete this series?
        </h4>
        <p className="mt-2 text-center">
          All cases will be unassigned from this series<br/>
          The cases themselves are not deleted.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
        >
          Delete
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteSeries;
