import React, { useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useScreenSize } from '@context/ScreenSizeContext';
import { ButtonSend, TextArea, CustomIcon } from "../ChatFooter";

// Styled Components
const StyledInputFreeTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.isMobile ? "0" : "8px")};
  padding: ${(props) => (props.isMobile ? "0" : "16px 20px")};
  border-radius: ${(props) => (props.isMobile ? "8px 8px 0px 0px" : "8px")};
  border: ${(props) => (props.isMobile ? "1px solid #EAECF0" : "1px solid #e3e8ef")};
  background: #fff;
  box-shadow: ${(props) => (props.isMobile ? "none" : "0 1px 2px rgba(16, 24, 40, 0.05)")};
  flex: ${(props) => (props.isMobile ? "1 0 0" : "unset")};
  position: ${(props) => (props.isMobile ? "relative" : "unset")};
  bottom: ${(props) => (props.isMobile ? "10px" : "unset")};
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: ${({ isMobile }) => (isMobile ? "12px" : "0")};
`;

function InputFreeText(props) {
  const { handleQuestionTypePatient, disabled, caseData, valueOptions, textareaRef } = props;

  // Redux selectors
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const isChatPending = useSelector((state) => state?.isChatPending?.isLock || false);

  // State
  const [value, setValue] = useState('');

  // Screen size context
  const { isMobile } = useScreenSize();

  // Extract data from props and Redux
  const TimePointId = caseData?.data?.Case?.Timeline?.CurrentTimePointId;
  const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPatient;
  const patientReplyPlaceholder = VirtualPreceptor?.patient_reply?.Text || "Enter your statement ...";

  // Handlers
  const handleChange = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    const params = {
      Action: "Submit",
      Message: value || valueOptions,
      TimePointId,
    };
    if (value || valueOptions) {
      try {
        const resData = await handleQuestionTypePatient(params);
        if (resData) {
          setValue(''); // Clear input after success
        }
      } catch (error) {
        console.error("Error submitting free text:", error);
      }
    }
  }, [value, valueOptions, TimePointId, handleQuestionTypePatient]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent default Enter behavior
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  // Render
  return (
    <StyledInputFreeTextContainer isMobile={isMobile}>
      <TextArea
        value={value}
        handleChange={handleChange}
        handleKeyDown={handleKeyDown}
        isMobile={isMobile}
        disabled={disabled || isChatPending}
        textareaRef={textareaRef}
        placeholder={patientReplyPlaceholder}
        name="answer"
      />
      <ButtonGroup isMobile={isMobile}>
        {isMobile ? (
          <CustomIcon 
            onSubmitWIcon={handleSubmit}
          />
        ) : (
          <ButtonSend 
            handleSubmit={handleSubmit} 
            disabled={disabled || isChatPending}
          />
        )}
      </ButtonGroup>
    </StyledInputFreeTextContainer>
  );
}

export default React.memo(InputFreeText);