import React from 'react';

//boostrap
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
} from "reactstrap";

function ModalEditTitle (props) {
    const { modal, toggle, stateTitle, handleSave, handleChange } = props && props;
    return (
        <Modal
            isOpen={modal}
            toggle={toggle}
            backdrop={`static`}
        >
            <ModalHeader toggle={toggle}>Edit Title</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label className="font-weight-bold" for="CaseTitle">
                        Case Title:
                    </Label>
                    <Input
                        type="textarea"
                        name="CaseTitle"
                        onChange={handleChange}
                        id="CaseTitle"
                        defaultValue={stateTitle}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="primary"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEditTitle;