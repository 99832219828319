import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";

//react-hook-form
import { useForm } from "react-hook-form";

//logo
import imgLogo from "@images/ucr/logo.png";

//untils
import { checkAuth } from "@utils";

//boostrap
import {
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Alert,
  Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { registerConstants } from "@constants";

function FormRegister() {
  //form
  const {
    register,
    formState: { errors
    },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  let history = useHistory();
  const dispatch = useDispatch();
  const Password = useRef({});
  Password.current = watch("Password", "");

  //state
  const [tokenCaptcha, setTokenCaptcha] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style["background-color"] = "#fafbfb";
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const onsubmit = (data) => {
    const params = {
      Mode: "Register",
      Product: "ucr",
      Email: data?.Email,
      FirstName: data?.FirstName,
      LastName: data?.LastName,
      Institution: data?.Institution,
      Role: "",
      isMedicalEducator: "No",
      isAdministrator: "No",
      Password: data?.Password,
      CAPTCHAToken: tokenCaptcha,
      PrivacyPolicyVersion: "1.0",
      PlatformUseAgreementVersion: "1.0",
    }

    if (tokenCaptcha !== "") {
      setLoading(true);
      //Call Api
      apiCaller(`/api/auth/register/`, "POST", params).then((res) => {
        const data = res?.data;
        const statusRes = data?.status;
        if (res?.status === 200) {
          dispatch({ type: registerConstants.REGISTER_SUCCESS, payload: data });
          setTimeout(() => {
            //reset Data
            reset();
            setLoading(false);
            document.body.style["background-color"] = "";
            //next page
            nextPage(statusRes);
          }, 2000);
        } else {
          dispatch({
            type: registerConstants.REGISTER_FAILURE,
            error: "error",
          });
        }
      });
    };
  }

  const nextPage = (status) => {
    if (status === "confirmation_email_sent") {
      history.push("/register/email-sent");
    }
    if (status === "email_existed") {
      history.push("/register/email-existed");
    }
  };

  const onChangeCaptcha = (value) => {
    setTokenCaptcha(value);
  };

  return (
    <Col md={`12`} lg={`6`} className="p-4 bg-white mx-auto position-relative">
      <div className="text-center mb-5">
        <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
      </div>
      <Alert className="m-alert--default border-0">
        Please fill out the form below to gain access
        to the NUS Universal Clinical Reasoning demo modules
      </Alert>
      <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
        <Row>
          <Col md={`6`}>
            <FormGroup>
              <Label for="FirstName">
                First Name: <span className="m--font-danger">*</span>
              </Label>
              <Input
                {...register("FirstName", { required: true })}
                type="text"
                name="FirstName"
                placeholder="Enter your first name"
                onChange={(e) => {
                  setValue("FirstName", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                invalid={errors.FirstName?.type === "required"}
                autoComplete="off"
              />
              <FormFeedback
                invalid={(errors.FirstName?.type === "required").toString()}
              >
                This field is required.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={`6`}>
            <FormGroup>
              <Label for="LastName">
                Last Name:<span className="m--font-danger">*</span>
              </Label>
              <Input
                {...register("LastName", { required: true })}
                type="text"
                name="LastName"
                placeholder="Enter your last name"
                onChange={(e) => {
                  setValue("LastName", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                invalid={errors.LastName?.type === "required"}
                autoComplete="off"
              />
              <FormFeedback
                invalid={(errors.LastName?.type === "required").toString()}
              >
                This field is required.
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={`12`}>
            <FormGroup>
              <Label for="Email">
                Email: <span className="m--font-danger">*</span>
              </Label>
              <Input
                {...register("Email", {
                  required: "This field is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
                type="email"
                name="Email"
                placeholder="Enter your email address"
                invalid={errors.Email?.type === "required"}
                autoComplete="off"
              />
              {errors.Email?.message && (
                <FormFeedback className="d-block">
                  {errors.Email?.message}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col md={`12`}>
            <FormGroup>
              <Label for="Institution">Organization:</Label>
              <Input
                type="text"
                id="Institution"
                name="Institution"
                placeholder="Enter the name of your institution / organization here"
                onChange={(e) => {
                  setValue("Institution", e.target.value, {
                    shouldValidate: true,
                  });
                }}
                autoComplete="off"
              />
            </FormGroup>
          </Col>

          <Col sm={`12`} md={`6`}>
            <FormGroup>
              <Label for="Password">
                Password:<span className="m--font-danger">*</span>
              </Label>
              <Input
                {...register("Password", {
                  required: "You must specify a password",
                  pattern: {
                    value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/,
                    message:
                      "Password must be 8 characters long, must contain one UPPERCASE letter, must contain one lowercase letter, must contain one number",
                  },
                })}
                type="Password"
                name="Password"
                placeholder="Enter your new password"
                invalid={errors.Password?.type === "required"}
              />
              {errors.Password?.message && (
                <FormFeedback className="d-block">
                  {errors.Password?.message}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col sm={`12`} md={`6`}>
            <FormGroup>
              <Label for="Password_repeat">
                Confirm Password:
                <span className="m--font-danger">*</span>
              </Label>
              <Input
                {...register("Password_repeat", {
                  validate: (value) =>
                    value === Password.current ||
                    "The passwords do not match",
                })}
                type="password"
                name="Password_repeat"
                placeholder="Re-enter password"
              />
              {errors.Password_repeat?.message && (
                <FormFeedback className="d-block">
                  {errors.Password_repeat?.message}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
          <Col sm={`12`} className="mt-2">
            <div className="d-flex justify-content-center">
              <ReCAPTCHA
                sitekey={Config.KEY_CAPTCHA}
                onChange={onChangeCaptcha}
              />
            </div>
            {!tokenCaptcha && (
              <FormFeedback className="d-block text-center">
                This field is required.
              </FormFeedback>
            )}
          </Col>
          <Col md={`12`} className="mt-3">
            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                className="btn m-btn--pill btn-lg w-100"
                onClick={handleSubmit(onsubmit)}
                disabled={isLoading}
                style={{ minWidth: "25%" }}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Col>
  );
}

export default FormRegister;