import React from 'react';

function FeedbackBox({ title, children, icon }) {
    return (
        <div className="feedback-box-inner">
            <h4 className="feedback-box-inner__heading">
                <span className='mr-2'>{title}</span>
                {icon &&                 
                    <img src={icon} className='mr-2' alt={title} />
                }
            </h4>
            <div className="feedback-box-inner__content">
                {children}
            </div>
        </div>
    );
}

export default FeedbackBox;