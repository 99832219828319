import React, { useState, useEffect } from "react";

const TruncatedText = ({ str, maxLength }) => {
  const [truncatedString, setTruncatedString] = useState(str);

  useEffect(() => {
    if (str.length > maxLength) {
      setTruncatedString(str.substring(0, maxLength) + "...");
    } else {
      setTruncatedString(str);
    }
  }, [str, maxLength]);

  return <>{truncatedString}</>;
};

export default TruncatedText;