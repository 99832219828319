import React from "react";
import { ResetCaseButton } from '@ui-partents/Button';
// import { ModalExitCase } from '@components/common/ResetAndBack';
// import { Iconsax } from "@components-common";

function ResetCasePanel(props) {
    // const [isExitCaseOpen, setIsExitCaseOpen] = useState(false);

    // const toggleExitCase = () => {
    //     setIsExitCaseOpen(!isExitCaseOpen);
    // };

    return (
        <React.Fragment>
            <div className="d-flex align-items-center justify-content-end">
                <ResetCaseButton {...props} />
                {/* <button
                    className="btn btn-exit-case button-cicrle bg-white"
                    onClick={toggleExitCase}
                >
                    <Iconsax iconName="close" fill="#F04438" />
                </button> */}
            </div>
            {/* <ModalExitCase
                {...props}
                isOpen={isExitCaseOpen}
                toggle={toggleExitCase}
            /> */}
        </React.Fragment>
    );
}

export default ResetCasePanel;