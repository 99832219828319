import React from 'react';
import styled from 'styled-components';
import IconMoney from '@images/coin/icon-money.svg';

// Define the styled component for the bold text
const BoldText = styled.strong`
    color: #F79E1C;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.36px;
`;

const MessageContentInvite = () => {
    return (
        <div>
            By enrolling learners via email,
            <div className='d-inline-flex align-items-center mx-1'>
                <BoldText>50</BoldText>
                <img width={14} src={IconMoney} alt="20 coin" />
            </div>
            per learner will be charged when a learner starts the interactive case (unlimited case resets).
        </div>
    );
};

export default MessageContentInvite;