import React, { useState, useEffect } from "react";
import { FormGroup, Input, Label } from "reactstrap";

function AskedAtTask(props) {
    const { handleChangeAskedAtTask, timepointData, itemQuestion } = props;
    const AskedAtTask = itemQuestion?.AskedAtTask;

    // Initialize state for selected task and timeline list
    const [selectedTask, setSelectedTask] = useState(AskedAtTask || '');
    const [timeLineList, setTimeLineList] = useState([]);

    // Update timeline list when timepointData changes
    useEffect(() => {
        if (timepointData?.TaskElements) {
            setTimeLineList(timepointData.TaskElements);
        }
    }, [timepointData]);

    // Update selected task when itemQuestion changes
    useEffect(() => {
        if (itemQuestion?.AskedAtTask) {
            setSelectedTask(itemQuestion.AskedAtTask);
        }
    }, [itemQuestion]);

    // Handle change event
    const onChange = (event) => {
        const { value } = event.target;
        setSelectedTask(value);
        handleChangeAskedAtTask(value);
    };

    return (
        <FormGroup>
            <Label className="labelForm">Task</Label>
            <Input
                type="select"
                name="AskedAtTask"
                value={selectedTask}
                onChange={onChange}
            >
                {timeLineList.map((value, index) => (
                    <option value={value.ViewClass} key={index}>{value.Label}</option>
                ))}
            </Input>
        </FormGroup>
    );
}

export default AskedAtTask;