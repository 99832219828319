import React, { useState } from "react";

//pdfView
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
};

const PdfViewer = (props) => {
  //props
  const { url } = props && props;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };

  return (
    <div className="reactPdfContainer">
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={options}
      >
        <Page pageNumber={pageNumber} />
        <div className="page-controls">
          <button
            disabled={pageNumber <= 1}
            aria-label="Previous page"
            onClick={previousPage}
          >
            <i className="fa fa-angle-left" aria-hidden="true"></i>
          </button>
          <span>
            {pageNumber} of {numPages}
          </span>
          <button
            disabled={pageNumber >= numPages}
            aria-label="Next page"
            onClick={nextPage}
          >
            <i className="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </div>
      </Document>
    </div>
  );
};

export default PdfViewer;
