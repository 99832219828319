import React, { useContext } from 'react'
import { WrapMcqBank } from '@components/teachV2/teachMcqBank'
import { TableLibrary } from '@components/teachV2/teachScriptLibrary'
import { InteractiveWrap } from '@components/teachV2/interactiveModule';
import { TeachPresentationLibrary } from '@components/teachV2/teachPresentationLibrary';
import { EngageLibrary } from '@components/teachV2/engage';
import UserContext from '@context/UserContext';
import { Icon } from '@components/common';

const InventoryWrap = (props) => {
  const { tabs, handleTabChange, activeTab } = props
  const context = useContext(UserContext);
  return (
    <div className='inventory-wrap'>
      <div className='inventory-wrap__tabs'>
        {tabs?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <Icon src={tab?.icon}/>
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <WrapMcqBank />
        )}
        {activeTab === 2 && (
          <TableLibrary />
        )} 
        {activeTab === 3 && (
          <InteractiveWrap />
        )} 
        {activeTab === 4 && (
          <TeachPresentationLibrary />
        )} 
        {activeTab === 5 && context.privilege === "alpha" && (
          <EngageLibrary />
        )} 
        {/* <TableInventory /> */}
      </div>
    </div>
  )
}

export default InventoryWrap
