import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

function ButtonNext(props) {
    const history = useHistory();

    const handleNextView = () => {
        if (props.feedbackData?.NextCaseId) {
            history.push(`/learn/case/${props.feedbackData.NextCaseId}`);
        }
    };

    return (
        <div className="button-next ml-3" style={{ minWidth: '142px' }}>
            <Button
                color="primary"
                size='lg'
                className='w-100'
                onClick={handleNextView}
            >
                Proceed to Next Case
            </Button>
        </div>
    )
}

export default ButtonNext;