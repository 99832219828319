import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { LabsContent } from "@components/elements";
import { HeadingBox } from "@components/common";

//actions
import { loadLabsForElement } from "@actions";

function LabsPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "Labs",
      };
      dispatch(loadLabsForElement(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  // store Labs
  const labStore = useSelector(
    (state) => state?.listLabsForElement || {}
  );

  return (
    <div className="wrapper -eLabsPage">
      <section className="labsPageCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Labs" />
          <LabsContent dataStore={(labStore && labStore) || {}} />
        </Container>
      </section>
    </div>
  );
}

export default LabsPage;