import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//components 
import { FrameChat, ListAsk } from "../Chat/ChatList";

//apiCaller
import { apiCaller } from "@utils";

function ChatClick(props) {
  //props
  const { handleOverideEffectMenu } = props && props;

  let { id } = useParams();
  const responseChatData = props?.data?.ChatData;
  const listAsk = responseChatData?.AvailableStandardMessages;
  const listRecords = responseChatData?.ChatRecords;
  const [stateListAsk, setListAsk] = useState([]);
  const [chatRecord, setChatrecord] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Scroll to the bottom of the chat box
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
  }, 100);

    return () => clearTimeout(timeoutId);
  }, [chatRecord]);

  //init data
  useEffect(() => {
    setListAsk(listAsk || []);
    setChatrecord(listRecords || []);
  }, [listAsk, listRecords]);

  //Handle CLick Question
  const handleClickAsk = (params, indexToDelete) => {
    // User Message - Push Object UserMessage vao mang
    const UserMessage = {
      "UserMessage": params?.Message,
      "ChatStatus": "user_input"
    }
    let newData = [...chatRecord, UserMessage];
    setChatrecord(newData);
    setLoading(true);

    // Remove Item Khoi List
    const filteredArr = stateListAsk?.filter((value, index) => {
      return index !== indexToDelete;
    });
    setListAsk(filteredArr);
    apiCaller(`/api/learn/case/chat/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        if (messagesEndRef && messagesEndRef?.current) {
          let newData = [...chatRecord];
          newData.push(data);
          setChatrecord(newData);

          //Scroll Bottom And Set Loading
          setLoading(false);
          messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
        }
      } else {
        console.log('error')
        setLoading(false);
      }
    });
  };

  return (
    <React.Fragment>
      <ListAsk
        {...props}
        isLoading={isLoading}
        dataListAsk={stateListAsk || []}
        handleClickAsk={handleClickAsk}
        responseChatData={responseChatData}
        chatRecord={chatRecord || []}
      />
      <FrameChat
        {...props}
        dataChatRecords={chatRecord || []}
        messagesEndRef={messagesEndRef}
        isLoading={isLoading}
        responseChatData={responseChatData}
      />
    </React.Fragment>
  );
}

export default ChatClick;