import React, { useState } from 'react';
import styled from 'styled-components';
import MultiSelect from './MultiSelect';

const InputWrapperContainerWrapper = styled.div`
    height: auto;
    display: flex;
    flex-direction: column; // Update to column to stack the MultiSelect component below input
    width: 100%;
`;

const InputWrapper = styled.div`
    border-radius: 8px 8px 0px 0px;
    background: #F5F5F5;
    box-shadow: 0px -6px 24px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px 12px;

    .input-typing-box {
        display: flex;
        width: 100%;
        position: relative;
        background-color: #F5F5F5;

        &:before {
            content: '';
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-image: url('images/learnerv2/icon-send-sp.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        textarea {
            border-radius: 8px;
            border: 1px solid #EAECF0;
            background: #FFF;
            color: #9AA4B2;
            font-size: 14px;
            font-weight: 400;
            padding: 16px 12px;
            width: 100%;
            min-height: 56px;
        }
    }
`;

const InputWrapperContainer = React.forwardRef((props, ref) => {
    const [selectedText, setSelectedText] = useState('');
    return (
        <InputWrapperContainerWrapper ref={ref}>
            <InputWrapper className='chat-form-typing'>
                <MultiSelect setSelectedText={setSelectedText} multiple={true} />
                {/* Set multiple={false} for single select mode */}
                <div className="input-typing-box">
                    <textarea
                        placeholder="Enter your question to the patient..."
                        value={selectedText}
                        onChange={(e) => setSelectedText(e.target.value)}
                    />
                </div>
            </InputWrapper>
        </InputWrapperContainerWrapper>
    );
});

export default InputWrapperContainer;