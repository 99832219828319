import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'reactstrap';

//components
import { ModalMCQ } from "./MCQ";

//icon
import { Iconsax } from "@components-common";

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  padding: 12px;
`;

const DropdownItem = styled.div`
  color: black;
  padding: 6px 12px;
  text-decoration: none;
  display: block;
  text-align: left;
  min-width: 240px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const AddQuestionButton = ({ handleActionView }) => {

    //state
    const [showDropdown, setShowDropdown] = useState(false);
    const [modal, setModal] = useState(false);
    const dropdownRef = useRef(null);

    // Handle Toggle Dropdown
    const handleToggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Handle Toggle Modal
    const toggleModal = () => {
        setModal(!modal);
    };

    // Handle Click Outside
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    // Handle Select Item
    const handleSelectItem = (type) => {
        if (type === 'AI_Generated_MCQ') {
            toggleModal();
        } else {
            handleActionView(type);
        }
        setShowDropdown(false); // Optionally close the dropdown after selection
    };

    // Handle Click Outside
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="text-right">
            <DropdownWrapper ref={dropdownRef}>
                <Button className="d-flex align-items-center mb-3" color="primary" onClick={handleToggleDropdown}>
                    <Iconsax iconName="plus-square" fill="#fff" size={15} />
                    <span className="ml-2">Add Question</span>
                </Button>
                <DropdownContent show={showDropdown}>
                    <DropdownItem onClick={() => handleSelectItem('Blank_Question')}>Blank question</DropdownItem>
                    <DropdownItem onClick={() => handleSelectItem('AI_Generated_MCQ')}>AI-generated MCQ</DropdownItem>
                </DropdownContent>
            </DropdownWrapper>
            <ModalMCQ
                isOpen={modal}
                toggleModal={toggleModal}
            />
        </div>
    );
};

export default AddQuestionButton;