import React from 'react';

//components
import {
    TheadTable,
    HeaderInterventions,
    InterventionsBody
} from '../Interventions';

function TableInterventions(props) {
    const {
        index,
        indexCount,
        active,
        value,
        listTimepointLast,
    } = props && props;
    return (
        <div
            key={index}
            id={"sect-" + indexCount(index)}
            className={
                active === index
                    ? "accordionExam__content accordionExam__open"
                    : "accordionExam__content accordionExam__close"
            }
        >
            <HeaderInterventions
                {...props}
                index={index}
                value={value}
            />
            <table className="table tableMedications mb-0">
                <TheadTable
                    listTimepointLast={listTimepointLast}
                />
                <InterventionsBody
                    {...props}
                    dataInterventions={value?.Interventions || []}
                    listTimepointLast={listTimepointLast}
                    value={value}
                />
            </table>
        </div>
    );
};

export default TableInterventions;