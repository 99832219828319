import React from 'react';

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//lodash
import _ from "lodash";

//components
import {
    InputFreeText,
    UserMessage,
    PatientMessage
} from '../../Chat/FreeText';

//loading
import { LoadingMessage } from "../FreeText";

function Framechat(props) {
    const { dataChatRecords, messagesEndRef, responseChatData, isLoading } = props && props;
    const ChatMaxQuestionsAllowed = responseChatData?.ChatMaxQuestionsAllowed;
    const totalPatient = _.filter(dataChatRecords, ['ChatStatus', 'patient']);
    return (
        <div className="frameChat">
            <div className={`frameChat__fullBox p-1`}>
                <Scrollbar style={{ height: 400, overflowY: 'auto' }} ref={messagesEndRef}>
                    <div className="messengerCt pr-0">
                        <div className="messengerCt__messages">
                            {dataChatRecords?.map((value, index) => (
                                <React.Fragment key={index}>
                                    {(value?.UserMessage || value?.ChatStatus === "user_input") && (
                                        <UserMessage {...props} value={value} />
                                    )}

                                    {(value?.ChatStatus === "patient" && value?.PatientResponse) ? (
                                        <PatientMessage
                                            {...props}
                                            index={index}
                                            value={value}
                                        />
                                    ) : dataChatRecords?.length - 1 === index && isLoading && <LoadingMessage {...props} />}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </Scrollbar>
                <InputFreeText
                    {...props}
                    disabled={ChatMaxQuestionsAllowed === totalPatient?.length}
                />
            </div>
        </div>
    );
};

export default Framechat;