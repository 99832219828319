import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";

//react router
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//reactstrap
import { Badge } from "reactstrap";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//call Action
import { atcRemoveChat } from "@actions";

//Modal
import { ModalDeleteClick, ModalUpdateChatClick } from "../Modal";

function ChatTableClick(props) {
  const columns = [
    {
      dataField: "TopicId",
      text: "TopicId",
      headerClasses: "font-weight-500 --text-primary",
      hidden: true,
    },
    {
      dataField: "TopicName",
      text: "Topic name",
      headerClasses: "font-weight-500 --text-primary",
      formatter: (cell, row) => {
        const Domains = row?.Domains;
        const ellipsify = (str) => {
          if (str.length > 10) {
            return str.substring(0, 10) + "...";
          } else {
            return str;
          }
        };
        return (
          <div className="d-flex flex-column mt-2">
            <p>{cell}</p>
            <div className="d-flex mt-2">
              {Domains?.map((value, i) =>
                value.Selected === true && (
                  <Badge
                    className="py-2 mr-1"
                    color="info"
                    data-tip={value.Name}
                    key={i}
                  >
                    {ellipsify(value.Name)}
                  </Badge>
                )
              )}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "ChatQuestion",
      text: "Question text",
      headerClasses: "font-weight-500 --text-primary",
    },
    {
      dataField: "ChatResponse",
      text: "Response text",
      headerClasses: "font-weight-500 --text-primary",
    },
    {
      dataField: "actions",
      text: "",
      headerClasses: "font-weight-500 --text-primary",
      style: {
        width: "120px",
        textAlign: "center",
      },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <ButtonEdit handleEdit={() => toggleUpdate(row, "edit")} />
            <ButtonDelete handleDelete={() => toggle(row)} />
          </React.Fragment>
        );
      },
    },
  ];

  let { id } = useParams();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [itemCurrent, setItemCurrent] = useState({});
  const [type, setType] = useState({});

  //props
  const { timePointId } = props && props;

  const toggle = (data) => {
    setModal(!modal);
    setItemCurrent(data);
  };

  const toggleUpdate = (data, type) => {
    setModalUpdate(!modalUpdate);
    setItemCurrent(data);
    setType(type);
  };

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "Chat",
      TimePointId: timePointId,
      TopicId: itemCurrent.TopicId || "",
    };
    dispatch(atcRemoveChat(id, params));
    setModal(false);
  };

  // get store data
  const chatData = useSelector((state) => (state?.loadChat?.data) || []);
  return (
    <div className="ChatTab position-relative">
      <BootstrapTable
        wrapperClasses="table-responsive"
        keyField="TopicId"
        data={chatData || []}
        columns={columns}
        bordered={false}
      />
      <ModalUpdateChatClick
        isOpen={modalUpdate}
        toggle={toggleUpdate}
        type={type}
        item={itemCurrent}
        timePointId={timePointId}
      />
      <ModalDeleteClick
        isOpen={modal}
        toggle={toggle}
        handleRemove={handleRemove}
      />
      <button
        className="btn btn-brand d-block mx-auto"
        onClick={() => toggleUpdate({}, "add")}
      >
        <i className="fa fa-plus mr-2"></i>Add Chat Topic
      </button>
    </div>
  );
}

export default ChatTableClick;