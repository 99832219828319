import React from "react";

//boostrap
import { Progress } from "reactstrap";

function ProgressBar (props) {
  const { value } = props && props;
  return (
    <div className="progressCt mt-sm-3 mt-md-0 justify-content-md-end bg-white p-0">
      <div className="progressCt__left">
        <span className="font-weight-500 text-2">Progress:</span>
      </div>
      <div className="progressCt__right">
        <Progress multi>
          <Progress bar striped value={value}>
            {value}%
          </Progress>
        </Progress>
      </div>
    </div>
  );
}

export default ProgressBar;