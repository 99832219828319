import React, { useEffect, useState } from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function ModalIdentifiedProblems(props) {
    //props
    const { isOpen, toggle, caseData } = props && props;

    const resCaseData = caseData?.data;
    const caseDetail = resCaseData?.Case;
    const Documentation = caseDetail?.Documentation;
    const FeedbackData = Documentation?.Feedback;

    //state
    const [stateFeedback, setFeedback] = useState(FeedbackData || "");

    //load Data
    useEffect(() => {
        const resCaseData = caseData?.data;
        const caseDetail = resCaseData?.Case;
        const Documentation = caseDetail?.Documentation;
        const FeedbackData = Documentation?.Feedback;
        setFeedback(FeedbackData || "");
    }, [caseData]);

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop={`static`}
            zIndex={16000}
            size={'lg'}
        >
            <ModalHeader toggle={toggle}>Identified Problems</ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: stateFeedback || "" }} />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={toggle}
                >
                    Close
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalIdentifiedProblems;