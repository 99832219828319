import React from 'react'

export default function ProgressStep(props) {
  const { listStep, step } = props
  return (
    <ul className="progress-step">
      {listStep?.map((flow, index) => (
        <li key={index} className={`${index + 1 < step ? "active-progress" : ""} ${index + 1 <= step ? "active-point" : ""}`}>
          <span>{flow?.StepName}</span>
        </li>
      ))}
    </ul>
  )
}
