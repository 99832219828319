import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

function HeadTabs(props) {
  const { listTabs, toggleTabCt, activeTabCt, title, task } = props && props;
  return (
    <React.Fragment>
      {title &&
        <h2 className="heading-2 -heading-primary --text-primary">
          {title}
        </h2>
      }
      <Nav className="tabBorder" tabs>
        {listTabs && listTabs[task]?.map(
          (item, index) =>
            item.show && (
              <NavItem key={index}>
                <NavLink
                  className={classnames({
                    active: activeTabCt === `${index + 1}`,
                    "--text-primary": activeTabCt === `${index + 1}`,
                    "--tab-active-text-primary": activeTabCt === `${index + 1}`,
                  })}
                  onClick={() => {
                    toggleTabCt(`${index + 1}`);
                  }}
                >
                  {item.name}
                </NavLink>
              </NavItem>
            )
        )}
      </Nav>
    </React.Fragment>
  );
}

export default HeadTabs;
