import React from 'react';

//lodash
import _ from 'lodash';

function ChangeModeFormat(props) {
    const { DefaultChange, row, TimePointDataLast } = props && props;
    const ChangeMode = DefaultChange?.ChangeMode;
    const ChangeLimit = DefaultChange?.ChangeLimit;
    const ChangeValue = DefaultChange?.ChangeValue;
    const isLastTimePoint = TimePointDataLast?.Time !== 0 ;

    let ChangeModeStyle = '';
    let logicCharacter = '';
    
    switch (ChangeMode) {
    case 'decrease':
        ChangeModeStyle = `decrease by ${ChangeValue}`
        break;
    case 'increase':
        ChangeModeStyle = `increase by ${ChangeValue}`
        break;
    case 'set':
        ChangeModeStyle = `set at ${ChangeValue}`
        break;
    case 'none':
        ChangeModeStyle = "-"
        break;
    default:
        ChangeModeStyle = "-"
    }
    
    switch (ChangeMode) {
        case 'decrease':
            logicCharacter = `<p class="text-muted">(but stay >= ${ChangeLimit} ${row?.Unit})</p>`
            break;
        case 'increase':
            logicCharacter = `<p class="text-muted">(but stay <= ${ChangeLimit} ${row?.Unit})</p>`
            break;
        default:
            logicCharacter = ""
    }
    const eleChangeMode = (ChangeMode === 'none' || _.isEmpty(ChangeMode)) ? '' : row?.Unit;
    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: isLastTimePoint ? `${ChangeModeStyle} ${eleChangeMode}` : `${ChangeModeStyle}` }} />
            <div dangerouslySetInnerHTML={{ __html: logicCharacter }} />
        </React.Fragment>
    );
};

export default ChangeModeFormat;