import React, { useState, useEffect } from "react";

//reactstrap
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

//useParams
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

// constants
import { storyboardConstants } from "@constants";

//apiCaller
import { apiCaller } from "@utils";

//lodash
import _ from 'lodash';

function DropDownGenerate(props) {
    const { data, caseData, timePointId } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [stateData, setData] = useState(data || {});
    const [dropdownOpen, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    // Load Case
    useEffect(() => {
        setData(data || {});
    }, [data])

    // Toggle Dropdown
    const toggleDropdown = () => setOpen(!dropdownOpen);

    // Handle Click Item
    const handleExtractLabs = () => {
        // Filter Available la true
        const filteredData = {
            Panels: _.filter(stateData?.Panels, { Available: true }),
            IndependentLabs: _.filter(stateData?.IndependentLabs, { Available: true }),
        };

        filteredData?.Panels?.forEach((panel) => {
            panel.Items = _.filter(stateData?.Panels.Items, { Available: true });
        });

        const params = {
            "action_type": "extract_labs_values",
            "case_draft": caseData?.CaseDraft,
            "labs_values": filteredData,
            "timepoint_id": timePointId,
            "case_id": id
        }
        setLoading(true);
        dispatch({ type: storyboardConstants.EXTRACT_LABS_REQUEST, isLoading: true });
        apiCaller(`/api/medicalcase/functional/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoading(false);
                dispatch({ type: storyboardConstants.EXTRACT_LABS_SUCCESS, payload: data });
            } else {
                console.log("error");
                setLoading(false);
                dispatch({ type: storyboardConstants.EXTRACT_LABS_FAILURE, error: 'error' });
            }
        });
    }

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle className="--bg-primary" caret disabled={isLoading}>
                {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Generate
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem onClick={handleExtractLabs}>
                    From Case Draft
                </DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
}

export default DropDownGenerate;