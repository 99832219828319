import React, { useState } from "react";
import { Icon } from '@components/common'
import Close from '@images/teachV2/close.svg'
import Feedback from '@images/teachV2/feedback-form.svg'
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";
import Success from '@images/login/success.svg'

//Boostrap
import { Button, Input, Label, Modal, ModalBody } from "reactstrap";

function ModalFeedback(props) {
  const { isOpen, toggle, type, dataFeedback, idFeedback } = props && props;
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false)
  
  //form
  const {
    handleSubmit,
    watch,
    setValue
  } = useForm();
  
  const onSubmit = (data) => {
    setLoading(true);
    const params = {
      "Contents": data.feedback,
      "ObjectType": type,
      "ObjectId": idFeedback || null,
      "ObjectData": dataFeedback,
      "Type": "user_feedback",
      "PrivacyMode": "private"
    }
    apiCaller(`/api/comment/submit/`, 'POST', params).then(res => {
      if (res.status === 200) {
        setLoading(false);
        setIsSuccess(true)
      }
    });
  }

  const handleToggle = () => {
    setValue("feedback", '');
    setIsSuccess(false)
    toggle()
  }


  return (
    <Modal className="modal-feedback" isOpen={isOpen} toggle={handleToggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="feedback__header" onClick={handleToggle}>
          <Icon src={Close}/>
        </div>
        {isSuccess ? (
          <div className="feedback__result">
            <Icon src={Success}/>
            <div className="feedback__result-content">
              <h1>Feedback Submitted Successfully</h1>
              <p>Thank you for your insightful feedback! We appreciate your input and will use it to enhance our platform.</p>
            </div>
          </div>
        ) : (
          <>
            <div className="feedback__content">
              <div className="feedback__content-info">
                <Icon src={Feedback} />
                <div className="info-feedback">
                  <h1 className="info-feedback__title">We Value Your Feedback</h1>
                  <p className="info-feedback__text">Your feedback is invaluable as we strive to improve our platform <br /> to better meet your needs. Thank you for your time!</p>
                </div>
              </div>
              <div className="feedback__content-form">
                <Label htmlFor="feedback">Tell Us What You Think</Label>
                <Input
                  type="textarea"
                  name="feedback"
                  placeholder="Use this space to share what you like, suggest improvements, report issues, request new features, or provide any other comments you have."
                  onChange={(e) => {
                    setValue("feedback", e.target.value);
                  }}
                  defaultValue={watch("feedback")}
                />
              </div>          
            </div>
            <div className="feedback__action">
              <Button className="btn btn-secondary" onClick={handleToggle}>
                Cancel
              </Button>{" "}
              <Button
                color="primary"
                className="btn d-flex align-items-center"
                onClick={handleSubmit(onSubmit)}
                disabled={isLoading || !watch("feedback")}
              >
                Submit Feedback
                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default ModalFeedback;