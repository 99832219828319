import { balanceConstants } from "@constants";
import {apiCaller} from "@utils";

// CCase - Update Timepoint - IO
export const actUpdateValueBalance = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
    function request(isLoading) {
      return { type: balanceConstants.UPDATE_VALUE_BALANCE_REQUEST, isLoading };
    }
    function success(data) {
      return { type: balanceConstants.UPDATE_VALUE_BALANCE_SUCCESS, payload: data };
    }
    function failure(error) {
      return { type: balanceConstants.UPDATE_VALUE_BALANCE_FAILURE, error: error };
    }
  };
};

// Case - Delete IO ITem
export const actDeleteBalanceId = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: balanceConstants.DELETE_IO_BALANCE_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: balanceConstants.DELETE_IO_BALANCE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: balanceConstants.DELETE_IO_BALANCE_FAILURE,
      error: error,
    };
  }
};

//Case - Create IO Item
export const actCreateIOItem = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: balanceConstants.ADD_IO_BALANCE_REQUEST, isLoading };
  }
  function success(data) {
    return {
      type: balanceConstants.ADD_IO_BALANCE_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: balanceConstants.ADD_IO_BALANCE_FAILURE,
      error: error,
    };
  }
};