import React, { useState } from "react";

//boostrap
import { Container, Row, Col, Button } from "reactstrap";

//components
import { MainTabsProposal } from "../reliProposal";

function PdfContent(props) {
    const { handleBackList } = props && props;
    const [activeIndex] = useState(1);

    return (
        <Container className="-box-shadow bg-white p-4">
            <Row>
                <Col className="text-right mb-4" md={12}>
                    <Button
                        color="primary"
                        className="btn btn-primary mb-3"
                        onClick={handleBackList}
                    >
                        <span className="fa fa-arrow-left mr-2"></span>
                        Back To List
                    </Button>
                </Col>
                <Col md={12}>
                    <MainTabsProposal
                        {...props}
                        pageActive={activeIndex}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default PdfContent;