import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { ChatMain } from "@components/elements";
import { HeadingBox } from "@components/common";

//actions
import { loadChatTopicsAllTimepoints } from "@actions";

function ChatPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "Chat",
      };
      dispatch(loadChatTopicsAllTimepoints(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //storeChat
  const storeChat = useSelector(
    (state) => state?.loadChatTopics || {}
  );

  const listData = storeChat?.data;
  return (
    <div className="wrapper -eChatPage">
      <section className="eChatCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Chat" />
          <ChatMain storeChat={storeChat} data={(listData && listData) || []} />
        </Container>
      </section>
    </div>
  );
}

export default ChatPage;