import React from 'react';

function HeaderInterventions(props) {
    const { value, toggleCategory, toggleIntervention } = props && props;
    return (
        <React.Fragment>
            <div className="d-flex align-items-center mb-3">
                <button
                    className="btn btn-danger"
                    onClick={() => toggleCategory("deleteCategory", value)}
                >
                    Delete
                </button>
                <button
                    className="btn btn-brand d-block ml-auto"
                    onClick={() =>
                        toggleIntervention("add", value, value.CategoryId)
                    }
                >
                    <i className="fa fa-plus"></i> Add {value.Name}
                </button>
            </div>
            <p className="mb-3 --text-primary">(*) score applies only to this time point</p>
        </React.Fragment>
    );
};

export default HeaderInterventions;