import React from "react";
//reactstrap
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

function ChangeMode(props) {
  const { handleChange, handleSave, data, indexPanel, indexItem, valuePanel} = props && props;
  const ChangeMode =
    (data.DefaultChange && data.DefaultChange.ChangeMode) || '';
  const ChangeValue =
    (data.DefaultChange && data.DefaultChange.ChangeValue) || '';
  const ChangeLimit =
    (data.DefaultChange && data.DefaultChange.ChangeLimit) || '';
  return (
    <td>
      <FormGroup>
        <Input
          type="select"
          data-name={ChangeMode}
          value={ChangeMode}
          onChange={(e) => handleChange(e, indexPanel, data.Id)}
          name="ChangeMode"
        >
          <option value="none">No Change</option>
          <option value="set">Set Value At ...</option>
          <option value="increase">Increase Value By ...</option>
          <option value="decrease">Decrease Value By ...</option>
        </Input>
      </FormGroup>
      {(ChangeMode === "set" ||
        ChangeMode === "increase" ||
        ChangeMode === "decrease") && (
        <React.Fragment>
          <InputGroup>
            <Input
              type="text"
              name="ChangeValue"
              defaultValue={ChangeValue || ""}
              placeholder="Value"
              onChange={(e) => handleChange(e, indexPanel, data.Id)}
              autoComplete="off"
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.UnitAlias,
                  }}
                ></div>
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          <div className="m--font-info font-weight-500 text-right mt-2">
            {ChangeMode === "increase" && "But Not Larger Than"}
            {ChangeMode === "decrease" && "But Not Smaller Than"}
          </div>
        </React.Fragment>
      )}
      {(ChangeMode === "increase" || ChangeMode === "decrease") && (
        <InputGroup className="mt-3">
          <Input
            type="text"
            name="ChangeLimit"
            defaultValue={ChangeLimit || ""}
            placeholder="Limit"
            onChange={(e) => handleChange(e, indexPanel, data.Id)}
            autoComplete="off"
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.UnitAlias,
                }}
              ></div>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      )}
      {ChangeMode !== "none" &&
        <button
          className="btn btn-sm btn-info button_edit_item mt-3 d-block mx-auto"
          onClick={() => handleSave(valuePanel, indexItem)}
        >
          <i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>
          Save
        </button>
      }
    </td>
  );
}

export default ChangeMode;
