import React, { useState, useEffect } from "react";

//Modal
import {
  ModalEditLabItem
} from "../storyboard/Modal";

//utils
import { LoaderText } from "@utils";

//lodash
import _ from "lodash";

//components
import { TableLabs, LabsTop } from "./Labs";

function LabsContent(props) {
  //props 
  const { dataStore } = props && props;

  //state
  const [modalItem, setModalItem] = useState(false);
  const [actions, setActions] = useState(false);
  const [itemEdit, setItemEdit] = useState({});
  const [labPanelId, setLabPanelId] = useState({});
  const [searchText, setSearchText] = useState("");
  const [isSwitch, setSwitch] = useState("Value");
  const [stateTimePoint, setTimePoint] = useState({});
  const [dataLabs, setDataLabs] = useState(dataStore?.data || []);

  useEffect(() => {
    setDataLabs(dataStore?.data || []);
  }, [dataStore]);

  //Modal edit lab item
  const toggleItem = (itemPanel, TimePointData, item, type) => {
    setLabPanelId(itemPanel?.Id); //Lab
    setItemEdit(item);
    setActions(type);
    setModalItem(!modalItem);
    setTimePoint(TimePointData);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase();
    if (lowercasedValue === "") {
      setDataLabs(dataStore?.data);
    } else {
      const dataNewClone = _.cloneDeep(dataStore?.data);
      _.map(dataNewClone, function (n, index) {
        const panelCurrent = dataNewClone[index][`Panels`];

        //Filter IndependentLabs
        if (n?.IndependentLabs?.length > 0) {
          dataNewClone[index]['IndependentLabs'] = n?.IndependentLabs.filter(({ Name }) => Name.toLowerCase().includes(lowercasedValue));
        }

        //Filter Panels
        if (panelCurrent && panelCurrent.length > 0) {
          _.map(panelCurrent, function (val, indexVal) {
            if (val?.Items.length > 0) {
              panelCurrent[indexVal][`Items`] = val?.Items.filter(({ Name }) => Name.toLowerCase().includes(lowercasedValue));
            }
          })
        }
      })
      setDataLabs(dataNewClone);
    }
  }

  const handleSwitchsActive = (value) => {
    setSwitch(value);
  }

  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    filterData(searchTerm);
  };

  return (
    <div className="boxPortlet position-relative">
      <LabsTop
        {...props}
        onSearch={onSearch}
        searchText={searchText}
        handleSwitchsActive={handleSwitchsActive}
        isSwitch={isSwitch}
      />

      {dataStore?.isLoading && (
        <div style={{ height: '400px' }}>
          <LoaderText />
        </div>
      )}

      <TableLabs
        {...props}
        dataLabs={dataLabs}
        typeGroup={`Panels`}
        handleEdit={toggleItem}
        isSwitch={isSwitch}
      />

      <TableLabs
        {...props}
        dataLabs={dataLabs}
        typeGroup={`IndependentLabs`}
        handleEdit={toggleItem}
        isSwitch={isSwitch}
      />

      {/* Modal Update And Create ITem */}
      <ModalEditLabItem
        {...props}
        isOpen={modalItem}
        data={itemEdit}
        toggle={toggleItem}
        actions={actions}
        labPanelId={labPanelId}
        timePointId={stateTimePoint?.TimepointId}
      />
    </div>
  );
}

export default LabsContent;