import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//router
import { useParams } from "react-router-dom";

//Actions
import { atcDeleteLabPanel, atcDeleteLabItem } from "@actions";

function ModalDeleteLab(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const labsData = useSelector((state) => state.loadLabs || []);

  //props
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const actions = props && props.actions;
  const dataProps = props && props.data;

  const handleRemove = () => {
    const timePointId = props && props.timePointId;
    const panelId = dataProps && dataProps.Id;
    // Remove Lab Panel
    if (actions === "delete-panel") {
      const params = {
        Action: "Delete",
        Target: "LabPanel",
        TimePointId: timePointId,
        LabPanelId: String(panelId),
      };
      dispatch(atcDeleteLabPanel(id, params));
      if (!labsData.isLoading) {
        props && props.toggle();
      }
    }

    // Remove Item
    if (actions === "delete-item") {
      const itemId = dataProps && dataProps.Id;
      const params = {
        Action: "Delete",
        Target: "LabItem",
        TimePointId: timePointId,
        LabItemId: itemId,
      };
      dispatch(atcDeleteLabItem(id, params));
      if (!labsData.isLoading) {
        props && props.toggle();
      }
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that you would like <br /> to delete this{" "}
          {actions === "delete-panel" ? "panel" : "item"}?
        </h4>
        <p className="mt-2 text-center">This action is irreversible.</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
          disabled={labsData && labsData.isLoading}
        >
          {labsData && labsData.isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Delete {actions === "delete-panel" ? "panel" : "item"}
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteLab;
