import { wizardConstants } from "@constants";
import { apiCaller } from "@utils";

//GET Case
export const loadWizard = (params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/wizard/${params}/`, 'GET').then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: wizardConstants.LOAD_WIZARD_REQUEST, isLoading } }
  function success(data) { return { type: wizardConstants.LOAD_WIZARD_SUCCESS, payload: data } }
  function failure(error) { return { type: wizardConstants.LOAD_WIZARD_FAILURE, error: error } }
}

export const loadNextStepWizard = (params, id, type) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/wizard/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: wizardConstants.LOAD_NEXT_STEP_WIZARD_REQUEST, isLoading } }
  function success(data) { return { type: wizardConstants.LOAD_NEXT_STEP_WIZARD_SUCCESS, payload: data, params: type} }
  function failure(error) { return { type: wizardConstants.LOAD_NEXT_STEP_WIZARD_FAILURE, error: error } }
}

export const fluxImageGeneration = (params, id) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/binh/openai/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: wizardConstants.IMAGE_GENERATION_REQUEST, isLoading } }
  function success(data) { return { type: wizardConstants.IMAGE_GENERATION_SUCCESS, payload: data } }
  function failure(error) { return { type: wizardConstants.IMAGE_GENERATION_FAILURE, error: error } }
}
