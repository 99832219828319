import React from 'react';

//components
import { TableOption, ButtonAddOption } from '../Options';

function ProblemAccordionChild(props) {
    const {
        index,
        indexCount,
        active
    } = props && props;

    return (
        <div
            key={index}
            id={"sect-" + indexCount(index)}
            className={
                active === index
                    ? "accordionExam__content accordionExam__open"
                    : "accordionExam__content accordionExam__close"
            }
        >
            <TableOption
                {...props}
            />
            <ButtonAddOption
                {...props}
            />
        </div>
    );
};

export default ProblemAccordionChild;