import React from "react";

//common
import { DataEmty } from "@components-common";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//reactstrap
import { LoaderText } from "@utils";

// Import the pagination configuration
import {paginationOptions} from "@utils";

function TableData(props) {
    const { isLoading } = props && props;
    const { analyzeReviews } = props && props;
    const table_data = analyzeReviews?.table_data || [];
    const columns = [
        {
            dataField: "log_id",
            text: "#",
            headerClasses: "align-middle font-weight-500 text-center",
            classes: "align-middle font-weight-500 text-center",
            width: "5%"
        },
        {
            dataField: "input",
            text: "Input",
            headerClasses: "align-middle font-weight-500",
            formatter: (value, row) => <div className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: value }} />,
            style: {
                width: "35%"
            },
        },
        {
            dataField: "ai_output",
            text: "AI Output",
            headerClasses: "align-middle font-weight-500",
            formatter: (value, row) => <div className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: value }} />,
            style: {
                width: "35%"
            },
        },
        {
            dataField: "reviewer_output",
            text: "Reviewer Output",
            headerClasses: "align-middle font-weight-500",
            formatter: (value, row) => <div className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: value }} />,
            style: {
                width: "35%"
            },
        },
    ];

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <div style={{ height: 'calc(100vh - 350px)', overflowY: 'auto' }}>
                <ToolkitProvider
                    keyField="log_id"
                    data={table_data}
                    columns={columns}
                    search
                >
                    {(toolkitprops) => (
                        <BootstrapTable
                            condensed
                            wrapperClasses="table-responsive"
                            noDataIndication={() => (
                                <div className="py-5">
                                    <DataEmty content={"No Data"} />
                                </div>
                            )}
                            {...paginationTableProps}
                            {...toolkitprops.baseProps}
                        />
                    )}
                </ToolkitProvider>
            </div>
            {/* /Table */}
            <div className="pagination-custom-case d-flex justify-content-center mt-4">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    const totalSize = table_data?.length || 0; // Dynamically calculate the total size
  
    // Use the paginationOptions function to get the configuration
    const paginationConfig = paginationOptions({ totalSize });
    return (
        <div className="position-relative">
            {isLoading && <div style={{ height: '400px' }}><LoaderText /></div>}
            {!isLoading && table_data?.length > 0 && (
                <PaginationProvider pagination={paginationFactory(paginationConfig)}>
                    {contentTable}
                </PaginationProvider>
            )}
        </div>
    );
}

export default TableData;