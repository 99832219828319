import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

// Styled Components
const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tag = styled.div`
  border-radius: 9999px;
  border: 1px solid ${({ color }) => {
        switch (color) {
            case 'green':
                return '#ABEFC6';
            case 'blue':
                return '#B2DDFF';
            default:
                return '#B3D4FF';
        }
    }};
  background: ${({ color }) => {
        switch (color) {
            case 'green':
                return '#ECFDF3';
            case 'blue':
                return '#EFF8FF';
            default:
                return '#E3F2FF';
        }
    }};
  color: ${({ color }) => {
        switch (color) {
            case 'green':
                return '#067647';
            case 'blue':
                return '#2E90FA';
            default:
                return '#0052CC';
        }
    }};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  margin-left: ${({ hasMargin }) => (hasMargin ? '4px' : '0')};
  min-width: 44px;
  text-align: center;
`;

const PlusTag = styled(Tag)`
  display: flex;
  min-width: 22px;
  align-items: center;
  gap: 4px;
  border-radius: 120px;
  border: 1px solid #F2F4F7;
  background: #F7F8FA;
  color: #475467;
`;

const TagsToolTip = ({ tags = [], documentId, color = 'green' }) => {
    // Ensure tags is always an array
    const tagList = Array.isArray(tags) ? tags : [];

    if (tagList.length === 0) return null;

    return (
        <TagsWrapper>
            <Tag color={color}>
                {tagList[0]}
            </Tag>
            {tagList.length > 1 && (
                <PlusTag hasMargin data-tip data-for={`tag-${documentId}`}>
                    +{tagList.length - 1}
                    <ReactTooltip id={`tag-${documentId}`} place="top" effect="solid" className="tooltip-tags">
                        <div className="list-tags">
                            {tagList.slice(1).map((tag, index) => (
                                <span key={index}>{tag}</span>
                            ))}
                        </div>
                    </ReactTooltip>
                </PlusTag>
            )}
        </TagsWrapper>
    );
};

export default TagsToolTip;