import React from "react";

//components
import { TrialPeriods }  from "@components/access";

//common
import { HeadingBox } from "@components/common";

//reactstrap
import { Container } from "reactstrap";

function TrialPeriodsPage(props) {
  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -trialPeriodsPage">
      <div className="learnersCt">
        <Container>
          <HeadingBox title={titlePage || ''} currentPage="Trial Periods" />
          <TrialPeriods />
        </Container>
      </div>
    </div>
  );
}

export default TrialPeriodsPage;
