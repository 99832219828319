import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'; // for validation schema
import { FormGroup, Label, Row, Col, Button, FormFeedback, Input } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import Select from 'react-select';

// react-router-dom
import { useParams } from "react-router-dom";

// common
import { Iconsax } from '@components-common';

// elements
import { ModalDeleteCommon } from "@ui-partents/Modal";

// utils
import { apiCaller } from "@utils";

// toast
import { toast } from 'react-toastify';

const ScoringInstruction = (props) => {
  const { timepointData, isActive, caseDetails } = props && props;
  const Domains = caseDetails?.Domains;

  // params
  const { id } = useParams();

  // validation schema
  const validationSchema = Yup.array().of(
    Yup.object().shape({
      Name: Yup.string().required('Dimension Name is required'),
      ScoringInstruction: Yup.string().required('Scoring Instruction is required'),
      MaxScore: Yup.number().required('Max Score is required').positive('Max Score must be positive'),
      Domains: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(),
          label: Yup.string().required()
        })
      ).min(1, 'At least one domain is required')
    })
  );

  // state
  const [formDataList, setFormDataList] = useState([]);
  const [originalFormDataList, setOriginalFormDataList] = useState([]);
  const [modal, setModal] = useState({
    delete: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Define fetchData function
  const fetchData = async () => {
    try {
      const params = {
        "Action": "Load",
        "TimePointId": timepointData?.Id,
        "Target": "ChatScoring"
      };
      const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
      if (res?.status === 200) {
        const data = res?.data?.ChatScoringData || [];
        // Convert domain IDs to { value, label } format
        const formattedData = data.map(item => ({
          ...item,
          Domains: item.Domains.map(domainId => {
            const domain = Domains.find(d => d.Id === domainId);
            return domain ? { value: domain.Id, label: domain.Name } : null;
          }).filter(Boolean)
        }));
        setFormDataList(formattedData);
        setOriginalFormDataList(formattedData);
      } else {
        console.log("Error fetching data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // load API data
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const handleAddDimension = () => {
    setFormDataList([...formDataList, {
      Name: '',
      ScoringInstruction: '',
      Domains: [],
      MaxScore: '',
    }]);
  };

  const handleRemoveDimension = () => {
    if (selectedIndex !== null) {
      // Filter out the item at the selected index
      const updatedFormDataList = formDataList.filter((_, index) => index !== selectedIndex);
      // Update state with the new array
      setFormDataList(updatedFormDataList);
      setSelectedIndex(null);
      // Close the modal
      toggle('delete', null);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // Convert Domains to array of IDs
    const formattedValues = values.map(item => ({
      ...item,
      Domains: item.Domains.map(domain => domain.value)
    }));

    // Perform API call to submit data
    const params = {
      "Action": "Update",
      "TimePointId": timepointData?.Id,
      "Target": "ChatScoring",
      "ChatScoringData": formattedValues
    }

    setSubmitting(true);
    const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
    if (res?.status === 200) {
      toast.success(`Data update successfully !`, {
        position: "top-right"
      });
      setSubmitting(false);
    } else {
      console.log("Error updating data!");
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setFormDataList(originalFormDataList);
    fetchData(); // Call fetchData to get the latest data from API
  };

  // Toggle modal
  const toggle = (type, index) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    if (type === 'delete') {
      setSelectedIndex(index);
    }
  };

  // Render form
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '44px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '44px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '44px',
    }),
  };

  return (
    <section className={`form-details`}>
      <Formik
        initialValues={formDataList}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false} // Disable validation on blur
        enableReinitialize
      >
        {({ values, errors, isSubmitting, setFieldValue, touched }) => {
          return (
            <Form>
              {formDataList.map((formData, index) => (
                <div className="card-area bg-grey mb-4" key={index}>
                  <FormGroup>
                    <Label className='labeForm' htmlFor={`Name-${index}`}>Dimension Name</Label>
                    <Field
                      type='text'
                      name={`formDataList[${index}].Name`}
                      id={`Name-${index}`}
                      placeholder='Enter dimension name'
                      minRows={1}
                      value={values[index]?.Name || ''}
                      invalid={touched[index]?.Name && !!errors[index]?.Name} // Show error only if field is touched
                      as={Input}
                      onChange={(e) => {
                        const newValues = [...formDataList];
                        newValues[index] = { ...newValues[index], Name: e.target.value };
                        setFormDataList(newValues);
                        setFieldValue(`formDataList[${index}].Name`, e.target.value);
                      }}
                    />
                    {touched[index]?.Name && errors[index]?.Name && ( // Show error only if field is touched
                      <FormFeedback className="d-block">{errors[index].Name}</FormFeedback>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label className='labeForm' htmlFor={`ScoringInstruction-${index}`}>Scoring Instruction</Label>
                    <Field
                      component={TextareaAutosize}
                      name={`formDataList[${index}].ScoringInstruction`}
                      id={`ScoringInstruction-${index}`}
                      className={`w-100 form-control ${touched[index]?.ScoringInstruction && errors[index]?.ScoringInstruction ? 'is-invalid' : ''}`}
                      minRows={5}
                      placeholder='Write something here...'
                      value={values[index]?.ScoringInstruction || ''}
                      invalid={touched[index]?.ScoringInstruction && !!errors[index]?.ScoringInstruction} // Show error only if field is touched
                      onChange={(e) => {
                        const newValues = [...formDataList];
                        newValues[index] = { ...newValues[index], ScoringInstruction: e.target.value };
                        setFormDataList(newValues);
                        setFieldValue(`formDataList[${index}].ScoringInstruction`, e.target.value);
                      }}
                    />
                    {touched[index]?.ScoringInstruction && errors[index]?.ScoringInstruction && ( // Show error only if field is touched
                      <FormFeedback className="d-block">{errors[index].ScoringInstruction}</FormFeedback>
                    )}
                  </FormGroup>

                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label className='labeForm' htmlFor={`Domains-${index}`}>Domain</Label>
                        <Field
                          component={Select}
                          className='form-select'
                          name={`formDataList[${index}].Domains`}
                          id={`Domains-${index}`}
                          value={values[index]?.Domains || []}
                          onChange={(selectedOptions) => {
                            const newValues = [...formDataList];
                            newValues[index].Domains = selectedOptions;
                            setFormDataList(newValues);
                            setFieldValue(`formDataList[${index}].Domains`, selectedOptions);
                          }}
                          options={Domains.map(domain => ({ value: domain.Id, label: domain.Name }))}
                          styles={customStyles}
                          isMulti
                        />
                        {touched[index]?.Domains && errors[index]?.Domains && ( // Show error only if field is touched
                          <div className="text-danger">{errors[index].Domains}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label className='labeForm' htmlFor={`MaxScore-${index}`}>Max Score</Label>
                        <Field
                          type='number'
                          name={`formDataList[${index}].MaxScore`}
                          id={`MaxScore-${index}`}
                          value={values[index]?.MaxScore || ''}
                          invalid={touched[index]?.MaxScore && !!errors[index]?.MaxScore} // Show error only if field is touched
                          as={Input}
                          onChange={(e) => {
                            const newValues = [...formDataList];
                            newValues[index] = { ...newValues[index], MaxScore: e.target.value };
                            setFormDataList(newValues);
                            setFieldValue(`formDataList[${index}].MaxScore`, e.target.value);
                          }}
                          placeholder='Enter max score'
                        />
                        {touched[index]?.MaxScore && errors[index]?.MaxScore && ( // Show error only if field is touched
                          <FormFeedback className="d-block">{errors[index].MaxScore}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="btn-close-box" onClick={() => toggle('delete', index)}>
                    <Iconsax iconName={`close-box`} fill="#323949" size={20} />
                  </div>
                </div>
              ))}

              <div className="card-area bg-grey p-2 mb-4">
                <div className="btn-add-item --border-blue --size-sm text-center m-0" onClick={handleAddDimension}>
                  <Iconsax iconName="plus" fill="#0089C2" size={15} />
                  <span className="ml-2 btn-add-item__text --text-blue">Add new scoring dimension</span>
                </div>
              </div>

              <div className='mt-4 text-right'>
                <Button
                  color="secondary"
                  className="px-5 mr-2"
                  onClick={handleCancel}
                  size='lg'
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>

                <Button
                  color="primary"
                  className="mr-2 px-5"
                  type="submit"
                  size='lg'
                  disabled={isSubmitting}
                >
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-2"></span>}
                  Save
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
      <ModalDeleteCommon
        title={`Delete Scoring Dimension`}
        sub={`Are you sure that you would like to delete scoring instruction ? This action cannot be undone.`}
        isOpen={modal.delete}
        toggle={() => toggle('delete', null)} // Fixed toggle function call
        handleRemove={handleRemoveDimension}
      />
    </section>
  );
};

export default ScoringInstruction;