import React, { useState } from 'react';
import styled from 'styled-components';

//Tooltip
import { TitleTooltipInfo } from "@components/patterns/UI/Tooltip";
import { InfoTooltip } from '@ui-partents/Tooltip';

// Style cho container của sidebar
const NavSidebar = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

// Style cho từng item trong sidebar
const NavSidebarItem = styled.li`
  padding: 10px 8px;
  border-radius: 8px;
  background: ${(props) => (props.isActive ? '#F0F9FC' : 'transparent')};
  
  &:hover {
    cursor: pointer;
    background-color: #F0F9FC;
  }
`;

// Style cho nội dung liên kết
const RefLink = styled.span`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ArrowIcon = ({ color = "#101828", width = 14, height = 14 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
    <path
      d="M5.25 10.5L8.75 7L5.25 3.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const truncateString = (str, maxLength) => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)}...`;
  }
  return str;
};

const AvailableCohorts = ({ cohortData, loadCohortsById, cohortObject }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <React.Fragment>
      <TitleTooltipInfo
        title="Available Cohorts"
        description="Cohorts allow you to group users together for easy assignment of reviews or cases. Select a cohort to view and manage its members."
        sizeIcon={20}
        widthTooltip={520}
        textSizeTooltop={12}
        textAlginContent={`center`}
      />
      <NavSidebar>
        {cohortData?.length > 0 && cohortData.map((values, index) => {
          return (
            <NavSidebarItem
              data-id={values.CohortId}
              key={index}
              onClick={() => loadCohortsById(values)}
              isActive={cohortObject?.CohortId === values.CohortId}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              id={`SelectTooltip-${index}`}
            >
              <RefLink>
                {truncateString(values.Name, 30)}
                <ArrowIcon />
              </RefLink>
              {hoveredIndex === index && (
                <InfoTooltip
                  id={`SelectTooltip-${index}`}
                  isOpen={hoveredIndex === index}
                  content={values.Name}
                />
              )}
            </NavSidebarItem>
          );
        })}
      </NavSidebar>
    </React.Fragment>
  );
};

export default AvailableCohorts;