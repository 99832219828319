import React from 'react';

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//components
import {
    InputFreeText,
    UserMessage,
    BotMessage,
    LoadingMessage
} from '../ChatFreeText';

function Framechat(props) {
    const { dataChatRecords, messageEl, isLoading, contentStream } = props && props;
    return (
        <div className={`frameChat__fullBox border p-3`}>
            <Scrollbar style={{ height: 400 }} ref={messageEl}>
                <div className="messengerCt">
                    <div className="messengerCt__messages">
                        {dataChatRecords?.map((value, index) => (
                            <React.Fragment key={index}>
                                {value?.role === "user" && (
                                    <UserMessage {...props} value={value} />
                                )}

                                {value?.role === "assistant" || (contentStream && isLoading) ? (
                                    <BotMessage
                                        {...props}
                                        index={index}
                                        value={value || contentStream}
                                    />
                                ) : dataChatRecords?.length - 1 === index && isLoading && <LoadingMessage {...props} />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </Scrollbar>
            <InputFreeText
                {...props}
            />
        </div>
    );
};

export default Framechat;