// MgmtCohortsPage.jsx
import React, { useEffect } from 'react';

//call Action
import { atcLoadAllCohorts, actFetchAccountInstitutionRequest } from "@actions";

//redux
import { useDispatch } from "react-redux";

//components
import { Cohorts } from "@components/teachV2/Manage/Tabs";

const MgmtCohortsPage = (props) => {
  const dispatch = useDispatch();

  //load Data
  useEffect(() => {
    const fetchData = async () => {
      // Load Cohorts
      dispatch(atcLoadAllCohorts());

      //Load Account Institution
      dispatch(actFetchAccountInstitutionRequest());
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='wrapper MgmtCohortsPage'>
      <Cohorts {...props} />
    </div>
  );
};

export default MgmtCohortsPage;