export const associatedSchemaConstant = {
    LOAD_ASSOCIATED_SCHEMA_REQUEST: 'LOAD_ASSOCIATED_SCHEMA_REQUEST',
    LOAD_ASSOCIATED_SCHEMA_SUCCESS: 'LOAD_ASSOCIATED_SCHEMA_SUCCESS',
    LOAD_ASSOCIATED_SCHEMA_FAILURE: 'LOAD_ASSOCIATED_SCHEMA_FAILURE',

    ADD_ASSOCIATED_SCHEMA_REQUEST: 'ADD_ASSOCIATED_SCHEMA_REQUEST',
    ADD_ASSOCIATED_SCHEMA_SUCCESS: 'ADD_ASSOCIATED_SCHEMA_SUCCESS',
    ADD_ASSOCIATED_SCHEMA_FAILURE: 'ADD_ASSOCIATED_SCHEMA_FAILURE',

    ORDER_ASSOCIATED_SCHEMA_REQUEST: 'ORDER_ASSOCIATED_SCHEMA_REQUEST',
    ORDER_ASSOCIATED_SCHEMA_SUCCESS: 'ORDER_ASSOCIATED_SCHEMA_SUCCESS',
    ORDER_ASSOCIATED_SCHEMA_FAILURE: 'ORDER_ASSOCIATED_SCHEMA_FAILURE',

    DELETE_ASSOCIATED_SCHEMA_REQUEST: 'DELETE_ASSOCIATED_SCHEMA_REQUEST',
    DELETE_ASSOCIATED_SCHEMA_SUCCESS: 'DELETE_ASSOCIATED_SCHEMA_SUCCESS',
    DELETE_ASSOCIATED_SCHEMA_FAILURE: 'DELETE_ASSOCIATED_SCHEMA_FAILURE',
};