import React from "react";
import styled, { keyframes } from "styled-components";
import { Button } from "reactstrap";

//images
import IconAllowMicrophone from "@images/access-mic/allow-mic.svg";

// Animation khi mở modal
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Overlay toàn màn hình
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 17000;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

// Modal hiển thị nội dung
const Modal = styled.div`
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 20px;
  background: #fff;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s ease-in-out;
  max-width: 422px;
  position: relative;
`;

// Close button
const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover svg path {
    stroke: #000;
  }
`;

// Title
const Title = styled.h1`
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.48px;
`;

// Content
const Content = styled.p`
  color: #1D2939;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;

  svg {
    margin-left: 4px;
    vertical-align: middle;
  }
`;

// Hình ảnh
const Image = styled.img`
  width: 81px;
  height: 81px;
  margin-bottom: 16px;
`;

// Button group
const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const AllowMicrophone = ({ isOpen, onClose, onAllow }) => {
  return (
    <Overlay isVisible={isOpen}>
      <Modal isVisible={isOpen}>
        <CloseButton onClick={onClose} aria-label="Close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M13 1L1 13"
              stroke="#9AA4B2"
              strokeWidth="1.875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 13L1 1"
              stroke="#9AA4B2"
              strokeWidth="1.875"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </CloseButton>
        <Image src={IconAllowMicrophone} alt="Allow Microphone" />
        <Title>Allow Microphone</Title>
        <Content>
          To use voice input, press <strong>Allow</strong> to give access to your microphone. Audio
          is only captured when
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#E9F3FF" />
            <circle cx="12.3712" cy="12.629" r="9.02062" fill="#2268F0" />
            <rect
              x="10.5864"
              y="7.9873"
              width="2.93312"
              height="4.94474"
              rx="1.46656"
              fill="white"
            />
            <rect
              x="11.2281"
              y="15.5947"
              width="1.46656"
              height="1.52146"
              rx="0.733281"
              fill="white"
            />
            <path
              d="M14.8488 11.4102C14.8488 13.0907 13.5356 14.4531 11.9157 14.4531C10.2958 14.4531 8.98254 13.0907 8.98254 11.4102"
              stroke="white"
              strokeWidth="0.832048"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          button is pressed.
        </Content>
        <ButtonGroup>
          <Button className="px-4" color="secondary" onClick={onClose}>
            Not Now
          </Button>
          <Button className="px-4" color="primary" onClick={onAllow}>
            Allow
          </Button>
        </ButtonGroup>
      </Modal>
    </Overlay>
  );
};

export default AllowMicrophone;