import React from "react";
import { TitleNoteCommon } from "../../home/generate";

//partents
import { FormModal } from "@ui-partents/Modal";

const ViewAnalysis = ({ isOpen, toggleModal, title, dataAnalyze, dataAnalysis }) => {

	return (
		<FormModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			title={title}
			maxWidth="700px"
			hiddenIcon={true}
			hiddenButtonCancel={true}
			classCustom="modal-create-analysis"
		>
			<form className="form-create-analysis">
        <div className='input-form'>
          <TitleNoteCommon title="Question Text"/>
          <p className="question-text">{dataAnalyze?.Contents?.question}</p>
        </div>
        <hr />
        <div className='input-form pb-3'>
          <TitleNoteCommon title="Rubrics"/>
          <div dangerouslySetInnerHTML={{ __html: dataAnalysis?.Rubric }} />
        </div>
      </form>
		</FormModal>
	);
};

export default ViewAnalysis;