import React, { useState, useEffect, useCallback } from "react";

//components
import {
  ModalAddLearner,
  ModalDeleteCohorts,
  ModalAddCohort,
  ModalDeleteLearner,
  ModalEditCohort,
} from "../Modal";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";

//actions
import { atcLoadCohortsById, loadAuthCodeListById } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { CohortMain, ButtonAddCohort } from "../Cohorts"; //TabsContainer, AuthTab

//Layout
import { GridContainer, AvailableCohorts, CloseIcon } from "@components/teachV2/Manage/Cohorts";

function Cohorts(props) {
  //load cohort  from store
  const cohortStore = useSelector((state) => state?.listCohort || {});
  const dataCohort = cohortStore?.data;

  //state
  const [cohortData, setCohortData] = useState((dataCohort && dataCohort) || []);
  const [cohortObject, setcohortObject] = useState({});
  const [itemLearner, setItemLearner] = useState({});
  const dispatch = useDispatch();

  //onLoad
  useEffect(() => {
    setCohortData((dataCohort && dataCohort) || []);
  }, [dataCohort]);

  const [modal, setModal] = useState({
    addCohort: false,
    addLearner: false,
    editCohort: false,
    deleteCohort: false,
    deleteLearner: false,
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  const columns = [
    {
      dataField: "firstname",
      text: "First Name",
      headerClasses: "align-middle text-left font-weight-500 text-nowrap",
    },
    {
      dataField: "lastname",
      text: "Last Name",
      headerClasses: "align-middle text-left font-weight-500 text-nowrap",
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: "align-middle text-left font-weight-500 text-nowrap",
      formatter: (cell, row) => {
        return (
          <div style={{ wordWrap: 'break-word', maxWidth: '200px' }}>{cell}</div>
        );
      },
    },
    {
      dataField: "Actions",
      text: "Action",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      style: {
        width: "10%",
        whiteSpace: "nowrap",
      },
      formatter: (cell, row) => {
        const handleRemoveLearner = (item) => {
          toggle("deleteLearner");
          setItemLearner(item);
        };

        return (
          <div className="d-flex justify-content-center align-items-center">
            <CloseIcon row={row} handleDelete={() => handleRemoveLearner(row)} />
          </div>
        );
      },
    },
  ];

  const loadCohortsById = (data) => {
    callApiCohortsById(data?.CohortId);
    callAuthCodeById(data.CohortId);
    setcohortObject(data);
  };

  const callApiCohortsById = useCallback(
    (Id) => {
      //load api
      const params = {
        Action: "Load",
        CohortId: Id,
      };
      dispatch(atcLoadCohortsById(params));
    },
    [dispatch]
  );

  const callAuthCodeById = (id) => {
    dispatch(loadAuthCodeListById(id));
  };

  const reloadDataAfterDelete = () => {
    callApiCohortsById(cohortData && cohortData[0]?.CohortId);
    setcohortObject(cohortData && cohortData[0]);
  }

  //Active item first/Load API
  useEffect(() => {
    //load object first
    if (cohortData?.length > 0) {
      callApiCohortsById(cohortData && cohortData[0]?.CohortId);
      setcohortObject(cohortData && cohortData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortData]);

  //Render Components
  const Left = ({ children }) => {
    return <div className="position-relative">{children}</div>;
  };

  const Right = ({ children }) => {
    return <div className="position-relative">{children}</div>;
  };

  const hasData = cohortData && cohortData.length > 0;
  return (
    <React.Fragment>
      <GridContainer>
        <Left>
          <AvailableCohorts
            {...props}
            cohortData={cohortData}
            loadCohortsById={loadCohortsById}
            cohortObject={cohortObject}
          />
          <ButtonAddCohort
            toggle={toggle}
          />
        </Left>
        <Right>
          {hasData ? (
            <CohortMain
              toggle={toggle}
              columns={columns}
              cohortObject={cohortObject}
            />
          ) : (
            <div
              className="mt-5"
              style={{
                borderRadius: '8px',
                border: '1px solid #D0D5DD',
                padding: '16px',
                backgroundColor: '#fff'
              }}
            >
              <MessageEmtyData
                title="No Cohorts Found"
                text="You haven’t created any cohorts yet. Start by clicking ‘Add Cohort’ to create a new one."
              />
            </div>
          )}
        </Right>
      </GridContainer>
      <ModalAddLearner
        isOpen={modal.addLearner}
        toggle={() => toggle("addLearner")}
        actions={modal}
        cohortObject={cohortObject}
      />
      <ModalDeleteCohorts
        isOpen={modal.deleteCohort}
        toggle={() => toggle("deleteCohort")}
        actions={modal}
        cohortObject={cohortObject}
        reloadDataAfterDelete={reloadDataAfterDelete}
      />
      <ModalAddCohort
        isOpen={modal.addCohort}
        toggle={() => toggle("addCohort")}
        actions={modal}
      />
      <ModalEditCohort
        isOpen={modal.editCohort}
        toggle={() => toggle("editCohort")}
        activeCohortAfterEdit={loadCohortsById}
        actions={modal}
        cohortObject={cohortObject}
      />
      <ModalDeleteLearner
        isOpen={modal.deleteLearner}
        toggle={() => toggle("deleteLearner")}
        item={itemLearner}
        cohortObject={cohortObject}
      />
    </React.Fragment>
  );
}

export default Cohorts;