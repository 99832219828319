import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Row, Col, FormGroup, Label, Button, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { SwitchItem } from "@ui-partents/SwitchButton";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//toast
import { toast } from 'react-toastify';

// constants
import { storyboardConstants } from "@constants";

const SettingsForm = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  // Extracting data from props
  const { timepointData } = props || {};
  const chatSettings = timepointData?.ChatSettings || {};
  const [isLoading, setLoading] = useState(false);

  // Extracting values from chatSettings
  const {
    InteractWith,
    InteractLanguage,
    AgeInteractingCharacter,
    AIModel,
    GenderInteractingCharacter,
    ChatToolbar,
    EndCaseFeedback
  } = chatSettings;

  // State for initial form values
  const [initialValues, setInitialValues] = useState({
    InteractWith: InteractWith || '',
    InteractLanguage: InteractLanguage || '',
    AgeInteractingCharacter: AgeInteractingCharacter || '',
    AIModel: AIModel || '',
    GenderInteractingCharacter: GenderInteractingCharacter || '',
    ChatToolbar: ChatToolbar || false,
    EndCaseFeedback: EndCaseFeedback || false
  });

  useEffect(() => {
    if (timepointData) {
      const chatSettings = timepointData.ChatSettings || {};
      setInitialValues({
        InteractWith: chatSettings.InteractWith || '',
        InteractLanguage: chatSettings.InteractLanguage || '',
        AgeInteractingCharacter: chatSettings.AgeInteractingCharacter || '',
        AIModel: chatSettings.AIModel || '',
        GenderInteractingCharacter: chatSettings.GenderInteractingCharacter || '',
        ChatToolbar: chatSettings.ChatToolbar || false,
        EndCaseFeedback: chatSettings.EndCaseFeedback || false
      });
    }
  }, [timepointData])

  // Define options object
  const options = {
    InteractWith: [
      { label: 'Patient', value: 'Patient' },
      { label: 'Mother', value: 'Mother' },
      { label: 'Father', value: 'Father' },
      { label: 'Son', value: 'Son' },
      { label: 'Daughter', value: 'Daughter' }
    ],
    InteractLanguage: [
      { label: 'English', value: 'English' },
      { label: 'Vietnamese', value: 'Vietnamese' },
      { label: 'Korean', value: 'Korean' }
    ],
    AIModel: [
      { label: 'gpt-3.5-turbo', value: 'gpt-3.5-turbo' },
      { label: 'gpt-4', value: 'gpt-4' }
    ],
    GenderInteractingCharacter: [
      { label: 'Male', value: 'Male' },
      { label: 'Female', value: 'Female' },
    ],
  };

  // Form submission handler
  const handleSubmit = (values) => {
    const params = {
      "Action": "Update",
      "Target": "ChatSettings",
      "InteractWith": values?.InteractWith,
      "InteractLanguage": values?.InteractLanguage,
      "AgeInteractingCharacter": values?.AgeInteractingCharacter,
      "GenderInteractingCharacter": values?.GenderInteractingCharacter,
      "AIModel": values?.AIModel,
      "EndCaseFeedback": values?.EndCaseFeedback,
      "ChatToolbar": values?.ChatToolbar,
      "TimePointId": timepointData?.Id
    }
    setLoading(true);
    dispatch({
      type: storyboardConstants.CHAT_SETTINGS_REQUEST,
      isLoading: true,
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
        setLoading(false);
        toast.success(`Data update successfully !`, {
          position: "top-right"
        });
      } else {
        dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
        setLoading(false);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors = {};

        // Validation rules
        if (!values.AgeInteractingCharacter) {
          errors.AgeInteractingCharacter = 'Age is required';
        }

        return errors;
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <section className='form-details card-area bg-grey'>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className='labeForm' for="InteractWith">Interacting with:</Label>
                  <Select
                    id="InteractWith"
                    value={options.InteractWith.find(option => option.value === values.InteractWith)}
                    onChange={(option) => setFieldValue('InteractWith', option.value)}
                    options={options.InteractWith}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className='labeForm' for="InteractLanguage">Interacting Language:</Label>
                  <Select
                    id="InteractLanguage"
                    value={options.InteractLanguage.find(option => option.value === values.InteractLanguage)}
                    onChange={(option) => setFieldValue('InteractLanguage', option.value)}
                    options={options.InteractLanguage}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className='labeForm' for="AgeInteractingCharacter">Age of interacting character:</Label>
                  <Field
                    type="number"
                    id="AgeInteractingCharacter"
                    name="AgeInteractingCharacter"
                    className={`form-control ${errors.AgeInteractingCharacter && touched.AgeInteractingCharacter ? 'is-invalid' : ''}`}
                  />
                  <ErrorMessage name="AgeInteractingCharacter" component={FormFeedback} className="text-danger" />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className='labeForm' for="AIModel">GPT Version:</Label>
                  <Select
                    id="AIModel"
                    value={options.AIModel.find(option => option.value === values.AIModel)}
                    onChange={(option) => setFieldValue('AIModel', option.value)}
                    options={options.AIModel}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label className='labeForm' for="GenderInteractingCharacter">Gender of interacting character:</Label>
                  <Select
                    id="GenderInteractingCharacter"
                    value={options.GenderInteractingCharacter.find(option => option.value === values.GenderInteractingCharacter)}
                    onChange={(option) => setFieldValue('GenderInteractingCharacter', option.value)}
                    options={options.GenderInteractingCharacter}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <div className='d-flex justify-content-between'>
                  <SwitchItem
                    label={`Chat Toolbar`}
                    name={`ChatToolbar`}
                    value={values.ChatToolbar}
                    handleChangeSwitch={(e) => setFieldValue('ChatToolbar', e.target.checked)}
                  />
                  <SwitchItem
                    label={`End-Of-Case Feedback:`}
                    name={`EndCaseFeedback`}
                    value={values.EndCaseFeedback}
                    handleChangeSwitch={(e) => setFieldValue('EndCaseFeedback', e.target.checked)}
                  />
                </div>
              </Col>
            </Row>
            <div className='mt-4 text-right'>
              {/* <Button
                color="secondary"
                className="px-5"
                size='lg'
              >
                Cancel
              </Button> */}
              <Button
                type="submit"
                color="primary"
                className="ml-2 px-5"
                size='lg'
                disabled={isLoading}
              >
                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Save
              </Button>
            </div>
          </Form>
        </section>
      )}
    </Formik>
  );
};

export default SettingsForm;