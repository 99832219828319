import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

const ChooseFirstAppearance = (props) => {
    let { id } = useParams();

    //state
    const [activeOption, setActiveOption] = useState(false);

    const callApiAndUpdateFlow = (value) => {
        const flowOrder = value === "reflection" ? ["reflection", "review"] : ["review", "reflection"];
        const params = {
            Action: "Update",
            Target: "FeedbackReflectionFlow",
            Flow: flowOrder
        };
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                console.log(data, "data.....");
            } else {
                console.log('error');
            }
        });
    };

    const handleInputChange = (event) => {
        const { value } = event.target;
        setActiveOption(value); // Cập nhật state của activeOption khi radio button thay đổi
        // Gọi hàm cập nhật API khi radio button thay đổi
        callApiAndUpdateFlow(value);
    };

    return (
        <div className="d-flex align-items-center mb-3">
            <Label className="mr-3 mb-0 labeForm">Choose which one to appear first:</Label>

            <FormGroup check inline>
                <Label className="d-flex align-items-center" check>
                    <Input
                        type="radio"
                        name='status'
                        value={'reflection'}
                        onChange={handleInputChange}
                        checked={!activeOption} // Kiểm tra nếu activeOption là false thì đánh dấu radio button này là checked
                    />
                    Self-Reflection Dialogue
                </Label>
                <Label className="d-flex align-items-center ml-3" check>
                    <Input
                        type="radio"
                        name='status'
                        value={`review`}
                        onChange={handleInputChange}
                        checked={activeOption} // Kiểm tra nếu activeOption là true thì đánh dấu radio button này là checked
                    />
                    Performance Feedback
                </Label>
            </FormGroup>
        </div>
    );
};

export default ChooseFirstAppearance;