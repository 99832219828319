import React, { useEffect } from "react";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);

function Accounts(props) {
  //props
  const { data } = props && props;

  useEffect(() => {
    Plotly.Plots.resize("plotlyChart");
  }, []);

  // Use screen size context
  const { isMobile } = useScreenSize();

  //Account
  const AccountsData = data?.Accounts;
  const NewAccounts = AccountsData?.NewAccounts;
  const NewAccountsWithNewCase = AccountsData?.NewAccountsWithNewCase;
  const RegisterPageVisitors = AccountsData?.RegisterPageVisitors;
  const WebsiteVisitors = AccountsData?.WebsiteVisitors;
  const listData = [WebsiteVisitors, RegisterPageVisitors, NewAccounts, NewAccountsWithNewCase];

  return (
    <section className="statistic-accounts -box-shadow bg-white p-3">
      <div className="mx-auto">
        <PlotlyComponent
          divId="plotlyChart"
          data={[
            {
              type: "bar",
              x: listData || [],
              y: ["Website Visitors", "Registration Page", "New Accounts", "New Accounts with New Cases"],
              orientation: "h",
              text: listData || [],
              textposition: "inside",
              insidetextanchor: "middle",
              insidetextfont: { size: 16, color: "#fff" },
              marker: {
                color: ["#7eab55", "#4f71be", "#3c88be", "#ea9b38"]
              },
              transforms: [
                {
                  type: "sort",
                  target: "y",
                  order: "descending"
                },
              ],
            },
          ]}
          layout={{
            font: { size: 13 },
            responsive: true,
            margin: {
              l: isMobile ? 0 : 300,
              pad: 10
            }
          }}
          useResizeHandler
          className="w-100 h-100"
        />
      </div>
    </section>
  );
}

export default Accounts;
