function ConvertFormatQuestionType(type) {
  const convertFormat = (type) => {
    switch (type) {
      case "multiple_choice":
        return "Multiple Choice";
      case "multiple_choice_table":
        return "Multiple Choice Table";
      case "free_text":
        return "Free Text";
      case "drag_n_drop":
        return "Drag-and-drop";
      case "drag_n_drop_cloze":
        return "Drag-and-drop Cloze";
      case "highlight":
        return "Highlight";
      default:
        return "Drop Down";
    }
  };

  return convertFormat(type);
}

module.exports = ConvertFormatQuestionType;
