import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//components
import {
    InstructionsAdvanceFinish,
    QuestionsAdvanceFinish,
    // CheckpointsAdvanceFinish,
} from "../ManageAvailableTasks/AdvanceFinish";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const AdvanceFinishMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Instructions", content: <InstructionsAdvanceFinish {...props} /> },
        { key: 2, label: "Questions", content: <QuestionsAdvanceFinish {...props} /> },
        // { key: 3, label: "Checkpoints", content: <CheckpointsAdvanceFinish {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(2); // 2 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Advance / Finish`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default AdvanceFinishMain;