import React, { useState } from "react";

//reactstrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

//classnames
import classnames from "classnames";

function TabsContainer(props) {
  const { data } = props && props;
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Nav className="tabBorder" tabs>
        {data?.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => {
                toggle(item.id);
              }}
            >
              <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data?.map((item, index) => (
          <TabPane key={index} tabId={item.id}>
            {item.component}
          </TabPane>
        ))}
      </TabContent>
    </React.Fragment>
  );
}

export default TabsContainer;
