// notifyToast.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastCommon from '@ui-partents/Toast/ToastCommon';

// Define the notifyToast function
const notifyToast = ({
  status = "normal",
  title = "",
  content = "",
  place = "top-center",
  timeClose = 5000,
  ...props
}) => {
  toast(
    <ToastCommon 
      title={title}
      content={content}
      status={status}
      {...props}
    />,
    {
      className: `toast-common ${status}`,
      position: place,
      autoClose: timeClose,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
};

// Export notifyToast as the default export
export default notifyToast;