import React from 'react';

function CorrectOrInCorrect(props) {
    const { NumberCorrect, NumberIncorrect } = props && props;
    return (
        <div className="pl-4 d-flex flex-column">
            <p>
                <span
                    style={{ fontSize: "large" }}
                    className="m--font-brand text-center font-weight-600 mr-2"
                >
                    {NumberCorrect || 0}
                </span>
                <span>Correct</span>
            </p>
            <p>
                <span
                    style={{ fontSize: "large" }}
                    className="m--font-warning text-center font-weight-600 mr-2"
                >
                    {NumberIncorrect || 0}
                </span>
                <span>Incorrect</span>
            </p>
        </div>
    );
};

export default CorrectOrInCorrect;