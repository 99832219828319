import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//react-hook-form
import { useForm } from "react-hook-form";

//logo
import imgLogo from "@images/ucr/logo.png";

//boostrap
import {
  Row,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  FormFeedback,
  Alert,
  Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//untils
import { checkAuth, apiCaller } from "@utils";

//queryString
import queryString from "query-string";

//components
import { RedirectView } from "../../NusComponent";

function FormLogin() {
  //form
  const {
    register,
    formState: { errors
    },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  let history = useHistory();
  const dispatch = useDispatch();
  const Password = useRef({});
  Password.current = watch("Password", "");

  //check params
  let parsed = queryString.parse(window.location.search);

  //state
  const [isLoading, setLoading] = useState(false);
  const [showNotic, setNotic] = useState(false);

  useEffect(() => {
    document.body.style["background-color"] = "#fafbfb";
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const onsubmit = (data) => {
    const formData = new FormData();
    formData.append("email", data?.Email);
    formData.append("firstname", data?.FirstName);
    formData.append("lastname", data?.LastName);
    formData.append("special_key", "nus2022");
    formData.append("password", data?.Password);

    setLoading(true);
    //Call Api
    apiCaller(`/api/auth/login/`, "POST", formData).then((response) => {
      let dataResponse = response?.data;
      if (dataResponse?.status === "OK") {
        const accountData = dataResponse?.account;
        const isAdministrator = accountData?.is_administrator;
        // const isInstructor = accountData?.is_instructor;
        const isSubscription = accountData?.subscription;

        // set localStorage
        localStorage.setItem(
          "Account",
          accountData ? JSON.stringify(accountData) : ""
        );

        //add token
        dispatch(actionLogin(dataResponse));

        //Call API Theme
        dispatch(actFetchThemeRequest());

        // Check Redirect Page
        if (isSubscription !== null) {
          nextPage(dataResponse);
        } else if (isSubscription === null && isAdministrator === "Yes") {
          history.push(`/plan`);
        } else {
          history.push(`/plan/error`);
        }

        //reset Data
        reset({ keepIsValid: false, keepValues: false });
      } else if (dataResponse?.status === 'Unable to authenticate with core back end') {
        setLoading(false);
        setNotic(true);
      } else {
        setLoading(false);
        console.log('error');
      }
    });
  }

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed[`redirect`];

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
      if (dataAccount?.is_instructor === "Yes") {
        history.push(`/teach/home`);
      } else {
        history.push("/learn/home");
      }
    }
  };

  return (
    <div className="m-portlet m-portlet--mobile" style={{ maxWidth: "540px" }}>
      <div className="m-portlet__body">
        <div className="text-center mb-5">
          <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
        </div>
        {showNotic ?
          <RedirectView /> : (
            <Form className="mt-3" onSubmit={(e) => e.preventDefault()}>
              <Alert className="m-alert--default border-0">
                Please fill in the form below to login to NUS-UCR system
              </Alert>
              <Row>
                <Col md={`12`}>
                  <FormGroup>
                    <Label for="FirstName">
                      First Name: <span className="m--font-danger">*</span>
                    </Label>
                    <Input
                      {...register("FirstName", { required: true })}
                      type="text"
                      name="FirstName"
                      placeholder="Enter your first name"
                      onChange={(e) => {
                        setValue("FirstName", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      invalid={errors.FirstName?.type === "required"}
                      autoComplete="off"
                    />
                    <FormFeedback
                      invalid={(errors.FirstName?.type === "required").toString()}
                    >
                      This field is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={`12`}>
                  <FormGroup>
                    <Label for="LastName">
                      Last Name:<span className="m--font-danger">*</span>
                    </Label>
                    <Input
                      {...register("LastName", { required: true })}
                      type="text"
                      name="LastName"
                      placeholder="Enter your last name"
                      onChange={(e) => {
                        setValue("LastName", e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      invalid={errors.LastName?.type === "required"}
                      autoComplete="off"
                    />
                    <FormFeedback
                      invalid={(errors.LastName?.type === "required").toString()}
                    >
                      This field is required.
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md={`12`}>
                  <FormGroup>
                    <Label for="Email">
                      Email: <span className="m--font-danger">*</span>
                    </Label>
                    <Input
                      {...register("Email", {
                        required: "This field is required.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid e-mail address",
                        },
                      })}
                      type="email"
                      name="Email"
                      placeholder="Enter your email address"
                      invalid={errors.Email?.type === "required"}
                      autoComplete="off"
                    />
                    {errors.Email?.message && (
                      <FormFeedback className="d-block">
                        {errors.Email?.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={`12`} md={`6`}>
                  <FormGroup>
                    <Label for="Password">
                      Password:<span className="m--font-danger">*</span>
                    </Label>
                    <Input
                      {...register("Password", {
                        required: "You must specify a password",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters"
                        }
                      })}
                      type="Password"
                      name="Password"
                      placeholder="Enter your new password"
                      invalid={errors.Password?.type === "required"}
                    />
                    {errors.Password?.message && (
                      <FormFeedback className="d-block">
                        {errors.Password?.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={`12`} md={`6`}>
                  <FormGroup>
                    <Label for="Password_repeat">
                      Confirm Password:
                      <span className="m--font-danger">*</span>
                    </Label>
                    <Input
                      {...register("Password_repeat", {
                        validate: (value) =>
                          value === Password.current ||
                          `Please make sure you enter the same password in the "confirm password" textbox`,
                      })}
                      type="password"
                      name="Password_repeat"
                      placeholder="Re-enter password"
                    />
                    {errors.Password_repeat?.message && (
                      <FormFeedback className="d-block">
                        {errors.Password_repeat?.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={`12`} className="mt-3">
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      className="btn m-btn--pill btn-lg w-100"
                      onClick={handleSubmit(onsubmit)}
                      disabled={isLoading}
                      style={{ minWidth: "25%" }}
                    >
                      {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                      )}
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )
        }
      </div>
    </div>
  );
}

export default FormLogin;