import React, { useEffect } from 'react';

//react-router-dom
import { useParams } from "react-router-dom";

//react-redux
import { useSelector, useDispatch } from "react-redux";

//components
import { CaseCreateLayout } from "@components/teachV2/NewCaseCreation";

//actions
import { actFetchTeachCaseRequest } from "@actions";

function CreateCasePage(props) {
  const dispatch = useDispatch();
  const { id } = useParams();

  // Fetch Teach Case if the token and id are available
  useEffect(() => {
    dispatch(actFetchTeachCaseRequest(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get data store
  const teachCaseData = useSelector((state) => state.teachCase || []);
  const dataResponse = teachCaseData?.data;
  return (
    <div className="wrapper -AddCasePage">
      <CaseCreateLayout
        {...props}
        teachCaseData={dataResponse}
      />
    </div>
  );
}

export default CreateCasePage;