import React, { useState, useEffect } from "react";

//reactstrap
import { FormGroup, Label, Input } from "reactstrap";

//react-router
import { useParams } from "react-router-dom";

//CreatableSelect
import CreatableSelect from "react-select/creatable";

//apiCaller
import { apiCaller } from "@utils";

function ActionTypeCondition(props) {
    const { flowControlOfAction, handleUpdateFlowControlActionId, timePointId } = props && props;

    //State
    const [arrActions, setActions] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);

    const [isStatus, setStatus] = useState(flowControlOfAction?.Status);

    //Load State
    useEffect(() => {
        setStatus(flowControlOfAction?.Status);
    }, [flowControlOfAction]);

    //id Case
    let { id } = useParams();

    useEffect(() => {
        const fetchData = () => {
            const params = {
                "Action": "Load",
                "Target": "Actions",
                "TimePointId": timePointId
            }

            //Call Api Load Text Item
            apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    const mergedInterventions = data?.sort((a, b) => a.Name.localeCompare(b.Name)).reduce((acc, obj) => acc.concat(obj.Interventions), []).sort((a, b) => a - b);
                    setActions(mergedInterventions);
                } else {
                    console.log("error");
                }
            })
        }
        fetchData();
    }, [id, timePointId])

    // convert data
    const convertDataOptions = () => {
        let listOptions = [];
        arrActions?.map(function (item) {
            const dataReturn = { value: item?.Id, label: `${item?.Name} (#${item?.Id})` };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    // Active ActionId
    useEffect(() => {
        setSelectedOption({
            value: flowControlOfAction?.ActionId,
            label: flowControlOfAction?.ActionName
        });
    }, [flowControlOfAction]);

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        handleUpdateFlowControlActionId("ActionId", data);
    };

    const handleChangeStatus = (e) => {
        const { name, value } = e.target;
        setStatus(value);
        handleUpdateFlowControlActionId(name, value);
    }

    return (
        <React.Fragment>
            <div className="my-3">
                <CreatableSelect
                    closeMenuOnSelect={true}
                    options={convertDataOptions()}
                    placeholder={`Click to select chat action(s)`}
                    value={selectedOption}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                />
            </div>
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    (To NOT impose a condition, simply not select a question)
                </small>
            </p>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="taken"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "taken"}
                    />
                    is taken
                </Label>
            </FormGroup>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="not_taken"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "not_taken"}
                    />
                    is NOT taken
                </Label>
            </FormGroup>
        </React.Fragment>
    );
}

export default ActionTypeCondition;