import React from "react";

//reactstrap
import { FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function TextareaAutosizeInput(props) {
    const { handleChange, nameState, register, errors } = props && props;
    return (
        <FormGroup>
            <Label className="font-weight-500 mb-0">Prompt Text</Label>
            <TextareaAutosize
                {...register("Content", { required: true })}
                name="Content"
                placeholder="Prompt text..."
                onChange={handleChange}
                className="w-100 form-control"
                maxRows="10"
                minRows={2}
                value={nameState}
                autoFocus
            />
            {(nameState === "" && errors?.Content?.type === "required") && (
                <div className="invalid-feedback d-block">
                    This is required.
                </div>
            )}
        </FormGroup>
    );
}

export default TextareaAutosizeInput;