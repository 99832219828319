// TablesPage.js
import React from 'react';
import { SimpleTables, TableCheckbox } from "@components/patterns/elements/Table";
import { Row, Col } from 'reactstrap';

const TablesPage = () => {
    return (
        <section className="content-component">
            <Row className='mb-4'>
                <Col md={12}>
                    <div className="content-header">
                        <h1>Simple Tables</h1>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <SimpleTables />
                </Col>
                <Col md={12}>
                    <TableCheckbox />
                </Col>
            </Row>
        </section>
    );
};

export default TablesPage;