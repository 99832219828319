import { storyboardConstants, teachConstants, balanceConstants, caseConstants } from "@constants";
import _ from 'lodash';

function updateAvailabilityCommon(newState, action) {
    let updateAvailabilityCommon = newState?.data?.IndependentLabs;
    const idAvailableCommon = action?.idAvailable;
    const isCheckedAvailabilityCommon = action?.isChecked;
    const indexAvailabilityCommon = _.findIndex(updateAvailabilityCommon, item => item.Id === idAvailableCommon);
    if (indexAvailabilityCommon !== -1) {
        updateAvailabilityCommon[indexAvailabilityCommon][`Available`] = isCheckedAvailabilityCommon;
    }
    return updateAvailabilityCommon;
}

export function timeline(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case storyboardConstants.TIMLINE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.TIMLINE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.TIMLINE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case storyboardConstants.CREATE_TIMEPOINT_SUCCESS:
            return {
                ...state,
                data: action?.payload?.UpdatedTimeline,
                isLoading: false
            };
        case storyboardConstants.UPDATE_TIMEPOINT_SUCCESS:
            newState = { ...state };
            return {
                ...newState,
                data: action?.payload?.UpdatedTimeline,
                isLoading: false
            };
        case storyboardConstants.DELETE_TIMEPOINT_SUCCESS:
            newState = { ...state };
            var dataTimeLine = (action.payload && action.payload.UpdatedTimeline) || []
            return {
                ...newState,
                data: dataTimeLine,
                isLoading: false
            };
        default: return state;
    }
};

export function timepoint(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case storyboardConstants.TIME_POINT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.TIME_POINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                timepointId: action.payload && action.payload.Id,
                isLoading: false
            };
        //update question
        case teachConstants.ORDER_QUESTION_SUCCESS:
            newState = { ...state }
            newState.data.Questions = action.payload
            return {
                ...newState,
                isLoading: false
            };
        // Update Point Exam
        case storyboardConstants.UPDATE_POINT_SUCCESS:
            newState = { ...state }
            newState.data.Exam = action?.payload?.Exam
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.TIME_POINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case storyboardConstants.UPDATE_STATUS_TIMEPOINT_SUCCESS: //Xoa Sau khi xong phan Tech Moi
            //update item in array
            var stateFilter = { ...state };
            const dataOld = stateFilter && stateFilter.data && stateFilter.data.TaskElements;
            const dataElement = action.payload && action.payload.TaskElement;
            const indexItem = _.findIndex(dataOld, item => item.ViewClass === (dataElement && dataElement.ViewClass));
            if (indexItem !== -1) {
                dataOld[indexItem] = dataElement
            }
            return {
                ...state,
                isLoading: false
            };
        case teachConstants.CREATE_QUESTION_SUCCESS:
            newState = { ...state };
            newState.data.Questions = action.payload
            return {
                ...state,
                isLoading: false
            };
        case teachConstants.CLONE_QUESTION_SUCCESS:
            newState = { ...state };
            newState.data.Questions = action.payload
            return {
                ...state,
                isLoading: false
            };
        case teachConstants.UPDATE_QUESTION_SUCCESS:
            newState = { ...state };
            newState.data.Questions = action.payload
            return {
                ...state,
                isLoading: false
            };
        case teachConstants.CREATE_QUESTION_FROM_QUESTION_BANK_SUCCESS:
            newState = { ...state };
            if (newState && newState.data && newState.data.Questions) {
                newState.data.Questions = action.payload
            }
            return {
                ...newState,
                isLoading: false
            };
        case teachConstants.DELETE_QUESTION_SUCCESS:
            newState = { ...state };
            if (newState && newState.data && newState.data.Questions) {
                newState.data.Questions = action.payload
            }
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.VITAL_SIGN_CHANGE_SUCCESS:
            newState = { ...state };
            const updateVitalSigns = action.payload.VitalSigns;
            newState.data.DefaultVitalSigns = updateVitalSigns
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.CREATE_VITAL_SIGN_SUCCESS:
            newState = { ...state };
            newState.data.DefaultVitalSigns = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_VITAL_SIGN_SUCCESS:
            newState = { ...state };
            newState.data.DefaultVitalSigns = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_VITAL_SIGNS_SUCCESS:
            newState = { ...state };
            newState.data.DefaultVitalSigns = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPLOAD_EXAM_PROFILE_SUCCESS:
            newState = { ...state };
            newState.data.Exam = (action.payload && action.payload.Exam) || {}
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_EXAM_FINDINGS_ID_SUCCESS:
            newState = { ...state };
            newState.data.Exam = action.payload && action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.CREATE_TIMEPOINT_EXAM_FINDING_SUCCESS:
            newState = { ...state };
            newState.data.Exam = action.payload && action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_EXAM_POINT_SUCCESS:
            newState = { ...state }
            newState.data.Exam.ExamPoints = (action.payload && action.payload.ExamPoints)
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.CREATE_EXAM_POINT_SUCCESS:
            newState = { ...state }
            newState.data.Exam.ExamPoints = (action.payload && action.payload.ExamPoints)
            return {
                ...newState,
                isLoading: false
            };
        case balanceConstants.UPDATE_VALUE_BALANCE_SUCCESS:
            newState = { ...state };
            const updateIOBalance = action.payload.IO;
            newState.data.IO = updateIOBalance
            return {
                ...newState,
                isLoading: false
            };
        case balanceConstants.DELETE_IO_BALANCE_SUCCESS:
            newState = { ...state };
            const updatedelIOBalance = action.payload;
            newState.data.IO = updatedelIOBalance
            return {
                ...newState,
                isLoading: false
            };
        case balanceConstants.ADD_IO_BALANCE_SUCCESS:
            newState = { ...state };
            const createlIOBalance = action.payload;
            newState.data.IO = createlIOBalance
            return {
                ...newState,
                isLoading: false
            };
        // Case - Update Take-Home Message
        case caseConstants.TAKE_HOME_MESSAGE_SUCCESS:
            newState = { ...state };
            newState.data.TakeHomeMessage = action?.payload?.TakeHomeMessage
            newState.data[`TakeHomeMessageSections`] = action?.payload?.TakeHomeMessageSections
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.PUT_DOCUMENTATION_NOTE_SUCCESS:
            newState = { ...state };
            newState.data.Documentations.DocumentationNotes = action?.payload?.DocumentationNotes
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_TIMEPOINT_EXAM_FINDING_SUCCESS:
            newState = { ...state };
            newState.data.Exam = action.payload && action.payload.Exam
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UDPATE_VALUE_VITAL_SUCCESS:
            newState = { ...state };
            newState.data.DefaultVitalSigns = action?.payload?.VitalSigns
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.CREATE_VITAL_SUCCESS:
            newState = { ...state };
            newState.data.DefaultVitalSigns = action?.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_NARRATIVE_CONTENT_SUCCESS:
            newState = { ...state };
            newState.data.DefaultNarrative = action?.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_DOCUMENTATION_TEMPLATE_SUCCESS:
            newState = { ...state };
            newState.data.Documentations.DocumentationTemplate = action?.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_CHANGE_MODE_CHAT_SUCCESS:
            newState = { ...state };
            newState.data.ChatMode = action?.payload?.ChatMode
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_MAX_CHAT_QUESTION_SUCCESS:
            newState = { ...state };
            newState.data.MaxChatQuestions = action?.payload
            return {
                ...newState,
                isLoading: false
            };
        //Load Timepoint after Create a time point
        case storyboardConstants.CREATE_TIMEPOINT_SUCCESS:
            return {
                ...state,
                data: action?.payload?.NewTimePoint,
                timepointId: action?.payload?.NewTimePointId,
                isLoading: false
            };
        //Load Timepoint after Update a time point
        case storyboardConstants.UPDATE_TIMEPOINT_SUCCESS:
            newState = { ...state };
            return {
                ...state,
                data: action?.payload?.TimePoint,
                timepointId: action?.payload?.TimePoint?.Id,
                isLoading: false
            };
        case teachConstants.UPDATE_AVAILABLE_TASK:
            newState = { ...state };
            newState.data.TaskElements = action.payload;
            return newState;
        case storyboardConstants.CHAT_SETTINGS_SUCCESS:
            newState = { ...state };
            newState.data.ChatSettings = action?.payload?.ChatSettings
            return {
                ...newState,
                isLoading: false
            };
        default: return state;
    }
};

export function loadLabs(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case storyboardConstants.LOAD_LABS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.LOAD_LABS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.LAB_CHANGE_SUCCESS:
            newState = { ...state };
            newState.data = action.payload && action.payload.Labs
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.CREATE_LAB_PANEL_SUCCESS:
            newState = { ...state };
            newState.data.Panels = action.payload && action.payload.Panels
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_LAB_PANEL_SUCCESS:
            newState = { ...state };
            newState.data.Panels = action.payload?.Panels
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_LAB_ITEM_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_LAB_AVAILABILITY_IN_BATCH_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_LAB_ITEM_AVAILABILITY_SUCCESS:
            newState = { ...state };
            if (action.payload && action.payload.status === "success") {
                const IndependentLabs = newState.data.IndependentLabs;
                const indexFilterItem = _.findIndex(IndependentLabs, item => item?.Id === action?.idAvailable);
                if (indexFilterItem !== -1) {
                    IndependentLabs[indexFilterItem]['Available'] = action?.isChecked;
                }
            }
            return {
                ...newState,
                isLoading: false,
                allChecked: action?.allChecked
            };
        case storyboardConstants.CREATE_LAB_ITEM_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_LAB_PANEL_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.DELETE_LAB_ITEM_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_ATTRIBUTE_SCORE_LAB_SUCCESS:
            newState = { ...state };
            const dataPanels = newState.data.Panels;
            const AttributeId = action.AttributeId
            const indexItem = _.findIndex(dataPanels, item => item.Id === AttributeId);
            if (indexItem !== -1) {
                dataPanels[indexItem].DomainScores = action.payload
            }
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_LAB_PANEL_AVAILABILITY_SUCCESS:
            newState = { ...state };
            let panelUpdateAvailability = newState.data.Panels;
            const idAvailable = action?.idAvailable;
            const isCheckedAvailability = action?.isChecked;
            const indexAvailability = _.findIndex(panelUpdateAvailability, item => item.Id === idAvailable);
            if (indexAvailability !== -1) {
                panelUpdateAvailability[indexAvailability][`Available`] = isCheckedAvailability;
            }

            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_COMMON_AVAILABILITY_SUCCESS:
            newState = { ...state };
            updateAvailabilityCommon(newState, action);
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_ATTRIBUTE_SCORE_INDEPENDENT_LABS_SUCCESS:
            newState = { ...state };
            if (action.payload.length > 0) {
                const IndependentLabs = newState.data.IndependentLabs;
                const AttributeIndependentId = action.AttributeId;
                const indexObject = _.findIndex(IndependentLabs, item => item.Id === AttributeIndependentId);
                if (indexObject !== -1) {
                    IndependentLabs[indexObject].DomainScores = action.payload
                }
            }
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_LABS_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.LOAD_LABS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function loadAttributesForEffects(state = {}, action) {
    switch (action.type) {
        case storyboardConstants.ATTRIBUTESF_OR_EFFECTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.ATTRIBUTESF_OR_EFFECTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.ATTRIBUTESF_OR_EFFECTS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function loadExamFindingById(state = {}, action) {
    switch (action.type) {
        case storyboardConstants.LOAD_EXAM_FINDINGS_ID_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.LOAD_EXAM_FINDINGS_ID_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.LOAD_EXAM_FINDINGS_ID_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function loadChat(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case storyboardConstants.LOAD_CHAT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.LOAD_CHAT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.REMOVE_CHAT_SUCCESS:
            newState = { ...state }
            newState.data = action.payload.ChatTopics
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.ADD_CHAT_SUCCESS:
            newState = { ...state }
            newState.data = action.payload.ChatTopics
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.EDIT_CHAT_SUCCESS:
            newState = { ...state }
            newState.data = action.payload.ChatTopics
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.LOAD_CHAT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function extractVitalSigns(state = {}, action) {
    switch (action.type) {
        case storyboardConstants.EXTRACT_VITAL_SIGNS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.EXTRACT_VITAL_SIGNS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_VITAL_SIGNS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function extractLabs(state = {}, action) {
    switch (action.type) {
        case storyboardConstants.EXTRACT_LABS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.EXTRACT_LABS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_LABS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};