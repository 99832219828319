import React from "react";

//boostrap
import { FormGroup, Label } from "reactstrap";

//react-select
import Select from 'react-select';

function CategoryFilterSelect(props) {
    const { handleChangeOptions, dataSelect, selectedOption, isLoading } = props || {};
    const selectedCategory = selectedOption?.category || null;
    return (
        <FormGroup>
            <Label className="font-weight-500" htmlFor="category">
                Category
            </Label>
            <Select
                options={dataSelect?.category || []}
                closeMenuOnSelect={true}
                value={selectedCategory}
                onChange={(optionValue) => handleChangeOptions(optionValue, 'category')}
                autoComplete="off"
                name="category"
                placeholder="Select a category"
                isLoading={isLoading?.getCategoryReviews}
            />
        </FormGroup>
    );
}

export default CategoryFilterSelect;