import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

import JoditEditor from "jodit-react";

//Validate
import { useForm } from "react-hook-form";

//redux
import { useDispatch } from "react-redux";

//router
import { useParams } from "react-router-dom";

//Actions
import { atcUpdateLabItem, atcCreateLabItem } from "@actions";

//components common
import { AudioFile, VideoLink, ImageFile, DropDownMedia } from "@components-common/media";

//Config
import * as Config from "@constants/Config";

import classnames from "classnames";

const ModalEditLabs = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, actions, timePointId, labPanelId } = props && props;
  const dataProps = props && props.data;
  const [activeTab, setActiveTab] = useState("1");

  //Media
  const [audioData, setAudioData] = useState([]); //files
  const [imageData, setImageData] = useState([]); //files
  const [stateData, setStateData] = useState({});
  const [dataMediaLoad] = useState(""); //data load
  const [isUpload, setIsUpload] = useState(false); //check is upload

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  //state
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (actions === "edit-lab-item" && dataProps) {
      //data Edit
      const Name = dataProps?.Name;
      const InitialValue = dataProps?.InitialValue;
      const NormalMax = dataProps?.NormalMax;
      const NormalMin = dataProps?.NormalMin;
      const ProcessTime = dataProps?.ProcessTime;
      const Unit = dataProps?.Unit;
      const UnitAlias = dataProps?.UnitAlias;

      //set value default
      setValue("Name", Name, { shouldDirty: false });
      setValue("InitialValue", InitialValue, { shouldDirty: false });
      setValue("NormalMax", NormalMax, { shouldDirty: false });
      setValue("NormalMin", NormalMin, { shouldDirty: false });
      setValue("ProcessTime", ProcessTime, { shouldDirty: false });
      setValue("Unit", Unit, { shouldDirty: false });
      setValue("UnitAlias", UnitAlias, { shouldDirty: false });
      setContent(dataProps.Explanation);
    }

    if (actions === "add-lab-item") {
      reset({ keepIsValid: false, keepValues: false });
    }
  }, [actions, dataProps, setValue, reset]);

  const handleType = (e) => {
    const value = e.target.value;
    //ResetData
    setAudioData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    //Nếu type là data load ban đầu
    if (dataMediaLoad.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
    }
  };

  // handleInput
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    //Set File FormData
    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  //Upload Audio
  const onFileAudioUpload = (event) => {
    const fileAudio = event.target.files[0];
    const urlPreview = URL.createObjectURL(fileAudio);
    setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
    setAudioData(fileAudio);
    setIsUpload(true);
  };

  const handleSave = (data) => {
    if (actions === "edit-lab-item") {
      const params = {
        Action: "Update",
        Target: "LabItem",
        TimePointId: timePointId,
        LabItem: {
          LabItemId: dataProps.Id,
          Name: data.Name,
          InitialValue: data.InitialValue,
          ProcessTime: data.ProcessTime || 0,
          Unit: data.Unit || '',
          UnitAlias: data.UnitAlias || '',
          NormalMin: data.NormalMin || 0,
          NormalMax: data.NormalMax || 0,
          Explanation: content
        },
      };
      dispatch(atcUpdateLabItem(id, params));
    } 
    
    if (actions === "add-lab-item") {
      const params = {
        Action: "Create",
        Target: "LabItem",
        TimePointId: timePointId,
        LabItem: {
          LabPanelId: labPanelId,
          Name: data.Name,
          InitialValue: data.InitialValue,
          ProcessTime: data.ProcessTime || 0,
          Unit: data.Unit || '',
          UnitAlias: data.UnitAlias || '',
          NormalMin: data.NormalMin || 0,
          NormalMax: data.NormalMax || 0,
          Explanation: content
        },
      };
      dispatch(atcCreateLabItem(id, params));
    }
    toggle();
  };

  const isType = stateData && stateData.MediaType;
  const urlValue = stateData && stateData.MediaUrl;
  console.log(audioData, imageData, isUpload);
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        style={{ maxWidth: "800px", padding: "0 10px" }}
        backdrop={`static`}
      >
        <ModalHeader toggle={toggle}>
          {actions === "add-lab-item" ? "Add Lab Item" : "Edit Lab Item"}
        </ModalHeader>
        <ModalBody>
          <Nav className="tabBorder" tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggleTabs("1");
                }}
              >
                Values
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggleTabs("2");
                }}
              >
                Media
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Form>
                <Row>
                  <Col md={6}>
                    <h4 className="heading-4 --text-primary">
                      Basic Information
                    </h4>
                    <FormGroup>
                      <Label for="Name">Name:</Label>
                      <Input
                        type="text"
                        placeholder="Enter name of lab sign"
                        {...register("Name", { required: true })} //first
                        invalid={errors.Name?.type === "required"}
                        onChange={(e) => {
                          setValue("Name", e.target.value, { shouldValidate: true });
                        }}
                        defaultValue={watch("Name")}
                        autoComplete="off"
                      />
                      {errors.Name?.type === "required" && (
                        <div className="invalid-feedback d-block">
                          This is required.
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="InitialValue">Initial Value:</Label>
                      <Input
                        type="text"
                        name="InitialValue"
                        id="InitialValue"
                        {...register("InitialValue", { required: true })}
                        placeholder="Initial value of lab sign at presentation"
                        invalid={errors.InitialValue?.type === "required"}
                        onChange={(e) => {
                          setValue("InitialValue", e.target.value, { shouldValidate: true });
                        }}
                        defaultValue={watch("InitialValue")}
                        autoComplete="off"
                      />
                      {errors.InitialValue?.type === "required" && (
                        <div className="invalid-feedback d-block">
                          This is required.
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Label for="Unit">Unit:</Label>
                      <Input
                        type="text"
                        placeholder="lab sign unit"
                        invalid={errors.Unit?.type === "required"}
                        onChange={(e) => {
                          setValue("Unit", e.target.value, { shouldValidate: true });
                        }}
                        defaultValue={watch("Unit")}
                        autoComplete="off"
                      />
                      {errors.Unit?.type === "required" && (
                        <div className="invalid-feedback d-block">
                          This is required.
                        </div>
                      )}
                      <p className="form-help mt-1">
                        No special characters allowed - use alias below
                      </p>
                    </FormGroup>
                    <FormGroup>
                      <Label for="UnitAlias">Unit Alias:</Label>
                      <Input
                        type="text"
                        name="UnitAlias"
                        id="UnitAlias"
                        placeholder="Unit alias"
                        invalid={errors.UnitAlias?.type === "required"}
                        onChange={(e) => {
                          setValue("UnitAlias", e.target.value, { shouldValidate: true });
                        }}
                        defaultValue={watch("UnitAlias")}
                        autoComplete="off"
                      />
                      {errors.UnitAlias?.type === "required" && (
                        <div className="invalid-feedback d-block">
                          This is required.
                        </div>
                      )}
                      <p className="form-help mt-1">
                        This alias can be used to display unit special
                        characters or format.
                      </p>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <h4 className="heading-4 --text-primary">
                      Reference Range
                    </h4>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="NormalMin">Min:</Label>
                          <Input
                            type="text"
                            name="NormalMin"
                            id="NormalMin"
                            placeholder=""
                            invalid={errors.NormalMin?.type === "required"}
                            onChange={(e) => {
                              setValue("NormalMin", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("NormalMin")}
                            autoComplete="off"
                          />
                          {errors.NormalMin?.type === "required" && (
                            <div className="invalid-feedback d-block">
                              This is required.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="NormalMax">Max:</Label>
                          <Input
                            type="text"
                            name="NormalMax"
                            id="NormalMax"
                            placeholder=""
                            invalid={errors.NormalMax?.type === "required"}
                            onChange={(e) => {
                              setValue("NormalMax", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("NormalMax")}
                            autoComplete="off"
                          />
                          {errors.NormalMax?.type === "required" && (
                            <div className="invalid-feedback d-block">
                              This is required.
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className="mb-2" md={12}>
                        <p className="form-help mt-1">
                          For non-numerical attributes, provide the normal value
                          in the "Min" textbox and leave "Max" blank
                        </p>
                      </Col>
                      <Col md={12}>
                        <FormGroup>
                          <Label for="ProcessTime">Process Time</Label>
                          <InputGroup>
                            <Input
                              type="number"
                              name="ProcessTime"
                              id="ProcessTime"
                              placeholder=""
                              invalid={errors.ProcessTime?.type === "required"}
                              onChange={(e) => {
                                setValue("ProcessTime", e.target.value, { shouldValidate: true });
                              }}
                              defaultValue={watch("ProcessTime")}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>mins</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                          {errors.ProcessTime?.type === "required" && (
                            <div className="invalid-feedback d-block">
                              This is required.
                            </div>
                          )}
                          <p className="form-help mt-1">
                            The number of minutes for the results of to become
                            available
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Label for="Content">Explanation</Label>
                    <JoditEditor
                      ref={editor}
                      value={content || ""}
                      config={Config.CONFIG_JODIT}
                      tabIndex={1} // tabIndex of textarea
                      // preferred to use only this option to update the content for performance reasons
                      onBlur={(newContent) => setContent(newContent)}
                    />
                  </Col>
                </Row>
              </Form>
            </TabPane>
            <TabPane tabId="2">
              <div className="mediaContainer">
                <DropDownMedia onChange={handleType} value={isType} />
                {isType === "VideoLink" && (
                  <VideoLink value={urlValue} onChange={handleInput} />
                )}
                {isType === "ImageFile" && (
                  <ImageFile onChange={handleUploadImage} value={urlValue} />
                )}
                {isType === "AudioFile" && (
                  <AudioFile onChange={onFileAudioUpload} value={urlValue} />
                )}
              </div>
            </TabPane>
          </TabContent>
          {/* /end */}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={handleSubmit(handleSave)}
          >
             {actions === "add-lab-item" ? "Add" : "Save"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditLabs;