import React from 'react';
import { Button } from "reactstrap";

function AddOption(props) {
    const { toggle } = props && props;
    return (
        <div className="text-center">
            <Button className="--btn-primary" onClick={toggle}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Option
            </Button>
        </div>
    );
};

export default AddOption;