import React from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function DropdownGroupAvailability(props) {
    const { toggleDropdown, handleAllAvailable, dropdownOpen, isLoading } = props && props;
    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={() => toggleDropdown('availability')}
            className="ml-2"
            disabled={isLoading}
        >
            <DropdownToggle className="btn-brand d-flex align-items-center" caret color="primary">
                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Set Availability
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem
                    onClick={() => handleAllAvailable("Available")}
                >
                    All Available
                </DropdownItem>
                <DropdownItem
                    onClick={() => handleAllAvailable("Unavailable")}
                >
                    All Unavailable
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default DropdownGroupAvailability;