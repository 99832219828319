import React from "react";

//Boostrap
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

function ModalShowPrompt(props) {
    const { isOpen, handleShowPrompt, data, toggleAll } = props && props;
    return (
        <Modal isOpen={isOpen} toggle={handleShowPrompt} backdrop={`static`} style={{ maxWidth: '800px' }}>
            <ModalHeader toggle={handleShowPrompt}>
                Prompt
            </ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: data?.system_prompt || '' }}></div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary" onClick={toggleAll}>
                    Close
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalShowPrompt;