import React, { useState, useEffect } from "react";

//redux
import { useSelector } from "react-redux";

//boostrap
import { Col } from "reactstrap";

//components
import { ModalReferences } from "../learner/StandardScreen/References";
import { ModalNotes } from "../learner/StandardScreen";
import { TaskActionTop, TaskActionBottom } from '../layouts';

function SidebarLearn(props) {
  //props
  const { handleOverideEffectMenu } = props && props;

  const { isOpen, resCase } = props && props;
  const questionData = useSelector(
    (state) => (state?.question?.data) || {}
  );

  //state
  const [modal, setModal] = useState({
    References: false,
    Notes: false,
  });

  const toggle = (type, value) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };
  
  //Check Remove and add effects
  useEffect(() => {
    const isQuestion = questionData?.Question;
    if (isQuestion !== null) {
      handleOverideEffectMenu();
    }
  }, [handleOverideEffectMenu, questionData])

  return (
    <Col
      lg={2}
      md={3}
      className={isOpen ? "caseWrap__right" : "caseWrap__right"}
    >
      <TaskActionTop
        {...props}
        questionData={questionData}
      />
      <TaskActionBottom
        {...props}
        questionData={questionData}
        toggle={toggle}
      />
      {/* /task */}
      <ModalReferences
        {...props}
        data={resCase}
        isOpen={modal.References}
        toggle={() => toggle("References")}
      />
      <ModalNotes
        {...props}
        data={resCase}
        isOpen={modal.Notes}
        toggle={() => toggle("Notes")}
      />
    </Col>
  );
}

export default SidebarLearn;
