import React, { useEffect, useState } from 'react';
import Select from 'react-select';

//useParams
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import { actFetchAccountInstitutionRequest } from "@actions";

//utils
import { apiCaller, formatReactSelect } from "@utils";

//lodash
import _ from "lodash";

function InputSuggest(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { item, TeamId, isLoading } = props && props;
    const Member = item?.Member;
    const RoleId = item?.RoleId;

    //store
    const storeAccInstitution = useSelector((state) => state?.accountInstitution?.data || {});
    const accountData = storeAccInstitution?.Accounts;

    //state
    const [selectedOption, setSelectedOption] = useState([]);
    const [dataSelect, setDataSelect] = useState({});

    //load
    useEffect(() => {
        const dropdownSelect = accountData?.length > 0 ? formatReactSelect(accountData, 'Email', 'Email') : [];
        let newOptions = [{
            id: 0,
            value: "",
            label: `Not assigned`
        }, ...dropdownSelect]
        const indexActive = _.findIndex(newOptions, { 'value': Member?.Email });
        setSelectedOption( Member ? newOptions[indexActive] : {
            id: 0,
            value: "",
            label: `Not assigned`
        });
        setDataSelect(newOptions || []);
    }, [accountData, Member]);

    //load Account
    useEffect(() => {
        dispatch(actFetchAccountInstitutionRequest());
    }, [dispatch]);

    //handleChange
    const handleChange = (value) => {
        setSelectedOption(value);
        const params = {
            "Action": "Update",
            "Target": "AssignTeamMember",
            "TeamId": TeamId,
            "RoleId": RoleId,
            "AccountEmail": value?.Email || ''
        }
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res && res.status === 200) {
                console.log('Success');
            } else {
                console.log('error');
            }
        });
    }

    return (
        <Select
            options={dataSelect || []}
            closeMenuOnSelect={true}
            value={selectedOption}
            onChange={value => handleChange(value)}
            isDisabled={isLoading}
        />
    );
}

export default InputSuggest;