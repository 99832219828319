import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Iconsax } from "@components-common";

function NewPasswordForm({ onSubmit }) {
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const newPassword = watch("newPassword", "");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup>
        <Label for="newPassword">New password</Label>
        <div className="d-flex position-relative">
          <Input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            id="newPassword"
            placeholder="Enter your new password"
            invalid={errors.newPassword}
            {...register("newPassword", { required: "New password is required" })}
          />
          {!errors.newPassword &&
            <div className="icn-view-pass icon-for-input" onClick={togglePasswordVisibility}>
              <Iconsax iconName={showPassword ? "eye" : "eye-off"} fill="#98A2B3" size={16} />
            </div>
          }
        </div>
        {errors.newPassword && <FormFeedback invalid>{errors.newPassword.message}</FormFeedback>}
      </FormGroup>
      <FormGroup>
        <Label className="ms-label" for="confirmNewPassword">Confirm new password</Label>
        <Input
          type={showPassword ? "text" : "password"}
          name="confirmNewPassword"
          id="confirmNewPassword"
          placeholder="Confirm your new password"
          invalid={errors.confirmNewPassword}
          {...register("confirmNewPassword", {
            required: "Please confirm your new password",
            validate: value => value === newPassword || "The passwords do not match"
          })}
        />
        {errors.confirmNewPassword && <FormFeedback invalid>{errors.confirmNewPassword.message}</FormFeedback>}
      </FormGroup>
      <Button color="primary" className="btn-brand-sm -btn-lg w-100 -shadown">
        Sign up
      </Button>
    </Form>
  );
}

export default NewPasswordForm;