import React, { useState } from "react";

//reactstrap
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
} from "reactstrap";

//utils
import { useQuery } from "@utils";

//classnames
import classnames from "classnames";

//components
import { QuestionSchema, MergedSchema } from '../TabsGroupSchema';

//boostrap
import { Row, Col } from "reactstrap";

//common
import { HeadingBox } from "@components/common";

function MainTabs(props) {
    const [activeTab, setActiveTab] = useState("1");
    let query = useQuery();
    let QuestionId = query.get("QuestionId");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const listTabs = [
        { id: "1", name: "This Question’s Schema", component: <QuestionSchema {...props} /> },
        { id: "2", name: "Merged Schema", component: <MergedSchema {...props} QuestionId={QuestionId} /> }
    ];

    return (
        <React.Fragment>
            <Row className="align-items-center">
                <Col md={12}>
                    <HeadingBox title={`Question #${QuestionId}` || ''} currentPage="Schema" />
                </Col>
            </Row>
            <Nav className="tabBorder" tabs>
                {listTabs?.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({ active: activeTab === item.id })}
                            onClick={() => {
                                toggle(item.id);
                            }}
                        >
                            <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                            {item.name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {listTabs?.map((item, index) => (
                    <TabPane key={index} tabId={item.id}>
                        {item.component}
                    </TabPane>
                ))}
            </TabContent>
        </React.Fragment>
    );
}

export default MainTabs;