import React, { useState } from "react";

//components
import { FiterData, MonitorTabs } from "../data";

//apiCaller
import { apiCaller } from "@utils";

function MainData(props) {

    //state
    const [isLoading, setLoading] = useState(false);
    const [stateData, setData] = useState([]);

    const handleSubmitFilter = (data) => {
        const params = {
            "action_type": "last_n_day",
            "delta_time": parseInt(data?.delta_time),
            "model_version": data?.model_version,
            "category": data?.category
        };
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);
                setLoading(false);
            } else {
                setLoading(false);
                console.log('error');
            }
        });
    }

    return (
        <React.Fragment>
            <FiterData
                {...props}
                isLoading={isLoading}
                handleSubmitFilter={handleSubmitFilter}
            />
            <MonitorTabs
                {...props}
                stateData={stateData || {}}
            />
        </React.Fragment>
    );
}

export default MainData;