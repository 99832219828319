import React from "react";

//components
import { EmailTemplateContainer } from "../EmailTemplate";

function ModalEmailTemplate(props) {
    return (
        <EmailTemplateContainer {...props} />
    );
}

export default ModalEmailTemplate;