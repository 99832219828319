import React from "react";

//partents
import { FormModal } from '@ui-partents/Modal';

//components
import FileUploadComponent from "../ReferenceLiblary/FileUploadComponent";

const ModalUploadReferenceDocument = ({ isOpen, toggleModal, title, loadDataReferenceBook, setListValue }) => {
    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={title}
            maxWidth="540px"
            hiddenIcon={true}
            hiddenButtonCancel={true}
        >
            <FileUploadComponent toggleModal={toggleModal} loadDataReferenceBook={loadDataReferenceBook} setListValue={setListValue} />
        </FormModal>
    );
};

export default ModalUploadReferenceDocument;