export const profileConstant = {
    UPDATE_PROFILE_PHOTO_REQUEST: 'UPDATE_PROFILE_PHOTO_REQUEST',
    UPDATE_PROFILE_PHOTO_SUCCESS: 'UPDATE_PROFILE_PHOTO_SUCCESS',
    UPDATE_PROFILE_PHOTO_FAILURE: 'UPDATE_PROFILE_PHOTO_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    GET_ACCOUNT_TIME_ZONE_REQUEST: 'GET_ACCOUNT_TIME_ZONE_REQUEST',
    GET_ACCOUNT_TIME_ZONE_SUCCESS: 'GET_ACCOUNT_TIME_ZONE_SUCCESS',
    GET_ACCOUNT_TIME_ZONE_FAILURE: 'GET_ACCOUNT_TIME_ZONE_FAILURE',

    SET_ACCOUNT_TIME_ZONE_REQUEST: 'SET_ACCOUNT_TIME_ZONE_REQUEST',
    SET_ACCOUNT_TIME_ZONE_SUCCESS: 'SET_ACCOUNT_TIME_ZONE_SUCCESS',
    SET_ACCOUNT_TIME_ZONE_FAILURE: 'SET_ACCOUNT_TIME_ZONE_FAILURE',

    GET_ACCOUNT_SETTINGS_REQUEST: 'GET_ACCOUNT_SETTINGS_REQUEST',
    GET_ACCOUNT_SETTINGS_SUCCESS: 'GET_ACCOUNT_SETTINGS_SUCCESS',
    GET_ACCOUNT_SETTINGS_FAILURE: 'GET_ACCOUNT_SETTINGS_FAILURE',

    UPDATE_ACCOUNT_SETTINGS_REQUEST: 'UPDATE_ACCOUNT_SETTINGS_REQUEST',
    UPDATE_ACCOUNT_SETTINGS_SUCCESS: 'UPDATE_ACCOUNT_SETTINGS_SUCCESS',
    UPDATE_ACCOUNT_SETTINGS_FAILURE: 'UPDATE_ACCOUNT_SETTINGS_FAILURE',

    CHANGE_AVATAR_PROFILE_REQUEST: 'CHANGE_AVATAR_PROFILE_REQUEST',
    CHANGE_AVATAR_PROFILE_SUCCESS: 'CHANGE_AVATAR_PROFILE_SUCCESS',
    CHANGE_AVATAR_PROFILE_FAILURE: 'CHANGE_AVATAR_PROFILE_FAILURE',

    GET_ACCOUNT_INFO_REQUEST: 'GET_ACCOUNT_INFO_REQUEST',
    GET_ACCOUNT_INFO_SUCCESS: 'GET_ACCOUNT_INFO_SUCCESS',
    GET_ACCOUNT_INFO_FAILURE: 'GET_ACCOUNT_INFO_FAILURE'
};