import { studiesConstants, storyboardConstants } from "@constants";
import _ from 'lodash';

function updateAvailabilityCommon(newState, action) {
    let updateAvailabilityCommon = newState?.data;
    const idAvailableCommon = action?.idAvailable;
    const isCheckedAvailabilityCommon = action?.isChecked;
    const indexAvailabilityCommon = _.findIndex(updateAvailabilityCommon, item => item.Id === idAvailableCommon);
    if (indexAvailabilityCommon !== -1) {
        updateAvailabilityCommon[indexAvailabilityCommon][`Available`] = isCheckedAvailabilityCommon;
    }
    return updateAvailabilityCommon;
}

export function loadStudies(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case studiesConstants.LOAD_STUDIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case studiesConstants.LOAD_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case studiesConstants.UPDATE_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload && action.payload.Studies,
                isLoading: false
            };
        case studiesConstants.CREATE_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload && action.payload,
                isLoading: false
            };
        case studiesConstants.DELETE_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload && action.payload,
                isLoading: false
            };
        case storyboardConstants.UDPATE_STUDY_CHANGE_SUCCESS:
            newState = { ...state };
            newState.data = action.payload && action.payload.Studies
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_ATTRIBUTE_SCORE_STUDY_SUCCESS:
            newState = { ...state };
            const AttributeId = action.AttributeId
            const indexItem = _.findIndex(newState?.data, item => parseInt(item.Id) === parseInt(AttributeId));
            if (indexItem !== -1) {
                newState.data[indexItem].DomainScores = action.payload
            }
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_COMMON_AVAILABILITY_SUCCESS:
            newState = { ...state };
            updateAvailabilityCommon(newState, action);
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.UPDATE_STUDY_AVAILABILITY_IN_BATCH_SUCCESS:
            newState = { ...state };
            newState.data = action.payload
            return {
                ...newState,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case studiesConstants.LOAD_STUDIES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function dataStudiesForElement(state = {}, action) {
    switch (action.type) {
        case studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function extractStudies(state = {}, action) {
    switch (action.type) {
        case storyboardConstants.EXTRACT_STUDIES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case storyboardConstants.EXTRACT_STUDIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case storyboardConstants.EXTRACT_STUDIES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};