import React, { useState, useEffect } from "react";

//react-hook-form
import { FormProvider, useForm } from "react-hook-form";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { schemaContanst } from "@constants";

//components
import { MainTabsEditCell } from "./QuestionSchemaTab";

function ModalAddCellDianostic(props) {
  const dispatch = useDispatch();
  
  //props
  const { isOpen, toggle, data } = props && props;

  let typeRelation = data?.typeRelation;
  const rowData = data?.item;
  const colData = data?.col;
  const questionId = colData?.question_id;

  //current edit
  const comments = data?.item?.comments;

  //form
  const {
    reset
  } = useForm();

  //state
  const [content, setContent] = useState(comments || '');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    //current edit
    const comments = data?.item?.comments;
    setContent(comments || '');
  }, [data]);

  const onSubmit = (val) => {
    const params = {
      Action: "UpdateConceptRelation",
      QuestionId: questionId,
      FirstQuestionConceptId: colData?.question_concept_id,
      SecondQuestionConceptId: rowData?.question_concept_id,
      Description: val?.description,
      Comments: content,
      Score: val?.Score,
      identification: {
        concepts: val?.concepts,
        regex: val?.regex,
        use_concept: val?.use_concept,
        use_regex: val?.use_regex,
      }
    }

    setLoading(true);
    dispatch({ type: schemaContanst.UPDATE_CONCEPT_REQUEST, isLoading: true });
    //Call Api
    apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: schemaContanst.UPDATE_CONCEPT_SUCCESS, payload: data });
        setLoading(false);
        toggle();
      } else {
        dispatch({ type: schemaContanst.UPDATE_CONCEPT_FAILURE, error: "error" });
        setLoading(false);
        toggle();
      }
    });

    if (typeRelation === 'Add') {
      //Reset data
      reset({ keepIsValid: false, keepValues: false });
    }
  }

  const methods = useForm();
  return (
    <Modal
      style={{ maxWidth: "800px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <p><span className="--text-primary">{typeRelation || ''} Relation Between</span></p>
        <p><span className="text-dark">{colData?.name}</span> and <span className="text-dark">{rowData?.name}</span></p>
      </ModalHeader>
      <ModalBody>
        <FormProvider {...methods} >
          <MainTabsEditCell
            {...props}
            handleSetContent={newContent => setContent(newContent)}
            stateComment={content}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <Button
          className="d-flex align-items-center"
          color="primary"
          onClick={methods?.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          {typeRelation === 'Add' ? 'Add' : 'Save'}
        </Button>
        <Button onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddCellDianostic;