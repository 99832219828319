import React, { useMemo } from 'react';
import { useSelector } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { useParams } from "react-router-dom";

function ButtonNextTimepoint({
    dataTour,
    overRideEffect,
    taskNexTour,
    handleClickBtnNextTimePoint
}) {
    const { id } = useParams();

    // Selectors
    const getStore = useSelector(state => state?.checkMissingTask?.data || {});
    const checkAutoNextTimepoint = useSelector(state => state.checkAutoNextTimepoint || {});
    const nextTimepointStatusReducer = useSelector(state => state.checkStatusNextTimePoint || {});

    const isMissingTask = getStore.MissingTask;
    const statusOk = getStore?.status === "OK";
    const autoNextLoading = checkAutoNextTimepoint?.isLoading;
    const loadingBtnNextTimepoint = nextTimepointStatusReducer?.isLoading;
    const btnNextLoading = loadingBtnNextTimepoint || autoNextLoading;

    // Memoized data
    const fileList = useMemo(() => dataTour?.filter(event => event?.selector?.includes('step-4')), [dataTour]);
    const buttonClassNames = useMemo(() => getClassNames(overRideEffect, isMissingTask, statusOk, loadingBtnNextTimepoint), [overRideEffect, isMissingTask, statusOk, loadingBtnNextTimepoint]);

    // Event handler
    const handleClick = () => {
        if (isMissingTask === 'advance' || statusOk) {
            handleClickBtnNextTimePoint(id, "advance");
        }
    };

    return (
        <div className={buttonClassNames} onClick={handleClick}>
            <div data-task="advance" data-tour={fileList?.[0]?.selector}>
                {btnNextLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Next
            </div>
            <PopperItem
                content={getContent()}
                styleInline={{ right: '220px', top: '-100%', position: 'absolute' }}
                postition="right"
                isOpen={taskNexTour === 'next-timepoint'}
                nextTour="end-tour"
            />
        </div>
    );

    function getClassNames(overRideEffect, isMissingTask, statusOk, loadingBtnNextTimepoint) {
        return [
            "btn",
            "btn-next-timepoint",
            "w-100",
            overRideEffect && "remove-effect",
            statusOk && "show-effect",
            isMissingTask !== 'advance' && !statusOk && "disabled"
        ].filter(Boolean).join(" ");
    }

    function getContent() {
        return (
            <div>
                When you have finished all tasks, click <strong>Next Timepoint</strong> to advance.
            </div>
        );
    }
}

export default ButtonNextTimepoint;