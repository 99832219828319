import React, { useState, useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";

//common
import { Iconsax } from "@components-common";

//constants
import { teachConstants } from "@constants";

//components common
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";
import { ConditionalNote, DomainList, QuestionListItem } from "../QuestionCommon";
import { ModalDeleteCommon } from "@ui-partents/Modal";

//call Action
import {
  actQuestionOrder,
  atcloadQuestionsFromTemplates,
} from "@actions";

//liblary
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//common
import { DataEmty } from "@components-common";

//test
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

function DragQuestion(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { data, disableSort, timePointId, task, handleEditQuestion } = props && props;

  const [itemSort, setItemSort] = useState(
    (data && data) || []
  );
  const [isSort, setIsSort] = useState(false);
  const [stateModal, setModal] = useState({
    deleteQuestion: false,
  });
  const [itemActive, setItemActive] = useState({});
  const [isLoading, setLoading] = useState({
    addTemplate: false,
    cloneQuestion: false,
    deleteQuestion: false
  });

  //Update Array Store
  useEffect(() => {
    setItemSort((data && data) || []);
    ReactTooltip.rebuild();
  }, [data]);

  //Load Question Blank
  useEffect(() => {
    const params = {
      Action: "Load",
      Target: "QuestionBank",
      Source: "Template",
    };
    dispatch(atcloadQuestionsFromTemplates(id, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SortableItem = sortableElement(({ item }) => {
    const flowConditionsValue = item.FlowConditions && item.FlowConditions[0];
    const QuestionId = item?.QuestionId;
    return (
      <div className="question-task__item drag-question-item" data-id={QuestionId}>
        {!disableSort &&
          <div className="drag-question-icon">
            <Iconsax iconName={`drag-drop`} fill="#939393" />
          </div>
        }
        <div className="question-taskCt">
          <div className="question-taskCt__left">
            <div className="question-task-info">
              <div className="question-id">
                <Iconsax iconName={`question-circle`} fill="#079455" size={15} />
                <span className="question-id__text">#{QuestionId}</span>
              </div>
              <QuestionListItem item={item} />
            </div>
            <ConditionalNote flowConditionsValue={flowConditionsValue} />
            <DomainList item={item} />
            <div className="question-task-desc"
              dangerouslySetInnerHTML={{
                __html: item.QuestionContent,
              }}
            ></div>
          </div>
          <div className="question-taskCt__right">
            <div className="d-flex align-items-center justify-content-end">
              <BtnIconEditV2 handleEditButtonClick={() => handleEditQuestion("Edit_Question", item)} colorIcon={`#16B364`} />
              <BtnIconDeleteV2 handleDelete={() => toggle("deleteQuestion", item)} />
            </div>
          </div>
        </div>
      </div>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="lsQuestion">{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItemSort(arrayMove(itemSort, oldIndex, newIndex));
    setIsSort(true);
  };

  useEffect(() => {
    const requestApiSort = (dataSort) => {
      const params = {
        Action: "Update",
        Target: "Questions_Order",
        TimePointId: timePointId,
        Task: task,
        QuestionsOrderByIds: dataSort,
      };

      dispatch(actQuestionOrder(id, params));

      //Done
      setIsSort(false);
    };

    if (itemSort && isSort) {
      var result = _.map(itemSort, "QuestionId");
      const newArray = result.map((item) => {
        return item;
      });
      requestApiSort(newArray);
    }
  }, [itemSort, isSort, dispatch, id, timePointId, task]);

  // Handle Modal
  const toggle = (name, item) => {
    setModal((prevState) => ({ ...prevState, [name]: !stateModal[name] }));
    setItemActive(item);
  };

  // Handle Remove Question
  const handleRemoveQuestion = () => {
    const params = {
      Action: "Delete",
      Target: "Question",
      TimePointId: timePointId,
      Task: task,
      QuestionId: itemActive?.QuestionId,
    };

    const actionList = {
      resquest: teachConstants?.DELETE_QUESTION_REQUEST,
      success: teachConstants?.DELETE_QUESTION_SUCCESS,
      failure: teachConstants?.DELETE_QUESTION_FAILURE,
    }
    sendApiByType(id, params, actionList, 'deleteQuestion');
  }

  //send Api with action
  const sendApiByType = (id, params, actionList, type) => {
    setLoading((prevState) => ({ ...prevState, [type]: true }));
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      dispatch({ type: actionList?.resquest, isLoading: true });
      if (res?.status === 200) {
        const data = res?.data;
        //Set Loading And Close
        setModal((prevState) => ({ ...prevState, [type]: false }));
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.success, payload: data });
      } else {
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.failure, isLoading: false });
        setModal((prevState) => ({ ...prevState, [type]: false }));
      }
    });
  }

  return (
    <React.Fragment>
      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis="y"
        helperClass="question-task"
        distance={1}
      >
        <div className="question-task">
          {itemSort.map((value, index) => (
            <SortableItem
              key={`item-${value.QuestionId}`}
              index={index}
              item={value}
              disabled={disableSort}
            />
          ))}
        </div>
      </SortableContainer>
      {/* /Modal Clone */}
      {itemSort.length === 0 && <DataEmty content="No Data" />}
      <ModalDeleteCommon
        title={`Are you certain that you would like to delete this question?`}
        sub={` This action is not reversible.<br />All data related to this question will also be deleted.`}
        isOpen={stateModal.deleteQuestion}
        toggle={() => toggle('deleteQuestion', {})}
        handleRemove={handleRemoveQuestion}
        isLoading={isLoading?.deleteQuestion}
      />
    </React.Fragment>
  );
}

export default DragQuestion;