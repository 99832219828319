import React, { useState } from "react";

//partents
import { ModalDeleteCommon } from "@ui-partents/Modal";

//redux
import { useDispatch } from "react-redux";

//actions
import { atcDeleteLearnerToCohort } from "@actions";

function ModalDeleteLearner(props) {
  //props
  const { isOpen, toggle, item, cohortObject } = props && props;
  const dispatch = useDispatch();

  //state
  const [isLoading, setIsLoading] = useState(false);

  const hanldeRemove = () => {
    setIsLoading(true);
    const params = {
      Action: "RemoveLearners",
      CohortId: cohortObject?.CohortId,
      LearnerIds: [item && item.id]
    };
    dispatch(atcDeleteLearnerToCohort(params)).then(res => {
      if (res?.status === 200) {
        toggle(); // Close modal
        setIsLoading(false);
      }
      // Các bước xử lý tiếp theo sau khi thành công
    }).catch(error => {
      console.error('Delete failed, error:', error);
      setIsLoading(false);
      // Các bước xử lý khi có lỗi
    }).finally(() => {
      // Set loading state back to false once the process is done
      setIsLoading(false);
    });
  };

  const firstname = item?.firstname;
  const lastname = item?.lastname;
  return (
    <ModalDeleteCommon
      title={`Remove User`}
      sub={`Are you sure you want to remove <strong>${firstname} ${lastname}</strong> from this cohort? This action cannot be undone. `}
      isOpen={isOpen}
      toggle={toggle}
      handleRemove={hanldeRemove}
      hideIcon={true}
      isLoading={isLoading}
    />
  );
}

export default ModalDeleteLearner;
