import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { CreatableMultiselect, DataEmty } from "@components/common";

//untils
import { LoaderPage, messageData, apiCaller, formatReactSelect } from "@utils";

//lodash
import _ from "lodash";

//call Action
import { atcLoadPlanDetails, actFetchAccountInstitutionRequest } from "@actions";
import { planConstant } from "@constants";

//elements
import { ButtonDelete } from '@components/common/elements';

function AccessTab(props) {
    const dispatch = useDispatch();

    //props
    const { item } = props && props;

    //store
    const dataStorePlan = useSelector((state) => state.loadPlanDetails);
    const storeAccInstitution = useSelector((state) => state?.accountInstitution?.data || {});
    const accountData = storeAccInstitution?.Accounts;

    //state
    const [isLoading, setLoading] = useState(false);
    const [listInvitedEmails, setInvitedEmails] = useState([]);

    //load Account
    useEffect(() => {
        dispatch(actFetchAccountInstitutionRequest());
    }, [dispatch]);


    //Load Lauch Plan Details
    useEffect(() => {
        const params = {
            Action: "LoadDetails",
            LaunchPlanId: item?.LaunchPlanId,
        };
        item?.LaunchPlanId && dispatch(atcLoadPlanDetails(params));
    }, [dispatch, item]);

    // Remove  Managers to Launch Plan
    const handleRemove = (value) => {
        setLoading(true);
        const params = {
            Action: "RemoveManagers",
            LaunchPlanId: item?.LaunchPlanId,
            AccountEmails: [value?.email]
        }

        dispatch({
            type: planConstant.REMOVE_MANAGERS_TO_LAUNCH_PLAN_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/launchplan/`, "POST", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: planConstant.REMOVE_MANAGERS_TO_LAUNCH_PLAN_SUCCESS,
                    payload: data,
                });
                setLoading(false);
            } else {
                dispatch({
                    type: planConstant.REMOVE_MANAGERS_TO_LAUNCH_PLAN_FAILURE,
                    error: "error",
                });
                setLoading(false);
            }
        });
    };

    //Add Managers to Launch Plan
    const addManagerstoLaunchPlan = () => {
        //Convert Array to String
        if (listInvitedEmails?.length > 0) {
            var arrEmails = _.map(listInvitedEmails, function (element, idx) {
                return element.value;
            });
        }
        const params = {
            Action: "AddManagers",
            LaunchPlanId: item?.LaunchPlanId,
            AccountEmails: arrEmails
        }

        // Add Managers to Launch Plan
        setLoading(true);
        dispatch({
            type: planConstant.ADD_MANAGERS_TO_LAUNCH_PLAN_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/launchplan/`, "POST", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: planConstant.ADD_MANAGERS_TO_LAUNCH_PLAN_SUCCESS,
                    payload: data,
                });
                setLoading(false);
            } else {
                dispatch({
                    type: planConstant.ADD_MANAGERS_TO_LAUNCH_PLAN_FAILURE,
                    error: "error",
                });
                setLoading(false);
            }
        });
    };

    const columns = [
        {
            dataField: "Id",
            text: "ID",
            hidden: true,
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "lastname",
            text: "Last Name",
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "firstname",
            text: "First Name",
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "email",
            text: "Email",
            headerClasses: "--text-primary font-weight-500",
            headerStyle: (colum, colIndex) => {
                return { width: "40%" };
            },
        },
        {
            dataField: "Actions",
            text: "",
            headerStyle: (colum, colIndex) => {
                return { width: "50px", textAlign: "center" };
            },
            headerClasses: "--text-primary font-weight-500 align-middle",
            formatter: (cell, row) => {
                return (
                    <ButtonDelete handleDelete={() => handleRemove(row)} />
                );
            },
        },
    ];

    const { SearchBar } = Search;
    const dataResponse = dataStorePlan?.data?.Managers;
    const dropdownSelect = accountData?.length > 0 ? formatReactSelect(accountData, 'Email', 'Email') : [];
    return (
        <React.Fragment>
            {dataStorePlan.isLoading && <LoaderPage />}
            {!dataStorePlan.isLoading && (
                <Container>
                    <Row className="justify-content-start mb-3">
                        <Col sm={`12`}>
                            <div className="dropdownMutiCt">
                                <div className="dropdownMutiCt__left">
                                    <CreatableMultiselect
                                        handleChangeData={(data) => setInvitedEmails(data)}
                                        placeholder={`Click and select managers to add to this launch plan`}
                                        DataOption={dropdownSelect || []}
                                    />
                                </div>
                                <div className="dropdownMutiCt__right">
                                    <Button
                                        className="--btn-primary d-flex align-items-center ml-auto text-nowrap"
                                        onClick={addManagerstoLaunchPlan}
                                        disabled={isLoading || (listInvitedEmails?.length > 0 ? false : true)}
                                    >
                                        {isLoading && (
                                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                        )}
                                        Add Manager(s)
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <ToolkitProvider
                        keyField="Id"
                        data={dataResponse || []}
                        columns={columns}
                        search
                    >
                        {(toolkitprops) => (
                            <React.Fragment>
                                <Row className="justify-content-start mb-3 mt-3">
                                    <Col md={6} sm={6} className="py">
                                        <div className="formSearchInline">
                                            <Label for="search" className="mr-2 mb-0">
                                                Search:
                                            </Label>
                                            <SearchBar
                                                {...toolkitprops.searchProps}
                                                placeholder="Enter name or email"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <BootstrapTable
                                    condensed
                                    striped
                                    wrapperClasses="table-responsive"
                                    bordered={false}
                                    noDataIndication={() => <DataEmty content={messageData?.filter} />}
                                    {...toolkitprops.baseProps}
                                />
                            </React.Fragment>
                        )}
                    </ToolkitProvider>
                </Container>
            )}
        </React.Fragment>
    );
}

export default AccessTab;