import React, { useState } from "react";

//router
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";
import { storyboardConstants, actionConstants, pnorderConstants } from "@constants";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteIntervention(props) {
  let { id } = useParams();
  let pathName = (window.location.pathname.includes("/elements/medications") || window.location.pathname.includes("/elements/actions"));
  const dispatch = useDispatch();

  //props
  const { interventionId, categoryName, categoryType, toggleDel, isOpen, timePointId } = props && props;
  const idCategory = props && props.activeCategory;

  //state
  const [isLoading, setLoading] = useState(false);

  const handleRemove = () => {
    setLoading(true);
    const params = {
      Action: "Delete",
      Target: "Intervention",
      InterventionId: interventionId,
    };

    switch (categoryType) {
      case "Action":
        //Reload Data
        if (pathName) {
          params["ReloadData"] = "Actions";
          params["TimePointId"] = timePointId;
        }
        atcDeleteInterventionForActions(id, params, idCategory);
        break;
      case "pnOrders":
        atcDeleteInterventionForPNOrder(id, params, categoryName);
        break;
      default:
        //Reload Data
        if (pathName) {
          params["ReloadData"] = "Medications";
          params["TimePointId"] = timePointId;
        }
        atcDeleteInterventionForMediactions(id, params, idCategory);
    }
  };

  const atcDeleteInterventionForMediactions = (id, params, idCategory) => {
    const isLoading = true;
    //Call Api
    dispatch({ type: storyboardConstants.DELETE_INTERVENTION_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.DELETE_INTERVENTION_SUCCESS,
          payload: data,
          InterventionId: params.InterventionId,
          idCategory: idCategory
        });

        //Close
        toggleDel();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.DELETE_INTERVENTION_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  const atcDeleteInterventionForActions = (id, params, idCategory) => {
    const isLoading = true;
    dispatch({
      type: actionConstants.DELETE_INTERVENTION_FOR_ACTIONS_REQUEST,
      isLoading
    });
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch({
          type: storyboardConstants.DELETE_INTERVENTION_FOR_ACTIONS_SUCCESS,
          payload: data,
          InterventionId: params.InterventionId,
          idCategory: idCategory,
        });

        //Close
        toggleDel();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.DELETE_INTERVENTION_FOR_ACTIONS_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  const atcDeleteInterventionForPNOrder = (id, params, categoryName) => {
    const isLoading = true;
    dispatch({
      type: pnorderConstants.DELETE_INTERVENTION_FOR_PNORDER_REQUEST,
      isLoading
    });
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch({
          type: pnorderConstants.DELETE_INTERVENTION_FOR_PNORDER_SUCCESS,
          payload: data,
          InterventionId: params.InterventionId,
          nameCategory: categoryName
        });

        //Close
        toggleDel();
        setLoading(false);
      } else {
        dispatch({
          type: pnorderConstants.DELETE_INTERVENTION_FOR_PNORDER_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleDel}
      backdrop={`static`}
    >
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you certain that you would like to delete this item?
        </h4>
        <p className="mt-2 text-center">
          All data related to this item will be removed. This action is not
          reversible.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Delete
        </Button>
        <Button
          className="btn btn-secondary"
          onClick={toggleDel}
        >
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteIntervention;