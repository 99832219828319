import React, { useState } from 'react';
import styled from 'styled-components';

const Button = styled.div`
   position: fixed; 
   width: 100%;
   right: 30px;
   bottom: 40px;
   height: 32px;
   width: 32px;
   z-index: 1000;
   cursor: pointer;
   background: #0089c3;
   color: white;
   border-radius: 50%;
   font-size: 22px;
   display: flex;
   align-items: center;
   justify-content: center;
`

const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true)
        }
        else if (scrolled <= 300) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
                in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);
    
    return (
        <Button onClick={scrollToTop} style={{ display: visible ? 'flex' : 'none' }}>
            <div>
                <i className="fa fa-angle-up" aria-hidden="true"></i>
            </div>
        </Button>
    );
}

export default ScrollButton;