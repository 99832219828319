import React from 'react'
import { TableLibrary } from '@components/teachV2/teachScriptLibrary'

const TeachScriptLibrary = () => {

  return (
    <div className='wrapper teachLibraryPage'>
      <p className='teach-title'>Case Scenario Library</p>
      <TableLibrary />
    </div>
  )
}

export default TeachScriptLibrary
