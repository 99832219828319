import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionBankOrg } from "@actions"
import { LoaderText } from "@utils";

//ui-partents
import { MessageEmtyData } from "@ui-partents/Message";
import { SearchCustomForTable } from "@ui-partents/Search";
// import { Rating } from '@ui-partents/Rating';

// Import the pagination configuration
import {paginationOptions} from "@utils";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TagsToolTip } from "@ui-partents/Tags";

const TextDetail = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #0089C2;
  cursor: pointer;  
`

const TableMcqBank = (props) => {
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBankOrg || []);
  const history = useHistory()

  useEffect(() => {
    dispatch(getQuestionBankOrg({
      Action: "LoadOrganizationQuestionBank"
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const handleUnshareReference = async (data) => {
  //   const dataShare = await dispatch(shareMediaOrg({
  //     Action: "UnShareToOrganization",
  //     QuestionItemId: data?.QuestionItemId
  //   }))
  //   if (dataShare?.data?.status === 200) {
  //     toast.success('Successfully shared to Organization Question Bank', {
  //       position: "bottom-right",
  //       autoClose: 4000,
  //       hideProgressBar: false,
  //       closeOnClick: false,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     dispatch(getQuestionBankOrg({
  //       Action: "LoadOrganizationQuestionBank"
  //     }))
  //     dispatch(getQuestionBank())
  //   }
  // }

  const columns = [
    {
      dataField: "title",
      text: "Question",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "QuestionType",
      text: "Owner",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Owner || "med2lab.com"}
          </div>
        );
      },
    },
    {
      Field: "tags",
      text: "Tag(s)",
      headerClasses: "align-middle text-center",
      headerStyle: { width: "168px" },
      formatter: (cell, row) => {
        return (
          <TagsToolTip
            tags={row?.Tags}
            documentId={row?.QuestionItemId}
            color={'blue'}
          />
        );
      },
    },
    // {
    //   dataField: "QuestionType",
    //   text: "Rating",
    //   headerStyle: { width: "190px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <Rating rating={0} />
    //     );
    //   },
    //   filterValue: (cell, row) => row.Contents.question
    // },
    {
      dataField: "QuestionType",
      text: "Question Type",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center question-type ${cell === "MCQ" ? "type-mcq" : ""}`}>
            <span></span>
            <p>
              {cell}
            </p>
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    // {
    //   dataField: "",
    //   text: "Action",
    //   headerClasses: "text-center",
    //   headerStyle: { width: "100px" },
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="d-flex align-items-center justify-content-center action-orgRefer">
    //         {(row?.Media?.owned_by_account || row?.owned_by_account) && (
    //           <p onClick={() => handleUnshareReference(row)}>
    //             Unshare
    //           </p>
    //         )}
    //       </div>
    //     );
    //   },
    // }
    {
      dataField: "QuestionType",
      text: "Action",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          !row?.Rubric?.instruction_text ? (
            <div className={`d-flex align-items-center`}>
              <TextDetail onClick={() => {
                const questionDetail = JSON.stringify(row)
                history.push({
                  pathname: `/teach/question-bank/edit`,
                  state: {  // location state
                    question: questionDetail, 
                    type: "org"
                  },
                })
              }}>View Details</TextDetail>
            </div>
          ) : (
            <></>
          )
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.data?.length}</div>
              <div className="boxSearch">
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder={`Search`}
                />
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={listQuestionBank?.data || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <MessageEmtyData 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listQuestionBank?.data?.length || 0; // Determine the total size dynamically
  
  // Retrieve dynamic pagination settings
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.data?.length === 0 ? (
          <MessageEmtyData 
            title="No Questions Available Yet"
            text="Please check back later for updates!"
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableMcqBank
