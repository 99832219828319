import React from 'react';

//Icon
import { Icon } from '@components/common';
import Idea from '@images/teachV2/idea.svg';

//Style
import styled from 'styled-components';

const NoteContainer = styled.div`
  display: flex;
  gap: 6px;
  p {
    color: #475467;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    text-align: start;
  }
  .choice-note__icon {
    padding: 7px;
    height: 24px;
    border-radius: 50%;
    background: #FEF0C7;
  }
`;

const ChoiceNote = () => (
  <NoteContainer>
    <div className="choice-note__icon d-flex align-items-center justify-content-between">
      <Icon src={Idea} />
    </div>
    <p>Attaching a source helps reduce risks of hallucination while emphasizing the most relevant learning objectives.</p>
  </NoteContainer>
);

export default ChoiceNote;