import { registerConstants } from "@constants";

export function register (state = {}, action) {
    switch (action.type) {
        case registerConstants.REGISTER_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case registerConstants.REGISTER_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case registerConstants.REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function verifyRegister (state = {}, action) {
    switch (action.type) {
        case registerConstants.VERIFY_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case registerConstants.VERIFY_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case registerConstants.VERIFY_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};