import React from "react";
import { Link } from "react-router-dom";

function ButtonEdit(props) {
  const { handleEdit, linkRef, url, target } = props && props;
  return (
    <React.Fragment>
      {linkRef ? (
        <Link to={url} target={target || `_self`} className="btn btn-sm --btn-primary ml-auto">
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </Link>
      ) : (
        <button className="btn btn-sm mx-1 btn-primary" onClick={handleEdit}>
          <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
        </button>
      )}
    </React.Fragment>
  );
}

export default ButtonEdit;