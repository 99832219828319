import React from "react";
import { Col, Spinner } from "reactstrap";

function TimeLineVertical(props) {

  const {
    activeTab,
    timeLineList,
    handleActive,
    handleTab,
    isLoading,
    taskLoading
  } = props && props;
  
  return (
    <Col lg={3}>
      <div className="alert alert-secondary mb-0 p-2" role="alert">
        <p className="text-center mb-2 font-weight-500">
          Click on the circles to
          <br />
          activate / deactivate different tasks in this time point
        </p>
        <p className="text-center">
          (Active tasks for this time point are indicated by the{" "}
          <span className="--text-primary">colored</span>
          circle)
        </p>
      </div>
      <div className="historyVertical">
        <ul className="timeLine">
          <li
            data-status="Active"
            data-item="history"
            className={`timeLine__item --current-before-primary ${activeTab === 1 ? "active" : ""
              }`}
          >
            <span className={`dotItem Active`}></span>
            <div
              className="item-title active"
              onClick={() => handleTab(1, "profile")}
            >
              Profile Image
            </div>
          </li>
          {timeLineList?.map((value, index) => (
            <li
              key={index}
              data-status={value.Status}
              data-item={value.ViewClass}
              className={`timeLine__item
                    ${value.Status === "Active"
                  ? "--current-before-primary"
                  : "--current-before-grey"
                }
                    ${activeTab === index + 2 ? "active" : ""}`}
            >
              <span
                className={`dotItem ${value?.Status}`}
                onClick={() => handleActive(value)}
              ></span>
              <div
                className={`item-title d-flex align-items-center ${value.Status}`}
                onClick={() => handleTab(index + 2, value.ViewClass)}
                data-task={value?.ViewClass}
              >
                {value.Label}
                {(taskLoading === value?.ViewClass && isLoading) && (
                  <Spinner
                    color="primary"
                    size="sm"
                    className="ml-2"
                  >
                    Loading...
                  </Spinner>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Col>
  );
}

export default TimeLineVertical;
