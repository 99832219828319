import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//components
import {
    LabsOrders,
    StudiesOrders,
    MedicationsOrders,
    InstructionsOrders,
    QuestionsOrders,
} from "../ManageAvailableTasks/Orders";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const OrdersMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Labs", content: <LabsOrders {...props} /> },
        { key: 2, label: "Studies", content: <StudiesOrders {...props} /> },
        { key: 3, label: "Medications", content: <MedicationsOrders {...props} /> },
        { key: 4, label: "Instructions", content: <InstructionsOrders {...props} /> },
        { key: 5, label: "Questions", content: <QuestionsOrders {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(5); // 5 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Orders`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default OrdersMain;