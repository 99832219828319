import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

//components
import { ReferenceLiblaryWrap, OrgReferenceLibraryWrap } from "@components/teachV2/ReferenceLiblary";
import { getReferenceBook } from "@actions";
import { teachV2Constants } from "@constants";
import { useLocation } from 'react-router-dom';

const ReferenceLiblaryPage = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const [activeTab, setActiveTab] = useState(pathname === "/teach/reference-library/org-ref" ? 2 : 1);
  const [listValue, setListValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const { dataFilter: documents } = useSelector((state) => state.getReferenceBook) || {};

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  
  const tabsOption = [
    { id: 1, key: "my-references", label: "My References" },
    { id: 2, key: "org-references", label: "Organization References" },
  ] 

  const loadDataReferenceBook = () => {
    dispatch(getReferenceBook())
  }

  useEffect(() => {
    loadDataReferenceBook()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: teachV2Constants.FILTER_REFERENCE_BOOK_SUCCESS, payload: documents, params: listValue });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue])

  return (
    <div className="wrapper -ReferenceLiblaryPage">
      <div className='reference-wrap__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <ReferenceLiblaryWrap 
            loadDataReferenceBook={loadDataReferenceBook}
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
        {activeTab === 2 && (
          <OrgReferenceLibraryWrap
            loadDataReferenceBook={loadDataReferenceBook}
            listValue={listValue}
            setListValue={setListValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
      </div>
    </div>
  )
}

export default ReferenceLiblaryPage