import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton';
import { ModalUpload } from "../ManageAvailableTasks/ProfileImage";

function ProfileImageMain(props) {
    const { timepointData } = props || {};
    const examData = timepointData?.Exam;
    const initialProfileUrl = examData?.Profile?.Url;

    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    const [profileUrl, setProfileUrl] = useState(initialProfileUrl);

    useEffect(() => {
        setProfileUrl(initialProfileUrl);
    }, [initialProfileUrl]);

    const toggleModal = () => {
        setUploadModalOpen(prevState => !prevState);
    };

    return (
        <React.Fragment>
            <div className="patientPhoto">
                {profileUrl ? (
                    <img
                        className="img-fluid"
                        src={profileUrl}
                        alt="exam_photo"
                    />
                ) : (
                    <Skeleton height={600} />
                )}
                <button
                    type="button"
                    className="btn btn-info my-4 d-block mx-auto"
                    onClick={toggleModal}
                >
                    <i className="fa fa-image" /> Change Image
                </button>
            </div>
            <ModalUpload
                {...props}
                toggleModal={toggleModal}
                isOpen={isUploadModalOpen}
                setProfileUrl={setProfileUrl} // Pass the setter to ModalUpload to allow updating the URL
            />
        </React.Fragment>
    );
}

export default ProfileImageMain;