import React from 'react';
import styled from 'styled-components';

//components
import { UploadButton } from "../ReferenceLiblary";

const Wrapper = styled.div`
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 500px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    border-radius: 16px;
`;

const Container = styled.div`
    max-width: 458px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled.h1`
  color: #081B2A;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
`;

const Description = styled.p`
  color: #1D2939;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 20px;
`;

const UploadFileSuggestion = (props) => {
    const { title, content, org, toggle } = props
    return (
        <Wrapper>
            <Container>
                <Title>{title}</Title>
                <Description>{content}</Description>
                {!org && (<UploadButton toggle={toggle} {...props} />)}
            </Container>
        </Wrapper>
    );
};

export default UploadFileSuggestion;
