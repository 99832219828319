import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

//actions
import { actFetchAllTemplates, addTemplatetoCollection } from "@actions" ;

//redux
import { useDispatch } from "react-redux";

const ModalAddTemplate = (props) => {
  const dispatch = useDispatch();
  const { toggle, isOpen, itemCollection } = props && props;
  const { allTemplateDefault } = props && props;
  const data = allTemplateDefault?.data;
  const dataDropdown = data?.SystemTemplates;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch
  } = useForm();

  useEffect(() => {
    dispatch(actFetchAllTemplates());
  }, [dispatch])

  const onsubmit = (value) => {
    const params = {
      "Action": "AddTemplateToCollection",
      "TemplateCollectionId": parseFloat(itemCollection?.TemplateCollectionId),
      "TemplateId": parseFloat(value?.TemplateId)
    }
    dispatch(addTemplatetoCollection(params));
    toggle();
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Template to Collection <br/>
          <span className="--text-primary">{itemCollection?.TemplateCollectionName}</span>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label htmlFor="Title">Template: <span className="m--font-danger">*</span></Label>
            <Input
              type="select"
              {...register("TemplateId", { required: true })}
              onChange={(e) => {
                setValue("TemplateId", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("TemplateId")}
              invalid={errors.TemplateId?.type === "required"}
              placeholder=""
            >
              <option value="">Select an item below</option>
              {dataDropdown?.map((value, index) => {
                return (
                  <option value={value?.TemplateId} key={index}>{value?.TemplateName}</option>
                );
              })}
            </Input>
            <FormFeedback invalid={String(errors.Title?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={handleSubmit(onsubmit)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddTemplate;