import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

//actions
import { atcUpdateCohort } from "@actions";

//redux
import { useDispatch } from "react-redux";

const ModalAddCohort = (props) => {
  const { toggle, isOpen, cohortObject, activeCohortAfterEdit } = props && props;
  const dispatch = useDispatch();

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm();

  //Load data
  useEffect(() => {
    setValue('Name', cohortObject?.Name, { shouldValidate: false });
  }, [cohortObject, setValue])

  const onSubmit = async (data) => {
    const params = {
      "Action": "Update",
      "CohortId": cohortObject?.CohortId,
      "Name": data?.Name
    };

    try {
      const response = await dispatch(atcUpdateCohort(params));
      if (response?.status === 200) {
        activeCohortAfterEdit(cohortObject);
      } else {
        console.error("Update failed: Response status not 200");
      }
    } catch (error) {
      console.error("Update failed:", error);
    } finally {
      toggle();
      setValue('Name', '', { shouldValidate: false });
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Cohort</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label for="Name">Name <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Name"
              {...register("Name", { required: true })}
              invalid={errors.Name?.type === "required"}
              defaultValue={watch("Name")}
              onChange={(e) => {
                setValue("Name", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback invalid={String(errors.Name?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddCohort;
