import React from "react";

//redux
import { useSelector } from "react-redux";

function LabsResult(props) {
  const { heightPatientMedRecord } = props && props;
  const caseData = useSelector((state) => state.cases || []);
  const caseDetails = caseData?.data?.Case;
  const DataLabResults = caseDetails?.ClinicalStatus?.LabResults;
  const results = DataLabResults?.results;
  const timepoints = DataLabResults?.timepoints;
  
  const checkDataShow = (minValue, currentValue, maxValue) => {
    const checkMax = currentValue > maxValue;
    const checkMin = currentValue < minValue;
    if (checkMax) {
      return (
        <span className="text-danger">
          {currentValue}&nbsp; {currentValue && Number(currentValue) && <i className="fa fa-arrow-up"></i>}
        </span>
      );
    } else if (checkMin) {
      return (
        <span className="text-danger">
          {currentValue}&nbsp; {currentValue && Number(currentValue) && <i className="fa fa-arrow-down"></i>}
        </span>
      );
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: currentValue
          }}
        ></div>
      );
    }
  };

  return (
    <React.Fragment>
      {results?.length > 0 ? (
        <div className="table-results-med isShowScroll" style={{ maxHeight: `${heightPatientMedRecord - 100}px`, borderBottomLeftRadius: '13px', borderBottomRightRadius: '13px' }}>
          <table className="table">
            <thead>
              <tr>
                <th className="tbl-heading">&nbsp;</th>
                {timepoints?.map((item, index) => (
                  <th className="tbl-heading text-center" key={index}>
                    {item?.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {results?.map((item, index) => (
                <React.Fragment key={index}>
                  <tr className={`tbl-heading-yellow`}>
                    <td colSpan={item?.Items?.length + 1}>
                      <h5 className="tbl-heading-lv2">{item?.Name}</h5>
                    </td>
                  </tr>
                  {item?.Items?.map((value, index) => {
                    const normalMin = value?.normalMin;
                    const normalMax = value?.normalMax;
                    const valueUnit = value?.Unit;
                    const valueName = value?.name;
                    const valueRange = `(nml: ${normalMin || ''} - ${normalMax || ''} ${valueUnit || ''})`;
                    const notes = value?.notes;
                    return (
                      <tr key={index}>
                        {/* Row */}
                        <th>
                          {valueName}{" "}
                          <p className="font-weight-400">
                            {normalMin >= 0 && normalMax >= 0 ? valueRange : ""}
                          </p>
                          {notes && (
                            <p className="--text-primary text-1 mt-2">{notes}</p>
                          )}
                        </th>
                        {timepoints?.map((data, index) => {
                          return (
                            <td
                              style={{ textAlign: "center" }}
                              key={index}
                            >
                              {value?.results?.map((result, k) => {
                                return (
                                  <React.Fragment key={k}>
                                    {result?.time === data?.time_value &&
                                      checkDataShow(
                                        normalMin,
                                        (result?.value),
                                        normalMax
                                      )
                                    }
                                  </React.Fragment>
                                )
                              })}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <h6 className="message-sp text-center py-5">
          There are no lab results currently available
        </h6>
      )}
    </React.Fragment>
  );
}

export default LabsResult;