import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import {
  atcLoadAllCohorts,
  actLoadAllPeriods,
  actReportCaseLearnersCompleted,
} from "@actions";

//format time
import moment from "moment";

import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { FormGroup, Input, Row, Col, Label } from "reactstrap";
import { Link } from "react-router-dom";
const PlotlyComponent = createPlotlyComponent(Plotly);

function Learners(props) {
  let { id } = useParams();
  //state
  const [stateData, setStateData] = useState({
    CohortId: null,
    PeriodId: null,
  });
  const [isChange, setChange] = useState(false);

  //props
  const dataCase = props && props.dataCase;
  const dataRes = dataCase && dataCase.data;
  const reportLearners = dataRes && dataRes.ReportLearnersCompleted;
  const enrolledLearners = reportLearners && reportLearners.EnrolledLearners;

  //react-redux
  const dispatch = useDispatch();

  useEffect(() => {
    //load Cohorts
    dispatch(atcLoadAllCohorts());

    //load Periods
    const params = { Action: "Load" };
    dispatch(actLoadAllPeriods(params));
  }, [dispatch]);

  const data = [
    {
      type: "pie",
      direction: "clockwise",
      sort: false,
      values: [
        enrolledLearners?.NumLearnersCompleted,
        (enrolledLearners?.NumTotalLearners) - (enrolledLearners?.NumLearnersCompleted)
      ],
      marker: {
        colors: ["#2fa38c", "#d1d1d1"],
      },
      labels: ["Completed", "Incompleted"],
      hoverinfo: "label+percent",
      textposition: "none",
      automargin: true,
      hole: 0.4
    },
  ];

  const layout = {
    height: 200,
    width: 200,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    showlegend: false,
  };

  const hanldeChange = (e) => {
    const { name, value } = e.target;
    setStateData((prevState) => ({ ...prevState, [name]: value }));
    setChange(true);
  };

  //data store
  const cohortStore = useSelector(
    (state) => (state.listCohort && state.listCohort.data) || []
  );

  const periodsData = useSelector(
    (state) => (state.loadPeriods && state.loadPeriods.data) || []
  );

  //Call Api
  useEffect(() => {
    if (isChange) {
      setChange(false);
      const params = {
        ReportType: "learners_Completed",
        CohortId: stateData.CohortId || null,
        PeriodId: stateData.PeriodId || null
      };
      dispatch(actReportCaseLearnersCompleted(id, params));
    }
  }, [isChange, dispatch, id, stateData]);

  const PercentLearnersCompleted = enrolledLearners?.PercentLearnersCompleted;
  const NumTotalLearners = enrolledLearners?.NumTotalLearners;
  const NumLearnersCompleted = enrolledLearners?.NumLearnersCompleted;
  const LastSubmission = enrolledLearners?.LastSubmission;
  return (
    <div className="panelCt mb-4">
      <div className="panelCt__head">
        <h5 className="heading-5 m--font-brand --font-bold font-roboto --text-primary my-0 ">
          Learners
        </h5>
      </div>
      <div className="panelCt__body">
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="CohortId">Cohorts: </Label>
              <Input
                className="ml-auto"
                type="select"
                name="CohortId"
                onChange={hanldeChange}
                defaultValue={stateData.CohortId}
              >
                <option value="">All Enrolled Learners</option>
                {cohortStore &&
                  cohortStore.map((value, index) => (
                    <option key={index} value={value.CohortId}>
                      {value.Name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label for="PeriodId">Periods:</Label>
              <Input
                className="ml-auto"
                type="select"
                name="PeriodId"
                onChange={hanldeChange}
                defaultValue={stateData.PeriodId}
              >
                <option value="">Any Time</option>
                {periodsData &&
                  periodsData.map((value, index) => (
                    <option key={index} value={value.PeriodId}>
                      {value.Name}
                    </option>
                  ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {reportLearners && (
          <React.Fragment>
            <hr />
            <Row>
              <Col md={4} className="text-center">
                <h5
                  className="text-center mb-3"
                  style={{ color: "#2fa38c", fontSize: "large" }}
                >
                  {PercentLearnersCompleted}
                  {` %`}
                  <br />
                  complete
                </h5>
                <PlotlyComponent data={data} layout={layout} />
                <div className="text-center">
                  <span
                    style={{ fontSize: "large" }}
                    className="m--font-info text-center font-weight-600"
                  >
                    {NumTotalLearners}
                  </span>{" "}
                  learners enrolled
                </div>
                <div className="text-center">
                  <span
                    style={{ fontSize: "large" }}
                    className="m--font-success text-center font-weight-600"
                  >
                    {NumLearnersCompleted}
                  </span>{" "}
                  learners completed
                </div>
              </Col>
              <Col md={5}>
                <h5 className="m--font-brand mb-3">Summary</h5>
                <p className="font-weight-500">Last Submission:</p>
                <p>{LastSubmission && moment(LastSubmission).format("LLL")}</p>
              </Col>
              <Col md={3}>
                <h6 className="m--font-brand mb-3">Links</h6>
                <Link to={`/teach/reports/learners/${id}`} className="m--font-info d-block">
                  Detail Learner Reports
                </Link>
                <Link to={`/teach/access/learners/${id}`} className="m--font-info d-block">
                  Manage Access
                </Link>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Learners;
