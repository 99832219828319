import React, { useState } from 'react';

//reactstrap
import { Col, FormGroup, Label, Input, Tooltip } from "reactstrap";

function CalculatedOsmolarity(props) {
    
    //props
    const { resultOsmolarity } = props && props;

    //state
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        setTooltipOpen(!tooltipOpen);
    }

    return (
        <React.Fragment>
            <FormGroup>
                <div className="d-flex align-items-start">
                    <Label className="col-order-label" for="CalculatedOsm" sm={5}>
                        Calculated Osmolarity
                    </Label>
                    <Col sm={7}>
                        <Input
                            id="CalculatedOsm"
                            type="text"
                            name="CalculatedOsm"
                            value={`${resultOsmolarity} mOsm/L`}
                            readOnly
                        />
                        {resultOsmolarity < 900 && (
                            <span
                                style={{ fontSize: "11px" }}
                                className="text-success"
                            >
                                OK for peripheral infusion
                            </span>
                        )}
                        <Tooltip
                            placement="top"
                            isOpen={tooltipOpen}
                            target="CalculatedOsm"
                            toggle={handleTooltipOpen}
                        >
                            {`${resultOsmolarity} mOsm/L`}
                        </Tooltip>
                    </Col>
                </div>
            </FormGroup>
        </React.Fragment>
    );
};

export default CalculatedOsmolarity;