import React from 'react';
import { Input, Label } from 'reactstrap';

const OTPInput = ({ formik, handleInputChange, handleKeyDown, successAnimation }) => {
    return (
        <div className="otp-inputs">
            {Array.from({ length: 6 }).map((_, index) => (
                <div key={index} className="otp-inputs__item">
                    <Label for={`otp${index}`} className="sr-only">Digit {index + 1}</Label>
                    <Input
                        id={`otp${index}`}
                        name={`otp${index}`}
                        type="text"
                        maxLength="1"
                        className={`otp-single-input ${formik.errors[`otp${index}`] && formik.touched[`otp${index}`] ? "is-invalid" : ""}`}
                        onChange={(e) => handleInputChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        value={formik.values[`otp${index}`]}
                        style={{ borderColor: successAnimation ? '#7592E1' : '' }}
                        autoComplete="off"
                    />
                </div>
            ))}
        </div>
    );
};

export default OTPInput;