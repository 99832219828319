import React from "react";

//Images
import bannerLogin from '@images/makansafe/pic-login-primary.png';

function BannerLogin() {
    return (
        <div className="col-lg-6 d-none d-lg-block">
            <div className="banner-login">
                <div className="banner-login__img">
                    <img className="img-fluid" src={bannerLogin} alt="SACH MakanSafe Data Manager" />
                </div>
                <h2 className="banner-login__title">SACH MakanSafe Data Manager</h2>
            </div>
        </div>
    );
}

export default BannerLogin;