import React, { useState, useEffect } from "react";

//reactstrap
import { FormGroup, Label, Input } from "reactstrap";

//react-router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//CreatableSelect
import CreatableSelect from "react-select/creatable";

//actions
import { atcLoadQuestionByIdFolowControl } from "@actions";

function QuestionTypeCondition(props) {
    const { answerById, handleChangeAnswerFlowControl, flowControlOfQuestion, questionData } = props && props;

    //useParams
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [isStatus, setStatus] = useState(flowControlOfQuestion?.Status)

    //convert dropdown
    const convertDataOptions = (data) => {
        let listOptions = [];
        data?.map(function (item) {
            const dataReturn = { value: item?.AnswerId, label: item?.AnswerContent };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    const listAnswerByQuestionId = answerById?.Answers;
    const DataOption = convertDataOptions((listAnswerByQuestionId && listAnswerByQuestionId) || []);

    //state
    const [selectedOption, setSelectedOption] = useState(null);

    //setState
    useEffect(() => {
        //convert dropdown
        const convertDataOptions = (data) => {
            let listOptions = [];
            data?.map(function (item) {
                const dataReturn = { value: item?.AnswerId, label: item?.AnswerContent };
                return listOptions.push(dataReturn);
            });
            return listOptions;
        };

        const filteredArray = listAnswerByQuestionId?.filter(answer => flowControlOfQuestion?.QuestionAnswers.includes(answer.AnswerId));
        const optionActive = convertDataOptions(filteredArray || []);
        setSelectedOption(optionActive);
    }, [flowControlOfQuestion, listAnswerByQuestionId]);

    //Call APi
    useEffect(() => {
        //Load Answer for dropdown
        const params = {
            "Action": "Load",
            "Target": "Question",
            "QuestionId": String(questionData?.QuestionId)
        }

        // Kiem tra chac chac QuestionId moi load (Vi truong hop Create khong co QuestionId)
        questionData?.QuestionId && dispatch(atcLoadQuestionByIdFolowControl(id, params));
    }, [dispatch, id, questionData])

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        //send
        handleChangeAnswerFlowControl("QuestionAnswers", data);
    };

    const handleChangeStatus = (e) => {
        const { name, value } = e.target;
        setStatus(value);
        handleChangeAnswerFlowControl(name, value);
    }
    return (
        <React.Fragment>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="correct"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "correct"}
                    />
                    is Correct
                </Label>
            </FormGroup>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="incorrect"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "incorrect"}
                    />
                    is Incorrect
                </Label>
            </FormGroup>
            <FormGroup check className="mb-2 d-flex align-items-center">
                <Label check>
                    <Input
                        type="radio"
                        value={'with_answers_one'}
                        name="Status"
                        style={{ top: '7px' }}
                        checked={isStatus === 'with_answers_one' || isStatus === 'with_answers_all'}
                        onChange={handleChangeStatus}
                    />
                    <div className="d-flex align-items-center">
                        <span className="text-nowrap mr-3">Is with</span>
                        <Input
                            name="Status"
                            type="select"
                            defaultValue={isStatus}
                            onChange={handleChangeStatus}
                        >
                            <option value="with_answers_one">
                                ONE
                            </option>
                            <option value="with_answers_all">
                                ALL
                            </option>
                        </Input>
                        <span className="text-nowrap ml-3">of these answers</span>
                    </div>
                </Label>
            </FormGroup>
            <div className="my-3">
                <CreatableSelect
                    isMulti
                    closeMenuOnSelect={false}
                    options={DataOption}
                    placeholder={`Click to select answer(s)`}
                    value={selectedOption || null}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                    styles={{
                        multiValueLabel: (base) => ({
                            ...base,
                            padding: '6px',
                            fontSize: '13px'
                        })
                    }}
                />
            </div>
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    (To NOT impose a condition, simply not select a question)
                </small>
            </p>
        </React.Fragment>
    );
}

export default QuestionTypeCondition;