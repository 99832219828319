import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import ItemFaq from './ItemFaq'

const listAskedQuestions = [
  {
    name: "General Questions",
    listFaq: [
      {
        question: "What is the purpose of the Case Scenario Generation feature?",
        answer: "The Case Scenario Generation feature allows you to create customized educational cases for your students or trainees. It uses AI to help generate realistic scenarios based on your input and requirements.",
      },
      {
        question: "Can I save my work and come back to it later?",
        answer: "Yes, your progress is automatically saved at each step. You can exit and return to continue where you left off.",
      },
    ],
  },
  {
    name: "Define Case Scenario Requirements",
    listFaq: [
      {
        question: "What should I include in the Requirements section?",
        answer: "Your Requirements should include the main topic of the case, target audience (e.g., medical students, residents), primary learning objective, and any key references or guidelines you want to incorporate.",
      },
      {
        question: "How detailed should my Requirements be?",
        answer: "Aim for 2-3 sentences that capture the essence of your case. You'll have opportunities to add more details later.",
      },
    ],
  },
  {
    name: "Template Selection",
    listFaq: [
      {
        question: "What if none of the templates exactly fit my needs?",
        answer: "Choose the closest match. You can customize the generated content later to better fit your specific requirements.",
      },
      {
        question: "Can I create my own template?",
        answer: "Currently, you can't create custom templates. If you have suggestions for new templates, please contact our support team.",
      },
    ],
  },
  {
    name: "Attaching References",
    listFaq: [
      {
        question: "What types of files can I upload as references?",
        answer: "You can upload PDF, PPT, DOCX, and TXT files. Make sure your files don't exceed the 10MB size limit.",
      },
      {
        question: "How does the AI use these references?",
        answer: "The AI analyzes the content of your references to ensure the generated case aligns with the information and guidelines provided.",
      },
    ],
  },
  {
    name: "Document Privacy and Security",
    listFaq: [
      {
        question: "Are my uploaded materials (articles, lecture notes, PowerPoints) kept private?",
        answer: `Yes, absolutely. When you upload your materials:<br>
          - They are never shared or made public on the internet<br>
          - Files remain private and secure<br>
          - The system analyzes the content internally<br>
          - Content is broken down into smaller pieces<br>
          - These pieces help validate and generate cases<br>
          - Original files stay confidential<br>
          Think of it like a private tutor who reads your materials to understand your teaching approach, but never shares your resources with others.
          `,
      },
      {
        question: "How does the system use my uploaded materials?",
        answer: `The system: <br>
          1. Analyzes your documents<br>
          2. Extracts relevant knowledge<br>
          3. Uses this information to validate and enhance case scenarios<br>
          4. Ensures generated content aligns with your teaching materials<br>
          `,
      },
      {
        question: "Can other users see my uploaded materials?",
        answer: `No. Your uploaded materials are strictly private and can only be accessed by you and your authorized team members.`,
      },
    ],
  },
  {
    name: "Review and Edit",
    listFaq: [
      {
        question: "Can I edit the generated content?",
        answer: "Yes, you can edit all generated content to ensure it meets your needs and expectations.",
      },
    ],
  },
  {
    name: "Generate Case",
    listFaq: [
      {
        question: "How long does it take to generate a case?",
        answer: "Generation typically takes 1-3 minutes, depending on the complexity of your case and the server load.",
      },
    ],
  },
  {
    name: "Editing and Regenerating Content",
    listFaq: [
      {
        question: "Can I edit the Requirements after it's been generated?",
        answer: "Yes, you can edit the Requirements at any time.",
      },
      {
        question: "If I edit the Requirements, will other sections automatically update?",
        answer: "No, changes to the Requirements won't automatically update other sections. You'll need to manually regenerate each section you want to update based on the regenerated requirements.",
      },
      {
        question: "How do I update other sections after editing the Requirements?",
        answer: 'After editing and saving the Requirements:1. Review each section to determine if it needs updating. 2. For each section that needs updating, click the "Regenerate" button associated with that section. 3. Review the newly generated content to ensure it aligns with your edited Requirements.',
      },
      {
        question: "Do I have to regenerate all sections after editing the Requirements?",
        answer: "Not necessarily. Only regenerate the sections that you feel need to be updated to align with your changes. Some sections may still be suitable without regeneration.",
      },
      {
        question: "What if I only want to make a small change to the Requirements?",
        answer: "Even for small changes, the process is the same. Edit the Requirements, click on Re-generate, then decide which (if any) sections need to be regenerated based on your changes.",
      },
      {
        question: "Can I undo a regeneration if I prefer the previous version?",
        answer: "Unfortunately, once you regenerate a section, the previous version is overwritten. However, you can always edit the newly generated content or regenerate again if you're not satisfied with the results.",
      },
    ],
  },
  {
    name: "Additional Elements",
    listFaq: [
      {
        question: 'What are "Additional Elements" and when should I use them?',
        answer: "Additional Elements like diagnoses lists or management plans can enrich your case. Use them when you want to provide more detailed learning materials or assessment tools.",
      },
      {
        question: "Can I add custom additional elements?",
        answer: "Currently, you can only select from the provided additional elements. If you need a custom element, please contact our support team.",
      },
    ],
  },
  {
    name: "Troubleshooting",
    listFaq: [
      {
        question: 'What should I do if the generation process seems stuck?',
        answer: "If generation takes more than 5 minutes, try refreshing the page. If the problem persists, contact our technical support.",
      },
      {
        question: "How can I report a bug or suggest an improvement?",
        answer: "Use the Flag button in the bottom right corner of each section to report issues or suggest improvements.",
      },
    ],
  },
]

export default function ModalFaq({ isOpen, toggle }) {
  return (
    <Modal className="modal-faq" isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{maxWidth: '800px'}}>
      <ModalHeader toggle={toggle}>
        <span>Frequently Asked Questions on Case Scenario Generation</span>
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="wrap-list">
          {listAskedQuestions?.map((question, index) => (
            <div  className="wrap-list__items" key={index}>
              <h1>{question.name}</h1>
              <div className="wrap-list__items-faq">
                {question?.listFaq?.map((faq, idx) => (
                  <ItemFaq key={idx} faq={faq}/>
                ))}
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  )
}
