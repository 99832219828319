import React, { useState, useEffect } from 'react';

//reactstrap
import { FormGroup, Label } from 'reactstrap';

//textarea
import TextareaAutosize from "react-textarea-autosize";

function FeedbackText(props) {
    const { data, handleSendParamsFeedback } = props && props;
    const Feedback = data?.Feedback;

    //state
    const [stateFeedback, setFeedback] = useState((Feedback && Feedback) || 0);
    const [sendData, setSendData] = useState(true);

    useEffect(() => {
        setFeedback((Feedback && Feedback) || 0);
    }, [Feedback])

    const handleChange = (e) => {
        const { value } = e.target;
        setFeedback(value);
        setSendData(true);
    }

        //send Data to parent
        useEffect(() => {
        if (sendData) {
            handleSendParamsFeedback(stateFeedback);
            setSendData(false);
        }
}, [data, handleSendParamsFeedback, stateFeedback, sendData]);

    return (
        <FormGroup>
            <Label for="answer">
                Feedback:
            </Label>
            <TextareaAutosize
                name="Feedback"
                placeholder=""
                className="w-100 form-control"
                maxRows={12}
                minRows={7}
                onChange={handleChange}
                value={Feedback}
            />
        </FormGroup>
    );
};

export default FeedbackText;