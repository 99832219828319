import React from "react";

function ProfileInfo(props) {
    const { portfolioData } = props && props;
    const CurrentLevelName = portfolioData?.XPData[0]?.CurrentLevel?.Name || '';
    const Account = portfolioData?.Account;
    return (
        <div className="profileInfo mt-3">
            <div className="font-weight-500">{Account?.firstname || ''}{` `}{Account?.lastname || ''}</div>
            <div className="mb-3">{CurrentLevelName}</div>
            <div className="font-weight-500 mb-1">{Account?.email || ""}</div>
            <div className="text-muted font-size-sm">{Account?.institution || ""}</div>
        </div>
    );
}

export default ProfileInfo;