import React from 'react';
import { Link } from "react-router-dom";

function RedirectView() {
    return (
        <div className="RedirectView">
            <div style={{ maxHeigh: '400px' }} className="text-center py-5">
                <h2
                    className={`heading-4 mb-0 --text-primary`}
                    dangerouslySetInnerHTML={{ __html: `You might have already had an account` }}
                ></h2>
                <p
                    className="text-3"
                    dangerouslySetInnerHTML={{ __html: `Please click on the button below to log in using your regular email and password` }}
                />
                <div className="text-center mt-4">
                    <Link
                        className="m-btn--pill btn-brand btn-lg text-white d-inline-block"
                        to={`/login`}
                        style={{ minWidth: "150px" }}
                    >
                        Return to Log In
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RedirectView;