import React, { useState } from 'react';
import SidebarItem from '../SidebarItem';
import { ModalFeedback } from "@components/teachV2/home/common";
import { Iconsax } from "@components-common";

const AccountSidebarMenu = ({ isSidebarCollapsed }) => {

    //state
    const [modalOpen, setModalOpen] = useState({ feedback: false });

    // Handle Modal
    const toggleModal = (type) => {
        setModalOpen(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const accountItems = [
        {
            icon: <Iconsax iconName="feedback" fill="#697592" />,
            name: 'Feedback',
            onClick: () => toggleModal("feedback")
        }
    ];

    return (
        <React.Fragment>
            <div className="sidebar-menu of-account">
                {!isSidebarCollapsed && <p className="sidebar__title">ACCOUNT</p>}
                {accountItems.map((item, index) => (
                    <SidebarItem
                        handleClickAction={item.onClick}
                        key={index}
                        item={item}
                        isSidebarCollapsed={isSidebarCollapsed}
                    />
                ))}
            </div>
            <ModalFeedback
                isOpen={modalOpen.feedback}
                toggle={() => toggleModal("feedback")}
                type="general"
            />
        </React.Fragment>
    );
};

export default AccountSidebarMenu;