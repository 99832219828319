export const caseConstants = {
    FETCH_CASE: 'FETCH_CASE',
    PUT_CASE: 'PUT_CASE',
    RESET_CASE: 'RESET_CASE',

    CASE_REQUEST: 'CASE_REQUEST',
    CASE_SUCCESS: 'CASE_SUCCESS',
    CASE_FAILURE: 'CASE_FAILURE',
    
    UPDATE_CASE_SUCCESS: 'UPDATE_CASE_SUCCESS',
    FETCH_QUESTION: 'FETCH_QUESTION',
    ACTIVE_TAB_MEDICAL_RECORD: 'ACTIVE_TAB_MEDICAL_RECORD',

    CASE_ACTIONS_REQUEST: 'CASE_ACTIONS_REQUEST',
    CASE_ACTIONS_SUCCESS: 'CASE_ACTIONS_SUCCESS',
    CASE_ACTIONS_FAILURE: 'CASE_ACTIONS_FAILURE',

    CASE_FEEDBACKS_REQUEST: 'CASE_FEEDBACKS_REQUEST',
    CASE_FEEDBACKS_SUCCESS: 'CASE_FEEDBACKS_SUCCESS',
    CASE_FEEDBACKS_FAILURE: 'CASE_FEEDBACKS_FAILURE',

    CHAT_SUBMIT_REQUEST: 'CHAT_SUBMIT_REQUEST',
    CHAT_SUBMIT_SUCCESS: 'CHAT_SUBMIT_SUCCESS',
    CHAT_SUBMIT_FAILURE: 'CHAT_SUBMIT_FAILURE',

    PUT_ACTIONS_TASK_REQUEST: 'PUT_ACTIONS_TASK_REQUEST',
    PUT_ACTIONS_TASK_SUCCESS: 'PUT_ACTIONS_TASK_SUCCESS',
    PUT_ACTIONS_TASK_FAILURE: 'PUT_ACTIONS_TASK_FAILURE',

    TAKE_HOME_MESSAGE_REQUEST: 'TAKE_HOME_MESSAGE_REQUEST',
    TAKE_HOME_MESSAGE_SUCCESS: 'TAKE_HOME_MESSAGE_SUCCESS',
    TAKE_HOME_MESSAGE_FAILURE: 'TAKE_HOME_MESSAGE_FAILURE',

    UPDATE_NOTE_REQUEST: 'UPDATE_NOTE_REQUEST',
    UPDATE_NOTE_SUCCESS: 'UPDATE_NOTE_SUCCESS',
    UPDATE_NOTE_FAILURE: 'UPDATE_NOTE_FAILURE',

    CREATE_REFERENCE_IN_CASE_REQUEST: 'CREATE_REFERENCE_IN_CASE_REQUEST',
    CREATE_REFERENCE_IN_CASE_SUCCESS: 'CREATE_REFERENCE_IN_CASE_SUCCESS',
    CREATE_REFERENCE_IN_CASE_FAILURE: 'CREATE_REFERENCE_IN_CASE_FAILURE',

    REMOVE_REFERENCE_IN_CASE_REQUEST: 'REMOVE_REFERENCE_IN_CASE_REQUEST',
    REMOVE_REFERENCE_IN_CASE_SUCCESS: 'REMOVE_REFERENCE_IN_CASE_SUCCESS',
    REMOVE_REFERENCE_IN_CASE_FAILURE: 'REMOVE_REFERENCE_IN_CASE_FAILURE',

    SUBMIT_DOCUMENTATION_REQUEST: 'SUBMIT_DOCUMENTATION_REQUEST',
    SUBMIT_DOCUMENTATION_SUCCESS: 'SUBMIT_DOCUMENTATION_SUCCESS',
    SUBMIT_DOCUMENTATION_FAILURE: 'SUBMIT_DOCUMENTATION_FAILURE',

    RESET_CASE_REQUEST: 'RESET_CASE_REQUEST',
    RESET_CASE_SUCCESS: 'RESET_CASE_SUCCESS',
    RESET_CASE_FAILURE: 'RESET_CASE_FAILURE',

    CHECK_MY_NOTE_REQUEST: 'CHECK_MY_NOTE_REQUEST',
    CHECK_MY_NOTE_SUCCESS: 'CHECK_MY_NOTE_SUCCESS',
    CHECK_MY_NOTE_FAILURE: 'CHECK_MY_NOTE_FAILURE',

    CHECK_MISSING_TASK_REQUEST: 'CHECK_MISSING_TASK_REQUEST',
    CHECK_MISSING_TASK_SUCCESS: 'CHECK_MISSING_TASK_SUCCESS',
    CHECK_MISSING_TASK_FAILURE: 'CHECK_MISSING_TASK_FAILURE',

    PUT_CURRENT_TAKS_REQUEST: 'PUT_CURRENT_TAKS_REQUEST',
    PUT_CURRENT_TAKS_SUCCESS: 'PUT_CURRENT_TAKS_SUCCESS',
    PUT_CURRENT_TAKS_FAILURE: 'PUT_CURRENT_TAKS_FAILURE',

    FEEDBACKS_ASSISTANT_FOR_QUESTION_REQUEST: 'FEEDBACKS_ASSISTANT_FOR_QUESTION_REQUEST',
    FEEDBACKS_ASSISTANT_FOR_QUESTION_SUCCESS: 'FEEDBACKS_ASSISTANT_FOR_QUESTION_SUCCESS',
    FEEDBACKS_ASSISTANT_FOR_QUESTION_FAILURE: 'FEEDBACKS_ASSISTANT_FOR_QUESTION_FAILURE',

    CLICK_TASK_RECORD_REQUEST: 'CLICK_TASK_RECORD_REQUEST',
    CLICK_TASK_RECORD_SUCCESS: 'CLICK_TASK_RECORD_SUCCESS',
    CLICK_TASK_RECORD_FAILURE: 'CLICK_TASK_RECORD_FAILURE',

    CASE_INTERFACE_REQUEST: 'CASE_INTERFACE_REQUEST',
    CASE_INTERFACE_SUCCESS: 'CASE_INTERFACE_SUCCESS',
    CASE_INTERFACE_FAILURE: 'CASE_INTERFACE_FAILURE',
};
