import React from 'react';

//components
import { HeadTabs } from "../../storyboard/common";
import { ChatContents, Settings, GenerativeAI } from "../Chat";
import { DragQuestion } from "../../storyboard/Question";

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//RichTextCommon
import RichTextCommon from "../RichTextCommon";

function ChatMain(props) {
    //props
    const {
        value,
        activeTabCt,
        listTabs,
        handleUpdateEditor,
        valueEditor,
        timePointId,
        filterQuestionForTask
    } = props && props;
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='chat'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.chat.map(
                    (item, index) =>
                        item?.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item?.id === "1" && (
                                    <ChatContents {...props} />
                                )}
                                {item?.id === "2" &&
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={`chat`}
                                        defaultValue={valueEditor?.chat}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                }
                                {item?.id === "3" &&
                                    <DragQuestion
                                        {...props}
                                        task={value?.ViewClass}
                                        data={filterQuestionForTask(value?.ViewClass)}
                                    />
                                }
                                {item?.id === "4" && <Settings {...props} />}
                                {item?.id === "5" && <GenerativeAI {...props} />}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>

    );
};

export default ChatMain;