import React from 'react'
import styled from 'styled-components'

const TextLink = styled.p`
  color: #1570EF;
  font-size: 14px;
  font-weight: 500;
  text-decoration-line: underline;
  .text-link {
    cursor: pointer;
  }
`

export default function ColumnLink(props) {
  const { text, handleClick } = props;
  return (
    <TextLink>
      <span 
        className='text-link'
        style={{wordBreak: 'break-all'}}
        onClick={handleClick}
      >
        {text}
      </span>
    </TextLink>
  )
}
