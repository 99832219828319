import React from 'react';
import styled from 'styled-components';
import IconMoney from '@images/coin/icon-money.svg';

const Button = styled.div`
    display: flex;
    height: 28px;
    padding: 0px 12px;
    align-items: center;
    gap: 2px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.25);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 26px;
`;

const CoinIcon = styled.img`
  width: 15px;
  margin-right: 1px;
`;

const CoinText = styled.span`
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
`;

function IconCoinBtn(props) {
  const { coin } = props;

  return (
    <Button>
      <CoinText>{coin}</CoinText>
      <CoinIcon src={IconMoney} alt="8 coin" />
    </Button>
  );
}

export default IconCoinBtn;
