import React, { useState, useEffect } from "react";
import placehoder from "@images/common/no-avatar.jpg";
import { Iconsax } from "@components-common";

function EmailTemplateContainer({ isOpen, handleToggleEmailTemplate, isEmailTemplate }) {
    const [isVisible, setIsVisible] = useState(false);
    const [emailData, setEmailData] = useState(null);

    useEffect(() => {
        setIsVisible(isOpen);
        // Kiểm tra xem isEmailTemplate có dữ liệu không
        if (isEmailTemplate?.data?.FullQuestionResponse?.ShowEmail) {
            setEmailData(isEmailTemplate.data.FullQuestionResponse.ShowEmail);
        }
    }, [isOpen, isEmailTemplate]);

    return (
        <div className={`email-template-container p-3 ${isVisible ? "show" : ""}`} style={{ overflow: "hidden" }}>
            <button className="btnClose-email" onClick={handleToggleEmailTemplate}>
                <Iconsax iconName="close" fill="#344054" size={12} />
            </button>
            <div className="email-template-ct" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                {emailData && (
                    <div className="email-header">
                        <div className="email-header-ct">
                            <div className="email-header-ct__left">
                                <div className="avatar-sender">
                                    <img src={emailData.SenderAvatar || placehoder} alt="placehoder" />
                                </div>
                                <span className="online-green"></span>
                            </div>
                            <div className="email-header-ct__right">
                                <p className="date-time">{`Thu 3/8/2018 1:35 PM`}</p>
                                <p className="sender">{`${emailData.Sender} <${emailData.SenderEmail}>`}</p>
                                <h3 className="subject">{emailData.Title}</h3>
                            </div>
                        </div>
                        <div className="email-header__receiver">To <span>{emailData.RecipientEmail}</span></div>
                    </div>
                )}
                {emailData && (
                    <div className="email-body scrollbarStyle" style={{ overflowY: "auto", flex: "1" }}>
                        <div className="email-body__details" dangerouslySetInnerHTML={{ __html: emailData.Contents }} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default EmailTemplateContainer;