import React, { useState, useEffect } from "react";
import First from './First';
import Second from './Second';
import Third from './Third';
import Four from './Four';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';

// Step Data
import stepData from "@utils/formStep";

//utils
import { apiCaller } from "@utils";

function UserForm() {
  let { slug } = useParams();
  let history = useHistory();
  let nameLocal = "params-step";

  const [stateParams, setStateParams] = useState({});
  const [isLoading, setLoading] = useState(false);
  let dataFromlocalStorage = localStorage.getItem(nameLocal);

  // Check localStorage
  useEffect(() => {
    const jsonData = JSON.parse(dataFromlocalStorage);
    setStateParams(jsonData || {});
  }, [dataFromlocalStorage])

  // Luu localStorage khi submit
  const handleSaveLocal = (data) => {
    localStorage.setItem(nameLocal, JSON.stringify(data));
  }

  // Hanlde Submit - Get Template
  const submitApiGetTemplate = (params) => {
    setLoading(true);
    apiCaller(`/api/teach/create/case/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        setLoading(false);
        setStateParams(data);
        handleSaveLocal(data);

        // get template xong thi chuyen qua step 2
        history.push(`/teach/new/step2`);
      } else {
        setLoading(false);
        console.log("error.....");
      }
    });
  }

  // Handle Submite All Step
  const handleCreateNewCase = (params, nextStep) => {
    setLoading(true);
    apiCaller(`/api/teach/create/case/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        setLoading(false);
        setStateParams(data);

        //Chuyen qua step cuoi cung
        history.push(`/teach/new/${nextStep}`);
      } else {
        setLoading(false);
        console.log("error.....");
      }
    });
  }

  // Handle Next Step
  const hanldeNextStep = (params, nextStep) => {
    setStateParams(params);
    handleSaveLocal(params);

    // Next Qua Step tiep theo
    history.push(`/teach/new/${nextStep}`);
  }

  return (
    <React.Fragment>
      {slug === 'step1' &&
        <First
          submitApi={submitApiGetTemplate}
          isLoading={isLoading}
          params={stateParams}
          stepData={stepData}
        />
      }
      {slug === 'step2' &&
        <Second
          params={stateParams}
          hanldeNextStep={hanldeNextStep}
          isLoading={isLoading}
          stepData={stepData}
        />
      }
      {slug === 'step3' &&
        <Third
          params={stateParams}
          handleCreateNewCase={handleCreateNewCase}
          isLoading={isLoading}
          stepData={stepData}
        />
      }
      {slug === 'step4' &&
        <Four
          params={stateParams}
          isLoading={isLoading}
          stepData={stepData}
        />
      }
    </React.Fragment>
  );
}

export default UserForm;
