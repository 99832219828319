import React, { useState } from 'react';

//Boostrap
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Input, Label } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { schemaContanst } from "@constants";

//common
import { DataEmty } from "@components/common";

function ModalAddConcept(props) {
    const dispatch = useDispatch();
    const { isOpen, toggle, handleUpdateConcept, indexItem } = props && props;

    //State
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [stateCUID, setCUID] = useState('');
    const [isIdChecked, setIdChecked] = useState('');
    const [isSearching, setSearching] = useState(false);

    //handleKeyDown
    const handleKeyDown = (event) => {
        if(event.keyCode === 13) {
            hanldeSearch();
        }
    }

    //handleSubmit
    const handleSubmit = (stateCUID, indexItem) => {
        handleUpdateConcept(stateCUID, indexItem, 'add'); 
        toggle();

        //reset Data
        setResults([]);
        setCUID('');
    }

    //hanldeChange
    const hanldeChange = (id) => {
        setCUID(id);
        setIdChecked(id);
    }

    //hanldeSearch
    const hanldeSearch = () => {
        const params = {
            "Action": "Search",
            "Keyword": searchTerm
        }

        setSearching(true);
        dispatch({ type: schemaContanst.SEARCH_CONCEPT_REQUEST, isLoading: true });
        //Call Api
        apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: schemaContanst.SEARCH_CONCEPT_SUCCESS, payload: data });
                // Set results state
                setResults(data);
                setSearching(false);
            } else {
                dispatch({
                    type: schemaContanst.SEARCH_CONCEPT_FAILURE,
                    error: "error"
                });
                setSearching(false);
                return [];
            }
        });
    }

    const todoItems = results?.map((todo, index) =>
        <FormGroup className="d-flex align-items-center border-bottom py-3" style={{ cursor: 'pointer' }} check key={index} onClick={() => hanldeChange(todo?.cui)}>
            <Input
                name={`radio_schema`}
                type="radio"
                value={todo?.cui}
                checked={isIdChecked === todo?.cui ? true : false}
                bsSize={'lg'}
                onChange={() => hanldeChange(todo?.cui)}
            />
            {' '}
            <Label id={`radio_schema`} check>
                <h3>{todo?.name}</h3>
                <p className="text-muted mt-2">CUI: {todo?.cui}</p>
            </Label>
        </FormGroup>
    );

    return (
        <Modal
            toggle={toggle}
            isOpen={isOpen}
            modalClassName={`right ${isOpen ? "in" : ""}`}
            wrapClassName={`modalFixed`}
        >
            <ModalHeader toggle={toggle}>
                Add Concept
            </ModalHeader>
            <div className="d-flex align-items-center w-100 p-3 border-bottom">
                <Input
                    type="text"
                    name="search"
                    placeholder="Search"
                    style={{ height: "38px" }}
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoComplete="off"
                />
                <button className="btn btn-brand ml-2" onClick={hanldeSearch}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                </button>
            </div>
            <ModalBody>
                {todoItems}
                {isSearching && (
                    <DataEmty content={`Searching ...`} />
                )}
                {results && results.length === 0 && !isSearching &&
                    <DataEmty content={`No Data`} />
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    onClick={() => handleSubmit(stateCUID, indexItem)}
                    disabled={stateCUID === '' ? true : false}
                >
                    Add
                </Button>
                {' '}
                <Button onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddConcept;