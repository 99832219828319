import React, { useState, useEffect } from 'react';

function CheckBoxDialog(props) {
    const { item, handleUpdateStatus } = props && props;
    const human_label = item?.human_label;

    //state
    const [stateData, setData] = useState(human_label || []);

    //load state
    useEffect(() => {
        setData(item?.human_label || [])
    }, [item])

    const listItems = stateData?.map((el, index) =>
        <div className="checkbox-chat-group__item" key={index}>
            <label>
                <input
                    type="checkbox"
                    checked={el?.status}
                    name={`status`}
                    onChange={(e) => handleUpdateStatus(e, index, item)}
                />
                <span>{el?.title}</span>
            </label>
        </div>
    );
    return (
        <div className="checkbox-chat-group">
            {listItems}
        </div>
    );
};

export default CheckBoxDialog;