import React, { useEffect } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//components
import { HeadingBox } from "@components/common";
import { QuestionMain } from '@components/elements';

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//call Action
import { actFetchTimeline } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

function QuestionElementPage(props) {
    const { caseDetails } = props && props;
    const dispatch = useDispatch();
    let { id } = useParams();

    //Prompt Templates - List All
    const getListPromptTemplate = () => {
        const isLoading = true;
        dispatch({ type: storyboardConstants.GET_PROMPT_TEMPLATE_REQUEST, isLoading });
        apiCaller(`/api/teach/prompt/template/`, "GET", null).then((res) => {
            const data = res && res.data;
            if (res?.status === 200) {
                dispatch({
                    type: storyboardConstants.GET_PROMPT_TEMPLATE_SUCCESS,
                    payload: data,
                    isLoading: false
                });
            } else {
                dispatch({
                    type: storyboardConstants.GET_PROMPT_TEMPLATE_FAILURE,
                    error: 'error',
                    isLoading: false
                });
            }
        })
    }

    //Prompt Templates - Load
    useEffect(() => {
        //Load Timeline - Flow Control
        const loadTimeline = async () => {
            const paramsTimeline = {
                Action: "Load",
                Target: "Timeline",
            };
            dispatch(actFetchTimeline(id, paramsTimeline));
        };
        getListPromptTemplate();
        loadTimeline();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //titlePage
    const titlePage = caseDetails?.Title;
    const dataPromptTemplate = useSelector((state) => state?.dataPromptTemplate?.data || {});
    return (
        <div className="wrapper -questionElementPage">
            <section className="questionElementCt">
                <Container>
                    <HeadingBox title={titlePage || ""} currentPage="Questions" />
                    <QuestionMain
                        {...props}
                        promptTemplateList={dataPromptTemplate}
                    />
                </Container>
            </section>
        </div>
    );
}

export default QuestionElementPage;