import React from 'react'
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    color: #1D2939;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`

const TitleNoteCommon = (props) => {
  const { title, content, dataFor } = props
  return (
    <WrapNote>
      <p>{title}</p>
      <div data-tip data-for={dataFor}>
        <Icon src={info} />
      </div>
      <ReactTooltip id={dataFor} place='top' effect='solid'>
        <span>{content}</span>
      </ReactTooltip>
    </WrapNote>
  )
}

export default TitleNoteCommon
