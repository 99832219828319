import { vitalConstant } from "@constants";
import { apiCaller } from "@utils";

//Case - Update Timepoint - Set Vital Signs Showing By Default
export const actSetStatusVitalSigns = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: vitalConstant.SET_STATUS_VITAL_SIGNS_REQUEST, isLoading } }
    function success(data) { return { type: vitalConstant.SET_STATUS_VITAL_SIGNS_SUCCESS, payload: data } }
    function failure(error) { return { type: vitalConstant.SET_STATUS_VITAL_SIGNS_FAILURE, error: error } }
}

// Case - Load Vital Signs for Elements
export const loadVitalSignsElements = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_REQUEST, isLoading } }
    function success(data) { return { type: vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_SUCCESS, payload: data } }
    function failure(error) { return { type: vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_FAILURE, error: error } }
}

//Update Timepoint - Vital Sign Change
export const actVitalSignChangeElements = (id, params, TimepointId) => {
    const isLoading = true;
    return (dispatch) => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data, TimepointId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) {
        return { type: vitalConstant.VITAL_SIGN_CHANGE_FOR_ELEMENTS_REQUEST, isLoading };
    }
    function success(data, TimepointId) {
        return {
            type: vitalConstant.VITAL_SIGN_CHANGE_FOR_ELEMENTS_SUCCESS,
            payload: data,
            TimepointId: TimepointId
        };
    }
    function failure(error) {
        return {
            type: vitalConstant.VITAL_SIGN_CHANGE_FOR_ELEMENTS_FAILURE,
            error: error,
        };
    }
};

//load Case
export const actFetchLoadTimePointForElement = (id, params) => {
    const isLoading = true;
    return (dispatch) => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));

                //Load List
                const paramsLoad = {
                    Action: "Load",
                    Target: "VitalSigns",
                };
                dispatch(loadVitalSignsElements(id, paramsLoad));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) {
        return { type: vitalConstant.UPDATE_VALUE_VITAL_FOR_ELEMENTS_REQUEST, isLoading };
    }
    function success(data) {
        return { type: vitalConstant.UPDATE_VALUE_VITAL_FOR_ELEMENTS_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: vitalConstant.UPDATE_VALUE_VITAL_FOR_ELEMENTS_SUCCESS, error: error };
    }
};


// Case - Update Vital Sign Order
export const updateVitalSignOrder = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));

                const paramsLoad = {
                    Action: "Load",
                    Target: "VitalSigns",
                };
                dispatch(loadVitalSignsElements(id, paramsLoad));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: vitalConstant.UPDATE_VITAL_SIGN_ORDER_REQUEST, isLoading } }
    function success(data) { return { type: vitalConstant.UPDATE_VITAL_SIGN_ORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: vitalConstant.UPDATE_VITAL_SIGN_ORDER_FAILURE, error: error } }
}