import React, { useState } from "react";

//reactstrap
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function DropdownActions(props) {
  //props
  // const { handleChangeActions } = props && props;

  //state
  const [dropdownOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown className="ml-3" isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className="--bg-primary" caret>
        Actions
      </DropdownToggle>
      <DropdownMenu container="body">
        {/* onClick={() => handleChangeActions("Suspend")} */}
        <DropdownItem>
          Suspend
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default DropdownActions;
