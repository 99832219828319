import React, { useState } from "react";

//reactstrap
import { Button, InputGroup, InputGroupAddon, Input } from "reactstrap";

//call Action
import { createAuthcode } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

function FromCreate(props) {
  const dispatch = useDispatch();

  //props
  const { cohortActive } = props && props;

  //state
  const [stateAuthCodeNum, setAuthCodeNum] = useState("");

  //handle Create
  const handleCreate = (e) => {
    const params = {
      Action: "Create",
      AuthCodeNum: parseFloat(stateAuthCodeNum),
      CohortId: cohortActive,
    };
    dispatch(createAuthcode(params));
  };

  //handle Change
  const handleChange = (e) => {
    const { value } = e.target;
    setAuthCodeNum(value);
  };

  const storeUpdate = useSelector((state) => state.createAuthcode || []);
  const isLoading = storeUpdate && storeUpdate.isLoading;
  return (
    <div className="formNewCode mb-3">
      <InputGroup className="align-items-center">
        <label className="mr-2 mb-0" htmlFor="Create">
          Create
        </label>
        <Input
          type="number"
          defaultValue={stateAuthCodeNum}
          name="AuthCodeNum"
          onChange={handleChange}
          min={1}
        />
        <InputGroupAddon addonType="prepend">New Codes</InputGroupAddon>
      </InputGroup>
      <Button
        className="--btn-primary d-flex align-items-center ml-2"
        onClick={handleCreate}
        disabled={isLoading}
      >
        {isLoading && (
          <span className="spinner-border spinner-border-sm mr-2"></span>
        )}
        Submit
      </Button>
    </div>
  );
}

export default FromCreate;