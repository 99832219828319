import React from "react";

//reactstrap
import { NavItem, NavLink, Nav } from "reactstrap";

//classnames
import classnames from "classnames";

function NavTab(props) {
    const { data, activeTab, toggle } = props && props;
    return (
        <Nav tabs>
            {data?.map((value, index) => (
                <NavItem key={index}>
                    <NavLink
                        className={classnames({
                            active: activeTab === (index + 1).toString(),
                        })}
                        onClick={() => {
                            toggle(String(index + 1));
                        }}
                    >
                        {value.Name}
                    </NavLink>
                </NavItem>
            ))}
        </Nav>
    );
}

export default NavTab;