import React, { useState, useEffect } from "react";

//utils
import { LoaderText } from "@utils";

// components
import { ChatGroup } from './Chat';

function ChatMain(props) {

  //props
  const { data, storeChat } = props && props;

  //state
  const [stateData, setData] = useState((data && data) || []);

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const DataMap = ({ data }) => {
    const Sections = data?.Sections;
    const Timeline = data?.Timeline;
    return (
      <React.Fragment>
        {/* First */}
        {Sections?.map((row, index) => {
          return (
            <div className="panel mb-3" key={index}>
              <ChatGroup
                rowData={(row && row) || {}}
                Timeline={Timeline}
              />
            </div>
          )
        })}
      </React.Fragment>
    );
  };

  return (
    <div className="boxPortlet position-relative chatTopicTable">
      {!storeChat?.isLoading && (
        <DataMap data={(stateData && stateData) || []} />
      )}
      {storeChat?.isLoading && (
        <div style={{ height: '400px' }}>
          <LoaderText />
        </div>
      )}
    </div>
  );
}

export default ChatMain;
