import React, { useState } from "react";

//components
import { ContactUs } from "@components/common/ContactUs";
import { ContentProcess } from "@components-common";

//boostrap
import { Button } from "reactstrap";

function LockMessage(props) {
  const { data } = props && props;
  const LockButtonMode = data && data.LockButtonMode;
  const LockButtonText = data && data.LockButtonText;
  // const LockImage = data && data.LockImage;
  const LockMessageText = data && data.LockMessageText;
  const LockMessageTitle = data && data.LockMessageTitle;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const dataMessage = {
    title: LockMessageTitle,
    description: LockMessageText,
  };
  
  return (
    <section className="lockMessage text-center py-5">
      <ContentProcess data={dataMessage} icon={``} />
      <Button
        style={{ cursor: "pointer" }}
        onClick={toggle}
        className="btn m-btn--pill --btn-primary mt-4 px-4 py-2"
      >
        {LockButtonText}
      </Button>
      {LockButtonMode === "contact" &&
        <ContactUs {...props} isOpen={modal} toggle={toggle} />
      }
    </section>
  );
}

export default LockMessage;
