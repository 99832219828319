import { studiesConstants } from "@constants";
import { apiCaller } from "@utils";

//Load Studies
export const atcLoadStudies = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.LOAD_STUDIES_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.LOAD_STUDIES_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.LOAD_STUDIES_FAILURE, error: error };
  }
};

//Edit Studies
export const atcUpdateStudyInfo = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.UPDATE_STUDIES_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.UPDATE_STUDIES_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.UPDATE_STUDIES_FAILURE, error: error };
  }
};

//Create Studies
export const atcCreateStudyInfo = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.CREATE_STUDIES_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.CREATE_STUDIES_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.CREATE_STUDIES_FAILURE, error: error };
  }
};

//Delete Studies
export const atcDeleteStudyInfo = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.DELETE_STUDIES_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.DELETE_STUDIES_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.DELETE_STUDIES_FAILURE, error: error };
  }
};

// Case - Load All Studies For Elements
export const atcLoadAllStudiesForElements = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.LOAD_ALL_STUDIES_FOR_ELEMENT_FAILURE, error: error };
  }
};

//Edit Studies
export const atcUpdateStudyInfoForElements = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        const data = res && res.data;
        dispatch(success(data));

        const paramsLoad = {
          Action: "Load",
          Target: "Studies",
        };
        dispatch(atcLoadAllStudiesForElements(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: studiesConstants.UPDATE_STUDIES_FOR_ELEMENTS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: studiesConstants.UPDATE_STUDIES_FOR_ELEMENTS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: studiesConstants.UPDATE_STUDIES_FOR_ELEMENTS_FAILURE, error: error };
  }
};