import React from "react";

//router
import { useParams } from "react-router-dom";

//action
import { actDeleteVitalSign } from "@actions";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteVitalSign(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const timePointId = props && props.timePointId;
  const vitalSignId = props && props.vitalSignId;
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "VitalSign",
      TimePointId: timePointId,
      VitalSignId: vitalSignId,
    };
    dispatch(actDeleteVitalSign(id, params));
    props && props.toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you sure you would like to delete this <br/> vital sign from the case?
        </h4>
        <p className="mt-2 text-center">This action is not reversible</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
        >
          Delete
        </Button>
        <Button
          className="btn btn-secondary"
          onClick={toggle}
        >
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteVitalSign;
