import React from "react";

function Confirm (props) {
  return (
    <div className="messageCt text-center">
      <svg
        className="svgCheck"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130.2 130.2"
        width="60"
        height="60"
      >
        <circle
          className="path circle"
          fill="none"
          stroke="#73AF55"
          strokeWidth="6"
          strokeMiterlimit="10"
          cx="65.1"
          cy="65.1"
          r="62.1"
        />
        <polyline
          className="path check"
          fill="none"
          stroke="#73AF55"
          strokeWidth="6"
          strokeLinecap="round"
          strokeMiterlimit="10"
          points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
      </svg>
      <h2
        className="heading-4 d-block mb-0 --text-primary"
        dangerouslySetInnerHTML={{
          __html: "Thank You For Contacting Us !",
        }}
      ></h2>
      <p
        dangerouslySetInnerHTML={{
          __html:
            "We will look into the information you provided, and will contact you within the next 24 hours.",
        }}
      />
    </div>
  );
}

export default Confirm;
