import React, { useState } from "react";

//elements
import { ButtonEdit, ButtonDelete, ButtonAdd } from '@components-common/elements';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//components
import { ModalAdd } from "../teach/Tags";

function ListTags(props) {

  const [itemCurrent, setItemCurrent] = useState({});
  const [modal, setModal] = useState({
    add: false,
    edit: false,
    del: false
  });

  const data = [
    {
      "text": "Tag Item 1.0",
      "category": "cat 1",
      "color": "#1a1a1a"
    },
    {
      "text": "Tag Item 2.0",
      "category": "cat 1",
      "color": "#1a1a1a"
    }, {
      "text": "Tag Item 3.0",
      "category": "cat 1",
      "color": "#1a1a1a"
    }, {
      "text": "Tag Item 4.0",
      "category": "cat 1",
      "color": "#1a1a1a"
    }
  ]

  const toggle = (type, data) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setItemCurrent(data);
  };

  return (
    <React.Fragment>
      <div className="widgetBox -box-shadow mt-4">
        <div className="widgetBox__head d-flex justify-content-between align-items-center">
          <h3 className="wg-title m--font-brand --text-primary">Tags</h3>
          <ButtonAdd handleAdd={() => toggle('add', {})} />
        </div>
        <div className="widgetBox__body">
          <ul className="lsDomains">
            {data?.map((item, index) => (
              <li className="lsDomains__item d-flex align-items-center" key={index}>
                <div className="lsDomains__left">
                  <p className="font-weight-500"><span className="--text-primary">Name:</span> {item.text}</p>
                  <p className="font-weight-500"><span className="--text-primary">Category:</span> {item.category}</p>
                </div>
                <div className="lsDomains__right">
                  <ButtonEdit handleEdit={() => toggle('edit', item)} />
                  <ButtonDelete handleDelete={() => toggle('del', item)} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <ModalDeleteCommon
        title={`Are you sure you want to delete this record ?`}
        sub={`This action is not reversible.`}
        isOpen={modal?.del}
        toggle={() => toggle('del', itemCurrent)}
        // isLoading={isLoading}
      />
      <ModalAdd
        {...props}
        isOpen={modal?.add}
        toggle={() => toggle('add', {})}
      />
    </React.Fragment>
  );
}

export default ListTags;
