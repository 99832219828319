import React from 'react';

//boostrap
import { Button } from "reactstrap";

function BtnAddRole(props) {
    const { isLoading, addTableRows, action } = props && props;
    return (
        <Button
            className="--btn-primary d-flex align-items-center mx-auto mt-3"
            disabled={action === 'Create' ? isLoading : false}
            onClick={addTableRows}
        >
            {(isLoading && action === 'Create') ?
                <span className="spinner-border spinner-border-sm mr-2"></span> :
                <i className="fa fa-plus mr-2" aria-hidden="true" />
            }
            Add Role
        </Button>
    );
};

export default BtnAddRole;