import React, { useCallback, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { CongratulationsContent } from "../ucr";
import { useHistory, useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { advanceConstants } from "@constants";

function UCREndCase(props) {
  const { isOpen, hanldeToggleAdvance } = props || {};
  let history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleCaseCompleted = useCallback(() => {
    setLoading(true); // Set loading to true when the API call starts
    let params = { "CaseId": id };
    dispatch({ type: advanceConstants.CASE_COMPLETE_REQUEST, isLoading: true });
    apiCaller(`/api/learn/case/complete/`, 'POST', params).then(res => {
      const data = res?.data;
      if (data?.status === "success" && data?.LearnerSessionId) {
        dispatch({ type: advanceConstants.CASE_COMPLETE_SUCCESS, payload: data });
        history.push(`/learn/feedback/${id}`);
      } else {
        dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "error" });
      }
      setLoading(false); // Set loading to false when the API call completes
    }).catch(() => {
      setLoading(false); // Set loading to false if there's an error
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, history]);

  // Handle Next Feedback Page
  const handleNextFeedbackPage = () => {
    handleCaseCompleted();
  }

  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

  // Chuyển đổi HTML
  const convertToHTML = (text) => ({ __html: text });
  
  return (
    <React.Fragment>
      <Modal
        className="modal-custom"
        zIndex={16000}
        isOpen={isOpen}
        backdrop={`static`}
        centered
        backdropClassName="modal-backdrop-custom"
        modalTransition={{ timeout: 700 }}
      >
        <ModalBody>
          {/* Render CongratulationsContent component */}
          <CongratulationsContent {...props} />
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto" style={{ width: "276px" }}>
            <Button
              color='primary'
              className="btn-brand-sm -btn-lg w-100 -shadown"
              onClick={handleNextFeedbackPage}
              disabled={loading} // Disable button when loading
            >
              {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
              <span dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_button_confirm?.Text || "Proceed to Feedback")} />
            </Button>
            <Button
              color='white'
              className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
              onClick={() => hanldeToggleAdvance("ucrTesting")}
            >
              <span dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_button_cancel?.Text || "Stay at this Timepoint")} />
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default UCREndCase;