import React from 'react';
import { Button } from 'reactstrap';

const UploadButton = ({ toggle, textBtn="Upload Document" }) => {
  return (
    <Button
      color="primary"
      size="lg"
      style={{ maxHeight: '40px' }}
      className="ml-2"
      onClick={() => toggle("uploadDocument")}
    >
      {textBtn}
    </Button>
  );
};

export default UploadButton;