import React, { useState, useEffect } from "react";

//boostrap
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
    Input,
    Row,
    Col,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { actIntakeOutputChangeElements } from "@actions";

function ModalUpdate(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { data, isOpenModal, handleClose } = props && props;
    const TimepointId = data?.TimepointId;
    const ChangeModeData = data?.row?.ChangeMode;
    const ChangeValueData = data?.row?.ChangeValue;
    const ChangeLimitData = data?.row?.ChangeLimit;
    const DisplayName = data?.DisplayName;
    const ItemId = data?.row?.Id;

    //state
    const [stateData, setStateData] = useState({
        ChangeMode: (ChangeModeData && ChangeModeData) || '',
        ChangeValue: (ChangeValueData && ChangeValueData) || '',
        ChangeLimit: (ChangeLimitData && ChangeLimitData) || '',
        Id: ItemId && ItemId
    });

    //call Api
    useEffect(() => {
        setStateData((prevState) => ({
            ...prevState,
            ChangeMode: (ChangeModeData && ChangeModeData) || '',
            ChangeValue: (ChangeValueData && ChangeValueData) || '',
            ChangeLimit: (ChangeLimitData && ChangeLimitData) || '',
            Id: ItemId && ItemId
        }));
    }, [ChangeModeData, ChangeValueData, ChangeLimitData, ItemId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setStateData((prevState) => ({
            ...prevState,
            [name]: value || ''
        }));
    }

    const handleSubmit = (e) => {
        const params = {
            "Action": "Update",
            "Target": "TimePoint",
            "TimePointId": TimepointId,
            "VitalSignChange": {
                "Id": stateData?.Id,
                "ChangeMode": stateData?.ChangeMode,
                "ChangeValue": stateData?.ChangeValue,
                "ChangeLimit": stateData?.ChangeLimit
            }
        }
        dispatch(actIntakeOutputChangeElements(id, params, TimepointId));
        handleClose();
    }
    
    return (
        <Modal
            style={{ maxWidth: "600px" }}
            className="modal-dialog"
            isOpen={isOpenModal}
            backdrop={`static`}
        >
            <ModalHeader toggle={handleClose}>
                {`Change At This Time Point ${DisplayName}`}
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={6} className="mb-3">
                        <FormGroup className="mb-0">
                            <Input
                                type="select"
                                defaultValue={stateData?.ChangeMode}
                                name="ChangeMode"
                                onChange={handleChange}
                            >
                                <option value="none">No Change</option>
                                <option value="set">Set Value At ...</option>
                                <option value="increase">Increase Value By ...</option>
                                <option value="decrease">Decrease Value By ...</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        {(stateData?.ChangeMode === "set" ||
                            stateData?.ChangeMode === "increase" ||
                            stateData?.ChangeMode === "decrease") && (
                                <React.Fragment>
                                    <InputGroup>
                                        <Input
                                            type="number"
                                            name="ChangeValue"
                                            defaultValue={stateData?.ChangeValue || ""}
                                            placeholder="Value"
                                            onChange={handleChange}
                                            inputMode="numeric"
                                            pattern="[0-9]+"
                                            min="0"
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.row?.UnitAlias,
                                                    }}
                                                ></div>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </React.Fragment>
                            )}
                    </Col>
                    <Col md={6} className="d-flex align-items-center justify-content-end">
                        <div className="m--font-info font-weight-500 text-right">
                            {stateData?.ChangeMode === "increase" && "But Not Larger Than"}
                            {stateData?.ChangeMode === "decrease" && "But Not Smaller Than"}
                        </div>
                    </Col>
                    <Col md={6}>
                        {(stateData?.ChangeMode === "increase" || stateData?.ChangeMode === "decrease") && (
                            <InputGroup>
                                <Input
                                    type="number"
                                    name="ChangeLimit"
                                    defaultValue={stateData?.ChangeLimit || ""}
                                    placeholder="Limit"
                                    onChange={handleChange}
                                    inputMode="numeric"
                                    pattern="[0-9]+"
                                    min="0"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: data?.row?.UnitAlias,
                                            }}
                                        ></div>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        )}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSubmit}>
                    Save
                </Button>
                <Button color="secondary" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalUpdate;
