import React, { useState } from "react";

//redux
import { useDispatch } from "react-redux";

//boostrap
import { Button, Col } from "reactstrap";

//call Action
import { changeSubscriptionAccount } from "../../actions";

//components
import { ContactModal } from "../plan";
import { PricingModal } from "../pricing";

function BottomContent(props) {
  const { institution_id, activeIndex } = props && props;

  const dispatch = useDispatch();

  const [modal, setModal] = useState({
    contact: false,
    plan: false
  });

  const toggle = (name) => {
    setModal((prevState) => ({ ...prevState, [name]: !modal[name] }));
  };

  const handleChangeSubscription = () => {
    const params = {
      Action: "ChangeSubscription",
      SubscriptionCode: activeIndex,
      InstitutionId: institution_id,
    };
    dispatch(changeSubscriptionAccount(params));
  };

  return (
    <React.Fragment>
      <Col lg={`8`} md={`12`} className="my-5 text-center mx-auto">
        <React.Fragment>
          <div className="text-3">
            <span className="--text-primary mb-2" onClick={() => toggle('plan')} style={{ cursor: 'pointer' }}>
              Compare plan details
            </span>
            <p>
              If you need assistance in choosing the right plan, don't hesitate
              to{" "}
              <span
                style={{ cursor: "pointer" }}
                className="--text-primary"
                onClick={() => toggle("contact")}
              >
                contact us !
              </span>
            </p>
          </div>
        </React.Fragment>
        <hr className="my-4" />
        <Button
          onClick={handleChangeSubscription}
          className="--btn-primary rounded-pill px-5 py-2 mb-2"
        >
          Let's Get Started
        </Button>
        <p className="--text-primary text-3">
          No Credits card is required at this time
        </p>
      </Col>
      <ContactModal isOpen={modal.contact} toggle={() => toggle('contact')} />
      <PricingModal isOpen={modal.plan} toggle={() => toggle('plan')} />
    </React.Fragment>
  );
}

export default BottomContent;