import React, { useState } from 'react';
import { Table, Button, Row, Col } from "reactstrap";

//components
import {
    ButtonAdd,
    ModalAddDianostic,
    ModalAddCellDianostic,
    ModalEditHeaderCol,
    ModalChangeOrder
} from '../schemav2';

import { Header, Body } from "../schemav2/Diagnostic";

//common
import { DataEmty } from "@components-common";

//components
import { ModalDeleteCommon } from "@ui-partents/Modal";

//actions
import { DeleteConceptRequest } from "@actions";

//redux
import { useDispatch } from "react-redux";

function DiagnosticMain({ ...props }) {
    //dispatch
    const dispatch = useDispatch();

    //props
    const { data, toggleTestSchema } = props && props;

    //state
    const [modalAdd, setModalAdd] = useState(false);
    const [modalOrder, setModalOrder] = useState(false);
    const [modalDel, setModalDel] = useState(false);
    const [modalEditHeader, setModalEditHeader] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [stateItem, setItem] = useState({});
    const [typeConcept, setTypeConcept] = useState('');

    const toggleAdd = (type, item) => {
        setModalAdd(!modalAdd);
        setItem({ type, item });
    }

    const toggleDel = (type, item) => {
        setModalDel(!modalDel);
        setItem(item || {});
        setTypeConcept(type);
    }

    const toggleEdit = (item) => {
        setModalEdit(!modalEdit);
        setItem(item || {});
    }

    const toggleEditHeader = (type, item) => {
        setModalEditHeader(!modalEditHeader);
        setItem(item || {});
        setTypeConcept(type);
    }

    const handleRemoveConcept = () => {
        const params = {
            "Action": "DeleteConcept",
            "QuestionId": stateItem?.question_id,
            "QuestionConceptId": stateItem?.question_concept_id
        }
        dispatch(DeleteConceptRequest(params));
        setModalDel(false);
    }

    const handleRemoveConceptRelation = () => {
        const params = {
            "Action": "DeleteConceptRelation",
            "QuestionId": stateItem?.question_id,
            "QuestionConceptRelationId": stateItem?.relation?.question_concept_relation_id
        }
        dispatch(DeleteConceptRequest(params));
        setModalDel(false);
    }

    //Modal Sort
    const handleModalOrder = () => {
        setModalOrder(!modalOrder);
    }

    const objectFirst = data && data[0];
    const rowData = objectFirst?.relevant_features_by_category;
    return (
        <React.Fragment>
            <Row className="mb-4">
                <Col md={12} className="text-right">
                    <ButtonAdd
                        buttonText={`Add Column`}
                        handelSubmit={() => toggleAdd(`Diagnosis`, '')}
                        type="Diagnosis"
                    />
                    <Button
                        className="--btn-primary"
                        onClick={() => toggleTestSchema('testSchema')}
                    >
                        Test Schema
                    </Button>
                    <Button
                        className="--btn-primary ml-2"
                        onClick={handleModalOrder}
                    >
                        Change Order
                    </Button>
                </Col>
            </Row>
            {data?.length > 0 ? (
                <div className="table-layout-fixed">
                    <Table responsive bordered>
                        <Header
                            {...props}
                            toggleEditHeader={toggleEditHeader}
                            toggleDel={toggleDel}
                        />
                        <Body 
                            {...props}
                            rowData={rowData}
                            toggleEditHeader={toggleEditHeader}
                            toggleDel={toggleDel}
                            toggleEdit={toggleEdit}
                        />
                    </Table>
                </div>
            ) : (
                <div className="border mb-4">
                    <DataEmty content={`No Data`} />
                </div>
            )}
            <ButtonAdd
                buttonText={`Add Row`}
                handelSubmit={() => toggleAdd(`Feature`, '')}
                type="Feature"
            />
            <ModalAddDianostic
                {...props}
                data={stateItem || {}}
                isOpen={modalAdd}
                toggle={() => toggleAdd(`Feature`, '')}
            />
            <ModalDeleteCommon
                title={`Are you sure you want to delete this reference?`}
                sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
                isOpen={modalDel}
                toggle={() => toggleDel({})}
                buttonText={`Remove Concept`}
                handleRemove={typeConcept === "DeleteConceptRelation" ? handleRemoveConceptRelation : handleRemoveConcept}
                centerButton={true}
            />
            <ModalAddCellDianostic
                data={stateItem || {}}
                isOpen={modalEdit}
                toggle={() => toggleEdit({})}
            />
            <ModalEditHeaderCol
                data={stateItem || {}}
                isOpen={modalEditHeader}
                toggle={() => toggleEditHeader({})}
            />
            <ModalChangeOrder
                {...props}
                data={data || {}}
                isOpen={modalOrder}
                toggle={handleModalOrder}
            />
        </React.Fragment>
    );
};

export default DiagnosticMain;