import React, { useState, useEffect } from "react";

//components
import { HeaderPayMent, DescriptionPayment } from "../Administrator";

//actions
import { SetDefaultPaymentMethod } from "@actions";

//redux
import { useDispatch } from "react-redux";

function PaymentContent(props) {
  //props
  const { data } = props && props;
  const PaymentInfo = data?.PaymentInfo;
  const DefaultMethod = PaymentInfo?.DefaultMethod;

  //dispatch
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(
    (DefaultMethod && DefaultMethod) || ""
  );

  //Active DefaultMethod
  useEffect(() => {
    setActiveIndex((DefaultMethod && DefaultMethod) || "");
  }, [DefaultMethod]);

  const handleOnClick = (index) => {
    setActiveIndex(index);
    const params = {
      Action: "set_default_method",
      DefaultMethod: index,
    };
    dispatch(SetDefaultPaymentMethod(params));
  };

  const listMethod = [
    {
      Name: "Credit Card",
      Type: "CreditCard",
      IconClass: "fa fa-credit-card-alt",
    },
    // {
    //   Name: "PayPal",
    //   Type: "PayPal",
    //   IconClass: "icon-paypal-logo",
    // },
    {
      Name: "SEND INVOICE",
      Type: "Invoice",
      IconClass: "icon-invoice-logo",
    },
  ];

  const listItems = listMethod?.map((value, index) => (
    <div
      key={index}
      className={`type col ${activeIndex === value?.Type ? "selected" : ""}`}
      onClick={() => handleOnClick(value?.Type)}
    >
      <div className="logo">
        <span className={value?.IconClass}></span>
      </div>
      <div className="text">
        <p>{value?.Name}</p>
      </div>
    </div>
  ));

  return (
    <section className="card border-0 paymentSection">
      <div className="card-body p-0">
        <div className="payment-type">
          <HeaderPayMent
            data={(PaymentInfo && PaymentInfo) || {}}
            type={activeIndex}
          />
          <div className="types flexPayment justify-space-between">
            {listItems}
          </div>
        </div>
        <DescriptionPayment
          data={(PaymentInfo && PaymentInfo) || {}}
          type={activeIndex}
        />
      </div>
    </section>
  );
}

export default PaymentContent;
