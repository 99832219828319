import React from "react";

//boostrap
import { Row, Col } from "reactstrap";

function Usage(props) {
    const { stateData } = props && props;
    const usage = stateData?.usage;
    const api_calls = usage?.api_calls;
    const prompts = usage?.prompts;
    const completions = usage?.completions;
    return (
        <React.Fragment>
            <Row className="py-3">
                <Col md={12}><h2 className="font-weight-500 mb-3 text-4 --text-primary">API Calles</h2></Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># API Calls</p>
                        <h3 className="text-6 m--font-brand ml-auto">{api_calls?.total_api_calls}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3">Sucessful API calls</p>
                        <h3 className="text-6 m--font-brand ml-auto">{api_calls?.sucessful_api_calls}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># Average Fee per API</p>
                        <h3 className="text-6 text-success ml-auto">{api_calls?.avg_fee_per_api}</h3>
                    </div>
                </Col>
            </Row>
            <Row className="py-3">
                <Col md={12}><h2 className="font-weight-500 mb-3 text-4 --text-primary">Prompts</h2></Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># Total Tokens</p>
                        <h3 className="text-6 m--font-brand ml-auto">{prompts?.total_tokens_in_prompt}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># Average Tokens per Prompt</p>
                        <h3 className="text-6 m--font-brand ml-auto">{prompts?.avg_tokens_in_prompt}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3">Total Fees</p>
                        <h3 className="text-6 m--font-brand ml-auto text-danger">{prompts?.fee_by_prompt}</h3>
                    </div>
                </Col>
            </Row>
            <Row className="py-3">
                <Col md={12}><h2 className="font-weight-500 mb-3 text-4 --text-primary">Generated Contents</h2></Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># Total Tokens</p>
                        <h3 className="text-6 m--font-brand ml-auto">{completions?.total_tokens_in_completion}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3"># Average Tokens per Prompt</p>
                        <h3 className="text-6 m--font-brand ml-auto">{completions?.avg_tokens_in_completion}</h3>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="d-flex p-4 border rounded h-100">
                        <p className="stats-text text-3">Total Fees</p>
                        <h3 className="text-6 m--font-brand ml-auto text-danger">{completions?.fee_by_completion}</h3>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Usage;