import axios from "axios";
import { API_URL, MAKANSAFE_API_URL } from '@constants/Config';
import { authHeader } from '@helpers';
import { checkAuth } from '@utils';
import { toast } from 'react-toastify';

const apiCaller = (endpoint, method = "GET", body, useMakanSafeUrl = false, config = {}) => {
  if (!endpoint) {
    return Promise.reject(new Error('Endpoint is undefined'));
  }
  
  const apiUrl = useMakanSafeUrl ? `${MAKANSAFE_API_URL || ''}${endpoint}` : `${API_URL || ''}${endpoint}`;
  
  return axios({
    method: method,
    url: apiUrl,
    data: body,
    headers: authHeader(),
    ...config
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      if (error.response && checkAuth()) {
        const message = error?.response?.data?.message || error?.response?.data?.error;
        switch (error.response.status) {
          case 401:
            localStorage.clear();  // Clear all items from localStorage
            sessionStorage.clear(); // Clear all items from sessionStorage
            window.location.href = `/login?message=${encodeURIComponent(message || 'Session expired. Please log in again.')}`;
            break;
          case 403:
            window.location.href = `/403-forbidden?message=${encodeURIComponent(message || 'Access denied.')}`;
            break;
          case 500:
            toast.error('Note: There might be something wrong. We will look into this.', {
              autoClose: 4000
            });
            break;
          default:
            // toast.error(`Error: ${message || 'An unexpected error occurred.'}`, {
            //   autoClose: 4000
            // });
            return error?.response;
        }
      }
      return Promise.reject(error);
    });
};

export default apiCaller;