import React from 'react';
import styled from 'styled-components';

// Styled component cho Heading
const StyledHeading = styled.div`
  border-radius: 6px;
  background: #DCFAE6;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const SuccessIcon = styled.svg`
  margin-right: 8px;
`;

const SuccessHeading = () => {
  return (
    <StyledHeading>
      <SuccessIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#079455" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </SuccessIcon>
      <h2>Orders successfully signed and submitted</h2>
    </StyledHeading>
  );
};

export default SuccessHeading;