import React from 'react'
import styled from 'styled-components'
import ButtonSubmitCommon from '../ButtonSubmitCommon'
import { Input, Label } from 'reactstrap';
import HeaderCommon from './HeaderCommon';

const WrapCommon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
`

export default function RadioCommon(props) {
  const { watch } = props;

  return (
    <WrapCommon>
      <div className="form-area">
        <HeaderCommon {...props} />
        <div className="form-area__radio">
          <div className='wrap-radio'>
            <div>
              <Label>
                <Input
                  type="radio"
                  name="option"
                  value="option1"
                />
                Choice from Reference
              </Label>
            </div>
            <div>
              <Label>
                <Input
                  type="radio"
                  name="option"
                  value="option2"
                />
                Attach document
              </Label>
            </div>
          </div>
        </div>
      </div> 
      <div className='action-mcq'>
        <ButtonSubmitCommon {...props} dataPass={watch("content")}/>
      </div>     
    </WrapCommon>
  )
}
