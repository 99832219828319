import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";

//components 
import { FrameChat } from "@components/storyboard/Chat/FreeText";

//apiCaller
import { apiCaller } from "@utils";

function ChatFreeText(props) {
  let { id } = useParams();

  //props
  const { caseData, isReset, handleResetDataChat } = props && props;

  const responseChatData = caseData?.ChatData;
  const listRecords = responseChatData?.ChatRecords;
  let intervalIDRef = React.useRef(null);

  //state
  const [chatRecord, setChatrecord] = useState(listRecords || []);
  const [isLoading, setLoading] = useState(false);
  const [contentStream, setStream] = useState(null);
  const messagesEndRef = useRef(null);

  // Check Reset Data
  useEffect(() => {
    handleResetDataChat();
    setChatrecord([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset])

  // Scroll to the bottom of the chat box
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [chatRecord]);

  // Call Api Stream
  const handleStreamData = (questionPromise) => {
    // Neu questionPromise chua co ket qua tra ve thi sau 3s goi api nay
    Promise.race([questionPromise, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        console.log('API question returned before timeout');
        return;
      }

      console.log('API question timed out. Calling API stream_data...');
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/learn/case/stream_data/${id}/`, 'GET').then(res => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setStream(dataStream);
            messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);
          } else {
            console.log("error....");
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        });
      }, 1000);
    });
  }

  // Submit FreeText Neu Dung View Voice (ChatMode === switch_voice_text)
  const handleQuestionTypePatient = useCallback((params) => {
    if (params?.Message) {
      const UserMessage = {
        "UserMessage": params?.Message,
        "ChatStatus": "user_input"
      }
      let newData = [...chatRecord, UserMessage];
      setChatrecord(newData);
      setLoading(true);

      const chatPromise = apiCaller(`/api/learn/case/chat/${id}/`, 'PUT', params).then(res => {
        let data = res?.data;
        delete data.UserMessage;
        if (res?.status === 200) {
          const newObj = Object.assign({}, data);
          newData.push(newObj);
          setChatrecord(newData);

          //Scroll Bottom And Set Loading
          setLoading(false);
          messagesEndRef?.current?.scrollTo(0, messagesEndRef.current.scrollHeight);

          // Dung Request timeout va xoa data stream (Quan trong - de hien thi du lieu)
          setStream(null);
          clearInterval(intervalIDRef.current);
          intervalIDRef.current = null;
        } else {
          console.log("error");
          setLoading(false);
        }
        return data;
      });
      handleStreamData(chatPromise);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRecord, id]);

  return (
    <div className="bg-white" style={{borderRadius: '12px'}}>
      <FrameChat
        {...props}
        dataChatRecords={chatRecord || []}
        messagesEndRef={messagesEndRef}
        handleQuestionTypePatient={handleQuestionTypePatient}
        responseChatData={responseChatData}
        isLoading={isLoading}
        contentStream={contentStream}
      />
    </div>
  );
}

export default ChatFreeText;