import React from "react";

function ButtonUpdateData(props) {
  const { hanldeImportData, loading } = props && props;
  return (
    <div className="mb-4 text-right">
      <button type="button" className="btn btn btn-primary" disabled={loading} onClick={hanldeImportData}>
        {loading ? <span className="spinner-border spinner-border-sm mr-2"></span> : <i className="fa fa-retweet mr-2" />}
        Import Data
      </button>
    </div>
  );
}

export default ButtonUpdateData;