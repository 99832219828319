import React from "react";

//react-select
import Select, { components } from 'react-select';

function CategoryFilterSelect(props) {
    //props
    const { handleChangeOptions, dataSelect, selectedOption, disabled, isLoading } = props || {};

    // Custom Option
    const Option = ({ innerProps, label }) => {
        return (
            <div
                {...innerProps}
                style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
                <span className="react-select-item">{label}</span>
            </div>
        );
    };

    // Custom Menu
    const Menu = (props) => {
        const { children } = props;
        return (
            <components.Menu {...props}>
                {children}
            </components.Menu>
        );
    };


    return (
        <Select
            isMulti={true}
            className="react-select"
            closeMenuOnSelect={true}
            // menuIsOpen={true}
            onChange={(optionValue) => handleChangeOptions(optionValue, 'account')}
            options={dataSelect?.account || []}
            value={selectedOption?.account}
            classNamePrefix="-reactSelectQuestion"
            components={{ Option, Menu }}
            menuPortalTarget={document.body}
            isDisabled={disabled}
            isLoading={isLoading?.getAccountsByCategory}
        />
    );
}

export default CategoryFilterSelect;