import React from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { NavigationComponent } from "@components/layouts";
import { HeadingForAdmin } from "@components/common/Heading";

//utils
import { navigation } from "@utils";

//components
import { TableAvatars } from "@components/admin/Avatars";

function AvatarsPage(props) {
    const mainMenu = navigation && navigation.mainAdmin;
    return (
        <React.Fragment>
            <NavigationComponent data={mainMenu} />
            <div className="wrapper -OnBoardingPage">
                <Container>
                    <HeadingForAdmin title={"Avatar"} />
                    <TableAvatars
                        {...props}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
}

export default AvatarsPage;