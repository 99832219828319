import React, { useState } from "react";

//reactstrap
import { Card, CardBody, Container } from "reactstrap";

//components
import { TextareaAutosizeInput, HtmlResult, ConceptsMain } from '@components/playground/freetext';

//apiCaller
import { apiCaller } from "@utils";

function FreeTextPage() {
  const [nameState, setNameState] = useState({ text: '' });
  const [result, setResult] = useState({});
  const [isshow, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataConcept, setConcept] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitStatement = (listConcept) => {
    const params = {
      "Section": "FreeTextQuestion",
      "Text": nameState?.text,
      "Type": "OpenQuestion",
      "Mode": "Data",
      "Concepts": listConcept
    }
    setConcept(listConcept);
    setLoading(true);
    apiCaller(`/api/learn/playground/`, "POST", params).then((res) => {
      if (res && res.status === 200) {
        setResult(res?.data || {});
        setLoading(false);
        setShow(true);
        setTimeout(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'auto'
          });
        }, 2000);
      } else {
        console.log('error');
        setLoading(false);
      }
    });
  };

  return (
    <div className="wrapper -analyzePage">
      <Container style={{ maxWidth: "800px" }}>
        <Card>
          <CardBody>
            <TextareaAutosizeInput
              handleChange={handleChange}
              nameState={nameState}
              disabled={nameState?.text ? false : true}
            />
            <ConceptsMain
              handleSubmit={handleSubmitStatement}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
        {nameState?.text && isshow && (
          <Card className="mt-3">
            <CardBody>
              <HtmlResult
                data={result || ''}
                listConcept={dataConcept || []}
                textQuestion={nameState?.text || ''}
              />
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
}

export default FreeTextPage;