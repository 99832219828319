//portfolio
import { PortfolioPage } from "@pages/med2lab";

const portfolioRoutes = [
    // portfolio
    {
        path: "/portfolio",
        exact: true,
        classPage: '',
        component: PortfolioPage,
        isLayout: true,
    },
    {
        path: "/portfolio/:slug",
        exact: true,
        classPage: '',
        component: PortfolioPage,
        isLayout: true,
    },
];

export { portfolioRoutes };