import { advanceConstants } from "@constants";

export function advance (state = {}, action) {
    switch (action.type) {
        case advanceConstants.ADVANCE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case advanceConstants.ADVANCE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case advanceConstants.UPDATE_CASE_ADVANCE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case advanceConstants.ADVANCE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function caseCompleted (state = {}, action) {
    switch (action.type) {
        case advanceConstants.CASE_COMPLETE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case advanceConstants.CASE_COMPLETE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case advanceConstants.CASE_COMPLETE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function checkAutoNextTimepoint (state = {}, action) {
    switch (action.type) {
        case advanceConstants.AUTO_NEXT_TIMEPOINT_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case advanceConstants.AUTO_NEXT_TIMEPOINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case advanceConstants.AUTO_NEXT_TIMEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function checkStatusNextTimePoint (state = {}, action) {
    switch (action.type) {
        case advanceConstants.CHECK_NEXT_TIMEPOINT_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case advanceConstants.CHECK_NEXT_TIMEPOINT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case advanceConstants.CHECK_NEXT_TIMEPOINT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};