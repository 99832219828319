// Reli
import { ReliDiagnosisPage, ReliListPage, ReliPdfPageUpload, ReliProposalPage } from "@pages/med2lab/reli";

const reliRoutes = [
    {
        path: "/reli/diagnosis",
        exact: true,
        classPage: '',
        component: ReliDiagnosisPage,
        isLayout: true,
    },
    {
        path: "/reli/pdf-demo",
        exact: true,
        classPage: '',
        component: ReliListPage,
        isLayout: true,
    },
    {
        path: "/reli/pdf-demo/upload",
        exact: false,
        component: ReliPdfPageUpload,
        isLayout: true,
    },
    {
        path: "/reli/proposal",
        exact: true,
        classPage: '',
        component: ReliProposalPage,
        isLayout: true,
    }
];

export { reliRoutes };