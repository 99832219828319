import React, { useState, useRef, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { DropdownTemplate } from '../EndingTimepoint';

//lodash
import _ from "lodash";

//boostrap
import {
    FormGroup,
    Label,
    Input,
    Col,
    Button,
    FormFeedback,
    Row,
} from "reactstrap";
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

//actions
import { atcUpdateFeedbackTemplate, atcAssignFeedback } from "@actions";

function AssignTemplate(props) {
    //props
    const { typeActive, data } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();
    const editor = useRef(null);

    //load Template
    const storeTemplate = useSelector(
        (state) => state.ListAvailableFeedbackTemplates || {}
    );
    const dataTemplate = storeTemplate?.data;

    //state
    const [stateFeedbackTemplateId, setFeedbackTemplateId] = useState((data && parseFloat(data?.FeedbackTemplateId)) || '');
    const [stateMessage, setMessage] = useState("");
    const [loadApi, setLoadApi] = useState(false);
    
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
    } = useForm();

    useEffect(() => {
        setFeedbackTemplateId((data && parseFloat(data?.FeedbackTemplateId)) || '');
        setValue("Name", data && data.Name, { shouldValidate: false });
        setValue("Title", data && data.Title, {
            shouldValidate: false,
        });
        setMessage((data && data.Message) || "");

        if(_.isEmpty(data)) {
            setLoadApi(true);
        }
    }, [setValue, data]);

    //Case - Assign Feedback
    const handleAssignFeedback = useCallback((idTemplate) => {
        const params = {
            "Action": "Update",
            "Target": "SelectFeedbackTemplate",
            "FeedbackTemplateId": idTemplate
        }
        dispatch(atcAssignFeedback(id, params));
    }, [dispatch, id]);

    //Call Api Template Id
    useEffect(() => {
        if (loadApi) {
            const idTemPlateFirst = dataTemplate && dataTemplate[0]?.FeedbackTemplateId;
            handleAssignFeedback(idTemPlateFirst);
            setLoadApi(false);
        }
    }, [loadApi, handleAssignFeedback, dataTemplate]);

    //handle Change
    const hanldeChange = (e) => {
        const { value } = e.target;
        setFeedbackTemplateId(parseFloat(value));
        handleAssignFeedback(parseFloat(value));
    }

    //Update With Api
    const onSubmit = (value) => {
        const params = {
            Action: "Update",
            Target: "FeedbackTemplate",
            Name: value?.Name,
            Title: value?.Title,
            Message: stateMessage,
            FeedbackTemplateId: data?.FeedbackTemplateId
        };
        dispatch(atcUpdateFeedbackTemplate(id, params));
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm={6}>
                    <DropdownTemplate
                        hanldeChange={hanldeChange}
                        feedbackTemplateId={stateFeedbackTemplateId}
                        typeActive={typeActive}
                        {...props}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="Name">
                            Name: <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            onChange={(e) =>
                                setValue("Name", e.target.value, { shouldValidate: false })
                            }
                            defaultValue={watch("Name")}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col sm={6}>
                    <FormGroup>
                        <Label for="Title">
                            Title: <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            type="text"
                            name="Title"
                            {...register("Title", { required: true })}
                            invalid={errors.Title?.type === "required"}
                            onChange={(e) =>
                                setValue("Title", e.target.value, { shouldValidate: false })
                            }
                            defaultValue={watch("Title")}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Title?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                </Col>
                <Col sm={12}>
                    <FormGroup>
                        <Label for="FeedbackText">Feedback Text:</Label>
                        <JoditEditor
                            ref={editor}
                            value={stateMessage || ""}
                            config={Config.CONFIG_JODIT}
                            tabIndex={1}
                            onBlur={(newContent) => setMessage(newContent)}
                        />
                    </FormGroup>
                    <div className="d-flex">
                        <Button
                            type="submit"
                            color="primary"
                            className="ml-auto"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default AssignTemplate;