import { billingConstant } from "@constants";
import {apiCaller} from "@utils";

//Update Assistant Tip Record
export const getBillingReport = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/billing/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: billingConstant.GET_BILLING_REPORT_REQUEST, isLoading } }
    function success(data) { return { type: billingConstant.GET_BILLING_REPORT_SUCCESS, payload: data } }
    function failure(error) { return { type: billingConstant.GET_BILLING_REPORT_FAILURE, error: error } }
}