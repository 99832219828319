import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Row,
    Col
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

//Config
import * as Config from "@constants/Config";

const ModalAddConcept = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { toggle, isOpen, data, ProblemId } = props && props;
    const editor = useRef(null);

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);
    const [content, setContent] = useState('');
    const [stateData, setData] = useState(data || {});

    const onSubmit = (value) => {
        const params = {
            "Action": "CreateProblemConcept",
            "CaseId": id,
            "Name": value?.Name,
            "SearchType": value?.SearchType,
            "SearchString": value?.SearchString,
            "DocumentationProblemIds": [ProblemId],
            "Required": stateData?.Required,
            "Comments": content,
            "Score": 0
        };

        setLoading(true);
        dispatch({ type: problemsConstant.CREATE_PROBLEM_CONCEPT_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.CREATE_PROBLEM_CONCEPT_SUCCESS, payload: data });
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.CREATE_PROBLEM_CONCEPT_FAILURE, error: "error" });
                setLoading(false);
                toggle();
            }
        });

        //Reset data
        setValue('Name', '', { shouldValidate: false });
        setValue('SearchType', '', { shouldValidate: false });
        setValue('SearchString', '', { shouldValidate: false });
    };

    const handleChange = (e) => {
        const { name, checked } = e.target;
        setData((prevState) => ({ ...prevState, [name]: checked }));
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
            <ModalHeader toggle={toggle}>Add Concept</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="Name"
                                {...register("Name", { required: true })}
                                invalid={errors.Name?.type === "required"}
                                defaultValue={watch("Name")}
                                onChange={(e) => {
                                    setValue("Name", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.Name?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">

                        <FormGroup>
                            <Label for="SearchType">Search Type: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="SearchType"
                                {...register("SearchType", { required: true })}
                                invalid={errors.SearchType?.type === "required"}
                                defaultValue={watch("SearchType")}
                                onChange={(e) => {
                                    setValue("SearchType", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.SearchType?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="SearchString">Search String: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="SearchString"
                                {...register("SearchString", { required: true })}
                                invalid={errors.SearchString?.type === "required"}
                                defaultValue={watch("SearchString")}
                                onChange={(e) => {
                                    setValue("SearchString", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.SearchString?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={12} className="mb-3">
                        <FormGroup className='d-flex align-items-center' check>
                            <Input
                                id="Required"
                                name="Required"
                                type="checkbox"
                                className='mt-0'
                                defaultChecked={stateData?.Required}
                                onChange={handleChange}
                            />
                            <Label
                                check
                                for="Required"
                            >
                                Required
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label for="Comments">Comments:</Label>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={Config.CONFIG_JODIT}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isLoading &&
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    }
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddConcept;