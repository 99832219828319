import { caseConstants } from "@constants";
import { apiCaller } from "@utils";

//alert
// import { Toasts } from '@components/common/Toasts';
import { toast } from 'react-toastify';

//UPDATE CASE AND CALL QUESTION NEXT
export const actFetchPutCaseRequest = (idParam, task) => {
    const params = { "Task": task };
    const isLoading = true;

    return dispatch => {
        dispatch(request(isLoading));

        return new Promise((resolve, reject) => {
            apiCaller(`/api/learn/case/task/${idParam}/`, 'PUT', params)
                .then(res => {
                    const data = res?.data;
                    if (res?.status === 200) {
                        dispatch(success(data));
                        resolve(res);  // Resolving the promise with data
                    } else {
                        const error = "error";
                        dispatch(failure(error));
                        reject(error);  // Rejecting the promise with an error
                    }
                })
                .catch(error => {
                    dispatch(failure(error.message || "Unknown error"));
                    reject(error);  // Rejecting the promise on catch
                });
        });
    };

    function request(isLoading) { return { type: caseConstants.PUT_CURRENT_TAKS_REQUEST, isLoading }; }
    function success(data) { return { type: caseConstants.PUT_CURRENT_TAKS_SUCCESS, payload: data }; }
    function failure(error) { return { type: caseConstants.PUT_CURRENT_TAKS_FAILURE, error }; }
};

//GET ACTIONS
export const actFetchCaseActionsRequest = (idParam) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/actions/${idParam}/`, 'GET', null).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.CASE_ACTIONS_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CASE_ACTIONS_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CASE_ACTIONS_FAILURE, error: error } }
}

//fetch check missing task
export const actFetchCheckMissingTask = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/check/${id}/`, 'GET', null).then(res => {
            let dataCurrent = res?.data;
            if (res?.status === 200) {
                dispatch(success(dataCurrent));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: caseConstants.CHECK_MISSING_TASK_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CHECK_MISSING_TASK_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CHECK_MISSING_TASK_FAILURE, error: error } }
}

//PUT CASE
export const actChatSubmit = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/chat/${idParam}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.CHAT_SUBMIT_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CHAT_SUBMIT_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CHAT_SUBMIT_FAILURE, error: error } }
}

// Case - Update Notes
export const updateNotesCase = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/notes/${idParam}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
                toast.success('Note has been updated', {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.UPDATE_NOTE_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.UPDATE_NOTE_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.UPDATE_NOTE_FAILURE, error: error } }
}

// Case - Create Reference in Case
export const atcCreateReference = (params, id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.CREATE_REFERENCE_IN_CASE_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CREATE_REFERENCE_IN_CASE_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CREATE_REFERENCE_IN_CASE_FAILURE, error: error } }
}

export const atcDeleteReference = (params, id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.REMOVE_REFERENCE_IN_CASE_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.REMOVE_REFERENCE_IN_CASE_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.REMOVE_REFERENCE_IN_CASE_FAILURE, error: error } }
}

// Click
export const atcClickResult = (params, id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/record_task/${id}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.CLICK_TASK_RECORD_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CLICK_TASK_RECORD_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CLICK_TASK_RECORD_FAILURE, error: error } }
}

export const LoadInterfaceTemplate = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/interface/${id}/`, 'GET', null).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: caseConstants.CASE_INTERFACE_REQUEST, isLoading } }
    function success(data) { return { type: caseConstants.CASE_INTERFACE_SUCCESS, payload: data } }
    function failure(error) { return { type: caseConstants.CASE_INTERFACE_FAILURE, error: error } }
}

// ----Active Tab - Medical Record
export const actActiveTabsMedicalRecord = (taskActive) => {
    return dispatch => {
      dispatch(success(taskActive));
    };
  
    function success(data) { return { type: caseConstants.ACTIVE_TAB_MEDICAL_RECORD, payload: data || null } }
}