import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AvatarSlider = (props) => {
    const { filteredData } = props && props;
    const [slidesToShow, setSlidesToShow] = useState(Math.min(4, filteredData.length));
    const [rows, setRows] = useState(2);

    useEffect(() => {
        const updateSlidesToShow = () => {
            const newSlidesToShow = Math.min(4, filteredData.length);
            setSlidesToShow(newSlidesToShow);
        };

        const updateRows = () => {
            const newRows = filteredData?.length > 8 ? 2 : 1;
            setRows(newRows);
        };

        updateSlidesToShow();
        updateRows();
    }, [filteredData, slidesToShow]);

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
                <i className="fa fa-angle-left" aria-hidden="true"></i>
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
                <i className="fa fa-angle-right" aria-hidden="true"></i>
            </div>
        );
    };

    const CustomDots = ({ children }) => {
        return <ul className="slick-dots-custom mt-3">{children}</ul>;
    };

    const settings = {
        rows: rows,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        dotsClass: 'slick-dots-custom',
        appendDots: (dots) => <CustomDots>{dots}</CustomDots>,
        draggable: true,
        swipe: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    return (
        <div className="slider-wrapper">
            <Slider {...settings}>
                {props?.children}
            </Slider>
        </div>
    );
};

export default AvatarSlider;