import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

//imgUser
import imgUser from '@images/common/placehoder-avatar.svg';

//utils
import { messageData, LoaderText } from "@utils";

//common
import { DataEmty } from "@components-common";

//boostrap
import {
    Badge
} from "reactstrap";

function UserStoriesItem(props) {
    const { tasks, isLoading } = props && props;

    const columns = [{
        dataField: 'task_gid',
        text: ' ',
        classes: "align-middle",
        hidden: true
    }, {
        dataField: 'checkbox',
        text: '',
        classes: "align-middle",
        style: {
            width: "40px"
        },
        formatter: (value, row) => {
            return (
                <span className="icon-checked text-3"></span>
            );
        }
    }, {
        dataField: 'name',
        text: '',
        classes: "align-middle text-left"
    }, {
        dataField: 'assignee',
        classes: "align-middle text-left",
        text: '',
        formatter: (value, row) => {
            const nameAssignee = value?.name;
            const photoAssignee = value?.photo;
            return (
                <div className="userBox">
                    <div className="userBox__left"><img src={photoAssignee || imgUser} className="imgUser bg-grey-light" alt="img" /></div>
                    <div className="userBox__right">
                        <p className="text-2">{nameAssignee}</p>
                    </div>
                </div>
            )
        }
    }, {
        dataField: 'scrum_point',
        text: '',
        style: {
            width: "69px"
        },
        classes: "align-middle text-center",
        formatter: (value, row) => {
            return (
                <Badge className="font-weight-400 text-1 badgeCustom">
                    {value}
                </Badge>
            );
        }
    }];

    return (
        <div className="position-relative">
            <BootstrapTable
                keyField='task_gid'
                wrapperClasses="table-responsive tableChildren"
                data={(tasks && tasks) || []}
                columns={columns}
                noDataIndication={() => (
                    <DataEmty content={messageData?.default?.content} />
                )}
            />
            {isLoading && (
                <LoaderText />
            )}
        </div>
    );
};

export default UserStoriesItem;