import React, { useState, useEffect } from "react";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";

//boostrap
import { FormGroup, Input, Button } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//router
import { useParams } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

//components
import { ModalCheckPoints, ModalDeleteCheckPoints } from "../Modal";

//common
import { DataEmty } from "@components-common";
import { atcEditCheckPoints } from "@actions";

var ID = function () {
  return Math.random().toString(36).substr(2, 9);
};

function ObjectsData(cell) {
  //cell, row, rowIndex, formatExtraData

  return (
    <React.Fragment>
      {cell && cell.map((value) => <p key={ID()}>{value}</p>)}
    </React.Fragment>
  );
}

function CheckPointstable(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { data } = props && props;

  const [modal, setModal] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [type, setType] = useState("");
  const [stateParams, setStateParams] = useState({});
  const [isCondition, setCondition] = useState(false);
  const [stateCheckPointId, setCheckPointId] = useState("");
  const timePointId = props && props.timePointId;

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        Action: "Update",
        Target: "Checkpoints",
        TimePointId: timePointId,
        CheckPointId: stateCheckPointId,
        Condition: (stateParams && stateParams.Condition) || "",
      };
      dispatch(atcEditCheckPoints(id, params));
    };

    if (isCondition) {
      fetchData();
      setCondition(false);
    }
  }, [isCondition, id, dispatch, stateParams, stateCheckPointId, timePointId]);

  const actionsFormatter = (cell, row, rowIndex) => {
    return (
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-outline-brand btn mr-2"
          data-id={row && row.CheckPointId}
          onClick={() => toggle("edit", row)}
        >
          <i
            className="fa fa-pencil"
            aria-hidden="true"
            data-id={row && row.CheckPointId}
          ></i>
        </button>
        <button
          className="btn btn-outline-danger"
          onClick={() => toggleDel("delete", row)}
        >
          <i className="fa fa-trash" aria-hidden="true"></i>
        </button>
      </div>
    );
  };

  const toggle = (type, data) => {
    setStateParams(data);
    setType(type);
    setModal(!modal);
  };

  const toggleDel = (type, data) => {
    if (type === "delete") {
      setStateParams(data);
      setType(type);
    }
    setModalDel(!modalDel);
  };

  const columns = [
    {
      dataField: "CheckPointId",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Title",
      text: "Name/Title",
      editable: false,
      classes: "align-middle",
      headerClasses: "headTable text-left font-weight-500 --text-primary",
    },
    {
      dataField: "Objects",
      text: "Item(s)",
      classes: "align-middle",
      formatter: ObjectsData,
      headerClasses: "headTable text-left font-weight-500 --text-primary",
    },
    {
      dataField: "Message",
      text: "Message",
      editable: false,
      classes: "align-middle text-left",
      headerClasses: "headTable text-left font-weight-500 --text-primary",
      formatter: (value, row) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: value }} />
        )
      }
    },
    {
      dataField: "Condition",
      style: {
        width: "25%",
      },
      text: "Action",
      editable: false,
      formatter: (value, row) => {
        const handleChange = (e) => {
          const { name, value } = e.target;
          setStateParams((prevState) => ({ ...prevState, [name]: value }));
          setCheckPointId(row.CheckPointId);
          setCondition(true);
        };

        return (
          <FormGroup className="mb-0">
            <Input
              type="select"
              name="Condition"
              defaultValue={row.Condition}
              onChange={handleChange}
            >
              <option value="" disabled>
                Select and option below...
              </option>
              <option value="must_order">Must Be Ordered Now</option>
              <option value="must_have_ordered">
                Must Have Been Ordered By Now
              </option>
              <option value="must_not_order">Must NOT Be Ordered</option>
            </Input>
          </FormGroup>
        );
      },
      classes: "align-middle",
      headerClasses: "headTable text-left font-weight-500 --text-primary",
    },
    {
      dataField: "Actions",
      style: {
        width: "10%",
      },
      text: "",
      editable: false,
      formatter: actionsFormatter,
      classes: "align-middle",
      headerClasses: "headTable text-left font-weight-500 --text-primary",
    },
  ];

  const listItemsDropdown = useSelector(
    (state) => state.itemsByCheckPoints || []
  );
  return (
    <React.Fragment>
      <BootstrapTable
        keyField="CheckPointId"
        condensed
        wrapperClasses="table-responsive"
        bordered={false}
        data={data || []}
        columns={columns}
      />
      {data?.length === 0 && <DataEmty content="No Data" />}
      <div className="text-center">
        <Button
          type="submit"
          className="--btn-primary my-3 mx-auto"
          name="add"
          onClick={() => toggle("add", {})}
        >
          <i className="fa fa-plus mr-2" />
          Add Condition
        </Button>
      </div>
      <ModalCheckPoints
        {...props}
        actions={type}
        items={(listItemsDropdown && listItemsDropdown) || []}
        toggle={toggle}
        isOpen={modal}
        paramsData={stateParams}
      />
      <ModalDeleteCheckPoints
        {...props}
        actions={type}
        toggleDel={toggleDel}
        isOpen={modalDel}
        paramsData={stateParams}
      />
    </React.Fragment>
  );
}

export default CheckPointstable;
