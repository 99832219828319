import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const ResponseTab = (props) => {
    const { values, errors, touched, handleChange, handleBlur } = props && props;
    return (
        <React.Fragment>
            <FormGroup>
                <Label className="labeForm" for="ChatQuestion">Question Text <span className="text-danger">*</span></Label>
                <Input
                    type="textarea"
                    name="ChatQuestion"
                    id="ChatQuestion"
                    value={values.ChatQuestion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!errors.ChatQuestion && touched.ChatQuestion}
                    rows="5"
                    autoComplete='off'
                />
                <FormFeedback>{errors.ChatQuestion}</FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label className="labeForm" for="ChatResponse">Response Text <span className="text-danger">*</span></Label>
                <Input
                    type="textarea"
                    name="ChatResponse"
                    id="ChatResponse"
                    value={values.ChatResponse}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={!!errors.ChatResponse && touched.ChatResponse}
                    rows="5"
                    autoComplete='off'
                />
                <FormFeedback>{errors.ChatResponse}</FormFeedback>
            </FormGroup>
        </React.Fragment>
    );
}

export default ResponseTab;