import React from 'react';
import styled from 'styled-components';
import { Icon } from '@components/common';
import upload from '@images/teachV2/upload-cloud.svg';

const UploadContainer = styled.div`
  min-height: 120px;
  padding: 40px 24px;
  border-radius: 6px;
  background: #FFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
  border: 1px dashed #D0D5DD;
`;

const UploadIconContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

const UploadTextContainer = styled.div`
  text-align: center;
`;

const UploadText = styled.p`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const HighlightText = styled.span`
  color: #0089C2;
  font-size: 14px;
  font-weight: 600;
`;

const FileUpload = ({ handleChange, handleClick, handleDrag, hiddenFileInputText, type }) => {
  return (
    <UploadContainer onDragOver={handleDrag} onDrop={(e) => handleChange(e, "drop")} onClick={handleClick}>
      <input
        type="file"
        onChange={(e) => handleChange(e, "change")}
        ref={hiddenFileInputText}
        name="text"
        style={{ display: "none" }}
      />
      <UploadIconContainer className='wrap-option__upload-icon'>
        <Icon src={upload} />
      </UploadIconContainer>
      <UploadTextContainer className='wrap-option__upload-text'>
        <UploadText className="list__input-item--note">
          <HighlightText>Click to upload</HighlightText> or drag and drop your file here
        </UploadText>
        <UploadText className="list__input-item--note">
          <HighlightText>{type}</HighlightText>
        </UploadText>
      </UploadTextContainer>
    </UploadContainer>
  );
};

export default FileUpload;