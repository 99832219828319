import React, { useState, useEffect } from 'react';

//reactBoostrap
import {
    FormGroup,
    Label,
    Col,
    Input
} from "reactstrap";

function SettingsTab(props) {
    //props
    const { stateFeedbackText, handleChangeFeedbackText } = props && props;
    
    //state 
    const [stateData, setData] = useState(stateFeedbackText || {});

    useEffect(() => {
        setData(stateFeedbackText || {});
    }, [stateFeedbackText])

    return (
        <Col md={6}>
            <FormGroup>
                <Label for="mentioned_correct">
                    Feedback text before listing mentioned concepts
                </Label>
                <Input
                    id="mentioned_correct"
                    name="mentioned_correct"
                    placeholder=""
                    type="text"
                    value={stateData?.mentioned_correct}
                    onChange={handleChangeFeedbackText}
                />
            </FormGroup>
            <FormGroup>
                <Label for="missed_correct">
                    Feedback text before listing missed concepts
                </Label>
                <Input
                    id="missed_correct"
                    name="missed_correct"
                    placeholder=""
                    type="text"
                    value={stateData?.missed_correct}
                    onChange={handleChangeFeedbackText}
                />
            </FormGroup>
        </Col>
    );
};

export default SettingsTab;