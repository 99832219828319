import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import classnames from "classnames";
import {apiCaller} from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//reactstrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import {
  actLoadExamFindingById,
  updateTimePointOfExamFinding,
  createTimePointOfExamFinding,
} from "@actions";

//components common
import { AudioFile, VideoLink, ImageFile, DropDownMedia, VideoFile } from "@components-common/media";

//Config
import * as Config from "@constants/Config";

function ModalPhysicalExam(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, timePointId, PointId, actions, AttributeId  } = props && props;
  const editor = useRef(null);

  //state
  const [isResetData, setResetData] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [stateData, setStateData] = useState({});
  const [activeTab, setActiveTab] = useState("1");
  const [content, setContent] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [dataMediaLoad, setDataMediaLoad] = useState("");

  //Call API
  useEffect(() => {
    //Media - Load Exam Finding
    const fetchData = async () => {
      const paramsLoad = {
        Action: "Load",
        Target: "ExamFinding",
        TimePointId: timePointId,
        PointId: PointId,
        AttributeId: AttributeId,
      };
      dispatch(actLoadExamFindingById(id, paramsLoad));
    };

    //Modal Open
    if (actions === "edit") {
      fetchData();
    }
  }, [AttributeId, isOpen, PointId, timePointId, dispatch, id, actions]);

  //Save
  const handleSubmit = () => {
    const currentType = stateData && stateData.MediaType;
    let formData = new FormData();
    formData.append("Action", "Upload");
    setIsloading(true);

    //Check Upload
    if (isUpload) {
      if (currentType === "ImageFile") {
        formData.append("File", imageData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else if (currentType === "AudioFile") {
        formData.append("File", audioData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else {
        sendData();
      }
    } else {
      //data cu
      setStateData((prevState) => ({
        ...prevState,
        MediaType: dataMediaLoad.MediaType,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
      sendData();
    }
  };

  const sendData = (newUrl) => {
    if (actions === "edit") {
      const params = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: timePointId,
        PointId: PointId,
        ExamFindings: [stateData],
      };
      params.ExamFindings[0].InitialValue = content;

      //Media
      if (stateData.MediaType !== "VideoLink" && isUpload) {
        params.ExamFindings[0].MediaUrl = newUrl;
      } else {
        params.ExamFindings[0].MediaUrl = stateData.MediaUrl;
      }
      dispatch(updateTimePointOfExamFinding(id, params));
    }

    if (actions === "add") {
      const paramsAdd = {
        Action: "Create",
        Target: "ExamFinding",
        TimePointId: timePointId,
        PointId: String(PointId),
        ExamFinding: {
          Name: "",
          InitialValue: "",
          MediaType: "",
          MediaUrl: null,
        },
      };

      //set value
      paramsAdd.ExamFinding.Name = stateData.Name;
      paramsAdd.ExamFinding.InitialValue = content;
      paramsAdd.ExamFinding.MediaType = stateData.MediaType;

      //Media
      if (stateData.MediaType !== "VideoLink" && isUpload) {
        paramsAdd.ExamFinding.MediaUrl = newUrl;
      } else {
        paramsAdd.ExamFinding.MediaUrl = stateData.MediaUrl;
      }

      dispatch(createTimePointOfExamFinding(id, paramsAdd));
    }
    //close Modal and Loading
    props && props.toggle();
    setIsloading(false);
    setIsUpload(false);
  };

  const requestApiUpload = (id, params) => {
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const newUrl = res && res.data && res.data.NewUrl;
      if (res && res.status === 200) {
        setTimeout(() => {
          //Send params
          sendData(newUrl);
        }, 3000);
      }
    });
  };

  //tabs
  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //get data store
  const loadExamFindingById = useSelector(
    (state) =>
      (state.loadExamFindingById && state.loadExamFindingById.data) || {}
  );

  const resetDataModalAdd = () => {
    //setState
    setStateData((prevState) => ({
      ...prevState,
      Name: "",
      InitialValue: "",
      MediaType: "",
      MediaUrl: "",
    }));
    setResetData(true);
  };

  //Set data
  useEffect(() => {
    //check actions is Edit setState Data
    if (actions === "edit") {
      const params = loadExamFindingById && loadExamFindingById.ExamFinding;
      setStateData(params);

      //Save data current
      setDataMediaLoad({
        MediaUrl: params && params.MediaUrl,
        MediaType: params && params.MediaType,
      });
      setResetData(false);
    }

    //check actions is Add setState Data
    if (!isResetData && actions === "add") {
      resetDataModalAdd();
    }
  }, [loadExamFindingById, actions, isResetData]);

  // handleInput
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  const handleType = (e) => {
    const value = e.target.value;
    //ResetData
    setAudioData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    //Nếu type là data load ban đầu
    if (dataMediaLoad.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
    }
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    //Set File FormData
    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  //Upload Audio
  const onFileAudioUpload = (event) => {
    const fileAudio = event.target.files[0];
    const urlPreview = URL.createObjectURL(fileAudio);
    setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
    setAudioData(fileAudio);
    setIsUpload(true);
  };

  const isType = stateData && stateData.MediaType;
  const urlValue = stateData && stateData.MediaUrl;
  return (
    <Modal
      style={{ maxWidth: "650px" }}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>
        {actions === "edit" ? (
          <span>Physical Exam - {(stateData && stateData.Name) || ""}</span>
        ) : (
          "Add Physical Exam"
        )}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="Name">Name</Label>
          <Input
            type="text"
            value={(stateData && stateData.Name) || ""}
            onChange={handleInput}
            name="Name"
            autoComplete="off"
          />
        </FormGroup>
        <Nav className="tabBorder" tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => {
                toggleTabs("1");
              }}
            >
              Content
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggleTabs("2");
              }}
            >
              Media
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <JoditEditor
              ref={editor}
              value={(stateData && stateData.InitialValue) || ""}
              config={Config.CONFIG_JODIT}
              placeholder="Enter the instructions for the learner here..."
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)}
            />
          </TabPane>
          <TabPane tabId="2">
            <div className="mediaContainer">
              <DropDownMedia onChange={handleType} value={isType} />
              {isType === "VideoLink" && (
                <VideoLink value={urlValue} onChange={handleInput} />
              )}
              {isType === "ImageFile" && (
                <ImageFile onChange={handleUploadImage} value={urlValue} />
              )}
              {isType === "AudioFile" && (
                <AudioFile onChange={onFileAudioUpload} value={urlValue} />
              )}
              {isType === "VideoFile" && (
                <VideoFile onChange={onFileAudioUpload} value={urlValue} />
              )}
              <hr />
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>{" "}
        <Button
          className="--btn-primary d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Save
        </Button>
        {actions === "edit" && (
          <Button
            className="btn btn-danger"
            disabled={isLoading}
            onClick={() => props && props.toggleDel(stateData)}
          >
            <i className="fa fa-trash mr-2" aria-hidden="true"></i> Delete
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ModalPhysicalExam;
