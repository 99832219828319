import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

//boostrap
import {
  Container,
  Row,
  Col
} from 'reactstrap';

//untils
import { checkAuth } from "@utils";

function NotFound() {
  let history = useHistory();

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  return (
    <div className="wrapper -notFoundPage" >
      <Container>
        <Row>
          <Col>
            <div className="notFoundCt">
              <h1 className="notFoundCt__h1">404</h1>
              <h2 className="notFoundCt__h2">Page Not Found</h2>
              <p className="notFoundCt__desc">The Page you are looking for doesn't exist or an other error occured. Go to <Link to="/">Home Page</Link>.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NotFound;