import React, { useCallback, useState, useEffect } from "react";
import { useDrop } from "react-dnd";
import update from "immutability-helper";
import styled from 'styled-components';

//common
import { ElementPoint } from "../../PhysicalExam/common";

//router
import { useParams } from "react-router-dom";

//Icon
import { Iconsax } from "@components-common";

//redux
import { useDispatch } from "react-redux";

//lodash
import _ from "lodash";

//constants
import { storyboardConstants } from "@constants";

//context menu
import {
  Menu,
  Item,
  Separator,
  animation,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

//api
import { apiCaller } from "@utils";

// Define the styled component
const StyledSpan = styled.span`
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
  display: flex;
`;

function ContainerDragPoint(props) {
  //props
  let { widthExPoint, heightExPoint, timepointData, data } = props && props;
  const dispatch = useDispatch();

  const styles = {
    width: widthExPoint,
    height: heightExPoint,
    position: "relative",
  };

  let { id } = useParams();
  const [boxes, setBoxes] = useState({});
  const [statePointId, setPointId] = useState("");
  const [dataContext, setDataContext] = useState([]);

  useEffect(() => {
    const covertData = async () => {
      //Convert data
      let convertData = Object.assign({}, data);
      let newObjectData = {};
      Object.keys(convertData).map((key) => {
        newObjectData = {
          ...convertData[key],
          top: (heightExPoint * convertData[key].PositionY) / 100,
          left: (widthExPoint * convertData[key].PositionX) / 100,
        };
        convertData[key] = newObjectData;
        return true;
      });
      setBoxes(convertData);
    };
    covertData();
  }, [widthExPoint, heightExPoint, data]);

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [boxes, setBoxes]
  );

  const apiUpdateExamPoint = (item, left, top) => {
    const boxesClone = _.cloneDeep(boxes);
    //params
    const pointId = boxesClone[item.id] && boxesClone[item.id].PointId;
    const PositionX = (left * 100) / widthExPoint;
    const PositionY = (top * 100) / heightExPoint;
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timepointData?.Id,
      ExamPoint: {
        PointId: pointId,
        PositionX: PositionX.toFixed(),
        PositionY: PositionY.toFixed(),
      },
    };

    dispatch({ type: storyboardConstants.UPDATE_POINT_REQUEST, isLoading: true });
    //Call Api Update Point
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch({ type: storyboardConstants.UPDATE_POINT_SUCCESS, payload: data });
      } else {
        dispatch({ type: storyboardConstants.UPDATE_POINT_FAILURE, error: 'error' });
        console.log("error");
      }
    });
  }

  const [, drop] = useDrop(() => {
    return {
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        apiUpdateExamPoint(item, left, top);
        moveBox(item.id, left, top);
        return undefined;
      },
    };
  }, [moveBox]);

  const { show } = useContextMenu({ id: statePointId });
  const handleContextMenu = (event, PointId, data) => {
    event.preventDefault();
    show(event, {
      props: {
        key: PointId,
      },
    });
    setPointId(PointId);
    setDataContext(data);
  };

  const handleItemClick = (PointId, value, actions) => {
    if (actions === "delete-exam-point") {
      props && props.toggleDelExamPoint(PointId, value, actions);
    } else {
      props && props.toggle(PointId, value, actions);
    }
  };

  //Context Menu - Fidings
  const renderMenu =
    dataContext?.map((value, index) => {
      return (
        <Item
          onClick={(e) => handleItemClick(statePointId, value, "edit")}
          key={index}
        >
          <i style={{ color: '#0089C2' }} className="fa fa-dot-circle-o mr-2" aria-hidden="true"></i>
          <StyledSpan>{value.Name}</StyledSpan>
        </Item>
      );
    });

  return (
    <div ref={drop} style={styles}>
      {Object.keys(boxes).map((key) => {
        const { left, top, PointId, Findings } = boxes[key];
        return (
          <div
            key={key}
            onContextMenu={(e) => handleContextMenu(e, PointId, Findings)}
          >
            <ElementPoint
              id={key}
              left={left}
              top={top}
              PointId={PointId}
              props={props}
            />
          </div>
        );
      })}
      {/* Menu */}
      <Menu id={statePointId} animation={animation.fade}>
        {dataContext?.length > 0 ? renderMenu : <Item className="noExam text-muted opacity-7"><StyledSpan>No exam finding</StyledSpan></Item>}
        <Separator />
        <Item onClick={(e) => handleItemClick(statePointId, {}, "add")}>
          <Iconsax iconName="plus-square" fill="#0089C2" size={15} />
          <StyledSpan>Add Exam Finding</StyledSpan>
        </Item>
        <Item onClick={(e) => handleItemClick(statePointId, {}, "delete-exam-point")}>
          <Iconsax iconName="trash-2" fill="#F04438" size={15} />
          <StyledSpan>Delete Point</StyledSpan>
        </Item>
      </Menu>
    </div>
  );
}

export default ContainerDragPoint;