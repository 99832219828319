import React, { useState, useEffect, useRef } from "react";
import { MessageEmtyData } from "@ui-partents/Message";
import { FormModal } from '@ui-partents/Modal';

// Reactstrap
import { FormGroup, Input } from 'reactstrap';

// Redux
import { useDispatch, useSelector } from "react-redux";

// React-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

// Actions
import { atcAddLearnersToCohort } from "@actions";

// Import the pagination configuration
import { paginationOptions } from "@utils";

//Style components
import styled from 'styled-components';

function ModalAddLearner(props) {
  const { cohortObject, toggle, isOpen } = props;

  // data Store
  const dataLearner = useSelector((state) => (state?.dataLearnersByCohortId?.data) || []);
  const institutionAccount = useSelector((state) => (state.accountInstitution?.data?.Accounts || []));

  const dispatch = useDispatch();

  // State
  const [accountLearners, setStateAccountLearners] = useState([]);
  const [loading, setLoading] = useState(false);
  const selectedItemsRef = useRef(new Set());

  const SearchIcon = styled.svg`
    position: absolute;
    left: 12px;
    width: 20px;
    height: 20px;
    fill: none;
    stroke: #7E8EA4;
    top: 50%;
    transform: translateY(-50%);
  `;

  // onLoad
  useEffect(() => {
    // Lọc mảng, loại trừ email hiện tại user đang login
    let newDataLearner = institutionAccount?.filter(
      (o1) => !dataLearner.some((o2) => o1.Email.toLowerCase() === o2.email.toLowerCase())
    );

    // Nếu có data
    setStateAccountLearners(newDataLearner?.length > 0 ? newDataLearner : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Columns definition
  const columns = [
    {
      dataField: "Id",
      text: "ID",
      hidden: true,
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "Email",
      text: "Email",
      headerClasses: "font-weight-400 text-nowrap",
    }
  ];

  const handleCheckboxChange = (row, isSelected) => {
    if (isSelected) {
      selectedItemsRef.current.add(row.Id);
    } else {
      selectedItemsRef.current.delete(row.Id);
    }
  };

  const handleSelectAll = (isSelected, rows) => {
    if (isSelected) {
      rows.forEach((row) => selectedItemsRef.current.add(row.Id));
    } else {
      rows.forEach((row) => selectedItemsRef.current.delete(row.Id));
    }
  };

  const CheckboxRenderer = ({ checked, disabled }) => (
    <FormGroup check className="form-common">
      <Input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className="react-checkbox-common react-checkbox-md"
      />
    </FormGroup>
  );

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelected) => handleCheckboxChange(row, isSelected),
    onSelectAll: (isSelected, rows) => handleSelectAll(isSelected, rows),
    selectionRenderer: CheckboxRenderer,
    selectionHeaderRenderer: CheckboxRenderer,
  };

  // Handle Add
  const handleAdd = async () => {
    setLoading(true);
    const params = {
      "Action": "AddLearners",
      "CohortId": cohortObject?.CohortId,
      "LearnerIds": Array.from(selectedItemsRef.current),
    };

    try {
      const response = await dispatch(atcAddLearnersToCohort(params));
      if (response?.status === 200) {
        console.log("Learners added successfully:", response);
        setStateAccountLearners([])
        toggle();
      } else {
        console.error("Failed to add learners. Status:", response?.status);
      }
    } catch (error) {
      console.error("Error while adding learners:", error);
    } finally {
      setLoading(false);
      console.log("Finished the add learners operation.");
      selectedItemsRef.current.clear();  // Xóa dữ liệu khi hoàn thành
    }
  };
  const totalSize = accountLearners?.length || 0;

  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize, pageSize: 6 });

  const { SearchBar } = Search; //ClearSearchButton
  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="Id"
        data={accountLearners || []}
        columns={columns}
        search
      >
        {(toolkitprops) => {
          return (
            <>
              <div style={{ marginBottom: "16px" }}>
                <div className="searchBarCustom full-100" style={{ position: 'relative' }}>
                  <SearchIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                      d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                      stroke="#7E8EA4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                    />
                  </SearchIcon>
                  <SearchBar
                    className="search-sm" {...toolkitprops.searchProps}
                    onSearch={(e) => {
                      paginationProps.page = 1
                      toolkitprops.searchProps.onSearch(e)
                    }}
                    placeholder="Enter email to search for user"
                  />
                  {/* <ClearSearchButton 
                    className="searchBarCustom__clear"
                    { ...props.searchProps }
                  /> */}
                </div>
              </div>
              <BootstrapTable
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                wrapperClasses="table-custom-common table-responsive mt-3"
                bordered={false}
                selectRow={selectRow}
                data={accountLearners || []}
                noDataIndication={() => (
                  <MessageEmtyData
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
              />
            </>
          );
        }}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`Add Learner(s) to ${cohortObject?.Name}`}
      hiddenIcon={true}
      onAction={handleAdd}
      actionText={`Add to Cohort`}
      maxWidth={`950px`}
      describeContent={`Select from the list below the user(s) that you would like to add to this cohort.`}
      isLoading={loading}
    >
      <PaginationProvider pagination={paginationFactory(paginationConfig)}>
        {contentTable}
      </PaginationProvider>
    </FormModal>
  );
}

export default ModalAddLearner;