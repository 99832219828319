import React from "react";

//components
import { CaseTitle } from "../InteractAssistant";
import { ResetCasePanel } from "@components/learner/StandardScreen/CaseTask";

function LearnerTop(props) {
    const { caseData } = props && props;
    const caseTitle = caseData?.data?.Case.Title;
    return (
        <div className="learn-top">
            <div className="learn-top__left">
                <CaseTitle {...props} textCenter={true} title={caseTitle} />
            </div>
            <div className="learn-top__right">
                <ResetCasePanel {...props} />
            </div>
        </div>
    );
}

export default LearnerTop;