import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from "jodit-react";

import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//Config
import * as Config from "@constants/Config";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

const ActionAddNotes = (props) => {
    const { isOpen, toggle, itemCurrent } = props && props;
    const dispatch = useDispatch();
    const editor = useRef(null);
    let { id } = useParams();

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);
    const [content, setContent] = useState(itemCurrent?.Contents || '');
    const [stateItem, setStateItem] = useState(itemCurrent || {});

    //load Data
    useEffect(() => {
        setValue("Title", itemCurrent?.Title, { shouldDirty: false });
        setValue("Type", itemCurrent?.Type, { shouldDirty: false });
        setValue("DateOffset", itemCurrent?.DateOffset || 0, { shouldDirty: false });
        setContent(itemCurrent?.Contents || '');
        setStateItem(itemCurrent || {});
    }, [itemCurrent, setValue])

    //Submit
    const onSubmit = (data) => {
        setLoading(true);
        const isLoading = true;
        const params = {
            "Action": "Update",
            "Target": "DocumentationNote",
            "DocumentationNoteId": stateItem?.DocumentationNoteId,
            "Title": data?.Title,
            "Type": data?.Type,
            "Contents": content,
            "DateOffset": data?.DateOffset

        }

        //Call Api
        dispatch({ type: storyboardConstants.PUT_DOCUMENTATION_NOTE_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: storyboardConstants.PUT_DOCUMENTATION_NOTE_SUCCESS,
                    payload: data
                });
                //Close
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: storyboardConstants.PUT_DOCUMENTATION_NOTE_FAILURE, error: 'error' });
                setLoading(false);
            }
        });
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
                <ModalHeader toggle={toggle}>Edit Note</ModalHeader>
                <ModalBody>
                    <FormGroup className="mt-2" style={{ maxWidth: "50%" }}>
                        <Label for="Title">Title <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Title", { required: true })}
                            type="text"
                            name="Title"
                            invalid={errors.Title?.type === "required"}
                            defaultValue={watch("Title")}
                            onChange={(e) => {
                                setValue("Title", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Title?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-2" style={{ maxWidth: "50%" }}>
                        <Label for="Type">Type <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("Type", { required: true })}
                            type="text"
                            name="Type"
                            invalid={errors.Type?.type === "required"}
                            defaultValue={watch("Type")}
                            onChange={(e) => {
                                setValue("Type", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Type?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Label for="DateOffset">Date Offset <span className="m--font-danger">*</span></Label>
                        <Input
                            {...register("DateOffset", { required: true })}
                            type="number"
                            name="DateOffset"
                            invalid={errors.DateOffset?.type === "required"}
                            defaultValue={watch("DateOffset")}
                            onChange={(e) => {
                                setValue("DateOffset", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                            pattern="[0-9]+"
                            min="0"
                            style={{ maxWidth: '150px' }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <FormFeedback invalid={String(errors.DateOffset?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="primary"
                        className="d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ActionAddNotes;