// --------
//Orders/Medications
//Actions/Actions
// --------
import React, { useState, useEffect } from "react";

//common
import { DataEmty } from "@components-common";

//utils
import { LoaderText } from "@utils";

//lodash
import _ from 'lodash';

//components
import {
  AccordionFilter,
  TableInterventions,
  AccordionHeader
} from '../Interventions';

//common
import {
  ModalUpdateCategory,
  ModalDelCategory,
  ModalIntervention,
  ModalDeleteIntervention,
  ModalAddEffects,
  ModalEffect,
  ModalDeleteEffects
} from '../../storyboard/Modal';

//Orders - Action - Medications
function InterventionsMain (props) {
  let indexPlus;

  //props
  const { dataStore, categoryType, paramsElements } = props && props;

  //filter Time === 0
  const listTimePointFirst = _.filter(dataStore?.data, function (o) {
    return o?.TimePoint?.Time === 0;
  });

  //filter Time !== 0
  const listTimepointLast = _.filter(dataStore?.data, function (o) {
    return o?.TimePoint?.Time !== 0;
  });

  let TimepointId = listTimePointFirst && listTimePointFirst[0]?.TimePoint?.TimepointId;
  const dataProps = listTimePointFirst?.[0]?.[paramsElements];

  const [dataAccordion, setDataAccordion] = useState((dataProps && dataProps) || []);
  const [active, setActive] = useState(0);
  const [searchText, setSearchText] = useState("");

  //Intervention
  const [modalIntervention, setModalIntervention] = useState(false);
  const [typeIntervention, setTypeIntervention] = useState("");
  const [dataIntervention, setdataIntervention] = useState({});

  //Delete Intervention
  const [modalDel, setModalDel] = useState(false);
  const [interventionId, setInterventionId] = useState(0);

  //Effect
  const [modalAddEffect, setModalAddEffect] = useState(false); //Add
  const [modalEffect, setModalEffect] = useState(false); //Edit
  const [dataEffects, setDataEffects] = useState({});
  const [modalDelEffect, setModalDelEffect] = useState(false); //delete
  const [effectId, setEffectId] = useState(0);

  //category
  const [activeCategory, setActiveCategory] = useState(0);
  const [dataCurrent, setDataCurrent] = useState({});
  const [actionsCategory, setActionsCategory] = useState("");
  const [modalCategory, setModalCategory] = useState({
    addCategory: false,
    editCategory: false,
    deleteCategory: false
  });

  useEffect(() => {
    setDataAccordion((dataProps && dataProps) || []);
  }, [dataProps])

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  //toggle - all actions category
  const toggleCategory = (type, value) => {
    setModalCategory((prevState) => ({ ...prevState, [type]: !modalCategory[type] }));
    setDataCurrent(value);
    setActiveCategory((value && value.CategoryId) || 0);
    setActionsCategory(type);
  };

  // toggleIntervention
  const toggleIntervention = (type, data, idCategory) => {
    setModalIntervention(!modalIntervention);
    setTypeIntervention(type);
    setdataIntervention(data);
    setActiveCategory(idCategory);
  };

  const toggleDelIntervention = (type, InterventionId, idCategory) => {
    setTypeIntervention(type);
    setInterventionId(InterventionId);
    setActiveCategory(idCategory);
    setModalDel(!modalDel);
  };

  //Edit Effect
  const toggleEffect = (data, idCategory, InterventionId) => {
    setModalEffect(!modalEffect);
    //set data modal
    setDataEffects(data);
    setActiveCategory(idCategory);
    setInterventionId(InterventionId);
  };

  //Delete Effect
  const toggleDelEffects = (InterventionId, idCategory, EffectId) => {
    setInterventionId(InterventionId);
    setModalDelEffect(!modalDelEffect);
    setActiveCategory(idCategory);
    setEffectId(EffectId);
  };

  //Add Effect
  const toggleAddEffect = (InterventionId, idCategory) => {
    setTypeIntervention("addEffects");
    setInterventionId(InterventionId);
    setActiveCategory(idCategory);
    setModalAddEffect(!modalAddEffect);
  };

  const onSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchText(searchTerm);
    filterData(searchTerm);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") {
      setDataAccordion(dataProps);
    } else {
      const newArrFilter = _.cloneDeep(dataProps);
      if (newArrFilter.length > 0) {
        const searchResult = _.filter(newArrFilter, category => {
          return (
            category.Name.includes(lowercasedValue) ||
            _.find((category.Interventions || []), intervention => intervention.Name.toLowerCase().includes(lowercasedValue))
          )
        }).map(item => {
          item.Interventions = _.filter((item.Interventions || []), intervention => intervention.Name.toLowerCase().includes(lowercasedValue))
          return item
        })
        setDataAccordion(searchResult);
      }
    }
  }

  return (
    <div className="medicationsTab position-relative">
      {dataStore.isLoading &&
        <LoaderText />
      }
      <div className="accordionExam">
        <AccordionFilter
          toggleCategory={toggleCategory}
          onSearch={onSearch}
          searchText={searchText}
        />
        {dataAccordion.length === 0 && <DataEmty content="No Data" />}
        {dataAccordion?.map((value, index) => (
          <div className="accordionExam__item" key={index}>
            <AccordionHeader
              eventHandler={eventHandler}
              index={index}
              active={active}
              indexCount={indexCount}
              value={value}
              toggleCategory={toggleCategory}
            />
            <TableInterventions
              {...props}
              index={index}
              indexCount={indexCount}
              active={active}
              value={value}
              listTimepointLast={listTimepointLast}
              listTimePointFirst={listTimePointFirst}
              toggleCategory={toggleCategory}
              toggleIntervention={toggleIntervention}
              toggleDel={toggleDelIntervention}
              toggleAddEffect={toggleAddEffect}
              toggleEffect={toggleEffect}
              toggleDelEffects={toggleDelEffects}
            />
          </div>
        ))}
        
        {/* Category */}
        <ModalUpdateCategory
          {...props}
          activeCategory={activeCategory}
          isOpen={actionsCategory === 'addCategory' ? modalCategory.addCategory : modalCategory.editCategory}
          dataCurrent={dataCurrent}
          toggle={() => toggleCategory(actionsCategory)}
          type={categoryType}
          action={actionsCategory}
        />

        <ModalDelCategory
          {...props}
          activeCategory={activeCategory}
          isOpen={modalCategory.deleteCategory}
          dataCurrent={dataCurrent}
          toggle={() => toggleCategory("deleteCategory")}
          categoryType={categoryType}
          action={actionsCategory}
        />

        {/* Intervention */}
        <ModalIntervention
          {...props}
          toggle={toggleIntervention}
          isOpen={modalIntervention}
          typeIntervention={typeIntervention}
          dataIntervention={dataIntervention}
          activeCategory={activeCategory}
          timePointId={TimepointId}
          categoryType={categoryType}
        />

        <ModalDeleteIntervention
          {...props}
          toggleDel={toggleDelIntervention}
          isOpen={modalDel}
          interventionId={interventionId}
          activeCategory={activeCategory}
          timePointId={TimepointId}
        />

        {/* Effect */}
        <ModalAddEffects
          {...props}
          toggle={toggleAddEffect}
          isOpen={modalAddEffect}
          typeIntervention={typeIntervention}
          interventionId={interventionId}
          activeCategory={activeCategory}
          timePointId={TimepointId}
          categoryType={categoryType}
        />

        {/* Edit Effect */}
        <ModalEffect
          {...props}
          toggle={toggleEffect}
          isOpen={modalEffect}
          data={dataEffects}
          activeCategory={activeCategory}
          interventionId={interventionId}
          categoryType={categoryType}
        />

        {/* Delete Effect */}
        <ModalDeleteEffects
          {...props}
          interventionId={interventionId}
          toggle={toggleDelEffects}
          isOpen={modalDelEffect}
          activeCategory={activeCategory}
          effectId={effectId}
          timePointId={TimepointId}
          categoryType={categoryType}
        />
      </div>
    </div>
  );
}

export default InterventionsMain;