import React from "react";

//components
import ItemRow from './ItemRow';

function TableVitalSignsLast(props) {
  //props
  const Header = [
    { name: "Item", className: "text-center"},
    { name: "Initial Value", className: "text-center"},
    { name: "Change At This Time Point", className: "text-center", styles: { 'width': '25%' } },
    { name: "", className: "text-center", styles: { 'width': '25%' } },
    { name: "", className: "" }
  ];

  return (
    <div className="table-responsive custom-table-corner">
      <table className="table table-condensed">
        <thead>
          <tr>
            {Header && Header.map((item, index) => (
              <th key={index} style={item.styles} className={item.className}>
                {item.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <ItemRow {...props} />
        </tbody>
      </table>
    </div>
  );
}

export default TableVitalSignsLast;