import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

//components
import { ReferenceMediaWrap, OrgReferenceMediaWrap } from "@components/teachV2/ReferenceMedia";
import { getReferenceMedia } from "@actions";
import { teachV2Constants } from "@constants";

const ReferenceMediaPage = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const [listValue, setListValue] = useState([]);
  const [listType, setListType] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();
  const { dataFilter: documents } = useSelector((state) => state.getReferenceMedia) || {};

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  
  const tabsOption = [
    { id: 1, key: "my-media", label: "My Media" },
    { id: 2, key: "org-media", label: "Organization Media" },
  ] 

  const loadDataReferenceBook = () => {
    dispatch(getReferenceMedia())
  }

  useEffect(() => {
    loadDataReferenceBook()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: teachV2Constants.FILTER_REFERENCE_MEDIA_SUCCESS, payload: documents, params: {
      listValue,
      listType
    }});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listValue, listType])

  return (
    <div className="wrapper -ReferenceLiblaryPage">
      <div className='reference-wrap__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <ReferenceMediaWrap 
            loadDataReferenceBook={loadDataReferenceBook}
            listValue={listValue}
            setListValue={setListValue}
            listType={listType}
            setListType={setListType}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
        {activeTab === 2 && (
          <OrgReferenceMediaWrap 
            loadDataReferenceBook={loadDataReferenceBook}
            listValue={listValue}
            setListValue={setListValue}
            listType={listType}
            setListType={setListType}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        )}
      </div>
    </div>
  )
}

export default ReferenceMediaPage