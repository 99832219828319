import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";

//Cropper
import Cropper from "react-easy-crop";

//reactstrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "@utils/cropimage";

//api
import { apiCaller } from "@utils";
import { teachConstants } from "@constants";

//redux
import { useDispatch } from 'react-redux';

function ModalUpload(props) {
    const { isOpen, toggle, name, srcImage } = props && props;

    //redux
    const dispatch = useDispatch();
    let { id } = useParams();

    //state
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [isLoading, setLoading] = useState(false);

    //Crop compeleted
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    //Submit Api
    const handleUploadAvatar = useCallback(async (formData) => {
        dispatch({ type: teachConstants.UPDATE_AVATAR_REQUEST, isLoading: true });
        //Call Api
        apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: teachConstants.UPDATE_AVATAR_SUCCESS, payload: data });
                setTimeout(() => {
                    setLoading(false);
                    toggle();
                }, 2000);
            } else {
                dispatch({ type: teachConstants.UPDATE_AVATAR_FAILURE, error: "error" });
                setTimeout(() => {
                    setLoading(false);
                    toggle();
                }, 2000);
            }
        });
    }, [id, dispatch, toggle]);

    //Save Crop
    const cropImageAndSave = useCallback(async () => {
        try {
            const rotation = 0;
            const blobData = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
            const formData = new FormData();
            formData.append('Action', 'Update');
            formData.append('Target', 'Avatar');
            setLoading(true);
            if (name === 'Preceptor') {
                formData.append('PreceptorAvatarFile', blobData);
                //Case - Upload Case Preceptor Avatar
                handleUploadAvatar(formData);
            } else {
                formData.append('PatientAvatarFile', blobData);
                //Case - Upload Case Patient Avatar
                handleUploadAvatar(formData);
            }
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, imageSrc, name, handleUploadAvatar]);

    //Read file upload
    const readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

    //on upload file
    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
        }
    };

    //Load
    useEffect(() => {
        if (isOpen) {
            setImageSrc(null);
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
            <ModalHeader toggle={toggle}>Change {name} Photo</ModalHeader>
            <ModalBody>
                {imageSrc ? (
                    <div className="cropImgCt">
                        <div className="crop-container">
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                cropShape="round"
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                                aspect={1 / 1}
                            />
                        </div>
                        <div className="controls">
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                                className="slider"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="text-center">
                        {srcImage &&
                            <img className="img-fluid mb-4" src={srcImage} alt={`avatar ${name}`} />
                        }
                        <CustomInput
                            className="text-left"
                            type="file"
                            name="imageFile"
                            id="imageFile"
                            onChange={onFileChange}
                            accept="image/*"
                        />
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="d-flex align-items-center"
                    onClick={cropImageAndSave}
                    disabled={!imageSrc || isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )} Save
                </Button>
                <Button
                    color="secondary"
                    disabled={isLoading}
                    onClick={toggle}
                >
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalUpload;