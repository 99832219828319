import React, { useState, useEffect } from "react";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//components
import ItemScoreIndependent from './ItemScoreIndependent.jsx';

import ChangeMode from "./ChangeMode";
import Thead from "./Thead";

//lodash
import _ from 'lodash';

function IndependentLabs(props) {
  const { data, toggleScore, toggle, toggleDel, isTime, handleChangeCheckbox } = props && props;

  //state
  const [stateData, setData] = useState(data?.IndependentLabs || []);

  useEffect(() => {
    setData(data?.IndependentLabs);
  }, [data]);

  //handleChange
  const handleChange = (e, index, IdItem) => {
    console.log(e, index, IdItem);
  };

  const handleSave = () => {
    console.log("handleSave");
  }

  const checkDataShow = (minValue, maxValue, valueUnit) => {
    const fullMinMax = (minValue !== 0 || maxValue !== 0) ? (minValue + " - " + maxValue + " " + valueUnit) : '';
    return fullMinMax;
  };
  
  return (
    <table className="table">
      <Thead {...props} />
      <tbody>
        <tr
          className="row_lab row_lab_panel"
          style={{ backgroundColor: "rgb(242, 242, 242)" }}
        >
          <td
            colSpan={isTime > 0 ? "6" : "7"}
            className="text-left font-weight-500 align-middle"
          >
            <span className="m--font-danger lab_name lab_panel_name">
              Independent Labs
            </span>
          </td>
        </tr>
        {stateData?.map((value, index) => (
          <tr className="row_lab row_lab_item" key={index}>
            <td className="text-left pl-4">
              <span className="lab_name lab_item_name">{value.Name}</span>
            </td>
            <td className="text-center">{value.InitialValue}</td>
            {isTime === 0 && (
              <React.Fragment>
                <td className="text-center align-middle">{value.Unit}</td>
                <td className="text-center align-middle">
                  {checkDataShow(
                    value.NormalMin,
                    value.NormalMax,
                    value.Unit
                  )}
                </td>
              </React.Fragment>
            )}
            {isTime > 0 && (
              <ChangeMode
                data={{}}
                handleChange={handleChange}
                handleSave={handleSave}
              />
            )}
            <td className="text-center align-middle">
              <label className="m-checkbox m-checkbox--state-brand mb-0">
                <input
                  type="checkbox"
                  className="checkbox_lab_availability"
                  name="Available"
                  key={Math.random()}
                  data-checked={value.Available}
                  data-id={value.Id}
                  defaultChecked={value?.Available}
                  onClick={(e) => {
                    //Update Item
                    const indexItem = _.findIndex(stateData, function (o) { return o.Id === value.Id });
                    stateData[indexItem][`Available`] = !value.Available
                    handleChangeCheckbox(e, value, indexItem, "item");
                  }}
                />
                <span />
              </label>
            </td>
            <td className="text-center align-middle">
              <ItemScoreIndependent
                {...props}
                listDomain={value?.DomainScores || []}
                itemRow={value || {}}
                toggleScore={toggleScore}
              />
            </td>
            <td className="text-right align-middle">
              {isTime > 0 && (
                <React.Fragment>
                  <button
                    className="btn btn-sm btn-info button_edit_item"
                  // onClick={() => handleSave(value, j)}
                  >
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                  </button>
                  &nbsp;
                </React.Fragment>
              )}
              <ButtonEdit handleEdit={() => toggle(value, value.Id, "edit-lab-item")} />
              <ButtonDelete handleDelete={(e) => toggleDel(value, "delete-item")} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default IndependentLabs;