import React from 'react';
import styled from 'styled-components';
import { Icon } from '@components/common';
import File from '@images/teachV2/file.svg';
import Trash from '@images/teachV2/trash.svg';
import ClipLoader from "react-spinners/ClipLoader";

const DisplayContainer = styled.div`
  min-height: 120px;
  padding: 16px;
  border-radius: 12px;
  background: #FFF;
  display: flex;
  align-items: start;
  gap: 8px;
  border: 1px solid #EAECF0;
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 8px;

  h4 {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
`;

const FileTrash = styled.div`
  cursor: pointer;

  &:hover {
    svg {
      path {
        stroke: red;
      }
    }
  }
`;

const StyledParagraph = styled.p`
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const Button = styled.button`
  border-radius: 8px;
  border: 1px solid ${({ primary }) => (primary ? '#0089C2' : '#D0D5DD')};
  background: ${({ primary }) => (primary ? '#0089C2' : '#FFF')};
  color: ${({ primary }) => (primary ? 'white' : '#344054')};
  padding: 10px 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background: ${({ primary }) => (primary ? '#007bb5' : '#F8F9FA')};
  }
`;

const FileDisplay = ({ dataFileImported, setDataFileImported, loading, handleConfirm, showPara=true, showSubmit=true }) => {
  const isPrimary = !loading && dataFileImported;

  return (
    <>
      {showPara && (
        <StyledParagraph>
          Your document has been successfully uploaded and is now being vectorized. This may take up to 1 hour. Click 'Confirm' to continue. You can then close the browser and return later. Check the 'Status' column in the Document List to see if it's ready.
        </StyledParagraph>
      )}
      <DisplayContainer>
        <FileInfo>
          <Icon src={File} />
          <div className='progress-loading'>
            <h4>{dataFileImported?.name}</h4>
            <p>{(dataFileImported?.size / (1024 * 1024)).toFixed(2)}MB</p>
          </div>
        </FileInfo>
        {loading ? (
          <div>
            <ClipLoader color="#F79009" size={28} />
          </div>
        ) : (
          <FileTrash onClick={() => setDataFileImported(null)}>
            <Icon src={Trash} />
          </FileTrash>
        )}
      </DisplayContainer>
      {showSubmit && (
        <div className="d-flex justify-content-end">
          <Button primary={isPrimary} disabled={loading} onClick={handleConfirm}>
            Confirm
          </Button>
        </div>
      )}
    </>
  );
};

export default FileDisplay;