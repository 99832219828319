import React from 'react';

//components
import { CheckBoxAvailability } from '@components/elements';

function CellLastTimePoint(props) {
    return (
        <CheckBoxAvailability {...props} typeFeat="labs" />
    );
};

export default CellLastTimePoint;