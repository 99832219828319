import React, { useRef, useState, useCallback, useEffect } from 'react';
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//reactstrap
import { FormGroup, Label } from "reactstrap";

//router
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";

//lodash
import { debounce } from 'lodash';

//redux
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";

function TemplateTab(props) {
    const editor = useRef(null);
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { timePointId, timepointData } = props && props;
    const timepointDataDetail = timepointData?.data;
    const Documentations = timepointDataDetail?.Documentations;
    const DocumentationTemplate = Documentations?.DocumentationTemplate;

    //state
    const [stateData, setData] = useState(DocumentationTemplate || {});
    const [initialLoad, setInitialLoad] = useState(true); // State to track initial load

    useEffect(() => {
        if(initialLoad) {
            const timepointDataDetail = timepointData?.data;
            const Documentations = timepointDataDetail?.Documentations;
            const DocumentationTemplate = Documentations?.DocumentationTemplate;
            setData(DocumentationTemplate || {});
            setInitialLoad(false); // Set initialLoad to false after updating the input value
        }
    }, [timepointData, initialLoad])

    //handleChange
    const handleChange = (newContent, name) => {
        const updatedStateData = { ...stateData, [name]: newContent };
        setData(updatedStateData);
        debounced(updatedStateData);
    }

    //debounced
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(debounce((updatedStateData) => callApi(updatedStateData), 2000), []);

    //Send Api
    const callApi = (newData) => {
        const params = {
            "Action": "Update",
            "Target": "TimePoint",
            "TimePointId": timePointId,
            "DocumentationTemplate": {
                "Type": "note",
                "Text": newData?.text,
                "Placeholder": newData?.placeholder,
                "DefaultContent": newData?.default_content,
                "ScoringInstructions": newData?.scoring_instructions,
                "FeedbackInstructions": newData?.feedback_instructions
            }
        }

        const isLoading = true;
        dispatch({ type: storyboardConstants.UPDATE_DOCUMENTATION_TEMPLATE_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.UPDATE_DOCUMENTATION_TEMPLATE_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.UPDATE_DOCUMENTATION_TEMPLATE_FAILURE, error: 'error' });
            }
        });
    }
    
    return (
        <React.Fragment>
            <FormGroup className="text">
                <Label className="d-block font-weight-500" for="text">
                    Text:
                </Label>
                <JoditEditor
                    ref={editor}
                    value={stateData?.text || ''}
                    config={Config.CONFIG_JODIT}
                    placeholder={''}
                    tabIndex={1}
                    name="text"
                    onChange={(newContent) => handleChange(newContent, 'text')}
                />
            </FormGroup>
            <FormGroup className="placeholder">
                <Label className="d-block font-weight-500" for="placeholder">
                    Placeholder:
                </Label>
                <TextareaAutosize
                    placeholder=""
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    value={stateData?.placeholder || ''}
                    name="placeholder"
                    onChange={(e) => handleChange(e.target.value, 'placeholder')}
                />
            </FormGroup>
            <FormGroup className="default_content">
                <Label className="d-block font-weight-500" for="default_content">
                    Default Content:
                </Label>
                <TextareaAutosize
                    placeholder=""
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    value={stateData?.default_content || ''}
                    name="default_content"
                    onChange={(e) => handleChange(e.target.value, 'default_content')}
                />
            </FormGroup>
            <FormGroup className="feedback_instructions">
                <Label className="d-block font-weight-500" for="feedback_instructions">
                    Feedback Instructions:
                </Label>
                <TextareaAutosize
                    placeholder=""
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    value={stateData?.feedback_instructions || ''}
                    name="feedback_instructions"
                    onChange={(e) => handleChange(e.target.value, 'feedback_instructions')}
                />
            </FormGroup>
            <FormGroup className="scoring_instructions">
                <Label className="d-block font-weight-500" for="scoring_instructions">
                    Scoring Instructions:
                </Label>
                <TextareaAutosize
                    placeholder=""
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    value={stateData?.scoring_instructions || ''}
                    name="scoring_instructions"
                    onChange={(e) => handleChange(e.target.value, 'scoring_instructions')}
                />
            </FormGroup>
        </React.Fragment>
    );
}

export default TemplateTab;