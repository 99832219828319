import { templatesConstants } from "@constants";
import { apiCaller } from "@utils";

//Load Template
export const actFetchTemplate = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'GET', null).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.TEMPLATE_LIST_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.TEMPLATE_LIST_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.TEMPLATE_LIST_FAILURE, error: error } }
}

//Load All Template
export const actFetchAllTemplates = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'GET', null).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.ALL_TEMPLATE_LIST_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.ALL_TEMPLATE_LIST_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.ALL_TEMPLATE_LIST_FAILURE, error: error } }
}

//Create Template
export const atcCreateTemplate = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.CREATE_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.CREATE_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.CREATE_TEMPLATE_FAILURE, error: error } }
}

//Update Template
export const atcUpdateTemplate = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.UPDATE_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.UPDATE_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.UPDATE_TEMPLATE_FAILURE, error: error } }
}

//Delete Template
export const atcDeleteTemplate = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.DELETE_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.DELETE_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.DELETE_TEMPLATE_FAILURE, error: error } }
}

//Update Hight Light
export const atcHightLightTemplate = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.UPDATE_TEMPLATE_HIGHT_LIGHT_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.UPDATE_TEMPLATE_HIGHT_LIGHT_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.UPDATE_TEMPLATE_HIGHT_LIGHT_FAILURE, error: error } }
}

//Load Template Collection
export const loadTemplateByCollectionId = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.LOAD_TEMPLATE_BY_COLLECTION_ID_FAILURE, error: error } }
}

//Create Template Collection
export const createTemplateCollection = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.CREATE_TEMPLATE_COLLECTION_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.CREATE_TEMPLATE_COLLECTION_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.CREATE_TEMPLATE_COLLECTION_FAILURE, error: error } }
}

//Add Template to Collection
export const addTemplatetoCollection = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));

                //Load api
                if (params?.TemplateCollectionId) {
                    const paramsLoad = {
                        "Action": "LoadTemplateCollection",
                        "TemplateCollectionId": params?.TemplateCollectionId
                    }
                    dispatch(loadTemplateByCollectionId(paramsLoad));
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.ADD_TEMPLATE_TO_COLLECTION_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.ADD_TEMPLATE_TO_COLLECTION_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.ADD_TEMPLATE_TO_COLLECTION_FAILURE, error: error } }
}

//Remove Template from Collection
export const actRemoveTemplateFromCollection = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));

                //Load api
                if (params?.TemplateCollectionId) {
                    const paramsLoad = {
                        "Action": "LoadTemplateCollection",
                        "TemplateCollectionId": params?.TemplateCollectionId
                    }
                    dispatch(loadTemplateByCollectionId(paramsLoad));
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.REMOVE_TEMPLATE_FROM_COLLECTION_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.REMOVE_TEMPLATE_FROM_COLLECTION_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.REMOVE_TEMPLATE_FROM_COLLECTION_FAILURE, error: error } }
}

//Delete Collection
export const atcDeleteCollectionId = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/templates/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));

                //Load api
                dispatch(actFetchTemplate())
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.DELETE_COLLECTION_ID_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.DELETE_COLLECTION_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.DELETE_COLLECTION_ID_FAILURE, error: error } }
}

//Load All Template
export const actFetchAllInterfaceTemplates = () => {
    const isLoading = true;
    const params = { "Action": "ListTemplates" }
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/interface_template/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_FAILURE, error: error } }
}

//Load Standard Interface Elements
export const actFetchStandardInterfaceElements = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/interface_template/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: templatesConstants.STANDARD_INTERFACE_ELEMENTS_REQUEST, isLoading } }
    function success(data) { return { type: templatesConstants.STANDARD_INTERFACE_ELEMENTS_SUCCESS, payload: data } }
    function failure(error) { return { type: templatesConstants.STANDARD_INTERFACE_ELEMENTS_FAILURE, error: error } }
}