import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//boostrap
import { Container } from "reactstrap";

//utils
import { LoaderPage } from "@utils";

//call Action
import {
  actFetchTimeline,
  actFetchLoadTimePoint,
  actLoadFeedbackTemplate,
} from "@actions";

// constants
import { storyboardConstants } from "@constants";

//call Api
import { apiCaller } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//Modal
import { ModalDeleteTimepoint } from '@components/storyboard/Modal/Timepoint';

//components
import { TimeLine, EndingTimePointMain, PresentationTime } from "@components/storyboard";
import { HeadingBox } from "@components/common";
import _ from "lodash";

//Modal
import { ModalAddTimePoint, ModalTemplateEditor } from "@components/storyboard/Modal";

function StoryboardPage() {
  let { id } = useParams();
  const timepointData = useSelector((state) => state.timepoint || []);

  //state
  const [isLoadingTimePoint, setIsLoadingTimePoint] = useState(false);
  const [modal, setModal] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [modalDel, setModalDel] = useState(false);
  const [modalTempEditor, setModalTempEditor] = useState(false);
  const [onLoad, setOnload] = useState(true);
  const [timePointCurrent, setTimePointCurrent] = useState({});
  const [stateTimePoint, setStateTimePoint] = useState(timepointData || {}); //Load Time Point Dau tien tu store
  const [endingPage, setEndingPage] = useState(false);
  const dispatch = useDispatch();

  //get data store
  const caseData = useSelector((state) => state.teachCase || []);
  const timelineData = useSelector((state) => state.timeline || []);
  const feedbackTemplateData = useSelector(
    (state) =>
      (state.loadFeedbackTemplate && state.loadFeedbackTemplate.data) || {}
  );
  const timeLineStore = timelineData?.data;
  const responseCase = caseData?.data;

  //Load Time Point Dau tien tu store
  useEffect(() => {
    setStateTimePoint(timepointData || {});
  }, [timepointData])

  const handleRemove = (timePointId) => {
    const params = {
      Action: "Delete",
      Target: "TimePoint",
      TimePointId: timePointId,
    };
    handleApiDeleteTimepoint(id, params);
  };

  const handleApiDeleteTimepoint = (id, params) => {
    const isLoading = true;
    setIsLoadingTimePoint(true);
    dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_SUCCESS, payload: data });

        //close
        setIsLoadingTimePoint(false);
        setModalDel(false);
        loadTimepointFirst();
      } else {
        dispatch({ type: storyboardConstants.DELETE_TIMEPOINT_FAILURE, error: 'error' });

        //close
        setIsLoadingTimePoint(false);
        setModalDel(false);
      }
    });
  }

  //Load timepoint first
  const loadTimepointFirst = () => {
    if (timeLineStore?.length > 0) {
      const TimePointIdFirst = _.filter(timeLineStore, function (o) {
        return o?.Time === 0;
      });
      const currentTimepointId = TimePointIdFirst && TimePointIdFirst[0]?.Id;
      const params = {
        Action: "Load",
        Target: "TimePoint",
        TimePointId: String(currentTimepointId),
      };
      setTimePointCurrent(TimePointIdFirst && TimePointIdFirst[0]);
      dispatch(actFetchLoadTimePoint(id, params));
    }
  }

  useEffect(() => {
    //Load Timeline
    const fetchData = async () => {
      const paramsTimeline = {
        Action: "Load",
        Target: "Timeline",
      };
      dispatch(actFetchTimeline(id, paramsTimeline));
    };

    //Load Feedback Template
    const fetchDataFeedbackTemplate = async () => {
      const params = {
        Action: "Load",
        Target: "FeedbackTemplate",
      };
      dispatch(actLoadFeedbackTemplate(id, params));
    };

    fetchDataFeedbackTemplate();
    fetchData();
  }, [dispatch, id]);

  const handleLoadTimePoint = (value) => {
    const params = {
      Action: "Load",
      Target: "TimePoint",
      TimePointId: value?.Id,
    };
    setIsLoadingTimePoint(true);
    dispatch({ type: storyboardConstants.TIME_POINT_REQUEST, isLoading: true });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        //Update Timepoint Khi Click 
        setStateTimePoint(data);

        dispatch({ type: storyboardConstants.TIME_POINT_SUCCESS, payload: data });

        //close
        setIsLoadingTimePoint(false);
      } else {
        dispatch({ type: storyboardConstants.TIME_POINT_FAILURE, error: 'error' });

        //close
        setIsLoadingTimePoint(false);
      }
    });

    setTimePointCurrent(value);
    setEndingPage(false);
  };

  const handleShowComponentEnding = () => {
    setIsLoadingTimePoint(true);
    setTimeout(() => {
      setEndingPage(true);
      setTimePointCurrent({});
      setIsLoadingTimePoint(false);
    }, 2000);
  };

  // Call time point first
  useEffect(() => {
    // Filter Id Timepoint Tu API Timeline
    const fetchData = async () => {
      if (timeLineStore?.length > 0) {
        const TimePointIdFirst = _.filter(timeLineStore, function (o) {
          return o?.Time === 0;
        });
        const currentTimepointId = TimePointIdFirst && TimePointIdFirst[0]?.Id;
        const params = {
          Action: "Load",
          Target: "TimePoint",
          TimePointId: String(currentTimepointId),
        };
        setTimePointCurrent(TimePointIdFirst && TimePointIdFirst[0]);
        dispatch(actFetchLoadTimePoint(id, params));
        setOnload(false);
      }
    }
    if (onLoad) {
      fetchData();
    }
  }, [dispatch, id, timeLineStore, onLoad]);

  //Modal
  const toggle = (type) => {
    setTypeModal(type);
    setModal(!modal);
  };

  const toggleDel = () => {
    setModalDel(!modalDel);
  };

  const toggleTempEditor = () => {
    setModalTempEditor(!modalTempEditor);
  };
  const filterDisplayNameAfterUpdate = _.filter(timeLineStore, function (o) { return o?.Id === timePointCurrent?.Id; });
  const nameCurrent = filterDisplayNameAfterUpdate && filterDisplayNameAfterUpdate[0]?.DisplayName;
  return (
    <div className="wrapper -storyboardPage">
      {((timelineData?.isLoading && caseData?.isLoading && timepointData?.isLoading) || onLoad) && <LoaderPage />}
      <div className="storyboardCt">
        <Container>
          <HeadingBox title={responseCase?.Title} currentPage="Storyboard" />
          <PresentationTime
            data={timeLineStore || []}
            handleLoadTimePoint={handleLoadTimePoint}
            handleShowComponentEnding={handleShowComponentEnding}
            handleToggleAddTimepoint={() => toggle("add")}
            endingPage={endingPage}
            stateTimePointId={timePointCurrent?.Id}
          />
          <div className="panelBox">
            <div className="panelBox__head">
              <div className="panelHeadCt">
                <div className="panelHeadCt__caption">
                  <h3 className="m--font-brand --text-primary">
                    {endingPage ? "Ending Page" : nameCurrent}
                  </h3>
                </div>
                {timePointCurrent?.Id && (
                  <div className="panelHeadCt__tools ml-auto">
                    <ul className="navTools">
                      <li className="navTools__item">
                        <button
                          className="btn btn-sm --bg-primary"
                          onClick={() => toggleTempEditor()}
                        >
                          Open Prompt Template Editor
                        </button>
                      </li>
                      <li className="navTools__item">
                        <button
                          className="btn btn-info m-btn--pill"
                          onClick={() => toggle("edit")}
                        >
                          <i className="fa fa-pencil"></i>
                        </button>
                      </li>
                      {timepointData?.data?.Time > 0 && (
                        <li className="navTools__item">
                          <button
                            type="submit"
                            className="btn btn-danger m-btn--pill"
                            onClick={() => toggleDel()}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {/* /head */}
            <div className="panelBox__body position-relative">
              {stateTimePoint?.timepointId && !endingPage && (
                <TimeLine
                  timePointId={stateTimePoint?.timepointId}
                  caseData={responseCase}
                  timepointData={stateTimePoint} //gia tri khi goi api
                  isLoadingTimePoint={isLoadingTimePoint}
                  timePointCurrent={timePointCurrent} // Gia tri khi click tu view
                />
              )}
              {endingPage && (
                <EndingTimePointMain
                  data={feedbackTemplateData || {}}
                  caseData={caseData}
                  isLoadingTimePoint={isLoadingTimePoint}
                />
              )}
            </div>
          </div>
        </Container>
        <ModalAddTimePoint
          timepointData={stateTimePoint}
          toggle={toggle}
          isOpen={modal}
          actions={typeModal}
          timePointCurrent={timePointCurrent || {}}
          handleLoadTimePoint={handleLoadTimePoint}
        />
        {/* Modal Delete */}
        <ModalDeleteTimepoint
          isOpen={modalDel}
          toggle={toggleDel}
          hanldeRemove={() => handleRemove(timePointCurrent?.Id)}
          isLoading={isLoadingTimePoint}
        />
        {/* /Modal Delete */}
        {/* Modal Temp Editor */}
        <ModalTemplateEditor
          isOpen={modalTempEditor}
          toggle={toggleTempEditor}
          // hanldeRemove={() => handleRemove(timePointCurrent?.Id)}
        />
        {/* Modal Temp Editor */}
      </div>
    </div>
  );
}

export default StoryboardPage;