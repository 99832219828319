import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import FileUpload from './FileUpload';
import FileDisplay from './FileDisplay';
import FileError from './FileError';

const WrapFormUpload = styled.div`
  width: 100%;
  .wrap-option {
    .four-step {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
`;

const FormUpload = (props) => {
  const { dataFileImported, setDataFileImported, typeUpload, typeFile, errorFile, setValue, name } = props;
  const hiddenFileInputText = useRef(null);
  const [error, setError] = useState('');

  const handleClick = () => {
    hiddenFileInputText.current.click();
  };

  const handleDrag = e => {
    e.preventDefault();
  };

  const handleFileChange = file => {
    if (!typeFile?.includes(file.type)) {
      setError(errorFile);
      setDataFileImported(null);
    } else if (file.size > 10485760) {
      setError("File is too large");
      setDataFileImported(null);
    } else {
      setError('');
      setDataFileImported(file);
      name && setValue(name, file)
    }
  };

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    const file = type === "change" ? event.target.files[0] : event.dataTransfer.files[0];
    handleFileChange(file);
  };

  return (
    <WrapFormUpload>
      <div className='wrap-option four-step'>
        {!dataFileImported && !error && (
          <FileUpload
            handleChange={handleChange}
            handleClick={handleClick}
            handleDrag={handleDrag}
            hiddenFileInputText={hiddenFileInputText}
            type={typeUpload}
          />
        )}
        {dataFileImported && !error && (
          <FileDisplay
            dataFileImported={dataFileImported}
            setDataFileImported={setDataFileImported}
            showPara={false}
            showSubmit={false}
          />
        )}
        {error && (
          <FileError
            error={error}
            setError={setError}
            fileName={dataFileImported ? dataFileImported.name : 'Unknown file'}
          />
        )}
      </div>
    </WrapFormUpload>
  );
};

export default FormUpload;