import React from 'react';
import { useSelector } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { useScreenSize } from '@context/ScreenSizeContext';
import styled, { keyframes, css } from 'styled-components';


// Định nghĩa keyframes cho hiệu ứng pulse với box-shadow nhỏ hơn
const pulseEffect = keyframes`
  0% {
    box-shadow: 0 0 3px rgba(9, 146, 80, 0.3), 0 0 6px rgba(9, 146, 80, 0.2), 0 0 9px rgba(9, 146, 80, 0.1);
  }
  50% {
    box-shadow: 0 0 8px rgba(9, 146, 80, 0.6), 0 0 12px rgba(9, 146, 80, 0.4), 0 0 16px rgba(9, 146, 80, 0.2);
  }
  100% {
    box-shadow: 0 0 3px rgba(9, 146, 80, 0.3), 0 0 6px rgba(9, 146, 80, 0.2), 0 0 9px rgba(9, 146, 80, 0.1);
  }
`;

// Styled component cho tasks-nav__item với hiệu ứng pulse
const TaskNavItem = styled.div`
    pointer-events: ${({ isActive, isMobile }) => (isActive ? 'none' : isMobile ? 'auto' : 'auto')};
    ${({ isSingleOrTwoItems }) =>
        isSingleOrTwoItems
            ? `
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: start;
            `
            : `
                height: 78px;
                padding-top: 15px;
            `}
    ${({ shouldShowReady }) =>
        shouldShowReady &&
        css`
            animation: ${pulseEffect} 2s ease-in-out infinite; // Áp dụng hiệu ứng pulse khi shouldShowReady là true
        `}
`;

// Component con cho nút Ready
const ReadyButton = ({ shouldShow, isMobile, isSingleOrTwoItems }) => {
  if (!shouldShow) return null;

  let mobileReadyStyle = null;

  if (isMobile) {
    if (isSingleOrTwoItems) {
      mobileReadyStyle = { position: 'absolute', top: '-10px', right: '0' };
    } else {
      mobileReadyStyle = { position: 'absolute', top: '-10px', right: 'unset', transform: 'translateX(-50%)' };
    }
  }

  return (
    <span
      className={`tasks-nav__item--focus ${isMobile && isSingleOrTwoItems ? '-mobile' : ''}`}
      style={isMobile ? mobileReadyStyle : null}
    >
      <span className="pulse"></span>
      Ready
    </span>
  );
};

function ButtonTaskItem({
  handleClickItem,
  label,
  icon,
  attributeTask,
  attributeTour,
  isDisabled,
  isOpenPopper,
  contentPopper,
  nextTour,
  isErm,
  activeMenuTask,
  dataPopper,
  isViewMessage,
  isSingleOrTwoItems
}) {
  const missingTaskData = useSelector(state => state?.checkMissingTask?.data) || {};
  const isMissingTask = missingTaskData?.MissingTask;

  const { isMobile } = useScreenSize();

  const isActive = activeMenuTask === attributeTask;
  const lockState = !isMobile && isDisabled ? "lockItem" : "unlockItem";
  const popperContent = isErm ? contentPopper : dataPopper?.[0]?.Content;
  const popperNextTour = isErm ? nextTour : dataPopper?.[0]?.nextTour;

  const shouldShowReady = isMissingTask === attributeTask || isViewMessage;

  return (
    <TaskNavItem
      isActive={isActive}
      isMobile={isMobile}
      shouldShowReady={shouldShowReady}
      isSingleOrTwoItems={isSingleOrTwoItems}
      className={`tasks-nav__item position-relative ${isMobile ? 'isStyleMobile' : ''} ${isActive ? "active" : ""} ${lockState} ${!isSingleOrTwoItems ? 'multi-items' : ''}`}
      data-task={attributeTask}
      data-tour={attributeTour}
      onClick={handleClickItem}
    >
      <div className="tasks-nav__item--icon">
        <img src={icon} alt={label} />
      </div>
      <div className="tasks-nav__item--text">
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </div>

      {/* Hiển thị nút Ready */}
      <ReadyButton
        shouldShow={shouldShowReady}
        isMobile={isMobile}
        isSingleOrTwoItems={isSingleOrTwoItems}
      />

      <PopperItem
        attributeTask={attributeTask}
        attributeTour={attributeTour}
        isOpenPopper={isOpenPopper}
        styleInline={{ right: '180px', bottom: '0', position: 'absolute' }}
        position="right"
        content={popperContent}
        nextTour={popperNextTour}
      />
    </TaskNavItem>
  );
}

export default ButtonTaskItem;