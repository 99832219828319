import React, { useState } from 'react'
import { ModalListBook } from '../home/generate'
import FormCommon from './FormCommon'
import ClipLoader from "react-spinners/ClipLoader";
import result from '@images/teachV2/engage.svg';
import { HeadingTitle } from '@ui-partents/Heading'

export default function FormEngage(props) {
  const { bookId, setBookId, loadingResult, watch } = props
  const [showModalBooks, setShowModalBooks] = useState(false);
  
  return (
    <div className="formEngage">
      <HeadingTitle
        icon={result}
        title="Engagement Modules"
        isLight={false}
      />
      <div className="formEngage__form">
        <FormCommon {...props} setShowModalBooks={setShowModalBooks} />
        <button className={`${loadingResult || !watch("name") || !watch("instruction") || !watch("statement") ? "disabled" : ""} formEngage__form-button`} type="submit">
          Create Module
          {loadingResult && <ClipLoader color="#FFF" size={16}/>}
        </button>
      </div>
      <ModalListBook
        isOpen={showModalBooks}
        toggle={() => setShowModalBooks(!showModalBooks)}
        setBookId={setBookId}
        bookId={bookId}
        chooseOne={true}
      />
    </div>
  )
}
