import React from 'react';

//Avtar
import avatarUser from "@images/common/avatar-user-demo.png";

//lodash
import _ from "lodash";

function Framechat(props) {
    const { data } = props && props;

    // Loai bo role la System
    let filterRoleNotSysTem = _.filter(data, function (o) { return o.role !== 'system' });
    const listItems = filterRoleNotSysTem?.map((item, index) => {
        return (
            <div className="messengerCt__messages" key={index}>
                {item?.role === "user" && (
                    <div className="messengerCt__wrapper">
                        <div className="messengerCt__message messengerCt__message--out" style={{paddingLeft: "0"}}>
                            <div className="messengerCt__message-body">
                                <div className="messengerCt__message-content mr-0 --arrow-right">
                                    <div className="messengerCt__message-username">
                                        Prompt / Input:
                                    </div>
                                    <div className="messengerCt__message-text whiteSpace-break" dangerouslySetInnerHTML={{ __html: item?.content || '' }} />
                                </div>
                            </div>
                            <div className="messengerCt__message-pic">
                                <img src={avatarUser} alt="user" />
                            </div>
                        </div>
                    </div>
                )}
                {item?.role === "assistant" && (
                    <div className={`messengerCt__wrapper`}>
                        <div className="messengerCt__message messengerCt__message--in" style={{paddingLeft: "0"}}>
                            <div className="messengerCt__message-pic">
                                <img
                                    src={avatarUser}
                                    alt="assistant"
                                />
                            </div>
                            <div className="messengerCt__message-body">
                                <div className={`messengerCt__message-content --bg-blue --arrow-left`}>
                                    <div className="messengerCt__message-username">
                                        Assistant:
                                    </div>
                                    <div className="messengerCt__message-text">
                                        <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: item?.content || '' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    })

    return (
        <div className={`frameChat__fullBox`}>
            <div className="messengerCt">
                {listItems}
            </div>
        </div>
    );
};

export default Framechat;