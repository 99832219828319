import { paymentConstant } from "@constants";

export function LoadStripeCreditCard (state = {}, action) {
    switch (action.type) {
        case paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case paymentConstant.LAUNCH_STRIPE_SET_UP_CREDIT_CARD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function DataPaymentMethod (state = {}, action) {
    switch (action.type) {
        case paymentConstant.SET_DEFAULT_PAYMENT_METHOD_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case paymentConstant.SET_DEFAULT_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case paymentConstant.SET_DEFAULT_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function dataAutomaticReNew (state = {}, action) {
    switch (action.type) {
        case paymentConstant.SET_AUTOMATIC_RENEW_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case paymentConstant.SET_AUTOMATIC_RENEW_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case paymentConstant.SET_AUTOMATIC_RENEW_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};