import React, { useState } from 'react';
import { Iconsax } from '@components-common';

const CustomCollapseSection = ({ title, children, borderHeader }) => {
  // State
  const [isOpen, setIsOpen] = useState(true);

  // Handle toggle collapse
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="form-details card-area bg-grey">
      <div
        className={`head-form ${borderHeader ? 'border-bottom' : ''} ${isOpen ? 'expanded' : 'collapsed'}`}
        style={{ cursor: 'pointer' }}
        onClick={toggleCollapse}
      >
        <h3 className="head-form__title">{title}</h3>
        <div className={`arrow-collapse ${isOpen ? 'expanded' : 'collapsed'}`}>
          <Iconsax iconName={`${isOpen ? "arrow-down" : "chevron-down"}`} fill="#70707B" size={20} />
        </div>
      </div>
      <div
        className={`inner-ct-collapse ${isOpen ? 'expanded' : 'collapsed'}`}
        style={{
          overflow: 'hidden',
          transition: 'height 0.3s ease-in-out',
          height: isOpen ? 'auto' : '0',
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default CustomCollapseSection;