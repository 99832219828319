import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ExamFindings, HumanModel, ButtonAddPoint } from "../../storyboard/PhysicalExam";

//Boostrap
import { Row, Col, Button, Alert } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcSwitchHumanModel } from "@actions";

//api
import {apiCaller} from "@utils";
import { humanConstants } from "@constants";

//components
import { ModalUploadHumanModel } from "../../storyboard/Modal";

function ExamPointContainer(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const caseData = props && props.caseData;
  const UseHumanModel = caseData && caseData.UseHumanModel;

  //state
  const [isChecked, setChecked] = useState(UseHumanModel || false);
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [loadImage, setLoadImage] = useState(false);

  useEffect(() => {
    setChecked(UseHumanModel || false);
  }, [caseData, UseHumanModel]);

  const handleChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);
    const params = {
      Action: "Update",
      Target: "HumanModel",
      UseHumanModel: checked,
    };

    //Case - Switch to Use Human Model
    dispatch(atcSwitchHumanModel(params, id));
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onSubmitUpload = (imageData) => {
    let formData = new FormData();
    formData.append("Action", "Update");
    formData.append("HumanModelFile", imageData);
    formData.append("UseHumanModel", true);
    formData.append("Target", "HumanModel");
    setLoading(true);
    
    //Call Api
    apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: humanConstants.UPLOAD_IMAGE_HUMAN_MODEL_SUCCESS,
          payload: data,
        });
        setTimeout(() => {
          setLoading(false);
          setModal(false);
          setLoadImage(true);
        }, 3000);
      } else {
        dispatch({
          type: humanConstants.UPLOAD_IMAGE_HUMAN_MODEL_FAILURE,
          error: "error",
        });
      }
    });
  };

  const imgHumanUrl = caseData && caseData.HumanModelUrl;
  const commentText =
    "Left click and hold to move the location of each point <br/> Right click to edit the exam findings at each point";
  return (
    <div className="examPointContainer">
      <div className="examPointContainer__head mb-4">
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <div className="d-flex align-items-center">
              <span className="innerCt__label mr-3">Use human model</span>
              <div className="switches">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleChange}
                  name="UsehumanModel"
                  id={1}
                />
                <label htmlFor={1}>
                  <span />
                </label>
              </div>
              {/* /switches */}
              {isChecked && (
                <Button
                  color="primary"
                  className="-btn-primary ml-auto"
                  onClick={toggle}
                >
                  <i className="fa fa-upload text-white" aria-hidden="true"></i>
                  <span className="text-white ml-2">Upload Image</span>
                </Button>
              )}
            </div>
          </Col>
          <Col md={6}>
            <ButtonAddPoint {...props}/>
          </Col>
        </Row>
      </div>
      {imgHumanUrl && (
        <Alert className="text-center mt-4" color="info">
          <p dangerouslySetInnerHTML={{ __html: commentText }}></p>
        </Alert>
      )}
      {isChecked && <HumanModel isLoadImage={loadImage} {...props} />}
      {isChecked === false && <ExamFindings {...props} />}
      <ModalUploadHumanModel
        isLoading={isLoading}
        isOpen={modal}
        toggle={toggle}
        onSubmitUpload={onSubmitUpload}
      />
    </div>
  );
}

export default ExamPointContainer;
