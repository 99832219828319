import React from "react";
import { RichTextCommon } from "@components/teachV2/storyboard";
// import { debounce } from "lodash";

// //redux
// import { useDispatch } from "react-redux";

// //react-router-dom
// import { useParams } from "react-router-dom";

// //constants
// import { storyboardConstants } from "@constants";

// //toast
// import { toast } from "react-toastify";

// //api
// import { apiCaller } from "@utils";

const InstructionsPNOrders = ({ activeViewClass, timepointData }) => {
  // let { id } = useParams();
  // const dispatch = useDispatch();
  // const [valueEditor, setValueEditor] = useState(
  //   timepointData?.DefaultNarrative
  // );

  //Load Value
  // useEffect(() => {
  //   setValueEditor(timepointData?.DefaultNarrative);
  // }, [timepointData]);

  //Update narrative
  // const handleApiContentNarrative = useCallback(
  //   (currentContent, currentTimepointId, valueNarrativeId) => {
  //     let params = {
  //       Action: "Update",
  //       Target: "TimePoint",
  //       TimePointId: currentTimepointId,
  //       Narrative: {
  //         Id: valueNarrativeId,
  //         Content: currentContent,
  //       },
  //     };

  //     //Call Api
  //     dispatch({
  //       type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_REQUEST,
  //       isLoading: true,
  //     });
  //     apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
  //       if (res?.status === 200) {
  //         dispatch({
  //           type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_SUCCESS,
  //           payload: params?.Narrative || {},
  //         });
  //         toast.success("The narrative has been updated", {
  //           position: "bottom-right",
  //           autoClose: 4000,
  //           hideProgressBar: false,
  //           closeOnClick: false,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       } else {
  //         dispatch({
  //           type: storyboardConstants.UPDATE_NARRATIVE_CONTENT_FAILURE,
  //           error: "Failed to update the narrative content",
  //         });
  //       }
  //     });
  //   },
  //   [dispatch, id]
  // );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debounced = useCallback(
  //   debounce(
  //     (currentContent, currentTimepointId, valueNarrativeId) =>
  //       handleApiContentNarrative(
  //         currentContent,
  //         currentTimepointId,
  //         valueNarrativeId
  //       ),
  //     500
  //   ),
  //   []
  // );

  // debounce content
  // const handleUpdateEditor = (currentContent, currentTask) => {
  //   const currentTimepointId = timepointData?.Id;
  //   const valueNarrativeId = timepointData?.DefaultNarrative?.Id ?? "";
  //   if (currentTask === "history") {
  //     debounced(currentContent, currentTimepointId, valueNarrativeId);
  //     // Update valueEditor to synchronize with DefaultNarrative
  //     setValueEditor({
  //       ...timepointData?.DefaultNarrative,
  //       Content: currentContent,
  //     });
  //   }
  // };

  return (
    <div className="content-narrative">
      <RichTextCommon
        // handleUpdateEditor={(content) =>
        //   handleUpdateEditor(content, activeViewClass)
        // }
        task={activeViewClass}
        defaultValue={timepointData?.DefaultNarrative?.Content || ""}
        placeholder="Enter the instructions for the learner here..."
      />
    </div>
  );
};

export default InstructionsPNOrders;
