import React from 'react';

//reactstrap
import { Alert } from "reactstrap";

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//lodash
import _ from "lodash";

//components
import {
    UserMessage,
    PatientMessage,
    ChatMaxQuestionsAnswer
} from '../../Chat/ChatList';

import { LoadingMessage } from '../FreeText';

function FrameChat(props) {
    const { dataChatRecords, messagesEndRef, responseChatData, isLoading } = props && props;
    const ChatMaxQuestionsAllowed = responseChatData?.ChatMaxQuestionsAllowed;
    const messageBytotal = "You have reached the max number of questions allowed. Click on a task button in the panel on the right to continue.";
    const totalPatient = _.filter(dataChatRecords, ['ChatStatus', 'patient']);
    return (
        <div className={`frameChat__right position-relative`}>
            <Alert className="m-alert--info text-left border-0 mt-5">
                You have asked {dataChatRecords?.length || '0'} out of {ChatMaxQuestionsAllowed} questions allowed
            </Alert>
            <Scrollbar style={{ height: 400, overflowY: 'auto' }} ref={messagesEndRef}>
                <div className="messengerCt">
                    <div className="messengerCt__messages">
                        {dataChatRecords?.map((value, index) => (
                            <React.Fragment key={index}>
                                {(value?.UserMessage || value?.ChatStatus === "user_input") && (
                                    <UserMessage {...props} value={value} />
                                )}

                                {(value?.ChatStatus === "patient" && value?.PatientResponse) ? (
                                    <PatientMessage
                                        {...props}
                                        index={index}
                                        value={value}
                                    />
                                ) : dataChatRecords?.length - 1 === index && isLoading && <LoadingMessage {...props} />}
                            </React.Fragment>
                        ))}
                        {ChatMaxQuestionsAllowed === totalPatient?.length && (
                            <ChatMaxQuestionsAnswer
                                {...props}
                                content={messageBytotal}
                            />
                        )}
                    </div>
                </div>
            </Scrollbar>
        </div>
    );
};

export default FrameChat;