import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from "react-redux";
import { createEngage } from "@actions";
import { FormEngage } from '@components/teachV2/engage';
import { useHistory } from 'react-router-dom'

export default function EngagementPage() {
  const dispatch = useDispatch();
  const [loadingResult, setLoadingResult] = useState(false)
  const [bookId, setBookId] = useState(null)
  let history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm()

  const handleUpload = async (data, bookId) => {
    let params = {
      action_type: "create_topic_module",
      module_name: data?.name,
      bot_instruction: data?.instruction,
      opening_statement: data?.statement,
      LLM_model: data?.model,
      reference_id: bookId?.length ? bookId[0]?.document_id : null,
      interaction_language: data?.interactionLanguage,
      interaction_method: data?.interactionMethod,
    }

    // Bắt đầu tải kết quả
    setLoadingResult(true);

    dispatch(createEngage(params))
      .then((result) => {
        if (result?.data?.status === 200) {
          history.push(`/engage/${result?.dispatch?.payload[0]?.topic_id}`)
        }
        setLoadingResult(false);
      })
  };

  const onSubmit = (data) => {
    handleUpload(data, bookId);
  }

  console.log(loadingResult, "loadingResult....");
  return (
    <div className="wrapper -teachV2HomePage">
      <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
        <FormEngage
          register={register}
          setValue={setValue}
          handleSubmit={handleSubmit}
          control={control}
          watch={watch}
          loadingResult={loadingResult}
          setBookId={setBookId}
          bookId={bookId}
        />
      </form>
    </div>
  )
}