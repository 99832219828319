import React, { useState, useRef } from "react";
import {
    FormGroup,
    Label,
    Input,
    InputGroupAddon,
    InputGroup,
    InputGroupText,
    Button,
} from "reactstrap";

function ReferenceRange(props) {
    const { row, handleSaveReferenceRange, onUpdate, isLoading } = props;
    const refContainer = useRef(null);

    const [nameState, setNameState] = useState((row && row) || {});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNameState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSave = async () => {
        try {
            const responseData = await handleSaveReferenceRange(nameState);
            // Gọi hàm onUpdate để đóng celll
            if(responseData?.status === "success") {
                onUpdate(nameState);
            }
        } catch (error) {
            console.error("Error while saving:", error);
            // Xử lý lỗi nếu cần
        }
    };  
    
    console.log(nameState, "nameState....");
    return (
        <div ref={refContainer}>
            <FormGroup>
                <Label for="NormalMin">Min:</Label>
                <InputGroup>
                    <Input
                        type="number"
                        name="NormalMin"
                        value={nameState.NormalMin}
                        onChange={handleChange}
                        id="NormalMin"
                        placeholder=""
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText>{row.Unit}</InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <FormGroup>
                <Label for="NormalMax">Max:</Label>
                <InputGroup>
                    <Input
                        type="number"
                        name="NormalMax"
                        value={nameState.NormalMax}
                        onChange={handleChange}
                        id="NormalMax"
                        placeholder=""
                    />
                    <InputGroupAddon addonType="append">
                        <InputGroupText>{row.Unit}</InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
            </FormGroup>
            <Button
                className="btn px-3 d-flex justify-content-center align-items-center mx-auto"
                color="primary"
                onClick={handleSave}
            >
                Save
                {isLoading && <span className="spinner-border spinner-border-sm ml-2"></span>}
            </Button>
        </div >
    );
}

export default ReferenceRange;