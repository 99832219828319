import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//action
import { loadFeatureList } from "@actions";

//components
import { PricingMobile, PricingPc } from "../pricing";

const PricingModal = (props) => {
  const { isOpen, toggle } = props && props;
  const dispatch = useDispatch();

  //Load All Questions
  useEffect(() => {
    dispatch(loadFeatureList());
  }, [dispatch]);

  const dataStore = useSelector(state => (state.featureList && state.featureList.data) || {});
  const dataFeat = dataStore && dataStore.Features;

  return (
    <Modal className="-pricingPage" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Pricing</ModalHeader>
      <ModalBody>
        <PricingMobile data={(dataFeat && dataFeat) || []} />
        <PricingPc data={(dataFeat && dataFeat) || []}/>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PricingModal;
