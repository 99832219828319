import React, { useState, useEffect } from "react";

//boostrap
import { Container, Row, Col, FormGroup, Label, Button, Input, InputGroup, InputGroupText } from "reactstrap";

//react-select
import Select from 'react-select';

//call Api
import { apiCaller, formatReactSelect } from "@utils";

function checkAllPropertiesHaveValue(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (!obj[key]) {
                return false;
            }
        }
    }
    return true;
}

function FiterData(props) {
    //props
    const { accountData, handleSubmitFilter } = props && props;

    //state
    const [selectedOption, setSelectedOption] = useState([]);
    const [dataSelect, setDataSelect] = useState({});
    const [stateFilter, setStateFilter] = useState({
        "delta_time": 0,
        "model_version": 'gpt-3.5-turbo',
        "category": null
    });

    //load
    useEffect(() => {
        const fetchData = () => {
            const params = { "action_type": "get_categories" }
            apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    const dropdownSelect = data?.length > 0 ? formatReactSelect(data) : [];
                    setDataSelect(dropdownSelect || []);
                } else {
                    console.log('error');
                }
            });
        }
        //Load Api Model
        fetchData();
    }, [accountData]);

    //handleChange
    const handleChangeSelect2 = (select) => {
        setSelectedOption(select);
        setStateFilter((prevState) => ({ ...prevState, 'category': select?.value }));
    }

    // Handle Change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setStateFilter((prevState) => ({ ...prevState, [name]: value }));
    }

    // Handle Submit
    const handleSubmit = () => {
        handleSubmitFilter(stateFilter);
    }

    // Handle reset
    const handleResetFilter = () => {
        setStateFilter({
            "delta_time": 0,
            "model_version": null,
            "category": null
        });
    }

    const dataModelVerSion = [
        { name: 'gpt-3.5-turbo', value: 'gpt-3.5-turbo' },
        { name: 'gpt-4', value: 'gpt-4' }
    ]

    const itemModelVerSion = dataModelVerSion.map((items, idx) =>
        <option key={idx} value={items?.value}>{items?.name}</option>
    );

    const allPropertiesHaveValue = checkAllPropertiesHaveValue(stateFilter);
    return (
        <Container className="py-5">
            <Row className="align-items-center">
                <Col md={`3`}>
                    <FormGroup>
                        <Label className="font-weight-500" htmlFor="category">
                            Category
                        </Label>
                        <Select
                            options={dataSelect || []}
                            closeMenuOnSelect={true}
                            value={selectedOption}
                            onChange={value => handleChangeSelect2(value)}
                            autoComplete="off"
                            name="category"
                        />
                    </FormGroup>
                </Col>
                <Col md={`3`}>
                    <FormGroup>
                        <Label className="font-weight-500" htmlFor="delta_time">
                            Time
                        </Label>
                        <InputGroup>
                            <InputGroupText>
                                Last
                            </InputGroupText>
                            <Input
                                name="delta_time"
                                type="number"
                                placeholder="14"
                                autoComplete="off"
                                inputMode="numeric"
                                min="1"
                                onChange={handleChange}
                                value={stateFilter?.delta_time}
                                onKeyPress={(event) => {
                                    if (!/^\d*$/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                }}
                            />
                            <InputGroupText>
                                Day
                            </InputGroupText>
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col md={`3`}>
                    <FormGroup>
                        <Label className="font-weight-500" htmlFor="model_version">
                            Model Version
                        </Label>
                        <Input
                            id="model_version"
                            name="model_version"
                            type="select"
                            autoComplete="off"
                            onChange={handleChange}
                            value={stateFilter?.model_version}
                        >
                            {itemModelVerSion}
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={`3`} style={{ marginTop: "12px" }}>
                    <Button
                        className="--btn-primary"
                        onClick={handleSubmit}
                        disabled={!allPropertiesHaveValue}
                    >
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </Button>
                    <Button
                        className="btn-danger ml-2"
                        onClick={handleResetFilter}
                    >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

export default FiterData;