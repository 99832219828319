import React, { useState, useEffect } from "react";

function ProgressBar({ start, end }) {
  const [percentComplete, setPercentComplete] = useState(start);

  useEffect(() => {
    if (percentComplete < end) {
      const interval = setInterval(() => {
        setPercentComplete((prevPercent) => prevPercent + 1);
      }, 10); // Tùy chỉnh tốc độ animation tại đây

      return () => {
        clearInterval(interval);
      };
    }
  }, [percentComplete, end]);

  return (
    <div className="timePoint-progress-bar">
      <div
        className="bar"
        style={{ width: `${percentComplete}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;