import React, { useState, useEffect } from "react";

//components
import { ContactUs } from "../../components/common/ContactUs";
import { BottomContent, ContactForm, PlanList } from "../plan";

function PricingBox(props) {
  const { subscription } = props && props;

  const [activeIndex, setActiveIndex] = useState(
    (subscription && subscription) || "Pilot"
  );

  //Load Type
  useEffect(() => {
    setActiveIndex((subscription && subscription) || "Pilot");
  }, [subscription]);

  const [modal, setModal] = useState({
    modalContact: false,
  });

  const handleOnClick = (index) => {
    setActiveIndex(index); // remove the curly braces
  };

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  return (
    <React.Fragment>
      <PlanList
        handleOnClick={handleOnClick}
        activeIndex={activeIndex}
      />
      {activeIndex !== "Institution" && (
        <BottomContent {...props} activeIndex={activeIndex} />
      )}
      {activeIndex === "Institution" && <ContactForm />}
      <ContactUs
        {...props}
        isOpen={modal.modalContact}
        toggle={() => toggle("modalContact")}
      />
    </React.Fragment>
  );
}

export default PricingBox;
