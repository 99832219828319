import React, { useState, useEffect } from "react";

//reactstrap
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Input
} from "reactstrap";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//actions
import { atcAddLearnersToCohort } from "@actions";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { Link } from "react-router-dom";

// Import the pagination configuration
import { paginationOptions } from "@utils";

function ModalAddLearner(props) {
  const { cohortObject, data, toggle } = props && props;
  const dispatch = useDispatch();

  //state
  const [stateData, setStateData] = useState([]);
  const [dataInstitution, setDataInstitution] = useState((data && data) || []);

  //onLoad
  useEffect(() => {
    setDataInstitution((data && data) || []);
  }, [data]);


  const columns = [
    {
      dataField: "Id",
      text: "ID",
      hidden: true,
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "font-weight-400 text-nowrap",
    },
    {
      dataField: "Email",
      text: "Email",
      headerClasses: "font-weight-400 text-nowrap"
    }
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row, isSelect) => {
      let newArray = [...stateData, row.Id];
      if (stateData.includes(row.Id)) {
        newArray = newArray.filter((item) => item !== row.Id);
      }
      setStateData(newArray);
    },

    onSelectAll: (isSelect, rows, e) => {
      const newArr = [];
      if (isSelect) {
        rows.map((value) => newArr.push(value.Id));
        setStateData(newArr);
      } else {
        setStateData([]);
      }
    },
  };

  // Handle Add
  const handleAdd = async () => {
    const params = {
      "Action": "AddLearners",
      "CohortId": cohortObject?.CohortId,
      "LearnerIds": stateData
    };

    try {
      const response = await dispatch(atcAddLearnersToCohort(params));
      if (response?.status === 200) {
        console.log("Learners added successfully:", response);
        toggle();
      } else {
        console.error("Failed to add learners. Status:", response?.status);
      }
    } catch (error) {
      console.error("Error while adding learners:", error);
    } finally {
      console.log("Finished the add learners operation.");
    }
  };

  const totalSize = dataInstitution?.length || 0; // Dynamically calculate the total size

  // Use the paginationOptions function to get the configuration
  const paginationConfig = paginationOptions({ totalSize });
  const MySearch = (props) => {
    return (
      <Input
        className="form-control"
        type="text"
        placeholder="Email of the new learner"
        autoComplete="off"
        onChange={(e) => props.onSearch(e.target.value)}
      />
    );
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <Alert
        className="m-alert--default border-0 text-left"
        color="secondary"
      >
        Select from the list below the learners that you would like to add to this cohort.<br />
        In order to invite/enroll new learner(s) into your institution, please go to the <Link to={`/adminstrator-tool`}>User Management</Link>
      </Alert>
      <ToolkitProvider
        keyField="Id"
        data={dataInstitution || []}
        columns={columns}
        search
      >
        {(props) => {
          return (
            <>
              <Row className="justify-content-start mb-3 mt-3">
                <Col md={6} sm={6}>
                  <div className="formSearchInline">
                    <MySearch {...props.searchProps} />
                  </div>
                </Col>
              </Row>
              <hr />
              <BootstrapTable
                condensed
                striped
                wrapperClasses="table-responsive checkBoxCustom"
                bordered={false}
                {...props.baseProps}
                {...paginationTableProps}
                selectRow={selectRow}
              />
            </>
          )
        }}
      </ToolkitProvider>
      {/* /Table */}
      <div className="pagination-custom-case d-flex justify-content-center mt-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  return (
    <Modal
      className="pl-md-3"
      isOpen={props && props.isOpen}
      toggle={props && props.toggle}
      style={{ maxWidth: "800px" }}
      backdrop={`static`}
    >
      <ModalHeader toggle={props && props.toggle}>
        Add Learner(s) to <span className="--text-primary">{cohortObject?.Name}</span>
      </ModalHeader>
      <ModalBody>
        <PaginationProvider pagination={paginationFactory(paginationConfig)}>
          {contentTable}
        </PaginationProvider>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props && props.toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand d-flex align-items-center"
          onClick={handleAdd}
        >
          {/* {isLoading &&
          <span className="spinner-border spinner-border-sm mr-2"></span>
        } */}
          Add Learner(s) to Cohort
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddLearner;