import React, { useState, useEffect } from "react";
import { FormGroup, Label } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";

function TextAreaInstruction(props) {
  const { handleChangeInstructionOfGradingSchema, questionData } = props;

  const [stateInstruction, setStateInstruction] = useState({
    AI_Instructions: { Text: '' },
    Scoring_Instructions: { Text: '' }
  });

  useEffect(() => {
    if (questionData) {
      setStateInstruction({
        AI_Instructions: { Text: questionData?.AI_Instructions?.Text || '' },
        Scoring_Instructions: { Text: questionData?.Scoring_Instructions?.Text || '' }
      });
    }
  }, [questionData]);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setStateInstruction((prevState) => ({
      ...prevState,
      [name]: { Text: value }
    }));
    handleChangeInstructionOfGradingSchema(e);
  };

  return (
    <React.Fragment>
      <FormGroup>
        <Label className="font-weight-500" for="AI_Instructions">Feedback Instruction:</Label>
        <TextareaAutosize
          name="AI_Instructions"
          placeholder="Enter your Feedback Instruction..."
          className="w-100 form-control py-3"
          maxRows="10"
          minRows={7}
          onChange={handleTextChange}
          value={stateInstruction.AI_Instructions.Text}
        />
      </FormGroup>
      <FormGroup className="mt-2">
        <Label className="font-weight-500" for="Scoring_Instructions">Scoring Instruction:</Label>
        <TextareaAutosize
          name="Scoring_Instructions"
          placeholder="Enter your Scoring Instruction..."
          className="w-100 form-control"
          maxRows="10"
          minRows={5}
          onChange={handleTextChange}
          value={stateInstruction.Scoring_Instructions.Text}
        />
      </FormGroup>
    </React.Fragment>
  );
}

export default TextAreaInstruction;