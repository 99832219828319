export const problemsConstant = {
    LOAD_PROBLEMS_REQUEST: 'LOAD_PROBLEMS_REQUEST',
    LOAD_PROBLEMS_SUCCESS: 'LOAD_PROBLEMS_SUCCESS',
    LOAD_PROBLEMS_FAILURE: 'LOAD_PROBLEMS_FAILURE',

    CREATE_PROBLEM_ITEM_REQUEST: 'CREATE_PROBLEM_ITEM_REQUEST',
    CREATE_PROBLEM_ITEM_SUCCESS: 'CREATE_PROBLEM_ITEM_SUCCESS',
    CREATE_PROBLEM_ITEM_FAILURE: 'CREATE_PROBLEM_ITEM_FAILURE',

    UPDATE_PROBLEM_ITEM_REQUEST: 'UPDATE_PROBLEM_ITEM_REQUEST',
    UPDATE_PROBLEM_ITEM_SUCCESS: 'UPDATE_PROBLEM_ITEM_SUCCESS',
    UPDATE_PROBLEM_ITEM_FAILURE: 'UPDATE_PROBLEM_ITEM_FAILURE',

    DELETE_PROBLEM_ITEM_REQUEST: 'DELETE_PROBLEM_ITEM_REQUEST',
    DELETE_PROBLEM_ITEM_SUCCESS: 'DELETE_PROBLEM_ITEM_SUCCESS',
    DELETE_PROBLEM_ITEM_FAILURE: 'DELETE_PROBLEM_ITEM_FAILURE',

    CREATE_PROBLEM_OPTION_REQUEST: 'CREATE_PROBLEM_OPTION_REQUEST',
    CREATE_PROBLEM_OPTION_SUCCESS: 'CREATE_PROBLEM_OPTION_SUCCESS',
    CREATE_PROBLEM_OPTION_FAILURE: 'CREATE_PROBLEM_OPTION_FAILURE',

    UPDATE_PROBLEM_OPTION_REQUEST: 'UPDATE_PROBLEM_OPTION_REQUEST',
    UPDATE_PROBLEM_OPTION_SUCCESS: 'UPDATE_PROBLEM_OPTION_SUCCESS',
    UPDATE_PROBLEM_OPTION_FAILURE: 'UPDATE_PROBLEM_OPTION_FAILURE',

    DELETE_PROBLEM_OPTION_REQUEST: 'DELETE_PROBLEM_OPTION_REQUEST',
    DELETE_PROBLEM_OPTION_SUCCESS: 'DELETE_PROBLEM_OPTION_SUCCESS',
    DELETE_PROBLEM_OPTION_FAILURE: 'DELETE_PROBLEM_OPTION_FAILURE',

    CREATE_PROBLEM_CONCEPT_REQUEST: 'CREATE_PROBLEM_CONCEPT_REQUEST',
    CREATE_PROBLEM_CONCEPT_SUCCESS: 'CREATE_PROBLEM_CONCEPT_SUCCESS',
    CREATE_PROBLEM_CONCEPT_FAILURE: 'CREATE_PROBLEM_CONCEPT_FAILURE',

    UPDATE_PROBLEM_CONCEPT_REQUEST: 'UPDATE_PROBLEM_CONCEPT_REQUEST',
    UPDATE_PROBLEM_CONCEPT_SUCCESS: 'UPDATE_PROBLEM_CONCEPT_SUCCESS',
    UPDATE_PROBLEM_CONCEPT_FAILURE: 'UPDATE_PROBLEM_CONCEPT_FAILURE',

    DELETE_PROBLEM_CONCEPT_REQUEST: 'DELETE_PROBLEM_CONCEPT_REQUEST',
    DELETE_PROBLEM_CONCEPT_SUCCESS: 'DELETE_PROBLEM_CONCEPT_SUCCESS',
    DELETE_PROBLEM_CONCEPT_FAILURE: 'DELETE_PROBLEM_CONCEPT_FAILURE',
};