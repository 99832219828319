import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

//components
import { UserStoriesItem } from '../scrum';

//redux
import { useSelector } from "react-redux";

//utils
import { messageData, LoaderText } from "@utils";

//boostrap
import {
    Badge
} from "reactstrap";

//common
import { DataEmty } from "@components-common";

function UserStories(props) {
    const { data, isLoading } = props && props;
    const dataTable = data?.user_stories;

    const columns = [{
        dataField: 'story_gid',
        text: '',
        hidden: true
    }, {
        dataField: 'name',
        text: 'Name',
        headerClasses: "--text-primary font-weight-500",
        classes: 'font-weight-500'
    }, {
        dataField: 'scrum_point',
        text: 'Point',
        headerClasses: "--text-primary font-weight-500",
        style: {
            width: "70px"
        },
        classes: "align-middle text-center",
        formatter: (value, row) => {
            return (
                <Badge
                    color="info"
                    className="font-weight-400 text-1 badgeCustom">
                    {value}
                </Badge>
            );
        }
    }];

    const expandRow = {
        renderer: row => (
            <UserStoriesItem tasks={row?.tasks || []} {...props} />
        ),
        showExpandColumn: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
                return <b>-</b>;
            }
            return <b>+</b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <b>-</b>
                );
            }
            return (
                <b>...</b>
            );
        }
    };

    const ScrumByIdStore = useSelector((state) => state.dataScrumById || []);
    return (
        <div className="widgetBox -box-shadow mb-3">
            <div className="widgetBox__body position-relative">
                <BootstrapTable
                    keyField='story_gid'
                    wrapperClasses="table-responsive tableExpand"
                    data={(dataTable && dataTable) || []}
                    columns={columns}
                    expandRow={expandRow}
                    noDataIndication={() => (
                        <div className="py-5">
                            <DataEmty content={messageData?.default?.content} />
                        </div>
                    )}
                />
                {(isLoading || ScrumByIdStore?.isLoading) && (
                    <LoaderText />
                )}
            </div>
        </div>
    );
};

export default UserStories;