import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//call Action
import {
  actFetchCaseFeedbacks
} from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//router
import { useHistory } from "react-router-dom";

import { ContentProcess } from '@components/common';

function CompletedPage() {
    let { id } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
      const fetchData = async () => {
        const params = { "CaseId": id}
        dispatch(actFetchCaseFeedbacks(params));
      };
      fetchData();
    }, [dispatch, id]);
    
    const feedbacksData = useSelector((state) => state.dataFeedbacks || []);
    const resData = feedbacksData && feedbacksData.data;
    let icon="loader"
    if((resData && resData.status === "success") || (resData && resData.FeedbackMode === "Customized")) {
      icon="checked";
      setTimeout(() => {
        history.push(`/learn/feedback/${id}?status=completed`);
      }, 3000);
    }

    const data = {
      title: "You Have Completed The Case!",
      description: `We are processing your input. <br/> This might take up to 1 minute. Please keep this window open during this time`
    }
    return (
      <div className="wrapper -completedPage" >
        <ContentProcess data={data} icon={icon} />
      </div>
    );
}

export default CompletedPage;