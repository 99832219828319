import React from 'react';

//components
import {
    ListChildren,
    CellFirstTimePoint,
    CellLastTimePoint
} from '../Labs';

function PanelsGroup(props) {
    const {
        col,
        indexRow,
        lisTimePointFirst,
        typeGroup,
        listTimepointLast,
        dataChildren,
        handleEdit,
        handleShow
    } = props && props;
    return (
        <React.Fragment>
            <tr
                className={`row_lab row_lab_panel mt-3 font-weight-500`}
                style={{ backgroundColor: "rgb(242, 242, 242)" }}
                key={indexRow}
            >
                {/* Before */}
                {lisTimePointFirst?.map((row) => {
                    const item = row[typeGroup][indexRow];
                    const TimepointId = row?.TimePoint?.TimepointId;
                    return (
                        <CellFirstTimePoint
                            {...props}
                            item={item}
                            type="first"
                            TimepointId={TimepointId}
                        />
                    )
                })}

                {listTimepointLast?.map((row) => {
                    const item = row[typeGroup][indexRow];
                    const TimepointId = row?.TimePoint?.TimepointId;
                    return (
                        <CellLastTimePoint
                            {...props}
                            item={item}
                            type="last"
                            TimepointId={TimepointId}
                        />
                    )
                })}
            </tr>

            <ListChildren
                {...props}
                data={dataChildren || []}
                indexRow={indexRow}
                handleEdit={handleEdit}
                itemPanel={col}
                handleShowModalChangeValue={handleShow}
            />
        </React.Fragment>
    );
};

export default PanelsGroup;