import React, { useState, useEffect } from 'react';

//boostrap
import { Card, CardBody, Table, Button } from "reactstrap";

//useParams
import { useParams } from "react-router-dom";

//components
import { TableRows } from '@components/access/Teams/AddRemoveTable';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//apiCaller
import { apiCaller } from "@utils";

function RolesMain(props) {
    let { id } = useParams();

    //props
    const { caseDetails } = props && props;
    const TeamsInfo = caseDetails?.TeamsInfo;
    const Teams = TeamsInfo?.Teams;
    const Roles = TeamsInfo?.Roles;

    //state
    const [rowsData, setRowsData] = useState(Teams || []);
    const [roleData, setRoleData] = useState(Roles || []);
    const [isLoading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState({});
    const [modal, setModal] = useState({
        Delete: false
    });

    //Load Teams
    useEffect(() => {
        setRowsData(Teams || []);
    }, [Teams])

    //Load Roles
    useEffect(() => {
        setRoleData(Roles || []);
    }, [Roles])

    //Add Rows
    const addTableRows = () => {
        const params = {
            "Action": "Create",
            "Target": "Team"
        }
        handleApi('Create', params);
    }

    const hanldeModal = (type, data) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
        setCurrentData(data);
    }

    //Delete Rows
    const deleteTableRows = () => {
        const params = {
            "Action": "Delete",
            "Target": "Team",
            "TeamId": currentData?.TeamId
        }
        handleApi('Delete', params);
    }

    const handleApi = (type, params) => {
        setLoading(true);
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                const TeamsInfo = data?.TeamsInfo;
                const Teams = TeamsInfo?.Teams;
                const Roles = TeamsInfo?.Roles;
                setRowsData(Teams);
                setRoleData(Roles);
                setLoading(false);
                if (type === 'Delete') {
                    setModal((prevState) => ({ ...prevState, [type]: false }));
                }
            } else {
                console.log('error');
                setLoading(false);
            }
        });
    }

    const handleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
    }

    return (
        <Card className="border-0">
            <CardBody className="-box-shadow">
                <h2 className="heading-5 font-weight-500 --text-primary mt-0">Teams</h2>
                <Table responsive>
                    <thead>
                        <tr>
                            <th className='font-weight-500 align-middle' width={`20%`}>Team Name</th>
                            {roleData?.map((value, index) =>
                                <th className='font-weight-500 align-middle text-nowrap' width={`20%`} key={index}>{value?.TeamRoleName || ''}</th>
                            )}
                            <th width={50}></th>
                        </tr>
                    </thead>
                    <tbody className={`border-bottom`}>
                        <TableRows
                            rowsData={rowsData}
                            handleChange={handleChange}
                            hanldeModal={hanldeModal}
                            isLoading={isLoading}
                        />
                    </tbody>
                </Table>
                <Button
                    className="--btn-primary d-flex align-items-center mx-auto"
                    onClick={addTableRows}
                    disabled={isLoading}
                >
                    {isLoading ? <span className="spinner-border spinner-border-sm mr-2"></span> : <i className="fa fa-plus mr-2" aria-hidden="true" />}
                    Add Team
                </Button>
                <ModalDeleteCommon
                    title={`Are you sure you want to delete team ${currentData?.TeamName}`}
                    sub={`This action is not reversible.`}
                    isOpen={modal?.Delete}
                    toggle={() => hanldeModal('Delete', currentData)}
                    handleRemove={deleteTableRows}
                    isLoading={isLoading}
                />
            </CardBody>
        </Card>
    );
};

export default RolesMain;