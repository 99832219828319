import React, { useState } from 'react';

//react-router-dom
import { Link, useParams } from "react-router-dom";

//components
import { FavoriteQuestionBank, FlagsList } from '../Question';

function ActionsForAction(props) {
    let { id } = useParams();
    const { QuestionId, toggleEdit, toggleDel, toogleClone, item, isSchema } = props && props;

    //state
    const [modal, setModal] = useState({
        modalFlags: false
    });

    //Modal
    const toggleModal = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    return (
        <div className="actionsList ml-auto">
            <span
                onClick={toggleEdit}
                className="actionsList__item text-1 text-nowrap --edit"
            >
                <i
                    className="fa fa-pencil-square-o mr-2"
                    aria-hidden="true"
                ></i>
                Edit
            </span>
            <span
                className="actionsList__item text-1 text-nowrap --delete"
                onClick={toggleDel}
            >
                <i className="fa fa-trash-o mr-2" aria-hidden="true"></i>
                Delete
            </span>
            <span
                className="actionsList__item text-1 text-nowrap --clone"
                onClick={toogleClone}
            >
                <i className="fa fa-clone mr-2" aria-hidden="true"></i>
                Clone
            </span>
            <span
                className="actionsList__item text-1 text-nowrap --flags"
                onClick={() => toggleModal('modalFlags')}
            >
                <i className="fa fa-flag-o mr-2" aria-hidden="true"></i>
                Flags
            </span>
            {item?.QuestionType === "free_text" && (
                <Link
                    className="actionsList__item text-1 text-nowrap --text-primary"
                    to={`/teach/test/${id}?QuestionId=${QuestionId}&Schema=${isSchema}`}
                    target={`_blank`}
                >
                    <i className="fa fa-tachometer mr-2" aria-hidden="true"></i>
                    Test
                </Link>
            )}
            {isSchema &&
                <Link
                    className="actionsList__item text-1 text-nowrap --text-primary"
                    to={`/teach/schema/${id}?QuestionId=${QuestionId}`}
                    target={`_blank`}
                >
                    <i className="fa fa-sitemap mr-2" aria-hidden="true"></i>
                    Schema
                </Link>
            }
            <FavoriteQuestionBank
                {...props}
            />
            <FlagsList
                {...props}
                isOpen={modal.modalFlags}
                toggle={() => toggleModal('modalFlags')}
                handleClose={() => setModal((prevState) => ({ ...prevState, modalFlags: false }))}
            />
        </div>
    );
};

export default ActionsForAction;