export const THEME_REQUEST = "THEME_REQUEST";
export const THEME_SUCCESS= "THEME_SUCCESS";
export const THEME_FAILURE = "THEME_FAILURE";

export const themeConstants = {
    THEME_REQUEST: 'THEME_REQUEST',
    THEME_SUCCESS: 'THEME_SUCCESS',
    THEME_FAILURE: 'THEME_FAILURE',

    LOAD_THEME_STATE: 'LOAD_THEME_STATE'
};
