import React from 'react';
import JoditEditor from "jodit-react";

//reactBoostrap
import {
    FormGroup,
    Label
} from "reactstrap";

//Config
import * as Config from "@constants/Config";

function OverallExplanation(props) {
    const { value, onBlurChange, refDiv } = props && props;

    return (
        <FormGroup>
            <Label className="font-weight-500 mb-3" for="QuestionText">
                Overall Explanation:
            </Label>
            <JoditEditor
                ref={refDiv}
                value={value}
                config={Config.CONFIG_JODIT}
                placeholder="Enter your text here..."
                tabIndex={1} // tabIndex of textarea
                onBlur={onBlurChange}
            />
        </FormGroup>
    );
};

export default OverallExplanation;