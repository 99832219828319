import React from 'react';

function InstitutionalAccess(props) {
    const { status, item, hanldeSwitch } = props && props;
    const templateId = item?.TemplateId;
    return (
        <input
            className="favorite-institution mr-4"
            type="checkbox"
            defaultChecked={status}
            name={`InstitutionalAccess`}
            onChange={(e) =>
                hanldeSwitch(templateId, e)
            }
        />
    );
};

export default InstitutionalAccess;