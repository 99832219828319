import React, { useState, useEffect } from "react";

//reactstrap
import { FormGroup, Label, Input } from "reactstrap";

//react-router
import { useParams } from "react-router-dom";

//CreatableSelect
import CreatableSelect from "react-select/creatable";

//apiCaller
import { apiCaller } from "@utils";

//lodash
import _ from "lodash";

function OrderTypeCondition(props) {
    const { flowControlOfOrder, handleUpdateFlowControlOrderId, timePointId } = props && props;

    //State
    const [arrLabs, setArrLabs] = useState([]);
    const [arrStudies, setArrStudies] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);

    const [isStatus, setStatus] = useState(flowControlOfOrder?.Status);

    //Load State
    useEffect(() => {
        setStatus(flowControlOfOrder?.Status);
    }, [flowControlOfOrder]);

    //id Case
    let { id } = useParams();

    useEffect(() => {
        const fetchData = (typeLoad) => {
            const params = {
                "Action": "Load",
                "Target": typeLoad,
                "TimePointId": timePointId
            }

            //Call Api Load Text Item
            apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    if (typeLoad === "Labs") {
                        let mergeLabs = _.merge(data?.IndependentLabs, data?.Panels);
                        setArrLabs(mergeLabs);
                    };
                    if (typeLoad === "Studies") {
                        setArrStudies(data);
                    }
                } else {
                    console.log("error");
                }
            })
        }
        fetchData("Labs");
        fetchData("Studies");
    }, [id, timePointId])

    // convert data
    const convertDataOptions = () => {
        let mergeArray = [...arrLabs, ...arrStudies];
        //Sap Xep theo ABC
        mergeArray?.sort(function (a, b) {
            let nameA = a.Name.toUpperCase();
            let nameB = b.Name.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        let listOptions = [];
        mergeArray?.map(function (item) {
            const dataReturn = { value: item?.Id, label: `${item?.Name} (#${item?.Id})` };
            return listOptions.push(dataReturn);
        });
        return listOptions;
    };

    // Active OrderId
    useEffect(() => {
        setSelectedOption({
            value: flowControlOfOrder?.OrderId,
            label: flowControlOfOrder?.OrderName
        });
    }, [flowControlOfOrder]);

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        handleUpdateFlowControlOrderId("OrderId", data);
    };

    const handleChangeStatus = (e) => {
        const { name, value } = e.target;
        setStatus(value);
        handleUpdateFlowControlOrderId(name, value);
    }

    return (
        <React.Fragment>
            <div className="my-3">
                <CreatableSelect
                    closeMenuOnSelect={true}
                    options={convertDataOptions()}
                    placeholder={`Click to select chat order(s)`}
                    value={selectedOption}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                />
            </div>
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    (To NOT impose a condition, simply not select a question)
                </small>
            </p>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="ordered"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "ordered"}
                    />
                    is ordered
                </Label>
            </FormGroup>
            <FormGroup check className="mb-2">
                <Label check>
                    <Input
                        type="radio"
                        value="not_ordered"
                        name="Status"
                        onChange={handleChangeStatus}
                        checked={isStatus === "not_ordered"}
                    />
                    is NOT ordered
                </Label>
            </FormGroup>
        </React.Fragment>
    );
}

export default OrderTypeCondition;