import React, { useState, useEffect } from 'react';

//reactstrap
import { Table, Collapse } from "reactstrap";

//lodash
import _ from "lodash";

//components
import {
    TableHeader,
    RowAdd,
    ChatTopicChildren,
    ModalAddChatTopic,
    ModalEditChatTopic,
    InputSearch,
    RowSencond
} from '../Chat';

function ChatGroup(props) {
    //props
    const { rowData, isActive, ParentChatTopicIdActive } = props && props;
    const ChatTopics = rowData?.ChatTopics;

    //state
    const [itemEdit, setItem] = useState({});
    const [isOpen, setOpen] = useState(ParentChatTopicIdActive ? { [`isOpen_${ParentChatTopicIdActive}`]: true } : {});
    const [dataTopic, setDataTopic] = useState((ChatTopics && ChatTopics) || []);
    const [isOpenCollapse, setIsOpenCollapse] = useState({
        [rowData?.SectionName]: rowData?.SectionName === isActive ? true : false
    });
    const [modal, setModal] = useState({
        add: false,
        edit: false
    });

    //load data
    useEffect(() => {
        setDataTopic((ChatTopics && ChatTopics) || []);
    }, [ChatTopics])

    const toggle = (type, data) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
        setItem(data);
    }

    const toggleCollapse = (name) => {
        setIsOpenCollapse((prevState) => ({ ...prevState, [name]: !isOpenCollapse[name] }));
    }


    const handleCollapseChildren = (TopicId) => {
        setOpen((prevState) => ({ ...prevState, [TopicId]: !isOpen[TopicId] }));
    };

    const handleSearchKeyWord = (key) => {
        const lowercasedValue = key.toLowerCase().trim();
        if (dataTopic && key) {
            const filterData = _.filter((dataTopic), item => item.Name.toLowerCase().includes(lowercasedValue));
            setDataTopic(filterData);
        } else {
            setDataTopic(dataTopic);
        }
    }
    
    return (
        <React.Fragment>
            <div
                className="panel-heading font-weight-500 border p-3 d-flex justify-content-between"
                style={{ backgroundColor: "#f2f2f2", cursor: "pointer" }}
                onClick={() => toggleCollapse(rowData?.SectionName)}
            >
                {rowData?.SectionName}
                {dataTopic?.length > 0 &&
                    <i className={`fa ${isOpenCollapse ? "fa-chevron-up" : "fa-chevron-down"}`} aria-hidden="true"></i>
                }
            </div>
            <Collapse isOpen={isOpenCollapse[rowData?.SectionName]}>
                <InputSearch
                    handleSearchKeyWord={handleSearchKeyWord}
                />
                <div className="panel-body">
                    <Table className="mb-0" responsive>
                        <TableHeader data={dataTopic} />
                        {dataTopic?.map((item, indexItem) => {
                            const Children = item?.Children;
                            const Id = item?.Id;
                            const isOpenCollpase = `isOpen_${Id}`;
                            return (
                                <tbody className="border-0" key={indexItem}>
                                    <RowSencond
                                        Children={Children}
                                        handleCollapse={handleCollapseChildren}
                                        isOpenCollpase={isOpenCollpase}
                                        isOpen={isOpen}
                                        item={item}
                                        toggle={toggle}
                                    />
                                    <ChatTopicChildren
                                        {...props}
                                        data={Children}
                                        isOpen={isOpen[isOpenCollpase]}
                                        ParentChatTopicId={Id}
                                    />
                                </tbody>
                            )
                        })}
                    </Table>
                    <ModalAddChatTopic
                        {...props}
                        isOpen={modal?.add}
                        toggle={() => toggle('add', {})}
                    />
                    <ModalEditChatTopic
                        {...props}
                        isOpen={modal?.edit}
                        toggle={() => toggle('edit', {})}
                        item={itemEdit}
                    />
                    <RowAdd
                        name={rowData?.SectionName}
                        handleAddStandardChatTopic={() => toggle('add', {})}
                    />
                </div>
            </Collapse>
        </React.Fragment>
    );
};

export default ChatGroup;