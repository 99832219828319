import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";

//boostrap
import { Row, Col, Label, Input, Button, FormGroup } from "reactstrap";

function Filter(props) {
  // let history = useHistory();

  //props
  const {
    handleSubmitFilter,
    handleResetFilter,
    handleChangeFilter,
    stateFilter
  } = props && props;
  const [filter, setFilter] = useState(stateFilter);

  useEffect(() => {
    setFilter(stateFilter);
  }, [stateFilter]);

  const handleSubmit = () => {
    handleSubmitFilter();
  };

  return (
    <Row className="align-items-center">
      <Col md={`3`}>
        <FormGroup>
          <Label className="font-weight-500" htmlFor="record_id">
            Record ID
          </Label>
          <Input
            type="number"
            name="record_id"
            placeholder="Record ID"
            style={{ height: "38px" }}
            onChange={handleChangeFilter}
            autoComplete="off"
            inputMode="numeric"
            min="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={filter?.record_id || ""}
          />
        </FormGroup>
      </Col>
      <Col md={`3`}>
        <FormGroup>
          <Label className="font-weight-500" htmlFor="model_id">
            Model ID
          </Label>
          <Input
            type="number"
            name="model_id"
            placeholder="Model ID"
            style={{ height: "38px" }}
            onChange={handleChangeFilter}
            autoComplete="off"
            inputMode="numeric"
            min="0"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={filter?.model_id || ""}
          />
        </FormGroup>
      </Col>
      <Col md={`3`} style={{ marginTop: "12px" }}>
        <Button className="--btn-primary" onClick={handleSubmit}>
          <i className="fa fa-search" aria-hidden="true"></i>
        </Button>
        <Button className="btn-danger ml-2" onClick={handleResetFilter}>
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
      </Col>
    </Row>
  );
}

export default Filter;
