import React from 'react';

function TheadTable(props) {
    const { listTimepointLast } = props && props;
    return (
        <thead>
            <tr className="m--font-brand">
                <th className="text-left">Intervention</th>
                {listTimepointLast?.map((col, index) => {
                    const TimePoint = col?.TimePoint;
                    const DisplayName = TimePoint?.DisplayName;
                    return (
                        <th key={index} className="text-center">{DisplayName}</th>
                    )
                })}
                <th width={200} className="text-center">Settings</th>
            </tr>
        </thead>
    );
};

export default TheadTable;