import React from "react";

//Boostrap
import { 
    Button, 
    Modal,
    ModalBody, 
    ModalFooter
} from "reactstrap";

function ModalDeleteLearners (props) {
  const type = props && props.type;
  return (
    <Modal isOpen={props && props.isOpen} toggle={props && props.toggleDel} backdrop={`static`}>
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          {props && props.title}
        </h4>
        <p className="mt-4 text-center" dangerouslySetInnerHTML={{__html: props && props.desc }} />
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={props && props.handleRemove}
        >
          {type === "Learners" ? "Remove Learner" : "Remove Collaborator"}
        </Button>
        <Button className="btn btn-secondary" onClick={props && props.toggleDel}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteLearners;
