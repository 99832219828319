import React from 'react';

// reactBoostrap
import { Row, Col } from "reactstrap";

// Question Type
import { QuestionTypeOptions, QuestionPromptTemp, ImmediateFeedbackModeSwitch } from "../CreateQuestion";

// Schema
import { GSFreeText } from "../AnswerList/GradingSchema";

// Answer List
import { TableAnswerList } from "../AnswerList";

function AnswersTab(props) {
    const { QuestionType } = props && props;
    const colSize = QuestionType === "free_text" ? 9 : 12;

    return (
        <Row className="mb-3">
            <Col md={colSize}>
                <QuestionTypeOptions {...props} />
                <QuestionPromptTemp {...props} />
            </Col>
            {QuestionType === "free_text" && (
                <GSFreeText {...props} />
            )}
            <Col md={12}>
                <ImmediateFeedbackModeSwitch {...props} />
            </Col>
            <Col md={12}>
                {/* {QuestionType === "multiple_choice" && (
                    <ExplanationCheckbox {...props} />
                )} */}
                <TableAnswerList {...props} />
            </Col>
        </Row>
    );
};

export default AnswersTab;