import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";

//components
import { TabComponentBorder } from "@ui-partents/Tabs";
import { PointList } from "../../PhysicalExam";

//partents
import { FormModal } from '@ui-partents/Modal';

//reactstrap
import {
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

//actions
import {
  actLoadExamFindingById,
  updateTimePointOfExamFinding,
  createTimePointOfExamFinding,
} from "@actions";

// Media components
import { AudioFile, VideoLink, ImageFile, DropDownMedia, VideoFile } from "@components-common/media";

//Config
import * as Config from "@constants/Config";

//utils
import { apiCaller } from "@utils";

function ModalPhysicalExam(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, timepointData, PointId, actions, AttributeId } = props && props;
  const editor = useRef(null);
  let timePointId = timepointData?.Id;

  //state
  const [isResetData, setResetData] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [stateData, setStateData] = useState({});
  const [activeTab, setActiveTab] = useState(1);
  const [content, setContent] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [dataMediaLoad, setDataMediaLoad] = useState("");
  const [currentAttributeId, setCurrentAttributeId] = useState(AttributeId);

  //Load AttributeId
  useEffect(() => {
    setCurrentAttributeId(AttributeId);
  }, [AttributeId])

  //Load ExamFinding by AttributeId
  useEffect(() => {
    const fetchData = async () => {
      const paramsLoad = {
        Action: "Load",
        Target: "ExamFinding",
        TimePointId: timePointId,
        PointId: PointId,
        AttributeId: currentAttributeId,
      };
      dispatch(actLoadExamFindingById(id, paramsLoad));
    };

    if (actions === "edit") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAttributeId]);

  // Handle Submit
  const handleSaveButtonClick = () => {
    const currentType = stateData?.MediaType;
    let formData = new FormData();
    formData.append("Action", "Upload");
    setIsloading(true);

    if (isUpload) {
      if (currentType === "ImageFile") {
        formData.append("File", imageData);
        requestApiUpload(id, formData);
      } else if (currentType === "AudioFile") {
        formData.append("File", audioData);
        requestApiUpload(id, formData);
      } else {
        sendData();
      }
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaType: dataMediaLoad.MediaType,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
      sendData();
    }
  };

  // Handle API
  const sendData = (newUrl) => {
    if (actions === "edit") {
      const params = {
        Action: "Update",
        Target: "TimePoint",
        TimePointId: timePointId,
        PointId: PointId,
        ExamFindings: [stateData],
      };
      params.ExamFindings[0].InitialValue = content;

      if (stateData.MediaType !== "VideoLink" && isUpload) {
        params.ExamFindings[0].MediaUrl = newUrl;
      } else {
        params.ExamFindings[0].MediaUrl = stateData.MediaUrl;
      }
      dispatch(updateTimePointOfExamFinding(id, params));
    }

    if (actions === "add") {
      const paramsAdd = {
        Action: "Create",
        Target: "ExamFinding",
        TimePointId: timePointId,
        PointId: String(PointId),
        ExamFinding: {
          Name: "",
          InitialValue: "",
          MediaType: "",
          MediaUrl: null,
        },
      };

      paramsAdd.ExamFinding.Name = stateData.Name;
      paramsAdd.ExamFinding.InitialValue = content;
      paramsAdd.ExamFinding.MediaType = stateData.MediaType;

      if (stateData.MediaType !== "VideoLink" && isUpload) {
        paramsAdd.ExamFinding.MediaUrl = newUrl;
      } else {
        paramsAdd.ExamFinding.MediaUrl = stateData.MediaUrl;
      }

      dispatch(createTimePointOfExamFinding(id, paramsAdd));
    }
    props && props.toggle();
    setIsloading(false);
    setIsUpload(false);
  };

  // Handle Upload
  const requestApiUpload = (id, params) => {
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const newUrl = res?.data?.NewUrl;
      if (res?.status === 200) {
        setTimeout(() => {
          sendData(newUrl);
        }, 3000);
      }
    });
  };

  const loadExamFindingById = useSelector((state) => (state.loadExamFindingById && state.loadExamFindingById.data) || {});

  // Reset Value
  const resetDataModalAdd = () => {
    setStateData((prevState) => ({
      ...prevState,
      Name: "",
      InitialValue: "",
      MediaType: "",
      MediaUrl: "",
    }));
    setResetData(true);
  };

  // Load Media
  useEffect(() => {
    if (actions === "edit") {
      const params = loadExamFindingById && loadExamFindingById.ExamFinding;
      setStateData(params);
      setDataMediaLoad({
        MediaUrl: params && params.MediaUrl,
        MediaType: params && params.MediaType,
      });
      setResetData(false);
    }

    if (!isResetData && actions === "add") {
      resetDataModalAdd();
    }
  }, [loadExamFindingById, actions, isResetData]);

  // Handle Change Input
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  // Handle Change Type Media
  const handleType = (e) => {
    const value = e.target.value;
    setAudioData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    if (dataMediaLoad.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
    }
  };

  // Handle Upload Image
  const handleUploadImage = (data) => {
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  // Upload
  const onFileAudioUpload = (event) => {
    const fileAudio = event.target.files[0];
    const urlPreview = URL.createObjectURL(fileAudio);
    setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
    setAudioData(fileAudio);
    setIsUpload(true);
  };

  // Handle Click AttributeId
  const handleItemClick = (attributeId) => {
    setCurrentAttributeId(attributeId);
  };

  const isType = stateData?.MediaType;
  const urlValue = stateData?.MediaUrl;
  const tabs = [
    {
      id: 1,
      title: "Content",
      content: (
        <JoditEditor
          ref={editor}
          value={stateData?.InitialValue || ""}
          config={Config.CONFIG_JODIT}
          placeholder="Enter the instructions for the learner here..."
          tabIndex={1}
          onBlur={(newContent) => setContent(newContent)}
        />
      ),
    },
    {
      id: 2,
      title: "Media",
      content: (
        <div className="mediaContainer">
          <DropDownMedia onChange={handleType} value={isType} />
          {isType === "VideoLink" && (
            <VideoLink value={urlValue} onChange={handleInput} />
          )}
          {isType === "ImageFile" && (
            <ImageFile onChange={handleUploadImage} value={urlValue} />
          )}
          {isType === "AudioFile" && (
            <AudioFile onChange={onFileAudioUpload} value={urlValue} />
          )}
          {isType === "VideoFile" && (
            <VideoFile onChange={onFileAudioUpload} value={urlValue} />
          )}
          <hr />
        </div>
      ),
    },
  ];

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggle}
      title={`${actions === "edit" ? `Edit Exam Finding` : `Add Exam Finding`}`}
      onAction={() => handleSaveButtonClick()}
      actionText={actions === "edit" ? "Save" : "Add"}
      maxWidth="860px"
      isLoading={isLoading}
      deleteButtonConfig={{
        text: "Delete Exam Finding",
        onAction: () => props && props.toggleDel(stateData),
        isLoading: isLoading,
        disabled: isLoading,
        // Hiển thị nút xóa chỉ khi actions là "edit"
        show: actions === "edit"
      }}
    >
      <ModalBody>
        {actions === "edit" &&
          <PointList
            {...props}
            onItemClick={handleItemClick}
            currentAttributeId={currentAttributeId}
          />
        }
        <FormGroup>
          <Label className="formLabel" for="Name">Name</Label>
          <Input
            type="text"
            value={stateData?.Name || ""}
            onChange={handleInput}
            name="Name"
            autoComplete="off"
          />
        </FormGroup>
        <TabComponentBorder
          tabs={tabs}
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
      </ModalBody>
    </FormModal>
  );
}

export default ModalPhysicalExam;