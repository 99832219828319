import React from 'react';
// import { SquarePayment } from '@components/PaymentGateway/PaymentOverview';
import styled from 'styled-components';

//components
import CommonBoxWrapper from './CommonBoxWrapper';

// Styled component for h2
const StyledHeading = styled.h2`
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
`;

// Styled component for p
const StyledParagraph = styled.p`
    color: #475467;
    font-size: 14px;
    font-weight: 400;
`;

const PaymentMethods = () => {
    //setIsSquarePaymentVisible, handleSquarePaymentSuccess, handleSquarePaymentError, isSquarePaymentVisible
    return (
        <CommonBoxWrapper>
            <StyledHeading>Payment Methods</StyledHeading>
            <div id="paypal-button-container"></div>
            <StyledParagraph>
                By clicking Continue with PayPal, you agree to CognitusAI’s Terms of Use and Privacy Policy. Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy.
            </StyledParagraph>
            {/* <button onClick={() => setIsSquarePaymentVisible(true)}>Pay with Square</button> */}
            {/* {isSquarePaymentVisible && (
                <SquarePayment onPaymentSuccess={handleSquarePaymentSuccess} onPaymentError={handleSquarePaymentError} />
            )} */}
        </CommonBoxWrapper>
    );
};

export default PaymentMethods;