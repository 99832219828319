import React from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { PricingBox } from '@components/plan';

//common
import { ButtonBack } from '@components/common';

function PlanPage(props) {
  return (
    <div className="wrapper -planPage bg-white --border-3-top">
      <Container>
        <ButtonBack
          url={`/adminstrator-tool?active=billing`}
        />
        <div className="m-subheader py-0 mb-4">
          <div className="d-flex align-items-center justify-content-center">
            <h1 className="m-subheader__title --text-primary font-weight-400 text-center">
              Welcome to your Med2Lab Account!<br/>
              First, please pick a plan that would work best for you
            </h1>
          </div>
        </div>
        <div className="border-0 card">
          <div className="card-body p-0">
            <PricingBox {...props} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PlanPage;
