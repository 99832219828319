import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Row, Col, Button, FormGroup, Input } from "reactstrap";

import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//common
import { IconDisplay } from "@components/common/media";

//Actions
import {
  atcUpdateOneOnlyCommon,
  actUpdateStudyChange,
} from "@actions";

//components
import { ModalEditStudies, ModalMedia, ModalScore } from "../../storyboard/Modal";
import { BadgeScoreStudy } from '../../storyboard/Orders';

function StudyTableTP(props) {
  const { data } = props && props;
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  let { timePointId } = props && props;

  //state
  const [stateData, setData] = useState(data || []);
  const [actions, setActions] = useState("");
  const [rowForMedia, setRowForMedia] = useState({});
  const { SearchBar } = Search;

  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);
  const [itemEdit, setItemEdit] = useState({});

  //score
  const [modalScore, setModalCore] = useState(false);
  const [actionScore, setActionScore] = useState("");
  const [attributeType, setAttributeType] = useState("");
  const [itemScore, setItemScore] = useState("");
  const [itemCurrent, setItemCurrent] = useState({});

  //Modal Edit
  const toggle = (item, type) => {
    setItemEdit(item);
    setModal(!modal);
    setActions(type);
  };

  //Score
  const toggleScore = (data, type, AttributeType, itemScore) => {
    setActionScore(type);
    setModalCore(!modalScore);
    setItemCurrent(data);
    setAttributeType(AttributeType);
    setItemScore(itemScore);
  };

  //Modal Media
  const toggleMedia = (item) => {
    setModalMedia(!modalMedia);
    if (item) {
      setRowForMedia(item);
    }
  };

  useEffect(() => {
    setData(data || []);
  }, [data]);

  //Handle Save
  const handleSave = (item, index) => {
    const itemChange = item?.DefaultChange;
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      StudyChange: {
        Id: item.Id,
        ChangeMode: itemChange?.ChangeMode || '',
        ChangeValue: itemChange?.ChangeValue || '',
      },
    };
    dispatch(actUpdateStudyChange(id, params));
  };

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      hidden: true,
    },
    {
      dataField: "Name",
      text: "Study",
      editable: false,
      classes: "align-middle",
      headerClasses: "headTable text-left font-weight-normal",
      style: {
        width: "10%",
      },
    },
    {
      dataField: "InitialValue",
      text: "Initial Finding",
      classes: "align-middle",
      headerClasses: "headTable text-left font-weight-normal",
      style: {
        width: "15%",
      },
      formatter: (cell, row) => {
        return <div dangerouslySetInnerHTML={{ __html: row.InitialValue || '' }} />;
      }
    },
    {
      dataField: "TimePoint",
      text: "Change at This Time Point",
      classes: "align-middle",
      headerClasses: "headTable text-center font-weight-normal",
      formatter: (cell, row, rowIndex) => {
        let newState = [...stateData];
        const handleChange = (e, index) => {
          const { value, name } = e.target;
          row.DefaultChange[name] = value;
          newState[index].DefaultChange[name] = value;
          setData(newState);
        };
        const isMedia = row?.MediaType;
        return (
          <Row>
            <Col md={6}>
              <FormGroup>
                <Input
                  type="select"
                  defaultValue={row?.DefaultChange?.ChangeMode || ""}
                  onChange={(e) => handleChange(e, rowIndex)}
                  name="ChangeMode"
                >
                  <option value="none">No Change</option>
                  <option value="set">Set Value At ...</option>
                  <option value="increase">Increase Value By ...</option>
                  <option value="decrease">Decrease Value By ...</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <Input
                type="text"
                name="ChangeValue"
                defaultValue={row?.DefaultChange?.ChangeValue || ""}
                placeholder="Value"
                onChange={(e) => handleChange(e, rowIndex)}
                autoComplete="off"
              />
            </Col>
            <Col md={12} className="text-center">
              <span
                style={{ cursor: "pointer" }}
                onClick={() => toggleMedia(row)}
              >
                {isMedia === "None" ? <span className="--text-primary">Set Media</span> : <IconDisplay type={isMedia} />}
              </span>
            </Col>
          </Row>
        );
      },
    },
    {
      dataField: "Available",
      text: "Available",
      classes: "align-middle",
      headerClasses: "headTable text-center font-weight-normal",
      style: {
        width: "5%",
      },
      formatter: (cell, row) => {
        const handleChangeCheckbox = (e, idAvailable, typeItem) => {
          const { checked } = e.target;
          const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timePointId,
            StudyAvailability: {
              Id: idAvailable,
              Type: typeItem,
              Available: checked,
            },
          };
          dispatch(atcUpdateOneOnlyCommon(id, params, idAvailable));
        };

        return (
          <div className="d-flex justify-content-center align-items-center">
            <Input
              type="checkbox"
              defaultChecked={row.Available}
              data-checked={row.Available}
              data-id={row.Id}
              name="Available"
              onClick={(e) => handleChangeCheckbox(e, row.Id, "item")}
              className="m-0"
            />
          </div>
        );
      },
    },
    {
      dataField: "Score",
      text: "Score",
      editable: false,
      formatter: (cell, row) => {
        return (
          <BadgeScoreStudy
            {...props}
            listDomain={row?.DomainScores || []}
            itemRow={row || {}}
            toggleScore={toggleScore}
          />
        );
      },
      classes: "align-middle text-center",
      headerClasses: "headTable text-center font-weight-normal",
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-info button_edit_item"
              onClick={() => handleSave(row, rowIndex)}
            >
              <i className="fa fa-floppy-o" aria-hidden="true"></i>
            </button>
            &nbsp;
            <button className="btn btn-sm btn-danger button_delete_item">
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
      classes: "align-middle",
      headerClasses: "headTable text-center font-weight-normal",
    },
  ];

  return (
    <React.Fragment>
      <ToolkitProvider
        keyField="Id"
        data={stateData || []}
        columns={columns}
        search
        key={Math.random()}
      >
        {(toolkitprops) => (
          <>
            <Row className="justify-content-end mb-3">
              <Col md={6} sm={6}>
                <div className="boxSearch">
                  <SearchBar {...toolkitprops.searchProps} />
                  <Button className="--bg-primary btnSearch" color="primary">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </Button>
                </div>
              </Col>
              <Col md={6}>
                <button className="btn btn-brand d-block ml-auto">
                  <i className="fa fa-plus"></i>&nbsp;Add
                </button>
              </Col>
            </Row>
            {stateData && (
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive"
                bordered={false}
                key={Math.random()}
                {...toolkitprops.baseProps}
              />
            )}
          </>
        )}
      </ToolkitProvider>
      <ModalEditStudies
        {...props}
        actions={actions}
        data={itemEdit}
        isOpen={modal}
        toggle={toggle}
      />
      <ModalMedia
        {...props}
        isOpen={modalMedia}
        toggle={toggleMedia}
        rowForMedia={rowForMedia}
      />
      {/* Modal Score */}
      <ModalScore
        {...props}
        toggle={toggleScore}
        isOpen={modalScore}
        actions={actionScore}
        itemEdit={itemCurrent}
        attributeType={attributeType}
        itemScore={itemScore}
      />
    </React.Fragment>
  );
}

export default StudyTableTP;
