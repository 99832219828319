import React from 'react';

//boostrap
import {
    TabContent,
    TabPane
} from "reactstrap";

//common components
import { HeadTabs } from "../../storyboard/common";

//components
import { NotesTab, ProblemsTab, TemplateTab } from '../../storyboard/Documentation';

//RichTextCommon
import RichTextCommon from "../RichTextCommon";
import { DragQuestion } from "../Question";

function DocumentationMain(props) {
    //props
    const {
        value,
        activeTabCt,
        listTabs,
        handleUpdateEditor,
        valueEditor,
        timePointId,
        filterQuestionForTask
    } = props && props;
    
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='documentation'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.documentation?.map(
                    (item, index) =>
                        item.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item.id === "1" && (
                                    <NotesTab {...props} />
                                )}
                                {item.id === "2" && (
                                    <ProblemsTab />
                                )}
                                {item.id === "3" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={value?.ViewClass}
                                        defaultValue={valueEditor?.documentation}
                                        placeholder="Enter the instructions for the documentation here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item.id === "4" && (
                                    <TemplateTab {...props} />
                                )}
                                {item.id === "5" && (
                                    <DragQuestion
                                        {...props}
                                        task={value?.ViewClass}
                                        data={filterQuestionForTask(value?.ViewClass)}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
};

export default DocumentationMain;