import React, { useEffect, useState } from "react";
import { Input, FormGroup, Label } from "reactstrap";

const StatusOptions = ({ handleChange, isChecked }) => {
    const [status, setStatus] = useState(isChecked ? "active" : "inactive");

    useEffect(() => {
        setStatus(isChecked ? "active" : "inactive");
    }, [isChecked]);

    const handleStatusChange = (e) => {
        const newValue = e.target.value;
        setStatus(newValue);
        handleChange(newValue === "active");
    };

    return (
        <>
            <Label for="DefaultActiveStatus" className="labeForm">
                Default Status
            </Label>
            {["active", "inactive"].map((statusOption) => (
                <FormGroup check key={statusOption}>
                    <Label check>
                        <Input
                            name="DefaultActiveStatus"
                            type="radio"
                            value={statusOption}
                            className="mt-1"
                            onChange={handleStatusChange}
                            checked={status === statusOption}
                        />
                        {statusOption.charAt(0).toUpperCase() + statusOption.slice(1)}
                    </Label>
                </FormGroup>
            ))}
        </>
    );
};

export default StatusOptions;