import React, { useState, useEffect } from 'react';

//reactstrap
import { FormGroup, Label } from 'reactstrap';

//textarea
import TextareaAutosize from "react-textarea-autosize";

function TextAvailableHighLight(props) {
    const { QuestionHighlightContent, handleChanegeQuestionHighlightContent } = props && props;

    const [stateData, setStateData] = useState(QuestionHighlightContent || "");

    useEffect(() => {
        setStateData(QuestionHighlightContent || "");
    }, [QuestionHighlightContent])

    return (
        <FormGroup>
            <Label className="font-weight-500" for="QuestionHighlightContent">
                Text Available for HighLight:
            </Label>
            <TextareaAutosize
                name="QuestionHighlightContent"
                placeholder=""
                className="w-100 form-control"
                maxRows={12}
                minRows={7}
                onChange={handleChanegeQuestionHighlightContent}
                value={stateData}
            />
        </FormGroup>
    );
};

export default TextAvailableHighLight;