import React from "react";

function RequirementsContent(props) {
    const { rowData } = props && props;
    const checklist = rowData?.checklist;
    const listItems = checklist?.map((item, index) =>
        <div className="form-group" key={index}>
            <input type="checkbox" disabled id={item} />
            <label htmlFor={item}>{item}</label>
        </div>
    );

    return (
        <div className="checkBox-custom-style mt-4">
            <form>
                {listItems}
            </form>
        </div>
    );
}

export default RequirementsContent;