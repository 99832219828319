import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormGroup, Button, FormFeedback } from 'reactstrap';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { checkAuth, apiCaller } from "@utils";
import { actionLogin, actFetchThemeRequest } from '@actions';

const validationSchema = Yup.object().shape({
  participantID: Yup.string().required('This field is required'),
});

const initialValues = {
  participantID: '',
};

const RegisterForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const nextPage = dataAccount?.is_instructor === "Yes" ? '/teach/home' : "/learn/home";
    history.push(nextPage);
  };

  const handleFormSubmit = async (values) => {
    const email = `${values.participantID}@aspet.med2lab.com`;
    const formData = new FormData();
    formData.append('Email', email);
    formData.append('Institution', 'ASPET');

    setLoading(true);
    try {
      const response = await apiCaller(`/api/auth/demo/`, "POST", formData);
      const dataResponse = response?.data;
      if (dataResponse?.status === "OK") {
        const accountData = dataResponse?.account;
        localStorage.setItem("Account", JSON.stringify(accountData));
        dispatch(actionLogin(dataResponse));
        dispatch(actFetchThemeRequest());
        nextPage(dataResponse);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.error('API request failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginPartnerContainer">
      <style jsx>{`
          body {
            overflow: hidden;
          }
        `}</style>
      <div className="form-auth-panel" style={{ minHeight: '300px', maxWidth: '600px' }}>
        <div className="auth-description text-center my-4">
          <p className="text-desc-lg">Please Begin by Entering Your Participant ID</p>
        </div>
        <div className="auth-login-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <FormGroup>
                  <Field
                    type="text"
                    name="participantID"
                    id="participantID"
                    placeholder="Enter your participant ID (*)"
                    className={`form-control${errors.participantID && touched.participantID ? ' is-invalid' : ''}`}
                  />
                  {errors.participantID && touched.participantID && (
                    <FormFeedback>{errors.participantID}</FormFeedback>
                  )}
                </FormGroup>
                <div className="text-right">
                  <Button
                    className='w-100'
                    type="submit"
                    color='primary'
                    size='lg'
                    disabled={isLoading}
                  >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;