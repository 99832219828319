const listTabs = {
    exam: [
        { id: '1', name: 'Exam Findings', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true },
        { id: '3', name: 'Questions', icon: '', show: true }
    ],
    history: [
        { id: '1', name: 'Contents / Values', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true },
        { id: '3', name: 'Questions', icon: '', show: true }
    ],
    chat: [
        { id: '1', name: 'Chat Contents', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true },
        { id: '3', name: 'Questions', icon: '', show: true },
        { id: '4', name: 'Settings', icon: '', show: true },
        { id: '5', name: 'Generative AI', icon: '', show: true }
    ],
    vital: [
        { id: '1', name: 'Contents / Values', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true }
    ],
    io: [
        { id: '1', name: 'Contents / Values', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true }
    ],
    orders: [
        { id: '1', name: 'Labs', icon: '', show: true },
        { id: '2', name: 'Studies', icon: '', show: true },
        { id: '3', name: 'Medications', icon: '', show: true },
        { id: '4', name: 'Instructions', icon: '', show: true },
        { id: '5', name: 'Questions', icon: '', show: true }
    ],
    pn: [
        { id: '1', name: 'Settings', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true },
        { id: '3', name: 'View Mode', icon: '', show: true },
        { id: '4', name: 'Questions', icon: '', show: true }
    ],
    documentation: [
        { id: '1', name: 'Notes', icon: '', show: true },
        { id: '2', name: 'Problems', icon: '', show: true },
        { id: '3', name: 'Instructions', icon: '', show: true },
        { id: '4', name: 'Template', icon: '', show: true },
        { id: '5', name: 'Questions', icon: '', show: true }
    ],
    actions: [
        { id: '1', name: 'Actions', icon: '', show: true },
        { id: '2', name: 'Instructions', icon: '', show: true },
        { id: '3', name: 'Questions', icon: '', show: true }
    ],
    advance: [
        { id: '1', name: 'Instructions', icon: '', show: true },
        { id: '2', name: 'Questions', icon: '', show: true },
        { id: '3', name: 'Checkpoints', icon: '', show: true }
    ]
}

export default listTabs;