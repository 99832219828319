import React, { useState } from 'react'

//Common
import { ModalDeleteCommon } from "@ui-partents/Modal";
import { BtnIconDeleteV2, BtnIconEditV2, BtnAddRow  } from "@ui-partents/Button";
import { ModalFormAdd, ModalFormEditNote } from './index';

export default function ListNotes(props) {
  //state
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpen, setModalOpen] = useState({
    createNote: false,
    delNote: false,
    editNote: false,
  });

  const handleEditButtonClick = (item) => {
    toggleModal('editNote', true, item);
  };

  const handleDeleteButtonClick = (item) => {
    toggleModal('delNote', item);
  };

  // Handle Toogle Modal
  const toggleModal = (type, item = null) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
    if (type === 'delNote') {
      setSelectedItem(item);
    }
  };

  // Sample data
  const data = [
    {
      id: 1,
      title: 'CPMC Admission Note',
      note: 'Admission Note - 2024-04-09',
    },
    {
      id: 2,
      title: 'CPMC Admission Note',
      note: 'Admission Note - 2024-04-09',
    },
    {
      id: 3,
      title: 'CPMC Admission Note',
      note: 'Admission Note - 2024-04-09',
    },
    {
      id: 4,
      title: 'CPMC Admission Note',
      note: 'Admission Note - 2024-04-09',
    },
  ];

  return (
    <div className="mt-4">
      {data?.map(item => (
        <div className="item-note d-flex align-items-center justify-content-between">
          <div className="item-note__info d-flex flex-column">
            <h3>{item?.title}</h3>
            <p>{item?.note}</p>
          </div>
          <div className="item-note__action d-flex align-items-center justify-content-center">
            <BtnIconEditV2 handleEditButtonClick={() => handleEditButtonClick(item)} colorIcon={`#16B364`} />
            <BtnIconDeleteV2 handleDelete={handleDeleteButtonClick} />
          </div>
        </div>
      ))}
      <BtnAddRow
        handleClickAddRow={() => toggleModal("createNote", {})}
        textBtn="Add Notes"
      />
      <ModalFormAdd 
        isOpen={modalOpen.createNote}
        toggleModal={() => toggleModal("createNote", {})}
        title={"Add Note"}
        {...props}
      />
      <ModalFormEditNote
        isOpen={modalOpen.editNote}
        toggle={() => toggleModal("editNote", {})}
        title={"Edit Intervention"}
      />
      <ModalDeleteCommon
        isOpen={modalOpen.delNote}
        toggle={() => toggleModal("delNote", {})}
        title={`Delete Topic`}
        sub={`Are you certain that you would like to delete this item?All data related to this item will be removed. This action is not reversible.`}
        selectedItem={selectedItem}
      />
    </div>
  )
}
