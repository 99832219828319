import React from 'react';

//reactstrap
import { Row, Col, Button } from "reactstrap";

//redux
import { useSelector } from "react-redux";

//LoaderText
import { LoaderText } from "@utils";

//components
import { TemplateList } from '../templates';

function TemplateListRight(props) {
    const {
        templatesStore,
        searchResults,
        toggle,
        itemCollection,
    } = props && props;

    const listTemplateCollectionById = useSelector((state) => state.listTemplateCollectionById || {});
    return (
        <Row>
            <Col md={12} className="position-relative">
                {(templatesStore.isLoading || listTemplateCollectionById.isLoading) &&
                    <div style={{ height: '400px' }}><LoaderText /></div>
                }
                {(!templatesStore.isLoading || listTemplateCollectionById.isLoading) && (
                    <React.Fragment>
                        <div className="caseBottom">
                            {itemCollection?.TemplateCollectionId === 0 &&
                                <div className="d-flex justify-content-end">
                                    <Button
                                        className="--btn-primary mb-3 text-nowrap"
                                        onClick={() => toggle({}, "addTemplate")}
                                    >
                                        <i className="fa fa-plus mr-2" aria-hidden="true" />
                                        New Template
                                    </Button>
                                </div>
                            }
                            <TemplateList
                                {...props}
                                data={(searchResults && searchResults) || []}
                            />
                        </div>
                    </React.Fragment>
                )}
            </Col>
        </Row>
    );
};

export default TemplateListRight;