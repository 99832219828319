import React from 'react'

const DetailPagePptx = (props) => {
  const { tab, dataRef, index, handleSave } = props
  return (
    <div className="detail-pptx">
      <div 
        ref={dataRef}
        contentEditable
        onBlur={() => handleSave(dataRef, index)}
        dangerouslySetInnerHTML={{ __html: tab?.content || '' }} 
      />
    </div>
  )
}

export default DetailPagePptx
