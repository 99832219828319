import React from 'react';

//common
import { SwitchItem } from "@ui-partents/SwitchButton";

function ButtonSwitchInstantFeedback(props) {
    return (
        <SwitchItem
            {...props}
            label={`Enable Instant Feedback in Learner Interface`}
            name={`InstantFeedback`}
        />
    );
};

export default ButtonSwitchInstantFeedback;