import React, { useState, useEffect } from "react";
import { Button, FormGroup, Row, Col, Input } from "reactstrap";

//common
import { ModalDeleteCommon } from "@ui-partents/Modal";

//common
import { DataEmty } from "@components-common";

function GradingFields(props) {
  const [isChange, setChange] = useState(false);
  const [modal, setModal] = useState(false);
  const [indexActive, setIndexActive] = useState("");

  //props
  const { data, itemActive } = props && props;
  console.log(props, "test...");
  const { handleChangeGradingFields } = props && props;

  //Modal
  const toggle = () => {
    setModal(!modal);
  };

  //state
  const [inputList, setInputList] = useState([]);

  //Merge Data
  useEffect(() => {
    let newGradingFieldsArray = null;
    const filteredData = data.find((item) => item.QuestionId === itemActive?.QuestionId);
    if (filteredData) {
      newGradingFieldsArray = filteredData.GradingFields;
    }
    setInputList(newGradingFieldsArray || []);
  }, [data, itemActive])


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setChange(true);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    setIndexActive(index);
    setModal(true);
  };

  const handleRemove = () => {
    const list = [...inputList];
    list.splice(indexActive, 1);
    setInputList(list);
    setChange(true);
    setModal(false);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      { GradingFieldId: 0, Name: "", ValueType: "" },
    ]);
    setChange(true);
  };

  //pass state to parent component
  useEffect(() => {
    if (isChange) {
      handleChangeGradingFields(inputList);
      setChange(false);
    }
  }, [isChange, inputList, handleChangeGradingFields]);

  const nameCurrent = inputList[indexActive] && inputList[indexActive].Name;
  return (
    <React.Fragment>
      <div className="gradingFieldsCt">
        <div className="listInput">
          {inputList?.length > 0 && inputList?.map((x, i) => {
            return (
              <Row className="mb-2 mx-auto" key={i} form>
                <Col md={6}>
                  <FormGroup className="mb-2 mt-sm-2 mb-sm-0">
                    <Input
                      name="Name"
                      placeholder="Enter a name for the new garding field"
                      value={x.Name}
                      onChange={(e) => handleInputChange(e, i)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-2 mt-sm-2 mb-sm-0">
                    <Input
                      type="select"
                      name="ValueType"
                      defaultValue={x.ValueType}
                      onChange={(e) => handleInputChange(e, i)}
                      placeholder="Select a value type"
                    >
                      <option value="">Select a value type...</option>
                      <option value="numeric">Numeric</option>
                      <option value="text">Text</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2} className="text-sm-center">
                  <Button
                    className="btn btn-sm btn-danger mt-sm-2"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <i className="fa fa-times mr-2" aria-hidden="true"></i>
                    Remove
                  </Button>
                </Col>
              </Row>
            );
          })}
          {inputList && inputList.length === 0 && (
            <DataEmty content={`No fields, Click the <strong class="mx-2"><i class="fa fa-plus mr-1"></i> Add New Field</strong> button to create your first field`} />
          )}
        </div>
        <button
          type="submit"
          className="btn --btn-primary mx-auto d-block mt-4"
          onClick={handleAddClick}
          name="add"
        >
          <i className="fa fa-plus mr-2"></i>
          Add New Field
        </button>
      </div>
      {/* Modal delete */}
      <ModalDeleteCommon
        title={`Are you sure you want to remove ${nameCurrent}?`}
        sub={`Analyses related to this grading field will be permanently removed from this question.`}
        isOpen={modal}
        toggle={toggle}
        handleRemove={handleRemove}
      />
    </React.Fragment>
  );
}

export default GradingFields;