import React from 'react';

// Components
import { QuestionFeedbackMain, FeedbackV2Layout } from '../FeedbackV2';

//Loading
import 'react-loading-skeleton/dist/skeleton.css';

function FeedbackTabsContainer(props) {
    // props
    const { isLoading } = props;

    return (
        <FeedbackV2Layout
            {...props}
            title={`Case Performance Feedback`}
        >
            <div className="overview-fb-tabs">
                <QuestionFeedbackMain {...props} isLoading={isLoading} />
            </div>
        </FeedbackV2Layout>
    );
}

export default FeedbackTabsContainer;