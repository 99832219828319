import React, { useState, useCallback } from 'react';
import { useParams } from "react-router-dom";

//reactstrap
import { Input, Label, FormGroup } from "reactstrap";

//lodash
import { debounce } from 'lodash';

//api
import { apiCaller } from "@utils";

//redux
import { useDispatch } from "react-redux";

//constants
import { storyboardConstants } from "@constants";

//alert
import { toast } from 'react-toastify';

function MaxQuestionInput(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { timepointData } = props && props;
    let currentTimepoint = timepointData?.data;
    const MaxChatQuestions = currentTimepoint?.MaxChatQuestions;

    //state
    const [stateMaxChatQuestions, setMaxChatQuestions] = useState((MaxChatQuestions && MaxChatQuestions) || '');

    //Case - Update Timepoint - Max Chat Questions Allowed
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounced = useCallback(debounce((data, currentTimepointId) => {
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: currentTimepointId,
            MaxChatQuestions: data
        }
        dispatch({ type: storyboardConstants.UPDATE_MAX_CHAT_QUESTION_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res && res.status === 200) {
                console.log('success');
                toast.success('Max number of allowed questions have been updated', {
                    position: "bottom-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                dispatch({ type: storyboardConstants.UPDATE_MAX_CHAT_QUESTION_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.UPDATE_MAX_CHAT_QUESTION_FAILURE, error: 'error' });
            }
        });
    }, 1000), []);

    const hanldeChange = (e) => {
        const { value } = e.target;
        debounced(value, currentTimepoint?.Id);
        setMaxChatQuestions(value);
    }

    return (
        <FormGroup className="d-flex align-items-center">
            <Label
                for="MaxChatQuestions"
                className='mb-0 mr-2'
            >
                Max questions learner can answer:
            </Label>
            <Input
                type="number"
                name="MaxChatQuestions"
                className="form-control m-input"
                defaultValue={stateMaxChatQuestions}
                onChange={hanldeChange}
                inputMode="numeric"
                pattern="[0-9]+"
                min="0"
                style={{ maxWidth: '150px' }}
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />
        </FormGroup>
    );
};

export default MaxQuestionInput;