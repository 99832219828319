import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

const questionTypeOptions = [
  { value: "multiple_choice", label: "Multiple Choice" },
  { value: "free_text", label: "Free Text" }
];

const convertFormatQuestionType = (value) => {
  return questionTypeOptions.find(option => option.value === value) || null;
};

function QuestionTypeOptions({ handleChangeQuestionType, QuestionType }) {
  const [selectedOption, setSelectedOption] = useState(convertFormatQuestionType(QuestionType));

  useEffect(() => {
    setSelectedOption(convertFormatQuestionType(QuestionType));
  }, [QuestionType]);

  const onChange = (value) => {
    setSelectedOption(value);
    handleChangeQuestionType(value);
  };

  return (
    <FormGroup>
      <Label className="labeForm" for="questionTypeOptions">
        Question Type <span className="m--font-danger">*</span>
      </Label>
      <Select
        closeMenuOnSelect={true}
        value={selectedOption}
        onChange={onChange}
        options={questionTypeOptions}
        placeholder="Select an item below"
        className="w-100"
        name="QuestionType"
      />
    </FormGroup>
  );
}

QuestionTypeOptions.propTypes = {
  handleChangeQuestionType: PropTypes.func.isRequired,
  QuestionType: PropTypes.string,
};

export default QuestionTypeOptions;