import React from "react";
import { Button } from "reactstrap";

function ButtonChatWrapup({ handleClickTab, isActive }) {
    return (
        <Button
            className="mb-4 w-50 mx-2"
            outline={!isActive}
            color={isActive ? "primary" : "info"}
            onClick={handleClickTab}
        >
            Chat Wrap-up
        </Button>
    );
}

export default ButtonChatWrapup;