import React from 'react';
import styled from 'styled-components';

// Styled components for AuthorList and JournalTitle
const StyledAuthorList = styled.p`
  color: #475467;
  font-weight: 400;
`;

const StyledJournalTitle = styled.p`
  color: #475467;
  font-style: italic;
  font-weight: 400;
`;

// Reusable component for AuthorList and JournalTitle
const AuthorJournalDisplay = ({ authorList, journalCitation }) => {
  return (
    <React.Fragment>
      <StyledAuthorList>{authorList}</StyledAuthorList>
      <StyledJournalTitle>{journalCitation}</StyledJournalTitle>
    </React.Fragment>
  );
};

export default AuthorJournalDisplay;