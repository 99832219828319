import React from 'react';

//Icon
import { Iconsax } from "@components-common";

const ButtonCollapse = ({ isSidebarCollapsed, onClickSideBar }) => {
    return (
        <div className="sidebar-collapse" onClick={onClickSideBar}>
            {isSidebarCollapsed ? <Iconsax iconName="arrow-right" size={16} fill="#131419" /> : <Iconsax iconName="arrow-left" size={16} fill="#131419" />}
        </div>
    );
}

export default ButtonCollapse;