import React from 'react'
import { AnswerAnalyses } from '@components/teachV2/FreeTextQuestion/AnalysesDetail'
import { Icon } from '@components/common'
import Back from '@images/teachV2/back.svg'
// import PieChart from '@images/teachV2/pie-chart.svg'
import { useHistory, useLocation, useParams } from 'react-router-dom';

export default function AnalysesDetail() {
  let { idQuestion, idAnalyses } = useParams();
  let history = useHistory();
  const location = useLocation();

  const handleBack = () => {
    history.push({
      pathname: `/teach/free-text/${idQuestion}`,
      state: {
        analysis: true,
        listBank: location.state?.isBank
      } 
    })
  }
 
  return (
    <div className="wrapper -freeTextPage">
      <div className="result-wrap">
        <div className="icon-back mb-3" onClick={handleBack}>
          <Icon src={Back}/>
          <span>Analysis #{idAnalyses}</span>
        </div>
        {/* <Icon src={PieChart}/> */}
        <AnswerAnalyses />
      </div>
    </div>
  )
}