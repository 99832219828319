import { assessmentConstants } from "@constants";
import {apiCaller} from "@utils";

// Case - Update Self-Assessment Requirement
export const actUpdateSelfAssessment = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: assessmentConstants.UPDATE_ASSESSMENT_REQUEST, isLoading } }
    function success(data) { return { type: assessmentConstants.UPDATE_ASSESSMENT_SUCCESS, payload: data } }
    function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_FAILURE, error: error } }
}