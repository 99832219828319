import React from 'react';
import { Icon } from '@components/common';
import File from '@images/teachV2/file.svg';

const UploadChooseReferenceLibrary = ({ setShowModalBooks, showModalBooks }) => {
    return (
        <div className={'wrap-option__upload-choose w-100'}>
            <div className="wrap-choice d-flex flex-column">
                <div
                    className="wrap-choice__btn d-flex align-items-center justify-content-center w-100"
                    onClick={() => setShowModalBooks(!showModalBooks)}
                >
                    <Icon src={File} stroke="#0089C2" />
                    <span>Choose from Reference Library</span>
                </div>
            </div>
            <div className="wrap-choice__or d-flex align-items-center w-100">
                <hr />
                <p>OR</p>
                <hr />
            </div>
        </div>
    );
};

export default UploadChooseReferenceLibrary;