import React from 'react';
import imgPerson from "@images/common/person.png";

//redux
import { useSelector } from "react-redux";

function LoadingMessage(props) {
    const { data, value, contentStream } = props && props;
    const Interface = data?.Interface;
    const PatientAvatar = Interface?.PatientAvatar;

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const VirtualPatient = InterfaceTemplateDetails?.VirtualPatient;
    return (
        <div className={`messengerCt__wrapper ${value?.ChatStatus === "preceptor" ? "isShow" : ""}`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PatientAvatar || imgPerson}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            <div dangerouslySetInnerHTML={{ __html: VirtualPatient?.patient_name?.Text || "Patient:" }} />
                        </div>
                        <div className="messengerCt__message-text">
                            {contentStream && (
                                <div dangerouslySetInnerHTML={{ __html: contentStream }} />
                            )}
                            <div className="typing-loader ml-2 my-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;