import React from "react";
import Select from "react-select";
import styled from "styled-components";

const ResultsText = styled.div`
    color: #000;
    font-size: 14px;
    font-weight: 600;
`;

const HeaderSort = ({ data, handleChange, selectedOption, setSelectedOption, options }) => {
    const handleSelectChange = (selected) => {
        setSelectedOption(selected); // Cập nhật state bên ngoài
        handleChange(selected?.value); // Gọi logic filter từ props
    };

    return (
        <div className="d-flex align-items-center mb-4">
            <ResultsText>{data?.length} Results</ResultsText>
            <div className="ml-auto">
                <Select
                    id="sourceSelect"
                    placeholder="Filter by Source"
                    options={options} // Nhận danh sách options từ component cha
                    value={selectedOption} // Hiển thị option hiện tại từ state bên ngoài
                    onChange={handleSelectChange}
                    classNamePrefix="react-select-custom"
                    styles={{
                        container: (provided) => ({
                            ...provided,
                            width: 170,
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontSize: "11px",
                            fontWeight: "500",
                            color: "#475467",
                        }),
                    }}
                />
            </div>
        </div>
    );
};

export default HeaderSort;