import React from "react";

//components
import { LoginForm } from "@components/weitzman";

function LoginWeitzmanPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <LoginForm />
    </div>
  );
}

export default LoginWeitzmanPage;