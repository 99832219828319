import React, { useState, useEffect } from "react";

import { Container, TabContent, TabPane } from "reactstrap";

//call Action
import {
  actFetchTeachPortfolioRequest,
  actFetchAccountInstitutionRequest,
  actFetchAllCase,
  atcLoadAllCohorts,
  actLoadAllPeriods,
  actFetchTemplate,
  getTeacherHome,
} from "@actions";

//utils
import { LoaderPage, useQuery } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

// common
// import { NavigationComponent } from "@components/layouts"; //xoa

//menu
// import navigation from "@utils/navigation"; //xoa

import {
  Modules,
  ListTabs,
  Cohorts,
  Periods,
  LaunchPlan,
  Home
} from "@components/instructor/Tabs";

//common
import { TemplateMain } from "@components/common/templates";

function InstructorPage(props) {
  //query slug
  let query = useQuery();
  let activeParams = query.get("tabActive");

  //active
  const [activeTab, setActiveTab] = useState(activeParams || "1");
  const dispatch = useDispatch();

  //setActive Tab
  useEffect(() => {
    setActiveTab(activeParams || "1");
  }, [activeParams]);


  //load Data
  useEffect(() => {
    const fetchData = async () => {
      //Load My Case From Portfolio
      dispatch(actFetchTeachPortfolioRequest());

      //Load Account
      dispatch(actFetchAccountInstitutionRequest());

      //Load Case
      dispatch(actFetchAllCase());

      // Load Cohorts
      dispatch(atcLoadAllCohorts());

      //Load Periods
      dispatch(actLoadAllPeriods({ Action: "Load" }));

      //load templates
      dispatch(actFetchTemplate());

      //Load List Ac
      dispatch(getTeacherHome());
    };
    fetchData();
  }, [dispatch]);

  // const toggle = (tab) => {
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  //load cohort, periods  from store
  const cohortStore = useSelector((state) => state?.listCohort || {});

  const periodsStore = useSelector((state) => state?.loadPeriods || {});

  const templatesStore = useSelector((state) => state?.loadTemplates || {});

  //show Dropdown all template
  const allTemplateDefault = useSelector((state) => state?.allTemplate || {});

  const techHomeStore = useSelector((state) => state.loadTechHome || {});

  //load series from store
  const instructorData = useSelector((state) => state.instructor || {});
  const responseInstructor = instructorData && instructorData.data;
  const seriesStore = (responseInstructor && responseInstructor.Series) || [];
  const navList = [
    {
      Name: "Home",
      Slug: "/instructor-tool?tabActive=1",
      Tour: "/instructor-tool?tabActive=1",
      Component: (
        <Home
          instructorData={(instructorData && instructorData) || []}
          techHomeStore={(techHomeStore && techHomeStore) || {}}
          templatesStore={(templatesStore && templatesStore) || {}}
          {...props}
        />
      ),
    },
    {
      Name: "Cases / Series",
      Slug: "/instructor-tool?tabActive=2",
      Tour: "/instructor-tool?tabActive=2",
      Component: (
        <Modules
          dataSeries={(seriesStore && seriesStore) || []}
          instructorData={(instructorData && instructorData) || []}
        />
      ),
    },
    {
      Name: "Templates",
      Slug: "/instructor-tool?tabActive=3",
      Tour: "/instructor-tool?tabActive=3",
      Component: (
        <TemplateMain
          type={`instructor`}
          templatesStore={(templatesStore && templatesStore) || {}}
          allTemplateDefault={(allTemplateDefault && allTemplateDefault) || {}}
        />
      ),
    },
    {
      Name: "Periods",
      Slug: "/instructor-tool?tabActive=4",
      Tour: "/instructor-tool?tabActive=4",
      Component: (
        <Periods periodsStore={(periodsStore && periodsStore) || {}} />
      ),
    },
    {
      Name: "Cohorts",
      Slug: "/instructor-tool?tabActive=5",
      Tour: "/instructor-tool?tabActive=5",
      Component: <Cohorts cohortStore={(cohortStore && cohortStore) || {}} />,
    },
    {
      Name: "Launch Plans",
      Slug: "/instructor-tool?tabActive=6",
      Tour: "/instructor-tool?tabActive=6",
      Component: (
        <LaunchPlan
          cohortStore={(cohortStore && cohortStore) || {}}
          periodsStore={(periodsStore && periodsStore) || {}}
          dataSeries={(seriesStore && seriesStore) || []}
        />
      ),
    }
  ];

  // const roleAdmin = props.is_administrator === "Yes" || props.is_instructor === "Yes";
  // const mainMenu = roleAdmin ? navigation && navigation.mainMenuAdmin : navigation && navigation.mainMenuLearner;
  return (
    <div className="wrapper -instructorPage">
      {techHomeStore.isLoading && <LoaderPage />}
      <React.Fragment>
        <div className="instructorCt bg-white">
          <Container>
            <ListTabs activeTab={activeTab} navList={navList} />
            <TabContent activeTab={activeTab}>
              {navList?.map((value, index) => (
                <TabPane key={index} tabId={(index + 1).toString()}>
                  {value.Component}
                </TabPane>
              ))}
            </TabContent>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default InstructorPage;