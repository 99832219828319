import React from 'react';
import EarnCoinItem from './EarnCoinItem';

const EarnCoinList = ({ items }) => {
    return (
        <ul className='list-earn-coins'>
            {items.map((item, index) => (
                <EarnCoinItem
                    key={index}
                    title={item.title}
                    description={item.description}
                    iconWidth={item.iconWidth}
                    iconAlt={item.iconAlt}
                    coinValue={item.coinValue}
                />
            ))}
        </ul>
    );
};

export default EarnCoinList;