import React, { useRef } from 'react'
import TitleNoteCommon from '../../home/generate/TitleNoteCommon'
import { apiCaller } from "@utils";
import { toast } from 'react-toastify';

export default function QuestionInformation(props) {
  const { dataAnalyze, setDataAnalyze } = props
  const questionRef = useRef()
  const rubricsRef = useRef()
  const answerRef = useRef()

  const handleSave = () => {
    let params = {
      Action: "Edit",
      QuestionItemId: dataAnalyze?.QuestionItemId,
      Type: "free_text",
      QuestionText: questionRef?.current?.innerText,
      Rubric: {
        instruction_text: rubricsRef?.current?.innerText
      },
      ModelAnswer: answerRef?.current?.innerText || ""
    }
    if (
      (questionRef?.current?.innerText === dataAnalyze?.Contents?.question
      && rubricsRef?.current?.innerText === dataAnalyze?.Rubric?.instruction_text
      && (answerRef?.current?.innerText === (dataAnalyze?.ModelAnswer || "")))
      || (questionRef?.current?.innerText === "" || rubricsRef?.current?.innerText === "")
    ) {
    } else {
      apiCaller(`/api/feedback/question/items/`, 'POST', params).then(res => {
        if (res.status === 200) {
          setDataAnalyze(res.data) 
          toast.success(`Information has been updated!`, {
            position: "top-right"
          });
        }
      });
    }
  }

  return (
    <div className='question-info'>
      <div className='question-info__item'>
        <TitleNoteCommon title="Question Text" required/>
        <div 
          ref={questionRef}
          onBlur={handleSave}
          contentEditable
          className="item-text" 
          dangerouslySetInnerHTML={{ __html: dataAnalyze?.Contents?.question?.replace(/\n/g,"<br />") || '' }} 
        />
      </div>
      <div className='question-info__item'>
        <TitleNoteCommon title="Rubrics" required/>
        <div 
          ref={rubricsRef}
          onBlur={handleSave}
          contentEditable
          className="item-text" 
          dangerouslySetInnerHTML={{ __html: dataAnalyze?.Rubric?.instruction_text?.replace(/\n/g,"<br />") || '' }} 
        />
      </div>
      <div className='question-info__item'>
        <TitleNoteCommon title="Model Answer"/>
        <div 
          ref={answerRef}
          onBlur={handleSave}
          contentEditable
          className="item-text" 
          dangerouslySetInnerHTML={{ __html: dataAnalyze?.ModelAnswer?.replace(/\n/g,"<br />") || '' }} />
      </div>
    </div>
  )
}
