import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input } from 'reactstrap';

function ScoreInput(props) {
    const { data, handleSendParamsScore } = props && props;
    const Score = data?.Score;

    //state
    const [stateScore, setScore] = useState((Score && Score) || 0);
    const [sendData, setSendData] = useState(true);

    useEffect(() => {
        setScore((Score && Score) || 0);
    }, [Score])

    const handleChange = (e) => {
        const { value } = e.target;
        setScore(value);
        setSendData(true);
    }

     //send Data to parent
     useEffect(() => {
        if (sendData) {
            handleSendParamsScore(stateScore);
            setSendData(false);
        }
    }, [data, handleSendParamsScore, stateScore, sendData]);
    
    return (
        <Form>
            <FormGroup>
                <Label
                    for="Score"
                >
                    Score:
                </Label>
                <Input
                    id="Score"
                    name="Score"
                    placeholder=""
                    type="number"
                    inputMode="numeric"
                    value={stateScore}
                    onChange={handleChange}
                    style={{maxWidth: '150px'}}
                />
            </FormGroup>
        </Form>
    );
};

export default ScoreInput;