import React from 'react';

//components
import { ListTimePoint } from "../storyboard";
import { LastTimePoint } from "../storyboard/EndingTimepoint";

function PresentationTime(props) {
    const { handleToggleAddTimepoint } = props && props;

    return (
        <div className="presentationTime">
            <ListTimePoint {...props}>
                <LastTimePoint {...props} />
            </ListTimePoint>
            <button
                type="button"
                onClick={handleToggleAddTimepoint}
                className="btn btn-sm m-btn--pill btn-outline-brand --btn-ouline-primary ml-2"
            >
                + Add Time Point
            </button>
        </div>
    );
};

export default PresentationTime;