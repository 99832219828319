import React from "react";

function AuthContainer({ children }) {
    return (
        <div className="wrapper makansafe-authencation">
            <div className="makansafe-login-container">
                <div className="container-fluid">
                    <div className="row vh-100 py-4">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AuthContainer;