import React from "react";
import { Alert } from "reactstrap";

function AnswerContent(props) {
  // const { QuestionContent } = props && props;
  return (
    <Alert className="m-alert--default my-0 border-0 pr-5">
      <p><strong>Question:</strong></p>
      <p>
        <em>Just a few more questions before we move on ...</em>
      </p>
      <p>
        <font color="#575962">
          <strong>
            Organise the following statements into the exact sequence of the
            optimal clinical approach you have performed in this case
          </strong>
        </font>
      </p>
    </Alert>
  );
}

export default AnswerContent;