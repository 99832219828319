import React from "react";

function PricingContentPc(props) {
  const { data } = props && props;
  return (
    <React.Fragment>
      {data?.map((value, index) => (
        <React.Fragment key={index}>
          <div className="pricing-flex-wrapper">
            <div className="pricing-flex pricing-description border-end border-bottom">
              <div className="flex-row">
                <div className="pricing-subtitle-mobile">{value.type}</div>
              </div>
            </div>
            <div className="pricing-flex pricing-tier border-end border-bottom">
              <div className="flex-row" />
            </div>
            <div className="pricing-flex pricing-tier border-end border-bottom">
              <div className="flex-row" />
            </div>
            <div className="pricing-flex pricing-tier border-bottom">
              <div className="flex-row" />
            </div>
          </div>
          {value.details?.map((detail, j) => (
            <div className="pricing-flex-wrapper relative" key={j}>
              <div className="flex-hover" />
              <div className="pricing-flex pricing-description border-end border-bottom">
                <div className="flex-row">
                  <div>{detail.name}</div>
                </div>
              </div>
              <div className="pricing-flex pricing-tier border-end border-bottom">
                <div className="flex-row">
                  {detail.type1 === "checked" ? (
                    <div className="font-awesome-icon">
                      <strong>
                        <i className="fa fa-check" aria-hidden="true" />
                      </strong>
                    </div>
                  ) : (
                    <span className="fw-400">{detail.type1}</span>
                  )}
                </div>
              </div>
              <div className="pricing-flex pricing-tier border-end border-bottom">
                <div className="flex-row">
                  {detail.type2 === "checked" ? (
                    <div className="font-awesome-icon">
                      <strong>
                        <i className="fa fa-check" aria-hidden="true" />
                      </strong>
                    </div>
                  ) : (
                    <span className="fw-400">{detail.type2}</span>
                  )}
                </div>
              </div>
              <div className="pricing-flex pricing-tier border-bottom">
                <div className="flex-row">
                  {detail.type3 === "checked" ? (
                    <div className="font-awesome-icon">
                      <strong>
                        <i className="fa fa-check" aria-hidden="true" />
                      </strong>
                    </div>
                  ) : (
                    <span className="fw-400">{detail.type3}</span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default PricingContentPc;
