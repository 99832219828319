import React from 'react';
import styled from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';

const ImagePreviewWrapper = styled.div`
  background: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 473px;
  margin: 0 auto 1rem auto;
  position: relative;
`;

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  z-index: 1;

  border-radius: 6px;
  background: rgba(255, 255, 255, 0.74);
  
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border: none;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: none;
  }

  span {
    color: #344054;
    font-size: 14px;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

const Overlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const ImagePreview = ({ foodAIData, isManuallyUpload, imgUrl, handleRegenerateImage, loadingGenerateImage }) => {
  return (
    <ImagePreviewWrapper>
      {!isManuallyUpload && foodAIData?.food_name && (
        <StyledButton onClick={handleRegenerateImage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M1.66675 11.6667C1.66675 11.6667 1.76785 12.3744 4.69678 15.3033C7.62571 18.2322 12.3744 18.2322 15.3034 15.3033C16.3411 14.2656 17.0112 12.9994 17.3136 11.6667M1.66675 11.6667V16.6667M1.66675 11.6667H6.66675M18.3334 8.33333C18.3334 8.33333 18.2323 7.62563 15.3034 4.6967C12.3744 1.76777 7.62571 1.76777 4.69678 4.6967C3.65905 5.73443 2.98899 7.0006 2.68661 8.33333M18.3334 8.33333V3.33333M18.3334 8.33333H13.3334" stroke="#1D2939" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <span>Regenerate Image</span>
        </StyledButton>
      )}
      {loadingGenerateImage && (
        <Overlay>
          <FadeLoader loading={true} color={'#fff'} size={20} />
          <span style={{ color: '#fff', marginTop: '8px', fontWeight: 600 }}>Loading...</span>
        </Overlay>
      )}
      <PreviewImage src={imgUrl} alt="Preview" />
    </ImagePreviewWrapper>
  );
};

export default ImagePreview;