import React from 'react';
import { Row, Col } from 'reactstrap';
import { EditCohortButton, AddUserButton } from '../Cohorts/Button';
import styled from 'styled-components';

// Styled component cho Heading
const Heading = styled.h3`
  color: #081B2A;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
`;


const ActionsHeadingCohort = (props) => {
    const { cohortObject } = props && props;
    return (
        <Row className='d-flex align-items-center'>
            <Col sm={7}>
                <Heading>{cohortObject?.Name}</Heading>
            </Col>
            <Col sm={5} className='d-flex align-items-center justify-content-end'>
                <EditCohortButton {...props} />
                <AddUserButton {...props} />
            </Col>
        </Row>
    );
};

export default ActionsHeadingCohort;