import React, { useEffect } from "react";

//call Action
import { getTeacherHome, actFetchTeachPortfolioRequest, actFetchTemplate } from "@actions";

//redux
import { useDispatch } from "react-redux";

import { Home } from "@components/teachV2/Manage/Tabs";

function MgmtHomePage(props) {

  //active
  const dispatch = useDispatch();


  //load Data
  useEffect(() => {
    const fetchData = async () => {
      //Load My Case From Portfolio
      dispatch(actFetchTeachPortfolioRequest());

      //Load Teach Home
      dispatch(getTeacherHome());

      //load templates
      dispatch(actFetchTemplate());
    };
    fetchData();
  }, [dispatch]);
  return (
    <div className="wrapper managementHomePage">
      <Home
        {...props}
      />
    </div>
  );
}

export default MgmtHomePage;