// MgmtTemplatePage.jsx
import React, { useEffect } from 'react';

//call Action
import { actFetchTemplate } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//common
import { TemplateMain } from "@components/common/templates";

const MgmtTemplatePage = () => {
  const dispatch = useDispatch();

  //load Data
  useEffect(() => {
    const fetchData = async () => {
      //load templates
      dispatch(actFetchTemplate());
    };
    fetchData();
  }, [dispatch]);

  //show Dropdown all template
  const allTemplateDefault = useSelector((state) => state?.allTemplate || {});
  const templatesStore = useSelector((state) => state?.loadTemplates || {});

  return (
    <div className='wrapper MgmtTemplatesPage'>
      <TemplateMain
        type={`instructor`}
        templatesStore={(templatesStore && templatesStore) || {}}
        allTemplateDefault={(allTemplateDefault && allTemplateDefault) || {}}
      />
    </div>
  );
};

export default MgmtTemplatePage;