import React from 'react';
import { Row, Col } from 'reactstrap';
import { SearchBasic } from '@ui-partents/Search';
import { TableSelectRow } from "@components/patterns/elements/Table";

const SearchFormPage = () => {
    const handleSearch = (query) => {
        console.log("Search query:", query);
    };

    const sampleData = [
        { id: 1, name: "Research Paper A" },
        { id: 2, name: "Journal Article B" },
        { id: 3, name: "Conference Paper C" },
        { id: 4, name: "Technical Report D" },
        { id: 5, name: "Thesis E" },
        { id: 6, name: "Review Paper F" },
        { id: 7, name: "Case Study G" },
        { id: 8, name: "White Paper H" },
        { id: 9, name: "E-book I" },
        { id: 10, name: "Magazine Article J" },
        { id: 11, name: "Online Blog K" },
        { id: 12, name: "Interview Transcript L" },
        { id: 13, name: "Survey Results M" },
        { id: 14, name: "Field Study N" },
        { id: 15, name: "Laboratory Report O" },
        { id: 16, name: "Policy Document P" },
        { id: 17, name: "Historical Analysis Q" },
        { id: 18, name: "Experiment Summary R" },
        { id: 19, name: "Literature Review S" },
        { id: 20, name: "Data Analysis Report T" },
    ];

    return (
        <section className="message-content">
            <div className="my-3">
                {/* Header */}
                <Row className="mb-4">
                    <Col md={12}>
                        <div className="content-header">
                            <h1>Search Basic Examples</h1>
                        </div>
                    </Col>
                </Row>

                {/* Examples */}
                <Row className="mb-4">
                    <Col md={12}>
                        {/* Default Search with Button */}
                        <h3 className='mb-2'>Default Search with Button</h3>
                        <SearchBasic onSearch={handleSearch} />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        {/* Search with No Button */}
                        <h3 className='mb-2'>Search without Button</h3>
                        <SearchBasic
                            onSearch={handleSearch}
                            showButton={false}
                            placeholder="Type and press Enter..."
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        {/* Search with Custom Width */}
                        <h3 className='mb-2'>Search with Custom Width</h3>
                        <SearchBasic
                            onSearch={handleSearch}
                            maxWidth="400px"
                            placeholder="Search with a wider input..."
                        />
                    </Col>
                </Row>
            </div>
            <div className='mt-4'>
                <Row>
                    <Col md={12}>
                        <div className="content-header">
                            <h1>Search With react-bootstrap-table2</h1>
                        </div>
                        <TableSelectRow stateData={sampleData} />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default SearchFormPage;