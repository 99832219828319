import React from 'react'
// import { Col, Row } from "reactstrap";
import InputGenerate from './generate/InputGenerate';
// import ResultGenerate from './result/ResultGenerate';

const HomeGenerate = (props) => {

  return (
    <InputGenerate {...props} />
  )
}

export default HomeGenerate