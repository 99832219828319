import styled from 'styled-components';

// Styled components
const Button = styled.button`
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  color: #475467;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    background: #F7F9FC;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const CancelOrderButton = ({ onRemove, isLoading }) => (
  <Button onClick={onRemove} disabled={isLoading}>
    {isLoading ? (
      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
    ) : (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path d="M10.5 3.5L3.5 10.5M3.5 3.5L10.5 10.5" stroke="#D92D20" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )}
    Cancel Order
  </Button>
);

export default CancelOrderButton;