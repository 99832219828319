import React from 'react';

//components
import { QuestionMain } from "../QuestionCommon";

const QuestionsNarrative = (props) => {
  return (
    <QuestionMain {...props} />
  );
};

export default QuestionsNarrative;