const messageData = {
  filter: "No matching records",
  modulesEmty: {
    messageAllCase: "You have not created any case <br/> Click on the button above to start a case",
    messageSeries: "The series is currently empty! <br/> Click on the “<strong>Add Case</strong>” button to starting adding cases to the series",
  },
  cohortsEmty: {
    loadAll: 'Click on “<strong>Add Cohort</strong>” on the left panel to create the first cohort',
    loaddLearnerByid: `The cohort  is currently empty! <br/> Click on the “<strong>Add Learner(s)</strong>” button to starting adding learners to this cohort`
  },
  verifyAccount: {
    title: 'Account Verfied!',
    description: `Your account is all set up. Click on the button below to log in`
  },
  feedbackTemplate: {
    content: `A standard feedback template is currently being used for this case<br/>Click on the options on the left panel if you would like to use a customized template`
  },
  templatesEmty: {
    content: 'You currently do not have any template.<br/> Click on the “New Template” button to start one.'
  },
  casesEmty: {
    content: 'You have not created any case <br/> Click on the button above to start a case.'
  },
  periodsEmty: {
    content: 'You have not set up any period'
  },
  default: {
    content: 'No Data'
  }
}

export default messageData;