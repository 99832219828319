import React from "react";
import { TabContent, TabPane } from "reactstrap";
//component check time
import { TableVitalSignsLast } from "../timePointActions/TableVitalSigns";

//Vital Signs
import { TableVitalSignsFirst } from "../VitalSigns";

//components main
import { Header } from "./VitalSignsBlock";

// Editor
import RichTextCommon from '../RichTextCommon';

function VitalSignsMain(props) {
  const {
    value,
    activeTabCt,
    listTabs,
    timepointData,
    valueEditorVital,
    handleUpdateEditor,
    timePointId
  } = props && props;
  const isTime = timepointData?.data?.Time;
  const ViewClass = value?.ViewClass;

  return (
    <React.Fragment>
      <Header
        {...props}
        title={value?.Label}
        task='vital'
      />
      <TabContent activeTab={activeTabCt}>
        {listTabs?.vital.map(
          (item, index) =>
            item.show && (
              <TabPane key={index} tabId={`${index + 1}`}>
                {item.id === "1" && (
                  <div className="mb-5">
                    <div className="mt-4 position-relative">
                      {isTime === 0 ? (
                        <TableVitalSignsFirst {...props} />
                      ) : (
                        <TableVitalSignsLast {...props} />
                      )}
                    </div>
                  </div>
                )}
                {item.id === "2" && (
                  <RichTextCommon
                    handleUpdateEditor={handleUpdateEditor}
                    task={ViewClass}
                    defaultValue={valueEditorVital}
                    placeholder="Enter the instructions for the learner here..."
                    timePointId={timePointId}
                  />
                )}
              </TabPane>
            )
        )}
      </TabContent>
    </React.Fragment>
  );
}

export default VitalSignsMain;
