import React from "react";

//Images
import logoMakansafe from '@images/makansafe/logo.png';

//components
import { AuthContainer, BannerLogin, NewPasswordForm } from "@components/Makansafe";

function CreateNewPasswordPage(props) {

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <AuthContainer>
      <div className="col-md-12 col-lg-6 d-flex align-items-center justify-content-center">
        <div className="form-makansafe-ct">
          <div className="logo-makansafe__logo">
            <img src={logoMakansafe} alt="logo" />
          </div>
          <div className="logo-makansafe__title">
            <h1>
              Create new password
            </h1>
          </div>
          <div className="form-makansafe-login">
            <p className="desc-form">Please enter your new password.</p>
            <NewPasswordForm onSubmit={onSubmit} />
          </div>
        </div>
      </div>
      <BannerLogin {...props} />
    </AuthContainer>
  );
}

export default CreateNewPasswordPage;