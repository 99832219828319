import { profileConstant } from "@constants";
import { apiCaller } from "@utils";

// Helper functions
const createRequest = (type, isLoading) => ({ type, isLoading });
const createSuccess = (type, data) => ({ type, payload: data });
const createFailure = (type, error) => ({ type, error });

// API call wrapper
const apiCall = async (dispatch, requestType, successType, failureType, url, method, params) => {
    dispatch(createRequest(requestType, true));
    try {
        const res = await apiCaller(url, method, params);
        if (res.status === 200) {
            dispatch(createSuccess(successType, res.data));
            return res; // Return data on success
        } else {
            dispatch(createFailure(failureType, res.data?.error || "Unknown error"));
            return null; // Return null on failure
        }
    } catch (error) {
        dispatch(createFailure(failureType, error.message));
        return null; // Return null on exception
    }
};

// Reset Password
export const actRequestResetPassword = (params) => async dispatch => {
    try {
        const res = await apiCaller(`/api/account/passwordreset/`, 'POST', params);
        const dataRes = res?.data;
        if (res?.status === 200) {
            if (dataRes?.error) {
                dispatch(createFailure(profileConstant.RESET_PASSWORD_FAILURE, "Failed to authenticate using your current password. Please try again"));
                window.location.href = '/reset-password/failed';
            } else if (dataRes?.status === "success") {
                dispatch(createSuccess(profileConstant.RESET_PASSWORD_SUCCESS, "Your Password Has Been Successfully Reset"));
                window.location.href = '/reset-password/success';
                return dataRes; // Return data on success
            } else {
                dispatch(createFailure(profileConstant.RESET_PASSWORD_FAILURE, "Unknown error"));
            }
        } else {
            dispatch(createFailure(profileConstant.RESET_PASSWORD_FAILURE, res.data?.error || "Unknown error"));
        }
    } catch (error) {
        dispatch(createFailure(profileConstant.RESET_PASSWORD_FAILURE, error.message));
    }
    return null; // Return null on failure or exception
};

// Get Account Time Zone
export const getAccountTimeZone = () => async dispatch => {
    return await apiCall(dispatch, profileConstant.GET_ACCOUNT_TIME_ZONE_REQUEST, profileConstant.GET_ACCOUNT_TIME_ZONE_SUCCESS, profileConstant.GET_ACCOUNT_TIME_ZONE_FAILURE, `/api/account/timezone/`, 'GET', null);
};

// Update Account Time Zone
export const setAccountTimeZone = (params) => async dispatch => {
    return await apiCall(dispatch, profileConstant.SET_ACCOUNT_TIME_ZONE_REQUEST, profileConstant.SET_ACCOUNT_TIME_ZONE_SUCCESS, profileConstant.SET_ACCOUNT_TIME_ZONE_FAILURE, `/api/account/timezone/`, 'POST', params);
};

// Get Account Settings
export const getAccountSettings = (params) => async dispatch => {
    return await apiCall(dispatch, profileConstant.GET_ACCOUNT_SETTINGS_REQUEST, profileConstant.GET_ACCOUNT_SETTINGS_SUCCESS, profileConstant.GET_ACCOUNT_SETTINGS_FAILURE, `/api/account/settings/`, 'GET', params);
};

// Update Account Settings
export const updateAccountSettings = (params) => async dispatch => {
    return await apiCall(dispatch, profileConstant.UPDATE_ACCOUNT_SETTINGS_REQUEST, profileConstant.UPDATE_ACCOUNT_SETTINGS_SUCCESS, profileConstant.UPDATE_ACCOUNT_SETTINGS_FAILURE, `/api/account/settings/`, 'POST', params);
};

// Get Account Info
export const actAccountInfo = () => async dispatch => {
    return await apiCall(dispatch, profileConstant.GET_ACCOUNT_INFO_REQUEST, profileConstant.GET_ACCOUNT_INFO_SUCCESS, profileConstant.GET_ACCOUNT_INFO_FAILURE, `/api/learn/account_info/`, 'GET', null);
};