import React from "react";
import styled, { keyframes } from "styled-components";
import { Button } from "reactstrap";

// Animation khi mở modal
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Overlay toàn màn hình
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 17000;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

// Modal hiển thị nội dung
const Modal = styled.div`
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: #fff;
  animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 0.3s ease-in-out;
  max-width: 422px;
  position: relative;
`;

// Close button
const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:hover svg path {
    stroke: #000; /* Thay đổi màu khi hover */
  }
`;

// Title
const Title = styled.h1`
  color: #101828;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.48px;
`;

// Content
const Content = styled.p`
  color: #1D2939;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

// Hình ảnh
const Image = styled.img`
  width: 81px;
  height: 81px;
  margin-bottom: 16px;
`;

const MicrophoneMessage = ({
  isOpen,
  onClose,
  title,
  content,
  icon,
  showCloseButton = true, // Cho phép hiển thị hoặc ẩn nút close
  buttonGotit
}) => {
  return (
    <Overlay isVisible={isOpen}>
      <Modal isVisible={isOpen}>
        {showCloseButton && (
          <CloseButton onClick={onClose} aria-label="Close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13 1L1 13"
                stroke="#9AA4B2"
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13 13L1 1"
                stroke="#9AA4B2"
                strokeWidth="1.875"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </CloseButton>
        )}
        <Image src={icon} alt={title} />
        <Title>{title}</Title>
        <Content>{content}</Content>
        {buttonGotit &&
            <Button className="px-3" color="primary" onClick={onClose}>
            Got It
            </Button>
        }
      </Modal>
    </Overlay>
  );
};

export default MicrophoneMessage;