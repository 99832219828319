import React from "react";
import { TasksDropdown } from "../CaseTimeline";

const AvailableTasksDropdown = (props) => {
  return (
    <div className="list-available-task">
      <TasksDropdown
        {...props}
      />
    </div>
  );
};

export default AvailableTasksDropdown;