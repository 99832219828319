import React, { useEffect, useState } from "react";

//utils
import { LoaderText } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

// components
import { ChatGroup } from '../Chat';

//actions
import { loadAllStandardChatTopics } from "@actions";

//Boostrap
import { Container } from "reactstrap";

function AdminChatMain(props) {

    let { id } = useParams();
    const dispatch = useDispatch();

    //storeChat
    const storeChat = useSelector((state) => state?.getAllStandardChatTopics || {});
    const data = storeChat?.data;
    const isActive = storeChat?.isActive;
    const ParentChatTopicIdActive = storeChat?.ParentChatTopicId;

    //state
    const [stateData, setData] = useState((data && data) || []);

    //load data
    useEffect(() => {
        setData((data && data) || [])
    }, [data])

    //Load All Standard Chat Topics
    useEffect(() => {
        const fetchData = () => {
            dispatch(loadAllStandardChatTopics());
        };
        fetchData();
    }, [dispatch, id]);

    const DataMap = ({ data }) => {
        return (
            <React.Fragment>
                {data?.map((row, index) => {
                    return (
                        <div className="panel mb-3" key={index}>
                            <ChatGroup
                                rowData={(row && row) || {}}
                                index={index}
                                isActive={isActive}
                                ParentChatTopicIdActive={ParentChatTopicIdActive}
                            />
                        </div>
                    )
                })}
            </React.Fragment>
        );
    };

    return (
        <Container className="boxPortlet position-relative chatTopicTable">
            {!storeChat?.isLoading && (
                <DataMap data={(stateData && stateData) || []} />
            )}
            {storeChat?.isLoading && (
                <div style={{ height: '400px' }}>
                    <LoaderText />
                </div>
            )}
        </Container>
    );
}

export default AdminChatMain;