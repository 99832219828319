import React from "react";

//reactstrap
import { Row, Col } from "reactstrap";

//LinesEllipsis
import LinesEllipsis from "react-lines-ellipsis";

//components
import {
  SettingMain,
  BriefViewCheckbox,
  ButtonDeleteSeries,
  ButtonAddCase
} from '../Modules';

function FilterCaseId(props) {
  const { itemActive } = props && props;
  return (
    <Row className="align-items-center">
      <Col md={5} sm={12}>
        <h5 className="heading-5 font-weight-600">
          <LinesEllipsis
            text={itemActive?.Name || ""}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </h5>
      </Col>
      <Col md={7} sm={12}>
        <div className="d-flex align-items-center justify-content-end">
          <BriefViewCheckbox {...props} />
          <SettingMain {...props} />
          <ButtonDeleteSeries {...props} />
          <ButtonAddCase {...props} />
        </div>
      </Col>
    </Row>
  );
}

export default FilterCaseId;