import React from 'react';

function MedRecordButton(props) {
    const { handleMedRecord } = props && props;
    return (
        <li className="taskList__item active" onClick={() => handleMedRecord()}>
            <span className="taskList__item--icon icon-emr-2"></span>
            <span className='taskList__item--text font-weight-600'>Review</span>
        </li>
    );
};

export default MedRecordButton;