import React from "react";

function ChatWrapUpHeader() {
    return (
        <div className="chat-wrapUp-header text-center">
            <h3>Chat Wrap-up: Your Performance Review</h3>
            <p>Great job on completing the chat interaction! <br /> Now, let's take a peek at how you did during the session. 📋</p>
        </div>
    );
}

export default ChatWrapUpHeader;