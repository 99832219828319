import React from 'react';

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

//components
import UserMessage from "./UserMessage";
import AssistantMessage from "./AssistantMessage";
import LoadingMessage from "./LoadingMessage";

//lodash
import _ from "lodash";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

function FrameChatSmart(props) {
    const { data, messagesEndRef, isLoading } = props && props;
    const dialog = data?.dialog;
    const filteredData = _.filter(dialog, (value) => value?.role !== "system");
    
    // Use screen size context
    const { isMobile } = useScreenSize();
    return (
        <div className={`frameChat__fullBox`}>
            <Scrollbar style={{ height: isMobile ? 'calc(100vh - 150px)' : 'calc(100vh - 160px)', overflowY: 'auto' }} ref={messagesEndRef}>
                <div className="messengerCt">
                    <div className="messengerCt__messages">
                        {filteredData?.map((value, index) => (
                            <React.Fragment key={index}>
                                {value?.role === "user" && (
                                    <UserMessage {...props} value={value?.content} />
                                )}

                                {value?.role === "assistant" ? (
                                    <AssistantMessage
                                        {...props}
                                        index={index}
                                        value={value}
                                    />
                                ) : filteredData?.length - 1 === index && isLoading && <LoadingMessage {...props} />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </Scrollbar>
        </div>
    );
};

export default FrameChatSmart;