import { makansafeConstants } from "@constants";

export function FoodGenerateAI(state = {}, action) {
    switch (action.type) {
        case makansafeConstants.GENERATE_AI_IDDSI_FOOD_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case makansafeConstants.GENERATE_AI_IDDSI_FOOD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case makansafeConstants.GENERATE_AI_IDDSI_FOOD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};