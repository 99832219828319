import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function PromptTemplateModal({ isOpen, toggleModal, selectedPromptTemplate }) {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader toggle={toggleModal}>Prompt Template - {selectedPromptTemplate?.Name}</ModalHeader>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: selectedPromptTemplate?.Content || '' }} />
      </ModalBody>
    </Modal>
  );
}

export default PromptTemplateModal;