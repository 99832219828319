import React, { useState, useEffect } from "react";

//logo default
import iconClose from "@images/common/close_modal.svg";

//components
import { Framechat } from "../../ReviewContentModal/GoldStandard";

//Avtar
import avatarUser from "@images/common/avatar-user-demo.png";
import avatarGpt from "@images/common/avatar-gpt-demo.png";

//Filter
import { ChatGptVersionFilter } from "../../Filter";

//call Api
import { LoaderText, apiCaller, formatReactSelect } from "@utils";

//lodash
import _ from "lodash";

//format time
import moment from "moment";
import { Row, Col } from "reactstrap";

function ModalComparisonData(props) {
    //props
    const { isOpen, toggle, dataCurrent } = props && props;

    //state
    const [ComparisonData, setComparisonData] = useState({});
    const [gptData, setGptData] = useState({});
    const [isLoading, setLoading] = useState({
        loadComparisonData: false
    });
    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {
        setLoading((prevState) => ({ ...prevState, loadComparisonData: true }));
        const fetchData = () => {
            const params = {
                "action_type": "load_comparison_data",
                "log_id": dataCurrent?.log_id,
                "review_type": dataCurrent?.review_type
            }
            apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setComparisonData(data);
                    setLoading((prevState) => ({ ...prevState, loadComparisonData: false }));
                } else {
                    setLoading((prevState) => ({ ...prevState, loadComparisonData: false }));
                    console.log('error');
                }
            });
        }
        //Load Api Model
        isOpen && fetchData();
    }, [dataCurrent, isOpen]);

    //Handle Change Dropdown
    const handleChangeOptions = (data) => {
        setSelectedOption(data);
        const dataVersion = _.find(ComparisonData?.chatgpt_versions, { version_uuid: data?.value });
        setGptData(dataVersion);
    };

    const dropdownSelect = ComparisonData?.chatgpt_versions?.length > 0 ? formatReactSelect(ComparisonData?.chatgpt_versions || [], 'version_uuid', 'version_name') : [];
    const layoutChatGoldStandard = {
        user: {
            name: 'Prompt / Input:',
            image: avatarUser
        },
        assistant: {
            name: 'Assistant:',
            image: avatarUser
        }
    }
    const layoutChatVerSion = {
        user: {
            name: 'Prompt / Input:',
            image: avatarUser
        },
        assistant: {
            name: 'Chat GPT:',
            image: avatarGpt
        }
    }
    return (
        <div
            className={`boxPushCt showRight scrollbarStyle fixed bg-white w-100 ${isOpen && "open-right"}`}
        >
            <div className="boxPushCt__heading">
                <h3 className="heading-3 p-0">
                    {`${moment(dataCurrent?.generated_time).format("LLL")} ${dataCurrent?.email ? `- ${dataCurrent?.email}` : ''}`}
                </h3>
                <img
                    className="icon-exit-to-app-button ml-auto closeIcon"
                    src={iconClose}
                    alt="close modal"
                    onClick={toggle}
                />
            </div>
            <div className="boxPushCt__body p-3 position-relative" style={{ overflowY: 'hidden' }}>
                {isLoading?.loadComparisonData && <LoaderText />}
                <Row className="mb-3">
                    <Col md={6}>
                        <h3 className="text-4 --text-primary">Gold Standard</h3>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex align-items-center">
                            <h3 className="text-4 --text-primary mr-3">ChatGPT</h3>
                            <div className="w-100">
                                <ChatGptVersionFilter
                                    {...props}
                                    dataSelect={dropdownSelect}
                                    handleChangeOptions={handleChangeOptions}
                                    selectedOption={selectedOption}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="h-100">
                    <Col className="h-100" md={6} style={{ overflowY: 'auto' }}>
                        <Framechat
                            {...props}
                            data={ComparisonData?.gold_standard || []}
                            layoutChat={layoutChatGoldStandard}
                        />
                    </Col>
                    <Col className="h-100" md={6} style={{ overflowY: 'auto' }}>
                        <Framechat
                            {...props}
                            data={gptData?.dialog || []}
                            layoutChat={layoutChatVerSion}
                        />
                    </Col>
                </Row>
            </div>
        </div>
        // </Resizable>
    );
}

export default ModalComparisonData;