import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Iconsax } from "@components-common";
import { BetaComponent } from '@ui-partents/Beta';
import UserContext from '@context/UserContext';

const CreateNewQuick = ({ isSidebarCollapsed, menuData }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { privilege } = useContext(UserContext);

    const toggleDropdown = () => setIsOpen(prev => !prev);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const dropdownItems = menuData?.filter(item => !item.privilege || item.privilege === privilege);

    return (
        <Container ref={dropdownRef}>
            <DropdownToggle onClick={toggleDropdown} isSidebarCollapsed={isSidebarCollapsed}>
                <Iconsax iconName="plus-square" fill="#FFF" size={16} />
                {!isSidebarCollapsed && <span className='ml-2'>Create</span>}
            </DropdownToggle>
            {isOpen && (
                <Dropdown isSidebarCollapsed={isSidebarCollapsed}>
                    {dropdownItems.map((item, index) => (
                        <DropdownItem key={index} to={item.to} activeClassName="active">
                            <Iconsax iconName={item.icon} fill="#697592" size={24} />
                            <DropdownText>{item.text}</DropdownText>
                            {item.isBeta && <BetaContainer><BetaComponent fontSize={10} /></BetaContainer>}
                        </DropdownItem>
                    ))}
                </Dropdown>
            )}
        </Container>
    );
};

const Container = styled.div`
  position: relative;
  margin: 0 16px;
`;

const DropdownToggle = styled.div`
  display: flex;
  padding: 12px 10px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: #0089C2;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  justify-content: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? 'center' : 'flex-start')};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  position: absolute;
  top: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '110%' : '100%')};
  left: 0;
  width: ${({ isSidebarCollapsed }) => (isSidebarCollapsed ? '300px' : '100%')};
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 1;
`;

const DropdownItem = styled(NavLink)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ isActive }) => (isActive ? '#F2F4F7' : 'transparent')};
  &:hover {
    border-radius: 4px;
    background: #F7F8F9;
  }
  &.active {
    background-color: #F2F4F7;
    border-radius: 6px;

    span {
        color: #0089C2;
    }
    .iconsax path[stroke],
    .iconsax rect[stroke] {
        stroke: var(--brand-color-green-600);
    }
    .iconsax path[fill] {
        fill: var(--brand-color-green-600);
    } 
    rect {
        stroke: var(--brand-color-green-600);
    }
  }
`;

const DropdownText = styled.span`
  color: ${({ isActive }) => (isActive ? '#0089C2' : '#475467')};
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
`;

const BetaContainer = styled.div`
    margin-left: auto;
`;

export default CreateNewQuick;