import { elementsConstants } from "@constants";
import { apiCaller } from "@utils";

// Case - Load IO Data
export const loadIODataForElement = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_IODATA_FOR_ELEMENT_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_IODATA_FOR_ELEMENT_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_IODATA_FOR_ELEMENT_FAILURE, error: error } }
}

// Case - Load
export const caseLoadById = (id) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'GET', null).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_CASE_FOR_ELEMENT_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_CASE_FOR_ELEMENT_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_CASE_FOR_ELEMENT_FAILURE, error: error } }
}

// Case - Update Timepoint - IO Elements
export const actUpdateValueBalanceForElements = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));

        //Load data
        const paramsLoad = {
          Action: "Load",
          Target: "IOData",
        };
        dispatch(loadIODataForElement(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
    function request(isLoading) {
      return { type: elementsConstants.UPDATE_VALUE_BALANCE_FOR_ELEMENTS_REQUEST, isLoading };
    }
    function success(data) {
      return { type: elementsConstants.UPDATE_VALUE_BALANCE_FOR_ELEMENTS_SUCCESS, payload: data };
    }
    function failure(error) {
      return { type: elementsConstants.UPDATE_VALUE_BALANCE_FOR_ELEMENTS_FAILURE, error: error };
    }
  };
};

//Update Timepoint - Vital Sign Change
export const actIntakeOutputChangeElements = (id, params, TimepointId) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data, TimepointId));
        //Load data
        const paramsLoad = {
          Action: "Load",
          Target: "IOData",
        };
        dispatch(loadIODataForElement(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: elementsConstants.INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_REQUEST, isLoading };
  }
  function success(data, TimepointId) {
    return {
      type: elementsConstants.INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_SUCCESS,
      payload: data,
      TimepointId: TimepointId
    };
  }
  function failure(error) {
    return {
      type: elementsConstants.INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_FAILURE,
      error: error,
    };
  }
};

// Case - Load Labs for elements
export const loadLabsForElement = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_LABS_FOR_ELEMENTS_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_LABS_FOR_ELEMENTS_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_LABS_FOR_ELEMENTS_FAILURE, error: error } }
}

// Load Chat Topics at All Timepoints
export const loadChatTopicsAllTimepoints = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_FAILURE, error: error } }
}

// Load Chat Topics at All Timepoints
export const loadStandardChatTopics = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_FAILURE, error: error } }
}

// Add Chat Topics To Case
export const addChatTopicsToCase = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));

        //Reload data
        const paramsLoad = {
          "Action": "Load",
          "Target": "Chat"
        }
        dispatch(loadChatTopicsAllTimepoints(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.ADD_CHAT_TOPICS_TO_CASE_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.ADD_CHAT_TOPICS_TO_CASE_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.ADD_CHAT_TOPICS_TO_CASE_FAILURE, error: error } }
}

//Delete Chat Topic in Case
export const deleteChatTopicInCase = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.DELETE_CHAT_TOPIC_IN_CASE_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.DELETE_CHAT_TOPIC_IN_CASE_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.DELETE_CHAT_TOPIC_IN_CASE_FAILURE, error: error } }
}

//Delete Chat Topic in Case For Free text
export const deleteChatTopicInCaseForFreeText = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.DELETE_CHAT_TOPIC_FREE_TEXT_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.DELETE_CHAT_TOPIC_FREE_TEXT_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.DELETE_CHAT_TOPIC_FREE_TEXT_FAILURE, error: error } }
}

// Update Chat Topic in Case
export const updateChatTopicInCase = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));

        //Reload data
        const paramsLoad = {
          "Action": "Load",
          "Target": "Chat"
        }
        dispatch(loadChatTopicsAllTimepoints(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.UPDATE_CHAT_TOPIC_IN_CASE_CASE_FAILURE, error: error } }
}

//Case - Load All Case Medications
export const loadAllCaseMedications = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_ALL_CASE_MEDICATIONS_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_ALL_CASE_MEDICATIONS_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_ALL_CASE_MEDICATIONS_FAILURE, error: error } }
}

//Case - Update Intervention Available For Medication - elements
export const updateAvailableForMedicationOfElement = (id, params) => {
  const isLoading = true;
  return dispatch => {
      dispatch(request(isLoading));
      return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
          if (res?.status === 200) {
              const data = res?.data;
              dispatch(success(data));
          } else {
              dispatch(failure("error"));
          }
      });
  };
  function request(isLoading) { return { type: elementsConstants.UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_REQUEST, isLoading } }
  function success(data) {
      return {
        type: elementsConstants.UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_SUCCESS, 
        payload: data
      }
  }
  function failure(error) { return { type: elementsConstants.UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_FAILURE, error: error } }
}

//Case - Load All Case - Actions
export const loadAllCaseActions = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: elementsConstants.LOAD_ALL_CASE_ACTIONS_REQUEST, isLoading } }
  function success(data) { return { type: elementsConstants.LOAD_ALL_CASE_ACTIONS_SUCCESS, payload: data } }
  function failure(error) { return { type: elementsConstants.LOAD_ALL_CASE_ACTIONS_FAILURE, error: error } }
}

//Case - Update Lab Change
export const actUpdateLabChangeForElements = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res?.data;
      if (data) {
        dispatch(success(data));

        //Load labs for elements
        const paramsLoad = {
          Action: "Load",
          Target: "Labs",
        };
        dispatch(loadLabsForElement(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return { type: elementsConstants.LAB_CHANGE_VALUE_ELEMENTS_REQUEST, isLoading };
  }
  function success(data) {
    return { type: elementsConstants.LAB_CHANGE_VALUE_ELEMENTS_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: elementsConstants.LAB_CHANGE_VALUE_ELEMENTS_FAILURE, error: error };
  }
};