import React from "react";

//components
import { FormLogin } from "@components/NusComponent/auth";

//boostrap
import { Container, Row } from "reactstrap";

function LoginNusPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <div className="loginPartnerContainer">
        <Container>
          <Row className="align-items-center">
            <FormLogin />
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LoginNusPage;