import React, { useEffect, useState } from "react";

//reactstrap
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    FormGroup,
    Input,
    Label
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//utils
import { apiCaller, LoaderText } from "@utils";
import AvatarSlider from "./AvatarSlider";

//constants
import { profileConstant } from "@constants";

function ModalAvatar(props) {
    const { isOpen, toggle, handleSubmitImageAvatar, photo_type } = props && props;
    const dispatch = useDispatch();

    //state
    const [stateData, setData] = useState({});
    const [isLoading, setLoading] = useState({
        get_all_avatars: false,
        create_account_avatar: false,
        turnOffAvatar: false
    });
    const [filterOptions, setFilterOptions] = useState({
        gender: '',
        roletype: '',
        level: ''
    });
    const stateOptions = {
        gender: [
            { name: 'Male', value: 'male' },
            { name: 'Female', value: 'female' }
        ],
        roletype: [
            { name: 'Doctor', value: 'doctor' },
            { name: 'Nurse', value: 'nurse' },
            { name: 'Pharmacist', value: 'pharmacist' }
        ],
        level: [
            { name: 'Beginner', value: 'beginner' },
            { name: 'Intermediate', value: 'intermediate' },
            { name: 'Advanced', value: 'advanced' }
        ]
    };

    // Get Avatar
    useEffect(() => {
        const fetchData = () => {
            const params = { "action_type": "get_all_avatars" };
            setLoading({ ...stateData, get_all_avatars: true });
            apiCaller(
                `/api/character/avatar/`,
                "POST",
                params
            ).then((res) => {
                const resData = res?.data;
                if (res?.status === 200) {
                    setData(resData);
                    setLoading({ ...stateData, get_all_avatars: false });
                } else {
                    console.log("error");
                    setLoading({ ...stateData, get_all_avatars: false });
                }
            });
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle Select Image
    const handleSelectImage = (value) => {
        setData({ ...stateData, current_avatar_id: value?.avatar_id });
    }

    // Handle Select Avatar
    const handleSaveAvatar = () => {
        const params = {
            "action_type": "create_account_avatar",
            "avatar_id": stateData?.current_avatar_id
        };

        setLoading({ ...stateData, create_account_avatar: true });
        dispatch({ type: profileConstant.CHANGE_AVATAR_PROFILE_REQUEST, isLoading: true });
        apiCaller(
            `/api/character/avatar/`,
            "POST",
            params
        ).then((res) => {
            const resData = res?.data;
            if (res?.status === 200) {
                dispatch({ type: profileConstant.CHANGE_AVATAR_PROFILE_SUCCESS, payload: resData });
                setLoading({ ...stateData, create_account_avatar: false });
                handleSubmitImageAvatar({ ...stateData });
                toggle();
            } else {
                dispatch({ type: profileConstant.CHANGE_AVATAR_PROFILE_FAILURE, error: "error" });
                setLoading({ ...stateData, create_account_avatar: false });
                toggle();
            }
        });
    }

    //
    const handleTurnOffAvatar = () => {
        const params = { "action_type": "turn_on_off_avatar" }
        setLoading({ ...stateData, turnOffAvatar: true });
        apiCaller(
            `/api/character/avatar/`,
            "POST",
            params
        ).then((res) => {
            const resData = res?.data;
            if (res?.status === 200) {
                dispatch({ type: profileConstant.CHANGE_AVATAR_PROFILE_SUCCESS, payload: resData });
                setLoading({ ...stateData, turnOffAvatar: false });
                toggle();
            } else {
                dispatch({ type: profileConstant.CHANGE_AVATAR_PROFILE_FAILURE, error: "error" });
                setLoading({ ...stateData, turnOffAvatar: false });
                toggle();
            }
        });
    }

    // Handle Change Filter
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [name]: value === 'all' ? '' : value.toLowerCase()
        }));
    };

    // Render Options
    const renderOptions = (type) => {
        return (
            stateOptions &&
            stateOptions[type]?.map((item, index) => (
                <option key={index} value={item.value}>
                    {item.name}
                </option>
            ))
        );
    };

    // Filter with options
    const filteredData = stateData?.all_avatars?.filter((item) => {
        const { tags } = item;

        if (Object.keys(filterOptions).length === 0) {
            return true;
        }

        for (const [name, value] of Object.entries(filterOptions)) {
            if (value !== "" && (!(name in tags) || tags[name] !== value)) {
                return false;
            }
        }

        return true;
    });
    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '700px' }}>
            <ModalHeader toggle={toggle}>Change Avatar</ModalHeader>
            <ModalBody className="position-relative avatar-slider">
                <Row className="border-bottom mb-4">
                    <Col md={4}>
                        <FormGroup>
                            <Label className="font-weight-500" for="gender">
                                Gender:
                            </Label>
                            <Input
                                id="gender"
                                name="gender"
                                type="select"
                                defaultValue={filterOptions.gender}
                                onChange={handleFilterChange}
                            >
                                <option value="all">All</option>
                                {renderOptions('gender')}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="font-weight-500" for="roletype">
                                Role:
                            </Label>
                            <Input
                                id="roletype"
                                name="roletype"
                                type="select"
                                defaultValue={filterOptions.roletype}
                                onChange={handleFilterChange}
                            >
                                <option value="all">All</option>
                                {renderOptions('roletype')}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label className="font-weight-500" for="level">
                                Level:
                            </Label>
                            <Input
                                id="level"
                                name="level"
                                type="select"
                                defaultValue={filterOptions.level}
                                onChange={handleFilterChange}
                            >
                                <option value="all">All</option>
                                {renderOptions('level')}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <AvatarSlider {...props} filteredData={filteredData}>
                    {filteredData?.map((avatar) => {
                        return (
                            <div key={avatar.avatar_id}>
                                <div className={`avatarItem border-grey-profile ${stateData?.current_avatar_id === avatar?.avatar_id ? 'active' : ''}`} onClick={() => handleSelectImage(avatar)} style={{ backgroundImage: `url(${avatar?.image_url})` }}></div>
                            </div>
                        )
                    })}
                </AvatarSlider>
                {isLoading?.get_all_avatars && (
                    <div style={{ height: '200px' }}>
                        <LoaderText />
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                {photo_type === "avatar" && (
                    <Button
                        color="primary"
                        className="btn btn-danger"
                        onClick={handleTurnOffAvatar}
                        disabled={isLoading?.turnOffAvatar || isLoading?.create_account_avatar}
                    >
                        {isLoading?.turnOffAvatar && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Turn Off Avatar
                    </Button>
                )}
                <Button
                    color="primary"
                    onClick={handleSaveAvatar}
                    disabled={isLoading?.turnOffAvatar || isLoading?.create_account_avatar}
                >
                    {isLoading?.create_account_avatar && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Save
                </Button>
                <Button color="secondary" onClick={toggle} disabled={isLoading?.turnOffAvatar || isLoading?.create_account_avatar}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalAvatar;