import React, { useEffect, useState } from 'react'
import { HomeGenerate } from '../MCQ'
import { useForm } from 'react-hook-form'
import * as Config from "@constants/Config";
import axios from "axios";

//partents
import { FormModal } from '@ui-partents/Modal';

const ModalMCQ = (props) => {
    //props
    const { isOpen, toggleModal } = props && props;

    //state
    // const [showChoice, setShowChoice] = useState(true)
    const [dataContentFile, setDataContentFile] = useState([])
    const [loadingResult, setLoadingResult] = useState(false)
    const [dataFileImported, setDataFileImported] = useState(null)
    const [listTemplate, setListTemplate] = useState([])

    useEffect(() => {
        // const params = {
        //     "action_type": "get_templates"
        // }
        // apiCaller(`/api/medicalcase/functional/`, "POST", params)
        //     .then(response => {
        //         const dataConvert = response.data?.map((temp) => {
        //             return {
        //                 value: temp?.template_id,
        //                 label: temp?.name,
        //             }
        //         })
        //         setListTemplate(dataConvert)
        //     })
        //     .catch(error => {
        //         setLoadingResult(false)
        //         console.error("Error in API call", error);
        //     });
    }, [])

    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch
    } = useForm()

    const handleUpload = (data, file) => {
        console.log(setListTemplate)
        setLoadingResult(true)
        const formData = new FormData();
        file && formData.append("document_file", file);
        formData.append("template_id", data?.template_id);
        formData.append("gpt_model", data?.gpt_model);
        formData.append("number_questions", "4");
        formData.append("prompt", data?.prompt);

        axios({
            method: "POST",
            url: `${Config.API_URL}/api/medicalcase/question-stream/`,
            data: formData,
            headers: {
                'Authorization': `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            onDownloadProgress: progressEvent => {
                const xhr = progressEvent.target
                const { responseText } = xhr
                let datasplit = responseText.split("event: question\ndata: ");
                setDataContentFile(
                    datasplit?.map((data, index) => {
                        return index > 0 && JSON.parse(data)
                    })
                )
            }
        }).then(function (response) {
            let datasplit = response.data.split("event: question\ndata: ");
            setDataContentFile(
                datasplit?.map((data, index) => {
                    return index > 0 && JSON.parse(data)
                })
            )
        }).catch(function (error) {
            console.error("Error in API call", error);
        })
            .finally(() => {
                setLoadingResult(false)
            })
    };

    const onSubmit = (data) => {
        handleUpload(data, dataFileImported)
    }


    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={`AI-generated Multiple Choice Question`}
            actionText={"Save"}
            maxWidth="655px"
        //   onAction={() => handleUpload()}
        //   isLoading={isLoading}
        //   actionDisabled={acceptedFiles?.length === 0}
        >
            <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
                <HomeGenerate
                    loadingResult={loadingResult}
                    register={register}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    setDataFileImported={setDataFileImported}
                    dataFileImported={dataFileImported}
                    dataContentFile={dataContentFile}
                    listTemplate={listTemplate}
                />
            </form>
        </FormModal>
    );
};

export default ModalMCQ;