import React from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import { Input } from 'reactstrap'

const WrapItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #D0D5DD;
  cursor: pointer;
  &:hover {
    background: #F5FCFF;
    border: 1px solid #0089C2;
  }
  &:active {
    background: #FFF;
    box-shadow: 0px 0px 0px 4px rgba(0, 110, 155, 0.12);
  }
  .wrap-content {
    display: flex;
    align-items: center;
    gap: 12px;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }
  &.disabled {
    pointer-events: none;
    background: #F7F8F9;
    border: 1px solid #D0D5DD;
    background: #F9FAFB;
    p {
      color: #98A2B3;
    }
  }
  &.active {
    border: 1px solid #0089C2;
    background: #FFF;
  }
`

export default function ItemsChose(props) {
  const { icon, title, isCheck, disabled, handleCheck } = props

  return (
    <WrapItems 
      className={`${disabled ? "disabled" : ""} ${isCheck ? "active" : ""}`}
      onClick={handleCheck}
    >
      <div className='wrap-content'>
        <Icon src={icon} stroke={disabled ? "#98A2B3" : "auto"} />
        <p>{title}</p>
      </div>
      <div className='wrap-check'>
        <Input className={`react-checkbox-common`} type="checkbox" checked={isCheck} name="checkbox"/>
      </div>
    </WrapItems>
  )
}
