import React from "react";
import { ProblemComponent } from "./componentProblem";

const ProblemsDocumentation = (props) => {
  return (
    <React.Fragment>
      <ProblemComponent {...props}/>
    </React.Fragment>
  );
};

export default ProblemsDocumentation;
