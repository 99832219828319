import { roleConstant } from "@constants";

//Load Virtual Preceptor Status
export function statusRoleSystem(state = {}, action) {
  switch (action.type) {
    case roleConstant.CURRENT_ROLE_ACTIVE:
      return {
        ...state,
        roleActive: action.payload
      };
    default:
      return state;
  }
}