import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import { actUpdateSelfAssessment } from "@actions";

function SelfReflection(props) {
  const { caseData } = props && props;
  const resData = caseData && caseData.data;
  const SelfAssessmentRequired = resData && resData.SelfAssessmentRequired;
  const [status, setStatus] = useState((SelfAssessmentRequired && SelfAssessmentRequired) ||false);

  let { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if(resData) {
      setStatus((SelfAssessmentRequired && SelfAssessmentRequired) || false);
    }
  }, [SelfAssessmentRequired, resData]);

  const handleChange = (e) => {
    const { checked } = e.target;
    const params = {
      Action: "Update",
      Target: "SelfAssessment",
      SelfAssessmentRequired: checked,
    };
    setStatus(checked);
    dispatch(actUpdateSelfAssessment(id, params));
  };

  return (
    <div className="d-flex align-items-center mb-4">
      <div className="switches">
        <input
          type="checkbox"
          onChange={handleChange}
          name="SelfAssessment"
          defaultChecked={status || false}
          id={`SelfAssessment`}
        />
        <label htmlFor={`SelfAssessment`}>
          <span />
        </label>
      </div>
      <span className="ml-3 font-weight-500">Self-reflection required</span>
    </div>
  );
}

export default SelfReflection;
