import {
    PlanPage,
    PlanErrorPage
} from "@pages/med2lab/plan";

const planRoutes = [
    // plan
    {
        path: "/plan",
        exact: true,
        classPage: '',
        component: PlanPage,
        isLayout: true,
    },
    {
        path: "/plan/error",
        exact: true,
        classPage: '',
        component: PlanErrorPage,
        isLayout: true,
    }
];

export { planRoutes };