import React, { useEffect, useState } from "react";
import { Container, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";

//common
import { DataEmty } from "@components/common";

// common
import { NavigationComponent } from "@components/layouts";

//components
import { ReLiPdfContent, PdfUpload } from "@components/reliPdf";
import { ModalDeleteCommon } from "@ui-partents/Modal";

//menu
import navigation from "@utils/navigation";

//untils
import { messageData, LoaderPage } from "@utils";

//api
import { apiCaller } from "@utils";

//elements
import { ButtonDelete } from '@components/common/elements';

//format time
import moment from "moment";

// Import the pagination configuration
import {paginationOptions} from "@utils";

function ReliListPage(props) {
    //state
    const [stateData, setData] = useState([]);
    const [dataDetails, setDetails] = useState(null);
    const [isLoading, setLoading] = useState(null);
    const [isLoad, setLoad] = useState(false);
    const [isViewUpload, setViewUpload] = useState(false);
    const [dataCurrent, setDataCurrent] = useState({});
    const [modal, setModal] = useState({
        delete: false,
    });

    // Handle Modal
    const toggleModal = (type, value) => {
        setModal((prevState) => ({
            ...prevState,
            [type]: !modal[type]
        }));
        setDataCurrent(value);
    };

    // Remove Item 
    const deleteTableRows = () => {
        const params = {
            "id": dataCurrent?.id,
            "action_type": "delete"
        }
        setLoading(true);
        apiCaller(`/api/binh/reli/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoading(false);
                setData(data);
                setModal((prevState) => ({ ...prevState, delete: false }));
            } else {
                console.log('error');
                setLoading(false);
                setModal((prevState) => ({ ...prevState, delete: false }));
            }
        });
    }

    const columns = [
        {
            dataField: "Id",
            text: "ID",
            hidden: true,
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "name",
            text: "Name",
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "created_at",
            text: "Created at",
            headerClasses: "--text-primary font-weight-500",
            formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
        },
        {
            dataField: "Actions",
            text: "Actions",
            editable: false,
            headerClasses: "--text-primary text-center font-weight-500",
            classes: "align-middle text-center text-nowrap",
            headerStyle: (colum, colIndex) => {
                return { width: "150px" };
            },
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        <Button
                            className="btn btn-brand btn-sm"
                            color="primary"
                            onClick={() => setDetails(row)}
                        >
                            <i className="fa fa-eye mr-2" aria-hidden="true"></i> Details
                        </Button>
                        <ButtonDelete handleDelete={() => {
                            setModal((prevState) => ({
                                ...prevState,
                                delete: true
                            }));
                            setDataCurrent(row);
                        }} />
                    </React.Fragment>
                );
            },
        }
    ];

    // Load Page and call api lis
    useEffect(() => {
        setLoad(true);
        const fetchData = () => {
            const params = { "action_type": "list_all" }
            //Call Api Load Text Item
            apiCaller(`/api/binh/reli/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                    setLoad(false);
                } else {
                    console.log("error....");
                    setLoad(false);
                }
            })
        }
        fetchData();
    }, [])

    const fetchData = () => {
        const params = { "action_type": "list_all" }
        //Call Api Load Text Item
        apiCaller(`/api/binh/reli/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);
                setLoad(false);
            } else {
                console.log("error....");
                setLoad(false);
            }
        })
    }

    const contentTable = ({ paginationProps, paginationTableProps }) => {
        return (
            <React.Fragment>
                <ToolkitProvider
                    keyField="id"
                    data={stateData || []}
                    columns={columns}
                    search
                >
                    {(toolkitprops) => (
                        <BootstrapTable
                            condensed
                            striped
                            wrapperClasses="table-responsive"
                            bordered={true}
                            noDataIndication={() => <DataEmty content={messageData?.filter} />}
                            {...paginationTableProps}
                            {...toolkitprops.baseProps}
                        />
                    )}
                </ToolkitProvider>
                <div className="pagination-custom-case d-flex justify-content-center mt-4">
                    <PaginationListStandalone {...paginationProps} />
                </div>
            </React.Fragment>
        )
    };

    const mainMenu = navigation?.mainDiagnosis;

    const totalSize = stateData?.length || 0; // Dynamically calculate the total size
  
    // Use the paginationOptions function to get the configuration
    const paginationConfig = paginationOptions({ totalSize });
    return (
        <div className="wrapper -pdfDemoPage position-relative">
            {isLoad && <LoaderPage />}
            <NavigationComponent data={mainMenu} />
            {isViewUpload && (
                <PdfUpload
                    {...props}
                    handleBackList={
                        () => {
                            setViewUpload(false);
                            fetchData();
                        }
                    }
                />
            )}
            {!isViewUpload && (
                <React.Fragment>
                    {!dataDetails && (
                        <Container className="p-4">
                            <div className="boxPortlet">
                                <div className="text-right">
                                    <Button
                                        color="primary"
                                        className="btn btn-primary mb-3"
                                        onClick={() => setViewUpload(true)}
                                    >
                                        <span className="fa fa-upload mr-2"></span>
                                        Upload File
                                    </Button>
                                </div>
                                <PaginationProvider pagination={paginationFactory(paginationConfig)}>
                                    {contentTable}
                                </PaginationProvider>
                            </div>
                        </Container>
                    )}
                    {dataDetails && (
                        <ReLiPdfContent
                            {...props}
                            rowData={dataDetails}
                            listData={stateData}
                            handleBackList={
                                () => {
                                    fetchData();
                                    setDetails(null);
                                }
                            }
                        />
                    )}
                </React.Fragment>
            )}
            <ModalDeleteCommon
                title={`Are you sure you want to delete team ${dataCurrent?.name}`}
                sub={`This action is not reversible.`}
                isOpen={modal?.delete}
                toggle={() => toggleModal('delete', dataCurrent)}
                handleRemove={deleteTableRows}
                isLoading={isLoading}
            />
        </div>
    );
}

export default ReliListPage;
