import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ProgressBarTimePoint } from "..";
import { CaseTasksSection } from ".";

function MenuTaskColumn(props) {
  //props
  const { handleOverideEffectMenu } = props && props;

  //get question
  const questionData = useSelector((state) => state?.question?.data || {});
  let isQuestion = questionData?.Question;

  //Check Remove and add effects
  useEffect(() => {
    if (isQuestion !== null) {
      handleOverideEffectMenu();
    }
  }, [handleOverideEffectMenu, questionData, isQuestion]);

  // Check if the device is mobile
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <React.Fragment>
      {!isMobile && <ProgressBarTimePoint {...props} />}
      <CaseTasksSection {...props} heading={`Case Tasks`} />
    </React.Fragment>
  );
}

export default MenuTaskColumn;