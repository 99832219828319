import React, { useState } from "react";

//partents
import { ModalDeleteCommon } from "@ui-partents/Modal";

//redux
import { useDispatch } from "react-redux";

//actions
import { atcDeleteCohort } from "@actions";

function ModalDeleteCohorts(props) {
  // props
  const { isOpen, toggle, cohortObject, reloadDataAfterDelete } = props;
  const dispatch = useDispatch();

  // local state for loading
  const [isLoading, setIsLoading] = useState(false);

  const hanldeRemove = () => {
    // Set loading state to true when starting the delete process
    setIsLoading(true);

    const params = {
      Action: "Delete",
      CohortId: cohortObject?.CohortId,
    };

    // Dispatch delete action
    dispatch(atcDeleteCohort(params))
      .then(res => {
        // Check if the response is successful
        if (res?.status === 200) {
          toggle(); // Close modal
          reloadDataAfterDelete(); // Reload data after delete
        }
      })
      .catch(error => {
        // Handle error
        console.error('Error deleting cohort', error);
        setIsLoading(false);
      })
      .finally(() => {
        // Set loading state back to false once the process is done
        setIsLoading(false);
      });
  };

  return (
    <ModalDeleteCommon
      title={`Delete Cohort`}
      sub={`Are you sure you want to delete this Cohort? This action cannot be undone.`}
      isOpen={isOpen}
      toggle={toggle}
      handleRemove={hanldeRemove}
      isLoading={isLoading}
      hideIcon={true}
    />
  );
}

export default ModalDeleteCohorts;