import React from 'react';
import styled from 'styled-components';

//IonERM
import IconErm from '@images/learnerv2/icon-erm-sp.svg';
import IconCloseChat from '@images/learnerv2/icon-close-sp.svg';

const HeaderChatWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 0;
    padding: 0 16px;
`;

const ErmButton = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background: #CDF1FF;
    position: relative;
    &::before {
        content: url(${IconErm});
        height: 24px;
        width: 18px;
    }
`;

const RoleChatWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RoleChatList = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
`;

const RoleChatItem = styled.li`
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid #475467;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 100%), lightgray 50% / cover no-repeat;
    margin-right: 8px;

    &.active {
        box-shadow: 0px 0px 0px 4px rgba(0, 110, 155, 0.12);
        border: 2.143px solid var(--Base-Green-500, #16B364);
        background: url(<path-to-image>) lightgray 50% / cover no-repeat;
    }
`;

const CloseButton = styled.div`
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    background: #fff;
    border-radius: 50%;

    &::before {
        content: url(${IconCloseChat});
        width: 12px;
        height: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const HeaderChat = () => {
    return (
        <HeaderChatWrapper>
            <ErmButton />
            <RoleChatWrapper>
                <RoleChatList>
                    <RoleChatItem className="role-chat__item"></RoleChatItem>
                    <RoleChatItem className="role-chat__item active"></RoleChatItem>
                    <RoleChatItem className="role-chat__item"></RoleChatItem>
                </RoleChatList>
            </RoleChatWrapper>
            <CloseButton />
        </HeaderChatWrapper>
    );
};

export default HeaderChat;