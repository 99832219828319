import React from "react";
import { Alert } from "reactstrap";

function Overview(props) {
  const data = props && props.data;
  return (
    <div className="overviewCt">
      <Alert
        color="success"
        className="m-alert--outline m-alert-success bg-white mt-3"
      >
        <p className="mb-3 font-weight-500 m--font-success">
          Thank you for completing this case!
        </p>
        <p className="font-weight-400 mb-2">
          You will find on this page detail feedbacks on your most recent
          performance, as well as the past performances if you have tried this
          case before.{" "}
        </p>
        <p className="font-weight-400">
          Your performance is assessed across different "domains", which
          represent different aspects of critical reasoning. To review your
          performance related to each of the domains, click on the domain name
          in the panel on the left.
        </p>
      </Alert>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr className="m--font-brand">
              <th></th>
              <th className="text-center font-weight-500">Your Score (%)</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((value, index) => {
                const summaryItem = value.Summary;
                return (
                  <tr key={index}>
                    <td className="font-weight-500 text-left">{value.Name}</td>
                    <td className="text-center">
                      {summaryItem && summaryItem.UserScore}/{summaryItem && summaryItem.TotalScore}{" "}
                      <span className="m--font-info">
                        ({summaryItem && summaryItem.PercentageScore}%)
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Overview;
