import React, { useState } from "react";

//reactstrap
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function DropdownAddUser(props) {
  //props
  const { handleChangeActions } = props && props;

  //state
  const [dropdownOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!dropdownOpen);

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle className="--bg-primary" caret>
        <i className="fa fa-plus mr-2" aria-hidden="true"></i> Add User (s)
      </DropdownToggle>
      <DropdownMenu container="body">
        <DropdownItem onClick={() => handleChangeActions("invite")}>
          Add new users
        </DropdownItem>
        <DropdownItem onClick={() => handleChangeActions("importExel")}>
          Import from Excel
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default DropdownAddUser;
