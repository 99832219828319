import React from 'react';

//boostrap
import {
    Nav,
    NavItem,
    NavLink,
    Badge
} from "reactstrap";

//classnames
import classnames from "classnames";

function PortfolioNavItem(props) {
    const { activeTab, filteredCaseData, filteredSeriesData, toggle } = props && props;
    return (
        <Nav className="tabsGreen" tabs>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                        toggle("1");
                    }}
                >
                    Cases
                    <Badge
                        className="m-badge m-badge--metal m-badge--wide"
                        color="secondary"
                    >
                        {filteredCaseData && filteredCaseData.length}
                    </Badge>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                        toggle("2");
                    }}
                >
                    Series
                    <Badge
                        className="m-badge m-badge--metal m-badge--wide"
                        color="secondary"
                    >
                        {filteredSeriesData && filteredSeriesData.length}
                    </Badge>
                </NavLink>
            </NavItem>
        </Nav>
    );
};

export default PortfolioNavItem;