import React from 'react';

//boostrap
import { Button } from "reactstrap";

function BtnDeleteRole(props) {
    const { hanldeModal, action, disabled, data } = props && props;
    return (
        <Button
            className="btn btn-danger"
            onClick={() => (hanldeModal(`Delete`, data))}
            disabled={(action === 'Delete' || action === 'Create') ? disabled : false}
        >
            <i className="fa fa-times" aria-hidden="true"></i>
        </Button>
    );
};

export default BtnDeleteRole;