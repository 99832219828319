import React from "react";

//reactstrap
import { FormGroup, Label, Col, Input } from "reactstrap";

function DropDownMedia (props) {
  const value = props && props.value;
  const onChange = props && props.onChange;

  return (
    <FormGroup row>
      <Label className="text-left" for="MediaType" sm={3}>
        Media type
      </Label>
      <Col sm={9}>
        <Input
          type="select"
          value={value || ""}
          name="MediaType"
          onChange={onChange}
        >
          <option value="">None</option>
          <option value="ImageFile">Image</option>
          <option value="VideoLink">Youtube</option>
          <option value="AudioFile">Audio</option>
          <option value="VideoFile">Video</option>
        </Input>
      </Col>
    </FormGroup>
  );
}

export default DropDownMedia;
