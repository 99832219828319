import React from "react";
import { Link } from "react-router-dom";
import { Iconsax } from "@components-common";

const FormHeader = ({ title, url = '/', disabled = false }) => {
    return (
        <div className="headingPage py-2 border-bottom">
            <div className="d-flex align-items-center">
                <Link to={disabled ? '#' : url} onClick={disabled ? (e) => e.preventDefault() : undefined}>
                    <Iconsax iconName="back" fill={`#101828`} size={37} />
                </Link>
                <h2 style={{ pointerEvents: disabled ? 'none' : 'auto' }}>{title}</h2>
            </div>
        </div>
    );
};

export default FormHeader;