import { ticketConstant } from "@constants";

export function submitTicket (state = {}, action) {
    switch (action.type) {
        case ticketConstant.SUBMIT_TICKET_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case ticketConstant.SUBMIT_TICKET_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ticketConstant.SUBMIT_TICKET_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadTicket (state = {}, action) {
    switch (action.type) {
        case ticketConstant.GET_TICKET_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case ticketConstant.GET_TICKET_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ticketConstant.UPDATE_TICKET_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case ticketConstant.GET_TICKET_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};