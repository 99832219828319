import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

//boostrap
import { Card, CardBody, Label, FormGroup, Input, Form } from "reactstrap";

//api
import { apiCaller } from "@utils";

function ViewMode(props) {
    let { id } = useParams();
    const { data, timePointId } = props && props;

    const [isStatus, setStatus] = useState(data?.PNOrderViewMode || '');

    //load Data
    useEffect(() => {
        setStatus(data?.PNOrderViewMode || '');
    }, [data])

    const handleChange = (e) => {
        const { value } = e.target;
        const boolValue = (/true/i).test(value)
        const stringValue = boolValue ? "show-top-only" : "default";
        setStatus(stringValue);
        handleUpdateApi(stringValue);
    }

    const handleUpdateApi = (stringValue) => {
        const params = {
            "Action": "Update",
            "Target": "TimePoint",
            "TimePointId": timePointId,
            "PNOrderViewMode": stringValue
        }

        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                console.log('successful');
            } else {
                console.log('error');
            }
        });
    }

    console.log(isStatus, "isStatus...");
    return (
        <Card className="border-0 mb-4">
            <CardBody className="-box-shadow">
                <h2 className="heading-5 font-weight-500 --text-primary mt-0">Team Assignment Mode</h2>
                <Form>
                    <FormGroup
                        check
                        inline
                    >
                        <Label className="d-flex align-items-center" check>
                            <Input
                                type="radio"
                                name='PNOrderViewMode'
                                value={false}
                                checked={isStatus === 'default'}
                                onChange={handleChange}
                            />
                            Default
                        </Label>
                    </FormGroup>
                    <FormGroup
                        check
                        inline
                    >
                        <Label className="d-flex align-items-center" check>
                            <Input
                                type="radio"
                                name='PNOrderViewMode'
                                value={true}
                                checked={isStatus === 'show-top-only'}
                                onChange={handleChange}
                            />
                            Show top portion only
                        </Label>
                    </FormGroup>
                </Form>
            </CardBody>
        </Card>
    );
};

export default ViewMode;