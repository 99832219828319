import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteCommon(props) {
  const { isOpen, toggle, data, handleConfirm } = props && props;
  const InvitedEmails = data && data.InvitedEmails;
  const AddedUsers = data && data.AddedUsers;

  return (
    <Modal
      style={{ maxWidth: "700px" }}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalBody>
        <div className="enrollBlock">
          {InvitedEmails && InvitedEmails.length > 0 && (
            <div className="enrollBlock__item">
              <h3 className="font-weight-500 mb-3">
                The following accounts have been enrolled into the case:
              </h3>
              <ul className="mailEnroll">
                {InvitedEmails.map((value) => (
                  <li className="mailEnroll__item">{value}</li>
                ))}
              </ul>
            </div>
          )}
          {AddedUsers && AddedUsers.length > 0 && (
            <div className="enrollBlock__item">
              <h3 className="font-weight-500 mb-3">
                Email have been sent to the following learners to invite them to
                enroll in the case:
              </h3>
              <ul className="mailEnroll">
                {AddedUsers.map((value) => (
                  <li className="mailEnroll__item">{value}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ minWidth: "150px" }}
          className="--btn-primary mx-auto"
          onClick={handleConfirm}
        >
          OK
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteCommon;
