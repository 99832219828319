import React from 'react';
import {
    Input,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap';

const InputDifferentHeight = () => {
    return (
        <Card className='my-2'>
            <CardHeader>
                Different Height
            </CardHeader>
            <CardBody>
                <Input size="lg" type="text" placeholder="Large input" /><br />
                <Input type="text" placeholder="Default input" /><br />
                <Input size="sm" type="text" placeholder="Small input" />
            </CardBody>
        </Card>
    );
};

export default InputDifferentHeight;