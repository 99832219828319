import React, { useEffect, useState } from "react";

//router
import { useParams } from "react-router-dom";

//components
import { DiagnosticMain, ModalTestSchema } from '@components/schemav2';

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { getSchemaList, loadQuestionDetailForSchema } from "@actions";

//untils
import { LoaderText, useQuery } from "@utils";

function QuestionSchema(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  let query = useQuery();
  let QuestionId = query.get("QuestionId");

  const [modal, setModal] = useState({
    testSchema: false
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  //Load Data by Slyg
  useEffect(() => {
    const fetchData = () => {
      const params = {
        "Action": "LoadSchema",
        "QuestionId": QuestionId
      }
      dispatch(getSchemaList(params));
    };

    const loadQuestionById = () => {
      const params = {
        "Action": "Load",
        "Target": "Question",
        "QuestionId": QuestionId
      }
      dispatch(loadQuestionDetailForSchema(id, params));
    };

    fetchData();
    loadQuestionById();
  }, [dispatch, QuestionId, id]);

  //get data store
  const resSchema = useSelector((state) => state.dataSchema || {});
  const questionData = useSelector((state) => state?.dataQuestionForSchema?.data || {});
  const dataTestQuestion = useSelector((state) => state?.dataTestQuestion?.data || {});
  const dataRes = resSchema?.data;
  const QuestionContent = questionData?.QuestionContent;
  
  return (
    <React.Fragment>
      <div className="position-relative">
        <div
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: QuestionContent || ''
          }}
        />
        {resSchema.isLoading && (
          <div style={{ height: '400px' }}>
            <LoaderText />
          </div>
        )}
        <DiagnosticMain
          QuestionId={QuestionId}
          data={dataRes}
          toggleTestSchema={() => toggle('testSchema')}
        />
      </div>
      <ModalTestSchema
        isOpen={modal.testSchema}
        toggleTestSchema={() => toggle('testSchema')}
        QuestionId={QuestionId}
        data={dataTestQuestion}
      />
    </React.Fragment>
  );
}

export default QuestionSchema;