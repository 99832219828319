import React from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

const ModalSeries = ({...props}) => {
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;
  const handleAddSeries = props && props.handleAddSeries;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm();

  const onsubmit = (data) => {
    const params = {
      Action: "Create",
      Title: data.Title,
      Descriptions: data.Descriptions,
    };
    handleAddSeries(params);

    //reset Data
    reset({ keepIsValid: false, keepValues: false });
    setValue("Title", '');
    setValue("Descriptions", '');
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Series</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label htmlFor="Title">Title: <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Title"
              {...register("Title", { required: true })}
              onChange={(e) => {
                setValue("Title", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("Title")}
              invalid={errors.Title?.type === "required"}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback invalid={String(errors.Title?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label htmlFor="Descriptions">Description: <span className="m--font-danger">*</span></Label>
            <Input 
                type="textarea"
                name="Descriptions"
                {...register("Descriptions", { required: true })}
                onChange={(e) => {
                  setValue("Descriptions", e.target.value, { shouldValidate: true });
                }}
                defaultValue={watch("Descriptions")}
                invalid={errors.Descriptions?.type === "required"}
            />
            <FormFeedback invalid={String(errors.Descriptions?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button 
            color="primary"
            onClick={handleSubmit(onsubmit)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalSeries;