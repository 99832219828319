import React from "react";
import { Col, FormGroup, Label, Button } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import PromptTemplates from "./PromptTemplates";

function TextAreaInstruction(props) {
    const {
        handleChangeInstruction,
        stateInstruction,
        loaderModal,
        toggle,
    } = props;

    return (
        <Col md={6}>
            <FormGroup>
                <Label className="font-weight-500" for="Comment">Feedback Instruction:</Label>
                <TextareaAutosize
                    name="AI_Instructions"
                    placeholder="Enter your Feedback Instruction..."
                    className="w-100 form-control py-3"
                    maxRows="10"
                    minRows={7}
                    onChange={handleChangeInstruction}
                    value={stateInstruction?.AI_Instructions?.Text || ''}
                />
                <div className="mt-4 text-right">
                    <Button
                        className="btn --btn-primary d-flex align-items-center ml-auto"
                        onClick={() => toggle("AI_Instructions")}
                        disabled={loaderModal?.AI_Instructions}
                        color="primary"
                    >
                        {loaderModal?.AI_Instructions && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </div>
            </FormGroup>
            <PromptTemplates {...props} />
            <FormGroup className="mt-2">
                <Label className="font-weight-500" for="Comment">Scoring Instruction:</Label>
                <TextareaAutosize
                    name="Scoring_Instructions"
                    placeholder="Enter your Scoring Instruction..."
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    onChange={handleChangeInstruction}
                    value={stateInstruction?.Scoring_Instructions?.Text || ''}
                />
                <div className="mt-4 text-right">
                    <Button
                        className="btn --btn-primary d-flex align-items-center ml-auto"
                        onClick={() => toggle("Scoring_Instructions")}
                        color="primary"
                    >
                        {loaderModal?.Scoring_Instructions && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </div>
            </FormGroup>
        </Col>
    );
}

export default TextAreaInstruction;