import React from "react";

//Icon
import { Iconsax } from "@components-common";

function BtnIconDeleteV2({ handleDelete }) {
  return (
    <div className="action-btn-table" onClick={handleDelete}>
      <Iconsax iconName="trash-2" fill="#F04438" size={24} />
    </div>
  );
}

export default BtnIconDeleteV2;