import React, { useState } from 'react'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import stick from '@images/teachV2/stick-btn.svg'
import { useLocation } from 'react-router-dom'

const GenerateItem = (props) => {
  const location = useLocation();
  const [showData, setShowData] = useState(!!location.state?.listCase ? false : true)
  const { 
    contentGenerate, 
    dataCaseScript, 
    handleGenerateSlides, 
    loadingGenerateScript, 
    handleSave,
    dataRef,
    // index,
    // toggleModal,
    // setDataFeedback,
    watch,
  } = props

  const handleShowData = () => {
    setShowData(!showData)
  }

  return (
    <>
      <div className='generateItem'>
        <div className="generateItem__header w-100">
          <div className="generateItem__header-title">
            <div className="icon-show" onClick={handleShowData}>
              {showData ? <Icon src={Up}/> : <Icon src={Down}/>}
            </div>
            <p>{'Requirements and Learning Objectives'}</p>
          </div>
        </div>
        {showData && (
          <div className='w-100'>
            <div className="resultComponent__required w-100">
              <div className="resultComponent__required-header">
                <p>Requirements:</p>
              </div>
              <p className="resultComponent__required-content">{watch('prompt')}</p>
            </div>
            <p className="generateItem__title">{contentGenerate?.title ? contentGenerate?.title : 'Learning Objectives:'}</p>
            <div
              ref={dataRef}
              id="htmlContainer"
              onBlur={() => handleSave(dataRef)}
              className="generateItem__content"
              contentEditable
              dangerouslySetInnerHTML={{ __html: contentGenerate?.replace(/\n/g,"<br />") || '' }}>
            </div>
          </div>
        )}
      </div>
      {!loadingGenerateScript && !dataCaseScript?.slides && (
        <div className="caseGenerate">
          <p>Once you’re satisfied with the above Learning Objectives, click here to generate the next elements of the Teaching Slides</p>
          <div 
            onClick={() => {
              setShowData(false)
              handleGenerateSlides()}
            } 
            className={`caseGenerate-btn`}
          >
            <Icon src={stick}/>
            Generate Slides
          </div>
        </div>
      )}
      {loadingGenerateScript && 
        <div className="generateItem w-100">
          <Skeleton height={20} width={`100%`} />
          <Skeleton height={20} width={`75%`} />
          <Skeleton height={20} width={`60%`} />
          <Skeleton height={20} width={`80%`} />
          <Skeleton height={20} width={`55%`} />
        </div>
      }
    </>
  )
}

export default GenerateItem
