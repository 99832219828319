import axios from 'axios';
import { API_URL } from "@constants/Config";
import { camelizeKeys } from 'humps';
import { getActionTypes } from 'redux-axios-middleware';

export const apiClients = {
  default: {
    client: axios.create({
      baseURL: API_URL,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': -1,
        'Authorization': `Token ${localStorage.getItem('token')}`
      },
      transformResponse: [function (data) {
        return camelizeKeys(typeof data !== 'object' ? JSON.parse(data) : data)
      }]
    })
  }
}

export const apiMiddlewareConfig = {
  onSuccess: ({ action, next, response }, options) => {
    const nextAction = {
      type: getActionTypes(action, options),
      payload: response,
      meta: {
        previousAction: action
      }
    }
    next(nextAction)
    return nextAction
  },
  onError: ({ action, next, error, dispatch }, options) => {
    let errorObject
    if (error && !error.response) {
      errorObject = {
        data: error.message,
        status: 0
      }
      if (process.env.NODE_ENV !== 'production') {
        console.log('HTTP Failure in Axios', error)
      }
    } else {
      errorObject = error
    }
    const nextAction = {
      type: getActionTypes(action, options),
      error: errorObject,
      meta: {
        previousAction: action
      }
    }
    next(nextAction)
    return nextAction
  }
}
