import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormGroup, Label, Input, FormFeedback, Button, Alert } from "reactstrap";
import { Iconsax } from "@components-common";
import { apiCaller } from "@utils";
import { useDispatch } from "react-redux";
import { actionLogin } from "@actions";
import { useHistory } from "react-router-dom";

const LoginForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (values, { setSubmitting }) => {
        try {
            const formData = new FormData();
            formData.append("email", values.email);
            formData.append("password", values.password);
            formData.append("app", "web_admin_makansafe");
            
            //Truyền true hàm apiCaller để sử dụng api makansafe
            const response = await apiCaller("/api/iddsi/admin_login/", "POST", formData, true);
            const resData = response?.data;

            if (response?.status === 200) {
                if (resData.status === "Login Successfully!") {
                    dispatch(actionLogin(resData));
                    history.push("/"); // Redirect after successful login
                } else {
                    setErrorMessage("Invalid login credentials."); // Set error message
                }
            } else {
                setErrorMessage(resData.status); // Set other error message
            }
        } catch (error) {
            setErrorMessage("Unauthorized. Please check your credentials."); // Set generic error message
            console.log("Error:", error.message);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
                const errors = {};
                if (!values.email) {
                    errors.email = "Please enter your email.";
                }
                if (!values.password) {
                    errors.password = "Please enter your password.";
                }
                return errors;
            }}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, errors }) => (
                <Form>
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <FormGroup>
                        <Label className="ms-label" for="email">Email</Label>
                        <div className="d-flex position-relative">
                            <Field
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                as={Input}
                                invalid={!!errors.email}
                            />
                            {!errors.email && (
                                <div className="icn-close-email icon-for-input">
                                    <Iconsax iconName="x-circle" fill="#98A2B3" size={16} />
                                </div>
                            )}
                        </div>
                        <ErrorMessage name="email" component={FormFeedback} className="d-block" />
                    </FormGroup>
                    <FormGroup>
                        <Label className="ms-label" for="password">Password</Label>
                        <div className="d-flex position-relative">
                            <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                id="password"
                                placeholder="Enter your password"
                                as={Input}
                                invalid={!!errors.password}
                            />
                            {!errors.password && (
                                <div className="icn-view-pass icon-for-input" onClick={togglePasswordVisibility}>
                                    <Iconsax iconName={showPassword ? "eye" : "eye-off"} fill="#98A2B3" size={16} />
                                </div>
                            )}
                        </div>
                        <ErrorMessage name="password" component={FormFeedback} className="d-block" />
                        <div className="forgot-pass text-right my-2">
                            <a href="#/">Forgot password?</a>
                        </div>
                    </FormGroup>
                    <Button
                        color="primary"
                        className="btn-brand-sm btn-lg w-100 shadow"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Logging in..." : "Login"}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;