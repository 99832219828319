// AvatarPage.js
import React from 'react';
import { Row, Col } from 'reactstrap';

// UI Components
import { AvatarImage, AvatarGroup } from '@ui-partents/Avatar';

// Sample data for AvatarGroup demo
const sampleAvatarData = [
    {
        "FirstName": "Bao",
        "LastName": "Truong",
        "Email": "bao@med2lab.com",
        "ProfilePhotoURL": "https://med2lab-frontend-media.s3.amazonaws.com/media/profile_photo/blob_QTGpLgO"
    },
    {
        "FirstName": "",
        "LastName": "User",
        "Email": "ha.nguyen1@med2lab.com",
        "ProfilePhotoURL": "https://med2lab-frontend-media.s3.amazonaws.com/media/profile_photo/blob_QTGpLgO"
    },
    {
        "FirstName": "Ha3",
        "LastName": "Nguyen",
        "Email": "ha.nguyen3@med2lab.com",
        "ProfilePhotoURL": "https://med2lab-frontend-media.s3.amazonaws.com/media/profile_photo/blob_QTGpLgO"
    },
    {
        "FirstName": "Anonymous",
        "LastName": "User",
        "Email": "hanguyen3@yopmail.com",
        "ProfilePhotoURL": "https://med2lab-frontend-media.s3.amazonaws.com/media/profile_photo/blob_QTGpLgO"
    },
    {
        "FirstName": "Ha4",
        "LastName": "Nguyen Tran",
        "Email": "ha.nguyen4@med2lab.com",
        "ProfilePhotoURL": "https://med2lab-frontend-media.s3.amazonaws.com/media/profile_photo/blob_QTGpLgO"
    }
];

const AvatarPage = () => {
    // Sample profile image link
    const linkImage = `https://cognitusai.com/wp-content/themes/med2lab/assets/images/about/teams/pic_quyen_nguyen.jpg`;

    return (
        <section className="content-component mb-5">
            <Row className="mb-4">
                <Col md={12}>
                    <div className="content-header">
                        <h1>Avatar</h1>
                    </div>
                </Col>
            </Row>

            {/* Single Avatar Demo */}
            <Row className="mt-4">
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Size - 36px</label>
                    <AvatarImage
                        image={linkImage}
                        size="36"
                        isOnline={true}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Size - 44px</label>
                    <AvatarImage
                        image={linkImage}
                        size="44"
                        isOnline={true}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Size - 56px</label>
                    <AvatarImage
                        image={linkImage}
                        size="56"
                        isOnline={true}
                    />
                </Col>
            </Row>

            {/* Placeholder Avatar Demo */}
            <Row className="mt-4">
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Placeholder - 36px</label>
                    <AvatarImage
                        image={''}
                        size="36"
                        isOnline={true}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Placeholder - 44px</label>
                    <AvatarImage
                        image={''}
                        size="44"
                        isOnline={true}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Placeholder - 56px</label>
                    <AvatarImage
                        image={''}
                        size="56"
                        isOnline={true}
                    />
                </Col>
            </Row>

            {/* Avatar Group Demo */}
            <Row className="mt-5">
                <Col md={12} className="text-center">
                    <h2>Avatar Group</h2>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Group Size - 24px</label>
                    <AvatarGroup
                        avatarData={sampleAvatarData}
                        NotInListNum={sampleAvatarData.length - 3}
                        size={24}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Group Size - 36px</label>
                    <AvatarGroup
                        avatarData={sampleAvatarData}
                        NotInListNum={sampleAvatarData.length - 3}
                        size={36}
                    />
                </Col>
                <Col md={4} className="d-flex justify-content-center flex-column align-items-center">
                    <label className="mb-4">Group Size - 40px</label>
                    <AvatarGroup
                        avatarData={sampleAvatarData}
                        NotInListNum={sampleAvatarData.length - 3}
                        size={40}
                    />
                </Col>
            </Row>
        </section>
    );
};

export default AvatarPage;