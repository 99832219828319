import React from 'react';

//boostrap
import { CustomInput, Label } from "reactstrap";

function AnswerItemRadio(props) {
    const { AnswerChoices, handleSelectOne } = props && props;

    // Choice
    const itemRadio = AnswerChoices?.map(function (item, i) {
        return (
            <div
                className={`answerItem fadeInElement ${item?.Selected && "selected"}`}
                key={i}
            >
                <Label
                    className="answerItem__option"
                    style={{ cursor: "pointer" }}
                >
                    <CustomInput
                        id={`radio_${i}`}
                        type="radio"
                        name="Selected"
                        onChange={(e) => handleSelectOne(item, e)}
                        defaultValue={item.Id}
                        className="d-none"
                    />
                    <span className="answerItem__text px-0">{item.Content}</span>
                </Label>
            </div>
        );
    });
    return (
        <React.Fragment>
            <p className="note_answer_choices py-2 font-weight-600 text-center">
                Select only ONE answer
            </p>
            <div className="d-flex flex-wrap justify-content-center">{itemRadio}</div>
        </React.Fragment>
    );
};

export default AnswerItemRadio;