import React from 'react'
import { Icon } from '@components/common';
import Media from '@images/teachV2/media.svg';
import styled from 'styled-components';

const WrapChooseMedia = styled.div`
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: #FFF;
  cursor: pointer;
  .content-choose {
    gap: 4px;
    span {
      color: #0089C2;
      font-size: 16px;
      font-weight: 500;
    }
  }
`

export default function ComponentChooseMedia(props) {
  const { openModalMedia } = props
  return (
    <WrapChooseMedia onClick={openModalMedia} >
      <div className="content-choose d-flex align-items-center justify-content-center w-100">
        <Icon src={Media} stroke="#0089C2" />
        <span>Choose from Media Library</span>
      </div>
    </WrapChooseMedia>
  )
}
