import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//call Action
import { actFetchCaseFeedbacks } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//router
import { useHistory } from "react-router-dom";

import { ContentProcess } from "@components/common";

function ReloadCasePage() {
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = { CaseId: id };
      dispatch(actFetchCaseFeedbacks(params));
    };
    fetchData();
  }, [dispatch, id]);

  const feedbacksData = useSelector((state) => state.dataFeedbacks || []);
  const resData = feedbacksData && feedbacksData.data;
  if((resData && resData.status === "success") || (resData && resData.FeedbackMode === "Customized")) {
    history.push(`/learn/feedback/${id}`);
  }

  const data = {
    title: "Reloading Case Data",
    description:
      "We will now process and analyze your works on the case. <br/> This might take up to 1 minute. Please keep this window open during this time",
  };
  return (
    <div className="wrapper -completedPage" >
      <ContentProcess data={data} icon="loader" />
    </div>
  );
}

export default ReloadCasePage;