import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { actFetchAllInterfaceTemplates } from "@actions";
import { TemplateSelector, GroupFilter, ModalCreateTemplate } from "../CustomizableElement";

function TemplateOptions({ onTemplateChange, handleResetTable, clearFilter, templateData, onGroupChange }) {
    const dispatch = useDispatch();

    // Reducer data
    const listInterfaceTemplates = useSelector((state) => state.listInterfaceTemplates?.data || []);
    const templates = listInterfaceTemplates.map(template => ({
        value: template.InterfaceTemplateId,
        label: template.Name
    }));

    // State
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedState, setSelectState] = useState({
        selectedGroup: null,
        selectedTemplate: null
    });
    const [internalTemplateData, setInternalTemplateData] = useState(templateData);

    // Load List
    useEffect(() => {
        dispatch(actFetchAllInterfaceTemplates());
    }, [dispatch]);

    // Update internalTemplateData when templateData prop changes
    useEffect(() => {
        setInternalTemplateData(templateData);
    }, [templateData]);

    // Clear Template active
    useEffect(() => {
        if (clearFilter) {
            setSelectState(prevState => ({
                ...prevState,
                selectedTemplate: null
            }));
        }
    }, [clearFilter]);

    // Handle Change Template or Group
    const handleChangeSelect = (option, type) => {
        setSelectState(prevState => ({
            ...prevState,
            [type]: option
        }));

        if (type === "selectedTemplate" && onTemplateChange && typeof onTemplateChange === 'function') {
            onTemplateChange(option);
        }

        if (type === "selectedGroup" && option.value) {
            onGroupChange(option.value);
        }
    };

    // Reload Template
    const handleReLoadTemplate = () => {
        dispatch(actFetchAllInterfaceTemplates());
        setSelectState(prevState => ({
            ...prevState,
            selectedTemplate: null
        }));
        handleResetTable();
    };

    // Modal Template
    const toggleModal = () => {
        setModalOpen(prevOpen => !prevOpen);
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <TemplateSelector
                        label={`Select Template`}
                        onChange={handleChangeSelect}
                        options={templates}
                        placeholder={"Select a template"}
                        value={selectedState.selectedTemplate}
                    />
                </Col>
                {internalTemplateData && (
                    <Col md={4}>
                        <GroupFilter
                            templateData={internalTemplateData}
                            onChange={handleChangeSelect}
                            value={selectedState.selectedGroup}
                        />
                    </Col>
                )}
            </Row>
            <ModalCreateTemplate
                isOpen={modalOpen}
                toggle={toggleModal}
                handleReLoadTemplate={handleReLoadTemplate}
            />
        </React.Fragment>
    );
}

export default TemplateOptions;