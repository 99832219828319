import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

//BootstrapTable
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

//call Action
import { atcUpdateQuestionGradingField } from "@actions";

//common
import { DataEmty } from "@components/common";

//redux
import { useDispatch } from "react-redux";

import { truncate } from "@utils";

//lodash
import _ from "lodash";

import { DetailGFModal } from '../TabQuestions';

function TableContainer(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { data, dataQuestion, paramsActive, isShow } = props && props;
  const PerformancesData = data?.Performances;
  const dataTable = PerformancesData?.Details;
  const GradingFieldsData = data?.GradingFields;
  const QuestionId = dataQuestion?.QuestionId;

  //state
  const [arrNew, setArrNew] = useState([]);
  const [modal, setModal] = useState(false);
  const [itemSendDetails, setItem] = useState(false);

  useEffect(() => {
    const setStateItem = (value) => {
      setItem(value);
      setModal(true);
    }

    const convertColumnsData = () => {
      const test = GradingFieldsData?.map((value) => ({
        dataField: value.Name,
        text: value.Name,
        headerClasses: "align-middle text-center font-weight-500",
        GradingFieldId: value.GradingFieldId,
        style: {
          width: "300px"
        },
        editor: {
          type: Type.TEXTAREA
        },
        formatter: (value, row) => {
          const truncateValue = truncate(value, 100);
          return truncateValue;
        },
        headerFormatter: (value, row) => {
          return (
            <div className="m--font-brand" style={{ cursor: 'pointer' }} onClick={() => setStateItem(value)}>
              {value?.text}
            </div>
          );
        },
      }));
      return test;
    };

    const newData = convertColumnsData();
    if (newData && newData.length > 0) {
      setArrNew(newData);
    }
  }, [GradingFieldsData]);

  //Modal
  const toggle = () => setModal(!modal);

  const columns = [
    {
      dataField: "Username/Email",
      text: "Username/Email",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
      classes: "align-middle",
      style: {
        width: "20%"
      },
    },
    {
      dataField: "Answer",
      text: "Answer",
      headerClasses: "align-middle text-left font-weight-500",
      editable: false,
      formatter: (value, row) => {
        return <div className="answerText" dangerouslySetInnerHTML={{ __html: value }}></div>
      },
      style: {
        width: "30%"
      },
    },
    // {
    //   dataField: "Session Time",
    //   text: "Session Time",
    //   headerClasses: "align-middle text-center font-weight-500",
    //   classes: "align-middle text-center",
    //   formatter: (value, row) => {
    //     return <span>{moment(value).format("YYYY-MM-DD")}</span>;
    //   }
    // },
    {
      dataField: "isCorrect",
      text: "Correct",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      editable: false,
      style: {
        width: "5%"
      },
      formatter: (value, row) => {
        return (
          <div>
            {value ? (
              <span className="icon-checkmark-switcher text-success"></span>
            ) : (
              <span className="icon-uncheck-switcher text-danger"></span>
            )}
          </div>
        );
      },
    },
    {
      dataField: "",
      headerClasses: "align-middle text-center font-weight-500",
      text: "Automated Score",
      classes: "align-middle text-center",
      editable: false,
      style: {
        width: "20%"
      },
      formatter: (value, row) => {
        const dataGrading = row.Grading;
        const Score = dataGrading.Score;
        const MaxPossibleScore = dataGrading.MaxPossibleScore;
        return (
          <p>
            <span className="text-muted"></span>{Score}/<span className="text-danger">{MaxPossibleScore}</span>
          </p>
        );
      }
    }
  ];

  //Edit Cell With API
  const afterSaveCell = (oldValue, newValue, row, column) => {
    let filtered_array = _.filter(GradingFieldsData, ["Name", column.dataField]);
    const filterGradingFieldId = filtered_array && filtered_array[0] && filtered_array[0].GradingFieldId;
    const params = {
      ReportType: "question_Records",
      Action: "Update",
      Target: "GradingField",
      SessionId: row["SessionId"],
      CohortId: paramsActive?.CohortId,
      PeriodId: paramsActive?.PeriodId,
      QuestionId: QuestionId,
      Email: row["Username/Email"],
      GradingFieldId: filterGradingFieldId,
      Value: newValue,
    };
    sendApi(params);
  };

  const sendApi = (params) => {
    dispatch(atcUpdateQuestionGradingField(id, params));
  }

  const columnsMerge = isShow ? [...columns, ...arrNew] : [...columns];
  return (
    <React.Fragment>
      <BootstrapTable
        keyField="SessionId"
        noDataIndication={() => <DataEmty content="No Data" />}
        data={(dataTable && dataTable) || []}
        columns={columnsMerge}
        wrapperClasses="table-responsive"
        cellEdit={cellEditFactory({
          mode: "click",
          blurToSave: true,
          afterSaveCell,
        })}
      />
      <DetailGFModal
        {...props}
        isOpen={modal}
        toggle={toggle}
        item={itemSendDetails}
        listAnswer={dataTable}
        QuestionId={QuestionId}
        sendApi={sendApi}
      />
    </React.Fragment>
  );
}

export default TableContainer;