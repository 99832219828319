import React from 'react';
import { css } from "@emotion/core";
import FadeLoader from "react-spinners/FadeLoader";

function LoaderPage(props) {
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #34bfa3;
  `;

  const { textMessage } = props;
  return (
    <div className="pageLoader d-flex flex-column">
      <FadeLoader loading={true} color="#F47814" css={override} size={84} />
      <div className="pageLoader__text">
        Loading...
      </div>
      {textMessage && <span className="pageLoader__desc" dangerouslySetInnerHTML={{ __html: textMessage }} />}
    </div>
  );
}

export default LoaderPage;