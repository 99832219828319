import React, { useState, useCallback } from 'react';
import { Input } from "reactstrap";


//lodash
import _ from "lodash";

function InputSearch(props) {
    //props
    const { handleSearchKeyWord } = props && props;

    //state
    const [keyword, setKeyword] = useState('');

    function fetchData(key) {
        handleSearchKeyWord(key);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceDropDown = useCallback(_.debounce((nextValue) => fetchData(nextValue), 1000), [])

    function handleInputOnchange(e) {
        const { value } = e.target;
        setKeyword(value);
        debounceDropDown(value);
    }

    return (
        <div className="d-flex align-items-center my-3 justify-content-lg-end">
            <Input
                type="text"
                name="search"
                placeholder="Search"
                value={keyword}
                onChange={handleInputOnchange}
                style={{maxWidth: "500px", height: "38px"}}
                autoComplete="off"
            />
            <button className="btn btn-brand ml-2">
                <i className="fa fa-search" aria-hidden="true"></i>
            </button>
        </div>
    );
}
export default InputSearch;