import React from "react";

//components
import { Avatar, IconChangeAvatar, ProfileInfo } from "@components/common/Profile";

function ProfileBox(props) {
    const { stateAccount } = props && props;
    return (
        <React.Fragment>
            <div className="profileCt">
                <div className="position-relative profileCt__avatar">
                    <Avatar 
                        {...props}
                        percentage={75}
                        stateAccount={stateAccount}
                    />
                    {/* {!userData?.isLoading &&
                        <div style={{ width: "50px", height: "50px" }} className="spinner-grow text-white position-absolute" role="status"></div>
                    } */}
                    <IconChangeAvatar {...props} />
                </div>
                <ProfileInfo 
                    {...props}
                />
            </div>
        </React.Fragment>
    );
}

export default ProfileBox;