import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDelete(props) {
  const isOpen = props && props.isOpen;
  const toggle = props && props.toggle;
  const handleRemove = props && props.handleRemove;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalBody className="text-center">
        <h4 className="text-center heading-4 --text-primary">
          Are you sure that <br /> you would like to delete this topic?
        </h4>
        <p className="mt-2 text-center">This action is not reversible</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
        >
          Delete Topic
        </Button>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDelete;
