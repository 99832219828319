import React from 'react'
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    color: #1D2939;
    font-size: 14px;
    font-weight: 500;
    span {
      color: red;
    }
  }
  .custom-tooltip {
    padding: 8px 12px;
    width: ${(props) => (props.width ? `${props.width}px` : "270px")};
    border-radius: 8px;
    text-align: start;
    &.custom-width {
      width: 340px;
    }
    div {
      text-align: start;
    }
    span, p {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px; /* 133.333% */
    }
    .tooltip-upload {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__wrap {
        display: flex;
        align-items: start;
        flex-direction: column;
        p {
          text-align: start;
        }
      }
      &__tip {
        font-style: italic;
      }
    }
  }
`

const TitleNoteCommon = (props) => {
  const { title, content, dataFor, required, slides, width, isUpload } = props
  return (
    <WrapNote width={width}>
      <p>
        {title}
        {required && <span>*</span>}
      </p>
      {(content || isUpload) && (
        <>
          <div data-tip data-for={dataFor}>
            <Icon src={info} />
          </div>
          <ReactTooltip id={dataFor} place='top' effect='solid' className={`${slides && "custom-width"} custom-tooltip`} >
            {slides ? (
              <div>
                Example: “Create 15 slides on ‘Cardiovascular System’ for first-year med students.” <br />
                Tips:
                <ul>
                  <li>- Specify number of slides needed</li>
                  <li>- Specify topic</li>
                  <li>- Specify target audience</li>
                  <li>- Set content depth</li>
                </ul>
              </div>
            ) : ( 
              isUpload ? (
                <div className="tooltip-upload">
                  <div className="tooltip-upload__wrap">
                    <p>You have two options for providing reference materials.</p>
                    <p>1. Choose from your Reference Library: Select existing documents from your personal library.</p>
                    <p>2. Upload a New File: Click on the "Upload" button to add a new document from your computer.</p>
                  </div>
                  <span className='tooltip-upload__tip'>
                    Tips: Attaching a source helps reducing risks of hallucination while emphasizing most relevant learning objectives. 
                  </span>
                </div>
              ) : (
                <span>{content}</span>
              )
            )}
          </ReactTooltip>
        </>
      )}
    </WrapNote>
  )
}

export default TitleNoteCommon
