import React, { useState } from 'react';

//reactstrap
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function QuestionTop(props) {
    const { handleAdd, dataQuestion } = props && props;

    //state
    const [dropdownOpen, setOpen] = useState(false);

    //toggleDropdown
    const toggleDropdown = () => setOpen(!dropdownOpen);

    return (
        <div className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-danger">Available questions: {dataQuestion?.length || 0}/1000</span>
            <ButtonDropdown className="ml-2 btn-sm" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className="--bg-primary" caret>
                    Add Question
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => handleAdd("createQuestion", "")}>
                        Blank Question
                    </DropdownItem>
                    <DropdownItem onClick={() => handleAdd("addTemplate", "")}>
                        Choose from Template
                    </DropdownItem>
                    <DropdownItem onClick={() => handleAdd("questionBank", "")}>
                        Choose from Question Bank
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    );
};

export default QuestionTop;