import React from 'react';

function TheadProblem() {
    return (
        <thead>
            <tr className="m--font-brand">
                <th style={{ width: '10%' }} className="text-left border-bottom-0 font-weight-500 text-nowrap">
                    Name
                </th>
                <th style={{ width: '20%' }} className="text-left border-bottom-0 font-weight-500 text-nowrap">
                    Search Type
                </th>
                <th style={{ width: '20%' }} className="text-left border-bottom-0 font-weight-500 text-nowrap">
                    Search string
                </th>
                <th style={{ width: '10%' }} className="text-center border-bottom-0 font-weight-500 text-nowrap">
                    ICD-10-CM
                </th>
                <th style={{ width: '10%' }} className="text-center border-bottom-0 font-weight-500 text-nowrap">
                    HCC
                </th>
                <th style={{ width: '10%' }} className="text-center border-bottom-0 font-weight-500 text-nowrap">
                    RAF
                </th>
                <th style={{ width: '20%' }} className="text-right border-bottom-0 font-weight-500 text-nowrap">
                    
                </th>
            </tr>
        </thead>
    );
};

export default TheadProblem;