import React from 'react';
import { Input } from "reactstrap";

//components
import { HeadingRow } from "../MutipleChoiTableType";

function RowTable(props) {
    const { rowIndex, rows, handleRemoveItem, columns, handleCheckbox, stateAnswerTable } = props && props;
    return (
        <tr>
            <HeadingRow
                {...props}
                rows={rows}
                handleRemoveItem={handleRemoveItem}
                stateAnswerTable={stateAnswerTable}
            />
            {columns?.map((itemCol, j) => (
                <td className="align-middle text-center" key={j}>
                    <Input
                        data-index={`Row_${rowIndex}_Col_${j}`}
                        name={`Row_${rowIndex}_Col_${j}`}
                        type="checkbox"
                        className='m-0 position-relative'
                        defaultChecked={itemCol?.isCorrect}
                        onChange={(e) => handleCheckbox(rowIndex, j, e)}
                    />
                </td>
            ))}
        </tr>
    );
};

export default RowTable;