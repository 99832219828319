import { pnorderConstants } from "@constants";
import { apiCaller } from "@utils";

//alert
import { Toasts } from '@components/common/Toasts';
import { toast } from 'react-toastify';

//fetch pnorder
export const actFetchPNOrderRequest = (idParam) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/pn/${idParam}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.PNORDER_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.PNORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.PNORDER_FAILURE, error: error } }
}

//update pnorder
export const actUpdatePNOrderRequest = (idParam, params) => {
    const isLoading = true;
    toast.dismiss();
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/pn/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data && data.status === "Success") {
                const responseDataAlert = data && data;
                const dataAlert = responseDataAlert && responseDataAlert.alert;
                const alertType = dataAlert && dataAlert.Severity;
                // const alertDuration = dataAlert && dataAlert.ShowDuration;

                if (alertType && alertType === "success") {
                    toast.success(<Toasts data={dataAlert} />, {
                        autoClose: false,
                        draggable: false,
                        toastId: 'PNORDER_UPDATE_SUCCESS'
                    });
                }
                if (alertType && alertType === "warning") {
                    toast.warning(<Toasts data={dataAlert} />, {
                        autoClose: false,
                        draggable: false,
                        toastId: 'PNORDER_UPDATE_WARNING'
                    });
                }
                if (alertType && alertType === "danger") {
                    toast.error(<Toasts data={dataAlert} />, {
                        autoClose: false,
                        draggable: false,
                        toastId: 'PNORDER_UPDATE_ERROR'
                    });
                }

                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.PNORDER_UPDATE_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.PNORDER_UPDATE_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.PNORDER_UPDATE_FAILURE, error: error } }
}

export const actCheckMyOrders = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/pn/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data && data.Status === "Alert") {
                dispatch(success(data));

                const responseDataCheck = data && data;
                const dataAlertCheck = responseDataCheck?.Alert;
                const alertTypeCheck = dataAlertCheck?.Severity;
                // const alertDurationCheck = alertTypeCheck && alertTypeCheck.ShowDuration;

                if (alertTypeCheck && alertTypeCheck === "success") {
                    const messageSuccess = {
                        Title: 'Your PN Orders Look Ok!',
                        Message: ''
                    }
                    toast.success(<Toasts data={messageSuccess} />, {
                        autoClose: false,
                        toastId: 'CHECK_MY_ORDERS_REQUEST'
                    });
                }
                if (alertTypeCheck && alertTypeCheck === "warning") {
                    toast.warning(<Toasts data={dataAlertCheck} />, {
                        autoClose: false,
                        toastId: 'CHECK_MY_ORDERS_REQUEST'
                    });
                }
                if (alertTypeCheck && alertTypeCheck === "danger") {
                    toast.error(<Toasts data={dataAlertCheck} />, {
                        autoClose: false,
                        toastId: 'CHECK_MY_ORDERS_REQUEST'
                    });
                }

            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.CHECK_MY_ORDERS_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.CHECK_MY_ORDERS_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.CHECK_MY_ORDERS_FAILURE, error: error } }
}

//fetch copy forward 
export const actFetchCopyForwardRequest = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/pn/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.PNORDER_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.PNORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.PNORDER_FAILURE, error: error } }
}

//Load PN Orders
export const atcLoadPnOrders = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.LOAD_PNORDER_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.LOAD_PNORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.LOAD_PNORDER_FAILURE, error: error } }
}


//*-----------------------------
//*----Intervention PNOrder-----
//*-----------------------------
//Create Intervention For PN Order
export const atcCreateInterventionForPN = (id, params, nameCategory) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data, nameCategory));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.CREATE_INTERVENTION_FOR_PNORDER_REQUEST, isLoading } }
    function success(data, nameCategory) {
        return {
            type: pnorderConstants.CREATE_INTERVENTION_FOR_PNORDER_SUCCESS,
            payload: data,
            nameCategory: nameCategory
        }
    }
    function failure(error) { return { type: pnorderConstants.CREATE_INTERVENTION_FOR_PNORDER_FAILURE, error: error } }
}

//Update Intervention For PN Order
export const atcUpdateInterventionForPN = (id, params, nameCategory, InterventionId) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data, nameCategory, InterventionId));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.UPDATE_INTERVENTION_FOR_PNORDER_REQUEST, isLoading } }
    function success(data, nameCategory, InterventionId) {
        return {
            type: pnorderConstants.UPDATE_INTERVENTION_FOR_PNORDER_SUCCESS,
            payload: data,
            interventionId: InterventionId,
            nameCategory: nameCategory
        }
    }
    function failure(error) { return { type: pnorderConstants.UPDATE_INTERVENTION_FOR_PNORDER_FAILURE, error: error } }
}

//Case - Update PN Availability
export const updateAvailabilityForPN = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                const data = res && res.data;
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.UPDATE_AVAILABILITY_FOR_PNORDER_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.UPDATE_AVAILABILITY_FOR_PNORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.UPDATE_AVAILABILITY_FOR_PNORDER_FAILURE, error: error } }
}

//Update Intervention Item
export const handleChangeAvailableForPN = (id, params, namePanel) => {
    // const isLoading = true;
    return dispatch => {
        // dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            if (res && res.status === 200) {
                console.log('Update Intervention Item - success', namePanel);
                // const data = res && res.data;
                // dispatch(success(data, namePanel));
            } 
            // else {
            //     dispatch(failure("error"));
            // }
        });
    };
    // function request(isLoading) { return { type: pnorderConstants.UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_REQUEST, isLoading } }
    // function success(data, namePanel) {
    //     return {
    //         type: pnorderConstants.UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_SUCCESS,
    //         payload: data,
    //         nameCategory: namePanel
    //     }
    // }
    // function failure(error) { return { type: pnorderConstants.UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_FAILURE, error: error } }
}

//Case - Update Attribute Score (PNOrders)
export const atcUpdateScoreForPN = (id, params, attributeId, namePanel) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data, attributeId, namePanel));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) {
        return { type: pnorderConstants.UPDATE_SCORE_FOR_PNORDER_REQUEST, isLoading }
    }
    function success(data, attributeId, namePanel) {
        return {
            type: pnorderConstants.UPDATE_SCORE_FOR_PNORDER_SUCCESS,
            payload: data,
            attributeId: attributeId,
            namePanel: namePanel
        }
    }
    function failure(error) { return { type: pnorderConstants.UPDATE_SCORE_FOR_PNORDER_FAILURE, error: error } }
}

//Case - Update PN Order TF-Unit
export const actUpdatePNOrderTFUnit = (idParam, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${idParam}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: pnorderConstants.UPDATE_PNORDER_TF_UNIT_REQUEST, isLoading } }
    function success(data) { return { type: pnorderConstants.UPDATE_PNORDER_TF_UNIT_SUCCESS, payload: data } }
    function failure(error) { return { type: pnorderConstants.UPDATE_PNORDER_TF_UNIT_FAILURE, error: error } }
}