import React from 'react';

// Image Default
import imgUser from '@images/common/placehoder-avatar.svg';

const Avatar = ({ percentage, profile_photo_url }) => {
  const radius = 85; // Bán kính của progress-bar
  const circumference = 2 * Math.PI * radius; // Chu vi của progress-bar
  const offset = circumference - (percentage / 100) * circumference; // Lượng offset để hiển thị tiến trình
  return (
    <div className="circular-progress-bar">
      <div className="avatar" style={{backgroundImage: `url(${profile_photo_url || imgUser})`}}></div>
      <svg className="progressBar" width="100%" height="100%">
        <circle
          className="background"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth="8"
          stroke="#e0e0e0"
        />
        <circle
          className="progressColor"
          cx="50%"
          cy="50%"
          r={radius}
          strokeWidth="8"
          stroke="#29b6f6"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      <div className="percentage-container">
        <div className="percentage" data-percentage={75}></div>
      </div>
    </div>
  );
};

export default Avatar;