import React, { useEffect, useState } from "react";

//boostrap
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Table } from "reactstrap";

//api
import { apiCaller } from "@utils";

//common
import { DataEmty } from "@components-common";

function FlagsList(props) {
    const { isOpen, handleClose, QuestionId } = props && props;

    //state
    const [stateData, setData] = useState([]);


    useEffect(() => {
        const LoadQuestionFlags = () => {
            const params = {
                "Action": "LoadQuestionFlags",
                "QuestionId": QuestionId
            }
            apiCaller(`/api/teach/question/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                } else {
                    console.log('error');
                }
            });
        }
        isOpen && LoadQuestionFlags();
    }, [isOpen, QuestionId])

    const todoItems = stateData?.map((item, idx) => {
        const Account = item?.Account;
        const email = Account?.email;
        return (
            <tr key={idx}>
                <td>{email}</td>
                <td>{item?.Content}</td>
                <td>{item?.LastModifiedAt}</td>
            </tr>
        )
    });

    return (
        <Modal
            style={{ maxWidth: "800px" }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            backdrop={`static`}
        >
            <ModalHeader toggle={handleClose}>
                Flags
            </ModalHeader>
            <ModalBody>
                {stateData?.length > 0 ? (
                    <div className="table-layout-fixed">
                        <Table responsive bordered>
                            <thead>
                                <tr>
                                    <th className="--text-primary font-weight-500">Email</th>
                                    <th className="--text-primary font-weight-500">Content</th>
                                    <th className="--text-primary font-weight-500">Last Modified At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {todoItems}
                            </tbody>
                        </Table>
                    </div>
                ) : <DataEmty content="No Data" />}
            </ModalBody>
            <ModalFooter>
                <Button className="mx-auto" color="primary" onClick={handleClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default FlagsList;
