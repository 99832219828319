import React, { useState, useEffect } from "react";

//reactstrap
import { Input, Badge, Button } from "reactstrap";

//components
import { ModalAddConcept } from '../../Modal/Question';

function TableAnswerUMLS(props) {
  const {
    answerData,
    handleChangeInput,
    handleRemoveAnswer,
    handleUpdateConcept,
    handleAddAnswer
  } = props && props;

  //state
  const [modal, setModal] = useState(false);
  const [indexItem, setItem] = useState({});
  const [cuidCurrent, setCuid] = useState({});
  const [stateData, setData] = useState(answerData || []);

  useEffect(() => {
    setData(answerData || []);
  }, [answerData]);

  const toggle = (indexObject, cuid) => {
    setModal(!modal);
    setItem(indexObject);
    setCuid(cuid);
  }

  return (
    <React.Fragment>
      <table
        className="table table-responsive"
        style={{ display: answerData?.length === 0 ? "table" : "block" }}
      >
        <thead>
          <tr>
            <th className="font-weight-500 align-middle">#</th>
            <th className="font-weight-500 align-middle" style={{ width: "50%" }}>
              Answer Text <span className="m--font-danger">*</span>
            </th>
            <th className="font-weight-500 align-middle text-center" style={{ width: "20%" }}>
              UMLS CUID
            </th>
            <th className="font-weight-500 align-middle text-center">Correct?</th>
            <th className="font-weight-500 align-middle" style={{ width: "15%" }}>
              Point
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stateData?.map((value, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="align-middle">
                <textarea
                  name="AnswerContent"
                  className="form-control m-input"
                  rows={1}
                  value={value?.AnswerContent}
                  data-index={index}
                  onChange={handleChangeInput}
                />
              </td>
              <td className="align-middle text-center">
                {value?.CUID ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Badge
                      onClick={() => toggle(index, value?.CUID)}
                      style={{ minWidth: "80px", cursor: "pointer" }}
                      className="font-weight-500 p-2"
                      color={`primary`}
                    >
                      {value?.CUID}
                    </Badge>
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-times text-danger ml-2"
                      aria-hidden="true"
                      onClick={() => handleUpdateConcept(value?.CUID, index, 'remove')}
                    ></i>
                  </div>
                ) : (
                  <div className="m--font-info font-weight-500" style={{ cursor: 'pointer' }} onClick={() => toggle(index, '')}>
                    <span className="icon-add"></span> Add
                  </div>
                )}
              </td>
              <td className="align-middle">
                <div className="d-flex align-items-center justify-content-center">
                  <Input
                    className="m-0"
                    name="isCorrect"
                    type="checkbox"
                    checked={value?.isCorrect}
                    data-index={index}
                    onChange={handleChangeInput}
                  />{" "}
                </div>
              </td>
              <td className="align-middle">
                <input
                  type="number"
                  name="Score"
                  className="form-control m-input"
                  value={value?.Score}
                  data-index={index}
                  onChange={handleChangeInput}
                  step="any"
                />
              </td>
              <td className="align-middle">
                <i
                  style={{ cursor: "pointer" }}
                  className="fa fa-2x fa-times text-danger"
                  aria-hidden="true"
                  onClick={() => handleRemoveAnswer(index)}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button
        className="btn btn-outline-brand d-block mx-auto mt-4"
        onClick={handleAddAnswer}
      >
        <i className="fa fa-plus" />
        &nbsp; More Options
      </Button>
      <ModalAddConcept {...props} cuidCurrent={cuidCurrent} indexItem={indexItem} handleUpdateConcept={handleUpdateConcept} isOpen={modal} toggle={toggle} />
    </React.Fragment>
  );
}

export default TableAnswerUMLS;