import React, { useState, useContext, useEffect } from 'react';

// Chat components
import {
    ChatClick,
    ChatFreeText,
    ChatVoiceBox,
    ChatQuestion,
    ChatPanel
} from "../Chat";
import { ModalEmailTemplate } from "@components/learner/StandardScreen/EmailTemplate";

// Components
import { MicrophoneMessage } from "@ui-partents/Message";

//Icon
import IconNotSupport from "@images/access-mic/not-support-voice.svg";

//redux
import { useSelector, useDispatch } from "react-redux";

//context
import { DeviceContext } from '@context/DeviceContext';

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { atcAddRecordToChanel } from "@actions";

function ChatInterface(props) {

    //variables
    let { id } = useParams();
    const dispatch = useDispatch();

    //context
    const { device, deviceType } = useContext(DeviceContext);  // Get device and device type from context

    //props
    const { children, ChatMode: propChatMode, data, handleOverideEffectMenu, switchChat, stateType } = props;

    //State to store the modified ChatMode
    const [ChatMode, setChatMode] = useState(propChatMode);

    // State to manage modal open/close
    const [isOpenMessage, setIsOpenMessage] = useState(false); // Renamed to isOpenMessage

    //Timepoint
    const CaseProgressReducer = useSelector((state) => (state?.caseTaskCurrent?.data) || {});
    let TimePointId = CaseProgressReducer?.CaseProgress?.CurrentTimePointProgress?.TimePointId;
    
    //  Case - Reducer
    const caseReducer = useSelector((state) => state.cases || []);
    const caseResponse = caseReducer?.data?.Case;
    let FirstTimePoint = caseResponse?.Timeline?.TimePointNum === 1;

    //Check Chat View - Mobile
    const isOpen = useSelector((state) => state.typeChatforChatFeedback.isOpen);

    //Chat - Add Record
    const apiCallerAddChatRecord = async (data) => {
        const params = {
            "Action": "AddData",
            "CaseId": parseInt(id),
            "TimePointId": parseInt(TimePointId),
            "Data": data
        };

        return new Promise(async (resolve, reject) => {
            try {
                await dispatch(atcAddRecordToChanel(params)); // Wait for dispatch to complete
                resolve(); // Resolve the promise if dispatch is successful
            } catch (error) {
                reject(error); // Reject the promise if there's an error
            }
        });
    };

    // State to store message from voice to submit free text
    const [messageFromVoiceToText, setFromVoiceToText] = useState('');

    // State to control Email Template visibility
    const [isEmailTemplate, setEmailTemplate] = useState({
        data: {},
        isToggle: false
    });

    // Handle Toggle Email Template
    const handleToggleEmailTemplate = (values) => {
        setEmailTemplate(prevState => ({
            ...prevState,
            isToggle: !prevState.isToggle,
            data: values || null
        }));
    };

    // Flag to ensure we only switch once
    const [hasSwitched, setHasSwitched] = useState(false);

    // Logic show message không hỗ trợ IOS cho Voice - Patient - Tự động chuyển sang freetext
    useEffect(() => {
        const userAgent = navigator.userAgent;

        // Kiểm tra thiết bị iOS
        const isIOSDevice = device === 'iOS' && (deviceType === 'iPhone' || deviceType === 'iPad');

        // Kiểm tra Safari trên macOS
        const isMacOSSafari = /Macintosh/.test(userAgent) && /Safari/.test(userAgent) && !/Chrome/.test(userAgent);

        // Kiểm tra điều kiện cần thiết để chuyển đổi chế độ
        const shouldSwitchToFreeText = (isIOSDevice || isMacOSSafari) && isOpen === 'chat';

        // Chuyển sang chế độ freetext nếu điều kiện thỏa mãn
        if (propChatMode === 'voice' && shouldSwitchToFreeText && !hasSwitched) {
            setChatMode('freetext');  // Override to freetext
            FirstTimePoint && setIsOpenMessage(true);   // Open message modal only once
            setHasSwitched(true);     // Ensure we don't switch again
        }
    }, [device, deviceType, propChatMode, isOpen, hasSwitched, FirstTimePoint]);

    return (
        <React.Fragment>
            <ChatPanel
                {...props}
                isOpen={isOpenMessage}
                handleClose={() => setIsOpenMessage(false)}
                ChatMode={ChatMode}
            >
                {/* Hiển thị view Instructor và Patient bên trong component này */}
                {children}

                {/* Display Email Template if the state is true */}
                {isEmailTemplate?.isToggle &&
                    <ModalEmailTemplate
                        {...props}
                        isOpen={isEmailTemplate?.isToggle}
                        handleToggleEmailTemplate={handleToggleEmailTemplate}
                        isEmailTemplate={isEmailTemplate}
                    />
                }

                {stateType?.chat && (
                    <>
                        {ChatMode === "click" && (
                            <ChatClick
                                {...props}
                                data={data}
                                handleOverideEffectMenu={handleOverideEffectMenu}
                                ChatMode={ChatMode}
                            />
                        )}
                        {ChatMode === "freetext" && (
                            <ChatFreeText
                                {...props}
                                data={data}
                                handleOverideEffectMenu={handleOverideEffectMenu}
                                ChatMode={ChatMode}
                                apiCallerAddChatRecord={apiCallerAddChatRecord}
                                handleToggleEmailTemplate={handleToggleEmailTemplate}
                            />
                        )}
                        {ChatMode === "voice" && (
                            <ChatVoiceBox
                                {...props}
                                data={data}
                                handleOverideEffectMenu={handleOverideEffectMenu}
                                ChatMode={ChatMode}
                            />
                        )}
                        {ChatMode === "switch_voice_text" && switchChat === "freetext" && (
                            <ChatFreeText
                                {...props}
                                data={data}
                                handleOverideEffectMenu={handleOverideEffectMenu}
                                ChatMode={ChatMode}
                                messageFromVoiceToText={messageFromVoiceToText}
                                apiCallerAddChatRecord={apiCallerAddChatRecord}
                                handleToggleEmailTemplate={handleToggleEmailTemplate}
                            />
                        )}
                        {ChatMode === "switch_voice_text" && switchChat === "voice" && (
                            <ChatVoiceBox
                                {...props}
                                data={data}
                                handleOverideEffectMenu={handleOverideEffectMenu}
                                ChatMode={ChatMode}
                                handleQuestionTypePatient={(value) => {
                                    setFromVoiceToText(value);
                                }}
                            />
                        )}
                    </>
                )}
                {stateType?.question && (
                    <ChatQuestion
                        {...props}
                        ChatMode={ChatMode}
                        apiCallerAddChatRecord={apiCallerAddChatRecord}
                        handleToggleEmailTemplate={handleToggleEmailTemplate}
                    />
                )}

                {/* Modal này dùng cho trường hợp voice - patient */}
                <MicrophoneMessage
                    {...props}
                    title={`Notice About Voice Feature`}
                    content={`This case supports Voice-based chat, but the feature is currently unavailable on iOS devices. You can still interact using Text. To explore the Voice feature, please use a desktop, laptop, or Android device. We apologize for the inconvenience and are actively working on a solution. All other features are fully accessible!`}
                    isOpen={isOpenMessage}
                    icon={IconNotSupport}
                    onClose={() => setIsOpenMessage(false)}
                />
            </ChatPanel>
        </React.Fragment>
    );
}

export default ChatInterface;