import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

//components
import { DataEmty } from "@components-common";

function WrapUpFeedback({ isLoading, feedbacksList }) {
    const [feedbackData, setFeedbackData] = useState({ good: '', toImprove: '' });

    useEffect(() => {
        if (feedbacksList) {
            setFeedbackData({
                good: feedbacksList.Good || '',
                toImprove: feedbacksList.ToImprove || ''
            });
        }
    }, [feedbacksList]);

    // Function to render skeleton loading boxes
    const renderSkeletonBoxes = () => {
        return (
            <>
                <Skeleton height={40} width={`100%`} />
                <Skeleton height={40} width={`80%`} />
                <Skeleton height={40} width={`100%`} />
            </>
        );
    };

    return (
        <div className="wrap-up-feedbackCt">
            {isLoading ? (
                <>
                    <div className="wrap-up-feedbackCt__item wrap-up-feedbackCt__item--good">
                        <h4>What Went Well 🚀</h4>
                        {renderSkeletonBoxes()}
                    </div>
                    <div className="wrap-up-feedbackCt__item wrap-up-feedbackCt__item--to-improve">
                        <h4>Suggestions for Improvement 💬</h4>
                        {renderSkeletonBoxes()}
                    </div>
                </>
            ) : (
                <>
                    <div className="wrap-up-feedbackCt__item wrap-up-feedbackCt__item--good">
                        <h4>What Went Well 🚀</h4>
                        {feedbackData.good ? (
                            <div className="mt-2 ml-3" dangerouslySetInnerHTML={{ __html: feedbackData.good }} />
                        ) : (
                            <div className="mt-2">
                                <DataEmty content="No Data" />
                            </div>
                        )}
                    </div>
                    <div className="wrap-up-feedbackCt__item wrap-up-feedbackCt__item--to-improve">
                        <h4>Suggestions for Improvement 💬</h4>
                        {feedbackData.toImprove ? (
                            <div className="mt-2 ml-3" dangerouslySetInnerHTML={{ __html: feedbackData.toImprove }} />
                        ) : (
                            <div className="mt-2">
                                <DataEmty content="No Data" />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default WrapUpFeedback;