// MgmtPeriodsPage.jsx
import React, { useEffect } from 'react';

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { actLoadAllPeriods } from "@actions";

//components
import { Periods } from "@components/teachV2/Manage/Tabs";

const MgmtPeriodsPage = () => {
  //dispatch
  const dispatch = useDispatch();

  //load Data
  useEffect(() => {
    const fetchData = async () => {
      //Load Periods
      dispatch(actLoadAllPeriods({ Action: "Load" }));
    };
    fetchData();
  }, [dispatch]);

  const periodsStore = useSelector((state) => state?.loadPeriods || {});
  return (
    <div className='wrapper MgmtPeriodsPage'>
      <Periods periodsStore={(periodsStore && periodsStore) || {}} />
    </div>
  );
};

export default MgmtPeriodsPage;