//Weitzman
import {
    WeitzmanConfirmPage,
    RegisterWeitzmanPage,
    LoginWeitzmanPage,
    ForgotpasswordPage,
    WeitzmanLaunchPlanPage
} from "@pages/med2lab/WeitzmanPage";

const weitzmanRoutes = [
    {
        path: "/weitzman/launchplan/:id",
        exact: true,
        classPage: '',
        component: WeitzmanLaunchPlanPage,
        isLayout: false,
    },
    {
        path: "/weitzman/confirm",
        exact: true,
        classPage: '',
        component: WeitzmanConfirmPage,
        isLayout: false,
    },
    {
        path: "/weitzman/access-email",
        exact: true,
        classPage: '',
        component: RegisterWeitzmanPage,
        isLayout: false,
    },
    {
        path: "/weitzman/login",
        exact: true,
        classPage: '',
        component: LoginWeitzmanPage,
        isLayout: false,
    },
    {
        path: "/weitzman/forgot-password",
        exact: true,
        classPage: '',
        component: ForgotpasswordPage,
        isLayout: false,
    }
];

export { weitzmanRoutes };