import { templatesConstants } from "@constants";

export function listInterfaceTemplates(state = {}, action) {
    switch (action.type) {
        case templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case templatesConstants.LIST_ALL_INTERFACE_TEMPLATES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function listStandardInterfaceElements(state = {}, action) {
    switch (action.type) {
        case templatesConstants.STANDARD_INTERFACE_ELEMENTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case templatesConstants.STANDARD_INTERFACE_ELEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case templatesConstants.STANDARD_INTERFACE_ELEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};