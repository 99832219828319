import React from 'react';
import styled from 'styled-components';

const DivBeta = styled.div`
  display: inline-block;  // Ensures the component behaves as an inline-block element
  border-radius: 120px;
  background: ${(props) => (props.trans ? `#FFF` : "#FDEBC8")};
  color: #F79E1C;
  text-align: center;
  font-size: ${(props) => props.fontSize || 12}px;
  font-weight: 500;
  padding: ${(props) => props.trans ? "0px 6px" : "0px 7.2px"};
  text-transform: uppercase;
`;

function BetaComponent(props) {
  const { trans, fontSize } = props;

  return (
    <DivBeta trans={trans} fontSize={fontSize}>
      BETA
    </DivBeta>
  );
}

BetaComponent.defaultProps = {
  fontSize: 12,
  trans: false,
};

export default BetaComponent;