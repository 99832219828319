import React, { useMemo, useCallback, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';

// Icons
import IconMicro from '@images/chatvoice/micro-icon.svg';
import IconPause from '@images/chatvoice/pause-icon.svg';

// Audio
import AudioClick from '@audio/select-click.wav';  // Import âm thanh từ thư mục assets

// Keyframes for pulse animation
const pulse = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #2268F0;
  }
  50% {
    border: solid #2268F0;
    opacity: 0.8;
  }
  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #2268F0;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #2268F0;
  }
`;

// Keyframes for shake animation
const shake = keyframes`
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  50% {
    transform: translateX(4px);
  }
  75% {
    transform: translateX(-4px);
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
  .object {
    display: flex;
    flex: 0 1 100%;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    position: relative;
  }
  .outline {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 40px solid #2268F0;
    position: absolute;
    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
    ${({ isRecording }) =>
    isRecording &&
    css`
        animation: ${pulse} 2s ease-out infinite;
      `}
  }
  .outline-delayed {
    ${({ isRecording }) =>
    isRecording &&
    css`
        animation-delay: 1.5s;
        animation: ${pulse} 2s ease-out infinite;
      `}
  }
  .button {
    width: 118px;
    height: 118px;
    border-radius: 50%;
    background: #2268F0;
    opacity: 0.34;
    cursor: pointer;
    transition: transform 0.2s;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
    &:active {
      transform: scale(0.95);
      animation: ${shake} 0.3s linear;
    }
    ${({ isRecording }) =>
    isRecording &&
    css`
        pointer-events: none;
      `}
  }
  .button-circle-in {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #2268F0;
    position: absolute;
    @media (max-width: 768px) {
      width: 90px;
      height: 90px;
    }
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  }
  .icon {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Text = styled.div`
  color: #081B2A;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const LoaderWrapper = styled.div`
  margin-top: 20px;
`;

const PulseButtonAnimate = (props) => {
  const { isRecording, onButtonClick, isLoadingChatVoice, statusGenerateAudio, audioStarted } = props;

  // Tạo đối tượng Audio một lần khi component mount
  const clickAudio = useMemo(() => new Audio(AudioClick), []);

  useEffect(() => {
    clickAudio.load();  // Tải âm thanh ngay khi component được mount
  }, [clickAudio]);

  const handleClick = useCallback(() => {
    if (!isLoadingChatVoice && !audioStarted) {
      clickAudio.play();  // Phát âm thanh khi click
      onButtonClick();
    }
  }, [isLoadingChatVoice, audioStarted, onButtonClick, clickAudio]);

  const handleOnTouchEnd = useCallback(() => {
    if (!isLoadingChatVoice && !audioStarted) {
      clickAudio.play();  // Phát âm thanh khi touch end
      onButtonClick();
    }
  }, [isLoadingChatVoice, audioStarted, onButtonClick, clickAudio]);

  const isProcessing = useMemo(() => {
    return statusGenerateAudio === 'streaming' || isLoadingChatVoice;
  }, [statusGenerateAudio, isLoadingChatVoice]);

  return isProcessing ? (
    <Box isLoading={true}>
      <LoaderWrapper>
        <PulseLoader color="#2268F0" size={18} />
      </LoaderWrapper>
      <Text>Processing...</Text>
    </Box>
  ) : (
    <Box
      isRecording={isRecording}
      isLoading={isLoadingChatVoice}
      onClick={handleClick}
      onTouchEnd={handleOnTouchEnd}
    >
      <div className="object">
        <div className="outline" />
        <div className="outline outline-delayed" />
        <div className="button" />
        <div className="button button-circle-in" />
        <div className="icon-wrapper">
          <img src={isRecording ? IconPause : IconMicro} alt={isRecording ? 'Stop Recording' : 'Start Recording'} className="icon" />
        </div>
      </div>
      <Text>{(isRecording || audioStarted) ? '' : 'Press to Talk'}</Text>
    </Box>
  );
};

export default PulseButtonAnimate;