import React, { useState } from 'react'
import { Icon } from '@components/common'
import create from '@images/teachV2/create.svg'
import Scenario from '@images/teachV2/scenario.svg'
import { useHistory } from 'react-router-dom'
import ModalCaseScenario from './ModalCaseScenario'

const BuilderChoice = (props) => {
  const [showExistModal, setShowExistModal] = useState(false)
  let history = useHistory();

  return (
    <div className="builderChoice">
      <div className="builderChoice-wrap">
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <h1>
              Interactive Module Builder
            </h1>
            {/* <div className="inline-block ml-2">
              <BetaComponent />
            </div> */}
          </div>
          <p>Our AI uses a case scenario as the foundation to build your interactive module. <br /> Choose an existing scenario from your library or generate a new one to get started!</p>
        </div>
        <div className="list-choice">
          <div className="list-choice__item" onClick={() => setShowExistModal(!showExistModal)}>
            <div className="list-choice__item-blue">
              <Icon src={create} />
            </div>
            <p>Use Existing Case Scenario</p>
          </div>
          <div className="list-choice__item" onClick={() => history.push(`/teach/script`)}>
            <div className="list-choice__item-blue">
              <Icon src={Scenario} />
            </div>
            <p>Generate New Case Scenario</p>
          </div>
        </div>
      </div>
      <ModalCaseScenario
        isOpen={showExistModal}
        toggle={() => setShowExistModal(!showExistModal)}
      />
    </div>
  )
}

export default BuilderChoice
