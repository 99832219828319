import React, { useState, useEffect } from 'react';

//reactBoostrap
import {
    FormGroup,
    Label,
    Input,
    Alert
} from "reactstrap";

function Domains(props) {
    const { data, handleChangeDomains } = props && props;
    const domainProps = data?.Domains;

    //state
    const [domainData, setDomainData] = useState((domainProps && domainProps) || []);

    useEffect(() => {
        setDomainData((domainProps && domainProps) || []);
    }, [domainProps]);

    return (
        <React.Fragment>
            <h5 className="heading-5">Domains:</h5>
            <Alert className="m-alert--default border-0">
                Which assessment domain(s) does this chat topic belong ?
            </Alert>
            {domainData?.map((value, index) => (
                <FormGroup
                    check
                    style={{ color: value.Color }}
                    className="mb-2"
                    key={index}
                >
                    <Label check>
                        <Input
                            type="checkbox"
                            onChange={(e) => handleChangeDomains(index, e)}
                            value={value?.Id}
                            name="Domains"
                            defaultChecked={value?.Selected}
                        />
                        {value?.Name}
                    </Label>
                </FormGroup>
            ))}
            <p>
                <small
                    className="text-muted text-justify"
                    style={{ fontSize: "12px" }}
                >
                    The points learners earn from asking this chat question will be added to all of the designated domains above
                </small>
            </p>
        </React.Fragment>
    );
};

export default Domains;