import React, { useState } from "react";
import "react-contexify/dist/ReactContexify.css";

//components
import { ModalViewExam } from "./ExamFindings";

function PointBubble(props) {
  //props
  const { useHumanModel, data, handleHumanModal } = props && props;

  //state
  const [modal, setModal] = useState({
    viewExamPoint: false,
  });
  const [listExam, setExam] = useState([]);
  const [itemActive, setItemActive] = useState({});

  const toggle = (type, item, data) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setExam(data);
    setItemActive(item);
  };

  const handleHumanExam = (event, item, list) => {
    setItemActive(item);
    handleHumanModal(item, list);
    event.currentTarget.classList.add('bg-onClick');
  };

  const devRef = props && props.devRef;
  const heightExPoint = devRef?.current?.clientHeight;
  const widthExPoint = devRef?.current?.clientWidth;

  return (
    <div>
      {heightExPoint && widthExPoint && data?.map((item, i) => (
        <div
          key={i}
          className={`pointBubble`}
          id={`exam_point_${item?.PointId}`}
          onClick={
            useHumanModel
              ? (e) => handleHumanExam(e, item, item?.ExamItems)
              : () => toggle("viewExamPoint", item, item?.ExamItems)
          }
          style={{
            top: (heightExPoint * item.PositionY) / 100 + "px",
            left: (widthExPoint * item.PositionX) / 100 + "px",
          }}
          data-id={item?.PointId}
        >
          {itemActive?.PointId === item?.PointId && (
            <div className="pointBubble__dot"></div>
          )}
        </div>
      ))}
      <ModalViewExam
        isOpen={modal?.viewExamPoint}
        toggle={() => toggle("viewExamPoint", {}, [])}
        data={listExam}
        item={itemActive}
      />
    </div>
  );
}

export default PointBubble;