import React from 'react';

function DomainTable(props) {
    const { data, handleChart } = props && props;
    const PeriodsData = data?.Periods;
    const LearnersData = data?.Learners;

    const renderTableData = (data) => {
        return (
            <tbody>
                {renderRows(data)}
            </tbody>
        )
    }

    const renderTableHeader = (data) => {
        return (
            <React.Fragment>
                <th className="--text-primary align-middle font-weight-500">Email</th>
                {renderColumns(data)}
                <th width="50px"></th>
            </React.Fragment>
        )
    }

    const renderColumns = (data) => {
        return data?.map((value, index) => {
            const PeriodName = value?.PeriodName;
            return (
                <th className="--text-primary align-middle font-weight-500" key={index} width="20%">{PeriodName}</th>
            )
        })
    }

    const renderItem = (ScoresData) => {
        return ScoresData?.map((value, index) => {
            return (
                <td className="text-center align-middle" key={index}>{value?.Score}</td>
            )
        })
    }

    const renderRows = (data) => {
        return data?.map((value, index) => {
            const Email = value?.Email
            const Scores = value?.Scores;
            return (
                <tr key={index}>
                    <td className="text-left align-middle">{Email}</td>
                    {renderItem(Scores)}
                    <td className="text-center align-middle">
                        <button className="btn btn-sm mx-1 btn-primary" onClick={() => handleChart(Email)}>
                            <i className="fa fa-line-chart" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="table-responsive">
            <table className="table" id='students'>
                <thead>
                    <tr>
                        {renderTableHeader(PeriodsData)}
                    </tr>
                </thead>
                {renderTableData(LearnersData)}
            </table>
        </div>
    );
};

export default DomainTable;