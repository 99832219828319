import React from "react";

const LabsOrders = () => {
  return (
    <div>
      <h2>LabsOrders Component</h2>
      {/* Thêm nội dung của LabsOrders component ở đây */}
    </div>
  );
};

export default LabsOrders;