import React from "react";
import { Container, Col, Row } from 'reactstrap';
import imgLogo from '@images/common/med2lab_logo.png';
import { Link } from "react-router-dom";

//react-redux
import { useSelector } from "react-redux";

function Header(props) {
  const listEngage = useSelector((state) => state?.listEngage?.data || {});
  return (
    <header className="header-container">
      <Container>
        <Row className={`align-items-center`}>
          <Col xs={12} className="logo">
            <div className={`d-flex align-items-center justify-content-center`}>
              <Link className="logo__img" to={"/"}>
                <img className="d-block mx-auto img-fluid" src={listEngage?.logo_url || imgLogo} alt="logo" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header;
