import React from "react";

import { Input } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcUpdateOneOnlyCommon } from "@actions";

//router
import { useParams } from "react-router-dom";

function CheckBoxAvailability(props) {
    const dispatch = useDispatch();
    let { id } = useParams();

    //props
    const { item, isSwitch, TimepointId, typeAvailability, typeFeat } = props && props;

    //handle Change
    const handleChangeCheckbox = (e, item) => {
        const { checked } = e.target;
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: TimepointId,
            [typeFeat === 'labs' ? `LabAvailability` : `StudyAvailability`]: {
                Id: item?.Id,
                Type: typeAvailability,
                Available: checked,
            },
        };

        if (typeFeat === 'labs') {
            dispatch(atcUpdateOneOnlyCommon(id, params, item?.Id));
        }

        if (typeFeat === 'studies') {
            dispatch(atcUpdateOneOnlyCommon(id, params, item?.Id));
        }
    };

    return (
        <td className={`align-middle font-weight-500 text-center`}>
            {isSwitch === 'Availability' && (
                <Input
                    type="checkbox"
                    defaultChecked={item?.Available}
                    data-checked={item?.Available}
                    data-id={item?.Id}
                    key={Math.random()}
                    name="Available"
                    onClick={(e) => handleChangeCheckbox(e, item)}
                    className="m-0 position-relative"
                />
            )}
        </td>
    );
}

export default CheckBoxAvailability;