import React from 'react';
import { Button, Spinner } from 'reactstrap';

const ButtonProceed = ({ loading, onClick, disabled }) => {
  return (
    <Button
      className="button-cta btn-orange m-3"
      onClick={onClick}
      disabled={disabled}
    >
      {loading && <Spinner className="mr-2" size="sm" />}
      Proceed with Selected Orders
    </Button>
  );
};

export default ButtonProceed;