import React from "react";

//boostrap
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

//classnames
import classnames from "classnames";

//untils
import { LoaderText } from "@utils";

//components
import { AiLabelContent, PdfViewer } from "../reliPdf";

function MainTabsPdf(props) {
    //props
    const { isLoading, toggleTab, activeTab } = props && props;

    //Tabs
    const listTabs = [
        {
            id: "1",
            name: "PDF",
            component: <PdfViewer {...props} />,
            show: true,
        },
        {
            id: "2",
            name: "Text",
            component: <AiLabelContent {...props} />,
            show: true,
        },
    ];

    return (
        <div className="tabsPills">
            <Nav tabs className="tabsPills__nav">
                {listTabs?.map(
                    (item, index) =>
                        item?.show && (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames({ active: activeTab === item.id })}
                                    onClick={() => {
                                        toggleTab(item.id);
                                    }}
                                >
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )
                )}
            </Nav>
            <TabContent className="py-0 tabsPills__content position-relative" activeTab={activeTab}>
                {isLoading &&
                    <div className="loader-overlay">
                        <LoaderText colorDot={`white`} />
                    </div>
                }
                {listTabs?.map(
                    (item, index) =>
                        item?.show && (
                            <TabPane key={index} tabId={item.id} className="p-3">
                                {item.component}
                            </TabPane>
                        )
                )}
            </TabContent>
        </div>
    );
}

export default MainTabsPdf;