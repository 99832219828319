import React from 'react';
import styled from 'styled-components';

const MessageShareContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #F79E1C;
  background: #FFFAEB;
  display: flex;
  max-width: 564px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
`;

const MessageShareContent = styled.div`
  color: #F79E1C;
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
`;

const MessageShare = ({ children }) => (
    <MessageShareContainer>
        <MessageShareContent>
            {children}
        </MessageShareContent>
    </MessageShareContainer>
);

export default MessageShare;