import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarItemMain from './SidebarItemMain';
import SidebarItemSubMenu from './SidebarItemSubMenu';
import { Iconsax } from "@components-common";

const SidebarItem = ({ item, index, isSidebarCollapsed, handleClickAction }) => {
    const location = useLocation();

    // State to manage submenu expanded state
    const [isExpanded, setIsExpanded] = useState(false);

    const hasSubMenu = item.subMenu && item.subMenu.length > 0;
    const isActiveItem = location.pathname.startsWith(item.to) || (hasSubMenu && item.subMenu.some(subItem => location.pathname.startsWith(subItem.to)));

    useEffect(() => {
        if (!isSidebarCollapsed && isActiveItem) {
            setIsExpanded(true);
        } else {
            setIsExpanded(false);
        }
    }, [isSidebarCollapsed, isActiveItem]);

    const handleToggleSubmenuExpand = useCallback(() => {
        setIsExpanded(prevState => !prevState);
    }, []);

    const renderMainItem = useCallback(() => {
        const iconColor = isActiveItem ? '#ffffff' : "#697586";
        const arrowIcon = isExpanded ? "chevron-down-sm" : "chevron-right-sm";
        const arrowFill = isExpanded ? "#0089C2" : "#1D2939";
        const itemNameClass = isExpanded ? 'expanded' : 'collapsed';

        return (
            <div
                className={`sidebar-menu__item--link ${isActiveItem ? 'active' : 'not-active'}`}
                onClick={handleToggleSubmenuExpand}
                data-tip
                data-for={`tooltip-${item.name}`}
            >
                <div className='d-flex align-items-center'>
                    {React.cloneElement(item.icon, { color: iconColor })}
                    {!isSidebarCollapsed && (
                        <>
                            <span className={`sidebar-menu__item--name ${itemNameClass}`}>{item.name}</span>
                            <div className="arrow-collapse ml-auto">
                                <Iconsax
                                    iconName={arrowIcon}
                                    fill={arrowFill}
                                    size={14}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }, [isActiveItem, isExpanded, isSidebarCollapsed, item.icon, item.name, handleToggleSubmenuExpand]);

    return (
        !item.isHidden && (
            <li className="sidebar-menu__item" key={index}>
                {hasSubMenu ? (
                    renderMainItem()
                ) : (
                    <SidebarItemMain
                        item={item}
                        isActive={location.pathname.startsWith(item.to)}
                        isSidebarCollapsed={isSidebarCollapsed}
                        handleMainItemClick={handleClickAction ? handleClickAction : handleToggleSubmenuExpand}
                        index={index}
                    />
                )}
                {hasSubMenu && !isSidebarCollapsed && (
                    <ul className={`sidebar-submenu ${isExpanded ? 'expanded' : 'collapsed'}`}>
                        {item.subMenu.map((subItem, subIndex) => (
                            <SidebarItemSubMenu
                                key={subIndex}
                                subItem={subItem}
                                isActive={location.pathname.startsWith(subItem.to)}
                                handleMainItemClick={handleToggleSubmenuExpand}
                                isSidebarCollapsed={isSidebarCollapsed}
                            />
                        ))}
                    </ul>
                )}
            </li>
        )
    );
};

export default SidebarItem;