import { examFindings } from "@constants";
import {apiCaller} from "@utils";

// Case - Load Exam Findings
export const loadExamFindingsElements = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_REQUEST, isLoading } }
  function success(data) { return { type: examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_SUCCESS, payload: data } }
  function failure(error) { return { type: examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_FAILURE, error: error } }
}


//Case - Update Timepoint - Exam Finding
export const updateExamFindingForElement = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));

        const paramsLoad = {
          Action: "Load",
          Target: "ExamFinding",
        };
        //load list
        dispatch(loadExamFindingsElements(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENT_FAILURE,
      error: error,
    };
  }
};

//Case - Update Timepoint - Exam Finding
export const updateExamFindingElements = (id, params) => {
  const isLoading = true;
  return (dispatch) => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (data) {
        dispatch(success(data));
        //load list
        const params = {
          Action: "Load",
          Target: "ExamFinding",
        };
        dispatch(loadExamFindingsElements(id, params));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_REQUEST,
      isLoading,
    };
  }
  function success(data) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: examFindings.UPDATE_TIMEPOINT_EXAM_FINDING_FOR_ELEMENTS_FAILURE,
      error: error,
    };
  }
};

// Case - Load Exam Findings By Attribute Id
export const loadExambyAttributeIdForElement = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_REQUEST, isLoading } }
  function success(data) { return { type: examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_SUCCESS, payload: data } }
  function failure(error) { return { type: examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_FAILURE, error: error } }
}

// Case - Update Exam Finding Order
export const updateExamFindingOrder = (id, params) => {
  const isLoading = true;
  return dispatch => {
    dispatch(request(isLoading));
    return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch(success(data));

        const paramsLoad = {
          Action: "Load",
          Target: "ExamFinding",
        };
        dispatch(loadExamFindingsElements(id, paramsLoad));
      } else {
        dispatch(failure("error"));
      }
    });
  };
  function request(isLoading) { return { type: examFindings.CASE_UPDATE_EXAM_ORDER_REQUEST, isLoading } }
  function success(data) { return { type: examFindings.CASE_UPDATE_EXAM_ORDER_SUCCESS, payload: data } }
  function failure(error) { return { type: examFindings.CASE_UPDATE_EXAM_ORDER_FAILURE, error: error } }
}

// toggleExamMobile
export const toggleExamMobile = (isOpen) => ({
  type: examFindings.TOGGLE_EXAM_MOBILE,
  payload: isOpen,
});