import React, { useState, useCallback, useEffect } from "react";

//Cropper
import Cropper from "react-easy-crop";

//reactstrap
import { CustomInput, Button } from "reactstrap";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "@utils/cropimage";

function CropImageAvatar(props) {
    const { isOpen, srcImage, handleCropImage, handleUploadFile, handleChangeZoom } = props && props;

    //state
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);

    //Crop compeleted
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    //Save
    const handleSubmitApi = useCallback(async () => {
        try {
            const rotation = 0;
            const blobData = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
            handleCropImage(blobData);
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, handleCropImage, imageSrc]);

    //Read file upload
    const readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    };

    //on upload file
    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            handleUploadFile();
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setImageSrc(imageDataUrl);
        }
    };

    //Load
    useEffect(() => {
        if (isOpen) {
            setImageSrc(null);
        }
    }, [isOpen]);

    return (
        <React.Fragment>
            {imageSrc ? (
                <React.Fragment>
                    <div className="py-2 --text-primary" dangerouslySetInnerHTML={{ __html: 'Please press the "Crop photo" button before saving the data for this record.' }} />
                    <div className="position-relative">
                        <div className="cropImgCt">
                            <div className="crop-container">
                                <Cropper
                                    image={imageSrc}
                                    crop={crop}
                                    zoom={zoom}
                                    cropShape="round"
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    aspect={1 / 1}
                                />
                            </div>
                            <div className="controls flex-wrap justify-content-center">
                                <Slider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e, zoom) => {
                                        setZoom(zoom);
                                        handleChangeZoom();
                                    }}
                                    className="slider"
                                />
                                {/* Crop photo */}
                                <Button
                                    className="btn btn-sm mb-4"
                                    color="primary"
                                    onClick={handleSubmitApi}
                                >
                                    <i className="fa fa-crop mr-2" aria-hidden="true"></i>Crop photo
                                </Button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <div className="text-left">
                    {srcImage &&
                        <img className="img-fluid mb-4" style={{ maxWidth: '250px' }} src={srcImage} alt={`avatar`} />
                    }
                    <CustomInput
                        className="text-left"
                        type="file"
                        name="imageFile"
                        id="imageFile"
                        onChange={onFileChange}
                        accept="image/*"
                    />
                </div>
            )}
        </React.Fragment>
    );
}

export default CropImageAvatar;