import React from 'react';
import styled from 'styled-components';

const Tag = styled.div`
  border-radius: 9999px;
  position: relative;
  border: 1px solid ${({ color }) => {
    switch (color) {
      case 'green':
        return '#ABEFC6';
      case 'blue':
        return '#B2DDFF';
      case 'draft':
        return '#F2F4F7';
      case 'warning':
        return '#F9DBAF';
      default:
        return '#F2F4F7';
    }
  }};
  background: ${({ color }) => {
    switch (color) {
      case 'green':
        return '#ECFDF3';
      case 'blue':
        return '#EFF8FF';
      case 'draft':
        return '#F7F8FA';
      case 'warning':
        return '#FEF6EE';
      default:
        return '#F7F8FA';
    }
  }};
  color: ${({ color }) => {
    switch (color) {
      case 'green':
        return '#067647';
      case 'blue':
        return '#2E90FA';
      case 'draft':
        return '#475467';
      case 'warning':
        return '#EF6820';
      default:
        return '#475467';
    }
  }};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  min-width: 44px;
  text-align: center;
  width: min-content;
  white-space: nowrap;
`;

const NumberNote = styled.div`
  position: absolute;
  background: #F04438;
  border-radius: 50%;
  padding: 1.5px 4px;
  font-size: 8px;
  font-weight: 800;
  color: #fff;
  top: -10px;
  right: -6px;
  min-width: 14px;
  min-height: 14px;
`

const TagsText = (props) => {
  const { 
    content, 
    color = 'draft',
    number,
  } = props;

  return (
    <Tag color={color}>
      {content}
      {number && <NumberNote>{number}</NumberNote>}
    </Tag>
  );
};

export default TagsText;