import React from "react";

//boostrap
import { FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

function TextAreaInstruction(props) {
  const {
    handleChangeInstruction,
    stateInstruction
  } = props && props;
  return (
    <React.Fragment>
      <FormGroup>
        <Label className="font-weight-500" for="Comment">Feedback Instruction:</Label>
        <TextareaAutosize
          name="AI_Instructions"
          placeholder="Enter your Feedback Instruction..."
          className="w-100 form-control py-3"
          maxRows="10"
          minRows={7}
          onChange={handleChangeInstruction}
          value={stateInstruction?.AI_Instructions?.Text || ''}
        />
      </FormGroup>
      <FormGroup className="mt-2">
        <Label className="font-weight-500" for="Comment">Scoring Instruction:</Label>
        <TextareaAutosize
          name="Scoring_Instructions"
          placeholder="Enter your Scoring Instruction..."
          className="w-100 form-control"
          maxRows="10"
          minRows={5}
          onChange={handleChangeInstruction}
          value={stateInstruction?.Scoring_Instructions?.Text || ''}
        />
      </FormGroup>
    </React.Fragment>
  );
}

export default TextAreaInstruction;