/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import InputComponent from '@components/teachV2/home/generate/InputComponent'
import GenerateOption from '@components/teachV2/home/generate/GenerateOption'
import { Icon } from '@components/common'
import stick from '@images/teachV2/stick-btn.svg'
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";
import { useTour } from '@reactour/tour'
import { apiCaller } from "@utils";

//components
import { IconCoinBtn } from '@components/Coin';

const FormGenerate = (props) => {
  const { loadingResult, getCoinCaseScriptByTemplate, watch } = props
  const { setIsOpen } = useTour()
  const listTemplate = useSelector((state) => state?.getTemplateCaseScript?.data || []);
  const coinCaseScript = useSelector((state) => (state.getCoinCaseScript && state.getCoinCaseScript.data) || 0);

  useEffect(() => {
    const params = {
      "Action": "ShowPageToolTips",
      "ExtraAction": "TurnOff",
      "Page": "teach-generate_case_script_start"
    }
    apiCaller(`/api/account/tour_guide/`, "POST", params).then((res) => {
      if (res && res.status === 200) {
        if (res.data) {
          setIsOpen(true)
        }
      } else {
        console.log('error');
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="scriptCase">
      <div className="scriptCase__wrap">
        <h1 className="scriptCase__wrap-title">
          Fill Out The Information Below to Begin <br />
          Generating Your <span>Case Scenario</span>
        </h1>
        <div className="scriptCase__wrap-form">
          <div className="inputGenerate__form">
            <InputComponent title="Requirements" textPlaceholder="E.g. Create a case to educate fourth-year medical students on managing a patient with acute appendicitis, using the guidelines provided in the ATTACHED DOCUMENT" {...props}/>
            <GenerateOption listTemplate={listTemplate} handleCoin={getCoinCaseScriptByTemplate} {...props}/>
          </div>
          <div className="inputGenerate__submit w-100  position-relative">
            <button type='submit' className={`${loadingResult || !watch("prompt") ? "disabled" : ""} inputGenerate__submit-btn fifth-step`}>
              <Icon src={stick}/>
              Generate 
              {loadingResult && <ClipLoader color="#FFF" size={16}/>}
            </button>
            <IconCoinBtn {...props} coin={coinCaseScript['btn-generate-script']} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormGenerate
