import React from 'react';
import styled from 'styled-components';

// Icons
import IconMoney from '@images/coin/icon-money.svg';
import BgTop from '@images/coin/bg-total-coins.svg';

const BoxTotalCoins = styled.div`
    height: 118px;
    align-self: stretch;
    border-radius: 12px;
    background: url(${BgTop}), linear-gradient(90deg, #FFFEFE 0%, #FFE9C2 100%);
    background-position: bottom right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
`;

const TotalCoinsTitle = styled.h2`
    color: #081B2A;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
`;

const CoinsText = styled.p`
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0;
`;

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const StyledIcon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const CoinsValue = styled.span`
    color: #F79E1C;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    margin-left: 12px;
`;

const CoinsLabel = styled.span`
    color: #081B2A;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
`;

const TotalCoin = () => {
    return (
        <BoxTotalCoins>
            <TotalCoinsTitle>Total Credits:</TotalCoinsTitle>
            <CoinsText>
                <IconWrapper>
                    <StyledIcon src={IconMoney} alt="5000 Credits" />
                </IconWrapper>
                <CoinsValue>5000</CoinsValue>
                <CoinsLabel>Credits</CoinsLabel>
            </CoinsText>
        </BoxTotalCoins>
    );
};

export default TotalCoin;