import React, { useState, memo } from 'react';
import styled from 'styled-components';
import { Iconsax } from "@components-common";

const AccordionContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const AccordionItem = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const AccordionHeader = styled.div`
  padding: 8px;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
  h4 {
    color: #101828;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
`;

const AccordionBody = styled.div`
  background-color: #ffffff;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${({ isOpen }) => (isOpen ? 'auto' : '0')}; 
`;

const AccordionGroup = styled.div`
  margin: 6px 12px 8px 6px;
`;

const WrapLabel = styled.div` 
  margin: 6px 0 !important;
  padding: 6px;
  background-color: ${({ isChecked }) => (isChecked ? '#F2F4F7' : 'transparent')};
  border-radius: 4px;
  transition: background-color 0.2s ease, border 0.3s ease, box-shadow 0.3s ease;
  // border: 1px solid ${({ isChecked }) => (isChecked ? '#0089C2' : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: #F7F8F9;
    // border: 1px solid #D0D5DD;
    color: #101828;
    ${({ isChecked }) => 
      isChecked &&
      `
        background-color: #fff;
        box-shadow: 0 0 0 3px #b6daff;
      `}
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  color: #101828;
  font-size: 16px;
  font-weight: 400;
`;

const TextItem = styled.span`
  flex: 1;
  color: #101828;
  font-size: 16px;
  font-weight: 500;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
  position: absolute;
  opacity: 0;
`;

const AvailableOrders = memo(({ availableOrders, handleCheckboxChange, selectedOrders, ordersDataFromStore }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const isOrderLocked = (itemId) => {
    return ordersDataFromStore?.AddedAtThisTimePoint?.some(order => order.Id === itemId);
  };

  const handleCheckboxClick = (item) => {
    handleCheckboxChange(item);
  };
  
  return (
    <AccordionContainer>
      {availableOrders?.map((order, index) => (
        <AccordionItem key={index}>
          <AccordionHeader
            onClick={() => toggleAccordion(index)}
            isActive={openIndex === index}
          >
            <h4>{order?.Category}</h4>
            <Iconsax
              iconName={openIndex === index ? "chevron-up" : "chevron-right"}
              fill="#1D2939"
              size={20}
            />
          </AccordionHeader>
          <AccordionBody isOpen={openIndex === index}>
            <AccordionGroup>
              {order?.Orders?.map((item) => (
                <div className="checkBox-custom-style --blue-color" key={item.Id}>
                  <WrapLabel
                    isChecked={selectedOrders.some(o => o.Id === item.Id)}
                    onClick={() => handleCheckboxClick(item)} // Gọi hàm khi click vào WrapLabel
                  >
                    <div className="form-group m-0 d-flex align-items-center">
                      <CheckboxInput
                        type="checkbox"
                        id={`${order.Category}-${item.Id}`}
                        checked={selectedOrders.some(o => o.Id === item.Id)}
                        disabled={isOrderLocked(item.Id)}
                        onChange={() => handleCheckboxChange(item)} // Giữ onChange cho checkbox
                      />
                      <CheckboxLabel
                        className='m-0'
                        htmlFor={`${order.Category}-${item.Id}`}
                      >
                        <TextItem>{item.Name}</TextItem>
                      </CheckboxLabel>
                    </div>
                  </WrapLabel>
                </div>
              ))}
            </AccordionGroup>
          </AccordionBody>
        </AccordionItem>
      ))}
    </AccordionContainer>
  );
});

export default AvailableOrders;