import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//common
import { DataEmty } from "@components-common";

//components
import { ViewExamContent } from '.';

function ModalViewExam(props) {
  const { isOpen, toggle, data } = props && props;

  return (
    <Modal style={{ maxWidth: "800px" }} isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Exam Findings</ModalHeader>
      <ModalBody>
        {data && data.length === 0 &&
          <DataEmty content="No Data" />
        }
        {data && data.length > 0 &&
          <ViewExamContent {...props} />
        }
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="btn btn-default" onClick={toggle}>
          Close
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalViewExam;
