import React from 'react';

//boostrap
import { Input, FormGroup, Label } from "reactstrap";

//components
import { SetAsDemo } from '..';

function General(props) {

    //props
    const { data, handleChange } = props && props;
    const RunMode = data?.RunMode;

    return (
        <React.Fragment>
            <SetAsDemo {...props} />
            <Label>Case Availability:</Label>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`RunMode`}
                        type="radio"
                        value={`all_cases`}
                        className="mt-1"
                        checked={RunMode === "all_cases"}
                        onChange={handleChange}
                    />
                    All cases are made available at once
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label className="d-flex align-items" check>
                    <Input
                        name={`RunMode`}
                        type="radio"
                        value={`sequential`}
                        className="mt-1"
                        checked={RunMode === "sequential"}
                        onChange={handleChange}
                    />
                    Cases are made available in sequential fashion
                </Label>
            </FormGroup>
        </React.Fragment>
    );
};

export default General;