import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//Boostrap
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//react-select
import Select from "react-select";

//actions
import { atcLoadAttributesForEffects } from "@actions";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

function ModalAddEffects(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [optionsValid, setOptionsValid] = useState(false);
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [isLoading, setLoading] = useState(false);

  //props
  const { activeCategory, timePointId, interventionId, typeIntervention, toggle, isOpen, categoryType } = props && props;
  let idCategory = activeCategory;

  //Attributes For Effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = {
          Action: "Load",
          Target: "AttributesForEffects",
          InterventionId: interventionId,
          TimePointId: timePointId,
        };
        if (typeIntervention === 'addEffects') {
          dispatch(atcLoadAttributesForEffects(id, params));
        }
      } catch (err) {
        // error handling code
      }
    }

    // call the async fetchData function
    fetchData()
  }, [dispatch, id, interventionId, timePointId, typeIntervention])

  const checkOptionValid = (value) => {
    if (value) {
      setOptionsValid(false);
      return false;
    } else {
      setOptionsValid(true);
      return true;
    }
  };

  const checkReloadApiForElements = (type) => {
    const params = {}
    const pathName = window.location.pathname.includes("/elements");
    if (pathName) {
      params["ReloadData"] = type;
    }
    return params;
  }

  const handleSubmit = () => {
    setLoading(true);
    if (!checkOptionValid(selectedOption)) {
      const reload = checkReloadApiForElements(categoryType === "Action" ? "Actions" : "Medications");
      const params = {
        "Action": "Create",
        "Target": "Effects",
        "TimePointId": timePointId,
        "InterventionId": interventionId,
        "AttributeId": selectedOption?.value,
        ...reload && reload
      }

      atcCreateEffects(id, params, idCategory, interventionId);
    }
  };

  //Create Effects
  const atcCreateEffects = (id, params, idCategory, interventionId) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: storyboardConstants.CREATE_EFFECTS_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.CREATE_EFFECTS_SUCCESS,
          payload: data,
          InterventionId: interventionId,
          idCategory: idCategory
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.CREATE_EFFECTS_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  const convertDataOptions = (data) => {
    let listOptions = [];
    data && data.map(function (item) {
      const dataReturn = { value: item.AttributeId, label: item.AttributeName };
      return listOptions.push(dataReturn);
    });
    return listOptions;
  }

  const loadAttributes = useSelector(state => state.loadAttributesForEffects || []);
  const listDropdown = convertDataOptions(loadAttributes && loadAttributes.data);
  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #dc3545"
    })
  }
  
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">Add Effect</span>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="AttributeName">Select the attribute that is to be affected by this intervention</Label>
          <Select
            closeMenuOnSelect={true}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={listDropdown}
            placeholder={`Select an item below`}
            styles={optionsValid ? customStyles : ''}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddEffects;
