import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//components
import {
    ExamContainerWrap,
    InstructionsPhysicalExam,
    QuestionsPhysicalExam,
} from "../ManageAvailableTasks/PhysicalExam";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const PhysicalExamMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Exam Findings", content: <ExamContainerWrap {...props} /> },
        { key: 2, label: "Instructions", content: <InstructionsPhysicalExam {...props} /> },
        { key: 3, label: "Questions", content: <QuestionsPhysicalExam {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(3); // 3 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Physical Exam`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default PhysicalExamMain;