//playground
import {
    DiagnosisPage,
    EvaluateNLPPage,
    FreeTextPage,
} from "@pages/med2lab/playground";

const playgroundRoutes = [
    {
        path: "/playground/freetext",
        exact: true,
        classPage: '',
        component: FreeTextPage,
        isLayout: true,
    },
    {
        path: "/playground/diagnosis",
        exact: true,
        classPage: '',
        component: DiagnosisPage,
        isLayout: true,
    },
    {
        path: "/playground/evaluateNLP",
        exact: true,
        classPage: '',
        component: EvaluateNLPPage,
        isLayout: true,
    }
];

export { playgroundRoutes };