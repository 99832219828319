import React from "react";

//boostrap
import { Alert, Spinner } from "reactstrap";

function ActionsSelected(props) {
    //props
    const { dataSub, handleOnClick, isLoading, idClick, heightPatientMedRecord } = props && props;
    return (
        <div className="isShowScroll" style={{ height: `${heightPatientMedRecord}px`}}>
            <Alert
                className="m-alert--default text-1"
                color="secondary"
            >
                <strong>
                    Click to select actions that you'd like to take. Click again to
                    unselect.
                </strong>
                <br />
                (blue background indicates active/selected actions)
            </Alert>
            <hr />
            <ul className="actions-sub isShowScroll" style={{ maxHeight: `${heightPatientMedRecord - 130}px` }}>
                {dataSub?.map((value, index) => (
                    <li
                        className={`actions-sub__item ${value.State === "active" ? "active" : "in-active"
                            }`}
                        onClick={value.State === "inactive" ? (() => handleOnClick(value)) : undefined}
                        key={index}
                    >
                        {isLoading && idClick === value?.Id && (
                            <Spinner
                                color={`${value.State === "inactive" ? "primary" : "white"}`}
                                size="sm"
                                className="mr-2"
                            >
                                Loading...
                            </Spinner>
                        )}
                        <span className="actions-sub__item--text">{value.Name}</span>
                        {value.State === "active" && (
                            <span className="actions-sub__item--icon icon-remove" onClick={() => handleOnClick(value)}></span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ActionsSelected;
