import React, { useState } from "react";

//boostrap
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

//classnames
import classnames from "classnames";

//components
import { RFPViewer, OutlineContent, RequirementsContent } from "../reliProposal";

function MainTabsPdf(props) {
    //state
    const [activeTab, setActiveTab] = useState("1");

    //handle tab
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    //Tabs
    const listTabs = [
        {
            id: "1",
            name: "RFP",
            component: <RFPViewer {...props} />,
            show: true,
        },
        {
            id: "2",
            name: "Outline",
            component: <OutlineContent {...props} />,
            show: true,
        },
        {
            id: "3",
            name: "Requirements",
            component: <RequirementsContent {...props} />,
            show: true,
        }
    ];

    return (
        <div className="tabsPills">
            <Nav tabs className="tabsPills__nav border-bottom pb-1">
                {listTabs?.map(
                    (item, index) =>
                        item?.show && (
                            <NavItem key={index}>
                                <NavLink
                                    className={classnames({ active: activeTab === item.id })}
                                    onClick={() => {
                                        toggleTab(item.id);
                                    }}
                                >
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        )
                )}
            </Nav>
            <TabContent className="py-0 tabsPills__content" activeTab={activeTab}>
                {listTabs?.map(
                    (item, index) =>
                        item?.show && (
                            <TabPane key={index} tabId={item.id} className="py-3">
                                {item.component}
                            </TabPane>
                        )
                )}
            </TabContent>
        </div>
    );
}

export default MainTabsPdf;