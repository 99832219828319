import React from 'react';
import styled from 'styled-components';

import imgUser from '@images/common/placehoder-avatar.svg';

// Các biến chung để sử dụng cho styles
const Colors = {
    border: '#F2F4F7',
    onlineBackground: 'var(--new-green-color)',
    onlineBorder: 'var(--base-white)',
};

const Sizes = {
    sm: '36px',
    md: '44px',
    lg: '56px',
};

// Styled component cho avatar container
const AvatarCircle = styled.div`
    position: relative;
    border: 2px solid ${Colors.border};
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
`;

// Styled component cho user avatar
const AvatarUser = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    cursor: pointer; /* Thêm con trỏ khi di chuột */
`;

// Styled component cho vòng tròn online
const OnlineIndicator = styled.span`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    border: 2px solid ${Colors.onlineBorder};
    background: ${Colors.onlineBackground};
    display: ${(props) => (props.isOnline ? 'block' : 'none')};
    position: absolute;
    right: 0;
    bottom: 0;
`;

const AvatarImage = ({ image, size, isOnline, onHover }) => {
    const avatarSize = Sizes[size] || Sizes.md; // Mặc định là medium nếu không có size hợp lệ

    return (
        <AvatarCircle size={avatarSize}>
            <AvatarUser
                image={image || imgUser}
                onMouseEnter={onHover}
            />
            <OnlineIndicator isOnline={isOnline} />
        </AvatarCircle>
    );
};

export default AvatarImage;