import React, { useEffect } from "react";

//components
import { ProblemAccordionGroup } from './ProblemGroup';

//redux
import { useSelector, useDispatch } from "react-redux";

//actions
import { getListProblem } from "@actions";

//react-router-dom
import { useParams } from "react-router-dom";

function ProblemsTab(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //Load Problems
    useEffect(() => {
        const fetchData = async () => {
            const params = {
                "Action": "LoadProblems",
                "CaseId": id
            }
            dispatch(getListProblem(params));
        };
        fetchData();
    }, [dispatch, id]);

    //get data store
    const dataProblem = useSelector((state) => state?.LoadProblems?.data || []);
    return (
        <div className="problemsTab position-relative">
            <div className="accordionExam ">
                <ProblemAccordionGroup
                    {...props}
                    data={dataProblem || []}
                />
                {/* {dataAccordion.length === 0 && <DataEmty content="No Data" />} */}
            </div>
        </div>
    );
}

export default ProblemsTab; 