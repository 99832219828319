import React from "react";

function HelperList(props) {
  const handleChange = props && props.handleChange;
  const data = props && props.data;
  const listItems = data.map((value, index) => {
    const isViewed = value && value.Viewed;
    return (
      <li
        className={`siblingList__item ${!isViewed ? 'isViewed' : ''}`}
        onClick={() => handleChange(value)}
        key={index}
      >
        <span className="sibling-link">
          <i className="fa fa-file-text-o mr-2" aria-hidden="true"></i>
          {value.Title}
        </span>
      </li>
  )});
  return (
    <React.Fragment>
      <ul className="siblingList">{listItems}</ul>
    </React.Fragment>
  );
}

export default HelperList;
