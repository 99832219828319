import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { uploadFileAnalysis } from '@actions'; // Đảm bảo đường dẫn đúng
import FileDisplay from '../../ReferenceLiblary/FileDisplay';
import FileUpload from '../../ReferenceLiblary/FileUpload';
import FileError from '../../ReferenceLiblary/FileError';
import { apiCaller } from "@utils";

const WrapFormUpload = styled.div`
  .wrap-option {
    .four-step {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
`;

const FileUploadComponent = ({ watch, toggleModal, dataAnalyze, handleGetListAnalyses }) => {
  const dispatch = useDispatch();
  const hiddenFileInputText = useRef(null);
  const [error, setError] = useState('');
  const [dataFileImported, setDataFileImported] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    hiddenFileInputText.current.click();
  };

  const handleDrag = e => {
    e.preventDefault();
  };

  const handleFileChange = file => {
    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setError('Only Excel files are allowed.');
      setDataFileImported(null);
    } else {
      setError('');
      setDataFileImported(file);
    }
  };

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    const file = type === "change" ? event.target.files[0] : event.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleConfirm = () => {
    if (dataFileImported) {
      setLoading(true);
      const formData = new FormData();
      formData.append('Action', 'CreateNewAnswerBatch');
      formData.append('Name', watch("name"));
      formData.append('QuestionItemId', dataAnalyze?.QuestionItemId);
      formData.append('UploadFile', dataFileImported);
      dispatch(uploadFileAnalysis(formData))
        .then(response => {
          if (response?.data?.status === 200) {
            handleGetListAnalyses()
            toggleModal();
            apiCaller(`/api/feedback/question/analysis/`, "POST", {
              Action: "ExecuteAnswerBatchAnalysis",
              QuestionAnswerBatchAnalysisId: response.data?.data?.QuestionAnswerBatchAnalysisId,
            }).then((res) => {
              if (res?.status === 200) {
                handleGetListAnalyses();
              }
            });
          } else {
            setLoading(false);
            setError(response?.data?.data?.error);
          }
        })
        .catch(error => {
          setLoading(false);
          setError('Upload failed. Please try again.');
          toggleModal();
        });
    }
  };

  return (
    <WrapFormUpload>
      <div className='wrap-option four-step'>
        {!dataFileImported && !error && (
          <FileUpload
            handleChange={handleChange}
            handleClick={handleClick}
            handleDrag={handleDrag}
            hiddenFileInputText={hiddenFileInputText}
            type="EXCEL Only"
          />
        )}
        {dataFileImported && !error && (
          <FileDisplay
            dataFileImported={dataFileImported}
            setDataFileImported={setDataFileImported}
            loading={loading}
            handleConfirm={handleConfirm}
            showPara={false}
          />
        )}
        {error && (
          <FileError
            error={error}
            setError={setError}
            setDataFileImported={setDataFileImported}
            fileName={dataFileImported ? dataFileImported.name : 'Unknown file'}
          />
        )}
      </div>
    </WrapFormUpload>
  );
};

export default FileUploadComponent;