import React from "react";

//Icon
import { Iconsax } from "@components-common";

function FlagModal(props) {
    const { toggle } = props && props;
    return (
        <div className="text-right" style={{position: 'absolute', right: '10px', top: '10px'}}>
            <span
                className="open_modal_flag"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    toggle("DiscussThisQuestion");
                }}
                id="DiscussThisQuestion-tooltip"
            >
                <Iconsax iconName="flag-2" fill="#9AA4B2" />
            </span>
        </div>
    );
}

export default FlagModal;