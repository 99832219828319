import React, { useState, useEffect } from "react";

//common
import { DataEmty } from "@components-common";

//test
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

function SortContent(props) {
  const { data, handleSendListSort, typeComponent } = props && props;
  const [itemSort, setItemSort] = useState((data && data) || []);

  //Update Array Store
  useEffect(() => {
    setItemSort((data && data) || []);
  }, [data]);

  // Sort
  const SortableItem = sortableElement(({ item }) => {
    let idItem = '';
    let nameItem = '';
    if(typeComponent === "ExamFindings") {
      idItem = item?.PointId;
      nameItem = item?.ExamName;
    } else {
      nameItem = item?.Name;
      idItem = item?.Id;
    }
    return (
      <li className="lsQuestion__item -box-shadow SortableItemModal border" data-id={idItem}>
        <div className="iconMove"></div>
        <div className="innerCt">
          <div className={`innerCt__left w-100`}>
            <p className="mb-2">
              <span className="font-weight-600 mr-2">#{idItem}</span>
            </p>
            <h3
              dangerouslySetInnerHTML={{
                __html: nameItem
              }}
            ></h3>
          </div>
        </div>
      </li>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="lsQuestion">{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItemSort(arrayMove(itemSort, oldIndex, newIndex));
    handleSendListSort(arrayMove(itemSort, oldIndex, newIndex));
  };

  return (
    <div className="dragQuestionCt">
      <SortableContainer onSortEnd={onSortEnd} lockAxis="y" helperClass="SortableModalContainer" distance={1}>
        {itemSort.map((value, index) => {
          let idItemSort = '';
          if(typeComponent === "ExamFindings") {
            idItemSort = value?.PointId;
          } else {
            idItemSort = value?.Id;
          }
          return (
            <SortableItem
              key={`item-${idItemSort}`}
              index={index}
              item={value}
              style = {{zIndex: 100000}}
            />
          )
        })}
      </SortableContainer>

      {itemSort.length === 0 && <DataEmty content="No Data" />}
    </div>
  );
}

export default SortContent;