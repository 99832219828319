import React, { useState } from 'react';

//components
import { ModalSettings } from '../Modules';

function SettingMain(props) {
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    return (
        <React.Fragment>
            <button
                className="btn-style2 btn-outline-brand mr-2"
                onClick={toggle}
            >
                <i className="fa fa-cogs mr-1" aria-hidden="true"></i> Settings
            </button>
            <ModalSettings
                {...props}
                isOpen={modal}
                toggle={toggle}
            />
        </React.Fragment>
    );
};

export default SettingMain;