import React from "react";

//reacttrap
import { Row, Col } from "reactstrap";

//components
import {
  PieChartList,
  CorrectOrInCorrect,
  ListAnswerChoice
} from '../../Questions';

//Plotly
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
const PlotlyComponent = createPlotlyComponent(Plotly);

function Statistics(props) {
  const { statisticsQuestionReport } = props && props;
  const dataProps = statisticsQuestionReport?.data;
  const Performances = dataProps?.Performances;
  const Statistics = Performances?.Statistics;
  const NumberCorrect = Statistics?.Correct;
  const NumberIncorrect = Statistics?.Incorrect;

  const data = [
    {
      type: "pie",
      direction: "clockwise",
      sort: false,
      values: [
        NumberCorrect || 0,
        NumberIncorrect || 0
      ],
      marker: {
        colors: ["#0089c3", "#ffb822"],
      },
      labels: ["Correct", "Incorrect"],
      hoverinfo: "label+percent",
      textposition: "none",
      automargin: true,
      hole: 0.4,
    },
  ];

  const layout = {
    height: 200,
    width: 200,
    margin: { t: 0, b: 0, l: 0, r: 0 },
    showlegend: false,
  };

  return (
    <React.Fragment>
      <Row className="mx-auto align-items-center" style={{ maxWidth: '900px' }}>
        <Col className="d-flex align-items-center" sm={6}>
          <PlotlyComponent data={data} layout={layout} />
          <CorrectOrInCorrect
            NumberCorrect={NumberCorrect}
            NumberIncorrect={NumberIncorrect}
          />
        </Col>
        <Col sm={6}>
          <ListAnswerChoice
            data={Statistics || []}
          />
        </Col>
      </Row>
      <PieChartList {...props} />
    </React.Fragment>
  );
}

export default Statistics;
