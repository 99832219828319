import React from 'react';

//components
import { ChangeModeFormat } from '../../elements/Labs';

function ListChildren(props) {
  const { data, listTimePointFirst, listTimepointLast, indexRow, typeGroup, handleEdit, itemPanel, handleShowModalChangeValue, isSwitch } = props && props;
  const TimePointDataFirst = listTimePointFirst && listTimePointFirst[0] && listTimePointFirst[0]?.TimePoint;
  return (
    <React.Fragment>
      {data?.map((item, indexItem) => {
        const InitialValue = isSwitch === 'Value' ? item?.InitialValue : "";
        return (
          <tr key={indexItem}>
            <td>{item?.Name}</td>
            <td
              style={{ cursor: 'pointer' }}
              onClick={() => handleEdit(itemPanel, TimePointDataFirst, item, "edit-lab-item")}
            >
              {InitialValue}
            </td>
            {listTimepointLast?.map((itemLast, indexLast) => {
              //INDEX ROW
              const rowDataLast = itemLast[typeGroup][indexRow]?.Items[indexItem];
              const TimePointDataLast = itemLast?.TimePoint;
              const DefaultChange = rowDataLast?.DefaultChange;
              return (
                <td
                  style={{ cursor: 'pointer' }}
                  key={indexLast}
                  onClick={() => handleShowModalChangeValue(rowDataLast, TimePointDataLast)}
                  className="text-center algin-middle"
                >
                  {isSwitch === 'Value' && (
                    <ChangeModeFormat
                      DefaultChange={(DefaultChange && DefaultChange) || {}}
                      row={(rowDataLast && rowDataLast) || {}}
                      TimePointDataLast={TimePointDataLast}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
};

export default ListChildren;