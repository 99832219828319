import React from 'react';

// components
import { HeaderRight, ButtonCollapse } from "@components/layouts/HeaderMakansafe";

const HeaderTop = (props) => {
    const { isSidebarCollapsed } = props && props;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 px-0">
                    <div className={`header_iner bg-white d-flex justify-content-between align-items-center ${isSidebarCollapsed ? 'collapsed' : ''}`}>
                        <HeaderRight {...props} />
                        <ButtonCollapse {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeaderTop;