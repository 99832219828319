export const feedbackConstant = {
    CASE_FEEDBACKS_REQUEST: 'CASE_FEEDBACKS_REQUEST',
    CASE_FEEDBACKS_SUCCESS: 'CASE_FEEDBACKS_SUCCESS',
    CASE_FEEDBACKS_FAILURE: 'CASE_FEEDBACKS_FAILURE',

    LOAD_FEEDBACK_TEMPLATE_REQUEST: 'LOAD_FEEDBACK_TEMPLATE_REQUEST',
    LOAD_FEEDBACK_TEMPLATE_SUCCESS: 'LOAD_FEEDBACK_TEMPLATE_SUCCESS',
    LOAD_FEEDBACK_TEMPLATE_FAILURE: 'LOAD_FEEDBACK_TEMPLATE_FAILURE',

    CREATE_FEEDBACK_TEMPLATE_REQUEST: 'CREATE_FEEDBACK_TEMPLATE_REQUEST',
    CREATE_FEEDBACK_TEMPLATE_SUCCESS: 'CREATE_FEEDBACK_TEMPLATE_SUCCESS',
    CREATE_FEEDBACK_TEMPLATE_FAILURE: 'CREATE_FEEDBACK_TEMPLATE_FAILURE',
    
    UPDATE_FEEDBACK_TEMPLATE_REQUEST: 'UPDATE_FEEDBACK_TEMPLATE_REQUEST',
    UPDATE_FEEDBACK_TEMPLATE_SUCCESS: 'UPDATE_FEEDBACK_TEMPLATE_SUCCESS',
    UPDATE_FEEDBACK_TEMPLATE_FAILURE: 'UPDATE_FEEDBACK_TEMPLATE_FAILURE',

    LOAD_AVAILABLE_FEEDBACK_TEMPLATES_REQUEST: 'LOAD_AVAILABLE_FEEDBACK_TEMPLATES_REQUEST',
    LOAD_AVAILABLE_FEEDBACK_TEMPLATES_SUCCESS: 'LOAD_AVAILABLE_FEEDBACK_TEMPLATES_SUCCESS',
    LOAD_AVAILABLE_FEEDBACK_TEMPLATES_FAILURE: 'LOAD_AVAILABLE_FEEDBACK_TEMPLATES_FAILURE',

    ASSIGN_FEEDBACK_TEMPLATE_REQUEST: 'ASSIGN_FEEDBACK_TEMPLATE_REQUEST',
    ASSIGN_FEEDBACK_TEMPLATE_SUCCESS: 'ASSIGN_FEEDBACK_TEMPLATE_SUCCESS',
    ASSIGN_FEEDBACK_TEMPLATE_FAILURE: 'ASSIGN_FEEDBACK_TEMPLATE_FAILURE',

    FEEDBACK_ASSISTANT_MESSAGE_REQUEST: 'FEEDBACK_ASSISTANT_MESSAGE_REQUEST',
    FEEDBACK_ASSISTANT_MESSAGE_SUCCESS: 'FEEDBACK_ASSISTANT_MESSAGE_SUCCESS',
    FEEDBACK_ASSISTANT_MESSAGE_FAILURE: 'FEEDBACK_ASSISTANT_MESSAGE_FAILURE',
    
    DOMAIN_HANDLE_REPORT_PROGRESS_REQUEST: 'DOMAIN_HANDLE_REPORT_PROGRESS_REQUEST',
    DOMAIN_HANDLE_REPORT_PROGRESS_SUCCESS: 'DOMAIN_HANDLE_REPORT_PROGRESS_SUCCESS',
    DOMAIN_HANDLE_REPORT_PROGRESS_FAILURE: 'DOMAIN_HANDLE_REPORT_PROGRESS_FAILURE',

    REMOVE_XPDATAUPDATE: 'REMOVE_XPDATAUPDATE',
};