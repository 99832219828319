import React from "react";

//router
import { useParams } from "react-router-dom";

//action
import { actDeleteBalanceId } from "@actions";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalDeleteIO(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { timePointId, toggleDelete, isOpen, AttributeId, typeBalance } = props && props;

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "IO",
      TimePointId: timePointId,
      AttributeId: AttributeId
    }
    dispatch(actDeleteBalanceId(id, params));
    toggleDelete();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleDelete}
    >
      <ModalBody>
        <h4 className="text-center heading-4 --text-primary">
          Are you sure you would like to delete this <br /> {typeBalance} Item from the case?
        </h4>
        <p className="mt-2 text-center">This action is not reversible</p>
      </ModalBody>
      <ModalFooter>
        <Button
          className="btn btn-danger d-flex align-items-center"
          onClick={handleRemove}
        >
          Delete
        </Button>
        <Button
          className="btn btn-secondary"
          onClick={toggleDelete}
        >
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDeleteIO;
