import React from 'react';

function IconDisplay(props) {
    const { type } = props && props;
    return (
        <React.Fragment>
            {type === "ImageFile" && (
                <span
                    style={{ fontSize: "30px" }}
                    className="icon-picture"
                ></span>
            )}
            {type === "VideoLink" && (
                <span
                    style={{ fontSize: "30px" }}
                    className="icon-youtube-logotype"
                ></span>
            )}
            {type === "VideoFile" && (
                <span
                    style={{ fontSize: "30px" }}
                    className="icon-video"
                ></span>
            )}
            {type === "AudioFile" && (
                <span
                    style={{ fontSize: "30px" }}
                    className="icon-audio-book"
                ></span>
            )}
        </React.Fragment>
    );
};

export default IconDisplay;