import React from "react";

//logo default
import iconClose from "@images/common/close_modal.svg";

//Boostrap
import { Button } from "reactstrap";

//components
import { MedicalRecordTabs } from '.';

function ModalMedicalRecord(props) {
  //props
  const { isOpen, handleExamMenu, isLoading, handleShowDocumentation } = props && props;

  return (
    <div
      className={`boxPushCt showRight scrollbarStyle fixed w-50 pt-0 ${isOpen && "open-right"
        }`}
      style={{ backgroundColor: '#fffbf1' }}
    >
      <div className="boxPushCt__heading p-3 border-bottom-0">
        <img
          className="icon-exit-to-app-button ml-auto closeIcon"
          src={iconClose}
          alt="close modal"
          onClick={() => handleExamMenu("documentation", 'closeTask')}
        />
      </div>
      <div className="boxPushCt__body border">
        <div className="d-flex align-items-center p-3 mb-2">
          <h3 className="heading-3 p-0 m-0">{`Documentation`}</h3>
          <div className="ml-auto d-flex">
            <Button
              color="primary"
              className="btn-sm d-flex align-items-center --bg-primary"
              // onClick={handleToggleERM}
            >
              <span className="icon-emr-2 mr-2 text-4"></span>
              Review
            </Button>
            <Button
              color="success"
              className="btn-sm d-flex align-items-center ml-2"
              onClick={handleShowDocumentation}
            >
              {isLoading ? <span className="spinner-border spinner-border-sm mr-2 text-now"></span> : <span className="fa fa-pencil-square-o text-4 mr-2"></span>}
              Review My Note
            </Button>
          </div>
        </div>
        <div className="p-3">
          {isOpen && (
            <MedicalRecordTabs
              {...props}
            />
          )}
        </div>
      </div>
    </div>
    // </Resizable>
  );
}

export default ModalMedicalRecord;