import React from 'react'
import McqWaiting from '@images/teachV2/waiting-mcq.gif'

export default function LoadingGenerate(props) {
  const { title, subtitle } = props
  return (
    <div className="loading-generate">
      <img src={McqWaiting} alt="mcq" />
      <div className="loading-generate__content">
        <h1>{title}</h1>
        <p>{subtitle}</p>
      </div>
    </div>
  )
}
