import { ticketConstant } from "@constants";
import {apiCaller} from "@utils";

//Update Assistant Tip Record
export const actSubmitTicket = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/ticket/submit/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: ticketConstant.SUBMIT_TICKET_REQUEST, isLoading } }
    function success(data) { return { type: ticketConstant.SUBMIT_TICKET_SUCCESS, payload: data } }
    function failure(error) { return { type: ticketConstant.SUBMIT_TICKET_FAILURE, error: error } }
}

//Load Open Tickets
export const actLoadTicket = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/ticket/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: ticketConstant.GET_TICKET_REQUEST, isLoading } }
    function success(data) { return { type: ticketConstant.GET_TICKET_SUCCESS, payload: data } }
    function failure(error) { return { type: ticketConstant.GET_TICKET_FAILURE, error: error } }
}

//Admin Update Ticket
export const actUpdateTicket = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/ticket/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: ticketConstant.UPDATE_TICKET_REQUEST, isLoading } }
    function success(data) { return { type: ticketConstant.UPDATE_TICKET_SUCCESS, payload: data } }
    function failure(error) { return { type: ticketConstant.UPDATE_TICKET_FAILURE, error: error } }
}