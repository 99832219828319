import React, { useState } from 'react';

//reactstrap
import { Input } from "reactstrap";
import IconSend from "@images/chatsmart/send-icon.svg";
import Refresh from "@images/chatsmart/refresh.svg";

function InputFreeText(props) {
    const { handleInput } = props && props;

    //state
    const [value, setValue] = useState('');

    //send Api
    const handleSubmit = () => {
        handleInput(value);

        //reset
        setValue('');
    }

    //handlechange
    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    //Handle Key Press
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    return (
        <div className="typing p-3">
            <div className="typing__refresh">
                <img src={Refresh} alt="Refresh" className="img-fluid" />
            </div>
            <div className="typing__input">
                <Input
                    name="Message"
                    placeholder={`Message`}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={value}
                    autoComplete="off"
                />
            </div>
            <button
                type="button"
                className="typing__btn btn btn-primary d-flex align-items-center justify-content-center"
                onClick={handleSubmit}
            >
                <img src={IconSend} alt="Send" className="img-fluid" />
            </button>
        </div>
    );
};

export default InputFreeText;