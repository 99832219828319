import React from "react";

//reactstrap
import {
  FormGroup,
  Label,
  Col,
  CustomInput
} from "reactstrap";

//React PLayer
import ReactPlayer from "react-player";

function AudioFile (props) {

  const value = props && props.value;
  const onChange = props && props.onChange;
  return (
    <React.Fragment>
      <FormGroup row>
        <Label className="text-left" for="AudioFile" sm={3}>
          File Audio
        </Label>
        <Col sm={9}>
          <CustomInput
            id="AudioFile"
            onChange={onChange}
            className="text-left"
            type="file"
            name="AudioFile"
            accept="audio/*"
          />
        </Col>
      </FormGroup>
      {value && (
        <ReactPlayer
          url={value || ""}
          width="100%"
          height="50px"
          controls={true}
          loop={true}
        />
      )}
    </React.Fragment>
  );
}

export default AudioFile;
