import React, { useState, useEffect } from "react";

//api
import { apiCaller } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

function ImmediateFeedbackModeSwitch(props) {
    const { itemQuestion } = props && props;
    let { id } = useParams();

    //state
    const [ImmediateFeedbackMode, setImmediateFeedbackMode] = useState(itemQuestion?.ImmediateFeedbackMode || false);

    // Load
    useEffect(() => {
        setImmediateFeedbackMode(itemQuestion?.ImmediateFeedbackMode || false);
    }, [itemQuestion])

    //Handle Change Switch
    const handleChangeSwitch = (e) => {
        const { checked } = e.target;
        setImmediateFeedbackMode(checked);
        handleApiUpdate(checked);
    }

    //Case - Set Immediate Feedback Mode for Question
    const handleApiUpdate = (status) => {
        const params = {
            "Target": "Question_ImmediateFeedbackMode",
            "Action": "Update",
            "QuestionId": itemQuestion?.QuestionId,
            "ImmediateFeedbackMode": status
        }
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                console.log('SUCCESS');
            } else {
                console.log('error');
            }
        });
    }

    console.log(itemQuestion?.ImmediateFeedbackMode, "itemQuestion?.ImmediateFeedbackMode...");
    return (
        <div className="py-3 align-items-center d-flex">
            <span className="font-weight-500 mr-4">Give Immediate Feedback</span>
            <div className="switches d-inline-flex">
                <input
                    type="checkbox"
                    name={"ImmediateFeedbackMode"}
                    checked={ImmediateFeedbackMode}
                    onChange={handleChangeSwitch}
                    id={`ImmediateFeedbackMode`}
                    readOnly
                />
                <label htmlFor={`ImmediateFeedbackMode`}>
                    <span />
                </label>
            </div>
        </div>
    );
}

export default ImmediateFeedbackModeSwitch;