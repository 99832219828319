import React, { useState, useEffect } from "react";
import { Input, Alert, Button } from "reactstrap";

function TableAnswer(props) {
  const {
    answerData,
    handleChangeInput,
    handleRemoveAnswer,
    isQuestionType,
    handleAddAnswer
  } = props && props;

  //state
  const [stateData, setData] = useState(answerData || []);

  useEffect(() => {
    setData(answerData || []);
  }, [answerData]);
  
  return (
    <React.Fragment>
      <table
        className="table table-responsive"
        style={{ display: answerData.length === 0 ? "table" : "block" }}
      >
        <thead>
          <tr>
            <th className="font-weight-500">#</th>
            <th className="font-weight-500" style={{ width: "30%" }}>
              Answer Text <span className="m--font-danger">*</span>
            </th>
            {isQuestionType === "free_text" && (
              <th className="font-weight-500" style={{ width: "30%" }}>
                Regex
              </th>
            )}
            <th className="font-weight-500" style={{ width: "30%" }}>
              Explanation
            </th>
            <th className="font-weight-500">Correct?</th>
            <th className="font-weight-500" style={{ width: "15%" }}>
              Point
            </th>
            <th></th>
          </tr>
        </thead>
        {isQuestionType === 'drag_n_drop' &&
          <tbody>
            <tr>
              <td colSpan="100%">
                <Alert color="default" className="m-alert--default">
                  For drag-n-drop question, enter the different answer choices in the correct order below. Mark the checkbox only for answer choices that are correct.
                  The learner will answer this correctly only if he/she put all correct answers in the right order.
                </Alert>
              </td>
            </tr>
          </tbody>
        }
        <tbody>
          {stateData?.map((value, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <textarea
                    name="AnswerContent"
                    className="form-control m-input"
                    rows={1}
                    value={value?.AnswerContent}
                    data-index={index}
                    onChange={handleChangeInput}
                  />
                </td>
                {isQuestionType === "free_text" && (
                  <td>
                    <textarea
                      name="RegEx"
                      className="form-control m-input"
                      rows={1}
                      value={value?.RegEx}
                      data-index={index}
                      onChange={handleChangeInput}
                    />
                  </td>
                )}
                <td>
                  <textarea
                    name="Explanation"
                    className="form-control m-input"
                    rows={1}
                    value={value?.Explanation}
                    data-index={index}
                    onChange={handleChangeInput}
                  />
                </td>
                <td className="align-middle">
                  <div className="d-flex align-items-center justify-content-center">
                    <Input
                      className="m-0"
                      name="isCorrect"
                      type="checkbox"
                      checked={value?.isCorrect}
                      data-index={index}
                      onChange={handleChangeInput}
                    />{" "}
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    name="Score"
                    className="form-control m-input"
                    value={value?.Score}
                    data-index={index}
                    onChange={handleChangeInput}
                    step="any"
                  />
                </td>
                <td className="align-middle">
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-2x fa-times text-danger"
                    aria-hidden="true"
                    onClick={() => handleRemoveAnswer(index)}
                  ></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Button
        className="btn btn-outline-brand d-block mx-auto mt-4"
        onClick={handleAddAnswer}
      >
        <i className="fa fa-plus" />
        &nbsp; More Options
      </Button>
    </React.Fragment>
  );
}

export default TableAnswer;
