import React from "react";

function IconChangeAvatar(props) {
    const { toggle } = props && props;
    return (
        <div className="avatar-change" onClick={() => toggle('pickAvatar')}>
            <button className="avatar-change__btn">
                <svg width="18" height="18" viewBox="0 0 24 24" focusable="false"><path d="M20 5h-3.17L15 3H9L7.17 5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 14H4V7h16v12z"></path><path d="M12 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z"></path></svg>
            </button>
        </div>
    );
}

export default IconChangeAvatar;