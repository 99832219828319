import React from "react";

//reactstrap
import { Col } from "reactstrap";

//Icon
import { Iconsax } from "@components-common";

const AddUpdateFoodForm = ({ addModificationLevel }) => {
    return (
        <Col md={12}>
            <div className="card-area bg-grey p-2 mb-4 w-100">
                <div className="btn-add-item --border-blue --size-sm text-center m-0" onClick={addModificationLevel}>
                    <Iconsax iconName="plus" fill="#0089C2" size={15} />
                    <span className="ml-2 btn-add-item__text --text-blue">Add new modify level option</span>
                </div>
            </div>
        </Col>
    )
};

export default AddUpdateFoodForm;