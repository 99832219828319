import { ordersConstants } from "@constants";
import { apiCaller } from "@utils";

//fetch Case
export const actFetchOrderRequest = (idParam) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/orders/${idParam}/`, 'GET', null).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: ordersConstants.LOAD_ORDER_REQUEST, isLoading } }
    function success(data) { return { type: ordersConstants.LOAD_ORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: ordersConstants.LOAD_ORDER_FAILURE, error: error } }
}

export const actPutItemOrder = (id, params) => {
    return (dispatch) => {
        // Trả về Promise từ apiCaller
        return apiCaller(`/api/learn/case/orders/${id}/`, 'PUT', params)
            .then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    dispatch(success(data));
                    return data; // Trả về dữ liệu nếu thành công
                } else {
                    dispatch(failure("error"));
                    throw new Error("Error updating order"); // Ném lỗi nếu không thành công
                }
            })
            .catch(error => {
                // Xử lý lỗi nếu cần thiết
                dispatch(failure(error.message)); // Gọi failure với thông điệp lỗi
                throw error; // Ném lỗi để có thể xử lý ở nơi gọi
            });
    };

    function success(data) {
        return { type: ordersConstants.UPDATE_ORDER_THIS_TIMEPOINT_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: ordersConstants.UPDATE_ORDER_THIS_TIMEPOINT_FAILURE, error: error };
    }
};

export const onSignOrdersApiCall = (id, params) => {
    return (dispatch) => {
        // Return Promise from apiCaller
        return apiCaller(`/api/learn/case/orders_v2/${id}/`, 'PUT', params)
            .then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    dispatch(success(data));
                    return data; // Return data on success
                } else {
                    dispatch(failure("error"));
                    throw new Error("Error signing orders"); // Throw error if unsuccessful
                }
            })
            .catch(error => {
                // Handle error if needed
                dispatch(failure(error.message)); // Dispatch failure with error message
                throw error; // Throw error to be handled where called
            });
    };

    function success(data) {
        return { type: ordersConstants.SIGN_ORDERS_SUCCESS, payload: data };
    }
    function failure(error) {
        return { type: ordersConstants.SIGN_ORDERS_FAILURE, error: error };
    }
};

export const removeOrdersApiCall = (idCase, params) => {
    const isLoading = false;
    return (dispatch) => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/orders/${idCase}/`, 'PUT', params)
            .then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    dispatch(success(data));
                    return data; // Return data on success
                } else {
                    dispatch(failure("Error removing order")); // Dispatch failure action
                }
            })
            .catch(error => {
                dispatch(failure(error.message)); // Dispatch failure with error message
                throw error; // Rethrow error to be handled where called
            });
    };

    function request(isLoading) { return { type: ordersConstants.REMOVE_ORDER_REQUEST, isLoading } }
    function success(data) { return { type: ordersConstants.REMOVE_ORDER_SUCCESS, payload: data } }
    function failure(error) { return { type: ordersConstants.REMOVE_ORDER_FAILURE, error: error } }
};