export const cohortsConstant = {
    GET_COHORT_REQUEST: 'GET_COHORT_REQUEST',
    GET_COHORT_SUCCESS: 'GET_COHORT_SUCCESS',
    GET_COHORT_FAILURE: 'GET_COHORT_FAILURE',

    LOAD_COHORTS_FOR_REVIEW_REQUEST: 'LOAD_COHORTS_FOR_REVIEW_REQUEST',
    LOAD_COHORTS_FOR_REVIEW_SUCCESS: 'LOAD_COHORTS_FOR_REVIEW_SUCCESS',
    LOAD_COHORTS_FOR_REVIEW_FAILURE: 'LOAD_COHORTS_FOR_REVIEW_FAILURE',

    LOAD_COHORT_BY_ID_REQUEST: 'LOAD_COHORT_BY_ID_REQUEST',
    LOAD_COHORT_BY_ID_SUCCESS: 'LOAD_COHORT_BY_ID_SUCCESS',
    LOAD_COHORT_BY_ID_FAILURE: 'LOAD_COHORT_BY_ID_FAILURE',

    CREATE_COHORT_REQUEST: 'CREATE_COHORT_REQUEST',
    CREATE_COHORT_SUCCESS: 'CREATE_COHORT_SUCCESS',
    CREATE_COHORT_FAILURE: 'CREATE_COHORT_FAILURE',

    UPDATE_COHORT_REQUEST: 'UPDATE_COHORT_REQUEST',
    UPDATE_COHORT_SUCCESS: 'UPDATE_COHORT_SUCCESS',
    UPDATE_COHORT_FAILURE: 'UPDATE_COHORT_FAILURE',

    DELETE_COHORT_REQUEST: 'DELETE_COHORT_REQUEST',
    DELETE_COHORT_SUCCESS: 'DELETE_COHORT_SUCCESS',
    DELETE_COHORT_FAILURE: 'DELETE_COHORT_FAILURE',

    ADD_LEARNERS_TO_COHORT_REQUEST: 'ADD_LEARNERS_TO_COHORT_REQUEST',
    ADD_LEARNERS_TO_COHORT_SUCCESS: 'ADD_LEARNERS_TO_COHORT_SUCCESS',
    ADD_LEARNERS_TO_COHORT_FAILURE: 'ADD_LEARNERS_TO_COHORT_FAILURE',

    REMOVE_LEARNERS_TO_COHORT_REQUEST: 'REMOVE_LEARNERS_TO_COHORT_REQUEST',
    REMOVE_LEARNERS_TO_COHORT_SUCCESS: 'REMOVE_LEARNERS_TO_COHORT_SUCCESS',
    REMOVE_LEARNERS_TO_COHORT_FAILURE: 'REMOVE_LEARNERS_TO_COHORT_FAILURE'
};
