import React, { useState, useEffect } from 'react';
import { Table } from "reactstrap";

//components
import { ButtonAdd, HeadingColumn, RowTable } from "../MutipleChoiTableType";

//lodash
import _ from "lodash";

// Example use --------------------
const MutipleChoiceTable = (props) => {
    const { answerTable, handleUpdateStateAnswerTable } = props && props;
    const [stateAnswerTable, setStateAnswerTable] = useState(answerTable || []);

    //Load Data
    useEffect(() => {
        setStateAnswerTable(answerTable || []);
    }, [answerTable]);

    //Handle Change Checkbox
    const handleCheckbox = (rowIndex, ColIndex, e) => {
        const { checked } = e.target;
        const listDataClone = [...stateAnswerTable];
        listDataClone[rowIndex][`Columns`][ColIndex][`isCorrect`] = checked;

        //update State
        handleUpdateStateAnswerTable(listDataClone);
    }

    //Clone Cloumn for Add Row
    const cloneColumnForRow = () => {
        let newArrPush = [];
        if (stateAnswerTable?.length > 0) {
            let arrColumns = _.cloneDeep(stateAnswerTable[0]?.Columns);
            _.map(arrColumns, (value) => {
                value[`isCorrect`] = false;
            });
            newArrPush = arrColumns;
        }
        return newArrPush;
    }

    //Push item for Column
    const pushColumns = (newItem, arrColumns) => {
        let newArrPush = [...arrColumns];
        newArrPush.push(newItem);
        return newArrPush;
    }

    //Handle Add Item
    const handleAddItem = (type) => {
        let statDataClone = [...stateAnswerTable];
        let innitColumn = {
            ColumnTitle: "New Column",
            isCorrect: false
        }

        let initRow = {
            RowTitle: `Row ${stateAnswerTable?.length + 1}`,
            Columns: cloneColumnForRow()
        }

        if (type === "Row") {
            let newRow = [...statDataClone, initRow];
            setStateAnswerTable(newRow);

            //update State
            handleUpdateStateAnswerTable(newRow);
        }
        if (type === "Column") {
            let cloneDataForColumns = [...stateAnswerTable];
            _.map(cloneDataForColumns, (value) => {
                value[`Columns`] = pushColumns(innitColumn, value[`Columns`]);
            });
            setStateAnswerTable(cloneDataForColumns);

            //update State
            handleUpdateStateAnswerTable(cloneDataForColumns);
        }
    }

    const handleRemoveItem = (indexItem, type) => {
        let cloneState = [...stateAnswerTable];
        if (type === "Column") {
            const newData = _.map(cloneState, (value) => {
                if (value?.Columns.length > 0) {
                    let newArrColumns = [...value?.Columns];
                    const resultRemoveIndexCol = _.remove(newArrColumns, function (c, index) {
                        return index !== indexItem;
                    });
                    return { ...value, Columns: resultRemoveIndexCol };
                }
            });
            setStateAnswerTable(newData);

            //update State
            handleUpdateStateAnswerTable(newData);
        } else {
            const resultRemoveIndexRow = _.remove(cloneState, function (c, index) {
                return index !== indexItem;
            });
            setStateAnswerTable(resultRemoveIndexRow);

            //update State
            handleUpdateStateAnswerTable(resultRemoveIndexRow);
        }
    }

    return (
        <div className="table-layout-fixed mutiple_choice_table">
            <ButtonAdd handleAddItem={handleAddItem} />
            <Table bordered responsive>
                <tbody>
                    <tr>
                        <th width={`300`}>&nbsp;</th>
                        {stateAnswerTable?.map((value, index) => (
                            <React.Fragment key={index}>
                                {index === 0 && value?.Columns?.map((itemCol, j) => (
                                    <HeadingColumn
                                        {...props}
                                        key={j}
                                        itemCol={itemCol}
                                        indexCol={j}
                                        handleRemoveItem={handleRemoveItem}
                                        stateAnswerTable={stateAnswerTable}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </tr>
                    {stateAnswerTable?.map((rows, index) => {
                        const Columns = rows?.Columns;
                        const rowIndex = index;
                        return (
                            <RowTable
                                {...props}
                                key={rowIndex}
                                rowIndex={rowIndex}
                                rows={rows}
                                columns={Columns}
                                handleCheckbox={handleCheckbox}
                                handleRemoveItem={handleRemoveItem}
                                stateAnswerTable={stateAnswerTable}
                            />
                        )
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default MutipleChoiceTable;