import React, { useState } from 'react';

// Icons
import IconMoney from '@images/coin/icon-money.svg';

// react-redux
import { useSelector } from "react-redux";

// components
import { CoinList, PurchaseCoin, ModalCoinsEarned, ModalPurchaseCoinItComingSoon } from '@components/Coin';

const EarnCoin = (props) => {

    // props
    const { handleCloseDropdown } = props;

    // redux
    const coin = useSelector(state => state.getCoin?.data || {});

    // state
    const [showCoinList, setShowCoinList] = useState(true);
    const [modal, setModal] = useState({
        PurchaseCoin: false,
        ComingSoon: false
    });

    // Handle Click Back
    const handleClickBack = () => {
        setShowCoinList(true);
    }

    // Handle Click Purchase Coin
    const toggle = (type) => {
        setModal({ ...modal, [type]: !modal[type] });

        // Reset
        handleCloseDropdown();
    }

    const { CoinRewardOptions = [] } = coin;
    return (
        <React.Fragment>
            <div className='earn-coin-dropdown arrow-top'>
                <div className="earn-coin-dropdown__header">
                    <div className="coint-couter">
                        <p>Total Credits:</p>
                        <div className="coint-number">
                            <img className='coint-number__icon' src={IconMoney} alt="1000 coin" />
                            <div className="coint-number-inner">
                                <span className='counter'>{coin?.Remaining}</span>&nbsp;<span className='name'>Credits</span>
                            </div>
                        </div>
                    </div>
                    {showCoinList &&
                        <div className="btn-purchase" onClick={() => toggle("ComingSoon")}>
                            Purchase More Credits
                        </div>
                    }
                </div>
                {CoinRewardOptions?.length > 0 && (
                    <div className="earn-coin-dropdown__body">
                        {showCoinList ? <CoinList {...props} /> :
                            <PurchaseCoin
                                {...props}
                                handleClickBack={handleClickBack}
                                handlePurchaseCoin={() => toggle("PurchaseCoin")}
                            />
                        }
                    </div>
                )}
            </div>
            <ModalCoinsEarned
                {...props}
                isOpen={modal.PurchaseCoin}
                toggle={() => toggle("PurchaseCoin")}
            />
            <ModalPurchaseCoinItComingSoon
                {...props}
                isOpen={modal.ComingSoon}
                toggle={() => toggle("ComingSoon")}
            />
        </React.Fragment>
    );
};

export default EarnCoin;