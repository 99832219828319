import React, { useState, useEffect } from "react";

//reactstrap
import { Button } from "reactstrap";

function AnswerItem(props) {
  const {
    AnswersGroupChild,
    indexParent,
    handleChangeInput,
    handleAddItemAnswerForGroup,
    handleRemoveItemAnswerGroup,
  } = props && props;

  //state
  const [stateData, setData] = useState(AnswersGroupChild || []);

  //load
  useEffect(() => {
    setData(AnswersGroupChild || []);
  }, [AnswersGroupChild]);

  return (
    <React.Fragment key={indexParent}>
      {stateData?.map((value, index) => {
        return (
          <div className="d-flex align-items-center" key={index}>
            <textarea
              name={`AnswerContent`}
              className="form-control m-input my-2"
              rows={1}
              value={value?.AnswerContent || ""}
              data-index-parent={indexParent}
              data-index-child={index}
              onChange={handleChangeInput}
              id={`AnswerContent_${indexParent}_${index}`}
            />
            <i
              style={{ cursor: "pointer" }}
              className="fa fa-times text-danger ml-2 text-4"
              aria-hidden="true"
              onClick={() => handleRemoveItemAnswerGroup(indexParent, index)}
            ></i>
          </div>
        );
      })}
      <Button
        className="btn btn-outline-brand btn-sm d-block mx-auto mt-2"
        onClick={() => handleAddItemAnswerForGroup(indexParent)}
      >
        <i className="fa fa-plus" />
        &nbsp; Add
      </Button>
    </React.Fragment>
  );
}

export default AnswerItem;
