export const balanceConstants = {
    UPDATE_VALUE_BALANCE_REQUEST: 'UPDATE_VALUE_BALANCE_REQUEST',
    UPDATE_VALUE_BALANCE_SUCCESS: 'UPDATE_VALUE_BALANCE_SUCCESS',
    UPDATE_VALUE_BALANCE_FAILURE: 'UPDATE_VALUE_BALANCE_FAILURE',

    DELETE_IO_BALANCE_REQUEST: 'DELETE_IO_BALANCE_REQUEST',
    DELETE_IO_BALANCE_SUCCESS: 'DELETE_IO_BALANCE_SUCCESS',
    DELETE_IO_BALANCE_FAILURE: 'DELETE_IO_BALANCE_FAILURE',

    ADD_IO_BALANCE_REQUEST: 'ADD_IO_BALANCE_REQUEST',
    ADD_IO_BALANCE_SUCCESS: 'ADD_IO_BALANCE_SUCCESS',
    ADD_IO_BALANCE_FAILURE: 'ADD_IO_BALANCE_FAILURE'
};