import { teachConstants, profileConstant } from "@constants";

export function loadTimeZoneInstitution (state = {}, action) {
    switch (action.type) {
        case teachConstants.GET_INSTITUTION_TIME_ZONE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case teachConstants.GET_INSTITUTION_TIME_ZONE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case teachConstants.SET_INSTITUTION_TIME_ZONE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case teachConstants.GET_INSTITUTION_TIME_ZONE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadAccountTimeZone (state = {}, action) {
    switch (action.type) {
        case profileConstant.GET_ACCOUNT_TIME_ZONE_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case profileConstant.GET_ACCOUNT_TIME_ZONE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case profileConstant.GET_ACCOUNT_TIME_ZONE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};