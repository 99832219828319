import React from 'react';

function ButtonEdit(props) {
    const { item, toggleEditHeader, type } = props && props;
    return (
        <button className="btn btn-primary actionConcept__btn actionConcept__btn--Edit btn-sm" onClick={() => toggleEditHeader(type, item)}>
            <i className="fa fa-pencil" aria-hidden="true"></i>
        </button>
    );
};

export default ButtonEdit;