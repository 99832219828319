import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalConfirm(props) {

    //props
    const { isOpen, toggle, handleConfirm, loaderModal, type } = props && props;

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
            <ModalBody className="text-center">
                <h4 className="text-center heading-4 --text-primary">
                    Are you sure you want to save the instruction?
                </h4>
                <p className="mt-2 text-center">
                    This will overwrite the instruction that is currently attached to this question
                </p>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn --btn-primary d-flex align-items-center"
                    onClick={() => handleConfirm(type)}
                    disabled={loaderModal[type]}
                >
                    {loaderModal[type] && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Save
                </Button>
                <Button className="btn btn-secondary" onClick={() => toggle(type)}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalConfirm;
