import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

//components
import { AddCondition } from '../Modules';

//reactstrap
import { Row, Col } from "reactstrap";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//call Action
import { actSortDataCase } from "@actions";

//lodash
import _ from "lodash";

//redux
import { useDispatch } from "react-redux";

const SortableItem = SortableElement(({ value, toggleModalDel }) => (
    <li className={`ListSortableElement__item hasDrag -box-shadow p-4`} key={value?.Id}>
        <Row className="align-items-center">
            <Col md={7} sm={12}>
                <i className="fa fa-bars iconDrag" aria-hidden="true"></i>
                <h6 className="title ml-3">
                    {value?.Title}
                    <span className="text-muted ml-2 text-1">{`(#${value?.Id})`}</span>
                </h6>
            </Col>
            <Col md={5} sm={12}>
                <div className="text-right">
                    <AddCondition />
                    <ButtonEdit linkRef={true} url={`/teach/case/${value?.Id}`} />
                    <ButtonDelete handleDelete={() => toggleModalDel(value, "deleteCase")} />
                </div>
            </Col>
        </Row>
    </li>
));

const SortableList = SortableContainer(({ items, toggleModalDel }) => {
    return (
        <ul>
            {items?.map((value, index) => (
                <SortableItem
                    key={`item-${value?.Id}`}
                    toggleModalDel={toggleModalDel}
                    index={index}
                    value={value}
                />
            ))}
        </ul>
    );
});

function BriefViewMain(props) {
    const dispatch = useDispatch();
    const { data, toggleModalDel, activeSeries } = props && props;
    const [itemSort, setItemSort] = useState(data);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newArrSort = arrayMove(itemSort, oldIndex, newIndex);
        setItemSort(newArrSort);

        //call api
        const result = _.map(newArrSort, function square(item) {
            return { Id: item?.Id }
        })
        const newParams = {
            Action: "Update-CasesOrder",
            Cases: result,
        };
        dispatch(actSortDataCase(activeSeries, newParams));
    };

    return (
        <SortableList
            items={itemSort || []}
            onSortEnd={onSortEnd}
            toggleModalDel={toggleModalDel}
            lockAxis="y"
            helperClass="SortableTbl"
            distance={1}
            useWindowAsScrollContainer={true}
        />
    );
}

export default BriefViewMain;