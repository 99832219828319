import React, { useState, useEffect } from "react";

//reactstrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { atcLoadPlanDetails } from "@actions";

//components
import { ContentPush } from "@components/reports/Learners";
import { LoaderText } from "@utils";

//api
import { apiCaller } from "@utils";
import { reportConstant } from "@constants";

const ModalDetails = (props) => {
  const { isOpen, toggle, item, action } = props && props;

  //dispatch
  const dispatch = useDispatch();

  //state
  const [toggleContent, setToggleContent] = useState(false);
  const [dataUser, setDataUser] = useState({});

  //Load API
  useEffect(() => {
    if (item?.LaunchPlanId && action === "details") {
      const params = {
        Action: "LoadDetails",
        LaunchPlanId: item.LaunchPlanId,
      };
      dispatch(atcLoadPlanDetails(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, action]);

  //Handle Change
  const handleContentPush = (currentId, value, caseId) => {
    setToggleContent(!toggleContent);
    setDataUser(value);
    if (value) {
      const params = {
        ReportType: "learner_CasePerformance",
        PerformanceId: currentId,
      };
      loadApiPerformanceFeedbacks(params, caseId);
    }
  }

  const loadApiPerformanceFeedbacks = (params, caseId) => {
    const isLoading = true;

    //Call Api
    dispatch({ type: reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_REQUEST, isLoading });
    apiCaller(`/api/teach/report/case/${caseId}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: reportConstant.LEARNER_PERFORMANCE_FEEDBACKS_SUCCESS,
          payload: data,
        });
      } else {
        dispatch({
          type: reportConstant.UPLOAD_IMAGE_HUMAN_MODEL_FAILURE,
          error: "error",
        });
      }
    });
  }

  const dataStore = useSelector((state) => state.loadPlanDetails);
  const listPerformanceFeedbacks = useSelector((state) => (state.getPerformanceFeedbacks && state.getPerformanceFeedbacks.data) || {});
  const fullData = dataStore?.data;
  const dataCohort = fullData?.Cohort;
  const listCaseId = dataCohort?.CaseIds;
  const listLeaner = dataCohort?.LearnersProgress;

  return (
    <Modal style={{ maxWidth: "900px" }} isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Details Launch Plan</ModalHeader>
      <ModalBody>
        <div className="table-responsive">
          <Table bordered>
            <thead>
              <tr>
                <th width="5%" className="--text-primary text-center font-weight-500">#</th>
                <th width="30%" className="--text-primary font-weight-500">Account</th>
                <th width="40%" className="--text-primary font-weight-500">Name</th>
                {listCaseId?.map((value, index) => (
                  <th
                    key={index}
                    className="--text-primary text-center font-weight-500"
                    width="10%"
                  >
                    Case #{value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="position-relative">
              {(dataStore?.isLoading) &&
                <div style={{ height: '300px' }}><LoaderText /></div>
              }
              {listLeaner?.map((value, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{value.Email}</td>
                  <td>{value.Name}</td>
                  {value?.Progress.map((item, j) => {
                    const badgeClass = (type) => {
                      let colorClass = "";
                      switch (type) {
                        case "Completed":
                          colorClass = "success";
                          break;
                        case "Started":
                          colorClass = "primary";
                          break;
                        default:
                          colorClass = "warning";
                      }
                      return colorClass;
                    };

                    //Detect Id compeleted
                    const checkIdCompleted = (type) => {
                      let PerformanceIdsCompleted = ""
                      switch (type) {
                        case "Completed":
                          PerformanceIdsCompleted = value[`PerformanceIds`][j]
                          break;
                        case "Started":
                          PerformanceIdsCompleted = "none";
                          break;
                        default:
                          PerformanceIdsCompleted = "none";
                      }
                      return PerformanceIdsCompleted;
                    };

                    return (
                      <td className="text-center" key={j}>
                        <Badge
                          className="font-weight-500 p-2"
                          color={badgeClass(item)}
                          onClick={() => handleContentPush(checkIdCompleted(item), value, listCaseId[j])}
                          current-caseid={listCaseId[j]}
                          style={{
                            pointerEvents: `${checkIdCompleted(item) === 'none' ? "none" : "auto"}`,
                            minWidth: "80px",
                            cursor: `${checkIdCompleted(item) !== 'none' ? "pointer" : "auto"}`
                          }}
                        >
                          {item}
                        </Badge>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <ContentPush
          isOpen={toggleContent}
          toggle={() => handleContentPush(null, null, null)}
          dataUser={dataUser}
          data={listPerformanceFeedbacks}
          layout="fixed"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ModalDetails;
