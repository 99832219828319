//NUS
import { aspet2024Page } from "@pages/aspet2024";

const aspet2024Routes = [
    {
        path: "/aspet2024",
        exact: true,
        classPage: '',
        component: aspet2024Page,
        isLayout: false,
    }
];

export { aspet2024Routes };