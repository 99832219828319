import React from "react";

function OutlineContent(props) {
    const { rowData } = props && props;
    return (
        <div
            className="mt-4 whiteSpace-break"
            dangerouslySetInnerHTML={{ __html: rowData?.proposal }}
        />
    );
}

export default OutlineContent;