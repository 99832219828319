import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col } from "reactstrap";
import { ModalAddPeriods } from "./Modal/";
import { useParams } from "react-router-dom";

//elements
import { ButtonDelete } from '@components-common/elements';
import { ModalDeleteCommon } from "@ui-partents/Modal";

//actions
import { actLoadAllPeriods, actRemovePeriodfromCase } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//format time
import moment from "moment";

function TrialPeriods(props) {
  const dispatch = useDispatch();
  let { id } = useParams();

  const [dataCurrent, setDataCurrent] = useState({});
  const [modal, setModal] = useState({
    add: false,
    delete: false,
  });

  const toggle = (type, value) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    setDataCurrent(value);
  };

  const handleRemove = () => {
    const params = {
      Action: "RemoveFromCase",
      CaseId: id,
      PeriodId: dataCurrent?.PeriodId,
    };
    dispatch(actRemovePeriodfromCase(params));
    setModal((prevState) => ({ ...prevState, delete: false }));
  };

  useEffect(() => {
    const params = {
      Action: "Load",
      CaseId: id,
    };
    dispatch(actLoadAllPeriods(params));
  }, [dispatch, id]);

  const columns = [
    {
      dataField: "PeriodId",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "Name",
      text: "Trial Period",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "StartTime",
      text: "Start Time",
      headerClasses: "--text-primary font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "EndTime",
      text: "End Time",
      headerClasses: "--text-primary font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "Actions",
      text: "",
      style: {
        width: "10%",
        whiteSpace: "nowrap",
      },
      headerClasses: "font-weight-400",
      formatter: (cell, row) => {
        return (
          <div className="text-right">
            <ButtonDelete handleDelete={() => toggle("delete", row)} />
          </div>
        );
      },
    },
  ];

  const periodsData = useSelector((state) => (state.loadPeriods && state.loadPeriods.data) || []);

  return (
    <React.Fragment>
      <div className="boxPortlet">
        <BootstrapTable
          keyField="PeriodId"
          condensed
          striped
          wrapperClasses="table-responsive"
          bordered={false}
          data={periodsData || []}
          columns={columns}
        />
        <Col sm={12} className="d-flex justify-content-center">
          <Button
            className="--btn-primary"
            onClick={() => toggle("add")}
          >
            <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add Trial
            Period
          </Button>
        </Col>
      </div>
      <ModalAddPeriods isOpen={modal.add} toggle={() => toggle("add", {})} />
      <ModalDeleteCommon
        title={`Confirming that you’d like to remove this period from this case?`}
        sub={`The period will remain available to be utilized in other cases. <br/> In order to permanently delete the period, go to <strong>Instructor’s Tools > Periods</strong> section.`}
        isOpen={modal.delete}
        toggle={() => toggle('delete', {})}
        handleRemove={handleRemove}
      />
    </React.Fragment>
  );
}

export default TrialPeriods;