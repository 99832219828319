import * as Types from "@constants/ActionTypes";
import { profileConstant, learnerConstants, demoConstants, instructorConstants, authConstants, planConstant, nusConstant } from "@constants";

// Define default view constant
const DEFAULT_VIEW = "Med2lab";

const saveAccountLocalStorage = (payload) => {
  const accountData = payload?.account ? { ...payload?.account, app: payload?.app ? payload?.app : DEFAULT_VIEW } : "";
  localStorage.setItem("Account", JSON.stringify(accountData));
};

const saveTokenLocalStorage = (data) => {
  localStorage.setItem("token", data);
};

const updateAccountData = (state, payload) => ({
  ...state,
  account: {
    ...payload,
    app: payload.app || DEFAULT_VIEW
  },
});

export function authUser(state = {}, action) {
  switch (action.type) {
    case Types.LOGIN:
    case nusConstant.LOGIN_USING_GUEST_ACCOUNT_SUCCESS:
    case demoConstants.VIEW_DEMO_GUEST_ACCOUNT_SUCCESS:
      saveTokenLocalStorage(action.payload.token);
      saveAccountLocalStorage(action.payload);
      return {
        ...state,
        token: action.payload.token,
        app: action.payload.app || DEFAULT_VIEW
      };
    case instructorConstants.REQUEST_INSTRUCTOR_PRIVILEGE_SUCCESS:
    case profileConstant.UPDATE_PROFILE_PHOTO_REQUEST:
      return {
        ...state,
        ...action.type === profileConstant.UPDATE_PROFILE_PHOTO_REQUEST && { isLoading: true },
        ...action.type !== profileConstant.UPDATE_PROFILE_PHOTO_REQUEST && updateAccountData(state, action.payload),
      };
    case profileConstant.UPDATE_PROFILE_PHOTO_SUCCESS:
    case profileConstant.CHANGE_AVATAR_PROFILE_SUCCESS:
    case learnerConstants.UPDATE_LEARNER_DEMO_SUCCESS:
    case planConstant.SUMIT_CHANGE_SUBSCRIPTION_SUCCESS:
      saveAccountLocalStorage(action.payload);
      return {
        ...state,
        isLoading: false,
        ...updateAccountData(state, action.payload),
      };
    case profileConstant.UPDATE_PROFILE_PHOTO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function resetPassword(state = {}, action) {
  switch (action.type) {
    case profileConstant.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case profileConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isReset: true,
        status: action.payload,
      };
    case profileConstant.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        isReset: false,
        status: action.payload,
      };
    default:
      return state;
  }
}

export function authCodeList(state = {}, action) {
  switch (action.type) {
    case authConstants.LOAD_AUTH_CODE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.LOAD_AUTH_CODE_LIST_SUCCESS:
    case authConstants.CREATE_AUTH_CODE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case authConstants.LOAD_AUTH_CODE_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function createAuthcode(state = {}, action) {
  switch (action.type) {
    case authConstants.CREATE_AUTH_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.CREATE_AUTH_CODE_SUCCESS:
    case authConstants.CREATE_AUTH_CODE_FAILURE:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}