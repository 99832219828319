import React, { useEffect } from "react";
import { ContentProcess } from "@components/common";
import queryString from "query-string";

//utils
import { messageData } from "@utils";

//actions
import { postVerifyRegister } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//Loader
import LoaderPage from "@utils/LoaderPage";

function VerifyPage(props) {
  const dispatch = useDispatch();
  const search = props.location.pathname;
  const parsed = queryString.parse(search);
  let codeData = parsed["/verify/code"];
  let emailData = parsed["email"];

  useEffect(() => {
    const params = {
      "Mode": "Register_Verify",
      "Email": emailData,
      "Code": codeData
    }
    dispatch(postVerifyRegister(params));
  }, [codeData, emailData, dispatch])

  const storeVerify = useSelector((state) => state.verifyRegister || {});
  const resultVerify = storeVerify && storeVerify.data;
  const status = resultVerify && resultVerify.status;

  //message
  const message = messageData.verifyAccount;
  const data = {
    title: message.title,
    description: message.description
  };
  
  const iconStatus = (status === "account_not_exist" || status === "code_not_matched") ? "not-checked" : "checked";
  return (
    <div className="wrapper -completedPage">
      {storeVerify.isLoading && <LoaderPage />}
      {status && !storeVerify.isLoading &&
        <ContentProcess backLogin={true} data={data} icon={iconStatus} />
      }
    </div>
  );
}

export default VerifyPage;
