import React from 'react';

//common
import { IconAnimationView } from '@components-common/IconAnimation';

//redux
import { useSelector } from "react-redux";

function CongratulationsBox() {
    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;
    return (
        <div className="modal-inner">
            <div className="modal-inner">
                <div className="modal-inner__pic d-flex justify-content-center">
                    <IconAnimationView nameAnimation={`vt0hUbkI1S`} />
                </div>
                <div className="modal-inner__desc text-center">
                    {ModalCaseCompleted?.m_complete_title?.Text ? (
                        <h2 className="headline --size-4 mb-4 text-center">
                            <div dangerouslySetInnerHTML={{ __html: ModalCaseCompleted?.m_complete_title?.Text || '' }}></div>
                        </h2>
                    ) : (
                        <h2 className="headline --size-4 mb-4 text-center">
                            Woohoo! You Did It! <br/>
                            Case Completed Like A Boss!
                        </h2>
                    )}
                    {ModalCaseCompleted?.m_complete_msg?.Text ? (
                        <div dangerouslySetInnerHTML={{ __html: ModalCaseCompleted?.m_complete_title?.Text || '' }}></div>
                    ) : (
                        <React.Fragment>
                           Now, it's decision time: Proceed to discover the feedback on your performance or Stay for an encore review of this Timepoint? 🎯
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CongratulationsBox;
