import React from 'react';

function TableHeader(props) {
    return (
        <thead className="table-row">
            <tr>
                <th scope="col" className="align-middle" width={`60%`}></th>
                <th scope="col" className="align-middle">Standard Response</th>
                <th scope="col" className="text-center align-middle" width={`5%`}></th>
            </tr>
        </thead>
    );
};

export default TableHeader;