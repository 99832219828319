import React from "react";

function PricingContentSp(props) {
  const { data, type } = props && props;
  return (
    <React.Fragment>
      {data?.map((value, index) => (
        <React.Fragment key={index}>
          <div className="pricing-subtitle-mobile">
            <strong>{value.type}</strong>
            <br />
          </div>
          {value &&
            value.details?.map((detail, j) => (
              <div className="pricing-features-mobile" key={j}>
                <div className="font-awesome-icon icon-left">
                  {(detail[type] === 'checked' || detail[type]) &&
                    <strong>
                      {" "}
                      <i className="fa fa-check me-2" aria-hidden="true" />
                    </strong>
                  }
                </div>
                {(detail[type] === 'checked' || detail[type]) &&
                  <div className="fw-400">
                    {detail.name}<br/>
                    {detail[type] !== 'checked' &&  `(${detail[type]})`}
                  </div>
                }
              </div>
            ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

export default PricingContentSp;
