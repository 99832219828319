import React from "react";

function ButtonAdd(props) {
    const { handleAdd } = props && props;
    return (
        <React.Fragment>
            <button className="btn btn-sm mx-1 btn-primary" onClick={handleAdd}>
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                Add New
            </button>
        </React.Fragment>
    );
}

export default ButtonAdd;