export const planConstant = {
    GET_LAUNCH_PLANS_REQUEST: 'GET_LAUNCH_PLANS_REQUEST',
    GET_LAUNCH_PLANS_SUCCESS: 'GET_LAUNCH_PLANS_SUCCESS',
    GET_LAUNCH_PLANS_FAILURE: 'GET_LAUNCH_PLANS_FAILURE',

    CREATE_LAUNCH_PLANS_REQUEST: 'CREATE_LAUNCH_PLANS_REQUEST',
    CREATE_LAUNCH_PLANS_SUCCESS: 'CREATE_LAUNCH_PLANS_SUCCESS',
    CREATE_LAUNCH_PLANS_FAILURE: 'CREATE_LAUNCH_PLANS_FAILURE',

    DELETE_LAUNCH_PLANS_REQUEST: 'DELETE_LAUNCH_PLANS_REQUEST',
    DELETE_LAUNCH_PLANS_SUCCESS: 'DELETE_LAUNCH_PLANS_SUCCESS',
    DELETE_LAUNCH_PLANS_FAILURE: 'DELETE_LAUNCH_PLANS_FAILURE',

    UPDATE_LAUNCH_PLANS_REQUEST: 'UPDATE_LAUNCH_PLANS_REQUEST',
    UPDATE_LAUNCH_PLANS_SUCCESS: 'UPDATE_LAUNCH_PLANS_SUCCESS',
    UPDATE_LAUNCH_PLANS_FAILURE: 'UPDATE_LAUNCH_PLANS_FAILURE',

    LOAD_PLAN_DETAILS_REQUEST: 'LOAD_PLAN_DETAILS_REQUEST',
    LOAD_PLAN_DETAILS_SUCCESS: 'LOAD_PLAN_DETAILS_SUCCESS',
    LOAD_PLAN_DETAILS_FAILURE: 'LOAD_PLAN_DETAILS_FAILURE',
    
    ADD_MANAGERS_TO_LAUNCH_PLAN_REQUEST: 'ADD_MANAGERS_TO_LAUNCH_PLAN_REQUEST',
    ADD_MANAGERS_TO_LAUNCH_PLAN_SUCCESS: 'ADD_MANAGERS_TO_LAUNCH_PLAN_SUCCESS',
    ADD_MANAGERS_TO_LAUNCH_PLAN_FAILURE: 'ADD_MANAGERS_TO_LAUNCH_PLAN_FAILURE',

    REMOVE_MANAGERS_TO_LAUNCH_PLAN_REQUEST: 'REMOVE_MANAGERS_TO_LAUNCH_PLAN_REQUEST',
    REMOVE_MANAGERS_TO_LAUNCH_PLAN_SUCCESS: 'REMOVE_MANAGERS_TO_LAUNCH_PLAN_SUCCESS',
    REMOVE_MANAGERS_TO_LAUNCH_PLAN_FAILURE: 'REMOVE_MANAGERS_TO_LAUNCH_PLAN_FAILURE',

    SUMIT_CHANGE_SUBSCRIPTION_REQUEST: 'SUMIT_CHANGE_SUBSCRIPTION_REQUEST',
    SUMIT_CHANGE_SUBSCRIPTION_SUCCESS: 'SUMIT_CHANGE_SUBSCRIPTION_SUCCESS',
    SUMIT_CHANGE_SUBSCRIPTION_FAILURE: 'SUMIT_CHANGE_SUBSCRIPTION_FAILURE',

    LOAD_FEATURE_LIST_REQUEST: 'LOAD_FEATURE_LIST_REQUEST',
    LOAD_FEATURE_LIST_SUCCESS: 'LOAD_FEATURE_LIST_SUCCESS',
    LOAD_FEATURE_LIST_FAILURE: 'LOAD_FEATURE_LIST_FAILURE'
};