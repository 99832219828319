
//NUS
import { LoginNusPage } from "@pages/med2lab/NusPage";

const nusRoutes = [
    {
        path: "/nus/login",
        exact: true,
        classPage: '',
        component: LoginNusPage,
        isLayout: false,
    }
];

export { nusRoutes };