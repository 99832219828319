import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { Label } from "reactstrap";

function ChatPromptTemp({
  currentTemplate = [],
  typeChatSettings,
  timepointData
}) {
  const { id } = useParams();
  const [selectedPrompt, setSelectedPrompt] = useState([]);
  const [promptTemplateList, setPromptTemplateList] = useState([]);

  //Load
  useEffect(() => {
    if (timepointData?.AvailablePromptTemplates) {
      const updatedPromptTemplateList = timepointData.AvailablePromptTemplates.map(prompt => ({
        ...prompt,
        value: prompt.Name,
        label: prompt.Name,
      }));
      setPromptTemplateList(updatedPromptTemplateList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTemplate.length > 0) {
      setSelectedPrompt(
        currentTemplate.map(prompt => ({
          ...prompt,
          value: prompt.Id,
          label: prompt.Name
        }))
      );
    }
  }, [currentTemplate]);

  const handleUpdateApi = (ids) => {
    const params = {
      "Action": "Update",
      "Target": "ChatSettings",
      "TimePointId": timepointData?.Id,
      [typeChatSettings]: ids
    };

    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      if (res?.status === 200) {
        console.log('success');
      } else {
        console.log("error....");
      }
    })
      .catch(error => {
        console.error("Error occurred:", error);
      });
  };

  const handleChangeOptions = (selectedOptions) => {
    setSelectedPrompt(selectedOptions);
    const ids = selectedOptions?.map(item => item.Id) || [];
    handleUpdateApi(ids);
  };

  return (
    <React.Fragment>
      <Label className="labeForm mt-4">
        Apply Prompt Template: <span className="text-danger">*</span>
      </Label>
      <Select
        classNamePrefix='form-react-select'
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        value={selectedPrompt}
        onChange={handleChangeOptions}
        options={promptTemplateList}
        placeholder={`Select an item below`}
        name="PromptTemplateIds"
      />
    </React.Fragment>
  );
}

export default ChatPromptTemp;