import React from "react";
import {
    Alert,
    Row,
    Col
} from "reactstrap";

function Overview (props) {
  const { data } = props && props;
  const valueSummary = data && data.Summary;
  const valueDescription = data && data.Description;
  return (
    <React.Fragment>
      {valueDescription && (
        <Alert
          color="success"
          className="m-alert--outline m-alert-success bg-white mt-3"
        >
          <div dangerouslySetInnerHTML={{ __html: valueDescription || '' }}></div>
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <div className="divContent">
            <h5 className="divContent__heading m--font-info">
              Your Score For This Domain
            </h5>
            <div className="divContent__detail">
              <p className="font-weight-400">
                Total Possible Score: {(valueSummary && valueSummary.TotalScore) || ''}
              </p>
              <p className="font-weight-400">
                Your Raw Score: {(valueSummary && valueSummary.UserScore) || ''}
              </p>
              <p className="font-weight-400 text-danger">
                Percent Score: {(valueSummary && valueSummary.PercentageScore + ' %') || ''}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Overview;
