import React from "react";
import { SettingList } from "./componentSetting";
import { BtnSetAvailable } from "@ui-partents/Button";

const SettingsPNOrders = (props) => {
  return (
    <div className="setting-pno">
      <div className="d-flex align-items-center justify-content-end mb-4">
        <BtnSetAvailable text="Set Availability"/>
      </div>
      <SettingList {...props}/>
    </div>
  );
};

export default SettingsPNOrders;