import React from 'react';
import { MainContentContainer } from "@components/layouts";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

const MainContentIner = ({ isLearner, isLockCase, children, ...props }) => {
    // Use screen size context
    const { isMobile } = useScreenSize();

    if (isLearner) {
        const className = `${isLockCase ? 'position-relative overflow-hidden' : ''} ${isMobile ? 'bg-white' : ''}`;
        const style = isLockCase ? { height: 'calc(100vh - 70px)' } : {};

        return (
            <div
                id="main-learner-view"
                className={className.trim()} // Loại bỏ khoảng trắng thừa
                style={style}
            >
                {children}
            </div>
        );
    }

    return (
        <MainContentContainer {...props}>
            {children}
        </MainContentContainer>
    );
};

export default MainContentIner;