import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import imgLogo from '@images/weitzman/logo.png';
import { checkAuth, apiCaller } from '@utils';

function LoginForm() {
    const history = useHistory();
    const { handleSubmit, control, formState: { errors } } = useForm();

    const [status, setStatus] = useState(null);
    const [isLoading, setLoading] = useState(null);

    useEffect(() => {
        document.body.style.backgroundColor = '#fafbfb';
        if (checkAuth()) {
            history.push("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('username', data?.email);
            formData.append('password', data?.password);
            const response = await apiCaller('/api/auth/login/', 'POST', formData);
            const dataResponse = response?.data;
            dataResponse?.status && setStatus(response?.data?.status);
            console.log(dataResponse, "dataResponse....");
        } catch (error) {
            console.error('Error during login:', error);
            setLoading(false);
        }
    };

    return (
        <div className="loginPartnerContainer">
            <div className="form-auth-panel">
                <div className="form-auth-panel__logo">
                    <img src={imgLogo} alt="weitzman" />
                </div>
                <div className="auth-login-form">
                    {status && (
                        <div className="message-form m--font-danger my-3 text-center" dangerouslySetInnerHTML={{ __html: status }} />
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <React.Fragment>
                            <div className="message-form m--font-danger my-3 text-center">
                                Enter your email to receive a password reset link.
                            </div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <Label className="labeForm" htmlFor="email">
                                        Email <span className="m--font-danger ml-2">*</span>
                                    </Label>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{ required: 'This field is required', pattern: /^\S+@\S+$/i }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                type="email"
                                                placeholder="Enter your email address"
                                                invalid={errors.email ? true : false}
                                            />
                                        )}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn-brand-sm -btn-lg w-100 -shadown mt-4"
                                    >
                                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                        Send Password Reset Email
                                    </Button>
                                </FormGroup>
                                <FormGroup>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className="btn-brand-sm -btn-lg w-100 -shadown mt-2"
                                    >
                                        Cancel
                                    </Button>
                                </FormGroup>
                            </Form>
                        </React.Fragment>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;