export const schemaContanst = {
    GET_SCHEMA_REQUEST: 'GET_SCHEMA_REQUEST',
    GET_SCHEMA_SUCCESS: 'GET_SCHEMA_SUCCESS',
    GET_SCHEMA_FAILURE: 'GET_SCHEMA_FAILURE',

    LOAD_QUESTION_ID_FOR_SCHEMA_REQUEST: 'LOAD__SCHEMA_REQUEST',
    LOAD_QUESTION_ID_FOR_SCHEMA_SUCCESS: 'LOAD__SCHEMA_SUCCESS',
    LOAD_QUESTION_ID_FOR_SCHEMA_FAILURE: 'LOAD__SCHEMA_FAILURE',

    SEARCH_CONCEPT_REQUEST: 'SEARCH_CONCEPT_REQUEST',
    SEARCH_CONCEPT_SUCCESS: 'SEARCH_CONCEPT_SUCCESS',
    SEARCH_CONCEPT_FAILURE: 'SEARCH_CONCEPT_FAILURE',

    ADD_CONCEPT_REQUEST: 'ADD_CONCEPT_REQUEST',
    ADD_CONCEPT_SUCCESS: 'ADD_CONCEPT_SUCCESS',
    ADD_CONCEPT_FAILURE: 'ADD_CONCEPT_FAILURE',

    UPDATE_CONCEPT_REQUEST: 'UPDATE_CONCEPT_REQUEST',
    UPDATE_CONCEPT_SUCCESS: 'UPDATE_CONCEPT_SUCCESS',
    UPDATE_CONCEPT_FAILURE: 'UPDATE_CONCEPT_FAILURE',

    UPDATE_CONCEPT_FOR_TAG_REQUEST: 'UPDATE_CONCEPT_FOR_TAG_REQUEST',
    UPDATE_CONCEPT_FOR_TAG_SUCCESS: 'UPDATE_CONCEPT_FOR_TAG_SUCCESS',
    UPDATE_CONCEPT_FOR_TAG_FAILURE: 'UPDATE_CONCEPT_FOR_TAG_FAILURE',

    DELETE_CONCEPT_REQUEST: 'DELETE_CONCEPT_REQUEST',
    DELETE_CONCEPT_SUCCESS: 'DELETE_CONCEPT_SUCCESS',
    DELETE_CONCEPT_FAILURE: 'DELETE_CONCEPT_FAILURE',

    SUBMIT_TEST_QUESTION_REQUEST: 'SUBMIT_TEST_QUESTION_REQUEST',
    SUBMIT_TEST_QUESTION_SUCCESS: 'SUBMIT_TEST_QUESTION_SUCCESS',
    SUBMIT_TEST_QUESTION_FAILURE: 'SUBMIT_TEST_QUESTION_FAILURE',

    SORT_CONCEPTS_IN_A_SCHEMA_REQUEST: 'SORT_CONCEPTS_IN_A_SCHEMA_REQUEST',
    SORT_CONCEPTS_IN_A_SCHEMA_SUCCESS: 'SORT_CONCEPTS_IN_A_SCHEMA_SUCCESS',
    SORT_CONCEPTS_IN_A_SCHEMA_FAILURE: 'SORT_CONCEPTS_IN_A_SCHEMA_FAILURE'
};