import React from "react";
import { ContentProcess } from "@components/common";
import { useParams } from "react-router-dom";

function PaymentMessagePage() {
  let { slug } = useParams();

  const data = {
    title: slug === 'failed' ? 'Payment Update Was Unsuccessful' : '',
    description: slug === 'success' ? "Your Payment Information Has Been Successfully Updated!" : "Please return to the billing page and try again",
  };

  const iconStatus = slug === 'success' ? 'checked' : 'not-checked';
  return (
    <div className="wrapper -completedPage">
      <ContentProcess data={data} icon={iconStatus} backBilling={true} />
    </div>
  );
}

export default PaymentMessagePage;