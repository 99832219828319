import React from "react";

//reactstrap
import { FormGroup, Input } from "reactstrap";

function AskedAtTask(props) {
    const { handleChange, stateCurrentQuestion, timepointData } = props && props;
    
    //timepointData
    const responseData = timepointData?.data;
    const timeLineList = responseData?.TaskElements;
    return (
        <FormGroup>
            <h5 className="heading-5">Task</h5>
            <Input type="select" name="AskedAtTask" defaultValue={stateCurrentQuestion?.AskedAtTask} onChange={handleChange}>
                {timeLineList?.map((value, index) =>
                    <option value={value.ViewClass} key={index}>{value.Label}</option>
                )}
            </Input>
        </FormGroup>
    );
}

export default AskedAtTask;
