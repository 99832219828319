import { useEffect, useRef } from "react";

export default function PdfViewerComponent(props) {
    const { document, pageActive } = props && props;
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        let PSPDFKit;
        (async function () {
            PSPDFKit = await import("pspdfkit");
            PSPDFKit.unload(container)

            await PSPDFKit.load({
                // Container where PSPDFKit should be mounted.
                container,
                // The document to open.
                document: document,
                // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                initialViewState: PSPDFKit.viewStateFromOpenParameters(
                    new PSPDFKit.ViewState({ currentPageIndex: pageActive - 1 })
                )
            });
        })();

        return () => PSPDFKit && PSPDFKit.unload(container);
    }, [document, pageActive]);

    return (
        <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
    );
}