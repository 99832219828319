import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import GeneralTab from "./GeneralTab";
import { AccessTab } from '../LaunchPlans';

// Utils
import { apiCaller } from "@utils";

const ModalAdd = (props) => {
  const { cohortStore, periodsStore, dataSeries, isOpen, toggle, action, item } = props && props;
  const dataPeriods = periodsStore?.data;
  const dataCohort = cohortStore?.data;

  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch
  } = useForm();

  const [initData, setInitData] = useState(item || {});
  const [stateCohort, setCohort] = useState((dataCohort && dataCohort) || []);
  const [statePeriods, setPeriods] = useState((dataPeriods && dataPeriods) || []);
  const [stateSeries, setSeries] = useState((dataSeries && dataSeries) || []);
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    setCohort((dataCohort && dataCohort) || []);
    setPeriods((dataPeriods && dataPeriods) || []);
    setSeries((dataSeries && dataSeries) || []);
  }, [dataCohort, dataPeriods, dataSeries]);

  useEffect(() => {
    if (action === 'add') {
      reset({ keepIsValid: false, keepValues: false });
    }

    const setEditData = (item, setValue) => {
      if (action === "edit") {
        const itemName = item?.Name || "";
        const itemSeries = item?.Series?.SeriesId || "";
        const itemPeriodId = item?.Period?.PeriodId || "";
        const itemCohort = item?.Cohort?.CohortId || "";
        const itemStatus = item?.Status || "";
        setValue("Name", itemName, { shouldValidate: false });
        setValue("SeriesId", itemSeries, { shouldValidate: false });
        setValue("PeriodId", itemPeriodId, { shouldValidate: false });
        setValue("CohortId", itemCohort, { shouldValidate: false });
        setValue("Status", itemStatus, { shouldValidate: false });
      }
    };

    setInitData(item || {});
    setEditData(item, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, item]);

  // Handle API
  const handleApiLauchPlans = async (params, actionPrefix) => {
    setLoading(true);
    try {
      const res = await apiCaller(`/api/teach/launchplan/`, "POST", params);
      const data = res?.data;

      dispatch({ type: `${actionPrefix}_LAUNCH_PLANS_REQUEST`, isLoading: true });

      if (res?.status === 200) {
        dispatch({ type: `${actionPrefix}_LAUNCH_PLANS_SUCCESS`, payload: data });
        reset({ keepIsValid: false, keepValues: false });
        setLoading(false);
        props && props.toggle();
      } else {
        dispatch({
          type: `${actionPrefix}_LAUNCH_PLANS_FAILURE`,
          error: "error",
        });
      }
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle Send API
  const handleSendApi = (data) => {
    const params = {
      Name: data.Name,
      SeriesId: data.SeriesId,
      CohortId: data.CohortId,
      PeriodId: data.PeriodId,
      Status: data.Status,
      InterfaceTemplateId: initData?.InterfaceTemplate?.InterfaceTemplateId || 0
    };

    if (action === 'add') {
      params['Action'] = "Create";
      handleApiLauchPlans(params, "CREATE");
    }

    if (action === 'edit') {
      params['Action'] = "Update";
      params['LaunchPlanId'] = item.LaunchPlanId;
      handleApiLauchPlans(params, "UPDATE");
    }
  };

  // Handle Change Interface Template
  const handleTemplateChange = (selectedOption) => {
    // Lấy InterfaceTemplateId từ selectedOption
    const InterfaceTemplateId = selectedOption?.value;

    // Cập nhật dữ liệu InitData với InterfaceTemplateId mới
    setInitData((prevData) => ({
      ...prevData,
      InterfaceTemplate: {
        InterfaceTemplateId: InterfaceTemplateId,
        // Các thuộc tính khác của InterfaceTemplate nếu cần
      },
    }));
  };

  const isDisable = action === "edit" && item.Status !== 'Draft';
  const listTabs = [
    {
      id: '1',
      name: 'General',
      component: <GeneralTab
        {...props}
        register={register}
        errors={errors}
        watch={watch}
        setValue={setValue}
        stateSeries={stateSeries}
        stateCohort={stateCohort}
        statePeriods={statePeriods}
        isDisable={isDisable}
        action={action}
        handleTemplateChange={handleTemplateChange}
        initData={initData}
      />,
      show: true
    },
    {
      id: '2',
      name: 'Access',
      component: <AccessTab {...props} item={item} />,
      show: true
    }
  ];

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "900px" }}>
      <ModalHeader toggle={toggle}>{action === 'add' ? 'Add' : 'Edit'} Launch Plan</ModalHeader>
      <ModalBody>
        <div className="tabsPills">
          <Nav tabs>
            {listTabs.map((item, index) =>
              item.show && (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === item.id })}
                    onClick={() => {
                      toggleTab(item.id);
                    }}
                  >
                    {item.name}
                  </NavLink>
                </NavItem>
              )
            )}
          </Nav>
          <TabContent activeTab={activeTab}>
            {listTabs.map((item, index) =>
              item.show && (
                <TabPane key={index} tabId={item.id}>
                  {item.component}
                </TabPane>
              )
            )}
          </TabContent>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={loading}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          onClick={handleSubmit(handleSendApi)}
          disabled={loading}
        >
          {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          {action === 'add' ? 'Add' : 'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalAdd;