import React, { useState, useEffect } from 'react';

//react-router-dom
import { useParams } from "react-router-dom";

//reactstrap
import { Table } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import { loadStandardChatTopics } from "@actions";

//lodash
import _ from "lodash";

//components
import { TableHeader, ChatTopicInstances, TableCell, ActionDelete, RowAdd, ModalAddChatTopic, ModalEditTopic } from '../Chat';

function ChatGroup(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { rowData, Timeline } = props && props;
    let ChatTopicInstancesData = rowData?.ChatTopicInstances;

    //state
    const [isOpen, setOpen] = useState({});
    const [itemEdit, setItemEdit] = useState({});
    const [buttonText, setButtonText] = useState('');
    const [modal, setModal] = useState({
        add: false,
        edit: false
    });

    useEffect(() => {
        ChatTopicInstancesData?.forEach(function (element) {
            element[`isOpen_${element.TopicId}`] = false;
        });
    }, [ChatTopicInstancesData])

    const handleLoadStandardChatTopics = (type) => {
        const TimePointIdFirst = _.filter(Timeline, function (o) {
            return o?.Time === 0;
        });
        const TimePointIdFilter = TimePointIdFirst && TimePointIdFirst[0]?.TimepointId;

        if(TimePointIdFilter) {
            const params = {
                "Action": "Load",
                "Target": "StandardChatTopics",
                "TimePointId": TimePointIdFilter,
                "TopicType": type
            }
            dispatch(loadStandardChatTopics(id, params));
        }
    }

    const handleCollapse = (TopicId) => {
        setOpen((prevState) => ({ ...prevState, [TopicId]: !isOpen[TopicId] }));
    };

    const toggle = (type, data) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        if(type === 'add') {
            setButtonText(data);
            handleLoadStandardChatTopics(data);
        } else {
            setItemEdit(data);
        }
    };

    //Load Standard Chat Topics
    const dataChatTopics = useSelector(
        (state) => (state?.listStandardChatTopics?.data) || []
    );
    
    return (
        <React.Fragment>
            {/* Begin */}
            <div className="panel-heading font-weight-500 border p-3 m--font-danger" style={{ backgroundColor: "#f2f2f2" }}>
                {rowData?.SectionName}
            </div>
            <div className="panel-body">
                <Table className="mb-0" responsive>
                    <TableHeader data={Timeline} />
                    {ChatTopicInstancesData?.map((item, indexItem) => {
                        const ChatResponses = item?.ChatResponses;
                        const Children = item?.Children;
                        const TopicId = item?.TopicId;
                        const isOpenCollpase = `isOpen_${TopicId}`;
                        return (
                            <tbody className="border-0" key={indexItem}>
                                <tr className="accordion-toggle">
                                    <td className="align-middle" width="20%" onClick={() => Children && Children.length > 0 ? handleCollapse(isOpenCollpase) : undefined}>
                                        {Children && Children.length > 0 &&
                                            <i className={`fa ${isOpen[isOpenCollpase] ? "fa-chevron-up" : "fa-chevron-down"} --text-primary`} aria-hidden="true"></i>
                                        }
                                        <span className="ml-2 --text-primary font-weight-500">{item?.TopicName}</span>
                                    </td>
                                    {ChatResponses?.map((itemtp, indextp) => {
                                        const obJectEdit = {...item, ...itemtp}
                                        return (
                                            <TableCell key={indextp} value={itemtp?.ChatResponse} handleEdit={() => toggle('edit', obJectEdit)} />
                                        )
                                    })}
                                    <ActionDelete value={item} />
                                </tr>
                                <ChatTopicInstances
                                    data={Children}
                                    isOpen={isOpen[isOpenCollpase]}
                                    item={item}
                                    handleEdit={toggle}
                                />
                            </tbody>
                        )
                    })}
                </Table>
                {rowData?.SectionName !== "chief complaint" &&
                    <RowAdd
                        rowData={rowData}
                        handeModalAdd={() => toggle('add', rowData?.SectionName)}
                    />
                }
            </div>
            {/* /end */}
            <ModalAddChatTopic
                data={(dataChatTopics && dataChatTopics) || []}
                buttonText={buttonText}
                isOpen={modal.add}
                toggle={() => toggle('add', buttonText)}
                Timeline={Timeline}
            />
            <ModalEditTopic
             isOpen={modal.edit}
             toggle={() => toggle('edit', buttonText)}
             data={(itemEdit && itemEdit) || {}}
             Timeline={Timeline}
            />
        </React.Fragment>
    );
};

export default ChatGroup;