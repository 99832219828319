import React from 'react';

//boostrap
import {
    Col
} from "reactstrap";

//components
import { UserStories } from '../scrum';

function ScrumLeft(props) {
    return (
        <Col sm={12} md={12} lg={7}>
            <UserStories {...props}/>
        </Col>
    );
};

export default ScrumLeft;