import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import {
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";

//apiCaller
import { apiCaller } from "@utils";

function CME(props) {
  const { dataCase } = props && props;
  const CaseSettings = dataCase?.data?.CaseSettings;
  let { id } = useParams();

  //state
  const [stateSettings, setSettings] = useState(CaseSettings || {});

  // Load Data
  useEffect(() => {
    const CaseSettings = dataCase?.data?.CaseSettings;
    setSettings(CaseSettings || {});
  }, [dataCase])

  const [stateOptions] = useState({
    credit: [
      { name: '', value: '' },
      { name: '0.25', value: '0.25' },
      { name: '0.5', value: '0.5' },
      { name: '0.75', value: '0.75' },
      { name: '1.0', value: '1.0' },
    ],
    duration: [
      { name: '', value: '' },
      { name: '15', value: '15' },
      { name: '30', value: '30' },
      { name: '45', value: '45' },
      { name: '60', value: '60' }
    ],
    level: [
      { name: '', value: '' },
      { name: 'Basic', value: 'Basic' },
      { name: 'Foundational', value: 'Foundational' },
      { name: 'Intermediate', value: 'Intermediate' },
      { name: 'Advanced', value: 'Advanced' }
    ]
  });

  // Render Options
  const renderOptions = (type) => {
    return (
      stateOptions[type]?.map((item, index) => <option key={index} selected={stateSettings && stateSettings[type] ? stateSettings[type] === item?.value : ''} value={item?.value}>{item?.name}</option>)
    )
  }

  // Hanlde Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    const params = {
      "Action": "Update",
      "Target": "Settings",
      "Parameter": name,
      "Value": value
    }
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      if (res && res.status === 200) {
        console.log('success');
      } else {
        console.log('error');
      }
    });
  }

  return (
    <div className="panelCt mb-4">
      <div className="panelCt__head">
        <h5 className="heading-5 m--font-brand --font-bold font-roboto --text-primary my-0 ">
          Case Settings
        </h5>
      </div>
      <div className="panelCt__body">
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="credit">
                Credits:
              </Label>
              <Input
                id="credit"
                name="credit"
                type="select"
                onChange={handleChange}
              >
                {renderOptions('credit')}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="duration">
                Duration:
              </Label>
              <div className="d-flex align-items-center">
                <Input
                  id="duration"
                  name="duration"
                  type="select"
                  onChange={handleChange}
                  style={{ width: '80%' }}
                >
                  {renderOptions('duration')}
                </Input>
                <span className="ml-2">min</span>
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="level">
                Difficulty Level:
              </Label>
              <Input
                id="level"
                name="level"
                type="select"
                onChange={handleChange}
              >
                {renderOptions('level')}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CME;