import React from 'react';

//imgPerson
import imgPerson from "@images/common/person.png";

function LoadingMessage(props) {
    const { data, value } = props && props;
    const Interface = data?.Interface;
    const PatientAvatar = Interface?.PatientAvatar;
    return (
        <div className={`messengerCt__wrapper ${value?.ChatStatus === "preceptor" ? "isShow" : ""}`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PatientAvatar || imgPerson}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            Bot:
                        </div>
                        <div className="messengerCt__message-text">
                            <div className="typing-loader"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;