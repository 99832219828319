import styled from 'styled-components';

const GridMain = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

const LeftSection = styled.div`
  flex: 0 0 273px;
`;

const RightSection = styled.div`
  flex: 1;
`;

function GridContainer({ children }) {
  return (
    <GridMain>
      <LeftSection>
        {children && children[0]}
      </LeftSection>
      <RightSection>
        {children && children[1]}
      </RightSection>
    </GridMain>
  );
}

export default GridContainer;