import React, { useState, forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

//format time
import moment from 'moment';

//redux
import { useDispatch } from "react-redux";

//actions
import { actUpdatePeriod } from "@actions";

const ModalEditPeriods = (props) => {
    const dispatch = useDispatch();

    //props
    const { isOpen, toggle, item } = props && props;
    const currentStartDate = moment(item?.StartTime).format("MM/DD/YYYY HH:mm:ss");
    const currentEndDate = moment(item?.EndTime).format("MM/DD/YYYY HH:mm:ss");
    
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    //state
    const [startDate, setStartDate] = useState(item?.StartTime ? new Date(currentStartDate) : null);
    const [endDate, setEndDate] = useState(item?.EndTime ? new Date(currentEndDate) : null);

    //Load Data
    useEffect(() => {
        setStartDate(item?.StartTime ? new Date(currentStartDate) : null);
        setValue('Name', item?.Name, { shouldValidate: true });
        setEndDate(item?.EndTime ? new Date(currentEndDate) : null);
    }, [item, currentStartDate, currentEndDate, setValue]);


    //On Submit
    const onSubmit = (data) => {
        const params = {
            Action: "Update",
            PeriodId: item?.PeriodId,
            Name: data.Name,
            StartTime: moment(startDate).format(),
            EndTime: moment(endDate).format(),
        };
        dispatch(actUpdatePeriod(params));
        toggle();

        //reset Data
        setValue('Name', '', { shouldValidate: false });
        setStartDate(null);
        setEndDate(null);
    };

    const CutomInput = forwardRef(({ value, onClick }, ref) => (
        <InputGroup>
            <Input onClick={onClick} defaultValue={value} type="text" ref={ref} />
            <InputGroupAddon addonType="append">
                <InputGroupText>
                    <i className="fa fa-calendar" />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    ));
    
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Edit Periods</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder="Enter the name for the new period"
                            autoComplete="off"
                        />
                    </FormGroup>
                    <FormGroup className="pickerCustom">
                        <Label className="d-block" for="StartTime">
                            Start Time:
                        </Label>
                        <DatePicker
                            placeholder="mm/dd/yyyy, --:-- --"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            className="form-control"
                            dateFormat="MMMM d, yyyy h:mm a"
                            showTimeInput
                            timeInputLabel="Time:"
                            customInput={<CutomInput />}
                        />
                    </FormGroup>
                    <FormGroup className="pickerCustom">
                        <Label className="d-block" for="EndTime">
                            End Time:
                        </Label>
                        <DatePicker
                            placeholder="mm/dd/yyyy, --:-- --"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            className="form-control"
                            dateFormat="MMMM d, yyyy h:mm a"
                            showTimeInput
                            timeInputLabel="Time:"
                            customInput={<CutomInput />}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalEditPeriods;