import React from 'react';
import RemindListItem from './RemindListItem';

//components
import { DataEmty } from '@components/common';

// Import your icon images
import assignmentIcon01 from '@images/new_interface/assignments/heart.svg';
import assignmentIcon02 from '@images/new_interface/assignments/pills.svg';
import assignmentIcon03 from '@images/new_interface/assignments/pulse.svg';
import assignmentIcon04 from '@images/new_interface/assignments/test-tube.svg';

// Redux
import { useSelector } from "react-redux";

function RemindSection() {
    const portfolioData = useSelector((state) => state.portfolio || []);
    const Reminders = portfolioData?.data?.Reminders;

    // Create an array of your icon images
    const iconImages = [assignmentIcon01, assignmentIcon02, assignmentIcon03, assignmentIcon04];
    return (
        <section className="other-assignments">
            <div className="card-area bg-white">
                <div className="d-flex justify-content-between mb-4">
                    <h2 className="headline --size-6">Reminders</h2>
                    {/* <span className="link-see-all-sm">See all</span> */}
                </div>
                <ul className="remind-list">
                    {Reminders?.map((item, index) => {
                        // Randomly select an icon from the array
                        const randomIconSrc = iconImages[Math.floor(Math.random() * iconImages.length)];

                        return (
                            <RemindListItem
                                key={index}
                                iconSrc={randomIconSrc}
                                Title={item.Title}
                                Content={item.Content}
                            />
                        );
                    })}
                    {Reminders?.length === 0 && <DataEmty content="Yay! You've finished all the upcoming deadlines!" title={`You're on Top of Your Assignments`} size="127px" />}
                </ul>
            </div>
        </section>
    );
}

export default RemindSection;