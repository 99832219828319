import React from 'react';

//common
import { IconDisplay } from '@components-common/media';

//components
import { CheckBoxAvailability } from '@components/elements';

function TableCellStudies(props) {
    const { value, handleShow, iconMedia, item, isSwitch, type, TimePointData } = props && props;
    const isMedia = item?.MediaType;
    const ItemName = item?.Name;
    const TimepointId = TimePointData?.TimepointId;
    return (
        <React.Fragment>
            {isSwitch === 'Value' && type !== 'first_name' ? (
                <td
                    style={{ cursor: 'pointer' }}
                    onClick={handleShow}
                    className={`table-cell align-middle`}
                >
                    <div className={`${isMedia !== 'None' ? "pr-2" : "mx-auto"} ${value === '(no change)' ? "text-muted" : ""}`}
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                    {isMedia !== 'None' &&
                        <div className={`mt-2`}>
                            {iconMedia &&
                                <IconDisplay type={isMedia} />
                            }
                        </div>
                    }
                </td>
            ) : (
                type === 'first_name' ? <td className='table-cell align-middle'>{ItemName}</td> : <CheckBoxAvailability {...props} typeFeat="studies" TimepointId={TimepointId} />
            )}
        </React.Fragment>
    );
};

export default TableCellStudies;