import React, { useState } from 'react';

//components 
import { ModalSortList } from '../Modal/SortList';

function TableHeader(props) {
    const { data, isSort } = props && props;

    const [modal, setModal] = useState({
        sort: false
    });

    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    return (
        <thead>
            <tr>
                {data?.map((col, index) => {
                    return (
                        <th
                            scope="col"
                            width={col?.width || (col?.TimePoint ? '250px' : '')}
                            className={`align-middle font-weight-500 --text-primary`}
                            key={index}
                        >
                            <div className={`d-flex align-items-center`}>
                                {index === 0 && isSort && <span style={{ cursor: "pointer" }} onClick={() => toggle("sort")} className="icon-sort-list mr-2 text-5"></span>}
                                {col?.Name ? col.Name : col?.TimePoint?.DisplayName}
                            </div>
                        </th>
                    )
                })}
            </tr>
            <ModalSortList
                isOpen={modal.sort}
                toggle={() => toggle("sort")}
                {...props}
            />
        </thead>
    );
};

export default TableHeader;