import React, { useState } from 'react'
import styled from 'styled-components'
// import { ButtonComponent } from "@components/patterns/elements/Button";
import { SwitchItem } from "@ui-partents/SwitchButton";
import { useDispatch } from 'react-redux';
import { shareMediaOrg } from "@actions"
import { toast } from 'react-toastify';
// import { Icon } from '@components/common'
// import Back from '@images/teachV2/back.svg'

const WrapFooter = styled.div`
  padding: 32px;
  border-top: 1px solid #D0D5DD;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  .action-question {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`

export default function FooterQuestionBank(props) {
  const { dataQuestionDetail, setDataQuestionDetail } = props
  const dispatch = useDispatch();
  const [valuePublish, setValuePublish] = useState(dataQuestionDetail?.SharedToOrganizationBank)

  const handleChangeSwitch = () => {
    if (!valuePublish) {
      handleshare()
    } else {
      handleUnshare()
    }
  }

  const handleshare = async () => {
    const dataShare = await dispatch(shareMediaOrg({
      Action: "ShareToOrganization",
      QuestionItemId: dataQuestionDetail?.QuestionItemId
    }))
    if (dataShare?.data?.status === 200) {
      setValuePublish(true)
      toast.success('Successfully sent to Organization Bank', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDataQuestionDetail((prev) => ({
        ...dataQuestionDetail,
        SharedToOrganizationBank: true
      }))
    }
  }

  const handleUnshare = async (data) => {
    const dataShare = await dispatch(shareMediaOrg({
      Action: "UnShareToOrganization",
      QuestionItemId: dataQuestionDetail?.QuestionItemId
    }))
    if (dataShare?.data?.status === 200) {
      setValuePublish(false)
      toast.success('Successfully unsent from Organization Bank', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setDataQuestionDetail((prev) => ({
        ...dataQuestionDetail,
        SharedToOrganizationBank: false
      }))
    }
  }

  return (
    <WrapFooter>
      <div className='d-flex align-items-start'>
        <SwitchItem
          label={``}
          name={`shareOrg`}
          handleChangeSwitch={handleChangeSwitch}
          value={valuePublish}
          noPadding
        />
        <div className='ml-2'>
          <h1>Send to Organization Bank</h1>
          <p>Toggle the switch to send this question to your Organization Bank.</p>
        </div>
      </div>
      {/* <div className='action-question'>
        <ButtonComponent size="small" className="py-2" color="outline">
          <Icon src={Back}/>
        </ButtonComponent>
        <ButtonComponent className="px-3" size="small">Next Question</ButtonComponent>
      </div> */}
    </WrapFooter>
  )
}
