import React from 'react';

import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

const Notic = () => {
    const portfolioData = useSelector((state) => state.portfolio || []);
    const Reminders = portfolioData?.data?.Reminders;
    return (
        <div className="bell">
            <Iconsax iconName="bell" fill="#202939" size={24} />
            <span className='bell__number'>{Reminders?.length || 0}</span>
        </div>
    );
}

export default Notic;