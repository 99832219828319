import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//logo
import imgLogo from "@images/kachihealth/logo.png";

//Link
import { Link } from "react-router-dom";

//boostrap
import { Row, Col, FormGroup, Input } from "reactstrap";

//call Action
import { actionLogin, actFetchThemeRequest } from "@actions";

//call Api
import { apiCaller, checkAuth } from "@utils";

//redux
import { useDispatch } from "react-redux";

//queryString
import queryString from "query-string";

function FormLogin(props) {
  let history = useHistory();
  //props
  const { handleView } = props && props;

  //check params
  let parsed = queryString.parse(window.location.search);

  //state
  const [stateEmail, setStateEmail] = useState("");
  const [statePassword, setStatePassword] = useState("");
  const [stateValid, setStateValid] = useState(false);
  const [stateMessage, setMessage] = useState(
    "Either your email or password is not valid. Please try again."
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setStateEmail(value);
        break;
      case "password":
        setStatePassword(value);
        break;
      default:
    }
  };

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed[`redirect`];

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
      if (dataAccount?.is_instructor === "Yes") {
        history.push(`/teach/home`);
      } else {
        history.push("/learn/home");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (stateEmail === "" || statePassword === "") {
      setStateValid(true);
      return;
    }

    if (stateEmail && statePassword) {
      apiCaller(`/api/auth/login/`, "POST", {
        username: stateEmail,
        password: statePassword,
      }).then((response) => {
        const dataResponse = response?.data;
        const messageStatus = dataResponse?.status;
        if (messageStatus) {
          setMessage(messageStatus);
        }
        if (dataResponse?.status === "OK") {
          const accountData = dataResponse?.account;
          const isAdministrator = accountData?.is_administrator;
          // const isInstructor = accountData?.is_instructor;
          const isSubscription = accountData?.subscription;
          setStateValid(false);

          // set localStorage
          localStorage.setItem(
            "Account",
            accountData ? JSON.stringify(accountData) : ""
          );

          //add token
          dispatch(actionLogin(dataResponse));

          //Call API Theme
          dispatch(actFetchThemeRequest());

          // Check Redirect Page
          if (isSubscription !== null) {
            nextPage(dataResponse);
          } else if (isSubscription === null && isAdministrator === "Yes") {
            history.push(`/plan`);
          } else {
            history.push(`/plan/error`);
          }
        } else {
          setStateValid(true);
        }
      });
    }
  };

  const getFullYear = new Date().getFullYear();
  return (
    <div className="m-portlet m-portlet--mobile flex-column">
      <div className="m-portlet__body">
        <form method="post" id="form_login" onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="text-center">
              <img src={imgLogo} style={{ maxWidth: "50%" }} alt="logo" />
            </Col>
          </Row>
          <Row style={{ marginTop: 40, marginBottom: 10 }}>
            <Col md={10} className="mx-auto">
              <FormGroup>
                <Input
                  type="email"
                  onChange={handleChange}
                  className="form-control form-control-lg m-input m-input--pill"
                  value={stateEmail}
                  placeholder="Email"
                  name="email"
                />
              </FormGroup>
            </Col>
            <Col md={10} className="mx-auto">
              <FormGroup>
                <Input
                  type="password"
                  onChange={handleChange}
                  className="form-control form-control-lg m-input m-input--pill"
                  value={statePassword}
                  placeholder="Password"
                  autoComplete="on"
                  name="password"
                />
              </FormGroup>
            </Col>
          </Row>
          {stateValid && (
            <Row>
              <Col
                md={10}
                className="mx-auto m--font-danger text-center"
                id="message_invalid"
              >
                {stateMessage}
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12} className="text-center">
              <button
                type="submit"
                className="btn m-btn--pill btn-brand btn-lg px-4"
                id="button_submit_form"
                style={{ minWidth: "200px", margin: 20 }}
              >
                Log In
              </button>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col md={12} className="text-center">
              <span
                style={{ cursor: "pointer" }}
                className="m--font-brand"
                onClick={handleView}
              >
                Forgot your password?
              </span>
            </Col>
            <Col md={12} className="text-center">
              <Link className="m--font-brand" to={`/kachi-health/register`}>
                Register for a new FREE account
              </Link>
            </Col>
          </Row>
        </form>
      </div>
      <div className="m-portlet__foot w-100" style={{ textAlign: "center" }}>
        <span className="m-footer__copyright">
         {`2017-${getFullYear}`}©
          <a
            href="http://med2lab.com"
            target="_blank"
            rel="noreferrer"
            className="m-link ml-1"
          >
            Med2Lab Inc
          </a>
          . All Rights Reserved.
        </span>
      </div>
    </div>
  );
}

export default FormLogin;
