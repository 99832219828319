import React, { useState } from "react";
import { Input, Table } from "reactstrap";
import { Iconsax } from "@components-common";

// Button
import { BtnIconDeleteV2, BtnIconEditV2, BtnIconAddV2 } from "@ui-partents/Button";

//Common
import { ModalDeleteCommon } from "@ui-partents/Modal";

// Component hiển thị mỗi hàng trong bảng
const TableRow = ({ item, toggleModal }) => {
  const handleEditButtonClick = () => {
    toggleModal("editProblem", true, item);
  };

  const handleDeleteButtonClick = () => {
    toggleModal("delProblem", item);
  };

  return (
    <tr>
      <td className="align-middle">{item.intervention}</td>
      <td className="align-middle">
        <Input
          className="m-0 checkbox-lg"
          defaultChecked={item.available}
          type="checkbox"
        />
      </td>
      <td className="align-middle wrap-score d-flex flex-column align-items-center">
        <div className="wrap-score__text d-flex align-items-center">
          <span></span>
          <p>{item.score}</p>
        </div>
        <div className="wrap-score__plus">
          <Iconsax iconName="plus" fill="#F1800F" size={18} />
        </div>
      </td>
      <td className="align-middle text-center">
        <div className="d-flex align-items-center justify-content-center">
          <BtnIconAddV2 handleEditButtonClick={handleEditButtonClick}/>
          <BtnIconEditV2
            handleEditButtonClick={handleEditButtonClick}
            colorIcon={`#16B364`}
          />
          <BtnIconDeleteV2 handleDelete={handleDeleteButtonClick} />
        </div>
      </td>
    </tr>
  );
};

const SettingItem = () => {
  //state
  const [modalOpen, setModalOpen] = useState({
    editProblem: false,
    delProblem: false,
  });

  // Handle Toogle Modal
  const toggleModal = (type, item = null) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  // Handle Remove
  const handleRemove = () => {
    toggleModal("delete", {});
  };

  // Sample data
  const data = [
    {
      id: 1,
      intervention: "Go To 1 Hour, Skip 4 Hours (#91158)",
      available: true,
      score: "2nd domain (2)",
    },
  ];

  return (
    <div className="mb-4">
      <Table className="custom-table-corner" responsive>
        <thead>
          <tr>
            <th className="align-middle --text-blue">Intervention</th>
            <th className="align-middle --text-blue">Available</th>
            <th className="align-middle --text-blue">Score</th>
            <th className="align-middle --text-blue">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow key={item.id} item={item} toggleModal={toggleModal} />
          ))}
        </tbody>
      </Table>
      <ModalDeleteCommon
        isOpen={modalOpen.delProblem}
        toggle={() => toggleModal("delProblem", {})}
        title={`Delete Topic`}
        sub={`Are you sure that you would like to delete this topic? This action cannot be undone.`}
        handleRemove={handleRemove}
      />
    </div>
  );
};

export default SettingItem;
