// Generate.js
import React from 'react';
import { Row, Col } from 'reactstrap';

// Components
import { ElementsLayout } from "@components/patterns/elements/Layout";

const LayoutMainPage = () => {
  return (
    <section className="content-component mb-5">
        <Row className='mb-4'>
          <Col md={12}>
            <div className="content-header">
              <h1>Layout Modules</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='px-5'>
            <ElementsLayout switchRoleMobile={true} title={`Switch Role - Mobile`} />
          </Col>
          <Col md={6} className='px-5'>
            <ElementsLayout switchRoleDesktop={true} title={`Switch Role - Desktop`} />
          </Col>
          <Col md={6} className='px-5'>
            <ElementsLayout dropdownSidebar={true} title={`Dropdown - Sidebar`} isSidebarCollapsed={false} />
          </Col>
          <Col md={6} className='px-5'>
            <ElementsLayout betaComponent={true} title={`Beta Component`} isSidebarCollapsed={false} />
          </Col>
        </Row>
    </section>
  );
};

export default LayoutMainPage;