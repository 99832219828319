import React, { useState } from 'react';

//components
import { ModalReferences } from "@components/learner/StandardScreen/References";
import { ButtonTaskItem } from '@components/learner/StandardScreen/CaseTask';

//Icon
import IconNote from "@images/learnerv2/icon-taks-note.svg";

//redux
import { useSelector } from "react-redux";

function ButtonReferences(props) {
    const { caseData } = props && props;
    const dataReferences = caseData?.data?.Case?.References;

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});

    //state
    const [modal, setModal] = useState({
        References: false
    });

    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const CaseTask =  InterfaceTemplateDetails?.CaseTask;
    return (
        <React.Fragment>
            {dataReferences?.length > 0 && (
                <ButtonTaskItem
                    {...props}
                    label={CaseTask?.button_ref?.Text || "References"}
                    icon={IconNote}
                    handleClickItem={() => toggle("References")}
                    attributeTask="References"
                />
            )}
            <ModalReferences
                {...props}
                caseData={caseData?.data?.Case}
                isOpen={modal.References}
                toggle={() => toggle("References")}
            />
        </React.Fragment>
    );
};

export default ButtonReferences;