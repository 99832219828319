import React, { useEffect, useState } from "react";
//uuid
import { v4 as uuidv4 } from "uuid";

//api
import { apiCaller } from "@utils";

//lodash
import _ from "lodash";

//api
import { LoaderText } from "@utils";

function HumanLabelContent(props) {
    const { rowRecord, divContainer, isOpen, activeCategory } = props && props;

    let idContainer = divContainer || "htmlHumanLabelText";
    const [loadData, setLoadData] = useState(false);

    //Load Api With Text_id
    useEffect(() => {
        const createStyledString = (obj) => {
            let { text, human_label } = obj;
            let dataByCategory = _.filter(human_label, function (o) { return o.category === activeCategory });
            let newData = activeCategory ? dataByCategory : human_label;

            // TO KEEP TRACK OF INSERTED TEXT
            let insertedAmmount = [];
            if (newData?.length > 0) {
                newData?.forEach((t, index) => {
                    let renderId = uuidv4(index);
                    t[`uuid`] = renderId;
                    const {
                        start,
                        end,
                        label_text,
                        label_color
                    } = t;

                    // COMPUTE THE REAL START AND END POSITIONS
                    // TAKING INSERTED TEXT INTO ACCOUNT
                    let realStart = start
                    let realEnd = end
                    for (let idx in insertedAmmount) {
                        if (idx < start) {
                            realStart += insertedAmmount[idx]
                        }
                        if (idx <= end) {
                            realEnd += insertedAmmount[idx]
                        }
                    }

                    const splitStringLabelText = (label_text) => {
                        let arrSplit = label_text?.split('||').join(')(');
                        return arrSplit || '';
                    }

                    const tagLabelText = splitStringLabelText(label_text);
                    let pre = `<mark class="highlight-${label_color}" style="background-color: rgb(${label_color}); color: white; display: inline-block; padding: 1px 4px; border-radius: 4px; margin: 5px;">`
                    let pos = `<span class="highlight-sub">${tagLabelText ? `(${tagLabelText})` : ''}</mark>`

                    // UPDATE THE INFORMATION ABOUT INSERTED TEXT
                    insertedAmmount[start] = (insertedAmmount[start] || 0) + pre?.length;
                    insertedAmmount[end] = (insertedAmmount[end] || 0) + pos?.length;
                    // DON'T INSERT DIRECTLY THE VALUE BUT THE ALREADY EXISTENT TEXT
                    text = text.substring(0, realStart)
                        + pre
                        + text.substring(realStart, realEnd)
                        + pos
                        + text.substring(realEnd)
                    document.getElementById(idContainer).innerHTML = text;
                });
            } else { document.getElementById(idContainer).innerHTML = text; }
        }

        const fetchData = () => {
            const params = {
                "Action": "LoadText",
                "text_id": parseFloat(rowRecord?.text_id)
            }

            setLoadData(true);
            //Call Api Load Text Item
            apiCaller(`/api/binh/model_management/text_item/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    createStyledString(data);
                    setLoadData(false);
                } else {
                    console.log("error....");
                    setLoadData(false);
                }
            })
        }
        isOpen && fetchData();
    }, [isOpen, rowRecord, idContainer, activeCategory]);

    return (
        <div className="position-relative">
            <div className="formatHtmlLabel" id={idContainer}></div>
            {loadData && <LoaderText />}
        </div>
    );
}

export default HumanLabelContent;