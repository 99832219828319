import React from 'react'
import styled from 'styled-components'

const WrapHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
  img {
    margin-bottom: 16px;
    width: 250px;
  }
  h1 {
    color: #101828;
    font-size: 32px;
    font-weight: 600;
    line-height: 42px;
  }
  div, p {
    color: #344054;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
`

export default function HeaderCommon(props) {
  const { title, subtitle, isHtml } = props

  return (
    <WrapHeader>
      <h1>{title}</h1>
      {subtitle && isHtml ? (
        <div dangerouslySetInnerHTML={{ __html: subtitle ? subtitle : "" }} />
      ) : (
        <p>{subtitle}</p>
      )}
    </WrapHeader>
  )
}
