import React, { useRef } from "react";

//jodit
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

const ContentEditor = (props) => {
    const { content, onBlur, placeholder, onChange } = props && props;
    const editor = useRef(null);
    return (
        <JoditEditor
            ref={editor}
            value={content}
            config={Config.CONFIG_JODIT}
            tabIndex={1}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
        />
    );
};

export default ContentEditor;