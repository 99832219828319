import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Label } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { CreatableMultiselect, DataEmty } from "@components/common";

//untils
import { LoaderText, messageData, apiCaller, formatReactSelect } from "@utils";

//lodash
import _ from "lodash";

//call Action
import { actFetchAccountInstitutionRequest } from "@actions";
import { seriesConstants } from "@constants";

//elements
import { ButtonDelete } from '@components/common/elements';

function AccessTab(props) {
    const dispatch = useDispatch();

    //props
    const { seriesLoadById, activeSeries } = props && props;
    const ManagersData = seriesLoadById?.Managers;

    //store
    const storeAccInstitution = useSelector((state) => state?.accountInstitution?.data || {});
    const accountData = storeAccInstitution?.Accounts;
    const dropdownSelect = accountData?.length > 0 ? formatReactSelect(accountData) : [];

    //state
    const [isLoading, setLoading] = useState({
        add: false,
        delete: false
    });
    const [listInvitedEmails, setInvitedEmails] = useState([]);

    //load Account
    useEffect(() => {
        dispatch(actFetchAccountInstitutionRequest());
    }, [dispatch]);

    // Remove  Managers to Access
    const handleRemove = (value) => {
        setLoading((prevState) => ({ ...prevState, delete: true }));
        const params = {
            Action: "RemoveManagers",
            AccountEmails: [value?.email]
        }

        dispatch({
            type: seriesConstants.SERIES_REMOVE_MANAGERS_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/series/${activeSeries}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: seriesConstants.SERIES_REMOVE_MANAGERS_SUCCESS,
                    payload: data,
                    seriesId: activeSeries
                });
                setLoading((prevState) => ({ ...prevState, delete: false }));
            } else {
                dispatch({
                    type: seriesConstants.SERIES_REMOVE_MANAGERS_FAILURE,
                    error: "error",
                });
                setLoading((prevState) => ({ ...prevState, delete: false }));
            }
        });
    };

    //Add Managers to Access
    const addManagerstoLaunchPlan = () => {
        //Convert Array to String
        if (listInvitedEmails?.length > 0) {
            var arrEmails = _.map(listInvitedEmails, function (element, idx) {
                return element.value;
            });
        }
        const params = {
            Action: "AddManagers",
            AccountEmails: arrEmails
        }

        // Add Managers to Access
        setLoading((prevState) => ({ ...prevState, add: true }));
        dispatch({
            type: seriesConstants.SERIES_ADD_MANAGERS_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/series/${activeSeries}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: seriesConstants.SERIES_ADD_MANAGERS_SUCCESS,
                    payload: data,
                    seriesId: activeSeries
                });
                setLoading((prevState) => ({ ...prevState, add: false }));
            } else {
                dispatch({
                    type: seriesConstants.SERIES_ADD_MANAGERS_FAILURE,
                    error: "error",
                });
                setLoading((prevState) => ({ ...prevState, add: false }));
            }
        });
    };

    const columns = [
        {
            dataField: "Id",
            text: "ID",
            hidden: true,
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "lastname",
            text: "Last Name",
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "firstname",
            text: "First Name",
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "email",
            text: "Email",
            headerClasses: "--text-primary font-weight-500",
            headerStyle: (colum, colIndex) => {
                return { width: "40%" };
            },
        },
        {
            dataField: "Actions",
            text: "",
            headerStyle: (colum, colIndex) => {
                return { width: "50px", textAlign: "center" };
            },
            headerClasses: "--text-primary font-weight-500 align-middle",
            formatter: (cell, row) => {
                return (
                    <ButtonDelete handleDelete={() => handleRemove(row)} />
                );
            },
        },
    ];

    const { SearchBar } = Search;
    return (
        <Container>
            <Row className="justify-content-start mb-3">
                <Col sm={`12`}>
                    <div className="dropdownMutiCt">
                        <div className="dropdownMutiCt__left">
                            <CreatableMultiselect
                                handleChangeData={(data) => setInvitedEmails(data)}
                                placeholder={`Click and select managers to add to this launch plan`}
                                DataOption={dropdownSelect || []}
                            />
                        </div>
                        <div className="dropdownMutiCt__right">
                            <Button
                                className="--btn-primary d-flex align-items-center ml-auto text-nowrap"
                                onClick={addManagerstoLaunchPlan}
                                disabled={isLoading?.add || (listInvitedEmails?.length > 0 ? false : true)}
                            >
                                {isLoading?.add && (
                                    <span className="spinner-border spinner-border-sm mr-2"></span>
                                )}
                                Add Manager(s)
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <div className="position-relative">
                {(isLoading?.add || isLoading?.delete) && <LoaderText />}
                <ToolkitProvider
                    keyField="Id"
                    data={ManagersData || []}
                    columns={columns}
                    search
                >
                    {(toolkitprops) => (
                        <React.Fragment>
                            <Row className="justify-content-start mb-3 mt-3">
                                <Col md={6} sm={6} className="py">
                                    <div className="formSearchInline">
                                        <Label for="search" className="mr-2 mb-0">
                                            Search:
                                        </Label>
                                        <SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder="Enter name or email"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <BootstrapTable
                                condensed
                                striped
                                wrapperClasses="table-responsive"
                                bordered={false}
                                noDataIndication={() => <DataEmty content={messageData?.filter} />}
                                {...toolkitprops.baseProps}
                            />
                        </React.Fragment>
                    )}
                </ToolkitProvider>
            </div>
        </Container>
    );
}

export default AccessTab;