import React from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import Star from '@images/common/star.svg'

const WrapRating = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export default function Rating(props) {
  const { 
    rating, 
    width = 20 
  } = props;
  let array = [1, 2, 3, 4, 5]

  return (
    <WrapRating>
      {array?.map((rate, index) => (
        <Icon key={index} src={Star} width={width} color={rate <= rating ? '#FDB022' : '#F2F4F7'}/>
      ))}
    </WrapRating>
  )
}
