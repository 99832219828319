import React from 'react';
import { Tooltip } from 'reactstrap';

const InfoTooltip = ({ isOpen, id, toggle, content, width = '273px' }) => {
    return (
        <Tooltip
            placement="top"
            isOpen={isOpen}
            target={id}
            toggle={toggle}
            style={{ minWidth: width }}  // Sử dụng width từ props
        >
            {content}
        </Tooltip>
    );
};

export default InfoTooltip;