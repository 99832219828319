function CommentArea(props) {
    const { content } = props && props;
    return (
        <div
            {...props}
            id="interact_panel_exam_instructions"
            dangerouslySetInnerHTML={{
                __html: content,
            }}
        ></div>
    )
};

export default CommentArea;