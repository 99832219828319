import { registerConstants } from "@constants";
import {apiCaller} from "@utils";

//post verify
export const postVerifyRegister = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/auth/register/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: registerConstants.VERIFY_REQUEST, isLoading } }
    function success(data) { return { type: registerConstants.VERIFY_SUCCESS, payload: data } }
    function failure(error) { return { type: registerConstants.VERIFY_FAILURE, error: error } }
}