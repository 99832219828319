import React, { useState, useEffect } from "react";
import { Badge } from "reactstrap";

import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//constants
import { teachConstants } from "@constants";

//apiCaller
import { apiCaller } from "@utils";

//actions
import { fetchAllQuestionsForElement } from "@actions";

// gradingSchemaOptions
import gradingSchemaOptions from "./DefineParams/GradingSchemaList";

// constants
import { storyboardConstants } from "@constants";

//call Action
import {
  actQuestionOrder,
  atcloadQuestionsFromTemplates,
} from "@actions";

//loader
import LoaderText from "@utils/LoaderText";

//liblary
import _ from "lodash";
import ReactTooltip from "react-tooltip";

//common
import { DataEmty } from "@components-common";

//components
import { ModalClone, ModalDelete, ModalCreateQuestion, ModalEditQuestion } from "../Modal/Question";
import {
  QuestionTop,
  ModalChooseTemplate,
  ActionsForAction,
  ModalQuestionBank,
} from "../Question";

//test
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

function DragQuestion(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { isViewQuestion, data, disableSort, timePointId, task } = props && props;
  const [modal, setModal] = useState({
    createQuestion: false,
    editQuestion: false,
    addTemplate: false,
    questionBank: false,
    cloneQuestion: false,
    deleteQuestion: false
  });
  const [itemActive, setItemActive] = useState(null);
  const [isLoading, setLoading] = useState({
    addTemplate: false,
    cloneQuestion: false,
    deleteQuestion: false
  });
  const [itemSort, setItemSort] = useState(
    (data && data) || []
  );
  const [isSort, setIsSort] = useState(false);

  //Update Array Store
  useEffect(() => {
    setItemSort((data && data) || []);
    ReactTooltip.rebuild();
  }, [data]);

  //Load TimePoint Data By TimepointId
  useEffect(() => {
    const loadTimePointById = () => {
      const params = {
        Action: "Load",
        Target: "TimePoint",
        TimePointId: timePointId,
      };
      dispatch({ type: storyboardConstants.TIME_POINT_REQUEST, isLoading: true });
      apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
        const data = res?.data;
        if (res?.status === 200) {
          console.log(data, "data...loadTimePointById");
          dispatch({ type: storyboardConstants.TIME_POINT_SUCCESS, payload: data });
        } else {
          dispatch({ type: storyboardConstants.TIME_POINT_FAILURE, error: 'error' });
        }
      });
      console.log(params, "params...loadTimePointById");
    }
    isViewQuestion === "elements" && loadTimePointById(); //Chỉ khi <DragQuestion /> View Settings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //Load Question Blank
  useEffect(() => {
    const params = {
      Action: "Load",
      Target: "QuestionBank",
      Source: "Template",
    };
    dispatch(atcloadQuestionsFromTemplates(id, params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (name, item) => {
    setModal((prevState) => ({ ...prevState, [name]: !modal[name] }));
    setItemActive(item);
  };

  //Remove Question
  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "Question",
      TimePointId: timePointId,
      Task: task,
      QuestionId: itemActive?.QuestionId,
    };

    const actionList = {
      resquest: teachConstants?.DELETE_QUESTION_REQUEST,
      success: teachConstants?.DELETE_QUESTION_SUCCESS,
      failure: teachConstants?.DELETE_QUESTION_FAILURE,
    }
    sendApiByType(id, params, actionList, 'deleteQuestion');
  };

  //send Api with action
  const sendApiByType = (id, params, actionList, type) => {
    setLoading((prevState) => ({ ...prevState, [type]: true }));
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      dispatch({ type: actionList?.resquest, isLoading: true });
      if (res?.status === 200) {
        const data = res?.data;
        //Load All Question
        const params = {
          Action: "Load",
          Target: "CaseQuestions",
        };
        dispatch(fetchAllQuestionsForElement(id, params));

        //Set Loading And Close
        setModal((prevState) => ({ ...prevState, [type]: false }));
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.success, payload: data });
      } else {
        setLoading((prevState) => ({ ...prevState, [type]: false }));
        dispatch({ type: actionList?.failure, isLoading: false });
        setModal((prevState) => ({ ...prevState, [type]: false }));
      }
    });
  }

  //Submit Question
  const handleCloneData = () => {
    itemActive?.Answers?.forEach((item) => {
      delete item.AnswerId;
      delete item.QuestionAnswerId;
    });
    const params = {
      Action: "Create",
      Target: "Question",
      TimePointId: timePointId,
      Task: task,
      QuestionContent: itemActive.QuestionContent,
      QuestionExplanation: itemActive.QuestionExplanation,
      QuestionType: itemActive.QuestionType,
      Domains: itemActive.Domains,
      Answers: itemActive.Answers,
      FollowUpExplanationRequired: itemActive.FollowUpExplanationRequired,
      FlowConditions: itemActive.FlowConditions,
    };

    const actionList = {
      resquest: teachConstants?.CLONE_QUESTION_REQUEST,
      success: teachConstants?.CLONE_QUESTION_SUCCESS,
      failure: teachConstants?.CLONE_QUESTION_FAILURE,
    }
    sendApiByType(id, params, actionList, 'cloneQuestion');
  };

  const ellipsify = (str) => {
    if (str.length > 10) {
      return str.substring(0, 10) + "...";
    } else {
      return str;
    }
  };

  const showNameByType = (type) => {
    let string = "";
    switch (type) {
      case "multiple_choice":
        string = "(Multiple Choice)";
        break;
      case "multiple_choice_table":
        string = "(Multiple Choice Table)";
        break;
      case "free_text":
        string = "(Free Text)";
        break;
      case "drag_n_drop":
        string = "(Drag And Drop)";
        break;
      case "drop_down":
        string = "(Drop Down)";
        break;
      case "highlight":
        string = "(Highlight)";
        break;
      default:
        string = "(Drag and drop Cloze)";
    }
    return string;
  };

  const showNameBySchema = (type, list) => {
    const filteredData = _.find(list, { value: type });
    return <span>({filteredData?.label})</span>
  };

  //store Data
  const questionData = useSelector((state) => state.loadQuestion || []);
  const listDomainsNotChecked = useSelector((state) => (state.teachCase && state.teachCase.listDomain) || []);
  const listQuestionBlank = useSelector((state) => state?.listQuestionFormTemplates?.data || []);

  //test
  const SortableItem = sortableElement(({ item }) => {
    const flowConditionsValue = item.FlowConditions && item.FlowConditions[0];
    const QuestionId = item?.QuestionId;
    const GradingSchema = item?.GradingSchema;
    return (
      <li
        className="lsQuestion__item -box-shadow SortableItemQuestion border"
        data-id={QuestionId}
      >
        {!disableSort && <div className="iconMove"></div>}
        <div className="innerCt">
          <div className={`innerCt__left ${disableSort ? "pl-0" : ""}`}>
            <p className="mb-2">
              <span className="font-weight-600 mr-2">#{QuestionId}</span>
              <span className="font-weight-300" style={{ color: "#bfbfbf" }}>
                {showNameByType(item?.QuestionType)}
                <span className="mx-1">|</span>
                {showNameBySchema(item?.GradingSchema, gradingSchemaOptions)}
              </span>
              <br />
              {flowConditionsValue?.Status === "correct" && (
                <span
                  className="font-italic"
                  style={{ color: "#bfbfbf" }}
                >{`(asked only if #${flowConditionsValue?.QuestionId} is correct)`}</span>
              )}
              {flowConditionsValue?.Status === "incorrect" && (
                <span
                  className="font-italic"
                  style={{ color: "#bfbfbf" }}
                >{`(asked only if #${flowConditionsValue?.QuestionId} is incorrect)`}</span>
              )}
            </p>
            <h3
              dangerouslySetInnerHTML={{
                __html: item.QuestionContent,
              }}
            ></h3>
          </div>
          <div className="innerCt__right d-flex flex-column">
            <ReactTooltip />
            <ActionsForAction
              item={item}
              QuestionId={QuestionId}
              toggleEdit={() => toggle("editQuestion", item)}
              toggleDel={() => toggle("deleteQuestion", item)}
              toogleClone={() => { toggle("cloneQuestion", item) }}
              isSchema={GradingSchema}
            />
            {item?.Domains?.length > 0 && (
              <ul className="lsBadge mt-2">
                {item?.Domains?.map(
                  (value, i) =>
                    value.Selected === true && (
                      <Badge
                        className="lsBadge__item py-2"
                        color="info"
                        data-tip={value.Name}
                        key={i}
                      >
                        {ellipsify(value.Name)}
                      </Badge>
                    )
                )}
              </ul>
            )}
          </div>
        </div>
      </li>
    );
  });

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className="lsQuestion">{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItemSort(arrayMove(itemSort, oldIndex, newIndex));
    setIsSort(true);
  };

  useEffect(() => {
    const requestApiSort = (dataSort) => {
      const params = {
        Action: "Update",
        Target: "Questions_Order",
        TimePointId: timePointId,
        Task: task,
        QuestionsOrderByIds: dataSort,
      };

      dispatch(actQuestionOrder(id, params));

      //Done
      setIsSort(false);
    };

    if (itemSort && isSort) {
      var result = _.map(itemSort, "QuestionId");
      const newArray = result.map((item) => {
        return item;
      });
      requestApiSort(newArray);
    }
  }, [itemSort, isSort, dispatch, id, timePointId, task]);

  const timepointData = useSelector((state) => state.timepoint || []); //isViewQuestion === "elements"
  return (
    <div className="dragQuestionCt">
      {isViewQuestion !== "elements" && <QuestionTop handleAdd={toggle} data={data || []} />}

      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis="y"
        helperClass="SortableQuestion"
        distance={1}
      >
        {itemSort.map((value, index) => (
          <SortableItem
            key={`item-${value.QuestionId}`}
            index={index}
            item={value}
            disabled={disableSort}
          />
        ))}
      </SortableContainer>

      {/* Modal Edit */}
      {questionData?.isLoaing ? (
        <LoaderText style={{ minHeight: "402px" }} />
      ) : (
        <React.Fragment>
          {modal.editQuestion && (
            <ModalEditQuestion
              {...props}
              isOpen={modal.editQuestion}
              toggle={() => toggle('editQuestion', itemActive)}
              itemQuestion={itemActive}
              domainsDefault={listDomainsNotChecked || []}
              task={task}
              itemActive={itemActive}
              timepointData={timepointData} //isViewQuestion === "elements"
            />
          )}
          {modal.createQuestion && (
            <ModalCreateQuestion
              {...props}
              isOpen={modal.createQuestion}
              toggle={() => toggle('createQuestion', itemActive)}
              domainsDefault={listDomainsNotChecked || []}
              task={task}
              itemActive={itemActive}
              timepointData={timepointData} //isViewQuestion === "elements"
            />
          )}
        </React.Fragment>
      )}

      {/* Modal Choose Template */}
      {modal.addTemplate && (
        <ModalChooseTemplate
          {...props}
          listQuestionBlank={listQuestionBlank || {}}
          isOpen={modal.addTemplate}
          toggle={() => toggle("addTemplate", {})}
          timepointData={timepointData} //isViewQuestion === "elements"
        />
      )}

      {/* Modal Question Bank */}
      {modal.questionBank && (
        <ModalQuestionBank
          {...props}
          listQuestionBlank={listQuestionBlank || {}}
          isOpen={modal.questionBank}
          toggle={() => toggle("questionBank", {})}
          timepointData={timepointData} //isViewQuestion === "elements"
        />
      )}

      {/* Modal Delete */}
      {modal?.deleteQuestion && (
        <ModalDelete
          isOpen={modal?.deleteQuestion}
          toggle={() => toggle("deleteQuestion", {})}
          handleRemove={handleRemove}
          isLoading={isLoading?.deleteQuestion}
          timepointData={timepointData} //isViewQuestion === "elements"
        />
      )}

      {/* Modal Clone */}
      {modal?.cloneQuestion && (
        <ModalClone
          isOpen={modal?.cloneQuestion}
          toggle={() => toggle("cloneQuestion", {})}
          data={itemActive}
          handleSubmit={handleCloneData}
          isLoading={isLoading?.cloneQuestion}
          timepointData={timepointData} //isViewQuestion === "elements"
        />
      )}
      {/* /Modal Clone */}
      {itemSort.length === 0 && <DataEmty content="No Data" />}
    </div>
  );
}

export default DragQuestion;