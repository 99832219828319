import React from 'react';

//reactstrap
import { FormGroup, Input, Label } from "reactstrap";

function ShowOnlyAvailable(props) {
    const { handleShowOnlyAvailable, availableOnly, label, name } = props && props;
    return (
        <FormGroup
            check
            inline
            className='mt-3'
        >
            <Label className="d-flex align-items-center font-weight-500" check>
                <Input
                    type="checkbox"
                    name={name}
                    onChange={(e) => handleShowOnlyAvailable(e, name)}
                    checked={availableOnly}
                />
                {label}
            </Label>
        </FormGroup>
    );
};

export default ShowOnlyAvailable;