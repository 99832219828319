import React from "react";

//Boostrap
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";

//components 
import { AiLabelContent } from "../ReliDiagnosis";

function ModalAiLabel(props) {
    const { isOpen, toggle } = props && props; 
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{maxWidth: '1000px'}}>
            <ModalHeader toggle={toggle}>
                AI label
            </ModalHeader>
            <ModalBody>
                <AiLabelContent {...props} />
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalAiLabel;