import React from 'react';
const MessageUser = ({ content, avatarUser, roleText }) => (
    <div className="messengerCt__wrapper">
        <div className="messengerCt__message messengerCt__message--out">
            <div className="messengerCt__message-body">
                <div className="messengerCt__message-content mr-0 --arrow-right">
                    {roleText && 
                        <div className="messengerCt__message-username">
                            {roleText}
                        </div>
                    }
                    <div className="messengerCt__message-text" dangerouslySetInnerHTML={{ __html: content || '' }} />
                </div>
            </div>
            <div className="messengerCt__message-pic">
                <img src={avatarUser} alt="profile" />
            </div>
        </div>
    </div>
);

export default MessageUser;