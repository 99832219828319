import React from "react";

// Boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

function ModalViewMedia(props) {
    const { isOpen, toggle, values } = props;
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop="static"
            zIndex={16000}
            size="lg"
            centered
        >
            <ModalBody className="text-center">
                <div className="py-4">
                    {values && values.Type?.toLowerCase() === "image" && (
                        <img
                            className="img-fluid"
                            src={values.URL}
                            alt={values.Type}
                        />
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalViewMedia;