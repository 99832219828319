import React, { useEffect } from "react";

//boostrap
import { Container } from "reactstrap";

//components
import { NavigationComponent } from "@components/layouts";

//redux
import { useDispatch, useSelector } from "react-redux";

//menu
import navigation from "@utils/navigation";

//call Action
import { atcOnBoardingReport } from "@actions";

//components
import { TabsOnBoarding, FilterTime } from "@components/admin";

function OnBoardingPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        StartDate: null,
        EndDate: null,
      };
      dispatch(atcOnBoardingReport(params));
    };
    //fetch data
    fetchData();
  }, [dispatch]);

  const handleChangeValue = (dateDate) => {
    dispatch(atcOnBoardingReport(dateDate));
  };

  // dataOnboaringReport
  const dataReport = useSelector(
    (state) =>
      (state.dataOnboaringReport && state.dataOnboaringReport.data) || []
  );

  const mainMenu = navigation && navigation.mainAdmin;
  return (
    <React.Fragment>
      <NavigationComponent data={mainMenu} />
      <div className="wrapper -OnBoardingPage">
        <Container>
          <FilterTime handleChangeValue={handleChangeValue} />
          <TabsOnBoarding dataReport={(dataReport && dataReport) || {}} />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default OnBoardingPage;