import React from 'react';
import moment from 'moment';

const DateFormatter = ({ date, format = 'DD/MM/YYYY hh:mm A' }) => {
  return (
    <div className='format-time text-nowrap'>
      {moment(date).format(format)}
    </div>
  );
};

export default DateFormatter;