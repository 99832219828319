import React, { useState, useEffect } from "react";

//reactstrap
import { Button, Col } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const AddNewReview = (props) => {
    const { handleBack, itemReviewSections, handleEditReviewforLogging, isLoading, typeReview } = props && props;

    //state
    const [itemReview, setItemReview] = useState(itemReviewSections || {
        "review_content": ""
    });

    //Load Content
    useEffect(() => {
        setItemReview(itemReviewSections || {
            "review_content": ""
        });
    }, [itemReviewSections])

    //Handle Change
    const handleChangeTextAreA = (e) => {
        const { name, value } = e.target;
        setItemReview((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    
    return (
        <Col md={4} className="h-100" style={{ overflowY: 'auto' }}>
            <div className={`d-flex align-items-center ${typeReview === "Add" ? "justify-content-between" : "justify-content-end"}`}>
                {typeReview === "Add" && (
                    <h3>{`New Review`}</h3>
                )}
                <div className="groupbtn">
                    <Button color="secondary" className="bg-white text-dark mr-2" onClick={() => handleBack()}>
                        <i className="fa fa-chevron-left mr-2" aria-hidden="true"></i>
                        Back
                    </Button>
                    {(itemReview?.is_editable || typeReview === "Add") && (
                        <Button color="primary" className="--btn-primary" onClick={() => handleEditReviewforLogging(itemReview, typeReview)}>
                            {isLoading?.editReviewForLogging && <span className="spinner-border spinner-border-sm mr-2"></span>}
                            Save
                        </Button>
                    )}
                </div>
            </div>
            <TextareaAutosize
                className={`w-100 form-control mt-3`}
                name="review_content"
                placeholder=""
                type="text"
                onChange={handleChangeTextAreA}
                defaultValue={itemReview?.review_content}
                maxRows={10}
                minRows={10}
                readOnly={(itemReview?.is_editable || typeReview === "Add") ? false : true}
            />
        </Col>
    );
};

export default AddNewReview;