export const ordersConstants = {
    LOAD_ORDER_REQUEST: 'LOAD_ORDERS_REQUEST',
    LOAD_ORDER_SUCCESS: 'LOAD_ORDERS_SUCCESS',
    LOAD_ORDER_FAILURE: 'LOAD_ORDERS_FAILURE',

    UPDATE_ORDER_THIS_TIMEPOIN_REQUEST: 'UPDATE_ORDER_THIS_TIMEPOIN_REQUEST',
    UPDATE_ORDER_THIS_TIMEPOINT_SUCCESS: 'UPDATE_ORDER_THIS_TIMEPOINT_SUCCESS',
    UPDATE_ORDER_THIS_TIMEPOINT_FAILURE: 'UPDATE_ORDER_THIS_TIMEPOINT_FAILURE',

    SIGN_ORDERS_REQUEST: 'SIGN_ORDERS_REQUEST',
    SIGN_ORDERS_SUCCESS: 'SIGN_ORDERS_SUCCESS',
    SIGN_ORDERS_FAILURE: 'SIGN_ORDERS_FAILURE',


    REMOVE_ORDER_REQUEST: 'REMOVE_ORDER_REQUEST',
    REMOVE_ORDER_SUCCESS: 'REMOVE_ORDER_SUCCESS',
    REMOVE_ORDER_FAILURE: 'REMOVE_ORDER_FAILURE',
};