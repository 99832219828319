import React from "react";

//react-select
import Select from 'react-select';

function ChatGptVersionFilter(props) {
    const { handleChangeOptions, dataSelect, selectedOption } = props || {};
    return (
        <Select
            options={dataSelect || []}
            closeMenuOnSelect={true}
            value={selectedOption}
            onChange={(optionValue) => handleChangeOptions(optionValue)}
            autoComplete="off"
            name="version"
            placeholder="Select a version"
            style={{ flex: 1 }}
        />
    );
}

export default ChatGptVersionFilter;