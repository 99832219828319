// AddUserButton.jsx
import React from 'react';
import styled from 'styled-components';

// Define the styled button component
const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  &:hover {
    background-color: #2563EB;
  }

  svg {
    width: 20px;
    height: 20px;
    stroke: white;
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    margin-right: 4px;
  }
`;

const AddUserButton = ({ toggle }) => {
  return (
    <StyledButton className='btn btn-primary btn-sm' onClick={() => toggle("addLearner")}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
        <path d="M10.0001 4.16666V15.8333M4.16675 9.99999H15.8334" />
      </svg>
      Add User(s)
    </StyledButton>
  );
};

export default AddUserButton;