import React, { useState } from 'react';
import Select from 'react-select';

const options = [
    { value: 'none', label: 'None' },
    { value: 'title-asc', label: 'Case Title (A-Z)' },
    { value: 'title-desc', label: 'Case Title (Z-A)' },
    { value: 'edited-newest', label: 'Date Created (Newest First)' },
    { value: 'edited-oldest', label: 'Date Created (Oldest First)' }
];

const customStyles = {
    control: (provided) => ({
        ...provided,
        width: 250,
    }),
};

const InteractiveModuleSort = ({ onChange }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selected) => {
        setSelectedOption(selected);
        onChange(selected);
    };

    return (
        <Select
            className="react-select-common ml-2"
            classNamePrefix="react-select-custom"
            value={selectedOption}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            isClearable
            placeholder="Sort by..."
        />
    );
};

export default InteractiveModuleSort;