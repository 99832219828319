import React from 'react';

//reactstrap
import { Row, Col } from 'reactstrap';
import { ScoreInput, FeedbackText, DomainsList } from '../ClickType';

function ScoringFeedBack(props) {
    return (
        <Row>
            <Col md={6}>
                <ScoreInput {...props} />
                <FeedbackText {...props} />
            </Col>
            <Col md={6}>
                <DomainsList {...props} />
            </Col>
        </Row>
    );
};

export default ScoringFeedBack;