import React, { useState, useRef, useEffect } from "react";

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch, useSelector } from "react-redux";

//Boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";

//action
import { atcCreateDomain, atcEditDomain } from "@actions";

//Config
import * as Config from "@constants/Config";

//Editor
import JoditEditor from "jodit-react";

function ModalUpdate(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const editor = useRef(null);

  //props
  let { item, isOpen, toggle, actionClick } = props && props;

  //editor
  const [valueTitle, setTitle] = useState("");
  const [valueEditor, setEditor] = useState("");

  //valid
  const [titleValid, setTitleValid] = useState(false);

  const checkTitleValid = (value) => {
    if (value) {
      setTitleValid(false);
      return false;
    } else {
      setTitleValid(true);
      return true;
    }
  };

  useEffect(() => {
    //Set params edit
    if (actionClick === "editDomain") {
      setEditor(item?.Description);
      setTitle(item?.Name);
      setTitleValid(false);
    } else {
      setEditor('');
      setTitle('');
      setTitleValid(false);
    }
  }, [actionClick, item]);

  const handleSubmit = () => {
    if (!checkTitleValid(valueTitle)) {
      //Add
      if (actionClick === "addDomain") {
        const params = {
          Action: "Create",
          Target: "Domain",
          DomainName: valueTitle,
          DomainDescription: valueEditor,
        };

        dispatch(atcCreateDomain(id, params));
        toggle();
      }

      if (actionClick === "editDomain") {
        const params = {
          Action: "Update",
          Target: "Domain",
          DomainId: item?.Id,
          DomainName: valueTitle,
          DomainDescription: valueEditor,
        };
        dispatch(atcEditDomain(id, params));
        toggle();
      }
    }
  };

  const checkData = useSelector(state => state.teachCase || []);
  return (
    <Modal
      style={{ maxWidth: "650px" }}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>
        {actionClick === "addDomain" ? "Add domain" : "Edit Domain"}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="title">Domain Name<span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="title"
              invalid={titleValid}
              placeholder=""
              defaultValue={valueTitle}
              onChange={(e) => setTitle(e.target.value)}
              autoComplete="off"
            />

            {titleValid && <FormFeedback>This field is required.</FormFeedback>}
          </FormGroup>
        </Form>
        <Form>
          <FormGroup>
            <Label className="font-weight-500 mb-3" for="QuestionText">
              Descriptions
            </Label>
            <JoditEditor
              ref={editor}
              value={valueEditor}
              config={Config.CONFIG_JODIT}
              placeholder="Enter your text here..."
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => setEditor(newContent)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={handleSubmit}
          disabled={checkData?.isLoading}
        >
          {checkData?.isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {actionClick === "addDomain" ? "Add domain" : "Save Domain"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalUpdate;
