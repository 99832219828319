import React from 'react';

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//components
import { DragQuestion } from "../../storyboard/Question";
import { Setting, ViewMode } from "../../storyboard/pnorder";

//common
import { RichTextCommon } from "../../storyboard";

//common components
import { HeadTabs } from "../../storyboard/common";

//redux
import { useSelector } from "react-redux";

//utils
import { LoaderText } from "@utils";

function PnOrderTabs(props) {
    const {
        listTabs,
        activeTabCt,
        handleUpdateEditor,
        value,
        valueEditor,
        timePointId,
        filterQuestionForTask
    } = props && props;

    //store
    const pnOrderData = useSelector((state) => state.loadPnOrders || []);
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='pn'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.pn?.map(
                    (item, index) =>
                        item?.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item?.id === "1" && (
                                    <React.Fragment>
                                        {pnOrderData?.isLoading && <div style={{ height: '300px' }}>
                                            <LoaderText />
                                        </div>}
                                        {!pnOrderData?.isLoading && (
                                            <Setting
                                                {...props}
                                                data={pnOrderData?.data || []}
                                            />
                                        )}
                                    </React.Fragment>
                                )}
                                {item?.id === "2" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={value?.ViewClass}
                                        defaultValue={valueEditor?.pn}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item?.id === "3" && (<ViewMode {...props} data={pnOrderData?.data || []} />)}
                                {item?.id === "4" && (
                                    <DragQuestion
                                        {...props}
                                        task={value?.ViewClass}
                                        data={filterQuestionForTask(value?.ViewClass)}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
};

export default PnOrderTabs;