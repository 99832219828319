import React from 'react';

// Icon
import { Icon } from '@components/common';
import Trash from '@images/teachV2/trash.svg';
import styled from 'styled-components';

const WrapFile = styled.div`
    .wrap-option__file-wrap {
        padding: 16px;
        border-radius: 12px;
        background: #FFF;
        display: flex;
        align-items: start;
        gap: 8px;
        border: 1px solid #EAECF0;
    }
    .wrap-option__file-info {
        width: 100%;
        display: flex;
        align-items: start;
        gap: 8px;
        h4 {
        color: #344054;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        }
        p {
        color: #475467;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        }
        .italic {
        font-style: italic;
        }
        .progress-loading {
        text-align: left;
        }
        .btn-try {
        cursor: pointer;
        color: #475467;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        }
    }
    .wrap-option__file-trash {
        cursor: pointer;
        &:hover {
        svg {
            path {
            stroke: red;
            }
        }
        }
    }
`

const BookListFile = ({ setBookId, bookId, setDataFileImported }) => {
    const handleRemoveBook = (id) => {
        const updatedBookId = bookId.filter(book => book.document_id !== id);
        setBookId(updatedBookId);
        setDataFileImported && setDataFileImported(null);
    };

    return (
        <WrapFile>
            {bookId?.map((book) => (
                <div key={book.document_id} className="wrap-option__file-wrap mb-2 d-flex align-items-center">
                    <div className="wrap-option__file-info">
                        <Icon src={File} />
                        <div className='progress-loading'>
                            <h4>{book.title}</h4>
                        </div>
                    </div>
                    <div className='wrap-option__file-trash' onClick={() => handleRemoveBook(book.document_id)}>
                        <Icon src={Trash} />
                    </div>
                </div>
            ))}
        </WrapFile>
    );
};

export default BookListFile;