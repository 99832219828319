import { authConstants } from "@constants";
import {apiCaller} from "@utils";

// Load Auth Code List
export const loadAuthCodeListById = (idCohort) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/admin/auth_codes/?cohort_id=${idCohort}`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: authConstants.LOAD_AUTH_CODE_LIST_REQUEST, isLoading } }
    function success(data) { return { type: authConstants.LOAD_AUTH_CODE_LIST_SUCCESS, payload: data } }
    function failure(error) { return { type: authConstants.LOAD_AUTH_CODE_LIST_FAILURE, error: error } }
}

export const createAuthcode = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/admin/auth_codes/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: authConstants.CREATE_AUTH_CODE_REQUEST, isLoading } }
    function success(data) { return { type: authConstants.CREATE_AUTH_CODE_SUCCESS, payload: data } }
    function failure(error) { return { type: authConstants.CREATE_AUTH_CODE_FAILURE, error: error } }
}