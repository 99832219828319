import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//boostrap
import { Container, Row, Col } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//call Action
import { actUpdateTeachCaseRequest } from "@actions";

import LoaderPage from "@utils/LoaderPage";

//components
import {
  ContentRight,
  ListDomain,
  QuickSettings,
  CasePhoto,
  DeleteCaseBox,
  ListTags
} from "@components/teach";

//common
import { HeadingBox } from "@components/common";

//Avatar
import { AvatarUpload } from '@components/teach/AvatarUpdate';

import { ContentProcess } from "@components/common";

function TechCase(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //state
  const [stateTitle, setTitle] = useState("");
  const [modal, setModal] = useState(false);

  //toggle
  const toggle = () => setModal(!modal);

  //get data store
  const teachCaseData = useSelector((state) => state.teachCase || []);
  const dataResponse = teachCaseData && teachCaseData.data;

  useEffect(() => {
    setTitle(dataResponse?.Title);
  }, [dataResponse]);

  const handleChange = (e) => {
    const { value } = e.target;
    setTitle(value);
  };

  const handleSave = (e) => {
    const params = {
      Action: "Update",
      Title: stateTitle,
    };
    dispatch(actUpdateTeachCaseRequest(id, params));
    setModal(false);
  };

  const handleChangeSwitch = (e) => {
    const { name, checked } = e.target;
    if (name === "LearnMode") {
      const params = {
        Action: "Update",
        LearnMode: checked,
      };
      dispatch(actUpdateTeachCaseRequest(id, params));
    }

    if (name === "Publish") {
      const params = {
        Action: "Update",
        Status: checked ? "Active" : "Draft",
      };
      dispatch(actUpdateTeachCaseRequest(id, params));
    }

    if (name === "Retries") {
      const params = {
        Action: "Update",
        Target: "Retries",
        Retries: checked ? "Unlimited" : 0,
      };
      dispatch(actUpdateTeachCaseRequest(id, params));
    }
  };

  const DomainData = dataResponse?.Domains;
  const TasksData = dataResponse?.Tasks;
  const errorCase = teachCaseData?.data?.error;
  const data = {
    title: "You do not have privilege to edit this case.",
    description:
      "Please use the back button below to return to the previous page.",
  };
  return (
    <React.Fragment>
      {teachCaseData.isLoading && <LoaderPage />}
      {!teachCaseData.isLoading && (
        <React.Fragment>
          <div className={`wrapper ${errorCase ? "-completedPage" : "-techCasePage"}`}>
            {errorCase ? (
              <ContentProcess data={data} backHistory={true} />
            ) : (
              <Container>
                <HeadingBox title={dataResponse?.Title} />
                <Row>
                  <Col sm={12} md={12} lg={4}>
                    <QuickSettings
                      {...props}
                      dataResponse={dataResponse}
                      toggle={toggle}
                      modal={modal}
                      handleChange={handleChange}
                      stateTitle={stateTitle}
                      handleSave={handleSave}
                      handleChangeSwitch={handleChangeSwitch}
                    />
                    <CasePhoto dataResponse={dataResponse} />
                    <AvatarUpload dataResponse={dataResponse} />
                    <ListDomain data={DomainData} />
                    {id === "653" && (
                      <ListTags data={DomainData} />
                    )}
                    <DeleteCaseBox />
                  </Col>
                  <Col sm={12} md={12} lg={8}>
                    <ContentRight
                      dataCase={teachCaseData}
                      DomainData={DomainData}
                      TasksData={TasksData}
                    />
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default TechCase;