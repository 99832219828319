import React from "react";
import { Button, FormGroup } from "reactstrap";

const SubmitButtons = ({ isLoading, disabledButton, handleBackHome, foodItem, handleSubmit }) => {
    return (
        <FormGroup className="mt-2 d-flex justify-content-end">
            <Button className="btn-white mr-3 px-5" size="lg" disabled={disabledButton || isLoading} onClick={handleBackHome}>
                Cancel
            </Button>
            <Button color="primary" className="px-5" size="lg" disabled={disabledButton || isLoading} onClick={handleSubmit}>
                {isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                {foodItem ? "Save" : "Add"}
            </Button>
        </FormGroup>
    );
};

export default SubmitButtons;