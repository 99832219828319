import React, { useState, useEffect } from "react";

import { Table } from "reactstrap";

//lodash
import _ from "lodash";

//utils
import { messageData } from "@utils";

//common
import { DataEmty } from "@components-common";

//Table Common
import { TableHeader, TableCell, ModalUpdate, ModalInitalValue } from './IntakeOuput';

function IntakeOuputTable(props) {

  //props
  const { data, storeIntakeOutput, type } = props && props;

  //state
  const [stateData, setData] = useState((data && data) || []);
  const [show, setShow] = useState({
    vitalSign: false,
    initalValue: false
  });
  const [itemEdit, setItem] = useState({});

  //call Api
  useEffect(() => {
    setData((data && data) || []);
  }, [data]);

  const handleShow = (name, row, TimepointData) => {
    setShow((prevState) => ({ ...prevState, [name]: true }));

    // set State Data
    const itemData = { row, ...TimepointData }
    setItem(itemData);
  }

  const TableData = ({ data }) => {
    //filter Time !== 0
    const listTimepointLast = _.filter(data, function (o) {
      return o?.TimePoint?.Time !== 0;
    });

    //filter Time === 0
    const TimePointFirst = _.filter(data, function (o) {
      return o?.TimePoint?.Time === 0;
    });
    const dataDefault = TimePointFirst?.[0] && TimePointFirst?.[0].IOData?.[type];

    //Header
    const listHeader = [
      { Name: 'Item', width: "300px" },
      { Name: 'Inital Value', width: "300px" },
      ...listTimepointLast
    ]
    
    return (
      <div className="table-layout-fixed">
        <h2 className="heading-4 -heading-primary --text-primary text-uppercase">{type}</h2>
        <Table responsive>
          <TableHeader data={listHeader} />
          <tbody>
            {stateData && stateData.length === 0 && (
              <tr>
                <td>
                  <DataEmty content={messageData?.default?.content} />
                </td>
              </tr>
            )}
            {dataDefault?.map((col, index) => {
              return (
                <tr className="table-row" key={index}>
                  {/* Before */}
                  {TimePointFirst?.map((row) => {
                    const item = row.IOData?.[type][index];
                    return <TableCell value={item?.Name} item={row} />;
                  })}

                  {TimePointFirst?.map((row) => {
                    const item = row.IOData?.[type][index];
                    return (
                      <TableCell
                        handleShow={() => handleShow('initalValue', item, row?.TimePoint)}
                        value={`${item?.InitialValue} ${item?.Unit}`}
                        item={item}
                        firstTimepoint={true}
                      />
                    )
                  })}

                  {/* Last */}
                  {listTimepointLast?.map((row, j) => {
                    const item = row.IOData?.[type][index];
                    return (
                      <TableCell
                        handleShow={() => handleShow('intakeOuput', item, row?.TimePoint)}
                        value={item?.ChangeMode ? `${item?.ChangeValue} ${item?.Unit}` : '-'}
                        lastTimepoint={true}
                        item={item}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <React.Fragment>
      {!storeIntakeOutput?.isLoading && (
        <TableData data={(stateData && stateData) || []} />
      )}
      <ModalUpdate typeBalance={type} data={itemEdit} handleClose={() => { setShow((prevState) => ({ ...prevState, intakeOuput: false })) }} isOpenModal={show?.intakeOuput} />
      <ModalInitalValue typeBalance={type} data={itemEdit} handleClose={() => { setShow((prevState) => ({ ...prevState, initalValue: false })) }} isOpenModal={show?.initalValue} />
    </React.Fragment>
  );
}

export default IntakeOuputTable;
