import React from "react";

//components
import { Header } from "../LayoutChat";

const MasterLayoutChat = (props) => {
    return (
        <div className="layout-chat-root">
            <Header />
            <main className="main-content">
                {React.Children.map(props.children, (child) => {
                    return React.cloneElement(child, props.children);
                })}
            </main>
        </div>
    );
};

export default MasterLayoutChat;
