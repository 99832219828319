import { schemaContanst } from "@constants";
import { apiCaller } from "@utils";

//Load Schema
export const getSchemaList = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.GET_SCHEMA_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.GET_SCHEMA_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.GET_SCHEMA_FAILURE, error: error } }
}

//Load Schema
export const loadQuestionDetailForSchema = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.LOAD_QUESTION_ID_FOR_SCHEMA_FAILURE, error: error } }
}

//Search Concept
export const loadSearchConcept = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.SEARCH_CONCEPT_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.SEARCH_CONCEPT_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.SEARCH_CONCEPT_FAILURE, error: error } }
}

//Add Concept
export const AddConceptRequest = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.ADD_CONCEPT_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.ADD_CONCEPT_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.ADD_CONCEPT_FAILURE, error: error } }
}


//Delete Concept
export const DeleteConceptRequest = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.DELETE_CONCEPT_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.DELETE_CONCEPT_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.DELETE_CONCEPT_FAILURE, error: error } }
}

//Update Question Concept
export const updateQuestionConcept = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.UPDATE_CONCEPT_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.UPDATE_CONCEPT_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.UPDATE_CONCEPT_FAILURE, error: error } }
}

//Update Question With Tags
export const updateQuestionConceptForTag = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.UPDATE_CONCEPT_FOR_TAG_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.UPDATE_CONCEPT_FOR_TAG_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.UPDATE_CONCEPT_FOR_TAG_FAILURE, error: error } }
}

// Case - Test Question
export const submitTestQuestion = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/test/`, 'POST', params).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: schemaContanst.SUBMIT_TEST_QUESTION_REQUEST, isLoading } }
    function success(data) { return { type: schemaContanst.SUBMIT_TEST_QUESTION_SUCCESS, payload: data } }
    function failure(error) { return { type: schemaContanst.SUBMIT_TEST_QUESTION_FAILURE, error: error } }
}