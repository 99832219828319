import React, { useState, useRef, useEffect } from 'react';

//reactstrap
import { Input } from "reactstrap";

//lodash
import _ from "lodash";

function HeadingColumn(props) {
    const { handleRemoveItem, itemCol, indexCol, stateAnswerTable, handleUpdateStateAnswerTable } = props && props;

    //state
    const [isEdit, setEdit] = useState(false);

    //Clone Data - Handle In Component
    const [dataAnswerTable, setDataAnswerTable] = useState(stateAnswerTable || []);

    //Click outSide
    const ref = useRef();
    useOnClickOutside(ref, () => {
        setEdit(false);

        //update State
        handleUpdateStateAnswerTable(dataAnswerTable);
    });

    const handleChangeEditCell = (evnt) => {
        const { name, value } = evnt.target;
        let arrCopy = [...stateAnswerTable];
        _.map(arrCopy, (items) => {
            items[`Columns`][indexCol][name] = value
        });

        setDataAnswerTable(arrCopy);
    }

    return (
        <th
            width="200px"
            className="align-middle font-weight-500 --text-primary text-center text-nowrap removeColRow"
        >
            {isEdit ? (
                <div ref={ref}>
                    <Input
                        name={`ColumnTitle`}
                        type="text"
                        className='m-0 position-relative'
                        defaultValue={itemCol?.ColumnTitle}
                        onChange={handleChangeEditCell}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                setEdit(false);
                                //update State
                                handleUpdateStateAnswerTable(dataAnswerTable);
                            }
                        }}
                    />
                </div>
            ) : (<span onClick={() => setEdit(true)}>{itemCol?.ColumnTitle}</span>)}
            <span className="removeColRow__close" onClick={() => handleRemoveItem(indexCol, "Column")}>
                <i className="fa fa-times-circle" aria-hidden="true"></i>
            </span>
        </th>
    );
};

export default HeadingColumn;

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        }, [ref, handler]
    );
}