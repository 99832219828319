import React, { useEffect, useState } from "react";

//boostrap
import { Col, Table } from "reactstrap";

//components
import { Theader } from '../Chat';

//router
import { useParams } from "react-router-dom";

//format time
import moment from "moment";

//lodash
import _ from "lodash";

//redux
import { useDispatch, useSelector } from "react-redux";

//common
import { DataEmty } from "@components/common";

//components
import { CorrectResponsive, TopicDropdown } from '../Chat';

//api
import { apiCaller } from "@utils";
import { reportConstant } from "@constants";

//actions
import { atcLoadTopic } from "@actions";

function TableChat(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { data } = props && props;

  //state
  const [stateData, setData] = useState(((data && data) || []));
  const [callApi, setCallApi] = useState(false);
  const [indexItem, setIndexItem] = useState('');

  //get data store
  const topicData = useSelector(
    (state) => (state.listTopic && state.listTopic.data) || []
  );

  //Setstate
  useEffect(() => {
    setData((data && data) || [])
  }, [data]);

  //call Api Topic
  useEffect(() => {
    let params = {
      Action: "Load",
      Target: "Chat"
    };

    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const dataTimeline = res && res.data?.Timeline;
      const filterData = _.filter(dataTimeline, { 'Time': 0 });
      const TimepointId = filterData && filterData[0]?.TimepointId;
      const paramsLoad = { ...params, TimePointId: TimepointId }
      if (res && res.status === 200) {
        if (TimepointId) {
          dispatch(atcLoadTopic(id, paramsLoad));
        }
      } else {
        console.log('error');
      }
    });
  }, [dispatch, id]);

  const handleChangeRadio = (e, index) => {
    const { value } = e.target;
    let dataClone = [...stateData];
    const isChecked = value === 'Correct' ? true : false;
    dataClone[index][`CorrectResponse`] = isChecked;
    setData(dataClone);
    setIndexItem(index);
    setCallApi(true);
  }

  useEffect(() => {
    const isLoading = true;
    if (callApi) {
      const params = {
        "ReportType": "chat",
        "Action": "Update",
        "ChatRecordId": stateData[indexItem]?.ChatRecordId,
        "CorrectResponse": stateData[indexItem]?.CorrectResponse,
        "CorrectTopicId": null
      }

      //Call Api
      dispatch({
        type: reportConstant.UPDATE_CORRECT_RESPONSE_REQUEST,
        isLoading
      });
      apiCaller(`/api/teach/report/case/${id}/`, "PUT", params).then((res) => {
        const data = res && res.data;
        if (res && res.status === 200) {
          dispatch({
            type: reportConstant.UPDATE_CORRECT_RESPONSE_SUCCESS,
            payload: data
          });

        } else {
          dispatch({
            type: reportConstant.UPDATE_CORRECT_RESPONSE_FAILURE,
            error: 'error',
          });
        }
      });
      setCallApi(false);
    }
  }, [callApi, stateData, indexItem, dispatch, id]);

  return (
    <Col md={12}>
      <Table className="mb-0" responsive>
        <Theader />
        <tbody>
          {stateData && stateData.length === 0 && (
            <tr>
              <td colSpan={5} className="text-center">
                <DataEmty content={`No Data`} />
              </td>
            </tr>
          )}

          {stateData?.map((item, index) => (
            <tr key={index}>
              <td className="align-middle text-left">
                {moment(item?.ChatRecordTime).format("LLL")}
              </td>
              <td className="align-middle">
                {item?.Question}
              </td>
              <td className="align-middle">
                {item?.Response}
              </td>
              <td className="align-middle">
                <CorrectResponsive
                  {...props}
                  index={index}
                  item={item}
                  handleChange={handleChangeRadio}
                />
              </td>
              <td className="align-middle">
                <TopicDropdown
                  {...props}
                  data={(topicData && topicData) || []}
                  isDisabled={(item?.CorrectResponse === null) || item?.CorrectResponse === '' || item?.CorrectResponse === true}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Col>
  );
}

export default TableChat;