import React, { useState } from 'react';
import styled from 'styled-components';
import { Iconsax } from "@components-common";
import { useHistory } from 'react-router-dom';

// Styled components
const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.button`
  border-radius: 8px;
  border: 1px solid #0E7AA8;
  background: #0E7AA8;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 16px;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
`;

const DropdownMenu = styled.ul`
  list-style: none;
  padding: 5px 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: ${props => (props.isVisible ? 'block' : 'none')};
  z-index: 1;
`;

const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #101828;
  cursor: pointer;
  flex: 1 0 0;

  &:hover {
    color: #0089C2;
    background-color: #EBF9FF;
  }
`;

const options = [
  {
    label: "Generate Food with AI",
    onClick: () => {
      localStorage.removeItem('generatedFoodData');
      return '/makansafe/generate-food-ai';
    },
  },
  {
    label: "Add Food Manually",
    onClick: () => {
      localStorage.removeItem('generatedFoodData');
      return '/makansafe/add-food';
    },
  },
];

const AddNewFoodItem = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();

  const toggleDropdown = () => setDropdownVisible(prev => !prev);

  const handleOptionClick = (path) => {
    history.push(path);
    setDropdownVisible(false); // Close the dropdown after clicking
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        <Iconsax iconName="plus" fill="#fff" size={25} />
        <span>Add New Food Item</span>
      </DropdownButton>
      <DropdownMenu isVisible={isDropdownVisible}>
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={() => handleOptionClick(option.onClick())}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default AddNewFoodItem;