import React, { useRef, useState, useEffect } from "react";
import JoditEditor from "jodit-react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import * as Config from "@constants/Config";

//textarea
import TextareaAutosize from "react-textarea-autosize";

const ModalEditElement = (props) => {
  const { handleSubmitElement, isOpen, toggle, currentValue, isLoading } = props || {};
  const editor = useRef(null);

  // State
  const [editorContent, setEditorContent] = useState("");
  const [useJodit, setUseJodit] = useState(false); // State to determine whether to use JoditEditor or textarea

  useEffect(() => {
    if (currentValue) {
      setEditorContent(currentValue.Value || "");
      setUseJodit(currentValue.FormatText); // Set useJodit based on FormatText value
    }
  }, [currentValue]);

  // Handle Change
  const handleChange = (newContent) => {
    setEditorContent(newContent);
  };

  // Handle Save
  const handleSave = () => {
    // Call the parent component's function with the latest data
    handleSubmitElement(editorContent, currentValue);
    toggle();
  };

  return (
    <Modal style={{ maxWidth: "700px" }} isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Edit Element</ModalHeader>
      <ModalBody>
        {useJodit ? (
          <JoditEditor
            ref={editor}
            value={editorContent}
            config={Config.CONFIG_JODIT}
            tabIndex={1}
            onChange={(newContent) => handleChange(newContent)}
          />
        ) : (
          <TextareaAutosize
            className="w-100 form-control"
            maxRows="10"
            minRows={5}
            value={editorContent}
            onChange={(e) => handleChange(e.target.value)}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} disabled={isLoading}>
          Close
        </Button>
        <Button
          color="primary"
          className="d-flex align-items-center"
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEditElement;