import React from "react";
import { TabContent, TabPane } from "reactstrap";

//common components
import { HeadTabs } from "../../storyboard/common";

//components
import { IOTableContainer } from './IOTableBlock';

// Editor
import RichTextCommon from '../RichTextCommon';

function IOMain(props) {
  const {
    value,
    activeTabCt,
    listTabs,
    valueEditorIO,
    handleUpdateEditor,
    timePointId
  } = props && props;

  const ViewClass = value?.ViewClass;

  return (
    <React.Fragment>
      <HeadTabs
        {...props}
        title={value?.Label}
        task='io'
      />
      <TabContent activeTab={activeTabCt}>
        {listTabs?.io.map(
          (item, index) =>
            item.show && (
              <TabPane key={index} tabId={`${index + 1}`}>
                {item.id === "1" && (
                  <IOTableContainer {...props} />
                )}
                {item.id === "2" && (
                  <RichTextCommon
                    handleUpdateEditor={handleUpdateEditor}
                    task={ViewClass}
                    defaultValue={valueEditorIO}
                    placeholder="Enter the instructions for the learner here..."
                    timePointId={timePointId}
                  />
                )}
              </TabPane>
            )
        )}
      </TabContent>
    </React.Fragment>
  );
}

export default IOMain;
