import React from "react";

//components
import { FormLoginV2, AuthLogoHeader } from "@components/auth";

function LoginPage(props) {

  return (
    <div className="wrapper -loginPageV2">
      <AuthLogoHeader {...props} />
      <div className="login_panel">
        <FormLoginV2 />
      </div>
    </div>
  );
}

export default LoginPage;