import React, { useState } from 'react'
import Faq from '@images/teachV2/faq.svg'
import { Icon } from '@components/common'
import ReactTooltip from 'react-tooltip';

const ScriptElement = (props) => {
  const { dataCaseScript, lineRefs, refDefault, refImage, toggleModal } = props
  const [active, setActive] = useState(0)

  const scrollToElement = (ref, index) => {
    setActive(index)
    if (ref.current) {
      window.scrollTo({ 
        behavior: 'smooth',
        top: ref.current.getBoundingClientRect().top + window.scrollY - 120,
      });
    }
  };

  return (
    <div className='scriptElement'>
      <div className='scriptElement-wrap'>
        <div className="scriptElement__header w-100">
          <p>Case Scenario Elements</p>
        </div>
        <div className="scriptElement__list w-100">
          <div className={`scriptElement__list-item ${active === 0 ? "active" : ""}`} onClick={() => scrollToElement(refDefault, 0)}>
            <div className="item-name">
              <span></span>
              <p>Learning Objectives</p>
            </div>
          </div>
          {dataCaseScript?.case_sections?.map((section, index) => (
            <div key={index} className={`scriptElement__list-item ${active === index + 1 ? "active" : ""}`} onClick={() => scrollToElement(lineRefs.current[index], index+1)}>
              <div className="item-name">
                <span></span>
                <p>{section?.name}</p>
              </div>
            </div>
          ))}
          {dataCaseScript?.case_sections?.length && (
            <div className={`scriptElement__list-item ${active === dataCaseScript?.case_sections?.length + 1 ? "active" : ""}`} onClick={() => scrollToElement(refImage, dataCaseScript?.case_sections?.length + 1)}>
              <div className="item-name">
                <span></span>
                <p>Patient Profile Image</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <ReactTooltip id={`faqs`} place='top' effect='solid' className="faqs-tooltip">
        <span>Frequently asked questions on how to create case scenarios.</span>
      </ReactTooltip>
      <div 
        data-tip
        data-for={`faqs`}
        className="scriptElement__faq" 
        onClick={() => toggleModal("modalFaq")}
      >
        <Icon src={Faq}/>
        <p>FAQs</p>
      </div>
    </div>
  )
}

export default ScriptElement
