import React from "react";

//components
import { AddNewFoodItem } from "../Home";

function TableHeader({ foodsDataState }) {
    return (
        <div className="header-table d-flex align-items-center py-3">
            <div className="headingPage d-sm-flex align-items-center">
                <h2 className="text-nowrap">Food Item</h2>
                <div className="count-items ml-3 mt-2 mt-sm-0">
                    {foodsDataState?.length} Food Items
                </div>
            </div>
            <div className="actions-other ml-auto d-flex align-items-center">
                {/* <Button className="btn-white mr-3" size="lg">
                    <Iconsax iconName="upload-cloud" fill="#344054" size={25} />
                    <span className="ml-2">Import</span>
                </Button> */}
                <AddNewFoodItem />
            </div>
        </div>
    );
}

export default TableHeader;