import React, { useEffect, useState } from "react";

function ChatWrapUpFullScreen({ isOpen, children }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Nếu prop isOpen thay đổi, set isVisible tương ứng
        setIsVisible(isOpen);
    }, [isOpen]);

    return (
        <div className={`chat-wrapUp-fixed ${isVisible ? 'visible' : ''}`}>
            {children}
        </div>
    );
}

export default ChatWrapUpFullScreen;