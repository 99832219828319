import React, { useState, Suspense } from "react";
import { TabContent, TabPane, NavItem, NavLink, Nav } from "reactstrap";
import classnames from "classnames";

// Lazy load your components using React.lazy
const InteractionScript = React.lazy(() => import("./GenerativeAIGroup/InteractionScript"));
const InstantFeedbackInstructions = React.lazy(() => import("./GenerativeAIGroup/InstantFeedbackInstructions"));
const EndOfCaseFeedback = React.lazy(() => import("./GenerativeAIGroup/EndOfCaseFeedback"));
const TestVirtualPatientMain = React.lazy(() => import("./GenerativeAIGroup/TestVirtualPatientMain"));

function GenerativeAI(props) {
    const listTab = [
        {
            Name: "Interaction Script",
            Component: <InteractionScript {...props} />,
        },
        {
            Name: "Instant Feedback Instructions",
            Component: <InstantFeedbackInstructions {...props} />,
        },
        {
            Name: "End-of-Case Feedback Instructions",
            Component: <EndOfCaseFeedback {...props} />,
        },
        {
            Name: "Test Virtual Patient",
            Component: <TestVirtualPatientMain {...props} />,
        },
    ];

    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <div className="tabsPills">
            <Nav tabs>
                {listTab?.map((value, index) => (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({
                                active: activeTab === (index + 1).toString(),
                            })}
                            onClick={() => {
                                toggle(String(index + 1));
                            }}
                        >
                            {value.Name}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
            {listTab?.length > 0 && (
                <TabContent activeTab={activeTab}>
                    {listTab?.map((value, index) => (
                        <TabPane key={index} tabId={(index + 1).toString()}>
                            {/* Wrap the component in Suspense to lazy load it */}
                            <Suspense fallback={<div>Loading...</div>}>
                                {activeTab === (index + 1).toString() && value.Component}
                            </Suspense>
                        </TabPane>
                    ))}
                </TabContent>
            )}
        </div>
    );
}

export default GenerativeAI;