import React, { useState } from 'react';

//boostrap
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function DropdownOptions(props) {
    //props
    const { handleItemClick } = props && props;

    //state
    const [dropdownOpen, setOpen] = useState(false);
    const toggleDropdown = () => setOpen(!dropdownOpen);

    return (
        <div className="text-center">
            <ButtonDropdown className="mt-4" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className="--btn-primary" caret>
                    Select an item below
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => handleItemClick({}, "addDomain")}>
                        <span className="fa fa-plus mr-2"></span>Add a blank domain
                    </DropdownItem>
                    <DropdownItem onClick={() => handleItemClick({}, "copyDomain")}>
                        <span className="fa fa-clone mr-2"></span>Copy from an existing case
                    </DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
        </div>
    );
};

export default DropdownOptions;