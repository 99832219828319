import React, { createRef, useRef, useState } from "react";
import { Icon } from '@components/common'
import Edit from '@images/teachV2/edit.svg'
import Close from '@images/teachV2/close.svg'
import { apiCaller } from "@utils";
import { getQuestionBank } from "@actions"
import { useDispatch } from "react-redux";

//Boostrap
import { Button, Modal, ModalBody } from "reactstrap";
import { ModalFeedback } from "../home/common";
import Feedback from '@images/teachV2/feedback.svg'
import { Waveform } from "../ReferenceMedia/ModalMedia/testAudio";

function ModalEditMcqBank(props) {
  const { isOpen, toggle, isLoading, dataEdit } = props && props;
  const [showModal, setShowModal] = useState(false)
  const [dataFeedback, setDataFeedback] = useState()
  const dispatch = useDispatch();
  const refQuestion = useRef()
  const refExplanation = useRef()
  let lineRefs = useRef([]);
  lineRefs.current = dataEdit?.Contents?.multiple_choices?.length > 0 && dataEdit?.Contents?.multiple_choices?.map((_, i) => lineRefs.current[i+1] ?? createRef());

  const handleUpdate = () => {
    const answerChoices = dataEdit?.Contents?.multiple_choices?.map((choice, index) => {
      return {
        id: choice.id,
        content: lineRefs?.current[index]?.current?.innerText
      }
    })
    const params = {
      "action_type": "update_question_bank",
      "id": dataEdit?.QuestionItemId,
      "contents": {
        ...dataEdit?.Contents,
        "question": refQuestion?.current?.innerText,
        "multiple_choices": answerChoices,
        "best_answer": {
          ...dataEdit?.Contents?.best_answer,
          "explanation": refExplanation?.current?.innerText,
        }
      }
    }
    apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      if (res.status === 200) {
        dispatch(getQuestionBank())
        toggle()
      }
    });
  }

  const handleFeedback = (question, listAnswers) => {
    setShowModal(!showModal)
    const answers = listAnswers.map((ans) => `${ans.id}. ${ans.content}`)
    const text = question.concat(" \n", answers.join(" \n"))
    setDataFeedback(text)
  }

  const showMediaDetail = (type, dataView) => {
    switch (type) {
      case "Image":
      case "URL":
        return (
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <img className='media-img' src={dataView.url} alt={dataView.title}/>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      case "Video":
        return (
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <video width="420" controls>
              <source src={dataView.url} />
              Your browser does not support the video tag.
            </video>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      case "Audio":
        return (
          dataView?.url.includes("med2lab-frontend-media.s3.amazonaws.com") ? (
            <Waveform {...props} dataView={dataView}/>
          ) : (
            <span className="text-link" onClick={() => window.open(`${dataView?.url}`, "_blank")}>
              {dataView?.url}
            </span>
          )
        )
      default:
        break;

    }
  }

  return (
    <Modal className="modal-edit-question" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="edit__header">
          <div className="edit__header-title">
            <div>
              <Icon src={Edit} />
            </div>
            <p className="title-modal">Edit Question</p>
          </div>
          <div onClick={toggle}>
            <Icon src={Close}/>
          </div>
        </div>
        <div
          className="edit__content"
        >
          <p contentEditable ref={refQuestion} className="edit__content-question">{dataEdit?.Contents?.question}</p>
          {dataEdit?.Contents?.multiple_choices?.map((choice, i) => (
            <p key={i} className={`${choice.id === dataEdit?.Contents?.best_answer?.id ? "correct-answer" : ""} answer`}>
              {choice.id}. <span contentEditable ref={lineRefs.current[i]}>{choice.content}</span>
            </p>
          ))}
          {dataEdit?.Media && (
            <div className="edit__content-media">
              <span>Media</span>
              {showMediaDetail(dataEdit?.Media?.type, dataEdit?.Media)}
            </div>
          )}
          <p className="edit__content-explanation"><span>Explanation:</span> <span contentEditable ref={refExplanation} className="edit__content-explanation--best">{dataEdit?.Contents?.best_answer?.explanation}</span></p>
        </div>
        <div className="edit__action">
          <Button
            className="edit__action-feed"
            onClick={() => handleFeedback(dataEdit?.Contents?.question, dataEdit?.Contents?.multiple_choices)}
          >
            <Icon src={Feedback} stroke="#475467"/>
            <span>
              Give Feedback              
            </span>
          </Button>
          <div className="edit__action-btn">
            <Button className="btn btn-secondary" onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button
              color="primary"
              className="btn d-flex align-items-center"
              onClick={() => handleUpdate()}
              disabled={isLoading || false}
            >
              Save
              {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
            </Button>
          </div>
        </div>
      </ModalBody>
      <ModalFeedback
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        type="mcq_draft"
        dataFeedback={dataFeedback}
        idFeedback={dataEdit?.id}
      />
    </Modal>
  );
}

export default ModalEditMcqBank;