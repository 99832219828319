import React, { useEffect, useState } from "react";

//Boostrap
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Label,
    FormGroup,
    Row,
    Col,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Nav
} from "reactstrap";

//classnames
import classnames from "classnames";

//uuid
import { v4 as uuidv4 } from "uuid";

//api
import { apiCaller, LoaderText } from "@utils";

//components
import { AiLabelContent, LabelCategoryTabs, CompareLabel } from "@components/ReliDiagnosis";

// let atributeTarget = `mark[class*="highlight"]`;
let classRemoveItem = `removeItem`;
let idContainer = "htmlHumanLabelText";

//Convert After when Remove
function convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement("mark");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

//Convert html string
function nodeToString(node) {
    var tmpNode = node?.cloneNode(true);
    var str = tmpNode?.innerHTML;
    tmpNode = node = null; // prevent memory leaks in IE
    return str || "";
}

function ModalHumanLabel(props) {
    const { isOpen, toggle, rowRecord, handleUpdateScoreTextItem } = props && props;
    const grouped_concepts = rowRecord?.grouped_concepts;

    //Convert to array
    let propertyNames = grouped_concepts && Object.keys(grouped_concepts);

    //state
    const [stateLabelText, setStateLabelText] = useState({});
    const [arrHumanLabel, setArrHumanLabel] = useState([]);
    const [stateData, setData] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [activeCategory, setActiveCategory] = useState((propertyNames && propertyNames[0]) || "");

    //toggle
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    //Load Category Default
    useEffect(() => {
        const grouped_concepts = rowRecord?.grouped_concepts;
        let propertyNames = grouped_concepts && Object.keys(grouped_concepts);
        setActiveCategory(propertyNames && propertyNames[0]);
    }, [rowRecord])

    //Load Api With Text_id
    useEffect(() => {
        const createStyledString = (obj) => {
            let { text, human_label } = obj;
            let arrPushIdUnique = human_label?.map((t, index) => {
                let renderId = uuidv4(index);
                let item = { ...t }
                item[`uuid`] = renderId;
                return item;
            });
            setArrHumanLabel(arrPushIdUnique);

            // TO KEEP TRACK OF INSERTED TEXT
            let insertedAmmount = [];
            if (arrPushIdUnique?.length > 0) {
                arrPushIdUnique?.forEach((t, index) => {
                    let renderId = uuidv4(index);
                    t[`uuid`] = renderId;
                    const {
                        start,
                        end,
                        label_text,
                        label_color,
                        uuid
                    } = t;

                    // COMPUTE THE REAL START AND END POSITIONS
                    // TAKING INSERTED TEXT INTO ACCOUNT
                    let realStart = start
                    let realEnd = end
                    for (let idx in insertedAmmount) {
                        if (idx < start) {
                            realStart += insertedAmmount[idx]
                        }
                        if (idx <= end) {
                            realEnd += insertedAmmount[idx]
                        }
                    }

                    const splitStringLabelText = (label_text) => {
                        let arrSplit = label_text?.split('||').join(')(');
                        return arrSplit || '';
                    }

                    const tagLabelText = splitStringLabelText(label_text);
                    let pre = `<mark class="highlight-${label_color}" style="background-color: ${label_color ? `rgb(${label_color})` : 'transparent'}; color: ${label_color ? 'white' : '#212529'}; display: inline-block; padding: 1px 4px; border-radius: 4px; margin: 5px;">`
                    let pos = `<span class="highlight-sub">${tagLabelText ? `(${tagLabelText})` : ''}<span class="removeItem" data-key="${uuid}"></span></mark>`

                    // UPDATE THE INFORMATION ABOUT INSERTED TEXT
                    insertedAmmount[start] = (insertedAmmount[start] || 0) + pre?.length;
                    insertedAmmount[end] = (insertedAmmount[end] || 0) + pos?.length;
                    // DON'T INSERT DIRECTLY THE VALUE BUT THE ALREADY EXISTENT TEXT
                    text = text.substring(0, realStart)
                        + pre
                        + text.substring(realStart, realEnd)
                        + pos
                        + text.substring(realEnd)
                    document.getElementById(idContainer).innerHTML = text;
                });
            } else { document.getElementById(idContainer).innerHTML = text; }
        }

        const fetchData = () => {
            const params = {
                "Action": "LoadText",
                "text_id": parseFloat(rowRecord?.text_id)
            }

            setLoadData(true);
            //Call Api Load Text Item
            apiCaller(`/api/binh/model_management/text_item/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);

                    //Kiem tra human_label co label_text hay khong ?
                    createStyledString(data);
                    setLoadData(false);
                } else {
                    console.log("error....");
                    setLoadData(false);
                }
            })
        }
        isOpen && fetchData();
    }, [rowRecord, isOpen]);

    //Load Data
    useEffect(() => {
        setData(rowRecord || {});
    }, [rowRecord]);

    //Click Remove
    useEffect(() => {
        // Remove Item
        const removeItemLabel = (data) => {
            let newData = [...data];
            let divContainerHtml = document?.getElementById(idContainer);
            if (divContainerHtml) {
                document.querySelectorAll(`.${classRemoveItem}`).forEach(occurence => {
                    occurence.addEventListener('click', (e) => {
                        let targetElement = e.target;
                        const keyUniqueId = targetElement?.getAttribute("data-key");
                        if (keyUniqueId) {
                            const elementMark = targetElement?.closest("mark");

                            // Xoa Object ra khoi mang voi index
                            const removeIndex = newData?.map(item => item?.uuid).indexOf(keyUniqueId);
                            newData.splice(removeIndex, 1);
                            setArrHumanLabel(newData);

                            //Xoa tat ca class highlight-sub trong element vua click
                            elementMark && elementMark?.querySelectorAll('.highlight-sub').forEach(e => e.remove());

                            // convert sang text
                            const convertHtmlToPlainText = convertToPlain(nodeToString(elementMark));
                            elementMark?.replaceWith(convertHtmlToPlainText);
                        }
                    });
                });
            }
        };
        removeItemLabel(arrHumanLabel);
    }, [arrHumanLabel]);


    //Gán các thẻ html vs style để label category
    const createStyledString = (obj) => {
        let { text, human_label } = obj;
        // TO KEEP TRACK OF INSERTED TEXT
        let insertedAmmount = [];
        if (human_label?.length > 0) {
            human_label?.forEach((t, index) => {
                const {
                    start,
                    end,
                    label_text,
                    label_color,
                    uuid
                } = t;

                // COMPUTE THE REAL START AND END POSITIONS
                // TAKING INSERTED TEXT INTO ACCOUNT
                let realStart = start
                let realEnd = end
                for (let idx in insertedAmmount) {
                    if (idx < start) {
                        realStart += insertedAmmount[idx]
                    }
                    if (idx <= end) {
                        realEnd += insertedAmmount[idx]
                    }
                }

                const splitStringLabelText = (label_text) => {
                    let arrSplit = label_text?.split('||').join(')(');
                    return arrSplit || '';
                }

                const tagLabelText = splitStringLabelText(label_text);
                let pre = `<mark class="highlight-${label_color}" style="background-color: ${label_color ? `rgb(${label_color})` : 'transparent'}; color: ${label_color ? 'white' : '#212529'}; display: inline-block; padding: 1px 4px; border-radius: 4px; margin: 5px;">`
                let pos = `<span class="highlight-sub">${tagLabelText ? `(${tagLabelText})` : ''}<span class="removeItem" data-key="${uuid}"></span></mark>`

                // UPDATE THE INFORMATION ABOUT INSERTED TEXT
                insertedAmmount[start] = (insertedAmmount[start] || 0) + pre?.length;
                insertedAmmount[end] = (insertedAmmount[end] || 0) + pos?.length;
                // DON'T INSERT DIRECTLY THE VALUE BUT THE ALREADY EXISTENT TEXT
                text = text.substring(0, realStart)
                    + pre
                    + text.substring(realStart, realEnd)
                    + pos
                    + text.substring(realEnd)
                document.getElementById(idContainer).innerHTML = text;
            });
        } else { document.getElementById(idContainer).innerHTML = text; }
    }

    // get toa do tat ca tu khoa lien quan
    const getAllMatches = (textSearch, string, stateLabel) => {
        const myRe = new RegExp(textSearch, "g");
        let res = [];
        let match = null;
        // let arrMatchApi = [];
        if (myRe.global) {
            // eslint-disable-next-line no-cond-assign
            while (match = myRe.exec(string)) {
                let renderId = uuidv4(match[`index`]);

                // Tạo params mới cho start, end
                match[`start`] = match[`index`];
                match[`end`] = match[`index`] + textSearch?.length;
                match[`label_text`] = stateLabel?.categories_text;
                match[`label_color`] = stateLabel?.categories_color;
                match[`uuid`] = renderId;

                res.push({
                    "start": match[`start`],
                    "end": match[`index`] + textSearch?.length,
                    "label_text": match[`label_text`],
                    "label_color": match[`label_color`],
                    "uuid": renderId
                });
            }
        } else {
            // eslint-disable-next-line no-cond-assign
            if (match = myRe.exec(string)) {
                let renderId = uuidv4(match[`index`]);

                // Tạo params mới cho start, end
                match[`start`] = match[`index`];
                match[`end`] = match[`index`] + textSearch?.length;
                match[`label_text`] = stateLabel?.label_text;
                match[`label_color`] = stateLabel?.label_color;
                match[`uuid`] = renderId;
                
                res.push({
                    "start": match[`start`],
                    "end": match[`index`] + textSearch?.length,
                    "label_text": match[`label_text`],
                    "label_color": match[`label_color`],
                    "uuid": renderId
                });
            }
        }

        return res;
    }

    //Chuyen vi tri text can label thanh start, end
    const convertLocationToArray = (textSearch, stateLabel) => {
        const stringForHuman =  stateData?.text;
        const arrMatch = getAllMatches(textSearch, stringForHuman, stateLabel);

        //Merge 2 array voi nhau (array tu api, arr tu ham get theo tu khoa)
        let newArray = arrHumanLabel?.concat(arrMatch);

        //Bỏ những đối tượng trùng lặp (Đã label rồi)
        const key = 'start';
        const arrayUniqueByKey = [...new Map(newArray.map(item => [item[key], item])).values()];
        
        //Setstate và chuyển đổi array thành style inline
        setArrHumanLabel(arrayUniqueByKey);
        // Gan Style Cho elment vua Selection
        createStyledString({
            text: stringForHuman,
            human_label: arrayUniqueByKey
        });
    }

    //Handle Add Label
    const handleAddLabel = () => {
        if (stateLabelText) {
            //Create Element
            let range = window.getSelection().getRangeAt(0);
            const textSelection = range.toString();
            let nodeRemove = document.createElement(`span`);
            nodeRemove.setAttribute("class", classRemoveItem);

            //Them Vi tri vao mang
            textSelection ? convertLocationToArray(textSelection, stateLabelText) : setData((prevState) => ({ ...prevState, [`text`]: rowRecord?.text }));
        }
    };

    // Hanlde Change Radio
    const handleChangeLabel = (val) => {
        const params = {
            "categories_key": val?.id,
            "categories_text": val?.concept,
            "categories_color": val?.color
        }
        setStateLabelText(params);
    };

    //Handle Submit Edit Label
    const handleSubmitEditLabel = () => {
        const params = {
            "Action": "UpdateText",
            "TextId": stateData?.text_id,
            "human_label": arrHumanLabel
        }

        setLoading(true);
        //Call Api Load Text Item
        apiCaller(`/api/binh/model_management/text_item/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);

                //set loading and close
                setLoading(false);
                toggle();

                //Update score for Text item
                handleUpdateScoreTextItem(data);
            } else {
                console.log("error....");
                //set loading and close
                setLoading(false);
                toggle();
            }
        })
    }

    //props
    const listTab = [
        {
            Name: "Human",
            Component: (
                <FormGroup>
                    <Label className="font-weight-500">Text:</Label>
                    <div className="formatHtmlLabel" id="htmlHumanLabelText"></div>
                    <div className="mt-4 text-center">
                        <Button
                            className="btn btn-sm"
                            color="primary"
                            onClick={handleAddLabel}
                            disabled={stateLabelText?.categories_text ? false : true}
                        >
                            <i className="fa fa-plus mr-2"></i>Add Label
                        </Button>
                    </div>
                </FormGroup>
            ),
        },
        {
            Name: "AI",
            Component: <AiLabelContent {...props} />,
        },
        {
            Name: "Compare",
            Component: <CompareLabel {...props} humanDataLabel={stateData} activeCategory={activeCategory} />,
        }
    ];

    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{ maxWidth: '1400px' }}>
            <ModalHeader toggle={toggle}>
                Human Labels
            </ModalHeader>
            <ModalBody>
                <div className={`analyzeCt`}>
                    <Row>
                        <Col md={4}>
                            <LabelCategoryTabs
                                {...props}
                                data={stateData}
                                handleChangeLabel={handleChangeLabel}
                                rowRecord={rowRecord}
                                handleClickCategory={(val) => setActiveCategory(val)}
                            />
                        </Col>
                        <Col md={8} className="border-left position-relative">
                            {loadData && <LoaderText />}
                            <Nav tabs className="tabBorder" >
                                {listTab?.map((value, index) => (
                                    <NavItem key={index}>
                                        <NavLink
                                            className={classnames({
                                                active: activeTab === (index + 1).toString(),
                                            })}
                                            onClick={() => {
                                                toggleTab(String(index + 1));
                                            }}
                                        >
                                            {value.Name}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                            {listTab?.length > 0 && (
                                <TabContent activeTab={activeTab}>
                                    {listTab?.map((value, index) => (
                                        <TabPane className="p-2" key={index} tabId={(index + 1).toString()}>
                                            {value.Component}
                                        </TabPane>
                                    ))}
                                </TabContent>
                            )}
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                {activeTab !== "3" && (
                    <Button className="btn --btn-primary mr-2" onClick={handleSubmitEditLabel}>
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                )}
                <Button className="btn btn-secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalHumanLabel;