import React from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

function ButtonShowSchema(props) {
    let { id } = useParams();
    const { itemQuestion } = props && props;
    return (
        <div className="text-center">
            <Link
                className="btn btn-outline-brand d-inline-block"
                to={`/teach/schema/${id}?QuestionId=${itemQuestion?.QuestionId}`}
                target={`_blank`}
            >
                <i className="fa fa-sitemap" />
                &nbsp; Show Schema
            </Link>
        </div>
    );
};

export default ButtonShowSchema;