import { profileConstant } from "@constants";

//Load profile setting
export function listAccountSettings(state = {}, action) {
    switch (action.type) {
        case profileConstant.GET_ACCOUNT_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case profileConstant.GET_ACCOUNT_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case profileConstant.GET_ACCOUNT_TIME_ZONE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function getAccountInfo(state = {}, action) {
    switch (action.type) {
        case profileConstant.GET_ACCOUNT_INFO_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case profileConstant.GET_ACCOUNT_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case profileConstant.GET_ACCOUNT_INFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        default:
            return state;
    }
}