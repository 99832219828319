import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { QuestionBankEditView } from '@components/teachV2/QuestionBankDetail';
import { useLocation } from 'react-router-dom';

const QuestionBankEdit = () => {
  const location = useLocation();
  const [dataQuestionDetail, setDataQuestionDetail] = useState(JSON.parse(location?.state?.question))

  const {
    handleSubmit,
    setValue, 
    control, 
    watch
  } = useForm()

  const onSubmit = (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data)
  }

  return (
    <div className="wrapper -teachV2HomePage pb-0">
      <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
        <QuestionBankEditView 
          setValue={setValue} 
          control={control} 
          watch={watch}
          dataQuestionDetail={dataQuestionDetail}
          setDataQuestionDetail={setDataQuestionDetail}
          type={location?.state?.type}
        />
      </form>
    </div>
  )
}

export default QuestionBankEdit
