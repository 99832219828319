import React from "react";

//boostrap
import { Col } from "reactstrap";

function DocumentsLeft(props) {
    const { rowData, handleClick, activeIndex } = props && props;
    const listItems = rowData?.analyze_result?.map((item, index) =>
        <div className={`listDocument__item ${activeIndex === item?.page_num ? "active" : ""}`} onClick={() => handleClick(item)} key={index}>
            Page {item?.page_num}
            <span className="ml-auto mr-3">({item?.page_type})</span>
            <i className="fa fa-chevron-right ml-2" aria-hidden="true"></i>
        </div>
    );
    return (
        <Col md={3}>
            <div className="widgetBox">
                <div className="widgetBox__head border">
                    <h3 className="wg-title --text-primary">Documents</h3>
                </div>
                <div className="widgetBox__body p-0 border">
                    <div className="listDocument">
                        {listItems}
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default DocumentsLeft;