// TasksMain.js
import React from "react";

//Icon
import IconErm from "@images/learnerv2/icon-task-erm.svg";

//components
import { ButtonReferences, ButtonTaskItem } from "@components/learner/StandardScreen/CaseTask";

//redux
import { useSelector } from "react-redux";

function TasksBottom(props) {

  //props
  const { taskNexTour, handleExamMenu, caseData } = props;

  // Interface Template
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const CaseTask =  InterfaceTemplateDetails?.CaseTask;
  const ShowPatientEMR = caseData?.data?.Case?.Interface?.ShowPatientEMR;
  return (
    <React.Fragment>
      {ShowPatientEMR && (
        <ButtonTaskItem
          {...props}
          label={CaseTask?.button_emr?.Text || "Review"}
          icon={IconErm}
          attributeTask="PatientErm"
          handleClickItem={() => handleExamMenu("PatientErm")}
          isOpenPopper={taskNexTour === "erm"}
          contentPopper={`The Patient <strong>EMR</strong> contains the Narrative, Vital Signs, and Lab/Studies Results, and Orders.`}
          nextTour={"chat"}
          isErm={true}
        />
      )}
      <ButtonReferences {...props} />
    </React.Fragment>
  );
}

export default TasksBottom;