import { planConstant } from "@constants";

export function loadAllLaunchPlans(state = {}, action) {
    switch (action.type) {
        case planConstant.GET_LAUNCH_PLANS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case planConstant.GET_LAUNCH_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.DELETE_LAUNCH_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.CREATE_LAUNCH_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.UPDATE_LAUNCH_PLANS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.GET_LAUNCH_PLANS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadPlanDetails(state = {}, action) {
    switch (action.type) {
        case planConstant.LOAD_PLAN_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case planConstant.LOAD_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.ADD_MANAGERS_TO_LAUNCH_PLAN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.REMOVE_MANAGERS_TO_LAUNCH_PLAN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case planConstant.LOAD_PLAN_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function featureList(state = {}, action) {
    switch (action.type) {
        case planConstant.LOAD_FEATURE_LIST_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case planConstant.LOAD_FEATURE_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        case planConstant.LOAD_FEATURE_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}