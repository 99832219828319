import React from "react";

// common
import { NavigationComponent } from "@components/layouts";

//menu
import navigation from "@utils/navigation";

//components
import { PortfolioContainer } from '@components/portfolio';

function PortfolioPage(props) {
  const roleAdmin = props.is_administrator === "Yes" || props.is_instructor === "Yes";
  const mainMenu = roleAdmin ? (navigation && navigation.mainMenuAdmin) : (navigation && navigation.mainMenuLearner);
  return (
    <React.Fragment>
      <NavigationComponent hiddenExitCase={true} data={mainMenu} />
      <div className="wrapper -portfolioPage">
        <PortfolioContainer />
      </div>
    </React.Fragment>
  );
}

export default PortfolioPage;
