import React from 'react';

const LevelBox = (props) => {
    const { level } = props && props;
    const levelColors = {
        0: '--white',
        1: '--gray',
        2: '--pink',
        3: '--yellow',
        4: '--green',
        5: '--orange',
        6: '--blue',
        7: '--black'
    };

    const levelClass = level?.includes('7') ? '--black' : levelColors[level] || '';
    return (
        <div className="level-box">
            <div className={`level-tag ${levelClass}`}>
                <span className="level-tag__name">
                    {`Level ${level}`}
                </span>
            </div>
        </div>
    );
};

export default LevelBox;