import React from "react";
import {
  InteractiveChatMain,
  NarrativeMain,
  ActionsMain,
  AdvanceFinishMain,
  IntakeOutputMain,
  OrdersMain,
  PhysicalExamMain,
  PNOrdersMain,
  DocumentationMain,
  VitalSignMain,
  ProfileImageMain
} from "../ManageAvailableTasks";

//Ending Page
import { EndingPageMain } from "../EndingPage";

const components = {
  profile_image: ProfileImageMain,
  history: NarrativeMain,
  chat: InteractiveChatMain,
  vital: VitalSignMain,
  io: IntakeOutputMain,
  exam: PhysicalExamMain,
  orders: OrdersMain,
  pn: PNOrdersMain,
  documentation: DocumentationMain,
  actions: ActionsMain,
  advance: AdvanceFinishMain
};

const CaseTimelineRightPanel = ({ activeViewClass, timepointIdActive, ...props }) => {
  const Component = components[activeViewClass];
  const style = timepointIdActive === 9999 ? { width: "100%", marginLeft: "0" } : {};

  return (
    <div className="case-timelineCt__right position-relative" style={style}>
      {timepointIdActive === 9999 ? <EndingPageMain {...props} /> : Component && <Component {...props} activeViewClass={activeViewClass} />}
    </div>
  );
};

export default CaseTimelineRightPanel;