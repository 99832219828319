import styled from 'styled-components';
import { useState } from 'react';
import { InfoTooltip } from '@ui-partents/Tooltip';

// Styled component cho IconClose (kích thước container 22px x 22px)
const IconClose = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FEF3F2;
  cursor: pointer;
  width: ${(props) => props.size || '22px'};
  height: ${(props) => props.size || '22px'};
  padding: 4px;
  position: relative;
`;

// SVG icon cho Close (kích thước 10px x 10px)
const CloseIconSVG = ({ color = "#D92D20", backgroundColor = "#FEF3F2" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
        <path d="M0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9Z" fill={backgroundColor} />
        <path d="M14 4.5L5 13.5M5 4.5L14 13.5" stroke={color} strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

// CloseIcon Component
const CloseIcon = ({ handleDelete, size = '22px', color = "#D92D20", row }) => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    return (
        <IconClose
            size={size}
            onClick={handleDelete}
            onMouseEnter={() => setHoveredIndex(row?.id)}
            onMouseLeave={() => setHoveredIndex(null)}
            id={`UserTooltip-${row?.id}`}
        >
            <CloseIconSVG color={color} />
            {hoveredIndex === row?.id && (
                <InfoTooltip
                    id={`UserTooltip-${row?.id}`}
                    isOpen={hoveredIndex === row?.id}
                    content="Remove User"
                    width="100px"
                />
            )}
        </IconClose>
    );
};

export default CloseIcon;