import React, { useState, useEffect } from "react";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from "reactstrap";

//components
import { ChangeOrderOptions } from '../schemav2';

//SortableList
import { SortableListColumns, SortableListRows } from '../schemav2/order';

function ModalEditDianostic(props) {

  //props
  const { isOpen, toggle } = props && props;

  //state
  const [typeSort, setTypeSort] = useState('Columns');

  //Update Array Store
  useEffect(() => {
    setTypeSort('Columns');
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setTypeSort(value);
  }

  return (
    <Modal
      style={{ maxWidth: "800px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">Change Order</span>
      </ModalHeader>
      <ModalBody>
        <ChangeOrderOptions
          {...props}
          handleChange={handleChange}
          typeSort={typeSort}
        />
        {typeSort === 'Columns' && (
          <SortableListColumns
            {...props}
          />
        )}
        {typeSort === 'Rows' && (
          <SortableListRows
            {...props}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalEditDianostic;