import React from "react";

//components
import { ConfirmLauchPlan } from "@components/weitzman";

function WeitzmanConfirmPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <ConfirmLauchPlan />
    </div>
  );
}

export default WeitzmanConfirmPage;