import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from "react-redux";
import { switchRole } from '@actions';
import { useLocation, Link } from "react-router-dom";

// Define the CSS variables
const switchBgColor = '#0089c3';
const switchesBgColor = '#F2F4F7';
const switchesLabelColor = '#fff';
const switchTextColor = '#000';

// Styled components
const Container = styled.div`
  border-radius: 47px;
  position: ${props => (props.isAbsolute ? 'absolute' : 'relative')};
  top: ${props => (props.isAbsolute ? '50%' : 'auto')};
  transform: ${props => (props.isAbsolute ? 'translate(-50%, -50%)' : 'none')};
  left: ${props => (props.isAbsolute ? '50%' : 'auto')};
`;

const SwitchesContainer = styled.div`
  width: 220px;
  position: relative;
  display: flex;
  padding: 0;
  background: ${switchesBgColor};
  line-height: 38px;
  border-radius: 47px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  font-weight: 600;
`;

const Label = styled(Link)`
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: ${props => (props.checked ? switchesLabelColor : '#000')};
  text-decoration: none;
`;

const SwitchWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(.77, 0, .175, 1);
  background: ${switchBgColor};
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.checked ? '#fff' : switchTextColor)};
  transform: ${props => (props.checked ? 'translateX(100%)' : 'translateX(0)')};
`;

const Switch = styled.div`
  border-radius: 3rem;
  background: ${switchBgColor};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SwitchLabel = styled.div`
  width: 100%;
  text-align: center;
  opacity: ${props => (props.visible ? '1' : '0')};
  color: ${props => (props.visible ? '#fff' : switchTextColor)};
  transition: opacity 0.2s cubic-bezier(.77, 0, .175, 1) 0.125s;
  position: absolute;
  top: 0;
  left: 0;
`;

const SwitchRoleMobile = ({isAbsolute}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  //Redux
  const currentRole = useSelector((state) => state?.statusRoleSystem || {});
  const activeRole = currentRole?.roleActive;

  useEffect(() => {
    const initialRole = location.pathname.includes('/teach') ? 'instructor' : 'learner';
    dispatch(switchRole(initialRole));
  }, [dispatch, location.pathname]);

  return (
    <Container isAbsolute={isAbsolute}>
      <div style={{ padding: '4px' }}>
        <SwitchesContainer>
          <Label 
            to="/learn/home"
            checked={activeRole === 'learner'} 
            onClick={() => dispatch(switchRole('learner'))}
          >
            Learner View
          </Label>
          <Label 
            to="/teach/home" 
            checked={activeRole === 'instructor'} 
            onClick={() => dispatch(switchRole('instructor'))}
          >
            Instructor View
          </Label>
          <SwitchWrapper checked={activeRole === 'instructor'}>
            <Switch>
              <SwitchLabel visible={activeRole === 'learner'}>Learner View</SwitchLabel>
              <SwitchLabel visible={activeRole === 'instructor'}>Instructor View</SwitchLabel>
            </Switch>
          </SwitchWrapper>
        </SwitchesContainer>
      </div>
    </Container>
  );
};

export default SwitchRoleMobile;