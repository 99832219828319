import React, { useState, useEffect } from "react";

//boostrap
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

import classnames from "classnames";

//components
import { NavigationComponent } from "@components/layouts";

//menu
import navigation from "@utils/navigation";

//components
import { UseAgreement, PrivacyPolicy } from "@components/admin";

//call Action
import { actLoadAgreements } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

function LegalPage() {
  const dispatch = useDispatch();
  const mainMenu = navigation && navigation.mainAdmin;
  const [activeTab, setActiveTab] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(actLoadAgreements());
    };

    //fetch data
    fetchData();
  }, [dispatch]);

  //Store
  const dataAgreements = useSelector(
    (state) => (state.getAgreements && state.getAgreements.data) || []
  );
  const dataUseAgreement = dataAgreements && dataAgreements.PlatformUseAgreement;
  const dataPrivacyPolicy = dataAgreements && dataAgreements.PrivacyPolicy;

  const listTabs = [
    {
      name: "Platform Use Agreement",
      component: <UseAgreement data={(dataUseAgreement && dataUseAgreement) || {}} />,
      show: true,
    },
    {
      name: "Privacy Policy",
      component: <PrivacyPolicy data={(dataPrivacyPolicy && dataPrivacyPolicy) || {}} />,
      show: true,
    },
  ];
  
  return (
    <React.Fragment>
      <NavigationComponent data={mainMenu} />
      <div className="wrapper -legalPage">
        <Container>
          <div className="tabsPills">
            <Nav tabs>
              {listTabs.map(
                (item, index) =>
                  item.show && (
                    <NavItem key={index}>
                      <NavLink
                        className={classnames({ active: activeTab === index })}
                        onClick={() => {
                          toggle(index);
                        }}
                      >
                        <i
                          className={`${item.icon} mr-2`}
                          aria-hidden="true"
                        ></i>
                        {item.name}
                      </NavLink>
                    </NavItem>
                  )
              )}
            </Nav>
            <TabContent activeTab={activeTab}>
              {listTabs.map(
                (item, index) =>
                  item.show && (
                    <TabPane key={index} tabId={index}>
                      {item.component}
                    </TabPane>
                  )
              )}
            </TabContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default LegalPage;
