import React from "react";

//reactstrap
import { Form, FormGroup, Input, Label, Col } from "reactstrap";

function FilterAuthCode(props) {
  const { hanldeFilter } = props && props;
  return (
    <React.Fragment>
      <Col md={3}>
        <Form>
          <FormGroup row>
            <Label for="exampleEmail" size="md" sm={4}>
              Assigned:
            </Label>
            <Col sm={8}>
              <Input
                id="Assigned"
                name="Assigned"
                type="select"
                onChange={(e) => hanldeFilter(e, 'Assigned')}
              >
                <option value="">No filter</option>
                <option value={true}>Assigned</option>
                <option value={false}>Unassigned</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col md={3}>
        <Form>
          <FormGroup row>
            <Label for="exampleEmail" size="md" sm={4}>
              Status:
            </Label>
            <Col sm={8}>
              <Input
                id="Status"
                name="Status"
                type="select"
                onChange={(e) => hanldeFilter(e, 'Status')}
              >
                <option value="">No filter</option>
                <option value="active">Active</option>
                <option value="deactivate">Deactivate</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </React.Fragment>
  );
}

export default FilterAuthCode;
