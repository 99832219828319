import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

//boostrap
import { FormGroup, Label, Input, Col, Form, Button } from "reactstrap";

function SectionTitleGroup(props) {
    const editor = useRef(null);

    //props
    const { handleSubmitSection, caseData } = props && props;
    const resData = caseData?.data;

    //state
    const [stateData, setData] = useState(resData?.TakeHomeMessageSections || []);

    //Load Data
    useEffect(() => {
        const resData = caseData?.data;
        setData(resData?.TakeHomeMessageSections || []);
    }, [caseData])

    //Handle Change Title
    const handleChangeTitle = (e, index) => {
        const { value } = e.target;
        let dataClone = [...stateData];
        dataClone[index][`Title`] = value;
        setData(dataClone);
    }

    //Handle Change content
    const handleChangeContent = (content, index) => {
        let dataClone = [...stateData];
        dataClone[index][`Content`] = content;
        setData(dataClone);
    }

    //Handle Add 
    const handleAddSection = () => {
        let newData = [...stateData, {
            "Id": null,
            "Title": "",
            "Content": ""
        }];
        setData(newData);
    }

    //Hanlde Remove
    const handleRemoveSection = (index) => {
        let dataClone = [...stateData];
        dataClone?.splice(index, 1);
        setData(dataClone);
    }

    return (
        <div className="mt-4">
            <hr />
            {stateData?.map((value, index) =>
                <Form className="mb-4" key={index}>
                    <FormGroup row>
                        <Label
                            for="Title"
                            sm={2}
                        >
                            Section Title {index + 1}:
                        </Label>
                        <Col sm={6}>
                            <div className="d-flex">
                                <Input
                                    id="Title"
                                    name="Title"
                                    placeholder=""
                                    type="Title"
                                    value={value?.Title}
                                    onChange={(e) => handleChangeTitle(e, index)}
                                />
                            </div>
                        </Col>
                        <Col sm={4} className="text-right">
                            <Button
                                className="--btn-primary btn-sm ml-auto"
                                onClick={(index) => handleRemoveSection(index)}
                            >
                                <i className="fa fa-times mr-2" aria-hidden="true"></i>
                                Remove Section
                            </Button>
                        </Col>
                    </FormGroup>
                    <JoditEditor
                        ref={editor}
                        value={value?.Content}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1}
                        onChange={(newContent) => handleChangeContent(newContent, index)}
                    />
                </Form>
            )}
            <div className="text-center">
                <Button
                    className="--btn-primary"
                    onClick={handleAddSection}
                >
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Section
                </Button>
                {stateData?.length > 0 && (
                    <Button
                        className="ml-3 btn btn-brand btnSaveEndingPageFixed"
                        onClick={() => handleSubmitSection(stateData)}
                        color="primary"
                    >
                        <i className="fa fa-floppy-o mr-2" aria-hidden="true"></i>
                        Save
                    </Button>
                )}
            </div>
        </div>
    );
}

export default SectionTitleGroup;