import React from 'react';
import styled from 'styled-components';

//components
import { ExamPointHeader, ExamNote } from "./common";
import { ExamFindings } from "../PhysicalExam";

const ExamContainer = styled.div`
  padding: 12px;
  border-radius: 8px;
  margin-top: 24px;
  background: #F2F6F9;
`;

const ExamContainerWrap = ({ children, ...props }) => {
  return (
    <React.Fragment>
      <ExamPointHeader
        {...props}
      />
      <ExamContainer {...props}>
        <ExamNote {...props} />
        <ExamFindings {...props} />
      </ExamContainer>
    </React.Fragment>
  );
};

export default ExamContainerWrap;