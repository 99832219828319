import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//components
import ChangeMode from "./ChangeMode";
import Thead from "./Thead";

//lodash
import _ from "lodash";

//actions
import { actSortLabItem } from "@actions";

function ItemChildren(props) {
  let { id } = useParams();
  let { items, valuePanel, indexPanel, isTime, timePointId } = props && props;
  const dispatch = useDispatch();

  //state
  const [data, setData] = useState({ [valuePanel.Id]: items });
  const [isSort, setIsSort] = useState(false);
  const [idCategory, setIdCategory] = useState("");

  useEffect(() => {
    setData({ [valuePanel.Id]: items });
  }, [items, valuePanel.Id]);

  const handleSort = (e, Id) => {
    const oldIndex = e.oldIndex;
    const newIndex = e.newIndex;
    const newData = arrayMove(data[Id], oldIndex, newIndex);
    setData({ [valuePanel.Id]: newData });
    setIsSort(true);
    setIdCategory(valuePanel.Id);
  };

  const handleSortStart = ({ node }) => {
    const tds = document.getElementsByClassName("SortableTbl")[0].childNodes;
    node.childNodes.forEach(
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
    );
  };

  const checkDataShow = (minValue, maxValue, valueUnit) => {
    const fullMinMax = (minValue !== 0 || maxValue !== 0) ? (minValue + " - " + maxValue + " " + valueUnit) : '';
    return fullMinMax;
  };

  const checkDataShowInitialValue = (minValue, currentValue, maxValue) => {
    const checkMax = currentValue > maxValue;
    const checkMin = currentValue < minValue;
    if (checkMax) {
      return (
        <span className="text-danger">
          {currentValue}&nbsp;{" "}
          {currentValue && Number(currentValue) && (
            <i className="fa fa-arrow-up"></i>
          )}
        </span>
      );
    } else if (checkMin) {
      return (
        <span className="text-danger">
          {currentValue}&nbsp;{" "}
          {currentValue && Number(currentValue) && (
            <i className="fa fa-arrow-down"></i>
          )}
        </span>
      );
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: currentValue,
          }}
        ></div>
      );
    }
  };

  const dataSortByIdPanel = data && data[valuePanel.Id];

  const SortableItem = SortableElement(({ data, indexItem, isCollapse }) => {
    const handleChange = props && props.handleChange;
    const handleSave = props && props.handleSave;
    return (
      <tr
        className={`row_lab row_lab_item SortableItemRow ${isCollapse ? "wow animated" : "d-none"}`}
        style={{ position: "relative" }}
      >
        <td className="text-left pl-5 align-middle">
          <i className="fa fa-ellipsis-v iconDrag" aria-hidden="true"></i>
          <span className="lab_name lab_item_name">{data.Name}</span>
        </td>
        <td className="align-middle text-center">
          {" "}
          {checkDataShowInitialValue(
            data.NormalMin,
            data.InitialValue,
            data.NormalMax
          )}
        </td>
        {isTime === 0 && (
          <React.Fragment>
            <td className="align-middle text-center">{data.Unit}</td>
            <td className="align-middle text-center">
              {checkDataShow(
                data.NormalMin,
                data.NormalMax,
                data.Unit
              )}
            </td>
          </React.Fragment>
        )}
        {isTime > 0 && (
          <ChangeMode
            indexItem={indexItem}
            indexPanel={indexPanel}
            valuePanel={valuePanel}
            data={data}
            handleChange={handleChange}
            handleSave={handleSave}
          />
        )}
        <td></td>
        <td></td>
        <td className="text-right">
          <ButtonEdit handleEdit={() => props && props.toggle(data, valuePanel.Id, "edit-lab-item")}/>
          <ButtonDelete handleDelete={(e) => props && props.toggleDel(data, "delete-item")} />
        </td>
      </tr>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <table className="w-100 mt-4">
        <Thead {...props} />
        <tbody>
          {props.children} {/* Panel */}
          {items?.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              indexItem={index}
              data={value}
              isCollapse={valuePanel?.isCollapse}
            />
          ))}
        </tbody>
      </table>
    );
  });

  useEffect(() => {
    const requestApiSort = (dataSort) => {
      const params = {
        Action: "Update",
        Target: "AttributesOrder",
        TimePointId: timePointId,
        AttributeType: "LabItem",
        AttributeIds: dataSort,
      };
      dispatch(actSortLabItem(id, params));
      //Done
      setIsSort(false);
    };

    if (isSort && idCategory) {
      var result = _.map(data && data[idCategory], "Id");
      const newArray = result.map((item) => {
        return item;
      });
      requestApiSort(newArray);
    }
  }, [data, isSort, dispatch, id, props, idCategory, timePointId]);

  return (
    <tr>
      <td colSpan="7" style={{ padding: 0, borderTop: 0 }}>
        <SortableList
          lockAxis="y"
          helperClass="SortableTbl"
          onSortStart={handleSortStart}
          distance={1}
          items={dataSortByIdPanel}
          onSortEnd={(e) => handleSort(e, valuePanel.Id)}
        />
      </td>
    </tr>
  );
}

export default ItemChildren;