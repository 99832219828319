import { chatConstants } from "@constants";
import { apiCaller } from "@utils";

//Chat - Add Record (Instructor Channel)
export const atcAddRecordToChanel = (params) => {
    return async dispatch => {
        dispatch(request(true));
        try {
            const res = await apiCaller(`/api/learn/case/chat_record/`, 'POST', params);
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
                console.error("API call failed");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    function request(isLoading) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_REQUEST, isLoading } }
    function success(data) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.CHAT_ADD_RECORD_CHANEL_FAILURE, error: error } }
}

// Chat - Load Records (Instructor Channel)
export const loadRecordInstuctor = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/case/chat_record/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isLoading) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_REQUEST, isLoading } }
    function success(data) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.LOAD_RECORD_INSTRUCTOR_FAILURE, error: error } }
}

// Chat - Load Records (Instructor Channel)
export const loadRecordPatient = (params) => {
    const isRender = false;
    return dispatch => {
        dispatch(request(isRender));
        return apiCaller(`/api/learn/case/chat_record/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };

    function request(isRender) { return { type: chatConstants.LOAD_RECORD_PATIENT_REQUEST, isRender } }
    function success(data) { return { type: chatConstants.LOAD_RECORD_PATIENT_SUCCESS, payload: data } }
    function failure(error) { return { type: chatConstants.LOAD_RECORD_PATIENT_FAILURE, error: error } }
}

// Check API Pending - Lock elments for Chat
export const atcCallLockElementsChat = (status) => {
    return dispatch => {
      dispatch(success(status));
    };
  
    function success(data) { return { type: chatConstants.CHECK_HAS_CHAT_LOCK_ELEMENT, payload: data || null } }
}