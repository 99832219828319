import React from "react";

//style
import styled from "styled-components";

//Dropzone
import { useDropzone } from "react-dropzone";

//style dropzone
const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const ContainerUpload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const thumb = {
  display: "block",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginTop: 20,
  marginRight: "auto",
  marginLeft: "auto",
  width: "300px",
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

function DragDropZoneFile(props) {
  const { value, onChange, acceptFile, placeholder } = props && props;

  //Upload and Preview Image
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: acceptFile ? acceptFile : "image/*",
    onDrop: (acceptedFiles) => {
      onChange(acceptedFiles);
    },
  });

  return (
    <React.Fragment>
      <ContainerUpload {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>{placeholder ? placeholder : `Drag 'n' drop some files here, or click to select files`}</p>
        {acceptedFiles.map((file, index) => (
          <p key={index}>
            {file.path} - {file.size} bytes
          </p>
        ))}
      </ContainerUpload>
      {value && (
        <div style={thumb}>
          <div style={thumbInner}>
            <img src={value || ""} style={img} alt="Upload File" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default DragDropZoneFile;