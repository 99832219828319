// SidebarMain.js
import React from 'react';

//components
import { LogoTop } from "../Sidebar/SidebarTop";
import SidebarItem from "./SidebarItem";

//icon
import { Iconsax } from "@components-common";

const SidebarMakansafe = (props) => {
    //props
    const { isSidebarCollapsed } = props && props;
    const sidebarItems = [
        {
            icon: <Iconsax iconName="sidebar-home" fill="#697592" />,
            name: 'Home',
            to: '/',
            isHidden: false
        }
    ];

    return (
        <nav className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <LogoTop
                {...props}
                makansafeLayout={true}
            />
            <ul className="sidebar-menu">
                {sidebarItems.map((item, index) => (
                    <SidebarItem key={index} item={item} index={index} isSidebarCollapsed={isSidebarCollapsed} />
                ))}
            </ul>
        </nav>
    );
};

export default SidebarMakansafe;