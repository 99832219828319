import React from "react";
import { Label, Row, Col, FormGroup, Input } from "reactstrap";

const ViewModePNOrders = () => {
  const options = [
    {
      label: 'Default',
      value: 'default'
    },
    {
      label: 'Show top portion only',
      value: 'show'
    },
  ]

  return (
    <div>
      <Row>
        <Col md={12}>
          <FormGroup tag="fieldset">
            <Label className='labeForm --label-blue mb-2'>Max questions learner can answer:</Label>
            {options?.map((item, index) =>
              <FormGroup check key={index}>
                <Label check>
                  <Input
                    type="radio"
                    className='radio-base'
                    id={`${item?.value}`}
                    name="ChatMode"
                    // defaultValue={stateChecked}
                    // checked={stateChecked === item?.value}
                    // onChange={() => handleChange(`${item?.value}`)}
                  />{' '}
                  {item?.label}
                </Label>
              </FormGroup>
            )}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ViewModePNOrders;