import {
    VitalSignsPage,
    ExamFindingsPage,
    QuestionElementPage,
    IOPage,
    StudyPage,
    LabsPage,
    ChatPage,
    MedicationsPage,
    ActionsPage,
    TeamsPage,
    CaseDraftPage
} from "@pages/med2lab/elements";

const elementsRoutes = [
    {
        path: "/teach/elements/vital-signs/:id",
        exact: true,
        classPage: '',
        component: VitalSignsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/intake-output/:id",
        exact: true,
        classPage: '',
        component: IOPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/exam-findings/:id",
        exact: true,
        classPage: '',
        component: ExamFindingsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/labs/:id",
        exact: true,
        classPage: '',
        component: LabsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/studies/:id",
        exact: true,
        classPage: '',
        component: StudyPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/questions/:id",
        exact: true,
        classPage: '',
        component: QuestionElementPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/case-draft/:id",
        exact: true,
        classPage: '',
        component: CaseDraftPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/chat/:id",
        exact: true,
        classPage: '',
        component: ChatPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/medications/:id",
        exact: true,
        classPage: '',
        component: MedicationsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/actions/:id",
        exact: true,
        classPage: '',
        component: ActionsPage,
        isLayout: true,
        navigator: true
    },
    {
        path: "/teach/elements/teams/:id",
        exact: true,
        classPage: '',
        component: TeamsPage,
        isLayout: true,
        navigator: true
    }
];

export { elementsRoutes };