import React from 'react';

function MessageInfo({ title, content }) {
    return (
        <div className="message-answer__info">
            {title &&
                <div className="headline --size-4">{title}</div>
            }
            {content && (
                <div className="desc whiteSpace-break" dangerouslySetInnerHTML={{ __html: content }}></div>
            )}
        </div>
    );
}

export default MessageInfo;