// actions/switchRole.js
import { roleConstant } from "@constants";

// ---- Active Role (Learner - Instructor)
export const switchRole = (currentRole) => {
    return (dispatch) => {
        // Dispatch action để thay đổi vai trò
        dispatch(success(currentRole));
        // Trả về một promise đã hoàn tất
        return Promise.resolve(currentRole);
    };

    function success(data) { 
        return { type: roleConstant.CURRENT_ROLE_ACTIVE, payload: data || null }; 
    }
};