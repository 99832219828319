import {
  DemoPage,
  DemoYouTubePage
} from "@pages/med2lab/demo";

const demoRoutes = [
  {
    path: "/demo-register",
    exact: true,
    classPage: '',
    component: DemoPage,
    isLayout: true,
  },
  {
    path: "/demo",
    exact: true,
    classPage: '',
    component: DemoYouTubePage,
    isLayout: true,
  }
];

export { demoRoutes };