import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import * as Config from "@constants/Config";
import imgLogo from "@images/vinuni/logo.svg";
import { FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { actionLogin, actFetchThemeRequest } from "@actions";
import { checkAuth, apiCaller } from "@utils";
import queryString from "query-string";

const FormLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const parsed = queryString.parse(window.location.search);

  const [formState, setFormState] = useState({
    isLoading: false,
    error: {},
    tokenCaptcha: "",
  });

  useEffect(() => {
    document.body.style.backgroundColor = "#fafbfb";
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const onChangeCaptcha = (value) => {
    setFormState((prev) => ({ ...prev, tokenCaptcha: value }));
  };

  const handleSubmit = useCallback((values, { resetForm }) => {
    const formData = new FormData();
    formData.append("Email", values.Email);
    formData.append("Institution", "VinUni_Demo");

    if (formState.tokenCaptcha) {
      setFormState((prev) => ({ ...prev, isLoading: true }));

      apiCaller(`/api/auth/demo/`, "POST", formData).then((response) => {
        const dataResponse = response?.data;
        if (dataResponse?.status === "OK") {
          const accountData = dataResponse?.account;

          localStorage.setItem("Account", accountData ? JSON.stringify(accountData) : "");
          dispatch(actionLogin(dataResponse));
          dispatch(actFetchThemeRequest());

          nextPage(dataResponse);
          resetForm();
        } else {
          setFormState({ isLoading: false, error: dataResponse, tokenCaptcha: "" });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.tokenCaptcha]);

  const nextPage = (data) => {
    const dataAccount = data?.account;
    const paramsRedirectLink = parsed.redirect;

    if (paramsRedirectLink) {
      history.push(paramsRedirectLink);
    } else {
       history.push(dataAccount?.is_instructor === "Yes" ? "/teach/home" : "/");
    }
  };

  const validationSchema = Yup.object({
    Email: Yup.string().email("Enter a valid e-mail address").required("This field is required."),
  });

  return (
    <div className="m-portlet m-portlet--mobile" style={{ maxWidth: "450px" }}>
      <div className="m-portlet__body">
        <div className="text-center mb-5">
          <img src={imgLogo} style={{ maxWidth: "296px" }} alt="logo" />
        </div>
        <Formik
          initialValues={{ Email: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form className="mt-3">
              <FormGroup>
                <Label for="Email">
                  Email: <span className="m--font-danger">*</span>
                </Label>
                <Field name="Email">
                  {({ field, meta }) => (
                    <div>
                      <Input
                        {...field}
                        type="email"
                        name="Email"
                        placeholder="Enter your email address"
                        invalid={meta.touched && !!meta.error}
                        autoComplete="off"
                      />
                      {meta.touched && meta.error && (
                        <FormFeedback className="d-block">{meta.error}</FormFeedback>
                      )}
                      {formState.error?.error && (
                        <FormFeedback className="d-block">{formState.error?.error}</FormFeedback>
                      )}
                    </div>
                  )}
                </Field>
              </FormGroup>
              <FormGroup>
                <div className="d-flex justify-content-center">
                  <ReCAPTCHA sitekey={Config.KEY_CAPTCHA} onChange={onChangeCaptcha} />
                </div>
                {!formState.tokenCaptcha && (
                  <FormFeedback className="d-block text-center">
                    This field is required.
                  </FormFeedback>
                )}
              </FormGroup>
              <div className="d-flex justify-content-center mt-4">
                <Button
                  color="primary"
                  type="submit"
                  className="btn m-btn--pill btn-lg w-100"
                  disabled={formState.isLoading || !isValid || !formState.tokenCaptcha}
                >
                  {formState.isLoading && (
                    <span className="spinner-border spinner-border-sm mr-2"></span>
                  )}
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FormLogin;