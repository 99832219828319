import React, { useState, useRef } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Button,
} from "reactstrap";

function ReferenceRange(props) {
  const { row, onUpdate, handleSaveReferenceRange } = props;
  const refContainer = useRef(null);

  //state
  const [nameState, setNameState] = useState((row && row) || {});
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSave = (data) => {
    handleSaveReferenceRange(data);
  }

  return (
    <div ref={refContainer}>
      <FormGroup>
        <Label for="NormalMin">Min:</Label>
        <InputGroup>
          <Input
            type="number"
            name="NormalMin"
            defaultValue={(nameState && nameState.NormalMin) || ''}
            onChange={handleChange}
            id="NormalMin"
            placeholder=""
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>{row.Unit}</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label for="NormalMax">Max:</Label>
        <InputGroup>
          <Input
            type="number"
            name="NormalMax"
            defaultValue={(nameState && nameState.NormalMax) || ''}
            onChange={handleChange}
            id="NormalMax"
            placeholder=""
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>{row.Unit}</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <Button
        className="btn btn-brand mx-auto --bg-primary d-block mt-2"
        onClick={ () => onUpdate(handleSave(nameState)) }
      >
        <i className="fa fa-floppy-o" aria-hidden="true"></i>
      </Button>
    </div>
  );
}

export default ReferenceRange;
