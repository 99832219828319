import React from "react";

//reactstrap
import { FormGroup, Input } from "reactstrap";

function QuestionConditionContent(props) {
    const { handleChange, qsContentCurrent, allQuestion, flowControlOfQuestion } = props && props;
    return (
        <React.Fragment>
            <FormGroup>
                <Input
                    type="select"
                    name="QuestionId"
                    defaultValue={flowControlOfQuestion?.QuestionId}
                    onChange={handleChange}
                >
                    {allQuestion?.map((value, index) => {
                        const qsId = value?.QuestionId;
                        return (
                            <option key={index} value={qsId || ""}>
                                Question #{qsId}
                            </option>
                        );
                    })}
                </Input>
            </FormGroup>
            {qsContentCurrent?.QuestionContent && (
                <div
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                        __html:
                            (qsContentCurrent?.QuestionContent) || "",
                    }}
                ></div>
            )}
        </React.Fragment>
    );
}

export default QuestionConditionContent;