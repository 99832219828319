import React from 'react';
import styled from 'styled-components';

const SignOrdersButton = ({ onClick, isSubmit, addedItems }) => {
  return (
    <Button 
      className="btn btn-primary" 
      onClick={onClick} 
      disabled={isSubmit || (addedItems?.length === 0)}
    >
      {isSubmit ? (
        <span className="spinner-border spinner-border-sm mr-2"></span>
      ) : (
        // SVG Icon for Sign Orders Button
        <SignIcon xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </SignIcon>
      )}
      Sign Orders
    </Button>
  );
};

export default SignOrdersButton;

// Styled Components
const Button = styled.button`
  cursor: pointer;
  text-align: center;
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const SignIcon = styled.svg`
  margin-right: 8px;
`;