import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  ListTabsOrders,
  InteractPanelOrderSheet,
  AvailableOrders,
  AddedAtThisTimePoint,
  CardWrap,
  ExistingOrders,
  SuccessHeading,
} from "../../StandardScreen/Orders";
import { onSignOrdersApiCall, removeOrdersApiCall } from "@actions";

//ToastCommon
import { notifyToast } from "@ui-partents/Toast";

// Styled components
const StyledRow = styled.div`
  display: flex;
  height: 100%;
`;

const StyledCol = styled.div`
  flex: 0 0 50%;
  padding-right: 8px;

  &:last-child {
    padding-right: 0;
  }
`;

const FadeOutWrapper = styled.div`
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease;
  display: ${(props) => (props.isVisible ? "block" : "none")};
  width: 100%;
`;

function OrderList({ handleOverideEffectMenu, ...props }) {
  let { id: idCase } = useParams();
  const dispatch = useDispatch();

  // Lấy dữ liệu orders từ Redux store
  const reducerOrders = useSelector((state) => state?.orders || {});
  const ordersDataFromStore = reducerOrders?.data;

  // State
  const [availableOrdersData, setAvailableOrdersData] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false); // Track submission success
  const [showSuccessHeading, setShowSuccessHeading] = useState(false); // State for heading visibility

  // Fill data into the state
  useEffect(() => {
    if (ordersDataFromStore) {
      const available = ordersDataFromStore?.Available || [];
      setAvailableOrdersData(available);
      setSelectedOrders([]); // Clear selected orders on initial load
    }
  }, [ordersDataFromStore]);

  // State cho tab hoạt động
  const [activeTab, setActiveTab] = useState("New_Orders");

  // Xử lý chuyển tab
  const handleTab = (category) => {
    setActiveTab(category);
  };

  // Override hiệu ứng menu khi component được mount/update
  useEffect(() => {
    if (handleOverideEffectMenu) {
      handleOverideEffectMenu();
    }
  }, [handleOverideEffectMenu]);

  // Handle Checkbox Change
  const handleCheckboxChange = (order) => {
    setSelectedOrders((prevState) => {
      const existingOrderIndex = prevState.findIndex((o) => o.Id === order.Id);

      if (existingOrderIndex !== -1) {
        // If the order is already selected, remove it
        const updatedOrders = [...prevState];
        updatedOrders.splice(existingOrderIndex, 1);
        return updatedOrders;
      } else {
        // Add the new order to the selected list
        return [...prevState, order]; // Store the entire order object
      }
    });
  };

  // Handle order removal
  const handleRemove = (currentItem) => {
    setSelectedOrders((prevState) =>
      prevState.filter((order) => order.Id !== currentItem.Id)
    );
  };

  // Handle clear all selected orders
  const handleClearAllSelected = () => {
    setSelectedOrders([]);
  };

  // Handle onSignOrders
  const handleSignOrders = async () => {
    setIsSubmit(true); // Set submission state to true
    setIsSubmissionSuccessful(false); // Reset submission success state
    try {
      const params = {
        Action: "add",
        Orders: selectedOrders.map(({ Id: OrderId, Name, Type }) => ({
          OrderId,
          Name,
          Type,
        })),
      };
      await dispatch(onSignOrdersApiCall(idCase, params));

      // Update active tab to Existing Orders after successful API call
      setActiveTab("Existing_Orders");
      setIsSubmissionSuccessful(true); // Set submission success state
      setShowSuccessHeading(true); // Show success heading

      // Set timeout to hide the success heading after 5 seconds
      setTimeout(() => {
        setShowSuccessHeading(false);
      }, 8000);
    } catch (error) {
      console.error("Error signing orders:", error);
      // Optionally notify user about the error
    } finally {
      setIsSubmit(false); // Reset submission state
    }
  };

  const handleRemoveItem = (order) => {
    // Set the loading state for the specific order ID
    setLoadingStates((prev) => ({ ...prev, [order.Id]: true }));

    const params = {
      Action: "remove",
      OrderRecordId: order.OrderRecordId,
      Type: order.Type,
    };

    // Simulating an API call
    dispatch(removeOrdersApiCall(idCase, params))
      .then(() => {
        notifyToast({
          status: "error",
          title: "Order has been canceled",
          place: "bottom-center",
          timeClose: 5000,
        })

        // Remove loading state for the order ID
        setLoadingStates((prev) => ({ ...prev, [order.Id]: false }));
        // Optional: Update UI or state to reflect the removed item
      })
      .catch((err) => {
        console.error("Error removing item:", err);
        // Remove loading state even on error
        setLoadingStates((prev) => ({ ...prev, [order.Id]: false }));
      });
  };

  // Định nghĩa danh sách điều hướng với các danh mục tương ứng
  const navListGroup = useMemo(
    () => [
      {
        Name: "New Orders",
        Component: "Labs.....",
        Category: "New_Orders",
      },
      {
        Name: "Existing Orders",
        Component: "Medications.....",
        Category: "Existing_Orders",
      },
    ],
    []
  );

  return (
    <>
      <ListTabsOrders
        {...props}
        navListGroup={navListGroup}
        handleTab={handleTab}
        activeTab={activeTab}
      />
      <InteractPanelOrderSheet>
        {/* Kiểm tra tab hiện tại và hiển thị component tương ứng */}
        {activeTab === "New_Orders" ? (
          <StyledRow>
            <StyledCol>
              <CardWrap {...props} heading={`Available Orders`} buttonClear={false}>
                <AvailableOrders
                  {...props}
                  availableOrders={availableOrdersData}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedOrders={selectedOrders}
                  ordersDataFromStore={ordersDataFromStore}
                />
              </CardWrap>
            </StyledCol>
            <StyledCol>
              <CardWrap
                {...props}
                heading={`Pending Orders`}
                buttonClear={true}
                handleClearAllSelected={handleClearAllSelected}
                addedItems={selectedOrders}
              >
                <AddedAtThisTimePoint
                  {...props}
                  addedItems={selectedOrders}
                  onSignOrders={handleSignOrders}
                  onRemove={handleRemove}
                  isSubmit={isSubmit}
                />
              </CardWrap>
            </StyledCol>
          </StyledRow>
        ) : (
          <React.Fragment>
            <CardWrap
              {...props}
              heading={``}
              buttonClear={false}
              headingFoeExistingOrder={() => (
                <FadeOutWrapper isVisible={showSuccessHeading}>
                  {isSubmissionSuccessful && <SuccessHeading />}
                </FadeOutWrapper>
              )}
            >
              <ExistingOrders
                {...props}
                addedOrders={ordersDataFromStore?.AddedAtThisTimePoint}
                handleRemoveItem={handleRemoveItem}
                loadingStates={loadingStates}
              />
            </CardWrap>
          </React.Fragment>
        )}
      </InteractPanelOrderSheet>
    </>
  );
}

export default OrderList;