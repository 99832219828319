import React, { useState } from 'react'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'

export default function ItemFaq({ faq }) {
  const [showContent, setShowContent] = useState(false)
  
  const handleShowData = () => {
    setShowContent(!showContent)
  }

  return (
    <div className={`${showContent ? "active-open" : ""} faq__wrap w-100`}>
      <div className="faq__wrap-header">
        <p>{faq?.question}</p>
        <div className="icon-show" onClick={handleShowData}>
          {showContent ? <Icon src={Up} stroke="#1D2939"/> : <Icon src={Down} stroke="#1D2939"/>}
        </div>
      </div>
      {showContent && (
        <div className="faq__wrap-content">
          <hr />
          <div dangerouslySetInnerHTML={{ __html: faq?.answer || '' }} />
        </div>
      )}    
    </div>
  )
}
