import React from 'react';

//reactstrap
import { Row, Col } from 'reactstrap';

//components
import { ScoreInput, DomainsList, FeedbackText } from '../../elements/Chat/FreeTextType';

function ScoringFeedBack(props) {
    const { handleSendParamsDomains, handleSendParamsScore, handleSendParamsFeedback } = props && props;

    return (
        <Row>
            <Col md={6}>
                <ScoreInput
                    {...props}
                    handleSendParamsScore={handleSendParamsScore}
                />
                <FeedbackText
                    {...props}
                    handleSendParamsFeedback={handleSendParamsFeedback}
                />
            </Col>
            <Col md={6}>
                <DomainsList
                    {...props}
                    handleSendParamsDomains={handleSendParamsDomains}
                />
            </Col>
        </Row>
    );
};

export default ScoringFeedBack;