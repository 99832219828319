import React from "react";

const LogoBox = ({ backgroundImage, text = "File" }) => {
    const boxStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "60px",
        height: "23px",
        borderRadius: "16px",
        backgroundColor: "#F1F1F1",
        overflow: "hidden",
        padding: "3px 6px"
    };

    const imgStyle = {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    };

    const textStyle = {
        color: "#F79E1C",
        fontFamily: "Inter, sans-serif",
        fontSize: "8px",
        fontWeight: 700,
        lineHeight: "18px",
    };

    return (
        <div style={boxStyle}>
            {backgroundImage ? (
                <img
                    src={backgroundImage}
                    alt="Logo"
                    style={imgStyle}
                />
            ) : (
                <>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        style={{ marginRight: "2px" }}
                    >
                        <g clipPath="url(#clip0_1414_7325)">
                            <path d="M11.1934 3.28594V11.2266C11.1934 11.6531 10.8466 12 10.42 12H3.27625C2.84968 12 2.50281 11.6531 2.50281 11.2266V0.773438C2.50281 0.346875 2.84968 0 3.27625 0H7.9075L11.1934 3.28594Z" fill="#F79F1E" />
                            <path d="M4.62598 6.02539H9.06973V6.51992H4.62598V6.02539ZM4.62598 7.12695H9.06973V7.62148H4.62598V7.12695ZM4.62598 8.23086H9.06973V8.72539H4.62598V8.23086ZM4.62598 9.33242H7.7877V9.82695H4.62598V9.33242Z" fill="white" />
                            <path d="M8.35498 3.16172L11.1933 5.46094V3.3L9.58545 2.36719L8.35498 3.16172Z" fill="black" fillOpacity="0.0980392" />
                            <path d="M11.1932 3.28594H8.68066C8.2541 3.28594 7.90723 2.93906 7.90723 2.5125V0L11.1932 3.28594Z" fill="#7A6114" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1414_7325">
                                <rect width="12" height="12" fill="white" transform="translate(0.847778)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span style={textStyle}>{text}</span>
                </>
            )}
        </div>
    );
};

export default LogoBox;