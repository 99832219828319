import React from "react";

//components
import { FormLoginV2 } from "@components/VinUni/auth";

//boostrap
import { Container } from "reactstrap";

function VinUniLoginPage(props) {
  return (
    <div className="wrapper -loginPartnerPage">
      <div className="loginPartnerContainer">
        <Container>
          <FormLoginV2 />
        </Container>
      </div>
    </div>
  );
}

export default VinUniLoginPage;