import React from 'react';
import SidebarItem from '../SidebarItem';

const MainMenu = ({ isSidebarCollapsed, sidebarItems }) => {
    return (
        <ul className="sidebar-menu">
            {!isSidebarCollapsed && <p className="sidebar__title">MANAGEMENT</p>}
            {sidebarItems.map((item, index) => 
                !item.isHidden && (
                    <SidebarItem 
                        key={index} 
                        item={item} 
                        isSidebarCollapsed={isSidebarCollapsed} 
                    />
                )
            )}
        </ul>
    );
};

export default MainMenu;