import React from 'react'
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    color: #1D2939;
    font-size: 14px;
    font-weight: 500;
    span {
      color: red;
    }
  }
  .custom-tooltip {
    padding: 8px 12px;
    width: ${(props) => (props.width ? `${props.width}px` : "270px")};
    border-radius: 8px;
    text-align: start;
    &.custom-width {
      width: 340px;
    }
    div {
      text-align: start;
    }
    span, p {
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px; /* 133.333% */
    }
    .tooltip-upload {
      display: flex;
      flex-direction: column;
      gap: 16px;
      &__wrap {
        display: flex;
        align-items: start;
        flex-direction: column;
        p {
          text-align: start;
        }
      }
      &__tip {
        font-style: italic;
      }
    }
  }
`

const TitleCommon = (props) => {
  const { title, content, dataFor, required, slides, width, isUpload, children } = props
  return (
    <WrapNote width={width}>
      <p>
        {title}
        {required && <span>*</span>}
      </p>
      {(content || isUpload) && (
        <>
          <div data-tip data-for={dataFor}>
            <Icon src={info} />
          </div>
          <ReactTooltip id={dataFor} place='top' effect='solid' className={`${slides && "custom-width"} custom-tooltip`} >
            {children}
          </ReactTooltip>
        </>
      )}
    </WrapNote>
  )
}

export default TitleCommon
