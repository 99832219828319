import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//components 
import { FrameChat } from "../Chat/ChatVoice";

//apiCaller
import { apiCaller } from "@utils";

function ChatVoiceBox(props) {
  let { id } = useParams();

  //props
  const { handleOverideEffectMenu, data, switchChat, handleQuestionTypePatient, ChatMode, handleCheckChatFeedback } = props;

  const responseChatData = data?.ChatData;

  //state
  const [responseUserMessage, setResponseUserMessage] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0); // Track the number of submissions
  const [patientResponses, setPatientResponses] = useState([]); // Store PatientResponses

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    return () => {
      // Any cleanup if needed
    };
  }, [handleOverideEffectMenu]);

  const handleSubmitVoice = async (params) => {
    setLoading(true);

    // Increment submit count on each submission
    setSubmitCount(prevCount => prevCount + 1);

    if (ChatMode === "switch_voice_text") {
      handleQuestionTypePatient(params);
    }

    try {
      const res = await apiCaller(`/api/learn/case/chat/${id}/`, 'PUT', params);
      const data = res?.data;
      delete data.UserMessage;

      if (res?.status === 200) {
        console.log('success');
        setResponseUserMessage(data);
        
        // Push the PatientResponse to the patientResponses array
        const updatedResponses = [...patientResponses, data?.PatientResponse];
        setPatientResponses(updatedResponses);

        // Call handleCheckChatFeedback and pass the patientResponses array
        handleCheckChatFeedback(updatedResponses, 'Voice');
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error('API call error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FrameChat
      {...props}
      handleSubmitVoice={handleSubmitVoice}
      responseChatData={responseChatData}
      isLoadingChatVoice={isLoading}
      responseUserMessage={responseUserMessage}
      isShowForSwitch={switchChat === "voice"}
      submitCount={submitCount} // Pass the submit count to the FrameChat component if needed
      patientResponses={patientResponses} // Pass the array of PatientResponses to FrameChat if needed
    />
  );
}

export default ChatVoiceBox;