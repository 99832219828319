import React from "react";
import { Alert } from "reactstrap";

function AnswerContent(props) {
  const { QuestionContent } = props && props;
  return (
    <Alert color="secondary" className="m-alert--default my-0 border-0">
      <p><strong>Question:</strong></p>
      <p className={`whiteSpace-break`} dangerouslySetInnerHTML={{ __html: QuestionContent || "" }}></p>
    </Alert>
  );
}

export default AnswerContent;
