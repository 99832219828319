import React from "react";

function Theader() {
    return (
        <thead>
            <tr>
                <th width="20%" className="align-middle font-weight-500 text-nowrap --text-primary">
                    Date/Time
                </th>
                <th className="align-middle font-weight-500 text-nowrap --text-primary">
                    Chat Question
                </th>
                <th className="align-middle font-weight-500 text-nowrap --text-primary">
                    Chat Answer
                </th>
                <th width="15%" className="align-middle font-weight-500 text-nowrap --text-primary">
                    Correct Responsive ?
                </th>
                <th width="20%" className="align-middle font-weight-500 text-nowrap --text-primary">
                    Topic
                </th>
            </tr>
        </thead>
    );
}

export default Theader;