import React, { useState } from 'react';

//boostrap
import { Table, Button, Badge } from "reactstrap";

function ConditionContent(props) {
    let inintalAnswer = {
        CaseId: "",
        DomainId: "",
        Result: "",
        Score: '',
    };

    const [rowData, setRowData] = useState([inintalAnswer]);

    const handleAddRow = () => {
        const newArr = [...rowData];
        var result = [
            ...newArr,
            {
                CaseId: "",
                DomainId: "",
                Result: "",
                Score: ''
            }
        ];
        setRowData(result);
    };

    const handleRemoveRow = (key) => {
        const newArr = [...rowData];
        const newData = newArr.filter(function (obj, index) {
            return index !== key;
        });
        setRowData(newData);
    };

    return (
        <React.Fragment>
            <Table className="mb-0" responsive>
                <thead>
                    <tr>
                        <th className="font-weight-500 --text-primary align-middle text-center"></th>
                        <th className="font-weight-500 --text-primary align-middle text-center" style={{ width: "30%" }}>
                            Case
                        </th>
                        <th className="font-weight-500 --text-primary align-middle text-center" style={{ width: "30%" }}>
                            Domain
                        </th>
                        <th className="font-weight-500 --text-primary align-middle text-center">

                        </th>
                        <th className="font-weight-500 --text-primary align-middle text-center" style={{ width: "15%" }}>
                            Score
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rowData?.map((value, index) => (
                        <tr key={index}>
                            <td className="align-middle text-center">AND</td>
                            <td className="align-middle text-center">
                                <Badge
                                    style={{ minWidth: "80px" }}
                                    className="font-weight-500 p-2 --bg-primary"
                                    color="primary"
                                >
                                    Case #565
                                </Badge>
                            </td>
                            <td className="align-middle text-center">
                                <Badge
                                    style={{ minWidth: "80px" }}
                                    className="font-weight-500 p-2 --bg-primary"
                                    color="primary"
                                >
                                    Domain #565
                                </Badge>
                            </td>
                            <td className="align-middle text-center">
                                {`>`}
                            </td>
                            <td className="align-middle text-center">
                                <Badge
                                    style={{ minWidth: "80px" }}
                                    className="font-weight-500 p-2 --bg-primary"
                                    color="primary"
                                >
                                    8
                                </Badge>
                            </td>
                            <td className="align-middle text-center">
                                <i
                                    style={{ cursor: "pointer" }}
                                    className="fa fa-2x fa-times text-danger"
                                    aria-hidden="true"
                                    onClick={() => handleRemoveRow(index)}
                                ></i>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button
                className="btn btn-outline-brand d-block mx-auto mt-4"
                onClick={handleAddRow}
            >
                <i className="fa fa-plus" />
                &nbsp; Add Condition
            </Button>
        </React.Fragment>
    );
};

export default ConditionContent;