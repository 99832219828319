import React from "react";

//reactstrap
import { FormGroup, Input } from "reactstrap";

function DropDownCondition(props) {
    const { handleChangeStateCondition, isCondition } = props && props;
    const options = [
        { value: '', name: 'No Condition' },
        { value: 'Question', name: 'Answering status of another question' },
        { value: 'Chat', name: 'Chat status of a topic' },
        { value: 'Order', name: 'Chat status of a order' },
        { value: 'Action', name: 'Chat status of a action' }
    ]

    const listItems = options?.map((item, idx) =>
        <option value={item?.value} key={idx}>{item?.name}</option>
    );

    return (
        <FormGroup>
            <Input
                type="select"
                name="ConditionType"
                defaultValue={isCondition}
                onChange={handleChangeStateCondition}
            >
                {listItems}
            </Input>
        </FormGroup>
    );
}

export default DropDownCondition;