import { scrumConstant } from "@constants";
import {apiCaller} from "@utils";

//Load Scrum
export const actLoadScrum = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/scrum/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: scrumConstant.LOAD_SCRUM_REQUEST, isLoading } }
    function success(data) { return { type: scrumConstant.LOAD_SCRUM_SUCCESS, payload: data } }
    function failure(error) { return { type: scrumConstant.LOAD_SCRUM_FAILURE, error: error } }
}

//Load Scrum
export const actLoadScrumById = (idScrum) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/scrum/${idScrum}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: scrumConstant.LOAD_SCRUM_BY_ID_REQUEST, isLoading } }
    function success(data) { return { type: scrumConstant.LOAD_SCRUM_BY_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: scrumConstant.LOAD_SCRUM_BY_ID_FAILURE, error: error } }
}