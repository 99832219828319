import React, { useRef, useState, useEffect } from "react";

//Boostrap
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Alert,
} from "reactstrap";

//common
import { DataEmty } from "@components-common";

//presentation
import { PointBubble } from "../../StandardScreen";
import { ViewExamContent } from "../ExamFindings";

function ModalHumanModel(props) {
  //state
  const [listExam, setExam] = useState([]);

  //props
  const { isOpen, toggle, caseData, handleOverideEffectMenu } = props && props;
  const dataResponse = caseData && caseData.data;
  const dataInterface = dataResponse?.Case?.Interface;
  const dataClinicalStatus = dataResponse?.Case?.ClinicalStatus;
  const useHumanModel = dataInterface?.UseHumanModel;
  const imgHumanMoel = dataInterface?.HumanModelUrl;

  const getSizeWrap = useRef();

  //Remove Effect When component render
  useEffect(() => {
    isOpen && handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleHumanModal = (item, list) => {
    setExam(list);
  };

  const alertData = "Click on each dot to view relevant exam findings.<br />(You might need to scroll down to view the full image and all exam points)";
  return (
    <Modal
      style={{ maxWidth: "1024px" }}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>Human Model</ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12}>
            <Alert className="text-center" color="info">
              <p dangerouslySetInnerHTML={{ __html: alertData }}></p>
            </Alert>
          </Col>
          <Col sm="3">
            <div className="patientPhoto isHumanModal" ref={getSizeWrap}>
              <img
                className="img-fluid"
                src={imgHumanMoel}
                alt="exam_photo"
                style={{ maxWidth: "250px" }}
              />
              <PointBubble
                data={dataClinicalStatus?.ExamPoints}
                devRef={getSizeWrap}
                useHumanModel={useHumanModel}
                handleHumanModal={handleHumanModal}
              />
            </div>
          </Col>
          <Col sm="9">
            {listExam?.length === 0 ? (
              <DataEmty content="No Data" />
            ) : (
              <ViewExamContent data={listExam} />
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="btn btn-default" onClick={toggle}>
          Close
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalHumanModel;