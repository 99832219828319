import React from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

function DropdownGroupAvailability(props) {
    const { data, index, toggleDropdown, handleAllAvailable, dropdownOpen } = props && props;
    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={() => toggleDropdown(index)}
            className="ml-2"
        >
            <DropdownToggle
                className="--btn-primary"
                caret
                color="primary"
            >
                Set Availability
            </DropdownToggle>
            <DropdownMenu container="body">
                <DropdownItem
                    onClick={() =>
                        data ? handleAllAvailable("Available", data) : handleAllAvailable("Available")
                    }
                >
                    All Available
                </DropdownItem>
                <DropdownItem
                    onClick={() =>
                        data ? handleAllAvailable("Unavailable", data) : handleAllAvailable("Unavailable")
                    }
                >
                    All Unavailable
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default DropdownGroupAvailability;