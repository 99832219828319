import React from 'react';

//router
import { Link } from "react-router-dom";

//boostrap
import {
    Container,
    Row,
    Col
} from 'reactstrap';

//utils
import { useQuery } from "@utils";

function ForbiddenPage() {
    let query = useQuery();
    let messageData = query.get("message");
    return (
        <div className="wrapper -notFoundPage" >
            <Container>
                <Row>
                    <Col>
                        <div className="notFoundCt">
                            <h1 className="notFoundCt__h1">403</h1>
                            <h2 className="notFoundCt__h2">Forbidden</h2>
                            <p className="notFoundCt__desc">
                                {messageData}. Go to <Link to="/">Home Page</Link>.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ForbiddenPage;