import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '@components/common'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux'

const WrapResultExplan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .text-explanation {
    padding-left: 20px;
    p {
      color: #101828;
      font-size: 16px;
      font-weight: 700;
    }
    span {
      color: #1D2939;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

export default function ResultExplanation(props) {
  const { choice, question } = props
  const [showData, setShowData] = useState(true)
  const dataExplan = useSelector((state) => state.generateExplanation || []);
  
  const handleShowData = () => {
    setShowData(!showData)
  }

  return (
    <WrapResultExplan>
      <div className='d-flex align-items-center justify-content-between'>
        <p className={`${choice.id === question?.best_answer?.id ? "correct-answer" : ""} answer`}>
          {choice.id}. {choice.content}
        </p>
        {(question?.explanation?.[choice?.id]?.explanation /* || choice?.id === question?.best_answer?.id */) && (
          <div onClick={handleShowData}>
          {showData ? <Icon stroke="#1D2939" src={Up}/> : <Icon stroke="#1D2939" src={Down}/>}
          </div>
        )}
      </div>
      {dataExplan?.isLoading && choice?.id !== question?.best_answer?.id ? (
        <div className="w-100">
          <Skeleton height={20} width={`100%`} />
          <Skeleton height={20} width={`100%`} />
          <Skeleton height={20} width={`100%`} />
        </div>
      ) : (
        (question?.explanation?.[choice?.id]?.explanation /* || choice?.id === question?.best_answer?.id */) && showData && (
          <div className='text-explanation'>
            <p>Explanation: <span>{question?.explanation?.[choice?.id]?.explanation || question?.best_answer?.explanation}</span></p>
          </div>
        )
      )}
    </WrapResultExplan>
  )
}
