import React from 'react';
import { Card, CardHeader, CardBody, FormGroup, Label, Input } from 'reactstrap';

// Define size configurations
const dropdownSizes = [
    { label: 'Checkbox Input (16 px)', className: 'react-checkbox-common' },
    { label: 'Checkbox Input (18 px)', className: 'react-checkbox-common react-checkbox-md' },
];

const CheckboxInput = () => {
    return (
        <Card className="my-2">
            <CardHeader>Checkbox Input</CardHeader>
            <CardBody>
                {dropdownSizes.map((size, index) => (
                    <FormGroup check key={index} className='form-common'>
                        <Input className={`${size.className}`} type="checkbox" name="checkbox"/>
                        <Label check>{size.label}</Label>
                    </FormGroup>
                ))}
            </CardBody>
        </Card>
    );
};

export default CheckboxInput;