import React from 'react';
import styled from 'styled-components';

// components
import { ChatFormContainer } from "../StandardScreen/LearnerMobile/ChatForm";
import { ChatBeginScreen } from "../StandardScreen/LearnerMobile/ChatBegin";

const WrapperContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
`;

const InnerWrapper = styled.div`
    width: 100%;
    max-width: ${(props) => (props.isChatForm ? '100%' : '358px')};
    height: 100%;
    padding: ${(props) => (props.isChatForm ? '16px' : '0')};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
`;

const LearnMobileContainer = ({ useChatForm }) => {
    return (
        <WrapperContainer>
            <InnerWrapper isChatForm={true}>
                {!useChatForm ? <ChatFormContainer /> : <ChatBeginScreen />}
            </InnerWrapper>
        </WrapperContainer>
    );
};

export default LearnMobileContainer;