//learn
import {
    MainLearnerPage,
    AssignmentsPage,
    LearnHomePage,
    FeedbackV2Page,
    WelcomePage,
    CompletedPage,
    ReloadCasePage
} from "@pages/med2lab/learn";

const learnerRoutes = [
    // Learn
    {
        path: ["/learn/home"],
        exact: true,
        classPage: '-homePage',
        component: LearnHomePage,
        isLayout: true,
    },
    {
        path: ["/learn/assignments", "/learn/assignments/:id"],
        exact: true,
        classPage: '-assignmentsPage',
        component: AssignmentsPage,
        isLayout: true,
    },
    // Feedback Moi
    {
        path: "/learn/feedback/:id",
        exact: true,
        classPage: '',
        component: FeedbackV2Page,
        isLayout: true,
        isLearner: true
    },
    {
        path: "/learn/case/:id",
        exact: true,
        classPage: '',
        component: MainLearnerPage,
        isLayout: true,
        isLearner: true
    },
    {
        path: "/learn/series/:id",
        exact: true,
        classPage: '',
        component: WelcomePage,
        isLayout: true
    },
    {
        path: "/learn/case/completed/:id",
        component: CompletedPage,
        isLayout: true
    },
    {
        path: "/learn/load_case/:id",
        component: ReloadCasePage,
        isLayout: true
    }
];

export { learnerRoutes };