/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import { Button, Modal, ModalBody } from "reactstrap";
import { Iconsax } from "@components-common";
import { useForm } from 'react-hook-form';
import FormCommon from './FormCommon';
import { ModalListBook } from '../home/generate';
import { useDispatch } from "react-redux";
import { getAllEngages } from "@actions";
import { toast } from 'react-toastify';

export default function ModalEditEngage(props) {
  const { isOpen, toggle, title, dataDetail } = props && props;
  const [bookId, setBookId] = useState(null)
  const [showModalBooks, setShowModalBooks] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false)
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm({
    defaultValues: {
      name: dataDetail?.topic_name,
      instruction: dataDetail?.ai_instructions_raw,
      statement: dataDetail?.open_conversation,
      model: dataDetail?.gpt_model,
      interactionLanguage: dataDetail?.interaction_language,
      interactionMethod: dataDetail?.interaction_method,
    }
  })

  const onSubmit = (data) => {
    handleUpload(data, bookId);
  }

  const handleUpload = async (data, bookId) => {
    let params = {
      action_type: "edit_topic_module",
      module_name: data?.name,
      bot_instruction: data?.instruction,
      opening_statement: data?.statement,
      LLM_model: data?.model,
      topic_id: dataDetail?.topic_id,
      reference_id: bookId?.length ? bookId[0]?.document_id : null,
      interaction_language: data?.interactionLanguage,
      interaction_method: data?.interactionMethod
    }

    // Bắt đầu tải kết quả
    setLoadingResult(true);

    dispatch(getAllEngages(params))
      .then((result) => {
        if (result?.data?.status === 200) {
          toast.success(`Data update successfully !`, {
            position: "top-right"
          });
          toggle()
        }
        setLoadingResult(false);
      })
  };
  
  return (
    <Modal className="modal-edit-engage" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="modal-header-custom">
          <Iconsax
            iconName={"edit-modal"}
            size={20}
            fill={`#0089C2`}
          />
          <p className='title-edti'>{title}</p>
        </div>
        <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
          <FormCommon
            setValue={setValue}
            register={register}
            control={control}
            watch={watch}
            setShowModalBooks={setShowModalBooks}
            setBookId={setBookId}
            bookId={bookId}
            {...props}
          />
          <div className='d-flex align-items-center justify-content-end mt-2'>
            <Button className="btn btn-secondary" disabled={loadingResult} onClick={toggle}>
              Cancel
            </Button>{" "}
            <Button
              className="btn d-flex align-items-center ml-2"
              color='primary'
              disabled={loadingResult || !watch("name") || !watch("instruction") || !watch("statement") || false}
              type='submit'
            >
              Save
              {loadingResult && <span className="spinner-border spinner-border-sm mr-2"></span>}
            </Button>
          </div>
        </form>
      </ModalBody>
      <ModalListBook
        isOpen={showModalBooks}
        toggle={() => setShowModalBooks(!showModalBooks)}
        setBookId={setBookId}
        bookId={bookId}
        chooseOne={true}
        dataDetail={dataDetail}
      />
    </Modal>
  )
}
