import React from 'react';
import styled from 'styled-components';
import CommonBoxWrapper from './CommonBoxWrapper';
import { AcknowledgementCheckbox } from "@components/PaymentGateway/PaymentOverview";

//Icon
import IconMoney from '@images/coin/icon-money.svg';

const BillSummaryTitle = styled.h3`
    display: flex;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: #0A0D13;
    font-size: 18px;
    font-weight: 600;
    border-bottom: 1px solid #ACACAC;
`;

const CoinNumbers = styled.div`
    display: flex;
    color: #F79E1C;
    font-size: 28px;
    font-weight: 700;
    align-items: center;
`;

const CoinsUSD = styled.div`
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    justify-self: end; /* Căn phải */
`;

const TotalLabel = styled.div`
    color: #0A0D13;
    font-size: 16px;
    font-weight: 500;
    justify-self: start; /* Căn trái */
`;

const TotalUSD = styled.div`
    color: #0A0D13;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    justify-self: end; /* Căn phải */
`;

const RowWithConditionalBorder = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 16px 0;
    border-bottom: ${({ borderBottom }) => (borderBottom ? '1px solid #ACACAC' : 'none')};
`;

const BillSummaryDetails = ({ isAcknowledged, handleCheckboxChange }) => {
    return (
        <CommonBoxWrapper>
            <div className="container">
                <BillSummaryTitle>Bill Summary</BillSummaryTitle>
                <RowWithConditionalBorder borderBottom={true}>
                    <CoinNumbers>20 <img className='ml-2' width={32} src={IconMoney} alt="20 Credits" /> </CoinNumbers>
                    <CoinsUSD>10 USD</CoinsUSD>
                </RowWithConditionalBorder>
                <RowWithConditionalBorder borderBottom={false}>
                    <TotalLabel>Total</TotalLabel>
                    <TotalUSD>$10.00</TotalUSD>
                </RowWithConditionalBorder>
                <AcknowledgementCheckbox
                    isAcknowledged={isAcknowledged}
                    handleCheckboxChange={handleCheckboxChange}
                />
            </div>
        </CommonBoxWrapper>
    );
};

export default BillSummaryDetails;