import React from 'react';
import styled, { css } from 'styled-components';

const ChatContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  ${props => props.isLeft 
    ? css`flex-direction: row;` 
    : css`flex-direction: row-reverse;`
  }
`;

const Avatar = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 24px;
  background: url(${props => props.image}) lightgray 50% / cover no-repeat;
  ${props => props.isLeft && css`
    align-self: flex-end;
  `}
`;

const BoxChatLeft = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 4px 18px 18px 16px;
  background: #F1F1F1;
  max-width: 70%;
`;

const BoxChatRight = styled.div`
  display: flex;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 18px 18px 4px 16px;
  background: #F79E1C;
  max-width: 70%;
`;

const Name = styled.div`
  color: #F1800F;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const ContentLeft = styled.div`
  color: #697586;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ContentRight = styled.div`
  color: #FFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;

const ChatMessage = ({ name, content, image, isLeft }) => (
  <ChatContainer isLeft={isLeft}>
    {isLeft && <Avatar image={image} isLeft={isLeft} />}
    {isLeft ? (
      <BoxChatLeft>
        <Name>{name}</Name>
        <ContentLeft>{content}</ContentLeft>
      </BoxChatLeft>
    ) : (
      <BoxChatRight>
        <ContentRight>{content}</ContentRight>
      </BoxChatRight>
    )}
  </ChatContainer>
);

export default ChatMessage;