import React from 'react';

function ButtonDeleteSeries(props) {
    const { toggleModalDel } = props && props;
    return (
        <button
            className="btn-style2 btn-outline-danger mr-2"
            onClick={() => toggleModalDel({}, "deleteSeries")}
        >
            <i className="fa fa-times mr-1" aria-hidden="true"></i> Delete Series
        </button>
    );
};

export default ButtonDeleteSeries;