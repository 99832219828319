import React from 'react';
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";

const TableRow = ({ indexKey, item, toggleModal }) => {
    const handleEditButtonClick = () => {
        toggleModal('Update', item);
    };

    const handleDeleteButtonClick = () => {
        toggleModal('Delete', item);
    };

    return (
        <tr index={indexKey}>
            <td className="align-middle">{item.TopicName}</td>
            <td className="align-middle">{item.ChatQuestion}</td>
            <td className="align-middle">{item.ChatResponse}</td>
            <td className="align-middle text-center">
                <div className="d-flex align-items-center justify-content-center">
                    <BtnIconEditV2 handleEditButtonClick={handleEditButtonClick} colorIcon={`#16B364`} />
                    <BtnIconDeleteV2 handleDelete={handleDeleteButtonClick} />
                </div>
            </td>
        </tr>
    );
};

export default TableRow;