import { examFindings } from "@constants";

export function dataExamFindingsByCaseId (state = {}, action) {
    switch (action.type) {
        case examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case examFindings.LOAD_EXAM_FINDINGS_FOR_ELEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function examFindingsByAtributeId(state = {}, action) {
    switch (action.type) {
        case examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case examFindings.EXAM_BY_ATTRIBUTEID_FOR_ELEMENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default: return state;
    }
};

export function examMobileReducer(state = {}, action) {
    switch (action.type) {
        case examFindings.TOGGLE_EXAM_MOBILE:
            return {
                ...state,
                isOpen: action.payload,
            };
        default: return state;
    }
};