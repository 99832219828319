import React from 'react';

//common
import { IconDisplay } from '@components-common/media';

function TableCellExam(props) {
    const { value, handleShow, iconMedia, lastTimepoint, item } = props && props;
    const isMedia = item?.MediaType;
    return (
        <td 
            style={{ cursor: 'pointer' }} 
            onClick={handleShow}
            className={`table-cell align-middle ${lastTimepoint ? 'text-center' : 'text-left'}`}
        >
            <div dangerouslySetInnerHTML={{ __html: value }} />
            <div className="mt-2 text-center">
                {iconMedia &&
                    <IconDisplay type={isMedia} />
                }
            </div>
        </td>
    );
};

export default TableCellExam;