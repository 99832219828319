import React from 'react';
import { Icon } from '@components/common';
import info from '@images/teachV2/info.svg';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  margin-bottom: 12px;
  p {
    color: #081B2A;
    font-size: 18px;
    font-weight: 600;
  }
  .custom-tooltip {
    padding: 8px 12px;
    width: ${(props) => (props.widthTooltip ? `${props.widthTooltip}px` : "270px")};
    border-radius: 8px;
    text-align: ${(props) => props.textAlginContent};
    &.custom-width {
      width: 340px;
    }
    span, p {
      color: #FFF;
      font-size: ${(props) => (props.textSizeTooltop ? `${props.textSizeTooltop}px` : "14px")};
      font-weight: 600;
      line-height: 18px;
    }
  }
`;

const TitleTooltipInfo = ({ title, description, widthTooltip, sizeIcon, textSizeTooltop, textAlginContent }) => {
    const tooltipId = title.toLowerCase().replace(/\s+/g, '-'); // Tạo id tooltip từ title (dùng cho nhiều component)

    // Xử lý kích thước biểu tượng
    const iconSize = sizeIcon || 20;  // Default size is 20 if no size is passed

    return (
        <WrapNote widthTooltip={widthTooltip} textSizeTooltop={textSizeTooltop} textAlginContent={textAlginContent}>
            <p>{title}</p>
            <div data-tip data-for={tooltipId}>
                <Icon src={info} width={iconSize} height={iconSize} />
            </div>
            <ReactTooltip id={tooltipId} place="top" effect="solid" className="custom-tooltip">
                <span>{description}</span>
            </ReactTooltip>
        </WrapNote>
    );
};

export default TitleTooltipInfo;