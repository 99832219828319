import React, { useState } from "react";
import { Modal, ModalBody, Button, Input } from 'reactstrap';
import TitleNoteCommon from "../../home/generate/TitleNoteCommon";
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { teachV2Constants } from "@constants";

const ModalEditRequirement = ({ isOpen, toggle, dataCaseScript }) => {
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false);

  //form
  const {
    handleSubmit,
    watch,
    setValue
  } = useForm({
    defaultValues: {
      requirements: dataCaseScript?.requirements
    }
  });

  const onSubmit = (data) => {
    setLoading(true);
    const params = {
      ...dataCaseScript,
      action_type: "save_case_script",
      requirements: data?.requirements
    }
    apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
      if (res.status === 200) {
        toast.success(`Requirements have been updated!`, {
          position: "top-right"
        });
        dispatch({ type: teachV2Constants.SAVE_CASE_SCRIPT_SUCCESS, payload: res?.data });
        toggle()
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <Modal className="modal-feedback modal-edit" isOpen={isOpen} toggle={toggle} backdrop={`static`} centered>
      <ModalBody>
        <div className="feedback__content">
          <div className="feedback__content-info edit-required">
            <div className="info-feedback align-items-start">
              <h1 className="info-feedback__title">Edit Requirements</h1>
              <p className="info-feedback__note">Editing the requirements will replace the current ones but won't update other sections automatically. <br />To have these changes reflected in specific sections, please click on the       icon for each section.</p>
            </div>
          </div>
          <div className="feedback__content-form">
            <TitleNoteCommon
              required={true} 
              title="Requirements"
            />
            <Input
              type="textarea"
              name="requirements"
              placeholder="Example: Include more details on patient symptoms"
              onChange={(e) => {
                setValue("requirements", e.target.value);
              }}
              defaultValue={watch("requirements")}
              className="mt-2"
            />
          </div>          
        </div>
        <div className="feedback__action">
          <Button className="btn btn-secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn d-flex align-items-center"
            onClick={handleSubmit(onSubmit)}
            disabled={!watch("requirements")}
          >
            Save
            {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditRequirement;
