import React, { useState, useEffect } from "react";

//boostrap
import { Row, Col, Button } from "reactstrap";

//components
import { ToBeShowedOptions, FormSelectItem, InputSearchCases } from '../ToursAssistants';

//elements
import { ButtonDelete } from '@components-common/elements';

//lodash
import _ from "lodash";

function ToursAssistantsMain(props) {

    const [rowData, setRowData] = useState([{ id: 1 }]);
    const createTourSetUp = () => {
        setRowData([
            ...rowData,
            { id: rowData.length + 1 }
        ]);
    };

    const listDropdown = [
        { value: '1', label: 'Item Dropdown 001' },
        { value: '2', label: 'Item Dropdown 002' },
        { value: '3', label: 'Item Dropdown 003' },
        { value: '4', label: 'Item Dropdown 004' }
    ]

    //state
    const [typeRadio, setRadio] = useState('allCases');

    //Update Array Store
    useEffect(() => {
        setRadio('allCases');
    }, []);

    const handleChange = (e) => {
        const { value } = e.target;
        setRadio(value);
    }

    const handleDeleteItem = (index) => {
        const newData = _.filter(rowData, function (o, j) {
            return j !== index;
        });
        setRowData(newData);
    }

    const listItems = rowData?.map((value, index) =>
        <div className="rowTour border p-4 rounded mb-3" key={index}>
            <Row className="d-flex flex-wrap align-items-center">
                <Col md={3}>
                    <FormSelectItem
                        placeholder={`Select an item below`}
                        label="Tour"
                        optionsData={listDropdown || []}
                    />
                </Col>
                <Col md={4}>
                    <FormSelectItem
                        placeholder={`Select an item below`}
                        label="View"
                        optionsData={listDropdown || []}
                    />
                </Col>
                <Col md={5}>
                    <ToBeShowedOptions
                        handleChange={handleChange}
                        typeRadio={typeRadio}
                        index={index}
                    />
                    <InputSearchCases />
                </Col>
            </Row>
            <div className="rowTour__action">
                <Button color="primary" className="--btn-primary btn-sm ml-3">
                    <i className="fa fa-floppy-o" aria-hidden="true"></i>
                </Button>
                <ButtonDelete
                    handleDelete={() => handleDeleteItem(index)}
                />
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <div className="text-right">
                <Button color="primary" className="--btn-primary mb-4" onClick={createTourSetUp}>
                    <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                    Add Tour Set Up
                </Button>
            </div>
            {listItems}
        </React.Fragment>
    );
};

export default ToursAssistantsMain;