import React from 'react';

//components
import { TableCell } from '../Labs';

function IndependentLabsGroup(props) {
    const { typeGroup, indexRow, lisTimePointFirst, listTimepointLast, dataChildren, col, handleEdit, handleShow } = props && props;
    return (
        <tr
            className={`row_item mt-3`}
            key={indexRow}
        >
            {/* Lab Item */}
            {lisTimePointFirst?.map((row) => {
                const item = row[typeGroup][indexRow];
                const TimePointData = lisTimePointFirst && lisTimePointFirst[0]?.TimePoint;
                return (
                    <TableCell
                        {...props}
                        value={item?.Name}
                        item={item}
                        firstTimepoint={true}
                        listChildren={dataChildren}
                        TimePointData={TimePointData}
                        handleEdit={() => undefined}
                        itemPanel={col}
                        type="first_name"
                    />
                )
            })}

            {/* Inital Value */}
            {lisTimePointFirst?.map((row) => {
                const item = row[typeGroup][indexRow];
                const TimePointData = lisTimePointFirst && lisTimePointFirst[0]?.TimePoint
                return (
                    <TableCell
                        {...props}
                        value={item?.InitialValue}
                        item={item}
                        firstTimepoint={true}
                        listChildren={dataChildren}
                        TimePointData={TimePointData}
                        handleEdit={handleEdit}
                        itemPanel={col}
                        type="first"
                    />
                )
            })}

            {/* Last Timepoint */}
            {listTimepointLast?.map((row) => {
                const item = row[typeGroup][indexRow];
                const TimePointData = row?.TimePoint
                return (
                    <TableCell
                        {...props}
                        item={item}
                        firstTimepoint={true}
                        listChildren={dataChildren}
                        handleEdit={handleEdit}
                        itemPanel={col}
                        TimePointData={TimePointData}
                        handleShowModalChangeValue={handleShow}
                        type="last"
                    />
                )
            })}
        </tr>
    );
};

export default IndependentLabsGroup;