import { vitalConstant } from "@constants";

//lodash
import _ from "lodash";

export function dataVitalSignsByCaseId (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case vitalConstant.VITAL_SIGN_CHANGE_FOR_ELEMENTS_SUCCESS:
            newState = { ...state };
            if(action.TimepointId) {
                const dataIndex = _.findIndex(newState?.data, function (o) {
                    return o?.TimePoint?.TimepointId === action.TimepointId;
                });
                const ArrUpdate = newState?.data[dataIndex];
                ArrUpdate[`VitalSigns`] = action.payload.VitalSigns;
            }
            return {
                ...newState,
                isLoading: false,
            };
        case vitalConstant.LOAD_VITAL_SIGNS_FOR_ELEMENTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};