import React from "react";

function ListTabsOrders({ navListGroup, activeTab, handleTab }) {
    return (
        <ul className="tabs-corner">
            {navListGroup?.map(({ Name, Icon, Category }) => (
                <li
                    key={Category}
                    className={`tabs-corner__item ${activeTab === Category ? 'active' : ''}`}
                    onClick={() => handleTab(Category)} // Directly use Category
                >
                    <span className="tabs-corner__item--text">{Name}</span>
                    <span className={`tabs-corner__item--icon ${Icon}`}></span>
                </li>
            ))}
        </ul>
    );
}

export default ListTabsOrders;