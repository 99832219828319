import React from "react";

//components
import { TotalIO, IOTable } from '../IOTableBlock';

//Last Timepoint
import { IOTableLastTimepoint } from '../../timePointActions/InTakeOutput'

function IOTableContainer(props) {
  const { timepointData } = props && props;
  const timepointDetails = timepointData?.data;
  const IOData = timepointDetails?.IO;
  const Intake = IOData?.Intake;
  const Output = IOData?.Output;
  const Time = timepointDetails?.Time;

  //Timepoint Last
  const DefaultVitalSigns = timepointDetails?.DefaultVitalSigns;
  const stringIntake = 'Intake';
  const stringOutput = 'Output';
  const IntakeFilter = DefaultVitalSigns?.filter((a) => a.NameAlias.includes(stringIntake));
  const OutputFilter = DefaultVitalSigns?.filter((a) => a.NameAlias.includes(stringOutput));

  const listIO = [
    {
      title: 'INTAKE',
      type: 'Intake',
      button: 'Add Intake Item',
      data: Time === 0 ? (Intake || []) : (IntakeFilter || [])
    },
    {
      title: 'OUTPUT',
      type: 'Output',
      button: 'Add Output Item',
      data: Time === 0 ? (Output || []) : (OutputFilter || [])
    }
  ]

  return (
    <React.Fragment>
      {Time === 0 ? (
        <React.Fragment>
          <TotalIO {...props} />
          {listIO?.map((item, index) =>
            <IOTable
              {...props}
              key={index}
              titleBlock={item?.title}
              typeBalance={item?.type}
              buttonText={item?.button}
              dataBalance={item?.data}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {listIO?.map((item, index) =>
            <IOTableLastTimepoint
              {...props}
              key={index}
              titleBlock={item?.title}
              typeBalance={item?.type}
              buttonText={item?.button}
              data={item?.data}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default IOTableContainer;