import React from "react";

//boostrap
import { Input, FormGroup, Label, Row, Col } from "reactstrap";

function SetAsDemo(props) {
  
  //props
  const { data, handleChange } = props && props;
  const isDemo = data?.isDemo;
  return (
    <React.Fragment>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="Name">
              Series Name:
            </Label>
            <Input
              type="text"
              onChange={handleChange}
              name="Name"
              defaultValue={data?.Title || data?.Name}
              autoComplete="off"
            />
          </FormGroup>
          <div className="d-flex align-items-center mb-3">
            <span className="innerCt__label font-weight-500 mr-5">
              Set As Demo:
            </span>
            <div className="switches ml-2">
              <Input
                type="checkbox"
                onChange={handleChange}
                name="isDemo"
                defaultChecked={isDemo}
                id={`isDemo`}
              />
              <label htmlFor={`isDemo`}>
                <span />
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default SetAsDemo;