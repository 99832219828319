import React, { useState, useEffect } from "react";

//components
import { AccountTableTypeComment } from "../ItemReview/TypeReview";

//Modal
import { ModalReviewTypeComment } from "../ItemReview/TypeReview/Comment";
import { ModalReview, AccountTable, ModalComparisonData } from "../ItemReview/TypeReview/GoldStandard";

//FIlter
import { FitterTop } from "../../validate/ItemReview/Filter";

//call Api
import { apiCaller } from "@utils";

//constants
import { validateConstant } from "@constants";

//redux
import { useDispatch } from "react-redux";

//lodash
import _ from "lodash";

function ItemReviewMain(props) {
    let dispatch = useDispatch();

    //state
    const [stateData, setData] = useState({
        tags: [],
        category: []
    });
    const [modal, setModal] = useState({
        reviewItem: false,
        comparisonData: false
    });
    const [dataCurrent, setDataCurrent] = useState({});
    const [typeReview, setTypeReview] = useState({});
    const [itemsReview, setItemsReview] = useState([]);
    const [isLoading, setLoading] = useState({
        getTagforValidation: false,
        getItemsForReviews: false,
        getCategoryReviews: false,
        filterItemsForReviews: false
    });

    //load category
    useEffect(() => {
        // Goi lay category truoc
        const fetchCategory = () => {
            setLoading((prevState) => ({ ...prevState, getCategoryReviews: true }));
            const params = { "action_type": "get_category_reviews" }
            apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    // fetchData(data || []);
                    setData((prevState) => ({ ...prevState, category: data }));
                    setLoading((prevState) => ({ ...prevState, getCategoryReviews: false }));
                } else {
                    console.log('error');
                    setLoading((prevState) => ({ ...prevState, getCategoryReviews: false }));
                }
            });
        }
        //Load Api Model
        fetchCategory();
    }, [dispatch]);

    // Handle Modal
    const toggle = (type, value) => {
        setModal((prevState) => ({
            ...prevState,
            [type]: !prevState[type]
        }));
        setDataCurrent(value);
    };

    // Handle Call Api All Tags
    const handleApiAllTag = (categoryValue) => {
        const reviewTypeBycategory = findObjectByCode(stateData?.category, categoryValue?.value);
        setTypeReview(reviewTypeBycategory);
        
        const params = {
            "action_type": "get_tags_for_validation",
            "code": categoryValue?.value,
            "review_type": reviewTypeBycategory?.review_type
        }
        setLoading((prevState) => ({ ...prevState, getTagforValidation: true }));
        dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_REQUEST, isLoading: true });
        //Call Api Load Text Item
        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setData((prevState) => ({ ...prevState, tags: data }));
                setLoading((prevState) => ({ ...prevState, getTagforValidation: false }));
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_SUCCESS, payload: data });
            } else {
                setLoading((prevState) => ({ ...prevState, getTagforValidation: false }));
                dispatch({ type: validateConstant.GET_TAGS_FOR_VALIDATION_FAILURE, payload: data });
            }
        })
    }

    // Filter Review Type
    const findObjectByCode = (data, code) => {
        return _.find(data, { 'code': code });
    };

    //handle Submit Filter
    const handleSubmitFilter = (dataFilter) => {
        const reviewTypeBycategory = findObjectByCode(stateData?.category, dataFilter?.category);
        setTypeReview(reviewTypeBycategory);

        setLoading((prevState) => ({ ...prevState, filterItemsForReviews: true }));
        const params = {
            "action_type": "get_items_for_reviews",
            "category": dataFilter?.category,
            "only_no_review": dataFilter?.only_no_review,
            "tag_id": dataFilter?.tag_id,
            "review_type": reviewTypeBycategory?.review_type
        }

        apiCaller(`/api/binh/logging/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setItemsReview(data);
                setLoading((prevState) => ({ ...prevState, filterItemsForReviews: false }));
            } else {
                console.log('error');
                setLoading((prevState) => ({ ...prevState, filterItemsForReviews: false }));
            }
        });
    }

    // Update data neu remove hoac add moi
    const handleUpdateNewData = (data) => {
        setData((prevState) => ({ ...prevState, tags: data }));
    }

    // Handle Update Data Item Review
    const handleUpdateReviewforLogging = (data) => {
        setItemsReview(prevLogData =>
            prevLogData.map((item) =>
                item.log_id === data?.log_id ? { ...item, is_reviewed: true } : item
            )
        );
    }
    
    return (
        <React.Fragment>
            <FitterTop
                {...props}
                handleSubmitFilter={handleSubmitFilter}
                isLoading={isLoading}
                categoryOptions={stateData?.category || []}
                tagsData={stateData?.tags || []}
                handleUpdateNewData={handleUpdateNewData}
                handleTagsForValidationByCategory={handleApiAllTag}
                typeReview={typeReview}
            />
            {typeReview?.review_type === "comment" && (
                <React.Fragment>
                    <AccountTableTypeComment
                        {...props}
                        toggle={toggle}
                        itemsReview={itemsReview}
                        isLoading={isLoading?.getItemsForReviews}
                        typeReview={typeReview}
                    />
                    <ModalReviewTypeComment
                        {...props}
                        isOpen={modal?.reviewItem}
                        toggle={() => toggle('reviewItem', dataCurrent)}
                        dataCurrent={dataCurrent}
                        handleUpdateReviewforLogging={handleUpdateReviewforLogging}
                        stateData={stateData || []}
                    />
                </React.Fragment>
            )}
            {typeReview?.review_type === "gold_standard" && (
                <React.Fragment>
                    <AccountTable
                        {...props}
                        toggle={toggle}
                        itemsReview={itemsReview}
                        isLoading={isLoading?.getItemsForReviews}
                        typeReview={typeReview}
                    />
                    <ModalReview
                        {...props}
                        isOpen={modal?.reviewItem}
                        toggle={() => toggle('reviewItem', dataCurrent)}
                        dataCurrent={dataCurrent}
                        handleUpdateReviewforLogging={handleUpdateReviewforLogging}
                        stateData={stateData || []}
                    />
                    <ModalComparisonData
                        {...props}
                        isOpen={modal?.comparisonData}
                        toggle={() => toggle('comparisonData', dataCurrent)}
                        dataCurrent={dataCurrent}
                        stateData={stateData || []}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ItemReviewMain;