import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//boostrap
import { Card, CardBody } from "reactstrap";

//lodash
import _ from "lodash";

//call Action
import {
  atcLoadAllCohorts,
  actLoadAllPeriods,
} from "@actions";

//components
import { QuestionFilter, InputFilter } from '../Questions';

function FilterTop(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { hanldeChange, stateData, itemActive } = props && props;

  useEffect(() => {
    const fetchData = async () => {
      //load Cohorts
      dispatch(atcLoadAllCohorts());
      //load Periods
      const params = { Action: "Load" };
      dispatch(actLoadAllPeriods(params));
    };

    fetchData();
  }, [dispatch, id]);

  const hanldeChangeOptions = (e) => {
    const { name, value } = e.target;
    hanldeChange(name, value);
  }

  //data store
  const cohortStore = useSelector(
    (state) => (state.listCohort && state.listCohort.data) || []
  );

  const periodsData = useSelector(
    (state) => (state.loadPeriods && state.loadPeriods.data) || []
  );

  return (
    <Card className="border-0 mb-3">
      <CardBody className="-box-shadow">
        <Row>
          <Col md={4}>
            <QuestionFilter
              handleChangeQuestion={hanldeChange}
              {...props}
            />
          </Col>
          <Col md={4}>
            <InputFilter
              handleChange={hanldeChangeOptions}
              name="Cohorts"
              placeholder={`All Enrolled Learners`}
              data={(cohortStore && cohortStore) || []}
              type="CohortId"
              paramsOptions={{ value: 'CohortId', content: 'Name' }}
              isDisabled={_.isEmpty(itemActive)}
              defaultValue={stateData?.CohortId}
            />
          </Col>
          <Col md={4}>
            <InputFilter
              handleChange={hanldeChangeOptions}
              name="Periods"
              placeholder={`Any Time`}
              data={(periodsData && periodsData) || []}
              type="PeriodId"
              paramsOptions={{ value: 'PeriodId', content: 'Name' }}
              isDisabled={_.isEmpty(itemActive)}
              defaultValue={stateData?.PeriodId}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default FilterTop;
