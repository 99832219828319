import { promptTemplateConstants } from "@constants";
import { apiCaller } from "@utils";

// List all prompt template
export const getPromptTemplate = () => {
    const isLoading = true;
    return (dispatch) => {
      dispatch(request(isLoading));
      return apiCaller(`/api/teach/prompt/template/`, "GET", null).then((res) => {
        const data = res?.data;
        if (data) {
          dispatch(success(data));
        } else {
          dispatch(failure("error"));
        }
      });
    };
    function request(isLoading) { return { type: promptTemplateConstants.GET_PROMPT_TEMPLATE_REQUEST, isLoading } }
    function success(data) { return { type: promptTemplateConstants.GET_PROMPT_TEMPLATE_SUCCESS, payload: data } }
    function failure(error) { return { type: promptTemplateConstants.GET_PROMPT_TEMPLATE_FAILURE, error: error } }
  }
  
  // Act Update Prompt Template
  export const atcUpdatePromptTemplate = (params) => {
    const isLoading = true;
    return (dispatch) => {
      dispatch(request(isLoading));
      return apiCaller(`/api/teach/prompt/template/`, "POST", params).then((res) => {
        const data = res?.data;
        if (data) {
          dispatch(success(data));
        } else {
          dispatch(failure("error"));
        }
      });
    };
    function request(isLoading) {
      return {
        type: promptTemplateConstants.UPDATE_PROMPT_TEMPLATE_REQUEST,
        isLoading,
      };
    }
    function success(data) {
      return {
        type: promptTemplateConstants.UPDATE_PROMPT_TEMPLATE_SUCCESS,
        payload: data,
      };
    }
    function failure(error) {
      return {
        type: promptTemplateConstants.UPDATE_PROMPT_TEMPLATE_FAILURE,
        error: error,
      };
    }
  };