import React, { useMemo } from "react";
import { Row } from "reactstrap";
import AssignmentItem from "./AssignmentItem";
import { DataEmty } from '@components/common';

const AssignmentList = ({ type, launchPlansList, ...props }) => {
    const messages = useMemo(() => ({
        "NotStarted": {
            "title": "You’ve started all of your assignments 🤯",
            "content": "🌟Nice work, superstar!"
        },
        "Completed": {
            "title": "It’s empty here?",
            "content": "Complete a case to get rid of this message 😉"
        },
        "InProgress": {
            "title": "You Don't Have Any Active Assignments Yet",
            "content": "Select an assignment to kick-start your adventure"
        }
    }), []);

    return (
        <>
            {launchPlansList?.length > 0 ? (
                <Row className="assignment-list">
                    {launchPlansList.map((assignment, index) => (
                        <AssignmentItem
                            {...props}
                            key={index}
                            type={type}
                            assignment={assignment}
                            showProgres={true}
                        />
                    ))}
                </Row>
            ) : (
                <DataEmty
                    title={messages[type]?.title}
                    content={messages[type]?.content}
                />
            )}
        </>
    );
}

export default AssignmentList;