import React, { useState, useEffect } from 'react';

function ButtonSwitch(props) {
    const { handleSwitchsActive, value } = props && props;
    const currentSwitch = (value === 'Value' ? "left" : "right");

    //state
    const [isActive, setActive] = useState(currentSwitch);

    //load Data
    useEffect(() => {
        setActive(currentSwitch);
    }, [currentSwitch]);

    const handleButtonSwitchs = (type) => {
        setActive(type);
        handleSwitchsActive(type === 'left' ? 'Value' : 'Availability');
    }

    return (
        <div className={`btnSwitchLR ${isActive}`}>
            <div
                className={`button left ${isActive === 'left' ? 'active' : ''}`}
                onClick={() => handleButtonSwitchs("left")}
            >
                Value
            </div>
            <div
                className={`button right ${isActive === 'right' ? 'active' : ''}`}
                onClick={() => handleButtonSwitchs("right")}
            >
                Availability
            </div>
        </div>
    );
};

export default ButtonSwitch;