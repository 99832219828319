import { questionConstants } from "@constants";
import {apiCaller} from "@utils";

//alert
import { Toasts } from '@components/common/Toasts';
import { toast } from 'react-toastify';

//fetch question
export const actFetchQuestionRequest = (idParam, task) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/question/next/${idParam}/?task=${task}`, 'GET', null).then(res => {
            const data = res?.data;
            if(data) {
                dispatch(success(data));
                //Neu Question Data null thi call api actFetchInstructionRequest vs show toast
                if(data?.Question === null && task) {
                  dispatch(actFetchInstructionRequest(idParam, task));
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: questionConstants.QUESTION_REQUEST, isLoading } }
    function success(data) { return { type: questionConstants.QUESTION_SUCCESS, payload: data } }
    function failure(error) { return { type: questionConstants.QUESTION_FAILURE, error: error } }
}


//Fetch Instruction
export const actFetchInstructionRequest = (idParam, task) => {
    return dispatch => {
        return apiCaller(`/api/learn/case/assistant/${idParam}/?task=${task}`, 'GET', null).then(res => {
            const data = res?.data;
            const dataAlert = data?.alert;
            const alertSeverity = dataAlert?.Severity;
            // const alertDuration = dataAlert?.ShowDuration;
            if (res?.status === 200) {
                // Neu Question la null thi moi hien thi toast
                if (data?.status === "instruction") {
                    if (alertSeverity === "success") {
                        toast.success(<Toasts data={dataAlert} />, {
                            autoClose: false,
                            toastId: 'actFetchInstructionRequest'
                        });
                    }
                    if (alertSeverity === "warning") {
                        toast.warning(<Toasts data={dataAlert} />, {
                            autoClose: false,
                            toastId: 'actFetchInstructionRequest'
                        });
                    }
                    if (alertSeverity === "danger") {
                        toast.error(<Toasts data={dataAlert} />, {
                            autoClose: false,
                            toastId: 'actFetchInstructionRequest'
                        });
                    }
                }
                // Check Missing Task
            } else {
                console.log(res);
            }
        });
    };
}

// Case - Load All Case Questions
export const fetchAllQuestionsForElement = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_REQUEST, isLoading } }
    function success(data) { return { type: questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_SUCCESS, payload: data } }
    function failure(error) { return { type: questionConstants.LOAD_ALL_QUESTION_FOR_ELEMENT_FAILURE, error: error } }
}

// Remove - Add Question from Question Bank
export const updateStatusQuestionBank = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/question/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: questionConstants.UPDATE_QUESTION_BANK_REQUEST, isLoading } }
    function success(data) { return { type: questionConstants.UPDATE_QUESTION_BANK_SUCCESS, payload: data } }
    function failure(error) { return { type: questionConstants.UPDATE_QUESTION_BANK_FAILURE, error: error } }
}

export const resetChatRecordForQuestion = () => {
    return {
      type: questionConstants.RESET_CHAT_RECORD_FOR_QUESTION,
    };
};