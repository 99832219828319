import React from "react";

//Icon
import { Iconsax } from "@components-common";
import { Button } from "reactstrap";

function BtnReviewErm(props) {
    const { handleOpenErmViewFixed } = props && props;
    return (
        <Button
            type="button"
            className="btn-warning text-1 justify-content-center px-2 mx-1"
            onClick={handleOpenErmViewFixed}
            color="warning"
        >
            <Iconsax iconName="document" fill="#fff" size={25} />
            <span className="ml-2">Review</span>
        </Button>
    );
}

export default BtnReviewErm;