import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { caseConstants } from "@constants";

//react-router
import { useParams } from "react-router-dom";

// common
import { Iconsax } from '@components-common';

//boostrap
import { FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";

// components
import { BtnAddRow } from "@ui-partents/Button";
import { SwitchItem } from "@ui-partents/SwitchButton";

//JoditEditor
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

// validation schema
const validationSchema = Yup.object().shape({
    overview: Yup.string().required("Overview is required"),
    sections: Yup.array().of(
        Yup.object().shape({
            Title: Yup.string().required("Title is required"),
            Content: Yup.string().required("Content is required"),
        })
    ).test('has-empty-fields', 'At least one field is required', function (value) {
        const emptyFieldIndex = value.findIndex(obj => {
            return Object.values(obj).some(field => {
                return field === '' || field === null || field === undefined;
            });
        });
        return emptyFieldIndex === -1; // Pass the test if no empty field is found
    })
});

const TakeHomeMessage = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { teachCaseData } = props || {};
    const TakeHomeMessageSections = teachCaseData?.TakeHomeMessageSections;
    const overviewDefaultValue = teachCaseData?.TakeHomeMessage || "";
    const editor = useRef(null);

    //state
    const [sections, setSections] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setSections(TakeHomeMessageSections || []);
    }, [TakeHomeMessageSections]);

    const handleChange = (index, newContent) => {
        const updatedSections = [...sections];
        updatedSections[index].Content = newContent;
        setSections(updatedSections);
    };

    const handleTitleChange = (index, event) => {
        const { value } = event.target;
        const updatedSections = [...sections];
        updatedSections[index].Title = value;
        setSections(updatedSections);
    };

    const handleAddSection = () => {
        setSections([...sections, { Id: null, Title: "", Content: "" }]);
    };

    const handleDeleteSection = (index) => {
        const updatedSections = [...sections];
        updatedSections.splice(index, 1);
        setSections(updatedSections);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        // Perform API call to submit data
        const params = {
            "Action": "Update",
            "Target": "TakeHomeMessage",
            "Message": values?.overview,
            "Sections": sections
        }

        // Case - Update Take-Home Message
        setLoading(true);
        dispatch({ type: caseConstants.TAKE_HOME_MESSAGE_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if (res?.status === 200) {
                dispatch({
                    type: caseConstants.TAKE_HOME_MESSAGE_SUCCESS,
                    payload: data
                });
                setLoading(false);
                setSubmitting(false);
            } else {
                dispatch({
                    type: caseConstants.TAKE_HOME_MESSAGE_FAILURE,
                    error: "error",
                });
                setLoading(false);
                setSubmitting(false);
            }
        });
    };
    
    return (
        <Formik
            initialValues={{ overview: overviewDefaultValue, sections: sections }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnChange={false}
            enableReinitialize
        >
            {({ errors, isSubmitting, setFieldValue }) => {
                return (
                    <Form>
                        <SwitchItem
                            label={`Enable Take-home Message in Learner Interface`}
                            name={`ChatToolbar`}
                            // value={values.ChatToolbar}
                            handleChangeSwitch={(e) => setFieldValue('ChatToolbar', e.target.checked)}
                        />
                        <div className="card-area bg-grey mb-4">
                            <FormGroup>
                                <Label className="labeForm">
                                    Overview
                                </Label>
                                <JoditEditor
                                    ref={editor}
                                    value={overviewDefaultValue}
                                    config={Config.CONFIG_JODIT}
                                    onChange={(newContent) => setFieldValue('overview', newContent)}
                                />
                                {errors?.overview && (
                                    <FormFeedback className="d-block">{errors?.overview}</FormFeedback>
                                )}
                            </FormGroup>
                        </div>
                        {sections?.map((section, index) => (
                            <div key={index} className="card-area bg-grey mb-4">
                                <FormGroup>
                                    <Label for={`Title-${index}`} className="labeForm">
                                        Section Title {index + 1}:
                                    </Label>
                                    <Field
                                        id={`Title-${index}`}
                                        name={`sections[${index}].Title`}
                                        placeholder=""
                                        type="text"
                                        as={Input}
                                        value={section.Title || ""}
                                        onChange={(event) => handleTitleChange(index, event)}
                                    />
                                    {errors.sections && errors.sections[index] && errors.sections[index].Title && (
                                        <FormFeedback className="d-block">{errors.sections[index].Title}</FormFeedback>
                                    )}
                                </FormGroup>
                                <JoditEditor
                                    ref={editor}
                                    value={section.Content || ""}
                                    config={Config.CONFIG_JODIT}
                                    tabIndex={index + 1} // tabIndex of textarea
                                    onChange={(newContent) => handleChange(index, newContent)}
                                />
                                {errors.sections && errors.sections[index] && errors.sections[index].Content && (
                                    <FormFeedback className="d-block">{errors.sections[index].Content}</FormFeedback>
                                )}
                                <div className="btn-close-box" onClick={() => handleDeleteSection(index)}>
                                    <Iconsax iconName={`close-box`} fill="#323949" size={20} />
                                </div>
                            </div>
                        ))}
                        <div className="text-right mb-4">
                            {sections?.length > 0 && (
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    color="primary"
                                    size="lg"
                                    className="px-5"
                                >
                                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                    Save
                                </Button>
                            )}
                        </div>
                        <BtnAddRow handleClickAddRow={handleAddSection} textBtn={`Add Section`} />
                    </Form>
                )
            }}
        </Formik>
    );
};

export default TakeHomeMessage;