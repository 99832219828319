import React from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

function GroupFilter({ templateData, onChange, placeholder, value }) {
  const convertDataToReactSelect = (data) => {
    const options = Object.keys(data).map(groupName => ({
      value: groupName,
      label: groupName
    }));

    // Add an option to show all groups
    options.unshift({ value: 'all', label: 'Show All Groups' });

    return options;
  };

  const options = templateData?.Groups ? convertDataToReactSelect(templateData?.Groups) : [];
  
  return (
    <FormGroup>
      <Label>Select a Group:</Label>
      <Select
        options={options}
        closeMenuOnSelect={true}
        onChange={(option) => onChange(option, "selectedGroup")}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
}

export default GroupFilter;