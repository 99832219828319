import React from 'react';

//reactstrap
import { TabContent, TabPane } from "reactstrap";

//common components
import { HeadTabs } from "../../storyboard/common";

//RichTextCommon
import RichTextCommon from "../RichTextCommon";
import { DragQuestion } from "../../storyboard/Question";

function HistoryMain(props) {
    const { value, activeTabCt, listTabs, handleUpdateEditor, valueEditor, timePointId, filterQuestionForTask } = props && props;
    return (
        <React.Fragment>
            <HeadTabs
                {...props}
                title={value?.Label}
                task='history'
            />
            <TabContent activeTab={activeTabCt}>
                {listTabs?.history.map(
                    (item, index) =>
                        item.show && (
                            <TabPane key={index} tabId={`${index + 1}`}>
                                {item?.id === "1" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={`narrative`}
                                        defaultValue={valueEditor?.narrative}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item?.id === "2" && (
                                    <RichTextCommon
                                        handleUpdateEditor={handleUpdateEditor}
                                        task={value?.ViewClass}
                                        defaultValue={value?.Instructions}
                                        placeholder="Enter the instructions for the learner here..."
                                        timePointId={timePointId}
                                    />
                                )}
                                {item?.id === "3" && (
                                    <DragQuestion
                                        {...props}
                                        task={value?.ViewClass}
                                        data={filterQuestionForTask(value?.ViewClass)}
                                    />
                                )}
                            </TabPane>
                        )
                )}
            </TabContent>
        </React.Fragment>
    );
};

export default HistoryMain;