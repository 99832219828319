import React from "react";
import { Scrollbar } from "react-scrollbars-custom";

//reactstrap
import { Table } from "reactstrap";

function IOBalance(props) {
  //props
  const { data } = props && props;

  //IOBalanceData
  const Intake = data?.Intake;
  const NetBalance = data?.NetBalance;
  const Output = data?.Output;
  const TotalIntake = data?.TotalIntake;
  const TotalOutput = data?.TotalOutput;

  const todoItemsIntake = Intake?.map((value, index) =>
    <tr key={index}>
      <th width="50%" className="font-weight-500">
        {value?.Name}
      </th>
      <td width="50%" className="text-left">
        <span>{value?.CurrentValue} {value?.Unit}</span>
      </td>
    </tr>
  );

  const todoItemsOutput = Output?.map((value, index) =>
    <tr key={index}>
      <th width="50%" className="font-weight-500">
        {value?.Name}
      </th>
      <td width="50%" className="text-left">
        <span>{value?.CurrentValue} {value?.Unit}</span>
      </td>
    </tr>
  );
  
  return (
    <div className="boxContent -hasBg">
      <Scrollbar className="scrollbarCustom" style={{ height: 460 }}>
        <Table borderless responsive size="sm">
          <tbody>
            {todoItemsIntake}
            <tr>
              <th width="50%" className="font-weight-500">
                <span className="m--font-success font-weight-500">Total Intake</span>
              </th>
              <td width="50%">
                <span className="m--font-success font-weight-500">{TotalIntake} unit</span>
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />
        <Table borderless responsive size="sm">
          <tbody>
            {todoItemsOutput}
            <tr>
              <th width="50%" className="font-weight-500">
                <span className="m--font-danger font-weight-500">Total Output</span>
              </th>
              <td width="50%">
                <span className="m--font-danger font-weight-500">{TotalOutput} unit</span>
              </td>
            </tr>
          </tbody>
        </Table>
        <hr />
        <Table borderless responsive size="sm">
          <tbody>
            <tr>
              <th width="50%" className="font-weight-500">
                <span className="m--font-brand font-weight-500">Net Balance</span>
              </th>
              <td width="50%">
                <span className="m--font-brand font-weight-500">
                  {parseFloat(NetBalance) > 0  ? `+ ${NetBalance}` : `${NetBalance}`}{` `} unit
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </Scrollbar>
    </div>
  );
}

export default IOBalance;
