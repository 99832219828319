export const seriesConstants = {
    SERIES_REQUEST: 'SERIES_REQUEST',
    SERIES_SUCCESS: 'SERIES_SUCCESS',
    SERIES_FAILURE: 'SERIES_FAILURE',

    CREATE_SERIES_REQUEST: 'CREATE_SERIES_REQUEST',
    CREATE_SERIES_SUCCESS: 'CREATE_SERIES_SUCCESS',
    CREATE_SERIES_FAILURE: 'CREATE_SERIES_FAILURE',

    DELETE_SERIES_REQUEST: 'DELETE_SERIES_REQUEST',
    DELETE_SERIES_SUCCESS: 'DELETE_SERIES_SUCCESS',
    DELETE_SERIES_FAILURE: 'DELETE_SERIES_FAILURE',

    DELETE_CASE_FROM_SERIES_REQUEST: 'DELETE_CASE_FROM_SERIES_REQUEST',
    DELETE_CASE_FROM_SERIES_SUCCESS: 'DELETE_CASE_FROM_SERIES_SUCCESS',
    DELETE_CASE_FROM_SERIES_FAILURE: 'DELETE_CASE_FROM_SERIES_FAILURE',

    SERIES_ADD_MANAGERS_REQUEST: 'SERIES_ADD_MANAGERS_REQUEST',
    SERIES_ADD_MANAGERS_SUCCESS: 'SERIES_ADD_MANAGERS_SUCCESS',
    SERIES_ADD_MANAGERS_FAILURE: 'SERIES_ADD_MANAGERS_FAILURE',

    SERIES_REMOVE_MANAGERS_REQUEST: 'SERIES_REMOVE_MANAGERS_REQUEST',
    SERIES_REMOVE_MANAGERS_SUCCESS: 'SERIES_REMOVE_MANAGERS_SUCCESS',
    SERIES_REMOVE_MANAGERS_FAILURE: 'SERIES_REMOVE_MANAGERS_FAILURE'
};
