import { themeConstants, assistantConstant } from "@constants";

export function theme (state = {}, action) {
    var newState = {};
    switch (action.type) {
        case themeConstants.THEME_REQUEST:
            return { 
                ...state,
                isLoading: true 
            };
        case themeConstants.THEME_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case themeConstants.LOAD_THEME_STATE:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case assistantConstant.UPDATE_ASSISTANT_SUCCESS:
            newState = { ...state };
            newState.data.Assistant = action.payload.Assistant;
            const themeData = JSON.stringify(newState && newState.data);
            if(themeData) {
                localStorage.setItem("themeData", themeData);
            }
            return {
                ...newState,
                isLoading: false
            };
        case themeConstants.THEME_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};