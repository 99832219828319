export const steps = [
  {
    selector: '.first-step',
    title: 'Title Guide',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 1</h1>
        <p>Enter your instructions, including the topic, learning objectives, target learners, or any specific details you want the AI to consider.</p>
      </div>
    ),
  },
  {
    selector: '.second-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 1</h1>
        <p>Check out our example here!</p>
      </div>
    ),
  },
  {
    selector: '.third-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 2</h1>
        <p>Select a template from this list. The AI will use this template as a guide for creating your MCQ.</p>
      </div>
    ),
  },
  {
    selector: '.four-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 3</h1>
        <p>Upload any relevant files, such as lectures or reference materials, to provide the AI with more context for generation.</p>
      </div>
    ),
  },
  {
    selector: '.fifth-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 4</h1>
        <p>Click here to generate and watch the magic happen in just a few seconds!</p>
      </div>
    ),
  },
]

export const stepsScenario = [
  {
    selector: '.first-step',
    title: 'Title Guide',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 1</h1>
        <p>Enter your requirements for case generation, including the scenario, learning objectives, target learners, or any specific details you want the AI to consider.</p>
      </div>
    ),
  },
  {
    selector: '.second-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 1</h1>
        <p>Check out our example here!</p>
      </div>
    ),
  },
  {
    selector: '.third-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 2</h1>
        <p>Select a template from this list. The AI will use this template as a guide for creating your case scenario.</p>
      </div>
    ),
  },
  {
    selector: '.four-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 3</h1>
        <p>Upload any relevant files, such as lectures or reference materials, to provide the AI with more context for generation.</p>
      </div>
    ),
  },
  {
    selector: '.fifth-step',
    content: ({ goTo, inDOM }) => (
      <div className="tour__component">
        <h1>Step 4</h1>
        <p>Click here to generate and watch the magic happen in just a few seconds!</p>
      </div>
    ),
  },
]