import React, { useState, useEffect } from 'react';

//boostrap
import {
    Row,
    Col
} from "reactstrap";

//truncate
import { truncate } from "@utils";

function PriorNotes(props) {

    //props
    const { data, isOpen, heightPatientMedRecord } = props && props;

    //state
    const [stateData, setData] = useState(data || []);
    const [activeItem, setActiveItem] = useState(0);
    const [itemData, setItemData] = useState((data && data[0]) || {});

    //loadData
    useEffect(() => {
        setData(data || []);
        setItemData((data && data[0]) || {});
    }, [data]);

    //Click Item
    const handleClickItem = (value, index) => {
        setActiveItem(index)
        setItemData(value);
    }

    const listItems = stateData?.map((value, index) =>
        <li onClick={() => handleClickItem(value, index)} className={`PriorNotesList__item border-bottom p-2 ${activeItem === index ? "--bg-primary" : ""} ${isOpen ? "" : "d-flex align-items-center"}`} style={{ minHeight: '60px' }} key={index}>
            <h3 className={`${isOpen ? "mb-2" : "mb-0"}  font-weight-500'`}>
                {isOpen ? value?.Title : truncate(value?.Title, 50)}
                <span className="d-block mt-2 text-1"><i className="fa fa-calendar-o mr-2" aria-hidden="true"></i>{value?.Date}</span>
            </h3>
            <div className={`${isOpen ? "d-flex" : "d-none"} align-items-center`}>
                <div className='w-50 text-1 text-muted'>{value?.Type || ''}</div>
                <div className='w-50 text-1 text-right text-muted'>{value?.Date || ''}</div>
            </div>
        </li>
    );
    
    return (
        <div className="PriorNotesListCt">
            {stateData?.length > 0 ? (
                <Row>
                    <Col md={`4`} className="border-right pr-0">
                        <div className="isShowScroll" style={{ maxHeight: `${heightPatientMedRecord}px`}}>
                            <ul className="PriorNotesList">
                                {listItems}
                            </ul>
                        </div>
                    </Col>
                    <Col md={`8`} className='p-3'>
                        <div className="isShowScroll" style={{ maxHeight: `${heightPatientMedRecord}px`, paddingBottom: '50px' }}>
                            <div dangerouslySetInnerHTML={{ __html: itemData?.Contents || '' }} />
                        </div>
                    </Col>
                </Row>
            ) : (
                <h6 className="text-center my-5">
                    There are no prior notes for this encounter
                </h6>
            )}
        </div>
    );
};

export default PriorNotes;