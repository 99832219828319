import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, FormFeedback } from 'reactstrap';
import ClipLoader from "react-spinners/ClipLoader";
import { useLocation, useHistory } from 'react-router-dom';
import { apiCaller } from "@utils";
import OTPInput from './OTPInput';

//alert
import { toast } from 'react-toastify';

const OTPSchema = Yup.object().shape({
    otp0: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
    otp1: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
    otp2: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
    otp3: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
    otp4: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
    otp5: Yup.string().matches(/^\d{1}$/, 'Invalid digit').required('Required'),
});

const OTPForm = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [cooldown, setCooldown] = useState(30); // Initialize cooldown to 30 seconds
    const [resendDisabled, setResendDisabled] = useState(true); // Initially disable resend button
    const [successAnimation, setSuccessAnimation] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');

    const formik = useFormik({
        initialValues: {
            otp0: '',
            otp1: '',
            otp2: '',
            otp3: '',
            otp4: '',
            otp5: '',
        },
        validationSchema: OTPSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setFormError(null);
        
            const otp = Object.values(values).join('');
            const params = {
                Email: emailParam,
                OTP: otp,
                Action: 'CheckOTP'
            };
        
            try {
                const response = await apiCaller('/api/auth/reset_password/', "POST", params);
                const responseData = response?.data;
        
                if (responseData?.status === "OK") {
                    setSuccessAnimation(true);
                    setTimeout(() => {
                        history.push(`/auth/set-new-password?email=${emailParam}&otp-code=${otp}`);
                    }, 2000);
                } else {
                    setLoading(false);
                    setFormError(responseData?.message);
                }
            } catch (error) {
                setLoading(false);
                setFormError("An error occurred while verifying OTP. Please try again.");
            }
        }        
    });

    useEffect(() => {
        if (cooldown > 0) {
            const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
            setResendDisabled(true);
            return () => clearTimeout(timer);
        } else {
            setResendDisabled(false);
        }
    }, [cooldown]);

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        // Check if input value is a single digit
        if (/^\d$/.test(value)) {
            // Update formik values
            formik.setFieldValue(`otp${index}`, value);

            // Move focus to next input if current input is filled
            if (index < 5 && value) {
                document.getElementById(`otp${index + 1}`).focus();
            }
        } else if (value === '' || /^\d$/.test(value)) {
            // Clear formik value if empty or non-digit is entered
            formik.setFieldValue(`otp${index}`, '');
        }
    };

    const handleKeyDown = (event, index) => {
        // Move focus to previous input on Backspace if current input is empty
        if (event.key === 'Backspace' && !formik.values[`otp${index}`] && index > 0) {
            // Prevent default Backspace behavior (like navigating back in browser)
            event.preventDefault();

            // Clear previous input value and focus
            formik.setFieldValue(`otp${index - 1}`, '');
            document.getElementById(`otp${index - 1}`).focus();
        }
    };

    const handleResendCode = async () => {
        setFormError(null);
        setLoading(true);
        try {
            const params = {
                Email: emailParam,
                Action: 'SendOTP'
            };
            const response = await apiCaller('/api/auth/reset_password/', "POST", params);
            const responseData = response?.data;
            if (responseData?.status === "OTP_sent") {
                toast.success("A new OTP has been sent to your email.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
                setCooldown(30); // Reset cooldown to 30 seconds

                // Clear the OTP inputs
                formik.resetForm();
                // Focus the first OTP input
                document.getElementById('otp0').focus();
            } else {
                setFormError('An error occurred while resending OTP. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            setFormError('An error occurred while resending OTP. Please try again.');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (successAnimation) {
            const timer = setTimeout(() => {
                setSuccessAnimation(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [successAnimation]);

    return (
        <div className="wrap-login">
            <div className="wrap-login__title">
                <h1>Verification Code Sent</h1>
                <p className='text-center'>
                    We've just sent a 6-digit code to your email. <br />Please enter it to proceed.
                </p>
            </div>
            <div className="wrap-login__form">
                <form className='form-custom' onSubmit={formik.handleSubmit}>
                    <div className='form-custom__wrap'>
                        <OTPInput
                            formik={formik}
                            handleInputChange={handleInputChange}
                            handleKeyDown={handleKeyDown}
                            successAnimation={successAnimation}
                        />
                    </div>
                    {formError && (
                        <FormFeedback className="d-block text-center" dangerouslySetInnerHTML={{ __html: formError }}></FormFeedback>
                    )}
                    <div className="resend-code">
                        {cooldown > 0 && (
                            <span>Resend code in {Math.floor(cooldown / 60).toString().padStart(2, '0')}:{(cooldown % 60).toString().padStart(2, '0')}</span>
                        )}
                    </div>
                    <div className='d-flex align-items-center justify-content-center'>
                        <Button
                            type="submit"
                            color='primary'
                            className='w-50 py-3 d-flex align-items-center justify-content-center'
                            disabled={loading}
                        >
                            Submit
                            {loading && (
                                <div className='ml-1 d-flex align-items-center'>
                                    <ClipLoader color="#FFF" size={16} />
                                </div>
                            )}
                        </Button>
                    </div>
                </form>
            </div>
            <div className="redirect-page">
                <p>
                    Not in inbox or spam folder?
                    <span
                        className='ml-2'
                        style={{ cursor: resendDisabled ? 'not-allowed' : 'pointer', color: resendDisabled ? 'grey' : '' }}
                        onClick={!resendDisabled ? handleResendCode : null}
                    >
                        Resend code
                    </span>
                </p>
            </div>
        </div>
    );
};

export default OTPForm;