import React from "react";

//boostrap
import { Col, Button, FormGroup, Label } from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//components
import { FeedbackContent } from "../../teach/TestAI";

function TestQuestionAIPage(props) {
    const { handleChange, stateAnswer, handleSubmit, isLoading } = props && props;
    return (
        <Col md={6}>
            <Label for="Comment">&nbsp;</Label>
            <FormGroup>
                <TextareaAutosize
                    name="Answer"
                    placeholder="Enter your text..."
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={8}
                    onChange={handleChange}
                    defaultValue={stateAnswer}
                />
            </FormGroup>
            <div className="text-right">
                <Button
                    className="btn --btn-primary mt-3 ml-auto"
                    onClick={handleSubmit}
                    disabled={(stateAnswer ? false : true) || isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Submit
                </Button>
            </div>
            <FeedbackContent {...props} />
        </Col>
    );
}

export default TestQuestionAIPage;