import React, { useState } from "react";
import { TabComponentBorder } from "@ui-partents/Tabs";

//components
import { InteractionScriptTab, InstantFeedbackTab, ChatWrapupInstructions, TestVirtualPatientTab } from "./GenerativeAiComponents";

const GenerativeAI = (props) => {
    // State
    const [activeTab, setActiveTab] = useState(1);

    // Tabs data
    const tabData = [
        { key: 1, label: "Interaction Script", content: <InteractionScriptTab {...props} /> },
        { key: 2, label: "Instant Feedback Instructions", content: <InstantFeedbackTab {...props} /> },
        { key: 3, label: "Chat Wrap-up Instructions", content: <ChatWrapupInstructions {...props} /> },
        { key: 4, label: "Test Virtual Patient", content: <TestVirtualPatientTab {...props} /> }
    ];

    // Handle tab change
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    // Map tabs
    const tabs = tabData.map((tab) => ({
        id: tab.key,
        title: tab.label,
        content: tab.content
    }));

    return (
        <TabComponentBorder
            tabs={tabs || []}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            theme={"fill"}
        />
    );
};

export default GenerativeAI;