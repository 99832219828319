import React, { useState, useEffect, useRef } from 'react';

//components
import { InputSuggest } from '../../../access/Teams/AddRemoveTable';
import { EditCell } from '../../Teams';

//useParams
import { useParams } from "react-router-dom";

//apiCaller
import { apiCaller } from "@utils";

//boostrap
import { Spinner } from "reactstrap";

function TableRows({ rowsData, hanldeModal, isLoading }) {
    let { id } = useParams();

    //state
    const [isEdit, setEdit] = useState({});
    const [stateData, setData] = useState(rowsData || []);
    const [isLoadingEdit, setLoadingEdit] = useState({});

    //load Data
    useEffect(() => {
        setData(rowsData || []);
    }, [rowsData])

    //Click outSide
    const ref = useRef();
    useOnClickOutside(ref, () => {
        const removeIdx = ref.current.getAttribute("data-index");
        setEdit({ [removeIdx]: false });
        callApiUpdate(stateData, removeIdx);
    });

    //edit cell
    const handleEditCell = (index) => {
        setEdit({ [index]: true });
    }

    //Update name
    const handleOnKeyUp = (event, index) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            callApiUpdate(stateData, index);
        }
    }

    const callApiUpdate = (data, index) => {
        setEdit({ [index]: false });
        setLoadingEdit({ [index]: true });
        const params = {
            "Action": "Update",
            "Target": "Team",
            "TeamId": data[index]?.TeamId,
            "TeamName": data[index]?.TeamName
        }
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                setData(data);
                setLoadingEdit({ [index]: false });
            } else {
                console.log('error');
                setLoadingEdit({ [index]: false });
            }
        });
    }

    //hanlde Change
    const handleChange = (evnt, index) => {
        const { name, value } = evnt.target;
        let arrCopy = [...rowsData];
        arrCopy[index][name] = value;
    }

    return (
        stateData?.map((data, index) => {
            const { Roles, TeamId } = data;
            return (
                <tr key={index}>
                    <td className="align-middle">
                        {isEdit[index] ?
                            <div ref={ref} data-index={index}>
                                <EditCell
                                    data={data || {}}
                                    handleOnKeyUp={handleOnKeyUp}
                                    handleChange={(e) => handleChange(e, index)}
                                    index={index}
                                />
                            </div> :
                            <div onClick={() => handleEditCell(index)}>
                                {isLoadingEdit[index] ? (
                                    <div className="d-flex align-items-center">
                                        <Spinner className='--text-primary' size="sm">Updating...</Spinner>
                                        <span className="ml-2 --text-primary">Updating...</span>
                                    </div>
                                ) : (
                                    data?.TeamName
                                )}
                            </div>
                        }
                    </td>
                    {Roles?.map((value, index) =>
                        <td className="align-middle" key={index}>
                            <InputSuggest
                                TeamId={TeamId}
                                item={value || {}}
                                isLoading={isLoading}
                            />
                        </td>
                    )}
                    <td className="align-middle" width={50}>
                        <button
                            className="btn btn-danger"
                            onClick={() => (hanldeModal('Delete', data))}
                            disabled={isLoading}
                        >
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </td>
                </tr>
            )
        })
    )
}

export default TableRows;

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        }, [ref, handler]
    );
}