import React from "react";

//Boostrap
import {
  Row,
  Col
} from "reactstrap";

// Components
import { AiLabelContentCompare, HumanLabelContent } from "../ReliDiagnosis";

function CompareLabel(props) {
  return (
    <Row>
      <Col md={6} className="border-right">
        <h3 className="mb-4 text-4">Human</h3>
        <HumanLabelContent {...props} divContainer="htmlHumanLabelTextCompare" />
      </Col>
      <Col md={6}>
        <h3 className="mb-4 text-4">AI</h3>
        <AiLabelContentCompare {...props} />
      </Col>
    </Row>
  );
}

export default CompareLabel;