import React from 'react';
import { Link } from "react-router-dom";

function MegaMenu(props) {
    const { data, idCase } = props && props;

    const listItems = (data) => {
        return (
            <React.Fragment>
                {data?.map((item, index) => {
                    return (
                        <li className={`subNav__item`} key={index}>
                            <Link className="subLink" to={`${item.path}/${idCase}`}>
                                <i
                                    className={`${item.icon || ""} mr-2`}
                                    aria-hidden="true"
                                ></i>
                                {item.name}
                            </Link>
                        </li>
                    )
                })}
            </React.Fragment>
        );
    }

    return (
        <div className="subNav rounded-0">
            <div className="px-0 container">
                <div className="row w-100">
                    {data?.map((item, index) => {
                        const isHeader = item?.isHeader;
                        const menuGroup = item?.menuGroup;
                        return (
                            <ul className={`col-md-3 ${index !== data.length - 1 ? "border-right" : ""}`} key={index}>
                                <li
                                    className={`subNav__item ${isHeader ? "dr-header" : ""}`}
                                    key={index}
                                >
                                    <span>{item?.name}</span>
                                    <ul>
                                        {listItems(menuGroup)}
                                    </ul>
                                </li>
                            </ul>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MegaMenu;