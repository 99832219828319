export const paymentConstant = {
    LAUNCH_STRIPE_SET_UP_CREDIT_CARD_REQUEST: 'LAUNCH_STRIPE_SET_UP_CREDIT_CARD_REQUEST',
    LAUNCH_STRIPE_SET_UP_CREDIT_CARD_SUCCESS: 'LAUNCH_STRIPE_SET_UP_CREDIT_CARD_SUCCESS',
    LAUNCH_STRIPE_SET_UP_CREDIT_CARD_FAILURE: 'LAUNCH_STRIPE_SET_UP_CREDIT_CARD_FAILURE',

    SET_DEFAULT_PAYMENT_METHOD_REQUEST: 'SET_DEFAULT_PAYMENT_METHOD_REQUEST',
    SET_DEFAULT_PAYMENT_METHOD_SUCCESS: 'SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    SET_DEFAULT_PAYMENT_METHOD_FAILURE: 'SET_DEFAULT_PAYMENT_METHOD_FAILURE',

    SET_AUTOMATIC_RENEW_REQUEST: 'SET_AUTOMATIC_RENEW_REQUEST',
    SET_AUTOMATIC_RENEW_SUCCESS: 'SET_AUTOMATIC_RENEW_SUCCESS',
    SET_AUTOMATIC_RENEW_FAILURE: 'SET_AUTOMATIC_RENEW_FAILURE'
 };