import React from "react";

function PlanList(props) {
  const { handleOnClick, activeIndex } = props && props;
  return (
    <React.Fragment>
      <div className="listPlan">
        <div
          className={`listPlan__item ${
            activeIndex === "Pilot" ? "active" : ""
          }`}
          onClick={() => handleOnClick("Pilot")}
        >
          <div className="check-wrapper">
            <div className="check-circle">
              <i className="fa fa-check check-white"></i>
            </div>
          </div>
          <div className="pricingCt">
            <div className="pricingCt__type">Pilot</div>
          </div>
          <div className="pricing-tab-column">
            <div className="tab-price-wrap flex-column flex-lg-row flex-wrap">
              <div className="price-tag">7.99 USD</div>
              <div className="date-text">per user, billed monthy</div>
              <div className="date-text opacity- mt-1">(First month FREE)</div>
            </div>
          </div>
        </div>
        <div
          className={`listPlan__item ${
            activeIndex === "Curriculum" ? "active" : ""
          }`}
          onClick={() => handleOnClick("Curriculum")}
        >
          <div className="check-wrapper">
            <div className="check-circle">
              <i className="fa fa-check check-white"></i>
            </div>
          </div>
          <div className="pricingCt">
            <div className="pricingCt__type">Curriculum</div>
          </div>
          <div className="pricing-tab-column">
            <div className="tab-price-wrap flex-column flex-lg-row flex-wrap">
              <div className="price-tag">499.00 USD</div>
              <div className="date-text">billed monthy</div>
              <div className="date-text opacity- mt-1">(First month FREE)</div>
            </div>
          </div>
        </div>
        <div
          className={`listPlan__item ${
            activeIndex === "Institution" ? "active" : ""
          }`}
          onClick={() => handleOnClick("Institution")}
        >
          <div className="check-wrapper">
            <div className="check-circle">
              <i className="fa fa-check check-white"></i>
            </div>
          </div>
          <div className="pricingCt">
            <div className="pricingCt__type">Institution</div>
          </div>
          <div className="pricing-tab-column">
            <div className="tab-price-wrap flex-column flex-lg-row flex-wrap">
              <div className="price-tag">Contact Us</div>
              <div className="date-text"></div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PlanList;
