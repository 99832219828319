import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

//components
import {
  AnswerItems,
  CorrectItems,
  ExplanationItem,
  ScoreItem,
} from "../AnswerGroup";

function TableAnswerDropdown(props) {
  const { answerData, handleAddAnswerGroups, handleRemoveAnswerGroup } = props && props;

  //state
  const [stateData, setData] = useState(answerData || []);

  useEffect(() => {
    setData(answerData || []);
  }, [answerData]);

  return (
    <React.Fragment>
      <table
        className="table table-responsive"
        style={{ display: stateData?.length === 0 ? "table" : "block" }}
      >
        <thead>
          <tr>
            <th className="font-weight-500 align-middle">#</th>
            <th
              className="font-weight-500 align-middle"
              style={{ width: "30%" }}
            ></th>
            <th className="font-weight-500 align-middle">Correct?</th>
            <th
              className="font-weight-500 align-middle"
              style={{ width: "30%" }}
            >
              Explanation
            </th>
            <th
              className="font-weight-500 align-middle"
              style={{ width: "15%" }}
            >
              Point
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stateData?.map((value, index) => {
            const AnswersGroupChild = value?.Answers;
            return (
              <tr key={index}>
                <td className="align-middle">Drop down {index + 1}:</td>
                <td className="align-middle">
                  <AnswerItems
                    {...props}
                    indexParent={index}
                    AnswersGroupChild={AnswersGroupChild || []}
                  />
                </td>
                <td className="text-center">
                  <CorrectItems
                    {...props}
                    indexParent={index}
                    AnswersGroupChild={AnswersGroupChild || []}
                  />
                </td>
                <td className="align-middle">
                  <ExplanationItem
                    {...props}
                    currentObject={value || {}}
                    indexParent={index}
                  />
                </td>
                <td className="align-middle">
                  <ScoreItem
                    {...props}
                    currentObject={value || {}}
                    indexParent={index}
                  />
                </td>
                <td className="align-middle">
                  <i
                    style={{ cursor: "pointer" }}
                    className="fa fa-2x fa-times text-danger"
                    aria-hidden="true"
                    onClick={() => handleRemoveAnswerGroup(index)}
                  ></i>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Button
        className="btn btn-outline-brand d-block mx-auto mt-4"
        onClick={handleAddAnswerGroups}
      >
        <i className="fa fa-plus" />
        &nbsp; Add Dropdown
      </Button>
    </React.Fragment>
  );
}

export default TableAnswerDropdown;