import React, { useState } from "react";
import { Alert } from "reactstrap";

function NotificationList(props) {
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);

  //props
  const { value } = props && props;
  return (
    <Alert color={value.Color} isOpen={visible} toggle={onDismiss} fade={false}>
      {value.Title &&
        <h4 className={`alert-heading ${value.Contents ? "mb-2" : "my-2"}`}>{value.Title}</h4>
      }
      {value.Contents &&
        <p dangerouslySetInnerHTML={{ __html: value.Contents }} />
      }
    </Alert>
  );
}

export default NotificationList;
