import React from "react";

function AnswerText(props) {
  const { data, FeedbackType } = props && props;
  return (
    <div className="answerText">
      {FeedbackType !== "dialog" &&
        <p><strong className="font-weight-600">Your Answer:</strong></p>
      }
      <div
        className="answerText__content whiteSpace-break"
        dangerouslySetInnerHTML={{ __html: data || '' }}
      ></div>
    </div>
  );
}

export default AnswerText;
