import React from 'react';
import _ from 'lodash';

const QuestionListItem = ({ item }) => {
    const showNameByType = (type) => {
        let string = "";
        switch (type) {
            case "multiple_choice":
                string = "(Multiple Choice)";
                break;
            case "multiple_choice_table":
                string = "(Multiple Choice Table)";
                break;
            case "free_text":
                string = "(Free Text)";
                break;
            case "drag_n_drop":
                string = "(Drag And Drop)";
                break;
            case "drop_down":
                string = "(Drop Down)";
                break;
            case "highlight":
                string = "(Highlight)";
                break;
            default:
                string = "(Drag and drop Cloze)";
        }
        return string;
    };

    const gradingSchemaOptions = [
        // Danh sách các tùy chọn
        {
            value: 'null',
            label: 'None (using regular expression)'
        },
        {
            value: 'concept',
            label: 'Concept Recognition'
        },
        {
            value: 'dialog',
            label: 'Guided Dialog'
        },
        // {
        //     value: 'umls',
        //     label: 'UMLS Concept'
        // },
        {
            value: 'diagnosis',
            label: 'Diagnosis Analysis'
        },
        {
            value: 'compliance',
            label: 'Risk Adjustment Documentation / Compliance'
        },
        {
            value: 'chatgpt',
            label: 'Open AI'
        },
        {
            value: 'chatgpt_voice',
            label: 'Open AI - voice'
        }
    ];

    const showNameBySchema = (type, list) => {
        const filteredData = _.find(list, { value: type });
        return <span>({filteredData?.label})</span>
    };

    return (
        <ul className="questionLs-type">
            <li className="questionLs-type__item">{showNameByType(item?.QuestionType)}</li>
            <li className="questionLs-type__item">{showNameBySchema(item?.GradingSchema, gradingSchemaOptions)}</li>
        </ul>
    );
};

export default QuestionListItem;