import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

function ListTabs(props) {
  const { activeTab, navList } = props && props;

  return (
    <Nav className="tabBorder" tabs>
      {navList?.map((value, index) => (
          <NavItem className="border-0" key={index}>
            <NavLink
              className={
                "--tab-active-text-primary " +
                classnames({ active: activeTab === ((index + 1).toString()) })
              }
              data-tour={`${value?.Tour}`}
              href={value?.Slug}
            >
              {value.Name}
            </NavLink>
          </NavItem>
        ))}
    </Nav>
  );
}

export default ListTabs;
