import React from 'react';

//common
import { DataEmty } from "@components/common";

function DiagnosisList(props) {
    const { data } = props && props;
    const listItems = data?.map((value, index) => {
        const ClinicalFeatures = value[`Clinical Features`];
        const RiskFactors = value[`Risk Factors`];
        return (
            <ul className="ml-4 mb-3" key={index}>
                <li className="font-weight-500 mb-2 --text-primary">{value?.Name || ''}</li>
                {ClinicalFeatures?.length > 0 && (
                    <li className="ml-3 mb-2">
                        <h3 className="font-weight-500 mb-2">Clinical Features:</h3>
                        <ul className="ml-2">
                            {ClinicalFeatures?.map((item, index) =>
                                <li key={index}>{item?.Name}</li>
                            )}
                        </ul>
                    </li>
                )}
                {RiskFactors?.length > 0 && (
                    <li className="ml-3 mb-2">
                        <h3 className="font-weight-500 mb-2">Risk Factors:</h3>
                        <ul className="ml-2">
                            {RiskFactors?.map((item, index) =>
                                <li key={index}>{item?.Name}</li>
                            )}
                        </ul>
                    </li>
                )}
            </ul>
        );
    });
    
    return (
        <React.Fragment>
            <h2 className="font-weight-500 mb-3">DIAGNOSES</h2>
            {data?.length > 0 ? (
                {listItems}
            ): (
                <DataEmty content={`No Data`} />
            )}
        </React.Fragment>
    );
};

export default DiagnosisList;