import React from 'react';

//boostrap
import {
    FormGroup,
    Label,
    Input
} from "reactstrap";

function OptionView(props) {
    const { handleChangeRaido, active } = props && props;
    return (
        <FormGroup tag="fieldset">
            <FormGroup className="mb-3" check>
                <Label check>
                    <Input
                        type="radio"
                        name="type"
                        onChange={() => handleChangeRaido("standard")}
                        checked={active === 'standard'}
                    />{" "}
                    Standard Template
                </Label>
            </FormGroup>
            <FormGroup className="mb-3" check>
                <Label check>
                    <Input
                        type="radio"
                        name="type"
                        onChange={() => handleChangeRaido("existing")}
                        checked={active === 'existing'}
                    />{" "}
                    Existing template
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="type"
                        onChange={() => handleChangeRaido("new")}
                        checked={active === 'new'}
                    />{" "}
                    New template
                </Label>
            </FormGroup>
        </FormGroup>
    );
};

export default OptionView;