import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";

// Icons
import IconMoney from '@images/coin/icon-money.svg';

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #121926;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  white-space: nowrap;
  img {
    width: 18px;
  }
`;

const ToTalCoinMobile = () => {
  const coin = useSelector((state) => (state.getCoin && state.getCoin.data) || 0);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${isHovered ? "show-dropdown-coin" : ""}`}
    >
      <img className='mr-1' src={IconMoney} alt={`${coin?.Remaining} Credits`} />
      {coin?.Remaining} Credits
    </StyledButton>
  );
};

export default ToTalCoinMobile;