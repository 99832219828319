import React from "react";
import { Col, Button } from "reactstrap";

function HeaderPnOrder(props) {
  const { thisTimePoint, toggle, handleUpdateActions, pnorderCheck } =
    props && props;
  return (
    <div className="headQuestion border-bottom mb-2">
      <div className="d-flex align-items-center">
        <Col lg={6}>
          <h5 className="heading-5 --font-bold m--font-brand --text-primary">
            Parenteral Nutrition Ordering
          </h5>
        </Col>
        <Col
          lg={6}
          className="d-flex justify-content-md-end justify-content-sm-start"
        >
          {thisTimePoint !== "0 minutes" && (
            <Button
              className="btn-warning text-white mr-2"
              onClick={toggle}
            >
              Copy Forward
            </Button>
          )}
          <Button
            onClick={() => handleUpdateActions("check_orders")}
            disabled={pnorderCheck.isLoading}
            className="btn --btn-sm --btn-primary"
          >
            Check My Orders
          </Button>
        </Col>
      </div>
    </div>
  );
}

export default HeaderPnOrder;
