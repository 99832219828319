import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { apiCaller } from '@utils';
import IconCloseChat from '@images/learnerv2/icon-close-sp.svg';

//actions
import { toggleChatFeedbackMobile } from "@actions";

// Image
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

//redux
import { useDispatch } from "react-redux";

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid #F79E1C;
  background: #FDEBC8;
  z-index: 10000;
  position: relative;
  padding: 16px;
  margin-top: 30px;
  height: calc(100% - 170px);
  `;
  
  const Title = styled.h1`
  color: #F1800F;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
`;

const Content = styled.div`
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 16000;
  padding: 16px;
  height: 100vh;
  min-height: 100dvh;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #16B364;
  background: lightgray;
  box-shadow: 0px 0px 0px 4px rgba(0, 110, 155, 0.12);
  overflow: hidden;
  margin: 0 auto;
`;

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CloseButton = styled.button`
  display: inline-flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 150px;
  background: #FFF;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

const Icon = styled.img`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 5px;
  justify-content: center;
  align-items: center;
`;

const ChatFeedBackPopup = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const intervalIDRef = useRef(null);
  const { caseData } = props && props;
  // const caseTitle = caseData?.data?.Case?.Title;
  const Interface = caseData?.data?.Case?.Interface;
  const PreceptorAvatar = Interface?.PreceptorAvatar;

  const [isLoading, setLoading] = useState(false);
  const [contentStream, setContentStream] = useState(null);
  const [feedbackContent, setFeedbackContent] = useState(null);

  const handleStreamData = (getFeedbackData) => {
    Promise.race([getFeedbackData, new Promise(resolve => setTimeout(resolve, 3000, 'timeout'))]).then(result => {
      if (result !== 'timeout') {
        return;
      }

      const params = { stream_mode: "chat" };
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/learn/case/stream_data/${id}/`, 'PUT', params).then(res => {
          if (res?.status === 200) {
            setContentStream(res.data);
          } else {
            clearInterval(intervalIDRef.current);
          }
        });
      }, 1000);
    });
  };

  useEffect(() => {
    const fetchFeedbackData = () => {
      setLoading(true);
      const params = { Action: "GetFeedback" };
      const getFeedbackData = apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then(res => {
        if (res?.status === 200 && res.data) {
          setFeedbackContent(res.data.FeedbackContent);
          setLoading(false);
          clearInterval(intervalIDRef.current);
        } else {
          setLoading(false);
        }
        return res.data;
      });

      handleStreamData(getFeedbackData);
    };

    fetchFeedbackData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Overlay>
      <Wrapper>
        <CloseButton onClick={() => {
          dispatch(toggleChatFeedbackMobile(false));
        }}>
          <Icon src={IconCloseChat} alt="Close" />
        </CloseButton>
        <PopupContainer>
          <Avatar>
            <AvatarImg src={PreceptorAvatar || imageInstructor} alt="Patient Avatar" />
          </Avatar>
          <Title>Chat Feedback</Title>
          <ContentWrapper>
            <Content className='whiteSpace-break' dangerouslySetInnerHTML={{ __html: contentStream || feedbackContent }} />
            {isLoading && <div className="typing-loader my-3 mx-auto"></div>}
          </ContentWrapper>
        </PopupContainer>
      </Wrapper>
    </Overlay>
  );
};

export default ChatFeedBackPopup;