import styled from 'styled-components';

//McqWaiting
import McqWaiting from '@images/teachV2/waiting-mcq.gif'

const LoadingGenerateAIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 1;
  min-height: 500px;
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const Heading = styled.h3`
  color: #101828;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin: 32px 0;
`;

const Description = styled.p`
  color: #475467;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-top: 8px;
`;

const LoadingGenerateAI = () => {
    return (
        <LoadingGenerateAIWrapper>
            <IconWrapper>
              <img src={McqWaiting} alt="mcq" />
            </IconWrapper>
            <Heading>Generating Your Food Item</Heading>
            <Description>Just a moment... Our AI is gathering the details.</Description>
        </LoadingGenerateAIWrapper>
    );
};

export default LoadingGenerateAI;
