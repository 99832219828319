import React from 'react';

//reactstrap
import { Input } from "reactstrap";

//SketchPicker
import { SketchPicker } from 'react-color';

function ColorPicker(props) {

    const { handleChangeComplete, stateColor } = props && props;

    return (
        <React.Fragment>
            <div className="position-relative d-flex align-items-center">
                <Input
                    type="text"
                    placeholder="Press enter to add tags"
                    value={stateColor?.hex}
                    autoFocus
                    autoComplete="off"
                    className='mr-2'
                />
                <div style={{ backgroundColor: stateColor?.hex, color: 'white', width: '50px', height: '35px'}}></div>
            </div>
            <div className="d-flex justify-content-center mt-3">
                <SketchPicker
                    color={stateColor}
                    onChangeComplete={handleChangeComplete}
                    className={`mt-2`}
                    disableAlpha={true}
                />
            </div>
        </React.Fragment>
    );
};

export default ColorPicker;