// CoinList.js
import React from 'react';
import { useSelector } from "react-redux";

//components
import EarnCoinList from './EarnCoinList';

const CoinList = () => {
    const coin = useSelector(state => state.getCoin?.data || {});
    const { CoinRewardOptions = [] } = coin;

    const combinedData = CoinRewardOptions?.map(option => ({
        title: option.name,
        description: option.descriptions,
        iconWidth: 24,
        iconAlt: 'coin',
        coinValue: option.amount,
    }));

    if (combinedData.length === 0) {
        return null; // Return null if there are no coin reward options
    }

    return (
        <React.Fragment>
            <h3>How To Earn Credits:</h3>
            <EarnCoinList items={combinedData} />
        </React.Fragment>
    );
};

export default CoinList;