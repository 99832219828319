import React from 'react';
import { Link } from 'react-router-dom';

const SidebarItemSubMenu = ({ subItem, isActive, handleMainItemClick }) => (
    <li className={`sidebar-submenu__item ${isActive ? 'active' : 'not-active'}`}>
        <span className='not-icon'></span>
        <Link
            className={`sidebar-submenu__item--link ${isActive ? 'active' : 'not-active'}`}
            to={subItem.to}
            onClick={handleMainItemClick}
        >
            <span className='sidebar-submenu__item--name'>{subItem.name}</span>
        </Link>
    </li>
);

export default SidebarItemSubMenu;