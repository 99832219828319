import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { apiCaller } from "@utils";
import _ from "lodash";

function OrderTypeCondition({ handleUpdateFlowControl, timepointData, handleChangeStatusByCondition, isCondition }) {
    const [arrLabs, setArrLabs] = useState([]);
    const [arrStudies, setArrStudies] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isStatus, setStatus] = useState("");
    const [isLoading, setLoading] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        const fetchData = async (typeLoad) => {
            setLoading(true);
            const params = {
                "Action": "Load",
                "Target": typeLoad,
                "TimePointId": timepointData?.Id,
            };

            try {
                const res = await apiCaller(`/api/teach/case/${id}/`, "PUT", params);
                const data = res?.data;

                if (res?.status === 200) {
                    if (typeLoad === "Labs") {
                        const mergedLabs = _.merge(data?.IndependentLabs, data?.Panels);
                        setArrLabs(mergedLabs);
                    } else if (typeLoad === "Studies") {
                        setArrStudies(data);
                    }
                } else {
                    console.error("Error loading data");
                }
            } catch (error) {
                console.error("API call failed", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData("Labs");
        fetchData("Studies");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertDataOptions = () => {
        const mergedArray = [...arrLabs, ...arrStudies];
        const sortedArray = mergedArray.sort((a, b) => a.Name.localeCompare(b.Name));
        return sortedArray.map((item) => ({
            value: item?.Id,
            label: `${item?.Name} (#${item?.Id})`,
        }));
    };

    const handleChangeSelect = (data) => {
        setSelectedOption(data);
        handleUpdateFlowControl(isCondition, data);
    };

    const handleChangeStatus = (e) => {
        const { value } = e.target;
        setStatus(value);
        handleChangeStatusByCondition(value);
    };

    const renderRadioButton = (value, label) => (
        <FormGroup check className="mb-2">
            <Label check>
                <Input
                    type="radio"
                    value={value}
                    name="Status"
                    onChange={handleChangeStatus}
                    checked={isStatus === value}
                />
                {label}
            </Label>
        </FormGroup>
    );

    return (
        <div>
            <div className="my-3">
                <CreatableSelect
                    closeMenuOnSelect={true}
                    options={convertDataOptions()}
                    placeholder="Click to select chat order(s)"
                    value={selectedOption}
                    classNamePrefix="react-select-custom"
                    onChange={handleChangeSelect}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                />
            </div>
            <p>
                <small className="text-muted text-justify" style={{ fontSize: "12px" }}>
                    (To NOT impose a condition, simply do not select a question)
                </small>
            </p>
            {renderRadioButton("ordered", "is ordered")}
            {renderRadioButton("not_ordered", "is NOT ordered")}
        </div>
    );
}

export default OrderTypeCondition;