import React from 'react'
import { HomeChoice } from '@components/teachV2/home'

const TeachHome = () => {
  return (
    <div className="wrapper -teachV2HomePage">
      <HomeChoice />
    </div>
  )
}

export default TeachHome
