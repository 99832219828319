import { useDrag } from 'react-dnd';

const style = {
    cursor: 'move'
};

function ElementPoint ({ id, left, top, PointId, props }) {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "box",
        item: { id, left, top },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [id, left, top]);
    if (isDragging) {
        return <div ref={drag}/>;
    }
    const roleBox = "Box";
    return (
        <div 
            className="pointBubble"
            data-id={PointId}
            ref={drag} style={{ ...style, left, top }} 
            role={roleBox}>
        </div>);
};

export default ElementPoint;