import React, { useRef } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

//partents
import { FormModal } from '@ui-partents/Modal';

const ModalFormAdd = ({ isOpen, toggleModal, type }) => {
  const formikRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    initialValue: Yup.string().required("Initial Value is required"),
    unit: Yup.string().required("Unit is required"),
    reference: Yup.number().required("Reference is required"),
  });

  const handleSaveButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={`Add ${type} Items`}
      onAction={handleSaveButtonClick}
      actionText={"Add Intake Items"}
      maxWidth="860px"
    >
      <Formik
        initialValues={{
          name: "",
          initialValue: "",
          unit: "",
          reference: "",
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
        }) => (
          <Form className="form-intake">
            <FormGroup>
              <Label className="labeForm" for="name">
                Name <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Enter the title"
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.name && touched.name}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="initialValue">
                Initial Value <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="Enter the title"
                type="text"
                name="initialValue"
                id="initialValue"
                value={values.initialValue}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.initialValue && touched.initialValue}
              />
              <FormFeedback>{errors.initialValue}</FormFeedback>
            </FormGroup>
            <FormGroup className="form-double">
              <div>
                <Label className="labeForm" for="unit">
                  Reference (Nomal) Range: <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Enter the title"
                  type="text"
                  name="unit"
                  id="unit"
                  value={values.unit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={!!errors.unit && touched.unit}
                />
                <FormFeedback>{errors.unit}</FormFeedback>
              </div>
              <div>
                <Label className="labeForm" for="reference">
                  Reference (Nomal) Range: <span className="text-danger">*</span>
                </Label>
                <Input
                  placeholder="Enter the title"
                  type="text"
                  name="reference"
                  id="reference"
                  value={values.reference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={!!errors.reference && touched.reference}
                />
                <FormFeedback>{errors.reference}</FormFeedback>
              </div>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </FormModal>
  );
};

export default ModalFormAdd;
