import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import picThanks from "@images/learnerv2/pic-thanks-feedback.svg";
import { useHistory, useParams } from "react-router-dom";

function ThankYouFeedback({ isOpen }) {
    let history = useHistory();
    let { id } = useParams();

    return (
        <Modal
            className="modal-custom"
            zIndex={16000}
            isOpen={isOpen}
            backdrop="static"
            centered
            backdropClassName="modal-backdrop-custom"
            modalTransition={{ timeout: 700 }}
        >
            <ModalBody>
                <div className="modal-inner">
                    <div className="modal-inner__pic d-flex justify-content-center">
                        <img src={picThanks} alt="Thank you for your feedback" />
                    </div>
                    <h2 className="headline --size-4 mb-4 text-center">
                        <div dangerouslySetInnerHTML={{ __html: `Your Opinion Is Highly Appreciated! Thanks a ton! 🙌` }}></div>
                    </h2>
                    <div className="modal-inner__desc text-center">
                        <p>
                            🔍 Now, are you ready to dive into the detailed analysis?
                        </p>
                        <p>
                            Hit that <span className="txt-organe">"Analysis, Let's Go!"</span> button below! If you prefer to bookmark it for a future blast, tap <span className="txt-organe">"Bookmark for Later"</span> and revisit it whenever you're in the mood for some investigative fun. 🔜
                        </p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="mx-auto button-container">
                    <Button
                        color='primary'
                        className="btn-brand-sm -btn-lg w-100 shadown"
                        onClick={() => history.push(`/learn/feedback/${id}`)}
                    >
                        Analysis, Let's Go!
                    </Button>
                    {/* Uncomment and use this button if needed later
                    <Button
                        color='white'
                        className="btn-white-sm mt-3 -btn-lg w-100 shadown"
                    >
                        Bookmark for Later
                    </Button>
                    */}
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ThankYouFeedback;