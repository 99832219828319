import React, { useState, useEffect, useRef } from "react";

//components
import { FrameChat } from '@components/learner/FeedbackV2/ChatTemplate';
import { ModalEndCase } from '../FeedbackV2/CompletedCase';
import { FeedbackV2Layout, MessageBoxEndChat } from '../FeedbackV2';

//react-router-dom
import { useParams } from "react-router-dom";

//lodash
import _ from "lodash";

//API
import { apiCaller } from "@utils";

function SelfReflectionDialogueMain(props) {
    let { id } = useParams();
    const endOfChatRef = useRef(null);

    const [stateData, setData] = useState([]);
    const [loading, setLoading] = useState({
        user: false,
        assistant: false
    });

    // State for input value
    const [value, setValue] = useState('');
    // State for ModalEndCase
    const [modalOpen, setModalOpen] = useState(false);

    // Function to handle input change
    const handleChange = (e) => {
        setValue(e.target.value); // Updating the input value state
    };

    // Function to handle key down events
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(value); // If Enter key is pressed, submitting the form
        }
    };

    useEffect(() => {
        const fetchData = () => {
            setLoading(prevState => ({ ...prevState, assistant: true })); // Bắt đầu loading cho trợ lý
            const params = {
                "CaseId": id,
                "Action": "LoadView"
            }

            apiCaller(`/api/learn/case/reflection/`, "POST", params)
                .then((res) => {
                    const data = res?.data;
                    if (res?.status === 200) {
                        const formattedData = {
                            role: 'assistant',
                            content: data.ResponseText
                        };
                        setData([formattedData]);
                    } else {
                        console.log("error....");
                    }
                })
                .finally(() => setLoading(prevState => ({ ...prevState, assistant: false }))); // Kết thúc loading cho trợ lý
        }
        fetchData();
    }, [id]);

    const handleSubmit = (statement) => {
        setLoading(prevState => ({ ...prevState, assistant: true })); // Bắt đầu loading cho assistant
        const userStatement = {
            role: 'user',
            content: statement
        };

        setData(prevState => [...prevState, userStatement]);

        const params = {
            "CaseId": id,
            "Action": "Submit",
            "Statement": statement
        }

        apiCaller(`/api/learn/case/reflection/`, "POST", params)
            .then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    // Remove Value for input and scroll bottom
                    setValue('');
                    endOfChatRef.current.scrollIntoView({ behavior: "smooth" });

                    // Delay
                    setTimeout(() => {
                        setLoading(prevState => ({ ...prevState, assistant: false }))

                        const formattedData = {
                            role: 'assistant',
                            content: data.ResponseText,
                            EndChatNow: data.EndChatNow
                        };
                        setData(prevState => [...prevState, formattedData]);
                    }, 2000);
                } else {
                    console.log("error....");
                }
            })
            .catch(error => {
                console.error('Error submitting statement:', error);
            })
    };

    const handleClickButton = () => {
        setModalOpen(!modalOpen); // Toggle modal state
    };

    const hasEndChatNow = _.some(stateData, { 'EndChatNow': true });
    return (
        <FeedbackV2Layout
            {...props}
            title={`Self-Reflection Dialogue`}
        >
            <div className="position-relative">
                <FrameChat
                    {...props}
                    DataChatRecord={stateData || []}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    handleKeyDown={handleKeyDown}
                    isLoading={loading}
                    value={value}
                    endOfChatRef={endOfChatRef}
                    disabled={hasEndChatNow}
                />
                {hasEndChatNow &&
                    <div className="mess-end-chat">
                        <MessageBoxEndChat
                            {...props}
                            handleClickButton={handleClickButton}
                        />
                    </div>
                }
            </div>
            {modalOpen &&
                <ModalEndCase
                    {...props}
                    isOpen={modalOpen}
                    handleClickButton={handleClickButton}
                />
            }
        </FeedbackV2Layout>
    );
}

export default SelfReflectionDialogueMain;