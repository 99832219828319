import React from 'react';

//reactstrap
import { Button } from "reactstrap";

function ActionDelete(props) {
    const { handeModalAdd, rowData } = props && props;
    return (
        <div className="text-center p-3">
            <Button
                className="btn-style2 btn-outline-brand d-inline-block"
                onClick={handeModalAdd}
            >
                <i className="fa fa-plus mr-2" aria-hidden="true" /> Add {rowData?.SectionName}
            </Button>
        </div>
    );
};

export default ActionDelete;