import React, { useState, useEffect } from "react";

//reactBoostrap
import {
    FormGroup,
    Label,
    Col,
    Input
} from "reactstrap";

function GSDropCloze(props) {
    const { stateGradingSchema, handleChangeSchema } = props && props;

    //state
    const [status, setStatus] = useState(stateGradingSchema || 'null');

    //load State
    useEffect(() => {
        setStatus(stateGradingSchema || 'null');
    }, [stateGradingSchema])

    const gradingSchemaOptions = [
        {
            value: 'order_matter',
            label: 'Matter'
        },
        {
            value: 'order_not_matter',
            label: 'Does not matter'
        }
    ]

    return (
        <Col md={4}>
            <legend className="col-form-label font-weight-500">
                Order of Choices ...
            </legend>
            {gradingSchemaOptions?.map((item, index) =>
                <FormGroup check className="mb-2" key={index}>
                    <Label check>
                        <Input
                            type="radio"
                            onChange={handleChangeSchema}
                            defaultValue={item?.value}
                            defaultChecked={status === item?.value}
                            name="GradingSchema"
                        />
                        {item?.label}
                    </Label>
                </FormGroup>
            )}
        </Col>
    );
};

export default GSDropCloze;