export const elementsConstants = {
    LOAD_IODATA_FOR_ELEMENT_REQUEST: 'LOAD_IODATA_FOR_ELEMENT_REQUEST',
    LOAD_IODATA_FOR_ELEMENT_SUCCESS: 'LOAD_IODATA_FOR_ELEMENT_SUCCESS',
    LOAD_IODATA_FOR_ELEMENT_FAILURE: 'LOAD_IODATA_FOR_ELEMENT_FAILURE',

    LOAD_CASE_FOR_ELEMENT_REQUEST: 'LOAD_CASE_FOR_ELEMENT_REQUEST',
    LOAD_CASE_FOR_ELEMENT_SUCCESS: 'LOAD_CASE_FOR_ELEMENT_SUCCESS',
    LOAD_CASE_FOR_ELEMENT_FAILURE: 'LOAD_CASE_FOR_ELEMENT_FAILURE',

    UPDATE_VALUE_BALANCE_FOR_ELEMENTS_REQUEST: 'UPDATE_VALUE_BALANCE_FOR_ELEMENTS_REQUEST',
    UPDATE_VALUE_BALANCE_FOR_ELEMENTS_SUCCESS: 'UPDATE_VALUE_BALANCE_FOR_ELEMENTS_SUCCESS',
    UPDATE_VALUE_BALANCE_FOR_ELEMENTS_FAILURE: 'UPDATE_VALUE_BALANCE_FOR_ELEMENTS_FAILURE',

    INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_REQUEST: 'INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_REQUEST',
    INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_SUCCESS: 'INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_SUCCESS',
    INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_FAILURE: 'INTAKE_OUTPUT_CHANGE_FOR_ELEMENTS_FAILURE',

    LOAD_LABS_FOR_ELEMENTS_REQUEST: 'LOAD_LABS_FOR_ELEMENTS_REQUEST',
    LOAD_LABS_FOR_ELEMENTS_SUCCESS: 'LOAD_LABS_FOR_ELEMENTS_SUCCESS',
    LOAD_LABS_FOR_ELEMENTS_FAILURE: 'LOAD_LABS_FOR_ELEMENTS_FAILURE',

    LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_REQUEST: 'LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_REQUEST',
    LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_SUCCESS: 'LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_SUCCESS',
    LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_FAILURE: 'LOAD_CHAT_TOPICS_AT_ALL_TIMEPOINTS_FAILURE',

    LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_REQUEST: 'LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_REQUEST',
    LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_SUCCESS: 'LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_SUCCESS',
    LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_FAILURE: 'LOAD_STANDARD_CHAT_TOPICS_TIMEPOINTS_FAILURE',

    ADD_CHAT_TOPICS_TO_CASE_REQUEST: 'ADD_CHAT_TOPICS_TO_CASE_REQUEST',
    ADD_CHAT_TOPICS_TO_CASE_SUCCESS: 'ADD_CHAT_TOPICS_TO_CASE_SUCCESS',
    ADD_CHAT_TOPICS_TO_CASE_FAILURE: 'ADD_CHAT_TOPICS_TO_CASE_FAILURE',

    DELETE_CHAT_TOPIC_IN_CASE_REQUEST: 'DELETE_CHAT_TOPIC_IN_CASE_REQUEST',
    DELETE_CHAT_TOPIC_IN_CASE_SUCCESS: 'DELETE_CHAT_TOPIC_IN_CASE_SUCCESS',
    DELETE_CHAT_TOPIC_IN_CASE_FAILURE: 'DELETE_CHAT_TOPIC_IN_CASE_FAILURE',

    DELETE_CHAT_TOPIC_FREE_TEXT_REQUEST: 'DELETE_CHAT_TOPIC_FREE_TEXT_REQUEST',
    DELETE_CHAT_TOPIC_FREE_TEXT_SUCCESS: 'DELETE_CHAT_TOPIC_FREE_TEXT_SUCCESS',
    DELETE_CHAT_TOPIC_FREE_TEXT_FAILURE: 'DELETE_CHAT_TOPIC_FREE_TEXT_FAILURE',

    UPDATE_CHAT_TOPIC_IN_CASE_CASE_REQUEST: 'UPDATE_CHAT_TOPIC_IN_CASE_CASE_REQUEST',
    UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS: 'UPDATE_CHAT_TOPIC_IN_CASE_CASE_SUCCESS',
    UPDATE_CHAT_TOPIC_IN_CASE_CASE_FAILURE: 'UPDATE_CHAT_TOPIC_IN_CASE_CASE_FAILURE',

    LOAD_ALL_CASE_MEDICATIONS_REQUEST: 'LOAD_ALL_CASE_MEDICATIONS_REQUEST',
    LOAD_ALL_CASE_MEDICATIONS_SUCCESS: 'LOAD_ALL_CASE_MEDICATIONS_SUCCESS',
    LOAD_ALL_CASE_MEDICATIONS_FAILURE: 'LOAD_ALL_CASE_MEDICATIONS_FAILURE',

    UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_REQUEST: 'UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_REQUEST',
    UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_SUCCESS: 'UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_SUCCESS',
    UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_FAILURE: 'UPDATE_INTERVENTION_AVAILABLE_MEDICATIONS_OF_ELEMENT_FAILURE',

    LOAD_ALL_CASE_ACTIONS_REQUEST: 'LOAD_ALL_CASE_ACTIONS_REQUEST',
    LOAD_ALL_CASE_ACTIONS_SUCCESS: 'LOAD_ALL_CASE_ACTIONS_SUCCESS',
    LOAD_ALL_CASE_ACTIONS_FAILURE: 'LOAD_ALL_CASE_ACTIONS_FAILURE',

    LAB_CHANGE_VALUE_ELEMENTS_REQUEST: 'LAB_CHANGE_VALUE_ELEMENTS_REQUEST',
    LAB_CHANGE_VALUE_ELEMENTS_SUCCESS: 'LAB_CHANGE_VALUE_ELEMENTS_SUCCESS',
    LAB_CHANGE_VALUE_ELEMENTS_FAILURE: 'LAB_CHANGE_VALUE_ELEMENTS_FAILURE',
};