import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Label, Badge } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//common
import { DataEmty, HeadingBox } from "@components/common";

//redux
import { useDispatch, useSelector } from "react-redux";

// common
import { ModalDelete, ModalInfoEnroll } from "@components/teach/Modal";
import { CreatableMultiselect } from "@components/common";

//lodash
import _ from "lodash";

//untils
import { LoaderPage, messageData, formatReactSelect, apiCaller } from "@utils";

//api
import { teachConstants } from "@constants";

//call Action
import {
  actFetchCaseLearnersRequest,
  loadCandidatesOfLearners,
  actLearnersRemove,
} from "@actions";

//elements
import { ButtonDelete } from '@components/common/elements';

function LearnersPage(props) {
  const { caseDetails } = props && props;

  let { id } = useParams();
  const dispatch = useDispatch();
  const [objectDel, setObjectDel] = useState({});
  const [modal, setModal] = useState({
    delete: false,
    confirm: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [listInvitedEmails, setInvitedEmails] = useState([]);
  const [dataConfirm, setDataConfirm] = useState({});
  const [isEnroll, setEnroll] = useState(false);

  const toggle = (type, data) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setObjectDel(data);
  };

  useEffect(() => {
    const fetchDataTable = async () => {
      dispatch(actFetchCaseLearnersRequest(id));
    };

    const fetchDataEnroll = async () => {
      const params = {
        Action: "candidates",
      };
      dispatch(loadCandidatesOfLearners(id, params));
    };

    fetchDataTable();
    fetchDataEnroll();
  }, [dispatch, id]);

  const loadCandidates = () => {
    const params = {
      Action: "candidates",
    };
    dispatch(loadCandidatesOfLearners(id, params));
  }

  const handleRemove = () => {
    const params = {
      Action: "remove",
      Emails: [objectDel.email],
    };
    dispatch(actLearnersRemove(id, params));
    toggle("delete", {});

    //load Candidates
    loadCandidates();
  };

  const submitEnroll = () => {
    //Convert Array to String
    if (listInvitedEmails.length > 0) {
      var arrEmails = _.map(listInvitedEmails, function (element, idx) {
        return element.value;
      });
    }

    const params = {
      Action: "enroll",
      Emails: arrEmails || [],
    };

    //Call Api
    setLoading(true);
    dispatch({ type: teachConstants.PUT_ENROLL_REQUEST, isLoading: true });
    apiCaller(`/api/teach/learners/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      const InvitedEmails = data && data.InvitedEmails;
      const AddedUsers = data && data.AddedUsers;
      if (res && res.status === 200) {
        dispatch({ type: teachConstants.PUT_ENROLL_SUCCESS, payload: data });
        setLoading(false);
        setEnroll(true);
        if (
          (AddedUsers && AddedUsers.length > 0) ||
          (InvitedEmails && InvitedEmails.length > 0)
        ) {
          setDataConfirm(data);
          setModal((prevState) => ({ ...prevState, confirm: true }));
        }
      } else {
        dispatch({
          type: teachConstants.PUT_ENROLL_FAILURE,
          error: "error",
        });
      }
    });
  };

  const handleConfirm = () => {
    //load Candidates
    loadCandidates();

    //load learners
    dispatch(actFetchCaseLearnersRequest(id));

    //set Modal
    setModal((prevState) => ({ ...prevState, confirm: false }));
    setEnroll(false);
  };

  const columns = [
    {
      dataField: "Id",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "lastname",
      text: "Last Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "firstname",
      text: "First Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: "--text-primary font-weight-500",
      headerStyle: (colum, colIndex) => {
        return { width: "40%" };
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerClasses: "--text-primary align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      formatter: (value, row) => {
        const checkStatus = (status) => {
          let statusBlock = "";
          switch (status) {
            case "Active":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="success"
                >
                  {value}
                </Badge>
              );
              break;
            default:
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="danger"
                >
                  Pending ...
                </Badge>
              );
          }
          return statusBlock;
        };
        return <div>{checkStatus(value)}</div>;
      },
    },
    {
      dataField: "Actions",
      text: "",
      headerStyle: (colum, colIndex) => {
        return { width: "50px", textAlign: "center" };
      },
      headerClasses: "--text-primary font-weight-500 align-middle",
      formatter: (cell, row) => {
        return (
          <ButtonDelete handleDelete={() => toggle("delete", row)} />
        );
      },
    },
  ];

  //get data store
  const teachCaseLearners = useSelector(
    (state) => state.caseLearnersList || []
  );
  const dataCandidatesForLearner = useSelector(
    (state) => state?.loadCandidatesForLearner?.data || []
  );
  const { SearchBar } = Search;
  const dataResponse = teachCaseLearners?.data?.Learners || [];
  const dropdownSelect = dataCandidatesForLearner?.Candidates?.length > 0 ? formatReactSelect(dataCandidatesForLearner?.Candidates) : [];

  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -learnersPage">
      {teachCaseLearners.isLoading && <LoaderPage />}
      {!teachCaseLearners.isLoading && (
        <React.Fragment>
          <div className="learnersCt">
            <Container>
              <HeadingBox title={titlePage || ''} currentPage="Learners" />
              <Row className="justify-content-start mb-3">
                <Col sm={`12`}>
                  <div className="dropdownMutiCt">
                    <div className="dropdownMutiCt__left">
                      <CreatableMultiselect
                        handleChangeData={(data) => setInvitedEmails(data)}
                        placeholder={`Click and select learners to enroll in this case`}
                        DataOption={dropdownSelect}
                        isEnroll={isEnroll}
                      />
                    </div>
                    <div className="dropdownMutiCt__right">
                      <Button
                        className="--btn-primary d-flex align-items-center ml-auto text-nowrap"
                        onClick={submitEnroll}
                        disabled={
                          isLoading ||
                          (listInvitedEmails?.length > 0
                            ? false
                            : true)
                        }
                      >
                        {isLoading && (
                          <span className="spinner-border spinner-border-sm mr-2"></span>
                        )}
                        Enroll Learner(s)
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="boxPortlet">
                <ToolkitProvider
                  keyField="Id"
                  data={dataResponse || []}
                  columns={columns}
                  search
                >
                  {(toolkitprops) => (
                    <React.Fragment>
                      <Row className="justify-content-start mb-3 mt-3">
                        <Col md={6} sm={6} className="py">
                          <div className="formSearchInline">
                            <Label for="search" className="mr-2 mb-0">
                              Search:
                            </Label>
                            <SearchBar
                              {...toolkitprops.searchProps}
                              placeholder="Enter name or email"
                            />
                          </div>
                        </Col>
                      </Row>
                      <BootstrapTable
                        condensed
                        striped
                        wrapperClasses="table-responsive"
                        bordered={false}
                        noDataIndication={() => <DataEmty content={messageData?.filter} />}
                        {...toolkitprops.baseProps}
                      />
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </Container>
          </div>
        </React.Fragment>
      )}
      <ModalDelete
        data={objectDel}
        toggleDel={() => toggle("delete", objectDel)}
        isOpen={modal.delete}
        handleRemove={handleRemove}
        type={`Learners`}
        title={`Are you sure you want to remove this learner from the case ?`}
        desc={`The learner will no longer have access to this case.<br/> However, all of their performances will remain saved in the system.`}
      />
      <ModalInfoEnroll
        isOpen={modal.confirm}
        toggle={() => toggle("confirm", {})}
        data={dataConfirm}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}

export default LearnersPage;
