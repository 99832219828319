import React, { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

//Router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//form
import { useForm } from "react-hook-form";

//redux
import { useSelector } from "react-redux";

//call Action
import {
  atcUpdateScoreLab,
  atcUpdateScoreStudy,
  atcUpdateScoreIndependentLabs,
  atcUpdateScoreMedication,
  atcUpdateScoreForPN,
  atcUpdateScoreAction
} from "@actions";

const ModalScore = (props) => {
  //params id
  let { id } = useParams();
  const dispatch = useDispatch();
  
  const {
    actions,
    attributeType,
    itemScore,
    activeCategory,
    categoryName,
    isComponent,
    timePointId,
    toggle,
    isOpen,
    itemEdit,
    handleStatusUpdateScore
  } = props && props;
  
  //Check View
  let conditionType = ["Medication", "Action", "pnOrders", "Study", "Lab"].indexOf(attributeType) > -1

  //data dropdown
  const listDomain = useSelector(
    (state) => state?.teachCase?.listDomain || []
  );

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    const listScore = itemScore?.Score;
    const domainId = itemScore?.DomainId;
    const timingCondition = itemScore?.TimingCondition;
    const timingValue = itemScore?.TimingValue;

    if (actions === "edit-score") {
      setValue("Score", listScore, { shouldDirty: false });
      setValue("DomainId", domainId, {
        shouldDirty: false,
      });

      if (typeof (timingValue) != "undefined" && typeof (timingCondition) != "undefined") {
        setValue("TimingCondition", 'At', { shouldDirty: false });
      } else {
        setValue("TimingCondition", 'All', { shouldDirty: false });
      }
    }

    if (actions === "add-score") {
      reset({
        TimingCondition: "All",
        DomainId: "",
        Score: ""
      }, {
        keepIsValid: false
      });
    }
  }, [actions, itemEdit, reset, setValue, props, itemScore]);

  const handleSave = (data) => {
    //props lab panel
    const AttributeId = itemEdit?.Id;
    const DomainId = data?.DomainId;
    const Score = data?.Score;

    //params Target Attribute
    const paramsAttributeScore = {
      Action: "Update",
      Target: "AttributeScore",
      AttributeType: attributeType,
      AttributeId: AttributeId,
      DomainId: DomainId,
      Score: Score
    };

    //params Target Intervention Score
    const paramsInterventionScore = {
      Action: "Update",
      Target: "InterventionScore",
      TimePointId: timePointId,
      InterventionId: AttributeId,
      DomainId: DomainId,
      Score: Score
    };

    if (data?.TimingCondition === "At" && conditionType) {
      paramsInterventionScore['TimingCondition'] = 'At'
    }

    // Call API with Type (Medication/Action/PnOrders =>InterventionScore)
    if (attributeType === "Lab" && !isComponent) {
      dispatch(atcUpdateScoreLab(id, paramsAttributeScore, AttributeId));
    } else if (attributeType === "Lab" && isComponent === "Independent-Labs") {
      dispatch(atcUpdateScoreIndependentLabs(id, paramsAttributeScore, AttributeId));
    } else if (attributeType === "Medication") {
      dispatch(atcUpdateScoreMedication(id, paramsInterventionScore, AttributeId, activeCategory));
    } else if (attributeType === "Action") {
      dispatch(atcUpdateScoreAction(id, paramsInterventionScore, AttributeId, activeCategory));
    } else if (attributeType === "pnOrders") {
      dispatch(atcUpdateScoreForPN(id, paramsInterventionScore, AttributeId, categoryName));
    } else if (attributeType === "Study") {
      dispatch(atcUpdateScoreStudy(id, paramsAttributeScore, AttributeId));
    }
    toggle();
    handleStatusUpdateScore(true);
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {actions === "add-score" ? "Add Score Element" : "Edit Score Element"}
        </ModalHeader>
        <ModalBody>
          <p className="mb-2">
            Lab:
            <strong className="font-weight-500">
              {" "}
              {itemEdit?.Name || ''}
            </strong>
          </p>
          <FormGroup>
            <Label for="DomainId">Domain</Label>
            <Input
              type="select"
              {...register("DomainId", { required: true })}
              invalid={errors.DomainId?.type === "required"}
              name="DomainId"
              id="DomainId"
              onChange={(e) => {
                setValue("DomainId", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("DomainId")}
              disabled={actions === "edit-score"}
            >
              <option value="">Select an item below</option>
              {listDomain &&
                listDomain.map((value, index) => (
                  <option value={value.Id} key={index}>
                    {value.Name}
                  </option>
                ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="Score">Score</Label>
            <Input
              type="number"
              {...register("Score", { required: true })}
              invalid={errors.Score?.type === "required"}
              onChange={(e) => {
                setValue("Score", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("Score")}
              step="any"
              name="Score"
              id="Score"
              placeholder=""
            />
            <FormFeedback invalid={String(errors.Score?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
          {conditionType &&
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    {...register("TimingCondition")}
                    type="radio"
                    name="TimingCondition"
                    defaultValue={'All'}
                    defaultChecked={watch("TimingCondition") === 'All'}
                    onChange={(e) => {
                      setValue("TimingCondition", 'All');
                    }}
                  />{" "}
                  Apply to all time points
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    {...register("TimingCondition")}
                    type="radio"
                    name="TimingCondition"
                    defaultValue={'At'}
                    defaultChecked={watch("TimingCondition") === 'At'}
                    onChange={(e) => {
                      setValue("TimingCondition", 'At');
                    }}
                  />{" "}
                  Apply only to this time point
                </Label>
              </FormGroup>
            </FormGroup>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={handleSubmit(handleSave)}
          >
            {actions === "add-score" ? "Add Score Element" : "Save Score Element"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalScore;
