import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Import your icon images
import iconTimepointFirst from '@images/new_interface/timepoint/timepoint-first.svg';
import iconTime from '@images/new_interface/timepoint/clock-circle.svg';
import iconEndCase from '@images/new_interface/timepoint/icon-end-case.svg';

//components
import { AddTimePoint } from "../TimePoint";

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 16px 0;
  display: flex;
  align-items: center;
`;

const ListItem = styled.li`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 35px;
  background: #fff;
  margin-right: 37px;
  border: ${(props) => (props.active ? '1px solid #0089C2' : '1px solid #D0D5DD')};
  position: relative;
  min-height: 44px;
  z-index: 1;
  transition: border-color 0.3s ease;
  &:hover {
    cursor: pointer;
  }
  /* Apply :before only to items from the 2nd onward */
  &:nth-child(n + 2):before {
    content: "";
    width: 40px; 
    height: 1px; 
    position: absolute;
    left: -40px;
    top: 50%; 
    background: linear-gradient(to right, transparent 50%, #344054 50%); 
    background-size: 8px 100%;
    z-index: 0;
  }
`;

const ListItemText = styled.span`
  color: ${(props) => (props.active ? '#0089C2' : '#4B5565')};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.28px;
  white-space: nowrap;
`;

const TimeLineTop = (props) => {
  // props
  const { timelineData: initialTimelineData, timepointIdActive, handleClickTimepoint } = props;

  // state
  const [timelineData, setTimelineData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  // Effect to set state based on props
  useEffect(() => {
    if (initialTimelineData) {
      const dataWithEndingPage = [
        ...initialTimelineData,
        { Id: 9999, Time: 9999, DisplayName: 'Ending Page', DefaultActiveStatus: false }
      ];
      setTimelineData(dataWithEndingPage);
      const initialActiveIndex = initialTimelineData.findIndex(item => item.Id === timepointIdActive);
      // If timepointIdActive is 9999, set activeIndex to the last item
      if (timepointIdActive === 9999) {
        setActiveIndex(dataWithEndingPage.length - 1);
      } else {
        setActiveIndex(initialActiveIndex !== -1 ? initialActiveIndex : null);
      }
    }
  }, [initialTimelineData, timepointIdActive]);

  // Icons mapping based on condition
  const getIcon = (time) => {
    if (time === 0) return iconTimepointFirst;
    if (time > 0 && time < 3000) return iconTime;
    if (time === 9999) return iconEndCase;
    return iconTime; // default icon
  };

  return (
    <div className='d-flex justify-content-between align-items-center'>
      <List>
        {timelineData.map((item, index) => (
          <ListItem key={item.Id} active={activeIndex === index} onClick={() => handleClickTimepoint(item)}>
            <img src={getIcon(item.Time)} alt={`icon-${index}`} />
            <ListItemText active={activeIndex === index}>{item.DisplayName}</ListItemText>
          </ListItem>
        ))}
      </List>
      <AddTimePoint
        {...props}
      />
    </div>
  );
};

export default TimeLineTop;