import React from 'react';
//import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';

const SquarePayment = ({ onPaymentSuccess, onPaymentError }) => {
   /* const handleCardTokenizeResponse = (token, buyer) => {
        if (!token) {
            console.error('Token is missing');
            onPaymentError('Token is missing');
            return;
        }
        onPaymentSuccess(token);
    };*/

    return (
        <div>Payment Gateway</div>
       /* <PaymentForm
            applicationId="sandbox-sq0idb-LdliCzQxi3O67LOWaNagBA"
            locationId="L8HGN2VD4AFVP"
            cardTokenizeResponseReceived={handleCardTokenizeResponse}
        >
            <CreditCard />
        </PaymentForm>*/
    );
};

export default SquarePayment;
