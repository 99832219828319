import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";

//Router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//form
import { useForm } from "react-hook-form";

//api
import { apiCaller } from "@utils";
import { actionConstants, medicationConstants } from "@constants";

const ModalUpdateCategory = (props) => {
  //params id
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, timePointId, action, categoryType } = props && props;
  const dataItem = props && props.dataCurrent;

  //state
  const [isLoading, setLoading] = useState(false);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (action === "editCategory") {
      setValue("Name", dataItem && dataItem.Name, { shouldDirty: false });
    }

    if (action === "addCategory") {
      reset({ keepIsValid: false, keepValues: false });
    }
  }, [action, dataItem, reset, setValue]);


  const checkReloadApiForElements = (type) => {
    const params = {}
    const pathName = window.location.pathname.includes("/elements");
    if (pathName) {
      params["ReloadData"] = type;
    }
    return params;
  }

  const handleAdd = (data) => {
    setLoading(true);
    let params = {
      Target: "InterventionCategory",
      CategoryType: categoryType,
      TimePointId: timePointId,
      Name: data && data.Name,
    };

    const reload = checkReloadApiForElements(categoryType === "Action" ? "Actions" : "Medications");
    if (reload) {
      //Reload Data
      params = { ...params, ...reload }
    }

    if (action === "addCategory") {
      params["Action"] = "Create";
      if (categoryType === "Action") {
        atcCreateActionCategory(id, params);
      } else {
        atcCreateMedicationCategory(id, params);
      }
    } else {
      params["Action"] = "Update";
      params["CategoryId"] = dataItem && dataItem.CategoryId;

      if (categoryType === "Action") {
        atcUPdateActionCategory(id, params);
      } else {
        atcUpdateMedicationCategory(id, params);
      }
    }
  };

  //Case - Create Action Category
  const atcCreateActionCategory = (id, params) => {
    const isLoading = true;
    //Call Api
    dispatch({ type: actionConstants.CREATE_ACTION_CATEGORY_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: actionConstants.CREATE_ACTION_CATEGORY_SUCCESS,
          payload: data
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: actionConstants.CREATE_ACTION_CATEGORY_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  //Case - Update Action Category
  const atcUPdateActionCategory = (id, params) => {
    const isLoading = true;
    //Call Api
    dispatch({ type: actionConstants.UPDATE_ACTION_CATEGORY_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: actionConstants.UPDATE_ACTION_CATEGORY_SUCCESS,
          payload: data
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: actionConstants.UPDATE_ACTION_CATEGORY_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  // Case - Create Medication Category
  const atcCreateMedicationCategory = (id, params) => {
    const isLoading = true;
    //Call Api
    dispatch({ type: medicationConstants.CREATE_MEDICATION_CATEGORY_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: medicationConstants.CREATE_MEDICATION_CATEGORY_SUCCESS,
          payload: data
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: medicationConstants.CREATE_MEDICATION_CATEGORY_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  // Case - Update Medication Category
  const atcUpdateMedicationCategory = (id, params) => {
    const isLoading = true;
    //Call Api
    dispatch({ type: medicationConstants.UPDATE_MEDICATION_CATEGORY_REQUEST, isLoading });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: medicationConstants.UPDATE_MEDICATION_CATEGORY_SUCCESS,
          payload: data
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: medicationConstants.UPDATE_MEDICATION_CATEGORY_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {action === "editCategory" ? "Edit" : "Add"} Category
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="Name">
              Name <span className="m--font-danger">*</span>
            </Label>
            <Input
              type="text"
              {...register("Name", { required: true })}
              invalid={errors.Name?.type === "required"}
              name="Name"
              onChange={(e) => {
                setValue("Name", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("Name")}
              autoComplete="off"
            />
            <FormFeedback
              invalid={(errors.Name?.type === "required").toString()}
            >
              This field is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="d-flex align-items-center"
            onClick={handleSubmit(handleAdd)}
            disabled={isLoading}
          >
            {isLoading &&
              <span className="spinner-border spinner-border-sm mr-2"></span>
            }
            {action === "editCategory" ? "Save" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalUpdateCategory;
