import React from "react";
import styled from "styled-components";

// context
import { useScreenSize } from "@context/ScreenSizeContext";

// Icon
import IconCase from "@images/learnerv2/case-alert.svg";

const CaseTitle = ({ title, textCenter }) => {
    // Use screen size context
    const { isMobile } = useScreenSize();

    return (
        <React.Fragment>
            {!isMobile ? (
                <div className="case-title-block d-flex align-items-center">
                    <img src={IconCase} alt="case" className="case info" />
                    <h2 className="case-title-block__name ml-2">{title}</h2>
                </div>
            ) : (
                <MobileStyle textCenter={textCenter}>{title}</MobileStyle>
            )}
        </React.Fragment>
    );
};

export default CaseTitle;

// Styled Component for Mobile
const MobileStyle = styled.div`
    color: #081B2A;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: ${({ textCenter }) => (textCenter ? "center" : "left")};
`;