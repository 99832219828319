import { seriesConstants } from "@constants";
import {apiCaller} from "@utils";

//fetch series
export const actFetchSeriesRequest = (idParam) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/learn/series/${idParam}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: seriesConstants.SERIES_REQUEST, isLoading } }
    function success(data) { return { type: seriesConstants.SERIES_SUCCESS, payload: data } }
    function failure(error) { return { type: seriesConstants.SERIES_FAILURE, error: error } }
}

// Series - Create
export const actCreateSeries = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/series/`, 'POST', params).then(res => {
            if(res && res.status === 200) {
                const data = res && res.data;
                if(data) {
                    dispatch(success(data));
                } else {
                    dispatch(failure("error"));
                }
            }
        });
    };
    function request(isLoading) { return { type: seriesConstants.CREATE_SERIES_REQUEST, isLoading } }
    function success(data) { return { type: seriesConstants.CREATE_SERIES_SUCCESS, payload: data } }
    function failure(error) { return { type: seriesConstants.CREATE_SERIES_FAILURE, error: error } }
}

// Series - Delete
export const actDeleteSeries = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/series/`, 'POST', params).then(res => {
            if(res && res.status === 200) {
                const data = res && res.data;
                if(data) {
                    dispatch(success(data));
                } else {
                    dispatch(failure("error"));
                }
            }
        });
    };
    function request(isLoading) { return { type: seriesConstants.DELETE_SERIES_REQUEST, isLoading } }
    function success(data) { return { type: seriesConstants.DELETE_SERIES_SUCCESS, payload: data } }
    function failure(error) { return { type: seriesConstants.DELETE_SERIES_FAILURE, error: error } }
}