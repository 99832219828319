import { coinConstant } from "@constants";

export function getCoin(state = {}, action) {
  switch (action.type) {
    case coinConstant.GET_COIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case coinConstant.GET_COIN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case coinConstant.GET_COIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getCoinMcq(state = {}, action) {
  switch (action.type) {
    case coinConstant.GET_COIN_MCQ_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case coinConstant.GET_COIN_MCQ_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case coinConstant.GET_COIN_MCQ_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getCoinCaseScript(state = {}, action) {
  switch (action.type) {
    case coinConstant.GET_COIN_CASE_SCRIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case coinConstant.GET_COIN_CASE_SCRIPT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case coinConstant.GET_COIN_CASE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
