import React, { useState } from 'react';

//reactstrap
import { Input } from "reactstrap";

//Icon
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

function InputFreeText(props) {
    const { handleQuestionTypePatient, disabled, caseData } = props && props;
    const TimePointId = caseData?.data?.Case?.Timeline?.CurrentTimePointId;

    //state
    const [value, setValue] = useState('');

    //send Api
    const handleSubmit = (currentValue) => {
        const params = {
            "Action": "Submit",
            "Message": value || currentValue,
            "TimePointId": TimePointId
        }
        if (value || currentValue) {
            handleQuestionTypePatient(params);
            setValue('');
        }
    }

    //handlechange
    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    //Handle Key Press
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPreceptor;
    const preceptor_reply = VirtualPreceptor?.preceptor_reply?.Text || "Enter your statement ...";
    return (
        <div className="chatFooter mt-3">
            <div className="chatFooter__left">
                <Input
                    name="answer"
                    placeholder={preceptor_reply}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    value={value}
                    disabled={disabled}
                    autoComplete="off"
                />
            </div>
            <div className="chatFooter__right">
                <button
                    type="button"
                    className="btn-show-hint"
                    disabled={disabled}
                    onClick={handleSubmit}
                >
                    <Iconsax iconName="more-square" fill="#9AA4B2" />
                    <span className="ml-2">Show hint</span>
                </button>
                <button
                    type="button"
                    className="btn-send ml-2"
                    disabled={disabled}
                    onClick={handleSubmit}
                >
                    <span className="mr-2">Send</span>  <Iconsax iconName="send" fill="#fff" />
                </button>
            </div>
        </div>
    );
};

export default InputFreeText;