import React, { useState, useEffect } from "react";

//test
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

//redux
import { useDispatch } from "react-redux";

//lodash
import _ from "lodash";

//constants
import { schemaContanst } from "@constants";

//call Api
import { apiCaller } from "@utils";

function SortableListColumns(props) {
    const dispatch = useDispatch();

    //props
    const { data, QuestionId } = props && props;
    const dataSort = _.map(data, i => _.pick(i, 'name', 'question_concept_id'));

    //state
    const [itemSort, setItemSort] = useState(dataSort || []);

    //Update Array Store
    useEffect(() => {
        const dataSort = _.map(data, i => _.pick(i, 'name', 'question_concept_id'));
        setItemSort(dataSort || []);
    }, [data]);

    const SortableItem = SortableElement(({ value }) => (
        <li className="lsQuestion__item -box-shadow SortableItemModal border py-3" data-id={`${value?.question_concept_id}`}>
            <div className="iconMove"></div>
            <span className="ml-5">{value?.name}</span>
        </li>
    ));

    const SortableList = SortableContainer(({ items }) => {
        return (
            <ul className="lsQuestion">
                {itemSort?.map((value, index) => (
                    <SortableItem key={`item-${index}`} index={index} value={value} />
                ))}
            </ul>
        );
    });

    //submit api
    const handleSubmitApi = (newData) => {
        const listUpdated = _.map(newData, 'question_concept_id');
        const params = {
            "Action": "SortConcepts",
            "QuestionId": QuestionId,
            "ObjectType": "Diagnosis",
            "QuestionConceptIds": listUpdated
        }

        dispatch({ type: schemaContanst.SORT_CONCEPTS_IN_A_SCHEMA_REQUEST, isLoading: true });
        apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
            const dataRes = res?.data;
            if (res?.status === 200) {
                dispatch({ 
                    type: schemaContanst.SORT_CONCEPTS_IN_A_SCHEMA_SUCCESS,
                    payload: dataRes
                });
            } else {
                dispatch({ 
                    type: schemaContanst.SORT_CONCEPTS_IN_A_SCHEMA_FAILURE,
                    error: 'error'
                });
                console.log('error');
            }
        });
    }

    //on Sort end
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newData = arrayMove(itemSort, oldIndex, newIndex);
        setItemSort(newData);
        handleSubmitApi(newData);
    };

    return (
        <SortableList
            onSortEnd={onSortEnd}
            lockAxis="y"
            helperClass="SortableQuestion"
            distance={1}
        />
    );
}

export default SortableListColumns;