import React, { useEffect, useState } from "react";

//reactstrap
import { Button, FormGroup, Label, Input } from "reactstrap";

// router
import { useParams } from "react-router-dom";

//components
import {
  Filter,
} from "../../AIRecords/Chat";

//queryString
import queryString from "query-string";

//untils
import { LoaderText, apiCaller } from "@utils";

//lodash
import _ from "lodash";

//Components common
import { ButtonDelete } from "@components-common/elements";
import { ModalDeleteCommon } from "@ui-partents/Modal";
import { DataEmty } from "@components-common";
import {
  ModalViewChat,
  ModelsPagination,
  ButtonUpdateData
} from "@components-common/AIChat";

//toast
import { toast } from 'react-toastify';

//format time
import moment from "moment";

function TableChat(props) {
  let { id } = useParams();
  let pageNumberLimit = 10;

  //check params
  let queryParams = queryString.parse(window.location.search);

  //state
  const [rowRecord, setRowRecord] = useState({});
  const [stateData, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [stateFilter, setStateFilter] = useState({
    session_id: queryParams?.session_id || null,
    user_name: queryParams?.user_name || null,
    from_date: null,
    to_date: null
  });

  // Get All Records - khi load page
  useEffect(() => {
    const fetchData = () => {
      const params = {
        action_type: "load_chat_data",
        page_size: pageNumberLimit,
        current_page: currentPage,
        session_id: parseInt(stateFilter?.session_id) || null,
        case_id: parseInt(id) || null,
        user_name: parseInt(stateFilter?.user_name) || null,
        from_date: stateFilter?.from_date || null,
        to_date: stateFilter?.end_date || null,
        is_done: null
      };
      setLoading(true);
      apiCaller(
        `/api/binh/openai/`,
        "POST",
        params
      ).then((res) => {
        const resData = res?.data;
        if (res?.status === 200) {
          setData(resData);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      });
    };
    currentPage && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageNumberLimit]);

  //props

  const [modal, setModal] = useState({
    chatModal: false,
    DeleteRow: false
  });

  const toggle = (type, row) => {
    setRowRecord(row);
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  //Handle Filter id
  const handleUpdateDataFilter = (type) => {
    const clickReset = type === "isReset";
    const params = {
      "action_type": "load_chat_data",
      "page_size": pageNumberLimit,
      "current_page": 1,
      "case_id": parseInt(id),
      "session_id": clickReset ? null : parseInt(stateFilter?.session_id),
      "user_name": clickReset ? null : stateFilter?.user_name,
      "from_date": clickReset ? null : moment(stateFilter?.from_date).format(),
      "to_date": clickReset ? null : moment(stateFilter?.to_date).format(),
      "is_done": false
    };
    setLoading(true);
    setCurrentPage(1);
    apiCaller(`/api/binh/openai/`, "POST", params).then(
      (res) => {
        const resData = res?.data;
        if (res?.status === 200) {
          setData(resData);
          setLoading(false);
        } else {
          console.log("error");
          setLoading(false);
        }
      }
    );

    // Clear
    if (clickReset) {
      setStateFilter({
        session_id: null,
        user_name: null,
        from_date: null,
        to_date: null
      });
      setCurrentPage(1);
    }
  };

  //Hanle Remove Record 
  const handleRemove = () => {
    const params = {
      "action_type": "delete_chatdata",
      "page_size": pageNumberLimit,
      "current_page": currentPage,
      "session_ids": stateFilter?.session_ids === null ? null : parseInt(stateFilter?.session_ids),
      "case_id": id,
      "user_name": stateFilter?.user_name === null ? null : stateFilter?.user_name,
      "from_date": stateFilter?.from_date === null ? null : stateFilter?.from_date,
      "to_date": stateFilter?.to_date === null ? null : stateFilter?.to_date,
      "is_done": false,
      "id": rowRecord?.id
    }

    setLoadingRemove(true);
    apiCaller(`/api/binh/openai/`, "POST", params).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        setModal((prevState) => ({ ...prevState, DeleteRow: false }));
        setLoadingRemove(false);
        setData(data);
      } else {
        setLoadingRemove(false);
        setModal((prevState) => ({ ...prevState, DeleteRow: false }));
      }
    });
  }

  //Handle Search
  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setStateFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  //Load Data After Submit Modal
  const handleUpdateData = (data) => {
    // Update state items
    let cloneData = [...stateData?.items];
    const indexItem = _.findIndex(stateData?.items, function (o) { return o.id === data?.id; });
    cloneData[indexItem] = data;
    setData({ ...stateData, items: cloneData });
  }

  //Update Status
  const handleChangeStatus = (e, index, item) => {
    // Update state items
    let cloneData = [...stateData?.items];
    const { checked } = e.target;
    cloneData[index][`is_done`] = checked;
    setData({ ...stateData, items: cloneData });

    //Call Api
    const params = {
      "action_type": "update_status_chat",
      "id": item?.id
    }

    apiCaller(`/api/binh/openai/`, "POST", params).then((res) => {
      if (res?.status === 200) {
        console.log('success');
      } else {
        console.log('error');
      }
    });
  }

  // Handle Import Data
  const hanldeImportData = () => {
    const params = { action_type: "import_chatdata" };
    setLoading(true);
    apiCaller(
      `/api/binh/openai/`,
      "POST",
      params
    ).then((res) => {
      const resData = res?.data;
      if (res?.status === 200) {
        let filterDataByCaseId = _.filter(resData?.item, function (o) { return parseInt(o.case_id) === parseInt(id) });
        setData(filterDataByCaseId);
        setLoading(false);
        toast.success(`Data update successfully !`, {
          position: "top-right"
        });

        //Reset
        setStateFilter({
          session_id: null,
          user_name: null,
          from_date: null,
          to_date: null
        });
        setCurrentPage(1);
      } else {
        console.log("error");
        setLoading(false);
      }
    });
  }

  // Handle Change Date
  const handleChangeDate = (date, type) => {
    setStateFilter({ ...stateFilter, [type]: date });
  }

  const rowItems = stateData?.items?.map((value, index) => {
    const isChecked = value?.is_done;
    return (
      <tr key={index}>
        <td>{value?.session_id}</td>
        <td>{value?.case_id}</td>
        <td>{moment(value?.session_created_at).format("LLL")}</td>
        <td>{value?.user_name}</td>
        <td className="text-center">
          <FormGroup check className="d-flex justify-content-center">
            <Label check>
              <Input
                type="checkbox"
                checked={isChecked}
                data-checked={value?.is_done}
                onChange={(e) => handleChangeStatus(e, index, value)}
                defaultValue={isChecked}
                name={`checkbox_${value?.id}`}
              />
            </Label>
          </FormGroup>
        </td>
        <td className="text-center">
          <Button
            className="btn btn-brand btn-sm"
            color="primary"
            onClick={() => toggle("chatModal", value)}
          >
            <i className="fa fa-eye mr-2" aria-hidden="true"></i> View
          </Button>
        </td>
        <td>{value?.score}</td>
        <td>
          <ButtonDelete handleDelete={() => toggle('DeleteRow', value)} />
        </td>
      </tr>
    );
  });

  return (
    <React.Fragment>
      <Filter
        {...props}
        handleSubmitFilter={handleUpdateDataFilter}
        handleChangeFilter={handleChangeFilter}
        stateFilter={stateFilter}
        currentPage={currentPage}
        handleResetFilter={() => {
          handleUpdateDataFilter("isReset");
        }}
        handleChangeDate={handleChangeDate}
      />
      <ButtonUpdateData
        {...props}
        hanldeImportData={hanldeImportData}
        loading={loading}
      />
      <div className="table-responsive position-relative">
        <table className="table table-bordered table-condensed">
          <thead>
            <tr>
              <th className="align-middle font-weight-500 text-nowrap">
                Session ID
              </th>
              <th className="align-middle font-weight-500 text-nowrap">
                Case ID
              </th>
              <th width="200px" className="align-middle font-weight-500 text-nowrap">
                Session Time
              </th>
              <th className="align-middle font-weight-500 text-nowrap">
                User
              </th>
              <th className="align-middle font-weight-500 text-nowrap text-center">
                Status
              </th>
              <th width="150px" className="align-middle font-weight-500 text-nowrap text-center">
                Data
              </th>
              <th className="align-middle font-weight-500 text-nowrap">
                Score
              </th>
              <th width="50px" className="align-middle font-weight-500 text-nowrap text-center"></th>
            </tr>
          </thead>
          <tbody>
            {rowItems}
            {loading && (
              <tr>
                <td colSpan={9}>
                  <LoaderText />
                </td>
              </tr>
            )}
            {stateData?.items?.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <DataEmty content="No Data" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <ModelsPagination
        {...props}
        loading={loading}
        currentData={stateData}
        stateCurrentPage={currentPage}
        handleSetCurrentPage={(pageClick) => setCurrentPage(pageClick)}
      />
      <ModalViewChat
        isOpen={modal.chatModal}
        toggle={() => toggle("chatModal", rowRecord)}
        rowRecord={rowRecord || {}}
        handleUpdateData={handleUpdateData}
      />
      <ModalDeleteCommon
        title={`Are you sure you want to delete this record ?`}
        sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
        isOpen={modal?.DeleteRow}
        toggle={() => toggle('DeleteRow', rowRecord)}
        handleRemove={handleRemove}
        isLoading={loadingRemove}
      />
    </React.Fragment>
  );
}

export default TableChat;