import React, { useRef } from 'react';
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

const RichTextCommon = (props) => {
    const editor = useRef(null);

    //props
    const {
        handleUpdateEditor,
        task,
        defaultValue,
        placeholder,
        timePointId
    } = props && props;
    
    return (
        <div className="richText mb-5">
            <div
                className="richText__content mt-4"
                data-task={task}
                data-timepoint-id={timePointId}
            >
                <JoditEditor
                    ref={editor}
                    value={defaultValue}
                    config={Config.CONFIG_JODIT}
                    placeholder={placeholder || ''}
                    tabIndex={1}
                    onBlur={(newContent, e) => handleUpdateEditor(newContent, e)}
                />
            </div>
        </div>
    );
}

export default RichTextCommon;