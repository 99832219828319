import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

const StyledCanvasWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.isActive ? "50px" : "0")};
  overflow: hidden;
  transition: height 0.5s ease;
`;

const StyledCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

const AudioWaveform = ({ stream }) => {
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const [isActive, setIsActive] = useState(false); // Trạng thái hiển thị canvas

  useEffect(() => {
    if (!stream) {
      setIsActive(false); // Ẩn canvas nếu không có stream
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);

    source.connect(analyser);
    analyser.fftSize = 256;

    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    // Xử lý độ phân giải dựa trên devicePixelRatio
    const devicePixelRatio = window.devicePixelRatio || 1;
    const width = 198; // Chiều rộng logic của canvas
    const height = 50; // Chiều cao logic của canvas

    canvas.width = width * devicePixelRatio; // Kích thước logic
    canvas.height = height * devicePixelRatio; // Kích thước logic
    canvas.style.width = `${width}px`; // Kích thước CSS
    canvas.style.height = `${height}px`; // Kích thước CSS
    ctx.scale(devicePixelRatio, devicePixelRatio); // Scale để đồng bộ

    setIsActive(true); // Hiển thị canvas khi có stream

    const draw = () => {
      analyser.getByteFrequencyData(dataArray);

      // Xóa canvas trước khi vẽ
      ctx.clearRect(0, 0, canvas.width / devicePixelRatio, canvas.height / devicePixelRatio);

      // Tạo gradient giống SVG
      const gradient = ctx.createLinearGradient(0, 0, width, 0); // Gradient cho chiều rộng logic
      gradient.addColorStop(0.127617, "#51A6D0");
      gradient.addColorStop(0.894027, "#0089C2");

      ctx.fillStyle = gradient;

      // Điều chỉnh chiều rộng, khoảng cách, và chiều cao tối thiểu
      const barWidth = 4; // Chiều rộng mỗi cột
      const gap = 2; // Khoảng cách giữa các cột
      const minBarHeight = 5; // Chiều cao tối thiểu
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const value = dataArray[i] / 255; // Chuẩn hóa giá trị từ 0-1
        const barHeight = Math.max(minBarHeight, height * value); // Đảm bảo chiều cao tối thiểu

        // Căn chỉnh chiều cao để bo tròn góc
        const y = (height - barHeight) / 2;

        // Vẽ hình chữ nhật bo góc
        ctx.beginPath();
        const radius = barWidth / 2; // Bo góc bằng nửa chiều rộng
        ctx.moveTo(x, y + radius);
        ctx.lineTo(x, y + barHeight - radius);
        ctx.arcTo(x, y + barHeight, x + radius, y + barHeight, radius);
        ctx.arcTo(x + barWidth, y + barHeight, x + barWidth, y + barHeight - radius, radius);
        ctx.lineTo(x + barWidth, y + radius);
        ctx.arcTo(x + barWidth, y, x + barWidth - radius, y, radius);
        ctx.arcTo(x, y, x, y + radius, radius);
        ctx.closePath();
        ctx.fill();

        x += barWidth + gap; // Di chuyển sang cột tiếp theo
      }

      animationRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationRef.current);
      audioContext.close();
    };
  }, [stream]);

  return (
    <StyledCanvasWrapper isActive={isActive}>
      <StyledCanvas ref={canvasRef} />
    </StyledCanvasWrapper>
  );
};

export default AudioWaveform;