export const PNORDER_REQUEST = "PNORDER_REQUEST";
export const PNORDER_SUCCESS= "PNORDER_SUCCESS";
export const PNORDER_FAILURE = "PNORDER_FAILURE";

export const PNORDER_UPDATE_REQUEST = "PNORDER_UPDATE_REQUEST";
export const PNORDER_UPDATE_SUCCESS= "PNORDER_UPDATE_SUCCESS";
export const PNORDER_UPDATE_FAILURE = "PNORDER_UPDATE_FAILURE";

export const CHECK_MY_ORDERS_REQUEST = "CHECK_MY_ORDERS_REQUEST";
export const CHECK_MY_ORDERS_SUCCESS= "CHECK_MY_ORDERS_SUCCESS";
export const CHECK_MY_ORDERS_FAILURE = "CHECK_MY_ORDERS_FAILURE";

export const pnorderConstants = {
    PNORDER_REQUEST: 'PNORDER_REQUEST',
    PNORDER_SUCCESS: 'PNORDER_SUCCESS',
    PNORDER_FAILURE: 'PNORDER_FAILURE',

    PNORDER_UPDATE_REQUEST: 'PNORDER_UPDATE_REQUEST',
    PNORDER_UPDATE_SUCCESS: 'PNORDER_UPDATE_SUCCESS',
    PNORDER_UPDATE_FAILURE: 'PNORDER_UPDATE_FAILURE',

    CHECK_MY_ORDERS_REQUEST: 'CHECK_MY_ORDERS_REQUEST',
    CHECK_MY_ORDERS_SUCCESS: 'CHECK_MY_ORDERS_SUCCESS',
    CHECK_MY_ORDERS_FAILURE: 'CHECK_MY_ORDERS_FAILURE',

    LOAD_PNORDER_REQUEST: 'LOAD_PNORDER_REQUEST',
    LOAD_PNORDER_SUCCESS: 'LOAD_PNORDER_SUCCESS',
    LOAD_PNORDER_FAILURE: 'LOAD_PNORDER_FAILURE',

    CREATE_INTERVENTION_FOR_PNORDER_REQUEST: 'CREATE_INTERVENTION_FOR_PNORDER_REQUEST',
    CREATE_INTERVENTION_FOR_PNORDER_SUCCESS: 'CREATE_INTERVENTION_FOR_PNORDER_SUCCESS',
    CREATE_INTERVENTION_FOR_PNORDER_FAILURE: 'CREATE_INTERVENTION_FOR_PNORDER_FAILURE',

    UPDATE_INTERVENTION_FOR_PNORDER_REQUEST: 'UPDATE_INTERVENTION_FOR_PNORDER_REQUEST',
    UPDATE_INTERVENTION_FOR_PNORDER_SUCCESS: 'UPDATE_INTERVENTION_FOR_PNORDER_SUCCESS',
    UPDATE_INTERVENTION_FOR_PNORDER_FAILURE: 'UPDATE_INTERVENTION_FOR_PNORDER_FAILURE',

    DELETE_INTERVENTION_FOR_PNORDER_REQUEST: 'DELETE_INTERVENTION_FOR_PNORDER_REQUEST',
    DELETE_INTERVENTION_FOR_PNORDER_SUCCESS: 'DELETE_INTERVENTION_FOR_PNORDER_SUCCESS',
    DELETE_INTERVENTION_FOR_PNORDER_FAILURE: 'DELETE_INTERVENTION_FOR_PNORDER_FAILURE',

    UPDATE_AVAILABILITY_FOR_PNORDER_REQUEST: 'UPDATE_AVAILABILITY_FOR_PNORDER_REQUEST',
    UPDATE_AVAILABILITY_FOR_PNORDER_SUCCESS: 'UPDATE_AVAILABILITY_FOR_PNORDER_SUCCESS',
    UPDATE_AVAILABILITY_FOR_PNORDER_FAILURE: 'UPDATE_AVAILABILITY_FOR_PNORDER_FAILURE',

    UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_REQUEST: 'UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_REQUEST',
    UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_SUCCESS: 'UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_SUCCESS',
    UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_FAILURE: 'UPDATE_INTERVENTION_ONE_ONLY_FOR_PNORDER_FAILURE',
    
    UPDATE_SCORE_FOR_PNORDER_REQUEST: 'UPDATE_SCORE_FOR_PNORDER_REQUEST',
    UPDATE_SCORE_FOR_PNORDER_SUCCESS: 'UPDATE_SCORE_FOR_PNORDER_SUCCESS',
    UPDATE_SCORE_FOR_PNORDER_FAILURE: 'UPDATE_SCORE_FOR_PNORDER_FAILURE',

    UPDATE_PNORDER_TF_UNIT_REQUEST: 'UPDATE_PNORDER_TF_UNIT_REQUEST',
    UPDATE_PNORDER_TF_UNIT_SUCCESS: 'UPDATE_PNORDER_TF_UNIT_SUCCESS',
    UPDATE_PNORDER_TF_UNIT_FAILURE: 'UPDATE_PNORDER_TF_UNIT_FAILURE',

    UPDATE_PN_AVAILABLE_IN_BATCH_REQUEST: 'UPDATE_PN_AVAILABLE_IN_BATCH_REQUEST',
    UPDATE_PN_AVAILABLE_IN_BATCH_SUCCESS: 'UPDATE_PN_AVAILABLE_IN_BATCH_SUCCESS',
    UPDATE_PN_AVAILABLE_IN_BATCH_FAILURE: 'UPDATE_PN_AVAILABLE_IN_BATCH_FAILURE'
};