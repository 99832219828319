import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getListCaseScript, removeCaseScript } from "@actions";
import { useHistory } from 'react-router-dom'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Icon } from '@components/common'
import File from '@images/teachV2/file-upload.svg';
import { LoaderText } from "@utils";

//ui-partents
import { SearchCustomForTable } from "@ui-partents/Search";
import { MessageEmtyData } from "@ui-partents/Message";
import { WrapModalDeleteCommon } from "@ui-partents/Modal";
import { BtnIconDeleteV2, BtnIconEditV2 } from "@ui-partents/Button";
import { DateFormatter } from "@ui-partents/DateTime";

// Import the pagination configuration
import { paginationOptions } from "@utils";

const TableLibrary = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const listCase = useSelector((state) => state?.getListCaseScript || []);

  const handleEditButtonClick = (id) => {
    history.push({
      pathname: `/teach/script/${id}`,
      state: {  // location state
        listCase: true,
      },
    })
  };

  useEffect(() => {
    dispatch(getListCaseScript({
      action_type: "list_case_scripts"
    }));
  }, [dispatch])

  const columns = [
    {
      dataField: "title",
      text: "Title",
      headerClasses: "font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            <Icon src={File} />
            <p className='ml-1'>{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "updated_at",
      text: "Date Created",
      headerClasses: "font-weight-500",
      formatter: (cell, row) => {
        return (
          <DateFormatter date={cell} format="MMMM Do YYYY, h:mm:ss A" />
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "font-weight-500 text-center",
      headerStyle: { width: "72px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-start">
            <BtnIconEditV2
              handleEditButtonClick={() => handleEditButtonClick(row.id)}
              colorIcon={`#16B364`}
            />
            <WrapModalDeleteCommon
              title={`Are you sure you'd like to delete this case scenario?`}
              sub={`This action is not reversible`}
              handleRemove={handleRemove}
              idRemove={row?.id}
            >
              {({ onOpen }) => (
                <BtnIconDeleteV2 handleDelete={onOpen} />
              )}
            </WrapModalDeleteCommon>
          </div>
        );
      },
    }
  ];

  const handleRemove = (idDelete) => {
    const params = {
      action_type: "delete_case_script",
      id: idDelete,
    };
    dispatch(removeCaseScript(params));
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="id"
        data={listCase?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-library__filter d-flex align-items-center justify-content-between">
              <p>Total Case Scenarios: {listCase?.data?.length}</p>
              <div className="boxSearch">
                <SearchCustomForTable
                  toolkitprops={toolkitprops}
                  paginationProps={paginationProps}
                  isResetPagination
                  searchMode="auto"
                  placeholder={`Search`}
                />
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={(listCase?.data && listCase?.data) || []}
              columns={columns}
              noDataIndication={() => (
                <MessageEmtyData
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const totalSize = listCase?.data?.length || 0; // Dynamically determine the total size

  // Dynamically generate pagination configuration
  const paginationConfig = paginationOptions({ totalSize });
  return (
    <div className="table-library position-relative">
      {listCase?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
      ) : (
        listCase?.data?.length === 0 ? (
          <MessageEmtyData
            title="Your Library is Empty"
            text="It looks like you haven't generated any case scenarios yet. Start creating a new one to see it here."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(paginationConfig)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
      {/* <ModalDeleteCommon
        title={`Are you sure you'd like to delete this case scenario?`}
        sub={`This action is not reversible`}
        isOpen={modal.delete}
        toggle={() => toggle('delete', {})}
        handleRemove={handleRemove}
      /> */}
    </div>
  )
}

export default TableLibrary
