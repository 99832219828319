import React, { useState, useEffect, useMemo } from "react";
import { Container, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";

//components
import { TableLearners } from "@components/instructor";
import { TemplateMain } from "@components/common/templates";
import { ListCase } from '@components/Administrator/cases';
import { Billing, CustomizableElementTemplates } from '@components/Administrator';
import { ToursAssistantsMain } from '@components/Administrator/ToursAssistants';

//actions
import {
  actFetchAccountInstitutionRequest,
  actFetchTemplate,
  actFetchAllCase
} from "@actions";

//utils
import { useQuery, LoaderPage } from "@utils";

function InstructorPage(props) {
  let query = useQuery();
  let slug = query.get("active");
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actFetchAccountInstitutionRequest());
    dispatch(actFetchTemplate());
    dispatch(actFetchAllCase());
  }, [dispatch]);

  useEffect(() => {
    const slugTabMap = {
      'user': 0,
      'case': 2,
      'billing': 3,
    };
    setActiveTab(slugTabMap[slug] || 0);
  }, [slug]);

  const { accountInstitution, loadTemplates, loadInstitutionAllCases } = useSelector(state => ({
    accountInstitution: state.accountInstitution || [],
    loadTemplates: state?.loadTemplates || {},
    loadInstitutionAllCases: state.loadInstitutionAllCases?.data || [],
  }));

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const listTabs = useMemo(() => ([
    { name: 'Users', component: <TableLearners data={accountInstitution} /> },
    { name: 'Templates', component: <TemplateMain templatesStore={loadTemplates} type={`administrator`} /> },
    { name: 'Cases', component: <ListCase dataInstitution={loadInstitutionAllCases} /> },
    { name: 'Billing', component: <Billing {...props} /> },
    { name: 'Tours / Assistants', component: <ToursAssistantsMain {...props} /> },
    { name: 'Customizable Element Templates', component: <CustomizableElementTemplates {...props} /> },
  ]), [accountInstitution, loadTemplates, loadInstitutionAllCases, props]);

  return (
    <div className="wrapper -administratorPage">
      {loadTemplates.isLoading && <LoaderPage />}
      {!loadTemplates.isLoading && (
        <div className="administratorCt bg-white p-4">
          <Container>
            <Nav className="tabBorder" tabs>
              {listTabs.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === index })}
                    onClick={() => toggle(index)}
                  >
                    <i className={`${item.icon} mr-2`} aria-hidden="true"></i>
                    {item.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              {listTabs.map((item, index) => (
                <TabPane key={index} tabId={index}>
                  {item.component}
                </TabPane>
              ))}
            </TabContent>
          </Container>
        </div>
      )}
    </div>
  );
}

export default InstructorPage;