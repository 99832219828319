import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//lodash
import _ from "lodash";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { caseConstants } from "@constants";

//alert
import { toast } from 'react-toastify';

//Main Box
import { PatientMainOnlyHeading } from "../../StandardScreen";

//Actions
import { ActionsCategory, ActionsSelected } from "../../StandardScreen/Actions";

function ActionsMain(props) {
  let { id } = useParams();
  //props
  const { handleOverideEffectMenu } = props && props;
  const dataProps = props && props.data;
  const dataResponse = dataProps && dataProps.data;

  //state
  const [dataSub, setDataSub] = useState([]);
  const [stateCategory, setCategory] = useState([]);
  const [idClick, setIdClick] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //check state update
  useEffect(() => {
    const dataFilter = filterDataSub(dataProps, stateCategory);
    if (dataFilter && dataFilter.length > 0) {
      setDataSub(dataFilter);
    }
  }, [dataProps, stateCategory, dataResponse]);

  const handleSubMenu = (value) => {
    setCategory(value?.CategoryName);

    const dataFilter = filterDataSub(dataResponse, value?.CategoryName);
    if (dataFilter && dataFilter.length > 0) {
      setDataSub(dataFilter);
    }
  };

  const filterDataSub = (data, cate) => {
    const filterCat = _.map(data, function (o) {
      if (o.CategoryName === cate) return o;
    });
    const dataJoin = _.without(filterCat, undefined);
    const dataSub = dataJoin && dataJoin[0] && dataJoin[0].Items;
    return dataSub;
  };

  const handleOnClick = (value) => {
    const actionId = value.Id;
    const actionRecordId = value.ActionRecordId;
    const status = value.State;
    const actionName = value.Name;
    if (status === "inactive") {
      const params = {
        Action: "Add",
        ActionId: actionId,
        ActionName: actionName
      };
      actPutActionsTask(id, params);
      setIdClick(actionId);
    }

    if (status === "active") {
      const params = {
        Action: "Remove",
        ActionId: actionId,
        ActionRecordId: actionRecordId,
      };
      actPutActionsTask(id, params);
      setIdClick(actionId);
    }
  };

  const actPutActionsTask = (idCase, params) => {
    setLoading(true);
    //Call Api
    apiCaller(`/api/learn/case/actions/${idCase}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      const dataAlert = data && data.Alert;
      const alertSeverity = dataAlert && dataAlert.Severity;
      const alertDuration = dataAlert && dataAlert.ShowDuration;
      if (res && res.status === 200) {
        dispatch({ type: caseConstants.PUT_ACTIONS_TASK_SUCCESS, payload: data });
        setLoading(false);
        if (dataAlert) {
          //update list actions
          if (alertSeverity && alertSeverity === "success") {
            toast.success(dataAlert.Title, {
              autoClose: alertDuration * 1000,
              toastId: 'PUT_ACTIONS_TASK_REQUEST'
            });
          }
          if (alertSeverity && alertSeverity === "warning") {
            toast.warning(dataAlert.Title, {
              autoClose: alertDuration * 1000,
              toastId: 'PUT_ACTIONS_TASK_REQUEST'
            });
          }
          if (alertSeverity && alertSeverity === "danger") {
            toast.error(dataAlert.Title, {
              autoClose: alertDuration * 1000,
              toastId: 'PUT_ACTIONS_TASK_REQUEST'
            });
          }
        }
      } else {
        dispatch({
          type: caseConstants.PUT_ACTIONS_TASK_FAILURE,
          error: "error",
        });
        setLoading(false);
      }
    });
  }

  return (
    <PatientMainOnlyHeading
      {...props}
      heading="Case Actions"
      iconPanel={`icon-actions-2`}
    >
      <div className="actions-task-grid">
        <div className="actions-task-grid__left">
          <h3 className="heading-patient-sm">Category</h3>
          <div
            style={{
              height: 'calc(100% - 50px)',
              overflowY: 'auto'
            }}
          >
            <ActionsCategory
              {...props}
              data={dataResponse}
              handleSubMenu={handleSubMenu}
              stateCategory={stateCategory}
            />
          </div>
        </div>
        <div className="actions-task-grid__right">
          <h3 className="heading-patient-sm">Actions</h3>
          <div
            style={{
              height: 'calc(100% - 50px)',
              overflowY: 'auto'
            }}
          >
            <ActionsSelected
              {...props}
              dataSub={dataSub}
              handleOnClick={handleOnClick}
              isLoading={isLoading}
              idClick={idClick}
            />
          </div>
        </div>
      </div>
    </PatientMainOnlyHeading>
  );
}

export default ActionsMain;
