import React from "react";

//Form
import { AddUpdateFoodForm } from "@components/Makansafe/AddUpdateFood";

function AddNewFoodPage(props) {
    return (
        <div className="makansafe-homepage">
            <div className="boxPortlet">
                <AddUpdateFoodForm title={`Add New Food Item`} />
            </div>
        </div>
    );
}

export default AddNewFoodPage;