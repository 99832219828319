import React from "react";
import { Col, Button } from "reactstrap";

//components
import { ButtonEdit, ButtonAdd } from "../../../../../../components/common/elements";

//format time
import moment from "moment";
import { LoaderText } from "@utils";

//lodash
import _ from "lodash";

function ListAddReview(props) {
    const { loggingItem, handleIsEditable, handleEdit, isLoading } = props && props;
    const review_sections = loggingItem?.review_sections || [];
    const itemEditTableForAdd = _.find(review_sections, function (o) { return o.is_editable === true });
    return (
        <Col md={4} className="h-100" style={{ overflowY: 'auto' }}>
            {isLoading?.editReviewForLogging && <LoaderText />}
            {!isLoading?.editReviewForLogging && (
                <div className="listAddReview">
                    {review_sections?.map((review, index) => (
                        <div className="d-flex justify-content-between align-content-center border-bottom py-2" key={index}>
                            <div className="d-flex flex-column">
                                <p>{moment(review.created_at).format("LLL")}</p>
                                <p>{review.email}</p>
                            </div>
                            <div className="ml-2">
                                {review?.is_editable ? (
                                    <ButtonEdit handleEdit={() => handleEdit(review, "Edit")} />
                                ) : (
                                    <Button color="primary" className="btn-sm mx-1 --btn-primary" onClick={() => handleIsEditable(review, "View")}>
                                        <i className="fa fa fa-eye" aria-hidden="true"></i>
                                    </Button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {review_sections?.length === 0 && (
                <p className="text-center my-4">
                    No reviews are available <br />
                    Click button below to create one
                </p>
            )}
            <div className="mt-3 text-center">
                <ButtonAdd
                    {...props}
                    text={"Add Review"}
                    handleAdd={() => handleIsEditable(itemEditTableForAdd || {}, "Add")}
                />
            </div>
        </Col>
    );
}

export default ListAddReview;