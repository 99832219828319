import React from 'react'
import analyze from '@images/teachV2/analyze.svg'
import TitleNoteCommon from '../home/generate/TitleNoteCommon'
import { Controller } from 'react-hook-form'
import { Input } from 'reactstrap';
import ClipLoader from "react-spinners/ClipLoader";
import { HeadingTitle } from '@ui-partents/Heading'

export default function FormAnalyzeQuestion(props) {
  const { control, watch, loadingResult } = props

  return (
    <div className="formFreeText">
      <HeadingTitle
        icon={analyze}
        title="Create New Free-Text Question"
        isLight={true}
      />
      <div className="formFreeText__form">
        <div className="formFreeText__form-input">
          <div className='input-form'>
            <TitleNoteCommon title="Question Text" required/>
            <Controller
              name="question"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='question'
                  placeholder="Enter the text of the question here…"
                  style={{ height: '80px' }}
                  {...field}
                />
              )}
            />
          </div>
          <div className='input-form'>
            <TitleNoteCommon title="Rubrics" required/>
            <Controller
              name="rubrics"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='rubrics'
                  placeholder="Enter your rubrics here…"
                  style={{ height: '80px' }}
                  {...field}
                />
              )}
            />
          </div>
          <div className='input-form'>
            <TitleNoteCommon title="Model Answer"/>
            <Controller
              name="answer"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='answer'
                  placeholder="Enter a model answer here..."
                  style={{ height: '80px' }}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <button className={`${loadingResult || !watch("question") || !watch("rubrics") ? "disabled" : ""} formFreeText__form-button`} type="submit">
          Create
          {loadingResult && <ClipLoader color="#FFF" size={16}/>}
        </button>
      </div>
    </div>
  )
}
