import React, { useState, useEffect } from 'react';

//boostrap
import { Col, Button } from "reactstrap";

//components
import { ModalUpload } from '../AvatarUpdate';

//Image Default
import logoImg from "@images/common/no-avatar.jpg";

function AvatarItem(props) {
    const { name, srcImage } = props && props;

    //state
    const [modal, setModal] = useState({
        Preceptor: false,
        Patient: false,
    });
    const [urlImage, setUrlImage] = useState((srcImage && srcImage) || '');

    useEffect(() => {
        setUrlImage((srcImage && srcImage) || '');
    }, [srcImage])

    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };
    
    return (
        <Col md={6} className="d-flex justify-content-center">
            <div className="avatarBox">
                <div className="avatarBox__img" onClick={() => toggle(name)}>
                    <img className="avatarBox__img--detail" src={urlImage || logoImg} alt="avatar" />
                </div>
                <p className="font-weight-500 mt-3">{name}</p>
                <Button className="btn btn-sm btn-primary mt-2" onClick={() => toggle(name)}>Change</Button>
            </div>
            <ModalUpload
                {...props}
                isOpen={modal[name]}
                toggle={() => toggle(name)}
            />
        </Col>
    );
};

export default AvatarItem;