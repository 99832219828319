import React from 'react';

//boostrap
import { Label, CustomInput } from "reactstrap";

function ChangeOrderOptions(props) {
    const { handleChange, typeSort } = props && props;
    return (
        <div className="d-flex mb-4">
            <Label className="d-flex align-items-center">
                <CustomInput
                    id={`type`}
                    type="radio"
                    name="type"
                    onClick={handleChange}
                    value="Columns"
                    defaultChecked={typeSort === 'Columns'}
                />
                Columns
            </Label>
            <Label className="d-flex align-items-center ml-2">
                <CustomInput
                    id={`type`}
                    type="radio"
                    name="type"
                    onClick={handleChange}
                    value="Rows"
                    defaultChecked={typeSort === 'Rows'}
                />
                Rows
            </Label>
        </div>
    );
};

export default ChangeOrderOptions;