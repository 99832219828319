import React from "react";
import {
  Row,
  Col,
  Input
} from "reactstrap";

function SortBy(props) {
  const { handleSort } = props && props;
  return (
    <Row>
      <Col sm={12}>
        <div className="d-flex align-items-center">
          <span className="text-2 text-nowrap">Sort By: </span>
          <div style={{ maxWidth: '300px' }}>
            <Input
              id="sortBy"
              name="sortBy"
              type="select"
              className="ml-3"
              onChange={handleSort}
            >
              <option value="">None</option>
              <option value={1}>Case Title - A-Z</option>
              <option value={2}>Case Title - Z-A</option>
              <option value={3}>Last Edited (Latest first)</option>
              <option value={4}>Last Edited (Earliest first)</option>
            </Input>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default SortBy;
