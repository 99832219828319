import React from "react";

//boostrap
import { Container, Row } from "reactstrap";

//components
import { FormRegister } from "@components/UcrComponent";

function RegisterUcrPage() {
  return (
    <div className="wrapper -registerNusPage">
      <div className="registerContainer">
        <Container>
          <Row className="align-items-center">
            <FormRegister />
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default RegisterUcrPage;