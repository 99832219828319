import React, { useState, useEffect, useCallback } from "react";
import { Button, UncontrolledAlert } from "reactstrap";

//components
import {
  ModalAddLearner,
  ModalDeleteCohorts,
  ModalAddCohort,
  ModalDeleteLearner,
  ModalEditCohort,
} from "../Modal";

//utils
import { LoaderText, messageData } from "@utils";

//elements
import { ButtonDelete } from '@components-common/elements';

//actions
import { atcLoadCohortsById, loadAuthCodeListById } from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import { CohortTab, TabsContainer, AuthTab } from "../Cohorts";

function Cohorts(props) {
  //props
  const { cohortStore, toggleTab } = props && props;
  const dataCohort = cohortStore?.data;

  //state
  const [cohortData, setCohortData] = useState((dataCohort && dataCohort) || []);
  const [cohortObject, setcohortObject] = useState({});
  const [loadData, setLoadData] = useState(true);
  const [itemLearner, setItemLearner] = useState({});
  const dispatch = useDispatch();

  //onLoad
  useEffect(() => {
    setCohortData((dataCohort && dataCohort) || []);
  }, [dataCohort]);

  const [modal, setModal] = useState({
    addCohort: false,
    addLearner: false,
    editCohort: false,
    deleteCohort: false,
    deleteLearner: false,
  });

  const toggle = (type) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  const columns = [
    {
      dataField: "firstname",
      text: "First Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "lastname",
      text: "Last Name",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "Actions",
      text: "",
      style: {
        width: "10%",
        whiteSpace: "nowrap",
      },
      headerClasses: "font-weight-400",
      formatter: (cell, row) => {
        const handleRemoveLearner = (item) => {
          toggle("deleteLearner");
          setItemLearner(item);
        };

        return (
          <div className="text-right">
            <ButtonDelete handleDelete={() => handleRemoveLearner(row)} />
          </div>
        );
      },
    },
  ];

  const loadCohortsById = (data) => {
    callApiCohortsById(data?.CohortId);
    callAuthCodeById(data.CohortId);
    setcohortObject(data);
  };

  const callApiCohortsById = useCallback(
    (Id) => {
      //load api
      const params = {
        Action: "Load",
        CohortId: Id,
      };
      dispatch(atcLoadCohortsById(params));
    },
    [dispatch]
  );

  const callAuthCodeById = (id) => {
    dispatch(loadAuthCodeListById(id));
  };

  const reloadDataAfterDelete = () => {
    callApiCohortsById(cohortData && cohortData[0]?.CohortId);
    setcohortObject(cohortData && cohortData[0]);
  }

  //Active item first/Load API
  useEffect(() => {
    //load object first
    if (loadData && (cohortData && cohortData.length > 0)) {
      callApiCohortsById(cohortData && cohortData[0]?.CohortId);
      setcohortObject(cohortData && cohortData[0]);
      setLoadData(false);
    }
  }, [cohortData, callApiCohortsById, loadData]);

  //data Store
  const dataLearner = useSelector(
    (state) =>
      (state.dataLearnersByCohortId && state.dataLearnersByCohortId.data) || []
  );
  const dataInstitution = useSelector(
    (state) =>
      (state.accountInstitution &&
        state.accountInstitution.data &&
        state.accountInstitution.data.Accounts) ||
      []
  );

  //filter tow array
  let newDataLearner = dataInstitution.filter(
    (o1) => !dataLearner.some((o2) => o1.Email === o2.email)
  );

  const listTabs = [
    {
      id: "1",
      name: "Learners",
      component: (
        <CohortTab
          newDataLearner={newDataLearner}
          toggle={toggle}
          dataLearner={dataLearner}
          columns={columns}
          message={
            cohortObject?.CohortId ? messageData?.cohortsEmty?.loaddLearnerByid : messageData?.cohortsEmty?.loadAll
          }
        />
      ),
    },
    {
      id: "2",
      name: "Auth Codes",
      component: (
        <AuthTab
          cohortActive={cohortObject?.CohortId}
          message={cohortObject?.CohortId ? "No Data" : messageData?.cohortsEmty?.loadAll}
        />
      ),
    },
  ];
  
  return (
    <React.Fragment>
      <UncontrolledAlert
        className="m-alert--default border-0 text-left"
        color="secondary"
      >
        You can group learners into cohorts to automatically assign series of
        cases (via <span style={{ cursor: 'pointer' }} onClick={toggleTab} className="--text-primary">launch plan</span>), <br />{" "}
        or to compare & track their performances.
      </UncontrolledAlert>
      <div className="modulesTab">
        <div className="modulesTab__left">
          <ul className="navSidebar">
            {cohortData?.map((value, index) => (
              <li
                data-id={value.CohortId}
                className="navSidebar__item"
                key={index}
                onClick={() => loadCohortsById(value)}
              >
                <span
                  className={`refLink ${cohortObject?.CohortId === value.CohortId && "--text-primary"
                    }`}
                >
                  {value.Name}{" "}
                  <i
                    className="fa fa-chevron-right ml-auto"
                    aria-hidden="true"
                  />
                </span>
              </li>
            ))}
          </ul>
          <hr />
          <div className="text-center">
            <Button
              className="--btn-primary"
              onClick={() => toggle("addCohort")}
            >
              <i className="fa fa-plus mr-2" aria-hidden="true"></i>
              Add Cohort
            </Button>
          </div>
        </div>
        <div className="modulesTab__right position-relative">
          {cohortStore.isLoading && <LoaderText />}
          {!cohortStore.isLoading && <TabsContainer data={listTabs} />}
        </div>
        <ModalAddLearner
          isOpen={modal.addLearner}
          toggle={() => toggle("addLearner")}
          actions={modal}
          data={newDataLearner}
          cohortObject={cohortObject}
        />
        <ModalDeleteCohorts
          isOpen={modal.deleteCohort}
          toggle={() => toggle("deleteCohort")}
          actions={modal}
          cohortObject={cohortObject}
          reloadDataAfterDelete={reloadDataAfterDelete}
        />
        <ModalAddCohort
          isOpen={modal.addCohort}
          toggle={() => toggle("addCohort")}
          actions={modal}
        />
        <ModalEditCohort
          isOpen={modal.editCohort}
          toggle={() => toggle("editCohort")}
          activeCohortAfterEdit={loadCohortsById}
          actions={modal}
          cohortObject={cohortObject}
        />
        <ModalDeleteLearner
          isOpen={modal.deleteLearner}
          toggle={() => toggle("deleteLearner")}
          item={itemLearner}
          cohortObject={cohortObject}
        />
      </div>
    </React.Fragment>
  );
}

export default Cohorts;