import React from "react";

// common
import { HeadingBox } from "@components/common";

//boostrap
import { Container, Row, Col, Card } from "reactstrap";

//components
import { TabMain } from "@components/reports/AIRecords";

function AIRecordsPage(props) {
  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;
  return (
    <div className="wrapper -reportPage">
      <div className="reportCt">
        <Container>
          <Row>
            <Col sm={12}>
              <HeadingBox title={titlePage || ""} currentPage="Ai Records" />
            </Col>
            <Col md={12}>
              <Card className="border-0 -box-shadow p-4 position-relative">
                <TabMain {...props} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default AIRecordsPage;