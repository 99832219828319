import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

//reactstrap
import { Container } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

//components
import { VitalSigns } from "@components/elements";
import { HeadingBox } from "@components/common";

//actions
import { loadVitalSignsElements } from "@actions";

function VitalSignsPage(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //call Api
  useEffect(() => {
    const fetchData = () => {
      const params = {
        Action: "Load",
        Target: "VitalSigns",
      };
      dispatch(loadVitalSignsElements(id, params));
    };
    fetchData();
  }, [dispatch, id]);

  //props
  const { caseDetails } = props && props;

  //titlePage
  const titlePage = caseDetails?.Title;

  //storeVitalSign
  const storeVitalSign = useSelector(
    (state) => state?.dataVitalSignsByCaseId || {}
  );

  const listData = storeVitalSign?.data;
  return (
    <div className="wrapper -eVitalSignsPage">
      <section className="eVitalSignsCt">
        <Container>
          <HeadingBox title={titlePage || ""} currentPage="Vital Signs" />
          <VitalSigns storeVitalSign={storeVitalSign} data={(listData && listData) || []} />
        </Container>
      </section>
    </div>
  );
}

export default VitalSignsPage;
