import React, { useState, useEffect } from "react";

//imgPerson
import imgPerson from "@images/common/person.png";
import imgUser from '@images/common/placehoder-avatar.svg';

function ChatRecordsContent(props) {
    const { ChatRecords, profile_photo_url, heightPatientMedRecord } = props && props;

    //state
    const [stateData, setData] = useState(ChatRecords || []);

    // 
    useEffect(() => {
        setData(ChatRecords || []);
    }, [ChatRecords])
    
    return (
        <div className="isShowScroll" style={{ maxHeight: `${heightPatientMedRecord - 100}px` }}>
            <div className="messengerCt">
                <div className="messengerCt__messages">
                    {stateData?.map((item, index) =>
                        <div className="messengerCt__wrapper" data-role={item?.Role} key={index}>
                            <div className="messengerCt__message messengerCt__message--in">
                                <div className="messengerCt__message-pic">
                                    <img
                                        src={`${item?.Role === "You" ? profile_photo_url || imgUser : imgPerson}`}
                                        alt=""
                                    />
                                </div>
                                <div className="messengerCt__message-body position-relative">
                                    <div
                                        className={`messengerCt__message-content --arrow-left ${index % 2 === 0 ? "--bg-orange" : ""}`}
                                    >
                                        <div className="messengerCt__message-text">
                                            {item?.Content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {stateData?.length === 0 && (
                        <div className="d-block"><h6 className="message-sp text-center">No chat records currently available</h6></div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ChatRecordsContent;