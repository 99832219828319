import React, { useEffect, useState } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";

function TemplateOptions(props) {
    // Props
    const { handleTemplateChange, initData, listInterfaceTemplates } = props && props;

    // State
    const [selectedOption, setSelectedOption] = useState(null);
    const [listTemplate, setTemplate] = useState([]);

    // Load Template
    useEffect(() => {
        const templates = listInterfaceTemplates?.map((template) => ({
            value: template.InterfaceTemplateId,
            label: template.Name,
        }));
        setTemplate(templates);
    }, [listInterfaceTemplates]);

    // Load Selected Option
    useEffect(() => {
        const InterfaceTemplateId = initData?.InterfaceTemplate?.InterfaceTemplateId;

        // Find the selected option based on InterfaceTemplateId
        const selected = listTemplate.find((template) => template.value === InterfaceTemplateId);

        setSelectedOption(selected || null);
    }, [initData, listTemplate]);
    
    return (
        <FormGroup>
            <Label for="template">Select Template</Label>
            <Select
                closeMenuOnSelect={true}
                onChange={handleTemplateChange}
                options={listTemplate}
                placeholder={"Select a template"}
                value={selectedOption} // Use the selected option instead of ID
            />
        </FormGroup>
    );
}

export default TemplateOptions;