import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

//reactstrap
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, Row, Col, FormFeedback } from 'reactstrap';

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//utils
import { apiCaller } from "@utils";

//constants
import { storyboardConstants } from "@constants";

// Định nghĩa schema validation
const validationSchema = Yup.object().shape({
    items: Yup.string().required('Items is required'),
    initialValue: Yup.number().required('Initial Value is required'),
    unit: Yup.string().required('Unit is required'),
});

const ModalVitalSign = ({ isOpen, toggleModal, items, timepointData, typeModal }) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    // Khởi tạo giá trị ban đầu cho form
    const initialValues = {
        items: items.Name || '',
        initialValue: items.Value || '',
        unit: items.Unit || '',
        minRange: items.NormalMin || '',
        maxRange: items.NormalMax || ''
    };

    // Xử lý submit form
    const handleSubmit = (values, actions) => {
        const params = {
            Action: typeModal,
            TimePointId: timepointData?.Id,
            VitalSign: {
                ...(typeModal === "Update" && { Id: items.Id }),
                Name: values.items,
                ...(typeModal === "Create" && { InitialValue: values.initialValue }),
                ...(typeModal === "Update" && { Value: values.initialValue }),
                Unit: values.unit,
                NormalMax: values.maxRange || 0,
                NormalMin: values.minRange || 0
            },
        };
        params["Target"] = typeModal === "Update" ? "TimePoint" : "VitalSign";
        setIsLoading(true);

        const requestType = typeModal === "Update" ? storyboardConstants.UDPATE_VALUE_VITAL_REQUEST : storyboardConstants.CREATE_VITAL_REQUEST;
        const successType = typeModal === "Update" ? storyboardConstants.UDPATE_VALUE_VITAL_SUCCESS : storyboardConstants.CREATE_VITAL_SUCCESS;
        const failureType = typeModal === "Update" ? storyboardConstants.UDPATE_VALUE_VITAL_FAILURE : storyboardConstants.CREATE_VITAL_FAILURE;
        dispatch({ type: requestType, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setIsLoading(false);
                dispatch({
                    type: successType,
                    payload: data
                });
                toggleModal(typeModal);
            } else {
                setIsLoading(false);
                dispatch({
                    type: failureType,
                    error: "error",
                });
                toggleModal(typeModal);
            }
        });
        actions.setSubmitting(false);
    };

    return (
        <Modal isOpen={isOpen} toggle={() => toggleModal(typeModal)} size="lg">
            <ModalHeader toggle={() => toggleModal(typeModal)}>{typeModal === "Create" ? "Create" : "Edit"} Vital Sign</ModalHeader>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ values, errors, handleChange, handleBlur, isSubmitting, touched }) => (
                    <Form>
                        <ModalBody>
                            <FormGroup>
                                <Label className="labeForm" for="items">Items <span className="text-danger">*</span></Label>
                                <Input
                                    type="text"
                                    name="items"
                                    id="items"
                                    tag={Field}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={!!errors.items && touched.items}
                                    value={values.items}
                                />
                                <FormFeedback>{errors.items}</FormFeedback>
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="labeForm" for="initialValue">Initial Value <span className="text-danger">*</span></Label>
                                        <Input
                                            type="number"
                                            name="initialValue"
                                            id="initialValue"
                                            tag={Field}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.initialValue && touched.initialValue}
                                            value={values.initialValue}
                                        />
                                        <FormFeedback>{errors.initialValue}</FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="labeForm" for="unit">Unit <span className="text-danger">*</span></Label>
                                        <Input
                                            type="text"
                                            name="unit"
                                            id="unit"
                                            tag={Field}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            invalid={!!errors.unit && touched.unit}
                                            value={values.unit}
                                        />
                                        <FormFeedback>{errors.unit}</FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col md={12}>
                                    <p className='font-weight-500' style={{color: "#0089C2"}}>Reference Range</p>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="labeForm" for="minRange">Min</Label>
                                        <Input
                                            type="number"
                                            name="minRange"
                                            id="minRange"
                                            tag={Field}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.minRange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label className="labeForm" for="maxRange">Max</Label>
                                        <Input
                                            type="number"
                                            name="maxRange"
                                            id="maxRange"
                                            tag={Field}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.maxRange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type="submit" disabled={isSubmitting || isLoading}>
                                {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                {typeModal === "Create" ? "Create" : "Save"}
                            </Button>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                        </ModalFooter>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalVitalSign;