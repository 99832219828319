import { associatedSchemaConstant } from "@constants";
import { apiCaller } from "@utils";

//Load Associated Schema
export const atcLoadAssociatedSchema = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_REQUEST, isLoading } }
    function success(data) { return { type: associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_SUCCESS, payload: data } }
    function failure(error) { return { type: associatedSchemaConstant.LOAD_ASSOCIATED_SCHEMA_FAILURE, error: error } }
}

//Order Associated Schema
export const actOrderAssociatedSchema = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: associatedSchemaConstant.ORDER_ASSOCIATED_SCHEMA_REQUEST, isLoading } }
    function success(data) { return { type: associatedSchemaConstant.ORDER_ASSOCIATED_SCHEMA_SUCCESS, payload: data } }
    function failure(error) { return { type: associatedSchemaConstant.ORDER_ASSOCIATED_SCHEMA_FAILURE, error: error } }
}

//Delete Associated Schema
export const actDeleteAssociatedSchema = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/concept/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: associatedSchemaConstant.DELETE_ASSOCIATED_SCHEMA_REQUEST, isLoading } }
    function success(data) { return { type: associatedSchemaConstant.DELETE_ASSOCIATED_SCHEMA_SUCCESS, payload: data } }
    function failure(error) { return { type: associatedSchemaConstant.DELETE_ASSOCIATED_SCHEMA_FAILURE, error: error } }
}