import React from "react";

function Measurements(props) {
  const propsData = props && props.data;
  const propsType = props && props.type;
  const objectEntries = Object.entries(propsData);
  return (
    <div className="boxContent mt-3">
      <div id="vital_sign_panel">
        {objectEntries &&
          objectEntries.map((value, index) => {
            const objectName = value[0];
            objectEntries[index][1]['Status'] = propsType.includes(objectName);
            const dataUnit = value[1].Unit;
            const dataName = value[1].Name;
            const dataNormalMin = value[1].NormalMin;
            const dataNormalMax = value[1].NormalMax;
            const dataCurrentValue = value[1].CurrentValue;
            const isShow = value[1].Status;
            return (
              <React.Fragment key={index}>
                <table data-name={objectName} className={`${isShow ? "d-table" : "d-none"}`} style={{ fontSize: "small", width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="vital_title">
                        {dataName}
                        <br />
                        <span className="vital_unit">
                          ({dataUnit})
                        </span>
                      </td>
                      <td className="vital_value">
                        <h3 className="vital_abnormal">
                          <span
                            className={
                              dataCurrentValue > dataNormalMin &&
                              dataCurrentValue < dataNormalMax
                                ? "default-color"
                                : "has-color"
                            }
                          >
                            {dataCurrentValue}
                          </span>
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}

export default Measurements;
