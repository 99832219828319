import React from 'react';

//redux
import { useSelector } from "react-redux";

function QuoteSection() {
    const portfolioData = useSelector((state) => state.portfolio || []);
    const Quote = portfolioData?.data?.Quote;
    return (
        <section className="quote-box mt-4">
            <p className="quote-box__text">
                {`“${Quote?.Content || ""}”`}
            </p>
            <p className="quote-box__author">-{Quote?.Author || ""}</p>
        </section>
    );
}

export default QuoteSection;