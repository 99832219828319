import React, { useState, useRef, useEffect } from "react";
import JoditEditor from "jodit-react";

//redux
import { useDispatch } from "react-redux";

//react-router
import { useParams } from "react-router-dom";

//Config
import * as Config from "@constants/Config";

//api
import { apiCaller } from "@utils";
import { caseConstants } from "@constants";

//components
import { SectionTitleGroup } from "../EndingTimepoint";

function TabTakeHome(props) {
  //props 
  const { caseData } = props && props;
  const resData = caseData?.data;

  let { id } = useParams();
  const dispatch = useDispatch();
  const editor = useRef(null);
  const [content, setContent] = useState(resData?.TakeHomeMessage || '');

  //loadData
  useEffect(() => {
    setContent(resData?.TakeHomeMessage || '');
  }, [resData])

  const handleApi = (content, dataSection) => {
    setContent(content);
    const params = {
      "Action": "Update",
      "Target": "TakeHomeMessage",
      "Message": content,
      "Sections": dataSection
    };
    
    // Case - Update Take-Home Message
    dispatch({ type: caseConstants.TAKE_HOME_MESSAGE_REQUEST, isLoading: true });
    apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
      console.log(res, "res....");
      const data = res && res.data;
      if (res && res.status === 200) {
        setContent(data?.TakeHomeMessage || '');
        dispatch({
          type: caseConstants.TAKE_HOME_MESSAGE_SUCCESS,
          payload: data
        });
      } else {
        dispatch({
          type: caseConstants.TAKE_HOME_MESSAGE_FAILURE,
          error: "error",
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className="mb-4">
        <h4 className="mb-3">Overview: </h4>
        <JoditEditor
          ref={editor}
          value={content}
          config={Config.CONFIG_JODIT}
          tabIndex={1}
          onChange={(newContent) => setContent(newContent)}
        />
      </div>
      <SectionTitleGroup
        {...props}
        handleSubmitSection={(sectionsState) => handleApi(content, sectionsState)}
      />
    </React.Fragment>
  );
}

export default TabTakeHome;