import React from 'react'
import { Input } from 'reactstrap';
import TitleNoteCommon from './TitleNoteCommon';

const InputComponent = (props) => {
  const { setValue } = props
  return (
    <div className='inputComponent'>
      <div className='inputComponent__header w-100'>
        <TitleNoteCommon dataFor='instruction' title={'Instructions'} content={'Describe the topic, learning objectives, target learners, or any specific details you want the AI to consider for generation.'} />
        {/* <div className='inputComponent__header-note'>
          <p className='note-remaining'>Runs remaining: 10</p>
          <div className='note-upgrade'>
            <Icon src={star}/>
            <p>Upgrade</p>
          </div>
        </div> */}
      </div>
      <div className='inputComponent__input w-100'>
        <Input
          type='textarea'
          name='prompt'
          placeholder='Enter your requirement here'
          style={{ height: '150px' }}
          onChange={(e) => {
            setValue("prompt", e.target.value);
          }}
        />
      </div>
    </div>
  )
}

export default InputComponent
