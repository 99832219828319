import React from "react";

//Boostrap
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

//components
import { ListTabDetail } from "./Ticket";

function ModalDetails(props) {
  const { isOpen, toggle, item } = props && props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`} style={{maxWidth: '600px'}}>
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">Ticket #{item.TicketId}</span>
      </ModalHeader>
      <ModalBody className="p-3">
        <ListTabDetail {...props} />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalDetails;
